import React from "react";
import { connect } from "react-redux";
import FormContainer from "../components/FormContainer";
import { actionCreators } from "../../../modules/actionCreators";
import { IRootState } from "../../../store";
import { IDaySchedule } from "../../../models/IDaySchedule";
import { ICrew } from "../../../models/ICrew";
import CrewMemberSelection from "../components/CrewMemberSelection";
import { ICrewMemberIdentifier } from "../../../models/ICrewMemberIdentifier";
import { ICrewMember } from "../../../models/ICrewMember";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import LinkButton2 from "../components/LinkButton2";
import { fullStoryTrack } from "../../../services/fullStoryService";
import { ScheduledCommunication } from "../../../enums/scheduledCommunication";

interface IProps {
  dayScheduleId: string | null;
  showForm: boolean;
  errorMessage: string | React.ReactNode;
  saving: boolean;
  startSave(formData: any): void;
  cancel(): void;
  setErrorMessage(errorMessage: string): void;
  crews: Array<ICrew>;
  crewMembers: Array<ICrewMember>;
  daySchedules: Array<IDaySchedule>;
  showScheduledDispatchSettingsForm(parameters: any): void;
}

interface IState {
  formData: IFormData;
}

interface IFormData {
  recipientEmailAddresses: Array<string>;
  crewMembers: Array<ICrewMemberIdentifier>;
  sendBetaLink: boolean;
}

type recipientProperties = "recipientEmailAddresses";

class PublishScheduleForm extends React.Component<IProps, IState> {
  private lastPhoneNumberRef: React.MutableRefObject<HTMLInputElement | null>;
  private lastEmailAddressRef: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);

    this.state = {
      formData: this.getDefaultFormData(),
    };

    this.lastPhoneNumberRef = React.createRef<HTMLInputElement>();
    this.lastEmailAddressRef = React.createRef<HTMLInputElement>();

    this.updateText = this.updateText.bind(this);
    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.getFormData = this.getFormData.bind(this);
  }

  componentDidUpdate(prevProps: IProps) {
    const props = this.props;
    if (prevProps.showForm !== this.props.showForm && props.showForm) {
      const formData = this.getDefaultFormData();
      this.setState({ formData });

      const isSetToEmptyValues = (input: Array<string>) =>
        input.length === 1 && !input[0];

      // Call setTimeout to allow phone number element to be refreshed with the empty string.
      // Also saw an issue with previous email address not being cleared without this.
      setTimeout(() => {
        if (
          this.lastPhoneNumberRef.current &&
          isSetToEmptyValues(formData.recipientEmailAddresses)
        ) {
          this.lastPhoneNumberRef.current.focus();
        }
      });
    }
  }

  getDefaultFormData(): IFormData {
    const crew = this.getCrew();
    const crewMembers = this.getCrewMembers();
    if (!crew) {
      return {
        recipientEmailAddresses: [""],
        crewMembers,
        sendBetaLink: false,
      };
    } else {
      return {
        recipientEmailAddresses: crew.defaultRecipientEmailAddresses || [],
        crewMembers,
        sendBetaLink: false,
      };
    }
  }

  getCrew(): ICrew | undefined {
    const dayScheduleId = this.props.dayScheduleId;
    let crew: ICrew | undefined = undefined;
    this.props.daySchedules.forEach((ds) => {
      if (ds.id === dayScheduleId) {
        crew = this.props.crews.find((c) => c.id === ds.crewId);
      }
    });

    return crew;
  }

  getCrewMembers(): Array<ICrewMemberIdentifier> {
    const dayScheduleId = this.props.dayScheduleId;

    if (!dayScheduleId) {
      return [];
    }

    const crew = this.getCrew();
    if (crew && crew.defaultRecipientCrewMembers) {
      return crew.defaultRecipientCrewMembers.filter((cm) =>
        this.props.crewMembers.some((c) => c.id === cm.id)
      );
    } else {
      return [];
    }
  }

  updateText(index: number, value: string, type: recipientProperties) {
    const items = [...this.state.formData[type]];
    items[index] = value;

    const newFormData = {
      ...this.state.formData,
    };
    newFormData[type] = items;

    this.setState({
      formData: newFormData,
    });
  }

  removeItem(index: number, type: recipientProperties) {
    const items = [...this.state.formData[type]];
    items.splice(index, 1);

    const newFormData = {
      ...this.state.formData,
    };
    newFormData[type] = items;

    this.setState({
      formData: newFormData,
    });
  }

  addItem(
    type: recipientProperties,
    refToFocusOn: React.RefObject<HTMLInputElement>
  ) {
    const items = [...this.state.formData[type]];
    items.push("");

    const newFormData = {
      ...this.state.formData,
    };
    newFormData[type] = items;

    this.setState({
      formData: newFormData,
    });

    setTimeout(() => {
      if (refToFocusOn.current) {
        refToFocusOn.current.focus();
      }
    });
  }

  getFormData() {
    const crew = this.getCrew();
    return {
      ...this.state.formData,
      crewId: crew ? crew.id : "",
    };
  }

  validate() {
    return { valid: true };
  }

  render() {
    const { formData } = this.state;
    const {
      showForm,
      startSave,
      cancel,
      errorMessage,
      saving,
      setErrorMessage,
    } = this.props;

    const hasAutomaticDispatchingSetup = this.props.crews.some(
      (c) =>
        c.scheduledDispatchSendSchedule === ScheduledCommunication.DayBefore ||
        c.scheduledDispatchSendSchedule === ScheduledCommunication.DayOf
    );

    return (
      <FormContainer
        setErrorMessage={setErrorMessage}
        validate={this.validate}
        getFormData={this.getFormData}
        formHeader="Dispatch Schedule"
        showForm={showForm}
        errorMessage={errorMessage}
        saving={saving}
        startSave={startSave}
        cancel={cancel}
        formKey="publishSchedule"
        saveButtonText="Dispatch"
        additionalSaveButtons={[
          {
            label: "Save Without Dispatching",
            enrichFormData: (input: any) => {
              return {
                ...input,
                savesDefaultRecipientsOnly: true,
              };
            },
          },
        ]}
      >
        {!hasAutomaticDispatchingSetup ? (
          <div
            className="alert alert-info"
            style={{ padding: "5px", paddingLeft: "10px" }}
            data-testid="automaticDispatchingAlert"
          >
            <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
            <small>
              Tired of dispatching every day? Setup automatic dispatching{" "}
              <LinkButton2
                buttonContents={<small>here!</small>}
                style={{
                  verticalAlign: "baseline",
                  textDecoration: "underline",
                }}
                className="text-reset"
                onClick={() => {
                  fullStoryTrack("Dispatch Form - Open Automatic Dispatching");
                  this.props.showScheduledDispatchSettingsForm({});
                }}
                testId="openAutomaticDispatching"
              />
            </small>
          </div>
        ) : null}

        <div className="form-group">
          <CrewMemberSelection
            value={formData.crewMembers}
            usePortal
            onChange={(newCrewMembers) =>
              this.setState({
                formData: {
                  ...formData,
                  crewMembers: newCrewMembers,
                },
              })
            }
          />
        </div>

        <div className="form-group mt-4">
          <div>
            <label htmlFor="recipientEmailAddress">Email addresses</label>
          </div>
          {formData.recipientEmailAddresses.map((e, index) => {
            const lastItem =
              index === formData.recipientEmailAddresses.length - 1;
            return (
              <div
                key={index}
                className={`input-group ${!lastItem ? "mb-3" : ""}`}
              >
                <input
                  data-testid="recipientEmailAddress"
                  id="recipientEmailAddress"
                  type="email"
                  name="recipientEmailAddress"
                  value={e}
                  className="form-control"
                  aria-label="Email address"
                  ref={
                    formData.recipientEmailAddresses.length - 1 === index
                      ? this.lastEmailAddressRef
                      : null
                  }
                  onChange={(e) => {
                    this.updateText(
                      index,
                      e.target.value,
                      "recipientEmailAddresses"
                    );
                  }}
                />
                <div className="input-group-append">
                  <button
                    data-testid="removeRecipientEmailAddress"
                    type="button"
                    className="btn btn-sm text-right pr-0"
                    onClick={() =>
                      this.removeItem(index, "recipientEmailAddresses")
                    }
                    style={{ zIndex: 0 }}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      title="Remove email address"
                    />
                  </button>
                </div>
              </div>
            );
          })}
          <div className="text-center">
            <button
              type="button"
              className="btn btn-link"
              onClick={() =>
                this.addItem(
                  "recipientEmailAddresses",
                  this.lastEmailAddressRef
                )
              }
              style={{ padding: 0 }}
            >
              Add email address
            </button>
          </div>
        </div>
      </FormContainer>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  crews: state.crew.crews,
  crewMembers: state.crew.crewMembers,
  daySchedules: state.schedule.daySchedules,
  dayScheduleId: state.forms.publishSchedule.parameters
    ? state.forms.publishSchedule.parameters.dayScheduleId
    : null,
  showForm: state.forms.publishSchedule.showForm,
  errorMessage: state.forms.publishSchedule.errorMessage,
  saving: state.forms.publishSchedule.saving,
});

const mapDispatchToProps = {
  startSave: actionCreators.forms.publishSchedule.startSaving,
  cancel: actionCreators.forms.publishSchedule.cancelForm,
  setErrorMessage: actionCreators.forms.publishSchedule.setErrorMessage,
  showScheduledDispatchSettingsForm:
    actionCreators.forms.scheduledDispatchSettings.showForm,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublishScheduleForm);
