import { BillingFrequencyType } from "../models/ICustomerPaymentConfiguration";

export function getFrequencyTypeForDisplay(
  frequencyType: BillingFrequencyType | null
) {
  switch (frequencyType) {
    case BillingFrequencyType.monthly:
      return "Monthly";
    case BillingFrequencyType.weekly:
      return "Weekly";
    case BillingFrequencyType.quarterly:
      return "Every 3 months";
    case BillingFrequencyType.annually:
      return "Annually";
    case BillingFrequencyType.semiAnnually:
      return "Every 6 months";
  }

  return "";
}
