import React from "react";

interface IProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {}

const TimeZoneSelect: React.FunctionComponent<IProps> = (props) => {
  return (
    <select {...props}>
      <option value="America/New_York">Eastern</option>
      <option value="America/Chicago">Central</option>
      <option value="America/Denver">Mountain</option>
      <option value="America/Phoenix">Arizona</option>
      <option value="America/Los_Angeles">Pacific</option>
      <option value="America/Anchorage">Alaska</option>
      <option value="Pacific/Honolulu">Hawaii</option>
    </select>
  );
};

export default TimeZoneSelect;
