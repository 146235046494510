import uuidv4 from "uuid/v4";

export type NoteToken = {
  id: string;
  contents: string;
} & (
  | {}
  | {
      link: string;
      trailingWhitespace: string;
    }
);

const propertyIntelRegex =
  /(https:\/\/pi.youraspire.com\/public\/sections\/[A-Za-z0-9-]{36})(\s*)/;
const onlyWhitespaceRegex = /^\s+$/;

export function getNoteTokens(note: string): Array<NoteToken> {
  note = note.trim();

  let processedNotes: Array<NoteToken>;
  if (propertyIntelRegex.test(note)) {
    const tokens = note.split(/(\s+)/);
    let piCounter = 0;

    processedNotes = [];
    let index = 0;
    while (index < tokens.length) {
      let token = tokens[index];

      let nextTokenProcessed = false;
      if (
        typeof tokens[index + 1] === "string" &&
        onlyWhitespaceRegex.test(tokens[index + 1])
      ) {
        token += tokens[index + 1];
        nextTokenProcessed = true;
      }

      const regexMatch = propertyIntelRegex.exec(token);
      if (regexMatch && regexMatch[1]) {
        let trailingWhitespace = "";
        if (regexMatch[2]) {
          trailingWhitespace = regexMatch[2];
        }

        piCounter++;

        const prefix = "Interactive property map";
        processedNotes.push({
          id: uuidv4(),
          contents: piCounter > 1 ? `${prefix} ${piCounter}` : prefix,
          link: regexMatch[1],
          trailingWhitespace,
        });
      } else {
        processedNotes.push({
          id: uuidv4(),
          contents: token,
        });
      }

      if (nextTokenProcessed) {
        index += 2;
      } else {
        index++;
      }
    }
  } else {
    processedNotes = [
      {
        id: uuidv4(),
        contents: note,
      },
    ];
  }

  return processedNotes;
}

export function getTruncatedTokens({
  noteTokens,
  inlineStringLength,
  maxLines,
}: {
  noteTokens: Array<NoteToken>;
  inlineStringLength: number;
  maxLines?: number;
}) {
  const result: Array<NoteToken> = [];

  let totalCharacterCount = 0;
  let totalNewlineCount = 0;

  let isPartialStringShown = false;

  noteTokens.forEach((token) => {
    const tokenCharacterCount = token.contents.length;
    let newToken: NoteToken | null = token;
    if (totalCharacterCount > inlineStringLength) {
      newToken = null;

      isPartialStringShown = true;
    } else if (totalCharacterCount + tokenCharacterCount > inlineStringLength) {
      newToken = {
        ...token,
        contents: token.contents.substring(
          0,
          inlineStringLength - totalCharacterCount
        ),
      };

      isPartialStringShown = true;
    }
    totalCharacterCount += tokenCharacterCount;

    if (newToken !== null) {
      const contentsSplitOnLines = newToken.contents.split("\n");
      const tokenNewLineCount = contentsSplitOnLines.length;

      if (typeof maxLines === "number") {
        if (totalNewlineCount > maxLines) {
          newToken = null;

          isPartialStringShown = true;
        } else if (totalNewlineCount + tokenNewLineCount > maxLines) {
          newToken = {
            ...newToken,
            contents: contentsSplitOnLines
              .filter((_, index) => index < maxLines)
              .join("\n"),
          };

          isPartialStringShown = true;
        }
      }

      totalNewlineCount += tokenNewLineCount - 1;
    }

    if (newToken !== null) {
      result.push(newToken);
    }
  });

  return {
    newTokens: result,
    isPartialStringShown,
  };
}
