import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { InvoiceDeliveryMethod } from "../../../models/InvoiceDeliveryMethod";
import { IQuickBooksInvoiceSettings } from "../../../models/IQuickBooksInvoiceSettings";
import { useUserSettings } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import InfoToolTip from "../../../containers/app/components/InfoToolTip";

export type PaymentMethodOptions = {
  allowCreditCardPayment: boolean;
  allowAchPayment: boolean;
  addConvenienceFee: boolean;
};

interface IProps {
  isCrewControlDeliveryWithPayments: boolean;
  value: PaymentMethodOptions;
  onChange: (newValue: PaymentMethodOptions) => void;
  hideAuthorizePaymentMethodOnFile?: boolean;
}

const InvoicePaymentMethodSelection: React.FunctionComponent<IProps> = ({
  isCrewControlDeliveryWithPayments: isCrewControlDelivery,
  value,
  onChange,
  hideAuthorizePaymentMethodOnFile,
}) => {
  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );
  const paymentsConvenienceFeePercentForDisplay = useApplicationStateSelector(
    (s) => s.common.paymentsConvenienceFeePercentForDisplay
  );
  const captureCustomerPaymentMethod = useApplicationStateSelector(
    (s) => s.common.invoiceConfiguration.captureCustomerPaymentMethod
  );
  const { setUserSettings } = useUserSettings();

  return (
    <>
      <div className="form-group">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="allowCreditCardPayment"
            name="allowCreditCardPayment"
            checked={value.allowCreditCardPayment}
            onChange={(e) => {
              const newValue = e.target.checked;
              onChange({
                ...value,
                allowCreditCardPayment: newValue,
                addConvenienceFee: !newValue ? false : value.addConvenienceFee,
              });
            }}
          />
          <label
            className="custom-control-label"
            htmlFor="allowCreditCardPayment"
          >
            Allow credit card payments
          </label>
        </div>
      </div>

      {isCrewControlDelivery && paymentsConvenienceFeePercentForDisplay ? (
        <>
          <div className="ml-3 form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="addConvenienceFee"
                name="addConvenienceFee"
                data-testid="addConvenienceFee"
                checked={value.addConvenienceFee}
                onChange={(e) => {
                  onChange({
                    ...value,
                    allowAchPayment: true,
                    addConvenienceFee: e.target.checked,
                  });

                  setUserSettings(
                    UserSettingsType.paymentsConvenienceFeePercentEnabled,
                    e.target.checked
                  );
                }}
                disabled={!value.allowCreditCardPayment}
              />
              <label
                className="custom-control-label"
                htmlFor="addConvenienceFee"
              >
                Add {paymentsConvenienceFeePercentForDisplay}% convenience fee
                {isQuickBooksEnabled ? " via Crew Control" : ""}
              </label>
            </div>
          </div>

          {value.addConvenienceFee &&
          captureCustomerPaymentMethod &&
          !hideAuthorizePaymentMethodOnFile ? (
            <div
              className="alert alert-warning mb-2"
              style={{ padding: "5px", paddingLeft: "10px" }}
              data-testid="savePaymentMethodWarning"
            >
              <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
              <small>
                The customer will not be asked to save their payment method on
                file if the convenience fee is enabled. Convenience fees will
                not be applied to saved card payments on file due to credit card
                regulations.
              </small>
            </div>
          ) : null}
        </>
      ) : null}
      <div className="form-group">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="allowAchPayment"
            name="allowAchPayment"
            checked={value.allowAchPayment}
            disabled={value.addConvenienceFee}
            onChange={(e) => {
              onChange({
                ...value,
                allowAchPayment: e.target.checked,
              });
            }}
          />
          <label
            className="custom-control-label"
            htmlFor="allowAchPayment"
            id="allowAchPaymentLabel"
          >
            Allow ACH payments
          </label>
          {value.addConvenienceFee ? (
            <InfoToolTip
              id={"allowAchPaymentToolTip"}
              testId="allowAchPaymentToolTip"
              text={
                "ACH must be enabled if using a convenience fee for credit cards.  Regulations for convenience fees for credit cards require customers to be able to choose a payment method that doesn't charge the customer a fee.  Convenience fees only apply to credit cards and not ACH."
              }
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default InvoicePaymentMethodSelection;

export function arePaymentFieldsVisibleForQuickBooks(
  quickBooksSettings: IQuickBooksInvoiceSettings | null,
  deliveryMethod: InvoiceDeliveryMethod,
  isQuickBooksEnabled: boolean
) {
  return (
    quickBooksSettings &&
    quickBooksSettings.paymentsEnabled &&
    deliveryMethod === InvoiceDeliveryMethod.emailWithQuickBooks &&
    isQuickBooksEnabled
  );
}

export function arePaymentFieldsVisibleForCrewControl(
  deliveryMethod: InvoiceDeliveryMethod,
  editingPayThroughCrewControlInvoice: boolean,
  areCreditCardsEnabled: boolean
) {
  const crewControlDeliveredPaymentMethod =
    deliveryMethod === InvoiceDeliveryMethod.emailWithCrewControl ||
    deliveryMethod === InvoiceDeliveryMethod.textWithCrewControl ||
    deliveryMethod === InvoiceDeliveryMethod.printWithCrewControl ||
    editingPayThroughCrewControlInvoice;
  return crewControlDeliveredPaymentMethod && areCreditCardsEnabled;
}

export function isCrewControlDeliveryMethod(
  deliveryMethod: InvoiceDeliveryMethod
) {
  return (
    deliveryMethod === InvoiceDeliveryMethod.emailWithCrewControl ||
    deliveryMethod === InvoiceDeliveryMethod.textWithCrewControl ||
    deliveryMethod === InvoiceDeliveryMethod.printWithCrewControl ||
    deliveryMethod === InvoiceDeliveryMethod.paymentMethodOnFile
  );
}

export function arePaymentFieldsVisible({
  quickBooksSettings,
  deliveryMethod,
  editingPayThroughCrewControlInvoice,
  isQuickBooksEnabled,
  areCreditCardsEnabled,
}: {
  quickBooksSettings: IQuickBooksInvoiceSettings | null;
  deliveryMethod: InvoiceDeliveryMethod;
  editingPayThroughCrewControlInvoice: boolean;
  isQuickBooksEnabled: boolean;
  areCreditCardsEnabled: boolean;
}) {
  return (
    arePaymentFieldsVisibleForCrewControl(
      deliveryMethod,
      editingPayThroughCrewControlInvoice,
      areCreditCardsEnabled
    ) ||
    arePaymentFieldsVisibleForQuickBooks(
      quickBooksSettings,
      deliveryMethod,
      isQuickBooksEnabled
    )
  );
}
