import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { DateFilterOptions } from "../../../../enums/dateFilterOptions";
import { SortDirection } from "../../../../enums/sortDirection";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { ICustomer } from "../../../../models/ICustomer";
import { getLocationName } from "../../../../services/customerLocationHelper";
import dateService from "../../../../services/dateService";
import { UserSettingsType } from "../../../../enums/userSettingsType";
import CustomerOpportunitiesActions from "./CustomerOpportunitiesActions";
import OpportunityForm from "../../../../slices/sales/components/OpportunityForm";
import { OpportunityProposalStatus } from "../../../../slices/sales/enums/opportunityProposalStatus";
import { IOpportunity } from "../../../../slices/sales/models/IOpportunity";
import { IOpportunityBoard } from "../../../../slices/sales/models/IOpportunityBoard";
import { IProposalExisting } from "../../../../slices/sales/models/IProposal";
import Spinner from "../../components/Spinner";
import CustomerOpportunitiesFilter from "./CustomerOpportunitiesFilter";
import CustomersChildEntityListing from "./CustomersChildEntityListing";

enum OpportunitySortColumns {
  location,
  status,
  notes,
  archived,
  proposalDate,
  proposalNumber,
}

interface IProps {
  customer: ICustomer;
}

export interface ICustomerOpportunitiesFilters {
  dates: {
    frequency: DateFilterOptions;
    startingDate: string | null;
    endingDate: string | null;
  };
  showArchived: boolean;
  opportunityStatus: OpportunityStatus | "";
  locationId: string | null;
}

export type OpportunityStatus =
  | "To Do"
  | "In Progress"
  | "Proposal Accepted"
  | "Proposal Rejected"
  | "Proposal Scheduled"
  | "Proposal Pending";

const CustomerOpportunities: React.FunctionComponent<IProps> = ({
  customer,
}) => {
  const opportunityBoard = useApplicationStateSelector(
    (s) => s.opportunity.board
  );
  const opportunities = useApplicationStateSelector(
    (s) => s.opportunity.opportunities
  );
  const proposals = useApplicationStateSelector((s) => s.proposal.proposals);
  const locations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );
  const customerUrlRoot = useApplicationStateSelector(
    (s) => s.common.customerUrlRoot
  );

  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [filters, setFilters] = useState<ICustomerOpportunitiesFilters>({
    showArchived: true,
    opportunityStatus: "",
    dates: {
      frequency: DateFilterOptions.all,
      startingDate: null,
      endingDate: null,
    },
    locationId: null,
  });

  const [showAddOpportunityForm, setShowAddOpportunityForm] = useState(false);

  const customerOpportunities = getFilteredOpportunities({
    opportunities,
    proposals,
    customer,
    filters,
    opportunityBoard,
  });

  return (
    <>
      {isSaving ? <Spinner /> : null}

      {errorMessage ? <div className="text-danger">{errorMessage}</div> : null}

      <CustomersChildEntityListing<IOpportunity, OpportunitySortColumns>
        filters={
          <CustomerOpportunitiesFilter
            filters={filters}
            setFilters={setFilters}
            customerId={customer.id}
            showLocationFilter={locations.some(
              (l) => l.customerId === customer.id
            )}
          />
        }
        addButtons={[
          {
            label: "Add opportunity",
            onClick: () => setShowAddOpportunityForm(true),
          },
        ]}
        items={customerOpportunities}
        noItemsMessage="No opportunities"
        hideRowButtons={true}
        columns={[
          {
            header: "Proposal date",
            testId: "proposalDate",
            key: "proposalDate",
            cell: ({ row: opportunity }) => {
              const proposal = getProposal(opportunity.proposalId, proposals);
              if (proposal) {
                return dateService.formatDateForDisplay(proposal.proposalDate);
              } else {
                return "";
              }
            },
            getSortableValue: (opportunity) => {
              const proposal = getProposal(opportunity.proposalId, proposals);
              if (proposal) {
                return proposal.proposalDate;
              } else {
                return "";
              }
            },
            sortable: true,
            sortColumn: OpportunitySortColumns.proposalDate,
          },
          {
            header: "Estimate",
            testId: "proposalNumber",
            key: "proposalNumber",
            cell: ({ row: opportunity }) => {
              const proposal = getProposal(opportunity.proposalId, proposals);
              if (proposal) {
                return (
                  <a
                    href={`${customerUrlRoot}/proposal/${proposal?.lookupId}?ref=admin`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    {proposal?.proposalNumber ?? ""}
                  </a>
                );
              } else {
                return "";
              }
            },
            sortable: true,
            sortColumn: OpportunitySortColumns.proposalNumber,
          },
          {
            header: "Status",
            testId: "status",
            key: "status",
            cell: ({ row: opportunity }) => {
              return getOpportunityStatusText(opportunity, opportunityBoard);
            },
            sortable: true,
            sortColumn: OpportunitySortColumns.status,
          },
          {
            header: "Archived",
            testId: "archived",
            key: "archived",
            cell: ({ row: opportunity }) => {
              return opportunity.archived ? (
                <FontAwesomeIcon icon={faCheck} title="Archived" />
              ) : null;
            },
            getSortableValue: (opportunity) =>
              opportunity.archived ? "1" : "0",
            sortable: true,
            sortColumn: OpportunitySortColumns.archived,
          },
          {
            header: "Location",
            testId: "location",
            key: "location",
            cell: ({ row: o }) => {
              if (o.customerAdditionalLocationId) {
                return getLocationName(
                  o.customerAdditionalLocationId,
                  locations
                );
              }

              return "Customer Address";
            },
            sortable: true,
            sortColumn: OpportunitySortColumns.location,
          },
          {
            header: "Notes",
            testId: "notes",
            key: "notes",
            cell: ({ row: o }) => {
              return o.notes;
            },
            sortable: true,
            sortColumn: OpportunitySortColumns.notes,
          },
          {
            header: "",
            testId: "actions",
            key: "actions",
            isButtonCell: true,
            cell: ({ row: o, displayType }) => {
              return (
                <CustomerOpportunitiesActions
                  customer={customer}
                  isProposalLane={isOpportunityInLane(
                    o,
                    opportunityBoard.proposalOpportunities
                  )}
                  opportunity={o}
                  proposal={getProposal(o.proposalId, proposals)}
                  setIsUnarchiving={setIsSaving}
                  setIsDeleting={setIsSaving}
                  setErrorMessage={setErrorMessage}
                  rightAlign={displayType === "desktop"}
                />
              );
            },
          },
        ]}
        sortColumnSettingsKey={UserSettingsType.customerOpportunitiesSortColumn}
        defaultSortColumn={OpportunitySortColumns.proposalDate}
        defaultSortDirection={SortDirection.Descending}
        mobileSortColumn={OpportunitySortColumns.proposalDate}
        mobileSortDirection={SortDirection.Descending}
      />

      {showAddOpportunityForm ? (
        <OpportunityForm
          defaultCustomerId={customer.id}
          onSaveComplete={() => setShowAddOpportunityForm(false)}
          onCancel={() => setShowAddOpportunityForm(false)}
        />
      ) : null}
    </>
  );
};

export default CustomerOpportunities;

function getFilteredOpportunities({
  opportunities,
  proposals,
  customer,
  filters,
  opportunityBoard,
}: {
  opportunities: IOpportunity[];
  proposals: IProposalExisting[];
  customer: ICustomer;
  filters: ICustomerOpportunitiesFilters;
  opportunityBoard: IOpportunityBoard;
}) {
  return opportunities.filter((o) => {
    const proposal = getProposal(o.proposalId, proposals);
    return (
      o.customerId === customer.id &&
      (!o.archived || filters.showArchived) &&
      (filters.opportunityStatus === "" ||
        getOpportunityStatusText(o, opportunityBoard) ===
          filters.opportunityStatus) &&
      ((filters.locationId === customer.id &&
        !o.customerAdditionalLocationId) ||
        !filters.locationId ||
        filters.locationId === o.customerAdditionalLocationId) &&
      (!proposal ||
        filters.dates.frequency === DateFilterOptions.all ||
        (filters.dates.startingDate &&
          filters.dates.endingDate &&
          proposal.proposalDate >= filters.dates.startingDate &&
          proposal.proposalDate <= filters.dates.endingDate))
    );
  });
}

export function getOpportunityStatusText(
  opportunity: IOpportunity,
  opportunityBoard: IOpportunityBoard
): OpportunityStatus | "" {
  if (isOpportunityInLane(opportunity, opportunityBoard.todoOpportunities)) {
    return "To Do";
  } else if (
    isOpportunityInLane(opportunity, opportunityBoard.inProgressOpportunities)
  ) {
    return "In Progress";
  } else if (
    isOpportunityInLane(opportunity, opportunityBoard.proposalOpportunities) ||
    opportunity.archived
  ) {
    switch (opportunity.proposalStatus) {
      case OpportunityProposalStatus.Accepted:
        return "Proposal Accepted";
      case OpportunityProposalStatus.Rejected:
        return "Proposal Rejected";
      case OpportunityProposalStatus.Scheduled:
        return "Proposal Scheduled";
      default:
        return "Proposal Pending";
    }
  } else {
    return "";
  }
}

function isOpportunityInLane(
  opportunity: IOpportunity,
  laneOpportunityIds: Array<string>
) {
  return laneOpportunityIds.some(
    (laneOpportunityId) => laneOpportunityId === opportunity.id
  );
}

function getProposal(
  proposalId: string | null,
  proposals: Array<IProposalExisting>
) {
  if (!proposalId) {
    return null;
  }

  return proposals.find((p) => p.id === proposalId) ?? null;
}
