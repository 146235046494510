import React, { useEffect, useState } from "react";
import { finalize } from "rxjs/operators";
import { IUserAccount } from "../../../models/IUserAccount";
import dataProvider from "../../../services/dataProvider";
import Prompt from "./Prompt";
import { UserAccountRole } from "../../../enums/userAccountRole";
import { SaveUserAccountRequest } from "../../../services/remoteDataProvider";

interface IProps {
  userAccount: IUserAccount;
  formWaiting: boolean;
  currentUserIsTenantOwner: boolean;
  reloadUserAccounts(): void;
  setFormWaiting(waiting: boolean): void;
  setErrorMessage(message: string): void;
  currentUserId: string;
}

const OwnerRoleId = "-1";

const ManageAdminUsersRoleSelection: React.FunctionComponent<IProps> = ({
  userAccount,
  formWaiting,
  currentUserIsTenantOwner,
  reloadUserAccounts,
  setErrorMessage,
  setFormWaiting,
  currentUserId,
}) => {
  const [showUpdateOwnerPrompt, setShowUpdateOwnerPrompt] = useState(false);
  const [value, setValue] = useState(UserAccountRole.administrator.toString());

  useEffect(() => {
    setValue(getDropDownValue(userAccount.isTenantOwner, userAccount.role));
  }, [userAccount.role, userAccount.isTenantOwner]);

  const updateUserAccount = (
    body: SaveUserAccountRequest,
    errorCallback?: () => void
  ) => {
    setErrorMessage("");
    setFormWaiting(true);
    setShowUpdateOwnerPrompt(false);

    dataProvider
      .saveUserAccount(userAccount.id, body)
      .pipe(finalize(() => setFormWaiting(false)))
      .subscribe(
        () => {
          reloadUserAccounts();
        },
        (err: any) => {
          setErrorMessage(
            "An unknown error occurred updating the user's role."
          );

          if (errorCallback) {
            errorCallback();
          }
        }
      );
  };

  const isCurrentUser = currentUserId === userAccount.id;

  return (
    <>
      <Prompt
        showPrompt={showUpdateOwnerPrompt}
        promptMessage={`Are you sure you want to set ${userAccount.emailAddress} as the owner?`}
        promptSubMessage={[
          {
            text: "You will no longer be able to remove admin users.",
            bold: true,
          },
        ]}
        onConfirm={() => {
          updateUserAccount({ isTenantOwner: true });
        }}
        onCancel={() => setShowUpdateOwnerPrompt(false)}
      />
      <select
        aria-label="Role"
        className="form-control"
        value={value}
        disabled={formWaiting || value === OwnerRoleId || isCurrentUser}
        onChange={(e) => {
          if (e.currentTarget.value === OwnerRoleId) {
            setShowUpdateOwnerPrompt(true);
          } else {
            setValue(e.currentTarget.value);
            updateUserAccount({ role: parseInt(e.currentTarget.value) }, () =>
              setValue(
                getDropDownValue(userAccount.isTenantOwner, userAccount.role)
              )
            );
          }
        }}
      >
        {currentUserIsTenantOwner || value === OwnerRoleId ? (
          <option value={OwnerRoleId}>Owner</option>
        ) : null}
        <option value={UserAccountRole.administrator.toString()}>Admin</option>
        <option value={UserAccountRole.scheduler.toString()}>Scheduler</option>
      </select>
    </>
  );
};

export default ManageAdminUsersRoleSelection;

function getDropDownValue(isTenantOwner: boolean, role: UserAccountRole) {
  let newValue: string;
  if (isTenantOwner) {
    newValue = OwnerRoleId;
  } else {
    newValue = role.toString();
  }
  return newValue;
}
