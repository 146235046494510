import parse from "date-fns/parse";
import { IScheduleColumnHeader } from "../../../containers/app/components/schedule";
import dateService from "../../../services/dateService";
import { IScheduleRow } from "./types/IScheduleRow";
import differenceInMinutes from "date-fns/difference_in_minutes";
import addMinutes from "date-fns/add_minutes";
import { IDaySchedule } from "../../../models/IDaySchedule";
import { IJobInstance } from "../../../models/IJobInstance";
import { IUnscheduledMaintenanceJob } from "../../../models/IUnscheduledMaintenanceJob";
import { isStringSet } from "../../../services/stringService";
import { useMemo } from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { subtractTimeBlocks } from "../services/timeBlockCalculator";
import {
  createTimeFromHourMinute,
  getHoursAndJobInstancesInTimeSlots,
} from "./ScheduleTimeCalendar.functions";
import { getSerializedTimes } from "./ScheduleTimeCalendarJobCard.functions";
import {
  FocusedTimeSlot,
  JobInstanceBeingMoved,
} from "./ScheduleTimePage.types";
import { IScheduleColumn } from "./types/IScheduleColumn";

export function getVisibleRows(
  isMapVisible: boolean,
  rows: IScheduleRow[],
  columnHeaders: IScheduleColumnHeader[]
) {
  if (isMapVisible) {
    const mapColumnIndex = rows.flatMap((r) =>
      r.columns
        .map((column, columnIndex) => ({
          column,
          columnIndex,
        }))
        .filter((c) => {
          return c.column?.isMapOpen;
        })
        .map((c) => c.columnIndex)
    );

    rows = rows
      .filter((r) => r.columns.some((c) => c?.isMapOpen))
      .map((r) => ({
        ...r,
        columns: r.columns.filter((c) => c?.isMapOpen),
      }));

    columnHeaders = columnHeaders.filter((_, columnHeaderIndex) =>
      mapColumnIndex.includes(columnHeaderIndex)
    );
  }
  return { rows, columnHeaders };
}

export function getTimesFromDragDrop({
  jobInstance,
  daySchedule,
  calcStartTime,
  isFromFlexibleJobContainer,
}: {
  jobInstance: IJobInstance;
  daySchedule: IDaySchedule;
  calcStartTime: Date;
  isFromFlexibleJobContainer: boolean;
}) {
  let diff: number;
  if (
    !isStringSet(jobInstance.startTime) ||
    !isStringSet(jobInstance.endTime) ||
    isFromFlexibleJobContainer
  ) {
    // When moving a job that didn't previously have time, set to 30 min block by default
    diff = 30;
  } else {
    const startTime = dateService.constructDateFromDateAndTime(
      parse(daySchedule.date),
      jobInstance.startTime
    );
    const endTime = dateService.constructDateFromDateAndTime(
      parse(daySchedule.date),
      jobInstance.endTime
    );
    diff = differenceInMinutes(endTime, startTime);
  }

  const newStartTime = calcStartTime;
  const newEndTime = addMinutes(newStartTime, diff);

  return { newStartTime, newEndTime };
}

export function getJobInstance({
  rows,
  daySchedules,
  jobInstanceId,
  weeksUnscheduledMaintenanceJobs,
}: {
  rows: IScheduleRow[];
  daySchedules: IDaySchedule[];
  jobInstanceId: string;
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>;
}): {
  daySchedule: IDaySchedule | null;
  flexibleJobContainer: boolean;
  jobInstance: IJobInstance | null;
} {
  let dayScheduleToReturn: IDaySchedule | null = null;
  let jobInstanceToReturn: IJobInstance | null = null;
  let flexibleJobContainer = false;
  rows.forEach((row) => {
    row.columns
      .filter((c) => c !== null)
      .forEach((column) => {
        const daySchedule = daySchedules.find(
          (ds) => ds.id === column?.dayScheduleId
        );
        if (daySchedule) {
          let jobInstance = daySchedule.jobInstances.find(
            (ji) => ji.id === jobInstanceId
          );
          if (jobInstance) {
            dayScheduleToReturn = daySchedule;
            jobInstanceToReturn = jobInstance;
          }
        }
      });
  });

  if (!jobInstanceToReturn) {
    flexibleJobContainer = true;
    jobInstanceToReturn =
      weeksUnscheduledMaintenanceJobs
        .flatMap((j) => j.jobInstances)
        .find((ji) => ji.id === jobInstanceId) ?? null;
  }

  return {
    daySchedule: dayScheduleToReturn,
    jobInstance: jobInstanceToReturn,
    flexibleJobContainer,
  };
}

export function useGetMovingJobInstanceWithAdjustedTimes({
  movingJobInstanceId,
  rows,
  focusedTimeSlot,
  blockOffset,
}: {
  movingJobInstanceId: string | null;
  rows: IScheduleRow[];
  focusedTimeSlot: FocusedTimeSlot;
  blockOffset: number | null;
}): JobInstanceBeingMoved {
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const weeksUnscheduledMaintenanceJobs = useApplicationStateSelector(
    (s) => s.schedule.weeksUnscheduledMaintenanceJobs
  );

  let jobInstance: IJobInstance | null = null;
  let newDaySchedule: IDaySchedule | null = null;
  let startTimeSerialized: string | null = null;
  let endTimeSerialized: string | null = null;

  if (
    movingJobInstanceId &&
    focusedTimeSlot &&
    focusedTimeSlot.type === "ScheduleTimeSlot"
  ) {
    let {
      daySchedule: sourceDaySchedule,
      jobInstance: obtainedJobInstance,
      flexibleJobContainer: isFromFlexibleJobContainer,
    } = getJobInstance({
      rows,
      daySchedules,
      jobInstanceId: movingJobInstanceId,
      weeksUnscheduledMaintenanceJobs,
    });

    jobInstance = obtainedJobInstance;

    if (jobInstance) {
      const destinationDaySchedule = daySchedules.find(
        (ds) => ds.id === focusedTimeSlot.dayScheduleId
      );

      if (destinationDaySchedule) {
        blockOffset = blockOffset ?? 0;
        const {
          hour: adjustedStartBlockHour,
          minute: adjustedStartBlockMinute,
        } = subtractTimeBlocks({
          hour: focusedTimeSlot.hour,
          minute: focusedTimeSlot.minute,
          blockOffset,
        });

        const calcStartTime = dateService.constructDateFromDateAndTime(
          parse(destinationDaySchedule.date),
          createTimeFromHourMinute(
            adjustedStartBlockHour,
            adjustedStartBlockMinute
          )
        );
        const { newStartTime, newEndTime } = getTimesFromDragDrop({
          jobInstance,
          daySchedule: destinationDaySchedule,
          calcStartTime,
          isFromFlexibleJobContainer,
        });

        ({ startTimeSerialized, endTimeSerialized } = getSerializedTimes(
          newStartTime,
          newEndTime
        ));

        newDaySchedule = destinationDaySchedule;

        if (newDaySchedule !== null) {
          const isDestinationTimeVisible = getIsDestinationTimeVisible({
            rows,
            newDaySchedule,
            daySchedules,
            startTimeSerialized,
            endTimeSerialized,
          });

          const hasTimeChanged =
            jobInstance.startTime !== startTimeSerialized ||
            jobInstance.endTime !== endTimeSerialized;
          const hasScheduleChanged =
            !sourceDaySchedule || sourceDaySchedule.id !== newDaySchedule.id;

          if (
            (!hasTimeChanged && !hasScheduleChanged) ||
            !isDestinationTimeVisible
          ) {
            jobInstance = null;
          }
        }
      }
    }
  } else if (
    movingJobInstanceId &&
    focusedTimeSlot?.type === "FlexibleContainer"
  ) {
    const { jobInstance: obtainedJobInstance, flexibleJobContainer } =
      getJobInstance({
        rows,
        daySchedules,
        jobInstanceId: movingJobInstanceId,
        weeksUnscheduledMaintenanceJobs,
      });

    if (!flexibleJobContainer) {
      jobInstance = obtainedJobInstance;
    }
  }

  const focusedTypeSlotType = focusedTimeSlot?.type ?? null;
  const result = useMemo(() => {
    if (
      focusedTypeSlotType === "ScheduleTimeSlot" &&
      jobInstance &&
      newDaySchedule &&
      startTimeSerialized &&
      endTimeSerialized
    ) {
      return {
        jobInstance: {
          ...jobInstance,
          startTime: startTimeSerialized,
          endTime: endTimeSerialized,
        },
        daySchedule: newDaySchedule,
        type: focusedTypeSlotType,
      };
    } else if (focusedTypeSlotType === "FlexibleContainer" && jobInstance) {
      return {
        jobInstance: jobInstance,
        type: focusedTypeSlotType,
      };
    } else {
      return null;
    }
  }, [
    newDaySchedule,
    jobInstance,
    startTimeSerialized,
    endTimeSerialized,
    focusedTypeSlotType,
  ]);

  return result;
}

function getIsDestinationTimeVisible({
  rows,
  newDaySchedule,
  daySchedules,
  startTimeSerialized,
  endTimeSerialized,
}: {
  rows: IScheduleRow[];
  newDaySchedule: IDaySchedule | null;
  daySchedules: IDaySchedule[];
  startTimeSerialized: string | null;
  endTimeSerialized: string | null;
}) {
  let isDestinationTimeVisible = true;

  const verifiedColumns = (
    rows.find((r) =>
      r.columns.some((c) => c?.dayScheduleId === newDaySchedule?.id)
    )?.columns ?? []
  )
    .filter((c) => c !== null)
    .map((c) => c as IScheduleColumn);

  if (verifiedColumns.length > 0) {
    const { hours: visibleHoursOnSchedule } =
      getHoursAndJobInstancesInTimeSlots({
        daySchedules,
        verifiedColumns,
        draggingJobInstance: null,
      });

    if (visibleHoursOnSchedule.length > 0) {
      const firstHourSerialized = createTimeFromHourMinute(
        visibleHoursOnSchedule[0],
        0
      );

      const lastHourSerialized = createTimeFromHourMinute(
        visibleHoursOnSchedule[visibleHoursOnSchedule.length - 1] + 1,
        0
      );

      if (
        (startTimeSerialized && startTimeSerialized < firstHourSerialized) ||
        (endTimeSerialized && endTimeSerialized > lastHourSerialized)
      ) {
        isDestinationTimeVisible = false;
      }
    }
  }
  return isDestinationTimeVisible;
}
