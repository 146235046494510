import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../../modules/actionCreators";
import addressFormatter from "../../../../services/addressFormatter";
import { getSortedItemsV2 } from "../../../../services/sortingService";
import ContainerHeader from "./ContainerHeader";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { ICrewMember } from "../../../../models/ICrewMember";
import remoteDataProvider from "../../../../services/remoteDataProvider";
import { timeout, finalize } from "rxjs/operators";
import Spinner from "../../components/Spinner";
import { getErrorMessageFromError } from "../../../../services/httpErrorHandler";
import constants from "../../../../constants";
import { CrewScheduleType } from "../../../../slices/schedule/enums/crewScheduleType";
import ResponsiveTable from "../../../../libraries/tableLayout/ResponsiveTable";
import { ICrew } from "../../../../models/ICrew";
import { ResponsiveTableMobileCard } from "../../../../libraries/tableLayout/ResponsiveTableMobileCard";
import { TableColumns } from "../../../../libraries/tableLayout/TableColumn";
import { SubscriptionType } from "../../../../enums/subscriptionType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import subscriptionDataProvider, {
  SubscriptionChangeType,
} from "../../../../slices/tenantSubscription/services/subscriptionDataProvider";
import { ISubscriptionPriceChange } from "../../../../slices/tenantSubscription/models/ISubscriptionPriceChange";
import Prompt from "../../components/Prompt";
import ButtonDropdown from "reactstrap/lib/ButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { TenantSubscriptionStatus } from "../../../../models/IInitialLoad";
import { getPriceChangeMessage } from "../../../../slices/tenantSubscription/services/crewControlSubscriptionService";

const Crews: React.FunctionComponent<{}> = () => {
  const crews = useApplicationStateSelector((s) => s.crew.crews);
  const subscriptionType = useApplicationStateSelector(
    (s) => s.common.subscriptionType
  );
  const sortedCrews = getSortedItemsV2(crews, ["name"], false);
  const [showInactive, setShowInactive] = useState(false);
  const errors = useApplicationStateSelector((s) => s.manageUi.errors);
  const filteredItems = sortedCrews
    .filter((i) => showInactive || !i.inactive)
    .map((c) => ({
      ...c,
      errorMessage: errors[c.id] ? errors[c.id] : undefined,
      key: c.id,
    }));
  const crewMembers = useApplicationStateSelector((c) => c.crew.crewMembers);

  const dispatch = useDispatch();

  const addButton = (
    <button
      className="btn btn-primary"
      onClick={() =>
        dispatch(
          actionCreators.forms.crew.showForm({
            skipRedirect: true,
          })
        )
      }
    >
      Add Crew
    </button>
  );

  const columns: TableColumns<ICrew> = [
    {
      key: "name",
      header: "Name",
      width: "",
      cell: "name",
      testId: "title",
    },
    {
      key: "startingLocation",
      header: "Starting location",
      testId: "startingLocation",
      width: "",
      cell: ({ row }) => addressFormatter.formatCrewAddress(row),
    },
    {
      key: "crewMembers",
      testId: "crewMembers",
      header: "Crew members",
      width: "",
      cell: ({ row }) => {
        const crewMembersForCrew: Array<ICrewMember> = row.crewMembers
          .map(({ id }) => {
            const crewMember = crewMembers.find((cm) => cm.id === id);
            return crewMember as ICrewMember;
          })
          .filter((crewMember) => crewMember && !crewMember.inactive);
        const sortedCrewMembers = getSortedItemsV2(crewMembersForCrew, [
          "name",
        ]);
        const crewMemberString = sortedCrewMembers
          .map((cm) => cm?.name)
          .join(", ");

        return crewMemberString;
      },
    },
    {
      key: "typicalCrewSize",
      testId: "typicalCrewSize",
      header: "Typical crew size",
      width: "0",
      cell: ({ row: crew }) =>
        crew.typicalCrewSize ? crew.typicalCrewSize.toString() : "0",
      headerClassName: "text-nowrap",
    },
    {
      key: "scheduleType",
      testId: "scheduleType",
      header: "Schedule type",
      width: "0",
      cell: ({ row: crew }) =>
        crew.scheduleType === CrewScheduleType.time ? "Time-based" : "Sequence",
      headerClassName: "text-nowrap",
    },
    {
      key: "inactive",
      testId: "inactive",
      header: "Inactive?",
      width: "0",
      cell: ({ row: crew }) => {
        return crew.inactive ? (
          <FontAwesomeIcon icon={faCheck} title="Inactive" />
        ) : null;
      },
      hidden: !showInactive,
    },
    {
      key: "buttons",
      header: "",
      width: "0",
      cellClassName: "text-nowrap py-2",
      isButtonCell: true,
      cell: ({ row: crew }) => <ActionButtons crew={crew} />,
    },
  ];

  return (
    <ContainerHeader
      addButton={addButton}
      pageHeader={`Crews (${filteredItems.length})`}
      messageToShow="No crews exist"
      showInactiveFilter={true}
      itemCount={filteredItems.length}
      onInactiveFilterChanged={(newShowInactive) =>
        setShowInactive(newShowInactive)
      }
      showInactive={showInactive}
    >
      {filteredItems.length === 1 &&
      subscriptionType === SubscriptionType.technician ? (
        <div
          className="alert alert-warning"
          data-testid="techNotAllCrewsUsedWarning"
        >
          <div>
            <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
            Your subscription allows for 2 active crews and you currently only
            have 1. Feel free to add an additional crew for no additional cost!
          </div>
        </div>
      ) : null}

      <ResponsiveTable
        tableClass="table-striped"
        rows={filteredItems}
        columns={columns}
        renderMobile={({ row, index }) => {
          return (
            <>
              <ResponsiveTableMobileCard
                row={row}
                columns={columns}
                rowIndex={index}
              />
            </>
          );
        }}
      />
    </ContainerHeader>
  );
};

export default Crews;

function ActionButtons({ crew }: { crew: ICrew }) {
  type PromptMode = "delete" | "inactivate" | "activate";
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const tenantSubscriptionStatus = useApplicationStateSelector(
    (s) => s.common.tenantSubscriptionStatus
  );

  const [saving, setSaving] = useState(false);
  const [loadingPriceDetails, setLoadingPriceDetails] = useState(false);

  type PromptDataType = {
    mode: PromptMode;
    actionText: string;
    buttonText: string;
    priceChangeDetails: ISubscriptionPriceChange | null;
  } | null;

  const [promptData, setPromptData] = useState<PromptDataType>(null);

  const loadPromptData = ({
    actionText,
    buttonText,
    mode,
    subscriptionChangeType,
  }: {
    actionText: string;
    buttonText: string;
    mode: PromptMode;
    subscriptionChangeType: SubscriptionChangeType;
  }) => {
    let pendingPromptData: PromptDataType = {
      priceChangeDetails: null,
      actionText,
      buttonText,
      mode,
    };

    const deletingInactiveCrew = mode === "delete" && crew.inactive;
    const showPriceDetails =
      tenantSubscriptionStatus === TenantSubscriptionStatus.Subscribed &&
      !deletingInactiveCrew;
    if (showPriceDetails) {
      setLoadingPriceDetails(true);
      dispatch(actionCreators.hideManageUiError(crew.id));

      subscriptionDataProvider
        .getUpdatedPrice({ subscriptionChangeType })
        .pipe(timeout(10000))
        .subscribe({
          next: (result) => {
            setLoadingPriceDetails(false);
            setPromptData({
              ...pendingPromptData,
              priceChangeDetails: result,
            });
          },

          error: () => {
            setLoadingPriceDetails(false);
            dispatch(
              actionCreators.showManageUiError(
                crew.id,
                "Unable to show the delete prompt. Please try again."
              )
            );
          },
        });
    } else {
      setPromptData({
        ...pendingPromptData,
        priceChangeDetails: null,
      });
    }
  };

  const updateActiveStatus = (inactive: boolean) => {
    setSaving(true);

    const crewChanges = {
      inactive,
    };
    remoteDataProvider
      .saveCrew(crewChanges, { crewId: crew.id })
      .pipe(
        timeout(10000),
        finalize(() => setSaving(false))
      )
      .subscribe({
        next: () => {
          dispatch(actionCreators.crewUpdate(crew.id, crewChanges));
        },

        error: (err) => {
          dispatch(
            actionCreators.showManageUiError(
              crew.id,
              getErrorMessageFromError(err, constants.unknownErrorMessage)
            )
          );
        },
      });
  };

  return (
    <>
      {saving || loadingPriceDetails ? <Spinner /> : null}

      <ButtonDropdown
        direction="down"
        isOpen={isContextMenuOpen}
        toggle={() => setIsContextMenuOpen(!isContextMenuOpen)}
        size="sm"
      >
        <button
          className="btn btn-secondary btn-sm"
          onClick={() => setIsContextMenuOpen(!isContextMenuOpen)}
        >
          Actions
        </button>
        <DropdownToggle split color="secondary" size="sm" />
        <DropdownMenu positionFixed={true} right={true}>
          {crew.inactive ? (
            <DropdownItem
              onClick={() => {
                loadPromptData({
                  actionText: "activate",
                  buttonText: "Activate",
                  mode: "activate",
                  subscriptionChangeType: SubscriptionChangeType.crewAdd,
                });
              }}
            >
              Activate
            </DropdownItem>
          ) : (
            <DropdownItem
              onClick={() => {
                loadPromptData({
                  actionText: "inactivate",
                  buttonText: "Inactivate",
                  mode: "inactivate",
                  subscriptionChangeType: SubscriptionChangeType.crewRemove,
                });
              }}
            >
              Inactivate
            </DropdownItem>
          )}
          <DropdownItem
            onClick={() => {
              dispatch(
                actionCreators.forms.crew.showForm({
                  crewId: crew.id,
                  skipRedirect: true,
                })
              );
            }}
          >
            Edit
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              loadPromptData({
                actionText: "delete",
                buttonText: "Delete",
                mode: "delete",
                subscriptionChangeType: SubscriptionChangeType.crewRemove,
              });
            }}
          >
            Delete
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>

      {promptData !== null ? (
        <Prompt
          showPrompt
          promptMessage={getCrewChangePromptMessage({
            promptData: promptData.priceChangeDetails,
            actionText: promptData.actionText,
          })}
          promptSaveText={
            typeof promptData.priceChangeDetails?.prorationAmount ===
              "number" && promptData.priceChangeDetails?.prorationAmount > 0
              ? `Purchase`
              : promptData.buttonText
          }
          onConfirm={() => {
            switch (promptData.mode) {
              case "delete":
                dispatch(actionCreators.crewDeleteStart(crew.id));
                break;
              case "inactivate":
                updateActiveStatus(true);
                break;
              case "activate":
                updateActiveStatus(false);
                break;
              default:
                const exhaustiveCheck: never = promptData.mode;
                return exhaustiveCheck;
            }

            setPromptData(null);
          }}
          onCancel={() => setPromptData(null)}
        />
      ) : null}
    </>
  );
}
function getCrewChangePromptMessage({
  promptData,
  actionText,
}: {
  promptData: ISubscriptionPriceChange | null;
  actionText: string;
}): JSX.Element {
  let priceChangeMessage: JSX.Element | null = null;

  if (promptData !== null) {
    priceChangeMessage = getPriceChangeMessage({
      priceChangeDetails: promptData,
    });
  }

  return (
    <>
      <div data-testid="promptMessage">
        Are you sure you want to {actionText} this crew?
      </div>
      {priceChangeMessage !== null ? (
        <div data-testid="priceChangeMessage" className="mt-2 text-info">
          {priceChangeMessage}
        </div>
      ) : null}
    </>
  );
}
