import parse from "date-fns/parse";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { useGetJobNameFunction } from "../hooks/useGetJobNameFunction";
import { formatTimeForCalendaryDisplay } from "../services/formatTimeForCalendaryDisplay";
import { blockSizeInMinutes } from "./Calendar";
import { JobInstanceWithTimeSlot } from "./ScheduleTimeCalendar.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faExclamationCircle,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";
import jobFinder, { IFoundJob } from "../../../services/jobFinder";
import { IndicatorsMissingTimeWarning } from "../../../containers/app/components/schedule/jobCard/IndicatorsMissingTimeWarning";
import { JobInstanceTimeForm } from "./JobInstanceTimeForm";
import { useDispatch } from "react-redux";
import { actionCreators, actionTypes } from "../../../modules/actionCreators";
import { round } from "../../../services/roundingService";
import addMinutes from "date-fns/add_minutes";
import remoteDataProvider from "../../../services/remoteDataProvider";
import {
  calculateJobCardHeight,
  getJobCardHeight,
  getJobCardPercentWidth,
  getJobInstanceOffsetFromStartingBlock,
  getSerializedTimes,
  getTimeRangeForDisplay,
} from "./ScheduleTimeCalendarJobCard.functions";
import dateService from "../../../services/dateService";
import { createRange } from "../services/createRange";
import { getOffsetFromElement } from "../services/getOffsetFromElement";
import ContextMenu from "../../../containers/app/components/schedule/jobCard/ContextMenu";
import { ICrew } from "../../../models/ICrew";
import { IJobInstance } from "../../../models/IJobInstance";
import JobCard from "../../../containers/app/components/schedule/jobCard";
import LinkButton2 from "../../../containers/app/components/LinkButton2";
import { getCardColors } from "../services/getCardColors";
import { preventClearingAllCards } from "../../../services/selectedCardService";
import { JobCardEmailAddresses } from "../../../containers/app/components/schedule/jobCard/JobCardEmailAddresses";
import { JobCardPhoneNumbers } from "../../../containers/app/components/schedule/jobCard/JobCardPhoneNumbers";
import { ICategoryIdentifier } from "../../../models/ICategoryIdentifier";
import JobInstanceCategoriesContainer from "../../../containers/app/components/JobInstanceCategoriesContainer";
import JobInstanceCategories from "../../../containers/app/components/JobInstanceCategories";
import Indicators from "../../../containers/app/components/schedule/jobCard/Indicators";
import addressFormatter, {
  IGetAddressForJobResult,
} from "../../../services/addressFormatter";
import { JobCardNotes } from "../../../containers/app/components/schedule/jobCard/JobCardNotes";

const preventDetailsTogglingClassName = "stop-details-toggling";

export function ScheduleTimeCalendarJobCard({
  jobInstanceWithTimeSlot,
  calendarBlockHeightInPxs,
  missingTime,
  jobIndex,
  onResizeStart,
  onResizeEnd,
  timeSlotStartTime,
  blocksFromCalendarEnd,
  onStartMove,
  isDragging,
  isAnyJobMoveInProgress,
  crew,
  date,
  jobInstanceShowingDetails,
  setJobInstanceShowingDetails,
  isDragPlaceholderJob,
  jobInstanceShowingContextMenu,
  setJobInstanceShowingContextMenu,
  hasJobMovedPosition,
}: {
  jobInstanceWithTimeSlot: JobInstanceWithTimeSlot;
  calendarBlockHeightInPxs: number;
  missingTime: boolean;
  jobIndex: number;
  onResizeStart: () => void;
  onResizeEnd: () => void;
  timeSlotStartTime: Date | null;
  blocksFromCalendarEnd: number | null;
  onStartMove: (jobInstanceId: string, blockOffset: number) => void;
  isDragging: boolean;
  isAnyJobMoveInProgress: boolean;
  crew: ICrew;
  date: string;
  jobInstanceShowingDetails: string | null;
  setJobInstanceShowingDetails: (newValue: string | null) => void;
  isDragPlaceholderJob: boolean;
  jobInstanceShowingContextMenu: string | null;
  setJobInstanceShowingContextMenu: (newValue: string | null) => void;
  hasJobMovedPosition: boolean;
}) {
  const { jobInstance } = jobInstanceWithTimeSlot;

  const jobCardRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const jobs = useApplicationStateSelector((s) => s.job.jobs);
  const oneTimeJobs = useApplicationStateSelector((s) => s.job.oneTimeJobs);
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const weeksUnscheduledMaintenanceJobs = useApplicationStateSelector(
    (s) => s.schedule.weeksUnscheduledMaintenanceJobs
  );
  const selectedJobInstanceIds = useApplicationStateSelector(
    (s) => s.scheduleUi.selectedJobInstanceIds
  );

  const selected = selectedJobInstanceIds.some((i) => i === jobInstance.id);

  const { resizing, resizingDelta, startResizing } = useHandleResizing();

  const pixelOffsetFromTimeBlockStart = getJobInstanceOffsetFromStartingBlock(
    timeSlotStartTime,
    jobInstanceWithTimeSlot,
    calendarBlockHeightInPxs
  );

  const { jobCardTimeBlocks, adjustedHeight } = calculateJobCardHeight({
    jobInstanceWithTimeSlot,
    calendarBlockHeightInPxs,
    resizingDelta,
    resizing,
    pixelOffsetFromTimeBlockStart,
    blocksFromCalendarEnd,
  });

  useHandleResizeComplete({
    resizing,
    resizingDelta,
    jobCardTimeBlocks,
    calendarBlockHeightInPxs,
    jobInstanceWithTimeSlot,
    adjustedHeight,
    onDragEnd: onResizeEnd,
    pixelOffsetFromTimeBlockStart,
    blocksFromCalendarEnd,
  });

  const getJobName = useGetJobNameFunction({
    fallbackToAddressIfAdditionalLocationNameNotSet: true,
  });
  const jobWithJobName = getJobName(jobInstance);
  const timeRangeForDisplay = getTimeRangeForDisplay(jobInstance);

  const foundJob = jobFinder.getJobForDayScheduleV3(
    jobInstanceWithTimeSlot.jobInstance.id,
    jobs,
    oneTimeJobs,
    daySchedules,
    weeksUnscheduledMaintenanceJobs
  );

  const showDetails =
    jobInstanceShowingDetails === jobInstanceWithTimeSlot.jobInstance.id &&
    !isDragPlaceholderJob;

  const showContextMenu =
    jobInstanceShowingContextMenu === jobInstanceWithTimeSlot.jobInstance.id &&
    !isDragPlaceholderJob;

  const toggleShowDetails = () => {
    setJobInstanceShowingDetails(
      showDetails ? null : jobInstanceWithTimeSlot.jobInstance.id
    );
  };
  const { classes: cardClasses, useWhiteText } = getCardColors({
    selected,
    jobInstance,
  });

  const percentWidth = getJobCardPercentWidth(jobInstanceWithTimeSlot);
  return (
    <>
      {/* spacers divs push out the job card to prevent an earlier job card from overlapping */}
      <SpacerDivs
        jobInstanceWithTimeSlot={jobInstanceWithTimeSlot}
        resizing={resizing}
        jobIndex={jobIndex}
        percentWidth={percentWidth}
      />

      <div
        className={`${cardClasses} border border-dark rounded board-card-v2 time-job-card ${
          jobInstanceWithTimeSlot.isDragPlaceholderJob ||
          isAnyJobMoveInProgress ||
          showDetails
            ? ""
            : "draggable-card"
        }`}
        style={{
          padding: ".1rem .25rem .1rem .25rem",
          height: `${adjustedHeight}px`,
          // Need position to be relative so it can exceed parent container
          position: "relative",
          // If job is missing time, resizing or is a drag placeholder, it should take the full width
          width:
            !missingTime &&
            !resizing &&
            !jobInstanceWithTimeSlot.isDragPlaceholderJob
              ? `${percentWidth}%`
              : "100%",
          // Ensure the job's being dragged are always visible
          zIndex:
            resizing || jobInstanceWithTimeSlot.isDragPlaceholderJob
              ? 2
              : undefined,
          marginTop:
            pixelOffsetFromTimeBlockStart !== null
              ? `${pixelOffsetFromTimeBlockStart}px`
              : undefined,
          opacity:
            isDragging && !jobInstanceWithTimeSlot.isDragPlaceholderJob
              ? 0.6
              : undefined,
          // Don't allow selecting text since throw's off the dragging behavior
          userSelect: "none",
        }}
        data-testid="jobCard"
        title={`${jobWithJobName.jobName}${
          timeRangeForDisplay ? " " + timeRangeForDisplay : ""
        }`}
        ref={jobCardRef}
      >
        {showDetails && foundJob ? (
          <Details
            jobInstance={jobInstance}
            crew={crew}
            setShowDetails={toggleShowDetails}
            foundJob={foundJob}
            nameForJob={jobWithJobName.jobName}
            date={date}
            jobCardHeight={adjustedHeight}
            parentRef={jobCardRef}
          />
        ) : null}

        <div
          data-testid="jobCardChild"
          className="d-flex flex-column"
          style={{ height: "100%", overflowY: "hidden" }}
          onMouseDown={(e) => {
            if (
              !jobInstanceWithTimeSlot.extendsToNextDay &&
              !jobInstanceWithTimeSlot.extendedFromPreviousDay
            ) {
              const offsetY = getOffsetFromElement(e);
              const blockOffset = Math.floor(
                offsetY / calendarBlockHeightInPxs
              );
              onStartMove(jobInstanceWithTimeSlot.jobInstance.id, blockOffset);

              setJobInstanceShowingContextMenu(null);
            }
          }}
          onClick={(e) => {
            preventClearingAllCards(e);

            if (
              !hasJobMovedPosition &&
              !resizing &&
              jobInstanceShowingContextMenu === null
            ) {
              dispatch(
                actionCreators.jobInstanceToggleSelected(
                  [jobInstance.id],
                  undefined,
                  true
                )
              );
            }

            // Have to manually close the job instance details here since
            // event propoagation is stopped so the window's event handler
            // won't close it
            if (showDetails) {
              setJobInstanceShowingDetails(null);
            }

            setJobInstanceShowingContextMenu(null);
          }}
        >
          <div
            className={`${missingTime ? "d-flex" : ""}`}
            style={{ width: "100%" }}
          >
            <div style={{ width: "100%", minWidth: 0, overflowY: "hidden" }}>
              <div
                className="card-text"
                style={{
                  overflowX: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <button
                  className={`btn btn-link m-0 p-0 ${preventDetailsTogglingClassName}`}
                  style={{
                    verticalAlign: "baseline",
                  }}
                  type="button"
                  data-testid="customerNameButton"
                  onClick={(e) => {
                    // Prevent selecting job card
                    e.stopPropagation();

                    toggleShowDetails();
                  }}
                  onMouseDown={(e) => {
                    // Prevent trigger a job card move
                    e.stopPropagation();
                  }}
                >
                  <div
                    className={`card-text font-weight-bold ${
                      useWhiteText ? "text-white" : "text-dark"
                    }`}
                    style={{ textDecoration: "underline" }}
                  >
                    {jobWithJobName.jobName}
                  </div>
                </button>

                {!jobInstance.startTime || !jobInstance.endTime ? null : (
                  <DisplayTimes
                    timeRangeForDisplay={timeRangeForDisplay}
                    startTime={jobInstance.startTime}
                    showOnNewLine={
                      adjustedHeight >= calendarBlockHeightInPxs * 2
                    }
                  />
                )}
              </div>
            </div>

            {missingTime && foundJob ? (
              <FixMissingTime
                jobInstanceWithTimeSlot={jobInstanceWithTimeSlot}
                foundJob={foundJob}
              />
            ) : null}
          </div>

          {foundJob ? (
            <div
              className="card-text"
              style={{
                overflow: "hidden",
                flexGrow: 1,
              }}
            >
              <DetailsInline
                foundJob={foundJob}
                jobInstance={jobInstance}
                useWhiteText={useWhiteText}
                crew={crew}
              />
            </div>
          ) : null}

          {foundJob !== null && !showDetails ? (
            <div
              onMouseDown={(e) => {
                // Stop mouse down so it doesn't trigger a job card move
                e.stopPropagation();
              }}
            >
              <ContextMenu
                jobInstance={jobInstance}
                nameForJob={jobWithJobName.jobName}
                showDetailsLink
                hideSkip={false}
                jobType={foundJob?.type}
                hideUpdateCompletionInformation={false}
                crew={crew}
                setShowDetails={() => {
                  toggleShowDetails();
                  setJobInstanceShowingContextMenu(null);
                }}
                smallContextTrigger={jobCardTimeBlocks < 2}
                isContextMenuOpen={showContextMenu}
                setIsContextMenuOpen={(v) => {
                  if (v) {
                    setJobInstanceShowingContextMenu(jobInstance.id);
                  } else if (jobInstanceShowingContextMenu === jobInstance.id) {
                    setJobInstanceShowingContextMenu(null);
                  }
                }}
                respectAllContextMenuButtonsInGlobalHandler
              />
            </div>
          ) : null}
        </div>

        {!missingTime &&
        !jobInstanceWithTimeSlot.extendsToNextDay &&
        !jobInstanceWithTimeSlot.isDragPlaceholderJob &&
        !isAnyJobMoveInProgress ? (
          <ResizeGrabHandle
            onMouseDown={(pageY) => {
              startResizing(pageY);
              onResizeStart();
            }}
          />
        ) : null}
      </div>
    </>
  );
}

function DetailsInline({
  foundJob,
  jobInstance,
  useWhiteText,
  crew,
}: {
  foundJob: IFoundJob;
  jobInstance: IJobInstance;
  useWhiteText: boolean;
  crew: ICrew;
}) {
  const [isIndicatorVisible, setIsIndicatorVisible] = useState(false);

  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );

  const address = useMemo(() => {
    if (foundJob) {
      return addressFormatter.getAddressForJob(
        foundJob,
        customers,
        customerAdditionalLocations
      );
    } else {
      return {} as IGetAddressForJobResult;
    }
  }, [foundJob, customers, customerAdditionalLocations]);

  const trimmedNotesFromCrew = (jobInstance.notesFromCrew || "").trim();
  const notesFromCrewSet =
    !!trimmedNotesFromCrew ||
    (jobInstance.photosFromCrew && jobInstance.photosFromCrew.length > 0);

  const missingLatOrLng = !address.latitude || !address.longitude;
  const previousJobNotComplete =
    typeof jobInstance.previousJobCompleted === "boolean" &&
    !jobInstance.previousJobCompleted &&
    !!jobInstance.jobId;

  let categories: Array<ICategoryIdentifier> = [];
  if (foundJob.categories) {
    categories = foundJob.categories;
  }

  return (
    <>
      <JobCardPhoneNumbers job={foundJob} useWhiteText={useWhiteText} />

      <JobCardEmailAddresses job={foundJob} useWhiteText={useWhiteText} />

      <JobCardNotes
        jobInstance={jobInstance}
        job={foundJob}
        jobType={foundJob.type}
        spacingClass="my-1"
        useWhiteText={useWhiteText}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <JobInstanceCategoriesContainer
            visible={categories.length > 0 || isIndicatorVisible}
          >
            <Indicators
              isIndicatorVisible={isIndicatorVisible}
              onVisibleChange={(v) => {
                setIsIndicatorVisible(v);
              }}
              jobInstance={jobInstance}
              jobType={foundJob.type}
              notesFromCrewSet={notesFromCrewSet}
              missingLatOrLng={missingLatOrLng}
              previousJobNotComplete={previousJobNotComplete}
              trimmedNotesFromCrew={trimmedNotesFromCrew}
              customerId={address.customerId}
              customerAdditionalLocationId={
                address.customerAdditionalLocationId
              }
              crew={crew}
            />

            <JobInstanceCategories
              jobInstanceId={jobInstance.id}
              categories={categories}
            />
          </JobInstanceCategoriesContainer>
        </div>
        {jobInstance.projectId ? (
          <div data-testid="jobCardJobCount">{`(${jobInstance.projectJobOrder}/${jobInstance.projectJobCount})`}</div>
        ) : null}
      </div>
    </>
  );
}

function Details({
  jobInstance,
  foundJob,
  crew,
  setShowDetails,
  nameForJob,
  date,
  jobCardHeight,
  parentRef,
}: {
  jobInstance: IJobInstance;
  foundJob: IFoundJob;
  crew: ICrew;
  setShowDetails: (newValue: boolean) => void;
  nameForJob: string;
  date: string;
  jobCardHeight: number;
  parentRef: React.RefObject<HTMLDivElement>;
}) {
  const detailsWidth = 200;
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    function onClick(e: MouseEvent) {
      if (e.target && typeof (e.target as any).closest === "function") {
        if ((e.target as any).closest(`.${preventDetailsTogglingClassName}`)) {
          return;
        }
      }

      setShowDetails(false);
    }

    window.addEventListener("click", onClick);

    return function cleanup() {
      window.removeEventListener("click", onClick);
    };
  }, [setShowDetails]);

  const [detailsPosition, setDetailsPosition] = useState<
    "left" | "center" | "right"
  >("right");
  useEffect(() => {
    if (parentRef.current) {
      const boundingRect = parentRef.current.getBoundingClientRect();

      const willOverflowOnLeft = boundingRect.left - detailsWidth < 0;
      const willOverflowOnRight =
        boundingRect.right + detailsWidth > window.innerWidth;
      if (willOverflowOnLeft && willOverflowOnRight) {
        setDetailsPosition("center");

        // Use setTimeout so the content is re-rendered with the details in the center below job card
        // before scrolling into view
        setTimeout(() => {
          if (ref.current) {
            ref.current.scrollIntoView(false);
          }
        });
      } else if (willOverflowOnLeft) {
        setDetailsPosition("right");
      } else if (willOverflowOnRight) {
        setDetailsPosition("left");
      }
    }
  }, [parentRef]);

  return (
    <>
      <div
        ref={ref}
        onClick={(e) => {
          // Stop propagation so details aren't closed by above window's click handler
          e.stopPropagation();
        }}
        className={`text-dark ${preventDetailsTogglingClassName}`}
        style={{
          position: "absolute",
          whiteSpace: "pre-wrap",
          top: detailsPosition === "center" ? `${jobCardHeight}px` : "-2px",
          left: detailsPosition === "left" ? `-${detailsWidth}px` : undefined,
          right: detailsPosition === "right" ? `-${detailsWidth}px` : undefined,
          width: `${detailsWidth}px`,
          zIndex: 8,
        }}
        data-testid="jobCardDetails"
      >
        <JobCard
          jobType={foundJob.type}
          crew={crew}
          date={date}
          distanceFromPreviousJobErrorLoading={false}
          distanceToCrewLocationErrorLoading={false}
          renderJobCardWithWrapper={({ element }) => <>{element}</>}
          job={foundJob}
          jobInstance={jobInstance}
          jobIndex={0}
          distanceFromPreviousJob={undefined}
          distanceToCrewLocation={undefined}
          lastJob={false}
          isMapVisible={false}
          hideContextMenu={true}
          hideJobIndex
          ignoreStatusForBackgroundColor
          upperRightContents={
            <div className="d-flex" style={{ gap: "10px" }}>
              <div style={{ position: "relative" }}>
                <ContextMenu
                  renderCustomTrigger={({
                    toggleContextMenuOpen,
                    skipContextMenuCloseClass,
                  }) => (
                    <LinkButton2
                      buttonContents={<FontAwesomeIcon icon={faEllipsisV} />}
                      onClick={() => {
                        toggleContextMenuOpen();
                      }}
                      className={`text-dark ${skipContextMenuCloseClass}`}
                      testId="detailsExpandButton"
                    />
                  )}
                  jobInstance={jobInstance}
                  nameForJob={nameForJob}
                  jobType={foundJob.type}
                  crew={crew}
                  isContextMenuOpen={isContextMenuOpen}
                  setIsContextMenuOpen={setIsContextMenuOpen}
                />
              </div>
              <div>
                <LinkButton2
                  buttonContents={<FontAwesomeIcon icon={faX} />}
                  onClick={() => setShowDetails(false)}
                  className="text-dark"
                  testId="closeDetails"
                />
              </div>
            </div>
          }
        />
      </div>
    </>
  );
}

function DisplayTimes({
  showOnNewLine,
  timeRangeForDisplay,
  startTime,
}: {
  showOnNewLine: boolean;
  timeRangeForDisplay: string;
  startTime: string;
}) {
  return (
    <>
      {showOnNewLine ? (
        <div className="text-wrap">{timeRangeForDisplay}</div>
      ) : (
        " " + formatTimeForCalendaryDisplay(startTime)
      )}
    </>
  );
}

function ResizeGrabHandle({
  onMouseDown,
}: {
  onMouseDown: (pageY: number) => void;
}) {
  return (
    <div
      style={{
        position: "absolute",
        userSelect: "none",
        width: "100%",
        height: "10px",
        bottom: "-5px",
        left: "0px",
        cursor: "row-resize",
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
        onMouseDown(e.pageY);
      }}
      onClick={(e) => {
        // Stop propagation to avoid job from being selected
        e.stopPropagation();
      }}
      data-testid="resizeGrabHandle"
    />
  );
}

function FixMissingTime({
  jobInstanceWithTimeSlot,
  foundJob,
}: {
  jobInstanceWithTimeSlot: JobInstanceWithTimeSlot;
  foundJob: IFoundJob;
}) {
  const [showPopover, setShowPopover] = useState(false);
  const [showJobInstanceTimeForm, setShowJobInstanceTimeForm] = useState(false);

  const id = `FixMissingTime_${jobInstanceWithTimeSlot.jobInstance.id}`;
  return (
    <>
      <div>
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="text-danger ml-1 mr-4"
          id={id}
          onClick={(e) => {
            e.stopPropagation();
            setShowPopover(!showPopover);
          }}
          style={{ cursor: "pointer" }}
          data-testid="warningIcon"
        />
        <Popover
          delay={0}
          trigger="legacy"
          placement="bottom"
          isOpen={showPopover}
          target={id}
          toggle={() => {
            setShowPopover(!showPopover);
          }}
        >
          <PopoverBody>
            <IndicatorsMissingTimeWarning
              job={foundJob}
              jobInstance={jobInstanceWithTimeSlot.jobInstance}
              jobType={foundJob?.type ?? null}
              onFixItClick={() => {
                setShowPopover(false);
              }}
              onShowJobInstanceTimeForm={() => {
                setShowPopover(false);
                setShowJobInstanceTimeForm(true);
              }}
            />
          </PopoverBody>
        </Popover>
      </div>

      {showJobInstanceTimeForm && foundJob && foundJob.type !== null ? (
        <JobInstanceTimeForm
          jobInstanceId={jobInstanceWithTimeSlot.jobInstance.id}
          jobType={foundJob.type}
          onSaveComplete={() => setShowJobInstanceTimeForm(false)}
          onCancel={() => setShowJobInstanceTimeForm(false)}
        />
      ) : null}
    </>
  );
}

function SpacerDivs({
  jobInstanceWithTimeSlot,
  resizing,
  jobIndex,
  percentWidth,
}: {
  jobInstanceWithTimeSlot: JobInstanceWithTimeSlot;
  resizing: boolean;
  jobIndex: number;
  percentWidth: string | number;
}) {
  let spacerDivs = <></>;
  if (typeof jobInstanceWithTimeSlot.slot === "number" && !resizing) {
    const spacersNeeded = jobInstanceWithTimeSlot.slot - jobIndex;
    if (spacersNeeded > 0) {
      const spacerRange = createRange(0, spacersNeeded, 1);
      spacerDivs = (
        <>
          {spacerRange.map((r) => (
            <div
              data-testid="jobCardSpacer"
              key={r}
              style={{ width: `${percentWidth}%` }}
            ></div>
          ))}
        </>
      );
    }
  }
  return spacerDivs;
}

type UseHandleResizingReducerActionState = {
  resizing: boolean;
  originalPosition: number | null;
  resizingDelta: number | null;
};
type UseHandleResizingReducerAction =
  | { type: "StartResizing"; currentPosition: number }
  | { type: "MoveMouse"; currentPosition: number }
  | { type: "EndResizing" };

function UseHandleResizingReducer(
  state: UseHandleResizingReducerActionState,
  action: UseHandleResizingReducerAction
): UseHandleResizingReducerActionState {
  switch (action.type) {
    case "StartResizing":
      return {
        ...state,
        resizing: true,
        originalPosition: action.currentPosition,
        resizingDelta: null,
      };
    case "MoveMouse":
      return state.resizing && typeof state.originalPosition === "number"
        ? {
            ...state,
            resizingDelta: action.currentPosition - state.originalPosition,
          }
        : state;
    case "EndResizing":
      return state.resizing
        ? {
            ...state,
            resizing: false,
          }
        : state;
    default:
      return {} as never;
  }
}

function useHandleResizing() {
  const [{ resizing, resizingDelta }, dispatch] = useReducer(
    UseHandleResizingReducer,
    {
      resizing: false,
      originalPosition: null,
      resizingDelta: null,
    }
  );

  useEffect(() => {
    function onMouseMove(e: MouseEvent) {
      dispatch({
        type: "MoveMouse",
        currentPosition: e.pageY,
      });
    }

    function onMouseUp() {
      dispatch({
        type: "EndResizing",
      });
    }

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);

    return function cleanup() {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  const startResizing = (originalPosition: number) => {
    dispatch({
      type: "StartResizing",
      currentPosition: originalPosition,
    });
  };

  return {
    resizing,
    resizingDelta,
    startResizing,
  };
}

function useHandleResizeComplete({
  resizing,
  resizingDelta,
  jobCardTimeBlocks,
  calendarBlockHeightInPxs,
  jobInstanceWithTimeSlot,
  adjustedHeight,
  onDragEnd,
  pixelOffsetFromTimeBlockStart,
  blocksFromCalendarEnd,
}: {
  resizing: boolean;
  resizingDelta: number | null;
  jobCardTimeBlocks: number;
  calendarBlockHeightInPxs: number;
  jobInstanceWithTimeSlot: JobInstanceWithTimeSlot;
  adjustedHeight: number;
  onDragEnd: () => void;
  pixelOffsetFromTimeBlockStart: number | null;
  blocksFromCalendarEnd: number | null;
}) {
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const dispatch = useDispatch();
  const previousResizing = useRef(resizing);
  useEffect(() => {
    const resizingComplete =
      !resizing &&
      previousResizing.current &&
      typeof resizingDelta === "number";
    if (resizingComplete) {
      onDragEnd();

      const newJobBlockSize = round(
        getJobCardHeight({
          jobCardTimeBlocks,
          calendarBlockHeightInPxs,
          resizingDelta,
          // Even though resizing is complete, pass it in so new value is used
          resizing: true,
          pixelOffsetFromTimeBlockStart: pixelOffsetFromTimeBlockStart,
          blocksFromCalendarEnd,
        }) / calendarBlockHeightInPxs,
        10
      );

      const originalDaySchedule = daySchedules.find((ds) =>
        ds.jobInstances.some(
          (ji) => ji.id === jobInstanceWithTimeSlot.jobInstance.id
        )
      );

      if (
        jobInstanceWithTimeSlot.startTime &&
        jobInstanceWithTimeSlot.jobInstance.startTime &&
        originalDaySchedule
      ) {
        // Intentionally using jobInstanceWithTimeSlot.startTime to calculate time difference
        // even though later time uses the actual job's start time
        const newEndTime = addMinutes(
          jobInstanceWithTimeSlot.startTime,
          newJobBlockSize * blockSizeInMinutes
        );

        // Need to use the raw start time rather than the slot's start time for midnight jobs
        const parsedStartTime = dateService.constructDateFromDateAndTime(
          parse(originalDaySchedule.date),
          jobInstanceWithTimeSlot.jobInstance.startTime
        );

        const { startTimeSerialized, endTimeSerialized } = getSerializedTimes(
          parsedStartTime,
          newEndTime
        );

        dispatch(
          actionCreators.jobInstanceTimeUpdated(
            jobInstanceWithTimeSlot.jobInstance.id,
            startTimeSerialized,
            endTimeSerialized,
            jobInstanceWithTimeSlot.jobInstance.arrivalWindowDurationMinutes
          )
        );

        remoteDataProvider
          .saveJobInstance(
            {
              startTime: startTimeSerialized,
              endTime: endTimeSerialized,
            },
            {
              jobInstanceId: jobInstanceWithTimeSlot.jobInstance.id,
            }
          )
          .subscribe({
            next: () => {},

            error: () => {
              dispatch({
                type: actionTypes.CALENDAR_JOB_RESIZE_ERROR,
              });
            },
          });
      }
    }

    previousResizing.current = resizing;
  }, [
    jobCardTimeBlocks,
    resizingDelta,
    resizing,
    calendarBlockHeightInPxs,
    dispatch,
    jobInstanceWithTimeSlot.jobInstance.id,
    jobInstanceWithTimeSlot.startTime,
    jobInstanceWithTimeSlot.jobInstance.startTime,
    adjustedHeight,
    daySchedules,
    onDragEnd,
    pixelOffsetFromTimeBlockStart,
    blocksFromCalendarEnd,
    jobInstanceWithTimeSlot.jobInstance.arrivalWindowDurationMinutes,
  ]);
}
