import gnormal from "../../../g-normal.png";

export function GoogleSignin({
  onClick,
  accountCreation,
}: {
  onClick(): void;
  accountCreation: boolean;
}) {
  const caption = accountCreation
    ? "Sign up with Google"
    : "Sign in with Google";
  return (
    <button
      className="border google-button"
      style={{ border: 0, width: "100%" }}
      onClick={onClick}
      data-testid="signInWithGoogle"
    >
      <img src={gnormal} alt={caption} />
      {caption}
    </button>
  );
}
