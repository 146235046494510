export const FS = (window as any).FS;

export function fullStoryLogError(error: unknown) {
  if (FS) {
    if (error instanceof Error) {
      let err: any = error;
      if (err.status === 400 || err.status === 404 || err.status === 401) {
        FS.log(
          "log",
          `Error: ${err.name} Message: ${err.message} Cause: ${err.cause} Stack: ${err.stack} Status: ${err.status} Response: ${err.response}`
        );
      } else {
        FS.log(
          "error",
          `Error: ${err.name} Message: ${err.message} Cause: ${err.cause} Stack: ${err.stack} Status: ${err.status} Response: ${err.response}`
        );
      }
    } else {
      let errorMessage;
      if (error && typeof error === "object") {
        errorMessage = safeStringify(error);
      } else if (error && typeof error === "function") {
        errorMessage = "<function>";
      } else {
        errorMessage = error;
      }

      FS.log("error", `Error: ${errorMessage}`);
    }
  }
}

function safeStringify(error: object): any {
  try {
    return JSON.stringify(error);
  } catch {
    return "<exception in safeStringify>";
  }
}

export function fullStoryLogInfo(message: string) {
  if (FS) {
    FS.log("log", message);
  }
}

export function fullStoryTrack(
  eventName: string,
  eventProperties?: { [property: string]: string }
) {
  if (FS) {
    FS.event(eventName, eventProperties);
  }
}
