import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FormContainerWithoutRedux from "../../../containers/app/components/FormContainerWithoutRedux";
import DayPicker, {
  format as weekPickerFormat,
} from "../../../containers/app/components/DayPicker";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import { InvoiceDeliveryMethod } from "../../../models/InvoiceDeliveryMethod";
import invoiceDataProvider from "../services/invoiceDataProvider";
import dateService from "../../../services/dateService";
import { format, parse } from "date-fns";
import {
  GetUserSettingsType,
  useUserSettings,
} from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import InvoicePaymentMethodSelection, {
  PaymentMethodOptions,
} from "./InvoicePaymentMethodSelection";
import { useDispatch } from "react-redux";
import { commonUiActionCreators } from "../../../modules/commonUi";
import { logError } from "../../../services/errorLogger";
import { actionCreators } from "../../../modules/actionCreators";
import InfoToolTip from "../../../containers/app/components/InfoToolTip";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { fullStoryTrack } from "../../../services/fullStoryService";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useGetDefaultTaxRate from "../../sales/hooks/useGetDefaultTaxRate";
import TaxRateField from "./TaxRateField";
import { IInvoiceConfiguration } from "../../../models/IInvoiceConfiguration";
import { InvoiceUpdateDefaultDueDatePrompt } from "./InvoiceUpdateDefaultDueDatePrompt";
import { getInvoiceDueDate } from "../../../services/invoiceService";
import {
  getNewDefaultNumberOfDaysDue,
  hasNumberOfDaysUntilDueChanged,
} from "./InvoiceUpdateDefaultDueDatePrompt.functions";
import { ErrorMessageType } from "../../../containers/app/components/FormContainer";

interface IFormData {
  taxRate: number | null;
  date: string;
  dueDate: string;
  paymentMethodOptions: PaymentMethodOptions;
  copyJobProposalSummary: boolean;
}

interface IProps {
  jobInstanceIds: Array<string>;
  taxExemptCustomerIncluded: boolean;
  onSaveComplete: () => void;
  onCancel: () => void;
}

const InvoiceBatchEmailForm: React.FunctionComponent<IProps> = ({
  jobInstanceIds,
  taxExemptCustomerIncluded,
  onSaveComplete,
  onCancel,
}) => {
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");

  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );
  const invoiceConfiguration = useApplicationStateSelector(
    (s) => s.common.invoiceConfiguration
  );
  const defaultTaxRate = useGetDefaultTaxRate(null);

  const { getUserSettings } = useUserSettings();

  const [originalFormValues] = useState(
    getDefaultValues({
      defaultTaxRate,
      invoiceConfiguration,
      getUserSettings,
    })
  );

  const { register, control, getValues, watch, setValue } = useForm<IFormData>({
    defaultValues: getDefaultValues({
      defaultTaxRate,
      invoiceConfiguration,
      getUserSettings,
    }),
  });

  const dispatch = useDispatch();

  const { setUserSettings } = useUserSettings();

  const date = watch("date");

  return (
    <FormContainerWithoutRedux
      formHeader="Batch Email"
      formType={FormTypesV2.batchEmailInvoiceForm}
      saveButtonText="Send emails"
      customTimeout={60_000}
      size="lg"
      customDefaultErrorMessage="An error occurred while saving. Any invoices already created will not be created again so please try again."
      save={() => {
        fullStoryTrack("Batch Email Saved");

        setUserSettings(
          UserSettingsType.allowCreditCardPayment,
          getValues("paymentMethodOptions.allowCreditCardPayment")
            ? "true"
            : "false"
        );
        setUserSettings(
          UserSettingsType.allowAchPayment,
          getValues("paymentMethodOptions.allowAchPayment") ? "true" : "false"
        );
        setUserSettings(UserSettingsType.taxRate, getValues("taxRate"));

        return invoiceDataProvider.createBatchInvoices({
          jobInstanceIds,
          date: getValues("date"),
          dueDate: getValues("dueDate") ? getValues("dueDate") : null,
          deliveryMethod: InvoiceDeliveryMethod.emailWithCrewControl,
          taxRate: getValues("taxRate"),
          addConvenienceFee: getValues(
            "paymentMethodOptions.addConvenienceFee"
          ),
          allowOnlineCreditCardPayment: getValues(
            "paymentMethodOptions.allowCreditCardPayment"
          ),
          allowOnlineAchPayment: getValues(
            "paymentMethodOptions.allowAchPayment"
          ),
          copyJobProposalSummary: getValues("copyJobProposalSummary"),
        });
      }}
      onSaveComplete={(response) => {
        if (response.invoiceCreationFailureCount > 0) {
          logError("failed to create 1 or more invoices in batch email form");
          dispatch(
            commonUiActionCreators.showAlertModal({
              alertErrorMessage:
                response.invoiceCreationFailureCount === 1
                  ? `Unfortunately, ${response.invoiceCreationFailureCount} invoice was not able to be created`
                  : `Unfortunately, ${response.invoiceCreationFailureCount} invoices were not able to be created`,
            })
          );
        }

        dispatch(
          actionCreators.invoiceBatchEmailCompleted(response.jobInstanceUpdates)
        );

        onSaveComplete();
      }}
      onCancel={onCancel}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      {jobInstanceIds.length >= 50 ? (
        <div
          className="p-2 alert alert-info"
          data-testid="largeNumberOfJobsAlert"
        >
          <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
          <small>
            You are invoicing a large amount of jobs. This form may save slower
            than normally so please be patient.
          </small>
        </div>
      ) : null}
      <div className="form-row">
        <div className="form-group col">
          <label htmlFor="date" className="required">
            Invoice date
          </label>
          <Controller
            name={`date`}
            control={control}
            render={({ field }) => (
              <DayPicker
                inputId="date"
                onDayPickerHide={() => null}
                dayPickerProps={{}}
                overlayFixed={true}
                value={
                  !!field.value ? format(field.value, weekPickerFormat) : ""
                }
                required={true}
                onDaySelected={(day: Date) => {
                  field.onChange(day ? dateService.formatAsIso(day) : "");

                  if (day) {
                    const newDueDate = getInvoiceDueDate(
                      day,
                      invoiceConfiguration
                    );

                    if (newDueDate) {
                      setValue("dueDate", dateService.formatAsIso(newDueDate));
                    }
                  }
                }}
              />
            )}
          />
        </div>
        <div className="form-group col">
          <label htmlFor="dueDate">Due date</label>
          <Controller
            name={`dueDate`}
            control={control}
            render={({ field }) => (
              <>
                <DayPicker
                  inputId="dueDate"
                  onDayPickerHide={() => null}
                  dayPickerProps={{}}
                  overlayFixed={true}
                  minDate={parse(date)}
                  value={
                    !!field.value ? format(field.value, weekPickerFormat) : ""
                  }
                  required={true}
                  onDaySelected={(day: Date) => {
                    field.onChange(day ? dateService.formatAsIso(day) : "");
                  }}
                />
                <InvoiceUpdateDefaultDueDatePrompt
                  hasNumberOfDaysUntilDueChanged={hasNumberOfDaysUntilDueChanged(
                    {
                      currentDueDate: field.value,
                      originalDueDate: originalFormValues.dueDate,
                    }
                  )}
                  newDefaultNumberOfDaysDue={getNewDefaultNumberOfDaysDue({
                    currentDueDate: field.value,
                    startingDate: date,
                  })}
                />
              </>
            )}
          />
        </div>
      </div>

      <>
        <div>
          <label htmlFor="taxRate">Tax rate</label>
          <Controller
            name={`taxRate`}
            control={control}
            render={({ field }) => (
              <>
                <TaxRateField
                  taxRate={field.value}
                  onTaxRateChange={(newValue) => field.onChange(newValue)}
                  taxRateDisabled={false}
                />
              </>
            )}
          />
        </div>
        {taxExemptCustomerIncluded ? (
          <div
            className="alert alert-warning mb-2"
            style={{ padding: "5px", paddingLeft: "10px" }}
            data-testid="taxExemptWarning"
          >
            <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
            <small>
              At least one of the jobs belong to a customer that is tax-exempt.
              Tax rate entered here will not apply to that customer's invoice.
            </small>
          </div>
        ) : null}
      </>

      <div className="form-group">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="copyJobProposalSummary"
            {...register("copyJobProposalSummary")}
          />
          <label
            className="custom-control-label"
            htmlFor="copyJobProposalSummary"
          >
            Copy job proposal summary
          </label>
        </div>
      </div>

      <Controller
        name={`paymentMethodOptions`}
        control={control}
        render={({ field }) => (
          <InvoicePaymentMethodSelection
            isCrewControlDeliveryWithPayments={true}
            value={field.value}
            onChange={(newPaymentValues) => {
              field.onChange(newPaymentValues);
            }}
          />
        )}
      />

      {isQuickBooksEnabled ? (
        <div className="form-group">
          <small>
            <span className="mr-1">
              <InfoToolTip
                id="QuickbooksBatchWarning"
                text="Invoices sent with will allow payment through Crew Control's payment
                processor."
              ></InfoToolTip>
            </span>
            Invoices sent will allow payment through Crew Control's payment
            processor.
          </small>
        </div>
      ) : null}
    </FormContainerWithoutRedux>
  );
};

export default InvoiceBatchEmailForm;

function getDefaultValues({
  defaultTaxRate,
  invoiceConfiguration,
  getUserSettings,
}: {
  defaultTaxRate: number | null;
  invoiceConfiguration: IInvoiceConfiguration;
  getUserSettings: GetUserSettingsType;
}) {
  const allowCreditCardPayment =
    getUserSettings<boolean>(UserSettingsType.allowCreditCardPayment) ?? true;
  const defaultDate = dateService.getCurrentDate();

  let dueDate = "";
  const calculatedDueDate = getInvoiceDueDate(
    defaultDate,
    invoiceConfiguration
  );
  if (calculatedDueDate !== null) {
    dueDate = dateService.formatAsIso(calculatedDueDate);
  }

  return {
    taxRate: defaultTaxRate,
    date: dateService.formatAsIso(defaultDate),
    dueDate,
    copyJobProposalSummary: true,
    paymentMethodOptions: {
      addConvenienceFee:
        allowCreditCardPayment && getDefaultConvenienceFee(getUserSettings),
      allowAchPayment:
        getUserSettings<boolean>(UserSettingsType.allowAchPayment) ?? true,
      allowCreditCardPayment: allowCreditCardPayment,
    },
  };
}

function getDefaultConvenienceFee(getUserSettings: GetUserSettingsType) {
  const savedSetting = getUserSettings<boolean>(
    UserSettingsType.paymentsConvenienceFeePercentEnabled
  );

  if (typeof savedSetting === "boolean") {
    return savedSetting;
  } else {
    return true;
  }
}
