import { useState } from "react";
import { useDispatch } from "react-redux";
import { timeout } from "rxjs/operators";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { actionCreators } from "../../../modules/actionCreators";
import { commonUiActionCreators } from "../../../modules/commonUi";
import { fullStoryTrack } from "../../../services/fullStoryService";
import remoteDataProvider from "../../../services/remoteDataProvider";
import Prompt from "./Prompt";
import Spinner from "./Spinner";

function CustomerClearJobsPromptInternal({
  customerId,
  customerName,
}: {
  customerId: string;
  customerName: string;
}) {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [errorSaving, setErrorSaving] = useState(false);

  return (
    <>
      {saving ? (
        <Spinner />
      ) : (
        <Prompt
          promptMessage={`${customerName} marked inactive`}
          promptSubMessage={
            !errorSaving
              ? "Would you also like to remove all future jobs for this customer?"
              : "An error occurred while removing jobs. Would you like to try again?"
          }
          promptSubMessageClassName={errorSaving ? "text-danger" : ""}
          promptSaveText="Yes, remove future visits"
          promptCancelText="No, just mark inactive"
          showPrompt={true}
          onConfirm={() => {
            fullStoryTrack("Inactive Customer Remove Jobs Confirmed");

            setErrorSaving(false);
            setSaving(true);
            remoteDataProvider
              .clearCustomerJobs(customerId)
              .pipe(timeout(30000))
              .subscribe({
                next: (response) => {
                  remoteDataProvider
                    .getMaintenanceJobs(response.updatedMaintenanceJobIds)
                    .subscribe({
                      next: (getJobsResponse) => {
                        dispatch(
                          actionCreators.loadMaintenanceJobsComplete(
                            getJobsResponse.maintenanceJobs,
                            getJobsResponse.customers,
                            getJobsResponse.customerAdditionalLocations
                          )
                        );
                      },
                    });

                  setSaving(false);

                  dispatch(
                    actionCreators.customerClearJobsComplete({
                      customerId,
                      ...response,
                    })
                  );

                  dispatch(
                    commonUiActionCreators.dismissCustomerClearJobsModal()
                  );
                },

                error() {
                  setSaving(false);
                  setErrorSaving(true);
                },
              });
          }}
          onCancel={() => {
            fullStoryTrack("Inactive Customer Clear Jobs Dismissed");
            dispatch(commonUiActionCreators.dismissCustomerClearJobsModal());
          }}
        />
      )}
    </>
  );
}

export function CustomerClearJobsPrompt() {
  const parametersForCustomerClearJobsModal = useApplicationStateSelector(
    (s) => s.commonUi.parametersForCustomerClearJobsModal
  );

  return parametersForCustomerClearJobsModal !== null ? (
    <CustomerClearJobsPromptInternal
      customerId={parametersForCustomerClearJobsModal.customerId}
      customerName={parametersForCustomerClearJobsModal.customerName}
    />
  ) : null;
}
