import { useState } from "react";
import LinkButton2 from "../../../containers/app/components/LinkButton2";
import { TollFreeVerificationForm } from "./TollFreeVerificationForm";

export function TollFreeVerificationPrompt({
  footerContents,
}: {
  footerContents?: React.ReactNode;
}) {
  const [showForm, setShowForm] = useState(false);

  return (
    <>
      <div
        data-testid="tollFreeVerificationAlert"
        className={"alert alert-info"}
      >
        <div>
          <strong>
            To avoid disruptions sending customer text messages, ensure you
            apply for texting by providing your information{" "}
            <LinkButton2
              style={{ textDecoration: "underline" }}
              className="text-dark font-weight-bold"
              buttonContents={"here"}
              testId="hereButton"
              onClick={() => {
                setShowForm(true);
              }}
              inlineButton
            />{" "}
            by August 31st 2024.
          </strong>
        </div>
        <div>
          Due to phone carrier regulations to reduce spam, text message
          providers are requiring information about businesses sending text
          messages.
        </div>

        {showForm ? (
          <TollFreeVerificationForm
            onSaveComplete={() => setShowForm(false)}
            onCancel={() => setShowForm(false)}
          />
        ) : null}

        {footerContents}
      </div>
    </>
  );
}
