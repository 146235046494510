import React from "react";
import { ICustomer } from "../../../../models/ICustomer";
import { useDispatch } from "react-redux";
import LabelledField from "../../components/LabelledField";
import { actionCreators } from "../../../../modules/actionCreators";
import remoteDataProvider from "../../../../services/remoteDataProvider";
import { getPaymentMethodType } from "../../../../services/customerService";
import CustomerBillingSection from "./CustomerBillingSection";

interface IProps {
  customer: ICustomer;
  visible: boolean;
}

const CustomerPaymentMethod: React.FunctionComponent<IProps> = ({
  customer,
  visible,
}) => {
  const dispatch = useDispatch();

  return (
    <CustomerBillingSection
      dataType="payment method"
      deleteFunction={() =>
        remoteDataProvider.deleteCustomerPaymentMethod(customer.id)
      }
      header="Payment Method"
      isDataSet={customer.paymentMethod?.isTokenSet ?? false}
      onDeleteComplete={() =>
        dispatch(actionCreators.customerPaymentMethodClear(customer.id))
      }
      onEdit={() =>
        dispatch(
          actionCreators.forms.customerPaymentMethod.showForm({
            customerId: customer.id,
          })
        )
      }
      visible={visible}
      fields={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <LabelledField
              label="Payment type"
              testId="paymentType"
              value={getPaymentMethodType(customer)}
            />
            <LabelledField
              label="Partial number"
              testId="partialNumber"
              value={customer.paymentMethod?.partialNumber ?? "Not set"}
            />
          </div>
        </div>
      }
    />
  );
};

export default CustomerPaymentMethod;
