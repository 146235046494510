import Alert from "./Alert";
import Spinner from "./Spinner";

export function ModalDataLoaderStateless({
  errorMessage,
  onErrorAlertClose,
  children,
  errorLoadingData,
  loadingData,
}: {
  errorMessage: string;
  onErrorAlertClose(): void;
  children: React.ReactElement;
  loadingData: boolean;
  errorLoadingData: boolean;
}) {
  if (errorLoadingData) {
    return <Alert message={errorMessage} closeForm={onErrorAlertClose} />;
  } else if (loadingData) {
    return <Spinner />;
  } else {
    return children;
  }
}
