import { getSortedItemsV2 } from "../../../../services/sortingService";
import addressFormatter, {
  IGetAddressForJobResult,
} from "../../../../services/addressFormatter";
import { getCategoryIdentifiersForDisplay } from "../../../../services/crewCategoryService";
import { IUnscheduleJobsToShow } from "../../../../models/IUnscheduleJobsToShow";
import { ICrewCategory } from "../../../../models/ICrewCategory";
import { Sort, SortColumns } from "./UnscheduledJobs.models";
import { SortDirection } from "../../../../enums/sortDirection";
import { ICustomerAdditionalLocation } from "../../../../models/ICustomerAdditionalLocation";
import { ICustomer } from "../../../../models/ICustomer";

export function getSortedJobs({
  filteredUnscheduledJobs,
  sort,
  customers,
  customerAdditionalLocations,
  categories,
}: {
  filteredUnscheduledJobs: IUnscheduleJobsToShow[];
  sort: Sort;
  customers: Array<ICustomer>;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
  categories: Array<ICrewCategory>;
}): IUnscheduleJobsToShow[] {
  type JobWithSortableFields = IUnscheduleJobsToShow & {
    customerName: string;
    customerPhoneNumber: string;
    categoriesForDisplay: string;
    address: IGetAddressForJobResult & { number: string; street: string };
  };

  let sortFields: Array<
    | ((t: JobWithSortableFields) => string | number)
    | keyof JobWithSortableFields
  > = [];

  const jobsWithSortableFields = filteredUnscheduledJobs.map((j) => {
    const customer = customers.find((c) => c.id === j.job.customerId);
    const address = addressFormatter.getAddressForJob(
      j.job,
      customers,
      customerAdditionalLocations
    );

    const { number: addressNumber, street: addressStreet } =
      addressFormatter.parseStreetAndNumber(address.streetAndNumber);

    const result: JobWithSortableFields = {
      ...j,
      customerName: customer?.name ?? "",
      customerPhoneNumber: customer?.phoneNumber ?? "",
      categoriesForDisplay: getCategoryIdentifiersForDisplay(
        j.job.categories,
        categories
      ),
      address: {
        ...address,
        number: addressNumber,
        street: addressStreet,
      },
    };

    return result;
  });

  const sortAddressNumber = (job: JobWithSortableFields) => {
    const parsedNumber = parseInt(job.address.number);
    if (isNaN(parsedNumber)) {
      return job.address.number;
    } else {
      return parsedNumber;
    }
  };

  switch (sort.column) {
    case SortColumns.CustomerName:
      sortFields = ["customerName"];
      break;
    case SortColumns.PhoneNumber:
      sortFields = ["customerPhoneNumber"];
      break;
    case SortColumns.ServicesProvided:
      sortFields = ["categoriesForDisplay"];
      break;
    case SortColumns.StreetNumber:
      sortFields = [sortAddressNumber];
      break;
    case SortColumns.StreetName:
      sortFields = [(j) => j.address.street, sortAddressNumber];
      break;
    case SortColumns.City:
      sortFields = [
        (j) => j.address.city,
        (j) => j.address.zip,
        (j) => j.address.street,
        sortAddressNumber,
      ];
      break;
    case SortColumns.State:
      sortFields = [
        (j) => j.address.state,
        (j) => j.address.city,
        (j) => j.address.zip,
        (j) => j.address.street,
        sortAddressNumber,
      ];
      break;
    case SortColumns.Zip:
      sortFields = [
        (j) => j.address.zip,
        (j) => j.address.street,
        sortAddressNumber,
      ];
      break;
    default:
      // Used to ensure all enum options accounted for
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const exhaustiveCheck: never = sort.column;
  }

  return getSortedItemsV2(
    jobsWithSortableFields,
    sortFields,
    sort.direction === SortDirection.Descending
  );
}
