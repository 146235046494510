import PercentField from "../../../../containers/app/components/PercentField";
import { useCreditCardsEnabled } from "../../../../hooks/useCreditCardsEnabled";
import InfoToolTip from "../../../../containers/app/components/InfoToolTip";
import { IDepositSettings } from "../../../../models/IDepositSettings";
import { DepositType } from "../../../../enums/DepositType";
import AmountField from "../../../../containers/app/components/AmountField";
import { isDepositRequired } from "../../services/proposalService";

type IProps = {
  idPrefix: string;
  depositSettings: IDepositSettings | null;
  setDepositSettings: (value: IDepositSettings | null) => void;
};

const DepositRequiredFields: React.FunctionComponent<IProps> = ({
  idPrefix,
  depositSettings,
  setDepositSettings,
}) => {
  const { areCreditCardsEnabled } = useCreditCardsEnabled();

  return (
    <>
      {areCreditCardsEnabled ? (
        <>
          <div className="form-group form-row align-items-baseline justify-content-end">
            <div
              className="col-xl-3 col-lg-4 col-md-6 text-md-right mb-3 mb-md-0"
              style={{ minWidth: "210px" }}
            >
              <select
                className="form-control"
                aria-label="Deposit type"
                data-testid="depositType"
                value={
                  depositSettings?.type?.toString() ??
                  DepositType.percent.toString()
                }
                onChange={(e) => {
                  let type = parseInt(e.currentTarget.value) as DepositType;

                  if (
                    type === DepositType.percent ||
                    type === DepositType.amount
                  ) {
                    setDepositSettings({
                      ...depositSettings,
                      type,
                    } as IDepositSettings);
                  } else {
                    setDepositSettings(null);
                  }
                }}
              >
                <option value={DepositType.percent.toString()}>
                  Required deposit percent
                </option>
                <option value={DepositType.amount.toString()}>
                  Required deposit amount
                </option>
              </select>
            </div>
            <div className={`col-md-3`}>
              {depositSettings?.type === DepositType.amount ? (
                <AmountField
                  id="depositSettingsAmount"
                  ariaLabel="Deposit settings amount"
                  max={null}
                  onChange={(newValue) => {
                    setDepositSettings({
                      ...depositSettings,
                      type: DepositType.amount,
                      amount: (newValue ?? 0) === 0 ? null : newValue,
                    } as IDepositSettings);
                  }}
                  value={depositSettings?.amount ?? null}
                />
              ) : (
                <PercentField
                  id="depositSettingsPercent"
                  ariaLabel="Deposit settings percent"
                  onChange={(newValue) =>
                    setDepositSettings({
                      ...depositSettings,
                      type: DepositType.percent,
                      percent: (newValue ?? 0) === 0 ? null : newValue,
                    } as IDepositSettings)
                  }
                  decimalPlacesAllowed={0}
                  value={depositSettings?.percent ?? null}
                />
              )}
            </div>
          </div>
          {isDepositRequired(depositSettings) ? (
            <div className="form-group form-row align-items-baseline justify-content-end">
              <div className="order-sm-2 order-lg-1 col-12 col-md-9 col-lg-7 col-xl-6">
                <small>
                  <span className="mr-1">
                    <InfoToolTip
                      id={`${idPrefix}_DepositWarning`}
                      text="When requiring a deposit, customers will be prompted to pay their deposit through Crew
                        Control's Credit Card or ACH payment methods."
                    ></InfoToolTip>
                  </span>
                  Customers will be prompted to pay through Crew Control's
                  credit card or ACH methods.
                </small>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default DepositRequiredFields;
