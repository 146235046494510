import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { TenantPlan } from "../../../enums/tenantPlan";
import { formatCurrencyWithoutDecimalDigits } from "../../../services/currencyFormatter";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap/lib";
import LinkButton2 from "../../../containers/app/components/LinkButton2";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { SubscriptionFrequency } from "../../../enums/subscriptionFrequency";
import { getSubscriptionPrice } from "../services/crewControlSubscriptionService";
import { SubscriptionPriceBreakdown } from "./SubscriptionPriceBreakdown";

interface IProps {
  tenantPlan: TenantPlan;
  onTenantPlanChange: (newTenantPlan: TenantPlan) => void;
  subscriptionFrequency: SubscriptionFrequency;
  onSubscriptionFrequencyChange: (
    newSubscriptionFrequency: SubscriptionFrequency
  ) => void;
  numberOfCrews: number;
  disableSelection: boolean;
}

const SubscriptionFormPlanSelection: React.FunctionComponent<IProps> = ({
  tenantPlan,
  numberOfCrews,
  onTenantPlanChange,
  disableSelection,
  subscriptionFrequency,
  onSubscriptionFrequencyChange,
}) => {
  const pricingUpdates =
    useApplicationStateSelector((s) => s.common.featureFlags?.pricingUpdates) ??
    false;

  const subscriptionType = useApplicationStateSelector(
    (s) => s.common.subscriptionType
  );

  const costBreakdownMonthly = getSubscriptionPrice({
    tenantPlan: TenantPlan.basic,
    subscriptionType: subscriptionType,
    subscriptionFrequency: SubscriptionFrequency.month,
    useUpdatedPricing: pricingUpdates,
    numberOfCrews,
  });

  const costBreakdownAnnually = getSubscriptionPrice({
    tenantPlan: TenantPlan.basic,
    subscriptionType: subscriptionType,
    subscriptionFrequency: SubscriptionFrequency.annual,
    useUpdatedPricing: pricingUpdates,
    numberOfCrews,
  });

  const annualPricingSavings =
    costBreakdownMonthly.totalCostPerMonth * 12 -
    costBreakdownAnnually.totalCostPerMonth * 12;

  return (
    <>
      {pricingUpdates ? (
        <>
          <div
            className="d-flex justify-content-center"
            style={{ gap: "10px" }}
          >
            <FrequencySelection
              buttonText="Annual"
              onClick={() =>
                onSubscriptionFrequencyChange(SubscriptionFrequency.annual)
              }
            />

            <div className="plan-custom-control plan-custom-switch">
              <input
                type="checkbox"
                className="plan-custom-control-input"
                id="annualPricing"
                checked={subscriptionFrequency === SubscriptionFrequency.annual}
                onChange={(e) =>
                  onSubscriptionFrequencyChange(
                    e.currentTarget.checked
                      ? SubscriptionFrequency.annual
                      : SubscriptionFrequency.month
                  )
                }
              />
              <label
                className="plan-custom-control-label"
                htmlFor="annualPricing"
              >
                <span className="sr-only">Toggle annual pricing</span>
              </label>
            </div>

            <FrequencySelection
              buttonText="Monthly"
              onClick={() =>
                onSubscriptionFrequencyChange(SubscriptionFrequency.month)
              }
            />
          </div>
          <div
            className="mb-2 font-weight-light text-center"
            data-testid="annualSavings"
          >
            Save at least{" "}
            {formatCurrencyWithoutDecimalDigits(annualPricingSavings)} with
            annual pricing.
          </div>
        </>
      ) : null}
      <div className="d-flex flex-nowrap justify-content-between">
        <Plan
          planToShow={TenantPlan.plus}
          currentPlan={tenantPlan}
          numberOfCrews={numberOfCrews}
          onTenantPlanChange={onTenantPlanChange}
          planName="Plus"
          showWhatsIncludedLink={true}
          disableSelection={disableSelection}
          subscriptionFrequency={subscriptionFrequency}
        />
        <div className="border-right mx-3"></div>
        <Plan
          planToShow={TenantPlan.basic}
          currentPlan={tenantPlan}
          numberOfCrews={numberOfCrews}
          onTenantPlanChange={onTenantPlanChange}
          planName="Base"
          showWhatsIncludedLink={false}
          disableSelection={disableSelection}
          subscriptionFrequency={subscriptionFrequency}
        />
      </div>
    </>
  );
};

export default SubscriptionFormPlanSelection;

function FrequencySelection({
  onClick,
  buttonText,
}: {
  onClick: () => void;
  buttonText: string;
}) {
  return (
    <LinkButton2
      buttonContents={buttonText}
      onClick={onClick}
      className="text-dark"
      style={{ fontSize: "1.5rem" }}
    />
  );
}

function Plan({
  planToShow,
  numberOfCrews,
  currentPlan,
  onTenantPlanChange,
  planName,
  showWhatsIncludedLink,
  disableSelection,
  subscriptionFrequency,
}: {
  planToShow: TenantPlan;
  numberOfCrews: number;
  currentPlan: TenantPlan;
  onTenantPlanChange: (newTenantPlan: TenantPlan) => void;
  planName: string;
  showWhatsIncludedLink: boolean;
  disableSelection: boolean;
  subscriptionFrequency: SubscriptionFrequency;
}) {
  const [showWhatsIncludedModal, setShowWhatsIncludedModal] = useState(false);
  const pricingUpdates = useApplicationStateSelector(
    (s) => s.common.featureFlags?.pricingUpdates ?? false
  );
  const subscriptionType = useApplicationStateSelector(
    (s) => s.common.subscriptionType
  );

  const costBreakdown = getSubscriptionPrice({
    tenantPlan: planToShow,
    subscriptionType: subscriptionType,
    subscriptionFrequency: subscriptionFrequency,
    useUpdatedPricing: pricingUpdates,
    numberOfCrews,
  });
  const isCurrentPlan = planToShow === currentPlan;

  let totalCostAmount: number;
  let totalCostPeriod: string;
  if (subscriptionFrequency === SubscriptionFrequency.annual) {
    totalCostAmount = costBreakdown.totalCostPerMonth * 12;
    totalCostPeriod = "year";
  } else {
    totalCostAmount = costBreakdown.totalCostPerMonth;
    totalCostPeriod = "month";
  }

  return (
    <>
      <div className={`flex-grow-1 card`} data-testid="planContainer">
        <div
          style={{ fontSize: "1.5rem" }}
          className={`d-flex justify-space-between px-1 pl-2 ${
            planToShow === TenantPlan.plus ? "alert-primary" : "alert-info"
          }`}
        >
          {planName}
        </div>

        <div className="p-2 px-1">
          <SubscriptionPriceBreakdown
            numberOfCrews={numberOfCrews}
            subscriptionFrequency={subscriptionFrequency}
            tooltipElementId={`planbreakdown_${planToShow}`}
            subscriptionPriceResult={costBreakdown}
          />
          <div data-testid="amountText">
            <span className="font-weight-bold">
              {formatCurrencyWithoutDecimalDigits(totalCostAmount)}
            </span>{" "}
            per {totalCostPeriod}
          </div>
          {!disableSelection ? (
            <div className="mt-2 text-center">
              {!isCurrentPlan ? (
                <button
                  className={"btn btn-sm btn-primary"}
                  onClick={() => onTenantPlanChange(planToShow)}
                >
                  Select Plan
                </button>
              ) : (
                <h5 className="mb-0 text-success">
                  <FontAwesomeIcon icon={faCheckCircle} /> Selected
                </h5>
              )}
            </div>
          ) : null}

          {showWhatsIncludedLink ? (
            <div className="mt-1 text-center">
              <LinkButton2
                buttonContents={"What's included?"}
                onClick={() => setShowWhatsIncludedModal(true)}
              />
            </div>
          ) : null}
        </div>

        {showWhatsIncludedModal ? (
          <WhatsIncluded
            onClose={() => {
              setShowWhatsIncludedModal(false);
            }}
          />
        ) : null}
      </div>
    </>
  );
}

function WhatsIncluded({ onClose }: { onClose: () => void }) {
  return (
    <Modal isOpen scrollable size="lg" toggle={onClose}>
      <ModalHeader toggle={onClose}>What's Included</ModalHeader>
      <ModalBody>
        <div className="card-deck">
          <PlanDetails
            name="Plus"
            showPlusOptions={true}
            useBlueHeader={true}
          />
          <PlanDetails
            name="Base"
            showPlusOptions={false}
            useBlueHeader={false}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-secondary" type="button" onClick={onClose}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
}

function PlanDetails({
  name,
  showPlusOptions,
  useBlueHeader,
}: {
  name: string;
  showPlusOptions: boolean;
  useBlueHeader: boolean;
}) {
  return (
    <div className="card">
      <div className={`card-header ${useBlueHeader ? "alert-primary" : ""}`}>
        {name}
      </div>
      <ul className="list-group list-group-flush">
        <OptionalItem text="Electronic proposals" showItem={showPlusOptions} />
        <OptionalItem text="On-site estimates" showItem={showPlusOptions} />
        <OptionalItem
          text="Customer text notifications"
          showItem={showPlusOptions}
        />
        <li className="list-group-item">Scheduling</li>
        <li className="list-group-item">Route optimization</li>
        <li className="list-group-item">Time entry & tracking</li>
        <li className="list-group-item">Invoicing</li>
        <li className="list-group-item">Electronic payments</li>
        <li className="list-group-item">Reporting</li>
        <li className="list-group-item">QuickBooks Online integration</li>
      </ul>
    </div>
  );
}

function OptionalItem({ text, showItem }: { text: string; showItem: boolean }) {
  return (
    <li
      className={`list-group-item ${
        showItem ? "font-weight-bold" : "d-none d-lg-block"
      }`}
    >
      {showItem ? text : "—"}
    </li>
  );
}
