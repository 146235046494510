import { IJob, JobType } from "../models/IJob";
import { IJobInstance } from "../models/IJobInstance";
import jobHelper from "../services/jobHelper";
import { round } from "../services/roundingService";
import { isProjectBilling as isProjectCompletionBilling } from "../slices/schedule/services/projectService";
import { useApplicationStateSelector } from "./useApplicationStateSelector";

export function useGetJobGrossAmountAndEstimatedTime() {
  const projects = useApplicationStateSelector((s) => s.project.projects);

  const getJobGrossAmountAndEstimatedTime = (
    date: string | undefined,
    jobType: JobType,
    job: IJob,
    jobInstance: IJobInstance
  ): {
    grossRevenuePerVisit: number | null;
    estimatedManHours: number | null;
  } => {
    let grossRevenuePerVisit = job.grossRevenuePerVisit;
    let originalEstimatedManHours = jobHelper.getEstimatedManHours(
      date,
      jobType,
      job
    );

    let estimatedManHours: number | null;
    if (typeof originalEstimatedManHours === "string") {
      if (originalEstimatedManHours !== "") {
        estimatedManHours = parseFloat(originalEstimatedManHours);
      } else {
        estimatedManHours = 0;
      }
    } else {
      estimatedManHours = originalEstimatedManHours;
    }

    if (
      typeof jobInstance.projectId === "string" &&
      typeof jobInstance.projectJobCount === "number"
    ) {
      const project =
        projects.find((p) => p.id === jobInstance.projectId) ?? null;
      if (project) {
        if (isProjectCompletionBilling(project)) {
          if (
            typeof project.grossRevenue === "number" &&
            jobInstance.projectJobCount !== 0
          ) {
            grossRevenuePerVisit = round(
              project.grossRevenue / jobInstance.projectJobCount,
              2
            );
          } else {
            grossRevenuePerVisit = 0;
          }

          if (
            typeof project.estimatedManHours === "number" &&
            jobInstance.projectJobCount !== 0
          ) {
            estimatedManHours = round(
              project.estimatedManHours / jobInstance.projectJobCount,
              2
            );
          } else {
            estimatedManHours = 0;
          }
        }
      }
    }

    return { grossRevenuePerVisit, estimatedManHours };
  };

  return getJobGrossAmountAndEstimatedTime;
}
