import React, { useState } from "react";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";

import { IJobInstance } from "../../../../../models/IJobInstance";
import { useApplicationStateSelector } from "../../../../../hooks/useApplicationStateSelector";
import LinkButton from "../../LinkButton";
import { JobType } from "../../../../../models/IJob";
import dateService from "../../../../../services/dateService";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../../../modules/actionCreators";
import Lightbox from "../../Lightbox";
import {
  isImageByType,
  isVideoByType,
} from "../../../../../services/fileService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faEnvelope,
  faExclamationCircle,
  faRepeat,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import CardImages from "../../CardImages";
import CardNonImageFiles from "../../CardNonImageFiles";
import { useFindJob } from "../../../../../hooks/useFindJob";
import { ICrew } from "../../../../../models/ICrew";
import { CrewScheduleType } from "../../../../../slices/schedule/enums/crewScheduleType";
import { isStringSet } from "../../../../../services/stringService";
import LinkButton2 from "../../LinkButton2";
import { JobInstanceTimeForm } from "../../../../../slices/schedule/components/JobInstanceTimeForm";
import { IndicatorsMissingTimeWarning } from "./IndicatorsMissingTimeWarning";

interface IProps {
  notesFromCrewSet: boolean;
  previousJobNotComplete: boolean;
  missingLatOrLng: boolean;
  jobInstance: IJobInstance;
  trimmedNotesFromCrew: string;
  jobType: JobType;
  onVisibleChange(newVisible: boolean): void;
  customerId: string | null;
  customerAdditionalLocationId: string | null;
  isIndicatorVisible: boolean;
  crew: ICrew | null;
}

const Indicators: React.FunctionComponent<IProps> = ({
  notesFromCrewSet,
  jobInstance,
  trimmedNotesFromCrew,
  previousJobNotComplete,
  missingLatOrLng,
  jobType,
  onVisibleChange,
  customerId,
  customerAdditionalLocationId,
  isIndicatorVisible,
  crew,
}) => {
  const dispatch = useDispatch();
  const [
    showPreviousJobNotCompletedDetails,
    setShowPreviousJobNotCompletedDetails,
  ] = useState(false);
  const [showCrewNotesDetails, setShowCrewNotesDetails] = useState(false);
  const [showCustomerNotificationDetails, setShowCustomerNotificationDetails] =
    useState(false);
  const [showRecurringJobNotice, setShowRecurringJobNotice] = useState(false);
  const [showPhotos, setShowPhotos] = useState(false);
  const [showJobInstanceTimeForm, setShowJobInstanceTimeForm] = useState(false);

  const jobInstancesSkipSaving = useApplicationStateSelector(
    (s) => s.scheduleUi.jobInstancesSkipSaving
  );
  const jobInstancesCompleteSaving = useApplicationStateSelector(
    (s) => s.scheduleUi.jobInstancesCompletedSaving
  );
  const jobInstancesMoving = useApplicationStateSelector(
    (s) => s.scheduleUi.jobInstancesMoving
  );
  const showAlertForSkippedAndUncompletedJobs = useApplicationStateSelector(
    (s) => s.common.adminViewConfiguration.showAlertForSkippedAndUncompletedJobs
  );
  const imagePrefix = useApplicationStateSelector((s) => s.common.imagePrefix);
  const [photoIndex, setPhotoIndex] = useState(0);

  const saving =
    jobInstancesSkipSaving.some((i) => i === jobInstance.id) ||
    jobInstancesCompleteSaving.some((i) => i === jobInstance.id) ||
    jobInstancesMoving.some((i) => i === jobInstance.id);

  const job = useFindJob(jobInstance);

  const jobPhotos = job?.photos ?? [];
  const jobInstancePhotos = jobInstance.photos ?? [];
  const photos = [
    ...jobPhotos,
    // Need to include job instance photos since Job Card Notes for Maintenance Jobs
    // adds job instance photos. Need to filter out photos already on jobs for One Time Jobs
    // since they have the same set of items.
    ...jobInstancePhotos.filter(
      (jobInstancePhoto) =>
        !jobPhotos.some((jobPhoto) => jobPhoto.id === jobInstancePhoto.id)
    ),
  ];

  const missingTime =
    crew?.scheduleType === CrewScheduleType.time &&
    (!isStringSet(jobInstance.startTime) || !isStringSet(jobInstance.endTime));

  const isRowVisible =
    notesFromCrewSet ||
    previousJobNotComplete ||
    missingLatOrLng ||
    jobType === JobType.maintenanceJob ||
    jobInstance.customerNotifications.length > 0 ||
    saving ||
    photos.length > 0 ||
    missingTime;

  const showPreviousJobNotCompleteAlert =
    previousJobNotComplete && showAlertForSkippedAndUncompletedJobs;

  if (isIndicatorVisible !== isRowVisible) {
    onVisibleChange(isRowVisible);
  }

  const photosToShow = jobInstance.photosFromCrew;
  const showExclamation =
    showPreviousJobNotCompleteAlert || missingLatOrLng || missingTime;

  return isRowVisible ? (
    <React.Fragment>
      <div
        style={{
          display: !notesFromCrewSet ? "none" : undefined,
        }}
      >
        <FontAwesomeIcon
          icon={faComment}
          className="text-danger board-card-indicators-icon"
          id={`notefromcrew_${jobInstance.id}`}
          size="lg"
          onClick={(e) => {
            e.stopPropagation();
            setShowCrewNotesDetails(!showCrewNotesDetails);
          }}
          style={{
            cursor: "pointer",
          }}
        />
        <Popover
          delay={0}
          trigger="legacy"
          placement="bottom"
          isOpen={showCrewNotesDetails && notesFromCrewSet}
          target={`notefromcrew_${jobInstance.id}`}
          toggle={() => {
            setShowCrewNotesDetails(!showCrewNotesDetails);
          }}
        >
          <PopoverBody>
            {trimmedNotesFromCrew ? (
              <div>Crew notes: {trimmedNotesFromCrew}</div>
            ) : null}
            {photosToShow && photosToShow.length > 0 ? (
              <>
                <div>
                  {photosToShow.some((p) => isImageByType(p.contentType)) ? (
                    <LinkButton
                      hrefForDisplay="photos"
                      linkText="Crew photos"
                      onClick={() => {
                        setShowPhotos(true);
                        setShowCrewNotesDetails(false);
                      }}
                    />
                  ) : null}
                </div>
                {photosToShow
                  .filter((p) => isVideoByType(p.contentType))
                  .map((p, videoIndex) => {
                    return (
                      <div key={p.id}>
                        <a
                          href={`${imagePrefix}/${p.imagePath}`}
                          onClick={(e) => {
                            // Stop propagation so card isn't selected
                            e.stopPropagation();
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Video {videoIndex + 1}
                        </a>
                      </div>
                    );
                  })}
              </>
            ) : null}
          </PopoverBody>
        </Popover>
      </div>

      <div style={{ display: !showExclamation ? "none" : undefined }}>
        <FontAwesomeIcon
          data-testid="job-card-exclamation"
          icon={faExclamationCircle}
          size="lg"
          className={
            "board-card-indicators-icon" +
            (!jobInstance.previousJobSkipped ? " text-danger" : "")
          }
          id={`previousjobnotcompleted_${jobInstance.id}`}
          onClick={(e) => {
            e.stopPropagation();
            setShowPreviousJobNotCompletedDetails(
              !showPreviousJobNotCompletedDetails
            );
          }}
          style={{ cursor: "pointer" }}
        />
        <Popover
          delay={0}
          trigger="legacy"
          placement="bottom"
          isOpen={showPreviousJobNotCompletedDetails && showExclamation}
          target={`previousjobnotcompleted_${jobInstance.id}`}
          toggle={() => {
            setShowPreviousJobNotCompletedDetails(
              !showPreviousJobNotCompletedDetails
            );
          }}
        >
          <PopoverBody>
            <ul className="pl-3 mb-0">
              {showPreviousJobNotCompleteAlert ? (
                <li>
                  {jobInstance.previousJobSkipped
                    ? "Previous job skipped"
                    : "Previous job not completed"}
                </li>
              ) : null}

              {missingLatOrLng ? (
                <li>
                  <div>
                    Missing latitude or longitude{" "}
                    {customerId ? (
                      <LinkButton2
                        buttonContents={"Fix it!"}
                        inlineButton
                        onClick={() => {
                          setShowPreviousJobNotCompletedDetails(false);

                          let action;
                          if (customerAdditionalLocationId) {
                            action =
                              actionCreators.forms.customerAdditionalLocation.showForm(
                                {
                                  customerAdditionalLocationId,
                                }
                              );
                          } else {
                            action = actionCreators.forms.customer.showForm({
                              customerId,
                            });
                          }
                          dispatch(action);
                        }}
                      ></LinkButton2>
                    ) : null}
                  </div>
                </li>
              ) : null}

              {missingTime ? (
                <li>
                  <IndicatorsMissingTimeWarning
                    job={job}
                    jobInstance={jobInstance}
                    jobType={jobType}
                    onFixItClick={() => {
                      setShowPreviousJobNotCompletedDetails(false);
                    }}
                    onShowJobInstanceTimeForm={() => {
                      setShowJobInstanceTimeForm(true);
                    }}
                  />
                </li>
              ) : null}
            </ul>
          </PopoverBody>
        </Popover>
      </div>

      {jobInstance.customerNotifications.length > 0 ? (
        <div>
          <FontAwesomeIcon
            icon={faEnvelope}
            size="lg"
            className="board-card-indicators-icon"
            id={`customerNotification_${jobInstance.id}`}
            onClick={(e) => {
              e.stopPropagation();
              setShowCustomerNotificationDetails(
                !showCustomerNotificationDetails
              );
            }}
            style={{ cursor: "pointer" }}
          />
          <Popover
            delay={0}
            trigger="legacy"
            placement="bottom"
            isOpen={showCustomerNotificationDetails}
            target={`customerNotification_${jobInstance.id}`}
            toggle={() => {
              setShowCustomerNotificationDetails(
                !showCustomerNotificationDetails
              );
            }}
          >
            <PopoverBody>
              {jobInstance.customerNotifications.map((notification, index) => (
                <div key={index} style={{ marginBottom: "10px" }}>
                  {notification.dateSent ? (
                    <div>
                      Sent:{" "}
                      {dateService.formatDateForDisplay(notification.dateSent)}
                    </div>
                  ) : null}
                  <div>{notification.text}</div>
                </div>
              ))}
            </PopoverBody>
          </Popover>
        </div>
      ) : null}

      {jobType === JobType.maintenanceJob ? (
        <div>
          <FontAwesomeIcon
            icon={faRepeat}
            size="lg"
            className="board-card-indicators-icon"
            id={`repeat_${jobInstance.id}`}
            onClick={(e) => {
              e.stopPropagation();
              setShowRecurringJobNotice(!showRecurringJobNotice);
            }}
            style={{ cursor: "pointer" }}
          />
          <Popover
            delay={0}
            fade={false}
            trigger="legacy"
            placement="bottom"
            isOpen={showRecurringJobNotice}
            target={`repeat_${jobInstance.id}`}
            toggle={() => {
              setShowRecurringJobNotice(!showRecurringJobNotice);
            }}
          >
            <PopoverBody>Recurring job</PopoverBody>
          </Popover>
        </div>
      ) : null}

      <CardImages
        imageFiles={photos.filter((p) => isImageByType(p.contentType))}
      />

      <CardNonImageFiles
        nonImageFiles={photos.filter((p) => !isImageByType(p.contentType))}
        id={jobInstance.id}
      />

      {saving ? (
        <div className="saving">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            fixedWidth={true}
            title="Saving..."
          />
        </div>
      ) : null}

      {showPhotos && (
        <Lightbox
          items={photosToShow}
          onClose={() => setShowPhotos(false)}
          itemIndex={photoIndex}
          setItemIndex={(newIndex) => setPhotoIndex(newIndex)}
        />
      )}

      {showJobInstanceTimeForm ? (
        <JobInstanceTimeForm
          jobInstanceId={jobInstance.id}
          jobType={jobType}
          onSaveComplete={() => setShowJobInstanceTimeForm(false)}
          onCancel={() => setShowJobInstanceTimeForm(false)}
        />
      ) : null}
    </React.Fragment>
  ) : null;
};

export default Indicators;
