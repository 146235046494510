export default function BillingHeaderField({
  label,
  value,
  valueTestId,
}: {
  label: string;
  value: string;
  valueTestId?: string;
}) {
  return (
    <div style={{ fontSize: "1.25rem" }}>
      <div className="font-weight-light mr-2">{label}:</div>
      <div data-testid={valueTestId}>{value}</div>
    </div>
  );
}
