import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { Flags, isFeatureFlagSet } from "../../../services/featureFlagService";

export default function useSalesEnabled() {
  const optionalCapabilitiesAllowed = useApplicationStateSelector(
    (s) => s.common.optionalCapabilitiesAllowed
  );
  return (
    optionalCapabilitiesAllowed?.sales || isFeatureFlagSet(Flags.estimates)
  );
}
