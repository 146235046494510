import React from "react";
import { connect } from "react-redux";
import FormContainer from "../components/FormContainer";
import { actionCreators } from "../../../modules/actionCreators";
import { IRootState } from "../../../store";
import { ICrew } from "../../../models/ICrew";
import { IDaySchedule } from "../../../models/IDaySchedule";
import dateService from "../../../services/dateService";
import { IDayToLoad } from "../../../services/dayScheduleLoader";
import { IOneTimeJob } from "../../../models/IOneTimeJob";
import { format as dayPickerFormat } from "../components/DayPicker";
import dateFnsFormat from "date-fns/format";
import jobFinder from "../../../services/jobFinder";
import { RouterState } from "connected-react-router";
import constants from "../../../constants";
import TextareaAutosize from "react-autosize-textarea";
import { IJob } from "../../../models/IJob";
import { ITodoItem } from "../components/ManageTodoItems";
import uuidv4 from "uuid/v4";
import Files from "../components/files/Index";
import { IFormDataFile } from "../components/files/ExistingFile";
import { isPhoto, mapPhotoToDataFile } from "../../../services/fileService";
import JobTodoItems from "../components/JobTodoItems";
import Spinner from "../components/Spinner";
import customerFinder from "../../../services/customerFinder";
import { ICustomer } from "../../../models/ICustomer";
import { getSortedCrews } from "../../../services/sortingService";
import CustomerSelection, {
  CustomerSelectionRecordType,
} from "../components/CustomerSelection";
import CustomerAdditionalLocationSelection from "../components/CustomerAdditionalLocationSelection";
import { ICustomerAdditionalLocation } from "../../../models/ICustomerAdditionalLocation";
import {
  getDefaultCrewId,
  getGrossRevenuePerVisitForForm,
} from "../../../services/jobService";
import { parsers } from "../../../services/routing";
import CrewCategorySelection from "../components/CrewCategorySelection";
import { ICategoryIdentifierForSave } from "../../../models/ICategoryIdentifierForSave";
import { ICrewCategory } from "../../../models/ICrewCategory";
import { getCategories } from "../../../services/crewCategoryService";
import CustomerAdditionalLocationInfoToolTip from "../components/CustomerAdditionalLocationInfoToolTip";
import CustomerBalanceWarning from "../components/CustomerBalanceWarning";
import JobBillingConfiguration from "../components/JobBillingConfiguration";
import { UserAccountRole } from "../../../enums/userAccountRole";
import { isAdmin } from "../../../hooks/useIsAdmin";
import { JobBillingType } from "../../../enums/jobBillingType";
import { ILineItem } from "../components/InvoiceLineItem";
import { IDiscount } from "../../../models/IDiscount";
import { DiscountType } from "../../../enums/DiscountType";
import { getLineItemsForForm } from "../../../services/lineItemService";
import { IProject } from "../../../slices/schedule/models/IProject";
import { isProjectBillingJobInstance } from "../../../slices/schedule/services/projectService";
import OneTimeJobFormProjectBillingNotice from "./OneTimeJobFormProjectBillingNotice";
import { areDiscountAndTaxRateEnabled } from "../components/JobBillingConfiguration.functions";
import { ManHours } from "../components/ManHours";
import { OneTimeJobFormDateTimeFields } from "./OneTimeJobFormDateTimeFields";
import { CrewScheduleType } from "../../../slices/schedule/enums/crewScheduleType";
import { UserSettingsType } from "../../../enums/userSettingsType";
import { isTaxRateSet } from "./InvoiceFormAmounts.functions";
import LinkButton2 from "../components/LinkButton2";
import IPhoto from "../../../models/IPhoto";
import { FlexibleJobToolTip } from "../components/FlexibleJobToolTip";
import { OneTimeJobOrderingDropDown } from "../../../slices/schedule/components/OneTimeJobOrderingDropDown";
import { userSettingsActionCreators } from "../../../modules/userSettings";
import { IUserSetting } from "../../../models/IUserSetting";
import { getUserSettingsFromStore } from "../../../services/userSettingsService";
import { JobInstructionsForCrewField } from "../components/JobInstructionsForCrewField";

function getDayScheduleForJobToMove(
  daySchedules: Array<IDaySchedule>,
  jobInstanceId: string
): IDaySchedule | null {
  let dayScheduleForJobToMove: IDaySchedule | null = null;
  daySchedules.forEach((daySchedule) => {
    daySchedule.jobInstances.forEach((jobInstance) => {
      if (jobInstance.id === jobInstanceId) {
        dayScheduleForJobToMove = daySchedule;
      }
    });
  });

  return dayScheduleForJobToMove;
}

interface IProps {
  crews: Array<ICrew>;
  oneTimeJobs: Array<IOneTimeJob>;
  oneTimeJobId: string | null;
  defaultFormData: Partial<IFormData>;
  showForm: boolean;
  errorMessage: string | React.ReactNode;
  saving: boolean;
  router: RouterState;
  daySchedules: Array<IDaySchedule>;
  startSave(payload: any): void;
  cancel(): void;
  setErrorMessage(message: string): void;
  loadDaySchedulesStarting(
    daysToLoad: Array<IDayToLoad>,
    weeksUnscheduledMaintenanceJobsToLoad: Array<Date>,
    maxUnassignedWeekAlreadyLoaded: string | null
  ): void;
  tenantId: string | null;
  imagePrefix: string;
  jobsLoading: Array<string>;
  loadOneTimeJobsStart: (ids: Array<string>) => any;
  customers: Array<ICustomer>;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
  showCustomersForm(payload: any): void;
  clearErrorMessage(): void;
  anyJobExists: boolean;
  headerOverride: string | null;
  isQuickBooksEnabled: boolean;
  crewCategories: Array<ICrewCategory>;
  userAccountRole: UserAccountRole;
  projects: Array<IProject>;
  fieldToFocus: string | null;
  showCrewCategoriesForm(payload: any): void;
  proposalFiles?: Array<IPhoto>;
  proposalJobSummary?: string;
  setUserSetting: (payload: { type: UserSettingsType; value: unknown }) => void;
  userSettings: Array<IUserSetting>;
}

interface IState {
  formData: IFormData;
  originalFormData: IFormData;
  hasPendingPhotoAdd: boolean;
  loadingJob: boolean;
  taxRateAlreadySet: boolean;
  hideAddAdditionalFiles: boolean;
  missingDaySchedule: boolean;
}

export interface IFormData {
  id?: string;
  customerAdditionalLocationId: string | null;
  crewId: string;
  date: string;
  customerRecordType: CustomerSelectionRecordType | null;
  customerId: string | null;
  estimatedManHours: string;
  highlightCrewNotes: boolean;
  showCrewNotesOnAdminJobCards: boolean;
  notes: string;
  precedingJobInstanceId: string | null;
  originalPrecedingJobInstanceId?: string | null;
  flexibleJob: boolean;
  todoItems: Array<ITodoItem>;
  photos: Array<IFormDataFile>;
  todoTemplateId: string;
  todoItemsLocked: boolean;
  administratorOnlyNotes: string;
  grossRevenuePerVisit: string;
  categories: Array<ICategoryIdentifierForSave>;
  billingType: JobBillingType;
  lineItems: Array<ILineItem>;
  opportunityId: string | null;
  taxRate: number | null;
  discount: IDiscount;
  paymentMethodOnFileAuthorized: boolean;
  hideLineItemPrices: boolean;
  startTime: string;
  endTime: string;
  arrivalWindowDurationMinutes: number | null;
  notifyCustomer: boolean;
  projectId: string | null;
}

const startTimeInputId = "oneTimeJobFormStartTime";

class OneTimeJobForm extends React.Component<IProps, IState> {
  private customerElement: React.RefObject<any>;

  constructor(props: IProps) {
    super(props);

    const formData = this.getEmptyFormData();
    this.state = {
      formData,
      originalFormData: formData,
      hasPendingPhotoAdd: false,
      loadingJob: false,
      taxRateAlreadySet: false,
      hideAddAdditionalFiles: false,
      missingDaySchedule: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.getFormData = this.getFormData.bind(this);

    this.customerElement = React.createRef();
  }

  // Used to populate precedingJobInstanceId if the day schedule wasn't initially loaded
  static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
    if (prevState && prevState.missingDaySchedule) {
      const { daySchedules } = nextProps;

      const daySchedule = daySchedules.find(
        (ds) =>
          ds.crewId === prevState.formData.crewId &&
          dateService.areDatesEqual(ds.date, prevState.formData.date) &&
          !ds.initialLoadRunning
      );

      if (daySchedule) {
        const jobInstances = daySchedule.jobInstances;
        const jobInstance = daySchedule.jobInstances.find(
          (ji) => ji.id === nextProps.oneTimeJobId
        );

        let precedingJobInstanceId: string = "";
        let originalPrecedingJobInstanceId =
          prevState.formData.originalPrecedingJobInstanceId;
        let originalJob: IOneTimeJob | null = null;
        if (nextProps.oneTimeJobId) {
          originalJob = jobFinder.getOneTimeJobById(
            nextProps.oneTimeJobs,
            nextProps.oneTimeJobId
          ) as IOneTimeJob;
        }
        if (
          originalJob &&
          jobInstance &&
          !prevState.formData.precedingJobInstanceId &&
          dateService.areDatesEqual(prevState.formData.date, originalJob.date)
        ) {
          precedingJobInstanceId = jobInstances.reduce(
            (acc, ji) => {
              if (ji.order > acc.order && ji.order < jobInstance.order) {
                return {
                  order: ji.order,
                  id: ji.id,
                };
              } else {
                return acc;
              }
            },
            { order: -1, id: constants.idForFirstJob }
          ).id;
          originalPrecedingJobInstanceId = precedingJobInstanceId;
        }

        if (!precedingJobInstanceId) {
          precedingJobInstanceId = constants.idForFirstJob;
        }

        return {
          missingDaySchedule: false,
          formData: {
            ...prevState.formData,
            precedingJobInstanceId,
            originalPrecedingJobInstanceId,
          },
        };
      }
    }

    return null;
  }

  getEmptyFormData(): IFormData {
    let date = "";
    const dayParseResult = parsers.schedule.tryParseDaySequence(
      this.props.router,
      this.props.crews
    );
    if (dayParseResult.isMatch && dayParseResult.dateInRoute) {
      date = dateFnsFormat(dayParseResult.dateInRoute, dayPickerFormat);
    }

    const jobArrivalWindowDurationMinutes = parseInt(
      getUserSettingsFromStore<string>(
        this.props.userSettings,
        UserSettingsType.jobArrivalWindowDurationMinutes
      ) ?? ""
    );
    return {
      customerRecordType: null,
      customerId: null,
      customerAdditionalLocationId: null,
      date,
      crewId: "",
      estimatedManHours: "",
      precedingJobInstanceId: constants.idForFirstJob,
      highlightCrewNotes: false,
      showCrewNotesOnAdminJobCards: false,
      notes: "",
      flexibleJob: false,
      todoItems: [],
      photos: [],
      todoTemplateId: "",
      todoItemsLocked: false,
      administratorOnlyNotes: "",
      grossRevenuePerVisit: "",
      billingType: JobBillingType.PerServiceTotal,
      categories: [],
      lineItems: [],
      opportunityId: null,
      taxRate: null,
      discount: { type: DiscountType.amount, amount: null, percent: null },
      paymentMethodOnFileAuthorized: false,
      hideLineItemPrices: false,
      startTime: "",
      endTime: "",
      arrivalWindowDurationMinutes: !isNaN(jobArrivalWindowDurationMinutes)
        ? jobArrivalWindowDurationMinutes
        : null,
      notifyCustomer: false,
      projectId: null,
    };
  }

  componentDidUpdate(prevProps: IProps) {
    const props = this.props;
    const { crews, router } = props;
    const sortedCrews = getSortedCrews(crews);

    if (
      props.showForm &&
      (prevProps.showForm !== props.showForm ||
        (prevProps.oneTimeJobs !== props.oneTimeJobs &&
          props.oneTimeJobId &&
          !prevProps.oneTimeJobs.find((j) => j.id === props.oneTimeJobId) &&
          !!props.oneTimeJobs.find((j) => j.id === props.oneTimeJobId)))
    ) {
      let formData: IFormData;
      if (!props.oneTimeJobId) {
        formData = this.getEmptyFormData();

        if (crews.length === 0) {
          console.error("no crews were specified");
        }

        const crewId = getDefaultCrewId(sortedCrews, router);
        formData.crewId = crewId;

        if (props.defaultFormData) {
          formData = {
            ...formData,
            ...props.defaultFormData,
          };
        }
        this.setState({
          formData,
          originalFormData: formData,
          loadingJob: false,
          hideAddAdditionalFiles: false,
          missingDaySchedule: false,
        });

        setTimeout(() => {
          if (this.customerElement.current && !formData.customerId) {
            this.customerElement.current.focus();
          }
        });
      } else {
        const job = jobFinder.getOneTimeJobById(
          props.oneTimeJobs,
          props.oneTimeJobId
        ) as IOneTimeJob;

        if (job) {
          formData = buildFormDataFromJob(
            job,
            job.crewId || sortedCrews[0].id,
            job.date,
            job.flexibleJob,
            this.props.crewCategories,
            job.projectId
          );

          const dayScheduleForJobToMove = getDayScheduleForJobToMove(
            props.daySchedules,
            props.oneTimeJobId
          );

          let missingDaySchedule = true;
          if (dayScheduleForJobToMove) {
            const jobInstance = dayScheduleForJobToMove.jobInstances.find(
              (ji) => ji.id === props.oneTimeJobId
            );

            if (jobInstance) {
              missingDaySchedule = false;

              const jobInstances = dayScheduleForJobToMove.jobInstances;
              formData.precedingJobInstanceId = jobInstances.reduce(
                (acc, ji) => {
                  if (ji.order > acc.order && ji.order < jobInstance.order) {
                    return {
                      order: ji.order,
                      id: ji.id,
                    };
                  } else {
                    return acc;
                  }
                },
                { order: -1, id: constants.idForFirstJob }
              ).id;
              formData.todoItemsLocked = jobInstance.todoItemsLocked;
              formData.projectId = jobInstance.projectId;
            } else {
              formData.precedingJobInstanceId = null;
            }
          } else {
            formData.precedingJobInstanceId = null;
          }
          formData.originalPrecedingJobInstanceId =
            formData.precedingJobInstanceId;

          this.setState({
            formData,
            originalFormData: formData,
            loadingJob: false,
            taxRateAlreadySet: isTaxRateSet(job),
            hideAddAdditionalFiles: false,
            missingDaySchedule,
          });

          setTimeout(() => {
            if (this.props.fieldToFocus === "startTime") {
              const startTime = document.getElementById(startTimeInputId);
              if (startTime) {
                startTime.focus();
              }
            }
          });
        } else {
          if (
            !this.props.jobsLoading.find(
              (jobLoading) => jobLoading === props.oneTimeJobId
            )
          ) {
            this.props.loadOneTimeJobsStart([props.oneTimeJobId]);
          }
          this.setState({ loadingJob: true, hideAddAdditionalFiles: false });
        }
      }
    }
  }

  handleChange(
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) {
    const fieldName = event.target.name;
    this.handleChangeWithFieldName(
      fieldName as keyof IFormData,
      event.target.value
    );
  }

  handleChangeWithFieldName(fieldName: keyof IFormData, value: any) {
    const formDataToUpdate = { ...this.state.formData };
    (formDataToUpdate as any)[fieldName] = value;

    if (fieldName === "flexibleJob") {
      if (!value) {
        formDataToUpdate.crewId = getDefaultCrewId(
          this.props.crews,
          this.props.router
        );
      } else {
        formDataToUpdate.date = "";
      }
    }

    if (fieldName === "estimatedManHours") {
      this.props.clearErrorMessage();
    }

    this.setState({
      formData: formDataToUpdate,
    });
  }

  getFormData() {
    // TODO: Change to have type for saves.  Right now, any doesn't give any type protection
    const discountAndTaxRateEnabled = areDiscountAndTaxRateEnabled(
      this.state.formData.billingType
    );
    const emptyDiscount: IDiscount = {
      type: DiscountType.amount,
      amount: null,
      percent: null,
    };
    const formData: any = {
      customerAdditionalLocationId:
        this.state.formData.customerAdditionalLocationId,
      crewId: !this.state.formData.flexibleJob
        ? this.state.formData.crewId
        : null,
      estimatedManHours: this.state.formData.estimatedManHours || null,
      id: this.state.formData.id ? this.state.formData.id : "",
      highlightCrewNotes: this.state.formData.highlightCrewNotes,
      showCrewNotesOnAdminJobCards:
        this.state.formData.showCrewNotesOnAdminJobCards,
      notes: this.state.formData.notes,
      administratorOnlyNotes: this.state.formData.administratorOnlyNotes,
      order: 0,
      date: this.getDateAsIso(),
      precedingJobInstanceId: !this.state.formData.flexibleJob
        ? this.state.formData.precedingJobInstanceId || constants.idForFirstJob
        : null,
      flexibleJob: this.state.formData.flexibleJob,
      todoItems: this.state.formData.todoItems.filter((i) => i.text.trim()),
      photos: this.state.formData.photos.map((i) => ({
        ...i,
        imagePath: i.imagePath as string,
      })),
      todoTemplateId: this.state.formData.todoTemplateId || null,
      categories: this.state.formData.categories,
      billingType: this.state.formData.billingType,
      grossRevenuePerVisit: getGrossRevenuePerVisitForForm(
        this.state.formData.billingType,
        this.state.formData.grossRevenuePerVisit,
        this.state.formData.lineItems,
        this.state.formData.discount,
        this.state.formData.taxRate
      ),
      lineItems:
        this.state.formData.billingType === JobBillingType.PerServiceLineItems
          ? this.state.formData.lineItems
          : [],
      taxRate: discountAndTaxRateEnabled ? this.state.formData.taxRate : null,
      discount: discountAndTaxRateEnabled
        ? this.state.formData.discount
        : emptyDiscount,
      opportunityId: this.state.formData.opportunityId,
      paymentMethodOnFileAuthorized:
        this.state.formData.paymentMethodOnFileAuthorized,
      hideLineItemPrices: this.state.formData.hideLineItemPrices,
      startTime: this.isCrewTimeBased(this.state.formData)
        ? dateService.formatTimeForSerialization(this.state.formData.startTime)
        : null,
      endTime: this.isCrewTimeBased(this.state.formData)
        ? dateService.formatTimeForSerialization(this.state.formData.endTime)
        : null,
      arrivalWindowDurationMinutes: this.isCrewTimeBased(this.state.formData)
        ? this.state.formData.arrivalWindowDurationMinutes
        : null,
      notifyCustomer: this.state.formData.notifyCustomer,
      projectId: this.state.formData.projectId,
    };

    if (this.state.formData.customerRecordType === "group") {
      formData.customerCategoryName = this.state.formData.customerId;
    } else {
      formData.customerId = this.state.formData.customerId;
    }

    if (!isAdmin(this.props.userAccountRole)) {
      delete (formData as any).billingType;
      delete (formData as any).grossRevenuePerVisit;
      delete (formData as any).lineItems;
      delete (formData as any).paymentMethodOnFileAuthorized;
      delete (formData as any).hideLineItemPrices;
    }

    if (this.isCrewTimeBased(this.state.formData) && !this.state.formData.id) {
      this.props.setUserSetting({
        type: UserSettingsType.jobArrivalWindowDurationMinutes,
        value: this.state.formData.arrivalWindowDurationMinutes,
      });
    }

    return {
      ...formData,
      // TODO: Add testing
      precedingJobInstanceId: this.state.formData.precedingJobInstanceId,
      precedingJobInstanceIdModified:
        this.state.formData.originalPrecedingJobInstanceId !==
        this.state.formData.precedingJobInstanceId,
    };
  }

  private getDateAsIso() {
    return dateService.formatAsIso(this.state.formData.date);
  }

  validate() {
    const result = { valid: true, errorMessage: "" };

    if (this.state.hasPendingPhotoAdd) {
      return {
        valid: false,
        errorMessage: "Please wait until all photos are uploaded",
      };
    }

    if (!this.state.formData.date) {
      return {
        valid: false,
        errorMessage: "Please select a date",
      };
    }

    if (!this.state.formData.customerId) {
      return {
        valid: false,
        errorMessage: "A customer must be selected",
      };
    }

    let customer = this.props.customers.find(
      (c) => c.id === this.state.formData.customerId
    );
    if (
      this.state.formData.notifyCustomer &&
      (!customer?.phoneNumberOptedIntoSms || !customer?.phoneNumber)
    ) {
      return {
        valid: false,
        errorMessage:
          "Customer must have an authorized phone number to notify.",
      };
    }

    return result;
  }

  render() {
    const {
      crews,
      oneTimeJobs,
      showForm,
      startSave,
      cancel,
      errorMessage,
      saving,
      setErrorMessage,
      oneTimeJobId,
      anyJobExists,
      userAccountRole,
    } = this.props;
    const { formData } = this.state;

    let formHeader = "Add Single Job";
    if (this.props.headerOverride) {
      formHeader = this.props.headerOverride;
    } else if (oneTimeJobId) {
      const job = jobFinder.getOneTimeJobById(oneTimeJobs, oneTimeJobId);

      if (job) {
        const customer = customerFinder.getCustomerByJob(
          job,
          this.props.customers
        );
        if (customer.name) {
          formHeader = `Update Job for ${customer.name}`;
        } else {
          formHeader = "Update Job";
        }
      }
    }

    const isCrewTimeBased = this.isCrewTimeBased(this.state.formData);

    return (
      <React.Fragment>
        <FormContainer
          size="lg"
          setErrorMessage={setErrorMessage}
          validate={this.validate}
          getFormData={this.getFormData}
          formHeader={formHeader}
          showForm={showForm && !this.state.loadingJob}
          errorMessage={errorMessage}
          saving={saving}
          startSave={startSave}
          cancel={cancel}
          formKey="oneTimeJob"
          hasFormDataChanged={() => {
            return (
              JSON.stringify(this.state.formData) !==
              JSON.stringify(this.state.originalFormData)
            );
          }}
          addSaveAndNewButton={!oneTimeJobId}
        >
          {!isProjectBillingJobInstance(
            this.state.formData.projectId,
            this.props.projects
          ) ? (
            <>
              <h5>Customer information</h5>
              <div className="form-section">
                <div className="form-group" data-testid="customerContainer">
                  <label htmlFor="customer" className="required">
                    Customer
                  </label>
                  <CustomerSelection
                    selectRef={this.customerElement}
                    // Prevent selecting groups when editing
                    includeGroups={!this.props.oneTimeJobId}
                    value={{
                      recordType: formData.customerRecordType ?? "customer",
                      id: formData.customerId,
                    }}
                    disabled={!!this.state.formData.projectId}
                    onCustomerClear={() => {
                      this.setState({
                        taxRateAlreadySet: false,
                        formData: {
                          ...this.state.formData,
                          customerRecordType: null,
                          customerAdditionalLocationId: "",
                          customerId: "",
                        },
                      });
                    }}
                    onCustomerSelection={(type, selectedValue) => {
                      if (type === "customer") {
                        const newCustomerId = selectedValue;
                        if (newCustomerId !== this.state.formData.customerId) {
                          if (
                            formData.customerAdditionalLocationId !== "" &&
                            formData.customerAdditionalLocationId !== null
                          ) {
                            console.error(
                              "clearing customer location by changing customer"
                            );
                          }

                          let customerTaxExempt = false;
                          if (newCustomerId) {
                            const customer =
                              customerFinder.getOptionalCustomerById(
                                newCustomerId,
                                this.props.customers
                              );
                            customerTaxExempt = customer?.taxExempt ?? false;
                          }

                          this.props.clearErrorMessage();
                          this.setState({
                            taxRateAlreadySet: false,
                            formData: {
                              ...this.state.formData,
                              customerRecordType: "customer",
                              customerAdditionalLocationId: "",
                              customerId: newCustomerId,
                              taxRate: customerTaxExempt
                                ? null
                                : this.state.formData.taxRate,
                            },
                          });
                        }
                      } else if (type === "group") {
                        this.props.clearErrorMessage();
                        this.setState({
                          formData: {
                            ...this.state.formData,
                            customerRecordType: "group",
                            customerAdditionalLocationId: "",
                            customerId: selectedValue,
                          },
                        });
                      }
                    }}
                  />
                  {formData.customerId &&
                  formData.customerRecordType !== "group" ? (
                    <div>
                      <CustomerBalanceWarning
                        customerId={formData.customerId}
                      />
                      <button
                        type="button"
                        className="btn btn-sm btn-link"
                        onClick={() =>
                          this.props.showCustomersForm({
                            customerId: formData.customerId,
                          })
                        }
                      >
                        Edit customer
                      </button>
                    </div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label htmlFor="customerAdditionalLocation">
                    Job location
                  </label>
                  <CustomerAdditionalLocationInfoToolTip />
                  <CustomerAdditionalLocationSelection
                    forceDisable={formData.customerRecordType === "group"}
                    value={formData.customerAdditionalLocationId}
                    customerId={formData.customerId || ""}
                    onCustomerAdditionalLocationSelection={(
                      customerAdditionalLocationId
                    ) => {
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          customerAdditionalLocationId,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </>
          ) : null}
          <h5>Schedule</h5>
          <div className="form-section">
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="flexibleJob"
                  name="flexibleJob"
                  checked={formData.flexibleJob}
                  onChange={(e) => {
                    let value = e.target.checked;
                    this.handleChangeWithFieldName("flexibleJob", value);
                  }}
                />
                <label className="custom-control-label" htmlFor="flexibleJob">
                  Flexible job?
                </label>
                <FlexibleJobToolTip />
              </div>
            </div>
            {crews.length > 1 ? (
              <div className="form-group">
                <label htmlFor="crewId" className="required">
                  Crew
                </label>
                <select
                  id="crewId"
                  className="form-control"
                  name="crewId"
                  data-testid="crewId"
                  value={formData.crewId}
                  onChange={this.handleChange}
                  disabled={formData.flexibleJob}
                >
                  {formData.flexibleJob ? (
                    <option>Not applicable for flexible jobs</option>
                  ) : (
                    getSortedCrews(crews)
                      .filter((c) => !c.inactive || c.id === formData.crewId)
                      .map((crew) => (
                        <option key={crew.id} value={crew.id}>
                          {crew.name}
                        </option>
                      ))
                  )}
                </select>
              </div>
            ) : null}

            <OneTimeJobFormDateTimeFields
              flexibleJob={formData.flexibleJob}
              date={formData.date}
              startTime={formData.startTime}
              endTime={formData.endTime}
              onDateChange={(newValue) => {
                this.handleChangeWithFieldName("date", newValue);
              }}
              onStartTimeChange={(newValue) => {
                this.handleChangeWithFieldName("startTime", newValue);
              }}
              onEndTimeChange={(newValue) => {
                this.handleChangeWithFieldName("endTime", newValue);
              }}
              setErrorMessage={setErrorMessage}
              isCrewTimeBased={isCrewTimeBased}
              crewId={formData.crewId}
              startTimeInputId={startTimeInputId}
              jobId={this.props.oneTimeJobId}
              arrivalWindowDurationMinutes={
                formData.arrivalWindowDurationMinutes
              }
              onArrivalWindowChange={(newValue) => {
                this.handleChangeWithFieldName(
                  "arrivalWindowDurationMinutes",
                  newValue
                );
              }}
              notifyCustomer={formData.notifyCustomer}
              onNotifyCustomerChange={(newValue) => {
                this.handleChangeWithFieldName("notifyCustomer", newValue);
              }}
              customerId={formData.customerId}
            />

            {anyJobExists && !isCrewTimeBased ? (
              <OneTimeJobOrderingDropDown
                destinationDate={formData.date}
                destinationCrewId={formData.crewId}
                destinationPrecedingJobInstanceId={
                  formData.precedingJobInstanceId
                }
                destinationUnscheduledJobs={formData.flexibleJob}
                onDestinationPrecedingJobInstanceIdChanged={(newValue) =>
                  this.handleChangeWithFieldName(
                    "precedingJobInstanceId",
                    newValue
                  )
                }
                jobInstanceIdsToExclude={oneTimeJobId ? [oneTimeJobId] : null}
              />
            ) : null}

            {!isProjectBillingJobInstance(
              this.state.formData.projectId,
              this.props.projects
            ) ? (
              <ManHours
                value={formData.estimatedManHours}
                label="Total man hours"
                onChange={(newValue) =>
                  this.handleChangeWithFieldName("estimatedManHours", newValue)
                }
              />
            ) : null}
          </div>
          <h5>Job details</h5>
          <div className="form-section">
            <CrewCategorySelection
              value={formData.categories}
              onChange={(newValue) =>
                this.setState({
                  formData: {
                    ...formData,
                    categories: newValue,
                  },
                })
              }
            />
            <JobInstructionsForCrewField
              value={formData.notes}
              onChange={(newValue) =>
                this.handleChangeWithFieldName("notes", newValue)
              }
              proposalJobSummary={this.props.proposalJobSummary}
            />
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="highlightCrewNotes"
                  name="highlightCrewNotes"
                  checked={formData.highlightCrewNotes}
                  onChange={(e) => {
                    let value = e.target.checked;
                    this.handleChangeWithFieldName("highlightCrewNotes", value);
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="highlightCrewNotes"
                >
                  Show note on crew schedule homepage
                </label>
              </div>
            </div>
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="showCrewNotesOnAdminJobCards"
                  name="showCrewNotesOnAdminJobCards"
                  checked={formData.showCrewNotesOnAdminJobCards}
                  onChange={(e) => {
                    let value = e.target.checked;
                    this.handleChangeWithFieldName(
                      "showCrewNotesOnAdminJobCards",
                      value
                    );
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="showCrewNotesOnAdminJobCards"
                >
                  Show note on admin job cards
                </label>
              </div>
            </div>
          </div>

          <JobTodoItems
            value={formData.todoItems}
            locked={formData.todoItemsLocked}
            todoTemplateId={formData.todoTemplateId}
            onChange={(newTodoItems, newTodoTemplateId) => {
              this.setState({
                formData: {
                  ...formData,
                  todoTemplateId: newTodoTemplateId,
                  todoItems: newTodoItems,
                },
              });
            }}
          />

          <Files
            header="Files for crew"
            files={this.state.formData.photos}
            tenantId={this.props.tenantId || ""}
            imagePrefix={this.props.imagePrefix}
            onFileUploadingStatusChange={(hasPendingPhotoAdd) => {
              this.setState({
                hasPendingPhotoAdd,
              });
            }}
            onFileAdded={(photo) => {
              this.setState({
                formData: {
                  ...this.state.formData,
                  photos: [
                    ...this.state.formData.photos,
                    {
                      ...photo,
                    },
                  ],
                },
              });
            }}
            onFileRemoved={(photoId, imagePath) => {
              this.setState({
                formData: {
                  ...this.state.formData,
                  photos: this.state.formData.photos.filter(
                    (p) => !isPhoto(photoId, imagePath, p)
                  ),
                },
              });
            }}
            onFileUpdated={(photoId, imagePath, caption) => {
              this.setState({
                formData: {
                  ...this.state.formData,
                  photos: this.state.formData.photos.map((p) => {
                    if (isPhoto(photoId, imagePath, p)) {
                      return {
                        ...p,
                        caption,
                      };
                    } else {
                      return p;
                    }
                  }),
                },
              });
            }}
            addAdditionalFiles={
              (this.props.proposalFiles?.length ?? 0) > 0 &&
              !this.state.hideAddAdditionalFiles ? (
                <LinkButton2
                  buttonContents={<small>Add proposal attachments</small>}
                  testId="AddProposalAttachments"
                  onClick={() => {
                    this.setState({
                      formData: {
                        ...this.state.formData,
                        photos: this.state.formData.photos.concat(
                          this.props.proposalFiles?.map((p) =>
                            mapPhotoToDataFile(p)
                          ) ?? []
                        ),
                      },
                      hideAddAdditionalFiles: true,
                    });
                  }}
                />
              ) : null
            }
          />
          {isAdmin(userAccountRole) ? (
            <>
              <h5>Billing information</h5>
              <div className="form-section">
                {!isProjectBillingJobInstance(
                  this.state.formData.projectId,
                  this.props.projects
                ) ? (
                  <JobBillingConfiguration
                    customerId={formData.customerId}
                    taxRateAlreadySet={this.state.taxRateAlreadySet}
                    values={{
                      grossRevenuePerVisit: formData.grossRevenuePerVisit,
                      lineItems: formData.lineItems,
                      billingType: formData.billingType,
                      taxRate: formData.taxRate,
                      discount: formData.discount,
                      paymentMethodOnFileAuthorized:
                        formData.paymentMethodOnFileAuthorized,
                    }}
                    onChange={(newValue) => {
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          grossRevenuePerVisit: newValue.grossRevenuePerVisit,
                          lineItems: newValue.lineItems,
                          billingType: newValue.billingType,
                          taxRate: newValue.taxRate,
                          discount: newValue.discount,
                          paymentMethodOnFileAuthorized:
                            newValue.paymentMethodOnFileAuthorized,
                        },
                      });
                    }}
                  />
                ) : (
                  <OneTimeJobFormProjectBillingNotice
                    projectId={this.state.formData.projectId}
                  />
                )}
              </div>
            </>
          ) : null}

          <div className="form-group">
            <label htmlFor="administratorOnlyNotes">
              Administrator only notes
            </label>
            <TextareaAutosize
              maxRows={10}
              id="administratorOnlyNotes"
              className="form-control"
              name="administratorOnlyNotes"
              value={formData.administratorOnlyNotes}
              onChange={(e) => {
                this.handleChangeWithFieldName(
                  "administratorOnlyNotes",
                  e.currentTarget.value
                );
              }}
            />
          </div>
        </FormContainer>
        {this.state.loadingJob ? <Spinner /> : null}
      </React.Fragment>
    );
  }

  private isCrewTimeBased(formData: IFormData) {
    return (
      this.props.crews.find((c) => c.id === formData.crewId)?.scheduleType ===
      CrewScheduleType.time
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  crews: state.crew.crews,
  oneTimeJobs: state.job.oneTimeJobs,
  jobsLoading: state.job.jobsLoading,
  daySchedules: state.schedule.daySchedules,
  oneTimeJobId: state.forms.oneTimeJob.parameters
    ? state.forms.oneTimeJob.parameters.oneTimeJobId
    : null,
  fieldToFocus: state.forms.oneTimeJob.parameters
    ? state.forms.oneTimeJob.parameters.fieldToFocus
    : null,
  defaultFormData: state.forms.oneTimeJob.parameters
    ? state.forms.oneTimeJob.parameters.defaultFormData
    : null,
  headerOverride: state.forms.oneTimeJob.parameters
    ? state.forms.oneTimeJob.parameters.headerOverride
    : null,
  showForm: state.forms.oneTimeJob.showForm,
  errorMessage: state.forms.oneTimeJob.errorMessage,
  saving: state.forms.oneTimeJob.saving,
  router: state.router,
  tenantId: state.common.tenantId,
  imagePrefix: state.common.imagePrefix || "",
  customers: state.customer.customers,
  customerAdditionalLocations: state.customer.customerAdditionalLocations,
  anyJobExists: state.common.anyJobExists,
  isQuickBooksEnabled: state.common.isQuickBooksEnabled,
  crewCategories: state.common.crewCategories,
  userAccountRole: state.common.userAccountRole,
  projects: state.project.projects,
  proposalFiles: state.forms.oneTimeJob.parameters
    ? state.forms.oneTimeJob.parameters.proposalFiles
    : null,
  proposalJobSummary: state.forms.oneTimeJob.parameters
    ? state.forms.oneTimeJob.parameters.proposalJobSummary
    : null,
  userSettings: state.userSettings.userSettings,
});

const mapDispatchToProps = {
  loadDaySchedulesStarting: actionCreators.loadDaySchedulesStarting,
  startSave: actionCreators.forms.oneTimeJob.startSaving,
  cancel: actionCreators.forms.oneTimeJob.cancelForm,
  setErrorMessage: actionCreators.forms.oneTimeJob.setErrorMessage,
  loadOneTimeJobsStart: actionCreators.loadOneTimeJobsStart,
  showCustomersForm: actionCreators.forms.customer.showForm,
  clearErrorMessage: actionCreators.forms.oneTimeJob.clearErrorMessage,
  showCrewCategoriesForm: actionCreators.forms.crewCategories.showForm,
  setUserSetting: userSettingsActionCreators.setUserSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(OneTimeJobForm);

export function buildFormDataFromJob(
  job: IJob,
  crewId: string,
  date: string,
  flexibleJob: boolean,
  crewCategories: Array<ICrewCategory>,
  projectId?: string | null
): IFormData {
  return {
    customerRecordType: "customer",
    customerId: job.customerId,
    customerAdditionalLocationId: job.customerAdditionalLocationId,
    crewId,
    estimatedManHours:
      typeof job.estimatedManHours === "number"
        ? job.estimatedManHours.toString()
        : job.estimatedManHours || "",
    // TODO: Correct
    originalPrecedingJobInstanceId: constants.idForFirstJob,
    precedingJobInstanceId: constants.idForFirstJob,
    date: date || "",
    highlightCrewNotes: job.highlightCrewNotes,
    showCrewNotesOnAdminJobCards: job.showCrewNotesOnAdminJobCards,
    notes: job.notes || "",
    administratorOnlyNotes: job.administratorOnlyNotes || "",
    flexibleJob: flexibleJob,
    todoItems: job.todoItems.map((i) => ({ ...i, tempId: uuidv4() })),
    photos: job.photos.map((p) => ({
      ...p,
      contentType: p.contentType,
      imagePath: p.imagePath,
      actualWidth: null,
      actualHeight: null,
    })),
    todoTemplateId: job.todoTemplateId || "",
    todoItemsLocked: false,
    grossRevenuePerVisit: job.grossRevenuePerVisit
      ? job.grossRevenuePerVisit.toString()
      : "",
    categories: getCategories(job.categories, crewCategories),
    billingType: job.billingType,
    lineItems: getLineItemsForForm(job),
    opportunityId: null,
    taxRate: job.taxRate,
    discount: job.discount,
    paymentMethodOnFileAuthorized: job.paymentMethodOnFileAuthorized,
    hideLineItemPrices: job.hideLineItemPrices,
    startTime: job.startTime ?? "",
    endTime: job.endTime ?? "",
    arrivalWindowDurationMinutes: job.arrivalWindowDurationMinutes ?? null,
    notifyCustomer: job.notifyCustomer,
    projectId: projectId ?? null,
  };
}
