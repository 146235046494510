import { Action } from "@reduxjs/toolkit";
import { Epic } from "redux-observable";
import { catchError, filter, mergeMap, timeout } from "rxjs/operators";
import { IApplicationState } from "../../../../modules";
import { actionCreators } from "../../../../modules/actionCreators";
import { of } from "rxjs";
import { proposalsActionCreators } from "../proposal";
import proposalDataProvider from "../../services/proposalDataProvider";

const reloadProposal: Epic<Action, Action, IApplicationState> = (
  action,
  state$
) =>
  action.pipe(
    filter(proposalsActionCreators.reloadProposal.match),
    mergeMap((action) => {
      return proposalDataProvider
        .getProposals({ proposalIds: [action.payload.proposalId] })
        .pipe(
          mergeMap((proposals) => {
            if (proposals.length === 1) {
              return of(
                proposalsActionCreators.reloadProposalCompleted({
                  proposal: proposals[0],
                })
              );
            } else {
              return of(actionCreators.null());
            }
          }),
          timeout(30000),
          catchError(() => of(actionCreators.null()))
        );
    })
  );

export default reloadProposal;
