import { useState } from "react";
import LinkButton2 from "../../../containers/app/components/LinkButton2";
import { IInvoiceView } from "../../../models/IInvoice";
import React from "react";
import BillingInvoiceListPaymentsList, {
  PaymentFields,
  PaymentHeader,
} from "./BillingInvoiceListPaymentsList";

interface IProps {
  invoice: IInvoiceView;
}

const BillingInvoiceListPaymentsDetails: React.FunctionComponent<IProps> = ({
  invoice,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <LinkButton2
        buttonContents="View"
        onClick={() => {
          setShowDetails(true);
        }}
      />

      {showDetails ? (
        <BillingInvoiceListPaymentsList
          invoice={invoice}
          formHeader={"Payment Details"}
          onClose={() => setShowDetails(false)}
          renderPayment={(payment) => (
            <>
              <PaymentHeader payment={payment} />

              <PaymentFields payment={payment} />
            </>
          )}
        />
      ) : null}
    </>
  );
};

export default BillingInvoiceListPaymentsDetails;
