import React, { useState, useEffect } from "react";
import PageWithNavBar2 from "../PageWithNavBar2";
import { IQuickBooksCustomer } from "../../../models/IQuickBooksCustomer";
import Spinner from "../components/Spinner";
import dataProvider from "../../../services/dataProvider";
import { QuickBooksCustomerImportCustomer } from "../components/QuickBooksCustomerImportCustomer";
import { forkJoin, Subscription } from "rxjs";
import { ISavedQuickBooksCustomers } from "../../../services/remoteDataProvider";
import { timeout } from "rxjs/operators";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import { useSelector } from "react-redux";
import { IRootState } from "../../../store";
import { fullStoryLogError } from "../../../services/fullStoryService";

const QuickBooksCustomerImport: React.FunctionComponent<{}> = () => {
  const [quickBooksCustomers, setQuickBooksCustomers] = useState<
    Array<IQuickBooksCustomer>
  >([]);

  const [savedQuickBooksCustomers, setSavedQuickBooksCustomers] = useState<
    Array<ISavedQuickBooksCustomers>
  >([]);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isQuickBooksEnabled = useSelector<IRootState, boolean>(
    (s) => s.common.isQuickBooksEnabled
  );

  useEffect(() => {
    let subscription: Subscription | null = null;

    if (isQuickBooksEnabled) {
      setLoading(true);
      subscription = forkJoin([
        dataProvider.getQuickBooksCustomers(),
        dataProvider.getQuickBooksCustomersItems(),
      ])
        .pipe(timeout(30000))
        .subscribe(
          ([returnedQuickBooksCustomers, returnedSavedQuickBooksCustomers]) => {
            setQuickBooksCustomers(returnedQuickBooksCustomers);
            setSavedQuickBooksCustomers(returnedSavedQuickBooksCustomers);
            setLoading(false);
          },
          (error) => {
            setLoading(false);
            setErrorMessage(
              getErrorMessageFromError(
                error,
                "An unknown error occurred while loading QuickBooks customers.  Administrators for the site have been alerted."
              )
            );
            fullStoryLogError("Unable to load data for QuickBooks import page");
          }
        );
    }

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [setLoading, setQuickBooksCustomers, isQuickBooksEnabled]);

  useEffect(() => {
    if (!isQuickBooksEnabled) {
      fullStoryLogError("QuickBooks import page loaded when not configured");
      setErrorMessage(
        "QuickBooks has not been configured.  Please click Manage > QuickBooks Configuration to import customers."
      );
    }
  }, [isQuickBooksEnabled]);

  return (
    <PageWithNavBar2 billingContext={true} notFluid={true}>
      <h1>QuickBooks Import</h1>
      {loading ? (
        <Spinner />
      ) : errorMessage ? (
        <div className="alert alert-danger" style={{ marginTop: "100px" }}>
          {errorMessage}
        </div>
      ) : (
        <React.Fragment>
          {quickBooksCustomers.map((c) => (
            <QuickBooksCustomerImportCustomer
              key={c.id}
              quickBooksCustomer={c}
              previouslySaved={
                !!savedQuickBooksCustomers.find((s) => s.quickBooksId === c.id)
              }
            />
          ))}
        </React.Fragment>
      )}
    </PageWithNavBar2>
  );
};

export default QuickBooksCustomerImport;
