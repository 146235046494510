import React, { useEffect, useState, useMemo } from "react";
import { GroupBase, OptionProps } from "react-select";
import { components } from "react-select";
import { connect } from "react-redux";
import { IRootState } from "../../../store";
import { actionCreators } from "../../../modules/actionCreators";
import uuidv4 from "uuid/v4";
import { ICrewMember } from "../../../models/ICrewMember";
import { ICrewMemberIdentifier } from "../../../models/ICrewMemberIdentifier";
import { getSortedItems } from "../../../services/sortingService";
import WrappedCreatableSelect from "./WrappedCreateableSelect";
import { WrappedCreatableSelectOption } from "./WrappedCreatableSelectOption";

interface IProps extends IOwnProps {
  crewMembers: Array<ICrewMember>;
  showCrewMemberForm: (params: any) => void;
}

interface IOwnProps {
  usePortal?: boolean;
  isDisabled?: boolean;
  value: Array<ICrewMemberIdentifier>;
  onChange: (newValue: Array<ICrewMemberIdentifier>) => void;
  trailingContent?: JSX.Element | null;
}

const newItemId = "newitem";

const CrewMemberSelection: React.FunctionComponent<IProps> = ({
  usePortal,
  crewMembers,
  value,
  onChange,
  showCrewMemberForm,
  isDisabled,
  trailingContent,
}) => {
  const [crewMemberFormInstanceKey, setCrewMemberFormInstanceKey] =
    useState("");
  useEffect(() => {
    if (crewMemberFormInstanceKey) {
      const customerAdditionalLocation = crewMembers.find(
        (c) =>
          (c as any).parameters &&
          (c as any).parameters.formInstanceKey === crewMemberFormInstanceKey
      );
      if (customerAdditionalLocation) {
        onChange([...value, { id: customerAdditionalLocation.id }]);
        setCrewMemberFormInstanceKey("");
      }
    }
  }, [crewMembers, crewMemberFormInstanceKey, onChange, value]);

  const crewMemberSuggestions = useMemo(
    () =>
      getSortedItems(
        crewMembers.filter(
          (cm) => !cm.inactive || value.some((v) => v.id === cm.id)
        ),
        "name"
      ),
    [crewMembers, value]
  );

  const Option = (
    props: OptionProps<ICrewMember, true, GroupBase<ICrewMember>>
  ) => {
    const crewMember = props.data as ICrewMember;
    const text = crewMember.name;

    return (
      <components.Option {...props}>
        <div>
          <WrappedCreatableSelectOption
            isNewItem={crewMember.id === newItemId}
            name={text}
            recordType="crew member"
          />
        </div>
      </components.Option>
    );
  };

  const handleChange = (newValue: any, actionMeta: any) => {
    if (actionMeta.action === "remove-value" && newValue === null) {
      newValue = [];
    }

    if (newValue && typeof newValue.length === "number") {
      const typedNewValue = newValue as Array<ICrewMember>;
      const crewMemberIdentifiers: Array<ICrewMemberIdentifier> =
        typedNewValue.map((v) => ({
          id: v.id,
        }));
      onChange(crewMemberIdentifiers);
    } else {
      console.error("newValue was not an array in CrewMemberSelection");
      console.error(actionMeta);
    }
  };

  const crewMembersValues = getValueAsCrewMembers(value, crewMembers);

  return (
    <div className="form-group">
      <label htmlFor="crewMemberSelection">Crew members</label>
      <WrappedCreatableSelect<ICrewMember, true>
        inputId="crewMemberSelection"
        menuShouldBlockScroll={usePortal ? true : undefined}
        menuPortalTarget={usePortal ? document.body : undefined}
        value={crewMembersValues}
        styles={{
          multiValue: (base) => {
            return {
              ...base,
              backgroundColor: "#e7f4ff",
              color: "#1f8dd6",
            };
          },

          multiValueLabel: (base) => {
            return {
              ...base,
              color: "#1f8dd6",
            };
          },

          multiValueRemove: (base) => {
            return {
              ...base,
              color: "#1f8dd6",
              cursor: "pointer",
            };
          },

          menuPortal: (base) => {
            return {
              ...base,
              zIndex: 2000,
            };
          },
        }}
        options={crewMemberSuggestions}
        isDisabled={isDisabled || false}
        isMulti
        isClearable={false}
        getOptionValue={(opt) => opt.id}
        getOptionLabel={(opt) => opt.name}
        getNewOptionData={(text, b) =>
          ({
            id: newItemId,
            name: text,
            inactive: false,
            emailAddress: "",
            phoneNumber: "",
          } as ICrewMember)
        }
        onChange={handleChange}
        isValidNewOption={() => true}
        createOptionPosition="first"
        components={{ Option }}
        placeholder="Select crew members"
        noOptionsMessage={() => "No matches found."}
        onLeaveInputField={(crewMemberName) => {
          // Hack warning.  Without this code, if the user clicked into a drop-down list on the containing form (i.e. Frequency on the Recurring Job form),
          // the drop-down list would stay open even though the custom form was also opened.  Appears need to leave focus from the select to a control on the existing form
          // and then open the customer form.  setTimeout is needed so for a slight delay.
          setTimeout(() => {
            const formInstanceKey = uuidv4();
            setCrewMemberFormInstanceKey(formInstanceKey);
            showCrewMemberForm({
              formInstanceKey,
              defaultName: crewMemberName,
            });
          });
        }}
        onCreateOption={(input: string) => {
          const formInstanceKey = uuidv4();
          setCrewMemberFormInstanceKey(formInstanceKey);

          showCrewMemberForm({
            formInstanceKey,
            defaultName: input,
          });
        }}
      />
      {trailingContent}
    </div>
  );
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => ({
  crewMembers: state.crew.crewMembers,
  value: ownProps.value,
  onChange: ownProps.onChange,
  isDisabled: ownProps.isDisabled,
  trailingContent: ownProps.trailingContent,
  customerAdditionalLocations: state.customer.customerAdditionalLocations,
});

const mapDispatchToProps = {
  showCrewMemberForm: actionCreators.forms.crewMember.showForm,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CrewMemberSelection);

function getValueAsCrewMembers(
  value: ICrewMemberIdentifier[],
  crewMembers: ICrewMember[]
) {
  const tenantiveResult = value.map((v) =>
    crewMembers.find((m) => m.id === v.id)
  );
  const itemNotFound = tenantiveResult.reduce((acc, r) => acc || !r, false);

  if (itemNotFound) {
    console.error("could not map from crew member identifier to crew member");
  }

  return tenantiveResult as Array<ICrewMember>;
}
