import { useDispatch } from "react-redux";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { commonUiActionCreators } from "../../../modules/commonUi";
import Alert from "./Alert";

const CommonUiAlertModal: React.FunctionComponent<{}> = () => {
  const alertErrorMessage = useApplicationStateSelector(
    (s) => s.commonUi.alertErrorMessage
  );
  const dispatch = useDispatch();

  return typeof alertErrorMessage === "string" ? (
    <Alert
      message={alertErrorMessage}
      closeForm={() =>
        dispatch(commonUiActionCreators.dismissCommonAlertModal())
      }
    />
  ) : null;
};

export default CommonUiAlertModal;
