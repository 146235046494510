import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import { SendType } from "../../../enums/sendType";
import {
  buildUrl,
  executeWithHeaders,
} from "../../../services/remoteDataProvider";
import { IProposalForPrint } from "../models/IProposalForPrint";
import { IProposal, IProposalExisting } from "../models/IProposal";
import { IProposalTemplate } from "../models/IProposalTemplate";
import { IProposalEmailConfiguration } from "../../../models/IProposalEmailConfiguration";

export type ProposalSaveRequest = Partial<IProposal> & {
  customerEmailAddresses: Array<string> | null;
  customerPhoneNumber: string | null;
  customerPhoneNumberOptedIntoSms: boolean | null;
  replyToEmailAddress: string | null;
  skipSend: boolean;
  customerId: string | null;
  opportunityId: string | null;
  subject: string | null;
};

export type ProposalSaveResponse = IProposalExisting & {
  sendFailureMessage: string | null;
};

const proposalDataProvider = {
  saveProposal: (proposal: ProposalSaveRequest) => {
    let saveFn: (url: string, body: any, headers: any) => Observable<any>;
    let urlSegment: string;
    if (proposal.id) {
      saveFn = ajax.patch;
      urlSegment = `proposal/${proposal.id}`;
    } else {
      saveFn = ajax.post;
      urlSegment = "proposal";
    }

    return executeWithHeaders((headers) =>
      saveFn(buildUrl(urlSegment), JSON.stringify(proposal), headers).pipe(
        map((r) => r.response as ProposalSaveResponse)
      )
    );
  },

  sendProposal: (body: {
    sendType: SendType;
    emailAddresses: Array<string>;
    replyToEmailAddress: string;
    phoneNumber: string;
    phoneNumberOptedIntoSms: boolean;
    proposalId: string;
  }) => {
    return executeWithHeaders((headers) =>
      ajax.post(buildUrl("proposal/send"), JSON.stringify(body), headers)
    );
  },

  getProposals: (request: { proposalIds: Array<string> }) => {
    return executeWithHeaders((headers) =>
      ajax
        .post(buildUrl("proposal/getbyids"), JSON.stringify(request), headers)
        .pipe(
          map((r) => {
            return r.response.proposals as Array<IProposalExisting>;
          })
        )
    );
  },

  getProposalTemplates: () => {
    return executeWithHeaders((headers) =>
      ajax
        .get(buildUrl("customerCommunicationTemplate?templateType=2"), headers)
        .pipe(
          map((result) => result.response.templates as Array<IProposalTemplate>)
        )
    );
  },

  saveProposalTemplate: (template: IProposalTemplate) => {
    let saveFn: (url: string, body: any, headers: any) => Observable<any>;
    let urlSegment: string;
    let created: boolean;
    if (template.id) {
      saveFn = ajax.patch;
      urlSegment = `customerCommunicationTemplate/v2/${template.id}?templateType=2`;
    } else {
      saveFn = ajax.post;
      urlSegment = "customerCommunicationTemplate/v2?templateType=2";
      created = true;
    }

    return executeWithHeaders((headers) =>
      saveFn(buildUrl(urlSegment), JSON.stringify(template), headers).pipe(
        map((r) => ({ id: r.response.id as string, created: created }))
      )
    );
  },

  deleteProposalTemplate: (templateId: string) => {
    return executeWithHeaders((headers) =>
      ajax.delete(
        buildUrl(`customerCommunicationTemplate/${templateId}`),
        headers
      )
    );
  },

  getProposalsForPrint: (proposalIds: Array<string>) => {
    const params: Array<string> = [];
    proposalIds.forEach((proposalId) =>
      params.push(`proposalIds=${encodeURIComponent(proposalId)}`)
    );

    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(`Proposal/Print?${params.join("&")}`), headers)
    ).pipe(
      map((result) => result.response.proposals as Array<IProposalForPrint>)
    );
  },

  updateEmailConfiguration: (configuration: IProposalEmailConfiguration) => {
    return executeWithHeaders((headers) =>
      ajax.patch(buildUrl(`ProposalEmailConfiguration`), configuration, headers)
    );
  },
};

export default proposalDataProvider;
