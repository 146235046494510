import React, { useEffect } from "react";
import { ICrew } from "../../../models/ICrew";
import { useUserSettings } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import { ISelectedCrew } from "../components/types/ISelectedCrew";
import { getScheduleWeekSelectedCrews } from "../services/scheduleWeekService";

export function useScheduleWeekDefaultSelectedCrews({
  crews,
  params,
  setSelectedCrews,
  layoutUserSettingsType,
}: {
  crews: ICrew[];
  params: { crewId: string };
  setSelectedCrews: React.Dispatch<React.SetStateAction<ISelectedCrew[]>>;
  layoutUserSettingsType: UserSettingsType;
}) {
  const { getUserSettings } = useUserSettings();

  useEffect(() => {
    let newSelectedCrews = getScheduleWeekSelectedCrews(crews, params).map(
      (c) => ({
        id: c.id,
        expanded: false,
      })
    );

    if (newSelectedCrews.length > 1) {
      const savedCrewOrdering = getUserSettings<Array<string | ISelectedCrew>>(
        layoutUserSettingsType
      );

      if (savedCrewOrdering) {
        const normalizedSavedCrews = savedCrewOrdering.map((savedCrew) => {
          if (typeof savedCrew === "string") {
            return {
              id: savedCrew,
              expanded: false,
            } as ISelectedCrew;
          } else {
            return savedCrew;
          }
        });

        newSelectedCrews = [
          // Add saved crew IDs that are still valid
          ...normalizedSavedCrews.filter((savedCrew) =>
            newSelectedCrews.some(
              (newSelectedCrew) => newSelectedCrew.id === savedCrew.id
            )
          ),
          // Add new crew IDs that weren't orginally saved
          ...newSelectedCrews.filter(
            (newCrew) =>
              !normalizedSavedCrews.some(
                (savedCrew) => savedCrew.id === newCrew.id
              )
          ),
        ];
      }
    }

    setSelectedCrews(newSelectedCrews);
  }, [
    crews,
    params,
    setSelectedCrews,
    layoutUserSettingsType,
    getUserSettings,
  ]);
}
