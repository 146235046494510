import { UserAccountRole } from "../enums/userAccountRole";
import { useApplicationStateSelector } from "./useApplicationStateSelector";

export default function useIsAdmin() {
  const role = useApplicationStateSelector((s) => s.common.userAccountRole);

  return isAdmin(role);
}

export function isAdmin(role: UserAccountRole) {
  return role === UserAccountRole.administrator;
}
