import React, { useState } from "react";
import PercentField from "../../../containers/app/components/PercentField";
import InvoiceConfigurationForm from "../../../containers/app/forms/InvoiceConfigurationForm";

interface IProps {
  taxRate: number | null;
  onTaxRateChange: (newValue: number | null) => void;
  className?: string;
  taxRateDisabled: boolean;
}

const TaxRateField: React.FunctionComponent<IProps> = ({
  taxRate,
  onTaxRateChange,
  className,
  taxRateDisabled,
}) => {
  const [showUpdateTaxRateDefault, setShowUpdateTaxRateDefault] =
    useState(false);
  const [showInvoiceConfigurationForm, setShowInvoiceConfigurationForm] =
    useState(false);
  const classes = showUpdateTaxRateDefault
    ? className
    : `form-group ${className}`;

  return (
    <div className={classes ?? ""}>
      <PercentField
        id="taxRate"
        dataTestId="taxRate"
        value={taxRate}
        onChange={(newValue) => {
          setShowUpdateTaxRateDefault(true);
          onTaxRateChange(newValue);
        }}
        decimalPlacesAllowed={3}
        disabled={taxRateDisabled}
      />

      {showInvoiceConfigurationForm ? (
        <InvoiceConfigurationForm
          defaultTaxRate={taxRate}
          onClose={() => {
            setShowInvoiceConfigurationForm(false);
          }}
          onSaveComplete={(newValue) => {
            setShowInvoiceConfigurationForm(false);
            setShowUpdateTaxRateDefault(false);
            onTaxRateChange(newValue);
          }}
        />
      ) : null}

      {showUpdateTaxRateDefault && !taxRateDisabled ? (
        <button
          type="button"
          className="btn btn-sm btn-link "
          data-testid="updateDefaultTaxRate"
          onClick={() => {
            setShowInvoiceConfigurationForm(true);
          }}
        >
          Update default tax rate
        </button>
      ) : null}
    </div>
  );
};

export default TaxRateField;
