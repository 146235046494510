import { IJobInstance } from "../../../models/IJobInstance";
import timeService from "../../../services/timeService";

export function getCardColors({
  selected,
  jobInstance,
}: {
  selected: boolean;
  jobInstance: IJobInstance;
}) {
  let classes = "";
  let useWhiteText = false;
  if (selected) {
    classes += "bg-secondary";
  } else if (jobInstance.complete) {
    classes += "bg-success";
    useWhiteText = true;
  } else if (jobInstance.skipped) {
    classes += "bg-warning";
    useWhiteText = true;
  } else if (
    timeService.hasAllStartAndEndTimesSet(jobInstance) &&
    !jobInstance.complete
  ) {
    classes += "schedule-job-paused";
  } else if (timeService.hasStartTimeSet(jobInstance)) {
    classes += "bg-primary";
    useWhiteText = true;
  } else {
    classes += "bg-white";
  }

  if (useWhiteText) {
    classes += " text-white";
  } else {
    classes += " text-dark";
  }

  return { classes, useWhiteText };
}
