import React, { useState, useMemo } from "react";
import ButtonDropdown from "reactstrap/lib/ButtonDropdown";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../../formGenerator";
import { Link } from "react-router-dom";
import { builders as routerBuilders } from "../../../../services/routing";
import { IManageCustomerSearchResult } from "../../../../models/IManageCustomersSearch";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import dateService from "../../../../services/dateService";
import { trackTrace } from "../../../../services/applicationInsights";
import addressFormatter from "../../../../services/addressFormatter";
import { ICustomer } from "../../../../models/ICustomer";
import { getCrewNameForJobVisit } from "../../../../services/customerService";
import ProjectFormBody from "../../../../slices/schedule/components/ProjectForm";
import { getCategoryIdentifiersForDisplay } from "../../../../services/crewCategoryService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { getNormalizedPhoneNumber } from "../../../../services/phoneNumberService";
import { useUserSettings } from "../../../../services/userSettingsService";

export function Address({
  searchResult,
}: {
  searchResult: IManageCustomerSearchResult;
}) {
  const customer = useGetCustomer(searchResult);
  return <>{addressFormatter.formatAddressEntity(customer)}</>;
}

export function Tags({
  searchResult,
}: {
  searchResult: IManageCustomerSearchResult;
}) {
  const customer = useGetCustomer(searchResult);
  const customerCategories = useApplicationStateSelector(
    (s) => s.common.customerCategories
  );

  return (
    <>
      {getCategoryIdentifiersForDisplay(
        customer.categories,
        customerCategories
      )}
    </>
  );
}

export function ContactInformation({
  searchResult,
}: {
  searchResult: IManageCustomerSearchResult;
}) {
  const customer = useGetCustomer(searchResult);

  const phoneNumber = getNormalizedPhoneNumber(customer.phoneNumber ?? "");
  const alternativePhoneNumber = getNormalizedPhoneNumber(
    customer.alternativePhoneNumber ?? ""
  );

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "min-content auto",
        gridTemplateRows: "auto auto auto",
        alignItems: "center",
      }}
    >
      {phoneNumber ? (
        <>
          <FontAwesomeIcon icon={faPhone} className="mr-2" />
          <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
        </>
      ) : null}
      {alternativePhoneNumber ? (
        <>
          Alt:
          <a href={`tel:${alternativePhoneNumber}`}>{alternativePhoneNumber}</a>
        </>
      ) : null}
      {customer.emailAddresses?.length > 0
        ? customer.emailAddresses.map((e) => (
            <React.Fragment key={e}>
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
              <div className="text-truncate">
                <a href={`mailto:${e}`} className="mr-2">
                  {e}
                </a>
              </div>
            </React.Fragment>
          ))
        : null}
    </div>
  );
}

export function CustomerName({
  searchResult,
  searchText,
}: {
  searchResult: IManageCustomerSearchResult;
  searchText: string;
}) {
  const customer = useGetCustomer(searchResult);

  return (
    <Link
      to={routerBuilders.manage.buildCustomerDetailsRoute({
        customerId: searchResult.id,
        search: searchText,
      })}
    >
      {customer.name}
    </Link>
  );
}

export function LastVisitDate({
  searchResult,
}: {
  searchResult: IManageCustomerSearchResult;
}) {
  const { getUserSettings } = useUserSettings();
  const crews = useApplicationStateSelector((s) => s.crew.crews);

  const lastVisitCrewName = getCrewNameForJobVisit(
    crews,
    searchResult.lastVisitCrewId,
    searchResult.lastVisitIsInWeeksFlexibleJobsHolder
  );

  return (
    <>
      {searchResult.lastVisitDate ? (
        <React.Fragment>
          <Link
            data-testid="customerLastVisitLink"
            to={routerBuilders.schedule.buildWeekRoute(
              getUserSettings,
              crews,
              searchResult.lastVisitDate,
              searchResult?.lastVisitCrewId ?? ""
            )}
          >
            {dateService.formatDateForDisplay(searchResult.lastVisitDate)}
            {lastVisitCrewName ? <div>{lastVisitCrewName}</div> : null}
          </Link>
        </React.Fragment>
      ) : (
        ""
      )}
    </>
  );
}

export function NextVisitDate({
  searchResult,
}: {
  searchResult: IManageCustomerSearchResult;
}) {
  const { getUserSettings } = useUserSettings();
  const crews = useApplicationStateSelector((s) => s.crew.crews);

  const nextVisitCrewName = getCrewNameForJobVisit(
    crews,
    searchResult.nextVisitCrewId,
    searchResult.nextVisitIsInWeeksFlexibleJobsHolder
  );

  return (
    <>
      {searchResult.nextVisitDate ? (
        <React.Fragment>
          <Link
            data-testid="customerNextVisitLink"
            to={routerBuilders.schedule.buildWeekRoute(
              getUserSettings,
              crews,
              searchResult.nextVisitDate,
              searchResult?.nextVisitCrewId ?? ""
            )}
          >
            {dateService.formatDateForDisplay(searchResult.nextVisitDate)}
            {nextVisitCrewName ? <div>{nextVisitCrewName}</div> : null}
          </Link>
        </React.Fragment>
      ) : (
        ""
      )}
    </>
  );
}

export function Buttons({
  searchResult,
}: {
  searchResult: IManageCustomerSearchResult;
}) {
  const [showMultiJobForm, setShowMultiJobForm] = useState(false);
  const [showActionDropdown, setShowActionDropdown] = useState(false);
  const customer = useGetCustomer(searchResult);

  const dispatch = useDispatch();

  return (
    <>
      <ButtonDropdown
        direction="down"
        isOpen={showActionDropdown}
        toggle={() => setShowActionDropdown(!showActionDropdown)}
      >
        <DropdownToggle className="btn-sm" caret>
          Add
        </DropdownToggle>
        <DropdownMenu right={true}>
          <DropdownItem
            onClick={() =>
              dispatch(
                actionCreators.oneTimeJob.showForm({
                  defaultFormData: {
                    customerId: customer.id,
                  },
                })
              )
            }
          >
            Single Job
          </DropdownItem>
          <DropdownItem onClick={() => setShowMultiJobForm(true)}>
            Multiple Jobs
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              dispatch(
                actionCreators.maintenanceJob.showForm({
                  customerId: customer.id,
                })
              )
            }
          >
            Recurring Job
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              dispatch(
                actionCreators.customerAdditionalLocation.showForm({
                  customerId: customer.id,
                })
              )
            }
          >
            Additional Location
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
      {showMultiJobForm ? (
        <ProjectFormBody
          initialCustomerId={customer.id}
          onSaveComplete={() => setShowMultiJobForm(false)}
          onCancel={() => setShowMultiJobForm(false)}
          mode="add"
        />
      ) : null}
    </>
  );
}

function useGetCustomer(searchResult: IManageCustomerSearchResult) {
  const customers = useApplicationStateSelector((s) => s.customer.customers);

  const customer = useMemo(() => {
    let result = customers.find((c) => c.id === searchResult.id);
    if (!result) {
      trackTrace(`could not find customer record for ${searchResult.id}`);
      return {
        name: "",
      } as ICustomer;
    }

    return result;
  }, [customers, searchResult]);
  return customer;
}
