import { useParams } from "react-router";
import { ScheduleTimePage } from "./ScheduleTimePage";
import { ScheduleWeekFilters } from "./ScheduleWeekFilters";
import PageWithNavBar2 from "../../../containers/app/PageWithNavBar2";
import { getDatesForScheduleWeek } from "../services/getDatesForScheduleWeek";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { useCallback, useEffect, useMemo, useState } from "react";
import { builders } from "../../../services/routing";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { useScheduleWeekDefaultSelectedCrews } from "../hooks/useScheduleWeekDefaultSelectedCrews";
import dateService from "../../../services/dateService";
import { ICrew } from "../../../models/ICrew";
import parse from "date-fns/parse";
import format from "date-fns/format";
import { IScheduleColumnHeader } from "../../../containers/app/components/schedule";
import { CrewScheduleType } from "../enums/crewScheduleType";
import { Dispatch } from "redux";
import { ISelectedCrew } from "./types/ISelectedCrew";
import {
  getWeekHeaderText,
  getScheduleWeekRows,
  getScheduleWeekNextScheduleParams,
  getScheduleWeekPreviousScheduleParams,
  getCurrentCrewId,
  getSelectedDateStartOfWeek,
  getScheduleWeekColumnHeader,
} from "../services/scheduleWeekService";
import { reorder } from "../services/reorder";
import { useEnsureSchedulesLoadedForWeek } from "../hooks/useEnsureSchedulesLoadedForWeek";
import { useUserSettings } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import { isLoadingScheduleDates } from "../../../services/scheduleService";
import { IScheduleColumn } from "./types/IScheduleColumn";
import { useRedirectToStickyCrew } from "../hooks/useRedirectToStickyCrew";

export function ScheduleTimeWeekPage() {
  const params = useParams<{
    date: string;
    crewId: string;
    mapDate: string;
  }>();

  const crews = useApplicationStateSelector((s) => s.crew.crews);
  const timeBasedCrews = useMemo(
    () => crews.filter((c) => c.scheduleType === CrewScheduleType.time),
    [crews]
  );
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const weeksUnscheduledMaintenanceJobs = useApplicationStateSelector(
    (s) => s.schedule.weeksUnscheduledMaintenanceJobs
  );
  const { setUserSettings } = useUserSettings();

  const [selectedCrews, setSelectedCrews] = useState<Array<ISelectedCrew>>([]);

  const dispatch = useDispatch();

  useRedirectToSequenceCalendarIfCrewNotTimeBased({
    params,
    timeBasedCrews,
    crews,
    dispatch,
  });

  useRedirectToStickyCrew({
    params: params,
    defaultCrewUserSettings: UserSettingsType.timeBasedWeekDefaultCrew,
    getRoute: builders.schedule.buildTimeWeekRoute,
  });

  useRedirectToSequenceScheduleIfCrewNotTimeBased({
    params,
    crews,
  });

  useScheduleWeekDefaultSelectedCrews({
    crews: timeBasedCrews,
    params,
    setSelectedCrews,
    layoutUserSettingsType: UserSettingsType.timeBasedWeekScheduleCrewLayout,
  });

  const scheduleDates = getDatesForScheduleWeek(params);
  useEnsureSchedulesLoadedForWeek({
    crews,
    daySchedules,
    weeksUnscheduledMaintenanceJobs,
    params,
    includePrecedingDay: true,
  });

  const pageHeaderText = getWeekHeaderText(scheduleDates, params);

  const columnHeaders = scheduleDates.map((d) => {
    const isCurrentDate = dateService.areDatesEqual(
      d,
      dateService.getCurrentDate()
    );
    return {
      key: d,
      columnHeader: getScheduleWeekColumnHeader(
        parse(d),
        format(d, "dddd"),
        isCurrentDate,
        CrewScheduleType.time
      ),
      highlightHeader: isCurrentDate,
    } as IScheduleColumnHeader;
  });

  const rows = useMemo(
    () =>
      getScheduleWeekRows({
        selectedCrews,
        crews: timeBasedCrews,
        scheduleDates,
        daySchedules,
        params: {
          crewId: params.crewId,
          date: params.date,
          mapDate: params.mapDate,
        },
        getRoutePath: builders.schedule.buildTimeWeekRoute,
      }),
    [
      daySchedules,
      scheduleDates,
      selectedCrews,
      timeBasedCrews,
      params.crewId,
      params.date,
      params.mapDate,
    ]
  );

  const { hasMissingDates, loadingUnscheduledJobs } = isLoadingScheduleDates(
    getSelectedDateStartOfWeek(params),
    rows.reduce(
      (acc, row) => [...acc, ...row.columns],
      [] as Array<IScheduleColumn | null>
    ),
    weeksUnscheduledMaintenanceJobs
  );

  const isMapViewOpen = !!params.mapDate;

  const onRowExpanded = useCallback(
    (rowIndexToToggle: number): void => {
      const updatedSelectedCrews = selectedCrews.map((c, index) => {
        if (index === rowIndexToToggle) {
          return {
            ...c,
            expanded: !c.expanded,
          };
        } else {
          return c;
        }
      });

      setSelectedCrews(updatedSelectedCrews);

      setUserSettings(
        UserSettingsType.timeBasedWeekScheduleCrewLayout,
        updatedSelectedCrews
      );
    },
    [selectedCrews, setUserSettings]
  );

  const onRowReorder = useCallback(
    (sourceIndex: number, destinationIndex: number): void => {
      const updatedSelectedCrews = reorder(
        selectedCrews,
        sourceIndex,
        destinationIndex
      );
      setSelectedCrews(updatedSelectedCrews);
      setUserSettings(
        UserSettingsType.timeBasedWeekScheduleCrewLayout,
        updatedSelectedCrews
      );
    },
    [selectedCrews, setUserSettings]
  );

  const changeScheduleDate = useCallback(
    (newDate: Date): void => {
      dispatch(
        push(
          builders.schedule.buildTimeWeekRoute(
            dateService.formatAsIso(newDate),
            params.crewId,
            undefined
          )
        )
      );
    },
    [dispatch, params.crewId]
  );

  const onNextSchedule = useCallback(() => {
    const { date, crewId, mapDate } = getScheduleWeekNextScheduleParams({
      isMapViewOpen: false,
      params: {
        mapDate: params.mapDate,
      },
      scheduleDate: scheduleDates[0],
      selectedCrews,
    });

    dispatch(push(builders.schedule.buildTimeWeekRoute(date, crewId, mapDate)));
  }, [dispatch, params.mapDate, scheduleDates, selectedCrews]);

  const onPreviousSchedule = useCallback(() => {
    const { date, crewId, mapDate } = getScheduleWeekPreviousScheduleParams({
      isMapViewOpen: false,
      params: {
        mapDate: params.mapDate,
      },
      scheduleDate: scheduleDates[0],
      selectedCrews,
    });

    dispatch(push(builders.schedule.buildTimeWeekRoute(date, crewId, mapDate)));
  }, [dispatch, params.mapDate, scheduleDates, selectedCrews]);

  return (
    <PageWithNavBar2
      subHeaderLeftSideContent={
        <ScheduleWeekFilters
          selectedCrews={selectedCrews}
          params={params}
          todayButtonLink={builders.schedule.buildTimeWeekRoute(
            dateService.formatAsIso(new Date()),
            getCurrentCrewId(selectedCrews),
            isMapViewOpen ? dateService.formatAsIso(new Date()) : undefined
          )}
        />
      }
    >
      <ScheduleTimePage
        isLoading={hasMissingDates}
        isLoadingFlexibleJobs={loadingUnscheduledJobs}
        columnHeaders={columnHeaders}
        rows={rows}
        mode="week"
        pageHeaderText={pageHeaderText}
        weekForUnscheduledJobs={getSelectedDateStartOfWeek(params)}
        currentWeekDate={scheduleDates[0]}
        onRowExpanded={onRowExpanded}
        onRowReorder={onRowReorder}
        changeScheduleDate={changeScheduleDate}
        onNextSchedule={onNextSchedule}
        onPreviousSchedule={onPreviousSchedule}
      />
    </PageWithNavBar2>
  );
}

function useRedirectToSequenceCalendarIfCrewNotTimeBased({
  params,
  timeBasedCrews,
  crews,
  dispatch,
}: {
  params: { date: string; crewId: string; mapDate: string };
  timeBasedCrews: ICrew[];
  crews: ICrew[];
  dispatch: Dispatch<any>;
}) {
  useEffect(() => {
    if (
      params.crewId &&
      !timeBasedCrews.some((c) => c.id === params.crewId) &&
      crews.some((c) => c.id === params.crewId)
    ) {
      dispatch(
        push(
          builders.schedule.buildSequenceWeekRoute(params.date, params.crewId)
        )
      );
    }
  }, [dispatch, params.crewId, params.date, crews, timeBasedCrews]);
}

function useRedirectToSequenceScheduleIfCrewNotTimeBased({
  params,
  crews,
}: {
  params: { date: string; crewId: string; mapDate: string };
  crews: Array<ICrew>;
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    const crew = crews.find((c) => c.id === params.crewId);
    if (crew && crew.scheduleType === CrewScheduleType.sequence) {
      dispatch(
        push(builders.schedule.buildSequenceWeekRoute(params.date, crew.id))
      );
    }
  }, [params.date, params.crewId, crews, dispatch]);
}
