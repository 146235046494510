import { useDispatch } from "react-redux";
import { retry, timeout } from "rxjs/operators";
import { UserSettingsType } from "../enums/userSettingsType";
import { useApplicationStateSelector } from "../hooks/useApplicationStateSelector";
import {
  decodeValue,
  encodeValue,
  userSettingsActionCreators,
} from "../modules/userSettings";
import remoteDataProvider from "../services/remoteDataProvider";
import {
  getKey,
  getUserSettingsFromStorageLegacy,
} from "../services/userSettingsService";
import { useEffect } from "react";

type UserSettingToMigrate = {
  type: UserSettingsType;
  value: unknown;
  localStorageKeyForRemoval: string;
};

export function useMigrateUserSettings() {
  const isRegistered = useApplicationStateSelector(
    (s) => s.common.isRegistered
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (isRegistered) {
      try {
        if (window.localStorage.getItem("migrationRun") !== "true") {
          const settingTypes = Object.keys(UserSettingsType)
            .map((t) => parseInt(t))
            .filter((t) => !isNaN(t));

          const settingsToMigrate: Array<UserSettingToMigrate> = settingTypes
            .map((type) => {
              const value = getUserSettingsFromStorageLegacy(type);
              return {
                type,
                value,
                localStorageKeyForRemoval: getKey(type),
              };
            })
            .filter((s) => s.value !== null);

          addLegacySetting(
            "reportSortValues",
            UserSettingsType.completedWorkSort,
            settingsToMigrate
          );

          addLegacySetting(
            "cachedAllowAchPayment",
            UserSettingsType.allowAchPayment,
            settingsToMigrate
          );

          addLegacySetting(
            "cachedAllowCreditCardPayment",
            UserSettingsType.allowCreditCardPayment,
            settingsToMigrate
          );

          addLegacySetting(
            "cachedSeasonalScheduleStart",
            UserSettingsType.seasonalScheduleStart,
            settingsToMigrate
          );

          addLegacySetting(
            "cachedSeasonalScheduleEnd",
            UserSettingsType.seasonalScheduleEnd,
            settingsToMigrate
          );

          addLegacySetting(
            "cachedDeliveryMethod",
            UserSettingsType.invoiceDeliveryMethod,
            settingsToMigrate
          );

          if (settingsToMigrate.length > 0) {
            remoteDataProvider
              .saveUserSettings(
                settingsToMigrate.map((s) => ({
                  type: s.type,
                  value: encodeValue(s.value),
                }))
              )
              .pipe(timeout(10000), retry(3))
              .subscribe({
                next: () => {
                  dispatch(
                    userSettingsActionCreators.setUserSettingsFromMigration({
                      userSettings: settingsToMigrate.map((s) => ({
                        type: s.type,
                        value: s.value,
                      })),
                    })
                  );
                  window.localStorage.setItem("migrationRun", "true");

                  settingsToMigrate.forEach((setting) => {
                    window.localStorage.removeItem(
                      setting.localStorageKeyForRemoval
                    );
                  });
                },

                error: () => {},
              });
          } else {
            window.localStorage.setItem("migrationRun", "true");
          }
        }
      } catch (ex) {
        console.error("failed migrating user settings");
        console.error(ex);
      }
    }
  }, [isRegistered, dispatch]);
}

function addLegacySetting(
  localStorageKey: string,
  newUserSettingType: UserSettingsType,
  settingsToMigrate: UserSettingToMigrate[]
) {
  const storedValue = window.localStorage.getItem(localStorageKey);
  if (storedValue) {
    settingsToMigrate.push({
      type: newUserSettingType,
      value: decodeValue(storedValue),
      localStorageKeyForRemoval: localStorageKey,
    });
  }
}
