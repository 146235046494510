import { useState } from "react";

export interface IProps {
  paymentMethodOnFileAuthorized?: boolean;
  setPaymentMethodOnFileAuthorized?: (newValue: boolean) => void;
  paymentMethodNumberToInclude?: string;
  fontSize?: "sm" | "md";
  required?: boolean;
}

const PaymentMethodOnFileDisclosure: React.FunctionComponent<IProps> = ({
  paymentMethodOnFileAuthorized,
  setPaymentMethodOnFileAuthorized,
  paymentMethodNumberToInclude,
  fontSize = "sm",
  required = true,
}) => {
  const [checked, setChecked] = useState(false);

  let prefix: string =
    "I have received authorization from the customer to charge this payment method as required under the";
  if (paymentMethodNumberToInclude) {
    prefix = `I have received authorization from the customer to charge their payment method ending in ${paymentMethodNumberToInclude} as required under the`;
  }

  return (
    <div className={`form-group ${fontSize === "sm" ? "small" : ""}`}>
      <div className="custom-control custom-checkbox mb-2">
        <input
          type="checkbox"
          className="custom-control-input"
          id="paymentMethodOnFileAuthorization"
          data-testid="paymentMethodOnFileAuthorization"
          name="paymentMethodOnFileAuthorization"
          checked={paymentMethodOnFileAuthorized ?? checked}
          required={required}
          onChange={(e) => {
            setChecked(e.currentTarget.checked);
            if (setPaymentMethodOnFileAuthorized) {
              setPaymentMethodOnFileAuthorized(e.currentTarget.checked);
            }
          }}
        />
        <label
          className="custom-control-label"
          htmlFor="paymentMethodOnFileAuthorization"
        >
          {prefix}{" "}
          <a
            href="https://payments.youraspire.com/bank_disclosures"
            target="_blank"
            rel="noopener noreferrer"
          >
            Bank Disclosures
          </a>{" "}
          {" & "}
          <a
            href="https://payments.youraspire.com/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            Merchant Terms and Conditions
          </a>
        </label>
      </div>
    </div>
  );
};

export default PaymentMethodOnFileDisclosure;
