export default function MobileDetailsPageTabContents({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div
      className="bg-light pt-2"
      style={{
        // Hack to ensure the bg-light goes to edges of the screen
        marginLeft: "-10px",
        marginRight: "-10px",
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
    >
      {children}
    </div>
  );
}
