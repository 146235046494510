import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Alert } from "reactstrap";
import { Subscription } from "rxjs";
import { timeout } from "rxjs/operators";
import Spinner from "../../../containers/app/components/Spinner";
import { IProposalForPrint } from "../models/IProposalForPrint";
import proposalDataProvider from "../services/proposalDataProvider";
import ProposalPrint from "./ProposalPrint";

interface IRouteParams {
  proposalId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {}

const ProposalPrintPage: React.FunctionComponent<IProps> = ({ match }) => {
  const { proposalId } = match.params;
  const [proposal, setProposal] = useState<IProposalForPrint | null>(null);
  const [errorLoading, setErrorLoading] = useState(false);
  const printContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let subscription: Subscription | null;
    if (proposalId) {
      subscription = proposalDataProvider
        .getProposalsForPrint([proposalId])
        .pipe(timeout(5000))
        .subscribe({
          next: (p) => {
            setProposal(p[0]);
          },

          error: () => {
            setErrorLoading(true);
          },
        });
    }

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [proposalId]);

  if (!proposalId || errorLoading) {
    return <Alert message="Unable to load the proposal to print." />;
  } else if (!proposal) {
    return <Spinner />;
  } else {
    return (
      <div ref={printContainer}>
        <ProposalPrint proposal={proposal} onLoadComplete={() => {}} />
      </div>
    );
  }
};

export default ProposalPrintPage;
