import { Action } from "@reduxjs/toolkit";
import { Epic } from "redux-observable";
import { filter, map } from "rxjs/operators";
import { opportunitiesActionCreators } from "../opportunity";
import { IApplicationState } from "../../../../modules";
import { tryGetOpportunityIdFromJobSaveAddCompleteAction } from "./utils/tryGetOpportunityIdFromJobSaveAddCompleteAction";
import { actionCreators } from "../../../../modules/actionCreators";

const showArchivePromptOnSchedule: Epic<Action, Action, IApplicationState> = (
  action,
  state$
) =>
  action.pipe(
    filter(
      (action) =>
        tryGetOpportunityIdFromJobSaveAddCompleteAction(action).opportunityIdSet
    ),
    map((action) => {
      const opportunityId = tryGetOpportunityIdFromJobSaveAddCompleteAction(
        action
      ).opportunityId as string;

      let customerName = "";
      const opportunity = state$.value.opportunity.opportunities.find(
        (o) => o.id === opportunityId
      );
      const customer = state$.value.customer.customers.find(
        (c) => c.id === opportunity?.customerId
      );
      customerName = customer?.name ?? "";

      if (!opportunity?.archived) {
        return opportunitiesActionCreators.showArchivePrompt({
          promptMessageOverride: "Would you like to archive this proposal?",
          opportunitiesToArchive: [
            {
              opportunityId,
              customerName,
            },
          ],
        });
      } else {
        return actionCreators.null();
      }
    })
  );

export default showArchivePromptOnSchedule;
