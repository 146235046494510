import { ICrew } from "../../../../models/ICrew";
import { getUniqueItems } from "../../../../services/arrayService";
import dateService from "../../../../services/dateService";
import { getSortedItemsV2 } from "../../../../services/sortingService";
import { builders as routerBuilders } from "../../../../services/routing";
import dateFnsParse from "date-fns/parse";
import { GetUserSettingsType } from "../../../../services/userSettingsService";
import { GetCustomerJobsProjectVisit } from "../../../../slices/schedule/services/jobDataProvider";

export function haveProjectIdsChanged(
  oldProjectIds: string[],
  newProjectIds: string[]
) {
  if (oldProjectIds.length !== newProjectIds.length) {
    return true;
  }

  for (let i = 0; i < oldProjectIds.length; i++) {
    if (oldProjectIds[i] !== newProjectIds[i]) {
      return true;
    }
  }

  return false;
}

export function getCrewsForProject({
  projectVisits,
  crews,
}: {
  crews: Array<ICrew>;
  projectVisits: Array<GetCustomerJobsProjectVisit>;
}) {
  const crewsWithVisits = getUniqueItems(
    projectVisits
      .map((c) => c.crewId)
      .filter((c) => typeof c === "string") as Array<string>
  )
    .map((crewId) => crews.find((crew) => crew.id === crewId))
    .filter((crew) => typeof crew === "object") as Array<ICrew>;

  const matchingCrews = getSortedItemsV2(crewsWithVisits, ["name"]).map(
    (c) => c.name
  );

  return matchingCrews.join(", ");
}

export function getRecentVisitLink({
  crews,
  projectVisits,
  getUserSettings,
}: {
  crews: ICrew[];
  projectVisits: Array<GetCustomerJobsProjectVisit>;
  getUserSettings: GetUserSettingsType;
}): string {
  if (projectVisits.length > 0) {
    const sortedVisits = getSortedItemsV2(projectVisits, ["date"]);

    // filter out any visits in the past
    let filterdVisits = sortedVisits.filter(
      (v) =>
        dateService.removeTimeComponents(dateFnsParse(v.date)) >=
        dateService.removeTimeComponents(dateService.getCurrentDate())
    );

    // use the first visit in the present or future.  If none exist, use the most recent past visit.
    let closestVisit =
      filterdVisits?.[0] ?? sortedVisits[sortedVisits.length - 1] ?? null;

    return routerBuilders.schedule.buildWeekRoute(
      getUserSettings,
      crews,
      closestVisit?.date ?? "",
      closestVisit.crewId ?? ""
    );
  }

  return "";
}

export function getDateRangeForProject({
  projectVisits,
}: {
  projectVisits: Array<GetCustomerJobsProjectVisit>;
}): { valueForDisplay: string; valueForSort: string } {
  if (projectVisits.length > 0) {
    const sortedVisits = getSortedItemsV2(projectVisits, ["date"]);
    const startDate = sortedVisits[0].date;
    const endDate = sortedVisits[sortedVisits.length - 1].date;

    if (startDate === endDate) {
      return {
        valueForDisplay: dateService.formatDateForDisplay(startDate),
        valueForSort: startDate,
      };
    } else {
      return {
        valueForDisplay: `${dateService.formatDateForDisplay(
          startDate
        )} - ${dateService.formatDateForDisplay(endDate)}`,
        valueForSort: startDate,
      };
    }
  }

  return { valueForDisplay: "", valueForSort: "" };
}
