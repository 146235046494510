export enum availableFields {
  complete,
  estimatedManHours,
  actualManHours,
  actualTimeWorked,
  numberOfCrewMembers,
  notesFromCrew,
  todo,
  photosFromCrew,
  notesFromAdmin,
  customCrewQuestions,
  purchaseOrderNumber,
  invoiceNumber,
  invoiceSent,
  customerSignature,
  timeRanges,
  adminOnlyNotes,
  grossRevenuePerVisit,
  estimatedTimeWorked,
  lineItems,
  crew,
}

export type customCrewQuestionId = string;
