import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FormContainerWithoutRedux from "../../../containers/app/components/FormContainerWithoutRedux";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { useDispatch } from "react-redux";
import dateService from "../../../services/dateService";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import TimeInput from "../../../containers/app/components/TimeInput";
import { getJobInstance } from "../../../services/jobInstanceService";
import { JobType } from "../../../models/IJob";
import { fullStoryLogError } from "../../../services/fullStoryService";
import { map } from "rxjs/operators";
import { actionCreators } from "../../../modules/actionCreators";
import { JobConflictAlert } from "./JobConflictAlert";
import { JobArrivalWindowSelection } from "./JobArrivalWindowSelection";
import { ErrorMessageType } from "../../../containers/app/components/FormContainer";

interface IFormData {
  startTime: string;
  endTime: string;
  arrivalWindowDurationMinutes: number | null;
}

interface IProps {
  jobInstanceId: string;
  jobType: JobType;
  onSaveComplete: () => void;
  onCancel: () => void;
  customerId?: string;
}

const startTimeInputId = "jobInstanceStartTime";

// Note: This form only supports recurring jobs for now! See note in useEffect
// where fullStoryLogError is called
export function JobInstanceTimeForm({
  customerId,
  jobInstanceId,
  jobType,
  onSaveComplete,
  onCancel,
}: IProps) {
  const { control, setValue, getValues, watch } = useForm<IFormData>({
    defaultValues: {
      startTime: "",
      endTime: "",
      arrivalWindowDurationMinutes: null,
    },
  });
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");
  const dispatch = useDispatch();
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );

  const { completeJobInstance, dayScheduleForCompleteJobInstance } =
    getJobInstance(daySchedules, jobInstanceId);

  const haveValuesDefaulted = useRef(false);
  useEffect(() => {
    if (!haveValuesDefaulted.current) {
      setValue("startTime", completeJobInstance.startTime ?? "");
      setValue("endTime", completeJobInstance.endTime ?? "");
      setValue(
        "arrivalWindowDurationMinutes",
        completeJobInstance.arrivalWindowDurationMinutes ?? null
      );

      if (jobType === JobType.oneTimeJob) {
        // Need to add real-time updates for one time jobs and update to onetimejobs redux store to support
        // one time jobs. Not used for now so not supporting. If this changes, update header comment on function.
        fullStoryLogError(
          "JobInstanceTimeForm does not support one time jobs and was incorrectly loaded for that job type"
        );
      }

      setTimeout(() => {
        const startTimeElement = document.getElementById(startTimeInputId);
        if (startTimeElement) {
          startTimeElement.focus();
        }
      });

      haveValuesDefaulted.current = true;
    }
  }, [
    jobInstanceId,
    jobType,
    completeJobInstance.startTime,
    completeJobInstance.endTime,
    completeJobInstance.arrivalWindowDurationMinutes,
    setValue,
  ]);

  const startTime = watch("startTime");
  const endTime = watch("endTime");
  const arrivalWindowDurationMinutes = watch("arrivalWindowDurationMinutes");

  return (
    <FormContainerWithoutRedux
      formHeader={`Update Job Card Time`}
      formType={FormTypesV2.jobInstanceTimeForm}
      save={() => {
        const values = getValues();
        const payload = {
          startTime: dateService.formatTimeForSerialization(values.startTime),
          endTime: dateService.formatTimeForSerialization(values.endTime),
          arrivalWindowDurationMinutes: values.arrivalWindowDurationMinutes,
        };
        return remoteDataProvider
          .saveJobInstance(payload, { jobInstanceId })
          .pipe(map(() => payload));
      }}
      onSaveComplete={(payload) => {
        onSaveComplete();
        dispatch(
          actionCreators.jobInstanceTimeUpdated(
            jobInstanceId,
            payload.startTime,
            payload.endTime,
            payload.arrivalWindowDurationMinutes
          )
        );
      }}
      onCancel={onCancel}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      showForm
    >
      <div className="form-row">
        <div className="form-group col-6">
          <label htmlFor={startTimeInputId} className="required">
            Start time
          </label>
          <Controller
            control={control}
            name="startTime"
            render={({ field }) => (
              <TimeInput
                className="form-control"
                data-testid="startTime"
                value={field.value}
                required
                id={startTimeInputId}
                onChange={(time) => {
                  field.onChange(time);
                }}
              />
            )}
          />
        </div>
        <div className="form-group col-6">
          <label htmlFor="endTime" className="required">
            End time
          </label>
          <Controller
            control={control}
            name="endTime"
            render={({ field }) => (
              <TimeInput
                className="form-control"
                data-testid="endTime"
                value={field.value}
                required
                id="endTime"
                onChange={(time) => {
                  field.onChange(time);
                }}
              />
            )}
          />
        </div>
      </div>
      <JobConflictAlert
        crewId={dayScheduleForCompleteJobInstance.crewId}
        date={dayScheduleForCompleteJobInstance.date}
        startTime={startTime}
        endTime={endTime}
        jobInstanceId={jobInstanceId}
      />
      <div className="form-row">
        <div className="form-group col-12 col-md-6">
          <JobArrivalWindowSelection
            isCrewTimeBased={true}
            startTime={startTime}
            endTime={endTime}
            arrivalWindowDurationMinutes={arrivalWindowDurationMinutes}
            onArrivalWindowChange={(newValue) =>
              setValue("arrivalWindowDurationMinutes", newValue)
            }
          />
        </div>
      </div>
    </FormContainerWithoutRedux>
  );
}
