import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import {
  builders as routerBuilders,
  parsers as routerParsers,
} from "../../../services/routing";
import { Link, RouteComponentProps } from "react-router-dom";
import { IRootState } from "../../../store";
import { ICrew } from "../../../models/ICrew";
import { withRouter } from "react-router-dom";

// TODO: Use 'lib' imports when support for UncontrolledDropdown is added.  This will allow not pulling in the full reactstrap library.
import { Navbar as BootstrapNavbar, Nav, NavItem } from "reactstrap";
import dateService from "../../../services/dateService";
import { Auth0Context } from "../../../services/AuthContext";
import { isMobileOnly } from "react-device-detect";
import NavbarNavigation, { navBarButtonStyle } from "./NavbarNavigation";
import NavbarAddButtons from "./NavbarAddButtons";
import logo from "../../../cc_icon_header.png";
import TenantSubscriptionStatusAlert from "../../../slices/tenantSubscription/components/TenantSubscriptionStatusAlert";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { startOfWeek } from "date-fns";
import { TollFreeVerificationAlert } from "../../../slices/tenantSubscription/components/TollFreeVerificationAlert";
import { useUserSettings } from "../../../services/userSettingsService";
import auth from "../../../services/Auth";

const styles = {
  nonMobileTopLevelButtonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
    marginBottom: "0px",
  },
};

interface IRouteParams {
  crewId?: string;
  date?: string;
}

interface IProps extends IOwnProps, RouteComponentProps<IRouteParams> {
  crews: Array<ICrew>;
  tenantName: string | null;
  isRegistered: boolean;
}

interface IOwnProps {
  subHeaderLeftSideContent?: React.ReactNode;
  subHeaderRightSideContent?: React.ReactNode;
  billingContext?: boolean;
  hideShiftSchedule?: boolean;
  hideNavBarNavigation?: boolean;
}

const Navbar: React.FunctionComponent<IProps> = ({
  crews,
  tenantName,
  subHeaderLeftSideContent,
  subHeaderRightSideContent,
  billingContext,
  match,
  hideShiftSchedule,
  isRegistered,
  hideNavBarNavigation,
}) => {
  const [isNavigationExpanded, setIsNavigationExpanded] = useState(false);
  const routerState = useApplicationStateSelector((s) => s.router);
  const { getUserSettings } = useUserSettings();

  let crewIdForScheduleLink: string | undefined = match.params.crewId;
  if (routerParsers.report.tryParseDailyMap(routerState).isMatch) {
    crewIdForScheduleLink = undefined;
  }

  return (
    <Auth0Context.Consumer>
      {(authContext) => {
        const showPrimaryLinks =
          crews.length > 0 && authContext.isAuthenticated;

        return !isMobileOnly ? (
          <Fragment>
            <BootstrapNavbar expand="md" id="navbar-heading" light>
              <Fragment>
                {!!tenantName && authContext.isAuthenticated ? (
                  <Link
                    className="navbar-brand text-truncate"
                    to={routerBuilders.schedule.buildWeekRoute(
                      getUserSettings,
                      crews,
                      dateService.formatAsIso(startOfWeek(new Date())),
                      crewIdForScheduleLink
                    )}
                  >
                    <img src={logo} alt="Crew Control Logo" />
                    <span className="ml-2">
                      <span id="tenant-name">{tenantName}</span>
                    </span>
                  </Link>
                ) : (
                  <span className="navbar-brand">Crew Control</span>
                )}

                {!hideNavBarNavigation ? (
                  <NavbarNavigation
                    showSettings={isRegistered}
                    showPrimaryLinks={showPrimaryLinks}
                    crewId={crewIdForScheduleLink}
                    isAuthenticated={authContext.isAuthenticated}
                  />
                ) : (
                  <Logout isAuthenticated={authContext.isAuthenticated} />
                )}
              </Fragment>
            </BootstrapNavbar>

            {!hideNavBarNavigation ? (
              <TenantSubscriptionStatusAlert containerClassName="navbar navbar-light d-xl-none" />
            ) : null}

            <TollFreeVerificationAlert />
            {!billingContext ? (
              <div
                style={styles.nonMobileTopLevelButtonsContainer}
                className={"d-print-none"}
              >
                <div style={{ flexGrow: 2, flexBasis: 0 }}>
                  {subHeaderLeftSideContent}
                </div>

                {crews.length > 0 && authContext.isAuthenticated ? (
                  <div style={{ flexGrow: 2, flexBasis: 0 }}>
                    <NavbarAddButtons
                      hideShiftSchedule={hideShiftSchedule}
                      customContent={subHeaderRightSideContent}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </Fragment>
        ) : (
          <Fragment>
            <BootstrapNavbar expand="md" id="navbar-heading" light>
              <Fragment>
                {!hideNavBarNavigation ? (
                  <NavbarNavigation
                    onNavigationExpanded={(expanded) =>
                      setIsNavigationExpanded(expanded)
                    }
                    showSettings={isRegistered}
                    showPrimaryLinks={showPrimaryLinks}
                    crewId={match.params.crewId}
                    isAuthenticated={authContext.isAuthenticated}
                  />
                ) : null}
              </Fragment>

              {!isNavigationExpanded ? (
                <img src={logo} alt="Crew Control Logo" />
              ) : null}

              {hideNavBarNavigation ? (
                <Logout isAuthenticated={authContext.isAuthenticated} />
              ) : null}

              {!isNavigationExpanded ? (
                <div className={"d-print-none"}>
                  {!billingContext ? (
                    <Fragment>
                      {crews.length > 0 && authContext.isAuthenticated ? (
                        <NavbarAddButtons
                          hideShiftSchedule={hideShiftSchedule}
                        />
                      ) : null}
                    </Fragment>
                  ) : null}
                </div>
              ) : null}
            </BootstrapNavbar>
            {!hideNavBarNavigation ? (
              <TenantSubscriptionStatusAlert containerClassName="navbar navbar-light d-xl-none" />
            ) : null}

            <TollFreeVerificationAlert />
            {!billingContext ? (
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {subHeaderLeftSideContent}
              </div>
            ) : null}
          </Fragment>
        );
      }}
    </Auth0Context.Consumer>
  );
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => ({
  crews: state.crew.crews,
  tenantName: state.common.tenantName,
  isRegistered: state.common.isRegistered,
  subHeaderLeftSideContent: ownProps.subHeaderLeftSideContent,
  subHeaderRightSideContent: ownProps.subHeaderRightSideContent,
  billingContext: ownProps.billingContext,
  hideNavBarNavigation: ownProps.hideNavBarNavigation,
});

const mapDispatchToProps = {
  showCrewForm: actionCreators.forms.crew.showForm,
  showMaintenanceJobForm: actionCreators.forms.maintenanceJob.showForm,
  showOneTimeJobForm: actionCreators.forms.oneTimeJob.showForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));

function Logout({ isAuthenticated }: { isAuthenticated: boolean }) {
  return isAuthenticated ? (
    <Nav className="ml-auto">
      <NavItem>
        <button
          style={navBarButtonStyle}
          className="nav-link"
          type="button"
          onClick={() => auth.logout()}
        >
          Logout
        </button>
      </NavItem>
    </Nav>
  ) : null;
}
