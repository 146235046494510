import React, { useRef, useState } from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { Controller, useForm, UseFormSetValue } from "react-hook-form";
import { useLoadCustomers } from "../../../hooks/useLoadCustomers";
import Spinner from "../components/Spinner";
import Alert from "../components/Alert";
import FormContainerWithoutRedux from "./FormContainerWithoutRedux";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import { Observable } from "rxjs";
import { SendType } from "../../../enums/sendType";
import PhoneNumberField from "./PhoneNumberField";
import { EmailSendFields, EmailSendValue } from "./EmailSendFields";
import {
  createEmailAddressRecord,
  createEmailAddressRecordsForCustomer,
  getEmailAddressesForSave,
} from "./EmailAddresses.functions";
import { IEmailAddressRecord } from "./EmailAddresses";
import SmsOptInCheckbox from "./SmsOptInCheckbox";
import { ICustomer } from "../../../models/ICustomer";
import { getOptInStatusAfterNumberChange } from "../../../services/phoneNumberService";
import { ErrorMessageType } from "./FormContainer";

interface IProps<TSaveResponse> {
  customerId: string;
  formHeader: string;
  formType: FormTypesV2;
  save: (formData: ISavePayload) => Observable<TSaveResponse>;

  onSaveComplete: () => void;
  onCancel: () => void;

  disableTextDeliveryMethod?: boolean;
  defaultEmailAddresses?: Array<string>;
  defaultReplyToEmailAddress?: string | null;
}

interface IFormData {
  sendType: string;
  emailFields: EmailSendValue;
  phoneNumber: string;
  phoneNumberOptedIntoSms: boolean;
}

interface ISavePayload {
  sendType: SendType;
  emailAddresses: Array<string>;
  replyToEmailAddress: string;
  phoneNumber: string;
  phoneNumberOptedIntoSms: boolean;
}

const SendFormBase = <TSaveResponse,>({
  customerId,
  formHeader,
  formType,
  save,
  onCancel,
  onSaveComplete,
  disableTextDeliveryMethod,
  defaultEmailAddresses,
  defaultReplyToEmailAddress,
}: IProps<TSaveResponse>) => {
  const { register, getValues, setValue, watch, control } = useForm<IFormData>({
    defaultValues: getEmptyFormData(),
  });
  const sendType = watch("sendType");
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");
  const [phoneNumberOptInDirty, setPhoneNumberOptInDirty] = useState(false);

  const [originalFormData, setOriginalFormData] = useState<IFormData>(
    getEmptyFormData()
  );

  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const userEmailAddress = useApplicationStateSelector(
    (s) => s.common.userAccountEmail
  );
  const customer = customers.find((c) => c.id === customerId);

  const { loading, errorLoading } = useLoadCustomers([customerId]);

  const customerTextingAllowed = useApplicationStateSelector(
    (s) => s.common.optionalCapabilitiesAllowed.customerTexting
  );

  useSetDefaultValues({
    customer,
    defaultEmailAddresses,
    defaultReplyToEmailAddress,
    userEmailAddress,
    setValue,
    setOriginalFormData,
  });

  const phoneNumber = watch("phoneNumber");

  if (errorLoading) {
    return (
      <Alert
        message="Unable to load customer data."
        closeForm={() => onCancel()}
      />
    );
  } else if (loading) {
    return <Spinner />;
  }

  return (
    <FormContainerWithoutRedux
      formHeader={formHeader}
      formType={formType}
      save={() => {
        const values = getValues();
        return save({
          phoneNumber: values.phoneNumber,
          phoneNumberOptedIntoSms: values.phoneNumberOptedIntoSms,
          emailAddresses: getEmailAddressesForSave(
            values.emailFields.recipients
          ),
          replyToEmailAddress: values.emailFields.replyTo,
          sendType: parseInt(values.sendType) as SendType,
        });
      }}
      hasFormDataChanged={() => {
        return JSON.stringify(getValues()) !== JSON.stringify(originalFormData);
      }}
      onSaveComplete={() => {
        onSaveComplete();
      }}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      onCancel={onCancel}
      saveButtonText="Send"
    >
      {!disableTextDeliveryMethod && customerTextingAllowed ? (
        <div className="form-group">
          <label htmlFor="sendType">How do you want to send?</label>
          <select
            id="sendType"
            className="form-control"
            {...register("sendType")}
          >
            <option value={SendType.email.toString()}>Email</option>
            <option value={SendType.text.toString()}>Text</option>
          </select>
        </div>
      ) : null}

      {sendType === SendType.email.toString() ? (
        <Controller
          name="emailFields"
          control={control}
          render={({ field }) => (
            <EmailSendFields value={field.value} onChange={field.onChange} />
          )}
        />
      ) : null}

      {sendType === SendType.text.toString() ? (
        <>
          <div className="form-group">
            <label htmlFor="phoneNumber" className="required">
              Phone number
            </label>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <PhoneNumberField
                  id="phoneNumber"
                  className="form-control"
                  required
                  {...field}
                  onChange={(e) => {
                    const newValue = e.currentTarget.value;
                    field.onChange(newValue);

                    setValue(
                      "phoneNumberOptedIntoSms",
                      getOptInStatusAfterNumberChange({
                        currentOptInStatus: getValues(
                          "phoneNumberOptedIntoSms"
                        ),
                        newNumber: newValue,
                        originalNumber: originalFormData.phoneNumber,
                        originalOptInStatus:
                          originalFormData.phoneNumberOptedIntoSms,
                        phoneNumberOptInDirty,
                      })
                    );
                  }}
                />
              )}
            />
          </div>
          <Controller
            name="phoneNumberOptedIntoSms"
            control={control}
            render={({ field }) => (
              <SmsOptInCheckbox
                checked={field.value}
                phoneNumber={phoneNumber}
                onCheckedChange={(newValue) => {
                  field.onChange(newValue);

                  setPhoneNumberOptInDirty(true);
                }}
              />
            )}
          />
        </>
      ) : null}
    </FormContainerWithoutRedux>
  );
};

export default SendFormBase;

function useSetDefaultValues({
  customer,
  defaultEmailAddresses,
  defaultReplyToEmailAddress,
  userEmailAddress,
  setValue,
  setOriginalFormData,
}: {
  customer: ICustomer | undefined;
  defaultEmailAddresses: string[] | undefined;
  defaultReplyToEmailAddress: string | null | undefined;
  userEmailAddress: string | null;
  setValue: UseFormSetValue<IFormData>;
  setOriginalFormData: React.Dispatch<React.SetStateAction<IFormData>>;
}) {
  const hasInitializedForm = useRef(false);
  if (customer && !hasInitializedForm.current) {
    let emailAddresses: Array<IEmailAddressRecord>;
    if (defaultEmailAddresses && defaultEmailAddresses.length > 0) {
      emailAddresses = defaultEmailAddresses.map((e) =>
        createEmailAddressRecord(e)
      );
    } else {
      emailAddresses = createEmailAddressRecordsForCustomer(customer);
    }

    const emailFieldsForInitialization = {
      recipients: emailAddresses,
      replyTo: defaultReplyToEmailAddress ?? userEmailAddress ?? "",
    };

    const updateFormData = (existingData: IFormData) => ({
      ...existingData,
      emailFields: emailFieldsForInitialization,
      phoneNumber: customer.phoneNumber,
      phoneNumberOptedIntoSms: customer.phoneNumberOptedIntoSms,
    });

    setValue("emailFields", emailFieldsForInitialization);
    setValue("phoneNumber", customer.phoneNumber);
    setValue("phoneNumberOptedIntoSms", customer.phoneNumberOptedIntoSms);
    setOriginalFormData(updateFormData);

    hasInitializedForm.current = true;
  }
}

function getEmptyFormData(): IFormData {
  return {
    sendType: SendType.email.toString(),
    emailFields: {
      recipients: [createEmailAddressRecord("")],
      replyTo: "",
    },
    phoneNumber: "",
    phoneNumberOptedIntoSms: false,
  };
}
