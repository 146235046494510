import AddressFields from "./AddressFields";
import {
  BusinessType,
  IOnboardingFormData,
  IPrincipal,
} from "./IOnboardingFormData";
import DayPicker, { format as dayPickerFormat } from "../DayPicker";
import { addDays, format } from "date-fns";
import dateService from "../../../../services/dateService";
import { forwardRef, useEffect, useRef } from "react";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import InfoToolTip from "../InfoToolTip";
import PhoneNumberField, {
  IProps as IPhoneNumberFieldProps,
  isValid as isPhoneNumberValid,
  phoneNumberValidationErrorMessage,
} from "../PhoneNumberField";
import NumberFormat from "react-number-format";

interface IProps {
  index: number;
  register: UseFormRegister<IOnboardingFormData>;
  control: Control<IOnboardingFormData, object>;
  setValue: UseFormSetValue<IOnboardingFormData>;
  getValues: UseFormGetValues<IOnboardingFormData>;
}

const AboutOwnerTabPrincipal: React.FunctionComponent<IProps> = ({
  control,
  register,
  index,
  setValue,
  getValues,
}) => {
  const principalId = useWatch({
    control,
    name: `principals.${index}.id` as const,
  });
  const businessType = useWatch({
    control,
    name: "businessType",
  });

  const dobLabelRef = useRef<HTMLLabelElement>(null);
  const ssnRef = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <div className="form-row">
        <div className="col form-group">
          <label
            htmlFor={getElementId("firstName", principalId)}
            className="required"
          >
            First name
          </label>
          <input
            maxLength={100}
            type="text"
            className="form-control"
            id={getElementId("firstName", principalId)}
            required
            {...register(`principals.${index}.firstName` as const)}
          />
        </div>
        <div className="col form-group">
          <label
            htmlFor={getElementId("lastName", principalId)}
            className="required"
          >
            Last name
          </label>
          <input
            maxLength={100}
            type="text"
            className="form-control"
            id={getElementId("lastName", principalId)}
            required
            {...register(`principals.${index}.lastName` as const)}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col form-group">
          <label
            htmlFor={getElementId("ssn", principalId)}
            className="required"
          >
            SSN
          </label>
          <InfoToolTip
            id="ssnToolTip"
            text="Social Security Number is used to verify applicant identity and protect against illicit activities."
          />
          <Controller
            name={`principals.${index}.ssn` as const}
            control={control}
            render={({ field }) => (
              <NumberFormat
                id={getElementId("ssn", principalId)}
                placeholder="xxx-xx-xxxx"
                className="form-control fs-mask"
                format="###-##-####"
                mask="_"
                required
                inputMode="numeric"
                getInputRef={(el: HTMLInputElement) => {
                  ssnRef.current = el;
                }}
                {...field}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.currentTarget.value?.trim() ?? "";
                  if (ssnRef.current) {
                    const validateRegex = /^\d{3}-\d{2}-\d{4}/;
                    const ssnInvalid =
                      value && !validateRegex.test(value.trim());
                    ssnRef.current.setCustomValidity(
                      ssnInvalid ? "SSN is not valid" : ""
                    );
                  }

                  if (field.onChange) {
                    field.onChange(e);
                  }
                }}
              />
            )}
          />
        </div>
        <div className="col form-group">
          <label
            htmlFor={getElementId("dob", principalId)}
            className="required"
            ref={dobLabelRef}
          >
            DOB
          </label>

          <Controller
            name={`principals.${index}.dob` as const}
            control={control}
            render={({ field }) => (
              <DayPicker
                preventMobileView={true}
                onDayPickerHide={() => null}
                maxDate={addDays(new Date(), -1)}
                inputClass=""
                dayPickerProps={{}}
                required={true}
                inputId={getElementId("dob", principalId)}
                labelRef={dobLabelRef}
                value={
                  !!field.value ? format(field.value, dayPickerFormat) : ""
                }
                onDaySelected={(day: Date) => {
                  if (!!day) {
                    field.onChange(dateService.formatAsIso(day));
                  } else {
                    field.onChange("");
                  }
                }}
              />
            )}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col form-group">
          <label
            htmlFor={getElementId("businessTitle", principalId)}
            className="required"
          >
            Business title
          </label>
          <input
            maxLength={100}
            type="text"
            className="form-control"
            id={getElementId("businessTitle", principalId)}
            required
            {...register(`principals.${index}.businessTitle` as const)}
          />
        </div>

        {businessType !== BusinessType.Gov ? (
          <div className="col form-group">
            <label
              htmlFor={getElementId("ownershipPercent", principalId)}
              className="required"
            >
              Ownership %
            </label>
            <input
              min={0}
              max={100}
              type="number"
              className="form-control"
              id={getElementId("ownershipPercent", principalId)}
              required
              {...register(`principals.${index}.ownershipPercent` as const)}
            />
          </div>
        ) : null}
      </div>

      <div className="form-row">
        <div className="col form-group">
          <div
            className="custom-control custom-checkbox"
            style={{ display: "inline-block" }}
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id={getElementId("significantResponsibility", principalId)}
              {...register(
                `principals.${index}.significantResponsibility` as const
              )}
            />
            <label
              className="custom-control-label"
              htmlFor={getElementId("significantResponsibility", principalId)}
            >
              Has significant responsibility?
            </label>
          </div>
          <InfoToolTip
            id={getElementId("significantResponsibilityTooltip", principalId)}
            text="Is the primary owner a controlling authority for this business? This includes, but is not limited to: CEO, CFO, Owner, VP, managing member etc."
          />
        </div>
      </div>

      <div className="my-2">
        <button
          type="button"
          className="btn btn-link"
          style={{ padding: 0 }}
          onClick={() => {
            setValue(
              `principals.${index}.address` as const,
              getValues("address")
            );
            setValue(
              `principals.${index}.phone` as const,
              getValues("daytimePhone")
            );
            setValue(
              `principals.${index}.email` as const,
              getValues("businessEmail")
            );
          }}
        >
          Copy from business information
        </button>
      </div>

      <Controller
        name={`principals.${index}.address` as const}
        control={control}
        render={({ field }) => (
          <AddressFields
            idPrefix={`principal_${principalId}`}
            address={field.value}
            onChange={(newAddress) => field.onChange(newAddress)}
            invalidAddressRegEx={
              new RegExp(
                "\\b(?:p\\.?\\s*o\\.?|post\\s+office)(\\s+)?(?:box|[0-9]*)?\\b",
                "i"
              )
            }
            invalidAddressMessage="The address cannot contain a PO Box. Please provide the physical location."
          />
        )}
      />

      <div className="form-row">
        <div className="col form-group">
          <label
            htmlFor={getElementId("email", principalId)}
            className="required"
          >
            Email
          </label>
          <input
            maxLength={100}
            type="email"
            className="form-control"
            id={getElementId("email", principalId)}
            required
            {...register(`principals.${index}.email` as const)}
          />
        </div>
        <div className="col form-group">
          <label
            htmlFor={getElementId("phone", principalId)}
            className="required"
          >
            Phone
          </label>
          <Controller
            name={`principals.${index}.phone` as const}
            control={control}
            render={({ field }) => (
              <PhoneNumberFieldWithValidationOnSet
                id={getElementId("phone", principalId)}
                className="form-control"
                required
                {...field}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default AboutOwnerTabPrincipal;

function getElementId(
  prefix:
    | keyof IPrincipal
    | "politicallyExposedTooltip"
    | "significantResponsibilityTooltip",
  principalId: string
) {
  return `${prefix}_${principalId}`;
}

// Only for Principal Phone do we need to validate when the value is set. This is due to the
// "Copy from business information" button that could set an invalid phone number without
// user entering the value in this specific field.
const PhoneNumberFieldWithValidationOnSet: React.FunctionComponent<IPhoneNumberFieldProps> =
  forwardRef<HTMLInputElement, IPhoneNumberFieldProps>((props, ref) => {
    const { value } = props;

    const internalRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      if (typeof value === "string" && value && internalRef.current) {
        internalRef.current.setCustomValidity(
          !isPhoneNumberValid(value) ? phoneNumberValidationErrorMessage : ""
        );
      }
    }, [value]);

    return <PhoneNumberField ref={internalRef} {...props} />;
  });
