import { useReactToPrint } from "react-to-print";
import { useCallback, useEffect, useRef, useState } from "react";
import Alert from "../../../containers/app/components/Alert";
import { Subscription } from "rxjs";
import Spinner from "../../../containers/app/components/Spinner";
import { timeout } from "rxjs/operators";
import proposalDataProvider from "../services/proposalDataProvider";
import { IProposalForPrint } from "../models/IProposalForPrint";
import ProposalPrint from "./ProposalPrint";

interface IProps {
  proposalId: string;
  onClose(): void;
}

const ProposalPrintContainer: React.FunctionComponent<IProps> = ({
  proposalId,
  onClose,
}) => {
  const printHandler = useReactToPrint({
    content: () => printContainer.current,
    removeAfterPrint: true,
    pageStyle: "@page {size: letter portrait; margin: .5in .5in .5in .5in;}",
  });
  const printContainer = useRef<HTMLDivElement>(null);

  const [proposal, setProposal] = useState<IProposalForPrint | null>(null);
  const [errorLoading, setErrorLoading] = useState(false);

  useEffect(() => {
    setErrorLoading(false);
    setProposal(null);
  }, [proposalId]);

  const onLoadComplete = useCallback(() => {
    printHandler();
    onClose();
  }, [printHandler, onClose]);

  useEffect(() => {
    let subscription: Subscription | null;
    if (proposalId) {
      subscription = proposalDataProvider
        .getProposalsForPrint([proposalId])
        .pipe(timeout(5000))
        .subscribe({
          next: (i) => {
            setProposal(i[0]);
          },

          error: () => {
            setErrorLoading(true);
          },
        });
    }

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [proposalId]);

  if (errorLoading) {
    return (
      <Alert
        message="Unable to load the proposal to print."
        closeForm={() => onClose()}
      />
    );
  } else if (!proposal) {
    return <Spinner />;
  } else {
    return (
      <div ref={printContainer} className="billing-item-print-container">
        <ProposalPrint proposal={proposal} onLoadComplete={onLoadComplete} />
      </div>
    );
  }
};

export default ProposalPrintContainer;
