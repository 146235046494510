import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProposalExisting } from "../models/IProposal";

export interface IProposalState {
  proposals: Array<IProposalExisting>;
}

const initialState: IProposalState = {
  proposals: [],
};

export interface ISaveProposalAction {
  proposal: IProposalExisting;
  // Customer fields used for customer reducer to update customer default if set
  customerId: string;
  customerPhoneNumber: string | null;
  customerEmailAddresses: Array<string> | null;
  customerPhoneNumberOptedIntoSms: boolean | null;
}

const { reducer, actions } = createSlice({
  initialState,
  name: "proposal",
  reducers: {
    addProposal: (state, action: PayloadAction<ISaveProposalAction>) => {
      state.proposals.push(action.payload.proposal);
    },

    editProposal: (state, action: PayloadAction<ISaveProposalAction>) => {
      state.proposals = [
        ...state.proposals.filter((p) => p.id !== action.payload.proposal.id),
        action.payload.proposal,
      ];
    },

    loadProposalsCompleted: (
      state,
      action: PayloadAction<{ proposals: Array<IProposalExisting> }>
    ) => {
      state.proposals = [
        ...state.proposals.filter(
          (existingProposal) =>
            !action.payload.proposals.some(
              (newProposal) => existingProposal.id === newProposal.id
            )
        ),
        ...action.payload.proposals,
      ];
    },

    reloadProposalCompleted: (
      state,
      action: PayloadAction<{ proposal: IProposalExisting }>
    ) => {
      state.proposals = [
        ...state.proposals.filter((o) => o.id !== action.payload.proposal.id),
        action.payload.proposal,
      ];
    },

    // Just used for actions that are fed to epic
    reloadProposal: (
      state,
      action: PayloadAction<{ proposalId: string }>
    ) => {},
  },
});

export const proposalsActionCreators = actions;

export default reducer;
