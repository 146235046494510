import { actionTypes } from "./actionCreators";
import {
  IDropJobAction,
  IJobDeleteComplete,
  IOneTimeJobLoadStart,
  IOneTimeJobLoadComplete,
  IMaintenanceJobLoadComplete,
  IShiftScheduleCompleteAggregate,
  IPermanentJobsSaveComplete,
  IAutoRouteJobsPermanentCompleted,
  ILoadDaySchedulesCompletedAction,
  ICustomerClearJobsComplete,
  IJobInstanceTimeUpdated,
} from "./actionTypeDefinitions";
import {
  formTypes,
  actionTypes as formActionTypes,
  createSpecificActionTypeName,
} from "../formGenerator";
import { IMaintenanceJob } from "../models/IMaintenanceJob";
import { IOneTimeJob } from "../models/IOneTimeJob";
import dateService from "../services/dateService";
import { projectActionCreators } from "../slices/schedule/modules/project";
import { ICachedItem } from "../models/ICachedItem";

export type CachedMaintenanceJob = IMaintenanceJob & ICachedItem;
export type CachedOneTimeJob = IOneTimeJob & ICachedItem;

export interface IJobState {
  jobs: Array<CachedMaintenanceJob>;
  oneTimeJobs: Array<CachedOneTimeJob>;
  jobsLoading: Array<string>;
}

export default (state: IJobState | undefined, action: any): IJobState => {
  if (!state) {
    state = {
      jobs: [],
      oneTimeJobs: [],
      jobsLoading: [],
    };
  }

  if (projectActionCreators.removeProjects.match(action)) {
    return {
      ...state,
      oneTimeJobs: state.oneTimeJobs.filter(
        (j) =>
          typeof j.projectId !== "string" ||
          !action.payload.projectIds.includes(j.projectId)
      ),
    };
  }

  switch (action.type) {
    case createSpecificActionTypeName(
      formTypes.crewNotes,
      formActionTypes.completeSaving
    ):
      return {
        ...state,
        oneTimeJobs: state.oneTimeJobs.map((j) => {
          if (j.id === action.payload.parameters.jobInstanceId) {
            return {
              ...j,
              todoTemplateId: action.payload.response.todoTemplateId,
              todoItems: action.payload.response.todoItems,
              photos: action.payload.response.photos,
              highlightCrewNotes: action.payload.response.highlightCrewNotes,
              showCrewNotesOnAdminJobCards:
                action.payload.response.showCrewNotesOnAdminJobCards,
              notes: action.payload.response.jobNotes,
              administratorOnlyNotes:
                action.payload.response.administratorOnlyNotes,
            };
          } else {
            return j;
          }
        }),
      };

    case createSpecificActionTypeName(
      formTypes.oneTimeJob,
      formActionTypes.completeSaving
    ):
      let isUpdateSave = false;
      let oneTimeJobs = state.oneTimeJobs.map((j) => {
        if (j.id === action.payload.id) {
          isUpdateSave = true;
          return { ...j, ...action.payload };
        } else {
          return j;
        }
      });

      if (!isUpdateSave) {
        const savedJobRecords = action.payload.savedJobs;
        oneTimeJobs = [
          ...oneTimeJobs,
          ...(savedJobRecords
            ?.filter(
              (savedJobRecord: any) =>
                !oneTimeJobs.some(
                  (existingOneTimeJob) =>
                    existingOneTimeJob.id === savedJobRecord.createdId
                )
            )
            ?.map((savedJobRecord: any) => {
              const payloadWithoutSavedJobs = { ...action.payload };
              delete payloadWithoutSavedJobs.savedJobs;

              const uniqueFields = { ...savedJobRecord };
              delete uniqueFields.createdId;

              const result = {
                // Add common fields like Notes, Date, etc.
                ...payloadWithoutSavedJobs,
                // Add ID
                id: savedJobRecord.createdId,
                // Add any customized fields like customer ID, photos, todo items, etc
                ...uniqueFields,
              };

              return result;
            }) ?? []),
        ];
      }

      return {
        ...state,
        oneTimeJobs,
      };

    case createSpecificActionTypeName(
      formTypes.maintenanceJob,
      formActionTypes.completeSaving
    ):
      let jobs;
      if (
        !action.payload.parameters ||
        !action.payload.parameters.maintenanceJobId
      ) {
        jobs = [
          ...state.jobs,
          {
            ...action.payload,
          },
        ];
      } else {
        jobs = state.jobs.map((j) => {
          if (j.id === action.payload.parameters.maintenanceJobId) {
            return {
              ...action.payload,
            };
          } else {
            return j;
          }
        });
      }

      return {
        ...state,
        jobs: [
          ...jobs.map((j) => {
            const updatedJob = action.payload.updatedJobs.find(
              (updatedJob: IMaintenanceJob) => updatedJob.id === j.id
            );
            return {
              ...j,
              order: updatedJob ? updatedJob.order : j.order,
            };
          }),
        ],
      };

    case actionTypes.PERMANENT_JOBS_SAVE_COMPLETE:
      let permanentJobsSaveCompleteAction =
        action as IPermanentJobsSaveComplete;

      return {
        ...state,
        jobs: state.jobs.filter(
          (existingJob) =>
            !permanentJobsSaveCompleteAction.movedMaintenanceJobIds.some(
              (savedJobId) => savedJobId === existingJob.id
            )
        ),
      };

    case actionTypes.AUTO_ROUTE_JOBS_PERMANENT_SAVE_COMPLETED:
      let autoRoutePermanentCompleteAction =
        action as IAutoRouteJobsPermanentCompleted;

      return {
        ...state,
        jobs: state.jobs.filter(
          (existingJob) =>
            !autoRoutePermanentCompleteAction.maintenanceJobIds.some(
              (savedJobId) => savedJobId === existingJob.id
            )
        ),
      };

    case createSpecificActionTypeName(
      formTypes.moveJobInstance,
      formActionTypes.completeSaving
    ):
      const moveJobUpdatedMaintenanceJobIds = action.payload
        .updatedMaintenanceJobIds as Array<string>;
      const movedJobInstanceIds = action.parameters
        .jobInstanceIds as Array<string>;

      return {
        ...state,
        jobs: state.jobs.filter(
          (existingJob) =>
            !moveJobUpdatedMaintenanceJobIds.some(
              (savedJobId) => savedJobId === existingJob.id
            )
        ),
        oneTimeJobs: state.oneTimeJobs.map((oneTimeJob) => {
          if (movedJobInstanceIds.some((i) => i === oneTimeJob.id)) {
            return {
              ...oneTimeJob,
              date: action.payload.destinationDate,
              crewId: action.payload.destinationCrewId,
              flexibleJob: false,
            };
          } else {
            return oneTimeJob;
          }
        }),
      };

    case actionTypes.DROP_JOB:
      return applyDropJobAction(action as IDropJobAction, state);

    case actionTypes.JOB_DELETE_COMPLETE:
      const jobDeleteAction = action as IJobDeleteComplete;
      return {
        ...state,
        oneTimeJobs: state.oneTimeJobs.filter(
          (c) => c.id !== jobDeleteAction.jobId
        ),
        jobs: state.jobs.filter((c) => c.id !== jobDeleteAction.jobId),
      };

    case actionTypes.CUSTOMER_CLEAR_JOBS_COMPLETE:
      const customerClearJobsCompleteAction =
        action as ICustomerClearJobsComplete;
      return {
        ...state,
        oneTimeJobs: state.oneTimeJobs.filter(
          (c) =>
            !customerClearJobsCompleteAction.removedJobInstances.includes(c.id)
        ),
      };

    case actionTypes.ONE_TIME_JOB_LOAD_START:
      const oneTimeJobLoadStartAction = action as IOneTimeJobLoadStart;
      const hasNewJobIds = oneTimeJobLoadStartAction.jobIds.reduce(
        (newItemFound, jobId) =>
          newItemFound ||
          (state as IJobState).jobsLoading.indexOf(jobId) === -1,
        false
      );

      return {
        ...state,
        jobsLoading: hasNewJobIds
          ? [
              ...state.jobsLoading,
              ...oneTimeJobLoadStartAction.jobIds.filter(
                (j) => (state as IJobState).jobsLoading.indexOf(j) === -1
              ),
            ]
          : state.jobsLoading,
      };

    case actionTypes.ONE_TIME_JOB_LOAD_COMPLETE:
      const oneTimeJobLoadCompleteAction = action as IOneTimeJobLoadComplete;

      return {
        ...state,
        jobsLoading: state.jobsLoading.filter(
          (jobLoadingId) =>
            !oneTimeJobLoadCompleteAction.oneTimeJobs.find(
              (oneTimeJob) => oneTimeJob.id === jobLoadingId
            )
        ),
        oneTimeJobs: [
          ...state.oneTimeJobs.filter(
            (existingJob) =>
              !oneTimeJobLoadCompleteAction.oneTimeJobs.some(
                (newJob) => existingJob.id === newJob.id
              )
          ),
          ...oneTimeJobLoadCompleteAction.oneTimeJobs.map((j) => ({
            ...j,
            stale: false,
          })),
        ],
      };

    case actionTypes.MAINTENANCE_JOB_LOAD_COMPLETE:
      const maintenanceJobLoadCompleteAction =
        action as IMaintenanceJobLoadComplete;

      return {
        ...state,
        jobsLoading: state.jobsLoading.filter(
          (jobLoadingId) =>
            !maintenanceJobLoadCompleteAction.maintenanceJobs.find(
              (maintenanceJob) => maintenanceJob.id === jobLoadingId
            )
        ),
        jobs: [
          ...state.jobs.filter(
            (existingJob) =>
              !maintenanceJobLoadCompleteAction.maintenanceJobs.some(
                (newJob) => existingJob.id === newJob.id
              )
          ),
          ...maintenanceJobLoadCompleteAction.maintenanceJobs.map((j) => ({
            ...j,
            stale: false,
          })),
        ],
      };

    case actionTypes.MARK_LOCAL_CACHE_STALE:
      return {
        ...state,
        jobs: state.jobs.map((j) => ({
          ...j,
          stale: true,
        })),
        oneTimeJobs: state.oneTimeJobs.map((j) => ({
          ...j,
          stale: true,
        })),
      };

    case actionTypes.SHIFT_SCHEDULE_COMPLETE_AGGREGATE:
      const shiftScheduleCompleteAction =
        action as IShiftScheduleCompleteAggregate;
      return {
        ...state,
        oneTimeJobs: state.oneTimeJobs.filter(
          (j) => shiftScheduleCompleteAction.oneTimeJobIds.indexOf(j.id) === -1
        ),
      };

    case actionTypes.LOAD_DAY_SCHEDULES_COMPLETED:
      return applyLoadDayschedulesComplete(
        action as ILoadDaySchedulesCompletedAction,
        state
      );

    case actionTypes.JOB_INSTANCE_TIME_UPDATED:
      const jobInstanceTimeUpdatedAction = action as IJobInstanceTimeUpdated;

      return {
        ...state,
        oneTimeJobs: state.oneTimeJobs.map((j) => {
          if (j.id === jobInstanceTimeUpdatedAction.jobInstanceId) {
            return {
              ...j,
              startTime: jobInstanceTimeUpdatedAction.startTime,
              endTime: jobInstanceTimeUpdatedAction.endTime,
            };
          } else {
            return j;
          }
        }),
      };

    default:
      return state;
  }
};

function applyDropJobAction(
  action: IDropJobAction,
  state: IJobState
): IJobState {
  return {
    ...state,
    oneTimeJobs: state.oneTimeJobs.map((oneTimeJob) => {
      if (action.jobInstanceIds.some((i) => i === oneTimeJob.id)) {
        return {
          ...oneTimeJob,
          flexibleJob: action.destinationFlexibleJob,
          date: action.destinationFlexibleJob
            ? dateService.formatAsIso(action.destinationFlexibleJobWeek as Date)
            : dateService.formatAsIso(
                action.destinationDayScheduleDate as Date
              ),
          crewId: action.destinationDayCrewId,
        };
      } else {
        return oneTimeJob;
      }
    }),
  };
}

function applyLoadDayschedulesComplete(
  action: ILoadDaySchedulesCompletedAction,
  state: IJobState
): IJobState {
  return {
    ...state,
    oneTimeJobs: state.oneTimeJobs.map((oneTimeJob) => {
      const scheduleWithJob = action.daySchedules.find((ds) =>
        ds.jobInstances.some((ji) => ji.oneTimeJobId === oneTimeJob.id)
      );

      if (scheduleWithJob) {
        return {
          ...oneTimeJob,
          flexibleJob: false,
          crewId: scheduleWithJob.crewId,
          date: scheduleWithJob.date,
        };
      }

      const unscheduledWeekWithJob =
        action.weeksUnscheduledMaintenanceJobs.find((ds) =>
          ds.jobInstances.some((ji) => ji.oneTimeJobId === oneTimeJob.id)
        );

      if (unscheduledWeekWithJob) {
        return {
          ...oneTimeJob,
          flexibleJob: true,
          crewId: null,
          date: unscheduledWeekWithJob.date,
        };
      }

      return oneTimeJob;
    }),
  };
}
