import React from "react";
import PageWithNavBar2 from "../../PageWithNavBar2";
import { actionCreators } from "../../../../modules/actionCreators";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { useDispatch } from "react-redux";

interface IProps {
  pageHeader: string;
  addButton: JSX.Element;
  messageToShow: string | JSX.Element;

  showInactive: boolean;
  showInactiveFilter?: boolean;
  itemCount: number;
  onInactiveFilterChanged: (showInactive: boolean) => void;
  notFluid?: boolean;
  customFilters?: React.ReactNode;
}

const ContainerHeader: React.FunctionComponent<IProps> = ({
  pageHeader,
  addButton,
  messageToShow,
  showInactive,
  showInactiveFilter,
  children,
  itemCount,
  onInactiveFilterChanged,
  notFluid,
  customFilters,
}) => {
  const topLevelMessage = useApplicationStateSelector(
    (s) => s.manageUi.topLevelMessage
  );
  const dispatch = useDispatch();

  return (
    <PageWithNavBar2 billingContext={true} notFluid={notFluid ?? true}>
      {!!topLevelMessage ? (
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
          style={{
            position: "sticky",
            top: "15px",
            marginTop: "15px",
            zIndex: 4,
          }}
          data-testid="topLevelMessageContainer"
        >
          {topLevelMessage}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => dispatch(actionCreators.hideManageUiMessage())}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ) : null}
      <div
        style={{
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            rowGap: "10px",
          }}
        >
          <div>
            <h1 style={{ marginBottom: 0 }}>{pageHeader}</h1>
          </div>
          <div>
            <div>{addButton}</div>
          </div>
        </div>

        {customFilters ? <div>{customFilters}</div> : null}

        {showInactiveFilter ? (
          <div className="text-right">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="include-inactive"
                checked={showInactive}
                onChange={(e) =>
                  onInactiveFilterChanged(e.currentTarget.checked)
                }
              />
              <label
                className="custom-control-label"
                htmlFor="include-inactive"
              >
                Show inactive
              </label>
            </div>
          </div>
        ) : null}
      </div>

      {itemCount === 0 && messageToShow ? (
        <div className="jumbotron" style={{ textAlign: "center" }}>
          <h5>{messageToShow}</h5>
        </div>
      ) : (
        children
      )}
    </PageWithNavBar2>
  );
};

export default ContainerHeader;
