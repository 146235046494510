import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import uuidv4 from "uuid/v4";

interface IProps {
  width?: number;

  children: (args: { skipContextMenuCloseClass: string }) => React.ReactNode;

  isContextMenuOpen: boolean;
  setIsContextMenuOpen: (open: boolean) => void;
  smallContextTrigger: boolean;

  renderCustomTrigger?: (arg: {
    toggleContextMenuOpen(): void;
    skipContextMenuCloseClass: string;
  }) => JSX.Element;

  respectAllContextMenuButtonsInGlobalHandler?: boolean;
}

const skipContextMenuCloseClassGlobal = `SkipCloseGlobal`;
const CardContextMenu: React.FunctionComponent<IProps> = ({
  width,
  children,
  isContextMenuOpen,
  setIsContextMenuOpen,
  renderCustomTrigger,
  smallContextTrigger,
  respectAllContextMenuButtonsInGlobalHandler,
}) => {
  const skipContextMenuCloseClass = useRef(`SkipClose${uuidv4()}`);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      let elementToCheck: HTMLElement | null = null;
      if (e.target instanceof HTMLElement) {
        elementToCheck = e.target as HTMLElement;

        if (
          elementToCheck &&
          elementToCheck.className &&
          (elementToCheck.className.indexOf(
            skipContextMenuCloseClass.current
          ) !== -1 ||
            (elementToCheck.className.indexOf(
              skipContextMenuCloseClassGlobal
            ) !== -1 &&
              respectAllContextMenuButtonsInGlobalHandler))
        ) {
          return;
        }
      }

      if (typeof (e.target as any)?.closest === "function") {
        // Handle fontawesome icon. svg element doesn't appear to be an instance of HTML element
        // but its ancestor does have the skipContextMenuCloseClass class applied.
        elementToCheck = (e.target as any).closest(
          `.${skipContextMenuCloseClass.current}`
        );

        if (!!elementToCheck) {
          return;
        }

        if (respectAllContextMenuButtonsInGlobalHandler) {
          elementToCheck = (e.target as any).closest(
            `.${skipContextMenuCloseClassGlobal}`
          );

          if (!!elementToCheck) {
            return;
          }
        }
      }

      setIsContextMenuOpen(false);
    };

    document.addEventListener("click", handler);

    return function cleanup() {
      document.removeEventListener("click", handler);
    };
  }, [setIsContextMenuOpen, respectAllContextMenuButtonsInGlobalHandler]);

  const standardWidth = 75;
  const baseXTranform = !smallContextTrigger ? -43 : -56;
  const widthToUse = width ?? standardWidth;
  const xTransform = baseXTranform - (widthToUse - standardWidth);

  const yTransform = !smallContextTrigger ? 30 : 15;

  const customTriggerElementSet = typeof renderCustomTrigger === "function";
  return (
    <>
      <div
        style={
          !customTriggerElementSet
            ? {
                position: "absolute",
                top: !smallContextTrigger ? "5px" : "2px",
                right: ".5rem",
              }
            : {}
        }
      >
        {!customTriggerElementSet ? (
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsContextMenuOpen(!isContextMenuOpen);
            }}
            className={
              `bg-secondary border border-dark job-card-context-menu ${skipContextMenuCloseClass.current} ${skipContextMenuCloseClassGlobal} ` +
              (isContextMenuOpen || isMobile
                ? " job-card-context-menu-show"
                : "")
            }
            style={{
              fontSize: !smallContextTrigger ? undefined : ".5rem",
              paddingTop: !smallContextTrigger ? "5px" : undefined,
              paddingBottom: !smallContextTrigger ? "5px" : undefined,
              paddingLeft: !smallContextTrigger ? "10px" : "5px",
              paddingRight: !smallContextTrigger ? "10px" : "5px",
            }}
            data-testid="expandButton"
          >
            <FontAwesomeIcon
              icon={faEllipsisH}
              className={`job-card-context-menu-trigger ${skipContextMenuCloseClass.current}`}
              aria-hidden="true"
              data-testid="expandEllipsis"
            />
          </button>
        ) : (
          renderCustomTrigger({
            skipContextMenuCloseClass: skipContextMenuCloseClass.current,
            toggleContextMenuOpen: () => {
              setIsContextMenuOpen(!isContextMenuOpen);
            },
          })
        )}

        {isContextMenuOpen ? (
          <div
            className="border border-dark bg-light context-menu-container"
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              display: "block",
              transform: `translate3d(${xTransform}px, ${yTransform}px, 0px)`,
              zIndex: 100,
              width: `${widthToUse}px`,
              padding: "5px",
            }}
          >
            {children({
              skipContextMenuCloseClass: skipContextMenuCloseClass.current,
            })}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default CardContextMenu;
