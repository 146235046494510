import {
  components,
  GroupBase,
  OptionProps,
  SelectInstance,
} from "react-select";
import { IQuickBooksCustomer } from "../../../models/IQuickBooksCustomer";
import WrappedCreatableSelect from "./WrappedCreateableSelect";
import { useEffect, useRef, useState } from "react";
import QuickBooksCustomerForm from "../forms/QuickBooksCustomerForm";
import { ICustomer } from "../../../models/ICustomer";
import { WrappedCreatableSelectOption } from "./WrappedCreatableSelectOption";
import { isStringSet } from "../../../services/stringService";

interface IProps {
  inputId: string;
  customer: ICustomer | null;
  options: Array<IQuickBooksCustomer>;
  quickBooksCustomerId: string;
  onChange: (id: string) => void;
  onCreatedQuickBooksCustomer: (args: {
    id: string;
    displayName: string;
  }) => void;
  usePortal?: boolean;
  required?: boolean;
  scrollIntoView?: boolean;
  selectRef?: React.MutableRefObject<SelectInstance<
    IQuickBooksCustomer,
    false
  > | null>;
}

const Option = (
  props: OptionProps<IQuickBooksCustomer, false, GroupBase<IQuickBooksCustomer>>
) => {
  const customer = props.data as IQuickBooksCustomer;

  return (
    <components.Option {...props}>
      <div id="customerSelectionAddItem">
        <WrappedCreatableSelectOption
          isNewItem={customer.id === newItemId}
          name={customer.displayName}
          recordType="QuickBooks customer"
        />
      </div>
    </components.Option>
  );
};

const newItemId = "newitem";
const QuickBooksCustomerSelection: React.FunctionComponent<IProps> = ({
  inputId,
  customer,
  options,
  quickBooksCustomerId,
  onChange,
  onCreatedQuickBooksCustomer,
  usePortal,
  required,
  scrollIntoView,
  selectRef: passedInSelectRef,
}) => {
  const innerSelectRef = useRef<SelectInstance<
    IQuickBooksCustomer,
    false
  > | null>();
  const [showForm, setShowForm] = useState(false);
  const [formDefaultCustomerName, setFormDefaultCustomerName] = useState("");

  useEffect(() => {
    const selectRef = passedInSelectRef ?? innerSelectRef;
    if (required && selectRef.current?.inputRef) {
      selectRef.current.inputRef.setCustomValidity(
        quickBooksCustomerId ? "" : "Please select a QuickBooks customer."
      );
    }
  }, [required, quickBooksCustomerId, passedInSelectRef]);

  function openQuickBooksCustomerForm(
    selectedCustomer: IQuickBooksCustomer | undefined
  ) {
    let defaultName = "";
    if (isStringSet(selectedCustomer?.displayName)) {
      defaultName = selectedCustomer.displayName;
    } else if (isStringSet(customer?.name)) {
      defaultName = customer.name;
    }
    setFormDefaultCustomerName(defaultName);
    setShowForm(true);
  }

  return (
    <>
      <WrappedCreatableSelect<IQuickBooksCustomer, false>
        selectRef={passedInSelectRef ?? innerSelectRef}
        inputId={inputId}
        options={options}
        styles={{
          menu: (base) => {
            return {
              ...base,
              zIndex: 4,
            };
          },
          menuPortal: (base) => {
            return {
              ...base,
              zIndex: 2000,
            };
          },
        }}
        isValidNewOption={() => true}
        getOptionLabel={(c) => c.displayName}
        getOptionValue={(c) => c.id}
        onChange={(option, actionMeta) => {
          if (actionMeta.action === "create-option") {
            const selectedOption = option as IQuickBooksCustomer | undefined;
            openQuickBooksCustomerForm(selectedOption);
          } else if (actionMeta.action === "clear") {
            onChange("");
          } else {
            let id = "";
            if (Array.isArray(option)) {
              id = (option[0] as IQuickBooksCustomer).id;
            } else if (option) {
              id = (option as IQuickBooksCustomer).id;
            }
            onChange(id);
          }
        }}
        value={options.find((c) => c.id === quickBooksCustomerId) ?? null}
        isClearable={true}
        createOptionPosition="first"
        components={{ Option }}
        getNewOptionData={(text, b) => {
          return {
            displayName: text,
            id: newItemId,
          } as IQuickBooksCustomer;
        }}
        onLeaveInputField={(customerName) => {
          // Hack warning.  Without this code, if the user clicked into a drop-down list on the containing form (i.e. Frequency on the Recurring Job form),
          // the drop-down list would stay open even though the custom form was also opened.  Appears need to leave focus from the select to a control on the existing form
          // and then open the customer form.  setTimeout is needed so for a slight delay.
          setTimeout(() => {
            innerSelectRef.current?.focus();
            openQuickBooksCustomerForm({
              displayName: customerName,
            } as IQuickBooksCustomer);
          });
        }}
        onMenuOpen={() => {
          if (scrollIntoView) {
            // Call with setTimeout to allow menu to render open
            setTimeout(() => {
              if (innerSelectRef.current?.inputRef) {
                innerSelectRef.current.inputRef.scrollIntoView(true);
              }
            });
          }
        }}
        menuShouldBlockScroll={usePortal ? true : undefined}
        menuPortalTarget={usePortal ? document.body : undefined}
      />
      {showForm ? (
        <QuickBooksCustomerForm
          defaultCustomerName={formDefaultCustomerName}
          customer={customer}
          onSaveComplete={(saveResult) => {
            setShowForm(false);
            onChange(saveResult.id);
            onCreatedQuickBooksCustomer(saveResult);
          }}
          onCancel={() => setShowForm(false)}
        />
      ) : null}
    </>
  );
};

export default QuickBooksCustomerSelection;
