import React, { useEffect, useRef, useState } from "react";
import remoteDataProvider from "../../../services/remoteDataProvider";
import {
  catchError,
  debounceTime,
  delay,
  switchMap,
  tap,
  timeout,
} from "rxjs/operators";
import { Subject, Subscription, merge, of } from "rxjs";
import { map } from "rxjs/operators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamationCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import LinkButton2 from "./LinkButton2";
import { isStringSet } from "../../../services/stringService";

export function ManageAdminUsersUserNameField({
  userAccountId,
  initialValue,
  reloadUserAccounts,
}: {
  userAccountId: string;
  initialValue: string | null;
  reloadUserAccounts: () => void;
}) {
  const formRef = useRef<HTMLFormElement>(null);
  const [name, setName] = useState(initialValue ?? "");
  const [savingStatus, setSavingStatus] = useState<
    "empty" | "saving" | "completed" | "error"
  >("empty");

  const onNameChange = useRef(new Subject<string>());
  const retrySave = useRef(new Subject<string>());
  const subscription = useRef<Subscription | null>(null);
  useEffect(() => {
    const onNameChangeDebounced = onNameChange.current.pipe(debounceTime(250));

    subscription.current = merge(onNameChangeDebounced, retrySave.current)
      .pipe(
        debounceTime(250),
        tap(() => setSavingStatus("saving")),
        switchMap((newName) =>
          remoteDataProvider
            .saveUserAccount(userAccountId, { name: newName })
            .pipe(
              timeout(10000),
              map(() => ({
                success: true,
              })),
              catchError(() =>
                of({
                  success: false,
                })
              )
            )
        ),
        tap((result) => {
          if (result.success) {
            setSavingStatus("completed");
            reloadUserAccounts();
          } else {
            setSavingStatus("error");
          }
        }),
        delay(5000)
      )
      .subscribe((result) => {
        if (result.success) {
          setSavingStatus("empty");
        }
      });

    return function cleanup() {
      if (subscription.current) {
        subscription.current.unsubscribe();
      }
    };
  }, [userAccountId, reloadUserAccounts]);

  return (
    <form ref={formRef}>
      <div>
        <div className="d-flex align-items-baseline">
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => {
              const value = e.currentTarget.value;
              setName(value);

              if (isStringSet(value)) {
                onNameChange.current.next(value);
              } else if (formRef.current !== null) {
                formRef.current.reportValidity();
              }
            }}
            placeholder="Name"
            maxLength={100}
            data-testid="usersName"
            required
          />
          <div
            data-testid="indicatorContainer"
            className="ml-2"
            style={{
              verticalAlign: "middle",
              visibility: savingStatus === "empty" ? "hidden" : undefined,
            }}
          >
            {savingStatus === "saving" ? (
              <FontAwesomeIcon icon={faSpinner} spin title="Saving..." />
            ) : null}

            {/* Note: Showing when savingstatus is empty to ensure space is preserved here. It won't show due to parent div setting visibility to hidden */}
            {savingStatus === "completed" || savingStatus === "empty" ? (
              <FontAwesomeIcon icon={faCheck} title="Save Completed" />
            ) : null}

            {savingStatus === "error" ? (
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="text-danger"
                title="Save Failed"
              />
            ) : null}
          </div>
        </div>
        {savingStatus === "error" ? (
          <div className="text-danger">
            An error occurred saving.{" "}
            <LinkButton2
              testId="retryButton"
              buttonContents={
                <span style={{ textDecoration: "underline" }}>Retry</span>
              }
              className="text-danger"
              inlineButton
              onClick={() => {
                retrySave.current.next(name);
              }}
            />
          </div>
        ) : null}
      </div>
    </form>
  );
}
