import { IJobInstance } from "../models/IJobInstance";
import jobFinder from "./jobFinder";
import { IMaintenanceJob } from "../models/IMaintenanceJob";
import { IOneTimeJob } from "../models/IOneTimeJob";
import { ICustomer } from "../models/ICustomer";
import { IDistance } from "../models/IDistance";
import { ICustomerAdditionalLocation } from "../models/ICustomerAdditionalLocation";
import addressFormatter from "./addressFormatter";

export function getJobDistances(
  jobInstances: Array<IJobInstance>,
  jobs: Array<IMaintenanceJob>,
  oneTimeJobs: Array<IOneTimeJob>,
  customers: Array<ICustomer>,
  crewLatitude: number,
  crewLongitude: number,
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>
) {
  const distancesToLoad: Array<IDistance> = [];
  if (jobInstances.length > 0) {
    for (let i = 0; i < jobInstances.length; i++) {
      const destinationJob = jobFinder.getJobForDayScheduleV2(
        jobs,
        oneTimeJobs,
        jobInstances[i]
      );
      if (i === 0) {
        if (destinationJob) {
          const destinationCustomer = addressFormatter.getAddressForJob(
            destinationJob,
            customers,
            customerAdditionalLocations
          );
          if (destinationCustomer.latitude && destinationCustomer.longitude) {
            distancesToLoad.push({
              sourceLatitude: crewLatitude,
              sourceLongitude: crewLongitude,
              destinationLatitude: destinationCustomer.latitude,
              destinationLongitude: destinationCustomer.longitude
            });
          }
        }
      } else {
        const sourceJob = jobFinder.getJobForDayScheduleV2(
          jobs,
          oneTimeJobs,
          jobInstances[i - 1]
        );
        if (sourceJob && destinationJob) {
          const sourceCustomer = addressFormatter.getAddressForJob(
            sourceJob,
            customers,
            customerAdditionalLocations
          );
          const destinationCustomer = addressFormatter.getAddressForJob(
            destinationJob,
            customers,
            customerAdditionalLocations
          );
          if (
            sourceCustomer.latitude &&
            sourceCustomer.longitude &&
            destinationCustomer.latitude &&
            destinationCustomer.longitude
          ) {
            distancesToLoad.push({
              sourceLatitude: sourceCustomer.latitude,
              sourceLongitude: sourceCustomer.longitude,
              destinationLatitude: destinationCustomer.latitude,
              destinationLongitude: destinationCustomer.longitude
            });
          }
        }
      }
    }

    const lastJob = jobFinder.getJobForDayScheduleV2(
      jobs,
      oneTimeJobs,
      jobInstances[jobInstances.length - 1]
    );

    if (lastJob) {
      const lastCustomer = addressFormatter.getAddressForJob(
        lastJob,
        customers,
        customerAdditionalLocations
      );

      if (lastCustomer.latitude && lastCustomer.longitude) {
        distancesToLoad.push({
          sourceLatitude: lastCustomer.latitude,
          sourceLongitude: lastCustomer.longitude,
          destinationLatitude: crewLatitude,
          destinationLongitude: crewLongitude
        });
      }
    }
  }

  return distancesToLoad;
}
