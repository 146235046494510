import React, { Fragment, useState } from "react";
import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import LinkButton from "../../components/LinkButton";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";
import {
  IBillingReportCustomer,
  BillingReportJobType,
} from "../../../../models/IBillingReport";
import { connect } from "react-redux";
import jobFinder from "../../../../services/jobFinder";
import { IMaintenanceJob } from "../../../../models/IMaintenanceJob";
import { IRootState } from "../../../../store";
import { actionCreators } from "../../../../modules/actionCreators";
import { IOneTimeJob } from "../../../../models/IOneTimeJob";
import { ICustomer as IModelCustomer } from "../../../../models/ICustomer";
import JobHelper from "../../../../services/jobHelper";
import billingReportService from "../../../../services/billingReportService";
import { formatCurrency } from "../../../../services/currencyFormatter";
import { ICustomerAdditionalLocation } from "../../../../models/ICustomerAdditionalLocation";
import SortColumn from "../../components/SortColumn";
import { Link } from "react-router-dom";
import { builders as routerBuilders } from "../../../../services/routing";
import constants from "../../../../constants";
import { CompletedWorkReportColumn } from "../../../../enums/completedWorkReportColumn";
import {
  ICustomerWithLocationName,
  IDateRange,
  ISort,
  SortProperty,
} from "./ReportContents.model";
import {
  getCustomerNameColSpan,
  getDefaultSort,
  getRevenuePerManHour,
  getTotalGrossRevenue,
  getTotalGrossRevenueForCustomer,
  getTotalManHours,
  getTotalManHoursForCustomer,
  getVarianceElement,
  getVarianceForCustomer,
  getVarianceForJob,
  isColumnVisible,
  roundToOneDecimalPlace,
  sortCustomers,
} from "./ReportContents.functions";
import ProjectFormLoader from "../../../../slices/schedule/components/ProjectForm";
import { useUserSettings } from "../../../../services/userSettingsService";
import { UserSettingsType } from "../../../../enums/userSettingsType";

export interface IPassedInProps {
  dateRanges: Array<IDateRange>;
  formatDateForReportDisplay(date: Date | string): string;
  reportData: Array<IBillingReportCustomer>;
  showNotes(customer: IBillingReportCustomer): void;
  showBillingDetails(customer: IBillingReportCustomer): void;
  visibleColumns: Array<CompletedWorkReportColumn>;
}

interface IProps extends IPassedInProps {
  maintenanceJobs: Array<IMaintenanceJob>;
  oneTimeJobs: Array<IOneTimeJob>;
  customers: Array<IModelCustomer>;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
  editMaintenanceJob(parameters: any): void;
  editOneTimeJob(parameters: any): void;
}

const ReportContents: React.FunctionComponent<IProps> = ({
  dateRanges,
  formatDateForReportDisplay,
  reportData,
  showNotes,
  showBillingDetails,
  maintenanceJobs,
  oneTimeJobs,
  editMaintenanceJob,
  editOneTimeJob,
  customers,
  customerAdditionalLocations,
  visibleColumns,
}) => {
  const [detailPopoversVisible, setDetailPopoversVisible] = useState<{
    [P: string]: boolean;
  }>({});
  const { getUserSettings } = useUserSettings();
  const [sort, setSort] = useState<ISort>(getDefaultSort(getUserSettings));
  const [projectIdToEdit, setProjectIdToEdit] = useState<null | string>(null);
  const { setUserSettings } = useUserSettings();

  function getSortableColumn(displayName: string, property: SortProperty) {
    return (
      <SortColumn<SortProperty>
        displayName={displayName}
        columnSortProperty={property}
        currentSortDirection={sort.direction}
        currentSortProperty={sort.property}
        onSortDirectionChange={(sortProperty, sortDirection) => {
          const newSort = {
            property: sortProperty,
            direction: sortDirection,
          };

          setUserSettings(UserSettingsType.completedWorkSort, newSort);

          setSort(newSort);
        }}
      />
    );
  }

  function toggleJobPopover(id: string) {
    let newValue = {
      ...detailPopoversVisible,
    };

    newValue[id] = !newValue[id];
    setDetailPopoversVisible(newValue);
  }

  const headerStyle = { verticalAlign: "top" };

  const filteredCustomers = reportData
    .map((reportCustomer) => {
      const globalCustomer = customers.find(
        (globalCustomer) => globalCustomer.id === reportCustomer.id
      );
      if (globalCustomer) {
        return {
          ...reportCustomer,
          name: globalCustomer.name,
        };
      } else {
        return reportCustomer;
      }
    })
    .map(
      (c) =>
        ({
          ...c,
          locationName: billingReportService.getLocationName(
            c,
            customers,
            customerAdditionalLocations
          ),
        } as ICustomerWithLocationName)
    );
  const sortedCustomers = sortCustomers(sort, filteredCustomers);

  let previousCustomer: IBillingReportCustomer | null = null;

  const isColumnVisibleInternal = (column: CompletedWorkReportColumn) =>
    isColumnVisible(visibleColumns, column);

  return (
    <>
      <table
        className="table table-sm table-striped"
        style={{ marginBottom: constants.marginBottomForIntercom }}
      >
        <thead>
          <tr>
            <th style={headerStyle} scope="col">
              {getSortableColumn("Customer", SortProperty.CustomerName)}
            </th>

            {isColumnVisibleInternal(CompletedWorkReportColumn.Frequency) ? (
              <th style={headerStyle} scope="col">
                Frequency
              </th>
            ) : null}

            {isColumnVisibleInternal(CompletedWorkReportColumn.SpecificVisits)
              ? dateRanges.map((r) => (
                  <th
                    style={headerStyle}
                    scope="col"
                    key={dateFnsFormat(r.start, "YYYY-MM-DD")}
                  >
                    {r.start === r.end ? (
                      formatDateForReportDisplay(r.end)
                    ) : (
                      <Fragment>
                        {formatDateForReportDisplay(r.start)} -{" "}
                        {formatDateForReportDisplay(r.end)}
                      </Fragment>
                    )}
                  </th>
                ))
              : null}

            {isColumnVisibleInternal(CompletedWorkReportColumn.VisitCount) ? (
              <th style={{ ...headerStyle, textAlign: "right" }} scope="col">
                {getSortableColumn("Visit count", SortProperty.VisitCount)}
              </th>
            ) : null}

            {isColumnVisibleInternal(CompletedWorkReportColumn.Notes) ? (
              <th style={headerStyle} scope="col">
                Notes
              </th>
            ) : null}

            {isColumnVisibleInternal(CompletedWorkReportColumn.Billing) ? (
              <th style={headerStyle} scope="col">
                Billing
              </th>
            ) : null}

            {isColumnVisibleInternal(CompletedWorkReportColumn.Services) ? (
              <th style={headerStyle} scope="col">
                Services
              </th>
            ) : null}

            {isColumnVisibleInternal(CompletedWorkReportColumn.Variance) ? (
              <th style={{ ...headerStyle, textAlign: "right" }} scope="col">
                {getSortableColumn("Variance", SortProperty.TotalVariance)}
              </th>
            ) : null}

            {isColumnVisibleInternal(CompletedWorkReportColumn.Hours) ? (
              <th style={{ ...headerStyle, textAlign: "right" }} scope="col">
                {getSortableColumn("Hours", SortProperty.ManHoursWorked)}
              </th>
            ) : null}

            {isColumnVisibleInternal(CompletedWorkReportColumn.Revenue) ? (
              <th style={{ ...headerStyle, textAlign: "right" }} scope="col">
                {getSortableColumn("Amount", SortProperty.GrossRevenue)}
              </th>
            ) : null}

            {isColumnVisibleInternal(
              CompletedWorkReportColumn.RevenuePerManHour
            ) ? (
              <th style={{ ...headerStyle, textAlign: "right" }} scope="col">
                {getSortableColumn(
                  "$ per man hour",
                  SortProperty.RevenuePerManHour
                )}
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {sortedCustomers.map((customer) => {
            let maintenanceJob: IMaintenanceJob | null = null;
            let oneTimeJob: IOneTimeJob | null = null;

            if (customer.type === BillingReportJobType.MaintenanceJob) {
              maintenanceJob = jobFinder.getJobById(
                maintenanceJobs,
                customer.jobId
              ) as IMaintenanceJob;
            } else {
              oneTimeJob = jobFinder.getOneTimeJobById(
                oneTimeJobs,
                customer.jobId
              ) as IOneTimeJob;
            }

            const isNewCustomer =
              !previousCustomer || previousCustomer.id !== customer.id;
            previousCustomer = customer;

            return (
              <React.Fragment key={customer.jobId}>
                {isNewCustomer ? (
                  <tr>
                    <td
                      colSpan={getCustomerNameColSpan(
                        visibleColumns,
                        dateRanges
                      )}
                    >
                      <Link
                        to={routerBuilders.manage.buildCustomerDetailsRoute({
                          customerId: customer.id,
                          search: "",
                        })}
                      >
                        {customer.name}
                      </Link>
                    </td>

                    {isColumnVisibleInternal(
                      CompletedWorkReportColumn.Variance
                    ) ? (
                      <td
                        style={{ textAlign: "right" }}
                        data-testid="varianceForCustomer"
                      >
                        {getVarianceElement(
                          getVarianceForCustomer(customer, sortedCustomers)
                        )}
                      </td>
                    ) : null}

                    {isColumnVisibleInternal(
                      CompletedWorkReportColumn.Hours
                    ) ? (
                      <td
                        style={{ textAlign: "right" }}
                        data-testid="totalHoursForCustomer"
                      >
                        {roundToOneDecimalPlace(
                          getTotalManHoursForCustomer(customer, sortedCustomers)
                        )}
                      </td>
                    ) : null}

                    {isColumnVisibleInternal(
                      CompletedWorkReportColumn.Revenue
                    ) ? (
                      <td style={{ textAlign: "right" }}>
                        {getTotalGrossRevenueForCustomer(
                          customer,
                          sortedCustomers
                        )
                          ? formatCurrency(
                              getTotalGrossRevenueForCustomer(
                                customer,
                                sortedCustomers
                              ) as number
                            )
                          : null}
                      </td>
                    ) : null}

                    {isColumnVisibleInternal(
                      CompletedWorkReportColumn.RevenuePerManHour
                    ) ? (
                      <td style={{ textAlign: "right" }}>
                        {getRevenuePerManHour(customer, sortedCustomers)
                          ? formatCurrency(
                              getRevenuePerManHour(
                                customer,
                                sortedCustomers
                              ) as number
                            )
                          : null}
                      </td>
                    ) : null}
                  </tr>
                ) : null}
                <tr>
                  <td className="pl-3">
                    <small>{customer.locationName}</small>
                  </td>

                  {isColumnVisibleInternal(
                    CompletedWorkReportColumn.Frequency
                  ) ? (
                    <td data-testid="frequencyCell">
                      <LinkButton
                        id="editJob"
                        hrefForDisplay="editjob"
                        className=""
                        style={{}}
                        onClick={() => {
                          if (
                            customer.type ===
                            BillingReportJobType.MaintenanceJob
                          ) {
                            editMaintenanceJob({
                              maintenanceJobId: customer.jobId,
                            });
                          } else if (
                            customer.type === BillingReportJobType.OneTimeJob
                          ) {
                            editOneTimeJob({
                              oneTimeJobId: customer.jobId,
                            });
                          } else if (
                            customer.type === BillingReportJobType.Project
                          ) {
                            setProjectIdToEdit(customer.jobId);
                          }
                        }}
                        linkText={
                          customer.type === BillingReportJobType.Project
                            ? "Project"
                            : JobHelper.getJobFrequencyForDisplay(
                                maintenanceJob,
                                oneTimeJob,
                                customer.frequency,
                                customer.seasonalScheduleFrequency,
                                customer.seasonalScheduleStart,
                                customer.seasonalScheduleEnd,
                                customer.jobInstances.map((ji) => ji.date)
                              )
                        }
                      />
                    </td>
                  ) : null}

                  {isColumnVisibleInternal(
                    CompletedWorkReportColumn.SpecificVisits
                  )
                    ? dateRanges.map((r) => {
                        return (
                          <td
                            key={dateFnsFormat(r.start, "YYYY-MM-DD")}
                            data-testid="specificVisitCell"
                          >
                            {customer.jobInstances
                              .filter((ji) => {
                                const jobInstanceDate = dateFnsParse(ji.date);

                                // TODO: Why wasn't this found my unit test?
                                return (
                                  jobInstanceDate >= dateFnsParse(r.start) &&
                                  jobInstanceDate <= dateFnsParse(r.end)
                                );
                              })
                              .map((ji) => {
                                const linkId = "ID" + ji.id;

                                let linkClass = "";
                                if (ji.actualManHours && ji.estimatedManHours) {
                                  if (
                                    ji.actualManHours > ji.estimatedManHours
                                  ) {
                                    linkClass = "text-danger";
                                  } else if (
                                    ji.actualManHours < ji.estimatedManHours
                                  ) {
                                    linkClass = "text-success";
                                  }
                                }

                                return (
                                  <Fragment key={ji.id}>
                                    {customer.type ===
                                    BillingReportJobType.Project ? (
                                      <span className="customer-report-date">
                                        {formatDateForReportDisplay(ji.date)}
                                      </span>
                                    ) : (
                                      <>
                                        <LinkButton
                                          id={linkId}
                                          onClick={() =>
                                            toggleJobPopover(linkId)
                                          }
                                          linkText={formatDateForReportDisplay(
                                            ji.date
                                          )}
                                          className={
                                            linkClass + " customer-report-date"
                                          }
                                          hrefForDisplay="details"
                                          style={{
                                            textDecoration: "underline",
                                          }}
                                        />

                                        <Popover
                                          delay={0}
                                          fade={false}
                                          trigger="legacy"
                                          placement="bottom"
                                          isOpen={detailPopoversVisible[linkId]}
                                          target={linkId}
                                          toggle={() =>
                                            toggleJobPopover(linkId)
                                          }
                                        >
                                          <PopoverBody>
                                            Estimated man hours:{" "}
                                            <span>
                                              {ji.estimatedManHours ||
                                                "Not set"}
                                            </span>
                                            <br />
                                            Actual man hours:{" "}
                                            <span>
                                              {!!ji.actualManHours
                                                ? ji.actualManHours
                                                : " Unknown"}
                                            </span>
                                          </PopoverBody>
                                        </Popover>
                                      </>
                                    )}
                                  </Fragment>
                                );
                              })}
                          </td>
                        );
                      })
                    : null}

                  {isColumnVisibleInternal(
                    CompletedWorkReportColumn.VisitCount
                  ) ? (
                    <td
                      style={{ textAlign: "right" }}
                      data-testid="visitCountCell"
                    >
                      <span className="mr-2">
                        {customer.jobInstances.length}
                      </span>
                    </td>
                  ) : null}

                  {isColumnVisibleInternal(CompletedWorkReportColumn.Notes) ? (
                    <td data-testid="notesCell">
                      {billingReportService.doesCustomerHaveNotesOrTodos(
                        customer
                      ) ? (
                        <LinkButton
                          id="view"
                          hrefForDisplay="view"
                          className=""
                          style={{ textDecoration: "underline" }}
                          onClick={() => {
                            showNotes(customer);
                          }}
                          linkText="View"
                        />
                      ) : (
                        <span />
                      )}
                    </td>
                  ) : null}

                  {isColumnVisibleInternal(
                    CompletedWorkReportColumn.Billing
                  ) ? (
                    <td data-testid="billingCell">
                      {billingReportService.doesCustomerHaveBillingDetails(
                        customer
                      ) ? (
                        <LinkButton
                          id="billingdetails"
                          hrefForDisplay="billingdetails"
                          className=""
                          style={{ textDecoration: "underline" }}
                          onClick={() => {
                            showBillingDetails(customer);
                          }}
                          linkText="View"
                        />
                      ) : (
                        <span />
                      )}
                    </td>
                  ) : null}

                  {isColumnVisibleInternal(
                    CompletedWorkReportColumn.Services
                  ) ? (
                    <td data-testid="servicesCell">{customer.services}</td>
                  ) : null}

                  {isColumnVisibleInternal(
                    CompletedWorkReportColumn.Variance
                  ) ? (
                    <td
                      style={{ textAlign: "right" }}
                      data-testid="varianceCell"
                    >
                      {getVarianceElement(
                        roundToOneDecimalPlace(getVarianceForJob(customer))
                      )}
                    </td>
                  ) : null}

                  {isColumnVisibleInternal(CompletedWorkReportColumn.Hours) ? (
                    <td style={{ textAlign: "right" }} data-testid="hoursCell">
                      {roundToOneDecimalPlace(getTotalManHours(customer))}
                    </td>
                  ) : null}

                  {isColumnVisibleInternal(
                    CompletedWorkReportColumn.Revenue
                  ) ? (
                    <td style={{ textAlign: "right" }} data-testid="amountCell">
                      {getTotalGrossRevenue(customer)
                        ? formatCurrency(
                            getTotalGrossRevenue(customer) as number
                          )
                        : null}
                    </td>
                  ) : null}

                  {isColumnVisibleInternal(
                    CompletedWorkReportColumn.RevenuePerManHour
                  ) ? (
                    <td
                      style={{ textAlign: "right" }}
                      data-testid="revenuePerManHourCell"
                    >
                      {getTotalGrossRevenue(customer) &&
                      getTotalManHours(customer)
                        ? formatCurrency(
                            (getTotalGrossRevenue(customer) as number) /
                              (getTotalManHours(customer) as number)
                          )
                        : null}
                    </td>
                  ) : null}
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      {projectIdToEdit !== null ? (
        <ProjectFormLoader
          mode="edit"
          projectId={projectIdToEdit}
          onCancel={() => setProjectIdToEdit(null)}
          onSaveComplete={() => setProjectIdToEdit(null)}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state: IRootState, ownProps: IPassedInProps) => ({
  ...ownProps,
  maintenanceJobs: state.job.jobs,
  oneTimeJobs: state.job.oneTimeJobs,
  customers: state.customer.customers,
  customerAdditionalLocations: state.customer.customerAdditionalLocations,
});

const mapDispatchToProps = {
  editMaintenanceJob: actionCreators.forms.maintenanceJob.showForm,
  editOneTimeJob: actionCreators.forms.oneTimeJob.showForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportContents);
