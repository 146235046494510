import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

const SpinnerModalFooter: React.FunctionComponent = () => (
  <>
    <FontAwesomeIcon
      icon={faSpinner}
      size="2x"
      spin={true}
      fixedWidth={true}
      data-testid="modalFooterSpinner"
    />
    <span className="sr-only">Loading...</span>
  </>
);

export default SpinnerModalFooter;
