import React, { useState } from "react";
import { connect } from "react-redux";
import { IRootState } from "../../../../store";
import { actionCreators } from "../../../../modules/actionCreators";
import { ICrewMember } from "../../../../models/ICrewMember";
import { getSortedItems } from "../../../../services/sortingService";
import ContainerHeader from "./ContainerHeader";
import { builders } from "../../../../services/routing";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import useSalesEnabled from "../../../../slices/sales/hooks/useSalesEnabled";
import ResponsiveTable from "../../../../libraries/tableLayout/ResponsiveTable";
import { ResponsiveTableMobileCard } from "../../../../libraries/tableLayout/ResponsiveTableMobileCard";
import { TableColumns } from "../../../../libraries/tableLayout/TableColumn";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";

interface IProps {
  crewMembers: Array<ICrewMember>;
  showCrewMemberForm(parameters: any): any;
}

const CrewMembers2: React.FunctionComponent<IProps> = ({
  crewMembers,
  showCrewMemberForm,
}) => {
  const sortedCrewMembers = getSortedItems(crewMembers, "name");
  const estimatingEnabled = useSalesEnabled();
  const featureFlags = useApplicationStateSelector(
    (s) => s.common.featureFlags
  );

  const [showInactive, setShowInactive] = useState(false);

  const filteredItems = sortedCrewMembers
    .filter((i) => showInactive || !i.inactive)
    .map((cm) => ({
      ...cm,
      key: cm.id,
    }));

  const columns: TableColumns<ICrewMember & { key: string }> = [
    {
      key: "name",
      header: "Name",
      width: "",
      cell: ({ row: crewMember }) => (
        <Link to={builders.manage.buildCrewMemberDetailsRoute(crewMember.id)}>
          {crewMember.name}
        </Link>
      ),
    },
    {
      key: "phoneNumber",
      header: "Phone number",
      width: "",
      cell: ({ row: crewMember }) => (
        <a
          href={`tel:${crewMember.phoneNumber}`}
          style={{ whiteSpace: "nowrap" }}
        >
          {crewMember.phoneNumber}
        </a>
      ),
    },
    {
      key: "emailAddress",
      header: "Email address",
      width: "",
      cell: ({ row: crewMember }) =>
        crewMember.emailAddress ? (
          <a href={`mailto:${crewMember.emailAddress}`}>
            {crewMember.emailAddress}
          </a>
        ) : null,
    },
    {
      key: "onsiteInvoicing",
      header: "On-site invoicing",
      width: "",
      cell: ({ row: crewMember }) =>
        crewMember.allowOnsiteInvoicing ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : null,
    },
    {
      hidden: !estimatingEnabled,
      key: "onsiteEstimating",
      header: "On-site estimating",
      width: "",
      cell: ({ row: crewMember }) =>
        crewMember.allowOnsiteEstimating ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : null,
    },
    {
      hidden: !featureFlags?.techApp,
      key: "mobileApplicationAllowedCrews",
      header: "Mobile application",
      width: "",
      cell: ({ row: crewMember }) =>
        crewMember.allowMobileApplication ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : null,
    },
  ];

  const addButton = (
    <button className="btn btn-primary" onClick={() => showCrewMemberForm({})}>
      Add Crew Member
    </button>
  );

  return (
    <ContainerHeader
      addButton={addButton}
      pageHeader={`Crew Members (${filteredItems.length})`}
      messageToShow="No crew members exist"
      showInactiveFilter={true}
      itemCount={filteredItems.length}
      onInactiveFilterChanged={(newShowInactive) =>
        setShowInactive(newShowInactive)
      }
      showInactive={showInactive}
    >
      <ResponsiveTable
        tableClass="table-striped"
        rows={filteredItems}
        columns={columns}
        renderMobile={({ row, index }) => {
          return (
            <>
              <ResponsiveTableMobileCard
                row={row}
                columns={columns}
                rowIndex={index}
              />
            </>
          );
        }}
      />
    </ContainerHeader>
  );
};

const mapStateToProps = (state: IRootState) => ({
  crewMembers: state.crew.crewMembers,
});

const mapDispatchToProps = {
  showCrewMemberForm: actionCreators.forms.crewMember.showForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(CrewMembers2);
