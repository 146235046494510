import auth0 from "auth0-js";
import { AUTH_CONFIG } from "./auth0Variables";
import { push, CallHistoryMethodAction } from "connected-react-router";
import { ensureConnectionOpenOnLogin, closeConnection } from "./signalr";
import { Observable, Observer, BehaviorSubject } from "rxjs";
import { builders as routeBuilders } from "./routing";
import constants from "../constants";
import { fullStoryLogInfo } from "./fullStoryService";

class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    audience: AUTH_CONFIG.audience,
    responseType: "token id_token",
    scope: "openid email",
  });
  tokenRenewalTimeout: any;
  parseHashInProgress = false;
  isAuthenticatedObservable = new BehaviorSubject<boolean>(false);

  constructor() {
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.loginEmbedded = this.loginEmbedded.bind(this);
    this.authorizeWithGoogle = this.authorizeWithGoogle.bind(this);
    this.signupAndAuthorize = this.signupAndAuthorize.bind(this);

    fullStoryLogInfo(`isAuthenticated: ${this.isAuthenticated()}`);
    this.isAuthenticatedObservable.next(this.isAuthenticated());
  }

  getAccessToken() {
    try {
      return localStorage.getItem("access_token");
    } catch (err) {
      console.error(`error in getAccessToken: ${err}`);
      return null;
    }
  }

  getAuthorizationHeaderValue() {
    return `Bearer ${this.getAccessToken()}`;
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let isAuthenticated = false;

    try {
      const expiresAtUnparsed = localStorage.getItem("expires_at");
      if (expiresAtUnparsed) {
        let expiresAt = JSON.parse(expiresAtUnparsed);
        isAuthenticated = new Date().getTime() < expiresAt;

        if (isAuthenticated && !this.tokenRenewalTimeout) {
          this.scheduleRenewal();
        }
      }
    } catch (err) {
      console.error(`error in isAuthenticated: ${err}`);
    }

    return isAuthenticated;
  }

  handleAuthentication({
    dispatch,
    invitationId,
  }: {
    dispatch: (arg: CallHistoryMethodAction) => void;
    invitationId?: string | null;
  }) {
    fullStoryLogInfo("starting handleAuthentication");

    if (!this.parseHashInProgress) {
      fullStoryLogInfo("parseHashInProgress not set");

      this.parseHashInProgress = true;
      this.auth0.parseHash((err, authResult) => {
        fullStoryLogInfo("parseHash complete");

        this.parseHashInProgress = false;
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);

          this.isAuthenticatedObservable.next(true);

          let url = "/";
          if (invitationId) {
            url = routeBuilders.registration.acceptInvitation(invitationId);
          } else if (
            window.sessionStorage.getItem(
              constants.authenticationRedirectSessionStorageKey
            )
          ) {
            url = window.sessionStorage.getItem(
              constants.authenticationRedirectSessionStorageKey
            ) as string;

            fullStoryLogInfo(`read redirect_path: ${url}`);

            window.sessionStorage.removeItem(
              constants.authenticationRedirectSessionStorageKey
            );
          }

          dispatch(push(url));
        } else if (err) {
          dispatch(push("/"));
          console.log(err);

          if (!this.isAuthenticated()) {
            alert(
              `Error: ${err.error}. Check the console for further details.`
            );
          }
        }
      });
    }
  }

  setSession(authResult: any) {
    fullStoryLogInfo("setSession");

    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);

    ensureConnectionOpenOnLogin();

    this.scheduleRenewal();
  }

  logout() {
    closeConnection();

    // Clear access token and ID token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    window.sessionStorage.removeItem("isSignUp");
    window.sessionStorage.removeItem("signUpReferral");

    this.auth0.logout({
      clientID: AUTH_CONFIG.clientId,
      returnTo: AUTH_CONFIG.logoutRedirectUrl,
    });
  }

  renewToken(
    successCallback?: (result: any) => void,
    errorCallback?: (err: any) => void
  ) {
    this.auth0.checkSession({}, (err, result) => {
      if (err) {
        console.log(JSON.stringify(err));
        this.isAuthenticatedObservable.next(false);
        if (errorCallback) {
          errorCallback(err);
        }
      } else {
        fullStoryLogInfo("renewToken calling setSession");

        this.setSession(result);
        this.isAuthenticatedObservable.next(true);
        if (successCallback) {
          successCallback(result);
        }
      }
    });
  }

  renewTokenObservable(): Observable<string> {
    return Observable.create((observer: Observer<{}>) => {
      this.renewToken(
        () => {
          observer.next(this.getAuthorizationHeaderValue());
          observer.complete();
        },
        (err) => {
          observer.error(err);
        }
      );
    });
  }

  isTokenSet() {
    return !!this.getExpiresAt();
  }

  isTokenExpired() {
    const expiresAtUnparsed = this.getExpiresAt();

    if (expiresAtUnparsed) {
      const expiresAt = JSON.parse(expiresAtUnparsed);

      // Give 150ms buffer
      const delay = expiresAt - Date.now() - 150;
      if (delay > 0) {
        return false;
      }
    }
    return true;
  }

  scheduleRenewal() {
    const expiresAtUnparsed = this.getExpiresAt();

    if (expiresAtUnparsed) {
      const expiresAt = JSON.parse(expiresAtUnparsed);
      const delay = expiresAt - Date.now();
      if (delay > 0) {
        this.tokenRenewalTimeout = setTimeout(() => {
          this.renewToken();
        }, delay);
      }
    }
  }

  authorizeWithGoogle({ invitationId }: { invitationId?: string | null }) {
    const redirectUri = getRedirectUri(invitationId);
    this.auth0.authorize({
      connection: "google-oauth2",
      redirectUri,
    });
  }

  signupAndAuthorize({
    email,
    password,
    onError,
    onSuccess,
  }: {
    email: string;
    password: string;
    onError(message: string): void;
    onSuccess?: () => void;
  }) {
    this.auth0.signupAndAuthorize(
      {
        connection: "Username-Password-Authentication",
        email,
        password,
      },
      (error, result) => {
        this.handleAuth0Response({
          result,
          error,
          onError,
          onSuccess,
          mode: "create account",
        });
      }
    );
  }

  loginEmbedded({
    email,
    password,
    onError,
    onSuccess,
    invitationId,
  }: {
    email: string;
    password: string;
    onError(message: string): void;
    onSuccess?: () => void;
    invitationId?: string | null;
  }) {
    const redirectUri = getRedirectUri(invitationId);

    this.auth0.login(
      {
        email,
        password,
        redirectUri,
      },
      (error, result) => {
        this.handleAuth0Response({
          result,
          error,
          onError,
          onSuccess,
          mode: "login",
        });
      }
    );
  }

  private handleAuth0Response({
    result,
    error,
    onError,
    onSuccess,
    mode,
  }: {
    result: any;
    error: auth0.Auth0Error | null;
    onSuccess?: () => void;
    onError: (message: string) => void;
    mode: "login" | "create account";
  }) {
    if (result && !error) {
      this.setSession(result);
      this.isAuthenticatedObservable.next(true);

      if (onSuccess) {
        onSuccess();
      }
    } else {
      let errorMessage = constants.unknownErrorMessage;
      if (error?.code === "request_error") {
        errorMessage = `Unable to ${mode}. Please check your Internet connection.`;
      } else if (typeof error?.description === "string") {
        errorMessage = error.description;
      } else if (error?.code === "invalid_password") {
        errorMessage = "Your password is too weak";
        if (typeof error?.policy === "string") {
          errorMessage += "\n" + error.policy;
        }
      }
      onError(errorMessage);
    }
  }

  private getExpiresAt() {
    try {
      return localStorage.getItem("expires_at");
    } catch (err) {
      console.error(`error in getExpiresAt: ${err}`);
      return null;
    }
  }
}

const auth = new Auth();
export default auth;
function getRedirectUri(invitationId: string | null | undefined) {
  return !invitationId
    ? AUTH_CONFIG.callbackUrl
    : `${AUTH_CONFIG.callbackUrl}?${constants.invitationIdQueryString}=${invitationId}`;
}
