import { Link } from "react-router-dom";
import { builders } from "../../../services/routing";
import dateService from "../../../services/dateService";
import { formatDateForSchedule } from "./formatDateForSchedule";
import addDays from "date-fns/add_days";
import parse from "date-fns/parse";
import format from "date-fns/format";
import { ISelectedCrew } from "../components/types/ISelectedCrew";
import { ICrew } from "../../../models/ICrew";
import { IDaySchedule } from "../../../models/IDaySchedule";
import { IScheduleColumn } from "../components/types/IScheduleColumn";
import { IScheduleRow } from "../components/types/IScheduleRow";
import { getSortedCrews } from "../../../services/sortingService";
import constants from "../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import startOfWeek from "date-fns/start_of_week";
import { CrewScheduleType } from "../enums/crewScheduleType";

export function getScheduleWeekNextScheduleParams({
  isMapViewOpen,
  scheduleDate,
  params,
  selectedCrews,
}: {
  isMapViewOpen: boolean;
  scheduleDate: string;
  params: { mapDate: string };
  selectedCrews: Array<ISelectedCrew>;
}) {
  if (isMapViewOpen) {
    const newMapDate = addDays(params.mapDate, 1);
    let parsedScheduleDate = parse(scheduleDate);
    if (newMapDate >= addDays(parsedScheduleDate, 7)) {
      parsedScheduleDate = addDays(parsedScheduleDate, 7);
    }

    return {
      date: dateService.formatAsIso(parsedScheduleDate),
      crewId: getCurrentCrewId(selectedCrews),
      mapDate: dateService.formatAsIso(newMapDate),
    };
  } else {
    return {
      date: dateService.formatAsIso(addDays(scheduleDate, 7)),
      crewId: getCurrentCrewId(selectedCrews),
      mapDate: undefined,
    };
  }
}

export function getScheduleWeekPreviousScheduleParams({
  isMapViewOpen,
  scheduleDate,
  params,
  selectedCrews,
}: {
  isMapViewOpen: boolean;
  scheduleDate: string;
  params: { mapDate: string };
  selectedCrews: Array<ISelectedCrew>;
}) {
  if (isMapViewOpen) {
    const newMapDate = addDays(params.mapDate, -1);
    let parsedScheduleDate = parse(scheduleDate);
    if (newMapDate < parsedScheduleDate) {
      parsedScheduleDate = addDays(parsedScheduleDate, -7);
    }

    return {
      date: dateService.formatAsIso(parsedScheduleDate),
      crewId: getCurrentCrewId(selectedCrews),
      mapDate: dateService.formatAsIso(newMapDate),
    };
  } else {
    return {
      date: format(addDays(scheduleDate, -7), "YYYY-MM-DD"),
      crewId: getCurrentCrewId(selectedCrews),
      mapDate: undefined,
    };
  }
}

export function getScheduleWeekColumnHeader(
  date: Date,
  dayOfWeekLabel: string,
  isCurrentDate: boolean,
  scheduleType: CrewScheduleType
) {
  return (
    <span>
      <Link
        to={
          scheduleType === CrewScheduleType.time
            ? builders.schedule.buildTimeDayRoute(dateService.formatAsIso(date))
            : builders.schedule.buildSequenceDayRoute(
                dateService.formatAsIso(date)
              )
        }
        className={isCurrentDate ? "text-white" : ""}
      >
        {dayOfWeekLabel}{" "}
        <span
          className={isCurrentDate ? "text-white" : ""}
          style={{
            color: !isCurrentDate ? "#495057" : "",
            whiteSpace: "pre",
          }}
        >
          ({formatDateForSchedule(date)})
        </span>
      </Link>
    </span>
  );
}

export function getScheduleWeekRows({
  selectedCrews,
  crews,
  scheduleDates,
  daySchedules,
  params,
  getRoutePath,
}: {
  selectedCrews: ISelectedCrew[];
  crews: ICrew[];
  scheduleDates: string[];
  daySchedules: IDaySchedule[];
  params: { crewId: string; date: string; mapDate: string };
  getRoutePath(date?: string, crewId?: string, mapDate?: string): string;
}): Array<IScheduleRow> {
  return selectedCrews
    .map((selectedCrew) => {
      const matchingCrew = crews.find((c) => c.id === selectedCrew.id);
      if (!matchingCrew) {
        return null;
      }

      return {
        name: matchingCrew.name,
        id: matchingCrew.id,
        expanded: selectedCrew.expanded,
        columns: scheduleDates.map(
          (scheduleDate: string): IScheduleColumn | null => {
            const daySchedule = daySchedules.find(
              (d) => matchingCrew.id === d.crewId && d.date === scheduleDate
            );

            if (daySchedule) {
              const isMapOpen = dateService.areDatesEqual(
                params.mapDate,
                scheduleDate
              );
              const mapUrl = isMapOpen
                ? getRoutePath(
                    dateService.formatAsIso(scheduleDates[0]),
                    matchingCrew.id
                  )
                : getRoutePath(
                    dateService.formatAsIso(scheduleDates[0]),
                    matchingCrew.id,
                    scheduleDate
                  );
              return {
                date: scheduleDate,
                crew: matchingCrew,
                dayScheduleId: daySchedule.id,
                additionalLink: null,
                mapLinkProps: {
                  url: mapUrl,
                  text: isMapOpen
                    ? constants.scheduleHideMapLinkText
                    : constants.scheduleShowMapLinkText,
                },
                isMapOpen,
                loading: daySchedule.initialLoadRunning,
                errorLoading: daySchedule.errorLoading || false,
              } as IScheduleColumn;
            } else {
              return null;
            }
          }
        ),
      };
    })
    .filter((r) => r !== null);
}

export function getScheduleWeekSelectedCrews(
  availableCrews: Array<ICrew>,
  currentParams: { crewId: string }
): Array<ICrew> {
  const sortedCrews = getSortedCrews(availableCrews);
  const activeSortedCrews = sortedCrews.filter((c) => !c.inactive);

  let selectedCrewId: string = getSelectedCrewId(currentParams);

  if (selectedCrewId === constants.allCrewsConstant) {
    return activeSortedCrews;
  }

  if (selectedCrewId === constants.allCrewsSequenceConstant) {
    return activeSortedCrews.filter(
      (c) => c.scheduleType === CrewScheduleType.sequence
    );
  }

  if (selectedCrewId === constants.allCrewsTimeBasedConstant) {
    return activeSortedCrews.filter(
      (c) => c.scheduleType === CrewScheduleType.time
    );
  }

  let crew = selectedCrewId
    ? activeSortedCrews.find(
        (c) => c.id.toLowerCase() === selectedCrewId.toLowerCase()
      )
    : activeSortedCrews.length > 0
    ? activeSortedCrews[0]
    : sortedCrews[0];

  if (!crew) {
    if (activeSortedCrews.length > 0) {
      crew = activeSortedCrews[0];
    } else {
      crew = sortedCrews[0];
    }
  }

  return [crew].filter((c) => typeof c === "object");

  function getSelectedCrewId(currentParams: { crewId: string }) {
    let selectedCrewId: string = "";
    if (currentParams.crewId) {
      selectedCrewId = currentParams.crewId;
    }
    return selectedCrewId;
  }
}

export function getWeekHeaderText(
  scheduleDates: string[],
  params: { date: string }
) {
  return (
    <div>
      {formatDateForSchedule(scheduleDates[0])}
      {" - "}
      {formatDateForSchedule(scheduleDates[scheduleDates.length - 1])}
      <Link to={builders.schedule.buildMapWeekRoute(params.date)}>
        <FontAwesomeIcon
          icon={faMap}
          className="map-link"
          style={{ marginLeft: "15px" }}
        />
      </Link>
    </div>
  );
}

export function getSelectedDateStartOfWeek(currentParams: { date: string }) {
  return startOfWeek(
    currentParams.date ? parse(currentParams.date) : new Date()
  );
}

export function getCurrentCrewId(
  selectedCrewIds: ISelectedCrew[]
): string | undefined {
  return selectedCrewIds.length > 1
    ? constants.allCrewsConstant
    : selectedCrewIds[0]?.id;
}
