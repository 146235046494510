import { TollFreeVerificationStatus } from "../../../enums/tollFreeVerificationStatus";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import useIsAdmin from "../../../hooks/useIsAdmin";
import { TenantSubscriptionStatus } from "../../../models/IInitialLoad";

export function useShowVerificationEnrollmentMessage() {
  const tenantSubscriptionStatus = useApplicationStateSelector(
    (s) => s.common.tenantSubscriptionStatus
  );
  const isAdmin = useIsAdmin();

  const customerTextMessagesSentRecently = useApplicationStateSelector(
    (s) => s.common.customerTextMessagesSentRecently
  );

  const tollFreeVerificationStatus = useApplicationStateSelector(
    (s) => s.common.tollFreeVerificationStatus
  );

  const showVerificationMessage =
    customerTextMessagesSentRecently &&
    tollFreeVerificationStatus === TollFreeVerificationStatus.notStarted &&
    isAdmin &&
    tenantSubscriptionStatus === TenantSubscriptionStatus.Subscribed;

  return showVerificationMessage;
}
