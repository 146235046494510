import AddressFields from "./AddressFields";
import { BusinessType, IOnboardingFormData } from "./IOnboardingFormData";
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { useEffect, useRef } from "react";
import InfoToolTip from "../InfoToolTip";
import PhoneNumberField from "../PhoneNumberField";
import IndustrySelection from "../IndustrySelection";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import CurrencyInputV2 from "../CurrencyInputV2";

interface IProps {
  introText?: React.ReactNode;

  register: UseFormRegister<IOnboardingFormData>;
  control: Control<IOnboardingFormData, object>;
  setValue: UseFormSetValue<IOnboardingFormData>;
  getValues: UseFormGetValues<IOnboardingFormData>;
}

const AboutBusinessTab: React.FunctionComponent<IProps> = ({
  introText,
  register,
  control,
  setValue,
  getValues,
}) => {
  const businessType = useWatch({
    control,
    name: "businessType",
  });

  const einType = useWatch({
    control,
    name: "einType",
  });

  const industry = useApplicationStateSelector((s) => s.common.industry);

  useEffect(() => {
    if (businessType !== null) {
      if (businessType === BusinessType.SolePropietor) {
        setValue("einType", "ssn");
      } else {
        setValue("einType", "tin");
      }
    }
  }, [setValue, businessType]);

  const einRef = useRef<HTMLInputElement | null>(null);
  const { ref: einFormRef, ...einRemainingProps } = register("ein");

  const validFilingNameRegEx = /[^A-Za-z0-9\s!"#$%&'()*+\-.,/:<>?@[\]^_`~{}\\]/;
  const irsFilingNameRef = useRef<HTMLInputElement | null>(null);
  const { ref: irsFilingNameFormRef, ...irsFilingNameRemainingProps } =
    register("irsFilingName", {
      onChange: (e) => {
        const value = e.currentTarget.value;
        setIRSFilingNameValidity(value);
      },
    });

  function setIRSFilingNameValidity(value: string) {
    // Payrix only allows certain fields in the IRS filing name. Check if
    // any non-supported fields were entered.
    const isValidIrsFilingName = validFilingNameRegEx.exec(value);

    irsFilingNameRef.current?.setCustomValidity(
      isValidIrsFilingName !== null && isValidIrsFilingName.length > 0
        ? `The character ${isValidIrsFilingName[0]} is not allowed in this field`
        : ""
    );
  }

  const dbaNameRef = useRef<HTMLInputElement | null>(null);
  const { ref: dbaNameFormRef, ...dbaNameRemainingProps } = register("dba", {
    onChange: (e) => {
      const value = e.currentTarget.value;
      setDBANameValidity(value);
    },
  });

  const dbaNameRegEx = /[^A-Za-z0-9\s_\-'&.,]/;
  function setDBANameValidity(value: string) {
    // Payrix only allows certain fields in the IRS filing name. Check if
    // any non-supported fields were entered.
    const isValidFilingName = dbaNameRegEx.exec(value);

    dbaNameRef.current?.setCustomValidity(
      isValidFilingName !== null && isValidFilingName.length > 0
        ? `The character ${isValidFilingName[0]} is not allowed in this field`
        : ""
    );
  }

  return (
    <>
      {introText ? <div className="form-group">{introText}</div> : null}
      <div className="form-row">
        <div className="col-6 form-group">
          <label htmlFor="businessType" className="required">
            Corporation type
          </label>
          <select
            className="form-control"
            id="businessType"
            required
            {...register("businessType", {
              setValueAs: (v) => (v !== "" ? parseInt(v) : null),
            })}
          >
            <option value=""></option>
            <option value={BusinessType.Llc}>LLC</option>
            <option value={BusinessType.Corp}>CORP</option>
            <option value={BusinessType.SolePropietor}>SOLE PROPIETOR</option>
            <option value={BusinessType.Partner}>PARTNER</option>
            <option value={BusinessType.NonProfit}>NONPROFIT</option>
            <option value={BusinessType.Gov}>GOV</option>
          </select>
        </div>

        {businessType !== null &&
        [BusinessType.Corp, BusinessType.Llc, BusinessType.Partner].includes(
          businessType
        ) ? (
          <div className="col-6 form-group">
            <label>&nbsp;</label>
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="publicCompany"
                {...register("publicCompany")}
              />
              <label className="custom-control-label" htmlFor="publicCompany">
                This is a public company
              </label>
            </div>
          </div>
        ) : null}
      </div>
      <div className="form-group">
        <label htmlFor="irsFilingName" className="required">
          IRS filing name
        </label>
        <input
          type="text"
          className="form-control"
          id="irsFilingName"
          maxLength={40}
          required
          {...irsFilingNameRemainingProps}
          ref={(r) => {
            irsFilingNameFormRef(r);
            irsFilingNameRef.current = r;
            setIRSFilingNameValidity(irsFilingNameRef?.current?.value ?? "");
          }}
        />
      </div>

      <div className="form-group">
        <label htmlFor="dba">Business name seen by customers</label>
        <input
          type="text"
          className="form-control"
          id="dba"
          data-testid="dbaName"
          maxLength={40}
          {...dbaNameRemainingProps}
          ref={(r) => {
            dbaNameFormRef(r);
            dbaNameRef.current = r;
            setDBANameValidity(dbaNameRef?.current?.value ?? "");
          }}
        />
      </div>

      {typeof industry !== "number" ? (
        <div className="form-row">
          <div className="col form-group">
            <label htmlFor="industry" className="required">
              Industry
            </label>
            <IndustrySelection
              className="form-control"
              id="industry"
              required
              {...register("industry")}
            />
          </div>
        </div>
      ) : null}

      <div className="form-row">
        <div className="col form-group">
          <label htmlFor="einType" className="required">
            EIN type
          </label>
          <InfoToolTip
            id="einTypeToolTip"
            text="The 9-digit business identification code as issued by the IRS. If this business is a sole proprietorship, you will choose SSN instead."
          />

          <select
            className="form-control"
            id="einType"
            required
            {...register("einType")}
          >
            <option value=""></option>
            <option value="ssn">SSN</option>
            <option value="tin">TIN</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="col form-group">
          <label htmlFor="ein" className="required">
            EIN
          </label>
          {einType === "other" ? (
            <input
              type="text"
              minLength={9}
              maxLength={9}
              inputMode="numeric"
              className="form-control fs-mask"
              id="ein"
              required
              {...einRemainingProps}
              ref={(r) => {
                einFormRef(r);
                einRef.current = r;
              }}
              onChange={(e) => {
                const value = e.target.value;

                const isValidEin = /^[A-Za-z0-9]+$/.test(value);

                einRef.current?.setCustomValidity(
                  !isValidEin ? "EIN can only contain letters and numbers" : ""
                );
              }}
            />
          ) : (
            <Controller
              name={`ein`}
              control={control}
              render={({ field }) => (
                <NumberFormat
                  id="ein"
                  placeholder={einType === "ssn" ? "xxx-xx-xxxx" : "xx-xxxxxxx"}
                  className="form-control fs-mask"
                  format={einType === "ssn" ? "###-##-####" : "##-#######"}
                  mask="_"
                  required
                  inputMode="numeric"
                  getInputRef={(el: HTMLInputElement) => {
                    einFormRef(el);
                    einRef.current = el;
                  }}
                  {...field}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value?.trim() ?? "";
                    if (einRef.current) {
                      const validateRegex =
                        einType === "ssn"
                          ? /^\d{3}-\d{2}-\d{4}/
                          : /^\d{2}-\d{7}/;
                      const ssnInvalid =
                        value && !validateRegex.test(value.trim());
                      einRef.current.setCustomValidity(
                        ssnInvalid
                          ? "EIN can only contain letters and numbers"
                          : ""
                      );
                    }

                    if (field.onChange) {
                      field.onChange(
                        typeof value === "string" && value
                          ? value.replace(/-/g, "").replace(/_/g, "")
                          : value
                      );
                    }
                  }}
                />
              )}
            />
          )}
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="website">
          Website{" "}
          <small>
            (required if you plan on texting your customers from Crew Control)
          </small>
        </label>
        <input
          type="url"
          className="form-control"
          id="website"
          placeholder="https://www.example.com/"
          {...register("website", {
            onBlur: () => {
              const website = getValues("website");
              if (website.trim() === "") {
                return;
              }

              const lowerCaseWebsite = website.toLowerCase();
              if (
                !lowerCaseWebsite.startsWith("http://") &&
                !lowerCaseWebsite.startsWith("https://")
              ) {
                // Needs to run after blur is done. react-hook-form's blur handler calls this onBlur and
                // then sets the value.
                setTimeout(() => setValue("website", `http://${website}`));
              }
            },
          })}
        />
      </div>

      <div className="form-row">
        <div className="col form-group">
          <label htmlFor="annualProcessingVolume" className="required">
            Annual processing amount
          </label>
          <Controller
            name={`annualProcessingVolume`}
            control={control}
            render={({ field }) => (
              <CurrencyInputV2
                id="annualProcessingVolume"
                data-testid="annualProcessingVolume"
                placeholder="$0.00"
                className="form-control"
                required
                value={field.value}
                onValueChange={(e) => {
                  field.onChange(e);
                }}
                min={10000}
              />
            )}
          />
        </div>
        <div className="col form-group">
          <label htmlFor="averageTransactionAmount" className="required">
            Average transaction amount
          </label>
          <Controller
            name={`averageTransactionAmount`}
            control={control}
            render={({ field }) => (
              <CurrencyInputV2
                id="averageTransactionAmount"
                data-testid="averageTransactionAmount"
                placeholder="$0.00"
                className="form-control"
                required
                value={field.value}
                onValueChange={(e) => {
                  field.onChange(e);
                }}
                min={1}
                max={
                  parseFloat(
                    typeof getValues("annualProcessingVolume") === "string" &&
                      getValues("annualProcessingVolume").trim() !== ""
                      ? getValues("annualProcessingVolume")
                      : "2"
                  ) - 1
                }
              />
            )}
          />
        </div>
      </div>
      <div
        className="alert alert-info"
        style={{ padding: "5px", paddingLeft: "10px" }}
      >
        <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
        <small>
          Answering the amount fields accurately will ensure you get paid
          quickly and without holdups.
        </small>
      </div>

      <div className="form-row">
        <div className="col form-group">
          <label htmlFor="businessEmail" className="required">
            Business email
          </label>
          <input
            maxLength={100}
            type="email"
            className="form-control"
            id="businessEmail"
            required
            {...register("businessEmail")}
          />
        </div>
        <div className="col form-group">
          <label htmlFor="daytimePhone" className="required">
            Daytime phone
          </label>
          <Controller
            name="daytimePhone"
            control={control}
            render={({ field }) => (
              <PhoneNumberField
                id="daytimePhone"
                className="form-control"
                required={true}
                value={field.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  field.onChange(e.currentTarget.value);
                }}
              />
            )}
          />
        </div>
      </div>

      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <AddressFields
            idPrefix="business"
            addressLabel="Business address"
            address={field.value}
            onChange={(newAddress) => field.onChange(newAddress)}
            invalidAddressRegEx={
              new RegExp(
                "\\b(?:p\\.?\\s*o\\.?|post\\s+office)(\\s+)?(?:box|[0-9]*)?\\b",
                "i"
              )
            }
            invalidAddressMessage="The address cannot contain a PO Box. Please provide the physical location."
          />
        )}
      />
    </>
  );
};

export default AboutBusinessTab;
