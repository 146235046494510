import { OpportunityProposalStatus } from "../enums/opportunityProposalStatus";
import { IOpportunity } from "../models/IOpportunity";
import { IProposalExisting } from "../models/IProposal";

export function showPaymentMethodNotAuthorizedIndicator(
  opportunity: IOpportunity,
  proposal: IProposalExisting | null
) {
  const statusValid =
    opportunity.proposalStatus === OpportunityProposalStatus.Accepted ||
    opportunity.proposalStatus === OpportunityProposalStatus.Scheduled;

  return (
    statusValid &&
    proposal?.captureCustomerPaymentMethod &&
    !proposal?.captureCustomerPaymentMethodOptional &&
    !proposal?.paymentMethodOnFileAuthorized
  );
}
