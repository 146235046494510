import { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import constants from "../../../constants";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { IJob } from "../../../models/IJob";
import dateService from "../../../services/dateService";
import jobFinder from "../../../services/jobFinder";
import { getNameForJob } from "../../../services/jobService";
import { actionCreators } from "../../../modules/actionCreators";
import { getDaysToLoad } from "../../../services/dayScheduleLoader";
import { IDaySchedule } from "../../../models/IDaySchedule";
import React from "react";

export function OneTimeJobOrderingDropDown({
  destinationDate,
  destinationCrewId,
  destinationPrecedingJobInstanceId,
  onDestinationPrecedingJobInstanceIdChanged,
  jobInstanceIdsToExclude,
  destinationUnscheduledJobs,
}: {
  destinationDate: string;
  destinationCrewId: string | null;
  destinationPrecedingJobInstanceId: string | null;
  destinationUnscheduledJobs: boolean;
  onDestinationPrecedingJobInstanceIdChanged: (newValue: string) => void;
  jobInstanceIdsToExclude: Array<string> | null;
}) {
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );

  useHandleDestinationDayScheduleChanging({
    destinationCrewId,
    destinationDate,
    daySchedules,
    onDestinationPrecedingJobInstanceIdChanged,
  });

  const daySchedule = getDaySchedule({
    daySchedules,
    destinationCrewId,
    destinationDate,
  });

  const jobInstancesForCrewAndDate = useGetJobInstancesForCrewAndDate(
    destinationDate,
    daySchedule,
    jobInstanceIdsToExclude
  );

  const isDayScheduleLoading = !daySchedule || daySchedule.initialLoadRunning;
  return (
    <div className="form-group">
      <label htmlFor="destinationPrecedingJobInstanceId">Job ordering</label>
      <select
        id="destinationPrecedingJobInstanceId"
        name="destinationPrecedingJobInstanceId"
        data-testid="destinationPrecedingJobInstanceId"
        className="form-control"
        value={destinationPrecedingJobInstanceId || constants.idForFirstJob}
        onChange={(e) =>
          onDestinationPrecedingJobInstanceIdChanged(e.currentTarget.value)
        }
        disabled={isDayScheduleLoading || destinationUnscheduledJobs}
      >
        {destinationUnscheduledJobs ? (
          <option>Not applicable for flexible jobs</option>
        ) : !destinationDate ? (
          <option value="">Select date to choose job ordering</option>
        ) : !destinationCrewId ? (
          <option value="">Select crew to choose job ordering</option>
        ) : isDayScheduleLoading ? (
          <option value="">Loading jobs...</option>
        ) : jobInstancesForCrewAndDate.length === 0 ? (
          <option value="">No jobs exist on the day</option>
        ) : (
          <React.Fragment>
            <option value={constants.idForFirstJob}>
              First job of the day
            </option>
            {jobInstancesForCrewAndDate.length > 0 ? (
              <option value={constants.idForLastJob}>
                Last job of the day
              </option>
            ) : null}
            {jobInstancesForCrewAndDate.map((jobInstance) => (
              <option value={jobInstance.id} key={jobInstance.id}>
                After {jobInstance.name}
              </option>
            ))}
          </React.Fragment>
        )}
      </select>
    </div>
  );
}
function getDaySchedule({
  daySchedules,
  destinationCrewId,
  destinationDate,
}: {
  daySchedules: Array<IDaySchedule>;
  destinationCrewId: string | null;
  destinationDate: string;
}) {
  return daySchedules.find(
    (ds) =>
      ds.crewId === destinationCrewId &&
      dateService.areDatesEqual(ds.date, destinationDate)
  );
}

function useHandleDestinationDayScheduleChanging({
  destinationCrewId,
  destinationDate,
  daySchedules,
  onDestinationPrecedingJobInstanceIdChanged,
}: {
  destinationCrewId: string | null;
  destinationDate: string;
  daySchedules: Array<IDaySchedule>;
  onDestinationPrecedingJobInstanceIdChanged: (newValue: string) => void;
}) {
  const crews = useApplicationStateSelector((s) => s.crew.crews);

  const dispatch = useDispatch();
  const oldDestinationCrewId = useRef<string | null>(null);
  const oldDestinationDate = useRef<string | null>(null);
  const isInitialLoad = useRef(true);
  useEffect(() => {
    if (
      oldDestinationCrewId.current !== destinationCrewId ||
      oldDestinationDate.current !== destinationDate
    ) {
      if (destinationCrewId && destinationDate) {
        const missingDates = getDaysToLoad({
          loadedDaySchedules: daySchedules,
          crewIds: [destinationCrewId],
          dates: [dateService.formatAsIso(destinationDate)],
        });

        if (missingDates.length > 0) {
          dispatch(
            actionCreators.loadDaySchedulesStarting(missingDates, [], null)
          );
        }
      }

      if (!isInitialLoad.current) {
        onDestinationPrecedingJobInstanceIdChanged(constants.idForFirstJob);
      }

      oldDestinationCrewId.current = destinationCrewId;
      oldDestinationDate.current = destinationDate;
      isInitialLoad.current = false;
    }
  }, [
    destinationCrewId,
    destinationDate,
    crews,
    daySchedules,
    dispatch,
    onDestinationPrecedingJobInstanceIdChanged,
  ]);
}

function useGetJobInstancesForCrewAndDate(
  destinationDate: string,
  daySchedule: IDaySchedule | undefined,
  jobInstanceIdsToExclude: string[] | null
) {
  const jobs = useApplicationStateSelector((s) => s.job.jobs);
  const oneTimeJobs = useApplicationStateSelector((s) => s.job.oneTimeJobs);
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );

  let jobInstancesForCrewAndDate: Array<{ id: string; name: string }> = [];

  if (destinationDate) {
    if (daySchedule) {
      jobInstancesForCrewAndDate = daySchedule.jobInstances
        .filter((ji) => {
          return (
            jobFinder.getJobForDayScheduleV2(jobs, oneTimeJobs, ji) !== null
          );
        })
        .map((ji) => {
          const job = jobFinder.getJobForDayScheduleV2(
            jobs,
            oneTimeJobs,
            ji
          ) as IJob;

          return {
            id: ji.id,
            name: getNameForJob({
              job,
              customers,
              customerAdditionalLocations,
              fallbackToAddressIfAdditionalLocationNameNotSet: true,
            }),
          };
        });
    }
  }

  if (jobInstanceIdsToExclude && jobInstanceIdsToExclude.length === 1) {
    jobInstancesForCrewAndDate = jobInstancesForCrewAndDate.filter(
      (j) => j.id !== jobInstanceIdsToExclude[0]
    );
  }
  return jobInstancesForCrewAndDate;
}
