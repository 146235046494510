import { createEmailAddressRecordsForCustomer } from "../../../../containers/app/components/EmailAddresses.functions";
import { ICustomer } from "../../../../models/ICustomer";

export default function getCustomerFields(customer: ICustomer | null) {
  return {
    customerPhoneNumber: customer?.phoneNumber ?? "",
    customerPhoneNumberOptedIntoSms: customer?.phoneNumberOptedIntoSms ?? false,
    customerEmailAddresses: createEmailAddressRecordsForCustomer(customer),
  };
}
