import {
  faCreditCard,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";
import { IInvoiceView } from "../../../models/IInvoice";
import dateService from "../../../services/dateService";

const BillingInvoicesListDate: React.FunctionComponent<{
  invoice: IInvoiceView;
}> = ({ invoice }) => {
  const [showPopover, setShowPopover] = useState(false);

  const iconId = `invoiceDateExclamationIcon_${invoice.id}`;

  let showIcon = false;
  let icon = faExclamationCircle;
  let iconClass = "";
  let iconText = "";
  if (invoice.paymentMethodOnFileChargePending) {
    showIcon = true;
    icon = faExclamationCircle;
    iconText = "Invoice Will Be Charged Automatically";
  } else if ((invoice.createPayrixTransactionFailureMessage ?? "").trim()) {
    showIcon = true;
    icon = faExclamationCircle;
    iconClass = "text-danger";
    iconText = invoice.createPayrixTransactionFailureMessage;
  } else if (invoice.paymentPending) {
    showIcon = true;
    icon = faExclamationCircle;
    iconText = "Payment Pending";
  } else if (typeof invoice.daysPastDue === "number") {
    showIcon = true;
    icon = faExclamationCircle;
    iconClass = "text-danger";
    iconText = `Past due by ${invoice.daysPastDue} days`;
  } else if (
    invoice.payments.some((p) => typeof p.payrixTransactionAmount === "number")
  ) {
    showIcon = true;
    icon = faCreditCard;
    iconText = "Paid through Crew Control";
  }
  return (
    <>
      <span style={{ width: "50px" }}>
        {dateService.formatDateForDisplay(invoice.date, "MM/DD/YYYY")}
      </span>
      {showIcon ? (
        <>
          <FontAwesomeIcon
            id={iconId}
            icon={icon}
            className={`ml-1 ${iconClass}`}
            style={{ cursor: "pointer" }}
            data-testid="paymentPendingExclamation"
          />

          <span className="sr-only">{iconText}</span>

          <Popover
            delay={0}
            trigger="legacy"
            placement="bottom"
            isOpen={showPopover}
            target={iconId}
            toggle={() => {
              setShowPopover(!showPopover);
            }}
          >
            <PopoverBody>{iconText}</PopoverBody>
          </Popover>
        </>
      ) : null}
    </>
  );
};

export default BillingInvoicesListDate;
