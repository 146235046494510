import React, { Fragment, useState, useEffect, CSSProperties } from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import { builders as routerBuilders, parsers } from "../../../services/routing";
import { Link } from "react-router-dom";
import { IRootState } from "../../../store";

import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavbarToggler,
  Nav,
  NavItem,
  Dropdown,
} from "reactstrap";
import auth from "../../../services/Auth";
import dateService from "../../../services/dateService";
import getDefaultsForScheduleLink from "../../../services/navigationService";
import {
  commonUiActionCreators,
  QuickBooksModalMode,
} from "../../../modules/commonUi";
import TenantSubscriptionStatusAlert from "../../../slices/tenantSubscription/components/TenantSubscriptionStatusAlert";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { TenantSubscriptionStatus } from "../../../models/IInitialLoad";
import { isMobileOnly } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faCreditCard,
  faHandshake,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCog,
  faPieChart,
  faQuestionCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import useIsAdmin from "../../../hooks/useIsAdmin";
import { ManageSubscription } from "../../../slices/tenantSubscription/components/ManageSubscription";
import UserNotificationsForm from "../forms/UserNotificationsForm";
import InvoiceConfigurationForm from "../forms/InvoiceConfigurationForm";
import { useUserSettings } from "../../../services/userSettingsService";

export const navBarButtonStyle: Partial<CSSProperties> = {
  backgroundColor: "transparent",
  border: 0,
  cursor: "pointer",
};

interface IProps extends IOwnProps {
  showCrewViewConfigurationForm(parameters: any): void;
  showAdminViewConfigurationForm(parameters: any): void;
  showQuickBooksConfigurationForm(parameters: any): void;
  showScheduledDispatchSettingsForm(parameters: any): void;
  showCompanyProfileForm(parameters: any): void;
  onNavigationExpanded?: (expanded: boolean) => void;
  showCustomerNotificationsConfigurationForm(parameters: any): void;
  showAdminUsersManageModal(): void;
  showMerchantOnboardingModal(): void;
}

interface IOwnProps {
  showPrimaryLinks: boolean;
  isAuthenticated: boolean;
  showSettings: boolean;
  crewId: string | undefined;
}

const NavbarNavigation: React.FunctionComponent<IProps> = ({
  showPrimaryLinks,
  showSettings,
  crewId,
  showCrewViewConfigurationForm,
  showAdminViewConfigurationForm,
  showQuickBooksConfigurationForm,
  showScheduledDispatchSettingsForm,
  showCompanyProfileForm,
  showCustomerNotificationsConfigurationForm,
  showAdminUsersManageModal,
  showMerchantOnboardingModal,
  onNavigationExpanded,
  isAuthenticated,
}) => {
  const [showOverallNavbar, setShowOverallNavbar] = useState(false);
  const showPayrollTimeTracking = useApplicationStateSelector(
    (s) => s.common.crewViewConfiguration.showPayrollTimeTracking
  );
  const tenantSubscriptionStatus = useApplicationStateSelector(
    (s) => s.common.tenantSubscriptionStatus
  );
  const canManageSubscription = useApplicationStateSelector(
    (s) => s.common.canManageSubscription
  );
  const subscriptionCancellationPending = useApplicationStateSelector(
    (s) => s.common.subscriptionCancellationPending
  );

  const routerState = useApplicationStateSelector((s) => s.router);
  const payrixOnboardingAllowed = useApplicationStateSelector(
    (s) => s.common.payrixOnboardingAllowed
  );
  const crews = useApplicationStateSelector((s) => s.crew.crews);

  // Blocking Handyman Anderson LLC from help access
  const isBlocked =
    useApplicationStateSelector((s) => s.common.tenantId) ===
    "fc68d988-0bab-4a6d-aa48-b4715bddc451";

  // Payment Onboarding won't be available to Canadian tenants or not subscribed
  const paymentsOnboardingAllowed =
    payrixOnboardingAllowed &&
    tenantSubscriptionStatus === TenantSubscriptionStatus.Subscribed;

  const [showManageSubscriptionModal, setShowManageSubscriptionModal] =
    useState(false);

  const [showUserNotificationsForm, setShowUserNotificationsForm] =
    useState(false);

  const [showInvoiceConfigurationForm, setShowInvoiceConfigurationForm] =
    useState(false);

  const [scheduleDropdownOpen, setScheduleDropdownOpen] = useState(false);
  const [manageDropdownOpen, setManageDropdownOpen] = useState(false);
  const [reportsDropdownOpen, setReportsDropdownOpen] = useState(false);
  const [settingsDropdownOpen, setSettingsDropdownOpen] = useState(false);
  const [helpDropdownOpen, setHelpDropdownOpen] = useState(false);
  const isAdmin = useIsAdmin();
  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );
  const { getUserSettings } = useUserSettings();

  useEffect(() => {
    if (onNavigationExpanded) {
      if (!showOverallNavbar) {
        // Give time for animation to run
        setTimeout(() => onNavigationExpanded(showOverallNavbar), 450);
      } else {
        onNavigationExpanded(showOverallNavbar);
      }
    }
  }, [showOverallNavbar, onNavigationExpanded]);

  let dailyReportDate = new Date();
  const dayScheduleMatchReport = parsers.schedule.tryParseDaySequence(
    routerState,
    crews
  );
  if (dayScheduleMatchReport.isMatch && dayScheduleMatchReport.dateInRoute) {
    dailyReportDate = dayScheduleMatchReport.dateInRoute;
  }

  const { date: dateForScheduleLink, crewId: crewIdForScheduleLink } =
    getDefaultsForScheduleLink(routerState, crews);
  return (
    <Fragment>
      <NavbarToggler onClick={() => setShowOverallNavbar(!showOverallNavbar)} />
      <Collapse isOpen={showOverallNavbar} navbar>
        <Nav className="mr-auto" navbar>
          {showPrimaryLinks ? (
            <React.Fragment>
              {isMobileOnly ? (
                <NavItem>
                  <Link
                    className="nav-link"
                    to={routerBuilders.schedule.buildWeekRoute(
                      getUserSettings,
                      crews,
                      dateService.formatAsIso(dateForScheduleLink),
                      crewId
                    )}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCalendarCheck}
                        className="navbar-icon"
                      />
                      Calendar
                    </div>
                  </Link>
                </NavItem>
              ) : (
                <Dropdown
                  nav
                  inNavbar
                  isOpen={scheduleDropdownOpen}
                  toggle={() => setScheduleDropdownOpen((o) => !o)}
                >
                  <DropdownToggle nav caret>
                    <FontAwesomeIcon
                      icon={faCalendarCheck}
                      className="navbar-icon"
                    />
                    Calendar
                  </DropdownToggle>
                  <DropdownMenu>
                    <Link
                      className="dropdown-item"
                      to={routerBuilders.schedule.buildDayRoute(
                        getUserSettings,
                        crews,
                        dateService.formatAsIso(dateForScheduleLink)
                      )}
                      onClick={() => setScheduleDropdownOpen(false)}
                      role="menuitem"
                    >
                      Day
                    </Link>

                    <Link
                      className="dropdown-item"
                      to={routerBuilders.schedule.buildWeekRoute(
                        getUserSettings,
                        crews,
                        dateService.formatAsIso(dateForScheduleLink),
                        crewId
                      )}
                      onClick={() => setScheduleDropdownOpen(false)}
                      role="menuitem"
                    >
                      Week
                    </Link>

                    <Link
                      className="dropdown-item"
                      to={routerBuilders.schedule.buildMonthRoute(
                        dateService.formatAsIso(dateForScheduleLink),
                        crewIdForScheduleLink ?? undefined
                      )}
                      onClick={() => setScheduleDropdownOpen(false)}
                      role="menuitem"
                    >
                      Month
                    </Link>
                  </DropdownMenu>
                </Dropdown>
              )}
              <Dropdown
                nav
                inNavbar
                isOpen={manageDropdownOpen}
                toggle={() => setManageDropdownOpen((o) => !o)}
              >
                <DropdownToggle nav caret>
                  <FontAwesomeIcon icon={faUser} className="navbar-icon" />
                  Manage
                </DropdownToggle>
                <DropdownMenu>
                  {isAdmin ? (
                    <>
                      <Link
                        className="dropdown-item"
                        to={routerBuilders.manage.buildCrewsRoute()}
                        onClick={() => setManageDropdownOpen(false)}
                        role="menuitem"
                      >
                        Crews
                      </Link>

                      <Link
                        className="dropdown-item"
                        to={routerBuilders.manage.buildCrewMembersRoute()}
                        onClick={() => setManageDropdownOpen(false)}
                        role="menuitem"
                      >
                        Crew Members
                      </Link>
                    </>
                  ) : null}

                  <Link
                    className="dropdown-item"
                    to={routerBuilders.manage.buildCustomersRoute()}
                    onClick={() => setManageDropdownOpen(false)}
                    role="menuitem"
                  >
                    Customers
                  </Link>
                </DropdownMenu>
              </Dropdown>
              {isAdmin ? (
                <Dropdown
                  nav
                  inNavbar
                  isOpen={reportsDropdownOpen}
                  toggle={() => setReportsDropdownOpen((o) => !o)}
                >
                  <DropdownToggle nav caret>
                    <FontAwesomeIcon
                      icon={faPieChart}
                      className="navbar-icon"
                    />
                    Reports
                  </DropdownToggle>
                  <DropdownMenu>
                    <Link
                      className="dropdown-item"
                      to={routerBuilders.completedWork.buildReportRoute()}
                      onClick={() => setReportsDropdownOpen(false)}
                      role="menuitem"
                    >
                      Work History
                    </Link>
                    <Link
                      className="dropdown-item"
                      to={routerBuilders.report.buildDailyRoute(
                        dateService.formatAsIso(dailyReportDate),
                        crewId
                      )}
                      onClick={() => setReportsDropdownOpen(false)}
                      role="menuitem"
                    >
                      Daily Details
                    </Link>
                    {showPayrollTimeTracking && (
                      <Link
                        className="dropdown-item"
                        to={routerBuilders.report.buildEmployeeTimes()}
                        onClick={() => setReportsDropdownOpen(false)}
                        role="menuitem"
                      >
                        Employee Times
                      </Link>
                    )}
                  </DropdownMenu>
                </Dropdown>
              ) : null}
              {isAdmin ? (
                <NavItem>
                  <Link
                    className="nav-link"
                    to={routerBuilders.sales.buildSalesPage()}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faHandshake}
                        className="navbar-icon"
                      />
                      Sales
                    </div>
                  </Link>
                </NavItem>
              ) : null}

              {isAdmin ? (
                <NavItem>
                  <Link
                    className="nav-link"
                    to={routerBuilders.billing.buildIndexRoute()}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "nowrap",
                        alignItems: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCreditCard}
                        className="navbar-icon"
                      />
                      Billing
                    </div>
                  </Link>
                </NavItem>
              ) : null}
            </React.Fragment>
          ) : null}
        </Nav>
        {isAuthenticated ? (
          <Fragment>
            <TenantSubscriptionStatusAlert
              containerClassName="d-none d-xl-inline-flex"
              containerStyle={{ marginRight: "15px" }}
            />
            {showSettings ? (
              <Nav navbar>
                {showSettings && isAdmin ? (
                  <Dropdown
                    nav
                    inNavbar
                    isOpen={settingsDropdownOpen}
                    toggle={() => setSettingsDropdownOpen((o) => !o)}
                  >
                    <DropdownToggle nav caret>
                      <FontAwesomeIcon icon={faCog} className="navbar-icon" />
                      Settings
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        className="dropdown-item-link-button"
                        onClick={() => showCrewViewConfigurationForm({})}
                      >
                        Crew View Display
                      </DropdownItem>

                      <DropdownItem
                        className="dropdown-item-link-button"
                        onClick={() => showAdminViewConfigurationForm({})}
                      >
                        Admin View Display
                      </DropdownItem>

                      <DropdownItem
                        className="dropdown-item-link-button"
                        onClick={() => showScheduledDispatchSettingsForm({})}
                      >
                        Dispatch Scheduling
                      </DropdownItem>

                      <Link
                        className="dropdown-item"
                        to={routerBuilders.manage.buildCustomerCommunicationRoute()}
                        onClick={() => setSettingsDropdownOpen(false)}
                      >
                        Customer Communication
                      </Link>

                      <DropdownItem
                        className="dropdown-item-link-button"
                        onClick={() => {
                          setShowUserNotificationsForm(true);
                        }}
                      >
                        My Notifications
                      </DropdownItem>

                      <Link
                        className="dropdown-item"
                        to={routerBuilders.manage.buildTodoTemplatesRoute()}
                        onClick={() => setSettingsDropdownOpen(false)}
                      >
                        Checklist Templates
                      </Link>

                      {!isQuickBooksEnabled ? (
                        <Link
                          className="dropdown-item"
                          to={routerBuilders.manage.buildLineItemsRoute()}
                          onClick={() => setSettingsDropdownOpen(false)}
                        >
                          Products &amp; Services
                        </Link>
                      ) : null}

                      <DropdownItem
                        className="dropdown-item-link-button"
                        onClick={() => {
                          setShowInvoiceConfigurationForm(true);
                        }}
                      >
                        Invoice Configuration
                      </DropdownItem>

                      <DropdownItem
                        className="dropdown-item-link-button"
                        onClick={() =>
                          showQuickBooksConfigurationForm(
                            QuickBooksModalMode.configuration
                          )
                        }
                      >
                        QuickBooks Link
                      </DropdownItem>

                      <DropdownItem
                        className="dropdown-item-link-button"
                        onClick={() => showCompanyProfileForm({})}
                      >
                        Company Profile
                      </DropdownItem>

                      {tenantSubscriptionStatus ===
                        TenantSubscriptionStatus.Subscribed &&
                      canManageSubscription &&
                      !subscriptionCancellationPending ? (
                        <DropdownItem
                          className="dropdown-item-link-button"
                          onClick={() => {
                            setShowManageSubscriptionModal(true);
                          }}
                        >
                          Manage Subscription
                        </DropdownItem>
                      ) : null}

                      <DropdownItem
                        className="dropdown-item-link-button"
                        onClick={() => showAdminUsersManageModal()}
                      >
                        Manage Admin Users
                      </DropdownItem>

                      {paymentsOnboardingAllowed ? (
                        <DropdownItem
                          className="dropdown-item-link-button"
                          onClick={() => showMerchantOnboardingModal()}
                        >
                          Payments Onboarding
                        </DropdownItem>
                      ) : null}
                    </DropdownMenu>
                  </Dropdown>
                ) : null}

                {isAuthenticated && !isBlocked ? (
                  <>
                    <Dropdown
                      nav
                      inNavbar
                      isOpen={helpDropdownOpen}
                      toggle={() => setHelpDropdownOpen((o) => !o)}
                      className="d-inline d-md-none"
                    >
                      <DropdownToggle nav caret>
                        <FontAwesomeIcon
                          icon={faQuestionCircle}
                          className="navbar-icon"
                        />
                        Help
                      </DropdownToggle>
                      <DropdownMenu right>
                        <a
                          href="https://youraspire.atlassian.net/servicedesk/customer/portal/9"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="dropdown-item"
                        >
                          Contact Support
                        </a>
                        <a
                          href="https://aspire-extensions.document360.io/crew-control/docs/about-crew-control"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="dropdown-item"
                        >
                          Learning Center
                        </a>
                      </DropdownMenu>
                    </Dropdown>
                    <NavItem>
                      <button
                        style={navBarButtonStyle}
                        className="nav-link"
                        type="button"
                        onClick={() => auth.logout()}
                      >
                        Logout
                      </button>
                    </NavItem>
                  </>
                ) : null}
              </Nav>
            ) : null}
          </Fragment>
        ) : null}
      </Collapse>

      {showManageSubscriptionModal ? (
        <ManageSubscription
          onClose={() => setShowManageSubscriptionModal(false)}
        />
      ) : null}

      {showUserNotificationsForm ? (
        <UserNotificationsForm
          onClose={() => setShowUserNotificationsForm(false)}
        />
      ) : null}

      {showInvoiceConfigurationForm ? (
        <InvoiceConfigurationForm
          onClose={() => setShowInvoiceConfigurationForm(false)}
          onSaveComplete={() => setShowInvoiceConfigurationForm(false)}
        />
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => ({});

const mapDispatchToProps = {
  showCrewViewConfigurationForm:
    actionCreators.forms.crewViewConfiguration.showForm,
  showAdminViewConfigurationForm:
    actionCreators.forms.adminViewConfiguration.showForm,
  showQuickBooksConfigurationForm: commonUiActionCreators.showQuickBooksModal,
  showScheduledDispatchSettingsForm:
    actionCreators.forms.scheduledDispatchSettings.showForm,
  showCompanyProfileForm: actionCreators.forms.companyProfile.showForm,
  showCustomerNotificationsConfigurationForm:
    actionCreators.forms.customerNotificationsConfiguration.showForm,
  showAdminUsersManageModal: commonUiActionCreators.showAdminUsersManageModal,
  showMerchantOnboardingModal:
    commonUiActionCreators.showMerchantOnboardingModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarNavigation);
