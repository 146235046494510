import InfoToolTip from "../../../containers/app/components/InfoToolTip";
import { SubscriptionFrequency } from "../../../enums/subscriptionFrequency";
import { formatCurrencyWithoutDecimalDigits } from "../../../services/currencyFormatter";
import { GetSubscriptionPriceResult } from "../services/crewControlSubscriptionService";

export function SubscriptionPriceBreakdown({
  subscriptionFrequency,
  numberOfCrews,
  tooltipElementId,
  subscriptionPriceResult,
}: {
  subscriptionFrequency: SubscriptionFrequency;
  numberOfCrews: number;
  tooltipElementId: string;
  subscriptionPriceResult: GetSubscriptionPriceResult;
}) {
  return (
    <div data-testid="amountDetailsText" className="mb-1">
      {numberOfCrews} {numberOfCrews === 1 ? "crew" : "crews"}
      {subscriptionFrequency !== SubscriptionFrequency.month ? (
        <>
          {" "}
          at{" "}
          {formatCurrencyWithoutDecimalDigits(
            subscriptionPriceResult.totalCostPerMonth
          )}{" "}
          / month
        </>
      ) : null}
      <PriceTooltip
        elementId={tooltipElementId}
        subscriptionPriceResult={subscriptionPriceResult}
      />
    </div>
  );
}

function PriceTooltip({
  elementId,
  subscriptionPriceResult,
}: {
  elementId: string;
  subscriptionPriceResult: GetSubscriptionPriceResult;
}) {
  let contents: React.ReactNode;
  if (
    subscriptionPriceResult.basePricePerMonth !== null &&
    subscriptionPriceResult.basePriceCrewCountCutOff !== null
  ) {
    contents = (
      <>
        <div data-testid="priceBreakdownOne">
          {formatCurrencyWithoutDecimalDigits(
            subscriptionPriceResult.basePricePerMonth
          )}{" "}
          a month for {subscriptionPriceResult.basePriceCrewCountCutOff} crews.
        </div>
        <div data-testid="priceBreakdownTwo">
          {formatCurrencyWithoutDecimalDigits(
            subscriptionPriceResult.additionalUnitPricePerMonth
          )}{" "}
          for each additional crew.
        </div>
      </>
    );
  } else {
    contents = (
      <div data-testid="priceBreakdownOne">
        {formatCurrencyWithoutDecimalDigits(
          subscriptionPriceResult.additionalUnitPricePerMonth
        )}{" "}
        for each crew.
      </div>
    );
  }

  return (
    <>
      <InfoToolTip id={elementId} title="Price Breakdown" text={contents} />
    </>
  );
}
