import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Popover, PopoverBody } from "reactstrap";

interface IProps {
  id: string;
  text: string | React.ReactNode;
  title?: string;
  testId?: string;
}

const InfoToolTip: React.FunctionComponent<IProps> = ({
  id,
  text,
  title,
  testId,
}) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <FontAwesomeIcon
        data-testid={testId}
        icon={faInfoCircle}
        style={{ marginLeft: "5px", cursor: "pointer" }}
        id={id}
        title={title}
        onClick={(e) => {
          e.stopPropagation();
          setShow(!show);
        }}
      />
      <Popover
        delay={0}
        // fade={false}
        trigger="legacy"
        placement="bottom"
        isOpen={show}
        target={id}
        toggle={() => {
          setShow(!show);
        }}
      >
        <PopoverBody>{text}</PopoverBody>
      </Popover>
    </>
  );
};

export default InfoToolTip;
