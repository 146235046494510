import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import FormContainerWithoutRedux from "../components/FormContainerWithoutRedux";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import ModalDataLoader from "../components/ModalDataLoader";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { IUserNotificationsSettings } from "../../../models/IUserNotificationsSettings";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import InfoToolTip from "../components/InfoToolTip";
import { ErrorMessageType } from "../components/FormContainer";

interface IFormData {
  receiveEmailedInvoices: boolean;
  receiveEmailedProposals: boolean;
  receiveEmailedReceipts: boolean;
  receiveEmailOnProposalAccepted: boolean;
  receiveEmailOnProposalRejected: boolean;
}

const UserNotificationsForm = ({ onClose }: { onClose(): void }) => {
  const userAccountEmail = useApplicationStateSelector(
    (s) => s.common.userAccountEmail
  );
  const { register, setValue, getValues } = useForm<IFormData>();
  const [originalFormData, setOriginalFormData] = useState<IFormData | null>(
    null
  );
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");

  const loadData = useCallback(
    () => remoteDataProvider.getUserNotificationsSettings(),
    []
  );

  const onDataLoaded = useCallback(
    (data: IUserNotificationsSettings) => {
      if (data) {
        setValue("receiveEmailedInvoices", data.receiveEmailedInvoices);
        setValue("receiveEmailedProposals", data.receiveEmailedProposals);
        setValue("receiveEmailedReceipts", data.receiveEmailedReceipts);
        setValue(
          "receiveEmailOnProposalAccepted",
          data.receiveEmailOnProposalAccepted
        );
        setValue(
          "receiveEmailOnProposalRejected",
          data.receiveEmailOnProposalRejected
        );

        setOriginalFormData(data);
      }
    },
    [setValue]
  );

  return (
    <ModalDataLoader<IUserNotificationsSettings>
      errorMessage="The form was unable to open. Please check your Internet connection and try again."
      onErrorAlertClose={onClose}
      loadData={loadData}
      onDataLoaded={onDataLoaded}
    >
      <FormContainerWithoutRedux
        formHeader={"My Notifications"}
        formType={FormTypesV2.userNotifications}
        showForm={true}
        hasFormDataChanged={() => {
          const currentValues = JSON.stringify(getValues());
          const originalValues = JSON.stringify(originalFormData);

          return currentValues !== originalValues;
        }}
        save={() => {
          return remoteDataProvider.saveUserNotificationsSettings(getValues());
        }}
        onSaveComplete={() => {
          onClose();
        }}
        onCancel={onClose}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      >
        {userAccountEmail ? (
          <div className="form-group">
            Emails will be sent to {userAccountEmail}
          </div>
        ) : null}
        <div style={{ minHeight: "14rem" }}>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="receiveEmailedInvoices"
                {...register("receiveEmailedInvoices")}
              />
              <label
                className="custom-control-label"
                htmlFor="receiveEmailedInvoices"
              >
                Receive emailed invoices?
              </label>
              <InfoToolTip
                id="receiveEmailedInvoicesToolTip"
                text="Your email address will receive any invoices emailed to a
              customer. If the invoice is printed or texted, you will not
              receive an emailed copy."
              />
            </div>
          </div>

          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="receiveEmailedProposals"
                {...register("receiveEmailedProposals")}
              />
              <label
                className="custom-control-label"
                htmlFor="receiveEmailedProposals"
              >
                Receive emailed proposals?
              </label>
              <InfoToolTip
                id="receiveEmailedProposalsToolTip"
                text="Your email address will receive any proposals emailed to a
              customer. If the invoice is printed or texted, you will not
              receive an emailed copy."
              />
            </div>
          </div>

          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="receiveEmailedReceipts"
                {...register("receiveEmailedReceipts")}
              />
              <label
                className="custom-control-label"
                htmlFor="receiveEmailedReceipts"
              >
                Receive all receipts?
              </label>
              <InfoToolTip
                id="receiveEmailedReceiptsToolTip"
                text="Your email address will receive any receipts when a customer makes
                a payment. If the customer does not have an email address but does
                have a phone number, they will have a receipt texted to them but
                you will still receive it as an email."
              />
            </div>
          </div>

          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="receiveEmailOnProposalAccepted"
                {...register("receiveEmailOnProposalAccepted")}
              />
              <label
                className="custom-control-label"
                htmlFor="receiveEmailOnProposalAccepted"
              >
                Receive email when proposal is accepted?
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="receiveEmailOnProposalRejected"
                {...register("receiveEmailOnProposalRejected")}
              />
              <label
                className="custom-control-label"
                htmlFor="receiveEmailOnProposalRejected"
              >
                Receive email when proposal is rejected?
              </label>
            </div>
          </div>
        </div>
      </FormContainerWithoutRedux>
    </ModalDataLoader>
  );
};

export default UserNotificationsForm;
