import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Droppable } from "react-beautiful-dnd";
import constants from "../../../constants";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { OpportunityStatus } from "../enums/opportunityStatus";
import {
  getOpportunitiesForLane,
  getOpportunityLaneKey,
} from "../services/opportunityService";
import NoOpportunitiesCard from "./NoOpportunitiesCard";
import OpportunityCard from "./OpportunityCard";
import dateService from "../../../services/dateService";
import { isStringSet } from "../../../services/stringService";

interface IProps {
  header: string;
  status: OpportunityStatus;
  opportunityToFocus: string | undefined;
  showExpiredFilter?: boolean;
  assignedToFilter: string;
}

const OpportunitiesLane: React.FunctionComponent<IProps> = ({
  header,
  status,
  opportunityToFocus,
  showExpiredFilter,
  assignedToFilter,
}) => {
  const opportunities = useApplicationStateSelector(
    (s) => s.opportunity.opportunities
  );
  const proposals = useApplicationStateSelector((s) => s.proposal.proposals);
  const board = useApplicationStateSelector((s) => s.opportunity.board);
  const [expanded, setExpanded] = useState(true);
  const [showExpiredOnly, setShowExpiredOnly] = useState(false);

  const boardLane = getOpportunityLaneKey(status);

  const wrapBreakpoint = "md";

  if (boardLane === null) {
    return null;
  }

  let filteredOpportunities = getOpportunitiesForLane(
    opportunities,
    board,
    boardLane
  );

  if (isStringSet(assignedToFilter)) {
    filteredOpportunities = filteredOpportunities.filter(
      (o) => o.assignedTo === assignedToFilter
    );
  }

  if (showExpiredOnly) {
    filteredOpportunities = filteredOpportunities.filter((o) => {
      if (!o.proposalId) {
        return false;
      }

      const proposal = proposals.find((p) => p.id === o.proposalId);
      if (!proposal) {
        return false;
      }

      const hasExpired =
        dateService.formatAsIso(proposal.validUntilDate) <
        dateService.formatAsIso(dateService.getCurrentDate());

      return hasExpired && o.proposalStatus === null;
    });
  }

  const expiredFilterId = `${status}ExpiredFilter`;

  return (
    <div
      className={`col-12 col-${wrapBreakpoint}-4 mb-4 mb-${wrapBreakpoint}-0 d-flex flex-column`}
    >
      <div
        className="bg-white"
        style={{ position: "sticky", top: "0px", zIndex: 2 }}
        data-testid="opportunityLaneHeader"
      >
        <div className="border board-column-header">
          <div className="d-flex justify-content-between align-items-center flex-wrap">
            <h5 className="pl-2 m-0 text-nowrap" data-testid="laneHeaderText">
              {header}
              {filteredOpportunities.length > 0
                ? ` (${filteredOpportunities.length})`
                : ""}
            </h5>

            <div className="d-flex">
              {showExpiredFilter ? (
                <div
                  className={`custom-control custom-checkbox ml-2 pr-2 text-nowrap`}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={expiredFilterId}
                    checked={showExpiredOnly}
                    onChange={(e) => {
                      setShowExpiredOnly(e.target.checked);
                    }}
                    data-testid="showExpiredOnly"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={expiredFilterId}
                  >
                    <small>Show expired only?</small>
                  </label>
                </div>
              ) : null}

              <div className={`d-${wrapBreakpoint}-none`}>
                <button
                  type="button"
                  className="btn btn-link p-0 text-dark"
                  onClick={() => setExpanded(!expanded)}
                >
                  <FontAwesomeIcon
                    icon={expanded ? faChevronDown : faChevronUp}
                    size="lg"
                    title={expanded ? "Collapse" : "Expand"}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {expanded ? (
        <div
          data-testid="opportunityLane"
          className="bg-secondary board-card-container d-flex flex-column"
          style={{ flex: "1" }}
        >
          <Droppable
            droppableId={status.toString()}
            type={constants.droppableTypeOpportunity}
          >
            {(provided) => (
              <div
                ref={provided.innerRef}
                style={{ flex: "1" }}
                {...provided.droppableProps}
              >
                {filteredOpportunities.map((opportunity, index) => (
                  <OpportunityCard
                    key={opportunity.id}
                    opportunity={opportunity}
                    index={index}
                    isProposalLane={status === OpportunityStatus.Proposal}
                    scrollIntoView={opportunityToFocus === opportunity.id}
                  />
                ))}

                {filteredOpportunities.length === 0 ? (
                  <NoOpportunitiesCard />
                ) : null}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      ) : null}
    </div>
  );
};

export default OpportunitiesLane;
