import { isMobileOnly } from "react-device-detect";
import useIsAdmin from "../../../../../hooks/useIsAdmin";
import { IJob, JobType } from "../../../../../models/IJob";
import {
  IMaintenanceJob,
  MaintenanceJobFrequency,
} from "../../../../../models/IMaintenanceJob";
import { IOneTimeJob } from "../../../../../models/IOneTimeJob";
import { formatCurrency } from "../../../../../services/currencyFormatter";
import dateService from "../../../../../services/dateService";
import JobHelper from "../../../../../services/jobHelper";
import CardAddress from "../../CardAddress";
import Name from "../../CardCustomerName";
import JobInstanceCategories from "../../JobInstanceCategories";
import JobInstanceCategoriesContainer from "../../JobInstanceCategoriesContainer";
import Indicators from "./Indicators";
import { JobCardPhoneNumbers } from "./JobCardPhoneNumbers";
import { IJobInstance } from "../../../../../models/IJobInstance";
import { ICategoryIdentifier } from "../../../../../models/ICategoryIdentifier";
import { useState } from "react";
import { useApplicationStateSelector } from "../../../../../hooks/useApplicationStateSelector";
import timeService from "../../../../../services/timeService";
import {
  getTimedWorkedForJobInstance,
  getEstimatedTimeWorked,
} from "../../../../../services/jobInstanceService";
import { ICrew } from "../../../../../models/ICrew";
import { useGetJobGrossAmountAndEstimatedTime } from "../../../../../hooks/useGetJobGrossAmountAndEstimatedTime";
import { IGetAddressForJobResult } from "../../../../../services/addressFormatter";
import { JobCardEmailAddresses } from "./JobCardEmailAddresses";
import { JobCardNotes } from "./JobCardNotes";

export function JobCardContents({
  job,
  jobInstance,
  nameForJob,
  jobIndex,
  hideJobIndex,
  useWhiteText,
  jobType,
  crew,
  upperRightContents,
  date,
  address,
  notesFromCrewSet,
  previousJobNotComplete,
  missingLatOrLng,
  trimmedNotesFromCrew,
  hideName,
  hideTime,
}: {
  job: IJob;
  jobInstance: IJobInstance;
  nameForJob: string;
  hideJobIndex: boolean | undefined;
  useWhiteText: boolean;
  jobIndex: number;
  jobType: JobType;
  crew: ICrew | null;
  upperRightContents?: JSX.Element;
  date: string;
  address: IGetAddressForJobResult;
  notesFromCrewSet: boolean;
  missingLatOrLng: boolean;
  previousJobNotComplete: boolean;
  trimmedNotesFromCrew: string;
  hideName: boolean;
  hideTime: boolean;
}) {
  const isAdmin = useIsAdmin();
  const [isIndicatorVisible, setIsIndicatorVisible] = useState(false);
  const adminViewConfiguration = useApplicationStateSelector(
    (s) => s.common.adminViewConfiguration
  );
  const getJobGrossAmount = useGetJobGrossAmountAndEstimatedTime();

  let categories: Array<ICategoryIdentifier> = [];
  if (job.categories) {
    categories = job.categories;
  }

  let jobInactive = false;
  if (jobType === JobType.maintenanceJob) {
    const maintenanceJob = job as IMaintenanceJob;
    jobInactive =
      maintenanceJob.frequency === MaintenanceJobFrequency.None &&
      (maintenanceJob.seasonalScheduleFrequency === null ||
        maintenanceJob.seasonalScheduleFrequency ===
          MaintenanceJobFrequency.None);
  }

  const { grossRevenuePerVisit, estimatedManHours } = getJobGrossAmount(
    date,
    jobType,
    job,
    jobInstance
  );

  let actualManHours = timeService.getActualManHours(jobInstance);
  let actualTime =
    getTimedWorkedForJobInstance(jobInstance)?.formattedValue ?? null;
  let estimatedTime =
    getEstimatedTimeWorked(crew, estimatedManHours)?.formattedValue ?? null;

  const isPurchaseOrderNumberSet = !!(
    jobInstance.purchaseOrderNumber || ""
  ).trim();
  const isInvoiceNumberSet = !!(jobInstance.invoiceNumber || "").trim();

  const isGrossRevenuePerVisitSet = !!grossRevenuePerVisit;

  const noteForAdminSet =
    (jobInstance.administratorOnlyNotes || "").trim() ||
    (jobInstance.showCrewNotesOnAdminJobCards &&
      (jobInstance.jobNotes || "").trim()) ||
    (jobType === JobType.maintenanceJob &&
      (job.administratorOnlyNotes || "").trim()) ||
    (jobType === JobType.maintenanceJob &&
      job.showCrewNotesOnAdminJobCards &&
      (job.notes || "").trim());

  const isOptionalRowVisible =
    isPurchaseOrderNumberSet ||
    isInvoiceNumberSet ||
    jobInstance.invoiceSent ||
    isGrossRevenuePerVisitSet ||
    noteForAdminSet ||
    categories.length > 0 ||
    isIndicatorVisible ||
    jobInstance.projectId ||
    adminViewConfiguration.showFrequencyOnJobCard;

  return (
    <>
      <div className={isMobileOnly ? "job-card-name-address-mobile" : ""}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            {!hideName ? (
              <b>
                {!hideJobIndex ? (
                  <span
                    className="schedule-day-index"
                    data-testid="scheduleDayIndex"
                  >
                    {jobIndex + 1}.{" "}
                  </span>
                ) : null}
                <Name
                  customerName={nameForJob}
                  customerId={job.customerId}
                  entityId={jobInstance.id}
                  useWhiteText={useWhiteText}
                />
                <span>{jobInactive ? " (Inactive)" : ""}</span>
                <br />
              </b>
            ) : null}
          </div>
          {upperRightContents ? upperRightContents : null}
        </div>

        <JobCardPhoneNumbers job={job} useWhiteText={useWhiteText} />

        <JobCardEmailAddresses job={job} useWhiteText={useWhiteText} />

        <CardAddress
          address={address}
          useWhiteText={useWhiteText}
          entityId={jobInstance.id}
        />
      </div>

      {adminViewConfiguration.showLastVisitDateOnAdminJobCard &&
      jobInstance.dateOfPreviousJob ? (
        <div>
          Last visit date:{" "}
          {dateService.formatDateForDisplay(jobInstance.dateOfPreviousJob)}
        </div>
      ) : null}

      {typeof estimatedManHours === "number" &&
      adminViewConfiguration.showEstimatedManHoursOnAdminJobCard ? (
        <div className="estimated-man-hours-containers">
          Estimated man hours: {estimatedManHours}
        </div>
      ) : null}

      {estimatedTime &&
      adminViewConfiguration.showEstimatedTimeWorkedOnAdminJobCard ? (
        <div data-testid="estimatedTimeWorked">
          Estimated time: {estimatedTime}
        </div>
      ) : null}

      {jobInstance.complete &&
      actualManHours !== null &&
      adminViewConfiguration.showActualManHoursOnAdminJobCard ? (
        <div>Actual man hours: {actualManHours}</div>
      ) : null}

      {actualTime &&
      adminViewConfiguration.showActualTimeWorkedOnAdminJobCard ? (
        <p data-testid="actualTimeWorked">Actual time: {actualTime}</p>
      ) : null}

      <div className={isOptionalRowVisible ? "" : "d-none"}>
        {isAdmin ? (
          <>
            {isPurchaseOrderNumberSet ? (
              <div>
                <div>
                  <b>PO number: </b>
                </div>
                <div>{jobInstance.purchaseOrderNumber}</div>
              </div>
            ) : null}
            {isInvoiceNumberSet ? (
              <div>
                <div>
                  <b>Invoice details: </b>
                </div>
                <div>{jobInstance.invoiceNumber}</div>
              </div>
            ) : null}
            {jobInstance.invoiceSent ? (
              <div>
                <b>Invoice sent!</b>
              </div>
            ) : null}
            {isGrossRevenuePerVisitSet &&
            adminViewConfiguration.showGrossRevenueOnAdminJobCard ? (
              <div>
                <div>
                  <b>
                    {jobType === JobType.maintenanceJob
                      ? "Amount per visit"
                      : "Amount"}
                    :{" "}
                  </b>
                </div>
                <div>{formatCurrency(grossRevenuePerVisit || 0)}</div>
              </div>
            ) : null}
          </>
        ) : null}

        <JobCardNotes
          jobInstance={jobInstance}
          job={job}
          jobType={jobType}
          useWhiteText={useWhiteText}
        />

        {adminViewConfiguration.showFrequencyOnJobCard ? (
          <div className="mb-1" data-testid="frequencyContainer">
            {JobHelper.getJobFrequencyForDisplay(
              jobType === JobType.maintenanceJob
                ? (job as IMaintenanceJob)
                : null,
              jobType === JobType.oneTimeJob ? (job as IOneTimeJob) : null,
              null,
              null,
              null,
              null,
              [date]
            )}
          </div>
        ) : null}

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <JobInstanceCategoriesContainer
              visible={categories.length > 0 || isIndicatorVisible}
            >
              <Indicators
                isIndicatorVisible={isIndicatorVisible}
                onVisibleChange={(v) => {
                  setIsIndicatorVisible(v);
                }}
                jobInstance={jobInstance}
                jobType={jobType}
                notesFromCrewSet={notesFromCrewSet}
                missingLatOrLng={missingLatOrLng}
                previousJobNotComplete={previousJobNotComplete}
                trimmedNotesFromCrew={trimmedNotesFromCrew}
                customerId={address.customerId}
                customerAdditionalLocationId={
                  address.customerAdditionalLocationId
                }
                crew={crew}
              />

              <JobInstanceCategories
                jobInstanceId={jobInstance.id}
                categories={categories}
              />
            </JobInstanceCategoriesContainer>
          </div>

          {jobInstance.projectId ? (
            <div>{`(${jobInstance.projectJobOrder}/${jobInstance.projectJobCount})`}</div>
          ) : null}
        </div>
      </div>
    </>
  );
}
