import React, { CSSProperties } from "react";
import { isImageByType } from "../../../../services/fileService";
import { ReadOnlyImage } from "./ReadOnlyImage";
import { ReadOnlyNonImage } from "./ReadOnlyNonImage";
import IPhoto from "../../../../models/IPhoto";

interface IProps {
  file: IPhoto;
  imagePrefix: string;
  onOpenLightbox: () => void;
  divStyle?: Partial<CSSProperties>;
  thumbnailKey?: 350 | 280;
  forcedThumbnailWidth?: number;
}

export const ReadOnlyFile: React.FunctionComponent<IProps> = ({
  file,
  imagePrefix,
  onOpenLightbox,
  divStyle,
  thumbnailKey,
  forcedThumbnailWidth,
}) => {
  return (
    <div style={{ marginBottom: "25px", ...(divStyle ?? {}) }}>
      {isImageByType(file.contentType) ? (
        <ReadOnlyImage
          imagePrefix={imagePrefix}
          file={file}
          onOpenLightbox={onOpenLightbox}
          divStyle={divStyle}
          thumbnailKey={thumbnailKey}
          forcedThumbnailWidth={forcedThumbnailWidth}
        />
      ) : (
        <ReadOnlyNonImage
          imagePrefix={imagePrefix}
          file={file}
          onOpenLightbox={onOpenLightbox}
        />
      )}
    </div>
  );
};
