import React from "react";

const TabContentContainer: React.FunctionComponent<{}> = ({ children }) => {
  return (
    <div
      style={{
        padding: "20px",
        borderColor: "rgb(222, 226, 230)",
        borderStyle: "solid",
        borderWidth: "1px",
      }}
    >
      {children}
    </div>
  );
};

export default TabContentContainer;
