import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import getMinutes from "date-fns/get_minutes";

export function formatTimeForCalendaryDisplay(input: string) {
  const inputWithDate = `2000-01-01 ${input}`;
  const minutes = getMinutes(inputWithDate);
  const timeFormatForDisplay = minutes === 0 ? "ha" : "h:mma";

  return dateFnsFormat(dateFnsParse(inputWithDate), timeFormatForDisplay);
}
