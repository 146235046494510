import { actionCreators } from "../actionCreators";
import { push } from "connected-react-router";
import {
  builders as routingBuilders,
  parsers as routingParsers,
} from "../../services/routing";
import { IRootState } from "../../store";
import dateService from "../../services/dateService";

export function redirectFromOldSchedules(
  isFirstRendering: boolean,
  state: IRootState
) {
  if (isFirstRendering) {
    if (routingParsers.schedule.tryParseWeekSequence(state.router).isMatch) {
      return push(routingBuilders.schedule.buildSequenceWeekRoute());
    } else if (routingParsers.schedule.tryParseTimeWeek(state.router).isMatch) {
      return push(routingBuilders.schedule.buildTimeWeekRoute());
    } else if (
      routingParsers.schedule.tryParseDaySequence(
        state.router,
        state.crew.crews
      ).isMatch
    ) {
      return push(
        routingBuilders.schedule.buildSequenceDayRoute(
          dateService.formatAsIso(dateService.getCurrentDate())
        )
      );
    } else if (
      routingParsers.schedule.tryParseTimeDay(state.router, state.crew.crews)
        .isMatch
    ) {
      return push(
        routingBuilders.schedule.buildTimeDayRoute(
          dateService.formatAsIso(dateService.getCurrentDate())
        )
      );
    } else if (
      routingParsers.schedule.tryParseMonthSchedule(state.router).isMatch
    ) {
      return push(
        routingBuilders.schedule.buildMonthRoute(
          dateService.formatAsIso(dateService.getCurrentDate())
        )
      );
    } else if (
      routingParsers.schedule.tryParseScheduleMap(state.router).isMatch
    ) {
      return push(
        routingBuilders.schedule.buildMapWeekRoute(
          dateService.formatAsIso(dateService.getCurrentDate())
        )
      );
    }
  }

  return actionCreators.null();
}
