import { actionCreators } from "../actionCreators";
import { ILoadDaySchedulesStartingAction } from "../actionTypeDefinitions";
import { IRootState } from "../../store";
import dataProvider from "../../services/dataProvider";
import { mergeMap, catchError } from "rxjs/operators";
import {
  loadJobsForSchedule,
  loadWithRetries,
} from "../../services/scheduleService";
import { logError } from "../../services/errorLogger";
import { of, TimeoutError } from "rxjs";
import { fullStoryLogError } from "../../services/fullStoryService";

export function loadDaySchedules(
  action: ILoadDaySchedulesStartingAction,
  state: IRootState
) {
  if (action.daySchedulesToLoad && action.daySchedulesToLoad.length > 0) {
    return loadWithRetries(() =>
      dataProvider.loadDaySchedules(
        action.daySchedulesToLoad,
        [],
        action.maxUnassignedWeekAlreadyLoaded
      )
    ).pipe(
      mergeMap((loadScheduleResult) => {
        return loadJobsForSchedule(loadScheduleResult, state);
      }),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          fullStoryLogError("ScheduleTimeout");
        }

        logError(`Error loading day schedules - ${JSON.stringify(error)}`);
        return of(
          actionCreators.loadDaySchedulesError(action.daySchedulesToLoad, [])
        );
      })
    );
  } else {
    return of(actionCreators.null());
  }
}
