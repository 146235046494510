import { faFileImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Lightbox from "./Lightbox";
import IPhoto from "../../../models/IPhoto";

interface IProps {
  imageFiles: Array<IPhoto>;
}

const CardImages: React.FunctionComponent<IProps> = ({ imageFiles }) => {
  const [showImagePopover, setShowImagePopover] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  return (
    <>
      {imageFiles.length > 0 ? (
        <div>
          <FontAwesomeIcon
            icon={faFileImage}
            size="lg"
            className={`board-card-indicators-icon`}
            onClick={(e) => {
              e.stopPropagation();
              setShowImagePopover(!showImagePopover);
            }}
            style={{
              cursor: "pointer",
            }}
            title="Photos"
          />
        </div>
      ) : null}

      {showImagePopover ? (
        <Lightbox
          items={imageFiles}
          onClose={() => setShowImagePopover(false)}
          itemIndex={imageIndex}
          setItemIndex={(newIndex) => setImageIndex(newIndex)}
        />
      ) : null}
    </>
  );
};

export default CardImages;
