import React from "react";
import { IAction } from "../modules/actionTypeDefinitions";

const actionTypes = {
  showForm: "SHOW_FORM",
  cancelForm: "CANCEL_FORM",
  startSaving: "START_SAVING",
  completeSaving: "COMPLETE_SAVING",
  setErrorMessage: "SET_ERROR_MESSAGE",
  clearErrorMessage: "CLEAR_ERROR_MESSAGE",
};

const createSpecificActionTypeName = (formType: any, actionType: any) =>
  `forms/${formType.actionPrefix.toLowerCase()}/${actionType.toUpperCase()}`;

export { actionTypes, createSpecificActionTypeName };

export interface IShowFormAction<TParameters> extends IAction {
  parameters: TParameters;
}

export interface ICancelFormAction extends IAction {}

export interface IStartSavingAction<TPayload> extends IAction {
  payload: TPayload;
  reopenFormOnComplete?: boolean;
}

export interface ICompleteSavingAction<TParameters, TPayload> extends IAction {
  messageKey: string;
  parameters: TParameters;
  payload: TPayload;
  reopenFormOnComplete?: boolean;
  keepFormOpen?: boolean;
}

export interface ISetErrorMessageAction extends IAction {
  errorMessage: string | React.ReactNode;
}

export interface IClearErrorMessageAction extends IAction {}
