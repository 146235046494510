import { Observable, Observer } from "rxjs";
import { IMaintenanceJob } from "../models/IMaintenanceJob";
import { IJobInstance } from "../models/IJobInstance";
import { IDistance } from "../models/IDistance";
import { IOneTimeJob } from "../models/IOneTimeJob";
import { ICustomer } from "../models/ICustomer";
import { ICustomerAdditionalLocation } from "../models/ICustomerAdditionalLocation";
import { getJobAddresses } from "./jobInstanceService";
import { fullStoryLogInfo } from "./fullStoryService";

const windowAny: any = window;

const createLatLng = (latitude: number, longitude: number) =>
  new windowAny.google.maps.LatLng(latitude, longitude);

export interface IRouteJobsResult {
  dayScheduleId: string;
  updatedJobs: Array<IRouteJobsUpdatedJob>;
}

export interface IRouteJobsUpdatedJob {
  jobInstanceId: string;
  newOrder: number;
}

export interface IGetDriveTimeResult extends IDistance {
  distanceInSeconds: number;
  errorLoadingDistance: boolean;
}

interface ILocation {
  latitude: number;
  longitude: number;
}

const routeJobsRxjs = (
  dayScheduleId: string,
  origin: ILocation,
  destination: ILocation,
  jobInstances: Array<IJobInstance>,
  jobs: Array<IMaintenanceJob>,
  oneTimeJobs: Array<IOneTimeJob>,
  optimizeWaypoints: boolean,
  customers: Array<ICustomer>,
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>
): Observable<IRouteJobsResult> => {
  return new Observable((observer: Observer<IRouteJobsResult>) => {
    const directionsService = new windowAny.google.maps.DirectionsService();

    const crewJobsLocations = getJobAddresses(
      jobInstances,
      jobs,
      oneTimeJobs,
      customers,
      customerAdditionalLocations
    ).map((address) => ({
      location: createLatLng(address.latitude, address.longitude),
      stopover: true,
    }));

    if (crewJobsLocations.length > 0) {
      directionsService.route(
        {
          origin: createLatLng(origin.latitude, origin.longitude),
          destination: createLatLng(
            destination.latitude,
            destination.longitude
          ),
          waypoints: crewJobsLocations,
          optimizeWaypoints: optimizeWaypoints,
          travelMode: windowAny.google.maps.TravelMode.DRIVING,
        },
        (result: any, status: any) => {
          if (status === windowAny.google.maps.DirectionsStatus.OK) {
            const order = result.routes[0].waypoint_order;

            let updatedJobs = order.map(
              (val: number, index: number): IRouteJobsUpdatedJob => ({
                jobInstanceId: jobInstances[val].id,
                newOrder: index,
              })
            );

            observer.next({
              dayScheduleId,
              updatedJobs,
            });
            observer.complete();
          } else {
            observer.error(
              `error fetching directions. status: ${status} - result: ${JSON.stringify(
                result
              )}`
            );
          }
        }
      );
    } else {
      observer.next({
        dayScheduleId,
        updatedJobs: [],
      });
      observer.complete();
    }
  });
};

const getDriveTime = (
  distanceToLoad: IDistance
): Observable<IGetDriveTimeResult> => {
  return new Observable((observer: Observer<IGetDriveTimeResult>) => {
    const service = new windowAny.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [
          createLatLng(
            distanceToLoad.sourceLatitude,
            distanceToLoad.sourceLongitude
          ),
        ],
        destinations: [
          createLatLng(
            distanceToLoad.destinationLatitude,
            distanceToLoad.destinationLongitude
          ),
        ],
        travelMode: "DRIVING",
      },
      (response: any) => {
        // TODO: handle errors
        if (
          response &&
          response.rows &&
          response.rows.length > 0 &&
          response.rows[0].elements.length > 0 &&
          response.rows[0].elements[0].status !== "NOT_FOUND"
        ) {
          if (
            response &&
            response.rows &&
            response.rows.length > 0 &&
            response.rows[0].elements &&
            response.rows[0].elements.length > 0 &&
            response.rows[0].elements[0].duration
          ) {
            observer.next({
              ...distanceToLoad,
              distanceInSeconds: response.rows[0].elements[0].duration.value,
              errorLoadingDistance: false,
            });
          } else {
            observer.next({
              ...distanceToLoad,
              distanceInSeconds: 0,
              errorLoadingDistance: true,
            });
          }
          observer.complete();
        } else {
          let responseInfo =
            response === undefined
              ? "<undefined>"
              : response === null
              ? "<null>"
              : typeof response === "object"
              ? JSON.stringify(response)
              : "";
          fullStoryLogInfo(`Invalid response from google for job routing {
            extra: {
              response: ${responseInfo}
              distanceToLoad: ${JSON.stringify(distanceToLoad)},
            },
          }`);

          observer.next({
            ...distanceToLoad,
            distanceInSeconds: 0,
            errorLoadingDistance: true,
          });
          observer.complete();
        }
      }
    );
  });
};

export { routeJobsRxjs, getDriveTime };
