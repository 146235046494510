import {
  Control,
  useFieldArray,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import AboutOwnerTabPrincipal from "./AboutOwnerTabPrincipal";
import { IOnboardingFormData } from "./IOnboardingFormData";
import { getEmptyPrincipal } from "./objectCreators";

interface IProps {
  register: UseFormRegister<IOnboardingFormData>;
  getValues: UseFormGetValues<IOnboardingFormData>;
  setValue: UseFormSetValue<IOnboardingFormData>;
  control: Control<IOnboardingFormData, object>;
}

const AboutOwnerTab: React.FunctionComponent<IProps> = ({
  register,
  getValues,
  setValue,
  control,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "principals",
    keyName: "internalId",
  });

  return (
    <>
      <div className="mb-2">
        <small>
          <strong>Please Note:</strong> To help the government fight the funding
          of terrorism and money laundering activities, federal law requires all
          financial institutions to obtain, verify, and record information that
          identifes each individual or business who opens an account. What this
          means for you: when you open an account, we will ask for your
          identifable information including your full name, address, date of
          birth, and other business information that will allow us to identify
          you. We may also ask to see your Identifcation Card, Driver's License,
          and/or other identifying documents.
        </small>
      </div>

      <div className="mb-4">
        <small>
          <strong>Please Note:</strong> The following information must be
          provided for Sole Proprietors or each individual, if any, who directly
          or indirectly owns twenty-fve percent (25%) or more of the ownership
          interest of the Legal Entity in this application as well as an
          individual with signifcant responsibility. A Legal Entity includes a
          general partnership, a corporation, limited liability company or other
          entity that is formed by a filing of a public document with a
          Secretary of State or similar office, and any similar business entity
          formed in the United States.
        </small>
      </div>

      {fields.map((principal, index) => (
        <div key={principal.internalId}>
          {fields.length > 1 ? (
            <div className="mb-2">
              <strong>Principal {index + 1}</strong>{" "}
              <button
                className="btn btn-link"
                type="button"
                style={{ padding: 0, border: 0, verticalAlign: "baseline" }}
                onClick={() => {
                  const primaryContactPrincipalId = getValues(
                    "primaryContactPrincipalId"
                  );
                  if (primaryContactPrincipalId === principal.id) {
                    const newPrimaryContactPrincipal = getValues(
                      "principals"
                    ).find((p) => p.id !== principal.id);

                    if (newPrimaryContactPrincipal) {
                      setValue(
                        "primaryContactPrincipalId",
                        newPrimaryContactPrincipal.id
                      );
                    }
                  }
                  remove(index);
                }}
              >
                (remove)
              </button>
            </div>
          ) : null}
          <AboutOwnerTabPrincipal
            control={control}
            index={index}
            register={register}
            setValue={setValue}
            getValues={getValues}
          />
        </div>
      ))}

      <div className="mt-3">
        Does any other principal own 25% or more of the business? If Yes, click{" "}
        <button
          className="btn btn-link"
          type="button"
          style={{ padding: 0, border: 0, verticalAlign: "baseline" }}
          onClick={() => append(getEmptyPrincipal())}
        >
          here
        </button>{" "}
        to add an additional Principal.
      </div>

      {fields.length > 1 ? (
        <div className="mt-3">
          <div className="form-group">
            <label htmlFor="primaryContactPrincipalId" className="required">
              Which of the principals provided is the primary contact of the
              business?
            </label>
            <select
              id="primaryContactPrincipalId"
              className="form-control"
              required
              {...register("primaryContactPrincipalId")}
            >
              {fields.map((p, index) => (
                <option value={p.id} key={p.internalId}>
                  {p.firstName || p.lastName
                    ? `${p.firstName} ${p.lastName}`
                    : `Principal ${index + 1}`}
                </option>
              ))}
            </select>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AboutOwnerTab;
