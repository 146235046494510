import React, { Fragment, useMemo, useState } from "react";
import { connect } from "react-redux";
import { IJob, JobType } from "../../../../../models/IJob";
import { IRootState } from "../../../../../store";
import { IJobInstance } from "../../../../../models/IJobInstance";
import addressFormatter from "../../../../../services/addressFormatter";
import { ICustomer } from "../../../../../models/ICustomer";
import { ICustomerAdditionalLocation } from "../../../../../models/ICustomerAdditionalLocation";
import { getNameForJob } from "../../../../../services/jobService";
import { ICrew } from "../../../../../models/ICrew";
import ContextMenu from "./ContextMenu";
import JobCardContainer from "../JobCardContainer";
import { JobCardContents } from "./JobCardContents";

const normalizeDriveTime = (distance: number | null | undefined) => {
  if (typeof distance === "number") {
    return Math.round(distance / 60);
  } else {
    return NaN;
  }
};

export type RenderJobCardWithWrapperType = (args: {
  element: JSX.Element;
  jobInstance: IJobInstance;
  jobIndex: number;
  selected: boolean;
  selectedJobInstanceIds: Array<string>;
  onJobCardMultiSelected: ((jobInstanceId: string) => void) | undefined;
  crew: ICrew | null;
  draggableStyleOverride: React.CSSProperties | undefined;
  isAnyCardDragged: boolean;
  isDraggedCard: boolean;
}) => JSX.Element;

interface IProps extends IOwnProps {
  customers: Array<ICustomer>;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
  selectedJobInstanceIds: Array<string>;
  draggedJobInstanceId: string | null;
  showAlertForSkippedAndUncompletedJobs: boolean;
}

interface IOwnProps {
  date: string;
  job: IJob;
  jobType: JobType;
  jobInstance: IJobInstance;
  jobIndex: number;
  distanceFromPreviousJob: number | null | undefined;
  distanceToCrewLocation: number | null | undefined;
  lastJob: boolean;
  isMapVisible: boolean;
  distanceFromPreviousJobErrorLoading: boolean;
  distanceToCrewLocationErrorLoading: boolean;
  draggableStyleOverride?: React.CSSProperties;
  hideUpdateCompletionInformation?: boolean;
  hideSkip?: boolean;
  crew: ICrew | null;
  onJobCardMultiSelected?: (jobInstanceId: string) => void;
  renderJobCardWithWrapper: RenderJobCardWithWrapperType;
  hideContextMenu: boolean;
  upperRightContents?: JSX.Element;
  hideJobIndex?: boolean;
  ignoreStatusForBackgroundColor?: boolean;
}

const JobCard: React.FunctionComponent<IProps> = ({
  date,
  job,
  jobType,
  customers,
  jobInstance,
  jobIndex,
  distanceFromPreviousJob,
  distanceToCrewLocation,
  lastJob,
  isMapVisible,
  distanceFromPreviousJobErrorLoading,
  distanceToCrewLocationErrorLoading,
  customerAdditionalLocations,
  draggableStyleOverride,
  hideUpdateCompletionInformation,
  hideSkip,
  selectedJobInstanceIds,
  draggedJobInstanceId,
  crew,
  showAlertForSkippedAndUncompletedJobs,
  onJobCardMultiSelected,
  renderJobCardWithWrapper,
  hideContextMenu,
  upperRightContents,
  hideJobIndex,
  ignoreStatusForBackgroundColor,
}) => {
  const selected = selectedJobInstanceIds.some((i) => i === jobInstance.id);
  const isDraggedCard = draggedJobInstanceId === jobInstance.id;
  const isAnyCardDragged = !!draggedJobInstanceId;
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  if (!draggableStyleOverride) {
    draggableStyleOverride = {};
  }

  const previousJobNotComplete =
    typeof jobInstance.previousJobCompleted === "boolean" &&
    !jobInstance.previousJobCompleted &&
    !!jobInstance.jobId;
  const trimmedNotesFromCrew = (jobInstance.notesFromCrew || "").trim();
  const notesFromCrewSet =
    !!trimmedNotesFromCrew ||
    (jobInstance.photosFromCrew && jobInstance.photosFromCrew.length > 0);

  const nameForJob = useMemo(
    () =>
      getNameForJob({
        job,
        customers,
        customerAdditionalLocations,
        fallbackToAddressIfAdditionalLocationNameNotSet: false,
      }),
    [job, customers, customerAdditionalLocations]
  );

  const address = useMemo(
    () =>
      addressFormatter.getAddressForJob(
        job,
        customers,
        customerAdditionalLocations
      ),
    [job, customers, customerAdditionalLocations]
  );

  const missingLatOrLng = !address.latitude || !address.longitude;

  const showAlertBorder =
    notesFromCrewSet ||
    (previousJobNotComplete && showAlertForSkippedAndUncompletedJobs) ||
    missingLatOrLng;

  let draggedJobCount = 0;
  if (isDraggedCard) {
    draggedJobCount = selectedJobInstanceIds.some((i) => i === jobInstance.id)
      ? selectedJobInstanceIds.length
      : selectedJobInstanceIds.length + 1;
  }

  const showDragCount = isDraggedCard && draggedJobCount > 1;

  return (
    <Fragment>
      {distanceFromPreviousJob !== null && isMapVisible ? (
        <p
          className={
            "font-weight-light text-center drive-time" +
            (distanceFromPreviousJob === null ||
            isNaN(normalizeDriveTime(distanceFromPreviousJob))
              ? " invisible"
              : "")
          }
        >
          {!distanceFromPreviousJobErrorLoading ? (
            <React.Fragment>
              <span className="drive-time-value">
                {normalizeDriveTime(distanceFromPreviousJob).toString()}
              </span>{" "}
              min drive time
            </React.Fragment>
          ) : (
            <React.Fragment>Unable to load drive time</React.Fragment>
          )}
        </p>
      ) : null}

      {renderJobCardWithWrapper({
        jobInstance: jobInstance,
        jobIndex: jobIndex,
        selected: selected,
        selectedJobInstanceIds,
        onJobCardMultiSelected,
        crew,
        draggableStyleOverride,
        isAnyCardDragged,
        isDraggedCard,
        element: (
          <div>
            <JobCardContainer
              jobInstance={jobInstance}
              selected={selected}
              showAlertBorder={showAlertBorder}
              ignoreStatusForBackgroundColor={ignoreStatusForBackgroundColor}
            >
              {(useWhiteText) => (
                <>
                  <JobCardContents
                    job={job}
                    jobInstance={jobInstance}
                    nameForJob={nameForJob}
                    hideJobIndex={hideJobIndex}
                    useWhiteText={useWhiteText}
                    jobIndex={jobIndex}
                    jobType={jobType}
                    crew={crew}
                    date={date}
                    address={address}
                    notesFromCrewSet={notesFromCrewSet}
                    missingLatOrLng={missingLatOrLng}
                    previousJobNotComplete={previousJobNotComplete}
                    trimmedNotesFromCrew={trimmedNotesFromCrew}
                    upperRightContents={upperRightContents}
                    hideName={false}
                    hideTime={false}
                  />

                  {showDragCount ? (
                    <div
                      className="bg-success"
                      style={{
                        right: "-10px",
                        top: "-10px",
                        borderRadius: "50%",
                        position: "absolute",
                        height: "30px",
                        width: "30px",
                        lineHeight: "30px",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      {draggedJobCount}
                    </div>
                  ) : null}

                  {!hideContextMenu ? (
                    <ContextMenu
                      jobInstance={jobInstance}
                      nameForJob={nameForJob}
                      hideSkip={hideSkip}
                      jobType={jobType}
                      hideUpdateCompletionInformation={
                        hideUpdateCompletionInformation
                      }
                      crew={crew}
                      isContextMenuOpen={isContextMenuOpen}
                      setIsContextMenuOpen={setIsContextMenuOpen}
                    />
                  ) : null}
                </>
              )}
            </JobCardContainer>
          </div>
        ),
      })}

      {lastJob && isMapVisible ? (
        <p
          className={
            "font-weight-light text-center drive-time" +
            (distanceToCrewLocation === null ||
            distanceToCrewLocation === undefined ||
            isNaN(distanceToCrewLocation)
              ? " invisible"
              : "")
          }
        >
          {!distanceToCrewLocationErrorLoading ? (
            <React.Fragment>
              <span className="drive-time-value">
                {normalizeDriveTime(distanceToCrewLocation).toString()}
              </span>{" "}
              min drive time
            </React.Fragment>
          ) : (
            <React.Fragment>Unable to load drive time</React.Fragment>
          )}
        </p>
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => ({
  job: ownProps.job,
  jobInstance: ownProps.jobInstance,
  jobIndex: ownProps.jobIndex,
  distanceFromPreviousJob: ownProps.distanceFromPreviousJob,
  distanceToCrewLocation: ownProps.distanceToCrewLocation,
  lastJob: ownProps.lastJob,
  isMapVisible: ownProps.isMapVisible,
  customers: state.customer.customers,
  customerAdditionalLocations: state.customer.customerAdditionalLocations,
  hideUpdateCompletionInformation: ownProps.hideUpdateCompletionInformation,
  hideSkip: ownProps.hideSkip,
  selectedJobInstanceIds: state.scheduleUi.selectedJobInstanceIds,
  draggedJobInstanceId: state.scheduleUi.draggedJobInstanceId,
  adminViewConfiguration: state.common.adminViewConfiguration,
  showAlertForSkippedAndUncompletedJobs:
    state.common.adminViewConfiguration.showAlertForSkippedAndUncompletedJobs,
  daySchedules: state.schedule.daySchedules,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(JobCard);
