import React from "react";
import { ITimeRange } from "../../../models/ITimeRange";
import uuidv4 from "uuid/v4";
import TimeInput from "./TimeInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  timeRanges: Array<ITimeRange>;
  onTimeRangesChanged: (newTimeRanges: Array<ITimeRange>) => void;
  defaultActualCrewMembers: number | null;
}

export const TimeRanges: React.SFC<IProps> = ({
  timeRanges,
  onTimeRangesChanged,
  defaultActualCrewMembers,
}) => {
  function setValue(id: string, field: keyof ITimeRange, value: any) {
    const updatedTimeRanges = timeRanges.map((tr) => {
      if (tr.id === id) {
        const newObj = { ...tr };
        (newObj as any)[field] = value;
        return newObj;
      } else {
        return tr;
      }
    });

    onTimeRangesChanged(updatedTimeRanges);
  }

  return (
    <React.Fragment>
      <h5>Time Worked</h5>
      <div className="form-group">
        <div className="d-none d-md-flex form-row">
          <div className="form-group col-md-4">
            <label>Start time</label>
          </div>
          <div className="form-group col-md-4">
            <label>End time</label>
          </div>
          <div className="form-group col-md-4">
            <label>Crew size</label>
          </div>
        </div>
        {timeRanges.map((tr) => (
          <div className="form-row" key={tr.id}>
            <div className="form-group col-md-4">
              <div className="d-md-none">
                <label htmlFor={"startTime_" + tr.id}>Start time</label>
              </div>
              <TimeInput
                className="form-control"
                name="startTime"
                value={tr.startTime || ""}
                id={"startTime_" + tr.id}
                onChange={(time) => setValue(tr.id, "startTime", time)}
              />
            </div>
            <div className="form-group col-md-4">
              <div className="d-md-none">
                <label htmlFor={"endTime_" + tr.id}>End time</label>
              </div>
              <TimeInput
                className="form-control"
                name="endTime"
                value={tr.endTime || ""}
                id={"endTime_" + tr.id}
                onChange={(time) => setValue(tr.id, "endTime", time)}
              />
            </div>
            <div className="form-group col-md-3">
              <div className="d-md-none">
                <label htmlFor={"crewMembers_" + tr.id}>Crew size</label>
              </div>
              <input
                type="number"
                className="form-control"
                value={
                  tr.actualCrewMembers ? tr.actualCrewMembers.toString() : ""
                }
                id={"crewMembers_" + tr.id}
                onChange={(e) =>
                  setValue(
                    tr.id,
                    "actualCrewMembers",
                    !e.currentTarget.value
                      ? undefined
                      : parseFloat(e.currentTarget.value)
                  )
                }
              />
            </div>
            <div
              className="form-group col-md-1"
              style={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              {timeRanges.length > 1 ? (
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={() =>
                    onTimeRangesChanged(
                      timeRanges.filter(
                        (timeRangeInFilter) => tr.id !== timeRangeInFilter.id
                      )
                    )
                  }
                >
                  <FontAwesomeIcon icon={faTrash} title="Delete Time Worked" />
                </button>
              ) : null}
            </div>
          </div>
        ))}
      </div>
      <div className="form-group">
        <button
          className="btn btn-secondary btn-sm"
          type="button"
          onClick={() =>
            onTimeRangesChanged([
              ...timeRanges,
              {
                id: uuidv4(),
                startTime: null,
                endTime: null,
                actualCrewMembers: defaultActualCrewMembers,
              },
            ])
          }
        >
          Add Time Range
        </button>
      </div>
    </React.Fragment>
  );
};
