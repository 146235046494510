import React from "react";
import { connect } from "react-redux";
import FormContainer from "../components/FormContainer";
import { actionCreators } from "../../../modules/actionCreators";
import { ICrew } from "../../../models/ICrew";
import { IRootState } from "../../../store";
import AddressComponents2, {
  IAddressComponents,
} from "../components/AddressComponents2";
import { ICrewMemberIdentifier } from "../../../models/ICrewMemberIdentifier";
import CrewMemberSelection from "../components/CrewMemberSelection";
import CrewCategorySelection from "../components/CrewCategorySelection";
import TextareaAutosize from "react-autosize-textarea/lib";
import { getSortedItems } from "../../../services/sortingService";
import { TenantSubscriptionStatus } from "../../../models/IInitialLoad";
import InfoToolTip from "../components/InfoToolTip";
import { ICategoryIdentifierForSave } from "../../../models/ICategoryIdentifierForSave";
import { getCategories } from "../../../services/crewCategoryService";
import { ICrewCategory } from "../../../models/ICrewCategory";
import { TenantPlan } from "../../../enums/tenantPlan";
import { CrewScheduleType } from "../../../slices/schedule/enums/crewScheduleType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import TimeInput from "../components/TimeInput";
import dateService from "../../../services/dateService";
import constants from "../../../constants";
import { SubscriptionType } from "../../../enums/subscriptionType";
import { getCrewWorkingHours } from "../../../services/crewService";
import { ModalDataLoaderStateless } from "../components/ModalDataLoaderStateless";
import subscriptionDataProvider, {
  SubscriptionChangeType,
} from "../../../slices/tenantSubscription/services/subscriptionDataProvider";
import { ISubscriptionPriceChange } from "../../../slices/tenantSubscription/models/ISubscriptionPriceChange";
import { getPriceChangeMessage } from "../../../slices/tenantSubscription/services/crewControlSubscriptionService";

interface IProps {
  crews: Array<ICrew>;
  crewId: string | null;
  showForm: boolean;
  errorMessage: string | React.ReactNode;
  saving: boolean;
  tenantSubscriptionStatus: TenantSubscriptionStatus;
  startSave(formData: any): void;
  cancel(): void;
  setErrorMessage(errorMessage: string): void;
  crewCategories: Array<ICrewCategory>;
  tenantPlan: TenantPlan | null;
  subscriptionType: SubscriptionType;
  fieldToFocus: string | null;
  showCrewCategoriesForm(payload: any): void;
  defaultCrewScheduleType: CrewScheduleType | null;
}

interface IState {
  formData: IFormData;
  originalFormData: IFormData;
  loadingPriceData: boolean;
  errorLoadingPriceData: boolean;
  subscriptionPriceChange: ISubscriptionPriceChange | null;
}

interface IFormData {
  crewName: string;
  address: IAddressComponents;
  typicalCrewSize: string;
  crewMembers: Array<ICrewMemberIdentifier>;
  crewCategories: Array<ICategoryIdentifierForSave>;
  notesForCrew: string;
  scheduleType: string;
  workingHoursStart: string;
  workingHoursEnd: string;
}

const startTimeInputId = "workingHoursStartTime";
class CrewForm extends React.Component<IProps, IState> {
  private crewNameRef: React.RefObject<HTMLInputElement>;
  private streetAndNumberRef: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);

    const formData = this.getEmptyFormData();
    this.state = {
      formData: formData,
      originalFormData: formData,
      loadingPriceData: false,
      errorLoadingPriceData: false,
      subscriptionPriceChange: null,
    };

    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getFormData = this.getFormData.bind(this);
    this.validate = this.validate.bind(this);

    this.crewNameRef = React.createRef();
    this.streetAndNumberRef = React.createRef();
  }

  getEmptyFormData(): IFormData {
    return {
      crewName: "",
      address: {
        streetAndNumber: "",
        city: "",
        state: "",
        zip: "",
        latitude: "",
        latitudeSignificantDigits: null,
        longitude: "",
        longitudeSignificantDigits: null,
      },
      typicalCrewSize: "",
      crewCategories: [],
      crewMembers: [],
      notesForCrew: "",
      scheduleType:
        typeof this.props.defaultCrewScheduleType === "number"
          ? this.props.defaultCrewScheduleType.toString()
          : "",
      workingHoursStart: constants.defaultWorkingHoursStart,
      workingHoursEnd: constants.defaultWorkingHoursEnd,
    };
  }

  componentDidUpdate(prevProps: IProps) {
    const props = this.props;
    if (prevProps.showForm !== this.props.showForm && props.showForm) {
      let formData: IFormData;
      let loadingPriceData = false;
      if (!props.crewId) {
        formData = this.getEmptyFormData();

        loadingPriceData = true;
        subscriptionDataProvider
          .getUpdatedPrice({
            subscriptionChangeType: SubscriptionChangeType.crewAdd,
          })
          .subscribe({
            next: (result) => {
              this.setState({
                loadingPriceData: false,
                subscriptionPriceChange: result,
              });

              setTimeout(() => {
                if (this.crewNameRef.current) {
                  this.crewNameRef.current.focus();
                }
              });
            },
            error: () => {
              this.setState({
                errorLoadingPriceData: true,
                loadingPriceData: false,
              });
            },
          });
      } else {
        const crew = getCrew(props);
        const { workingHoursStart, workingHoursEnd } =
          getCrewWorkingHours(crew);
        formData = {
          crewName: crew.name,
          address: {
            streetAndNumber: crew.streetAndNumber,
            city: crew.city,
            state: crew.state,
            zip: crew.zip,
            latitude: crew.latitude ? crew.latitude.toString() : "",
            longitude: crew.longitude ? crew.longitude.toString() : "",
            latitudeSignificantDigits: null,
            longitudeSignificantDigits: null,
          },
          typicalCrewSize:
            typeof crew.typicalCrewSize === "number"
              ? crew.typicalCrewSize.toString()
              : typeof crew.typicalCrewSize === "string"
              ? crew.typicalCrewSize
              : "",
          crewCategories: getCategories(
            crew.crewCategories,
            this.props.crewCategories
          ),
          crewMembers: crew.crewMembers,
          notesForCrew: crew.notesForCrew,
          scheduleType: crew.scheduleType.toString(),
          workingHoursStart,
          workingHoursEnd,
        };
      }
      this.setState({
        formData,
        originalFormData: formData,
        loadingPriceData,
        errorLoadingPriceData: false,
        subscriptionPriceChange: null,
      });

      setTimeout(() => {
        if (this.props.fieldToFocus === "startTime") {
          const startTime = document.getElementById(startTimeInputId);
          if (startTime) {
            startTime.focus();
          }
        }
      });
    }
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const formDataToUpdate = { ...this.state.formData };
    (formDataToUpdate[event.target.name as keyof IFormData] as any) = event
      .target.value as any;
    this.setState({
      formData: formDataToUpdate,
    });
  }

  handleAddressChange(newAddressValue: IAddressComponents) {
    this.setState({
      formData: {
        ...this.state.formData,
        address: newAddressValue,
      },
    });
  }

  getFormData(): Partial<ICrew> {
    const result: any = {
      ...this.state.formData,
      name: this.state.formData.crewName,
      notesForCrew: this.state.formData.notesForCrew,
      streetAndNumber: this.state.formData.address.streetAndNumber,
      city: this.state.formData.address.city,
      state: this.state.formData.address.state,
      zip: this.state.formData.address.zip,
      latitude:
        typeof this.state.formData.address.latitude === "string"
          ? parseFloat(this.state.formData.address.latitude)
          : this.state.formData.address.latitude,
      longitude:
        typeof this.state.formData.address.longitude === "string"
          ? parseFloat(this.state.formData.address.longitude)
          : this.state.formData.address.longitude,
      typicalCrewSize: this.state.formData.typicalCrewSize
        ? parseFloat(this.state.formData.typicalCrewSize)
        : 0,
      crewCategories: getSortedItems(
        this.state.formData.crewCategories,
        "name"
      ),
      scheduleType: parseInt(this.state.formData.scheduleType),
      workingHoursStart: dateService.formatTimeForSerialization(
        this.state.formData.workingHoursStart
      ),
      workingHoursEnd: dateService.formatTimeForSerialization(
        this.state.formData.workingHoursEnd
      ),
    };

    delete result.address;

    return result;
  }

  validate() {
    return { valid: true, errorMessage: "" };
  }

  render() {
    const { formData } = this.state;
    const {
      showForm,
      startSave,
      cancel,
      errorMessage,
      saving,
      setErrorMessage,
      crewId,
      crews,
    } = this.props;

    let formHeader = "Add Crew";
    if (crewId) {
      const crew = getCrew(this.props);
      formHeader = `Update ${crew.name}`;
    }

    return showForm ? (
      <ModalDataLoaderStateless
        loadingData={this.state.loadingPriceData}
        errorLoadingData={this.state.errorLoadingPriceData}
        errorMessage="Unable to load the crew form. Please try again."
        onErrorAlertClose={() => {
          this.props.cancel();
        }}
      >
        <FormContainer
          setErrorMessage={setErrorMessage}
          validate={this.validate}
          getFormData={this.getFormData}
          formHeader={formHeader}
          showForm={showForm}
          errorMessage={errorMessage}
          saving={saving}
          startSave={startSave}
          cancel={cancel}
          formKey="crew"
          hasFormDataChanged={() => {
            return (
              JSON.stringify(this.state.formData) !==
              JSON.stringify(this.state.originalFormData)
            );
          }}
          footerMessage={
            this.props.tenantSubscriptionStatus ===
              TenantSubscriptionStatus.Subscribed &&
            this.state.subscriptionPriceChange !== null ? (
              <div data-testid="priceMessage">
                {getPriceChangeMessage({
                  priceChangeDetails: this.state.subscriptionPriceChange,
                })}
              </div>
            ) : null
          }
          footerClassName="text-info"
          saveButtonText={
            typeof this.state.subscriptionPriceChange?.newPlanAmount ===
            "number"
              ? "Purchase"
              : undefined
          }
        >
          <div className="form-section">
            <div className="form-group">
              <label htmlFor="crewName" className="required">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                name="crewName"
                id="crewName"
                required={true}
                value={formData.crewName}
                onChange={this.handleChange}
                ref={this.crewNameRef}
                autoComplete="off"
              />
            </div>
            <div className="form-group">
              <label htmlFor="typicalCrewSize" className="required">
                Typical number of crew members
              </label>
              <input
                type="number"
                className="form-control"
                name="typicalCrewSize"
                id="typicalCrewSize"
                required={true}
                value={formData.typicalCrewSize}
                onChange={this.handleChange}
              />
            </div>
            {crews.length > 0 ? (
              <React.Fragment>
                <CrewMemberSelection
                  value={formData.crewMembers}
                  onChange={(newCrewMembers) =>
                    this.setState({
                      formData: {
                        ...formData,
                        crewMembers: newCrewMembers,
                      },
                    })
                  }
                />
                <CrewCategorySelection
                  value={formData.crewCategories}
                  onChange={(newValue) =>
                    this.setState({
                      formData: {
                        ...formData,
                        crewCategories: newValue,
                      },
                    })
                  }
                  hideToolTip={true}
                />
              </React.Fragment>
            ) : null}
          </div>
          <h5>Crew starting location</h5>
          <div className="form-section">
            <AddressComponents2
              streetAndNumberId="startingLocation"
              streetAndNumberName="startingLocation"
              value={formData.address}
              onChange={this.handleAddressChange}
              streetAndNumberRef={this.streetAndNumberRef}
            />
          </div>
          <div className="form-section">
            <div className="form-group">
              <label htmlFor="notesForCrew">Notes for crew</label>
              <InfoToolTip
                id="notesToolTip"
                text="These notes display at the top of crew schedules every day"
              />
              <TextareaAutosize
                maxRows={10}
                className="form-control"
                name="notesForCrew"
                id="notesForCrew"
                value={formData.notesForCrew ?? ""}
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  this.setState({
                    formData: {
                      ...formData,
                      notesForCrew: value,
                    },
                  });
                }}
              />
            </div>
          </div>

          <SchedulingOptions
            isNewCrew={!this.props.crewId}
            value={formData.scheduleType}
            originalValue={this.state.originalFormData.scheduleType}
            onChange={(v) =>
              this.setState({
                formData: {
                  ...formData,
                  scheduleType: v,
                },
              })
            }
          />

          {formData.scheduleType === CrewScheduleType.time.toString() ? (
            <>
              <h5>Daily working hours</h5>
              <div className="form-section">
                <div className="d-flex justify-content-between flex-nowrap">
                  <div className="form-group" style={{ flexGrow: 1 }}>
                    <label htmlFor={startTimeInputId} className="required">
                      Start
                    </label>
                    <TimeInput
                      id={startTimeInputId}
                      value={formData.workingHoursStart}
                      onChange={(e) => {
                        this.setState({
                          formData: {
                            ...formData,
                            workingHoursStart: e,
                          },
                        });
                      }}
                      className="form-control"
                      required
                      data-testid="startTime"
                    />
                  </div>
                  <div className="mx-3">
                    <label>&nbsp;</label>
                    <div>to</div>
                  </div>
                  <div className="form-group" style={{ flexGrow: 1 }}>
                    <label htmlFor="workingHoursEnd" className="required">
                      End
                    </label>
                    <TimeInput
                      id="workingHoursEnd"
                      value={formData.workingHoursEnd}
                      onChange={(e) => {
                        this.setState({
                          formData: {
                            ...formData,
                            workingHoursEnd: e,
                          },
                        });
                      }}
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </FormContainer>
      </ModalDataLoaderStateless>
    ) : null;
  }
}

function SchedulingOptions({
  value,
  originalValue,
  onChange,
  isNewCrew,
}: {
  value: string;
  originalValue: string;
  onChange: (newValue: string) => void;
  isNewCrew: boolean;
}) {
  return (
    <>
      <h5>Scheduling</h5>
      <div className="form-section">
        <div className="mb-2">
          Sequenced visits have no start and end time, but are rather scheduled
          in a sequence and based on the capacity (man hours) of a route.
        </div>

        <div className="mb-2">
          Time-based scheduling requires working hours to be set for which
          visits can be scheduled with a given start and end time.
        </div>

        <div className="form-group">
          <SchedulingRadioButton
            radioOption={CrewScheduleType.sequence.toString()}
            label="Sequenced"
            inputId="schedulingSequence"
            value={value}
            onChange={onChange}
          />
          <SchedulingRadioButton
            radioOption={CrewScheduleType.time.toString()}
            label="Time-based"
            inputId="schedulingTime"
            value={value}
            onChange={onChange}
          />
        </div>

        {value !== originalValue && !isNewCrew ? (
          <div
            className="my-2 alert alert-info p-2"
            data-testid="scheduleTypeChangeAlert"
          >
            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
            {value === CrewScheduleType.time.toString()
              ? "Applying this change will result in job cards being placed at the top of their days schedule.  You can adjust their time accordingly."
              : "Applying this change will result in scheduled times being removed from their job cards. The system will add back to the these times if you decide to switch back to a time-based schedule."}
          </div>
        ) : null}
      </div>
    </>
  );
}

function SchedulingRadioButton({
  radioOption,
  label,
  inputId,
  value,
  onChange,
}: {
  radioOption: string;
  label: string;
  inputId: string;
  value: string;
  onChange: (newValue: string) => void;
}) {
  return (
    <>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="scheduling"
          required
          id={inputId}
          checked={radioOption === value}
          onChange={() => {
            onChange(radioOption);
          }}
        />
        <label className="form-check-label" htmlFor={inputId}>
          {label}
        </label>
      </div>
    </>
  );
}

const mapStateToProps = (state: IRootState) => ({
  crews: state.crew.crews,
  crewId: state.forms.crew.parameters
    ? state.forms.crew.parameters.crewId
    : null,
  fieldToFocus: state.forms.crew.parameters
    ? state.forms.crew.parameters.fieldToFocus
    : null,
  showForm: state.forms.crew.showForm,
  errorMessage: state.forms.crew.errorMessage,
  saving: state.forms.crew.saving,
  tenantSubscriptionStatus: state.common.tenantSubscriptionStatus,
  crewCategories: state.common.crewCategories,
  tenantPlan: state.common.tenantPlan,
  subscriptionType: state.common.subscriptionType,
  defaultCrewScheduleType: state.common.defaultCrewScheduleType,
});

const mapDispatchToProps = {
  startSave: actionCreators.forms.crew.startSaving,
  cancel: actionCreators.forms.crew.cancelForm,
  setErrorMessage: actionCreators.forms.crew.setErrorMessage,
  showCrewCategoriesForm: actionCreators.forms.crewCategories.showForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(CrewForm);

function getCrew(
  props: Readonly<IProps> & Readonly<{ children?: React.ReactNode }>
) {
  const crew = props.crews.find((c) => c.id === props.crewId);
  if (!crew) {
    throw new Error(`crew not found ${props.crewId}`);
  }
  return crew;
}
