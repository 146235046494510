import PageWithNavBar2 from "../../../containers/app/PageWithNavBar2";
import schedulePageDesktop from "../../../schedule-page-desktop-800.png";
import schedulePageMobile from "../../../schedule-page-mobile-250.png";
import { isStringSet } from "../../../services/stringService";

export function LockedOutPage({
  header,
  subHeader,
  button,
  errorMessage,
}: {
  header: React.ReactNode;
  subHeader?: string;
  button: React.ReactNode | null;
  errorMessage?: string | null;
}) {
  return (
    <PageWithNavBar2 billingContext={true} hideNavBarNavigation>
      <>
        <div className="container pt-2">
          <div className="alert alert-primary">
            <div style={{ fontSize: "2.5rem" }} className="mb-2 text-center">
              {header}
            </div>

            {subHeader ? (
              <div className="lead text-center mb-2">{subHeader}</div>
            ) : null}

            <div className="mt-3 d-flex justify-content-center">{button}</div>

            {isStringSet(errorMessage) ? (
              <div
                className="text-center text-danger"
                data-testid="errorCreatingPortalSession"
              >
                {errorMessage}
              </div>
            ) : null}

            <div className="lead text-center mb-2">
              Have questions or need help?{" "}
              <a
                href="https://youraspire.atlassian.net/servicedesk/customer/portal/9"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontWeight: 500 }}
              >
                Contact Support
              </a>
            </div>
            <div className="my-2 text-center">
              <picture>
                <source
                  srcSet={schedulePageDesktop}
                  media="(min-width: 1000px)"
                />
                <img
                  className="border border-primary rounded"
                  src={schedulePageMobile}
                  alt="Schedule board screenshot"
                />
              </picture>
            </div>
          </div>
        </div>
      </>
    </PageWithNavBar2>
  );
}
