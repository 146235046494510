import React, { useRef } from "react";
import { GroupBase, SelectInstance } from "react-select";
import CreatableSelect, { CreatableProps } from "react-select/creatable";

interface IProps<Option, IsMulti extends boolean = false>
  extends CreatableProps<Option, IsMulti, GroupBase<Option>> {
  selectRef?: React.MutableRefObject<
    SelectInstance<Option, IsMulti> | null | undefined
  >;
  onLeaveInputField(inputFieldValue: string): any;
}

function WrappedCreatableSelect<Option, IsMulti extends boolean = false>(
  props: IProps<Option, IsMulti>
): React.ReactElement<IProps<Option>> {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef}>
      <CreatableSelect<Option, IsMulti>
        openMenuOnFocus
        tabSelectsValue={false}
        {...props}
        ref={(r) => {
          if (props.selectRef) {
            props.selectRef.current = r;
          }
        }}
        onBlur={(e) => {
          // Ensure the passed in onBlur is called as well
          if (props.onBlur) {
            props.onBlur(e);
          }

          if (!containerRef.current) {
            return;
          }

          // relatedTarget can be null on mobile devices.  In that case, onLeaveInputField
          // can't be fired since won't know if the user selected a drop-down item or
          // clicked outside the select control
          if (
            !e.relatedTarget ||
            containerRef.current.contains(e.relatedTarget as Node)
          ) {
            return;
          }

          const inputElement = e.target as HTMLInputElement;
          if (!inputElement || !inputElement.value) {
            return;
          }

          const relatedElement = e.relatedTarget as HTMLElement;
          if (
            typeof relatedElement?.getAttribute === "function" &&
            relatedElement.getAttribute("data-cancelbutton") === "true"
          ) {
            return;
          }

          const inputValue = inputElement.value;
          props.onLeaveInputField(inputValue);
        }}
      />
    </div>
  );
}

export default WrappedCreatableSelect;
