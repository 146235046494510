import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

export default () => (
  <div data-testid="spinnerNoBlock">
    <FontAwesomeIcon icon={faSpinner} size="3x" spin={true} fixedWidth={true} />
    <span className="sr-only">Loading...</span>
  </div>
);
