import React, {
  CSSProperties,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Droppable } from "react-beautiful-dnd";
import jobFinder, { IFoundJob } from "../../../../services/jobFinder";
import constants from "../../../../constants";
import { getNameForJob } from "../../../../services/jobService";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { getSortedItems } from "../../../../services/sortingService";
import addressFormatter from "../../../../services/addressFormatter";
import UnscheduledJobsFilters from "./UnscheduledJobsFilters";
import { preventClearingAllCards } from "../../../../services/selectedCardService";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../../modules/actionCreators";
import {
  getUnscheduledJobs,
  getSortedUnscheduledJobs,
} from "../../../../services/jobInstanceService";
import { getCategories } from "../../../../services/crewCategoryService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { UnscheduledJobsDisplayMode } from "../../../../enums/unscheduledJobsDisplayMode";
import { IUnscheduleJobsToShow } from "../../../../models/IUnscheduleJobsToShow";
import UnscheduledJobsCards from "./UnscheduledJobsCards";
import UnscheduledJobsGrid from "./UnscheduledJobsGrid";
import { IUnscheduledJobCategoryFilter } from "../../../../modules/scheduleUi";
import { ICrewCategory } from "../../../../models/ICrewCategory";
import { Sort, SortColumns } from "./UnscheduledJobs.models";
import { SortDirection } from "../../../../enums/sortDirection";
import { getSortedJobs } from "./UnscheduledJobs.functions";
import { useUserSettings } from "../../../../services/userSettingsService";
import { UserSettingsType } from "../../../../enums/userSettingsType";
import { RenderJobCardWithWrapperType } from "./jobCard";
import { IJobInstance } from "../../../../models/IJobInstance";

interface IProps {
  weekForUnscheduledJobs: Date;
  loadingFlexibleJobs: boolean;
  renderJobCardWithWrapper: RenderJobCardWithWrapperType;
  jobInstanceBeingDraggedFromTimeCalendar: IJobInstance | null;
}

const UnscheduledJobs: React.FunctionComponent<IProps> = ({
  weekForUnscheduledJobs,
  loadingFlexibleJobs,
  renderJobCardWithWrapper,
  jobInstanceBeingDraggedFromTimeCalendar,
}) => {
  const unscheduledJobs: Array<IUnscheduleJobsToShow> = useGetUnscheduledJobs({
    weekForUnscheduledJobs,
    jobInstanceBeingDraggedFromTimeCalendar,
  });
  const categoryFilter = useApplicationStateSelector(
    (s) => s.scheduleUi.unscheduledJobCategoryFilter
  );
  const searchText = useApplicationStateSelector(
    (s) => s.scheduleUi.unscheduledJobSearchTextFilter
  );
  const selectedJobInstances = useApplicationStateSelector(
    (s) => s.scheduleUi.selectedJobInstanceIds
  );
  const crewCategories = useApplicationStateSelector(
    (s) => s.common.crewCategories
  );
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );

  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();

  const displayMode = useApplicationStateSelector(
    (s) => s.scheduleUi.unscheduledJobsDisplayMode
  );

  const filteredUnscheduledJobs = useGetFilteredJobs(
    unscheduledJobs,
    categoryFilter,
    searchText,
    expanded
  );

  useResetPageOnFilterChange(categoryFilter, searchText);

  const categories = useGetCategories(unscheduledJobs, crewCategories);

  const { getUserSettings, setUserSettings } = useUserSettings();

  const [gridSort, setGridSort] = useState<Sort>(
    getUserSettings(UserSettingsType.unscheduledJobsSorting) ?? {
      column: SortColumns.CustomerName,
      direction: SortDirection.Ascending,
    }
  );

  const numberOfElementsToShow = 50;
  const { page, amountForNextPage, elementsToShow, topDivRef } =
    usePageElements({
      data: getSortedJobs({
        filteredUnscheduledJobs,
        sort: gridSort,
        customers,
        customerAdditionalLocations,
        categories,
      }),
      numberOfElementsToShow,
    });

  let boxStyle: React.CSSProperties = getBoxStyle(loadingFlexibleJobs);

  const showCountText = !expanded || filteredUnscheduledJobs.length === 0;

  let areAllItemsSelected = getAreAllItemsSelected(
    elementsToShow,
    selectedJobInstances
  );

  const toggleButton =
    filteredUnscheduledJobs.length > 0 ? (
      <UnscheduledJobButton
        onClick={() => {
          setExpanded(!expanded);
        }}
        style={{
          cursor: !loadingFlexibleJobs ? "pointer" : "default",
        }}
        disabled={loadingFlexibleJobs || false}
      >
        {!expanded ? "View Jobs" : "Hide Jobs"}
      </UnscheduledJobButton>
    ) : null;

  return (
    <Droppable
      droppableId={constants.flexibleJobDroppableId}
      direction="horizontal"
      type={constants.droppableTypeJob}
    >
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          <div className="alert mb-1" style={boxStyle}>
            <div
              style={{
                marginBottom:
                  jobInstanceBeingDraggedFromTimeCalendar !== null
                    ? "80px"
                    : undefined,
              }}
            >
              {loadingFlexibleJobs ? (
                <React.Fragment>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin={true}
                    size="3x"
                    fixedWidth={true}
                    style={{
                      position: "absolute",
                      zIndex: 15000,
                      opacity: 100,
                      filter: "alpha(opacity=100)",
                      marginLeft: "auto",
                      marginRight: "auto",
                      left: 0,
                      right: 0,
                    }}
                  />
                </React.Fragment>
              ) : null}
              <React.Fragment>
                <UnscheduledJobsFilters
                  expanded={expanded}
                  categories={categories}
                  loadingFlexibleJobs={loadingFlexibleJobs}
                  flexibleJobsExist={unscheduledJobs.length > 0}
                  displayMode={displayMode}
                  setDisplayMode={(m) =>
                    dispatch(actionCreators.setUnscheduledJobsDisplayMode(m))
                  }
                  centerText={
                    showCountText ? (
                      <>
                        <div data-testid="countContainer">
                          <strong>{filteredUnscheduledJobs.length}</strong>{" "}
                          unscheduled jobs
                        </div>
                      </>
                    ) : displayMode === UnscheduledJobsDisplayMode.Card ? (
                      <div>
                        <button
                          className="link-button expansion-button"
                          onClick={(e) => {
                            preventClearingAllCards(e);
                            dispatch(
                              actionCreators.jobInstanceToggleSelected(
                                elementsToShow.map((ji) => ji.jobInstance.id),
                                !areAllItemsSelected
                              )
                            );
                          }}
                        >
                          {areAllItemsSelected ? "Clear" : "Select"} All
                        </button>
                      </div>
                    ) : (
                      <></>
                    )
                  }
                  toggleButton={!expanded ? <div>{toggleButton}</div> : null}
                />
                {!expanded ? provided.placeholder : null}
              </React.Fragment>

              {expanded && filteredUnscheduledJobs.length > 0 ? (
                <>
                  {page > 0 ? (
                    <UnscheduledJobButton
                      onClick={() => {
                        dispatch(
                          actionCreators.setUnscheduledJobsPage(page - 1)
                        );
                      }}
                    >
                      Show Previous {numberOfElementsToShow}
                    </UnscheduledJobButton>
                  ) : null}

                  <div ref={topDivRef} />

                  {displayMode === UnscheduledJobsDisplayMode.Card ? (
                    <UnscheduledJobsCards
                      elementsToShow={elementsToShow}
                      filteredUnscheduledJobs={filteredUnscheduledJobs}
                      weekForUnscheduledJobs={weekForUnscheduledJobs}
                      dragAndDropPlaceholder={provided.placeholder}
                      renderJobCardWithWrapper={renderJobCardWithWrapper}
                    />
                  ) : (
                    <UnscheduledJobsGrid
                      elementsToShow={elementsToShow}
                      categories={categories}
                      gridSort={gridSort}
                      onGridSortChange={(newValue) => {
                        setGridSort(newValue);
                        setUserSettings(
                          UserSettingsType.unscheduledJobsSorting,
                          newValue
                        );
                      }}
                      dragAndDropPlaceholder={provided.placeholder}
                    />
                  )}

                  {amountForNextPage > 0 ? (
                    <UnscheduledJobButton
                      onClick={() => {
                        dispatch(
                          actionCreators.setUnscheduledJobsPage(page + 1)
                        );
                        if (topDivRef.current) {
                          topDivRef.current.scrollIntoView(true);
                        }
                      }}
                    >
                      Show Next {amountForNextPage}
                    </UnscheduledJobButton>
                  ) : null}
                </>
              ) : null}
              {expanded ? <div>{toggleButton}</div> : null}
            </div>
          </div>
        </div>
      )}
    </Droppable>
  );
};

export default UnscheduledJobs;

function useResetPageOnFilterChange(
  categoryFilter: IUnscheduledJobCategoryFilter | null,
  searchText: string
) {
  const dispatch = useDispatch();
  useEffect(() => {
    // Reset paging when filters are changed
    dispatch(actionCreators.setUnscheduledJobsPage(0));
  }, [categoryFilter, searchText, dispatch]);
}

function useGetFilteredJobs(
  unscheduledJobs: IUnscheduleJobsToShow[],
  categoryFilter: IUnscheduledJobCategoryFilter | null,
  searchText: string,
  expanded: boolean
) {
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );

  return useMemo(() => {
    let filteredJobs = unscheduledJobs;
    if (categoryFilter !== null && expanded) {
      filteredJobs = filteredJobs
        .filter((j) => !!j.job)
        .filter((j) => !!j.job.categories)
        .filter((j) =>
          j.job.categories.some((c) => c.id === categoryFilter.id)
        );
    }

    if (searchText.trim() !== "" && expanded) {
      const normalizedSearchText = searchText.toLocaleLowerCase();
      const tokens = normalizedSearchText.split(" ");
      filteredJobs = filteredJobs.filter((j) => {
        const name =
          getNameForJob({
            job: j.job,
            customers,
            customerAdditionalLocations,
            fallbackToAddressIfAdditionalLocationNameNotSet: false,
          }) || "";
        const address = addressFormatter.formatAddressForJob(
          j.job,
          customers,
          customerAdditionalLocations
        );

        const allTokensMatch = tokens.reduce((acc, token) => {
          return (
            acc &&
            (name.toLocaleLowerCase().indexOf(token) !== -1 ||
              address.toLocaleLowerCase().indexOf(token) !== -1)
          );
        }, true);
        return allTokensMatch;
      });
    }

    return filteredJobs;
  }, [
    categoryFilter,
    unscheduledJobs,
    searchText,
    customers,
    customerAdditionalLocations,
    expanded,
  ]);
}

function getAreAllItemsSelected(
  jobsInCurrentPage: IUnscheduleJobsToShow[],
  selectedJobInstances: string[]
) {
  return jobsInCurrentPage.reduce((allItemsSelected, jobInstance) => {
    if (!allItemsSelected) {
      return false;
    }

    return selectedJobInstances.includes(jobInstance.jobInstance.id);
  }, true as boolean);
}

function getBoxStyle(loadingFlexibleJobs: boolean) {
  let boxStyle: React.CSSProperties = {
    backgroundColor: "#F4F6F8",
    borderWidth: "0 0 2px 0",
    borderColor: "#D2D7DC",
  };

  if (loadingFlexibleJobs) {
    boxStyle = {
      ...boxStyle,
      opacity: 0.6,
      filter: "alpha(opacity=50)",
      minHeight: "75px",
    };
  }
  return boxStyle;
}

function useGetCategories(
  unscheduledJobs: IUnscheduleJobsToShow[],
  crewCategories: ICrewCategory[]
) {
  return useMemo(() => {
    const listOfListCategories = unscheduledJobs
      .filter((j) => !!j.job)
      .map((j) => j.job)
      .map((j) => (j.categories ? j.categories : []));

    const listOfCategories = getCategories(
      listOfListCategories.reduce((acc, current) => [...acc, ...current], []),
      crewCategories
    );

    const uniqueCategories = listOfCategories.filter(
      (value, index, self) => self.findIndex((c) => c.id === value.id) === index
    );

    const sortedCategories = getSortedItems(uniqueCategories, "name");

    return sortedCategories;
  }, [unscheduledJobs, crewCategories]);
}

function useGetUnscheduledJobs({
  weekForUnscheduledJobs,
  jobInstanceBeingDraggedFromTimeCalendar,
}: {
  weekForUnscheduledJobs: Date;
  jobInstanceBeingDraggedFromTimeCalendar: IJobInstance | null;
}) {
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );
  const jobs = useApplicationStateSelector((s) => s.job.jobs);
  const oneTimeJobs = useApplicationStateSelector((s) => s.job.oneTimeJobs);
  const weeksUnscheduledMaintenanceJobs = useApplicationStateSelector(
    (s) => s.schedule.weeksUnscheduledMaintenanceJobs
  );

  return useMemo(() => {
    const unscheduledJobs = getUnscheduledJobs(
      weekForUnscheduledJobs,
      weeksUnscheduledMaintenanceJobs,
      jobs
    );

    if (jobInstanceBeingDraggedFromTimeCalendar !== null) {
      unscheduledJobs.push(jobInstanceBeingDraggedFromTimeCalendar);
    }

    const jobInstances = unscheduledJobs
      .map((jobInstance) => {
        return {
          jobInstance,
          job: jobFinder.getJobForDayScheduleV2(jobs, oneTimeJobs, jobInstance),
        };
      })
      .filter((j) => j.job !== null)
      .map(
        (j) =>
          ({
            jobInstance: j.jobInstance,
            job: j.job as IFoundJob,
          } as IUnscheduleJobsToShow)
      );

    return getSortedUnscheduledJobs(
      jobInstances,
      customers,
      customerAdditionalLocations
    );
  }, [
    customerAdditionalLocations,
    customers,
    jobs,
    oneTimeJobs,
    weeksUnscheduledMaintenanceJobs,
    weekForUnscheduledJobs,
    jobInstanceBeingDraggedFromTimeCalendar,
  ]);
}

function UnscheduledJobButton(
  props: PropsWithChildren<{
    onClick(): void;
    style?: Partial<CSSProperties>;
    disabled?: boolean;
  }>
) {
  return (
    <button
      type="button"
      className="link-button expansion-button"
      data-testid="unscheduledJobsVisibilityToggle"
      onClick={(e) => {
        e.stopPropagation();
        preventClearingAllCards(e);
        props.onClick();
      }}
      style={{
        width: "100%",
        ...(props.style ?? {}),
      }}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}

function usePageElements<TData>({
  data,
  numberOfElementsToShow,
}: {
  data: Array<TData>;
  numberOfElementsToShow: number;
}) {
  const topDivRef = useRef<HTMLDivElement>(null);
  const page = useApplicationStateSelector(
    (s) => s.scheduleUi.unscheduledJobsCurrentPage
  );

  const minBound = page * numberOfElementsToShow;
  const maxBound = (page + 1) * numberOfElementsToShow - 1;

  const elementsToShow = useMemo(
    () =>
      data
        .map((d, originalIndex) => ({
          ...d,
          originalIndex,
        }))
        .filter((_, index) => index >= minBound && index <= maxBound),
    [data, minBound, maxBound]
  );

  const oldMinBound = useRef<number | null>(null);
  const oldMaxBound = useRef<number | null>(null);

  if (oldMinBound.current !== minBound || oldMaxBound.current !== maxBound) {
    oldMinBound.current = minBound;
    oldMaxBound.current = maxBound;
  }

  const remainingItems = data.length - (page + 1) * numberOfElementsToShow;
  const amountForNextPage =
    remainingItems > numberOfElementsToShow
      ? numberOfElementsToShow
      : remainingItems;

  return { page, elementsToShow, amountForNextPage, topDivRef };
}
