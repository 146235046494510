import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState } from "react";
import { connect } from "react-redux";

// TODO: Use 'lib' imports when support for UncontrolledDropdown is added.  This will allow not pulling in the full reactstrap library.
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { actionCreators } from "../../../modules/actionCreators";
import ProjectFormBody from "../../../slices/schedule/components/ProjectForm";

interface IProps {
  showMaintenanceJobForm(parameters: any): void;
  showOneTimeJobForm(parameters: any): void;
  showShiftSchedule(parameters: any): void;
  hideShiftSchedule?: boolean;
  customContent?: React.ReactNode;
}

const NavbarAddButtons: React.FunctionComponent<IProps> = ({
  showMaintenanceJobForm,
  showOneTimeJobForm,
  showShiftSchedule,
  hideShiftSchedule,
  customContent,
}) => {
  const [showAddNewDropDown, setShowAddNewDropDown] = useState(false);
  const [showMultiJobForm, setShowMultiJobForm] = useState(false);
  const toggleButtonId = "mobile-add-toggle";
  const desktopContainerId = "desktop-container";

  return (
    <Fragment>
      <div className={"d-none d-xl-flex justify-content-end"}>
        <Fragment>
          <div
            id={desktopContainerId}
            className="d-flex align-items-center"
            style={{ columnGap: "10px", rowGap: "10px" }}
          >
            <button
              type="button"
              className={"btn btn-primary text-nowrap"}
              onClick={() => showOneTimeJobForm(null)}
              id="nav-bar-add-job-button"
            >
              Add Single Job
            </button>
            <button
              type="button"
              className={"btn btn-primary text-nowrap"}
              onClick={() => setShowMultiJobForm(true)}
              id="nav-bar-add-mulitple-jobs-button"
              data-testid="addMultipleJobs"
            >
              Add Multiple Jobs
            </button>
            <button
              type="button"
              className="btn btn-primary text-nowrap"
              onClick={() => showMaintenanceJobForm(null)}
              id="nav-bar-add-recurring-job-button"
            >
              Add Recurring Job
            </button>

            {!hideShiftSchedule ? (
              <button
                type="button"
                className={"btn btn-secondary text-nowrap"}
                onClick={() => showShiftSchedule(null)}
                id="nav-bar-add-job-button"
              >
                Shift Schedule
              </button>
            ) : null}
            {customContent}
          </div>
        </Fragment>
      </div>
      <div className={"d-block d-xl-none text-right"}>
        <ButtonDropdown
          direction="left"
          isOpen={showAddNewDropDown}
          toggle={() => setShowAddNewDropDown(!showAddNewDropDown)}
        >
          <DropdownToggle color="secondary" id={toggleButtonId}>
            <FontAwesomeIcon
              icon={faPlus}
              data-testid="addButtonToggle"
              title="Add..."
            />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => showOneTimeJobForm(null)}>
              Single Job
            </DropdownItem>
            <DropdownItem onClick={() => setShowMultiJobForm(true)}>
              Multiple Jobs
            </DropdownItem>
            <DropdownItem onClick={() => showMaintenanceJobForm(null)}>
              Recurring Job
            </DropdownItem>

            {!hideShiftSchedule ? (
              <DropdownItem onClick={() => showShiftSchedule(null)}>
                Shift Schedule
              </DropdownItem>
            ) : null}
          </DropdownMenu>
        </ButtonDropdown>
        {customContent}
      </div>
      {showMultiJobForm ? (
        <ProjectFormBody
          onSaveComplete={() => setShowMultiJobForm(false)}
          onCancel={() => setShowMultiJobForm(false)}
          mode="add"
        />
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  showMaintenanceJobForm: actionCreators.forms.maintenanceJob.showForm,
  showOneTimeJobForm: actionCreators.forms.oneTimeJob.showForm,
  showShiftSchedule: actionCreators.forms.shiftSchedules.showForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarAddButtons);
