import React, { useRef } from "react";
import NumberFormat from "react-number-format";

export type IProps = {
  id: string;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  value: string;
  className: string;
  dataTestId?: string;
  ariaLabel?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
};

const PhoneNumberField = React.forwardRef<HTMLInputElement, IProps>(
  (
    {
      id,
      onChange,
      value,
      className,
      dataTestId,
      ariaLabel,
      name,
      required,
      disabled,
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    return (
      <NumberFormat
        id={id}
        data-testid={dataTestId}
        name={name}
        aria-label={ariaLabel}
        className={className}
        required={required ?? false}
        disabled={disabled ?? false}
        defaultValue="xxx-xxx-xxxx"
        placeholder="xxx-xxx-xxxx"
        format="###-###-####"
        mask="_"
        type="tel"
        minLength={10}
        getInputRef={(el: HTMLInputElement) => {
          inputRef.current = el;

          if (typeof ref === "function") {
            ref(el);
          } else if (ref && typeof ref === "object") {
            ref.current = el;
          }
        }}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (inputRef.current) {
            inputRef.current.setCustomValidity(
              !isValid(e.currentTarget.value ?? "")
                ? phoneNumberValidationErrorMessage
                : ""
            );
          }

          if (onChange) {
            onChange(e);
          }
        }}
      />
    );
  }
);

export default PhoneNumberField;

const validateRegex = /^\d{3}-\d{3}-\d{4}$/;
export const phoneNumberValidationErrorMessage = "Phone number is not valid";
export function isValid(value: string) {
  const normalizedValue = value?.trim() ?? "";
  const phoneNumberValid =
    !normalizedValue || validateRegex.test(normalizedValue.trim());

  return phoneNumberValid;
}
