import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from "react";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import AboutBusinessTab from "./AboutBusinessTab";
import AboutOwnerTab from "./AboutOwnerTab";
import { IOnboardingFormData } from "./IOnboardingFormData";
import AddBankAccountTab from "./AddBankAccountTab";
import TermsConditionsTab from "./TermsConditionsTab";
import Spinner from "../Spinner";
import remoteDataProvider, {
  IMerchantOnboardingDefaults,
} from "../../../../services/remoteDataProvider";
import MerchantOnboardingFormTabs from "./MerchantOnboardingFormTabs";
import ModalDataLoader from "../ModalDataLoader";
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { isMobileOnly } from "react-device-detect";
import { fullStoryTrack } from "../../../../services/fullStoryService";
import NonRefundableWarning from "./NonRefundableWarning";

interface IProps {
  modalHeader: string;
  onClose(): void;
  onSubmit(): void;

  merchantFormSaved: boolean;
  showSaveConfirmation: boolean;
  saving: boolean;

  additionalTabs?: Array<ITab>;

  originalFormData: IOnboardingFormData;
  setOriginalFormData: Dispatch<SetStateAction<IOnboardingFormData>>;

  saveErrors: Array<string>;

  introText?: React.ReactNode;

  saveConfigurationHeaderOverride?: string;
  saveConfigurationBodyOverride?: string;

  register: UseFormRegister<IOnboardingFormData>;
  setValue: UseFormSetValue<IOnboardingFormData>;
  getValues: UseFormGetValues<IOnboardingFormData>;
  watch: UseFormWatch<IOnboardingFormData>;
  control: Control<IOnboardingFormData, object>;

  submitButtonText: string;
}

interface ITab {
  header: string;
  key: string;
  contents: React.ReactNode;
  disabled?: boolean;
}

const MerchantOnboardingFormElements: React.FunctionComponent<IProps> = ({
  modalHeader,
  onClose,
  additionalTabs,
  originalFormData,
  setOriginalFormData,
  saveErrors,
  onSubmit,
  merchantFormSaved,
  showSaveConfirmation,
  saving,
  introText,
  saveConfigurationHeaderOverride,
  saveConfigurationBodyOverride,
  register,
  control,
  setValue,
  getValues,
  watch,
  submitButtonText,
}) => {
  const modalBodyTopElementRef = useRef<HTMLDivElement>(null);

  const loadDefaults = useCallback(() => {
    return remoteDataProvider.getMerchantOnboardingDefaults();
  }, []);

  const handleDefaultsLoaded = useCallback(
    (result: IMerchantOnboardingDefaults) => {
      const normalizePhoneNumber = (input: string) => {
        const inputWithFormattingRemoved = (input ?? "")
          .replace(/-/g, "")
          .replace(/\(/g, "")
          .replace(/\)/g, "")
          .replace(/ /g, "");

        if (inputWithFormattingRemoved.length < 10) {
          return inputWithFormattingRemoved;
        } else {
          return `${inputWithFormattingRemoved.substring(
            0,
            3
          )}-${inputWithFormattingRemoved.substring(
            3,
            6
          )}-${inputWithFormattingRemoved.substring(6, 10)}`;
        }
      };

      const normalizedPhoneNumber = normalizePhoneNumber(result.phoneNumber);
      const updateFormData = (d: IOnboardingFormData): IOnboardingFormData => ({
        ...d,
        businessEmail: result.emailAddress,
        daytimePhone: normalizedPhoneNumber,
        customerServicePhone: normalizedPhoneNumber,
      });

      setValue("businessEmail", result.emailAddress);
      setValue("daytimePhone", normalizedPhoneNumber);
      setValue("customerServicePhone", normalizedPhoneNumber);

      setOriginalFormData(updateFormData);
    },
    [setValue, setOriginalFormData]
  );

  const defaultTabs: Array<ITab> = getDefaultTabs({
    merchantFormSaved,
    introText,
    register,
    control,
    setValue,
    getValues,
    watch,
  });

  const tabs: Array<ITab> = [...defaultTabs, ...(additionalTabs ?? [])];

  const [activeTab, setActiveTab] = useState(tabs[0].key);

  const submitForm = () => {
    if (formRef.current?.checkValidity()) {
      onSubmit();
    } else {
      showInvalidField(formRef, setActiveTab);
    }
  };

  const closeForm = () => {
    const hasFormChanged =
      JSON.stringify(getValues()) !== JSON.stringify(originalFormData);
    if (
      showSaveConfirmation ||
      !hasFormChanged ||
      window.confirm("Are you sure you want to close this form without saving?")
    ) {
      onClose();
    }
  };

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <ModalDataLoader<IMerchantOnboardingDefaults>
      errorMessage="The payments onboarding form was unable to open. Please check your Internet connection and try again."
      onErrorAlertClose={() => onClose()}
      loadData={loadDefaults}
      onDataLoaded={handleDefaultsLoaded}
    >
      <Modal
        isOpen={true}
        toggle={() => {
          closeForm();
        }}
        size="lg"
        backdrop="static"
        scrollable={true}
      >
        <ModalHeader
          toggle={() => {
            closeForm();
          }}
        >
          {modalHeader}
        </ModalHeader>
        <ModalBody>
          {saving ? <Spinner /> : null}

          <div ref={modalBodyTopElementRef}></div>

          {!showSaveConfirmation ? (
            <FormTabs
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              modalBodyTopElementRef={modalBodyTopElementRef}
              formRef={formRef}
            />
          ) : (
            <SaveConfirmation
              headerOverride={saveConfigurationHeaderOverride}
              bodyOverride={saveConfigurationBodyOverride}
            />
          )}
        </ModalBody>
        <ModalFooter style={{ justifyContent: "space-between" }}>
          <div>
            <ErrorMessage saveErrors={saveErrors} />
          </div>
          <div>
            {showSaveConfirmation ? (
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => closeForm()}
              >
                Close
              </button>
            ) : (
              <FormInProgressButtons
                activeTab={activeTab}
                tabs={tabs}
                setActiveTab={setActiveTab}
                modalBodyTopElementRef={modalBodyTopElementRef}
                submitForm={submitForm}
                closeForm={closeForm}
                merchantFormSaved={merchantFormSaved}
                submitButtonText={submitButtonText}
              />
            )}
          </div>
        </ModalFooter>
      </Modal>
    </ModalDataLoader>
  );
};

export default MerchantOnboardingFormElements;

function FormInProgressButtons(props: {
  activeTab: string;
  merchantFormSaved: boolean;
  tabs: ITab[];
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  modalBodyTopElementRef: React.RefObject<HTMLDivElement>;
  submitForm: () => void;
  closeForm: () => void;
  submitButtonText: string;
}) {
  const {
    activeTab,
    merchantFormSaved,
    tabs,
    setActiveTab,
    modalBodyTopElementRef,
    submitForm,
    closeForm,
    submitButtonText,
  } = props;

  const finalStep = activeTab === tabs[tabs.length - 1].key;
  return (
    <>
      {finalStep ? (
        <div className="mb-2">
          <NonRefundableWarning />
        </div>
      ) : null}

      <div className="d-flex justify-content-end">
        {!finalStep ? (
          <button
            type="button"
            className="btn btn-primary mr-3"
            onClick={() => {
              changeActiveTab(
                setActiveTab,
                tabs[tabs.findIndex((t) => t.key === activeTab) + 1].key,
                modalBodyTopElementRef
              );
            }}
          >
            {!isMobileOnly ? "Next step" : "Next"}
          </button>
        ) : (
          <button
            type="submit"
            className="btn btn-primary mr-3"
            onClick={() => {
              fullStoryTrack("Onboarding Submit Clicked");
              submitForm();
            }}
          >
            {submitButtonText}
          </button>
        )}

        {activeTab !== tabs[0].key ? (
          <button
            type="button"
            className="btn btn-secondary mr-3"
            disabled={merchantFormSaved}
            onClick={() => {
              changeActiveTab(
                setActiveTab,
                tabs[tabs.findIndex((t) => t.key === activeTab) - 1].key,
                modalBodyTopElementRef
              );
            }}
          >
            {!isMobileOnly ? "Previous step" : "Previous"}
          </button>
        ) : null}

        <button
          className="btn btn-link"
          onClick={() => {
            closeForm();
          }}
          type="button"
        >
          Cancel
        </button>
      </div>
    </>
  );
}

function ErrorMessage(props: { saveErrors: string[] }) {
  const { saveErrors } = props;

  return (
    <>
      {saveErrors.length > 0 ? (
        <div className="text-danger" style={{ whiteSpace: "pre-line" }}>
          <FontAwesomeIcon
            icon={faExclamationCircle}
            style={{ marginRight: "5px" }}
          />
          {saveErrors.length === 1 ? (
            saveErrors[0]
          ) : (
            <>
              Several errors occurred:
              <ul>
                {saveErrors.map((e) => (
                  <li key={e}>{e}</li>
                ))}
              </ul>
            </>
          )}
        </div>
      ) : null}
    </>
  );
}

function FormTabs(props: {
  tabs: ITab[];
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  modalBodyTopElementRef: React.RefObject<HTMLDivElement>;
  formRef: React.RefObject<HTMLFormElement>;
}) {
  return (
    <>
      <div>
        <MerchantOnboardingFormTabs
          tabs={props.tabs}
          activeTab={props.activeTab}
          setActiveTab={(newActiveTab) => {
            changeActiveTab(
              props.setActiveTab,
              newActiveTab,
              props.modalBodyTopElementRef
            );
          }}
        />
      </div>
      <div className="mt-4">
        <form
          ref={props.formRef}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {props.tabs.map((t) => (
            <div
              className={props.activeTab !== t.key ? "d-none" : ""}
              data-tabid={t.key}
              key={t.key}
            >
              {t.contents}
            </div>
          ))}
        </form>
      </div>
    </>
  );
}

function SaveConfirmation({
  headerOverride,
  bodyOverride,
}: {
  headerOverride?: string;
  bodyOverride?: string;
}) {
  return (
    <div>
      <h4 className="text-center">
        {headerOverride ?? "Your application is submitted!"}
      </h4>
      <div className="d-flex justify-content-center">
        <div style={{ maxWidth: "500px" }}>
          {bodyOverride ??
            "We will follow-up in 1-2 business days after your application is processed. Please contact support if you have any questions in the meantime."}
        </div>
      </div>
    </div>
  );
}

function getDefaultTabs(args: {
  merchantFormSaved: boolean;
  introText?: React.ReactNode;
  register: UseFormRegister<IOnboardingFormData>;
  setValue: UseFormSetValue<IOnboardingFormData>;
  getValues: UseFormGetValues<IOnboardingFormData>;
  watch: UseFormWatch<IOnboardingFormData>;
  control: Control<IOnboardingFormData, object>;
}): ITab[] {
  const {
    merchantFormSaved,
    introText,
    register,
    setValue,
    getValues,
    control,
    watch,
  } = args;

  return [
    {
      header: "About the Business",
      key: "business",
      contents: (
        <AboutBusinessTab
          introText={introText}
          register={register}
          setValue={setValue}
          getValues={getValues}
          control={control}
        />
      ),
      disabled: merchantFormSaved,
    },
    {
      header: "About the Owner",
      key: "owner",
      contents: (
        <AboutOwnerTab
          register={register}
          setValue={setValue}
          getValues={getValues}
          control={control}
        />
      ),
      disabled: merchantFormSaved,
    },
    {
      header: "Add Bank Account",
      key: "bank",
      contents: <AddBankAccountTab watch={watch} setValue={setValue} />,
      disabled: merchantFormSaved,
    },
    {
      header: "Terms and Conditions",
      key: "terms",
      contents: (
        <TermsConditionsTab register={register} getValues={getValues} />
      ),
      disabled: merchantFormSaved,
    },
  ];
}

function changeActiveTab(
  setActiveTab: React.Dispatch<React.SetStateAction<string>>,
  newActiveTab: string,
  modalBodyChildRef: React.RefObject<HTMLDivElement>
) {
  setActiveTab(newActiveTab);

  setTimeout(() => {
    modalBodyChildRef.current?.scrollIntoView();
  });
}

function showInvalidField(
  formRef: React.RefObject<HTMLFormElement>,
  setActiveTab: React.Dispatch<React.SetStateAction<string>>
) {
  const invalidElements = getInvalidFormElements(formRef);
  if (invalidElements && invalidElements.length > 0) {
    const firstInvalidItem = invalidElements[0];
    const parentTab = firstInvalidItem.closest("[data-tabid]");
    if (parentTab) {
      const newTab = parentTab.getAttribute("data-tabid");

      if (newTab) {
        setActiveTab(newTab);

        setTimeout(() => {
          // Wait for the tab to be updated and call reportValidity again. If the field wasn't initially visible
          // the error indicator won't show
          formRef.current?.reportValidity();
        });
      }
    }
  }
}

function getInvalidFormElements(formRef: React.RefObject<HTMLFormElement>) {
  return formRef.current?.querySelectorAll(":invalid:not(form):not(fieldset)");
}
