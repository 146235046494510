import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { IApplicationState } from "../../../modules";

const conditionalRenderer = <P extends object>(
  Component: React.ComponentType<P>,
  selector: (state: IApplicationState) => boolean
) => {
  return function ConditionalRendererResult(props: P) {
    const isComponentVisible = useApplicationStateSelector(selector);
    return isComponentVisible ? <Component {...(props as P)} /> : null;
  };
};

export default conditionalRenderer;
