import { isStringSet } from "../../services/stringService";
import { ResponsiveTableMobileCardGrid } from "./ResponsiveTableMobileCardGrid";

export function ResponsiveTableMobileCardContainer({
  children,
  cardMargin,
  errorMessage,
  contentBelowRow,
}: {
  children: React.ReactNode;
  cardMargin?: string;
  errorMessage: string | undefined;
  contentBelowRow: React.ReactNode | undefined;
}) {
  return (
    <div className={`card ${cardMargin}`}>
      <ResponsiveTableMobileCardGrid className="card-body p-3 ">
        <>
          {children}
          {!!contentBelowRow ? (
            <div
              style={{
                gridColumnStart: 1,
                gridColumnEnd: -1,
              }}
            >
              {contentBelowRow}
            </div>
          ) : null}
        </>
      </ResponsiveTableMobileCardGrid>
      {isStringSet(errorMessage) ? (
        <div className="text-danger">{errorMessage}</div>
      ) : null}
    </div>
  );
}
