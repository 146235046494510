import { Action } from "@reduxjs/toolkit";
import { Epic } from "redux-observable";
import { filter, map } from "rxjs/operators";
import { IOpportunityState, opportunitiesActionCreators } from "../opportunity";
import { IApplicationState } from "../../../../modules";
import { actionCreators } from "../../../../modules/actionCreators";
import { OpportunityProposalStatus } from "../../enums/opportunityProposalStatus";
import { tryGetOpportunityIdFromJobSaveAddCompleteAction } from "./utils/tryGetOpportunityIdFromJobSaveAddCompleteAction";

const reloadOpportunityOnJobCreation: Epic<
  Action,
  Action,
  IApplicationState
> = (action, state$) =>
  action.pipe(
    filter(
      (action) =>
        tryGetOpportunityIdFromJobSaveAddCompleteAction(action).opportunityIdSet
    ),
    map((action) => {
      const opportunityId = tryGetOpportunityIdFromJobSaveAddCompleteAction(
        action
      ).opportunityId as string;

      const opportunityState = state$.value.opportunity;
      if (didOpportunityMove(opportunityId, opportunityState)) {
        return opportunitiesActionCreators.reloadBoard();
      } else if (didProposalStatusUpdate(opportunityId, opportunityState)) {
        return opportunitiesActionCreators.reloadOpportunity({
          opportunityId,
        });
      } else {
        return actionCreators.null();
      }
    })
  );

export default reloadOpportunityOnJobCreation;

function didOpportunityMove(
  opportunityId: string,
  opportunityState: IOpportunityState
) {
  const opportunityBoard = opportunityState.board;

  return !opportunityBoard.proposalOpportunities.includes(opportunityId);
}

function didProposalStatusUpdate(
  opportunityId: string,
  opportunityState: IOpportunityState
) {
  const opportunity = opportunityState.opportunities.find(
    (o) => o.id === opportunityId
  );

  return opportunity?.proposalStatus !== OpportunityProposalStatus.Scheduled;
}
