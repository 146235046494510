import React from "react";
import { Navbar as BootstrapNavbar } from "reactstrap";

interface IProps {
  children: React.ReactNode;
}

const PublicContentContainer: React.SFC<IProps> = ({ children }) => (
  <React.Fragment>
    <BootstrapNavbar color="light" light expand="md" id="navbar-heading">
      <a className="navbar-brand text-truncate" href="/">
        Crew Control
      </a>
    </BootstrapNavbar>
    <div className="container-fluid">{children}</div>
  </React.Fragment>
);

export default PublicContentContainer;
