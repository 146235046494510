import * as React from "react";

const NoJobCard: React.FunctionComponent<{}> = () => {
  return (
    <div
      className="card bg-white board-card no-job-card"
      style={{ height: "80px" }}
    >
      <div className="card-body board-card-body">
        <div className="card-text text-center">
          <b>No Jobs</b>
        </div>
      </div>
    </div>
  );
};

export default NoJobCard;
