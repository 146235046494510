const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export function formatCurrency(input: number) {
  return formatter.format(input);
}

const formatterWithoutDecimalDigits = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export function formatCurrencyWithoutDecimalDigits(input: number) {
  return formatterWithoutDecimalDigits.format(input);
}
