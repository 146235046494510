import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { useCreditCardsEnabled } from "../../../../hooks/useCreditCardsEnabled";

const ConsumerPaymentPageInfo: React.FunctionComponent<{}> = () => {
  const tenantId = useApplicationStateSelector((s) => s.common.tenantId);
  const customerUrlRoot = useApplicationStateSelector(
    (s) => s.common.customerUrlRoot
  );
  const { areCreditCardsEnabled } = useCreditCardsEnabled();
  const [copyClicked, setCopyClicked] = useState(false);

  function copyLink() {
    navigator.clipboard.writeText(
      `${customerUrlRoot}/paymentportal/${tenantId}`
    );
    setCopyClicked(true);

    setTimeout(() => {
      setCopyClicked(false);
    }, 5000);
  }

  return (
    <>
      {areCreditCardsEnabled ? (
        <div
          className="form-row border border-info m-0 pt-2 pb-2 d-none d-md-flex flex-nowrap"
          style={{
            borderRadius: "1rem",
            maxWidth: "400px",
          }}
        >
          <div className="col-1 ml-2">
            <FontAwesomeIcon icon={faInfoCircle} className="mt-2" size="2x" />
          </div>
          <div className="col-8 pr-0 ml-2">
            <small>
              Allow customers to use their phone number to lookup and pay
              invoices on your website!
            </small>
          </div>
          <div className="col-2 ml-2">
            <button
              className="btn btn-link"
              style={{ margin: "0px", padding: "0px" }}
              onClick={copyLink}
            >
              {!copyClicked ? (
                <>
                  <FontAwesomeIcon icon={faCopy} size="2x" />
                  <small style={{ display: "block" }}>Copy link</small>{" "}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faCheckCircle} size="2x" />
                  <small style={{ display: "block" }}>Copied!</small>
                </>
              )}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ConsumerPaymentPageInfo;
