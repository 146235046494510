import { useEffect, useState } from "react";
import { formatCurrency } from "../../../../services/currencyFormatter";
import remoteDataProvider from "../../../../services/remoteDataProvider";
import { getSortedItemsV2 } from "../../../../services/sortingService";
import NonQuickBooksInvoiceItemForm from "../../forms/NonQuickBooksInvoiceItemForm";
import ContainerHeader from "./ContainerHeader";
import { timeout } from "rxjs/operators";
import { SortDirection } from "../../../../enums/sortDirection";
import { useSortColumn } from "../../../../hooks/useSortColumn";
import { InvoiceItemsImport } from "../../../../slices/billing/components/InvoiceItemsImport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ServerLoadedContent from "../../components/ServerLoadedContent";
import { ResponsiveTableMobileCard } from "../../../../libraries/tableLayout/ResponsiveTableMobileCard";
import ResponsiveTable from "../../../../libraries/tableLayout/ResponsiveTable";
import { NoteWithModal } from "../../components/NoteWithModal";
import { TableColumns } from "../../../../libraries/tableLayout/TableColumn";
import { IInvoiceItemCrewControl } from "../../../../models/IInvoiceItemCrewControl";

export enum InvoiceItemSortColumns {
  name,
  description,
  unitPrice,
  inactive,
  taxable,
  internalNotes,
}

export default function InvoiceItems() {
  const [loadingData, setLoadingData] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [showInactive, setShowInactive] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");

  const [invoiceItems, setInvoiceItems] =
    useState<Array<IInvoiceItemCrewControl> | null>(null);

  useEffect(() => {
    const subscription = loadData({
      setLoadingData,
      setErrorLoading,
      setInvoiceItems,
    });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, []);

  const filteredItems = (invoiceItems ?? [])
    .filter((i) => !i.inactive || showInactive)
    .filter((i) => {
      const normalizedSearchFilter = searchFilter.trim().toLocaleLowerCase();
      if (!normalizedSearchFilter) {
        return true;
      }

      return (
        i.name.toLocaleLowerCase().includes(normalizedSearchFilter) ||
        (i.description &&
          i.description.toLocaleLowerCase().includes(normalizedSearchFilter))
      );
    })
    .map((i) => ({
      ...i,
      key: i.id,
    }));

  const { currentSortColumn, currentSortDirection, getSortColumn } =
    useSortColumn<InvoiceItemSortColumns>(
      InvoiceItemSortColumns.name,
      SortDirection.Ascending
    );

  const columns: TableColumns<IInvoiceItemCrewControl> = [
    {
      key: "name",
      header: ({ displayType }) =>
        displayType === "desktop"
          ? getSortColumn("Name", InvoiceItemSortColumns.name)
          : "Name",
      testId: "name",
      cell: ({ row: v }) => v.name,
    },
    {
      key: "description",
      header: ({ displayType }) =>
        displayType === "desktop"
          ? getSortColumn("Description", InvoiceItemSortColumns.description)
          : "Description",
      testId: "description",
      cell: ({ row: v }) => (
        <NoteWithModal
          text={v.description ?? ""}
          maxLines={1}
          inlineStringLength={100}
        />
      ),
    },
    {
      key: "internalNotes",
      header: ({ displayType }) =>
        displayType === "desktop"
          ? getSortColumn(
              "Internal notes",
              InvoiceItemSortColumns.internalNotes
            )
          : "Internal notes",
      testId: "internalNotes",
      cell: ({ row: v }) => (
        <NoteWithModal
          text={v.internalNotes ?? ""}
          maxLines={1}
          inlineStringLength={100}
        />
      ),
    },
    {
      key: "unitPrice",
      header: ({ displayType }) =>
        displayType === "desktop"
          ? getSortColumn("Amount per item", InvoiceItemSortColumns.unitPrice)
          : "Amount per item",
      testId: "unitPrice",
      cell: ({ row: v }) =>
        typeof v.unitPrice === "number" ? formatCurrency(v.unitPrice) : null,
      cellClassName: "text-right pr-5",
      headerClassName: "text-right pr-5 text-nowrap",
    },
    {
      key: "taxable",
      header: ({ displayType }) =>
        displayType === "desktop"
          ? getSortColumn("Taxable?", InvoiceItemSortColumns.taxable)
          : "Taxable",
      testId: "taxable",
      cell: ({ row: v }) => (v.taxable ? "Yes" : "No"),
    },
    {
      key: "inactive",
      header: ({ displayType }) =>
        displayType === "desktop"
          ? getSortColumn("Inactive?", InvoiceItemSortColumns.inactive)
          : "Inactive?",
      testId: "inactive",
      cell: ({ row: v }) => (v.inactive ? "Yes" : "No"),
    },
    {
      key: "edit",
      header: "",
      isButtonCell: true,
      cell: ({ row: v, displayType }) => (
        <div className={displayType === "desktop" ? "text-right" : undefined}>
          <EditInvoiceItemButton
            invoiceItemId={v.id}
            item={v}
            onSaveComplete={() => {
              loadData({
                setLoadingData,
                setErrorLoading,
                setInvoiceItems,
              });
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <ContainerHeader
      addButton={
        <Buttons
          onSaveComplete={() => {
            loadData({
              setLoadingData,
              setErrorLoading,
              setInvoiceItems,
            });
          }}
        />
      }
      pageHeader={`Products & Services`}
      messageToShow=""
      showInactiveFilter={false}
      customFilters={
        <div
          className="d-flex flex-wrap align-items-baseline mt-3"
          style={{ columnGap: "20px", rowGap: "20px" }}
        >
          <div
            className="input-group"
            style={{ flexGrow: 1, maxWidth: "380px" }}
          >
            <input
              value={searchFilter}
              onChange={(e) => setSearchFilter(e.currentTarget.value)}
              type="text"
              className="form-control"
              placeholder="Search"
              id={"billingWorkInvoicesSearchField"}
            />
            <button
              className="btn bg-transparent"
              type="button"
              style={{ marginLeft: "-40px", zIndex: 3 }}
              onClick={() => setSearchFilter("")}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>

          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="include-inactive"
              checked={showInactive}
              onChange={(e) => setShowInactive(e.currentTarget.checked)}
            />
            <label className="custom-control-label" htmlFor="include-inactive">
              Show inactive
            </label>
          </div>
        </div>
      }
      itemCount={filteredItems.length}
      onInactiveFilterChanged={(newShowInactive) =>
        setShowInactive(newShowInactive)
      }
      showInactive={showInactive}
      notFluid={false}
    >
      <ServerLoadedContent
        header={null}
        filter={<></>}
        refreshData={() => {
          loadData({
            setLoadingData,
            setErrorLoading,
            setInvoiceItems,
          });
        }}
        dataLength={filteredItems.length}
        loadingData={loadingData}
        errorLoading={errorLoading}
        dataType="invoice items"
        showContentWhileRefreshing={true}
        hasInitialLoadCompleted={invoiceItems !== null}
      >
        <ResponsiveTable
          tableClass="table-striped"
          rows={getSortedInvoiceItems(
            filteredItems,
            currentSortColumn,
            currentSortDirection
          )}
          columns={columns}
          renderMobile={({ row, index }) => {
            return (
              <>
                <ResponsiveTableMobileCard
                  row={row}
                  columns={columns}
                  rowIndex={index}
                />
              </>
            );
          }}
        />
      </ServerLoadedContent>
    </ContainerHeader>
  );
}

function EditInvoiceItemButton({
  invoiceItemId,
  item,
  onSaveComplete,
}: {
  invoiceItemId: string;
  item: IInvoiceItemCrewControl;
  onSaveComplete(): void;
}) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button
        className="btn btn-secondary btn-sm"
        onClick={() => setShowModal(true)}
      >
        Edit
      </button>
      {showModal ? (
        <NonQuickBooksInvoiceItemForm
          invoiceItemId={invoiceItemId}
          onSaveComplete={() => {
            setShowModal(false);
            onSaveComplete();
          }}
          onCancel={() => setShowModal(false)}
          mode="edit"
        />
      ) : null}
    </>
  );
}

function Buttons({ onSaveComplete }: { onSaveComplete(): void }) {
  const [showAddForm, setShowAddForm] = useState(false);
  const [showImport, setShowImport] = useState(false);

  return (
    <>
      <button
        className="btn btn-primary text-nowrap"
        onClick={() => setShowAddForm(true)}
      >
        Add Item
      </button>

      <button
        className="btn btn-secondary ml-3"
        onClick={() => setShowImport(true)}
      >
        Import
      </button>

      {showAddForm ? (
        <NonQuickBooksInvoiceItemForm
          defaults={{}}
          onSaveComplete={() => {
            setShowAddForm(false);
            onSaveComplete();
          }}
          onCancel={() => setShowAddForm(false)}
          mode="add"
        />
      ) : null}

      {showImport ? (
        <InvoiceItemsImport
          onClose={(dataSaved) => {
            if (dataSaved) {
              onSaveComplete();
            }
            setShowImport(false);
          }}
        />
      ) : null}
    </>
  );
}

function loadData({
  setLoadingData,
  setErrorLoading,
  setInvoiceItems,
}: {
  setLoadingData: (v: boolean) => void;
  setErrorLoading: (v: boolean) => void;
  setInvoiceItems: (v: Array<IInvoiceItemCrewControl>) => void;
}) {
  setLoadingData(true);

  return remoteDataProvider
    .getNonQuickBooksInvoiceItems()
    .pipe(timeout(30000))
    .subscribe(
      (result) => {
        setLoadingData(false);
        setInvoiceItems(result);
        setErrorLoading(false);
      },
      () => {
        setLoadingData(false);
        setErrorLoading(true);
      }
    );
}

function getSortedInvoiceItems(
  invoiceItems: Array<IInvoiceItemCrewControl>,
  sortColumn: InvoiceItemSortColumns | null,
  sortDirection: SortDirection
) {
  if (sortColumn === null) {
    return invoiceItems;
  }

  let sortProperties: Array<
    | keyof IInvoiceItemCrewControl
    | ((i: IInvoiceItemCrewControl) => string | number)
  >;
  switch (sortColumn) {
    case InvoiceItemSortColumns.description:
      sortProperties = ["description"];
      break;
    case InvoiceItemSortColumns.unitPrice:
      sortProperties = ["unitPrice"];
      break;
    case InvoiceItemSortColumns.inactive:
      sortProperties = ["inactive"];
      break;
    case InvoiceItemSortColumns.taxable:
      sortProperties = ["taxable"];
      break;
    case InvoiceItemSortColumns.internalNotes:
      sortProperties = ["internalNotes"];
      break;
    default:
      sortProperties = ["name"];
  }

  return getSortedItemsV2(
    invoiceItems,
    sortProperties,
    sortDirection === SortDirection.Descending
  );
}
