import { PageWithBranding } from "../../../containers/app/PageWithBranding";
import { HelpIcon } from "./HelpIcon";
import { Login } from "./Login";

export function LoginPage() {
  return (
    <PageWithBranding>
      <Login />
      <HelpIcon />
    </PageWithBranding>
  );
}
