import { JobBillingType } from "../../../enums/jobBillingType";
import {
  ICustomerPaymentConfiguration,
  BillingFrequencyType,
} from "../../../models/ICustomerPaymentConfiguration";
import { logError } from "../../../services/errorLogger";

// Had to remove from JobBillingConfiguration since
// having an export function and modifying the file would
// trigger a full page refresh rather than hot loading
// the module. Appears due to an exported function
// not in the React tree.
export function getFrequency(
  paymentConfiguration: ICustomerPaymentConfiguration
) {
  if (paymentConfiguration.frequencyType === BillingFrequencyType.daily) {
    if (paymentConfiguration.frequencyInterval === 1) {
      return "day";
    } else {
      return `${paymentConfiguration.frequencyInterval} days`;
    }
  } else if (
    paymentConfiguration.frequencyType === BillingFrequencyType.weekly
  ) {
    if (paymentConfiguration.frequencyInterval === 1) {
      return "week";
    } else {
      return `${paymentConfiguration.frequencyInterval} weeks`;
    }
  } else if (
    paymentConfiguration.frequencyType === BillingFrequencyType.monthly
  ) {
    if (paymentConfiguration.frequencyInterval === 1) {
      return "month";
    } else {
      return `${paymentConfiguration.frequencyInterval} months`;
    }
  } else if (
    paymentConfiguration.frequencyType === BillingFrequencyType.annually
  ) {
    if (paymentConfiguration.frequencyInterval === 1) {
      return "year";
    } else {
      return `${paymentConfiguration.frequencyInterval} years`;
    }
  } else if (
    paymentConfiguration.frequencyType === BillingFrequencyType.quarterly
  ) {
    return "every 3 months";
  } else if (
    paymentConfiguration.frequencyType === BillingFrequencyType.semiAnnually
  ) {
    return "every 6 months";
  } else {
    logError(
      `unexpected payment config frequency type: ${paymentConfiguration.frequencyType}`
    );
    return "";
  }
}

export function areDiscountAndTaxRateEnabled(billingType: JobBillingType) {
  return (
    billingType === JobBillingType.AtProjectCompletionLineItems ||
    billingType === JobBillingType.PerServiceLineItems
  );
}
