import {
  faArrowUpFromBracket,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { isAndroid, isMobileSafari } from "react-device-detect";

interface IParams {}

const InstallPrompt: React.FunctionComponent<IParams> = () => {
  // Checks if should display install popup notification:
  const [show, setShow] = useState<boolean>(
    (isMobileSafari || isAndroid) &&
      !runningAsPwa() &&
      !hasPromptBeenDismissed()
  );

  const [hasInitialDelayPassed, setInitialDelayPassed] = useState(false);

  const [deferredInstall, setDeferredInstall] = useState<unknown>(null);
  useEffect(() => {
    const handleInstallPrompt = (e: unknown) => {
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      if (
        e &&
        typeof e === "object" &&
        "preventDefault" in e &&
        typeof e.preventDefault === "function"
      ) {
        e.preventDefault();

        setDeferredInstall(e);
      }
    };

    if ("BeforeInstallPromptEvent" in window) {
      window.addEventListener("beforeinstallprompt", handleInstallPrompt);
    }

    const timeoutId = setTimeout(() => {
      setInitialDelayPassed(true);
    }, 500);

    return function cleanup() {
      window.removeEventListener("beforeinstallprompt", handleInstallPrompt);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  if (!show) {
    return null;
  }

  // Don't show the message immediately for Android since BeforeInstallPromptEvent
  // takes a moment to fire and don't want to show this content if the
  // install button will show later
  if (
    !hasInitialDelayPassed &&
    isAndroid &&
    !doesDeferredInstallHavePromptMethod(deferredInstall)
  ) {
    return null;
  }

  let textAlignmentClass = "text-left";
  let containerClass = "mr-4";
  let installMessage: React.ReactNode;
  if (isMobileSafari) {
    installMessage = (
      <small>
        Install Crew Control on your device by tapping
        <FontAwesomeIcon icon={faArrowUpFromBracket} className="ml-2 mr-2" />
        then "Add to Home Screen".
      </small>
    );
  } else if (!doesDeferredInstallHavePromptMethod(deferredInstall)) {
    installMessage = (
      <small>
        Install Crew Control on your device by tapping your browser menu icon
        <FontAwesomeIcon icon={faEllipsisVertical} className="ml-2 mr-2" />
        then "Install app".
      </small>
    );
  } else {
    installMessage = (
      <button
        className="btn btn-small btn-light"
        type="button"
        onClick={() => {
          deferredInstall.prompt();

          setPromptDismissed();
          setShow(false);
        }}
      >
        Install as App
      </button>
    );
    textAlignmentClass = "text-center";
    containerClass = "";
  }

  return (
    <div
      className={`alert alert-dark alert-dismissible mb-0 border rounded py-0 px-1 ${textAlignmentClass}`}
      data-testid="installPromptContainer"
    >
      <div className={`m-2 ${containerClass}`}>{installMessage}</div>
      <button
        type="button"
        className="close pr-2"
        data-dismiss="alert"
        aria-label="Close"
        onClick={() => {
          setPromptDismissed();
          setShow(false);
        }}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export default InstallPrompt;

function doesDeferredInstallHavePromptMethod(
  deferredInstall: unknown
): deferredInstall is { prompt: () => void } {
  return (
    deferredInstall !== null &&
    typeof deferredInstall === "object" &&
    "prompt" in deferredInstall
  );
}

const installPromptDismissedValue = "true";
function hasPromptBeenDismissed() {
  try {
    return (
      window.localStorage.getItem("InstallPrompt_dismissed") ===
      installPromptDismissedValue
    );
  } catch {
    return true;
  }
}

function setPromptDismissed() {
  window.localStorage.setItem(
    "InstallPrompt_dismissed",
    installPromptDismissedValue
  );
}

function runningAsPwa() {
  let runningAsPwa = false;
  try {
    const navigatorAny = navigator as any;
    if (
      navigatorAny.standalone ||
      window.matchMedia("(display-mode: standalone)").matches
    ) {
      runningAsPwa = true;
    }
  } catch {
    console.error("unable to determine if running as pwa");
  }

  return runningAsPwa;
}
