import React from "react";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../../modules/actionCreators";
import { getSortedItemsV2 } from "../../../../services/sortingService";

const CustomerNotificationResultsAlert: React.FunctionComponent<{}> = () => {
  const customerNotificationResults = useApplicationStateSelector(
    (s) => s.scheduleUi.customerNotificationResults
  );
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customersWithResults = getSortedItemsV2(
    customerNotificationResults
      .map((r) => {
        const matchingCustomer = customers.find((c) => c.id === r.customerId);
        return {
          ...r,
          customerName: matchingCustomer?.name,
        };
      })
      .filter((r) => !!r.customerName),
    ["customerName"]
  );
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Modal
        isOpen={customerNotificationResults.length > 0}
        backdrop="static"
        toggle={() => {
          dispatch(actionCreators.clearCustomerNotificationResults());
        }}
        scrollable={true}
      >
        <ModalHeader
          toggle={() => {
            dispatch(actionCreators.clearCustomerNotificationResults());
          }}
        >
          Notification Results
        </ModalHeader>
        <ModalBody>
          <ul>
            {customersWithResults.map((r) => (
              <li key={r.customerId}>
                {r.customerName}:{" "}
                {r.success ? (
                  <span className="text-success">Success!</span>
                ) : (
                  <span className="text-danger">{r.error}</span>
                )}
              </li>
            ))}
          </ul>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "space-between" }}>
          <div></div>
          <div>
            <button
              className="btn btn-primary"
              onClick={() => {
                dispatch(actionCreators.clearCustomerNotificationResults());
              }}
              type="button"
            >
              Close
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default CustomerNotificationResultsAlert;
