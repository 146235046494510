import { IJob } from "./IJob";
import { IMaintenanceJobScheduleProperties } from "./IMaintenanceJobScheduleProperties";

export interface IMaintenanceJob
  extends IJob,
    IMaintenanceJobScheduleProperties {
  order: number;

  // TODO: Move to save specific model?
  precedingJobId?: string;
}

export interface ISeasonalDate {
  month: number;
  dayOfMonth: number;
}

export enum MaintenanceJobFrequency {
  Weekly = 0,
  EveryTwoWeeks = 1,
  EveryFourWeeks = 2,
  None = 3,
  Custom = 4,
  EverySixWeeks = 5,
  Daily = 6,
  Monthly = 7,
}
