import { Action } from "@reduxjs/toolkit";
import { map, mergeMap } from "rxjs/operators";
import { concat, EMPTY, Observable } from "rxjs";
import { actionCreators } from "../../../modules/actionCreators";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { IApplicationState } from "../../../modules";
import { IOpportunity } from "../models/IOpportunity";
import proposalDataProvider from "./proposalDataProvider";
import { proposalsActionCreators } from "../modules/proposal";

export function loadOpportunityRelatedData(
  state: IApplicationState,
  opportunities: Array<IOpportunity>
) {
  const customersLoad = loadCustomers(state, opportunities);

  const proposalLoad = loadProposals(state, opportunities);

  return concat(customersLoad, proposalLoad);
}

function loadCustomers(
  state: IApplicationState,
  opportunities: IOpportunity[]
) {
  const existingCustomers = state.customer.customers;
  const existingCustomerLocations = state.customer.customerAdditionalLocations;
  const missingCustomers = opportunities
    .filter(
      (opportunity) =>
        !existingCustomers.some((c) => c.id === opportunity.customerId)
    )
    .map((o) => o.customerId);
  const customersWithMissingLocations = opportunities
    .filter(
      (opportunity) =>
        opportunity.customerAdditionalLocationId &&
        !existingCustomerLocations.some(
          (l) => l.id === opportunity.customerAdditionalLocationId
        )
    )
    .map((o) => o.customerId);

  const customersToLoad = [
    ...missingCustomers,
    ...customersWithMissingLocations,
  ];

  let opportunityRelatedDataLoads: Observable<Action<any>>;
  if (customersToLoad.length === 0) {
    opportunityRelatedDataLoads = EMPTY;
  } else {
    opportunityRelatedDataLoads = remoteDataProvider
      .getCustomers({ customerIds: customersToLoad })
      .pipe(
        mergeMap(({ customers, customerAdditionalLocations }) => {
          return [
            actionCreators.loadCustomersComplete(
              customers,
              customerAdditionalLocations
            ),
          ];
        })
      );
  }
  return opportunityRelatedDataLoads;
}

export function loadProposals(
  state: IApplicationState,
  opportunities: Array<IOpportunity>
) {
  const proposals = state.proposal.proposals;
  const missingProposals = opportunities
    .filter(
      (opportunity) =>
        opportunity.proposalId &&
        !proposals.some((proposal) => proposal.id === opportunity.proposalId)
    )
    .map((opportunity) => opportunity.proposalId as string);

  if (missingProposals.length === 0) {
    return EMPTY;
  } else {
    return proposalDataProvider
      .getProposals({ proposalIds: missingProposals })
      .pipe(
        map((proposals) =>
          proposalsActionCreators.loadProposalsCompleted({ proposals })
        )
      );
  }
}
