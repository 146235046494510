import React from "react";
import { useDispatch } from "react-redux";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { actionCreators } from "../../../../modules/actionCreators";
import { IPermanentDropJobs } from "../../../../models/IPermanentDropJobs";
import jobFinder from "../../../../services/jobFinder";
import { getNameForJob } from "../../../../services/jobService";
import { IJob } from "../../../../models/IJob";
import { ICustomer } from "../../../../models/ICustomer";
import { ICustomerAdditionalLocation } from "../../../../models/ICustomerAdditionalLocation";
import { IMaintenanceJob } from "../../../../models/IMaintenanceJob";
import Spinner from "../Spinner";

interface IProps {}

function getNamesForJobs(
  maintenanceJobIds: string[],
  maintenanceJobs: Array<IMaintenanceJob>,
  customers: Array<ICustomer>,
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>
) {
  return maintenanceJobIds
    .map((jid) => jobFinder.getJobById(maintenanceJobs, jid))
    .filter((j) => j !== null)
    .map((j) =>
      getNameForJob({
        job: j as IJob,
        customers,
        customerAdditionalLocations,
        fallbackToAddressIfAdditionalLocationNameNotSet: false,
      })
    )
    .sort((a, b) => {
      if (a.toLocaleLowerCase() < b.toLocaleLowerCase()) {
        return -1;
      } else if (a.toLocaleLowerCase() > b.toLocaleLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });
}

const DropJobsPermanentPrompt: React.FunctionComponent<IProps> = () => {
  const dispatch = useDispatch();
  const permanentDropJobsSaveError = useApplicationStateSelector(
    (s) => s.scheduleUi.permanentDropJobsSaveError
  );
  const permanentDropJobsSaveValidationError = useApplicationStateSelector(
    (s) => s.scheduleUi.permanentDropJobsSaveValidationError
  );
  const permanentDropJobsSaveValidationErrorMessage =
    useApplicationStateSelector(
      (s) => s.scheduleUi.permanentDropJobsSaveValidationErrorMessage
    );
  const permanentDropJobsSaveInProgress = useApplicationStateSelector(
    (s) => s.scheduleUi.permanentDropJobsSaveInProgress
  );
  const permanentDropJobsMessage = useApplicationStateSelector(
    (s) => s.scheduleUi.permanentDropJobsMessage
  );
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );
  const maintenanceJobs = useApplicationStateSelector((s) => s.job.jobs);
  const showPermanentChangePrompt = useApplicationStateSelector(
    (s) => s.common.adminViewConfiguration.showPermanentChangePrompt
  );

  if (
    !showPermanentChangePrompt ||
    permanentDropJobsMessage === null ||
    permanentDropJobsMessage.maintenanceJobIds.length === 0
  ) {
    return null;
  }

  const customerNamesForJobsAffected = getNamesForJobs(
    permanentDropJobsMessage.maintenanceJobIds,
    maintenanceJobs,
    customers,
    customerAdditionalLocations
  );

  const customerNamesForJobsExcluded = getNamesForJobs(
    permanentDropJobsMessage.excludedMaintenanceJobIds,
    maintenanceJobs,
    customers,
    customerAdditionalLocations
  );

  if (!permanentDropJobsMessage) {
    return null;
  }

  let message: React.ReactNode;
  let showYesNoButtons: boolean;
  if (!permanentDropJobsSaveError) {
    message = "Apply changes to future weeks?";
    showYesNoButtons = true;
  } else if (!permanentDropJobsSaveValidationError) {
    message =
      "An error occurred while making this change permanent.  Would you like to try again?";
    showYesNoButtons = true;
  } else {
    if (permanentDropJobsSaveValidationErrorMessage) {
      message = (
        <>
          An error occurred while making this change permanent:{" "}
          <strong>{permanentDropJobsSaveValidationErrorMessage}</strong>
        </>
      );
    } else {
      message = "An error occurred while making this change permanent.";
    }
    showYesNoButtons = false;
  }

  return (
    <React.Fragment>
      {permanentDropJobsSaveInProgress ? <Spinner /> : null}

      <div className="board-sticky-banner" role="alert">
        <div
          className={
            "alert alert-dismissible fade show alert-info permanent-drop-job-message"
          }
        >
          <div>
            {message}

            {showYesNoButtons ? (
              <>
                <button
                  className="btn btn-link schedule-banner-confirm-button"
                  onClick={() => {
                    dispatch(
                      actionCreators.startSavePermanentJobs(
                        permanentDropJobsMessage as IPermanentDropJobs
                      )
                    );
                  }}
                >
                  Yes
                </button>
                <button
                  className="btn btn-link schedule-banner-confirm-button"
                  onClick={(e) =>
                    dispatch(actionCreators.clearPermanentJobsMessage())
                  }
                >
                  No
                </button>
              </>
            ) : null}

            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={(e) =>
                dispatch(actionCreators.clearPermanentJobsMessage())
              }
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div>
            The following recurring jobs will be updated:
            <ul>
              {customerNamesForJobsAffected.map((j) => (
                <li key={j}>{j}</li>
              ))}
            </ul>
          </div>
          {customerNamesForJobsExcluded.length > 0 ? (
            <div>
              The following recurring jobs will be skipped because Custom
              frequency jobs cannot be set as flexible jobs:
              <ul>
                {customerNamesForJobsExcluded.map((j) => (
                  <li key={j}>{j}</li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default DropJobsPermanentPrompt;
