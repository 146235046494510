import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import constants from "../../../constants";
import { OpportunityStatus } from "../enums/opportunityStatus";
import { IOpportunity } from "../models/IOpportunity";
import { IOpportunityBoard } from "../models/IOpportunityBoard";
import { getOpportunityLaneKey } from "../services/opportunityService";

type ArchivePrompt = {
  promptMessageOverride?: string;
  opportunitiesToArchive: Array<{
    opportunityId: string;
    customerName: string;
  }>;
};

export interface IOpportunityState {
  opportunities: Array<IOpportunity>;
  opportunityLoadStatus: OpportunityLoadStatus;
  opportunitiesMoving: Array<string>;
  opportunitiesSelected: Array<string>;
  board: IOpportunityBoard;

  pageErrorMessage: string | null;
  archivePrompt: null | ArchivePrompt;
}

export enum OpportunityLoadStatus {
  notStarted = 0,
  started = 1,
  complete = 2,
  error = 3,
}

const initialState: IOpportunityState = {
  opportunities: [],
  opportunitiesMoving: [],
  opportunitiesSelected: [],
  opportunityLoadStatus: OpportunityLoadStatus.notStarted,
  board: {
    todoOpportunities: [],
    inProgressOpportunities: [],
    proposalOpportunities: [],
  },

  pageErrorMessage: "",
  archivePrompt: null,
};

const { reducer, actions } = createSlice({
  initialState,
  name: "opportunity",
  reducers: {
    loadOpportunitiesStart: (state) => {
      state.opportunityLoadStatus = OpportunityLoadStatus.started;
      state.pageErrorMessage = null;
    },

    loadOpportunitiesCompleted: (
      state,
      action: PayloadAction<{
        opportunities: Array<IOpportunity>;
        board: IOpportunityBoard;
      }>
    ) => {
      state.opportunityLoadStatus = OpportunityLoadStatus.complete;
      state.opportunities = [
        // Ensure archived items that aren't loaded in default load aren't cleared
        ...state.opportunities.filter(
          (existingOpportunity) =>
            existingOpportunity.archived &&
            !action.payload.opportunities.some(
              (newOpportunity) => newOpportunity.id === existingOpportunity.id
            )
        ),
        ...action.payload.opportunities,
      ];
      state.board = action.payload.board;
    },

    loadOpportunitiesError: (state) => {
      state.opportunityLoadStatus = OpportunityLoadStatus.error;
    },

    addOpportunity: (state, action: PayloadAction<IOpportunity>) => {
      state.board.todoOpportunities.splice(0, 0, action.payload.id);

      state.opportunities.push({
        ...action.payload,
      } as IOpportunity);
    },

    editOpportunity: (state, action: PayloadAction<IOpportunity>) => {
      const opportunityIndex = state.opportunities.findIndex(
        (o) => o.id === action.payload.id
      );
      if (opportunityIndex !== -1) {
        state.opportunities[opportunityIndex] = {
          ...state.opportunities[opportunityIndex],
          ...action.payload,
        };
      }
    },

    deleteOpportunity: (
      state,
      action: PayloadAction<{ opportunityId: string }>
    ) => {
      state.opportunities = state.opportunities.filter(
        (o) => o.id !== action.payload.opportunityId
      );
    },

    dropOpportunity: (
      state,
      action: PayloadAction<{
        opportunityId: string;
        precedingOpportunityId: string;
        status: OpportunityStatus;
      }>
    ) => {
      const laneKey = getOpportunityLaneKey(action.payload.status);
      if (laneKey === null) {
        return state;
      }

      const precedingOpportunityPredicate = (opportunityId: string): boolean =>
        opportunityId === action.payload.precedingOpportunityId;

      const isDestinationFirstItem =
        action.payload.precedingOpportunityId ===
        constants.idForFirstOpportunity;

      const isPrecedingJobFound =
        isDestinationFirstItem ||
        state.board[laneKey].some(precedingOpportunityPredicate);

      if (!isPrecedingJobFound) {
        return state;
      }

      removeOpportunityId(
        action.payload.opportunityId,
        state.board,
        "todoOpportunities"
      );

      removeOpportunityId(
        action.payload.opportunityId,
        state.board,
        "inProgressOpportunities"
      );

      removeOpportunityId(
        action.payload.opportunityId,
        state.board,
        "proposalOpportunities"
      );

      let destinationOrder: number | null = null;
      if (isDestinationFirstItem) {
        destinationOrder = 0;
      } else {
        const precedingOpportunityIndex = state.board[laneKey].findIndex(
          precedingOpportunityPredicate
        );

        if (precedingOpportunityIndex !== -1) {
          destinationOrder = precedingOpportunityIndex + 1;
        }
      }

      state.opportunitiesSelected = state.opportunitiesSelected.filter(
        (o) => o !== action.payload.opportunityId
      );

      if (destinationOrder !== null) {
        state.opportunitiesMoving.push(action.payload.opportunityId);

        state.board[laneKey].splice(
          destinationOrder,
          0,
          action.payload.opportunityId
        );
      }
    },

    dropOpportunityCompleted: (
      state,
      action: PayloadAction<{
        opportunityId: string;
      }>
    ) => {
      state.opportunitiesMoving = state.opportunitiesMoving.filter(
        (id) => id !== action.payload.opportunityId
      );
    },

    dropOpportunityError: (
      state,
      action: PayloadAction<{
        opportunityId: string;
      }>
    ) => {
      state.pageErrorMessage =
        "An error occurred when dragging the opportunity. Please refresh your browser to get to a valid state.";
      state.opportunitiesMoving = state.opportunitiesMoving.filter(
        (id) => id !== action.payload.opportunityId
      );
    },

    setError: (
      state,
      action: PayloadAction<{
        pageErrorMessage: string;
      }>
    ) => {
      state.pageErrorMessage = action.payload.pageErrorMessage;
    },

    clearError: (state) => {
      state.pageErrorMessage = null;
    },

    toggleSelectOpportunity: (
      state,
      action: PayloadAction<{ opportunityId: string }>
    ) => {
      if (
        state.opportunitiesSelected.indexOf(action.payload.opportunityId) === -1
      ) {
        // For now, only a single opportunity can be selected
        state.opportunitiesSelected = [
          ...state.opportunitiesSelected,
          action.payload.opportunityId,
        ];
      } else {
        state.opportunitiesSelected = state.opportunitiesSelected.filter(
          (o) => o !== action.payload.opportunityId
        );
      }
    },

    clearSelectedOpportunity: (state) => {
      state.opportunitiesSelected = [];
    },

    loadSpecificOpportunities: (
      state,
      action: PayloadAction<{ opportunities: Array<IOpportunity> }>
    ) => {
      state.opportunities = [
        ...state.opportunities.filter(
          (existingOpportunity) =>
            !action.payload.opportunities.some(
              (newOpportunity) => existingOpportunity.id === newOpportunity.id
            )
        ),
        ...action.payload.opportunities,
      ];
    },

    // Just used for actions that are fed to epic
    reloadBoard: () => {},
    reloadOpportunity: (
      state,
      action: PayloadAction<{ opportunityId: string }>
    ) => {},

    archiveOpportunityComplete: (
      state,
      action: PayloadAction<{ opportunityId: string }>
    ) => {
      const opportunityIndex = state.opportunities.findIndex(
        (o) => o.id === action.payload.opportunityId
      );
      if (opportunityIndex !== -1) {
        state.opportunities[opportunityIndex] = {
          ...state.opportunities[opportunityIndex],
          archived: true,
        };

        removeOpportunityId(
          action.payload.opportunityId,
          state.board,
          "todoOpportunities"
        );

        removeOpportunityId(
          action.payload.opportunityId,
          state.board,
          "inProgressOpportunities"
        );

        removeOpportunityId(
          action.payload.opportunityId,
          state.board,
          "proposalOpportunities"
        );
      }
    },

    showArchivePrompt: (state, action: PayloadAction<ArchivePrompt>) => {
      state.archivePrompt = action.payload;
    },

    closeArchivePrompt: (state, action: PayloadAction<{}>) => {
      state.archivePrompt = null;
    },
  },
});

export const opportunitiesActionCreators = actions;

export default reducer;

function removeOpportunityId(
  id: string,
  board: IOpportunityBoard,
  key: keyof IOpportunityBoard
) {
  board[key] = board[key].filter((a) => a !== id);
}
