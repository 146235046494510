export interface IOnboardingFormData {
  businessType: BusinessType | null;
  irsFilingName: string;
  dba: string;
  secondaryPayoutDescriptor: string;
  einType: string;
  ein: string;
  website: string;
  annualProcessingVolume: string;
  averageTransactionAmount: string;
  businessEmail: string;
  daytimePhone: string;
  customerServicePhone: string;
  businessDescription: string;
  publicCompany: boolean;
  irsNotForProfitLetterStatus: boolean | null;
  irsNotForProfitLetterIssuedDate: string;
  industry: string;

  bankRoutingNumber: string;
  bankAccountNumber: string;
  bankAccountNumberConfirmed: string;
  bankAccountType: string;

  principals: Array<IPrincipal>;
  primaryContactPrincipalId: string;

  agreeTermsAndConditions: boolean;
  attestInformationCorrect: boolean;
  paymentsPortalUsername: string;
  paymentsPortalPassword: string;
  paymentsPortalPasswordConfirmed: string;

  address: IAddress;
}

export interface IPrincipal {
  id: string;
  // Need to add internalId since react-hook-form won't send up its id in payload
  internalId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  ssn: string;
  dob: string;
  businessTitle: string;
  ownershipPercent: string;
  significantResponsibility: boolean;
  politicallyExposed: boolean;
  email: string;
  phone: string;

  address: IAddress;
}

export interface IAddress {
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

export enum BusinessType {
  SolePropietor = 0,
  Corp = 1,
  Llc = 2,
  Partner = 3,
  NonProfit = 5,
  Gov = 6,
}
