import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format, getDay, parse, startOfMonth } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";
import dateService from "../../../services/dateService";
import { builders } from "../../../services/routing";

const DayHeader: React.FunctionComponent<{ name: string }> = ({ name }) => (
  <div className="font-weight-bold text-center">{name}</div>
);

interface IProps {
  className: string;
  date: string | Date;
  calendarContents: { [k: string]: JSX.Element | undefined };
  previousMonthLink: string;
  nextMonthLink: string;
  nextMonthLinkVisible: boolean;
}

const Calendar: React.FunctionComponent<IProps> = ({
  className,
  date,
  calendarContents,
  previousMonthLink,
  nextMonthLink,
  nextMonthLinkVisible,
}) => {
  const startOfMonthDate = startOfMonth(parse(date));
  const monthDates = dateService.getMonthDates(startOfMonthDate);

  return (
    <>
      <div className="d-flex  justify-content-between">
        <div>
          <Link to={previousMonthLink}>
            <FontAwesomeIcon
              icon={faChevronLeft}
              size={"2x"}
              className="text-dark py-3"
            />
            <span className="sr-only">Previous Month</span>
          </Link>
        </div>

        <div>
          <h1 data-testid="calendarHeader">{format(date, "MMMM YYYY")}</h1>
        </div>

        <div>
          {nextMonthLinkVisible ? (
            <Link to={nextMonthLink}>
              <FontAwesomeIcon
                icon={faChevronRight}
                size={"2x"}
                className="text-dark py-3"
              />
              <span className="sr-only">Next Month</span>
            </Link>
          ) : null}
        </div>
      </div>
      <div
        className={className}
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7, 1fr)",
          columnGap: "20px",
          rowGap: "10px",
        }}
      >
        <DayHeader name="Sunday" />
        <DayHeader name="Monday" />
        <DayHeader name="Tuesday" />
        <DayHeader name="Wednesday" />
        <DayHeader name="Thursday" />
        <DayHeader name="Friday" />
        <DayHeader name="Saturday" />
        {monthDates.map((date, dateIndex) => {
          const isTodaysDate = dateService.areDatesEqual(
            date,
            dateService.getCurrentDate()
          );

          return (
            <div
              key={dateService.formatAsIso(date)}
              style={{
                minHeight: "100px",
                gridColumn: dateIndex === 0 ? getDay(date) + 1 : undefined,
                position: "relative",
              }}
              className="border border-light p-2 bg-secondary"
              data-testid="calendarDay"
              data-date={dateService.formatAsIso(date)}
            >
              <div className="d-flex justify-content-center font-weight-light">
                <Link
                  to={builders.schedule.buildSequenceDayRoute(
                    dateService.formatAsIso(date)
                  )}
                  className={isTodaysDate ? "text-white" : "text-dark"}
                >
                  <div
                    className={
                      "text-center align-middle calendar-day-number" +
                      (isTodaysDate ? " bg-primary" : "")
                    }
                    data-testid="calendarDayNumber"
                  >
                    {format(date, "D")}
                  </div>
                </Link>
              </div>
              {calendarContents[dateService.formatAsIso(date)] ?? null}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Calendar;
