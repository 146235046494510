export function getOffsetFromElement(
  e: React.MouseEvent<HTMLDivElement, MouseEvent>
) {
  let offset = e.clientY - e.currentTarget.getBoundingClientRect().top;

  if (isNaN(offset)) {
    offset = 0;
  }

  return offset;
}
