import { actionCreators } from "../actionCreators";
import { IAction, IManageCustomersSearchStart } from "../actionTypeDefinitions";
import { IRootState } from "../../store";
import { mergeMap, catchError } from "rxjs/operators";
import { concat, forkJoin, of } from "rxjs";
import remoteDataProvider, {
  IGetCustomersResult,
} from "../../services/remoteDataProvider";
import { ICustomer } from "../../models/ICustomer";
import { IManageCustomersSearchResult } from "../../models/IManageCustomersSearch";
import { getCustomersToLoad } from "../../services/lazyLoadingService";

export function handleManageCustomersSearch(
  action: IManageCustomersSearchStart,
  state: IRootState
) {
  const { customers, customersLoading } = state.customer;

  return remoteDataProvider
    .manageCustomersSearch(
      action.search,
      action.searchingAllCustomers,
      action.page,
      action.pageSize,
      true,
      action.includeInactive
    )
    .pipe(
      mergeMap((searchResults) => {
        return loadAdditionalData(searchResults, customers, customersLoading);
      }),
      mergeMap(([searchResults, customers]) => {
        return handleSearchComplete(
          action.search,
          action.append,
          searchResults,
          customers
        );
      }),
      catchError(() => of(actionCreators.errorManageCustomersSearch()))
    );
}

function handleSearchComplete(
  search: string,
  append: boolean,
  searchResults: IManageCustomersSearchResult,
  loadedCustomers: IGetCustomersResult
) {
  const actionsToCreate: Array<IAction> = [];
  if (loadedCustomers.customers.length > 0) {
    actionsToCreate.push(
      actionCreators.loadCustomersComplete(
        loadedCustomers.customers,
        loadedCustomers.customerAdditionalLocations
      )
    );
  }

  actionsToCreate.push(
    actionCreators.completeManageCustomersSearch(
      searchResults.customers,
      append,
      searchResults.additionalCustomers,
      search
    )
  );

  return concat(actionsToCreate);
}

function loadAdditionalData(
  searchResults: IManageCustomersSearchResult,
  customers: ICustomer[],
  customersLoading: string[]
) {
  let loadCustomers = of({
    customers: [],
    customerAdditionalLocations: [],
    oneTimeJobIds: [],
    maintenanceJobIds: [],
    opportunityIds: [],
    projectIds: [],
  } as IGetCustomersResult);

  const customersToLoad = getCustomersToLoad(
    searchResults.customers.map((c) => c.id),
    customers,
    customersLoading
  );

  if (customersToLoad.length > 0) {
    loadCustomers = remoteDataProvider.getCustomers({
      customerIds: customersToLoad,
    });
  }

  return forkJoin([of(searchResults), loadCustomers]);
}
