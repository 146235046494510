import React from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { isImageByType } from "../../../services/fileService";
import ReactImagesLightbox from "react-images";

interface IProps {
  itemIndex?: number;
  setItemIndex: (newIndex: number) => void;
  items: Array<ILightboxItem>;
  onClose: () => void;
}

interface ILightboxItem {
  caption: string | null;
  imagePath: string | null;
  contentType: string;
}

const Lightbox: React.FunctionComponent<IProps> = ({
  itemIndex,
  setItemIndex,
  items,
  onClose,
}) => {
  const internalItemIndex = itemIndex ?? 0;

  const imagePrefix = useApplicationStateSelector((s) => s.common.imagePrefix);
  const photosToShow = items.filter(
    (p) => p.imagePath && isImageByType(p.contentType)
  );

  return (
    <ReactImagesLightbox
      backdropClosesModal={true}
      isOpen={true}
      currentImage={internalItemIndex}
      onClose={() => onClose()}
      onClickNext={() => {
        setItemIndex(internalItemIndex + (1 % photosToShow.length));
      }}
      onClickPrev={() => {
        setItemIndex(
          internalItemIndex - 1 < 0
            ? photosToShow.length - 1
            : internalItemIndex - 1
        );
      }}
      images={photosToShow.map((p) => ({
        caption: p.caption ?? "",
        src: `${imagePrefix}/${encodeURIComponent(p.imagePath ?? "")}`,
      }))}
    />
  );
};

export default Lightbox;
