import { forkJoin, of } from "rxjs";
import { catchError, mergeMap, timeout } from "rxjs/operators";
import { getErrorMessageFromError } from "../../services/httpErrorHandler";
import remoteDataProvider from "../../services/remoteDataProvider";
import { actionCreators } from "../actionCreators";
import { IJobInstanceDeleteStart } from "../actionTypeDefinitions";

export function handleJobInstanceDeleteStart(action: IJobInstanceDeleteStart) {
  return remoteDataProvider
    .deleteJobInstance(
      action.jobInstanceIds,
      action.markMaintenanceJobsInactive
    )
    .pipe(
      timeout(15000),
      mergeMap((deleteJobInstanceResult) => {
        if (deleteJobInstanceResult.updatedMaintenanceJobIds.length > 0) {
          return remoteDataProvider
            .getMaintenanceJobs(
              deleteJobInstanceResult.updatedMaintenanceJobIds
            )
            .pipe(
              mergeMap((getMaintenanceJobsResult) =>
                forkJoin({
                  deleteJobInstanceResult: of(deleteJobInstanceResult),
                  loadJobsCompleteAction: of(
                    actionCreators.loadMaintenanceJobsComplete(
                      getMaintenanceJobsResult.maintenanceJobs,
                      getMaintenanceJobsResult.customers,
                      getMaintenanceJobsResult.customerAdditionalLocations
                    )
                  ),
                })
              )
            );
        } else {
          return forkJoin({
            deleteJobInstanceResult: of(deleteJobInstanceResult),
            loadJobsCompleteAction: of(actionCreators.null()),
          });
        }
      }),
      mergeMap(({ deleteJobInstanceResult, loadJobsCompleteAction }) => {
        return of(
          loadJobsCompleteAction,
          actionCreators.jobInstanceDeleteComplete(
            action.jobInstanceIds,
            deleteJobInstanceResult.updatedDaySchedules,
            deleteJobInstanceResult.updatedFlexibleJobWeeks
          ),
          actionCreators.showManageUiMessage("Job card deleted")
        );
      }),
      catchError((err) => {
        let errorMessage = getErrorMessageFromError(
          err,
          "An unknown error occurred while deleting the job card."
        );

        return of(
          actionCreators.jobInstanceDeleteError(
            action.jobInstanceIds,
            errorMessage
          )
        );
      })
    );
}
