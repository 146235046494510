import { useEffect, useRef, useState } from "react";
import { forkJoin, of, Subscription } from "rxjs";
import { catchError, map, timeout } from "rxjs/operators";
import {
  IInvoiceJobInstance,
  IInvoiceProject,
} from "../containers/app/forms/InvoiceForm.types";
import { IBillingReportJobInstanceDetails } from "../models/IBillingReport";
import { IQuickBooksCustomer } from "../models/IQuickBooksCustomer";
import { IQuickBooksInvoiceSettings } from "../models/IQuickBooksInvoiceSettings";
import { IInvoiceItem } from "../models/IInvoiceItem";
import dataProvider from "../services/dataProvider";
import { getErrorMessageFromError } from "../services/httpErrorHandler";
import { useApplicationStateSelector } from "./useApplicationStateSelector";
import { useLoadCustomers } from "./useLoadCustomers";
import { UserSettingsType } from "../enums/userSettingsType";
import { useDispatch } from "react-redux";
import {
  commonUiActionCreators,
  QuickBooksModalMode,
} from "../modules/commonUi";
import { getInvoiceItems } from "../services/invoiceFormService";
import { IInvoiceDefaultLineItem } from "../models/IInvoiceDefaultLineItem";
import invoiceDataProvider, {
  IInvoiceDefaultsResponse,
} from "../slices/billing/services/invoiceDataProvider";
import { IInvoiceAmountAdjustments } from "../slices/sales/components/ProposalForm/IFormData";
import { DiscountType } from "../enums/DiscountType";
import { IInvoiceDefaultDepositCredit } from "../slices/billing/models/IInvoiceDefaultDepositCredit";
import constants from "../constants";
import { isStringSet } from "../services/stringService";
import { useUserSettings } from "../services/userSettingsService";
import { IInvoiceDefaultProposalJobSummary } from "../slices/billing/models/IInvoiceDefaultProposalJobSummary";

interface IArgs {
  showForm: boolean;
  jobInstances: Array<IInvoiceJobInstance> | undefined;
  customerId: string;
  invoiceId: string | null;
  setAllowAchPayment?: (input: boolean) => void;
  setAllowCreditCardPayment?: (input: boolean) => void;
  closeForm: () => void;
  projects?: Array<IInvoiceProject> | null;
  setPaymentMethodPreviouslyAuthorized?: (hide: boolean) => void;
  setShowLineItemPrices?: (hide: boolean) => void;
}

export function useLoadInvoiceData(args: IArgs) {
  const {
    showForm,
    jobInstances,
    customerId,
    setAllowAchPayment,
    setAllowCreditCardPayment,
    projects,
    setPaymentMethodPreviouslyAuthorized,
    setShowLineItemPrices,
    invoiceId,
  } = args;

  const { getUserSettings, setUserSettings } = useUserSettings();

  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );

  const [quickBooksCustomers, setQuickBooksCustomers] = useState<
    Array<IQuickBooksCustomer>
  >([]);

  const [invoiceItems, setInvoiceItems] = useState<Array<IInvoiceItem>>([]);

  const [billingDetails, setBillingDetails] = useState<
    Array<IBillingReportJobInstanceDetails>
  >([]);

  const [quickBooksSettings, setQuickBooksSettings] =
    useState<IQuickBooksInvoiceSettings | null>(null);

  const { loading: loadingCustomer, errorLoading: errorLoadingCustomer } =
    useLoadCustomers(isStringSet(customerId) ? [customerId] : []);

  const [loadingInvoiceData, setLoadingInvoiceData] = useState(true);
  const [errorLoadingInvoiceData, setErrorLoadingInvoiceData] = useState(false);

  const [promptDismissed, setPromptDismissed] = useState(false);

  const [defaultPurchaseOrderNumber, setDefaultPurchaseOrderNumber] =
    useState("");

  const [quickBooksRefreshRequired, setQuickBooksRefreshRequired] =
    useState(false);

  const [defaultLineItems, setDefaultLineItems] =
    useState<null | Array<IInvoiceDefaultLineItem>>(null);

  const [defaultAmountAdjustments, setDefaultAmountAdjustments] =
    useState<IInvoiceAmountAdjustments>({
      taxRate: null,
      discount: { type: DiscountType.percent, amount: null, percent: null },
      depositCreditAmount: null,
    });

  const [defaultDepositCredits, setDefaultDepositCredits] = useState<
    IInvoiceDefaultDepositCredit[]
  >([]);

  const [proposalJobSummaries, setProposalJobSummaries] = useState<
    IInvoiceDefaultProposalJobSummary[]
  >([]);

  const [captureDepositItemOverride, setCaptureDepositItemOverride] =
    useState(false);

  const dispatch = useDispatch();

  const showTaxAlert =
    !promptDismissed &&
    quickBooksSettings?.automaticTaxesEnabled === false &&
    isQuickBooksEnabled &&
    !getUserSettings(UserSettingsType.quickBooksOnlineASTPromptDismissed) ===
      true;

  const hasDataLoaded = useRef(false);

  useEffect(() => {
    let subscription: Subscription | null = null;
    if (showForm && !hasDataLoaded.current) {
      let jobInstanceIds: Array<string> = [];
      let projectIds: Array<string> = [];

      if (jobInstances) {
        jobInstanceIds = jobInstances.map((ji) => ji.id);
      }

      if (projects) {
        projectIds = projects.map((p) => p.id);
      }

      subscription = forkJoin({
        updatedCustomers: isQuickBooksEnabled
          ? dataProvider.getQuickBooksCustomers()
          : of([]),
        updatedItems: getInvoiceItems(isQuickBooksEnabled),
        retrievedBillingNotes: dataProvider.getBillingReportJobInstanceDetails(
          jobInstanceIds,
          projectIds
        ),
        quickBooksInvoiceSettings: isQuickBooksEnabled
          ? dataProvider.getQuickBooksInvoiceSettings()
          : of(getEmptyQuickBooksInvoiceSettings()),
        invoiceDefaults:
          jobInstanceIds.length > 0 ||
          projectIds.length > 0 ||
          isStringSet(invoiceId)
            ? invoiceDataProvider.getInvoiceDefaults({
                invoiceId,
                jobInstanceIds,
                projectIds,
              })
            : of(null),
      })
        .pipe(
          timeout(30000),
          map(
            ({
              updatedCustomers,
              updatedItems,
              retrievedBillingNotes,
              quickBooksInvoiceSettings,
              invoiceDefaults,
            }) => {
              return {
                success: true,
                updatedCustomers,
                updatedItems,
                quickBooksInvoiceSettings,
                billingDetails: retrievedBillingNotes,
                errorMessage: "",
                quickBooksRefreshRequired: false,
                invoiceDefaults,
              };
            }
          ),
          catchError((err) => {
            let quickBooksRefreshRequired = false;
            if (
              err?.response?.errorCode === constants.quickBooksReconnectRequired
            ) {
              quickBooksRefreshRequired = true;
            }

            const errorMessage = getErrorMessageFromError(
              err,
              "Data for invoicing was unable to be loaded. Please check your Internet connection and try again."
            );

            return of({
              success: false,
              quickBooksInvoiceSettings: getEmptyQuickBooksInvoiceSettings(),
              updatedCustomers: [],
              updatedItems: [],
              billingDetails: [],
              errorMessage,
              quickBooksRefreshRequired,
              invoiceDefaults: null,
            });
          })
        )
        .subscribe((result) => {
          if (result.success) {
            setQuickBooksCustomers(result.updatedCustomers);
            setInvoiceItems(result.updatedItems);
            setBillingDetails(result.billingDetails);
            setQuickBooksSettings(result.quickBooksInvoiceSettings);
            setErrorLoadingInvoiceData(false);

            populateInvoiceDefaults({
              invoiceDefaults: result.invoiceDefaults,
              setPaymentMethodPreviouslyAuthorized,
              setShowLineItemPrices,
              setDefaultDepositCredits,
              setDefaultLineItems,
              setDefaultAmountAdjustments,
              setDefaultPurchaseOrderNumber,
              setCaptureDepositItemOverride,
              setProposalJobSummaries,
            });

            if (typeof setAllowAchPayment === "function") {
              const cachedAllowAchPaymentUnparsed = getUserSettings(
                UserSettingsType.allowAchPayment
              );
              if (typeof cachedAllowAchPaymentUnparsed === "boolean") {
                setAllowAchPayment(cachedAllowAchPaymentUnparsed);
              }
            }

            if (typeof setAllowCreditCardPayment === "function") {
              const cachedAllowCreditCardPaymentUnparsed = getUserSettings(
                UserSettingsType.allowCreditCardPayment
              );
              if (typeof cachedAllowCreditCardPaymentUnparsed === "boolean") {
                setAllowCreditCardPayment(cachedAllowCreditCardPaymentUnparsed);
              }
            }
          } else if (result.quickBooksRefreshRequired) {
            setQuickBooksRefreshRequired(true);
          } else {
            console.error("Unable to load QuickBooks form");
            setErrorLoadingInvoiceData(true);
          }

          setLoadingInvoiceData(false);
          hasDataLoaded.current = true;
        });
    }

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [
    jobInstances,
    projects,
    showForm,
    customerId,
    isQuickBooksEnabled,
    invoiceId,
    setAllowAchPayment,
    setAllowCreditCardPayment,
    setDefaultLineItems,
    setDefaultAmountAdjustments,
    setPaymentMethodPreviouslyAuthorized,
    setShowLineItemPrices,
    getUserSettings,
  ]);

  useEffect(() => {
    if (quickBooksRefreshRequired) {
      args.closeForm();
      dispatch(
        commonUiActionCreators.showQuickBooksModal({
          quickBooksModalMode: QuickBooksModalMode.reconnect,
        })
      );
    }
  }, [args, dispatch, quickBooksRefreshRequired]);

  return {
    isQuickBooksEnabled,
    quickBooksCustomers,
    invoiceItems,
    setInvoiceItems,
    setQuickBooksCustomers,
    billingDetails,
    quickBooksSettings,
    loaded: !loadingCustomer && !loadingInvoiceData,
    errorLoading: errorLoadingCustomer || errorLoadingInvoiceData,
    showTaxAlert,
    defaultLineItems,
    defaultAmountAdjustments,
    defaultDepositCredits,
    proposalJobSummaries,
    taxAlertDismiss: () => {
      setUserSettings(
        UserSettingsType.quickBooksOnlineASTPromptDismissed,
        true
      );
      setPromptDismissed(true);
    },
    captureDepositItemOverride,
    defaultPurchaseOrderNumber,
    setDefaultLineItems,
    setDefaultAmountAdjustments,
    setDefaultDepositCredits,
    setDefaultPurchaseOrderNumber,
    setCaptureDepositItemOverride,
    setProposalJobSummaries,
  };
}

export function populateInvoiceDefaults({
  setPaymentMethodPreviouslyAuthorized,
  invoiceDefaults,
  setShowLineItemPrices,
  setDefaultDepositCredits,
  setDefaultLineItems,
  setDefaultAmountAdjustments,
  setDefaultPurchaseOrderNumber,
  setCaptureDepositItemOverride,
  setProposalJobSummaries,
}: {
  setPaymentMethodPreviouslyAuthorized: ((hide: boolean) => void) | undefined;
  invoiceDefaults: IInvoiceDefaultsResponse | null;
  setShowLineItemPrices: ((hide: boolean) => void) | undefined;
  setDefaultDepositCredits: React.Dispatch<
    React.SetStateAction<IInvoiceDefaultDepositCredit[]>
  >;
  setDefaultAmountAdjustments: React.Dispatch<
    React.SetStateAction<IInvoiceAmountAdjustments>
  >;
  setDefaultLineItems: React.Dispatch<
    React.SetStateAction<Array<IInvoiceDefaultLineItem> | null>
  >;
  setProposalJobSummaries: React.Dispatch<
    React.SetStateAction<Array<IInvoiceDefaultProposalJobSummary>>
  >;
  setDefaultPurchaseOrderNumber: React.Dispatch<React.SetStateAction<string>>;
  setCaptureDepositItemOverride: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const defaultLineItems = invoiceDefaults?.lineItems ?? null;
  const defaultAmountAdjustments = {
    taxRate: invoiceDefaults?.taxRate ?? null,
    discount: invoiceDefaults?.discount ?? {
      type: DiscountType.percent,
      amount: null,
      percent: null,
    },
    depositCreditAmount: null,
  };
  const defaultDepositCredits = invoiceDefaults?.depositCredits ?? [];
  const defaultPurchaseOrderNumber = invoiceDefaults?.purchaseOrderNumber ?? "";

  if (typeof setPaymentMethodPreviouslyAuthorized === "function") {
    setPaymentMethodPreviouslyAuthorized(
      invoiceDefaults?.paymentMethodOnFileAuthorized ?? false
    );
  }

  if (typeof setShowLineItemPrices === "function") {
    setShowLineItemPrices(!invoiceDefaults?.hideLineItemPrices ?? true);
  }

  setDefaultDepositCredits(defaultDepositCredits);
  setDefaultLineItems(defaultLineItems);
  setDefaultAmountAdjustments(defaultAmountAdjustments);
  setDefaultPurchaseOrderNumber(defaultPurchaseOrderNumber);
  setProposalJobSummaries(invoiceDefaults?.proposalSummaries ?? []);

  setCaptureDepositItemOverride(
    invoiceDefaults?.captureDepositItemOverride ?? false
  );

  return {
    defaultLineItems,
    defaultAmountAdjustments,
    defaultDepositCredits,
    defaultPurchaseOrderNumber,
  };
}

function getEmptyQuickBooksInvoiceSettings(): {
  paymentsEnabled: boolean;
  automaticTaxesEnabled: boolean;
} {
  return {
    paymentsEnabled: false,
    automaticTaxesEnabled: false,
  };
}
