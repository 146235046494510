import React, { Fragment } from "react";
import {
  MapWithDirections,
  IJobMarker,
  MapJobStatus,
} from "./MapWithDirections";
import { connect } from "react-redux";
import jobFinder from "../../../services/jobFinder";
import { IMaintenanceJob } from "../../../models/IMaintenanceJob";
import { ICrew } from "../../../models/ICrew";
import { IDaySchedule } from "../../../models/IDaySchedule";
import { IRootState } from "../../../store";
import { IJob } from "../../../models/IJob";
import { IOneTimeJob } from "../../../models/IOneTimeJob";
import { ICustomer } from "../../../models/ICustomer";
import { ICustomerAdditionalLocation } from "../../../models/ICustomerAdditionalLocation";
import addressFormatter from "../../../services/addressFormatter";
import timeService from "../../../services/timeService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

interface IProps {
  jobs: Array<IMaintenanceJob>;
  oneTimeJobs: Array<IOneTimeJob>;
  crews: Array<ICrew>;
  daySchedules: Array<IDaySchedule>;
  dayScheduleId: string;
  customers: Array<ICustomer>;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
  closeMapUrl: string;
}

interface IJobSetWithSkipped extends IJobMarker {
  skipped: boolean;
}

const JobsMap: React.FunctionComponent<IProps> = ({
  jobs,
  oneTimeJobs,
  crews,
  customers,
  customerAdditionalLocations,
  daySchedules,
  dayScheduleId,
  closeMapUrl,
}) => {
  let jobSets: Array<Array<IJobMarker>> = [];

  const daySchedule = daySchedules.find((ds) => ds.id === dayScheduleId);
  if (!daySchedule) {
    throw new Error(`day schedule not found ${dayScheduleId}`);
  }

  jobSets[0] = daySchedule.jobInstances
    .filter((jobInstance) => {
      return (
        jobFinder.getJobForDayScheduleV2(jobs, oneTimeJobs, jobInstance) !==
        null
      );
    })
    .map((jobInstance, jobInstanceIndex) => {
      const job = jobFinder.getJobForDayScheduleV2(
        jobs,
        oneTimeJobs,
        jobInstance
      ) as IJob;

      const location = addressFormatter.getAddressForJob(
        job,
        customers,
        customerAdditionalLocations
      );

      let status: MapJobStatus;
      if (jobInstance.complete) {
        status = MapJobStatus.Completed;
      } else if (timeService.hasStartTimeSet(jobInstance)) {
        status = MapJobStatus.Started;
      } else {
        status = MapJobStatus.NotStarted;
      }

      return {
        index: jobInstanceIndex,
        id: jobInstance.id,
        status,
        locationCoordinates: {
          latitude: location.latitude,
          longitude: location.longitude,
        },
        skipped: jobInstance.skipped,
      } as IJobSetWithSkipped;
    })
    .filter(
      (j) =>
        j.locationCoordinates.latitude &&
        j.locationCoordinates.longitude &&
        !j.skipped
    );

  const crew = crews.find((c) => c.id === daySchedule.crewId);
  if (!crew) {
    throw new Error(`crew not found ${daySchedule.crewId}`);
  }

  return (
    <Fragment>
      <div className="text-right mb-2">
        <Link to={closeMapUrl}>
          <FontAwesomeIcon icon={faX} className="mr-2" />
          Close map
        </Link>
      </div>
      <MapWithDirections
        rendezvousPoint={crew}
        jobSets={jobSets}
        dayScheduleId={dayScheduleId}
      />
    </Fragment>
  );
};

const mapStateToProps = (state: IRootState) => ({
  jobs: state.job.jobs,
  oneTimeJobs: state.job.oneTimeJobs,
  crews: state.crew.crews,
  daySchedules: state.schedule.daySchedules,
  customers: state.customer.customers,
  customerAdditionalLocations: state.customer.customerAdditionalLocations,
});

export default connect(mapStateToProps)(JobsMap);
