import React from "react";
import { MaintenanceJobFrequency } from "../../../models/IMaintenanceJob";
import JobHelepr from "../../../services/jobHelper";
import { getDaysOfWeekForDisplay } from "../../../services/jobService";

interface IProps {
  id: string;
  label: string;
  value: IFrequency;
  onChange(value: IFrequency): void;
  hideWeekly?: boolean;
  hideEvery2Weeks?: boolean;
  hideEvery4Weeks?: boolean;
  hideEvery6Weeks?: boolean;
  hideMonthly?: boolean;
  hideCustom?: boolean;
  hideInactive?: boolean;
  hideDaily?: boolean;
  hideCustomDay?: boolean;
  hideCustomWeek?: boolean;
  daysOfWeek?: Array<string>;
}

export interface IFrequency {
  frequencyType: MaintenanceJobFrequency;
  customFrequencyPeriod: string;
  customFrequencyValue: string;
  monthlyWeek: string | null;
}

const createOption = (
  hide: boolean | undefined,
  value: MaintenanceJobFrequency
) => {
  return !hide ? (
    <option value={value.toString()}>
      {JobHelepr.getFrequencyForDisplay(value)}
    </option>
  ) : null;
};
export const convertToMaintenanceJobFrequency = (
  input: string
): MaintenanceJobFrequency => parseInt(input, 10) as MaintenanceJobFrequency;

const FrequencySelection: React.SFC<IProps> = ({
  id,
  label,
  value,
  onChange,
  hideWeekly,
  hideEvery2Weeks,
  hideEvery4Weeks,
  hideEvery6Weeks,
  hideCustom,
  hideInactive,
  hideMonthly,
  hideCustomDay,
  hideCustomWeek,
  daysOfWeek,
}) => {
  return (
    <React.Fragment>
      <div className="form-group">
        <label htmlFor={id} className="required">
          {label}
        </label>
        <select
          className="form-control"
          id={id}
          required={true}
          value={value.frequencyType.toString()}
          onChange={(e) =>
            onChange({
              ...value,
              frequencyType: convertToMaintenanceJobFrequency(e.target.value),
            })
          }
        >
          {/* {createOption(hideDaily, MaintenanceJobFrequency.Daily)} */}
          {createOption(hideWeekly, MaintenanceJobFrequency.Weekly)}
          {createOption(hideEvery2Weeks, MaintenanceJobFrequency.EveryTwoWeeks)}
          {createOption(
            hideEvery4Weeks,
            MaintenanceJobFrequency.EveryFourWeeks
          )}
          {createOption(hideEvery6Weeks, MaintenanceJobFrequency.EverySixWeeks)}
          {createOption(hideMonthly, MaintenanceJobFrequency.Monthly)}
          {createOption(hideCustom, MaintenanceJobFrequency.Custom)}
          {createOption(hideInactive, MaintenanceJobFrequency.None)}
        </select>
      </div>
      {value.frequencyType === MaintenanceJobFrequency.Custom ? (
        <React.Fragment>
          <div className="form-group">
            <div className="form-row align-items-end">
              <div className="col">
                <label htmlFor={id + "_customvalue"} className="required">
                  Custom frequency
                </label>
                <input
                  type="number"
                  className="form-control"
                  id={id + "_customvalue"}
                  required={true}
                  value={value.customFrequencyValue}
                  onChange={(e) =>
                    onChange({ ...value, customFrequencyValue: e.target.value })
                  }
                />
              </div>
              <div className="col">
                <select
                  aria-label="Custom frequency period"
                  className="form-control"
                  required={true}
                  value={value.customFrequencyPeriod}
                  onChange={(e) =>
                    onChange({
                      ...value,
                      customFrequencyPeriod: e.target.value,
                    })
                  }
                >
                  {!hideCustomDay ? <option value="0">Days</option> : null}
                  {!hideCustomWeek ? <option value="1">Weeks</option> : null}
                </select>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : null}
      {value.frequencyType === MaintenanceJobFrequency.Monthly ? (
        <React.Fragment>
          <div className="form-group">
            <label htmlFor={id + "_monthlyweek"} className="required">
              Week
            </label>
            <select
              className="form-control"
              required={true}
              id={id + "_monthlyweek"}
              value={value.monthlyWeek || ""}
              onChange={(e) =>
                onChange({
                  ...value,
                  monthlyWeek: e.target.value,
                })
              }
            >
              {value.monthlyWeek === "" ? <option value=""></option> : null}
              {getWeekOption("1", "1st", daysOfWeek)}
              {getWeekOption("2", "2nd", daysOfWeek)}
              {getWeekOption("3", "3rd", daysOfWeek)}
              {getWeekOption("4", "4th", daysOfWeek)}
              {value.monthlyWeek === "5"
                ? getWeekOption("5", "5th", daysOfWeek)
                : null}
            </select>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default FrequencySelection;

function getWeekOption(
  value: string,
  prefix: string,
  daysOfWeek?: Array<string>
) {
  const optionText =
    daysOfWeek && daysOfWeek.length > 0
      ? `${prefix} ${getDaysOfWeekForDisplay(daysOfWeek)}`
      : prefix;
  return <option value={value}>{optionText}</option>;
}
