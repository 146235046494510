import React, { useState, useRef, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { IRootState } from "../../../../store";
import { actionCreators } from "../../../../modules/actionCreators";
import { Subject } from "rxjs/internal/Subject";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { ICustomer } from "../../../../models/ICustomer";
import { Path } from "history";
import { RouterAction, push } from "connected-react-router";
import { builders as routerBuilders } from "../../../../services/routing";
import { ICustomerAdditionalLocation } from "../../../../models/ICustomerAdditionalLocation";
import Spinner from "../../components/Spinner";
import PageWithNavBar2 from "../../PageWithNavBar2";
import {
  Address,
  Buttons,
  ContactInformation,
  CustomerName,
  LastVisitDate,
  NextVisitDate,
  Tags,
} from "./CustomersV2Row";
import { RouteComponentProps } from "react-router";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import useIsAdmin from "../../../../hooks/useIsAdmin";
import CustomersImport from "../../components/CustomersImport";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { isMobileOnly } from "react-device-detect";
import CustomersV2Export from "./CustomersV2Export";
import ResponsiveTable from "../../../../libraries/tableLayout/ResponsiveTable";
import { TableColumns } from "../../../../libraries/tableLayout/TableColumn";
import { IManageCustomerSearchResult } from "../../../../models/IManageCustomersSearch";
import { ResponsiveTableMobileCard } from "../../../../libraries/tableLayout/ResponsiveTableMobileCard";

const pageSizeSearching = 10;
const pageSizeAllCustomers = 50;

interface IRouteParams {
  customerId: string;
  search?: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {
  customers: Array<ICustomer>;
  errors: {
    [key: string]: string;
  };
  topLevelMessage: string | undefined;
  showMaintenanceJobForm(parameters: any): void;
  showOneTimeJobForm(parameters: any): void;
  showCustomerForm(parameters: any): void;
  showCustomerAdditionalLocationForm(parameters: any): void;
  hideTopLevelMessage: () => any;
  addedCustomerId: string | undefined;
  isQuickBooksEnabled: boolean;
  redirect(path: Path): RouterAction;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
}

const minimumSearchLength = 1;
const CustomersV2: React.FunctionComponent<IProps> = ({
  showCustomerForm,
  addedCustomerId,
  isQuickBooksEnabled,
  redirect,
  match,
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchingAllCustomers, setSearchingAllCustomers] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(pageSizeSearching);
  const [showInactive, setShowInactive] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showImportDropDown, setShowImportDropDown] = useState(false);

  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const searchError = useApplicationStateSelector(
    (c) => c.customer.manageCustomersSearch.error
  );
  const searchLoading = useApplicationStateSelector(
    (c) => c.customer.manageCustomersSearch.loading
  );
  const textForSearchAlreadyPerformed = useApplicationStateSelector(
    (c) => c.customer.manageCustomersSearch.searchText
  );
  const matchingCustomers = useApplicationStateSelector(
    (c) => c.customer.manageCustomersSearch.matchingCustomers
  );
  const additionalCustomers = useApplicationStateSelector(
    (c) => c.customer.manageCustomersSearch.additionalCustomers
  );
  const isAdmin = useIsAdmin();
  const [exportErrorMessage, setExportErrorMessage] = useState("");

  const dispatch = useDispatch();

  const searchRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, []);

  const onSearch$ = useRef(new Subject<string>());
  const subscription = useRef<Subscription | null>(null);
  useEffect(() => {
    subscription.current = onSearch$.current
      .pipe(debounceTime(300))
      .subscribe((value) => {
        const normalizedValue = normalizeSearchText(value);

        if (normalizedValue.length >= minimumSearchLength) {
          dispatch(
            actionCreators.startManageCustomersSearch(
              normalizedValue,
              false,
              false,
              page,
              pageSize,
              showInactive
            )
          );
        }
      });

    return function cleanup() {
      if (subscription.current) {
        subscription.current.unsubscribe();
      }
    };
  }, [dispatch, page, pageSize, searchingAllCustomers, showInactive]);

  const defaultSearchText = match.params.search || "";
  useEffect(() => {
    if (defaultSearchText) {
      setSearchText(defaultSearchText);
      const normalizedValue = normalizeSearchText(defaultSearchText);

      if (normalizedValue.length >= minimumSearchLength) {
        dispatch(
          actionCreators.startManageCustomersSearch(
            normalizedValue,
            false,
            false,
            0,
            pageSizeSearching,
            false
          )
        );
      }
    }
  }, [defaultSearchText, setSearchText, dispatch]);

  const previousAddedCustomerId = useRef("");
  useEffect(() => {
    if (
      addedCustomerId &&
      previousAddedCustomerId.current !== addedCustomerId
    ) {
      const customer = customers.find((c) => c.id === addedCustomerId);
      if (customer) {
        setSearchText(customer.name);
        onSearch$.current.next(customer.name);
      }
    }

    previousAddedCustomerId.current = addedCustomerId || "";
  }, [addedCustomerId, customers]);

  useResetInputFieldsOnStoreSet({
    setPage,
    setPageSize,
    setSearchText,
    setSearchingAllCustomers,
  });

  const addButtons = (
    <div className="mb-4">
      <div className="d-flex" style={{ columnGap: "10px" }}>
        <button
          className="btn btn-primary"
          onClick={() => showCustomerForm({})}
        >
          Add Customer
        </button>

        {isAdmin ? (
          <>
            <ButtonDropdown
              direction="down"
              isOpen={showImportDropDown}
              toggle={() => setShowImportDropDown(!showImportDropDown)}
            >
              <button
                className="btn btn-secondary"
                onClick={() => setShowImportDropDown(!showImportDropDown)}
              >
                Import
              </button>
              <DropdownToggle split color="secondary" />
              <DropdownMenu positionFixed={true}>
                <DropdownItem
                  onClick={() => {
                    setShowImportModal(true);
                  }}
                >
                  From CSV File
                </DropdownItem>

                {isQuickBooksEnabled ? (
                  <DropdownItem
                    onClick={() =>
                      redirect(
                        routerBuilders.setup.buildQuickBooksCustomerImport()
                      )
                    }
                  >
                    From QuickBooks
                  </DropdownItem>
                ) : null}
              </DropdownMenu>
            </ButtonDropdown>

            {!isMobileOnly ? (
              <CustomersV2Export
                setExportErrorMessage={setExportErrorMessage}
                searchText={searchText}
                includeInactive={showInactive}
              />
            ) : null}
          </>
        ) : null}

        {showImportModal ? (
          <CustomersImport onClose={() => setShowImportModal(false)} />
        ) : null}
      </div>
      {exportErrorMessage ? (
        <div className="text-danger" data-testid="exportErrorMessage">
          {exportErrorMessage}
        </div>
      ) : null}
    </div>
  );

  let messageToShow: JSX.Element | null = null;
  let showSearchResults = true;
  const hasSearchNotStarted =
    normalizeSearchText(searchText).length < minimumSearchLength &&
    !searchingAllCustomers;
  if (searchLoading && pageSize === 0) {
    showSearchResults = false;
    messageToShow = <span>Loading results...</span>;
  } else if (hasSearchNotStarted) {
    showSearchResults = false;
    messageToShow = (
      <div>
        Start searching to find customers
        <br />
        <br />
        OR
        <br />
        <br />
        <button
          type="button"
          className="btn btn-primary btn-lg"
          onClick={() => {
            setSearchingAllCustomers(true);
            setPageSize(pageSizeAllCustomers);
            setPage(0);
            dispatch(
              actionCreators.startManageCustomersSearch(
                "",
                false,
                true,
                0,
                pageSizeAllCustomers,
                showInactive
              )
            );
          }}
        >
          Show all customers
        </button>
      </div>
    );
  } else if (
    normalizeSearchText(searchText).length >= minimumSearchLength &&
    searchText === textForSearchAlreadyPerformed &&
    matchingCustomers.length === 0
  ) {
    showSearchResults = false;
    messageToShow = <span>No customers found</span>;
  } else if (searchText.trim() !== textForSearchAlreadyPerformed.trim()) {
    showSearchResults = false;
  }

  const endOfPageMessageToShow =
    additionalCustomers > 0 && !searchLoading && showSearchResults ? (
      <button
        type="button"
        className="btn btn-primary btn-lg"
        onClick={() => {
          setPage(page + 1);
          dispatch(
            actionCreators.startManageCustomersSearch(
              textForSearchAlreadyPerformed,
              true,
              searchingAllCustomers,
              page + 1,
              pageSize,
              showInactive
            )
          );
        }}
      >
        Load additional customers
      </button>
    ) : null;

  const columns: TableColumns<IManageCustomerSearchResult> = [
    {
      header: "Customer",
      cell: ({ row: searchResult }) => (
        <CustomerName searchResult={searchResult} searchText={searchText} />
      ),
      key: "customerName",
      testId: "customerName",
    },
    {
      header: "Contact Information",
      cell: ({ row: searchResult }) => (
        <ContactInformation searchResult={searchResult} />
      ),
      key: "customerContactInformation",
      testId: "customerContactInformation",
    },
    {
      header: "Address",
      cell: ({ row: searchResult }) => <Address searchResult={searchResult} />,
      key: "customerPrimaryAddress",
      testId: "customerPrimaryAddress",
    },
    {
      header: "Tags",
      key: "customerTags",
      testId: "customerTags",
      cell: ({ row: searchResult }) => <Tags searchResult={searchResult} />,
    },
    {
      header: "Last visit date",
      testId: "customerLastVisitDate",
      key: "customerLastVisitDate",
      cell: ({ row: searchResult }) => (
        <LastVisitDate searchResult={searchResult} />
      ),
    },
    {
      header: "Next visit date",
      testId: "customerNextVisitDate",
      key: "customerNextVisitDate",
      cell: ({ row: searchResult }) => (
        <NextVisitDate searchResult={searchResult} />
      ),
    },
    {
      header: "",
      testId: "buttons",
      key: "buttons",
      cell: ({ row: searchResult }) => <Buttons searchResult={searchResult} />,
      isButtonCell: true,
    },
  ];

  return (
    <React.Fragment>
      {searchLoading && pageSize > 0 ? <Spinner /> : null}
      <PageWithNavBar2 billingContext={true} notFluid={false}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div>
            <h1>
              Customers
              {matchingCustomers.length > 0 ? (
                <span> ({matchingCustomers.length + additionalCustomers})</span>
              ) : null}
            </h1>
          </div>
          <div>{addButtons}</div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            marginTop: "10px",
            flexWrap: "wrap",
          }}
          className="form-group"
        >
          <input
            type="text"
            className="form-control"
            aria-label="Search"
            placeholder="Search"
            value={searchText}
            ref={searchRef}
            onChange={(e) => {
              const value = e.currentTarget.value;

              setSearchText(value);
              setPage(0);
              setPageSize(pageSizeSearching);
              setSearchingAllCustomers(false);

              onSearch$.current.next(value);
            }}
            style={{ maxWidth: "400px" }}
          />
          <div className="custom-control custom-checkbox ml-sm-4 mt-2">
            <input
              type="checkbox"
              className="custom-control-input"
              id="include-inactive"
              checked={showInactive}
              onChange={(e) => {
                const currentInactive = e.currentTarget.checked;
                setShowInactive(currentInactive);

                if (
                  normalizeSearchText(textForSearchAlreadyPerformed).length >=
                    minimumSearchLength ||
                  searchingAllCustomers
                ) {
                  setPage(0);
                  dispatch(
                    actionCreators.startManageCustomersSearch(
                      textForSearchAlreadyPerformed,
                      false,
                      searchingAllCustomers,
                      0,
                      pageSize,
                      currentInactive
                    )
                  );
                }
              }}
            />
            <label className="custom-control-label" htmlFor="include-inactive">
              Show inactive
            </label>
          </div>
        </div>
        {showSearchResults ? (
          <>
            <div style={{ minHeight: "350px" }}>
              <ResponsiveTable
                rows={matchingCustomers}
                columns={columns}
                renderMobile={({ row, index }) => {
                  return (
                    <ResponsiveTableMobileCard
                      columns={columns}
                      row={row}
                      rowIndex={index}
                    />
                  );
                }}
              />
            </div>
          </>
        ) : null}

        {searchError ? (
          <div className="text-danger" style={{ marginTop: "10px" }}>
            An error occurred retrieving search results. Please try again.
          </div>
        ) : null}

        {messageToShow ? (
          <div className="jumbotron" style={{ textAlign: "center" }}>
            <h5>{messageToShow}</h5>
          </div>
        ) : null}

        {endOfPageMessageToShow && !messageToShow ? (
          <div className="jumbotron" style={{ textAlign: "center" }}>
            <h5>{endOfPageMessageToShow}</h5>
          </div>
        ) : null}
      </PageWithNavBar2>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IRootState) => ({
  errors: state.manageUi.errors,
  topLevelMessage: state.manageUi.topLevelMessage,
  customers: state.customer.customers,
  addedCustomerId: state.manageUi.addedCustomerId,
  isQuickBooksEnabled: state.common.isQuickBooksEnabled,
  customerAdditionalLocations: state.customer.customerAdditionalLocations,
});

const mapDispatchToProps = {
  showMaintenanceJobForm: actionCreators.forms.maintenanceJob.showForm,
  showOneTimeJobForm: actionCreators.forms.oneTimeJob.showForm,
  showCustomerForm: actionCreators.forms.customer.showForm,
  showCustomerAdditionalLocationForm:
    actionCreators.forms.customerAdditionalLocation.showForm,
  hideTopLevelMessage: actionCreators.hideManageUiMessage,
  redirect: push,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersV2);

function normalizeSearchText(searchText: string) {
  return searchText.trim();
}

function useResetInputFieldsOnStoreSet({
  setPage,
  setPageSize,
  setSearchText,
  setSearchingAllCustomers,
}: {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setSearchingAllCustomers: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const resetCount = useApplicationStateSelector(
    (s) => s.customer.manageCustomersSearch.resetCount
  );
  const previousResetCount = useRef(0);
  if (resetCount !== previousResetCount.current) {
    setPage(0);
    setPageSize(pageSizeSearching);
    setSearchText("");
    setSearchingAllCustomers(false);

    previousResetCount.current = resetCount;
  }
}
