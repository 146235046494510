import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../../modules/actionCreators";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { getSortedItemsV2 } from "../../../../services/sortingService";
import { ICrewCategory } from "../../../../models/ICrewCategory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTableList,
  faTimes,
  faTableCellsLarge,
} from "@fortawesome/free-solid-svg-icons";
import { UnscheduledJobsDisplayMode } from "../../../../enums/unscheduledJobsDisplayMode";

interface IProps {
  loadingFlexibleJobs: boolean;
  flexibleJobsExist: boolean;
  expanded: boolean;
  categories: Array<ICrewCategory>;
  centerText: JSX.Element;
  toggleButton: JSX.Element | null;
  displayMode: UnscheduledJobsDisplayMode;
  setDisplayMode(newMode: UnscheduledJobsDisplayMode): void;
}

const responsiveBreakpoint = "md";

const UnscheduledJobsFilters: React.FunctionComponent<IProps> = ({
  loadingFlexibleJobs,
  flexibleJobsExist,
  expanded,
  categories,
  centerText: countText,
  toggleButton,
  displayMode,
  setDisplayMode,
}) => {
  const [searchText, setSearchText] = useState("");
  const categoryFilter = useApplicationStateSelector(
    (s) => s.scheduleUi.unscheduledJobCategoryFilter
  );
  const dispatch = useDispatch();
  const reduxSearchText = useApplicationStateSelector(
    (s) => s.scheduleUi.unscheduledJobSearchTextFilter
  );

  const previousLocalSearchText = useRef(searchText);
  useEffect(() => {
    if (
      previousLocalSearchText.current === searchText &&
      reduxSearchText !== searchText
    ) {
      setSearchText(reduxSearchText);
    }
    previousLocalSearchText.current = searchText;
  }, [searchText, setSearchText, reduxSearchText]);

  function onSearchTextChange(input: string) {
    setSearchText(input);

    // Call setTimeout so the filter is applied asynchronously.  Otherwise, the text
    // input will lag until the list of jobs are re-rendered.  If there are a large number
    // of jobs, this could be slow.
    setTimeout(() =>
      dispatch(actionCreators.unscheduledJobsSearchTextFilterSet(input))
    );
  }

  // If the category no longer exists because the job was moved out, add the option back so it
  // isn't cleared
  const updatedCategories = getSortedItemsV2(
    categoryFilter && !categories.some((c) => c.id === categoryFilter?.id)
      ? [...categories, categoryFilter]
      : categories,
    ["name"]
  );

  return !loadingFlexibleJobs ? (
    <div>
      <div className="row align-items-center">
        {flexibleJobsExist && expanded ? (
          <div className={`col-12 col-${responsiveBreakpoint}-4 mb-1`}>
            <div className="input-group">
              <input
                aria-label="Search jobs"
                data-testid="searchJobs"
                type="text"
                placeholder="Search"
                className="form-control"
                value={searchText}
                onChange={(e) => onSearchTextChange(e.currentTarget.value)}
              ></input>
              <button
                className="btn bg-transparent"
                style={{ marginLeft: "-40px", zIndex: 3 }}
                onClick={() => onSearchTextChange("")}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
        ) : null}
        {flexibleJobsExist && expanded ? (
          <div
            className={`col-12 col-${responsiveBreakpoint}-4 order-${responsiveBreakpoint}-12 mb-1`}
          >
            <div className="d-flex justify-content-end align-items-center">
              <button
                className={`d-none d-${responsiveBreakpoint}-block btn btn-secondary text-nowrap`}
                type="button"
                data-testid="displayMode"
                onClick={() =>
                  setDisplayMode(
                    displayMode === UnscheduledJobsDisplayMode.Card
                      ? UnscheduledJobsDisplayMode.Grid
                      : UnscheduledJobsDisplayMode.Card
                  )
                }
              >
                <FontAwesomeIcon
                  icon={
                    displayMode === UnscheduledJobsDisplayMode.Card
                      ? faTableList
                      : faTableCellsLarge
                  }
                />
                <span className="ml-1 d-none d-lg-inline">
                  {displayMode === UnscheduledJobsDisplayMode.Card
                    ? "Table view"
                    : "Card view"}
                </span>
              </button>
              {updatedCategories.length > 0 ? (
                <>
                  <select
                    aria-label="Category filter"
                    data-testid="categoryFilter"
                    className={`form-control ml-0 ml-${responsiveBreakpoint}-3`}
                    onChange={(e) =>
                      dispatch(
                        actionCreators.unscheduledJobsCategoryFilterSet(
                          e.currentTarget.value === ""
                            ? null
                            : (updatedCategories.find(
                                (c) => c.id === e.currentTarget.value
                              ) as ICrewCategory)
                        )
                      )
                    }
                    value={categoryFilter?.id}
                  >
                    <option value="">All services</option>
                    {updatedCategories.map((c) => (
                      <option value={c.id} key={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                </>
              ) : null}
            </div>
          </div>
        ) : null}
        <div
          className={`col-12 text-center order-${responsiveBreakpoint}-1 mb-1 ${
            flexibleJobsExist && expanded ? `col-${responsiveBreakpoint}-4` : ""
          }`}
        >
          {countText}
        </div>
      </div>
      {toggleButton ? (
        <div className="row">
          <div className="col text-center">{toggleButton}</div>
        </div>
      ) : null}
    </div>
  ) : null;
};

export default UnscheduledJobsFilters;
