import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import clientSupportDataProvider from "../services/clientSupportDataProvider";

interface IFormData {
  transactionId: string;
}

const PaymentDocumentationSearch: React.FunctionComponent<{}> = () => {
  const { register, getValues } = useForm<IFormData>({
    defaultValues: {},
  });
  const [documentationUrl, setDocumentationUrl] = useState<string>("");

  return (
    <Fragment>
      <div className="d-flex justifyContent align-items-start"></div>
      <div
        className="d-flex flex-wrap align-items-end mb-4"
        style={{ columnGap: "10px", rowGap: "10px" }}
      >
        <div>
          <label style={{ fontSize: "large" }} htmlFor="transactionId">
            Payrix Transaction ID
          </label>
          <input
            id="transactionId"
            type="text"
            className="form-control"
            width="300px"
            {...register("transactionId")}
          ></input>
        </div>
        <button
          className="btn btn-primary"
          onClick={() => {
            <Fragment>
              {clientSupportDataProvider
                .getTransactionDocumentation(getValues("transactionId"))
                .subscribe(
                  (url) => {
                    setDocumentationUrl(url);
                  },
                  (er) => setDocumentationUrl("")
                )}
            </Fragment>;
          }}
          style={{ whiteSpace: "nowrap" }}
        >
          Search
        </button>
        {documentationUrl.length > 0 ? (
          <a
            href={documentationUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="ml-3"
          >
            {documentationUrl}
          </a>
        ) : null}
      </div>
    </Fragment>
  );
};

export default PaymentDocumentationSearch;
