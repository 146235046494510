export function ResponsiveTableMobileCardValue({
  children,
  testId,
  isButtonCell,
}: {
  children: React.ReactNode;
  testId?: string;
  isButtonCell?: boolean;
}) {
  return (
    <div
      data-testid={testId}
      className={isButtonCell ? "mt-3" : undefined}
      style={{
        textOverflow: "ellipsis",
        overflow: "hidden",
        overflowWrap: "normal",
        gridColumnStart: isButtonCell ? 1 : undefined,
        gridColumnEnd: isButtonCell ? -1 : undefined,
      }}
    >
      {children}
    </div>
  );
}
