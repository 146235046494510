import React from "react";

interface IProps {
  columnHeader: React.ReactNode;
  columnHeaderAddition?: React.ReactNode;
  highlightHeader?: boolean;
  isCalendarHeader: boolean;
  isLastColumn: boolean;
}

const HeaderContainer: React.FunctionComponent<IProps> = ({
  columnHeader,
  columnHeaderAddition,
  children,
  highlightHeader,
  isCalendarHeader,
  isLastColumn,
}) => {
  return (
    <div
      style={{
        position: "sticky",
        top: "0px",
        backgroundColor: "white",
        zIndex: 2,
        height: isCalendarHeader ? "100%" : undefined,
      }}
    >
      <div
        className={`border text-center board-column-header ${
          isCalendarHeader && !isLastColumn ? "border-right-0" : ""
        }`}
        style={{ height: isCalendarHeader ? "100%" : undefined }}
      >
        <div
          className={
            "py-1 " +
            (highlightHeader ? `bg-primary border border-dark rounded` : "")
          }
          data-testid="headerContainer"
        >
          <h5 style={{ marginBottom: "0px" }}>
            {columnHeader}
            {columnHeaderAddition}
          </h5>
        </div>

        {children}
      </div>
    </div>
  );
};

export default HeaderContainer;
