import * as React from "react";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import { isImageByType } from "../../../../services/fileService";
import billingReportService from "../../../../services/billingReportService";
import { ReadOnlyFile } from "../../components/files/ReadOnlyFile";
import { useState, useEffect } from "react";
import Spinner from "../../components/Spinner";
import {
  IBillingReportJobInstanceDetails,
  ITodoItem,
} from "../../../../models/IBillingReport";
import remoteDataProvider from "../../../../services/remoteDataProvider";
import { Subscription, of } from "rxjs";
import { map, timeout, catchError } from "rxjs/operators";
import Lightbox from "../../components/Lightbox";
import { ResponsiveTableMobileCardLabel } from "../../../../libraries/tableLayout/ResponsiveTableMobileCardLabel";
import { ResponsiveTableMobileCardValue } from "../../../../libraries/tableLayout/ResponsiveTableMobileCardValue";

export interface INotesModalJobs {
  name: string;
  jobInstances: Array<INotesModalJobInstance>;
}

interface INotesModalJobInstance {
  id: string;
  date: string;
  showNotesLink: boolean;
}

export interface IProps {
  imagePrefix: string;
  customerForNotes: INotesModalJobs | null;
  closeNotes(): void;
  formatDateForReportDisplay(date: Date | string): string;
  hideDatesOnFieldHeaders?: boolean;
}

const NotesModal: React.FunctionComponent<IProps> = ({
  customerForNotes,
  closeNotes,
  formatDateForReportDisplay,
  imagePrefix,
  hideDatesOnFieldHeaders,
}) => {
  const [lightBoxJobInstanceId, setLightBoxJobInstanceId] = useState<
    string | null
  >(null);
  const [photoIndex, setPhotoIndex] = useState<number>(0);
  const [jobInstanceDetails, setJobInstanceDetails] =
    useState<Array<IBillingReportJobInstanceDetails> | null>(null);
  const [errorLoadingJobDetails, setErrorLoadingJobDetails] =
    useState<boolean>(false);
  useEffect(() => {
    let subscription: Subscription | null = null;
    setJobInstanceDetails(null);
    setErrorLoadingJobDetails(false);

    if (customerForNotes) {
      subscription = remoteDataProvider
        .getBillingReportJobInstanceDetails(
          customerForNotes.jobInstances.map((ji) => ji.id),
          []
        )
        .pipe(
          map((result) => ({
            success: true,
            result,
          })),
          timeout(10000),
          catchError(() => {
            return of({
              success: false,
              result: [],
            });
          })
        )
        .subscribe((r) => {
          if (r.success) {
            setJobInstanceDetails(r.result);
          } else {
            setErrorLoadingJobDetails(true);
          }
        });
    }

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [customerForNotes, setJobInstanceDetails, setErrorLoadingJobDetails]);

  return !customerForNotes ? null : jobInstanceDetails ||
    errorLoadingJobDetails ? (
    <Modal
      isOpen={!!customerForNotes}
      toggle={closeNotes}
      keyboard={false}
      fade={false}
      scrollable
    >
      <ModalHeader toggle={closeNotes}>
        Notes for {!!customerForNotes ? customerForNotes.name : ""}
      </ModalHeader>
      <ModalBody>
        {errorLoadingJobDetails || !jobInstanceDetails ? (
          <div>Unable to load notes at this time. Please try again later.</div>
        ) : (
          <React.Fragment>
            {customerForNotes.jobInstances
              .sort(billingReportService.sortCustomerJobInstances)
              .filter((ji) => {
                return billingReportService.doesJobInstanceHaveNotesOrTodos(ji);
              })
              .map((ji) => {
                let todoItems: Array<ITodoItem> = [];

                const jobDetails = jobInstanceDetails.find(
                  (details) => details.jobInstanceId === ji.id
                );
                if (jobDetails) {
                  todoItems = jobDetails.todoItems;
                } else {
                  return null;
                }

                return (
                  <React.Fragment key={ji.id}>
                    <h5>Job on {formatDateForReportDisplay(ji.date)}</h5>
                    {!!jobDetails.notesFromCrew ? (
                      <React.Fragment>
                        <ResponsiveTableMobileCardLabel>
                          Crew Notes
                        </ResponsiveTableMobileCardLabel>
                        <div style={{ marginBottom: "15px" }}>
                          <ResponsiveTableMobileCardValue>
                            <span style={{ whiteSpace: "pre-wrap" }}>
                              {jobDetails.notesFromCrew}
                            </span>
                          </ResponsiveTableMobileCardValue>
                        </div>
                      </React.Fragment>
                    ) : null}

                    {!!jobDetails.administratorOnlyNotes ? (
                      <React.Fragment>
                        <ResponsiveTableMobileCardLabel>
                          Administrator Only Notes
                        </ResponsiveTableMobileCardLabel>
                        <div style={{ marginBottom: "15px" }}>
                          <ResponsiveTableMobileCardValue>
                            <span style={{ whiteSpace: "pre-wrap" }}>
                              {jobDetails.administratorOnlyNotes}
                            </span>
                          </ResponsiveTableMobileCardValue>
                        </div>
                      </React.Fragment>
                    ) : null}

                    {!!jobDetails.signatureImagePath ? (
                      <React.Fragment>
                        <ResponsiveTableMobileCardLabel>
                          Customer Signature
                        </ResponsiveTableMobileCardLabel>
                        <img
                          src={`${imagePrefix}/${jobDetails.signatureImagePath}`}
                          alt="Customer signature"
                        />
                      </React.Fragment>
                    ) : null}

                    {jobDetails.customCrewQuestionResponses.length > 0 ? (
                      <div className="form-group">
                        {jobDetails.customCrewQuestionResponses.map((q) => {
                          return (
                            <div
                              key={q.questionText}
                              style={{ marginBottom: "10px" }}
                            >
                              <ResponsiveTableMobileCardLabel>
                                {q.questionText}
                              </ResponsiveTableMobileCardLabel>
                              <div style={{ marginBottom: "15px" }}>
                                <ResponsiveTableMobileCardValue>
                                  <span style={{ whiteSpace: "pre-wrap" }}>
                                    {q.answer}
                                  </span>
                                </ResponsiveTableMobileCardValue>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}

                    {todoItems.length > 0 ? (
                      <div className="form-group">
                        <ResponsiveTableMobileCardLabel>
                          Checklist
                        </ResponsiveTableMobileCardLabel>
                        {todoItems.map((todoItem, index) => (
                          <div
                            className="custom-control custom-checkbox"
                            key={index}
                          >
                            <input
                              className="custom-control-input"
                              type="checkbox"
                              id={index + "_cb"}
                              defaultChecked={todoItem.completed}
                              disabled
                            />
                            <label
                              className="custom-control-label text-dark"
                              htmlFor={index + "_cb"}
                            >
                              {todoItem.text}
                            </label>
                          </div>
                        ))}
                      </div>
                    ) : null}

                    {jobDetails.photos.length > 0 ? (
                      <div className="form-group">
                        <ResponsiveTableMobileCardLabel>
                          Files
                        </ResponsiveTableMobileCardLabel>
                        {jobDetails.photos.map((file) => (
                          <ReadOnlyFile
                            onOpenLightbox={() => {
                              setLightBoxJobInstanceId(ji.id);
                              setPhotoIndex(
                                jobDetails.photos
                                  .filter((p) => isImageByType(p.contentType))
                                  .indexOf(file)
                              );
                            }}
                            file={file}
                            imagePrefix={imagePrefix}
                            key={file.id}
                          />
                        ))}
                      </div>
                    ) : null}
                    {lightBoxJobInstanceId === ji.id && (
                      <Lightbox
                        itemIndex={photoIndex}
                        setItemIndex={(newIndex) => setPhotoIndex(newIndex)}
                        onClose={() => setLightBoxJobInstanceId(null)}
                        items={jobDetails.photos.map((p) => ({
                          ...p,
                          caption: p.caption,
                          src: `${imagePrefix}/${p.imagePath}`,
                        }))}
                      />
                    )}
                  </React.Fragment>
                );
              })}
          </React.Fragment>
        )}
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-primary" type="button" onClick={closeNotes}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  ) : (
    <Spinner />
  );
};

export default NotesModal;
