import { useState } from "react";
import LinkButton2 from "../../../containers/app/components/LinkButton2";
import Spinner from "../../../containers/app/components/Spinner";
import auth from "../../../services/Auth";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import accountManagementDataProvider from "../services/accountManagementDataProvider";
import { UserAccountForm } from "./UserAccountForm";

export function Login({
  onSuccess,
  defaultEmailAddress,
  invitationId,
}: {
  onSuccess?: () => void;
  defaultEmailAddress?: string;
  invitationId?: string | null;
}) {
  const [mode, setMode] = useState<"login" | "forgotPassword">("login");
  const [showEmailSentConfirmation, setShowEmailSentConfirmation] =
    useState(false);
  const [isGoogleAuth, setIsGoogleAuth] = useState(false);

  return (
    <>
      {mode === "login" ? (
        <LoginForm
          showEmailSentConfirmation={showEmailSentConfirmation}
          onSuccess={onSuccess}
          onForgotPasswordClick={() => setMode("forgotPassword")}
          defaultEmailAddress={defaultEmailAddress}
          invitationId={invitationId}
          isGoogleAuth={isGoogleAuth}
          setIsGoogleAuth={setIsGoogleAuth}
        />
      ) : (
        <ForgotPassword
          onSuccess={() => {
            setShowEmailSentConfirmation(true);
            setMode("login");
          }}
          onCancel={() => {
            setShowEmailSentConfirmation(false);
            setMode("login");
          }}
        />
      )}
    </>
  );
}

function LoginForm({
  showEmailSentConfirmation,
  onForgotPasswordClick,
  onSuccess,
  defaultEmailAddress,
  invitationId,
  isGoogleAuth,
  setIsGoogleAuth,
}: {
  showEmailSentConfirmation: boolean;
  onForgotPasswordClick(): void;
  onSuccess?: () => void;
  defaultEmailAddress?: string;
  invitationId?: string | null;
  isGoogleAuth: boolean;
  setIsGoogleAuth: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <>
      {showEmailSentConfirmation ? (
        <div className="alert alert-success" data-testid="resetEmailSent">
          The email to reset your password has been sent!
        </div>
      ) : null}

      <UserAccountForm
        buttonText="Log in"
        defaultEmailAddress={defaultEmailAddress}
        accountCreation={false}
        onSubmit={({ email, password, onError }) =>
          auth.loginEmbedded({
            email,
            password,
            onError: (message) => {
              onError(message);
            },
            onSuccess,
            invitationId,
          })
        }
        checkLoginType={true}
        isGoogleAuth={isGoogleAuth}
        setIsGoogleAuth={setIsGoogleAuth}
        invitationId={invitationId}
      />

      {!isGoogleAuth ? (
        <div className="mt-1 text-center">
          <LinkButton2
            buttonContents={"Forgot your password?"}
            className="branded-public-link"
            testId="forgotPasswordLink"
            onClick={() => onForgotPasswordClick()}
          />
        </div>
      ) : null}
    </>
  );
}

function ForgotPassword({
  onSuccess,
  onCancel,
}: {
  onSuccess: () => void;
  onCancel: () => void;
}) {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [saving, setSaving] = useState(false);

  return (
    <>
      {saving ? <Spinner /> : null}
      <div
        className="my-3 font-weight-light"
        data-testid="emailResetInstructions"
      >
        Enter your email address and we'll send you a link to reset your
        password.
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setErrorMessage("");
          setSaving(true);

          accountManagementDataProvider
            .resetPassword({
              email,
            })
            .subscribe({
              next: () => {
                setSaving(false);

                onSuccess();
              },

              error: (err) => {
                setSaving(false);
                setErrorMessage(getErrorMessageFromError(err));
              },
            });
        }}
      >
        <div className="form-group">
          <label htmlFor="email" className="required">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            required={true}
            data-testid="email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </div>

        {errorMessage ? (
          <div className="text-danger" data-testid="passwordResetEmail">
            {errorMessage}
          </div>
        ) : null}

        <button
          type="submit"
          data-testid="forgotPasswordButton"
          className="btn btn-block btn-primary mb-4 branded-public-buttons"
          disabled={false}
        >
          Send Email
        </button>
      </form>

      <div className="mt-2 text-center">
        <small>
          Not what you wanted?
          <LinkButton2
            buttonContents={<small>Go back</small>}
            onClick={() => onCancel()}
            className="ml-1 branded-public-link"
            style={{ verticalAlign: "baseline" }}
            testId="goBackLink"
          />
        </small>
      </div>
    </>
  );
}
