import { ICustomer } from "../models/ICustomer";
import { ICustomerPaymentMethod } from "../models/ICustomerPaymentMethod";

export function isPaymentMethodOnFileAuthorizedVisible(
  areCreditCardsEnabled: boolean,
  customer: Partial<ICustomer> | null
): customer is { paymentMethod: ICustomerPaymentMethod } {
  const paymentAuthorizationFieldsVisible =
    areCreditCardsEnabled && (customer?.paymentMethod?.isTokenSet ?? false);

  return paymentAuthorizationFieldsVisible;
}
