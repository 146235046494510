import * as React from "react";
import { IPendingFile } from "./Index";
import { isImageByType } from "../../../../services/fileService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  file: IPendingFile;
  onRetryFileUpload: () => any;
  onCloseError: () => any;
}

const PendingPhoto: React.FunctionComponent<IProps> = ({
  file,
  onRetryFileUpload: onRetryPhotoUpload,
  onCloseError,
}) => {
  return (
    <div className="form-group" style={{ textAlign: "center" }}>
      <div style={{ position: "relative", display: "inline-block" }}>
        {/* TODO: Don't set width if less than 280 and be careful with spinner for small images */}
        {isImageByType(file.file.type) ? (
          <img src={window.URL.createObjectURL(file.file)} width={280} alt="" />
        ) : null}
        <span
          style={{
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "10px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="3x"
            fixedWidth={true}
          />
          <br />
          Uploading...
        </span>
      </div>
      {file.errorUploading ? (
        <div className="alert alert-danger" style={{ marginTop: "10px" }}>
          <button type="button" className="close" onClick={onCloseError}>
            &times;
          </button>
          <p>Upload failed!</p>
          <p>Please ensure you still have an internet connection.</p>
          <p>
            <a
              className="alert-link"
              href="/retry"
              onClick={(e) => {
                e.preventDefault();
                onRetryPhotoUpload();
              }}
            >
              Retry
            </a>
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default PendingPhoto;
