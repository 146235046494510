import React, { useState } from "react";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";
import { Link } from "react-router-dom";
import { builders } from "../../../services/routing";
import LinkButton from "./LinkButton";

interface IProps {
  customerName: string;
  entityId: string;
  customerId: string | null;
  useWhiteText?: boolean;
}

const Name: React.FunctionComponent<IProps> = ({
  customerName,
  entityId,
  customerId,
  useWhiteText,
}) => {
  const [showLink, setShowLink] = useState(false);
  const spanId = `name_${entityId}`;

  return (
    <>
      <span id={spanId}>
        {customerId ? (
          <LinkButton
            hrefForDisplay="customer"
            linkText={customerName}
            onClick={() => setShowLink(!showLink)}
            className="schedule-link"
            style={{
              color: useWhiteText ? "#fff" : "#495057",
            }}
            testId="scheduleCustomerName"
          />
        ) : (
          customerName
        )}
      </span>
      <Popover
        delay={0}
        trigger="legacy"
        placement="bottom"
        isOpen={showLink}
        target={spanId}
        toggle={() => {
          setShowLink(!showLink);
        }}
      >
        <PopoverBody>
          <Link
            to={builders.manage.buildCustomerDetailsRoute({
              customerId: customerId as string,
            })}
            onClick={(e) => {
              e.stopPropagation();
              setShowLink(false);
            }}
          >
            Open Customer Record
          </Link>
        </PopoverBody>
      </Popover>
    </>
  );
};

export default Name;
