import React from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import conditionalRenderer from "../components/conditionalRenderer";
import FormContainer2 from "../components/FormContainer2";
import { useLoadCustomers } from "../../../hooks/useLoadCustomers";
import Spinner from "../components/Spinner";
import Alert from "../components/Alert";
import { actionCreators } from "../../../modules/actionCreators";
import { useDispatch } from "react-redux";
import { IInvoiceRetryChargeParameters } from "../../../formGenerator/formParameters/IInvoiceRetryChargeParameters";
import { IFormsState } from "../../../formGenerator/formReducers";
import CustomerPaymentMethodForForms from "../components/CustomerPaymentMethodForForms";
import { IInvoiceRetryChargeRequest } from "../../../slices/billing/services/invoiceDataProvider";

const formKey: keyof IFormsState = "invoiceRetryCharge";
const InvoiceRetryChargeForm: React.FunctionComponent<{}> = () => {
  const dispatch = useDispatch();

  const { customerId, invoiceId } = useApplicationStateSelector(
    (s) => s.forms[formKey].parameters
  ) as IInvoiceRetryChargeParameters;

  const { loading, errorLoading } = useLoadCustomers([customerId]);

  if (errorLoading) {
    return (
      <Alert
        message="Unable to load customer data."
        closeForm={() => dispatch(actionCreators.forms[formKey].cancelForm())}
      />
    );
  } else if (loading) {
    return <Spinner />;
  }

  return (
    <FormContainer2
      formHeader="Retry charge"
      formType={formKey}
      saveButtonText="Charge customer"
      getFormData={() => {
        const result: IInvoiceRetryChargeRequest = {
          invoiceId,
        };

        return result;
      }}
    >
      <CustomerPaymentMethodForForms customerId={customerId} />
    </FormContainer2>
  );
};

export default conditionalRenderer(
  InvoiceRetryChargeForm,
  (s) => s.forms[formKey].showForm
);
