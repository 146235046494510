import { useApplicationStateSelector } from "./useApplicationStateSelector";

export function useGetCustomerFromStore(customerId: string | null) {
  const customers = useApplicationStateSelector((s) => s.customer.customers);

  if (!customerId) {
    return null;
  }

  const customer = customers.find((c) => c.id === customerId) ?? null;

  return customer;
}
