import differenceInMinutes from "date-fns/difference_in_minutes";
import { blockSizeInMinutes } from "./Calendar";
import dateService from "../../../services/dateService";
import { JobInstanceWithTimeSlot } from "./ScheduleTimeCalendar.types";
import { formatTimeForCalendaryDisplay } from "../services/formatTimeForCalendaryDisplay";
import { IJobInstance } from "../../../models/IJobInstance";
import { getOffsetFromStart } from "../services/timeBlockCalculator";

export function getJobCardHeight({
  jobCardTimeBlocks,
  calendarBlockHeightInPxs,
  resizingDelta,
  resizing,
  pixelOffsetFromTimeBlockStart,
  blocksFromCalendarEnd,
}: {
  jobCardTimeBlocks: number;
  calendarBlockHeightInPxs: number;
  resizingDelta: number | null;
  resizing: boolean;
  pixelOffsetFromTimeBlockStart: number | null;
  blocksFromCalendarEnd: number | null;
}) {
  const calculatedHeight = jobCardTimeBlocks * calendarBlockHeightInPxs;
  if (!resizing || typeof resizingDelta !== "number") {
    return calculatedHeight;
  }

  let adjustedHeight = calculatedHeight + resizingDelta;

  const gridAdjustmentFactor = adjustedHeight % calendarBlockHeightInPxs;
  if (gridAdjustmentFactor < calendarBlockHeightInPxs / 2) {
    adjustedHeight = adjustedHeight - gridAdjustmentFactor;
  } else {
    adjustedHeight =
      adjustedHeight + (calendarBlockHeightInPxs - gridAdjustmentFactor);
  }

  adjustedHeight -= pixelOffsetFromTimeBlockStart ?? 0;

  if (adjustedHeight < calendarBlockHeightInPxs) {
    adjustedHeight = calendarBlockHeightInPxs;
  }

  let maxNumberOfPxs =
    typeof blocksFromCalendarEnd === "number"
      ? blocksFromCalendarEnd * calendarBlockHeightInPxs
      : null;
  if (typeof maxNumberOfPxs === "number" && adjustedHeight > maxNumberOfPxs) {
    adjustedHeight = maxNumberOfPxs;
  }

  return adjustedHeight;
}

export function getCalendarBlockDifference(
  startTime: Date | null,
  endTime: Date | null
) {
  const minuteDifference =
    startTime && endTime ? differenceInMinutes(endTime, startTime) : null;
  const calendarBlockDifference =
    minuteDifference && minuteDifference >= blockSizeInMinutes
      ? minuteDifference / blockSizeInMinutes
      : 1;
  return calendarBlockDifference;
}

export function getJobInstanceOffsetFromStartingBlock(
  timeSlotStartTime: Date | null,
  jobInstanceWithTimeSlot: JobInstanceWithTimeSlot,
  calendarBlockHeightInPxs: number
) {
  return getOffsetFromStart({
    timeSlotStartTime,
    referenceTime: jobInstanceWithTimeSlot.startTime,
    calendarBlockHeightInPxs,
  });
}

export function getJobCardPercentWidth(
  jobInstanceWithTimeSlot: JobInstanceWithTimeSlot
) {
  return jobInstanceWithTimeSlot.columns === null
    ? 100
    : 100 / jobInstanceWithTimeSlot.columns;
}

export function getTimeRangeForDisplay(jobInstance: IJobInstance) {
  let timeRangeForDisplay = "";
  if (jobInstance.startTime && jobInstance.endTime) {
    timeRangeForDisplay = `${formatTimeForCalendaryDisplay(
      jobInstance.startTime
    )} - ${formatTimeForCalendaryDisplay(jobInstance.endTime)}`;
  }
  return timeRangeForDisplay;
}

export function getSerializedTimes(parsedStartTime: Date, newEndTime: Date) {
  const startTimeSerialized =
    dateService.formatTimeForSerialization(parsedStartTime);
  const endTimeSerialized = dateService.formatTimeForSerialization(newEndTime);
  return { startTimeSerialized, endTimeSerialized };
}

export function calculateJobCardHeight({
  jobInstanceWithTimeSlot,
  calendarBlockHeightInPxs,
  resizingDelta,
  resizing,
  pixelOffsetFromTimeBlockStart,
  blocksFromCalendarEnd,
}: {
  jobInstanceWithTimeSlot: JobInstanceWithTimeSlot;
  calendarBlockHeightInPxs: number;
  resizingDelta: number | null;
  resizing: boolean;
  pixelOffsetFromTimeBlockStart: number | null;
  blocksFromCalendarEnd: number | null;
}) {
  const jobCardTimeBlocks = getCalendarBlockDifference(
    jobInstanceWithTimeSlot.startTime,
    jobInstanceWithTimeSlot.endTime
  );

  const adjustedHeight = getJobCardHeight({
    jobCardTimeBlocks,
    calendarBlockHeightInPxs,
    resizingDelta,
    resizing,
    pixelOffsetFromTimeBlockStart,
    blocksFromCalendarEnd,
  });
  return { jobCardTimeBlocks, adjustedHeight };
}
