import { useState } from "react";
import { useDispatch } from "react-redux";
import constants from "../../../../constants";
import { IPromptMessageSection } from "../../../../modules/prompt";
import { getErrorMessageFromError } from "../../../../services/httpErrorHandler";
import { projectActionCreators } from "../../../../slices/schedule/modules/project";
import projectDataProvider from "../../../../slices/schedule/services/projectDataProvider";
import Prompt from "../../components/Prompt";
import { timeout } from "rxjs/operators";

export function CustomerDetailsJobsProjectDelete({
  projectId,
  onClose,
}: {
  projectId: string;
  onClose(): void;
}) {
  const [deleting, setDeleting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const promptSubMessages: Array<IPromptMessageSection> = [
    {
      text: "Deleting this project will remove ",
    },
    {
      text: "all ",
      bold: true,
    },
    {
      text: "visits and historical information for this project.",
    },
  ];

  if (errorMessage) {
    promptSubMessages.push({
      text: errorMessage,
      dangerColor: true,
      newBlock: true,
    });
  }

  return (
    <>
      <Prompt
        promptMessage="Are you sure you want to delete this project?"
        promptSaveText="Delete"
        promptSubMessage={promptSubMessages}
        onCancel={onClose}
        onConfirm={() => {
          setDeleting(true);
          projectDataProvider
            .deleteProject(projectId)
            .pipe(timeout(10000))
            .subscribe({
              next: () => {
                setDeleting(false);
                onClose();

                dispatch(
                  projectActionCreators.removeProjects({
                    projectIds: [projectId],
                  })
                );
              },

              error: (err) => {
                setDeleting(false);
                setErrorMessage(
                  getErrorMessageFromError(err, constants.unknownErrorMessage)
                );
              },
            });
        }}
        showPrompt={true}
        showSpinner={deleting}
      />
    </>
  );
}
