import React from "react";
import { useRef, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { ModalFooter } from "reactstrap/lib";
import { getErrorMessagesFromError } from "../../../services/httpErrorHandler";
import uuidv4 from "uuid/v4";
import Spinner from "./Spinner";
import { timeout } from "rxjs/operators";
import { Observable } from "rxjs";
import { AjaxResponse } from "rxjs/ajax";
import { useIsResolution } from "../../../hooks/useIsResolution";

type Column = { name: string; required?: boolean };

export default function Import<TPreviewResponse extends { id: string }>({
  onClose,
  uploadFile,
  importSave,
  headerRecordType,
  successRecordType,
  exampleFile,
  headerRow,
  renderRow,
  columns,
  size,
}: {
  onClose: (dataSaved: boolean) => void;
  uploadFile(file: File): Observable<Array<TPreviewResponse>>;
  importSave(
    recordsToImport: Array<TPreviewResponse>
  ): Observable<AjaxResponse>;
  exampleFile: string;
  headerRecordType: string;
  successRecordType: string;
  headerRow: JSX.Element;
  renderRow(preview: TPreviewResponse): JSX.Element;
  columns: Array<Column>;
  size?: "xl" | "lg" | "md";
}) {
  const formId = "importCsvForm";

  const [currentStep, setCurrentStep] = useState<
    "fileUpload" | "preview" | "importComplete"
  >("fileUpload");
  const [recordsToPreview, setRecordsToPreview] = useState<
    Array<TPreviewResponse>
  >([]);
  const [saving, setSaving] = useState(false);

  let modalBody: React.ReactNode;
  if (currentStep === "preview") {
    modalBody = (
      <Preview<TPreviewResponse>
        recordsToPreview={recordsToPreview}
        importSave={importSave}
        headerRecordType={headerRecordType}
        formId={formId}
        onImportComplete={() => setCurrentStep("importComplete")}
        onSaveChange={setSaving}
        headerRow={headerRow}
        renderRow={renderRow}
      />
    );
  } else if (currentStep === "importComplete") {
    modalBody = (
      <ImportComplete<TPreviewResponse>
        recordsImported={recordsToPreview}
        successRecordType={successRecordType}
      />
    );
  } else {
    modalBody = (
      <FileSelection<TPreviewResponse>
        exampleFile={exampleFile}
        formId={formId}
        uploadFile={uploadFile}
        onSaveChange={setSaving}
        columns={columns}
        onUploadComplete={(c) => {
          setRecordsToPreview(c);
          setCurrentStep("preview");
        }}
      />
    );
  }

  const dataSaved = currentStep === "importComplete";
  return (
    <Modal
      isOpen
      toggle={() => onClose(dataSaved)}
      size={size ?? "xl"}
      scrollable
    >
      <ModalHeader toggle={() => onClose(dataSaved)} data-testid="importHeader">
        Import {headerRecordType}
      </ModalHeader>
      <ModalBody>
        {saving ? <Spinner /> : null}
        {modalBody}
      </ModalBody>
      <ModalFooter>
        {currentStep !== "importComplete" ? (
          <button type="submit" form={formId} className="btn btn-primary">
            {currentStep === "fileUpload" ? "Next" : "Import"}
          </button>
        ) : null}

        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => onClose(dataSaved)}
        >
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
}

function FileSelection<TPreviewResponse>({
  formId,
  uploadFile,
  onUploadComplete: onUpload,
  onSaveChange,
  exampleFile,
  columns,
}: {
  formId: string;
  uploadFile(file: File): Observable<Array<TPreviewResponse>>;
  onUploadComplete(recordsToImport: Array<TPreviewResponse>): void;
  onSaveChange(newValue: boolean): void;
  exampleFile: string;
  columns: Array<Column>;
}) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [errorMessages, setErrorMessages] = useState<Array<string>>([]);
  const formRef = useRef<HTMLFormElement>(null);
  const isExtraSmallResolution = useIsResolution("xs");

  let columnSections: Array<Array<Column>>;
  if (isExtraSmallResolution) {
    columnSections = [columns];
  } else {
    columnSections = breakColumnsIntoChunks(columns, 6);
  }

  return (
    <form
      ref={formRef}
      id={formId}
      onSubmit={(e) => {
        e.preventDefault();

        setErrorMessages([]);

        if (selectedFile) {
          onSaveChange(true);

          uploadFile(selectedFile)
            .pipe(timeout(60000))
            .subscribe({
              next: (result) => {
                onUpload(
                  result.map((c) => ({
                    ...c,
                    id: uuidv4(),
                  }))
                );
                onSaveChange(false);
              },
              error: (err) => {
                setErrorMessages(getErrorMessagesFromError(err));
                onSaveChange(false);

                if (
                  typeof err.status === "number" &&
                  err.status >= 400 &&
                  err.status < 500
                ) {
                  // Reset file in case user modified it.
                  // Chrome will give an error if file changed after
                  // it was selected.
                  if (formRef.current) {
                    formRef.current.reset();
                    setSelectedFile(null);
                  }
                }
              },
            });
        }
      }}
    >
      <div className="mb-3">
        Please choose a CSV file. You can download an example file{" "}
        <a href={exampleFile} target="_blank" rel="noopener noreferrer">
          here
        </a>
        .
      </div>
      <div className="form-group">
        <div className="custom-file">
          <input
            type="file"
            id="importCsvFile"
            className="custom-file-input"
            accept="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            required
            onChange={(e) => {
              if (e.target?.files) {
                setSelectedFile(e.target.files[0]);
                setErrorMessages([]);
              }
            }}
          />
          <label className="custom-file-label" htmlFor="importCsvFile">
            {selectedFile?.name ?? "CSV File"}
          </label>
        </div>
        <Errors errorMessages={errorMessages} />
      </div>
      <div>
        Available column headers (bold are required):
        <small>
          <div className="d-flex">
            {columnSections.map((s, index) => (
              <ul key={index}>
                {s.map((c) => (
                  <li
                    key={c.name}
                    className={c.required ? "font-weight-bold" : ""}
                  >
                    {c.name}
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </small>
      </div>
    </form>
  );
}

function Preview<TPreviewResponse extends { id: string }>({
  formId,
  recordsToPreview,
  onImportComplete,
  onSaveChange,
  headerRecordType,
  importSave,
  headerRow,
  renderRow,
}: {
  formId: string;
  recordsToPreview: Array<TPreviewResponse>;
  onImportComplete(): void;
  onSaveChange(newValue: boolean): void;
  headerRecordType: string;
  importSave(
    recordsToImport: Array<TPreviewResponse>
  ): Observable<AjaxResponse>;
  headerRow: JSX.Element;
  renderRow(preview: TPreviewResponse): JSX.Element;
}) {
  const [errorMessages, setErrorMessages] = useState<Array<string>>([]);
  const errorContainer = useRef<HTMLDivElement>(null);

  return (
    <>
      <form
        id={formId}
        onSubmit={(e) => {
          e.preventDefault();

          onSaveChange(true);
          setErrorMessages([]);

          importSave(recordsToPreview)
            .pipe(timeout(60000))
            .subscribe({
              next: () => {
                onSaveChange(false);
                onImportComplete();
              },
              error: (err) => {
                onSaveChange(false);
                setErrorMessages(getErrorMessagesFromError(err));

                if (errorContainer.current) {
                  errorContainer.current.scrollIntoView();
                }
              },
            });
        }}
      >
        <div className="mb-3">
          <h6>{headerRecordType} To Import</h6>
        </div>
        <div ref={errorContainer} data-testid="errorContainer" className="py-3">
          <Errors errorMessages={errorMessages} />
        </div>
        <small>
          <table className="table table-sm">
            <thead>{headerRow}</thead>
            <tbody>
              {recordsToPreview.map((c) => (
                <React.Fragment key={c.id}>{renderRow(c)}</React.Fragment>
              ))}
            </tbody>
          </table>
        </small>
      </form>
    </>
  );
}

function ImportComplete<TPreviewResponse>({
  recordsImported,
  successRecordType,
}: {
  recordsImported: Array<TPreviewResponse>;
  successRecordType: string;
}) {
  return (
    <div className="text-center text-success">
      {recordsImported.length} {successRecordType} imported!
    </div>
  );
}

function Errors({ errorMessages }: { errorMessages: Array<string> }) {
  if (errorMessages.length === 0) {
    return null;
  } else if (errorMessages.length === 1) {
    return <div className="text-danger">{errorMessages[0]}</div>;
  } else {
    return (
      <div className="text-danger">
        The following problems were found:{" "}
        <ul>
          {errorMessages.map((e, index) => (
            <li key={index}>{e}</li>
          ))}
        </ul>
      </div>
    );
  }
}

function breakColumnsIntoChunks(columns: Array<Column>, chunkSize: number) {
  if (chunkSize <= 0) {
    return [];
  }

  const result: Array<Array<Column>> = [];
  for (let i = 0; i < columns.length; i += chunkSize) {
    result.push(columns.slice(i, i + chunkSize));
  }

  return result;
}
