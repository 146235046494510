import * as React from "react";

interface IProps {
  hrefForDisplay: string;
  className?: string;
  style?: React.CSSProperties;
  id?: string;
  linkText: string | JSX.Element;
  onClick(event: React.MouseEvent): void;
  testId?: string;
}

const LinkButton: React.SFC<IProps> = ({
  linkText,
  onClick,
  hrefForDisplay,
  className,
  style,
  id,
  testId,
}) => {
  return (
    <a
      data-testid={testId}
      id={id}
      href={`/${hrefForDisplay}`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      className={className}
      style={style}
    >
      {linkText}
    </a>
  );
};

export default LinkButton;
