import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { builders as routerBuilders } from "../../../../../services/routing";
import LinkButton from "../../LinkButton";
import ExpansionChevron from "../../ExpansionChevron";
import { ICrew } from "../../../../../models/ICrew";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";
import { isMobileOnly } from "react-device-detect";
import { useDispatch, useStore } from "react-redux";
import { actionCreators } from "../../../../../modules/actionCreators";
import { useApplicationStateSelector } from "../../../../../hooks/useApplicationStateSelector";
import { preventClearingAllCards } from "../../../../../services/selectedCardService";
import Prompt from "../../Prompt";
import { getJobsForRouting } from "../../../../../modules/epicActionHandlers/autoRouteJobs";
import { CrewScheduleType } from "../../../../../slices/schedule/enums/crewScheduleType";
import { IMapLinkProps } from "../../../../../slices/schedule/components/types/IMapLinkProps";
import { getDaySchedulesWithJobInstancesInTimeSlots } from "../../../../../slices/schedule/components/ScheduleTimeCalendar.functions";
import { IJobInstance } from "../../../../../models/IJobInstance";

interface IProps {
  dayScheduleId: string;
  isHeaderVisible: boolean;
  isMapVisible: boolean;
  mode: string;
  hasJobsMissingLocations: boolean;
  hasCrewMissingLocation: boolean;
  additionalLink: any;
  date: string;
  crew: ICrew;
  mapLinkProps: IMapLinkProps;
  jobsOnDayCount: number;
  hideExpandCollapseChevron?: boolean;
  hideSelectClearAllButton: boolean;
}

const DayWithJobsHeaderLinks: React.FunctionComponent<IProps> = ({
  dayScheduleId,
  isHeaderVisible,
  mode,
  additionalLink,
  date,
  crew,
  hasJobsMissingLocations,
  mapLinkProps,
  hasCrewMissingLocation,
  jobsOnDayCount,
  hideExpandCollapseChevron,
  hideSelectClearAllButton,
}) => {
  const [
    showAutoRouteUnavilableDueToMaxJobsExceed,
    setShowAutoRouteUnavilableDueToMaxJobsExceed,
  ] = useState(false);

  const [showOptimizeRoutePrompt, setShowOptimizeRoutePrompt] = useState(false);

  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const selectedJobInstances = useApplicationStateSelector(
    (s) => s.scheduleUi.selectedJobInstanceIds
  );

  const daySchedule = daySchedules.find((ds) => ds.id === dayScheduleId);

  const dispatch = useDispatch();
  const store = useStore();

  let jobInstancesForSelectAll: Array<IJobInstance> = [];

  if (daySchedule) {
    jobInstancesForSelectAll =
      crew.scheduleType === CrewScheduleType.sequence
        ? daySchedule.jobInstances
        : getDaySchedulesWithJobInstancesInTimeSlots(
            daySchedules,
            [daySchedule.id],
            null
          )
            .flatMap((ji) => ji.jobInstancesInTimeSlots)
            .map((s) => s.jobInstance);
  }

  const areAllItemsSelected =
    jobInstancesForSelectAll.length > 0 &&
    jobInstancesForSelectAll.reduce((allItemsSelected, jobInstance) => {
      if (!allItemsSelected) {
        return false;
      }

      return selectedJobInstances.includes(jobInstance.id);
    }, true as boolean);

  const maxJobsForAutoRoute = 25;

  const detailsLink = (
    <div>
      <LinkButton
        linkText="Details"
        hrefForDisplay="dayschedulenotes"
        onClick={() =>
          dispatch(actionCreators.forms.daySchedule.showForm({ dayScheduleId }))
        }
      />
    </div>
  );

  const isOptimizeRouteAvailable =
    !hasJobsMissingLocations &&
    !hasCrewMissingLocation &&
    crew.scheduleType !== CrewScheduleType.time;
  return (
    <Fragment>
      {jobsOnDayCount > 0 && isHeaderVisible ? (
        <Fragment>
          {!!additionalLink ? additionalLink : null}
          {!isMobileOnly ? (
            <div>
              <Link to={routerBuilders.schedule.buildPrintRoute(date, crew.id)}>
                Print schedule
              </Link>
            </div>
          ) : null}
          {!isMobileOnly ? (
            <div>
              <Link to={mapLinkProps.url}>{mapLinkProps.text}</Link>
            </div>
          ) : null}
          {isOptimizeRouteAvailable ? (
            <React.Fragment>
              <div>
                <a
                  href="/autoroute"
                  id={`autoroute_${dayScheduleId}`}
                  onClick={(e) => {
                    e.preventDefault();

                    const { jobInstancesToRoute } = getJobsForRouting(
                      daySchedule?.jobInstances ?? [],
                      store.getState()
                    );

                    if (jobInstancesToRoute.length > maxJobsForAutoRoute) {
                      setShowAutoRouteUnavilableDueToMaxJobsExceed(true);
                    } else {
                      setShowOptimizeRoutePrompt(true);
                    }
                  }}
                >
                  Optimize route
                </a>
              </div>
              <Popover
                placement="bottom"
                isOpen={showAutoRouteUnavilableDueToMaxJobsExceed}
                target={`autoroute_${dayScheduleId}`}
                toggle={() => {
                  setShowAutoRouteUnavilableDueToMaxJobsExceed(false);
                }}
                delay={0}
                fade={false}
                trigger="legacy"
              >
                <PopoverBody>
                  <p>
                    Auto routing is not supported with more than{" "}
                    {maxJobsForAutoRoute} jobs on a day
                  </p>
                </PopoverBody>
              </Popover>
              <Prompt
                showPrompt={showOptimizeRoutePrompt}
                promptMessage={`Are you sure you want to optimize the route?`}
                promptSubMessage={[
                  {
                    text: "This will change the job order for the day to accommodate the fastest route.",
                  },
                ]}
                onConfirm={() => {
                  setShowOptimizeRoutePrompt(false);
                  dispatch(actionCreators.autoRouteJobsStarting(dayScheduleId));
                }}
                onCancel={() => setShowOptimizeRoutePrompt(false)}
              />
            </React.Fragment>
          ) : null}
          {detailsLink}

          {!hideSelectClearAllButton ? (
            <div>
              <LinkButton
                linkText={areAllItemsSelected ? "Clear all" : "Select all"}
                hrefForDisplay="selectall"
                onClick={(e) => {
                  if (daySchedule) {
                    preventClearingAllCards(e);
                    const jobInstanceSelected = !areAllItemsSelected;
                    dispatch(
                      actionCreators.jobInstanceToggleSelected(
                        jobInstancesForSelectAll.map((ji) => ji.id),
                        jobInstanceSelected
                      )
                    );
                  }
                }}
              />
            </div>
          ) : null}
        </Fragment>
      ) : null}
      {jobsOnDayCount > 0 && !hideExpandCollapseChevron ? (
        <div>
          <ExpansionChevron
            expanded={isHeaderVisible}
            onClick={(e) => preventClearingAllCards(e)}
            onExpandedChange={(value) =>
              dispatch(
                actionCreators.toggleHeaderVisibility(
                  dayScheduleId,
                  mode,
                  value
                )
              )
            }
            testId="toggleHeaderLinks"
          />
        </div>
      ) : (
        <div className="mt-1">{detailsLink}</div>
      )}
    </Fragment>
  );
};

export default DayWithJobsHeaderLinks;
