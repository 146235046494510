export class Profiler {
  private operations: Array<IOperation>;

  constructor(private readonly name: string) {
    this.operations = [];
  }

  logEntry(key: string) {
    this.operations.push({
      key,
      time: new Date()
    });
  }

  getOutput() {
    return {
      name: this.name,
      totalTimeTaken:
        this.operations.length >= 2
          ? this.operations[this.operations.length - 1].time.getTime() -
            this.operations[0].time.getTime()
          : null,
      operations: this.operations.map((o, index) => {
        return {
          key: o.key,
          timeTaken:
            index === 0
              ? null
              : o.time.getTime() - this.operations[index - 1].time.getTime()
        };
      })
    };
  }
}

interface IOperation {
  key: string;
  time: Date;
}
