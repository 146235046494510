// TODO:  Pull from current hostname instead
const hostnameLowercase = window.location.hostname.toLowerCase();
let urlBase;
if (hostnameLowercase === "localhost") {
  urlBase = "http://localhost:3000";
} else if (
  hostnameLowercase === "greenerpastures-local-frontend.azurewebsites.net"
) {
  urlBase = "http://greenerpastures-local-frontend.azurewebsites.net:3000";
} else if (
  hostnameLowercase === "greenerpastures-staging-frontend.azurewebsites.net"
) {
  urlBase = "https://greenerpastures-staging-frontend.azurewebsites.net";
} else if (
  hostnameLowercase === "greenerpastures-production-frontend.azurewebsites.net"
) {
  urlBase = "https://greenerpastures-production-frontend.azurewebsites.net";
} else if (
  hostnameLowercase === "greenerpastures-staging-frontend.azureedge.net"
) {
  urlBase = "https://greenerpastures-staging-frontend.azureedge.net";
} else if (
  hostnameLowercase === "greenerpastures-production-frontend.azureedge.net"
) {
  urlBase = "https://greenerpastures-production-frontend.azureedge.net";
} else {
  urlBase = "https://admin.crewcontrol.us";
}

export const AUTH_CONFIG = {
  domain: "login.crewcontrol.us",
  clientId: "87avYED9Vy9yG_YPkSBh8WoGMEWlgwC0",
  // TODO: Change to use current host
  callbackUrl: `${urlBase}/callback`,
  logoutRedirectUrl: urlBase,
  audience: "https://greenerpastures-api.azurewebsites.net",
};
