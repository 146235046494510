import IPhoto from "../../../models/IPhoto";
import dateService from "../../../services/dateService";
import { isImageByType } from "../../../services/fileService";
import { isStringSet } from "../../../services/stringService";

export function PrintImages({
  files,
  filePrefix,
}: {
  files: Array<IPhoto>;
  filePrefix: string;
}) {
  const images = files.filter((f) => isImageByType(f.contentType));

  return (
    <>
      {images.length > 0 ? (
        <div className="my-3">
          <div>
            <h5>Images</h5>
          </div>
          <div className="d-flex flex-wrap" style={{ gap: "20px" }}>
            {images.map((file) => (
              <ImageElement key={file.id} file={file} filePrefix={filePrefix} />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}

function ImageElement({
  file,
  filePrefix,
}: {
  file: IPhoto;
  filePrefix: string;
}) {
  const width = 280;
  const thumbnail = file.thumbnails.find((t) => t.thumbnailKey === width);

  if (!thumbnail) {
    return null;
  }

  return (
    <div style={{ width }}>
      <img
        src={`${filePrefix}/${thumbnail.imagePath}`}
        height={thumbnail.actualHeight}
        width={thumbnail.actualWidth}
        alt={file.caption}
        data-testid="printThumbnail"
      />
      {isStringSet(file.timestamp) ? (
        <div data-testid="timestamp">
          {dateService.formatDateTimeForDateTimeDisplay(file.timestamp)}
        </div>
      ) : null}
      {file.caption ? <div data-testid="caption">{file.caption}</div> : null}
    </div>
  );
}
