import { useEffect, useRef, useState } from "react";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import { Controller, useForm } from "react-hook-form";
import FormContainerWithoutRedux from "../../../containers/app/components/FormContainerWithoutRedux";
import PhoneNumberField from "../../../containers/app/components/PhoneNumberField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import AddressComponents2, {
  IAddressComponents,
} from "../../../containers/app/components/AddressComponents2";
import InfoToolTip from "../../../containers/app/components/InfoToolTip";
import onboardingDataProvider from "../services/onboardingDataProvider";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import { ModalDataLoaderStateless } from "../../../containers/app/components/ModalDataLoaderStateless";
import { isStringSet } from "../../../services/stringService";
import { ErrorMessageType } from "../../../containers/app/components/FormContainer";

interface IFormData {
  address: IAddressComponents;
  contactFirstName: string;
  contactLastName: string;
  contactEmailAddress: string;
  contactPhoneNumber: string;
  businessName: string;
  businessWebsite: string;
}

export function TollFreeVerificationForm({
  onSaveComplete,
  onCancel,
}: {
  onSaveComplete: () => void;
  onCancel: () => void;
}) {
  const streetAndNumberRef = useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");
  const { register, control, getValues, reset } = useForm<IFormData>({
    defaultValues: {
      address: {
        streetAndNumber: "",
        city: "",
        latitude: "",
        latitudeSignificantDigits: null,
        longitude: "",
        longitudeSignificantDigits: null,
        state: "",
        zip: "",
        country: "",
      },
      contactFirstName: "",
      contactLastName: "",
      contactEmailAddress: "",
      contactPhoneNumber: "",
      businessName: "",
      businessWebsite: "",
    },
  });
  const [loadingData, setLoadingData] = useState(true);
  const [loadingErrorMessage, setLoadingErrorMessage] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    const subscription = onboardingDataProvider
      .getTollFreeVerificationInformation()
      .subscribe({
        next: (result) => {
          setLoadingData(false);
          reset({
            address: {
              streetAndNumber: result.streetAddress,
              city: result.city,
              latitude: "",
              latitudeSignificantDigits: null,
              longitude: "",
              longitudeSignificantDigits: null,
              state: result.state,
              zip: result.postalCode,
              country: result.country,
            },
            contactFirstName: result.contactFirstName,
            contactLastName: result.contactLastName,
            contactEmailAddress: result.contactEmailAddress,
            contactPhoneNumber: result.contactPhoneNumber,
            businessName: result.businessName,
            businessWebsite: result.businessWebsite,
          });
        },

        error: () => {
          setLoadingData(false);
          setLoadingErrorMessage("Unable to load information for form.");
        },
      });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [reset]);

  return (
    <ModalDataLoaderStateless
      loadingData={loadingData}
      errorLoadingData={isStringSet(loadingErrorMessage)}
      errorMessage={loadingErrorMessage}
      onErrorAlertClose={() => {
        onCancel();
      }}
    >
      <FormContainerWithoutRedux
        formHeader="Phone Number Application"
        size="lg"
        formType={FormTypesV2.tollFreeVerificationForm}
        saveButtonText="Submit"
        save={() => {
          const values = getValues();
          return onboardingDataProvider.provisionCustomerTextPhoneNumber({
            ...values,
            streetAddress: values.address.streetAndNumber,
            streetAddress2: "",
            city: values.address.city,
            state: values.address.state,
            postalCode: values.address.zip,
            country: values.address.country ?? "",
          });
        }}
        onSaveComplete={() => {
          dispatch(actionCreators.tollFreeVerificationSubmitted());
          onSaveComplete();
        }}
        onCancel={onCancel}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      >
        <div className="alert alert-info">
          <div>
            <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
            Crew Control will order a phone number for your business to send
            text messages to your customers.
          </div>
          <div>
            This information will be sent to our text message provider for
            verification. The process can take 2-4 weeks. You will be notified
            once your phone number is ready.
          </div>
        </div>
        <h5>Contact information</h5>
        <div className="form-section">
          <div className="form-row">
            <div className="col-12 col-lg-6 form-group">
              <label className="required" htmlFor="contactFirstName">
                First name
              </label>
              <input
                type="text"
                id="contactFirstName"
                data-testid="contactFirstName"
                className="form-control"
                required
                maxLength={500}
                {...register("contactFirstName")}
              />
            </div>
            <div className="col-12 col-lg-6 form-group">
              <label className="required" htmlFor="contactLastName">
                Last name
              </label>
              <input
                type="text"
                id="contactLastName"
                data-testid="contactLastName"
                className="form-control"
                required
                maxLength={500}
                {...register("contactLastName")}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-12 col-lg-6 form-group">
              <label className="required" htmlFor="contactPhoneNumber">
                Phone number
              </label>
              <Controller
                name="contactPhoneNumber"
                control={control}
                render={({ field }) => (
                  <PhoneNumberField
                    id="contactPhoneNumber"
                    dataTestId="contactPhoneNumber"
                    onChange={field.onChange}
                    value={field.value}
                    className="form-control"
                  />
                )}
              />
            </div>
            <div className="col-12 col-lg-6 form-group">
              <label className="required" htmlFor="contactEmailAddress">
                Email address
              </label>
              <input
                type="email"
                id="contactEmailAddress"
                data-testid="contactEmailAddress"
                className="form-control"
                required
                maxLength={500}
                {...register("contactEmailAddress")}
              />
            </div>
          </div>
        </div>
        <h5>Business information</h5>
        <div className="form-section">
          <div className="form-row">
            <div className="col-12 col-lg-6 form-group">
              <label className="required" htmlFor="businessName">
                Name
              </label>
              <input
                type="text"
                id="businessName"
                data-testid="businessName"
                className="form-control"
                required
                maxLength={500}
                {...register("businessName")}
              />
            </div>
            <div className="col-12 col-lg-6 form-group">
              <label className="required" htmlFor="businessWebsite">
                Website or Social Media Page
              </label>
              <InfoToolTip
                id="businessWebsiteToolTip"
                text={
                  <>
                    <div className="mb-1">
                      If you do not have a website, we require at least a social
                      media page for verification.
                    </div>
                    <div>
                      Please ensure your social media page includes your
                      business information and address.
                    </div>
                  </>
                }
              />
              <input
                type="url"
                data-testid="businessWebsite"
                id="businessWebsite"
                className="form-control"
                required
                maxLength={500}
                {...register("businessWebsite")}
              />
            </div>
          </div>
          <Controller
            control={control}
            name="address"
            render={({ field }) => {
              return (
                <AddressComponents2
                  streetAndNumberId="streetAddress"
                  streetAndNumberName="streetAddress"
                  value={field.value}
                  onChange={field.onChange}
                  streetAndNumberRef={streetAndNumberRef}
                  hideCoordinates
                  required
                  showCountry
                />
              );
            }}
          />
        </div>
      </FormContainerWithoutRedux>
    </ModalDataLoaderStateless>
  );
}
