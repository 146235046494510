import handleOpportunityDrop from "./epics/handleOpportunityDrop";
import loadOpportunities from "./epics/loadOpportunities";
import reloadOpportunity from "./epics/reloadOpportunity";
import reloadOpportunityBoard from "./epics/reloadOpportunityBoard";
import reloadOpportunityOnJobCreation from "./epics/reloadOpportunityOnJobCreation";
import reloadProposal from "./epics/reloadProposal";
import showArchivePromptOnSchedule from "./epics/showArchivePromptOnSchedule";

const salesEpics = [
  loadOpportunities,
  reloadOpportunityBoard,
  reloadOpportunity,
  handleOpportunityDrop,
  reloadProposal,
  reloadOpportunityOnJobCreation,
  showArchivePromptOnSchedule,
];

export default salesEpics;
