import React from "react";
import background from "../../public-background.png";
import screenshot from "../../scheduling2.png";

export function PageWithBranding({
  children,
  showHeaderScreenshot,
  showTransformationText,
  showFreeAccessText,
}: {
  children: React.ReactNode;
  showHeaderScreenshot?: boolean;
  showTransformationText?: boolean;
  showFreeAccessText?: boolean;
}) {
  return (
    <div
      className="container-fluid py-4"
      style={{
        background: `url(${background})`,
        backgroundPosition: "center",
        backgroundSize: "100vw 100%",
        minHeight: "100vh",
      }}
    >
      <div className="row justify-content-center px-3">
        <div
          className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-6 py-4 bg-white"
          style={{ borderRadius: ".75rem", maxWidth: "630px" }}
        >
          <div className="mb-3" style={{ textAlign: "center" }}>
            <picture>
              <source
                media="(max-width: 799px)"
                srcSet="/homepage_logo_sm.png"
                height={55}
              />
              <source media="(min-width: 800px)" srcSet="/homepage_logo.png" />
              <img
                src="/homepage_logo.png"
                alt="Logo"
                className="mb-3"
                height={80}
              />
            </picture>
            <div className="d-flex justify-content-center">
              <div>
                {showTransformationText ? (
                  <div
                    className="text-center"
                    style={{
                      maxWidth: "400px",
                      fontSize: "18px",
                    }}
                  >
                    You're moments away from transforming your business - from
                    proposal to invoicing.
                  </div>
                ) : null}

                {showFreeAccessText ? (
                  <div
                    className="text-center font-weight-bold mt-2"
                    style={{
                      maxWidth: "400px",
                      fontSize: "18px",
                    }}
                  >
                    Get 14 days of free access on us!
                  </div>
                ) : null}

                {showHeaderScreenshot ? (
                  <div className="mt-3">
                    <img
                      src={screenshot}
                      alt="Screenshot"
                      className="p-1 pb-3 border rounded"
                      height={169}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
