import { EmailAddresses, IEmailAddressRecord } from "./EmailAddresses";

export type EmailSendValue = {
  replyTo: string;
  recipients: Array<IEmailAddressRecord>;
};

export function EmailSendFields({
  value,
  onChange,
  recipientHeaderLabel,
  recipientFieldLabel,
  hideReplyToField,
  disabled,
}: {
  value: EmailSendValue;
  onChange(newValue: EmailSendValue): void;
  recipientHeaderLabel?: string;
  recipientFieldLabel?: string;
  hideReplyToField?: boolean;
  disabled?: boolean;
}) {
  return (
    <>
      {!hideReplyToField ? (
        <div className="form-group" key="replyToEmailAddress">
          <label
            htmlFor="replyToEmailAddress"
            className={!disabled ? "required" : ""}
          >
            Reply to email address
          </label>

          <input
            id="replyToEmailAddress"
            className="form-control"
            type="email"
            value={value.replyTo}
            required={!disabled}
            disabled={disabled}
            maxLength={250}
            onChange={(e) =>
              onChange({
                ...value,
                replyTo: e.currentTarget.value,
              })
            }
          />
        </div>
      ) : null}

      <div className="form-group" key="emailAddress">
        <label htmlFor="emailAddress" className={!disabled ? "required" : ""}>
          {recipientHeaderLabel ?? "Recipient email addresses"}
        </label>

        <EmailAddresses
          label={recipientFieldLabel ?? "Recipient email address"}
          emailAddresses={value.recipients}
          onChange={(newValue) =>
            onChange({
              ...value,
              recipients: newValue,
            })
          }
          required={true}
          disabled={disabled}
        />
      </div>
    </>
  );
}
