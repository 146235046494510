import { PaymentMethodType } from "../../../enums/paymentMethodType";
import { IInvoicePaymentParameters } from "../../../formGenerator/formParameters/IInvoicePaymentParameters";
import { IGetInvoicePaymentFormDataResponse } from "../../../slices/billing/services/invoiceDataProvider";
import { FormPaymentMethodOptions } from "./InvoicePaymentForm.types";

export function parsePaymentMethodType(
  paymentMethodTypeToParse: string
): FormPaymentMethodOptions | null {
  if (paymentMethodTypeToParse === "") {
    return null;
  }

  return parseInt(paymentMethodTypeToParse);
}

export function getPaymentMethodTypeForSave(
  input: FormPaymentMethodOptions | null
) {
  switch (input) {
    case FormPaymentMethodOptions.Ach:
      return PaymentMethodType.Ach;
    case FormPaymentMethodOptions.Cash:
      return PaymentMethodType.Cash;
    case FormPaymentMethodOptions.Check:
      return PaymentMethodType.Check;
    case FormPaymentMethodOptions.CreditCard:
      return PaymentMethodType.CreditCard;
    case FormPaymentMethodOptions.Other:
      return PaymentMethodType.Other;
    case FormPaymentMethodOptions.PaymentMethodOnFile:
      return null;
    case null:
      return null;
    default:
      // Used to ensure all enum options accounted for
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const exhaustiveCheck: never = input;
      return null;
  }
}

export function getPaymentMethodTypeFromFormParameters(
  formParameters: IGetInvoicePaymentFormDataResponse
): string {
  if (formParameters.existingPayment === null) {
    return "";
  }

  switch (formParameters.existingPayment.paymentMethodType) {
    case PaymentMethodType.Ach:
      return FormPaymentMethodOptions.Ach.toString();
    case PaymentMethodType.Cash:
      return FormPaymentMethodOptions.Cash.toString();
    case PaymentMethodType.Check:
      return FormPaymentMethodOptions.Check.toString();
    case PaymentMethodType.CreditCard:
      return FormPaymentMethodOptions.CreditCard.toString();
    case PaymentMethodType.Other:
      return FormPaymentMethodOptions.Other.toString();
    case null:
      return "";
    default:
      // Used to ensure all enum options accounted for
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const exhaustiveCheck: never =
        formParameters.existingPayment.paymentMethodType;
      return "";
  }
}

export function getSaveButtonText(
  paymentMethodType: FormPaymentMethodOptions | null
): string | undefined {
  if (
    paymentMethodType === FormPaymentMethodOptions.Ach ||
    paymentMethodType === FormPaymentMethodOptions.CreditCard ||
    paymentMethodType === FormPaymentMethodOptions.PaymentMethodOnFile
  ) {
    return "Charge invoice";
  } else {
    return undefined;
  }
}

export function isAddingPayment(
  formParameters: IInvoicePaymentParameters | null
) {
  return typeof formParameters?.paymentId !== "string";
}

export function isPaymentDateVisible(
  paymentMethodType: FormPaymentMethodOptions | null
) {
  if (
    paymentMethodType === FormPaymentMethodOptions.Ach ||
    paymentMethodType === FormPaymentMethodOptions.CreditCard ||
    paymentMethodType === FormPaymentMethodOptions.PaymentMethodOnFile
  ) {
    return false;
  }

  return true;
}

export function getMinimumPaymentAmount(
  paymentMethodType: FormPaymentMethodOptions | null
) {
  if (
    paymentMethodType === FormPaymentMethodOptions.Ach ||
    paymentMethodType === FormPaymentMethodOptions.CreditCard ||
    paymentMethodType === FormPaymentMethodOptions.PaymentMethodOnFile
  ) {
    return 1;
  }

  return 0.01;
}
