import React, { useEffect, useState, Fragment } from "react";
import CreatableSelect from "react-select/creatable";
import { GroupBase, OptionProps } from "react-select";
import { components } from "react-select";
import { connect } from "react-redux";
import { IRootState } from "../../../store";
import { actionCreators } from "../../../modules/actionCreators";
import addressFormatter from "../../../services/addressFormatter";
import { ICustomerAdditionalLocation } from "../../../models/ICustomerAdditionalLocation";
import uuidv4 from "uuid/v4";
import { getSortedItems } from "../../../services/sortingService";
import { ICustomerState } from "../../../modules/customer";
import CustomerLocationHelper from "../../../services/customerLocationHelper";
import { WrappedCreatableSelectOption } from "./WrappedCreatableSelectOption";

interface IProps extends IOwnProps {
  showCustomerAdditionalLocationForm: (parameters: any) => any;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
  customerState: ICustomerState;
}

interface IOwnProps {
  value: string | null;
  onCustomerAdditionalLocationSelection: (id: string | null) => void;
  customerId: string;
  forceDisable?: boolean;
  inputId?: string;
  placeholder?: string;
  disableAddEdit?: boolean;
  includeCustomerAddress?: boolean;
}

const CustomerAdditionalLocationSelection: React.SFC<IProps> = ({
  customerId,
  value,
  customerAdditionalLocations,
  customerState,
  onCustomerAdditionalLocationSelection,
  showCustomerAdditionalLocationForm,
  forceDisable,
  inputId,
  placeholder,
  disableAddEdit,
  includeCustomerAddress,
}) => {
  const [
    customerAdditionalLocationFormInstanceKey,
    setCustomerAdditionalLocationFormInstanceKey,
  ] = useState("");
  useEffect(() => {
    if (customerAdditionalLocationFormInstanceKey) {
      // TODO: Cleanup
      const customerAdditionalLocation = customerAdditionalLocations.find(
        (c) =>
          (c as any).parameters &&
          (c as any).parameters.formInstanceKey ===
            customerAdditionalLocationFormInstanceKey
      );
      if (customerAdditionalLocation) {
        onCustomerAdditionalLocationSelection(customerAdditionalLocation.id);
        setCustomerAdditionalLocationFormInstanceKey("");
      }
    }
  }, [
    customerAdditionalLocations,
    customerAdditionalLocationFormInstanceKey,
    onCustomerAdditionalLocationSelection,
  ]);

  const Option = (
    props: OptionProps<
      ICustomerAdditionalLocation,
      false,
      GroupBase<ICustomerAdditionalLocation>
    >
  ) => {
    const isNewItem =
      typeof props.data.id === "string" && props.data.id !== "" ? false : true;
    const hasName = !isNewItem && !!props.data.name;

    let optionContent: JSX.Element;
    if (isNewItem) {
      optionContent = (
        <div id="customerAdditionalLocationAddItem">
          <WrappedCreatableSelectOption
            isNewItem
            name={props.data.name ?? ""}
            recordType="location"
          />
        </div>
      );
    } else if (hasName) {
      optionContent = (
        <Fragment>
          <span>{props.data.name}</span>
          <small className="font-weight-light">
            {" "}
            {addressFormatter.formatAddressEntity(
              props.data as ICustomerAdditionalLocation
            )}
          </small>
        </Fragment>
      );
    } else {
      optionContent = (
        <span>
          {addressFormatter.formatAddressEntity(
            props.data as ICustomerAdditionalLocation
          )}
        </span>
      );
    }

    return <components.Option {...props}>{optionContent}</components.Option>;
  };

  const SingleValue = (args: any) => {
    const { children, ...props } = args;
    const data = props.data as ICustomerAdditionalLocation;

    const hasName = !!data.name;

    let optionContent: JSX.Element;
    if (hasName) {
      optionContent = (
        <Fragment>
          <span>{props.data.name}</span>
          <small className="font-weight-light">
            {" "}
            {addressFormatter.formatAddressEntity(
              props.data as ICustomerAdditionalLocation
            )}
          </small>
        </Fragment>
      );
    } else {
      optionContent = (
        <span>
          {addressFormatter.formatAddressEntity(
            props.data as ICustomerAdditionalLocation
          )}
        </span>
      );
    }

    return (
      <components.SingleValue {...props}>
        {optionContent}
      </components.SingleValue>
    );
  };

  const handleChange = (newValue: any, actionMeta: any) => {
    if (actionMeta.action === "select-option") {
      const selectedLocation = newValue as ICustomerAdditionalLocation;
      onCustomerAdditionalLocationSelection(selectedLocation.id);
    } else if (actionMeta.action === "clear") {
      onCustomerAdditionalLocationSelection(null);
    }
  };
  const sortedAdditonalLocations = getSortedItems(
    customerAdditionalLocations.filter(
      (l) => (l.customerId === customerId && !l.inactive) || l.id === value
    ),
    "name"
  );

  const customerAdditionalLocationsForSelection = includeCustomerAddress
    ? getLocationsWithCustomerAddress(
        customerId,
        customerState,
        sortedAdditonalLocations
      )
    : sortedAdditonalLocations;

  let selectedOption: ICustomerAdditionalLocation | undefined | null =
    customerAdditionalLocationsForSelection.find((c) => c.id === value);

  if (!selectedOption) {
    selectedOption = null;
  }

  return (
    <React.Fragment>
      <CreatableSelect<ICustomerAdditionalLocation>
        inputId={inputId ?? "customerAdditionalLocationSelectionInput"}
        isDisabled={!customerId || forceDisable}
        value={selectedOption}
        openMenuOnFocus
        tabSelectsValue={false}
        options={customerAdditionalLocationsForSelection}
        isClearable={true}
        onChange={handleChange}
        isValidNewOption={() => !disableAddEdit}
        createOptionPosition="first"
        components={{ Option, SingleValue }}
        placeholder={
          placeholder ??
          (customerId ? "Same as customer address" : "Select a customer")
        }
        filterOption={(option, input) =>
          !option.value || // is new
          CustomerLocationHelper.isMatchingItem(
            option.data as ICustomerAdditionalLocation,
            input
          )
        }
        getNewOptionData={(text) =>
          ({
            id: "",
            name: text,
          } as ICustomerAdditionalLocation)
        }
        onCreateOption={(input: string) => {
          if (customerId) {
            const formInstanceKey = uuidv4();
            setCustomerAdditionalLocationFormInstanceKey(formInstanceKey);

            showCustomerAdditionalLocationForm({
              customerId,
              formInstanceKey,
              defaultName: input,
            });
          }
        }}
        getOptionValue={(opt) => opt.id}
        noOptionsMessage={() =>
          !disableAddEdit
            ? "Start typing to find locations or add a new one..."
            : "Start typing to find locations..."
        }
        styles={{
          menu: (styles) => {
            return {
              ...styles,
              zIndex: 3,
            };
          },
        }}
      />
      {value && !disableAddEdit ? (
        <div>
          <button
            type="button"
            className="btn btn-sm btn-link"
            onClick={() =>
              showCustomerAdditionalLocationForm({
                customerAdditionalLocationId: value,
              })
            }
          >
            Edit location
          </button>
        </div>
      ) : null}
    </React.Fragment>
  );
};

function getLocationsWithCustomerAddress(
  customerId: string,
  customerState: ICustomerState,
  locations: ICustomerAdditionalLocation[]
) {
  const customer = customerState.customers.find((c) => c.id === customerId);

  const customerAddress = {
    id: customerId,
    customerId: customerId,
    name: "Customer Address",
    streetAndNumber: customer?.streetAndNumber,
    city: customer?.city,
    state: customer?.state,
    zip: customer?.zip,
  } as ICustomerAdditionalLocation;

  return [customerAddress, ...locations];
}

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => ({
  value: ownProps.value,
  onCustomerAdditionalLocationSelection:
    ownProps.onCustomerAdditionalLocationSelection,
  customerId: ownProps.customerId,
  customerAdditionalLocations: state.customer.customerAdditionalLocations,
  forceDisable: ownProps.forceDisable,
  inputId: ownProps.inputId,
  customerState: state.customer,
});

const mapDispatchToProps = {
  showCustomerAdditionalLocationForm:
    actionCreators.forms.customerAdditionalLocation.showForm,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerAdditionalLocationSelection);
