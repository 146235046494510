import { Action } from "@reduxjs/toolkit";
import { Epic } from "redux-observable";
import { catchError, filter, mergeMap, timeout } from "rxjs/operators";
import { opportunitiesActionCreators } from "../opportunity";
import { IApplicationState } from "../../../../modules";
import { loadOpportunitiesBoard } from "../../services/loadOpportunitiesBoard";
import { actionCreators } from "../../../../modules/actionCreators";
import { of } from "rxjs";

const reloadOpportunityBoard: Epic<Action, Action, IApplicationState> = (
  action,
  state$
) =>
  action.pipe(
    filter(opportunitiesActionCreators.reloadBoard.match),
    mergeMap(() => {
      return loadOpportunitiesBoard(state$.value).pipe(
        timeout(30000),
        catchError(() => of(actionCreators.null()))
      );
    })
  );

export default reloadOpportunityBoard;
