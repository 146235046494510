import { IDepositItem } from "../../../models/IDepositItem";
import { IInvoiceItem } from "../../../models/IInvoiceItem";
import { ILineItem } from "./InvoiceLineItem";
import { InvoiceItemField } from "./InvoiceLineItemInvoiceItemField";

export function DepositInvoiceItem({
  value,
  onChange,
  invoiceItems,
  setInvoiceItems,
  inputElementId,
}: {
  invoiceItems: Array<IInvoiceItem>;
  setInvoiceItems: React.Dispatch<React.SetStateAction<Array<IInvoiceItem>>>;
  value: IDepositItem;
  onChange(newValue: IDepositItem): void;
  inputElementId: string;
}) {
  function getDepositItem(depositItem: IDepositItem) {
    let items = invoiceItems.filter((i) => i.id === depositItem.itemId);
    let result: ILineItem | null = null;

    if (items.length > 0) {
      let item = items[0];
      result = {
        ...item,
        description: item.description ?? "",
        itemId: item.id,
        quantity: "",
        amountPerItem: "",
      };
    }
    return result;
  }

  return (
    <InvoiceItemField
      ariaLabel={"Deposit Item"}
      placeHolderText={"Select deposit item..."}
      lineItem={
        getDepositItem(value) ?? {
          amountPerItem: "",
          description: "",
          name: "",
          quantity: "",
          serviceDate: "",
          id: "",
          itemId: "",
          taxable: false,
        }
      }
      onLineItemChanged={(changedItem) => {
        onChange({
          itemId: changedItem.itemId,
          name: changedItem.name,
          description: changedItem.description,
        });
      }}
      items={invoiceItems}
      onClearErrorMessage={() => {}}
      jobInstances={[]}
      selectedJobInstances={[]}
      setInvoiceItems={setInvoiceItems}
      inputElementId={inputElementId}
      onItemAdded={(id, name, unitPrice) => {
        onChange({
          ...value,
          itemId: id,
        });

        setInvoiceItems([
          ...(invoiceItems ?? []),
          {
            id,
            name,
            unitPrice,
            description: "",
            taxable: false,
            inactive: false,
          },
        ]);
      }}
      onItemUpdated={({ id, name, description, inactive, unitPrice }) => {
        setInvoiceItems(
          (invoiceItems ?? []).map((i) => {
            if (i.id === id) {
              return {
                ...i,
                name,
                description,
                inactive,
                unitPrice,
              };
            }

            return i;
          })
        );

        onChange({
          itemId: id,
          name: name,
          description: description,
        });
      }}
    />
  );
}
