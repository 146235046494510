import { useEffect, useState } from "react";
import dateService from "../../../services/dateService";
import jobDataProvider, {
  JobConflict,
} from "../../../slices/schedule/services/jobDataProvider";
import { Subscription } from "rxjs";
import { isStringSet } from "../../../services/stringService";

export function JobConflictAlert({
  crewId,
  date,
  startTime,
  endTime,
  jobInstanceId,
}: {
  crewId: string;
  date: string;
  startTime: string;
  endTime: string;
  jobInstanceId: string | null;
}) {
  const [conflicts, setConflicts] = useState<Array<JobConflict>>([]);
  useEffect(() => {
    setConflicts([]);

    let subscription: Subscription | null = null;

    const formattedDate = date ? dateService.formatAsIso(date) : null;
    const formattedStartTime =
      dateService.formatTimeForSerialization(startTime);
    const formattedEndTime = dateService.formatTimeForSerialization(endTime);
    if (crewId && formattedDate && formattedStartTime && formattedEndTime) {
      subscription = jobDataProvider
        .getConflicts({
          currentJobInstanceId: jobInstanceId,
          crewId,
          date: formattedDate,
          startTime: formattedStartTime,
          endTime: formattedEndTime,
        })
        .subscribe({
          next: (result) => setConflicts(result),
        });
    }

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [jobInstanceId, crewId, date, startTime, endTime]);

  return conflicts.length > 0 ? (
    <div className="alert alert-warning p-2" data-testid="conflictContainer">
      This job has the following conflicts:
      <ul className="mb-0">
        {conflicts.map((c) =>
          c.startTime && c.endTime ? (
            <li key={c.jobInstanceId} data-testid="conflict">
              {!dateService.areDatesEqual(date, c.date)
                ? dateService.formatDateForDisplay(c.date) + " "
                : null}
              {dateService.formatTimeForDisplay(c.startTime)} -{" "}
              {dateService.formatTimeForDisplay(c.endTime)} {c.customerName}
              {isStringSet(c.address) ? ` at ${c.address}` : ""}
            </li>
          ) : null
        )}
      </ul>
    </div>
  ) : null;
}
