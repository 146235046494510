export const au_administrativeAreaLabel = "State";

export const au_postalCodeLabel = "Postal code";

export const au_localityLabel = "Suburb";

export const au_administrativeAreas = [
  {
    name: "Australian Capital Territory",
    abbreviation: "ACT",
  },
  {
    name: "Jervis Bay Territory",
    abbreviation: "JBT",
  },
  {
    name: "New South Wales",
    abbreviation: "NSW",
  },
  {
    name: "Northern Territory",
    abbreviation: "NT",
  },
  {
    name: "Queensland",
    abbreviation: "QLD",
  },
  {
    name: "South Australia",
    abbreviation: "SA",
  },
  {
    name: "Tasmania",
    abbreviation: "TAS",
  },
  {
    name: "Victoria",
    abbreviation: "VIC",
  },
  {
    name: "Western Australia",
    abbreviation: "WA",
  },
];
