import React, { useState } from "react";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";
import { IAddress } from "../../../models/IAddress";
import addressFormatter from "../../../services/addressFormatter";
import LinkButton from "./LinkButton";

interface IProps {
  useWhiteText?: boolean;
  address: IAddress;
  entityId: string;
}

const CardAddress: React.FunctionComponent<IProps> = ({
  useWhiteText,
  address,
  entityId,
}) => {
  const formattedAddress = addressFormatter.formatAddressEntity(address);
  const [showLink, setShowLink] = useState(false);

  return formattedAddress ? (
    <>
      <div id={`addresscontent_${entityId}`} data-testid="address-text">
        <LinkButton
          linkText={formattedAddress}
          hrefForDisplay="map"
          onClick={() => setShowLink(!showLink)}
          className="schedule-links"
          style={{
            color: useWhiteText ? "#fff" : "#495057",
          }}
          testId="address-link-trigger"
        />
      </div>
      <Popover
        delay={0}
        trigger="legacy"
        placement="bottom"
        isOpen={showLink}
        target={`addresscontent_${entityId}`}
        toggle={() => {
          setShowLink(!showLink);
        }}
      >
        <PopoverBody>
          <a
            href={addressFormatter.getGoogleMapsAddressLink(address)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.stopPropagation();
              setShowLink(false);
            }}
            data-testid="address-link"
          >
            Open in Google Maps
          </a>
        </PopoverBody>
      </Popover>
    </>
  ) : null;
};

export default CardAddress;
