import React, { CSSProperties } from "react";
import TimeInputPolyfill from "react-time-input-polyfill";
import { isFeatureFlagSet, Flags } from "../../../services/featureFlagService";

interface IProps {
  id?: string;
  name?: string;
  disabled?: boolean;
  className?: string;
  value: string;
  onChange: (newValue: string) => void;
  ["aria-label"]?: string;
  required?: boolean;
  style?: Partial<CSSProperties>;
}

const TimeInput: React.FunctionComponent<IProps> = (props) => {
  const { id, name, disabled, className, value, onChange, required } = props;

  return (
    <TimeInputPolyfill
      {...props}
      id={id}
      name={name}
      disabled={disabled}
      className={className}
      value={value}
      onChange={({ value }) => {
        // Export the new value to the parent component
        onChange(value);
      }}
      forcePolyfill={isFeatureFlagSet(Flags.altTimeInput)}
      polyfillSource={`${process.env.PUBLIC_URL}/assets/libs/timePolyfillHelpers.js`}
      required={required}
    />
  );
};

export default TimeInput;
