import React, { useState, CSSProperties, useMemo, useEffect } from "react";
import Spinner from "../../components/Spinner";
import { builders as routerBuilders } from "../../../../services/routing";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch } from "react-redux";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import NavLink from "reactstrap/lib/NavLink";
import TabContent from "reactstrap/lib/TabContent";
import TabPane from "reactstrap/lib/TabPane";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { ICustomer } from "../../../../models/ICustomer";
import addressFormatter from "../../../../services/addressFormatter";
import { actionCreators } from "../../../../modules/actionCreators";
import CustomerDetailsHistory from "./CustomerDetailsHistory";
import TabContentContainer from "../../components/DetailsPageTabContentContainer";
import RecordDetails from "../../components/RecordDetails";
import remoteDataProvider from "../../../../services/remoteDataProvider";
import CustomerBilling from "./CustomerBilling";
import { formatCurrency } from "../../../../services/currencyFormatter";
import useSalesEnabled from "../../../../slices/sales/hooks/useSalesEnabled";
import CustomerOpportunities from "./CustomerOpportunities";
import useIsAdmin from "../../../../hooks/useIsAdmin";
import invoiceDataProvider from "../../../../slices/billing/services/invoiceDataProvider";
import { NoteWithModal } from "../../components/NoteWithModal";
import { isStringSet } from "../../../../services/stringService";
import ErrorJumbotron from "../../components/ErrorJumbotron";
import PageWithNavBar2 from "../../PageWithNavBar2";
import CustomerDetailsJobs from "./CustomerDetailsJobs";
import { getNormalizedPhoneNumber } from "../../../../services/phoneNumberService";
import CustomerDetailsLocations from "./CustomerDetailsLocations";
import { useQuery } from "../../../../hooks/useQuery";
import constants from "../../../../constants";
import { CustomerRecordTab } from "../../../../enums/customerRecordTab";
import { useIsResolution } from "../../../../hooks/useIsResolution";
import MobileDetailsPageTabContents from "../../components/MobileDetailsPageTabContents";

export interface IRouteParams {
  customerId: string;
  search?: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {}

const tabStyle: Partial<CSSProperties> = {
  cursor: "pointer",
};

const CustomerDetails: React.FunctionComponent<IProps> = ({ match }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { customerId, search } = match.params;
  const [requestedCustomerIds, setRequestedCustomerIds] = useState<
    Array<string>
  >([]);

  useEffect(() => {
    dispatch(actionCreators.loadCustomersErrorClear([customerId]));
  }, [dispatch, customerId]);

  const errors = useApplicationStateSelector((s) => s.manageUi.errors);
  const errorMessageFromRedux = errors[customerId];
  const [errorMessage, setErrorMessage] = useState("");

  const salesEnabled = useSalesEnabled();

  const navbarBreakpoint = "sm";
  const isDesktopNavbar = useIsResolution(navbarBreakpoint);

  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const fullyLoadedCustomers = useApplicationStateSelector(
    (s) => s.customer.fullyLoadedCustomers
  );
  const customersFailedToLoad = useApplicationStateSelector(
    (s) => s.customer.customersFailedToLoad
  );
  const customersNotFound = useApplicationStateSelector(
    (s) => s.customer.customersNotFound
  );
  const customer = useMemo(() => {
    return customers.find((c) => c.id === customerId) as ICustomer;
  }, [customers, customerId]);

  const [activeTab, setActiveTab] = useState(
    query.get(constants.customerRecordTab) ??
      CustomerRecordTab.history.toString()
  );
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const defaultSearchText = query.get(constants.customerRecordSearchText);

  const [hasOutstandingBalance, setHasOutstandingBalance] = useState(false);
  const [outstandingBalance, setOutstandingBalance] = useState<number | null>(
    null
  );
  const [balanceLoaded, setBalanceLoaded] = useState<boolean>(false);
  const isAdmin = useIsAdmin();

  const isDesktopView = useIsResolution("sm");

  useEffect(() => {
    invoiceDataProvider.getCustomerBalance(customerId).subscribe(
      (i) => {
        setHasOutstandingBalance(i.hasBalance);
        setOutstandingBalance(i.balance);
        setBalanceLoaded(true);
      },
      (err) => {
        setBalanceLoaded(true);
      }
    );
  }, [customerId]);

  if (customersFailedToLoad.includes(customerId)) {
    return (
      <PageWithNavBar2 billingContext={true} notFluid={true}>
        <ErrorJumbotron
          details={
            <>
              <div>
                An error occurred while loading this customer. Please check your
                Internet connection and try again.
              </div>
              <div className="mt-4">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    dispatch(
                      actionCreators.loadCustomersErrorClear([customerId])
                    );
                    setRequestedCustomerIds([]);
                  }}
                >
                  Try Again
                </button>
              </div>
            </>
          }
        />
      </PageWithNavBar2>
    );
  } else if (customersNotFound.includes(customerId)) {
    return (
      <PageWithNavBar2 billingContext={true} notFluid={true}>
        <ErrorJumbotron details={<>This customer no longer exists.</>} />
      </PageWithNavBar2>
    );
  } else if (
    !customer ||
    !fullyLoadedCustomers.includes(customerId) ||
    !balanceLoaded
  ) {
    if (!requestedCustomerIds.includes(customerId)) {
      setRequestedCustomerIds([...requestedCustomerIds, customerId]);
      dispatch(actionCreators.loadCustomersStart([customerId]));
    }

    return <Spinner />;
  }

  const normalizedPhoneNumber = getNormalizedPhoneNumber(customer.phoneNumber);
  const normalizedAlternativePhoneNumber = getNormalizedPhoneNumber(
    customer.alternativePhoneNumber
  );

  const noneText = "None";
  const noteFieldStyle = {
    maxWidth: "500px",
  };
  return (
    <RecordDetails
      backLinkText="Customers"
      backLinkUrl={routerBuilders.manage.buildCustomersRoute(search)}
      getEditAction={() =>
        actionCreators.forms.customer.showForm({
          customerId: match.params.customerId,
        })
      }
      fullWidth={true}
      recordName={customer.name}
      recordTypeForActions="customer"
      deleteFunction={() => remoteDataProvider.deleteCustomer(customerId)}
      deleteRedirectRoute={routerBuilders.manage.buildCustomersRoute()}
      errorMessage={errorMessageFromRedux || errorMessage}
      setErrorMessage={setErrorMessage}
      clearErrorMessage={() => {
        setErrorMessage("");
        dispatch(actionCreators.hideManageUiError(customerId));
      }}
      warningMessage={
        hasOutstandingBalance
          ? outstandingBalance === null
            ? "Customer has an outstanding balance"
            : `Outstanding balance: ${formatCurrency(outstandingBalance)}`
          : null
      }
      labelFieldRows={[
        {
          key: "0",
          fields: [
            {
              label: "Primary address",
              value: addressFormatter.formatAddressEntity(customer)
                ? addressFormatter.formatAddressEntity(customer)
                : noneText,
            },
            {
              label: "Phone",
              value: !normalizedPhoneNumber ? (
                noneText
              ) : (
                <a
                  href={"tel:" + normalizedPhoneNumber}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {normalizedPhoneNumber}
                </a>
              ),
            },
            {
              label: "Alternative phone",
              value: !normalizedAlternativePhoneNumber ? (
                noneText
              ) : (
                <a
                  href={"tel:" + normalizedAlternativePhoneNumber}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {normalizedAlternativePhoneNumber}
                </a>
              ),
            },
            {
              label: "Email",
              value:
                !customer.emailAddresses ||
                customer.emailAddresses.length === 0 ? (
                  noneText
                ) : (
                  <ul className="comma-list">
                    {customer.emailAddresses.map((emailAddress, index) => (
                      <li key={index} className="text-truncate">
                        <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
                      </li>
                    ))}
                  </ul>
                ),
            },
            {
              label: "Tax-exempt",
              value: customer.taxExempt ? "Yes" : "No",
              testId: "taxExempt",
            },
          ],
        },
        {
          key: "1",
          fields: [
            {
              label: "Administrator only notes",
              value: (
                <NoteWithModal text={customer.administratorOnlyNotes ?? ""} />
              ),
              hidden: !isStringSet(customer.administratorOnlyNotes),
              style: noteFieldStyle,
            },
            {
              label: "Crew notes",
              value: <NoteWithModal text={customer.notesForCrew ?? ""} />,
              hidden: !isStringSet(customer.notesForCrew),
              style: noteFieldStyle,
            },
          ],
        },
      ]}
      getDeleteCompleteAction={() =>
        actionCreators.customerDeleteComplete(customerId)
      }
    >
      <>
        <Nav
          tabs
          style={{
            marginLeft: !isDesktopNavbar ? "-14px" : undefined,
            marginRight: !isDesktopNavbar ? "-14px" : undefined,
            borderBottomStyle: isDesktopNavbar ? "none" : undefined,
          }}
          pills={true}
          fill={true}
          className={`d-block d-${navbarBreakpoint}-flex`}
        >
          <NavItem>
            <NavLink
              style={tabStyle}
              className={getStyles(
                CustomerRecordTab.history.toString(),
                activeTab,
                isDesktopNavbar
              )}
              onClick={() => {
                toggle(CustomerRecordTab.history.toString());
              }}
            >
              <small>History</small>
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              style={tabStyle}
              className={getStyles(
                CustomerRecordTab.jobs.toString(),
                activeTab,
                isDesktopNavbar
              )}
              onClick={() => {
                toggle(CustomerRecordTab.jobs.toString());
              }}
            >
              <small>Jobs</small>
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              style={tabStyle}
              className={getStyles(
                CustomerRecordTab.locations.toString(),
                activeTab,
                isDesktopNavbar
              )}
              onClick={() => {
                toggle(CustomerRecordTab.locations.toString());
              }}
            >
              <small>Locations</small>
            </NavLink>
          </NavItem>

          {isAdmin ? (
            <NavItem>
              <NavLink
                style={tabStyle}
                className={getStyles(
                  CustomerRecordTab.billing.toString(),
                  activeTab,
                  isDesktopNavbar
                )}
                onClick={() => {
                  toggle(CustomerRecordTab.billing.toString());
                }}
              >
                <small>Billing</small>
              </NavLink>
            </NavItem>
          ) : null}

          {salesEnabled && isAdmin ? (
            <NavItem>
              <NavLink
                style={tabStyle}
                className={getStyles(
                  CustomerRecordTab.sales.toString(),
                  activeTab,
                  isDesktopNavbar
                )}
                onClick={() => {
                  toggle(CustomerRecordTab.sales.toString());
                }}
              >
                <small>Sales</small>
              </NavLink>
            </NavItem>
          ) : null}
        </Nav>

        {isDesktopView ? (
          <TabContent activeTab={activeTab}>
            <TabPane tabId={CustomerRecordTab.history.toString()}>
              <TabContentContainer>
                <CustomerDetailsHistory
                  customerName={customer.name}
                  customerId={customerId}
                />
              </TabContentContainer>
            </TabPane>
            <TabPane tabId={CustomerRecordTab.jobs.toString()}>
              <TabContentContainer>
                <CustomerDetailsJobs
                  customerId={customerId}
                  defaultSearchText={defaultSearchText}
                />
              </TabContentContainer>
            </TabPane>
            <TabPane tabId={CustomerRecordTab.locations.toString()}>
              <TabContentContainer>
                <CustomerDetailsLocations customerId={customerId} />
              </TabContentContainer>
            </TabPane>
            <TabPane tabId={CustomerRecordTab.billing.toString()}>
              <TabContentContainer>
                <CustomerBilling
                  customer={customer}
                  visible={activeTab === CustomerRecordTab.billing.toString()}
                />
              </TabContentContainer>
            </TabPane>
            {salesEnabled ? (
              <TabPane tabId={CustomerRecordTab.sales.toString()}>
                <TabContentContainer>
                  <CustomerOpportunities customer={customer} />
                </TabContentContainer>
              </TabPane>
            ) : null}
          </TabContent>
        ) : (
          <MobileDetailsPageTabContents>
            <CurrentCustomerTab
              customer={customer}
              activeTab={activeTab}
              defaultSearchText={defaultSearchText}
            />
          </MobileDetailsPageTabContents>
        )}
      </>
    </RecordDetails>
  );
};

export default CustomerDetails;

function getStyles(
  tabIndex: string,
  activeTab: string,
  isDesktopNavbar: boolean
) {
  return (
    "text-center " +
    (isDesktopNavbar ? "" : " border rounded-0 ") +
    (activeTab === tabIndex ? "active" : "")
  );
}

function CurrentCustomerTab({
  customer,
  activeTab,
  defaultSearchText,
}: {
  customer: ICustomer;
  activeTab: string;
  defaultSearchText: string | null;
}) {
  if (activeTab === CustomerRecordTab.history.toString()) {
    return (
      <CustomerDetailsHistory
        customerName={customer.name}
        customerId={customer.id}
      />
    );
  } else if (activeTab === CustomerRecordTab.jobs.toString()) {
    return (
      <CustomerDetailsJobs
        customerId={customer.id}
        defaultSearchText={defaultSearchText}
      />
    );
  } else if (activeTab === CustomerRecordTab.locations.toString()) {
    return <CustomerDetailsLocations customerId={customer.id} />;
  } else if (activeTab === CustomerRecordTab.billing.toString()) {
    return <CustomerBilling customer={customer} visible />;
  } else if (activeTab === CustomerRecordTab.sales.toString()) {
    return <CustomerOpportunities customer={customer} />;
  } else {
    return null;
  }
}
