import { IJobInstance } from "../models/IJobInstance";
import jobFinder from "../services/jobFinder";
import { useApplicationStateSelector } from "./useApplicationStateSelector";

export function useFindJob(jobInstance: IJobInstance) {
  const maintenanceJobs = useApplicationStateSelector((s) => s.job.jobs);
  const oneTimeJobs = useApplicationStateSelector((s) => s.job.oneTimeJobs);

  return jobFinder.getJobForDayScheduleV2(
    maintenanceJobs,
    oneTimeJobs,
    jobInstance
  );
}
