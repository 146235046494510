import * as React from "react";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import { IBillingReportCustomer } from "../../../../models/IBillingReport";
import billingReportService from "../../../../services/billingReportService";

export interface IProps {
  customerForBillingDetails: IBillingReportCustomer | null;
  closeBillingDetails(): void;
  formatDateForReportDisplay(date: Date | string): string;
}

const BillingDetailsModal: React.SFC<IProps> = ({
  customerForBillingDetails,
  closeBillingDetails,
  formatDateForReportDisplay,
}) => {
  return (
    <Modal
      isOpen={!!customerForBillingDetails}
      toggle={closeBillingDetails}
      keyboard={false}
      scrollable
    >
      <ModalHeader toggle={closeBillingDetails}>
        Billing Details for{" "}
        {!!customerForBillingDetails ? customerForBillingDetails.name : ""}
      </ModalHeader>
      <ModalBody>
        {customerForBillingDetails
          ? customerForBillingDetails.jobInstances
              .sort(billingReportService.sortCustomerJobInstances)
              .filter((ji) =>
                billingReportService.doesJobInstanceHaveBillingDetails(ji)
              )
              .map((ji) => (
                <React.Fragment key={ji.id}>
                  <h5>
                    Billing Details for {formatDateForReportDisplay(ji.date)}
                  </h5>
                  <form>
                    <div className="form-group">
                      <label htmlFor="purchaseOrderNumber">PO number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="purchaseOrderNumber"
                        value={ji.purchaseOrderNumber || ""}
                        readOnly={true}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="invoiceNumber">Invoice details</label>
                      <input
                        type="text"
                        className="form-control"
                        id="invoiceNumber"
                        value={ji.invoiceNumber}
                        readOnly={true}
                      />
                    </div>
                    <div className="form-group">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="invoiceSent"
                          defaultChecked={ji.invoiceSent}
                          disabled
                        />
                        <label
                          className="custom-control-label text-dark"
                          htmlFor="invoiceSent"
                        >
                          Invoice sent
                        </label>
                      </div>
                    </div>
                  </form>
                </React.Fragment>
              ))
          : null}
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-primary"
          type="button"
          onClick={closeBillingDetails}
        >
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default BillingDetailsModal;
