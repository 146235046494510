import React from "react";
import { TabPane } from "reactstrap/lib";
import TabContentContainer from "./DetailsPageTabContentContainer";

interface IProps {
  activeTab: string;
  tabId: string;
}

const TabPaneAndContainerLazyLoaded: React.FunctionComponent<IProps> = ({
  activeTab,
  tabId,
  children,
}) => {
  return (
    <TabPane tabId={tabId}>
      <TabContentContainer>
        {activeTab === tabId ? children : null}
      </TabContentContainer>
    </TabPane>
  );
};

export default TabPaneAndContainerLazyLoaded;
