import constants from "../../../constants";
import { getQueryVariable } from "../../../services/getQueryVariable";
import { isStringSet } from "../../../services/stringService";

export function setSessionProperties() {
  if (
    !window.sessionStorage.getItem(constants.signUpReferralSessionStorageKey) &&
    isUtmQueryStringSet()
  ) {
    const decodedReferral = window.location.href;

    if (decodedReferral) {
      window.sessionStorage.setItem(
        constants.signUpReferralSessionStorageKey,
        decodedReferral
      );
    }
  }
}

function isUtmQueryStringSet() {
  const utmFields = [
    "utm_campaign",
    "utm_source",
    "utm_medium",
    "utm_content",
    "utm_term",
  ];
  return utmFields.some((field) =>
    isStringSet(getQueryVariable(field) ?? null)
  );
}
