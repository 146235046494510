import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { SortDirection } from "../../../enums/sortDirection";
import LinkButton from "./LinkButton";

interface IProps<TSortEnum> {
  displayName: string | React.ReactNode;
  columnSortProperty: TSortEnum;
  currentSortProperty: TSortEnum | null;
  currentSortDirection: SortDirection;
  onSortDirectionChange: (
    sortProperty: TSortEnum,
    sortDirection: SortDirection
  ) => void;
  testId?: string;
}

const SortColumn: <T>(p: IProps<T>) => React.ReactElement<IProps<T>> = ({
  displayName,
  columnSortProperty,
  currentSortProperty,
  currentSortDirection,
  onSortDirectionChange: setSortDirection,
  testId,
}) => {
  let linkText: JSX.Element;
  if (columnSortProperty === currentSortProperty) {
    if (currentSortDirection === SortDirection.Ascending) {
      linkText = (
        <>
          {displayName}{" "}
          <FontAwesomeIcon icon={faChevronUp} data-testid="chevronUp" />
        </>
      );
    } else {
      linkText = (
        <>
          {displayName}{" "}
          <FontAwesomeIcon icon={faChevronDown} data-testid="chevronDown" />
        </>
      );
    }
  } else {
    linkText = <>{displayName}</>;
  }
  return (
    <LinkButton
      hrefForDisplay="/sort"
      linkText={linkText}
      testId={testId}
      onClick={() => {
        setSortDirection(
          columnSortProperty,
          currentSortProperty === columnSortProperty &&
            currentSortDirection === SortDirection.Ascending
            ? SortDirection.Descending
            : SortDirection.Ascending
        );
      }}
    />
  );
};

export default SortColumn;
