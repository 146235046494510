import { useEffect, useRef, useState } from "react";
import { Observable } from "rxjs";
import { finalize, timeout } from "rxjs/operators";
import Alert from "./Alert";
import Spinner from "./Spinner";

interface IProps<TDataToLoad> {
  errorMessage: string;
  loadData(): Observable<TDataToLoad>;
  onDataLoaded(data: TDataToLoad): void;
  onErrorAlertClose(): void;
  children: React.ReactElement<TDataToLoad>;
  loadingFromParent?: boolean;
  errorFromParent?: boolean;
}

const ModalDataLoader: <TDataToLoad>(
  p: IProps<TDataToLoad>
) => React.ReactElement<IProps<TDataToLoad>> = ({
  errorMessage,
  loadData,
  onDataLoaded,
  onErrorAlertClose,
  children,
  loadingFromParent,
  errorFromParent,
}) => {
  const [loadingData, setLoadingData] = useState(true);
  const [errorLoadingData, setErrorLoadingData] = useState(false);
  const loadedData = useRef(false);

  useEffect(() => {
    if (!loadedData.current) {
      loadData()
        .pipe(
          timeout(10000),
          finalize(() => setLoadingData(false))
        )
        .subscribe({
          next: (result) => {
            onDataLoaded(result);
            setLoadingData(false);
          },

          error: () => setErrorLoadingData(true),
        });

      loadedData.current = true;
    } else {
      console.error(
        "ModalDataLoader incorrectly called twice. Please ensure loadData and onDataLoaded are wrapped in useCallback to prevent this method from firing on each parent render."
      );
    }
  }, [loadData, onDataLoaded]);

  if (errorLoadingData || errorFromParent) {
    return <Alert message={errorMessage} closeForm={onErrorAlertClose} />;
  } else if (loadingData || loadingFromParent) {
    return <Spinner />;
  } else {
    return children;
  }
};

export default ModalDataLoader;
