import React from "react";
import { TableColumns } from "./TableColumn";
import RowProperties from "./rowProperties";
import { ResponsiveTableMobileCardContainer } from "./ResponsiveTableMobileCardContainer";
import { ResponsiveTableMobileCardColumns } from "./ResponsiveTableMobileCardColumns";

export function ResponsiveTableMobileCard<T extends RowProperties>({
  row,
  rowIndex,
  columns,
  cardMargin = "mb-3",
}: {
  row: T;
  rowIndex: number;
  columns: TableColumns<T>;
  cardMargin?: string;
}) {
  return (
    <>
      <React.Fragment key={row.id}>
        <ResponsiveTableMobileCardContainer
          cardMargin={cardMargin}
          errorMessage={row.errorMessage}
          contentBelowRow={row.contentBelowRow}
        >
          <ResponsiveTableMobileCardColumns
            columns={columns}
            row={row}
            rowIndex={rowIndex}
          />
        </ResponsiveTableMobileCardContainer>
      </React.Fragment>
    </>
  );
}
