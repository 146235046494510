import { useMemo } from "react";
import { useMediaQuery } from "./useMediaQuery";

export function useIsResolution(resolution: "xs" | "sm" | "md" | "lg") {
  let mediaQuery = "";
  switch (resolution) {
    case "xs":
      mediaQuery = "(max-width: 575px)";
      break;
    case "sm":
      mediaQuery = "(min-width: 576px)";
      break;
    case "md":
      mediaQuery = "(min-width: 768px)";
      break;
    case "lg":
      mediaQuery = "(min-width: 992px)";
      break;
    default:
      // Used to ensure all options accounted for
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const exhaustiveCheck: never = resolution;
  }

  const initialValue = useMemo(
    () => window.matchMedia(mediaQuery).matches,
    [mediaQuery]
  );

  return useMediaQuery(mediaQuery, initialValue);
}
