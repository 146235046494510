export function isTaxRateDisabled({
  customerTaxExempt,
  taxRateAlreadySet,
}: {
  customerTaxExempt: boolean;
  taxRateAlreadySet: boolean;
}) {
  const taxRateDisabled = customerTaxExempt && !taxRateAlreadySet;

  return taxRateDisabled;
}
