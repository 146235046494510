export enum CompletedWorkReportColumn {
  SpecificVisits = 0,
  VisitCount = 1,
  Frequency = 2,
  Notes = 3,
  Billing = 4,
  Services = 5,
  Variance = 6,
  Hours = 7,
  Revenue = 8,
  RevenuePerManHour = 9,
}
