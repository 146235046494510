import React, { useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import constants from "../../../constants";
import PageWithNavBar2 from "../../../containers/app/PageWithNavBar2";
import Opportunities from "./Opportunities";
import OpportunityForm from "./OpportunityForm";
import { builders } from "../../../services/routing";
import useSalesEnabled from "../hooks/useSalesEnabled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket } from "@fortawesome/free-solid-svg-icons";
import { useIsCurrentUserOwner } from "../../../hooks/useIsCurrentUserOwner";
import { UpgradePlan } from "../../tenantSubscription/components/UpgradePlan";
import salesPageDesktop from "../../../sales-page-desktop-800.png";
import salesPageMobile from "../../../sales-page-mobile-250.png";

interface IRouteParams {
  opportunityId?: string;
}

const SalesPage: React.FunctionComponent<RouteComponentProps<IRouteParams>> = ({
  match,
}) => {
  const salesEnabled = useSalesEnabled();

  return (
    <PageWithNavBar2 billingContext={true}>
      {salesEnabled ? (
        <SalesActivePage opportunityToFocus={match.params.opportunityId} />
      ) : (
        <SalesInactivePage />
      )}
    </PageWithNavBar2>
  );
};

function SalesActivePage({
  opportunityToFocus,
}: {
  opportunityToFocus: string | undefined;
}) {
  const [showForm, setShowForm] = useState(false);
  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center flex-wrap"
        style={{ columnGap: "10px" }}
      >
        <h1>Sales</h1>
        <div className="d-flex flex-nowrap">
          <button
            className="btn btn-primary text-nowrap"
            type="button"
            onClick={() => setShowForm(true)}
          >
            Add Opportunity
          </button>

          <Link
            to={builders.manage.buildProposalTemplatesPage()}
            className="btn btn-secondary ml-3 text-nowrap"
          >
            Proposal Templates
          </Link>
        </div>
      </div>
      <div
        style={{
          marginBottom: constants.marginBottomForIntercom,
        }}
        className="mt-3"
      >
        <Opportunities opportunityToFocus={opportunityToFocus} />
      </div>

      {showForm ? (
        <OpportunityForm
          onSaveComplete={() => setShowForm(false)}
          onCancel={() => setShowForm(false)}
        />
      ) : null}
    </>
  );
}

function SalesInactivePage() {
  const currentUserIsOwner = useIsCurrentUserOwner();
  return (
    <>
      <div className="container pt-2">
        <div className="alert alert-primary">
          <div style={{ fontSize: "2.5rem" }} className="mb-2 text-center">
            <FontAwesomeIcon
              icon={faRocket}
              className="d-none d-md-inline-block"
            />{" "}
            Win more work and optimize your sales!
          </div>
          <div className="lead text-center">
            Learn more about{" "}
            <a
              href="https://www.youtube.com/watch?v=iSreFmVqNtE"
              target="_blank"
              rel="noopener noreferrer"
            >
              customer sales and electronic proposals.
            </a>
            {!currentUserIsOwner ? (
              <div>
                Contact your owner to upgrade your plan in Crew Control.
              </div>
            ) : null}
          </div>
          <div className="mt-3 d-flex justify-content-center">
            {currentUserIsOwner ? (
              <div style={{ maxWidth: "500px" }} className="text-center">
                <UpgradePlan
                  renderButton={({ onClick }) => (
                    <button
                      type="button"
                      className="text-nowrap btn border border-dark mb-3"
                      style={{
                        background: "#ffc107",
                      }}
                      onClick={onClick}
                    >
                      <b>Upgrade to Plus</b>
                    </button>
                  )}
                  onRedirectToPortal={(portalUrl) =>
                    (window.location.href = portalUrl)
                  }
                />
              </div>
            ) : null}
          </div>
          <div className="my-2 text-center">
            <picture>
              <source srcSet={salesPageDesktop} media="(min-width: 1000px)" />
              <img
                className="border border-primary rounded"
                src={salesPageMobile}
                alt="Sales board screenshot"
              />
            </picture>
          </div>
        </div>
      </div>
    </>
  );
}

export default SalesPage;
