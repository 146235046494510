import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import {
  buildUrl,
  executeWithHeaders,
} from "../../../services/remoteDataProvider";
import { SubscriptionCancelReason } from "../enums/subscriptionCancelReason";
import { ISubscriptionPriceChange } from "../models/ISubscriptionPriceChange";
import { ISubscriptionDetails } from "../models/ISubscriptionDetails";
import { PortalPage } from "../enums/portalPage";

export enum SubscriptionChangeType {
  crewAdd = 0,
  crewRemove = 1,
}

const subscriptionDataProvider = {
  createSubscriptionPortalSession: (body: { page?: PortalPage }) => {
    return executeWithHeaders((headers) =>
      ajax.post(buildUrl("Subscription/portalSession"), body, headers)
    ).pipe(map((result) => result.response.customerPortalUrl as string));
  },

  cancelSubscription: (body: {
    reason: SubscriptionCancelReason;
    details: string;
  }) => {
    return executeWithHeaders((headers) =>
      ajax.post(buildUrl(`Subscription/cancel`), body, headers)
    );
  },

  reactivateSubscription: () => {
    return executeWithHeaders((headers) =>
      ajax.post(buildUrl(`Subscription/reactivate`), {}, headers)
    );
  },

  getUpdatedPrice: ({
    subscriptionChangeType,
  }: {
    subscriptionChangeType: SubscriptionChangeType;
  }) => {
    return executeWithHeaders((headers) =>
      ajax
        .get(
          buildUrl(
            `Subscription/UpdatedPricing?changeType=${subscriptionChangeType}`
          ),
          headers
        )
        .pipe(map((result) => result.response as ISubscriptionPriceChange))
    );
  },

  getSubscriptionDetails: () => {
    return executeWithHeaders((headers) =>
      ajax
        .get(buildUrl(`Subscription/SubscriptionDetails`), headers)
        .pipe(map((result) => result.response as ISubscriptionDetails))
    );
  },
};

export default subscriptionDataProvider;
