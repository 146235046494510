import { useState } from "react";
import FormContainerWithoutRedux from "../../../containers/app/components/FormContainerWithoutRedux";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import { IReminderConfiguration } from "../../../models/IReminderConfiguration";
import { Controller, UseFormGetValues, useForm } from "react-hook-form";
import CustomerCommunicationReminderOption from "./CustomerCommunicationReminderOption";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import useSalesEnabled from "../../sales/hooks/useSalesEnabled";
import { useGetReplyToEmailAddress } from "../../../hooks/useGetReplyToEmailAddress";
import { isStringSet } from "../../../services/stringService";
import { useUserSettings } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import { EditorState, convertFromRaw } from "draft-js";
import {
  isTemplateTextSet,
  serializeTextState,
} from "../../../services/richTextService";
import { CustomerReminderType } from "../../accountManagement/enums/customerReminderType";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { CustomerCommunicationSettingsReminderConfiguration } from "./CustomerCommunicationSettingsReminderConfiguration";
import { ErrorMessageType } from "../../../containers/app/components/FormContainer";

interface IProps {
  onSaveComplete: () => void;
  onCancel: () => void;
}

interface IFormData {
  proposalBeforeReminderConfiguration: CustomerCommunicationSettingsReminderConfiguration;
  proposalAfterReminderConfiguration: CustomerCommunicationSettingsReminderConfiguration;
  invoiceBeforeReminderConfiguration: CustomerCommunicationSettingsReminderConfiguration;
  invoiceAfterReminderConfiguration: CustomerCommunicationSettingsReminderConfiguration;
}

const CustomerCommunicationRemindersForm: React.FunctionComponent<IProps> = ({
  onSaveComplete,
  onCancel,
}) => {
  const salesEnabled = useSalesEnabled();
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");

  const replyToEmailAddress = useGetReplyToEmailAddress();
  const tenantName = useApplicationStateSelector((s) => s.common.tenantName);

  const ProposalBeforeReminderConfiguration = useApplicationStateSelector(
    (s) => s.common.proposalBeforeReminderConfiguration
  );
  const ProposalAfterReminderConfiguration = useApplicationStateSelector(
    (s) => s.common.proposalAfterReminderConfiguration
  );
  const InvoiceBeforeReminderConfiguration = useApplicationStateSelector(
    (s) => s.common.invoiceBeforeReminderConfiguration
  );
  const InvoiceAfterReminderConfiguration = useApplicationStateSelector(
    (s) => s.common.invoiceAfterReminderConfiguration
  );

  const { getValues, control } = useForm<IFormData>({
    defaultValues: {
      proposalBeforeReminderConfiguration: getDefaultConfiguration(
        tenantName,
        ProposalBeforeReminderConfiguration,
        CustomerReminderType.ProposalBefore,
        replyToEmailAddress
      ),

      proposalAfterReminderConfiguration: getDefaultConfiguration(
        tenantName,
        ProposalAfterReminderConfiguration,
        CustomerReminderType.ProposalAfter,
        replyToEmailAddress
      ),

      invoiceBeforeReminderConfiguration: getDefaultConfiguration(
        tenantName,
        InvoiceBeforeReminderConfiguration,
        CustomerReminderType.InvoiceBefore,
        replyToEmailAddress
      ),

      invoiceAfterReminderConfiguration: getDefaultConfiguration(
        tenantName,
        InvoiceAfterReminderConfiguration,
        CustomerReminderType.InvoiceAfter,
        replyToEmailAddress
      ),
    },
  });

  const dispatch = useDispatch();

  const { setUserSettings } = useUserSettings();

  return (
    <FormContainerWithoutRedux
      formType={FormTypesV2.customerCommunicationReminders}
      size="lg"
      formHeader="Update Sales & Billing Reminders"
      onSaveComplete={(result) => {
        const emailAddress = result.data.invoiceAfterReminderConfiguration
          .isEnabled
          ? result.data.invoiceAfterReminderConfiguration.replyToEmailAddress
          : result.data.invoiceBeforeReminderConfiguration.isEnabled
          ? result.data.invoiceBeforeReminderConfiguration.replyToEmailAddress
          : result.data.proposalAfterReminderConfiguration.isEnabled
          ? result.data.proposalAfterReminderConfiguration.replyToEmailAddress
          : result.data.proposalBeforeReminderConfiguration.isEnabled
          ? result.data.proposalBeforeReminderConfiguration.replyToEmailAddress
          : replyToEmailAddress;

        setUserSettings(UserSettingsType.replyToEmailAddress, emailAddress);

        dispatch(
          actionCreators.reminderConfigurationUpdated(
            result.data.proposalBeforeReminderConfiguration,
            result.data.proposalAfterReminderConfiguration,
            result.data.invoiceBeforeReminderConfiguration,
            result.data.invoiceAfterReminderConfiguration
          )
        );
        onSaveComplete();
      }}
      onCancel={onCancel}
      save={() => {
        const data = getSavePayload(getValues);
        return remoteDataProvider.saveCustomerReminderConfiguration(
          data.proposalBeforeReminderConfiguration,
          data.proposalAfterReminderConfiguration,
          data.invoiceBeforeReminderConfiguration,
          data.invoiceAfterReminderConfiguration
        );
      }}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      {salesEnabled ? (
        <>
          <Controller
            control={control}
            name="proposalBeforeReminderConfiguration"
            render={({ field }) => (
              <CustomerCommunicationReminderOption
                valuePrefix="proposalBeforeReminderConfiguration"
                optionLabel="Email proposal reminder before expiration date"
                daysLabel="Number of days before expiration date"
                allowInvoiceConfigurationLink={false}
                allowAdditionalReminder={true}
                value={field.value}
                onChange={(newValue) => field.onChange(newValue)}
              />
            )}
          />

          <Controller
            control={control}
            name="proposalAfterReminderConfiguration"
            render={({ field }) => (
              <CustomerCommunicationReminderOption
                valuePrefix="proposalAfterReminderConfiguration"
                optionLabel="Email proposal reminder after expiration date"
                daysLabel="Number of days after expiration date"
                allowInvoiceConfigurationLink={false}
                allowAdditionalReminder={false}
                value={field.value}
                onChange={(newValue) => field.onChange(newValue)}
              />
            )}
          />
        </>
      ) : null}

      <Controller
        control={control}
        name="invoiceBeforeReminderConfiguration"
        render={({ field }) => (
          <CustomerCommunicationReminderOption
            valuePrefix="invoiceBeforeReminderConfiguration"
            optionLabel="Email invoice reminder before due date"
            daysLabel="Number of days before due date"
            allowInvoiceConfigurationLink={true}
            allowAdditionalReminder={true}
            value={field.value}
            onChange={(newValue) => field.onChange(newValue)}
          />
        )}
      />

      <Controller
        control={control}
        name="invoiceAfterReminderConfiguration"
        render={({ field }) => (
          <CustomerCommunicationReminderOption
            valuePrefix="invoiceAfterReminderConfiguration"
            optionLabel="Email invoice reminder after due date"
            daysLabel="Number of days after due date"
            allowInvoiceConfigurationLink={true}
            allowAdditionalReminder={true}
            value={field.value}
            onChange={(newValue) => field.onChange(newValue)}
          />
        )}
      />
    </FormContainerWithoutRedux>
  );
};

export default CustomerCommunicationRemindersForm;

function getDefaultConfiguration(
  tenantName: string | null,
  configuration: IReminderConfiguration,
  reminderType: CustomerReminderType,
  replyToEmailAddress: string | null
) {
  return {
    ...configuration,
    replyToEmailAddress: isStringSet(configuration?.replyToEmailAddress)
      ? configuration?.replyToEmailAddress
      : replyToEmailAddress ?? "",
    subject: isStringSet(configuration?.subject)
      ? configuration.subject
      : getDefaultSubject(tenantName, reminderType),
    messageTextState: EditorState.createWithContent(
      isStringSet(configuration.message) &&
        isTemplateTextSet(configuration.message)
        ? convertFromRaw(JSON.parse(configuration?.message))
        : getDefaultMessage(tenantName, reminderType)
    ),
    firstReminderNumberOfDays: configuration.firstReminderNumberOfDays
      ? configuration.firstReminderNumberOfDays
      : reminderType === CustomerReminderType.InvoiceBefore ||
        reminderType === CustomerReminderType.ProposalBefore
      ? 5
      : 1,
    secondReminderNumberOfDays: configuration.secondReminderNumberOfDays
      ? configuration.secondReminderNumberOfDays
      : reminderType === CustomerReminderType.InvoiceBefore ||
        reminderType === CustomerReminderType.ProposalBefore
      ? 0
      : 7,
  };
}

function getDefaultSubject(
  tenantName: string | null,
  reminderType: CustomerReminderType
) {
  let name = tenantName ?? "";
  switch (reminderType) {
    case CustomerReminderType.ProposalBefore:
      return `Reminder: ${name ?? ""} Proposal`;
    case CustomerReminderType.ProposalAfter:
      return `Reminder: ${name} Proposal Expired`;
    case CustomerReminderType.InvoiceBefore:
      return `Reminder: ${name} Invoice Due`;
    case CustomerReminderType.InvoiceAfter:
      return `Reminder: ${name} Invoice Past Due`;
  }
}

function getDefaultMessage(
  tenantName: string | null,
  reminderType: CustomerReminderType
) {
  let content: any = [];

  switch (reminderType) {
    case CustomerReminderType.ProposalBefore:
      content = [
        {
          key: "bt6nf",
          text: "We are sending a reminder to let you know that we’re waiting to learn of your decision for this proposal.  We're happy to answer any questions you may have.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "4djmp",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "alk0q",
          text: "If you would like to proceed, let us know and we can get you scheduled.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ];
      break;
    case CustomerReminderType.ProposalAfter:
      content = [
        {
          key: "bt6nf",
          text: "We are letting you know that your proposal has expired.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "4djmp",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "alk0q",
          text: "If you decide to move forward on this proposal, please let us know and we can get an updated one sent your way.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ];
      break;
    case CustomerReminderType.InvoiceBefore:
      content = [
        {
          key: "bt6nf",
          text: "We are sending a reminder to let you know that payment for your invoice is due.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "4djmp",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "alk0q",
          text: "Kindly contact us with any questions you might have.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ];
      break;

    case CustomerReminderType.InvoiceAfter:
      content = [
        {
          key: "bt6nf",
          text: "We are sending you a reminder to let you know that your invoice payment is past due.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "4djmp",
          text: "",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
        {
          key: "alk0q",
          text: "Kindly contact us with any questions you might have.",
          type: "unstyled",
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [],
          data: {},
        },
      ];
      break;
  }

  return convertFromRaw({
    blocks: [
      {
        key: "ehlc4",
        text: "Dear <Customer name>,",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "6cjrp",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      ...content,
      {
        key: "dli3p",
        text: "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "e3lqk",
        text: "Thank you,",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
      {
        key: "489b6",
        text: tenantName ?? "",
        type: "unstyled",
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  });
}

function getSavePayload(getValues: UseFormGetValues<IFormData>) {
  const values = getValues();

  return {
    proposalBeforeReminderConfiguration: {
      ...values.proposalBeforeReminderConfiguration,
      message: serializeTextState(
        values.proposalBeforeReminderConfiguration.messageTextState
      ),
    } as IReminderConfiguration,
    proposalAfterReminderConfiguration: {
      ...values.proposalAfterReminderConfiguration,
      message: serializeTextState(
        values.proposalAfterReminderConfiguration.messageTextState
      ),
    } as IReminderConfiguration,
    invoiceBeforeReminderConfiguration: {
      ...values.invoiceBeforeReminderConfiguration,
      message: serializeTextState(
        values.invoiceBeforeReminderConfiguration.messageTextState
      ),
    } as IReminderConfiguration,
    invoiceAfterReminderConfiguration: {
      ...values.invoiceAfterReminderConfiguration,
      message: serializeTextState(
        values.invoiceAfterReminderConfiguration.messageTextState
      ),
    } as IReminderConfiguration,
  };
}
