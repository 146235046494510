import {
  MutableRefObject,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  addTimeBlocks,
  getOffsetFromStart,
  getWindowThresholds,
  isTimeInSlot,
} from "../services/timeBlockCalculator";
import { getOffsetFromElement } from "../services/getOffsetFromElement";
import parse from "date-fns/parse";
import dateService from "../../../services/dateService";

const calendarBlockHeightInRems = 1.4;
const calendarBlockHeight = `${calendarBlockHeightInRems}rem`;
const border = "1px solid #dadce0";

export const emptyCellClass = "calendar-empty-cell";
export const cellFontSize = ".8rem";

export type MinuteWindowOptions = 0 | 15 | 30 | 45;
export const blockSizeInMinutes = 15;
export const initialCalendarBlockHeightInPxs = 22.391;

type ObjWithKeyAndDate = {
  key: string;
  date: string;
};

type GetHeaderContents<TColumn extends ObjWithKeyAndDate> = (args: {
  column: TColumn;
  columnIndex: number;
  isLastColumn: boolean;
}) => JSX.Element;

type GetSpacerContents<TColumn extends ObjWithKeyAndDate> = (args: {
  column: TColumn;
}) => JSX.Element;

type GetTimeSlotContents<TColumn extends ObjWithKeyAndDate> = (args: {
  column: TColumn;
  hour: number;
  minuteWindow: MinuteWindowOptions;
}) => JSX.Element;

type GetIsBlocked<TColumn extends ObjWithKeyAndDate> = (args: {
  column: TColumn;
  hour: number;
  minuteWindow: MinuteWindowOptions;
}) => boolean;

type TimeSlotClicked<TColumn extends ObjWithKeyAndDate> = (args: {
  column: TColumn;
  hour: number;
  minuteWindow: MinuteWindowOptions;
}) => void;

type OnMouseOver<TColumn extends ObjWithKeyAndDate> = (args: {
  column: TColumn;
  hour: number;
  minuteWindow: MinuteWindowOptions;
}) => void;

export function Calendar<TColumn extends ObjWithKeyAndDate>({
  columns,
  hours,
  getHeaderContents,
  getSpacerContents,
  getIsBlocked,
  getTimeSlotContents,
  onTimeSlotClicked,
  calendarBlockHeightInPxs,
  setCalendarBlockHeightInPxs,
  onMouseOver,
}: {
  columns: Array<TColumn>;
  hours: Array<number>;
  getHeaderContents: GetHeaderContents<TColumn>;
  getSpacerContents: GetSpacerContents<TColumn>;
  getTimeSlotContents: GetTimeSlotContents<TColumn>;
  getIsBlocked: GetIsBlocked<TColumn>;
  onTimeSlotClicked: TimeSlotClicked<TColumn>;
  calendarBlockHeightInPxs: number;
  setCalendarBlockHeightInPxs: (newValue: number) => void;
  onMouseOver: OnMouseOver<TColumn>;
}) {
  const timeSlotHeightTestRef = useSetCalendarBlockDimensions({
    setCalendarBlockHeightInPxs,
    columns,
  });

  return (
    <>
      <div
        className="mt-3 mb-5"
        style={{
          display: "grid",
          // First column is time label, second is spacer with line, third is day
          gridTemplateColumns: `50px 8px ${columns
            .map(() => "minmax(100px, 1fr)")
            .join(" ")}`,
          // First row is header, second header is line spacer, followed by time grids
          gridTemplateRows: `min-content minmax(15px, min-content) ${hours
            .map(
              () =>
                `${calendarBlockHeight} ${calendarBlockHeight} ${calendarBlockHeight} ${calendarBlockHeight}`
            )
            .join(" ")}`,
        }}
        data-testid="calendarGrid"
      >
        <HeaderRow columns={columns} getHeaderContents={getHeaderContents} />

        <HeaderLineRow
          columns={columns}
          getSpacerContents={getSpacerContents}
        />

        {hours.map((hour, hourIndex) => (
          <Hour
            key={hour}
            hour={hour}
            columns={columns}
            getIsBlocked={getIsBlocked}
            getTimeSlotContents={getTimeSlotContents}
            onTimeSlotClicked={onTimeSlotClicked}
            timeSlotHeightTestRef={timeSlotHeightTestRef}
            firstHour={hourIndex === 0}
            onMouseOver={onMouseOver}
            calendarBlockHeightInPxs={calendarBlockHeightInPxs}
          />
        ))}
      </div>
    </>
  );
}

function HeaderRow<TColumn extends ObjWithKeyAndDate>({
  columns,
  getHeaderContents,
}: {
  columns: Array<TColumn>;
  getHeaderContents: GetHeaderContents<TColumn>;
}) {
  return (
    <>
      {/* Add sticky, zIndex and bg-white for next 2 divs so hour labels don't appear next to days */}
      <div
        style={{
          position: "sticky",
          top: "0px",
          zIndex: 3,
        }}
        className="bg-white"
      ></div>
      <div
        style={{
          position: "sticky",
          top: "0px",
          zIndex: 3,
        }}
        className="bg-white"
      ></div>
      {columns.map((column, columnIndex) => (
        <div
          style={{
            position: "sticky",
            top: "0px",
            zIndex: 9,
          }}
          className="bg-white"
          data-testid="calendarHeaderContainer"
          key={column.key}
        >
          {getHeaderContents({
            column,
            columnIndex,
            isLastColumn: columnIndex === columns.length - 1,
          })}
        </div>
      ))}
    </>
  );
}

function HeaderLineRow<TColumn extends ObjWithKeyAndDate>({
  columns,
  getSpacerContents,
}: {
  columns: Array<TColumn>;
  getSpacerContents: GetSpacerContents<TColumn>;
}) {
  return (
    <>
      <div></div>
      <div style={{ borderBottom: border }}></div>
      {columns.map((column, cIndex) => (
        <div
          key={column.key}
          style={{
            borderLeft: border,
            borderRight: cIndex === columns.length - 1 ? border : undefined,
            borderBottom: border,
            fontSize: cellFontSize,
          }}
          data-testid="spacerContents"
        >
          {getSpacerContents({ column })}
        </div>
      ))}
    </>
  );
}

function Hour<TColumn extends ObjWithKeyAndDate>({
  hour,
  columns,
  getTimeSlotContents,
  getIsBlocked,
  onTimeSlotClicked,
  timeSlotHeightTestRef,
  firstHour,
  onMouseOver,
  calendarBlockHeightInPxs,
}: {
  hour: number;
  columns: Array<TColumn>;
  getTimeSlotContents: GetTimeSlotContents<TColumn>;
  getIsBlocked: GetIsBlocked<TColumn>;
  onTimeSlotClicked: TimeSlotClicked<TColumn>;
  timeSlotHeightTestRef: RefObject<HTMLDivElement>;
  firstHour: boolean;
  onMouseOver: OnMouseOver<TColumn>;
  calendarBlockHeightInPxs: number;
}) {
  const isColumnDateDifferentThanPrecedingColumn = (columnIndex: number) => {
    if (columnIndex === 0) {
      return true;
    } else {
      return columns[columnIndex - 1].date !== columns[columnIndex].date;
    }
  };

  return (
    <>
      {/* 0-15 minutes */}
      <div
        key={hour}
        className="text-right font-weight-light mr-2"
        style={{ marginTop: "-12px", fontSize: ".9rem" }}
        data-testid="calendarHour"
      >
        {getHourForPresentation(hour)}
      </div>
      <div></div>
      {columns.map((column, cIndex) => (
        <ScheduleTimeSlot
          key={column.key}
          showTopBorder={false}
          showBottomBorder={false}
          lastDay={cIndex === columns.length - 1}
          hour={hour}
          column={column}
          minuteWindow={0}
          getTimeSlotContents={getTimeSlotContents}
          getIsBlocked={getIsBlocked}
          onTimeSlotClicked={onTimeSlotClicked}
          timeSlotHeightTestRef={firstHour ? timeSlotHeightTestRef : undefined}
          onMouseOver={onMouseOver}
          calendarBlockHeightInPxs={calendarBlockHeightInPxs}
          columnDateDifferentThanPrecedingColumn={isColumnDateDifferentThanPrecedingColumn(
            cIndex
          )}
        />
      ))}

      {/* 15-30 minutes */}
      <div></div>
      <div></div>
      {columns.map((column, cIndex) => (
        <ScheduleTimeSlot
          key={column.key}
          showTopBorder={false}
          showBottomBorder={false}
          lastDay={cIndex === columns.length - 1}
          getIsBlocked={getIsBlocked}
          hour={hour}
          column={column}
          minuteWindow={15}
          getTimeSlotContents={getTimeSlotContents}
          onTimeSlotClicked={onTimeSlotClicked}
          timeSlotHeightTestRef={undefined}
          onMouseOver={onMouseOver}
          calendarBlockHeightInPxs={calendarBlockHeightInPxs}
          columnDateDifferentThanPrecedingColumn={isColumnDateDifferentThanPrecedingColumn(
            cIndex
          )}
        />
      ))}

      {/* 30-45 minutes */}
      <div></div>
      <div></div>
      {columns.map((column, cIndex) => (
        <ScheduleTimeSlot
          key={column.key}
          showTopBorder={false}
          showBottomBorder={false}
          lastDay={cIndex === columns.length - 1}
          getIsBlocked={getIsBlocked}
          hour={hour}
          column={column}
          minuteWindow={30}
          getTimeSlotContents={getTimeSlotContents}
          onTimeSlotClicked={onTimeSlotClicked}
          timeSlotHeightTestRef={undefined}
          onMouseOver={onMouseOver}
          calendarBlockHeightInPxs={calendarBlockHeightInPxs}
          columnDateDifferentThanPrecedingColumn={isColumnDateDifferentThanPrecedingColumn(
            cIndex
          )}
        />
      ))}

      {/* 45-59 minutes */}
      <div></div>
      <div
        style={{
          borderBottom: border,
        }}
      ></div>
      {columns.map((column, cIndex) => (
        <ScheduleTimeSlot
          key={column.key}
          showTopBorder={false}
          showBottomBorder={true}
          lastDay={cIndex === columns.length - 1}
          getIsBlocked={getIsBlocked}
          hour={hour}
          column={column}
          minuteWindow={45}
          getTimeSlotContents={getTimeSlotContents}
          onTimeSlotClicked={onTimeSlotClicked}
          timeSlotHeightTestRef={undefined}
          onMouseOver={onMouseOver}
          calendarBlockHeightInPxs={calendarBlockHeightInPxs}
          columnDateDifferentThanPrecedingColumn={isColumnDateDifferentThanPrecedingColumn(
            cIndex
          )}
        />
      ))}
    </>
  );
}

function ScheduleTimeSlot<TColumn extends ObjWithKeyAndDate>({
  showTopBorder,
  showBottomBorder,
  lastDay,
  column,
  getIsBlocked,
  hour,
  minuteWindow,
  getTimeSlotContents,
  onTimeSlotClicked,
  timeSlotHeightTestRef,
  onMouseOver,
  calendarBlockHeightInPxs,
  columnDateDifferentThanPrecedingColumn,
}: {
  showTopBorder: boolean;
  showBottomBorder: boolean;
  lastDay: boolean;
  getIsBlocked: GetIsBlocked<TColumn>;
  column: TColumn;
  hour: number;
  minuteWindow: MinuteWindowOptions;
  getTimeSlotContents: GetTimeSlotContents<TColumn>;
  onTimeSlotClicked: TimeSlotClicked<TColumn>;
  timeSlotHeightTestRef: MutableRefObject<HTMLDivElement | null> | undefined;
  onMouseOver: OnMouseOver<TColumn>;
  calendarBlockHeightInPxs: number;
  columnDateDifferentThanPrecedingColumn: boolean;
}) {
  const isBlocked = getIsBlocked({ column, hour, minuteWindow });
  const divRef = useRef<HTMLDivElement | null>();

  const { isCurrentTimeOffsetInSlot, currentTimeOffset } =
    useCalculateCurrentTimeOffset<TColumn>({
      column,
      hour,
      minuteWindow,
      calendarBlockHeightInPxs,
    });

  return (
    <div
      className={isBlocked ? "bg-secondary" : "bg-light"}
      style={{
        borderLeft: border,
        borderBottom: showBottomBorder ? border : undefined,
        borderRight: lastDay ? border : undefined,
        borderTop: showTopBorder ? border : undefined,
        fontSize: cellFontSize,
        textOverflow: "clip",
      }}
      ref={(r) => {
        divRef.current = r;
        if (timeSlotHeightTestRef) {
          timeSlotHeightTestRef.current = r;
        }
      }}
      onClick={(e) => {
        const isCurrentElement = e.target === divRef.current;

        let hasEmptyCellClass = false;
        if (e.target) {
          const target = e.target as any;
          if (
            typeof target.classList === "object" &&
            typeof target.classList.contains === "function"
          ) {
            hasEmptyCellClass = target.classList.contains(emptyCellClass);
          }
        }

        if (isCurrentElement || hasEmptyCellClass) {
          onTimeSlotClicked({
            column,
            hour,
            minuteWindow,
          });
        }
      }}
      data-testid="scheduleTimeSlot"
      onMouseMove={(e) => {
        if (divRef.current) {
          // Blocks from start is necessary in case mouse is over a job card that spans multiple blocks.
          // In that case, the mouse move is over the job's first time slot but in reality, the user's
          // mouse is over a lower time slot.
          let blocksFromStart: number = 0;
          const offsetY = getOffsetFromElement(e);
          if (offsetY >= 0) {
            blocksFromStart = Math.floor(offsetY / calendarBlockHeightInPxs);
          }

          const { hour: newHour, minute: newMinuteWindow } = addTimeBlocks({
            hour,
            minute: minuteWindow,
            blockOffset: blocksFromStart,
          });

          onMouseOver({
            column,
            hour: newHour,
            minuteWindow: newMinuteWindow as MinuteWindowOptions,
          });
        }
      }}
    >
      {isCurrentTimeOffsetInSlot ? (
        <CurrentTimeIndicator
          currentTimeOffset={currentTimeOffset}
          showCirclePrefix={columnDateDifferentThanPrecedingColumn}
        />
      ) : null}

      {getTimeSlotContents({
        column,
        hour,
        minuteWindow,
      })}
    </div>
  );
}

function CurrentTimeIndicator({
  currentTimeOffset,
  showCirclePrefix,
}: {
  currentTimeOffset: number;
  showCirclePrefix: boolean;
}) {
  return (
    <div
      // emptyCellClass is needed so the event handler that triggers adding a job knows
      // this element is eligible
      className={`border-top border-danger ${emptyCellClass}`}
      style={{
        position: "relative",
        top: `${currentTimeOffset}px`,
        zIndex: 2,
        height: 0,
      }}
      data-testid="currentTimeIndicatorLine"
    >
      {showCirclePrefix ? (
        <div
          // emptyCellClass is needed so the event handler that triggers adding a job knows
          // this element is eligible
          className={`bg-danger ${emptyCellClass}`}
          style={{
            borderRadius: "50%",
            width: "12px",
            height: "12px",
            marginLeft: "-6px",
            marginTop: "-6px",
          }}
          data-testid="currentTimeIndicatorLinePrefix"
        ></div>
      ) : null}
    </div>
  );
}

function useCalculateCurrentTimeOffset<TColumn extends ObjWithKeyAndDate>({
  column,
  hour,
  minuteWindow,
  calendarBlockHeightInPxs,
}: {
  column: TColumn;
  hour: number;
  minuteWindow: number;
  calendarBlockHeightInPxs: number;
}):
  | { currentTimeOffset: number; isCurrentTimeOffsetInSlot: true }
  | {
      currentTimeOffset: null;
      isCurrentTimeOffsetInSlot: false;
    } {
  const [currentTimeOffset, setCurrentTimeOffset] = useState<number | null>(
    null
  );

  useEffect(() => {
    function calculateAndSetOffset() {
      const { startThreshold, endThreshold } = getWindowThresholds(
        parse(column.date),
        hour,
        minuteWindow
      );

      const currentDate = dateService.getCurrentDate();
      if (
        isTimeInSlot({
          startThreshold,
          endThreshold,
          dateTimeToCheck: currentDate,
        })
      ) {
        const offset = getOffsetFromStart({
          timeSlotStartTime: startThreshold,
          referenceTime: currentDate,
          calendarBlockHeightInPxs,
        });

        setCurrentTimeOffset(offset ?? 0);
      } else {
        setCurrentTimeOffset(null);
      }
    }

    calculateAndSetOffset();

    // Recalculate indicator every 5 minutes so if user stays on page, the
    // current time indicator will move
    const intervalId = setInterval(() => {
      calculateAndSetOffset();
    }, 1000 * 60 * 5);

    return function cleanup() {
      clearInterval(intervalId);
    };
  }, [calendarBlockHeightInPxs, column.date, hour, minuteWindow]);

  if (typeof currentTimeOffset === "number") {
    return {
      currentTimeOffset,
      isCurrentTimeOffsetInSlot: true,
    };
  } else {
    return {
      currentTimeOffset,
      isCurrentTimeOffsetInSlot: false,
    };
  }
}

function useSetCalendarBlockDimensions<TColumn>({
  setCalendarBlockHeightInPxs,
  columns,
}: {
  setCalendarBlockHeightInPxs: (newValue: number) => void;
  columns: Array<TColumn>;
}) {
  const timeSlotHeightTestRef = useRef<HTMLDivElement>(null);
  const lastHeight = useRef<number | null>(null);

  useEffect(() => {
    if (timeSlotHeightTestRef.current) {
      const computedStyle = getComputedStyle(timeSlotHeightTestRef.current);

      const parsedHeight = parseFloat(computedStyle.height);
      if (!isNaN(parsedHeight)) {
        setCalendarBlockHeightInPxs(parsedHeight);
      }

      const observer = new ResizeObserver((entries) => {
        if (entries.length === 1) {
          const entry = entries[0];
          if (entry.contentRect) {
            if (
              entry.contentRect.height > 0 &&
              lastHeight.current !== entry.contentRect.height
            ) {
              setCalendarBlockHeightInPxs(entry.contentRect.height);
              lastHeight.current = entry.contentRect.height;
            }
          }
        } else {
          console.error("unexpected entries count");
        }
      });

      observer.observe(timeSlotHeightTestRef.current);

      return function cleanup() {
        observer.disconnect();
      };
    }
  }, [setCalendarBlockHeightInPxs]);

  return timeSlotHeightTestRef;
}

function getHourForPresentation(h: number): string {
  if (h === 0) {
    return "12am";
  } else if (h < 12) {
    return `${h}am`;
  } else if (h === 12) {
    return "12pm";
  } else {
    return `${h - 12}pm`;
  }
}
