import { ITime, Period } from "../../../models/ITime";

interface IProps {
  disabled: boolean;
  value: ITime;
  onChange(newValue: ITime): void;
}

const TimeInputQuarterHour: React.FunctionComponent<IProps> = ({
  disabled,
  value,
  onChange,
}) => {
  return (
    <>
      <label>Time:</label>
      <div className="form-row">
        <div className="col-4">
          <select
            aria-label="Hour"
            className="form-control"
            value={value.hour.toString()}
            onChange={(e) =>
              onChange({
                ...value,
                hour: parseInt(e.currentTarget.value),
              })
            }
            disabled={disabled}
          >
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
            <option>10</option>
            <option>11</option>
            <option>12</option>
          </select>
        </div>
        <div className="col-4">
          <select
            aria-label="Minute"
            className="form-control"
            value={value.minute.toString()}
            onChange={(e) =>
              onChange({
                ...value,
                minute: parseInt(e.currentTarget.value),
              })
            }
            disabled={disabled}
          >
            <option>00</option>
            <option>15</option>
            <option>30</option>
            <option>45</option>
          </select>
        </div>
        <div className="col-4">
          <select
            aria-label="AM/PM"
            className="form-control"
            value={value.period}
            onChange={(e) =>
              onChange({
                ...value,
                period: e.currentTarget.value as Period,
              })
            }
            disabled={disabled}
          >
            <option>AM</option>
            <option>PM</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default TimeInputQuarterHour;
