import differenceInSeconds from "date-fns/difference_in_seconds";
import { IDistance } from "../../../../../models/IDistance";
import { IMaintenanceJob } from "../../../../../models/IMaintenanceJob";
import { IJobInstance } from "../../../../../models/IJobInstance";
import { ICrew } from "../../../../../models/ICrew";
import { areDistancesEqual } from "../../../../../services/distanceService";
import jobFinder from "../../../../../services/jobFinder";
import { IOneTimeJob } from "../../../../../models/IOneTimeJob";
import { ICustomer } from "../../../../../models/ICustomer";
import { ICustomerAdditionalLocation } from "../../../../../models/ICustomerAdditionalLocation";
import addressFormatter from "../../../../../services/addressFormatter";
import { logError } from "../../../../../services/errorLogger";

const distancesStuckInPending: Array<IDistance> = [];

export interface IGetDistanceResult {
  distanceInSeconds: number;
  errorLoadingDistance: boolean;
}

function getDistanceInSeconds(
  distances: Array<IDistance>,
  distanceToFind: IDistance
): IGetDistanceResult | undefined {
  const distance = distances.find((d) => areDistancesEqual(d, distanceToFind));

  // TODO: Log if not found at all
  if (distance && distance.loading) {
    let secondsSinceStartedLoading = 0;
    if (distance.startLoadingTime) {
      secondsSinceStartedLoading = differenceInSeconds(
        new Date(),
        distance.startLoadingTime
      );
    }

    if (secondsSinceStartedLoading > 3) {
      // TODO: Add array functions.  "any()", "flatten", others?
      const matchingDistanceThatRaisedStuckInPendingError = distancesStuckInPending.find(
        (d) => areDistancesEqual(distanceToFind, d)
      );
      if (!matchingDistanceThatRaisedStuckInPendingError) {
        if (distancesStuckInPending.length === 0) {
          logError("distance stuck loading");
        }
        console.error(
          "distance never finished loading - " + JSON.stringify(distance)
        );
        distancesStuckInPending.push(distanceToFind);
      }
    }
  }

  if (distance && !distance.loading) {
    return {
      distanceInSeconds: distance.distanceInSeconds as number,
      errorLoadingDistance: distance.errorLoadingDistance || false,
    };
  } else {
    return undefined;
  }
}

function getDistanceBetweenJobInstances(
  jobs: Array<IMaintenanceJob>,
  oneTimeJobs: Array<IOneTimeJob>,
  distances: Array<IDistance>,
  jobInstanceSource: IJobInstance,
  jobInstanceDestination: IJobInstance,
  customers: Array<ICustomer>,
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>
) {
  const jobSource = jobFinder.getJobForDayScheduleV2(
    jobs,
    oneTimeJobs,
    jobInstanceSource
  );
  const jobDestination = jobFinder.getJobForDayScheduleV2(
    jobs,
    oneTimeJobs,
    jobInstanceDestination
  );

  if (jobSource === null || jobDestination === null) {
    return null;
  }

  const customerSource = addressFormatter.getAddressForJob(
    jobSource,
    customers,
    customerAdditionalLocations
  );
  const customerDestination = addressFormatter.getAddressForJob(
    jobDestination,
    customers,
    customerAdditionalLocations
  );

  if (
    customerSource.latitude === null ||
    customerSource.longitude === null ||
    customerDestination.latitude === null ||
    customerDestination.longitude === null
  ) {
    return null;
  }

  // TODO: Correct
  return getDistanceInSeconds(distances, {
    sourceLatitude: customerSource.latitude,
    sourceLongitude: customerSource.longitude,
    destinationLatitude: customerDestination.latitude,
    destinationLongitude: customerDestination.longitude,
  });
}

function getDistanceFromCrewToJob(
  jobs: Array<IMaintenanceJob>,
  oneTimeJobs: Array<IOneTimeJob>,
  distances: Array<IDistance>,
  crewSource: ICrew,
  jobInstanceDestination: IJobInstance,
  customers: Array<ICustomer>,
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>
) {
  const jobDestination = jobFinder.getJobForDayScheduleV2(
    jobs,
    oneTimeJobs,
    jobInstanceDestination
  );

  if (jobDestination === null) {
    return null;
  }

  const customerDestination = addressFormatter.getAddressForJob(
    jobDestination,
    customers,
    customerAdditionalLocations
  );

  if (
    customerDestination.latitude === null ||
    customerDestination.longitude === null
  ) {
    return null;
  }

  return getDistanceInSeconds(distances, {
    sourceLatitude: crewSource.latitude,
    sourceLongitude: crewSource.longitude,
    destinationLatitude: customerDestination.latitude,
    destinationLongitude: customerDestination.longitude,
  });
}

function getDistanceFromJobToCrew(
  jobs: Array<IMaintenanceJob>,
  oneTimeJobs: Array<IOneTimeJob>,
  distances: Array<IDistance>,
  jobInstanceSource: IJobInstance,
  crewDestination: ICrew,
  customers: Array<ICustomer>,
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>
) {
  const jobSource = jobFinder.getJobForDayScheduleV2(
    jobs,
    oneTimeJobs,
    jobInstanceSource
  );

  if (jobSource === null) {
    return null;
  }
  const customerSource = addressFormatter.getAddressForJob(
    jobSource,
    customers,
    customerAdditionalLocations
  );

  if (customerSource.latitude === null || customerSource.longitude === null) {
    return null;
  }

  return getDistanceInSeconds(distances, {
    sourceLatitude: customerSource.latitude,
    sourceLongitude: customerSource.longitude,
    destinationLongitude: crewDestination.longitude,
    destinationLatitude: crewDestination.latitude,
  });
}

export {
  areDistancesEqual,
  getDistanceBetweenJobInstances,
  getDistanceFromCrewToJob,
  getDistanceFromJobToCrew,
};
