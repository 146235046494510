import React, { Fragment } from "react";
import { connect } from "react-redux";
import format from "date-fns/format";
import { actionCreators } from "../../../../../modules/actionCreators";
import { getDaysToLoad } from "../../../../../services/dayScheduleLoader";
import { IRootState } from "../../../../../store";
import { IDaySchedule } from "../../../../../models/IDaySchedule";
import { ICrew } from "../../../../../models/ICrew";
import { IMaintenanceJob } from "../../../../../models/IMaintenanceJob";
import dateService from "../../../../../services/dateService";
import Spinner from "../../../components/Spinner";
import PageWithNavBar2 from "../../../PageWithNavBar2";
import DayPicker, {
  format as dayPickerFormat,
} from "../../../components/DayPicker";
import { ICustomCrewQuestion } from "../../../../../models/ICustomCrewQuestion";
import {
  getDistinctSortedCrewCategories,
  doesCrewHaveCategory,
} from "../../../../../services/crewCategoryService";
import { IOneTimeJob } from "../../../../../models/IOneTimeJob";
import { ITodoTemplate } from "../../../../../models/ITodoTemplate";
import { availableFields, customCrewQuestionId } from "./availableFields";
import { isFieldShown } from "./isFieldShown";
import Job from "./Job";
import {
  getSortedCrews,
  getSortedItemsV2,
} from "../../../../../services/sortingService";
import { RouteComponentProps } from "react-router";
import { isMobileOnly } from "react-device-detect";
import { ICrewMember } from "../../../../../models/ICrewMember";
import constants from "../../../../../constants";
import jobFinder from "../../../../../services/jobFinder";
import { IUnscheduledMaintenanceJob } from "../../../../../models/IUnscheduledMaintenanceJob";
import { push, RouterAction } from "connected-react-router";
import { Path } from "history";
import { builders } from "../../../../../services/routing";
import DayHeader from "./DayHeader";
import { ICrewCategory } from "../../../../../models/ICrewCategory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

const savedCategoryFiltersKey = "DailyReportCrewCategoryFilters2";

interface IProps extends RouteComponentProps<IRouteParams> {
  daySchedules: Array<IDaySchedule>;
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>;
  jobs: Array<IMaintenanceJob>;
  oneTimeJobs: Array<IOneTimeJob>;
  crews: Array<ICrew>;
  crewMembers: Array<ICrewMember>;
  customCrewQuestions: Array<ICustomCrewQuestion>;
  todoTemplates: Array<ITodoTemplate>;
  loadDaySchedulesStarting(
    daySchedulesToLoad: any,
    weeksUnscheduledMaintenanceJobsToLoad: Array<Date>,
    maxUnassignedWeekAlreadyLoaded: string | null
  ): any;
  redirect(path: Path): RouterAction;
  crewCategories: Array<ICrewCategory>;
}

interface IRouteParams {
  crewId?: string;
  date?: string;
}

interface IState {
  includeSkippedJobs: boolean;
  fieldsToShow: Array<availableFields | customCrewQuestionId>;
  crewCategoryFilters: Array<string>;
  lightBoxJobInstanceId: string | null;
  showOptions: boolean;

  showOnlyJobsWithChecklists: boolean;
}

class DailyReport extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let fieldsToShow: Array<availableFields | customCrewQuestionId>;
    const savedFieldsToShow = window.localStorage["DailyReportFields"];
    if (savedFieldsToShow) {
      fieldsToShow = JSON.parse(savedFieldsToShow) as Array<
        availableFields | customCrewQuestionId
      >;
    } else {
      fieldsToShow = [
        availableFields.complete,
        availableFields.estimatedManHours,
        availableFields.actualManHours,
        availableFields.timeRanges,
        availableFields.actualTimeWorked,
        availableFields.numberOfCrewMembers,
        availableFields.notesFromCrew,
        availableFields.photosFromCrew,
        availableFields.todo,
        availableFields.notesFromAdmin,
      ];
    }

    const savedCrewCategoryyFilters =
      window.localStorage[savedCategoryFiltersKey];
    let crewCategoryFilters: Array<string> = [];
    if (savedCrewCategoryyFilters) {
      crewCategoryFilters = JSON.parse(
        savedCrewCategoryyFilters
      ) as Array<string>;
    }

    this.state = {
      includeSkippedJobs: false,
      fieldsToShow,
      crewCategoryFilters,
      lightBoxJobInstanceId: null,
      showOptions: !isMobileOnly,
      showOnlyJobsWithChecklists: false,
    };
  }

  onFieldToShowChanged(
    fieldKey: availableFields | customCrewQuestionId,
    shown: boolean
  ) {
    let newValue: Array<availableFields | customCrewQuestionId>;
    if (shown) {
      newValue = [...this.state.fieldsToShow, fieldKey];
    } else {
      newValue = this.state.fieldsToShow.filter((f) => f !== fieldKey);
    }

    this.setState({
      fieldsToShow: newValue,
    });

    window.localStorage["DailyReportFields"] = JSON.stringify(newValue);
  }

  onCrewCategoryFilterChanged(crewCategory: string, shown: boolean) {
    let newValue: Array<string>;
    if (shown) {
      newValue = [...this.state.crewCategoryFilters, crewCategory];
    } else {
      newValue = this.state.crewCategoryFilters.filter(
        (f) => f !== crewCategory
      );
    }

    const sortedAndFilteredCrews = getSortedCrews(this.props.crews).filter(
      this.doesCrewPassCategoryFilter(newValue)
    );
    const crewId =
      sortedAndFilteredCrews.length > 0
        ? sortedAndFilteredCrews[0].id
        : this.props.crews[0].id;
    this.props.redirect(
      builders.report.buildDailyRoute(this.getDate(), crewId)
    );

    this.setState({
      crewCategoryFilters: newValue,
    });

    window.localStorage[savedCategoryFiltersKey] = JSON.stringify(newValue);
  }

  componentDidMount() {
    this.ensureDaySchedulesLoaded();
  }

  componentDidUpdate(prevProps: IProps) {
    this.ensureDaySchedulesLoaded();
  }

  ensureDaySchedulesLoaded() {
    const { daySchedules, loadDaySchedulesStarting } = this.props;

    let crewIds: Array<string> = this.getCrewIdsToShow();

    const scheduleDate = this.getDate();
    const missingDates = getDaysToLoad({
      loadedDaySchedules: daySchedules,
      crewIds,
      dates: [scheduleDate],
    });

    if (missingDates.length > 0) {
      loadDaySchedulesStarting(missingDates, [], null);
    }
  }

  render() {
    const { daySchedules, crews } = this.props;

    const crewIdsToShow = this.getCrewIdsToShow();
    const date = this.getDate();

    const daySchedulesToShow = daySchedules.filter(
      (ds) => crewIdsToShow.includes(ds.crewId) && ds.date === date
    );
    const areSchedulesLoading = daySchedulesToShow.some(
      (ds) => ds.initialLoadRunning
    );

    const combinedJobInstances = getSortedItemsV2(
      daySchedulesToShow.map((daySchedule) => {
        const crew = crews.find((c) => c.id === daySchedule.crewId) as ICrew;
        return {
          jobInstances: getSortedItemsV2(daySchedule.jobInstances, ["order"]),
          crewName: crew.name,
        };
      }),
      ["crewName"]
    ).flatMap((d) => d.jobInstances);

    const sortedCrews = getSortedCrews(crews)
      .filter((c) => !c.inactive)
      .filter(this.doesCrewPassCategoryFilter(this.state.crewCategoryFilters));

    const fieldsToChoose: Array<{
      label: string;
      key: availableFields | customCrewQuestionId;
    }> = [
      { label: "Crew", key: availableFields.crew },
      { label: "Complete", key: availableFields.complete },
      { label: "Estimated man hours", key: availableFields.estimatedManHours },
      { label: "Actual man hours", key: availableFields.actualManHours },
      {
        label: "Estimated time worked",
        key: availableFields.estimatedTimeWorked,
      },
      { label: "Actual time worked", key: availableFields.actualTimeWorked },
      { label: "Time ranges worked", key: availableFields.timeRanges },
      {
        label: "Number of crew members",
        key: availableFields.numberOfCrewMembers,
      },
      { label: "Notes from crew", key: availableFields.notesFromCrew },
      {
        label: "Notes from administrator",
        key: availableFields.notesFromAdmin,
      },
      {
        label: "Administrator only notes",
        key: availableFields.adminOnlyNotes,
      },
      { label: "Files from crew", key: availableFields.photosFromCrew },
      { label: "Checklist", key: availableFields.todo },
      { label: "PO Number", key: availableFields.purchaseOrderNumber },
      { label: "Invoice details", key: availableFields.invoiceNumber },
      { label: "Invoice sent", key: availableFields.invoiceSent },
      { label: "Amount", key: availableFields.grossRevenuePerVisit },
      {
        label: "Job line items",
        key: availableFields.lineItems,
      },
      {
        label: "Custom crew questions",
        key: availableFields.customCrewQuestions,
      },
    ];

    const crewCategories = getDistinctSortedCrewCategories(
      crews.filter((c) => !c.inactive),
      this.props.crewCategories
    );

    const maxDateForSchedule = dateService.getMaximumDateForSchedule();
    return !!daySchedulesToShow ? (
      <PageWithNavBar2 billingContext={true} notFluid={true}>
        <div className="row" style={{ marginTop: "10px" }}>
          <div className="col-12 col-sm-3 d-print-none">
            {isMobileOnly ? (
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() =>
                    this.setState({
                      showOptions: !this.state.showOptions,
                    })
                  }
                >
                  {this.state.showOptions ? "Hide Options" : "Show Options"}
                </button>
              </div>
            ) : null}
            {this.state.showOptions ? (
              <Fragment>
                {crewCategories.length > 0 ? (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Filter Crews By Categories</th>
                      </tr>
                    </thead>
                    <tbody>
                      {crewCategories.map((crewCategory) => (
                        <tr key={crewCategory.id}>
                          <td>
                            <label style={{ marginBottom: "0" }}>
                              <input
                                type="checkbox"
                                style={{ marginRight: "10px" }}
                                checked={this.state.crewCategoryFilters.includes(
                                  crewCategory.id
                                )}
                                onChange={(e) =>
                                  this.onCrewCategoryFilterChanged(
                                    crewCategory.id,
                                    e.currentTarget.checked
                                  )
                                }
                              />
                              {crewCategory.name}
                            </label>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : null}

                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Filter Jobs</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <label style={{ marginBottom: "0" }}>
                          <input
                            type="checkbox"
                            style={{ marginRight: "10px" }}
                            checked={this.state.showOnlyJobsWithChecklists}
                            onChange={(e) =>
                              this.setState({
                                showOnlyJobsWithChecklists:
                                  e.currentTarget.checked,
                              })
                            }
                          />
                          Only jobs with checklists
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label style={{ marginBottom: "0" }}>
                          <input
                            type="checkbox"
                            style={{ marginRight: "10px" }}
                            checked={this.state.includeSkippedJobs}
                            onChange={(e) =>
                              this.setState({
                                includeSkippedJobs: e.currentTarget.checked,
                              })
                            }
                          />
                          Include skipped jobs
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Fields To Show</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fieldsToChoose.map((f) => (
                      <tr key={f.key}>
                        <td>
                          <label style={{ marginBottom: "0" }}>
                            <input
                              type="checkbox"
                              style={{ marginRight: "10px" }}
                              checked={isFieldShown(
                                f.key,
                                this.state.fieldsToShow
                              )}
                              onChange={(e) =>
                                this.onFieldToShowChanged(
                                  f.key,
                                  e.currentTarget.checked
                                )
                              }
                            />
                            {f.label}
                          </label>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Fragment>
            ) : null}
          </div>

          <div className="col-12 col-sm-9">
            <div className="d-flex justify-content-between align-items-baseline">
              <h1>Daily Details</h1>
              <button
                className="btn btn-link d-print-none"
                type="button"
                onClick={() => window.print()}
              >
                <FontAwesomeIcon
                  icon={faPrint}
                  size="2x"
                  className="text-dark"
                  style={{ verticalAlign: "middle" }}
                  title="Print"
                />
              </button>
            </div>
            <div>
              <form>
                <div className="form-row">
                  <div className="form-group col-auto">
                    <label htmlFor="crewId">Crew</label>
                    <select
                      id="crewId"
                      onChange={(e) => {
                        this.props.redirect(
                          builders.report.buildDailyRoute(
                            this.getDate(),
                            e.currentTarget.value
                          )
                        );
                      }}
                      defaultValue={this.getCrewId()}
                      className="form-control"
                    >
                      {sortedCrews.length > 1 ? (
                        <option value={constants.allCrewsConstant}>
                          All Crews
                        </option>
                      ) : null}
                      {sortedCrews.map((c) => {
                        return (
                          <option value={c.id} key={c.id}>
                            {c.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group col-auto">
                    <label htmlFor="date">Date</label>
                    <div>
                      <DayPicker
                        onDayPickerHide={() => null}
                        dayPickerProps={{}}
                        value={!!date ? format(date, dayPickerFormat) : ""}
                        required={true}
                        inputId="date"
                        maxDate={maxDateForSchedule}
                        onDaySelected={(day: Date) => {
                          let newValue = "";

                          if (
                            !!day &&
                            dateService.removeTimeComponents(day) <=
                              dateService.removeTimeComponents(
                                maxDateForSchedule
                              )
                          ) {
                            newValue = dateService.formatAsIso(day);

                            this.props.redirect(
                              builders.report.buildDailyRoute(
                                newValue,
                                this.getCrewId()
                              )
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {areSchedulesLoading ? (
              <Spinner />
            ) : (
              <>
                {sortedCrews.map((crew) => {
                  const dayScheduleToShow = daySchedulesToShow.find(
                    (daySchedule) => crew.id === daySchedule.crewId
                  );
                  return (
                    <React.Fragment key={crew.id}>
                      {dayScheduleToShow ? (
                        <React.Fragment>
                          <DayHeader
                            dayScheduleToShow={dayScheduleToShow}
                            crewName={crew.name}
                          />
                          {dayScheduleToShow.jobInstances
                            .filter((ji) => {
                              if (!this.state.showOnlyJobsWithChecklists) {
                                return true;
                              }

                              if (ji.todoItems.length > 0) {
                                return true;
                              }

                              const jobFindResult =
                                jobFinder.getJobForDayScheduleV3(
                                  ji.id,
                                  this.props.jobs,
                                  this.props.oneTimeJobs,
                                  this.props.daySchedules,
                                  this.props.weeksUnscheduledMaintenanceJobs
                                );
                              if (
                                jobFindResult &&
                                jobFindResult.todoItems.length > 0
                              ) {
                                return true;
                              }

                              return false;
                            })
                            .filter(
                              (ji) =>
                                !ji.skipped || this.state.includeSkippedJobs
                            )
                            .map((jobInstance) => {
                              return (
                                <Job
                                  crew={crew}
                                  date={this.getDate()}
                                  fieldsToShow={this.state.fieldsToShow}
                                  jobInstance={jobInstance}
                                  key={jobInstance.id}
                                  isLightboxOpen={
                                    this.state.lightBoxJobInstanceId ===
                                    jobInstance.id
                                  }
                                  onOpenLightbox={() =>
                                    this.setState({
                                      lightBoxJobInstanceId: jobInstance.id,
                                    })
                                  }
                                  onCloseLightbox={() =>
                                    this.setState({
                                      lightBoxJobInstanceId: null,
                                    })
                                  }
                                />
                              );
                            })}
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  );
                })}
                {combinedJobInstances.length === 0 ? (
                  <div className="jumbotron" style={{ textAlign: "center" }}>
                    <h5>No jobs were scheduled for this day</h5>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
      </PageWithNavBar2>
    ) : (
      <Spinner />
    );
  }

  private doesCrewPassCategoryFilter(
    crewCategoryFilters: Array<string>
  ): (value: ICrew) => boolean {
    return (crew) => {
      if (!crewCategoryFilters || crewCategoryFilters.length === 0) {
        return true;
      }

      return doesCrewHaveCategory(crew, crewCategoryFilters);
    };
  }

  private getCrewId() {
    let sortedAndFilteredCrews = this.getSortedAndFilteredCrews();

    // No crews match the criteria anymore so old filter is invalid.  Clear it.
    if (sortedAndFilteredCrews.length === 0) {
      sortedAndFilteredCrews = sortedAndFilteredCrews = getSortedCrews(
        this.props.crews
      ).filter((c) => !c.inactive);
    }

    const paramCrewId = this.props.match.params.crewId;
    if (
      paramCrewId &&
      (sortedAndFilteredCrews.some((c) => c.id === paramCrewId) ||
        paramCrewId === constants.allCrewsConstant)
    ) {
      return paramCrewId;
    }

    if (sortedAndFilteredCrews.length > 0) {
      return sortedAndFilteredCrews[0].id;
    }

    return this.props.crews[0].id;
  }

  private getSortedAndFilteredCrews() {
    let { crewCategoryFilters } = this.state;

    return getSortedCrews(this.props.crews)
      .filter((c) => !c.inactive)
      .filter(this.doesCrewPassCategoryFilter(crewCategoryFilters));
  }

  private getDate() {
    if (this.props.match.params.date) {
      return this.props.match.params.date;
    } else {
      return dateService.formatAsIso(new Date());
    }
  }

  private getCrewIdsToShow() {
    const selectedCrewId = this.getCrewId();
    let crewIds: Array<string> = [];
    if (selectedCrewId === constants.allCrewsConstant) {
      crewIds = this.getSortedAndFilteredCrews().map((c) => c.id);
    } else {
      crewIds = [selectedCrewId];
    }
    return crewIds;
  }
}

const mapStateToProps = (state: IRootState) => ({
  daySchedules: state.schedule.daySchedules,
  weeksUnscheduledMaintenanceJobs:
    state.schedule.weeksUnscheduledMaintenanceJobs,
  crews: state.crew.crews,
  crewMembers: state.crew.crewMembers,
  customCrewQuestions: state.common.crewViewConfiguration.customCrewQuestions,
  jobs: state.job.jobs,
  oneTimeJobs: state.job.oneTimeJobs,
  crewCategories: state.common.crewCategories,
});

const mapDispatchToProps = {
  loadDaySchedulesStarting: actionCreators.loadDaySchedulesStarting,
  redirect: push,
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyReport);
