import React, { CSSProperties } from "react";
import { getNoteTokens, NoteToken } from "./NoteWithHyperlinks.functions";

type Props = {
  testId?: string;
  containerClassName?: string;
  containerStyle?: Partial<CSSProperties>;
  linkClassName?: string;
  linkStyle?: Partial<CSSProperties>;
} & ({ notes: string } | { noteTokens: Array<NoteToken> });

export function NoteWithHyperlinks(props: Props) {
  const {
    containerClassName,
    containerStyle,
    testId,
    linkStyle,
    linkClassName,
  } = props;

  let noteTokens: Array<NoteToken>;
  if ("noteTokens" in props) {
    noteTokens = props.noteTokens;
  } else {
    noteTokens = getNoteTokens(props.notes);
  }

  const elements = noteTokens.map((token) => {
    if ("link" in token) {
      return (
        <React.Fragment key={token.id}>
          <a
            href={token.link}
            target="_blank"
            rel="noopener noreferrer"
            className={linkClassName}
            style={linkStyle}
            onClick={(e) => {
              // Prevent the card from being selected
              e.stopPropagation();
            }}
          >
            {token.contents}
          </a>
          <span>{token.trailingWhitespace}</span>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment key={token.id}>
          <span>{token.contents}</span>
        </React.Fragment>
      );
    }
  });

  return (
    <div
      style={{ whiteSpace: "pre-wrap", ...(containerStyle ?? {}) }}
      className={containerClassName}
      data-testid={testId}
    >
      {elements}
    </div>
  );
}
