import { useState } from "react";
import { finalize, timeout } from "rxjs/operators";
import { downloadBlob } from "../../../../services/csvDownloadService";
import { getErrorMessageFromError } from "../../../../services/httpErrorHandler";
import remoteDataProvider from "../../../../services/remoteDataProvider";
import ExportButton from "../../components/ExportButton";
import Spinner from "../../components/Spinner";

export default function CustomersV2Export({
  setExportErrorMessage,
  searchText,
  includeInactive,
}: {
  searchText: string;
  includeInactive: boolean;
  setExportErrorMessage(errorMessage: string): void;
}) {
  const [downloading, setDownloading] = useState(false);

  return (
    <>
      {downloading ? <Spinner /> : null}

      <ExportButton
        onClick={() => {
          setDownloading(true);
          setExportErrorMessage("");
          remoteDataProvider
            .downloadCustomersCsv(searchText, includeInactive)
            .pipe(
              timeout(120_000),
              finalize(() => setDownloading(false))
            )
            .subscribe(
              (blob) => {
                downloadBlob(blob, "Customers.csv");
              },
              (err) => {
                setExportErrorMessage(getErrorMessageFromError(err));
              }
            );
        }}
      />
    </>
  );
}
