import { useDispatch, useStore } from "react-redux";
import { finalize } from "rxjs/operators";
import { ICustomer } from "../../../../models/ICustomer";
import { commonUiActionCreators } from "../../../../modules/commonUi";
import { logError } from "../../../../services/errorLogger";
import { IProposalExisting } from "../../models/IProposal";
import { proposalsActionCreators } from "../../modules/proposal";
import { loadOpportunitiesBoard } from "../../services/loadOpportunitiesBoard";
import {
  ProposalSaveRequest,
  ProposalSaveResponse,
} from "../../services/proposalDataProvider";

export default function useSaveCompleteHandler() {
  const dispatch = useDispatch();
  const store = useStore();

  return ({
    initialPayload,
    saveResult,
    customer,
    proposalId,
    onSaveComplete,
  }: {
    initialPayload: ProposalSaveRequest;
    saveResult: ProposalSaveResponse;
    customer: ICustomer;
    proposalId: string | null;
    onSaveComplete: () => void;
  }) => {
    const sendFailureMessage = saveResult.sendFailureMessage;

    const proposal = saveResult as IProposalExisting & {
      sendFailureMessage?: string | null;
    };
    delete proposal.sendFailureMessage;

    const saveProposalActionPayload = {
      customerId: customer.id,
      customerEmailAddresses: initialPayload.customerEmailAddresses,
      customerPhoneNumber: initialPayload.customerPhoneNumber,
      customerPhoneNumberOptedIntoSms:
        initialPayload.customerPhoneNumberOptedIntoSms,
      proposal,
    };

    dispatch(
      proposalId
        ? proposalsActionCreators.editProposal(saveProposalActionPayload)
        : proposalsActionCreators.addProposal(saveProposalActionPayload)
    );

    loadOpportunitiesBoard(store.getState())
      .pipe(
        finalize(() => {
          onSaveComplete();

          if (!!sendFailureMessage) {
            dispatch(
              commonUiActionCreators.showAlertModal({
                alertErrorMessage:
                  sendFailureMessage +
                  "\n\nThe proposal can be resent by choosing Resend on the opportunity card.",
              })
            );
          }
        })
      )
      .subscribe({
        next: (action) => {
          dispatch(action);
        },

        error: (err) => {
          logError(`unable to refresh board after saving proposal: ${err}`);
        },
      });
  };
}
