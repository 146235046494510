import { logError } from "../../../../services/errorLogger";
import { IProposalExisting } from "../../models/IProposal";

export default function getProposal(
  proposals: IProposalExisting[],
  proposalId: string
) {
  const proposal = proposals.find((p) => p.id === proposalId);
  if (!proposal) {
    logError(`proposal ${proposalId} not found for ProposalForm`);
  }
  return proposal;
}
