import React from "react";
import FrequencySelection, { IFrequency } from "./FrequencySelection";
import { MaintenanceJobFrequency } from "../../../models/IMaintenanceJob";
import { isWeeklyOrMonthlySchedule } from "../../../services/frequencyService";

interface IProps {
  label: string;
  frequency: IFrequency;
  seasonalFrequency: IFrequency;
  onChange: (
    newFrequency: IFrequency,
    newSeasonalFrequency: IFrequency
  ) => void;
}

const FrequencySelectionNonSeasonal: React.SFC<IProps> = ({
  frequency,
  label,
  seasonalFrequency,
  onChange,
}) => {
  return (
    <FrequencySelection
      id="frequency"
      label={label}
      hideInactive={false}
      value={frequency}
      onChange={(value) => {
        onChange(value, getUpdatedSeasonalFrequency(value, seasonalFrequency));
      }}
    />
  );
};

export default FrequencySelectionNonSeasonal;

function getUpdatedSeasonalFrequency(
  frequency: IFrequency,
  seasonalFrequency: IFrequency
): IFrequency {
  if (!seasonalFrequency || typeof seasonalFrequency !== "object") {
    return seasonalFrequency;
  }

  const newSeasonalFrequency = {
    ...seasonalFrequency,
  };

  if (
    isWeeklyOrMonthlySchedule(frequency.frequencyType) &&
    (seasonalFrequency.frequencyType === MaintenanceJobFrequency.Custom ||
      seasonalFrequency.frequencyType === MaintenanceJobFrequency.Daily)
  ) {
    newSeasonalFrequency.frequencyType = MaintenanceJobFrequency.Weekly;
  } else if (
    frequency.frequencyType === MaintenanceJobFrequency.Custom &&
    (isWeeklyOrMonthlySchedule(seasonalFrequency.frequencyType) ||
      seasonalFrequency.frequencyType === MaintenanceJobFrequency.Daily)
  ) {
    newSeasonalFrequency.frequencyType = MaintenanceJobFrequency.Custom;
  } else if (
    frequency.frequencyType === MaintenanceJobFrequency.Daily &&
    (isWeeklyOrMonthlySchedule(seasonalFrequency.frequencyType) ||
      seasonalFrequency.frequencyType === MaintenanceJobFrequency.Custom)
  ) {
    newSeasonalFrequency.frequencyType = MaintenanceJobFrequency.Daily;
  }

  if (frequency.frequencyType === MaintenanceJobFrequency.Custom) {
    newSeasonalFrequency.customFrequencyPeriod =
      frequency.customFrequencyPeriod;
  }

  return newSeasonalFrequency;
}
