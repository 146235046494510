import { CustomerNotificationTemplateType } from "../enums/customerNotificationTemplateType";

export interface ITemplatedMessage {
  id: string;
  message: string;
  variables: Array<ITemplatedMessageVariable>;
  type: CustomerNotificationTemplateType;
}

export interface ITemplatedMessageDefaults {
  id: string;
  variableDefaults: {
    [key: string]: string;
  };
}

// Do not change these values! They need to stay in sync with the server.
export const calculatedContactMethodInputType = "calculatedContactMethod";
export const calculatedShowDayOfServiceInputType = "calculatedShowDayOfService";
type InputType =
  | "text"
  | "date"
  | "phone"
  | "email"
  | "contactMethod"
  | "calculatedContactMethod"
  | "calculatedShowDayOfService"
  | "checkBox";

export interface ITemplatedMessageVariable {
  key: VariableKey;
  label: string;
  inputType: InputType;
  addLeadingWhitespace?: boolean;
  canDefault: boolean;
  optional?: boolean;
}

export interface ITemplatedMessageDefaultValue {
  key: string;
  value: string;
}

// Do not change these values! They need to stay in sync with the server.
export type VariableKey =
  | "<Explanation>"
  | "<Original date>"
  | "<New date>"
  | "<Phone number>"
  | "<Date>"
  | "<Visit instructions>"
  | "<Post visit notes>"
  | "<Day scheduled>"
  | "<Email address>"
  | "<Contact method type>"
  | "<Contact method>"
  | "<Show day selection>"
  | "<Service day>"
  | "<Details>";
