import { useState, useRef, useEffect } from "react";
import { useDispatch, useStore } from "react-redux";
import { concat, of } from "rxjs";
import { mergeMap, timeout } from "rxjs/operators";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { loadCustomersAndChildRecords } from "../../../services/customerService";
import { projectActionCreators } from "../modules/project";
import projectDataProvider from "../services/projectDataProvider";

export function useLoadProject(projectId: string) {
  const dispatch = useDispatch();
  const store = useStore();

  const projects = useApplicationStateSelector((s) => s.project.projects);
  const [errorLoadingProject, setErrorLoadingProject] = useState(false);
  const projectLoadStarted = useRef(false);
  const projectMissing =
    projectId !== "" && !projects.some((p) => p.id === projectId);
  useEffect(() => {
    if (!projectLoadStarted.current && projectMissing) {
      projectDataProvider
        .getProjects({ projectIds: [projectId] })
        .pipe(
          mergeMap((newProjects) => {
            return concat(
              loadCustomersAndChildRecords(
                newProjects.map((p) => p.customerId),
                store.getState()
              ),
              of(
                projectActionCreators.loadProjectsComplete({
                  projects: newProjects,
                })
              )
            );
          }),
          timeout(20000)
        )
        .subscribe({
          next: (action) => {
            dispatch(action);
          },

          error: () => {
            setErrorLoadingProject(true);
          },
        });

      projectLoadStarted.current = true;
    }
  }, [projectId, dispatch, store, projectMissing]);

  return { errorLoadingProject, projectMissing };
}
