import { useDispatch } from "react-redux";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { commonUiActionCreators } from "../../../modules/commonUi";
import { CrewMemberSendAppInstructions } from "./CrewMemberSendAppInstructions";

export function CrewMemberSendAppInstructionsRedux() {
  const dispatch = useDispatch();
  const parametersForCrewMemberInvitationModal = useApplicationStateSelector(
    (s) => s.commonUi.parametersForCrewMemberInvitationModal
  );

  return parametersForCrewMemberInvitationModal !== null ? (
    <CrewMemberSendAppInstructions
      crewMemberId={parametersForCrewMemberInvitationModal.crewMemberId}
      crewMemberName={parametersForCrewMemberInvitationModal.crewMemberName}
      onClose={() => {
        dispatch(commonUiActionCreators.dismissCrewMemberInvitationModal());
      }}
      cancelButton="Skip"
    />
  ) : null;
}
