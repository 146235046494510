import React, { Fragment } from "react";
import Navbar from "./components/Navbar";

const PageWithNavBar = (WrappedComponent: any) => {
  return class extends React.Component {
    render() {
      return (
        <Fragment>
          <Navbar />
          <div className="container-fluid">
            <WrappedComponent {...this.props} />
          </div>
        </Fragment>
      );
    }
  };
};

// TODO: To indicate name better
export default PageWithNavBar;
