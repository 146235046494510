import { ajax } from "rxjs/ajax";
import { catchError, map } from "rxjs/operators";
import {
  buildUrl,
  executeWithHeaders,
} from "../../../services/remoteDataProvider";
import { throwError } from "rxjs";
import { IInvoiceItem } from "../../../models/IInvoiceItem";
import { fullStoryLogError } from "../../../services/fullStoryService";
import { QuickBooksItemType } from "../enums/quickBooksItemType";
import { IQuickBooksAccount } from "../models/IQuickBooksAccount";

export interface ILineItemIdMapping {
  id: string;
  quickBooksLineItemId: string;
}

export interface IFixInvoiceForQuickBooksChanges {
  quickBooksCustomerId: string;
  lineItemIds: Array<ILineItemIdMapping>;
  quickBooksDepositItemIdOverride: string | null;
}

const quickBooksDataProvider = {
  getQuickBooksAccounts: () => {
    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(`quickBooks/accounts`), headers)
    ).pipe(
      map((result) => {
        return result.response.accounts as Array<IQuickBooksAccount>;
      })
    );
  },

  updateQuickBooksItem: (
    invoiceItemId: string,
    payload: Partial<
      IInvoiceItem & {
        itemType: QuickBooksItemType;
        accountId: string;
        quantityOnHand: number | null;
        quantityOnHandAsOfDate: string | null;
        inventoryAssetAccountId: string | null;
      }
    >
  ) => {
    return executeWithHeaders((headers) =>
      ajax.patch(buildUrl(`quickBooks/item/${invoiceItemId}`), payload, headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      })
    );
  },

  addQuickBooksItem: (
    request: Partial<
      IInvoiceItem & {
        itemType: QuickBooksItemType;
        accountId: string;
        quantityOnHand: number | null;
        quantityOnHandAsOfDate: string | null;
        inventoryAssetAccountId: string | null;
      }
    >
  ) => {
    return executeWithHeaders((headers) =>
      ajax.post(buildUrl(`quickBooks/item`), JSON.stringify(request), headers)
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return {
          id: result.response.id as string,
          name: request.name,
        };
      })
    );
  },

  fixInvoiceForQuickBooks: ({
    invoiceId,
    changes,
  }: {
    invoiceId: string;
    changes: IFixInvoiceForQuickBooksChanges;
  }) => {
    return executeWithHeaders((headers) =>
      ajax.patch(
        buildUrl(`invoice/${invoiceId}/fixinvoiceforquickbooks`),
        changes,
        headers
      )
    );
  },
};

export default quickBooksDataProvider;
