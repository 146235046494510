import { useApplicationStateSelector } from "./useApplicationStateSelector";

export function useIsCurrentUserOwner() {
  const userAccounts = useApplicationStateSelector(
    (s) => s.common.userAccounts
  );

  const currentUserId = useApplicationStateSelector(
    (s) => s.common.userAccountId
  );

  let tenantOwner = userAccounts.find((u) => u.isTenantOwner === true);
  return tenantOwner?.id === currentUserId;
}
