import { SendType } from "../../../../enums/sendType";
import { ICustomer } from "../../../../models/ICustomer";
import dateService from "../../../../services/dateService";
import { createNewLineItem } from "../../../../services/invoiceFormService";
import { GetUserSettingsType } from "../../../../services/userSettingsService";
import { UserSettingsType } from "../../../../enums/userSettingsType";
import { IProposalTemplate } from "../../models/IProposalTemplate";
import getCustomerFields from "./getCustomerFields";
import { IFormData } from "./IFormData";
import { EditorState } from "draft-js";
import getDefaultValuesFromTemplate from "./getDefaultValuesFromTemplate";
import { DiscountType } from "../../../../enums/DiscountType";
import { IInvoiceItem } from "../../../../models/IInvoiceItem";

export default function getFormDataForNewProposal({
  customer,
  replyToEmailAddress,
  proposalTemplates,
  defaultValues,
  invoiceItems,
  defaultTaxRate,
  getUserSettings,
}: {
  customer: ICustomer | null;
  replyToEmailAddress: string | null;
  proposalTemplates: Array<IProposalTemplate>;
  defaultValues?: Partial<IFormData>;
  invoiceItems: Array<IInvoiceItem>;
  defaultTaxRate: number | null;
  getUserSettings: GetUserSettingsType;
}): IFormData {
  const defaultTemplate = proposalTemplates.find((t) => t.isPrimaryTemplate);

  if (!defaultValues) {
    defaultValues = defaultTemplate
      ? getDefaultValuesFromTemplate(defaultTemplate, getUserSettings)
      : {};
  }

  if (!defaultValues?.depositItem?.itemId) {
    defaultValues.depositItem = {
      itemId: getUserSettings<string>(UserSettingsType.proposalDepositItemId),
      name: null,
      description: null,
    };
  }

  const customerFields = getCustomerFields(customer);
  if (!defaultValues.depositSettings) {
    defaultValues.depositSettings = null;
  }

  let defaultDepositItem = null;
  if (defaultValues.depositItem?.itemId) {
    defaultDepositItem = invoiceItems.filter(
      (i) => i.id === defaultValues?.depositItem?.itemId
    )[0];
  }

  return {
    quickBooksCustomerId: customer?.quickBooksId ?? null,
    deliveryMethod: SendType.email,
    proposalDate: dateService.formatAsIso(dateService.getCurrentDate()),
    validUntilDate: "",
    lineItems: [createNewLineItem(1)],
    amountAdjustments: {
      taxRate: defaultTaxRate,
      discount: { type: DiscountType.percent, amount: null, percent: null },
    },
    showLineItemPrices: true,
    customerPhoneNumber: customerFields.customerPhoneNumber,
    customerPhoneNumberOptedIntoSms:
      customerFields.customerPhoneNumberOptedIntoSms,
    files: [],
    depositSettings: null,
    depositRequired: false,
    addConvenienceFee: defaultValues.addConvenienceFee ?? false,
    introText: EditorState.createEmpty(),
    footerText: EditorState.createEmpty(),
    ...defaultValues,
    emailFields: {
      customerEmailAddresses: customerFields.customerEmailAddresses,
      ...defaultValues.emailFields,
      // Don't allow Reply To to be overwritten as a default
      replyToEmailAddress: replyToEmailAddress ?? "",
    },
    depositItem: {
      itemId: defaultDepositItem?.id ?? null,
      name: defaultDepositItem?.name ?? null,
      description: defaultDepositItem?.description ?? null,
    },
    captureCustomerPaymentMethod:
      defaultValues?.captureCustomerPaymentMethod ?? false,
    captureCustomerPaymentMethodOptional:
      defaultValues?.captureCustomerPaymentMethodOptional ?? false,
    summary: "",
    subject: defaultValues?.subject ?? null,
  };
}
