import { IJobInstance } from "../models/IJobInstance";
import {
  format,
  parse,
  addDays,
  differenceInMinutes,
  getHours,
  getMinutes,
} from "date-fns";
import { ITimeRange } from "../models/ITimeRange";
import { ITime } from "../models/ITime";
import { round } from "./roundingService";

function getDifferentInMinutes(startTime: string, endTime: string) {
  const startTimeParsed = parse("2018-01-01 " + startTime);
  let endTimeParsed = parse("2018-01-01 " + endTime);

  if (endTimeParsed < startTimeParsed) {
    endTimeParsed = addDays(endTimeParsed, 1);
  }

  return differenceInMinutes(endTimeParsed, startTimeParsed);
}

function hasStartTimeSet(jobInstance: IJobInstance) {
  return jobInstance.timeRanges.reduce((acc, tr) => {
    return acc || !!tr.startTime;
  }, false);
}

function hasStartAndEndTimeSet(jobInstance: IJobInstance) {
  return jobInstance.timeRanges.reduce((acc, tr) => {
    return acc || (!!tr.startTime && !!tr.endTime);
  }, false);
}

function hasAllStartAndEndTimesSet(jobInstance: IJobInstance) {
  // Handling case where there are no time ranges with reduce logic's initial value
  return (
    hasStartAndEndTimeSet(jobInstance) &&
    jobInstance.timeRanges.reduce((acc, tr) => {
      return (
        acc &&
        ((!!tr.startTime && !!tr.endTime) || (!tr.startTime && !tr.endTime))
      );
    }, !(jobInstance.timeRanges.length === 0))
  );
}

function getActualTimeInMinutes(jobInstance: {
  timeRanges: Array<ITimeRange>;
}) {
  return jobInstance.timeRanges.reduce(
    (acc: number | null, timeRange: ITimeRange) => {
      if (timeRange.startTime && timeRange.endTime) {
        return (
          (acc || 0) +
          getDifferentInMinutes(timeRange.startTime, timeRange.endTime)
        );
      } else {
        return acc;
      }
    },
    null
  );
}

function getActualManHours(jobInstance: {
  timeRanges: Array<ITimeRange>;
  actualCrewMembers?: number | null;
}) {
  const hasFullTimeRange = jobInstance.timeRanges.some(
    (tr) => tr.startTime && tr.endTime
  );
  const hasFullTimeRangeMissingCrewCount =
    typeof jobInstance.actualCrewMembers === "number"
      ? false
      : jobInstance.timeRanges.some(
          (tr) => typeof tr.actualCrewMembers !== "number"
        );

  if (hasFullTimeRange && !hasFullTimeRangeMissingCrewCount) {
    const manMinutes = jobInstance.timeRanges.reduce(
      (acc: number, timeRange: ITimeRange) => {
        const actualCrewMembers =
          timeRange.actualCrewMembers ?? jobInstance.actualCrewMembers;
        if (
          typeof acc === "number" &&
          timeRange.startTime &&
          timeRange.endTime &&
          actualCrewMembers
        ) {
          return (
            acc +
            getDifferentInMinutes(timeRange.startTime, timeRange.endTime) *
              actualCrewMembers
          );
        } else {
          return acc;
        }
      },
      0
    );

    return Math.round((manMinutes * 100) / 60) / 100;
  } else {
    return null;
  }
}

function getCrewSize(jobInstance: {
  timeRanges: Array<ITimeRange>;
  actualCrewMembers?: number | null;
}) {
  const filteredTimes = jobInstance.timeRanges.filter(
    (t) => t.startTime && t.endTime
  );
  const manHours = getActualManHours(jobInstance);
  const timeRangeMinutes = getActualTimeInMinutes(jobInstance);
  const hasSameCrewSize =
    (filteredTimes.filter(
      (j) => j.actualCrewMembers !== filteredTimes[0].actualCrewMembers
    )?.length ?? 0) === 0;

  if (
    (filteredTimes.length === 1 ||
      (filteredTimes.length > 0 && hasSameCrewSize)) &&
    filteredTimes[0].actualCrewMembers
  ) {
    return filteredTimes[0].actualCrewMembers;
  } else if (manHours && timeRangeMinutes) {
    return round((manHours * 60) / timeRangeMinutes, 1);
  } else if (
    jobInstance.timeRanges.length === 1 &&
    jobInstance.timeRanges[0].actualCrewMembers
  ) {
    return jobInstance.timeRanges[0].actualCrewMembers;
  } else {
    return jobInstance.actualCrewMembers;
  }
}

function formatTimeForDisplay(input: string) {
  const parsedTime = parseTime(input);
  return format(parsedTime, "h:mmA");
}

function formatTimeForJson(input: ITime) {
  const adjustTwelveHour = (inputHour: number) =>
    inputHour === 12 ? 0 : inputHour;

  let adjustedHour;
  if (input.period === "PM") {
    adjustedHour = adjustTwelveHour(input.hour) + 12;
  } else {
    adjustedHour = adjustTwelveHour(input.hour);
  }

  return `${addLeadingZero(adjustedHour)}:${addLeadingZero(input.minute)}:00`;

  function addLeadingZero(value: number) {
    if (value < 10) {
      return `0${value}`;
    } else {
      return value.toString();
    }
  }
}

function parseTime(input: string) {
  return parse("2018-01-01 " + input);
}

function getTimeComponents(input: string): ITime {
  const parsedTime = parseTime(input);

  const hour = getHours(parsedTime);
  const minute = getMinutes(parsedTime);

  if (hour > 12) {
    return {
      hour: hour - 12,
      minute,
      period: "PM",
    };
  } else if (hour === 12) {
    return {
      hour,
      minute,
      period: "PM",
    };
  } else if (hour === 0) {
    return {
      hour: 12,
      minute,
      period: "AM",
    };
  } else {
    return {
      hour,
      minute,
      period: "AM",
    };
  }
}

export default {
  getActualManHours,
  getCrewSize,
  formatTimeForDisplay,
  getDifferentInMinutes,
  hasStartTimeSet,
  hasStartAndEndTimeSet,
  hasAllStartAndEndTimesSet,
  getActualTimeInMinutes,
  getTimeComponents,
  formatTimeForJson,
  parseTime,
};
