import { faEnvelopeOpen } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";
import dateService from "../../../services/dateService";
import { IProposalExisting } from "../models/IProposal";

interface IProps {
  proposal: IProposalExisting | null;
}

const ProposalViewedIndicator: React.FunctionComponent<IProps> = ({
  proposal,
}) => {
  const [showPopover, setShowPopover] = useState(false);

  let dateText = "";
  if (proposal?.lastViewedDateTime) {
    dateText = `Last viewed date: ${dateService.formatDateTimeForDateTimeDisplay(
      proposal.lastViewedDateTime
    )}`;
  }

  const iconId = `proposalViewed${proposal?.id}`;
  return proposal?.lastViewedDateTime ? (
    <>
      <FontAwesomeIcon
        icon={faEnvelopeOpen}
        size="lg"
        data-testid="proposalViewedContainer"
        className={`board-card-indicators-icon`}
        id={iconId}
        onClick={(e) => {
          e.stopPropagation();
          setShowPopover(showPopover);
        }}
        style={{
          cursor: "pointer",
        }}
      />
      <span className="sr-only">{dateText}</span>
      <Popover
        delay={0}
        trigger="legacy"
        placement="bottom"
        isOpen={showPopover}
        target={iconId}
        toggle={() => {
          setShowPopover(!showPopover);
        }}
      >
        <PopoverBody>{dateText}</PopoverBody>
      </Popover>
    </>
  ) : null;
};

export default ProposalViewedIndicator;
