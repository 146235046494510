import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";

export default () => (
  <div className="loading" data-testid="spinner" style={{ zIndex: 2050 }}>
    <FontAwesomeIcon icon={faSpinner} size="3x" spin={true} />
    <span className="sr-only">Loading...</span>
  </div>
);
