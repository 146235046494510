import {
  IBillingReportCustomer,
  IBillingReportJobInstance,
} from "../models/IBillingReport";
import { ICustomer } from "../models/ICustomer";
import { ICustomerAdditionalLocation } from "../models/ICustomerAdditionalLocation";
import addressFormatter from "./addressFormatter";

const doesJobInstanceHaveNotesOrTodos = (ji: { showNotesLink: boolean }) => {
  return ji.showNotesLink;
};

const doesCustomerHaveNotesOrTodos = (customer: IBillingReportCustomer) =>
  !!customer.jobInstances.find((ji) => doesJobInstanceHaveNotesOrTodos(ji));

const doesJobInstanceHaveBillingDetails = (ji: IBillingReportJobInstance) =>
  !!ji.purchaseOrderNumber || !!ji.invoiceNumber || ji.invoiceSent;

const doesCustomerHaveBillingDetails = (customer: IBillingReportCustomer) =>
  !!customer.jobInstances.find((ji) => doesJobInstanceHaveBillingDetails(ji));

const sortCustomerJobInstances = (a: { date: string }, b: { date: string }) => {
  if (a.date < b.date) {
    return -1;
  }

  if (a.date > b.date) {
    return 1;
  }

  return 0;
};

const getLocationName = (
  customer: {
    id: string;
    customerAdditionalLocationId: string | null;
    customerStreetAndNumber: string;
    customerLatitude: number | null;
    customerLongitude: number | null;
    customerAdditionalLocationName: string | null;
    customerAdditionalLocationsStreetAndNumber: string | null;
    customerAdditionalLocationsLatitude: number | null;
    customerAdditionalLocationsLongitude: number | null;
  },
  customers: Array<ICustomer>,
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>
) => {
  if (!customer.customerAdditionalLocationId) {
    let reduxCustomer = customers.find((c) => c.id === customer.id);
    if (reduxCustomer) {
      return addressFormatter.formatAddressEntity({
        streetAndNumber: reduxCustomer.streetAndNumber,
        latitude: reduxCustomer.latitude,
        longitude: reduxCustomer.longitude,
        latitudeSignificantDigits: null,
        longitudeSignificantDigits: null,
        city: "",
        state: "",
        zip: "",
      });
    } else {
      return addressFormatter.formatAddressEntity({
        streetAndNumber: customer.customerStreetAndNumber,
        latitude: customer.customerLatitude,
        longitude: customer.customerLongitude,
        latitudeSignificantDigits: null,
        longitudeSignificantDigits: null,
        city: "",
        state: "",
        zip: "",
      });
    }
  }

  let locationName: string | null = null;
  let locationAddressFormatted: string;
  const reduxLocation = customerAdditionalLocations.find(
    (l) => l.id === customer.customerAdditionalLocationId
  );
  if (reduxLocation) {
    locationName = reduxLocation.name;
    locationAddressFormatted = addressFormatter.formatAddressEntity({
      streetAndNumber: reduxLocation.streetAndNumber,
      latitude: reduxLocation.latitude,
      longitude: reduxLocation.longitude,
      latitudeSignificantDigits: null,
      longitudeSignificantDigits: null,
      city: "",
      state: "",
      zip: "",
    });
  } else {
    locationName = customer.customerAdditionalLocationName;
    locationAddressFormatted = addressFormatter.formatAddressEntity({
      streetAndNumber:
        customer.customerAdditionalLocationsStreetAndNumber ?? "",
      latitude: customer.customerAdditionalLocationsLatitude,
      longitude: customer.customerAdditionalLocationsLongitude,
      latitudeSignificantDigits: null,
      longitudeSignificantDigits: null,
      city: "",
      state: "",
      zip: "",
    });
  }

  if (locationName) {
    if (locationAddressFormatted) {
      return `${locationName} - ${locationAddressFormatted}`;
    } else {
      return locationName;
    }
  } else {
    return locationAddressFormatted;
  }
};

export default {
  doesJobInstanceHaveNotesOrTodos,
  doesCustomerHaveNotesOrTodos,
  doesJobInstanceHaveBillingDetails,
  doesCustomerHaveBillingDetails,
  sortCustomerJobInstances,
  getLocationName,
};
