import TimeInput from "../components/TimeInput";
import { JobArrivalWindowSelection } from "../../../slices/schedule/components/JobArrivalWindowSelection";
import { JobWorkingHoursAlert } from "../../../slices/schedule/components/JobWorkingHoursAlert";

export function MaintenanceJobFormTimeFields({
  isCrewTimeBased,
  onlyDayOfWeekFlexible,
  startTime,
  endTime,
  onStartTimeChange,
  onEndTimeChange,
  startTimeInputId,
  arrivalWindowDurationMinutes,
  onArrivalWindowChange,
  crewId,
}: {
  isCrewTimeBased: boolean;
  onlyDayOfWeekFlexible: boolean;
  startTime: string;
  endTime: string;
  onStartTimeChange(newValue: string): void;
  onEndTimeChange(newValue: string): void;
  startTimeInputId: string;
  arrivalWindowDurationMinutes: number | null;
  onArrivalWindowChange(newValue: number | null): void;
  crewId: string;
}) {
  const showTimeFields = isCrewTimeBased && !onlyDayOfWeekFlexible;

  return (
    <>
      <div className="form-row">
        {showTimeFields ? (
          <>
            <div className="form-group col-12 col-md-4">
              <label htmlFor={startTimeInputId} className="required">
                Start time
              </label>
              <TimeInput
                className="form-control"
                data-testid="startTime"
                value={startTime}
                required
                id={startTimeInputId}
                onChange={(time) => {
                  onStartTimeChange(time);
                }}
              />
            </div>
            <div className="form-group col-12 col-md-4">
              <label htmlFor="endTime" className="required">
                End time
              </label>
              <TimeInput
                className="form-control"
                data-testid="endTime"
                required
                value={endTime}
                id={"endTime"}
                onChange={(time) => {
                  onEndTimeChange(time);
                }}
              />
            </div>
            <div className="form-group col-12 col-md-4">
              <JobArrivalWindowSelection
                isCrewTimeBased={showTimeFields}
                startTime={startTime}
                endTime={endTime}
                arrivalWindowDurationMinutes={arrivalWindowDurationMinutes}
                onArrivalWindowChange={onArrivalWindowChange}
              />
            </div>
          </>
        ) : null}
      </div>

      <JobWorkingHoursAlert
        crewId={crewId}
        startTime={startTime}
        endTime={endTime}
      />
    </>
  );
}
