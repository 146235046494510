import { useCallback, useEffect } from "react";
import { ICrew } from "../../../models/ICrew";
import { IDaySchedule } from "../../../models/IDaySchedule";
import { IUnscheduledMaintenanceJob } from "../../../models/IUnscheduledMaintenanceJob";
import { IDayToLoad, getDaysToLoad } from "../../../services/dayScheduleLoader";
import {
  getUnassignedWeeksToLoad,
  getMaxUnassignedWeekAlreadyLoaded,
} from "../../../services/scheduleService";
import { getDatesForScheduleWeek } from "../services/getDatesForScheduleWeek";
import {
  getScheduleWeekSelectedCrews,
  getSelectedDateStartOfWeek,
} from "../services/scheduleWeekService";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import dateService from "../../../services/dateService";
import addDays from "date-fns/add_days";

export function useEnsureSchedulesLoadedForWeek({
  crews,
  daySchedules,
  weeksUnscheduledMaintenanceJobs,
  params,
  includePrecedingDay,
}: {
  crews: ICrew[];
  daySchedules: IDaySchedule[];
  weeksUnscheduledMaintenanceJobs: IUnscheduledMaintenanceJob[];
  params: { date: string; crewId: string; mapDate: string };
  includePrecedingDay: boolean;
}) {
  const dispatch = useDispatch();
  crews = crews.filter((c) => !c.inactive);

  const loadDaySchedulesStarting = useCallback(
    (
      daySchedulesToLoad: Array<IDayToLoad>,
      weeksUnscheduledMaintenanceJobsToLoad: Array<Date>,
      maxUnassignedWeekAlreadyLoaded: string | null
    ) =>
      dispatch(
        actionCreators.loadDaySchedulesStarting(
          daySchedulesToLoad,
          weeksUnscheduledMaintenanceJobsToLoad,
          maxUnassignedWeekAlreadyLoaded
        )
      ),
    [dispatch]
  );

  useEffect(() => {
    ensureDaySchedulesLoaded({
      crews,
      daySchedules,
      weeksUnscheduledMaintenanceJobs,
      loadDaySchedulesStarting,
      currentParams: params,
      includePrecedingDay,
    });
  }, [
    crews,
    daySchedules,
    loadDaySchedulesStarting,
    weeksUnscheduledMaintenanceJobs,
    params,
    includePrecedingDay,
  ]);
}

function ensureDaySchedulesLoaded({
  crews,
  daySchedules,
  weeksUnscheduledMaintenanceJobs,
  loadDaySchedulesStarting,
  currentParams,
  includePrecedingDay,
}: {
  crews: Array<ICrew>;
  daySchedules: Array<IDaySchedule>;
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>;
  loadDaySchedulesStarting: (
    daySchedulesToLoad: Array<IDayToLoad>,
    weeksUnscheduledMaintenanceJobsToLoad: any,
    maxUnassignedWeekAlreadyLoaded: string | null
  ) => void;
  currentParams: { crewId: string; date: string };
  includePrecedingDay: boolean;
}) {
  const selectedCrews = getScheduleWeekSelectedCrews(crews, currentParams);

  const scheduleDates = getDatesForScheduleWeek(currentParams);
  if (includePrecedingDay) {
    scheduleDates.push(dateService.formatAsIso(addDays(scheduleDates[0], -1)));
  }

  const missingDates = getDaysToLoad({
    loadedDaySchedules: daySchedules,
    crewIds: selectedCrews.map((c) => c.id),
    dates: scheduleDates,
  });

  const unscheduledWeeksToLoad = getUnassignedWeeksToLoad(
    weeksUnscheduledMaintenanceJobs,
    getSelectedDateStartOfWeek(currentParams)
  );

  if (missingDates.length > 0 || unscheduledWeeksToLoad.length > 0) {
    const maxUnassignedWeekAlreadyLoaded = getMaxUnassignedWeekAlreadyLoaded(
      weeksUnscheduledMaintenanceJobs
    );
    loadDaySchedulesStarting(
      missingDates,
      unscheduledWeeksToLoad,
      maxUnassignedWeekAlreadyLoaded
    );
  }
}
