import React, { useState, useRef } from "react";
import { IJobInstance } from "../../../../../models/IJobInstance";
import jobFinder from "../../../../../services/jobFinder";
import { IOneTimeJob } from "../../../../../models/IOneTimeJob";
import { IMaintenanceJob } from "../../../../../models/IMaintenanceJob";
import { getTodoItems } from "../../../../../services/todoService";
import { ITodoTemplate } from "../../../../../models/ITodoTemplate";
import { ICustomer } from "../../../../../models/ICustomer";
import addressFormatter from "../../../../../services/addressFormatter";
import {
  isImageByType,
  getFileComponents,
} from "../../../../../services/fileService";
import { connect } from "react-redux";
import { IRootState } from "../../../../../store";
import { availableFields, customCrewQuestionId } from "./availableFields";
import { isFieldShown } from "./isFieldShown";
import { ICustomCrewQuestion } from "../../../../../models/ICustomCrewQuestion";
import { FieldsForJob } from "./FieldsForJob";
import { isCompleted } from "./isCompleted";
import ReactToPrint from "react-to-print";
import dateService from "../../../../../services/dateService";
import { ReadOnlyFile } from "../../../components/files/ReadOnlyFile";
import { ICustomerAdditionalLocation } from "../../../../../models/ICustomerAdditionalLocation";
import { getNameForJob } from "../../../../../services/jobService";
import Lightbox from "../../../components/Lightbox";
import { ICrew } from "../../../../../models/ICrew";
import { formatCurrency } from "../../../../../services/currencyFormatter";
import ResponsiveGridTable from "../../../../../libraries/tableLayout/ResponsiveGridTable";
import CompleteForm from "../../../forms/CompleteForm";

export interface IProps extends IOwnProps {
  crew: ICrew;
  jobs: Array<IMaintenanceJob>;
  oneTimeJobs: Array<IOneTimeJob>;
  customers: Array<ICustomer>;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
  todoTemplates: Array<ITodoTemplate>;
  customCrewQuestions: Array<ICustomCrewQuestion>;
  imagePrefix: string;
}

export interface IOwnProps {
  jobInstance: IJobInstance;
  fieldsToShow: Array<availableFields | customCrewQuestionId>;
  isLightboxOpen: boolean;
  onOpenLightbox: () => void;
  onCloseLightbox: () => void;
  date: string;
}

const Job: React.FunctionComponent<IProps> = ({
  crew,
  jobInstance,
  jobs,
  oneTimeJobs,
  todoTemplates,
  customers,
  imagePrefix,
  fieldsToShow,
  customCrewQuestions,
  isLightboxOpen,
  onOpenLightbox,
  onCloseLightbox,
  date,
  customerAdditionalLocations,
}) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const cardRef = useRef<HTMLDivElement | null>(null);
  const [showCompletionInfoForm, setShowCompletionInfoForm] = useState(false);

  const job = jobFinder.getJobForDayScheduleV2(jobs, oneTimeJobs, jobInstance);

  if (job === null) {
    return null;
  }

  const getTodoItemResult = getTodoItems(
    jobInstance,
    todoTemplates,
    jobs,
    oneTimeJobs
  );
  const todoItems = getTodoItemResult.todoItems;
  const lineItems = job.lineItems ?? [];

  return (
    <>
      <div
        key={jobInstance.id}
        className="card"
        style={{
          marginBottom: "30px",
          breakInside:
            jobInstance.photosFromCrew.length === 0 ? "avoid" : undefined,
        }}
        ref={cardRef}
      >
        <div className="card-body">
          <div
            className="card-title"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>
              <h4>
                {getNameForJob({
                  job,
                  customers,
                  customerAdditionalLocations,
                  fallbackToAddressIfAdditionalLocationNameNotSet: false,
                })}
                <span className="d-none d-print-inline">
                  {" "}
                  {dateService.formatDateForDisplay(date)}
                </span>
              </h4>
            </div>
            <div>
              {jobInstance.skipped ? (
                <span className="badge badge-info">Skipped</span>
              ) : null}
            </div>
          </div>
          <h5 className="card-subtitle mb-2 text-muted">
            {addressFormatter.formatAddressForJob(
              job,
              customers,
              customerAdditionalLocations
            )}
          </h5>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <FieldsForJob
              crew={crew}
              job={job}
              jobInstance={jobInstance}
              customCrewQuestions={customCrewQuestions}
              fieldsToShow={fieldsToShow}
              date={date}
            />
          </div>

          {isFieldShown(availableFields.lineItems, fieldsToShow) ? (
            <div className="form-group" data-testid="lineItemsContainer">
              <h6>Job Line Items</h6>
              {lineItems.length === 0 ? (
                <div data-testid="noLineItems">
                  No line items are on the job
                </div>
              ) : (
                <>
                  <ResponsiveGridTable
                    breakpoint="md"
                    rows={lineItems}
                    mobileHeader={(arg, index) => (
                      <strong>Line Item {index + 1}</strong>
                    )}
                    columns={[
                      {
                        header: ({ displayType }) => (
                          <div
                            className={
                              displayType === "mobile"
                                ? "font-weight-light"
                                : ""
                            }
                          >
                            Product/Service
                          </div>
                        ),
                        cell: ({ row: rowObject }) => rowObject.name,
                        key: "name",
                        width: "auto",
                        testId: "lineItemName",
                      },
                      {
                        header: ({ displayType }) => (
                          <div
                            className={
                              displayType === "mobile"
                                ? "font-weight-light"
                                : ""
                            }
                          >
                            Description
                          </div>
                        ),
                        cell: ({ row: rowObject }) => rowObject.description,
                        key: "description",
                        width: "auto",
                        testId: "lineItemDescription",
                      },
                      {
                        header: ({ displayType }) => (
                          <div
                            className={
                              displayType === "mobile"
                                ? "font-weight-light"
                                : ""
                            }
                          >
                            Quantity
                          </div>
                        ),
                        cell: ({ row: rowObject }) => rowObject.quantity,
                        key: "quantity",
                        width: "auto",
                        cellClassName: "text-right",
                        testId: "lineItemQuantity",
                      },
                      {
                        header: ({ displayType }) => (
                          <div
                            className={
                              displayType === "mobile"
                                ? "font-weight-light"
                                : ""
                            }
                          >
                            Amount per item
                          </div>
                        ),
                        cell: ({ row: rowObject }) =>
                          rowObject.amountPerItem
                            ? formatCurrency(
                                typeof rowObject.amountPerItem === "string"
                                  ? parseFloat(rowObject.amountPerItem)
                                  : rowObject.amountPerItem
                              )
                            : "",
                        key: "amountPerItem",
                        width: "auto",
                        cellClassName: "text-right",
                        testId: "lineItemAmountPerItem",
                      },
                    ]}
                  />
                </>
              )}
            </div>
          ) : null}

          {isFieldShown(availableFields.todo, fieldsToShow) ? (
            <div className="form-group">
              <h6>
                Checklist
                {!!getTodoItemResult.todoTemplate ? (
                  <React.Fragment>
                    {" "}
                    - {getTodoItemResult.todoTemplate.name}
                  </React.Fragment>
                ) : null}
              </h6>
              {todoItems.length === 0 ? (
                <div>No checklist items defined for job</div>
              ) : null}
              {todoItems.map((todoItem) => (
                <div
                  className="custom-control custom-checkbox"
                  key={todoItem.id as string}
                >
                  <input
                    className="custom-control-input"
                    type="checkbox"
                    id={todoItem.id + "_DailyReportJob_cb"}
                    checked={isCompleted(
                      todoItem.id as string,
                      jobInstance.todoItemResponses
                    )}
                    disabled
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={todoItem.id + "_DailyReportJob_cb"}
                  >
                    {todoItem.text}
                  </label>
                </div>
              ))}
            </div>
          ) : null}

          {isFieldShown(availableFields.photosFromCrew, fieldsToShow) ? (
            <div className="form-group">
              <h6>Files From Crew</h6>
              {jobInstance.photosFromCrew.length === 0 ? (
                <div>No files from crew</div>
              ) : null}
              <div className="d-flex flex-wrap" style={{ gap: "10px" }}>
                {jobInstance.photosFromCrew.map((photo) => (
                  <ReadOnlyFile
                    key={photo.id as string}
                    onOpenLightbox={() => {
                      onOpenLightbox();
                      setPhotoIndex(
                        jobInstance.photosFromCrew
                          .filter((p) => isImageByType(p.contentType))
                          .indexOf(photo)
                      );
                    }}
                    imagePrefix={imagePrefix}
                    file={photo}
                    divStyle={{ marginBottom: undefined }}
                    thumbnailKey={280}
                  />
                ))}
              </div>
              {isLightboxOpen && (
                <Lightbox
                  onClose={() => onCloseLightbox()}
                  itemIndex={photoIndex}
                  setItemIndex={(newIndex) => setPhotoIndex(newIndex)}
                  items={jobInstance.photosFromCrew.map((p) => {
                    const fileComponents = getFileComponents(p.imagePath);
                    const path = fileComponents.success
                      ? fileComponents.linkUrl
                      : p.imagePath;
                    return {
                      ...p,
                      caption: p.caption,
                      src: `${imagePrefix}/${path}`,
                    };
                  })}
                />
              )}
            </div>
          ) : null}

          <div
            className="d-print-none"
            style={{ marginTop: "10px", display: "flex", flexWrap: "wrap" }}
          >
            <div style={{ marginRight: "25px", marginBottom: "10px" }}>
              <ReactToPrint
                trigger={() => (
                  <button className="btn btn-secondary">Print Job Card</button>
                )}
                content={() => cardRef.current as HTMLDivElement}
              />
            </div>
            <div>
              <button
                className="btn btn-secondary"
                onClick={() => setShowCompletionInfoForm(true)}
              >
                Update Completion Information
              </button>
            </div>
          </div>
        </div>
      </div>

      {showCompletionInfoForm ? (
        <CompleteForm
          jobId={jobInstance.id}
          onCancel={() => {
            setShowCompletionInfoForm(false);
          }}
          onSaveComplete={() => {
            setShowCompletionInfoForm(false);
          }}
        />
      ) : null}
    </>
  );
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => ({
  jobInstance: ownProps.jobInstance,
  fieldsToShow: ownProps.fieldsToShow,
  daySchedules: state.schedule.daySchedules,
  crews: state.crew.crews,
  jobs: state.job.jobs,
  oneTimeJobs: state.job.oneTimeJobs,
  todoTemplates: state.common.todoTemplates,
  customCrewQuestions: state.common.crewViewConfiguration.customCrewQuestions,
  imagePrefix: state.common.imagePrefix || "",
  customers: state.customer.customers,
  customerAdditionalLocations: state.customer.customerAdditionalLocations,
  isLightboxOpen: ownProps.isLightboxOpen,
  onOpenLightbox: ownProps.onOpenLightbox,
  onCloseLightbox: ownProps.onCloseLightbox,
  date: ownProps.date,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Job);
