export default function CardPhoneNumbers({
  phoneNumber,
  alternativePhoneNumber,
  useWhiteText,
}: {
  phoneNumber: string;
  alternativePhoneNumber: string;
  useWhiteText: boolean;
}) {
  if (!phoneNumber && !alternativePhoneNumber) {
    return null;
  }

  return (
    <div data-testid="phoneContainer">
      {phoneNumber ? (
        <div>
          <a
            href={"tel:" + phoneNumber}
            onClick={(e) => e.stopPropagation()}
            className="schedule-link"
            style={{
              color: useWhiteText ? "#fff" : "#495057",
            }}
          >
            {phoneNumber}
          </a>
        </div>
      ) : null}

      {alternativePhoneNumber ? (
        <div>
          <a
            href={"tel:" + alternativePhoneNumber}
            onClick={(e) => e.stopPropagation()}
            className="schedule-link"
            style={{
              color: useWhiteText ? "#fff" : "#495057",
            }}
          >
            Alt: {alternativePhoneNumber}
          </a>
        </div>
      ) : null}
    </div>
  );
}
