import { createNewLineItem } from "../../../../services/invoiceFormService";
import { mapToFormLineItems } from "../../services/proposalService";
import { parseSourceText } from "../../../../services/richTextService";
import { IProposalTemplate } from "../../models/IProposalTemplate";
import uuidv4 from "uuid/v4";
import { GetUserSettingsType } from "../../../../services/userSettingsService";
import { UserSettingsType } from "../../../../enums/userSettingsType";
import dateService from "../../../../services/dateService";
import { addDays } from "date-fns";
import { isDepositRequired } from "../../services/proposalService";

export default function getDefaultValuesFromTemplate(
  selectedTemplate: IProposalTemplate,
  getUserSettings: GetUserSettingsType
) {
  return {
    introText: parseSourceText(selectedTemplate?.introText ?? null),
    footerText: parseSourceText(selectedTemplate?.footerText ?? null),
    showLineItemPrices: !selectedTemplate.hideLineItemPrices,
    lineItems: mapToFormLineItems(
      selectedTemplate?.lineItems.map((li) => {
        return { ...li, id: uuidv4() };
      })
    ) ?? [createNewLineItem(1)],
    depositRequired: isDepositRequired(selectedTemplate?.depositSettings),
    depositSettings: selectedTemplate.depositSettings,
    addConvenienceFee:
      (selectedTemplate?.depositSettings ?? null) !== null
        ? getUserSettings<boolean>(
            UserSettingsType.paymentsConvenienceFeePercentEnabled
          ) ?? true
        : false,
    captureCustomerPaymentMethod:
      selectedTemplate?.captureCustomerPaymentMethod ?? false,
    captureCustomerPaymentMethodOptional:
      selectedTemplate?.captureCustomerPaymentMethodOptional ?? false,
    validUntilDate: selectedTemplate.daysValid
      ? dateService.formatAsIso(addDays(new Date(), selectedTemplate.daysValid))
      : "",
    subject: selectedTemplate?.subject,
  };
}
