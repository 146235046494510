import { Action } from "@reduxjs/toolkit";
import { Epic } from "redux-observable";
import { catchError, filter, map, mergeMap, timeout } from "rxjs/operators";
import { IApplicationState } from "../../../../modules";
import { actionCreators } from "../../../../modules/actionCreators";
import { of } from "rxjs";
import { projectActionCreators } from "../project";
import projectDataProvider from "../../services/projectDataProvider";

const reloadProjects: Epic<Action, Action, IApplicationState> = (
  action,
  state$
) =>
  action.pipe(
    filter(projectActionCreators.reloadProjects.match),
    mergeMap((action) => {
      return projectDataProvider
        .getProjects({ projectIds: action.payload.projectIds })
        .pipe(
          map((projects) => {
            return projectActionCreators.reloadProjectsComplete({ projects });
          }),
          timeout(30000),
          catchError(() => of(actionCreators.null()))
        );
    })
  );

export default reloadProjects;
