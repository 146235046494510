import React, { useEffect, useState, useRef } from "react";
import FormContainer2 from "../components/FormContainer2";
import DayPicker, { format as dayPickerFormat } from "../components/DayPicker";
import dateFnsFormat from "date-fns/format";
import dateService from "../../../services/dateService";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { BillingFrequencyType } from "../../../models/ICustomerPaymentConfiguration";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../formGenerator";
import { useLoadInvoiceData } from "../../../hooks/useLoadInvoiceData";
import Spinner from "../components/Spinner";
import { IQuickBooksCustomer } from "../../../models/IQuickBooksCustomer";
import { ILineItem } from "../components/InvoiceLineItem";
import {
  createNewLineItem,
  getDefaultIntroText,
} from "../../../services/invoiceFormService";
import QuickBooksTaxAlertPrompt from "../components/QuickBooksTaxAlertPrompt";
import { InvoiceDeliveryMethod } from "../../../models/InvoiceDeliveryMethod";
import InvoiceFormAmounts from "./InvoiceFormAmounts";
import conditionalRenderer from "../components/conditionalRenderer";
import Alert from "../components/Alert";
import QuickBooksCustomerSelection from "../components/QuickBooksCustomerSelection";
import InvoiceLineItems from "../components/InvoiceLineItems";
import {
  GetUserSettingsType,
  useUserSettings,
} from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import RichTextEditor from "../components/RichTextEditor";
import { convertFromRaw, EditorState } from "draft-js";
import {
  isTemplateTextSet,
  serializeTextState,
} from "../../../services/richTextService";
import InvoiceDeliveryMethodSelection from "../../../slices/billing/components/InvoiceDeliveryMethodSelection";
import InvoicePaymentMethodSelection, {
  arePaymentFieldsVisible,
  arePaymentFieldsVisibleForCrewControl,
} from "../../../slices/billing/components/InvoicePaymentMethodSelection";
import { CustomerCommunicationTemplateType } from "../../../enums/customerCommunicationTemplateType";
import { ICustomerCommunicationTemplate } from "../../../models/ICustomerCommunicationTemplate";
import { useCreditCardsEnabled } from "../../../hooks/useCreditCardsEnabled";
import { getLineItemName } from "../../../services/lineItemService";
import {
  createEmailAddressRecord,
  createEmailAddressRecordsForCustomer,
  getEmailAddressesForSave,
} from "../components/EmailAddresses.functions";
import { IEmailAddressRecord } from "../components/EmailAddresses";
import { useGetCustomerFromStore } from "../../../hooks/useGetCustomerFromStore";
import { useGetReplyToEmailAddress } from "../../../hooks/useGetReplyToEmailAddress";
import { IFormData } from "./CustomerContractBillingForm.types";
import {
  getBillingExplanation,
  getParsedDeliveryMethod,
} from "./CustomerContractBillingForm.functions";
import { isStringSet } from "../../../services/stringService";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useGetDefaultTaxRate from "../../../slices/sales/hooks/useGetDefaultTaxRate";
import { IInvoiceConfiguration } from "../../../models/IInvoiceConfiguration";
import { InvoiceUpdateDefaultDueDatePrompt } from "../../../slices/billing/components/InvoiceUpdateDefaultDueDatePrompt";
import { isTaxRateSet } from "./InvoiceFormAmounts.functions";

function emptyFunction() {}

const CustomerContractBillingForm: React.FunctionComponent<{}> = () => {
  const tenantName =
    useApplicationStateSelector((s) => s.common.tenantName) ?? "";

  const contractBillingTemplate = useApplicationStateSelector(
    (s) => s.common.contractBillingTemplate
  );
  const invoiceConfiguration = useApplicationStateSelector(
    (s) => s.common.invoiceConfiguration
  );

  const formParameters = useApplicationStateSelector(
    (s) => s.forms.customerContractBilling.parameters
  );
  const customerId = (formParameters?.customerId ?? "") as string;
  const customer = useGetCustomerFromStore(customerId);
  const defaultTaxRate = useGetDefaultTaxRate(customer);
  const replyToEmailAddress = useGetReplyToEmailAddress();

  const { areCreditCardsEnabled } = useCreditCardsEnabled();

  const { getUserSettings, setUserSettings } = useUserSettings();

  const [formData, setFormData] = useState<IFormData>(
    getEmptyFormData({
      defaultQuickBooksCustomerId: null,
      isQuickBooksEnabled: false,
      tenantName,
      customerEmailAddresses: [createEmailAddressRecord("")],
      contractBillingTemplate,
      replyToEmailAddress,
      areCreditCardsEnabled,
      defaultTaxRate,
      invoiceConfiguration,
      getUserSettings,
    })
  );
  const [originalFormData, setOriginalFormData] = useState<IFormData>({
    ...getEmptyFormData({
      defaultQuickBooksCustomerId: null,
      isQuickBooksEnabled: false,
      tenantName,
      customerEmailAddresses: [createEmailAddressRecord("")],
      contractBillingTemplate,
      replyToEmailAddress,
      areCreditCardsEnabled,
      defaultTaxRate,
      invoiceConfiguration,
      getUserSettings,
    }),
  });

  const [initialIntroText, setInitialIntroText] = useState<EditorState>(
    formData.introText
  );
  const [initialFooterText, setInitialFooterText] = useState<EditorState>(
    formData.footerText
  );

  const dispatch = useDispatch();

  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const isFormOpen = useApplicationStateSelector(
    (s) => s.forms.customerContractBilling.showForm
  );
  const hasInitialized = useRef(false);

  const [isAdd, setIsAdd] = useState(false);

  const {
    quickBooksCustomers,
    invoiceItems,
    setInvoiceItems,
    setQuickBooksCustomers,
    quickBooksSettings,
    isQuickBooksEnabled,
    loaded,
    errorLoading,
    showTaxAlert,
    taxAlertDismiss,
  } = useLoadInvoiceData({
    showForm: isFormOpen,
    jobInstances: undefined,
    customerId: formParameters?.customerId ?? "",
    setAllowAchPayment: emptyFunction,
    setAllowCreditCardPayment: emptyFunction,
    closeForm: () =>
      dispatch(actionCreators.customerContractBilling.cancelForm()),
    invoiceId: null,
  });

  useEffect(() => {
    if (!hasInitialized.current) {
      let newFormData: IFormData;
      if (customer?.paymentConfiguration) {
        setIsAdd(false);

        const newLineItems: Array<ILineItem> = (
          customer.paymentConfiguration.lineItems ?? []
        ).map((li) => ({
          ...li,
          itemId: (li.quickBooksLineItemId ??
            li.crewControlInvoiceItemId) as string,
          quantity: li.quantity.toString(),
          amountPerItem: li.amountPerItem.toString(),
        }));

        if (newLineItems.length === 0) {
          newLineItems.push(createNewLineItem(1));
        }

        newFormData = {
          startingDate: customer.paymentConfiguration.startingDate ?? "",
          endingDate: customer.paymentConfiguration.endingDate,
          frequencyType:
            customer.paymentConfiguration.frequencyType ??
            BillingFrequencyType.weekly,
          quickBooksCustomerId:
            customer.paymentConfiguration.quickBooksCustomerId ??
            customer.quickBooksId ??
            "",
          hideLineItemPrices:
            customer.paymentConfiguration.hideLineItemPrices ?? false,
          lineItems: newLineItems,
          allowAchPayment:
            customer.paymentConfiguration.allowOnlineAchPayment ?? false,
          allowCreditCardPayment:
            customer.paymentConfiguration.allowOnlineCreditCardPayment ?? false,
          invoiceDeliveryMethod:
            customer.paymentConfiguration.invoiceDeliveryMethod.toString(),
          paymentMethodOnFileAuthorized:
            customer.paymentConfiguration.paymentMethodOnFileAuthorized ??
            false,
          addConvenienceFee: customer.paymentConfiguration.addConvenienceFee,
          taxRate: customer.paymentConfiguration.taxRate,
          emailFields: {
            recipients: createEmailAddressRecordsForCustomer(customer),
            replyTo:
              customer.paymentConfiguration.replyToEmailAddress ??
              replyToEmailAddress ??
              "",
          },
          introText: customer.paymentConfiguration.introText
            ? getEditorStateFromJson(customer.paymentConfiguration.introText)
            : EditorState.createWithContent(getDefaultIntroText(tenantName)),
          footerText: getEditorStateFromJson(
            customer.paymentConfiguration.footerText
          ),
          numberOfDaysUntilDue: !!customer.paymentConfiguration
            .numberOfDaysUntilDue
            ? customer.paymentConfiguration.numberOfDaysUntilDue.toString()
            : "",
        };
      } else {
        setIsAdd(true);

        newFormData = getEmptyFormData({
          defaultQuickBooksCustomerId: customer?.quickBooksId ?? null,
          isQuickBooksEnabled,
          tenantName,
          customerEmailAddresses:
            createEmailAddressRecordsForCustomer(customer),
          contractBillingTemplate,
          replyToEmailAddress,
          areCreditCardsEnabled,
          defaultTaxRate,
          invoiceConfiguration,
          getUserSettings,
        });
      }

      setFormData(newFormData);
      setOriginalFormData(newFormData);

      setInitialIntroText(newFormData.introText);
      setInitialFooterText(newFormData.footerText);

      hasInitialized.current = true;
    }
  }, [
    customer,
    isFormOpen,
    customerId,
    isQuickBooksEnabled,
    tenantName,
    contractBillingTemplate,
    replyToEmailAddress,
    areCreditCardsEnabled,
    defaultTaxRate,
    invoiceConfiguration,
    getUserSettings,
  ]);

  if (errorLoading) {
    return (
      <Alert
        message="Unable to load form data."
        closeForm={() =>
          dispatch(actionCreators.customerContractBilling.cancelForm())
        }
      />
    );
  } else if (!loaded) {
    return <Spinner />;
  }

  const billingExplanation = getBillingExplanation(formData);

  return (
    <>
      {showTaxAlert ? (
        <QuickBooksTaxAlertPrompt
          onCancel={() =>
            dispatch(actionCreators.customerContractBilling.cancelForm())
          }
          onConfirm={taxAlertDismiss}
        />
      ) : (
        <FormContainer2
          formType="customerContractBilling"
          getFormData={() => {
            const parsedDeliveryMethod = getParsedDeliveryMethod(formData);
            const payload: any = {
              startingDate: formData.startingDate,
              endingDate: formData.endingDate,
              frequencyInterval: 1,
              frequencyType: formData.frequencyType,
              quickBooksCustomerId: !isQuickBooksEnabled
                ? null
                : formData.quickBooksCustomerId,
              allowOnlineAchPayment: arePaymentFieldsVisible({
                quickBooksSettings,
                deliveryMethod: parsedDeliveryMethod,
                editingPayThroughCrewControlInvoice: false,
                isQuickBooksEnabled,
                areCreditCardsEnabled,
              })
                ? formData.allowAchPayment
                : false,
              allowOnlineCreditCardPayment: arePaymentFieldsVisible({
                quickBooksSettings,
                deliveryMethod: parsedDeliveryMethod,
                editingPayThroughCrewControlInvoice: false,
                isQuickBooksEnabled,
                areCreditCardsEnabled,
              })
                ? formData.allowCreditCardPayment
                : false,
              addConvenienceFee: arePaymentFieldsVisibleForCrewControl(
                parsedDeliveryMethod,
                false,
                areCreditCardsEnabled
              )
                ? formData.addConvenienceFee
                : false,
              invoiceDeliveryMethod: parsedDeliveryMethod,
              paymentMethodOnFileAuthorized:
                formData.paymentMethodOnFileAuthorized,
              hideLineItemPrices: formData.hideLineItemPrices,
              lineItems: formData.lineItems.map((li) => ({
                id: li.id,
                description: li.description,
                quantity: parseFloat(li.quantity),
                itemId: li.itemId,
                quickBooksLineItemId: isQuickBooksEnabled ? li.itemId : null,
                crewControlInvoiceItemId: isQuickBooksEnabled
                  ? null
                  : li.itemId,
                amountPerItem: parseFloat(li.amountPerItem),
                taxable: li.taxable ?? null,
                name: getLineItemName(invoiceItems, li),
                hide: li.hide ?? false,
              })),
              taxRate: !isQuickBooksEnabled ? formData.taxRate : null,
              introText: serializeTextState(formData.introText),
              footerText: serializeTextState(formData.footerText),
              customerEmailAddresses: getEmailAddressesForSave(
                formData.emailFields.recipients
              ),
              replyToEmailAddress: formData.emailFields.replyTo,
              numberOfDaysUntilDue: formData.numberOfDaysUntilDue,
            };

            if (
              !arePaymentFieldsVisible({
                quickBooksSettings,
                deliveryMethod: parsedDeliveryMethod,
                editingPayThroughCrewControlInvoice: false,
                isQuickBooksEnabled,
                areCreditCardsEnabled,
              })
            ) {
              payload.allowOnlineAchPayment = false;
              payload.allowOnlineCreditCardPayment = false;
            }

            if (
              !arePaymentFieldsVisibleForCrewControl(
                parsedDeliveryMethod,
                false,
                areCreditCardsEnabled
              )
            ) {
              payload.addConvenienceFee = false;
              payload.introText = null;
              payload.footerText = null;
            }

            if (
              !isQuickBooksEnabled &&
              originalFormData.taxRate !== formData.taxRate
            ) {
              setUserSettings(UserSettingsType.taxRate, formData.taxRate);
            }

            setUserSettings(
              UserSettingsType.replyToEmailAddress,
              formData.emailFields.replyTo
            );

            return payload;
          }}
          validate={() => {
            if (isQuickBooksEnabled && !formData.quickBooksCustomerId) {
              return {
                errorMessage: "You must select a customer",
                valid: false,
              };
            }

            if (formData.lineItems.filter((li) => !li.itemId).length > 0) {
              return {
                errorMessage: "You must select a line item",
                valid: false,
              };
            }

            const customer = customers.find((c) => c.id === customerId);
            if (
              getParsedDeliveryMethod(formData) ===
                InvoiceDeliveryMethod.paymentMethodOnFile &&
              !(customer?.paymentMethod?.isTokenSet ?? false)
            ) {
              return {
                valid: false,
                errorMessage:
                  "The customer must have a payment method to use Payment Method On File",
              };
            }

            return {
              valid: true,
            };
          }}
          formHeader={`${isAdd ? "Add" : "Update"} Contract Billing`}
          saveButtonText={getSaveText(getParsedDeliveryMethod(formData))}
          hasFormDataChanged={() => {
            return (
              JSON.stringify(formData) !== JSON.stringify(originalFormData)
            );
          }}
          size="lg"
        >
          {errorLoading ? null : (
            <>
              <div className="row">
                <div className="form-group col">
                  <label htmlFor="startingDate" className="required">
                    Start date
                  </label>
                  <div>
                    <DayPicker
                      inputId="startingDate"
                      onDayPickerHide={() => null}
                      dayPickerProps={{}}
                      value={
                        !!formData.startingDate
                          ? dateFnsFormat(
                              formData.startingDate,
                              dayPickerFormat
                            )
                          : ""
                      }
                      required={true}
                      onDaySelected={(day: Date) => {
                        setFormData({
                          ...formData,
                          startingDate: day ? dateService.formatAsIso(day) : "",
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="form-group col">
                  <label htmlFor="endingDate">End date</label>
                  <div>
                    <DayPicker
                      inputId="endingDate"
                      onDayPickerHide={() => null}
                      dayPickerProps={{}}
                      value={
                        !!formData.endingDate
                          ? dateFnsFormat(formData.endingDate, dayPickerFormat)
                          : ""
                      }
                      required={false}
                      onDaySelected={(day: Date) => {
                        setFormData({
                          ...formData,
                          endingDate: day ? dateService.formatAsIso(day) : "",
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col">
                  <label htmlFor="frequency" className="required">
                    Frequency
                  </label>
                  <select
                    id="frequency"
                    className="form-control"
                    value={formData.frequencyType.toString()}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        frequencyType: parseInt(e.currentTarget.value),
                      })
                    }
                  >
                    <option value={BillingFrequencyType.weekly.toString()}>
                      Weekly
                    </option>
                    <option value={BillingFrequencyType.monthly.toString()}>
                      Monthly
                    </option>
                    <option value={BillingFrequencyType.quarterly.toString()}>
                      Every 3 months
                    </option>
                    <option
                      value={BillingFrequencyType.semiAnnually.toString()}
                    >
                      Every 6 months
                    </option>
                    <option value={BillingFrequencyType.annually.toString()}>
                      Annually
                    </option>
                  </select>
                </div>
              </div>

              <div className="form-group">
                <div>
                  <label htmlFor="numberOfDaysUntilDue">
                    Number of days until invoice is due
                  </label>
                  <input
                    type="number"
                    data-testid="numberOfDaysUntilDue"
                    id="numberOfDaysUntilDue"
                    className="form-control"
                    min={0}
                    value={formData.numberOfDaysUntilDue}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        numberOfDaysUntilDue: e.currentTarget.value,
                      })
                    }
                  />
                </div>
                <InvoiceUpdateDefaultDueDatePrompt
                  hasNumberOfDaysUntilDueChanged={
                    formData.numberOfDaysUntilDue !==
                    originalFormData.numberOfDaysUntilDue
                  }
                  newDefaultNumberOfDaysDue={formData.numberOfDaysUntilDue}
                />
              </div>

              {isQuickBooksEnabled ? (
                <div className="form-group">
                  <label htmlFor="customer" className="required">
                    QuickBooks Customer
                  </label>
                  <QuickBooksCustomerSelection
                    inputId="customer"
                    customer={customer}
                    options={quickBooksCustomers}
                    quickBooksCustomerId={formData.quickBooksCustomerId}
                    required
                    onChange={(id) => {
                      setFormData({
                        ...formData,
                        quickBooksCustomerId: id,
                      });
                      dispatch(
                        actionCreators.customerContractBilling.setErrorMessage(
                          ""
                        )
                      );
                    }}
                    onCreatedQuickBooksCustomer={({ id, displayName }) => {
                      setQuickBooksCustomers([
                        ...quickBooksCustomers,
                        {
                          id,
                          displayName,
                        } as IQuickBooksCustomer,
                      ]);
                    }}
                  />
                </div>
              ) : null}

              <InvoiceDeliveryMethodSelection
                customerId={customerId}
                enableTexting={false}
                emailFields={formData.emailFields}
                setEmailFields={(newEmailFields) =>
                  setFormData({
                    ...formData,
                    emailFields: newEmailFields,
                  })
                }
                // Phone number not shown since texting not enabled
                customerPhoneNumber={""}
                setCustomerPhoneNumber={() => {}}
                deliveryMethod={formData.invoiceDeliveryMethod}
                setDeliveryMethod={(newDeliveryMethod) => {
                  let newAllowCreditCardPayment =
                    formData.allowCreditCardPayment;
                  let newAllowAchPayment = formData.allowAchPayment;
                  if (
                    parseInt(newDeliveryMethod) ===
                    InvoiceDeliveryMethod.emailWithCrewControl
                  ) {
                    newAllowCreditCardPayment = true;
                    newAllowAchPayment = true;
                  }

                  setFormData({
                    ...formData,
                    invoiceDeliveryMethod: newDeliveryMethod,
                    allowCreditCardPayment: newAllowCreditCardPayment,
                    allowAchPayment: newAllowAchPayment,
                  });
                }}
                paymentMethodOnFileAuthorized={
                  formData.paymentMethodOnFileAuthorized
                }
                setPaymentMethodOnFileAuthorized={(authorized) => {
                  setFormData({
                    ...formData,
                    paymentMethodOnFileAuthorized: authorized,
                  });
                }}
              />

              {arePaymentFieldsVisible({
                quickBooksSettings,
                deliveryMethod: getParsedDeliveryMethod(formData),
                editingPayThroughCrewControlInvoice: false,
                isQuickBooksEnabled,
                areCreditCardsEnabled,
              }) ? (
                <InvoicePaymentMethodSelection
                  isCrewControlDeliveryWithPayments={arePaymentFieldsVisibleForCrewControl(
                    getParsedDeliveryMethod(formData),
                    false,
                    areCreditCardsEnabled
                  )}
                  value={{
                    addConvenienceFee: formData.addConvenienceFee,
                    allowAchPayment: formData.allowAchPayment,
                    allowCreditCardPayment: formData.allowCreditCardPayment,
                  }}
                  onChange={(newPaymentValues) => {
                    setFormData({
                      ...formData,
                      ...newPaymentValues,
                    });
                  }}
                  hideAuthorizePaymentMethodOnFile={true}
                />
              ) : null}

              {areRichTextFieldsEnabled(getParsedDeliveryMethod(formData)) ? (
                <div className="form-group mb-4">
                  <h5>Intro text</h5>
                  <div data-testid="introTextContainer">
                    <RichTextEditor
                      templateOptions={{
                        template: "contractBillingTemplate",
                        templateType:
                          CustomerCommunicationTemplateType.contractBilling,
                        initialState: initialIntroText,
                        setInitialState: (v) => setInitialIntroText(v),
                        templateProperty: "introText",
                      }}
                      editorState={formData.introText}
                      setEditorState={(newText) =>
                        setFormData({
                          ...formData,
                          introText: newText,
                        })
                      }
                      ariaLabel="Intro text"
                      placeholder="Type your intro text here..."
                    />
                  </div>
                </div>
              ) : null}

              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    id="showLineItemPrices"
                    type="checkbox"
                    className="custom-control-input"
                    checked={!formData.hideLineItemPrices}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        hideLineItemPrices: !e.currentTarget.checked,
                      })
                    }
                  />
                  <label
                    htmlFor="showLineItemPrices"
                    className="custom-control-label"
                  >
                    Show line item prices
                  </label>
                </div>
              </div>

              <InvoiceLineItems
                elementIdPrefix="customerContract"
                lineItems={formData.lineItems}
                invoiceItems={invoiceItems}
                setInvoiceItems={setInvoiceItems}
                showHideOption={formData.hideLineItemPrices}
                customerTaxExempt={customer?.taxExempt ?? false}
                taxRateAlreadySet={isTaxRateSet(
                  customer?.paymentConfiguration ?? null
                )}
                setLineItems={(newLineItems) =>
                  setFormData({
                    ...formData,
                    lineItems: newLineItems,
                  })
                }
                onClearErrorMessage={() =>
                  dispatch(
                    actionCreators.customerContractBilling.setErrorMessage("")
                  )
                }
              />

              {!isQuickBooksEnabled ? (
                <InvoiceFormAmounts
                  customerTaxExempt={customer?.taxExempt ?? false}
                  taxRateAlreadySet={isTaxRateSet(
                    customer?.paymentConfiguration ?? null
                  )}
                  lineItems={formData.lineItems}
                  taxRate={formData.taxRate}
                  onTaxRateChange={(newTaxRate) =>
                    setFormData({
                      ...formData,
                      taxRate: newTaxRate,
                    })
                  }
                />
              ) : null}

              {areRichTextFieldsEnabled(getParsedDeliveryMethod(formData)) ? (
                <div className="form-group mt-4">
                  <h5>Footer text</h5>
                  <div data-testid="footerTextContainer">
                    <RichTextEditor
                      templateOptions={{
                        template: "contractBillingTemplate",
                        templateType:
                          CustomerCommunicationTemplateType.contractBilling,
                        initialState: initialFooterText,
                        setInitialState: (v) => setInitialFooterText(v),
                        templateProperty: "footerText",
                      }}
                      editorState={formData.footerText}
                      setEditorState={(newText) =>
                        setFormData({
                          ...formData,
                          footerText: newText,
                        })
                      }
                      ariaLabel="Footer text"
                      placeholder="Type your footer text here..."
                    />
                  </div>
                </div>
              ) : null}
            </>
          )}
          {isStringSet(billingExplanation) ? (
            <div
              className={`alert alert-info p-1 pl-2 ${
                isQuickBooksEnabled ? "mt-3" : ""
              }`}
              data-testid="paymentExplanation"
            >
              <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
              <small>{billingExplanation}</small>
            </div>
          ) : null}
        </FormContainer2>
      )}
    </>
  );
};

export default conditionalRenderer(
  CustomerContractBillingForm,
  (s) => s.forms.customerContractBilling.showForm
);

function getSaveText(deliveryMethod: InvoiceDeliveryMethod) {
  switch (deliveryMethod) {
    case InvoiceDeliveryMethod.paymentMethodOnFile:
      return "Schedule charging";
    case InvoiceDeliveryMethod.emailWithCrewControl:
    case InvoiceDeliveryMethod.textWithCrewControl:
    case InvoiceDeliveryMethod.emailWithQuickBooks:
      return "Schedule sending";
    case InvoiceDeliveryMethod.printWithCrewControl:
    case InvoiceDeliveryMethod.printWithQuickBooks:
      return "Schedule printing";
    default:
      return "Save";
  }
}

function getEmptyFormData({
  defaultQuickBooksCustomerId,
  isQuickBooksEnabled,
  tenantName,
  customerEmailAddresses,
  contractBillingTemplate,
  replyToEmailAddress,
  areCreditCardsEnabled,
  defaultTaxRate,
  invoiceConfiguration,
  getUserSettings,
}: {
  defaultQuickBooksCustomerId: string | null;
  isQuickBooksEnabled: boolean;
  tenantName: string;
  customerEmailAddresses: Array<IEmailAddressRecord>;
  contractBillingTemplate: ICustomerCommunicationTemplate | null;
  replyToEmailAddress: string | null;
  areCreditCardsEnabled: boolean;
  defaultTaxRate: number | null;
  invoiceConfiguration: IInvoiceConfiguration;
  getUserSettings: GetUserSettingsType;
}): IFormData {
  return {
    frequencyType: BillingFrequencyType.monthly,
    startingDate: "",
    endingDate: null,
    quickBooksCustomerId: defaultQuickBooksCustomerId ?? "",
    hideLineItemPrices: false,
    lineItems: [createNewLineItem(1)],
    allowAchPayment: true,
    allowCreditCardPayment: true,
    addConvenienceFee:
      getUserSettings<boolean>(
        UserSettingsType.paymentsConvenienceFeePercentEnabled
      ) ?? true,
    invoiceDeliveryMethod: areCreditCardsEnabled
      ? InvoiceDeliveryMethod.paymentMethodOnFile.toString()
      : InvoiceDeliveryMethod.emailWithCrewControl.toString(),
    paymentMethodOnFileAuthorized: false,
    emailFields: {
      recipients: customerEmailAddresses,
      replyTo: replyToEmailAddress ?? "",
    },
    introText: EditorState.createWithContent(
      contractBillingTemplate &&
        isTemplateTextSet(contractBillingTemplate.introText)
        ? convertFromRaw(JSON.parse(contractBillingTemplate?.introText))
        : getDefaultIntroText(tenantName)
    ),
    footerText:
      contractBillingTemplate &&
      isTemplateTextSet(contractBillingTemplate.footerText)
        ? EditorState.createWithContent(
            convertFromRaw(JSON.parse(contractBillingTemplate?.footerText))
          )
        : EditorState.createEmpty(),
    taxRate: !isQuickBooksEnabled ? defaultTaxRate : null,
    numberOfDaysUntilDue:
      typeof invoiceConfiguration.invoiceDefaultNumberOfDaysValid === "number"
        ? invoiceConfiguration.invoiceDefaultNumberOfDaysValid.toString()
        : "",
  };
}

function areRichTextFieldsEnabled(
  invoiceDeliveryMethod: InvoiceDeliveryMethod
) {
  return invoiceDeliveryMethod === InvoiceDeliveryMethod.emailWithCrewControl;
}

function getEditorStateFromJson(input: string | null) {
  if (input) {
    const contentState = convertFromRaw(JSON.parse(input));
    return EditorState.createWithContent(contentState);
  } else {
    return EditorState.createEmpty();
  }
}
