import React, { useRef } from "react";
import DayPicker, { format as dayPickerFormat } from "./DayPicker";
import dateService from "../../../services/dateService";
import format from "date-fns/format";
import { DateFilterOptions } from "../../../enums/dateFilterOptions";

interface IDateFilters {
  frequency: DateFilterOptions;
  startingDate: string | null;
  endingDate: string | null;
}

interface IProps {
  filters: IDateFilters;
  onFiltersChanged: (filters: IDateFilters) => void;
  showAllDates?: boolean;
  children(args: {
    dateRangeElements: React.ReactNode;
    customDateElements: {
      startingDateElement: React.ReactNode;
      endingDateElement: React.ReactNode;
    } | null;
  }): React.ReactElement<any, any>;
  setErrorMessage(newValue: string): void;
}

const DateFilterHeadless: React.FunctionComponent<IProps> = ({
  filters,
  onFiltersChanged,
  showAllDates,
  children,
  setErrorMessage,
}) => {
  const isEndingDateBeforeStartingDate =
    filters.startingDate &&
    filters.endingDate &&
    filters.endingDate < filters.startingDate;

  const newErrorMessage = isEndingDateBeforeStartingDate
    ? "Ending Date cannot be before Starting Date"
    : "";
  const previousSetErrorMessage = useRef("");
  if (previousSetErrorMessage.current !== newErrorMessage) {
    setErrorMessage(newErrorMessage);
    previousSetErrorMessage.current = newErrorMessage;
  }

  return children({
    dateRangeElements: (
      <>
        <label htmlFor="customerDetailsHistoryFilterDate">Date range</label>
        <select
          style={{}}
          id="customerDetailsHistoryFilterDate"
          className="form-control"
          onChange={(e) => {
            onFiltersChanged({
              ...filters,
              frequency: parseInt(e.currentTarget.value) as DateFilterOptions,
            });
            previousSetErrorMessage.current = "";
            setErrorMessage("");
          }}
          value={filters.frequency}
        >
          {getFrequency(DateFilterOptions.last30Days, "Last 30 days")}
          {getFrequency(DateFilterOptions.last60Days, "Last 60 days")}
          {getFrequency(DateFilterOptions.last90Days, "Last 90 days")}
          {showAllDates
            ? getFrequency(DateFilterOptions.all, "All days")
            : null}
          {getFrequency(DateFilterOptions.custom, "Custom")}
        </select>
      </>
    ),

    customDateElements:
      filters.frequency === DateFilterOptions.custom
        ? {
            startingDateElement: (
              <div className="flex-fill">
                <label htmlFor="startingDate">Starting date</label>
                <div>
                  <DayPicker
                    preventMobileView={true}
                    onDayPickerHide={() => null}
                    dayPickerProps={{}}
                    value={
                      !!filters.startingDate
                        ? format(filters.startingDate, dayPickerFormat)
                        : ""
                    }
                    required={true}
                    inputId="startingDate"
                    onDaySelected={(day: Date) => {
                      if (!!day) {
                        onFiltersChanged({
                          ...filters,
                          startingDate: dateService.formatAsIso(day),
                        });
                      }
                    }}
                  />
                </div>
              </div>
            ),

            endingDateElement: (
              <div className="flex-fill">
                <label htmlFor="endingDate">Ending date</label>
                <div>
                  <DayPicker
                    preventMobileView={true}
                    onDayPickerHide={() => null}
                    dayPickerProps={{}}
                    value={
                      !!filters.endingDate
                        ? format(filters.endingDate, dayPickerFormat)
                        : ""
                    }
                    required={true}
                    inputId="endingDate"
                    onDaySelected={(day: Date) => {
                      if (!!day) {
                        onFiltersChanged({
                          ...filters,
                          endingDate: dateService.formatAsIso(day),
                        });
                      }
                    }}
                  />
                </div>
              </div>
            ),
          }
        : null,
  });
};

export default DateFilterHeadless;

function getFrequency(frequency: DateFilterOptions, name: string) {
  return <option value={(frequency as number).toString()}>{name}</option>;
}
