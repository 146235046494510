import { CallHistoryMethodAction } from "connected-react-router";
import React, { createContext, useState, useEffect } from "react";
import auth from "./Auth";
import { Subscription, of } from "rxjs";
import { timeout, catchError } from "rxjs/operators";
import { fullStoryLogInfo } from "./fullStoryService";

export interface IAuthContext {
  isAuthenticated: boolean;
  isLoading: boolean;
  handleAuthentication: ({
    dispatch,
    invitationId,
  }: {
    dispatch: (arg: CallHistoryMethodAction) => void;
    invitationId?: string | null;
  }) => void;
}

export const Auth0Context = createContext<IAuthContext>({
  isAuthenticated: false,
  isLoading: false,
  handleAuthentication: () => {},
});

interface IProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FunctionComponent<IProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    auth.isAuthenticated()
  );
  const [isLoading, setIsLoading] = useState(shouldRenewToken());
  useEffect(() => {
    const isAuthenticatedSubscription =
      auth.isAuthenticatedObservable.subscribe((newIsAuthenticated) => {
        setIsAuthenticated(newIsAuthenticated);
      });

    let renewTokenSubscription: Subscription | null = null;
    if (shouldRenewToken()) {
      fullStoryLogInfo("AuthContext calling renewTokenObservable");
      renewTokenSubscription = auth
        .renewTokenObservable()
        .pipe(
          timeout(2000),
          catchError(() => of(""))
        )
        .subscribe(() => {
          setIsLoading(false);
        });
    }

    return function cleanup() {
      isAuthenticatedSubscription.unsubscribe();
      if (renewTokenSubscription) {
        renewTokenSubscription.unsubscribe();
      }
    };
  }, [setIsAuthenticated, setIsLoading]);

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        isLoading,
        handleAuthentication: ({ dispatch, invitationId }) =>
          auth.handleAuthentication({ dispatch, invitationId }),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

function shouldRenewToken(): boolean {
  const isTokenSet = auth.isTokenSet();
  const isAuthenticated = auth.isAuthenticated();

  return isTokenSet && !isAuthenticated;
}
