import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FormContainerWithoutRedux from "../components/FormContainerWithoutRedux";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import PercentField from "../components/PercentField";
import { useCreditCardsEnabled } from "../../../hooks/useCreditCardsEnabled";
import { IInvoiceConfiguration } from "../../../models/IInvoiceConfiguration";
import { timeout } from "rxjs/operators";
import { ModalDataLoaderStateless } from "../components/ModalDataLoaderStateless";
import RichTextEditor from "../components/RichTextEditor";
import { CustomerCommunicationTemplateType } from "../../../enums/customerCommunicationTemplateType";
import { EditorState, convertFromRaw } from "draft-js";
import {
  isTemplateTextSet,
  serializeTextState,
} from "../../../services/richTextService";
import {
  getDefaultFooterText,
  getDefaultIntroText,
} from "../../../services/invoiceFormService";
import { forkJoin } from "rxjs";
import { ErrorMessageType } from "../components/FormContainer";

interface IFormData {
  captureCustomerPaymentMethod: boolean;
  taxRate: number | null;
  invoiceDefaultNumberOfDaysValid: number | null | string;
  updateContractBilling: boolean;
  introTextState: EditorState;
  footerTextState: EditorState;
}

interface ILoadData {
  customerContractBillingCount: number;
}

const InvoiceConfigurationForm = ({
  onClose,
  onSaveComplete,
  defaultTaxRate,
  defaultInvoiceDefaultNumberOfDaysValid,
}: {
  onClose(): void;
  onSaveComplete(newValue: number | null): void;
  defaultTaxRate?: number | null;
  defaultInvoiceDefaultNumberOfDaysValid?: number | null;
}) => {
  const defaultCaptureCustomerPaymentMethod = useApplicationStateSelector(
    (s) => s.common.invoiceConfiguration.captureCustomerPaymentMethod
  );
  const { areCreditCardsEnabled } = useCreditCardsEnabled();
  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );
  const invoiceConfiguration = useApplicationStateSelector(
    (s) => s.common.invoiceConfiguration
  );
  const invoiceTemplate = useApplicationStateSelector(
    (s) => s.common.invoiceTemplate
  );
  const tenantName = useApplicationStateSelector((s) => s.common.tenantName);
  const {
    taxRate: savedTaxRate,
    invoiceDefaultNumberOfDaysValid: savedInvoiceDefaultNumberOfDaysValid,
  } = invoiceConfiguration;
  const existingTaxRate = defaultTaxRate ?? savedTaxRate ?? null;
  const existingInvoiceDefaultNumberOfDaysValid =
    defaultInvoiceDefaultNumberOfDaysValid ??
    savedInvoiceDefaultNumberOfDaysValid ??
    null;

  const defaultValues = {
    captureCustomerPaymentMethod: defaultCaptureCustomerPaymentMethod,
    taxRate: existingTaxRate,
    invoiceDefaultNumberOfDaysValid: existingInvoiceDefaultNumberOfDaysValid,
    updateContractBilling: false,
    introTextState: EditorState.createWithContent(
      invoiceTemplate && isTemplateTextSet(invoiceTemplate.introText)
        ? convertFromRaw(JSON.parse(invoiceTemplate?.introText))
        : getDefaultIntroText(tenantName)
    ),
    footerTextState: EditorState.createWithContent(
      invoiceTemplate && isTemplateTextSet(invoiceTemplate.footerText)
        ? convertFromRaw(JSON.parse(invoiceTemplate?.footerText))
        : getDefaultFooterText()
    ),
  };
  const { register, getValues, control, watch } = useForm<IFormData>({
    defaultValues,
  });

  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");

  const [loadingData, setLoadingData] = useState(false);
  const [errorLoadingData, setErrorLoadingData] = useState(false);
  const [usageData, setUsageData] = useState<ILoadData | null>(null);
  const [initialIntroText, setInitialIntroText] = useState<EditorState>(
    defaultValues.introTextState
  );
  const [initialFooterText, setInitialFooterText] = useState<EditorState>(
    defaultValues.footerTextState
  );

  const introTextState = watch("introTextState");
  const footerTextState = watch("footerTextState");

  const dispatch = useDispatch();

  useEffect(() => {
    setLoadingData(true);
    setErrorLoadingData(false);

    const subscription = remoteDataProvider
      .getTenantInvoiceConfigurationUsage()
      .pipe(timeout(30000))
      .subscribe({
        next: (r) => {
          setUsageData(r);
          setLoadingData(false);
        },

        error: () => {
          setErrorLoadingData(true);
          setLoadingData(false);
        },
      });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, []);

  const invoiceDefaultNumberOfDaysValid = watch(
    "invoiceDefaultNumberOfDaysValid"
  );

  return (
    <ModalDataLoaderStateless
      errorMessage={
        "The data could not be loaded for this form. Please check your Internet connection and try again."
      }
      onErrorAlertClose={onClose}
      loadingData={loadingData}
      errorLoadingData={errorLoadingData}
    >
      <FormContainerWithoutRedux
        formHeader={"Invoice Configuration"}
        formType={FormTypesV2.invoiceConfiguration}
        size="lg"
        showForm={true}
        hasFormDataChanged={() => {
          const values = getValues();
          const currentValues = {
            ...values,
            introTextState: serializeTextState(values.introTextState),
            footerTextState: serializeTextState(values.footerTextState),
          };
          const originalValues = {
            captureCustomerPaymentMethod: defaultCaptureCustomerPaymentMethod,
            taxRate: existingTaxRate,
            invoiceDefaultNumberOfDaysValid:
              existingInvoiceDefaultNumberOfDaysValid,
            updateContractBilling: false,
            introTextState: serializeTextState(defaultValues.introTextState),
            footerTextState: serializeTextState(defaultValues.footerTextState),
          };

          return (
            JSON.stringify(currentValues) !== JSON.stringify(originalValues)
          );
        }}
        save={() => {
          return forkJoin({
            configResult: remoteDataProvider.saveTenantInvoiceConfiguration(
              getSavePayload({
                values: getValues(),
                existingInvoiceDefaultNumberOfDaysValid,
              })
            ),
            introResult: remoteDataProvider.saveCustomerCommunicationTemplate(
              CustomerCommunicationTemplateType.invoice,
              {
                introText: serializeTextState(introTextState),
                footerText: serializeTextState(footerTextState),
              }
            ),
          });
        }}
        onSaveComplete={(response) => {
          dispatch(
            actionCreators.invoiceConfigurationUpdated(
              getSavePayload({
                values: getValues(),
                existingInvoiceDefaultNumberOfDaysValid,
              })
            )
          );
          dispatch(
            actionCreators.updateCustomerCommunicationTemplate(
              "invoiceTemplate",
              "introText",
              serializeTextState(introTextState)
            )
          );
          dispatch(
            actionCreators.updateCustomerCommunicationTemplate(
              "invoiceTemplate",
              "footerText",
              serializeTextState(footerTextState)
            )
          );
          onSaveComplete(response.configResult.data.taxRate);
        }}
        onCancel={onClose}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      >
        <div style={{ minHeight: "14rem" }}>
          <div className="form-group">
            <div>
              <label htmlFor="taxRateSetting">Default tax rate</label>
              <Controller
                name={`taxRate`}
                control={control}
                render={({ field }) => (
                  <PercentField
                    id="taxRateSetting"
                    dataTestId="taxRateSetting"
                    value={field.value}
                    onChange={(newValue) => field.onChange(newValue)}
                    decimalPlacesAllowed={3}
                    className="form-control"
                  />
                )}
              />
              {isQuickBooksEnabled ? (
                <small>Only applies to proposals and on-site invoices</small>
              ) : null}
            </div>
          </div>

          <div className="form-group">
            <div>
              <label htmlFor="invoiceDefaultNumberOfDaysValid">
                Default number of days until invoice are due
              </label>
              <input
                type="number"
                id="invoiceDefaultNumberOfDaysValid"
                className="form-control"
                min={0}
                {...register("invoiceDefaultNumberOfDaysValid")}
              />
            </div>
          </div>
          {isUpdateContractBillingVisible({
            invoiceDefaultNumberOfDaysValid,
            existingInvoiceDefaultNumberOfDaysValid,
          }) ? (
            <div className="custom-control custom-checkbox mb-4">
              <input
                type="checkbox"
                className="custom-control-input"
                id="updateContractBilling"
                data-testid="updateContractBilling"
                {...register("updateContractBilling")}
              />
              <label
                className="custom-control-label"
                htmlFor="updateContractBilling"
              >
                {usageData
                  ? `Update all existing ${usageData.customerContractBillingCount} contract billing records`
                  : "Update all existing contract billing records"}
              </label>
            </div>
          ) : null}
          {areCreditCardsEnabled ? (
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="captureCustomerPaymentMethod"
                  data-testid="captureCustomerPaymentMethod"
                  {...register("captureCustomerPaymentMethod")}
                />
                <label
                  className="custom-control-label"
                  htmlFor="captureCustomerPaymentMethod"
                >
                  Allow customers to save payment method on file when paying an
                  invoice for recurring jobs?
                </label>
                <div
                  data-testid={`$PaymentMethodConvenienceFeeWarning`}
                  className="alert alert-info mt-1"
                  style={{
                    maxWidth: "700px",
                    border: "0px",
                    padding: "12px",
                  }}
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                  <small>
                    Invoices paid using payment method on file cannot include a
                    3% convenience fee due to credit card regulations.
                  </small>
                </div>
              </div>
            </div>
          ) : null}
          <div className="form-group mb-4">
            <h5>Intro text</h5>
            <div data-testid="introTextContainer">
              <Controller
                name={`introTextState`}
                control={control}
                render={({ field }) => (
                  <RichTextEditor
                    templateOptions={{
                      template: "invoiceTemplate",
                      templateType: CustomerCommunicationTemplateType.invoice,
                      initialState: initialIntroText,
                      setInitialState: (v) => setInitialIntroText(v),
                      templateProperty: "introText",
                    }}
                    editorState={field.value as EditorState}
                    setEditorState={(newText) => field.onChange(newText)}
                    ariaLabel="Intro text"
                    placeholder="Type your intro text here..."
                    hideSaveAsDefault={true}
                  />
                )}
              />
            </div>
          </div>
          <div className="form-group mt-4">
            <h5>Footer text</h5>
            <div data-testid="footerTextContainer">
              <Controller
                name={`footerTextState`}
                control={control}
                render={({ field }) => (
                  <RichTextEditor
                    templateOptions={{
                      template: "invoiceTemplate",
                      templateType: CustomerCommunicationTemplateType.invoice,
                      initialState: initialFooterText,
                      setInitialState: (v) => setInitialFooterText(v),
                      templateProperty: "footerText",
                    }}
                    editorState={field.value as EditorState}
                    setEditorState={(newText) => field.onChange(newText)}
                    ariaLabel="Footer text"
                    placeholder="Type your footer text here..."
                    hideSaveAsDefault={true}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </FormContainerWithoutRedux>
    </ModalDataLoaderStateless>
  );
};

export default InvoiceConfigurationForm;

function isUpdateContractBillingVisible({
  invoiceDefaultNumberOfDaysValid,
  existingInvoiceDefaultNumberOfDaysValid,
}: {
  invoiceDefaultNumberOfDaysValid: string | number | null;
  existingInvoiceDefaultNumberOfDaysValid: number | null;
}) {
  const normalizedInvoiceDefaultNumberOfDaysValid =
    invoiceDefaultNumberOfDaysValid !== null
      ? invoiceDefaultNumberOfDaysValid.toString()
      : "";
  const normalizedExistingInvoiceDefaultNumberOfDaysValid =
    existingInvoiceDefaultNumberOfDaysValid !== null
      ? existingInvoiceDefaultNumberOfDaysValid.toString()
      : "";

  return (
    normalizedInvoiceDefaultNumberOfDaysValid !==
    normalizedExistingInvoiceDefaultNumberOfDaysValid
  );
}

function getSavePayload({
  values,
  existingInvoiceDefaultNumberOfDaysValid,
}: {
  values: IFormData;
  existingInvoiceDefaultNumberOfDaysValid: number | null;
}): IInvoiceConfiguration & { updateContractBilling: boolean } {
  let invoiceDefaultNumberOfDaysValid: number | null;
  if (typeof values.invoiceDefaultNumberOfDaysValid === "string") {
    invoiceDefaultNumberOfDaysValid =
      values.invoiceDefaultNumberOfDaysValid === ""
        ? null
        : parseInt(values.invoiceDefaultNumberOfDaysValid);
  } else {
    invoiceDefaultNumberOfDaysValid = values.invoiceDefaultNumberOfDaysValid;
  }

  return {
    captureCustomerPaymentMethod: values.captureCustomerPaymentMethod,
    taxRate: values.taxRate,
    updateContractBilling: isUpdateContractBillingVisible({
      invoiceDefaultNumberOfDaysValid: values.invoiceDefaultNumberOfDaysValid,
      existingInvoiceDefaultNumberOfDaysValid,
    })
      ? values.updateContractBilling
      : false,
    invoiceDefaultNumberOfDaysValid,
  };
}
