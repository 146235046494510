import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import configuration from "./configuration";
import { isFeatureFlagSet, Flags } from "./featureFlagService";
import { getGeocodedAddress } from "./googleGeocoderService";

export function getGoogelAnalyticsSignupCategory() {
  if (
    !configuration.useProductionGoogleAnalyticsCategories ||
    isFeatureFlagSet(Flags.testRegistration)
  ) {
    return "Test-Signup";
  }

  return "Signup";
}

// Const per recommendation from library
export const googleMapLibraries = ["geometry", "drawing", "places"];

export function hasGoogleMapsLoaded() {
  const windowAny: any = window;
  return !!windowAny.google?.maps;
}

export function hasGoogleMapsAutocompleteLoaded() {
  const windowAny: any = window as any;
  return (
    windowAny &&
    windowAny.google &&
    windowAny.google.maps &&
    windowAny.google.maps.places &&
    windowAny.google.maps.places.Autocomplete
  );
}

export function getSignificantDigits(input: string) {
  if (!input) {
    return null;
  }

  const parts = input.split(".");
  if (parts.length !== 2) {
    return null;
  }

  return parts[1].length;
}

export function getAddressComponentValue(
  place: any,
  componentType: ComponentType
) {
  if (!place) {
    return "";
  }

  const addressComponents =
    place.address_components as Array<IGoogleAddressComponent>;

  if (!addressComponents) {
    return "";
  }

  const matchingItem = addressComponents.find(
    (ac) => !!ac.types.find((t) => t === componentType)
  );

  if (matchingItem) {
    return matchingItem.short_name;
  } else {
    return "";
  }
}

export function createLatLng(location: {
  latitude: number;
  longitude: number;
}) {
  const windowAny: any = window;
  return new windowAny.google.maps.LatLng(
    location.latitude,
    location.longitude
  );
}

export function getLatLng(place: any, fn: "lat" | "lng") {
  if (
    place &&
    place.geometry &&
    place.geometry.location &&
    typeof place.geometry.location[fn] === "function"
  ) {
    const value = place.geometry.location[fn]();
    if (typeof value === "string") {
      return value;
    } else if (!!value) {
      return value.toString();
    }
  }
  return "";
}

export function getAddressPlace(
  fullAddress: string | undefined,
  place: google.maps.places.PlaceResult
): Observable<GooglePlace> {
  let result = new GooglePlace(place);

  // Fetch geocoder address information if the address doesn't have a street number
  if (!result.streetNumber) {
    return getGeocodedAddress(fullAddress).pipe(
      map((geoResults) => {
        let result = new GooglePlace(place);

        if (geoResults?.length > 0) {
          let geocoderAddress = new GooglePlace(geoResults[0]);

          // If geocoder address has a better address return it.
          if (geocoderAddress.streetNumber) {
            result = geocoderAddress;
            result.showWarning = true;
          }
        }
        setAddressWarning(result);
        return result;
      })
    );
  } else {
    setAddressWarning(result);
    return of(result);
  }
}

function setAddressWarning(place: GooglePlace) {
  // Validate results from Google show warning if incomlete.
  if (
    !place.streetNumber ||
    !place.route ||
    !place.locality ||
    !place.administrativeArea ||
    !place.postalCode
  ) {
    place.showWarning = true;
  }
}

export class GooglePlace {
  streetNumber: string;
  route: string;
  locality: string;
  administrativeArea: string;
  country: string;
  postalCode: string;
  placeId?: string;
  latitude: string;
  longitude: string;
  formattedAddress: string | undefined;
  showWarning: boolean;

  constructor(
    place: google.maps.places.PlaceResult | google.maps.GeocoderResult
  ) {
    this.placeId = place.place_id;
    this.streetNumber = getAddressComponentValue(place, "street_number");
    this.route = getAddressComponentValue(place, "route");
    this.locality = getAddressComponentValue(place, "locality");
    this.administrativeArea = getAddressComponentValue(
      place,
      "administrative_area_level_1"
    );
    this.country = getAddressComponentValue(place, "country");
    this.postalCode = getAddressComponentValue(place, "postal_code");
    this.latitude = getLatLng(place, "lat");
    this.longitude = getLatLng(place, "lng");
    this.formattedAddress = place.formatted_address;
    this.showWarning = false;
  }
}

interface IGoogleAddressComponent {
  long_name: string;
  short_name: string;
  types: Array<string>;
}

type ComponentType =
  | "street_number"
  | "route"
  | "locality"
  | "administrative_area_level_1"
  | "country"
  | "postal_code";
