import React, { useState } from "react";
import { IPayrollReportCrewMemberDayDetails } from "../../../../../models/IPayrollReportCrewMember";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";
import { format } from "date-fns";
import LinkButton from "../../../components/LinkButton";
import { getSortedItemsV2 } from "../../../../../services/sortingService";
import { Link } from "react-router-dom";
import { builders } from "../../../../../services/routing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../../../modules/actionCreators";

interface IProps {
  crewMemberId: string;
  roundHours: (input: number) => number;
  dayDetails: IPayrollReportCrewMemberDayDetails;
}

const DayDetails: React.FunctionComponent<IProps> = ({
  crewMemberId,
  dayDetails,
  roundHours,
}) => {
  const [showTimeRangeListPopover, setShowTimeRangeListPopover] =
    useState(false);
  const [showUnclosedTimeRangePopover, setShowUnclosedTimeRangePopover] =
    useState(false);
  const linkContainerId = `daydetails_${crewMemberId}_${dayDetails.day}`;
  const alertId = `daydetails_alert_${crewMemberId}_${dayDetails.day}`;
  const dispatch = useDispatch();

  const cellContents = roundHours(dayDetails.hours).toString();
  const hasTimeRanges = dayDetails.timeRanges.length > 0;
  const hasUnclosedTimeRanges = dayDetails.unclosedTimeRanges.length > 0;

  return (
    <>
      {hasTimeRanges ? (
        <>
          <LinkButton
            id={linkContainerId}
            onClick={() => {}}
            hrefForDisplay="details"
            linkText={cellContents}
            style={{ width: undefined }}
          ></LinkButton>

          <Popover
            delay={0}
            fade={false}
            trigger="legacy"
            isOpen={showTimeRangeListPopover}
            target={linkContainerId}
            toggle={() =>
              setShowTimeRangeListPopover(!showTimeRangeListPopover)
            }
          >
            <PopoverBody>
              <div className="text-nowrap">
                {dayDetails.timeRanges.map((tr) => (
                  <div key={tr.id}>
                    {formatTimeRange(tr.start)} - {formatTimeRange(tr.end)}
                  </div>
                ))}

                {dayDetails.customers.length > 0 ? (
                  <div className="mt-1">
                    <strong>Customers Visited</strong>
                  </div>
                ) : null}

                {getSortedItemsV2(dayDetails.customers, ["name"]).map((c) => (
                  <div key={c.id}>
                    <Link
                      to={builders.manage.buildCustomerDetailsRoute({
                        customerId: c.id,
                      })}
                    >
                      {c.name}
                    </Link>
                  </div>
                ))}
              </div>
            </PopoverBody>
          </Popover>
        </>
      ) : (
        cellContents
      )}

      {hasUnclosedTimeRanges ? (
        <>
          <button
            className="btn btn-link"
            style={{ margin: 0, padding: 0 }}
            onClick={() => setShowUnclosedTimeRangePopover(true)}
          >
            <FontAwesomeIcon
              icon={faExclamationCircle}
              id={alertId}
              size="sm"
              className="ml-1 text-danger"
              title="Crew member was not clocked out"
            />
          </button>

          <Popover
            delay={0}
            fade={false}
            trigger="legacy"
            isOpen={showUnclosedTimeRangePopover}
            target={alertId}
            toggle={() =>
              setShowUnclosedTimeRangePopover(!showUnclosedTimeRangePopover)
            }
          >
            <PopoverBody>
              <div>Crew member was not clocked out</div>
              <button
                className="btn btn-link"
                style={{ padding: 0 }}
                onClick={() => {
                  setShowUnclosedTimeRangePopover(false);
                  if (dayDetails.unclosedTimeRanges.length > 0) {
                    dispatch(
                      actionCreators.forms.payrollTimeRange.showForm({
                        timeRangeId: dayDetails.unclosedTimeRanges[0].id,
                      })
                    );
                  }
                }}
              >
                Clock them out
              </button>
            </PopoverBody>
          </Popover>
        </>
      ) : null}
    </>
  );
};

export default DayDetails;

function formatTimeRange(input: string) {
  return format(new Date(input), "M/D/YYYY h:mmA");
}
