import React, { useState } from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import {
  IBillingReportJobInstanceDetails,
  IPhoto,
} from "../../../models/IBillingReport";
import { formatCurrency } from "../../../services/currencyFormatter";
import dateService from "../../../services/dateService";
import { getTotalHoursForJobInstances } from "../../../services/invoiceService";
import { round } from "../../../services/roundingService";
import { getSortedItemsV2 } from "../../../services/sortingService";
import {
  IInvoiceFormSelectedWork,
  IInvoiceJobInstance,
  IInvoiceProject,
} from "../forms/InvoiceForm.types";
import { ReadOnlyFile } from "./files/ReadOnlyFile";
import Lightbox from "./Lightbox";
import LinkButton2 from "./LinkButton2";
import { isStringSet } from "../../../services/stringService";
import ResponsiveTable from "../../../libraries/tableLayout/ResponsiveTable";
import { ResponsiveTableMobileCard } from "../../../libraries/tableLayout/ResponsiveTableMobileCard";
import { TableColumns } from "../../../libraries/tableLayout/TableColumn";
import RowProperties from "../../../libraries/tableLayout/rowProperties";
import { ResponsiveTableMobileCardLabel } from "../../../libraries/tableLayout/ResponsiveTableMobileCardLabel";
import { ResponsiveTableMobileCardValue } from "../../../libraries/tableLayout/ResponsiveTableMobileCardValue";
import { ICrew } from "../../../models/ICrew";

type ProjectRow = IInvoiceProject & {
  type: "project";
} & RowProperties;

type JobInstanceRow = IInvoiceJobInstance & {
  type: "job";
  isProjectVisit: boolean;
} & RowProperties;

interface IProps {
  jobInstances: Array<IInvoiceJobInstance>;
  projects: Array<IInvoiceProject>;
  billingDetails: Array<IBillingReportJobInstanceDetails>;
  selectedWorkToBill: IInvoiceFormSelectedWork;
  setSelectedWorkToBill: (newValue: IInvoiceFormSelectedWork) => void;
  addedFiles: Array<IPhoto>;
  onAddFile(file: IPhoto): void;
}

const InvoiceWork: React.FunctionComponent<IProps> = ({
  jobInstances,
  billingDetails,
  selectedWorkToBill,
  setSelectedWorkToBill,
  addedFiles,
  onAddFile,
  projects,
}) => {
  const [jobsToShowDetailsFor, setJobsToShowDetailsFor] = useState<
    Array<string>
  >([]);
  const [projectsToShowVisitsFor, setProjectsToShowVisitsFor] = useState<
    Array<string>
  >([]);
  const crews = useApplicationStateSelector((s) => s.crew.crews);

  const purchaseOrderNumberSet =
    jobInstances.some((ji) => isStringSet(ji.purchaseOrderNumber)) ||
    projects.some((project) =>
      project.jobInstances.some((ji) => isStringSet(ji.purchaseOrderNumber))
    );

  const combinedRows = [
    ...projects.flatMap((project) => {
      let result: Array<ProjectRow | JobInstanceRow> = [
        {
          ...project,
          type: "project",
        } as ProjectRow,
      ];

      if (areDetailsShown(projectsToShowVisitsFor, project)) {
        result = [
          ...result,
          ...project.jobInstances.map((j) =>
            getJobInstanceRow({
              job: j,
              jobsToShowDetailsFor,
              billingDetails,
              onAddFile,
              addedFiles,
              isProjectVisit: true,
            })
          ),
        ];
      }

      return result;
    }),
    ...getSortedItemsV2(jobInstances, ["date", "address"]).map((j) =>
      getJobInstanceRow({
        job: j,
        jobsToShowDetailsFor,
        billingDetails,
        onAddFile,
        addedFiles,
        isProjectVisit: false,
      })
    ),
  ];

  const columns: TableColumns<ProjectRow | JobInstanceRow> = [
    {
      key: "date",
      testId: "date",
      header: "Date",
      cell: ({ row: job }) =>
        job.type === "project" ? (
          <ProjectDates project={job} />
        ) : (
          dateService.formatDateForDisplay(job.date)
        ),
    },
    {
      key: "address",
      testId: "address",
      header: "Address",
      cell: "address",
    },
    {
      key: "services",
      testId: "services",
      header: "Service(s)",
      cell: "services",
    },
    {
      key: "purchaseOrderNumber",
      testId: "purchaseOrderNumber",
      header: "PO #",
      cell: ({ row: job }) =>
        job.type === "project"
          ? getPurchaseOrderNumbersForProject(job)
          : job.purchaseOrderNumber,
      hidden: !purchaseOrderNumberSet,
    },
    {
      key: "totalHours",
      testId: "totalHours",
      header: "Total Hrs",
      cell: ({ row: job }) =>
        job.type === "project"
          ? getTotalHoursForJobInstances(job.jobInstances)
          : typeof job.totalHours === "number"
          ? round(job.totalHours, 1)
          : "",
    },
    {
      key: "totalAmount",
      testId: "amountPerVisit",
      header: "Total $",
      cell: ({ row: job }) =>
        job.type === "project"
          ? typeof job.amount === "number"
            ? formatCurrency(job.amount)
            : ""
          : typeof job.grossRevenuePerVisit === "number"
          ? formatCurrency(job.grossRevenuePerVisit)
          : "",
    },
    {
      header: "Details",
      key: "details",
      cell: ({ row: job }) => {
        if (
          job.type === "job" &&
          doesJobHaveDetails({
            billingNotesForJob: getBillingNotesForJob(billingDetails, job),
            jobInstance: job,
            crews,
          }).hasAnyTypeOfResponse
        ) {
          return (
            <LinkButton2
              buttonContents={
                areDetailsShown(jobsToShowDetailsFor, job) ? "Close" : "View"
              }
              onClick={() => {
                toggleDetailsVisibility(
                  jobsToShowDetailsFor,
                  job,
                  setJobsToShowDetailsFor
                );
              }}
              testId="viewJobDetails"
              inlineButton
            />
          );
        } else if (job.type === "project" && job.jobInstances.length > 0) {
          return (
            <LinkButton2
              buttonContents={
                areDetailsShown(projectsToShowVisitsFor, job)
                  ? "Hide visits"
                  : "Show visits"
              }
              onClick={() => {
                toggleDetailsVisibility(
                  projectsToShowVisitsFor,
                  job,
                  setProjectsToShowVisitsFor
                );
              }}
            />
          );
        }
      },
    },
    {
      key: "linkToInvoice",
      header: "Link to invoice",
      testId: "linkToInvoice",
      hidden: ({ row }) => row.type === "job" && row.isProjectVisit,
      cell: ({ displayType, row: job }) => {
        let checked: boolean;
        let onChanged: (newChecked: boolean) => void;
        if (job.type === "project") {
          checked = !!selectedWorkToBill.selectedProjects.find(
            (s) => s === job.id
          );

          onChanged = (newChecked) => {
            if (newChecked) {
              setSelectedWorkToBill({
                ...selectedWorkToBill,
                selectedProjects: [
                  ...selectedWorkToBill.selectedProjects,
                  job.id,
                ],
              });
            } else {
              setSelectedWorkToBill({
                ...selectedWorkToBill,
                selectedProjects: selectedWorkToBill.selectedProjects.filter(
                  (s) => s !== job.id
                ),
              });
            }
          };
        } else {
          checked = !!selectedWorkToBill.selectedJobInstances.find(
            (s) => s === job.id
          );

          onChanged = (newChecked) => {
            if (newChecked) {
              setSelectedWorkToBill({
                ...selectedWorkToBill,
                selectedJobInstances: [
                  ...selectedWorkToBill.selectedJobInstances,
                  job.id,
                ],
              });
            } else {
              setSelectedWorkToBill({
                ...selectedWorkToBill,
                selectedJobInstances:
                  selectedWorkToBill.selectedJobInstances.filter(
                    (s) => s !== job.id
                  ),
              });
            }
          };
        }

        const linkCheckboxId = `link_${displayType}_${job.id}`;
        return (
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              checked={checked}
              onChange={(e) => {
                onChanged(e.currentTarget.checked);
              }}
              id={linkCheckboxId}
            ></input>
            <label className="custom-control-label" htmlFor={linkCheckboxId}>
              <span className="sr-only">Link to invoice</span>
            </label>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <h5>Work To Invoice</h5>
      <ResponsiveTable
        rows={combinedRows}
        tableClass="table-sm"
        columns={columns}
        renderMobile={({ row, index }) => (
          <ResponsiveTableMobileCard
            columns={columns}
            row={row}
            rowIndex={index}
          />
        )}
      />
    </>
  );
};

export default InvoiceWork;

function getJobInstanceRow({
  job: j,
  jobsToShowDetailsFor,
  billingDetails,
  onAddFile,
  addedFiles,
  isProjectVisit,
}: {
  job: IInvoiceJobInstance;
  jobsToShowDetailsFor: string[];
  billingDetails: IBillingReportJobInstanceDetails[];
  onAddFile: (file: IPhoto) => void;
  addedFiles: IPhoto[];
  isProjectVisit: boolean;
}): JobInstanceRow {
  return {
    ...j,
    type: "job",
    isProjectVisit,
    contentBelowRow: areDetailsShown(jobsToShowDetailsFor, j) ? (
      <JobDetails
        billingDetails={billingDetails}
        jobInstance={j}
        onAddFile={onAddFile}
        addedFiles={addedFiles}
      />
    ) : null,
  } as JobInstanceRow;
}

function toggleDetailsVisibility(
  jobsToShowDetailsFor: string[],
  job: { id: string },
  setJobsToShowDetailsFor: React.Dispatch<React.SetStateAction<string[]>>
) {
  jobsToShowDetailsFor.includes(job.id)
    ? setJobsToShowDetailsFor(jobsToShowDetailsFor.filter((j) => j !== job.id))
    : setJobsToShowDetailsFor([...jobsToShowDetailsFor, job.id]);
}

function areDetailsShown(jobsToShowDetailsFor: string[], job: { id: string }) {
  return jobsToShowDetailsFor.includes(job.id);
}

function getPurchaseOrderNumbersForProject(project: IInvoiceProject) {
  return project.jobInstances
    .filter((ji) => isStringSet(ji.purchaseOrderNumber))
    .map((ji) => ji.purchaseOrderNumber)
    .join(", ");
}

function ProjectDates({ project }: { project: IInvoiceProject }) {
  let firstDate = "",
    lastDate = "";
  if (project.jobInstances.length > 0) {
    const sortedJobInstances = getSortedItemsV2(project.jobInstances, ["date"]);
    firstDate = sortedJobInstances[0].date;
    lastDate = sortedJobInstances[sortedJobInstances.length - 1].date;
  }

  return (
    <>
      {firstDate !== lastDate
        ? `${dateService.formatDateForDisplay(
            firstDate
          )} - ${dateService.formatDateForDisplay(lastDate)}`
        : dateService.formatDateForDisplay(firstDate)}
    </>
  );
}

function JobDetails({
  billingDetails,
  jobInstance,
  addedFiles,
  onAddFile,
}: {
  billingDetails: Array<IBillingReportJobInstanceDetails>;
  jobInstance: IInvoiceJobInstance;
  addedFiles: Array<IPhoto>;
  onAddFile(file: IPhoto): void;
}) {
  const [lightboxPhotoIndex, setLightboxPhotoIndex] = useState<number | null>(
    null
  );
  const imagePrefix = useApplicationStateSelector((s) => s.common.imagePrefix);
  const crews = useApplicationStateSelector((s) => s.crew.crews);

  const billingNotesForJob = getBillingNotesForJob(billingDetails, jobInstance);
  const {
    hasNotesFromCrew,
    hasAdminOnlyNotes,
    hasNotesForCrew,
    hasCustomRequests,
    hasChecklistItems,
    hasTimeRanges,
    hasPhotos,
    crewExists,
  } = doesJobHaveDetails({ billingNotesForJob, jobInstance, crews });

  const noteDivStyle: React.CSSProperties = {
    marginBottom: "10px",
    marginRight: "20px",
    whiteSpace: "pre-wrap",
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {crewExists ? (
          <div style={noteDivStyle}>
            <ResponsiveTableMobileCardLabel>
              Crew
            </ResponsiveTableMobileCardLabel>
            <ResponsiveTableMobileCardValue>
              {crews.find((c) => c.id === billingNotesForJob?.crewId)?.name}
            </ResponsiveTableMobileCardValue>
          </div>
        ) : null}

        {hasNotesFromCrew ? (
          <div style={noteDivStyle}>
            <ResponsiveTableMobileCardLabel>
              Crew notes
            </ResponsiveTableMobileCardLabel>
            <ResponsiveTableMobileCardValue>
              {billingNotesForJob?.notesFromCrew}
            </ResponsiveTableMobileCardValue>
          </div>
        ) : null}

        {hasAdminOnlyNotes ? (
          <div style={noteDivStyle}>
            <ResponsiveTableMobileCardLabel>
              Administrator only notes
            </ResponsiveTableMobileCardLabel>
            <ResponsiveTableMobileCardValue>
              {billingNotesForJob?.administratorOnlyNotes}
            </ResponsiveTableMobileCardValue>
          </div>
        ) : null}

        {hasNotesForCrew ? (
          <div style={noteDivStyle}>
            <ResponsiveTableMobileCardLabel>
              Instructions for crew
            </ResponsiveTableMobileCardLabel>
            <ResponsiveTableMobileCardValue>
              {billingNotesForJob?.notesForCrew}
            </ResponsiveTableMobileCardValue>
          </div>
        ) : null}

        {hasCustomRequests
          ? billingNotesForJob?.customCrewQuestionResponses.map((r) => (
              <div key={r.questionText} style={noteDivStyle}>
                <ResponsiveTableMobileCardLabel>
                  {r.questionText}
                </ResponsiveTableMobileCardLabel>
                <ResponsiveTableMobileCardValue>
                  {r.answer}
                </ResponsiveTableMobileCardValue>
              </div>
            ))
          : null}

        {hasChecklistItems ? (
          <div style={noteDivStyle}>
            <ResponsiveTableMobileCardLabel>
              Checklist
            </ResponsiveTableMobileCardLabel>
            {billingNotesForJob?.todoItems.map((r) => (
              <div key={r.text}>
                <div
                  className="custom-control custom-checkbox"
                  style={{ display: "inline-block" }}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={r.text + "_cb"}
                    defaultChecked={r.completed}
                    disabled
                  />
                  <label
                    className="custom-control-label text-dark"
                    htmlFor={r.text + "_cb"}
                  >
                    {r.text}
                  </label>
                </div>
              </div>
            ))}
          </div>
        ) : null}

        {hasTimeRanges ? (
          <div style={noteDivStyle}>
            <ResponsiveTableMobileCardLabel>
              Time range(s) worked
            </ResponsiveTableMobileCardLabel>
            <ResponsiveTableMobileCardValue>
              {jobInstance.timeRanges
                .filter((tr) => !!tr.startTime && !!tr.endTime)
                .map((tr) => (
                  <div key={tr.id}>
                    {dateService.formatTimeForDisplay(tr.startTime as string)} -{" "}
                    {dateService.formatTimeForDisplay(tr.endTime as string)}
                  </div>
                ))}
            </ResponsiveTableMobileCardValue>
          </div>
        ) : null}
      </div>

      {hasPhotos && imagePrefix ? (
        <>
          <div>
            <strong>Crew attachments</strong>
          </div>
          <div
            className="d-flex flex-wrap align-items-stretch"
            style={{
              gap: "20px",
            }}
          >
            {billingNotesForJob?.photos.map((file, fileIndex) => {
              const fileAlreadyAdded = addedFiles.some((f) => f.id === file.id);
              return (
                <div
                  key={file.id}
                  className="d-flex flex-column justify-content-between"
                >
                  <ReadOnlyFile
                    onOpenLightbox={() => {
                      setLightboxPhotoIndex(fileIndex);
                    }}
                    file={file}
                    imagePrefix={imagePrefix}
                    divStyle={{ marginBottom: undefined }}
                    thumbnailKey={280}
                    forcedThumbnailWidth={150}
                  />
                  <div className="text-center mt-2">
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary"
                      data-testid="addFileToInvoice"
                      onClick={() => {
                        onAddFile(file);
                      }}
                      disabled={fileAlreadyAdded}
                      style={{
                        cursor: fileAlreadyAdded ? "default" : undefined,
                      }}
                    >
                      {fileAlreadyAdded ? "Added!" : "Add to Invoice"}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : null}
      {typeof lightboxPhotoIndex === "number" ? (
        <Lightbox
          itemIndex={lightboxPhotoIndex}
          setItemIndex={(newIndex) => setLightboxPhotoIndex(newIndex)}
          onClose={() => setLightboxPhotoIndex(null)}
          items={
            billingNotesForJob?.photos.map((p) => ({
              ...p,
              caption: p.caption,
              src: `${imagePrefix}/${p.imagePath}`,
            })) ?? []
          }
        />
      ) : null}
    </>
  );
}

function getBillingNotesForJob(
  billingDetails: IBillingReportJobInstanceDetails[],
  jobInstance: IInvoiceJobInstance
) {
  return billingDetails.find((d) => d.jobInstanceId === jobInstance.id);
}

function doesJobHaveDetails({
  billingNotesForJob,
  jobInstance,
  crews,
}: {
  billingNotesForJob: IBillingReportJobInstanceDetails | undefined;
  jobInstance: IInvoiceJobInstance;
  crews: Array<ICrew>;
}) {
  const hasNotes = (s: string | undefined | null) => s && s.trim();
  const hasNotesFromCrew = hasNotes(billingNotesForJob?.notesFromCrew);
  const hasNotesForCrew = hasNotes(billingNotesForJob?.notesForCrew);
  const hasAdminOnlyNotes = hasNotes(
    billingNotesForJob?.administratorOnlyNotes
  );

  let hasCustomRequests: boolean = false;
  if (
    billingNotesForJob &&
    billingNotesForJob.customCrewQuestionResponses &&
    billingNotesForJob.customCrewQuestionResponses.length > 0
  ) {
    hasCustomRequests = true;
  }

  let hasChecklistItems: boolean = false;
  if (
    billingNotesForJob &&
    billingNotesForJob.todoItems &&
    billingNotesForJob.todoItems.length > 0
  ) {
    hasChecklistItems = true;
  }

  let hasTimeRanges = (jobInstance.timeRanges ?? []).some(
    (tr) => tr.startTime || tr.endTime
  );
  const hasPhotos = (billingNotesForJob?.photos ?? []).length > 0;

  const crewExists =
    isStringSet(billingNotesForJob?.crewId) &&
    crews.some((c) => c.id === billingNotesForJob?.crewId);

  const hasAnyTypeOfResponse =
    hasNotesFromCrew ||
    hasNotesForCrew ||
    hasAdminOnlyNotes ||
    hasCustomRequests ||
    hasChecklistItems ||
    hasTimeRanges ||
    hasPhotos ||
    crewExists;
  return {
    hasAnyTypeOfResponse,
    hasNotesFromCrew,
    hasAdminOnlyNotes,
    hasNotesForCrew,
    hasCustomRequests,
    hasChecklistItems,
    hasTimeRanges,
    hasPhotos,
    crewExists,
  };
}
