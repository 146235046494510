import { useCallback, useEffect, useMemo, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import constants from "../../../constants";
import Spinner from "../../../containers/app/components/Spinner";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { logError } from "../../../services/errorLogger";
import {
  isClearAllCardsPrevented,
  preventClearClass,
} from "../../../services/selectedCardService";
import { OpportunityStatus } from "../enums/opportunityStatus";
import { IOpportunityBoard } from "../models/IOpportunityBoard";
import {
  opportunitiesActionCreators,
  OpportunityLoadStatus,
} from "../modules/opportunity";
import {
  getNameToShow,
  getAssignedToOptions,
  getOpportunitiesForLane,
  getOpportunityLaneKey,
} from "../services/opportunityService";
import OpportunitiesLane from "./OpportunitiesLane";
import OpportunitiesLoadError from "./OpportunitiesLoadError";
import { IOpportunity } from "../models/IOpportunity";
import ActionBar, {
  actionBarButtonClassName,
} from "../../../containers/app/components/ActionBar";
import { fullStoryTrack } from "../../../services/fullStoryService";
import { useUserSettings } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";

const Opportunities: React.FunctionComponent<{
  opportunityToFocus: string | undefined;
}> = ({ opportunityToFocus }) => {
  const dispatch = useDispatch();
  const opportunityLoadStatus = useApplicationStateSelector(
    (s) => s.opportunity.opportunityLoadStatus
  );
  const board = useApplicationStateSelector((s) => s.opportunity.board);
  const opportunitiesSelected = useApplicationStateSelector(
    (s) => s.opportunity.opportunitiesSelected
  );
  const opportunities = useApplicationStateSelector(
    (s) => s.opportunity.opportunities
  );
  const pageErrorMessage = useApplicationStateSelector(
    (s) => s.opportunity.pageErrorMessage
  );
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );

  const { assignedToFilter, setAssignedToFilter } = useGetAssignedToFilter();

  useEffect(() => {
    if (opportunityLoadStatus === OpportunityLoadStatus.notStarted) {
      dispatch(opportunitiesActionCreators.loadOpportunitiesStart());
    }
  }, [dispatch, opportunityLoadStatus]);

  const clearSelectedCard = useCallback(
    (event: MouseEvent) => {
      if (
        !isClearAllCardsPrevented(event) &&
        opportunitiesSelected.length > 0
      ) {
        dispatch(opportunitiesActionCreators.clearSelectedOpportunity());
      }
    },
    [dispatch, opportunitiesSelected]
  );

  useEffect(() => {
    document.addEventListener("mousedown", clearSelectedCard);
    document.addEventListener("click", clearSelectedCard);

    return function cleanup() {
      document.removeEventListener("mousedown", clearSelectedCard);
      document.removeEventListener("click", clearSelectedCard);
    };
  }, [clearSelectedCard]);

  if (
    opportunityLoadStatus === OpportunityLoadStatus.notStarted ||
    opportunityLoadStatus === OpportunityLoadStatus.started
  ) {
    return <Spinner />;
  } else if (opportunityLoadStatus === OpportunityLoadStatus.error) {
    return <OpportunitiesLoadError />;
  }

  return (
    <>
      {pageErrorMessage ? (
        <div className="board-sticky-banner" role="alert">
          <div className="alert alert-dismissible fade show alert-danger">
            {pageErrorMessage}
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() => dispatch(opportunitiesActionCreators.clearError())}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
      ) : null}

      <AssignedToFilter
        assignedTo={assignedToFilter}
        onAssignedToChange={(v) => setAssignedToFilter(v)}
      />

      <div className="row">
        <DragDropContext
          onDragEnd={(result) => {
            let action = getDropAction({ result, board, opportunities });

            if (action !== null) {
              dispatch(action);
            }

            // Add instrumentation to see if users are wanting to drag/drop multiple jobs
            // Not adding support for now since it takes a bit of effort and not sure actually
            // wanted by users.
            if (opportunitiesSelected.length > 1) {
              fullStoryTrack(
                "Opportunities - Dropped with multiple selected items"
              );
            }
          }}
        >
          <OpportunitiesLane
            header="To Do"
            status={OpportunityStatus.Todo}
            opportunityToFocus={opportunityToFocus}
            assignedToFilter={assignedToFilter}
          />

          <OpportunitiesLane
            header="In Progress"
            status={OpportunityStatus.InProgress}
            opportunityToFocus={opportunityToFocus}
            assignedToFilter={assignedToFilter}
          />

          <OpportunitiesLane
            header="Proposal"
            status={OpportunityStatus.Proposal}
            opportunityToFocus={opportunityToFocus}
            showExpiredFilter
            assignedToFilter={assignedToFilter}
          />
        </DragDropContext>
      </div>

      {opportunitiesSelected.length > 0 ? (
        <ActionBar className={preventClearClass}>
          <button
            className={`btn btn-${actionBarButtonClassName} ${preventClearClass}`}
            data-testid="archive"
            onClick={() => {
              dispatch(
                opportunitiesActionCreators.showArchivePrompt({
                  opportunitiesToArchive: opportunitiesSelected.map(
                    (opportunityId) => {
                      let customerName = "";

                      const opportunity = opportunities.find(
                        (o) => o.id === opportunityId
                      );
                      if (opportunity) {
                        const { nameToShow } = getNameToShow(
                          customers,
                          opportunity,
                          customerAdditionalLocations
                        );
                        customerName = nameToShow;
                      }

                      return {
                        opportunityId,
                        customerName,
                      };
                    }
                  ),
                })
              );
            }}
          >
            Archive
          </button>
        </ActionBar>
      ) : null}
    </>
  );
};

export default Opportunities;

function useGetAssignedToFilter() {
  const { getUserSettings } = useUserSettings();

  const userAccounts = useApplicationStateSelector(
    (s) => s.common.userAccounts
  );

  const availableUserAccounts = useMemo(
    () => getAssignedToOptions(userAccounts),
    [userAccounts]
  );

  const defaultUserAccount =
    getUserSettings<string>(UserSettingsType.salesPageAssignedToFilter) ?? "";

  const [assignedToFilter, setAssignedToFilter] = useState(defaultUserAccount);

  // Ensure the filtered user account still exists
  useEffect(() => {
    if (!doesUserAccountExist(assignedToFilter, availableUserAccounts)) {
      setAssignedToFilter("");
    }
  }, [assignedToFilter, availableUserAccounts]);

  return { assignedToFilter, setAssignedToFilter };
}

function doesUserAccountExist(
  userAccountToCheck: string,
  userAccounts: Array<{ id: string }>
) {
  return (
    userAccountToCheck === "" ||
    userAccounts.some((ua) => ua.id === userAccountToCheck)
  );
}

export function getDropAction({
  result,
  board,
  opportunities,
}: {
  result: DropResult;
  board: IOpportunityBoard;
  opportunities: Array<IOpportunity>;
}) {
  if (!result?.destination) {
    return null;
  }

  const hasPositionChanged =
    result.source.droppableId !== result.destination.droppableId ||
    result.source.index !== result.destination.index;

  if (!hasPositionChanged) {
    return null;
  }

  const destinationStatus = parseInt(
    result.destination.droppableId
  ) as OpportunityStatus;

  const destinationLane = getOpportunityLaneKey(destinationStatus);
  if (destinationLane === null) {
    logError(`unable to find destination lane for ${destinationStatus}`);
    return null;
  }

  let precedingOpportunityId: string;
  if (result.destination.index === 0) {
    precedingOpportunityId = constants.idForFirstOpportunity;
  } else {
    const destinationOpportunities = getOpportunitiesForLane(
      opportunities,
      board,
      destinationLane
    );

    let precedingJobIndex: number;
    if (
      result.source.droppableId !== result.destination.droppableId ||
      result.source.index > result.destination.index
    ) {
      precedingJobIndex = result.destination.index - 1;
    } else {
      precedingJobIndex = result.destination.index;
    }

    if (precedingJobIndex < destinationOpportunities.length) {
      precedingOpportunityId = destinationOpportunities[precedingJobIndex].id;
    } else {
      logError(
        `precedingJobIndex is out of bounds when moving ${result.draggableId}`
      );
      return null;
    }
  }

  return opportunitiesActionCreators.dropOpportunity({
    opportunityId: result.draggableId,
    precedingOpportunityId,
    status: destinationStatus,
  });
}

function AssignedToFilter({
  assignedTo,
  onAssignedToChange,
}: {
  assignedTo: string;
  onAssignedToChange(newValue: string): void;
}) {
  const userAccounts = useApplicationStateSelector(
    (s) => s.common.userAccounts
  );
  const { setUserSettings } = useUserSettings();

  return (
    <>
      <div className="form-group" style={{ maxWidth: "500px" }}>
        <label htmlFor="assignedToFilter">Assigned to</label>
        <select
          id="assignedToFilter"
          className="form-control"
          value={assignedTo}
          onChange={(e) => {
            const newValue = e.currentTarget.value;
            onAssignedToChange(newValue);
            setUserSettings(
              UserSettingsType.salesPageAssignedToFilter,
              newValue
            );
          }}
        >
          <option value="">Everyone</option>
          {getAssignedToOptions(userAccounts).map((ua) => (
            <option key={ua.id} value={ua.id}>
              {ua.label}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
