import React from "react";
import Spinner from "./Spinner";
import SpinnerNoBlock from "./SpinnerNoBlock";

interface IProps {
  header: string | null;
  dataType: string;
  dataLength: number | null;
  errorLoading: boolean;
  errorMessage?: string | null;
  loadingData: boolean;
  refreshData: () => void;
  filter: React.ReactNode;
  largeHeader?: boolean;
  dataLoadNotStarted?: boolean;
  dataNotLoadedContent?: React.ReactNode;
  noRecordsMessage?: string;
  hasFilterError?: boolean;
  hasInitialLoadCompleted: boolean;
  showContentWhileRefreshing: boolean;
}

const ServerLoadedContent: React.FunctionComponent<IProps> = ({
  header,
  largeHeader,
  refreshData,
  filter,
  errorLoading,
  errorMessage,
  loadingData,
  dataType,
  dataLength,
  dataLoadNotStarted,
  dataNotLoadedContent,
  noRecordsMessage,
  children,
  hasFilterError,
  hasInitialLoadCompleted,
  showContentWhileRefreshing,
}) => {
  return (
    <>
      {header !== null ? (
        <div>{largeHeader ? <h1>{header}</h1> : <h5>{header}</h5>}</div>
      ) : null}
      <div className="mb-3">{filter}</div>
      {loadingData ? (
        hasInitialLoadCompleted && showContentWhileRefreshing ? (
          <Spinner />
        ) : (
          <SpinnerNoBlock />
        )
      ) : null}
      {errorLoading && !hasFilterError && !loadingData ? (
        <div className="text-danger">
          {!errorMessage ? (
            <>
              <div>
                The {dataType} could not load. Please check your Internet
                connection.
              </div>
              <div style={{ marginTop: "20px" }}>
                <button
                  className="btn btn-link"
                  style={{ paddingLeft: 0 }}
                  onClick={() => refreshData()}
                >
                  Try Again
                </button>
              </div>
            </>
          ) : (
            errorMessage
          )}
        </div>
      ) : null}
      {dataLoadNotStarted ? dataNotLoadedContent : null}
      {hasInitialLoadCompleted &&
      (showContentWhileRefreshing || !loadingData) &&
      !errorLoading &&
      !dataLoadNotStarted &&
      !hasFilterError ? (
        (dataLength ?? 0) > 0 ? (
          children
        ) : (
          <div className="font-weight-bold text-center text-sm-left mt-2 mt-sm-0 pb-4">
            {noRecordsMessage ? noRecordsMessage : `No ${dataType} were found.`}
          </div>
        )
      ) : null}
    </>
  );
};

export default ServerLoadedContent;
