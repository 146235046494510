import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { finalize } from "rxjs/operators";
import { IInvitation } from "../../../models/IInvitation";
import dataProvider from "../../../services/dataProvider";
import Prompt from "./Prompt";

interface IProps {
  invitation: IInvitation;
  currentUserIsTenantOwner: boolean;
  reloadInvitations(): void;
  setFormWaiting(waiting: boolean): void;
  setErrorMessage(message: string): void;
}

const ManageAdminUsersInvitedDelete: React.FunctionComponent<IProps> = ({
  invitation,
  currentUserIsTenantOwner,
  reloadInvitations,
  setErrorMessage,
  setFormWaiting,
}) => {
  const [showPrompt, setShowPrompt] = useState(false);

  return (
    <>
      <Prompt
        showPrompt={showPrompt}
        promptMessage={`Are you sure you want to delete the invitation for ${invitation.emailAddress}?`}
        onConfirm={() => {
          setErrorMessage("");
          setFormWaiting(true);
          setShowPrompt(false);

          dataProvider
            .deleteInvitation(invitation.id)
            .pipe(finalize(() => setFormWaiting(false)))
            .subscribe(
              () => {
                reloadInvitations();
              },
              (err: any) => {
                setErrorMessage(
                  "An unknown error occurred when deleting the invitation."
                );
              }
            );
        }}
        onCancel={() => setShowPrompt(false)}
      />
      {currentUserIsTenantOwner ? (
        <div className="text-right mb-2">
          <button
            className="btn btn-link m-0 p-0 text-dark"
            onClick={() => setShowPrompt(true)}
          >
            <FontAwesomeIcon icon={faTrash} title="Delete" />
          </button>
        </div>
      ) : null}
    </>
  );
};

export default ManageAdminUsersInvitedDelete;
