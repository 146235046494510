import React from "react";
import { useContractBillingEnabled } from "../../../../hooks/useContractBillingEnabled";
import { useCreditCardsEnabled } from "../../../../hooks/useCreditCardsEnabled";
import { ICustomer } from "../../../../models/ICustomer";
import CustomerBillingHistory from "./CustomerBillingHistory";
import CustomerContractBilling from "./CustomerContractBilling";
import CustomerPaymentMethod from "./CustomerPaymentMethod";

interface IProps {
  customer: ICustomer;
  visible: boolean;
}

const CustomerBilling: React.FunctionComponent<IProps> = ({
  customer,
  visible,
}) => {
  const { areCreditCardsEnabled } = useCreditCardsEnabled();
  const contractBillingEnabled = useContractBillingEnabled();

  return (
    <>
      {contractBillingEnabled ? (
        <>
          <div className="row mt-3">
            <div className="col-sm-12 col-md-6 mb-4">
              <CustomerContractBilling customer={customer} visible={visible} />
            </div>
            {areCreditCardsEnabled ? (
              <div className="col-sm-12 col-md-6 mb-4">
                <CustomerPaymentMethod customer={customer} visible={visible} />
              </div>
            ) : null}
          </div>
          <hr className="mt-3 mb-3" />
        </>
      ) : null}

      <div className="row mt-3">
        <div className="col-12">
          <CustomerBillingHistory customerId={customer.id} />
        </div>
      </div>
    </>
  );
};

export default CustomerBilling;
