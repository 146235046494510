import { SortDirection } from "../../../../enums/sortDirection";

export enum SortColumns {
  CustomerName = 0,
  StreetNumber = 1,
  StreetName = 2,
  City = 3,
  State = 4,
  Zip = 5,
  PhoneNumber = 6,
  ServicesProvided = 7,
}

export type Sort = {
  column: SortColumns;
  direction: SortDirection;
};
