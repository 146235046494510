import { IProposalExisting } from "../models/IProposal";
import { IFormData } from "./ProposalForm/IFormData";
import { parseSourceText } from "../../../services/richTextService";
import { createNewLineItem } from "../../../services/invoiceFormService";
import { mapToFormLineItems } from "../services/proposalService";
import getCustomerFields from "./ProposalForm/getCustomerFields";
import { ICustomer } from "../../../models/ICustomer";
import dateService from "../../../services/dateService";
import { DiscountType } from "../../../enums/DiscountType";
import { isDepositRequired } from "../services/proposalService";

export function getCopyProposalDefaultValues(
  proposal: IProposalExisting,
  customer: ICustomer
): IFormData {
  const customerFields = getCustomerFields(customer);
  return {
    deliveryMethod: proposal.deliveryMethod,
    proposalDate: dateService.formatAsIso(dateService.getCurrentDate()),
    validUntilDate: "",
    amountAdjustments: {
      taxRate: proposal.taxRate,
      discount: proposal.discount ?? {
        type: DiscountType.percent,
        amount: null,
        percent: null,
      },
    },
    showLineItemPrices: !proposal.hideLineItemPrices,
    lineItems: mapToFormLineItems(proposal.lineItems)?.map((li) => {
      return { ...li, selected: false };
    }) ?? [createNewLineItem(1)],
    emailFields: {
      replyToEmailAddress: "",
      customerEmailAddresses: customerFields.customerEmailAddresses,
    },
    customerPhoneNumber: customerFields.customerPhoneNumber,
    customerPhoneNumberOptedIntoSms:
      customerFields.customerPhoneNumberOptedIntoSms,
    files: proposal.files.map((f) => ({
      ...f,
      actualWidth: null,
      actualHeight: null,
    })),
    depositSettings: proposal.depositSettings ?? null,
    depositRequired: isDepositRequired(proposal?.depositSettings),
    addConvenienceFee: proposal.addConvenienceFee,
    introText: parseSourceText(proposal.introText),
    footerText: parseSourceText(proposal.footerText),
    depositItem: {
      itemId: proposal.depositItemId,
      name: null,
      description: null,
    },
    quickBooksCustomerId: customer.quickBooksId,
    captureCustomerPaymentMethod: proposal.captureCustomerPaymentMethod,
    captureCustomerPaymentMethodOptional:
      proposal.captureCustomerPaymentMethodOptional,
    summary: "",
    subject: proposal.subject,
  };
}
