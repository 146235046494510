import { ITimeRange } from "../../../models/ITimeRange";
import { ILineItem } from "../components/InvoiceLineItem";
import { EmailSendValue } from "../components/EmailSendFields";
import { JobType } from "../../../models/IJob";
import { EditorState } from "draft-js";
import { PaymentMethodOptions } from "../../../slices/billing/components/InvoicePaymentMethodSelection";
import { IInvoiceAmountAdjustments } from "../../../slices/sales/components/ProposalForm/IFormData";
import { IFormDataFile } from "../components/files/ExistingFile";
import { actionCreators } from "../../../modules/actionCreators";

export const genericLoadErrorMessage = "Unable to load form data.";
export const formActionCreators = actionCreators.forms.invoice;

export interface IInvoiceJobInstance {
  id: string;
  jobId: string | null;
  projectId: string | null;
  jobType: JobType | null;
  date: string;
  address: string;
  grossRevenuePerVisit: number | undefined;
  totalHours?: number;
  services: string;
  timeRanges: Array<ITimeRange>;
  customerAdditionalLocationId: string | null;
  purchaseOrderNumber: string;
}

export interface IInvoiceProject {
  id: string;
  totalHours?: number;
  services: string;
  address: string;
  amount: number | null;
  jobInstances: Array<IInvoiceJobInstance & { actualManHours?: number }>;
}

export interface IInvoiceFormSelectedWork {
  selectedJobInstances: Array<string>;
  selectedProjects: Array<string>;
}

export type LineItemForForm = ILineItem & {
  defaultedFromJob?: boolean;
  sourceId?: string | null;
};

export interface IDeliveryMethodDetails {
  invoiceDeliveryMethod: string;
  emailFields: EmailSendValue;
  customerPhoneNumber: string;
  customerPhoneNumberOptedIntoSms: boolean;
}

export type LineItemByLocation = {
  customerAdditionalLocationId: string | null;
  lineItems: Array<LineItemForForm>;
};
export interface IInvoiceFormData {
  customerId: string;
  quickBooksCustomerId: string;
  startingDate: string;
  dueDate: string;
  lineItemsByLocation: Array<LineItemByLocation>;
  deliveryMethodDetails: IDeliveryMethodDetails;
  selectedWorkToBill: IInvoiceFormSelectedWork;
  paymentMethodOptions: PaymentMethodOptions;
  introTextState: EditorState;
  footerTextState: EditorState;
  purchaseOrderNumber: string;
  amountAdjustments: IInvoiceAmountAdjustments;
  files: Array<IFormDataFile>;
  showLineItemPrices: boolean;
  summary: string;
}
