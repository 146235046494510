import { IJob, JobType } from "../../../../../models/IJob";
import { IJobInstance } from "../../../../../models/IJobInstance";
import { NoteWithHyperlinks } from "../../NoteWithHyperlinks";

export function JobCardNotes({
  jobInstance,
  job,
  jobType,
  spacingClass = "my-3",
  useWhiteText,
}: {
  jobInstance: IJobInstance;
  job: IJob;
  jobType: JobType;
  spacingClass?: "my-3" | "my-1";
  useWhiteText: boolean;
}) {
  const linkClassName = useWhiteText ? "text-white" : "";
  return (
    <>
      {(jobInstance.administratorOnlyNotes || "").trim() ? (
        <NoteWithHyperlinks
          containerClassName={spacingClass}
          notes={jobInstance.administratorOnlyNotes}
          linkClassName={linkClassName}
        />
      ) : null}

      {jobInstance.showCrewNotesOnAdminJobCards &&
      (jobInstance.jobNotes || "").trim() ? (
        <NoteWithHyperlinks
          containerClassName={spacingClass}
          notes={jobInstance.jobNotes}
          linkClassName={linkClassName}
        />
      ) : null}

      {jobType === JobType.maintenanceJob &&
      (job.administratorOnlyNotes || "").trim() ? (
        <NoteWithHyperlinks
          containerClassName={spacingClass}
          notes={job.administratorOnlyNotes ?? ""}
          linkClassName={linkClassName}
        />
      ) : null}

      {jobType === JobType.maintenanceJob &&
      job.showCrewNotesOnAdminJobCards &&
      (job.notes || "").trim() ? (
        <NoteWithHyperlinks
          containerClassName={spacingClass}
          notes={job.notes}
          linkClassName={linkClassName}
        />
      ) : null}
    </>
  );
}
