import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import {
  buildUrl,
  createCommonHeaders,
  executeWithHeaders,
} from "../../../services/remoteDataProvider";
import { Observable } from "rxjs";

export interface IHasExistingAccountResponse {
  hasExistingAccount: boolean;
  message: string | null;
}

const accountManagementDataProvider = {
  resetPassword: (body: { email: string }) => {
    // This request is made anonymous so not using executeWithHeaders
    return ajax.post(
      buildUrl(`userAccount/resetPassword`),
      body,
      createCommonHeaders()
    );
  },

  isGoogleAuthentication: (email: string) => {
    // This request is made anonymous so not using executeWithHeaders
    return ajax
      .post(
        buildUrl(`userAccount/isGoogleAuthentication`),
        { email },
        createCommonHeaders()
      )
      .pipe(map((result) => result.response.isGoogleAuthentication as boolean));
  },

  hasExistingAccount: (): Observable<IHasExistingAccountResponse> => {
    // This request is made anonymous so not using executeWithHeaders
    return executeWithHeaders((headers) =>
      ajax
        .get(buildUrl(`userAccount/hasExistingAccount`), headers)
        .pipe(map((result) => result.response))
    );
  },
};

export default accountManagementDataProvider;
