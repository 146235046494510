import { actionTypes } from "./actionCreators";
import {
  ICustomerSelectionSearchComplete,
  ICustomerSelectionSearchInput,
} from "./actionTypeDefinitions";
import {
  ICustomerSearchResult,
  IGroupSearchResult,
} from "../models/ICustomerSearchResult";

export interface ICustomerSelectionState {
  searching: boolean;
  matchedCustomers: Array<ICustomerSearchResult>;
  matchedGroups: Array<IGroupSearchResult>;
  errorLoadingResults: boolean;
}

export default (
  state: ICustomerSelectionState | undefined,
  action: any
): ICustomerSelectionState => {
  if (!state) {
    state = {
      searching: false,
      matchedCustomers: [],
      matchedGroups: [],
      errorLoadingResults: false,
    };
  }

  switch (action.type) {
    case actionTypes.CUSTOMER_SELECTION_SEARCH_INPUT:
      const searchInputAction = action as ICustomerSelectionSearchInput;
      return {
        ...state,
        searching: (searchInputAction.searchTerm || "").trim().length > 0,
        errorLoadingResults: false,
        matchedCustomers: [],
        matchedGroups: [],
      };

    case actionTypes.CUSTOMER_SELECTION_SEARCH_START:
      return {
        ...state,
        searching: true,
        errorLoadingResults: false,
        matchedCustomers: [],
        matchedGroups: [],
      };

    case actionTypes.CUSTOMER_SELECTION_SEARCH_COMPLETE:
      const searchCompleteAction = action as ICustomerSelectionSearchComplete;
      return {
        ...state,
        searching: false,
        errorLoadingResults: false,
        matchedCustomers: searchCompleteAction.matchedCustomers,
        matchedGroups: searchCompleteAction.matchedGroups,
      };

    case actionTypes.CUSTOMER_SELECTION_SEARCH_EMPTY:
      return {
        ...state,
        searching: false,
        errorLoadingResults: false,
        matchedCustomers: [],
      };

    case actionTypes.CUSTOMER_SELECTION_SEARCH_ERROR:
      return {
        ...state,
        searching: false,
        errorLoadingResults: true,
        matchedCustomers: [],
      };

    default:
      return state;
  }
};
