import React from "react";
import { TableColumns } from "./TableColumn";
import { getCellContents } from "./getCellContents";
import { ResponsiveTableMobileCardLabel } from "./ResponsiveTableMobileCardLabel";
import { isColumnVisible } from "./isColumnVisible";
import RowProperties from "./rowProperties";
import getCellHeader from "./getHeaderContents";
import { ResponsiveTableMobileCardValue } from "./ResponsiveTableMobileCardValue";

export function ResponsiveTableMobileCardColumns<T extends RowProperties>({
  row,
  rowIndex,
  columns,
}: {
  row: T;
  rowIndex: number;
  columns: TableColumns<T>;
}) {
  return (
    <>
      {columns
        .filter((column) => getCellContents(row, column, "mobile", rowIndex))
        .filter((column) => isColumnVisible<T>(column, row))
        .map((column) => (
          <React.Fragment key={column.key}>
            {!column.isButtonCell ? (
              <ResponsiveTableMobileCardLabel>
                {getCellHeader(column, {
                  displayType: "mobile",
                  row,
                  rowIndex,
                })}
              </ResponsiveTableMobileCardLabel>
            ) : null}

            <ResponsiveTableMobileCardValue
              testId={column.testId}
              isButtonCell={column.isButtonCell}
            >
              {getCellContents(row, column, "mobile", rowIndex)}
            </ResponsiveTableMobileCardValue>
          </React.Fragment>
        ))}
    </>
  );
}
