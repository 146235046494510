import React from "react";
import { OptionProps, components, GroupBase } from "react-select";
import { getSortedItems } from "../../../services/sortingService";
import WrappedCreatableSelect from "./WrappedCreateableSelect";
import { IBaseCategory } from "../../../models/IBaseCategory";
import { ICategoryIdentifierForSave } from "../../../models/ICategoryIdentifierForSave";
import { WrappedCreatableSelectOption } from "./WrappedCreatableSelectOption";

interface IProps {
  placeholderText: string;
  value: Array<ICategoryIdentifierForSave>;
  onChange: (newValue: Array<ICategoryIdentifierForSave>) => void;
  categories: Array<IBaseCategory>;
  noOptionsMessage: string;
}

const CategorySelection: React.FunctionComponent<IProps> = ({
  placeholderText,
  value,
  onChange,
  categories,
  noOptionsMessage,
}) => {
  const distinctCategories = getSortedItems(
    categories.filter((value, index, self) => self.indexOf(value) === index),
    "name"
  );

  const Option = (
    props: OptionProps<
      ICategoryIdentifierForSave,
      true,
      GroupBase<ICategoryIdentifierForSave>
    >
  ) => {
    const category = props.data as ICategoryIdentifierForSave;
    const text = category.name;

    return (
      <components.Option {...props}>
        <div>
          <WrappedCreatableSelectOption
            isNewItem={!distinctCategories.find((c) => c.id === category.id)}
            name={text}
            recordType="category"
          />
        </div>
      </components.Option>
    );
  };

  return (
    <WrappedCreatableSelect<ICategoryIdentifierForSave, true>
      inputId="categories"
      value={value}
      styles={{
        multiValue: (base) => {
          return {
            ...base,
            backgroundColor: "#e7f4ff",
            color: "#1f8dd6",
          };
        },

        multiValueLabel: (base) => {
          return {
            ...base,
            color: "#1f8dd6",
          };
        },

        multiValueRemove: (base) => {
          return {
            ...base,
            color: "#1f8dd6",
            cursor: "pointer",
          };
        },

        menu: (base) => {
          return {
            ...base,
            zIndex: 4,
          };
        },
      }}
      options={distinctCategories}
      isMulti
      components={{
        Option,
      }}
      isClearable={false}
      getOptionValue={(opt) => opt.name}
      getOptionLabel={(opt) => opt.name}
      getNewOptionData={(text) =>
        ({
          id: null,
          name: text ? text.trim() : "",
        } as ICategoryIdentifierForSave)
      }
      onChange={(newValue: any, actionMeta: any) => {
        if (actionMeta.action === "remove-value" && newValue === null) {
          newValue = [];
        }

        if (newValue && typeof newValue.length === "number") {
          onChange(newValue);
        } else {
          console.error("newValue was not an array in Categories");
          console.error(actionMeta);
        }
      }}
      isValidNewOption={(input) => (input || "").trim() !== ""}
      createOptionPosition="first"
      placeholder={placeholderText}
      noOptionsMessage={() => noOptionsMessage}
      onLeaveInputField={(serviceCategoryName) => {
        if (!value.some((cc) => cc.name === serviceCategoryName.trim())) {
          onChange([...value, { id: null, name: serviceCategoryName }]);
        }
      }}
      onCreateOption={(input: string) => {
        onChange([...value, { id: null, name: input }]);
      }}
    />
  );
};

export default CategorySelection;
