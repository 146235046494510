import {
  ca_administrativeAreas,
  ca_administrativeAreaLabel,
  ca_postalCodeLabel,
  ca_localityLabel,
} from "../data/ca_AddressData";

import {
  us_administrativeAreas,
  us_administrativeAreaLabel,
  us_postalCodeLabel,
  us_localityLabel,
} from "../data/us_AddressData";

import {
  au_administrativeAreas,
  au_administrativeAreaLabel,
  au_postalCodeLabel,
  au_localityLabel,
} from "../data/au_AddressData";

import { useApplicationStateSelector } from "./useApplicationStateSelector";

export function useGetAddressFields() {
  const tenantCountry = useApplicationStateSelector((s) => s.common.country);

  switch (tenantCountry) {
    case "CA":
      return {
        administrativeAreaLabel: ca_administrativeAreaLabel,
        administrativeAreas: ca_administrativeAreas,
        localityLabel: ca_localityLabel,
        postCodeLabel: ca_postalCodeLabel,
      };
    case "US":
      return {
        administrativeAreaLabel: us_administrativeAreaLabel,
        administrativeAreas: us_administrativeAreas,
        localityLabel: us_localityLabel,
        postCodeLabel: us_postalCodeLabel,
      };
    case "AU":
      return {
        administrativeAreaLabel: au_administrativeAreaLabel,
        administrativeAreas: au_administrativeAreas,
        localityLabel: au_localityLabel,
        postCodeLabel: au_postalCodeLabel,
      };
    default:
      return {
        administrativeAreaLabel: us_administrativeAreaLabel,
        administrativeAreas: us_administrativeAreas,
        localityLabel: us_localityLabel,
        postCodeLabel: us_postalCodeLabel,
      };
  }
}
