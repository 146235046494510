import { of } from "rxjs";
import { catchError, map, mergeMap, timeout } from "rxjs/operators";
import { ICrew } from "../../models/ICrew";
import { IDaySchedule } from "../../models/IDaySchedule";
import { IJobInstance, maintenanceJobType } from "../../models/IJobInstance";
import dataProvider from "../../services/dataProvider";
import { logError } from "../../services/errorLogger";
import { getJobAddresses } from "../../services/jobInstanceService";
import { routeJobsRxjs } from "../../services/jobRouter";
import { getSortedItemsV2 } from "../../services/sortingService";
import { IRootState } from "../../store";
import { actionCreators } from "../actionCreators";
import { IAutoRouteJobsStart } from "../actionTypeDefinitions";

export function autoRouteJobs(action: IAutoRouteJobsStart, state: IRootState) {
  const daySchedule = state.schedule.daySchedules.find(
    (ds) => ds.id === action.dayScheduleId
  ) as IDaySchedule;
  const crew = state.crew.crews.find(
    (c) => c.id === daySchedule.crewId
  ) as ICrew;

  const { completedJobInstances, jobInstancesToRoute, skippedJobInstances } =
    getJobsForRouting(daySchedule.jobInstances, state);

  return routeJobsRxjs(
    action.dayScheduleId,
    completedJobInstances.length === 0
      ? crew
      : completedJobInstances[completedJobInstances.length - 1],
    crew,
    jobInstancesToRoute,
    state.job.jobs,
    state.job.oneTimeJobs,
    true,
    state.customer.customers,
    state.customer.customerAdditionalLocations
  ).pipe(
    mergeMap((result) => {
      const maxNewOrder = result.updatedJobs
        .map((j) => j.newOrder)
        .reduce((acc, v) => {
          if (v > acc) {
            return v;
          }

          return acc;
        }, 0);

      const updatedJobs = [
        ...completedJobInstances.map((ji, index) => ({
          ...ji,
          newOrder: index,
        })),
        ...result.updatedJobs.map((j) => ({
          ...j,
          newOrder: j.newOrder + completedJobInstances.length,
        })),
        ...skippedJobInstances.map((j, index) => ({
          ...j,
          newOrder: completedJobInstances.length + maxNewOrder + index + 1,
        })),
      ];

      return dataProvider
        .saveAutoRoutedJobs({
          ...result,
          updatedJobs,
        })
        .pipe(timeout(15000));
    }),
    map((result) => {
      const orderedJobInstances = getSortedItemsV2(result.data.updatedJobs, [
        "newOrder",
      ])
        .map((updatedJobInstance) =>
          daySchedule.jobInstances.find(
            (ji) => ji.id === updatedJobInstance.jobInstanceId
          )
        )
        .filter((ji) => typeof ji === "object");

      const orderedJobInstanceIds = orderedJobInstances.map(
        (ji) => ji?.id as string
      );
      const orderedMaintenanceJobIds = orderedJobInstances
        .filter((ji) => ji?.type === maintenanceJobType)
        .map((ji) => ji?.jobId as string);

      return actionCreators.autoRouteJobsCompleted(
        result.data.dayScheduleId,
        orderedJobInstanceIds,
        orderedMaintenanceJobIds,
        true
      );
    }),
    timeout(15000),
    catchError((err: any) => {
      logError(`unable to auto-route: ${JSON.stringify(err)}`);
      return of(actionCreators.autoRouteJobsError(action.dayScheduleId));
    })
  );
}

export function getJobsForRouting(
  jobInstances: Array<IJobInstance>,
  state: IRootState
) {
  const completedJobInstances = getJobAddresses(
    jobInstances.filter((ji) => ji.complete),
    state.job.jobs,
    state.job.oneTimeJobs,
    state.customer.customers,
    state.customer.customerAdditionalLocations
  );
  const skippedJobInstances = getJobAddresses(
    jobInstances.filter((ji) => !ji.complete && ji.skipped),
    state.job.jobs,
    state.job.oneTimeJobs,
    state.customer.customers,
    state.customer.customerAdditionalLocations
  );
  const jobInstancesToRoute = jobInstances.filter(
    (ji) =>
      !completedJobInstances.some((c) => c.jobInstanceId === ji.id) &&
      !skippedJobInstances.some((c) => c.jobInstanceId === ji.id)
  );
  return { completedJobInstances, jobInstancesToRoute, skippedJobInstances };
}
