import { actionTypes } from "./actionCreators";
import {
  ICrewDeleteComplete,
  ICrewMemberDeleteComplete,
  ICrewUpdate,
} from "./actionTypeDefinitions";
import {
  formTypes,
  actionTypes as formActionTypes,
  createSpecificActionTypeName,
} from "../formGenerator";
import { ICrew } from "../models/ICrew";
import { ICrewMember } from "../models/ICrewMember";
import { IScheduledDispatchSettings } from "../models/IScheduledDispatchSettings";

export interface ICrewState {
  crews: Array<ICrew>;
  crewMembers: Array<ICrewMember>;
}

export default (state: ICrewState | undefined, action: any): ICrewState => {
  if (!state) {
    state = {
      crews: [],
      crewMembers: [],
    };
  }

  switch (action.type) {
    case createSpecificActionTypeName(
      formTypes.crew,
      formActionTypes.completeSaving
    ):
      let crews;
      if (!action.payload.parameters || !action.payload.parameters.crewId) {
        crews = [
          ...state.crews,
          {
            ...action.payload,
          },
        ];
      } else {
        crews = state.crews.map((c) => {
          if (c.id === action.payload.parameters.crewId) {
            return {
              // Keep fields not on the save payload like default phone + email address
              ...c,
              ...action.payload,
            };
          } else {
            return c;
          }
        });
      }

      return {
        ...state,
        crews,
      };

    case createSpecificActionTypeName(
      formTypes.scheduledDispatchSettings,
      formActionTypes.completeSaving
    ):
      const payload = action.payload as IScheduledDispatchSettings;
      return {
        ...state,
        crews: state.crews.map((c) => {
          const updatedCrew = payload.crews.find(
            (crewSettings) => c.id === crewSettings.crewId
          );
          if (updatedCrew) {
            return {
              ...c,
              scheduledDispatchTime: updatedCrew.time,
              scheduledDispatchSendSchedule: updatedCrew.sendSchedule,
              scheduledDispatchRecipientCrewMembers: updatedCrew.recipients,
            };
          } else {
            return c;
          }
        }),
      };

    case createSpecificActionTypeName(
      formTypes.crewMember,
      formActionTypes.completeSaving
    ):
      let crewMembers;
      let crewsForCrewMemberSave = state.crews;
      if (
        !action.payload.parameters ||
        !action.payload.parameters.crewMemberId
      ) {
        crewMembers = [
          ...state.crewMembers,
          {
            ...action.payload,
          },
        ];
      } else {
        crewMembers = state.crewMembers.map((c) => {
          if (c.id === action.payload.parameters.crewMemberId) {
            return {
              // Keep fields not on the save payload like default phone + email address
              ...c,
              ...action.payload,
            };
          } else {
            return c;
          }
        });

        if (action.payload.inactivated) {
          crewsForCrewMemberSave = crewsForCrewMemberSave.map((c) => ({
            ...c,
            defaultRecipientCrewMembers: c.defaultRecipientCrewMembers
              ? c.defaultRecipientCrewMembers.filter(
                  (cm) => cm.id !== action.payload.parameters.crewMemberId
                )
              : [],
            scheduledDispatchRecipientCrewMembers:
              c.scheduledDispatchRecipientCrewMembers
                ? c.scheduledDispatchRecipientCrewMembers.filter(
                    (cm) => cm.id !== action.payload.parameters.crewMemberId
                  )
                : [],
            crewMembers: c.crewMembers
              ? c.crewMembers.filter(
                  (cm) => cm.id !== action.payload.parameters.crewMemberId
                )
              : [],
          }));
        }
      }

      return {
        ...state,
        crews: crewsForCrewMemberSave,
        crewMembers,
      };

    case createSpecificActionTypeName(
      formTypes.publishSchedule,
      formActionTypes.completeSaving
    ):
      return {
        ...state,
        crews: state.crews.map((c) => {
          if (c.id === action.payload.crewId) {
            return {
              ...c,
              defaultRecipientEmailAddresses:
                action.payload.recipientEmailAddresses,
              defaultRecipientCrewMembers: action.payload.crewMembers,
            };
          } else {
            return c;
          }
        }),
      };

    case actionTypes.INITIAL_LOAD_COMPLETED:
      return {
        ...state,
        crews: action.crews.map((c: ICrew) => ({
          ...c,
        })),
        crewMembers: action.crewMembers.map((c: ICrewMember) => ({ ...c })),
      };

    case actionTypes.CREW_DELETE_COMPLETE:
      const crewDeleteAction = action as ICrewDeleteComplete;
      return {
        ...state,
        crews: state.crews.filter((c) => c.id !== crewDeleteAction.crewId),
      };

    case actionTypes.CREW_MEMBER_DELETE_COMPLETE:
      const crewMemberDeleteAction = action as ICrewMemberDeleteComplete;
      return {
        ...state,
        crewMembers: state.crewMembers.filter(
          (c) => c.id !== crewMemberDeleteAction.crewMemberId
        ),
      };

    case actionTypes.CREW_UPDATE:
      const crewUpdateAction = action as ICrewUpdate;
      return {
        ...state,
        crews: state.crews.map((c) => {
          if (c.id === crewUpdateAction.crewId) {
            return {
              ...c,
              ...action.crewChanges,
            };
          }

          return c;
        }),
      };

    default:
      return state;
  }
};
