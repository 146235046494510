import React, { Fragment } from "react";
import { connect } from "react-redux";
import FormContainer from "../components/FormContainer";
import { actionCreators } from "../../../modules/actionCreators";
import SeasonalDate from "../components/SeasonalDate";

import { IRootState } from "../../../store";
import { ICrew } from "../../../models/ICrew";
import {
  IMaintenanceJob,
  MaintenanceJobFrequency,
} from "../../../models/IMaintenanceJob";
import { RouterState } from "connected-react-router";
import constants from "../../../constants";
import TextareaAutosize from "react-autosize-textarea";
import uuidv4 from "uuid/v4";
import Files from "../components/files/Index";
import { isPhoto, mapPhotoToDataFile } from "../../../services/fileService";
import { getSortedCrews } from "../../../services/sortingService";
import JobTodoItems from "../components/JobTodoItems";
import Spinner from "../components/Spinner";
import CustomerSelection from "../components/CustomerSelection";
import { ICustomer } from "../../../models/ICustomer";
import customerFinder from "../../../services/customerFinder";
import { MaintenanceJobOrdering } from "../components/MaintenanceJobOrdering";
import CustomerAdditionalLocationSelection from "../components/CustomerAdditionalLocationSelection";
import FrequencySelectionNonSeasonal from "../components/FrequencySelectionNonSeasonal";
import FrequencySelectionSeasonal from "../components/FrequencySelectionSeasonal";
import CrewCategorySelection from "../components/CrewCategorySelection";
import { getCategories } from "../../../services/crewCategoryService";
import { ICrewCategory } from "../../../models/ICrewCategory";
import MaintenanceJobDayOfWeekSelection from "../components/MaintenanceJobDayOfWeekSelection";
import CustomerAdditionalLocationInfoToolTip from "../components/CustomerAdditionalLocationInfoToolTip";
import CustomerBalanceWarning from "../components/CustomerBalanceWarning";
import JobBillingConfiguration from "../components/JobBillingConfiguration";
import { UserAccountRole } from "../../../enums/userAccountRole";
import { isAdmin } from "../../../hooks/useIsAdmin";
import { JobBillingType } from "../../../enums/jobBillingType";
import { DiscountType } from "../../../enums/DiscountType";
import { getLineItemsForForm } from "../../../services/lineItemService";
import { IFormData, IFormDataSeasonalDate } from "./MaintenanceJobForms.types";
import {
  getCrewIdForForm,
  getFormData,
  getSchedulePropertiesFormData,
  isFlexibleJob,
  shouldUseWeekPicker,
} from "./MaintenanceJobForm.functions";
import { ManHours } from "../components/ManHours";
import { MaintenanceJobFormTimeFields } from "./MaintenanceJobFormTimeFields";
import { CrewScheduleType } from "../../../slices/schedule/enums/crewScheduleType";
import { UserSettingsType } from "../../../enums/userSettingsType";
import { isTaxRateSet } from "./InvoiceFormAmounts.functions";
import LinkButton2 from "../components/LinkButton2";
import IPhoto from "../../../models/IPhoto";
import { MaintenanceJobFormDateFields } from "./MaintenanceJobFormDateFields";
import { userSettingsActionCreators } from "../../../modules/userSettings";
import { IUserSetting } from "../../../models/IUserSetting";
import {
  GetUserSettingsType,
  getUserSettingsFromStore,
} from "../../../services/userSettingsService";
import { JobInstructionsForCrewField } from "../components/JobInstructionsForCrewField";

interface IProps {
  crews: Array<ICrew>;
  jobs: Array<IMaintenanceJob>;
  jobsLoading: Array<string>;
  maintenanceJobId: string | null;
  customerId: string | null;
  showForm: boolean;
  errorMessage: string | React.ReactNode;
  saving: boolean;
  router: RouterState;
  startSave(payload: any): void;
  cancel(): void;
  setErrorMessage(message: string): void;
  tenantId: string | null;
  imagePrefix: string;
  loadMaintenanceJobsStart: (ids: Array<string>) => any;
  customers: Array<ICustomer>;
  showCustomersForm(payload: any): void;
  clearErrorMessage(): void;
  anyJobExists: boolean;
  isQuickBooksEnabled: boolean;
  crewCategories: Array<ICrewCategory>;
  defaultFormData: Partial<IFormData>;
  headerOverride: string;
  userAccountRole: UserAccountRole;
  fieldToFocus: string | null;
  showCrewCategoriesForm(payload: any): void;
  proposalFiles?: Array<IPhoto>;
  proposalJobSummary?: string;
  setUserSetting: (payload: { type: UserSettingsType; value: unknown }) => void;
  userSettings: Array<IUserSetting>;
}

interface IState {
  formData: IFormData;
  originalFormData: IFormData;
  hasPendingPhotoAdd: boolean;
  loadingJob: boolean;
  loadingJobsForDayOfWeek: boolean;
  orderingPrecedingJobInstanceId: string;
  taxRateAlreadySet: boolean;
  hideAddAdditionalFiles: boolean;
}

const startTimeInputId = "maintenanceJobFormStartTime";

class MaintenanceJobForm extends React.Component<IProps, IState> {
  private customerElement: React.RefObject<any>;
  private dayPickerLabelElement: React.RefObject<HTMLLabelElement>;

  constructor(props: IProps) {
    super(props);

    const formData = this.getEmptyFormData();

    this.state = {
      formData,
      originalFormData: formData,
      hasPendingPhotoAdd: false,
      loadingJob: false,
      loadingJobsForDayOfWeek: false,
      orderingPrecedingJobInstanceId: constants.idForFirstJob,
      taxRateAlreadySet: false,
      hideAddAdditionalFiles: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.getFormData = this.getFormData.bind(this);

    this.customerElement = React.createRef();
    this.dayPickerLabelElement = React.createRef<HTMLLabelElement>();
  }

  getEmptyFormData(): IFormData {
    let seasonalScheduleStart = getCachedSeasonalScheduleStart((t) =>
      getUserSettingsFromStore(this.props.userSettings, t)
    );
    let seasonalScheduleEnd = getCachedSeasonalScheduleEnd((t) =>
      getUserSettingsFromStore(this.props.userSettings, t)
    );

    const jobArrivalWindowDurationMinutes = parseInt(
      getUserSettingsFromStore<string>(
        this.props.userSettings,
        UserSettingsType.jobArrivalWindowDurationMinutes
      ) ?? ""
    );
    return {
      customerId: null,
      customerAdditionalLocationId: null,
      grossRevenuePerVisit: "",
      crewId: "",
      estimatedManHours: "",
      daysOfWeek: [],
      precedingJobId: "",
      frequency: {
        frequencyType: MaintenanceJobFrequency.Weekly,
        customFrequencyPeriod: "0",
        customFrequencyValue: "",
        monthlyWeek: "",
      },
      startingDate: "",
      endingDate: "",
      highlightCrewNotes: false,
      showCrewNotesOnAdminJobCards: false,
      notes: "",
      administratorOnlyNotes: "",
      seasonalScheduleStart,
      seasonalScheduleEnd,
      seasonalScheduleFrequency: {
        frequencyType: MaintenanceJobFrequency.Weekly,
        customFrequencyPeriod: "0",
        customFrequencyValue: "",
        monthlyWeek: "",
      },
      seasonalScheduleDaysOfWeek: [],
      seasonalScheduleEstimatedManHours: "",
      scheduleVariesBySeason: false,
      todoItems: [],
      photos: [],
      todoTemplateId: "",
      precedingJobIdModified: true,
      categories: [],
      dayScheduleOrderOverride: null,
      billingType: JobBillingType.PerServiceTotal,
      lineItems: [],
      opportunityId: null,
      taxRate: null,
      discount: { type: DiscountType.amount, amount: null, percent: null },
      paymentMethodOnFileAuthorized: false,
      hideLineItemPrices: false,
      startTime: "",
      endTime: "",
      arrivalWindowDurationMinutes: !isNaN(jobArrivalWindowDurationMinutes)
        ? jobArrivalWindowDurationMinutes
        : null,
    };
  }

  componentDidUpdate(prevProps: IProps) {
    const props = this.props;
    const { crews, router } = props;

    if (
      props.showForm &&
      (prevProps.showForm !== props.showForm ||
        (prevProps.jobs !== props.jobs &&
          props.maintenanceJobId &&
          !prevProps.jobs.find((j) => j.id === props.maintenanceJobId) &&
          !!props.jobs.find((j) => j.id === props.maintenanceJobId)))
    ) {
      let formData: IFormData;
      if (!props.maintenanceJobId) {
        formData = this.getEmptyFormData();

        if (this.props.defaultFormData) {
          formData = {
            ...formData,
            ...this.props.defaultFormData,
          };
        }

        if (crews.length === 0) {
          console.error("no crews were specified");
        }

        if (props.customerId) {
          formData.customerId = props.customerId;
        }

        const { defaultFormData } = this.props;
        formData.crewId = getCrewIdForForm(crews, router, defaultFormData);

        formData.precedingJobId = constants.idForFirstJob;
        this.setState({
          formData,
          originalFormData: formData,
          loadingJob: false,
          hideAddAdditionalFiles: false,
        });

        setTimeout(() => {
          if (this.customerElement.current && !formData.customerId) {
            this.customerElement.current.focus();
          }
        });
      } else {
        const job = props.jobs.find((j) => j.id === props.maintenanceJobId);
        if (job) {
          formData = buildFormDataFromJob(job, this.props.crewCategories, (t) =>
            getUserSettingsFromStore(this.props.userSettings, t)
          );
          this.setState({
            formData,
            originalFormData: formData,
            loadingJob: false,
            taxRateAlreadySet: isTaxRateSet(job),
          });

          setTimeout(() => {
            if (this.props.fieldToFocus === "startTime") {
              const startTime = document.getElementById(startTimeInputId);
              if (startTime) {
                startTime.focus();
              }
            }
          });
        } else {
          if (
            !this.props.jobsLoading.find(
              (jobLoading) => jobLoading === props.maintenanceJobId
            )
          ) {
            this.props.loadMaintenanceJobsStart([props.maintenanceJobId]);
          }
          this.setState({ loadingJob: true, hideAddAdditionalFiles: false });
        }
      }
    } else if (prevProps.showForm && !props.showForm) {
      const formData = this.getEmptyFormData();

      this.setState({
        formData,
        originalFormData: formData,
        hasPendingPhotoAdd: false,
        loadingJob: false,
        loadingJobsForDayOfWeek: false,
        orderingPrecedingJobInstanceId: constants.idForFirstJob,
        hideAddAdditionalFiles: false,
      });
    }
  }

  handleChange(
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) {
    const fieldName = event.target.name;
    this.handleChangeWithFieldName(
      fieldName as keyof IFormData,
      event.target.value
    );
  }

  handleChangeWithFieldName(fieldName: keyof IFormData, value: any) {
    const formDataToUpdate = { ...this.state.formData };

    const typedFieldName = fieldName as keyof IFormData;
    const originalValue = (formDataToUpdate as any)[typedFieldName];
    (formDataToUpdate as any)[typedFieldName] = value;

    if (typedFieldName === "daysOfWeek") {
      const originalValueFlexible = originalValue.includes("0");
      const newValueFlexible = value.includes("0");
      if (
        originalValueFlexible &&
        !newValueFlexible &&
        this.props.crews.length > 1
      ) {
        formDataToUpdate["crewId"] = "";
      }

      formDataToUpdate.seasonalScheduleDaysOfWeek = value;
    }

    this.setState({
      formData: formDataToUpdate,
    });

    if (fieldName === "seasonalScheduleStart") {
      this.props.setUserSetting({
        type: UserSettingsType.seasonalScheduleStart,
        value,
      });
    }

    if (fieldName === "seasonalScheduleEnd") {
      this.props.setUserSetting({
        type: UserSettingsType.seasonalScheduleEnd,
        value,
      });
    }

    if (fieldName === "estimatedManHours") {
      this.props.clearErrorMessage();
    }
  }

  getFormData() {
    const isCrewTimeBased = this.isCrewTimeBased(this.state.formData);

    return getFormData({
      formData: {
        ...this.state.formData,
        precedingJobId: isCrewTimeBased
          ? constants.idForFirstJob
          : this.state.formData.precedingJobId,
      },
      userAccountRole: this.props.userAccountRole,
      crews: this.props.crews,
      router: this.props.router,
      isCrewTimeBased: isCrewTimeBased,
      setUserSettings: (type, value) =>
        this.props.setUserSetting({
          type,
          value,
        }),
    });
  }

  validate() {
    const result = { valid: true, errorMessage: "" };

    if (this.state.hasPendingPhotoAdd) {
      return {
        valid: false,
        errorMessage: "Please wait until all photos are uploaded",
      };
    }

    if (!this.state.formData.customerId) {
      return {
        valid: false,
        errorMessage: "A customer must be selected",
      };
    }

    return result;
  }

  private isCrewTimeBased(formData: IFormData) {
    return (
      this.props.crews.find((c) => c.id === formData.crewId)?.scheduleType ===
      CrewScheduleType.time
    );
  }

  render() {
    const {
      crews,
      showForm,
      startSave,
      cancel,
      errorMessage,
      saving,
      setErrorMessage,
      maintenanceJobId,
      anyJobExists,
      headerOverride,
      userAccountRole,
    } = this.props;
    const { formData } = this.state;

    let customer = this.getCustomer();

    let formHeader = "Add Recurring Job";
    if (headerOverride) {
      formHeader = headerOverride;
    } else if (maintenanceJobId) {
      if (customer) {
        formHeader = `Update Recurring Job for ${customer.name}`;
      }
    }

    let defaultFrequencyLabel = "Frequency";
    let defaultManHoursLabel = "Total man hours per visit";
    let dayOfWeekLabel = "Day(s) of the week";
    if (formData.scheduleVariesBySeason) {
      defaultFrequencyLabel = "Peak season frequency";
      defaultManHoursLabel = "Peak season total man hours per visit";
    }

    const crewTimeBased = this.isCrewTimeBased(formData);

    return (
      <React.Fragment>
        <FormContainer
          size="lg"
          setErrorMessage={setErrorMessage}
          validate={this.validate}
          getFormData={this.getFormData}
          formHeader={formHeader}
          showForm={showForm && !this.state.loadingJob}
          errorMessage={errorMessage}
          saving={saving || this.state.loadingJobsForDayOfWeek}
          startSave={startSave}
          cancel={cancel}
          formKey="maintenanceJob"
          hasFormDataChanged={() => {
            return (
              JSON.stringify(this.state.formData) !==
              JSON.stringify(this.state.originalFormData)
            );
          }}
          addSaveAndNewButton={!maintenanceJobId}
        >
          <h5>Customer information</h5>
          <div className="form-section">
            <div className="form-group">
              <label htmlFor="customerSelectionInput" className="required">
                Customer
              </label>
              <CustomerSelection
                selectRef={this.customerElement}
                value={{
                  recordType: "customer",
                  id: formData.customerId,
                }}
                onCustomerClear={() => {
                  this.setState({
                    taxRateAlreadySet: false,
                    formData: {
                      ...this.state.formData,
                      customerAdditionalLocationId: "",
                      customerId: "",
                    },
                  });
                }}
                onCustomerSelection={(type, customerId) => {
                  if (type === "customer") {
                    if (customerId !== formData.customerId) {
                      if (
                        formData.customerAdditionalLocationId !== "" &&
                        formData.customerAdditionalLocationId !== null
                      ) {
                        console.error(
                          "clearing customer location by changing customer"
                        );
                      }

                      let customerTaxExempt = false;
                      if (customerId) {
                        const customer = customerFinder.getOptionalCustomerById(
                          customerId,
                          this.props.customers
                        );
                        customerTaxExempt = customer?.taxExempt ?? false;
                      }

                      this.props.clearErrorMessage();
                      this.setState({
                        taxRateAlreadySet: false,
                        formData: {
                          ...this.state.formData,
                          customerAdditionalLocationId: "",
                          customerId,
                          taxRate: customerTaxExempt
                            ? null
                            : this.state.formData.taxRate,
                        },
                      });
                    }
                  }
                }}
              />
              {formData.customerId ? (
                <div>
                  <CustomerBalanceWarning customerId={formData.customerId} />
                  <button
                    type="button"
                    className="btn btn-sm btn-link"
                    onClick={() =>
                      this.props.showCustomersForm({
                        customerId: formData.customerId,
                      })
                    }
                  >
                    Edit customer
                  </button>
                </div>
              ) : null}
            </div>
            <div className="form-group">
              <label htmlFor="customerAdditionalLocation">Job location</label>
              <CustomerAdditionalLocationInfoToolTip />
              <CustomerAdditionalLocationSelection
                value={formData.customerAdditionalLocationId}
                customerId={formData.customerId || ""}
                onCustomerAdditionalLocationSelection={(
                  customerAdditionalLocationId
                ) => {
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      customerAdditionalLocationId,
                    },
                  });
                }}
              />
            </div>
          </div>
          <h5>Schedule</h5>
          <div className="form-section">
            <FrequencySelectionNonSeasonal
              label={defaultFrequencyLabel}
              frequency={formData.frequency}
              seasonalFrequency={formData.seasonalScheduleFrequency}
              onChange={(newFrequency, newSeasonalFrequency) => {
                const daysPeriod = "0";

                const oldDaysOfWeek = formData.daysOfWeek;

                let daysOfWeek = formData.daysOfWeek;
                let crewId = formData.crewId;
                if (
                  newFrequency.frequencyType ===
                    MaintenanceJobFrequency.Custom &&
                  newFrequency.customFrequencyPeriod === daysPeriod
                ) {
                  daysOfWeek = [];

                  if (
                    oldDaysOfWeek.includes("0") &&
                    crews.filter((c) => !c.inactive).length > 1
                  ) {
                    // Clear crew to require reselection since
                    // old crew saved when flexible may not be applicable
                    // now.
                    crewId = "";
                  }
                }
                const formDataToUpdate = {
                  ...formData,
                  daysOfWeek,
                  crewId,
                  frequency: newFrequency,
                  seasonalScheduleFrequency: newSeasonalFrequency,
                };

                if (
                  !shouldUseWeekPicker(this.state.formData) &&
                  shouldUseWeekPicker(formDataToUpdate)
                ) {
                  formDataToUpdate["startingDate"] = "";
                }

                this.setState({
                  formData: formDataToUpdate,
                });
              }}
            />

            <MaintenanceJobFormDateFields
              useWeekPicker={shouldUseWeekPicker(formData)}
              frequency={formData.frequency}
              startingDate={formData.startingDate}
              onStartingDateChange={(v) =>
                this.handleChangeWithFieldName("startingDate", v)
              }
              endingDate={formData.endingDate}
              onEndingDateChange={(v) =>
                this.handleChangeWithFieldName("endingDate", v)
              }
            />

            <MaintenanceJobDayOfWeekSelection
              value={formData.daysOfWeek}
              onChange={(newDayOfWeek) => {
                this.handleChangeWithFieldName("daysOfWeek", newDayOfWeek);

                // Clear error in case the server returned an error
                // regarding flex + non-flex job selected and the user corrected it
                setErrorMessage("");
              }}
              disabled={this.isDayOfWeekFieldDisabled(formData) ?? false}
              jobDisabled={this.isJobDisabled(formData)}
              isDailyFrequency={!shouldUseWeekPicker(formData)}
              frequency={formData.frequency}
              idPrefix=""
              label={dayOfWeekLabel}
            />

            {crews.length > 1 ? (
              <div className="form-group">
                <label htmlFor="crewId" className="required">
                  Crew
                </label>
                <select
                  id="crewId"
                  data-testid="crewId"
                  className="form-control"
                  name="crewId"
                  value={formData.crewId}
                  onChange={this.handleChange}
                  disabled={isFlexibleJob(formData)}
                  required={true}
                >
                  {isFlexibleJob(formData) ? (
                    <option>Not applicable for flexible jobs</option>
                  ) : (
                    <React.Fragment>
                      <option value="" disabled>
                        &nbsp;
                      </option>
                      {getSortedCrews(crews)
                        .filter((c) => !c.inactive || c.id === formData.crewId)
                        .map((crew) => (
                          <option key={crew.id} value={crew.id}>
                            {crew.name}
                          </option>
                        ))}
                    </React.Fragment>
                  )}
                </select>
              </div>
            ) : null}

            <MaintenanceJobFormTimeFields
              startTime={formData.startTime}
              onStartTimeChange={(v) =>
                this.handleChangeWithFieldName("startTime", v)
              }
              endTime={formData.endTime}
              onEndTimeChange={(v) =>
                this.handleChangeWithFieldName("endTime", v)
              }
              isCrewTimeBased={crewTimeBased}
              onlyDayOfWeekFlexible={isFlexibleJob(this.state.formData)}
              startTimeInputId={startTimeInputId}
              arrivalWindowDurationMinutes={
                formData.arrivalWindowDurationMinutes
              }
              onArrivalWindowChange={(v) => {
                this.handleChangeWithFieldName(
                  "arrivalWindowDurationMinutes",
                  v
                );
              }}
              crewId={formData.crewId}
            />

            {anyJobExists && !crewTimeBased ? (
              <div className="form-group">
                <label htmlFor="precedingJobId" className="required">
                  Job ordering
                </label>
                <MaintenanceJobOrdering
                  crewId={formData.crewId}
                  scheduleProperties={getSchedulePropertiesFormData(formData)}
                  maintenanceJobId={maintenanceJobId}
                  loading={this.state.loadingJobsForDayOfWeek}
                  onLoadingChanged={(loading) =>
                    this.setState({ loadingJobsForDayOfWeek: loading })
                  }
                  precedingJobId={this.state.orderingPrecedingJobInstanceId}
                  onPrecedingJobIdChanged={({
                    maintenanceJobId,
                    jobInstanceId,
                    dayScheduleId,
                    originalModified,
                  }) => {
                    const newFormData = {
                      ...formData,
                      precedingJobId: maintenanceJobId,
                      precedingJobIdModified: originalModified,
                    };

                    let newOriginalFormData = this.state.originalFormData;
                    if (!originalModified) {
                      newOriginalFormData = newFormData;
                    }

                    if (jobInstanceId && dayScheduleId) {
                      newFormData.dayScheduleOrderOverride = {
                        jobInstanceId,
                        dayScheduleId,
                      };
                    } else {
                      newFormData.dayScheduleOrderOverride = null;
                    }

                    this.setState({
                      formData: newFormData,
                      originalFormData: newOriginalFormData,
                      orderingPrecedingJobInstanceId:
                        typeof jobInstanceId === "string"
                          ? jobInstanceId
                          : constants.idForFirstJob,
                    });
                  }}
                />
              </div>
            ) : null}

            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="scheduleVariesBySeason"
                  name="scheduleVariesBySeason"
                  checked={formData.scheduleVariesBySeason}
                  onChange={(e) => {
                    let value = e.target.checked;
                    this.handleChangeWithFieldName(
                      "scheduleVariesBySeason",
                      value
                    );
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="scheduleVariesBySeason"
                >
                  Schedule varies by season
                </label>
              </div>
            </div>

            <ManHours
              value={formData.estimatedManHours}
              label={defaultManHoursLabel}
              idPrefix="maintenanceJobPeak"
              onChange={(newValue) =>
                this.handleChangeWithFieldName("estimatedManHours", newValue)
              }
            />

            {formData.scheduleVariesBySeason ? (
              <Fragment>
                <div className="form-group">
                  <SeasonalDate
                    label="Non-peak season start"
                    value={formData.seasonalScheduleStart}
                    onChange={(v: IFormDataSeasonalDate) =>
                      this.handleChangeWithFieldName("seasonalScheduleStart", v)
                    }
                  />
                </div>
                <div className="form-group">
                  <SeasonalDate
                    label="Non-peak season end"
                    value={formData.seasonalScheduleEnd}
                    onChange={(v: IFormDataSeasonalDate) =>
                      this.handleChangeWithFieldName("seasonalScheduleEnd", v)
                    }
                  />
                </div>
                <FrequencySelectionSeasonal
                  frequency={formData.frequency}
                  seasonalScheduleFrequency={formData.seasonalScheduleFrequency}
                  onChange={(value) =>
                    this.handleChangeWithFieldName(
                      "seasonalScheduleFrequency",
                      value
                    )
                  }
                  daysOfWeek={
                    formData.seasonalScheduleDaysOfWeek.length !== 0
                      ? formData.seasonalScheduleDaysOfWeek
                      : formData.daysOfWeek
                  }
                />

                {formData.seasonalScheduleFrequency.frequencyType !==
                MaintenanceJobFrequency.None ? (
                  <ManHours
                    value={formData.seasonalScheduleEstimatedManHours}
                    label="Non-peak season total man hours per visit"
                    onChange={(newValue) =>
                      this.handleChangeWithFieldName(
                        "seasonalScheduleEstimatedManHours",
                        newValue
                      )
                    }
                    idPrefix="seasonalSchedule"
                  />
                ) : null}
              </Fragment>
            ) : null}
          </div>
          <h5>Job details</h5>
          <div className="form-section">
            <CrewCategorySelection
              value={formData.categories}
              onChange={(newValue) =>
                this.setState({
                  formData: {
                    ...formData,
                    categories: newValue,
                  },
                })
              }
            />
            <JobInstructionsForCrewField
              value={formData.notes}
              onChange={(newValue) =>
                this.handleChangeWithFieldName("notes", newValue)
              }
              proposalJobSummary={this.props.proposalJobSummary}
            />
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="highlightCrewNotes"
                  name="highlightCrewNotes"
                  checked={formData.highlightCrewNotes}
                  onChange={(e) => {
                    let value = e.target.checked;
                    this.handleChangeWithFieldName("highlightCrewNotes", value);
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="highlightCrewNotes"
                >
                  Show note on crew schedule homepage
                </label>
              </div>
            </div>
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="showCrewNotesOnAdminJobCards"
                  name="showCrewNotesOnAdminJobCards"
                  checked={formData.showCrewNotesOnAdminJobCards}
                  onChange={(e) => {
                    let value = e.target.checked;
                    this.handleChangeWithFieldName(
                      "showCrewNotesOnAdminJobCards",
                      value
                    );
                  }}
                />
                <label
                  className="custom-control-label"
                  htmlFor="showCrewNotesOnAdminJobCards"
                >
                  Show note on admin job cards
                </label>
              </div>
            </div>
          </div>
          <JobTodoItems
            value={formData.todoItems}
            todoTemplateId={formData.todoTemplateId}
            onChange={(newTodoItems, newTodoTemplateId) => {
              this.setState({
                formData: {
                  ...formData,
                  todoTemplateId: newTodoTemplateId,
                  todoItems: newTodoItems,
                },
              });
            }}
          />

          <Files
            header="Files for crew"
            files={this.state.formData.photos}
            tenantId={this.props.tenantId || ""}
            imagePrefix={this.props.imagePrefix}
            onFileUploadingStatusChange={(hasPendingPhotoAdd) => {
              this.setState({
                hasPendingPhotoAdd,
              });
            }}
            onFileAdded={(photo) => {
              this.setState({
                formData: {
                  ...this.state.formData,
                  photos: [
                    ...this.state.formData.photos,
                    {
                      ...photo,
                    },
                  ],
                },
              });
            }}
            onFileRemoved={(photoId, imagePath) => {
              this.setState({
                formData: {
                  ...this.state.formData,
                  photos: this.state.formData.photos.filter(
                    (p) => !isPhoto(photoId, imagePath, p)
                  ),
                },
              });
            }}
            onFileUpdated={(photoId, imagePath, caption) => {
              this.setState({
                formData: {
                  ...this.state.formData,
                  photos: this.state.formData.photos.map((p) => {
                    if (isPhoto(photoId, imagePath, p)) {
                      return {
                        ...p,
                        caption,
                      };
                    } else {
                      return p;
                    }
                  }),
                },
              });
            }}
            addAdditionalFiles={
              (this.props.proposalFiles?.length ?? 0) > 0 &&
              !this.state.hideAddAdditionalFiles ? (
                <LinkButton2
                  testId="AddProposalAttachments"
                  buttonContents={<small>Add proposal attachments</small>}
                  onClick={() => {
                    this.setState({
                      formData: {
                        ...this.state.formData,
                        photos: this.state.formData.photos.concat(
                          this.props.proposalFiles?.map((p) =>
                            mapPhotoToDataFile(p)
                          ) ?? []
                        ),
                      },
                      hideAddAdditionalFiles: true,
                    });
                  }}
                />
              ) : null
            }
          />

          {isAdmin(userAccountRole) ? (
            <>
              <h5>Billing information</h5>
              <div className="form-section">
                <JobBillingConfiguration
                  customerId={formData.customerId}
                  taxRateAlreadySet={this.state.taxRateAlreadySet}
                  values={{
                    grossRevenuePerVisit: formData.grossRevenuePerVisit,
                    lineItems: formData.lineItems,
                    billingType: formData.billingType,
                    taxRate: formData.taxRate,
                    discount: formData.discount,
                    paymentMethodOnFileAuthorized:
                      formData.paymentMethodOnFileAuthorized,
                  }}
                  onChange={(newValue) => {
                    this.setState({
                      formData: {
                        ...this.state.formData,
                        grossRevenuePerVisit: newValue.grossRevenuePerVisit,
                        lineItems: newValue.lineItems,
                        billingType: newValue.billingType,
                        taxRate: newValue.taxRate,
                        discount: newValue.discount,
                        paymentMethodOnFileAuthorized:
                          newValue.paymentMethodOnFileAuthorized,
                      },
                    });
                  }}
                />
              </div>
            </>
          ) : null}

          <div className="form-group">
            <label htmlFor="administratorOnlyNotes">
              Administrator only notes
            </label>
            <TextareaAutosize
              maxRows={10}
              id="administratorOnlyNotes"
              className="form-control"
              name="administratorOnlyNotes"
              value={formData.administratorOnlyNotes}
              onChange={(e) => {
                this.handleChangeWithFieldName(
                  "administratorOnlyNotes",
                  e.currentTarget.value
                );
              }}
            />
          </div>
        </FormContainer>
        {this.state.loadingJob ? <Spinner /> : null}
      </React.Fragment>
    );
  }

  private getCustomer() {
    let customer: ICustomer | null = null;
    const formDataCustomerId = this.state.formData.customerId;
    if (formDataCustomerId) {
      customer = customerFinder.getCustomerById(
        formDataCustomerId,
        this.props.customers
      );
    } else if (this.props.maintenanceJobId) {
      const job = this.props.jobs.find(
        (j) => j.id === this.props.maintenanceJobId
      );
      if (job) {
        customer = customerFinder.getCustomerByJob(job, this.props.customers);
      }
    }
    return customer;
  }

  private isDayOfWeekFieldDisabled(formData: IFormData): boolean | undefined {
    return !shouldUseWeekPicker(formData) || this.isJobDisabled(formData);
  }

  private isJobDisabled(formData: IFormData) {
    return (
      formData.frequency.frequencyType === MaintenanceJobFrequency.None &&
      (!formData.scheduleVariesBySeason ||
        formData.seasonalScheduleFrequency?.frequencyType ===
          MaintenanceJobFrequency.None)
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  crews: state.crew.crews,
  jobs: state.job.jobs,
  maintenanceJobId: state.forms.maintenanceJob.parameters
    ? state.forms.maintenanceJob.parameters.maintenanceJobId
    : null,
  customerId: state.forms.maintenanceJob.parameters
    ? state.forms.maintenanceJob.parameters.customerId
    : null,
  fieldToFocus: state.forms.maintenanceJob.parameters
    ? state.forms.maintenanceJob.parameters.fieldToFocus
    : null,
  showForm: state.forms.maintenanceJob.showForm,
  errorMessage: state.forms.maintenanceJob.errorMessage,
  saving: state.forms.maintenanceJob.saving,
  router: state.router,
  tenantId: state.common.tenantId,
  imagePrefix: state.common.imagePrefix || "",
  jobsLoading: state.job.jobsLoading,
  customers: state.customer.customers,
  anyJobExists: state.common.anyJobExists,
  isQuickBooksEnabled: state.common.isQuickBooksEnabled,
  crewCategories: state.common.crewCategories,
  defaultFormData: state.forms.maintenanceJob.parameters
    ? state.forms.maintenanceJob.parameters.defaultFormData
    : null,
  headerOverride: state.forms.maintenanceJob.parameters
    ? state.forms.maintenanceJob.parameters.headerOverride
    : null,
  userAccountRole: state.common.userAccountRole,
  proposalFiles: state.forms.maintenanceJob.parameters
    ? state.forms.maintenanceJob.parameters.proposalFiles
    : null,
  proposalJobSummary: state.forms.maintenanceJob.parameters
    ? state.forms.maintenanceJob.parameters.proposalJobSummary
    : null,
  userSettings: state.userSettings.userSettings,
});

const mapDispatchToProps = {
  startSave: actionCreators.forms.maintenanceJob.startSaving,
  cancel: actionCreators.forms.maintenanceJob.cancelForm,
  setErrorMessage: actionCreators.forms.maintenanceJob.setErrorMessage,
  loadMaintenanceJobsStart: actionCreators.loadMaintenanceJobsStart,
  showCustomersForm: actionCreators.forms.customer.showForm,
  clearErrorMessage: actionCreators.forms.maintenanceJob.clearErrorMessage,
  showCrewCategoriesForm: actionCreators.forms.crewCategories.showForm,
  setUserSetting: userSettingsActionCreators.setUserSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceJobForm);

function getDaysOfWeekForFormData(
  daysOfWeek: Array<number | string>
): Array<string> {
  if (!daysOfWeek || daysOfWeek.length === 0) {
    return [];
  }

  return daysOfWeek.map((dayOfWeek) => {
    if (typeof dayOfWeek === "number") {
      return dayOfWeek.toString();
    } else if (typeof dayOfWeek === "string") {
      return dayOfWeek;
    } else {
      return "";
    }
  });
}

function getCachedSeasonalScheduleEnd(getUserSettings: GetUserSettingsType) {
  let seasonalScheduleEnd: IFormDataSeasonalDate | null;
  const cachedSeasonalScheduleEnd = getUserSettings<IFormDataSeasonalDate>(
    UserSettingsType.seasonalScheduleEnd
  );
  if (cachedSeasonalScheduleEnd) {
    seasonalScheduleEnd = cachedSeasonalScheduleEnd;
  } else {
    seasonalScheduleEnd = { month: "1", dayOfMonth: "1" };
  }
  return seasonalScheduleEnd;
}

function getCachedSeasonalScheduleStart(getUserSettings: GetUserSettingsType) {
  let seasonalScheduleStart: IFormDataSeasonalDate | null;
  const cachedSeasonalScheduleStart = getUserSettings<IFormDataSeasonalDate>(
    UserSettingsType.seasonalScheduleStart
  );
  if (cachedSeasonalScheduleStart) {
    seasonalScheduleStart = cachedSeasonalScheduleStart;
  } else {
    seasonalScheduleStart = { month: "1", dayOfMonth: "1" };
  }
  return seasonalScheduleStart;
}

export function buildFormDataFromJob(
  job: IMaintenanceJob,
  crewCategories: Array<ICrewCategory>,
  getUserSettings: GetUserSettingsType
): IFormData {
  return {
    customerId: job.customerId,
    grossRevenuePerVisit: job.grossRevenuePerVisit
      ? job.grossRevenuePerVisit.toString()
      : "",
    customerAdditionalLocationId: job.customerAdditionalLocationId,
    crewId: job.crewId || "",
    estimatedManHours:
      typeof job.estimatedManHours === "number"
        ? job.estimatedManHours.toString()
        : job.estimatedManHours || "",
    daysOfWeek: getDaysOfWeekForFormData(job.daysOfWeek),
    precedingJobIdModified: false,
    precedingJobId: constants.idForFirstJob,
    frequency: {
      frequencyType: job.frequency,
      customFrequencyPeriod: !job.customFrequencyPeriod
        ? "0"
        : job.customFrequencyPeriod.toString(),
      customFrequencyValue: !job.customFrequencyValue
        ? ""
        : job.customFrequencyValue.toString(),
      monthlyWeek:
        typeof job.monthlyWeek === "number" ? job.monthlyWeek.toString() : "",
    },
    startingDate: job.startingDate || "",
    endingDate: job.endingDate || "",
    highlightCrewNotes: job.highlightCrewNotes,
    showCrewNotesOnAdminJobCards: job.showCrewNotesOnAdminJobCards,
    notes: job.notes,
    administratorOnlyNotes: job.administratorOnlyNotes || "",
    seasonalScheduleStart: job.seasonalScheduleStart
      ? {
          month: job.seasonalScheduleStart.month.toString(),
          dayOfMonth: job.seasonalScheduleStart.dayOfMonth.toString(),
        }
      : getCachedSeasonalScheduleStart(getUserSettings),
    seasonalScheduleEnd: job.seasonalScheduleEnd
      ? {
          month: job.seasonalScheduleEnd.month.toString(),
          dayOfMonth: job.seasonalScheduleEnd.dayOfMonth.toString(),
        }
      : getCachedSeasonalScheduleEnd(getUserSettings),
    seasonalScheduleFrequency: {
      frequencyType:
        typeof job.seasonalScheduleFrequency === "number"
          ? job.seasonalScheduleFrequency
          : job.frequency,
      customFrequencyPeriod: !job.seasonalScheduleCustomFrequencyPeriod
        ? !job.customFrequencyPeriod
          ? "0"
          : job.customFrequencyPeriod.toString()
        : job.seasonalScheduleCustomFrequencyPeriod.toString(),
      customFrequencyValue: !job.seasonalScheduleCustomFrequencyValue
        ? ""
        : job.seasonalScheduleCustomFrequencyValue.toString(),
      monthlyWeek:
        typeof job.seasonalMonthlyWeek === "number"
          ? job.seasonalMonthlyWeek.toString()
          : "",
    },
    seasonalScheduleEstimatedManHours:
      typeof job.seasonalScheduleEstimatedManHours === "number"
        ? job.seasonalScheduleEstimatedManHours.toString()
        : "",
    seasonalScheduleDaysOfWeek: getDaysOfWeekForFormData(
      job.seasonalScheduleDaysOfWeek
    ),
    scheduleVariesBySeason:
      !!job.seasonalScheduleStart && !!job.seasonalScheduleEnd,
    todoItems: job.todoItems.map((i) => ({ ...i, tempId: uuidv4() })),
    photos: job.photos.map((p) => ({
      ...p,
      contentType: p.contentType,
      imagePath: p.imagePath,
      actualWidth: null,
      actualHeight: null,
    })),
    todoTemplateId: job.todoTemplateId || "",
    categories: getCategories(job.categories, crewCategories),
    billingType: job.billingType,
    dayScheduleOrderOverride: null,
    lineItems: getLineItemsForForm(job),
    opportunityId: null,
    taxRate: job.taxRate,
    discount: job.discount,
    paymentMethodOnFileAuthorized: job.paymentMethodOnFileAuthorized,
    hideLineItemPrices: job.hideLineItemPrices,
    startTime: job.startTime ?? "",
    endTime: job.endTime ?? "",
    arrivalWindowDurationMinutes: job.arrivalWindowDurationMinutes ?? null,
  };
}
