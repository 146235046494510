import React from "react";

interface IProps {
  onRefreshSchedule: () => void;
}

const ErrorLoadingSchedule: React.FunctionComponent<IProps> = ({
  onRefreshSchedule,
}) => {
  return (
    <div data-testid="errorLoadingScheduleAlert">
      <h1 className="display-4">Schedule Could Not Be Loaded</h1>
      <div>
        Please check your Internet connection and try again. If it continues to
        fail, please contact support for help.
      </div>
      <button
        type="button"
        className="btn btn-secondary mt-3"
        onClick={onRefreshSchedule}
      >
        Refresh Schedule
      </button>
    </div>
  );
};

export default ErrorLoadingSchedule;
