import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FormContainerWithoutRedux from "../components/FormContainerWithoutRedux";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import remoteDataProvider from "../../../services/remoteDataProvider";
import {
  getNullableFloatFromNumberOrString,
  getNumericString,
} from "../../../services/typeConverter";
import CurrencyInput from "../components/CurrencyInput";
import modelConversion from "../../../services/modelConversion";
import { Observable, Subscription } from "rxjs";
import TextareaAutosize from "react-autosize-textarea/lib";
import { IInvoiceItemCrewControl } from "../../../models/IInvoiceItemCrewControl";
import { ModalDataLoaderStateless } from "../components/ModalDataLoaderStateless";
import { isStringSet } from "../../../services/stringService";
import { ErrorMessageType } from "../components/FormContainer";

interface IFormData {
  name: string;
  description: string;
  inactive: boolean;
  unitPrice: string;
  taxable: boolean;
  internalNotes: string;
}

type IProps = {
  onSaveComplete: (updatedItem: {
    id: string;
    name: string;
    description: string;
    inactive: boolean;
    unitPrice: number | null;
    taxable: boolean;
    internalNotes: string;
  }) => void;
  onCancel: () => void;
} & (
  | {
      invoiceItemId: string;
      mode: "edit";
    }
  | {
      invoiceItemId?: undefined;
      mode: "add";
      defaults: Partial<{
        name: string;
        description: string | null;
        inactive: boolean;
        unitPrice: number | null;
        taxable: boolean;
        internalNotes: string;
      }>;
    }
);

const NonQuickBooksInvoiceItemForm: React.FunctionComponent<IProps> = (
  props
) => {
  const { invoiceItemId, onSaveComplete, onCancel, mode } = props;
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");
  const nameRef = useRef<HTMLInputElement | null>(null);

  const [existingItem, setExistingItem] =
    useState<IInvoiceItemCrewControl | null>(null);
  const [loadingExistingItem, setLoadingExistingItem] = useState(
    mode === "edit"
  );
  const [loadingErrorMessage, setLoadingErrorMessage] = useState<string | null>(
    null
  );

  const { register, getValues, control, watch, reset } = useForm<IFormData>({
    defaultValues: {
      name: props.mode === "add" ? props.defaults.name ?? "" : "",
      description: props.mode === "add" ? props.defaults.description ?? "" : "",
      inactive: props.mode === "add" ? props.defaults.inactive ?? false : false,
      unitPrice: getNumericString(
        props.mode === "add" ? props.defaults.unitPrice ?? "" : ""
      ),
      taxable: props.mode === "add" ? props.defaults.taxable ?? false : false,
      internalNotes:
        props.mode === "add" ? props.defaults.internalNotes ?? "" : "",
    },
  });

  useEffect(() => {
    let subscription: Subscription | null;

    if (isStringSet(invoiceItemId)) {
      subscription = remoteDataProvider
        .getNonQuickBooksInvoiceItem(invoiceItemId)
        .subscribe({
          next: (invoiceItem) => {
            setLoadingExistingItem(false);
            setExistingItem(invoiceItem);

            reset({
              name: invoiceItem.name,
              description: invoiceItem.description ?? "",
              inactive: invoiceItem.inactive,
              internalNotes: invoiceItem.internalNotes,
              taxable: invoiceItem.taxable,
              unitPrice: getNumericString(invoiceItem.unitPrice),
            });
          },
          error: () => {
            setLoadingExistingItem(false);
            setLoadingErrorMessage("Unable to load product/service.");
          },
        });
    }

    return function cleanup() {
      subscription?.unsubscribe();
    };
  }, [reset, invoiceItemId]);

  const { ref: registerNameRef, ...registerName } = register("name");

  const inactive = watch("inactive");

  useEffect(() => {
    setTimeout(() => {
      if (mode === "add") {
        if (nameRef.current) {
          nameRef.current.focus();
        }
      }
    });
  }, [mode]);

  return (
    <ModalDataLoaderStateless
      errorMessage={loadingErrorMessage ?? ""}
      onErrorAlertClose={onCancel}
      loadingData={loadingExistingItem}
      errorLoadingData={isStringSet(loadingErrorMessage)}
    >
      <FormContainerWithoutRedux
        formHeader={mode === "edit" ? "Update Item" : "Add Item"}
        formType={FormTypesV2.nonQuickBooksInvoiceItem}
        saveButtonText={mode === "edit" ? "Update item" : "Add item"}
        confirmation={
          inactive && !existingItem?.inactive ? (
            <>
              <span className="font-weight-bold">Heads-up!</span> Marking an
              item inactive will prevent you from selecting this item in the
              future.
              <div className="mt-1">Are you sure you want to continue?</div>
            </>
          ) : null
        }
        save={() => {
          const payload = {
            ...getValues(),
            unitPrice: modelConversion.convertStringToNumberOrNull(
              getValues("unitPrice")
            ),
          };

          let returnValue: Observable<{} | string>;
          if (mode === "edit") {
            returnValue = remoteDataProvider.updateNonQuickBooksInvoiceItems(
              invoiceItemId,
              payload
            );
          } else {
            returnValue =
              remoteDataProvider.addNonQuickBooksInvoiceItems(payload);
          }

          return returnValue;
        }}
        onSaveComplete={(result) =>
          onSaveComplete({
            id: typeof result === "string" ? result : "",
            name: getValues("name"),
            description: getValues("description"),
            inactive: getValues("inactive"),
            unitPrice: getNullableFloatFromNumberOrString(
              getValues("unitPrice")
            ),
            taxable: getValues("taxable"),
            internalNotes: getValues("internalNotes"),
          })
        }
        onCancel={onCancel}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      >
        <div className="form-group">
          <label htmlFor="name" className="required">
            Name
          </label>
          <input
            {...registerName}
            ref={(r) => {
              registerNameRef(r);
              nameRef.current = r;
            }}
            type="text"
            id="name"
            className="form-control"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <TextareaAutosize
                maxRows={10}
                className="form-control"
                id="description"
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                }}
              />
            )}
          />
        </div>
        <div className="form-group">
          <label htmlFor="unitPrice">Amount per item</label>
          <Controller
            control={control}
            name="unitPrice"
            render={({ field }) => (
              <CurrencyInput
                type="text"
                id="unitPrice"
                className="form-control"
                value={field.value}
                onValueChange={(e) => field.onChange(e)}
                max={9999999}
              />
            )}
          />
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="taxable"
              data-testid="taxable"
              {...register("taxable")}
            />
            <label className="custom-control-label" htmlFor="taxable">
              Taxable
            </label>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="internalNotes">Internal notes</label>
          <Controller
            control={control}
            name="internalNotes"
            render={({ field }) => (
              <TextareaAutosize
                maxRows={10}
                className="form-control"
                id="internalNotes"
                value={field.value}
                onChange={(e) => {
                  field.onChange(e);
                }}
              />
            )}
          />
        </div>
        {invoiceItemId ? (
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="inactive"
                {...register("inactive")}
              />
              <label className="custom-control-label" htmlFor="inactive">
                Inactive
              </label>
            </div>
          </div>
        ) : null}
      </FormContainerWithoutRedux>
    </ModalDataLoaderStateless>
  );
};

export default NonQuickBooksInvoiceItemForm;
