import { actionTypes } from "./actionCreators";
import {
  IDropJobAction,
  ICrewDeleteComplete,
  IJobDeleteComplete,
  IRemoteJobInstanceUpdated,
  IJobInstanceToggleSkippedComplete,
  IJobInstanceDeleteComplete,
  ILoadDaySchedulesCompletedAction,
  IDropJobCompleteAction,
  IShiftScheduleCompleteAggregate,
  IPermanentJobsSaveComplete,
  IRemoteJobInstanceFieldUpdated,
  ILoadDaySchedulesErrorAction,
  IRemoteDayScheduleFieldUpdated,
  IJobInstanceBulkUpdated,
  IAutoRouteJobsPermanentCompleted,
  IJobInstanceToggleCompletedComplete,
  IInvoiceBatchEmailComplete as IInvoiceBatchEmailCompleted,
  ICustomerClearJobsComplete,
  IJobInstanceTimeUpdated,
  ILoadDaySchedulesStartingAction,
} from "./actionTypeDefinitions";
import {
  formTypes,
  actionTypes as formActionTypes,
  createSpecificActionTypeName,
} from "../formGenerator";
import uuidv4 from "uuid/v4";
import parse from "date-fns/parse";
import { IDaySchedule } from "../models/IDaySchedule";
import {
  IUnscheduledMaintenanceJob,
  IUnscheduledJobInstance,
} from "../models/IUnscheduledMaintenanceJob";
import dateService from "../services/dateService";
import { IJobInstance } from "../models/IJobInstance";
import constants from "../constants";
import { IRemoteCustomerNotificationAdded } from "../models/IRemoteCustomerNotificationAdded";
import jobFinder from "../services/jobFinder";
import { IInvoiceSaveJobInstanceUpdate } from "../models/IInvoiceSaveJobInstanceUpdate";
import { projectActionCreators } from "../slices/schedule/modules/project";
import { ICachedItem } from "../models/ICachedItem";
import { IDayToLoad } from "../services/dayScheduleLoader";
import { CopyJobsRequest } from "../slices/schedule/services/jobDataProvider";

type CachedDaySchedule = IDaySchedule & ICachedItem;
type CachedUnscheduledMaintenanceJob = IUnscheduledMaintenanceJob & ICachedItem;

export interface IScheduleState {
  daySchedules: Array<CachedDaySchedule>;
  weeksUnscheduledMaintenanceJobs: Array<CachedUnscheduledMaintenanceJob>;
}

export default (
  state: IScheduleState | undefined,
  action: any
): IScheduleState => {
  if (!state) {
    state = {
      daySchedules: [],
      weeksUnscheduledMaintenanceJobs: [],
    };
  }

  if (projectActionCreators.updateProject.match(action)) {
    const {
      dailySchedules: updateProjectDaySchedules,
      unscheduledJobs: updateProjectUnscheduledJobs,
    } = updateProjectSchedules(
      [action.payload.newProject.id],
      state.daySchedules,
      state.weeksUnscheduledMaintenanceJobs
    );

    return {
      ...state,
      daySchedules: updateProjectDaySchedules,
      weeksUnscheduledMaintenanceJobs: updateProjectUnscheduledJobs,
    };
  } else if (projectActionCreators.removeProjects.match(action)) {
    const {
      dailySchedules: updateProjectDaySchedules,
      unscheduledJobs: updateProjectUnscheduledJobs,
    } = removeJobInstancesTiedToProject(
      action.payload.projectIds,
      state.daySchedules,
      state.weeksUnscheduledMaintenanceJobs
    );

    return {
      ...state,
      daySchedules: updateProjectDaySchedules,
      weeksUnscheduledMaintenanceJobs: updateProjectUnscheduledJobs,
    };
  }

  switch (action.type) {
    case actionTypes.REMOTE_JOBINSTANCE_UPDATED:
      const remoteJobInstanceUpdatedAction =
        action as IRemoteJobInstanceUpdated;
      return {
        ...state,
        daySchedules: state.daySchedules.map((ds) => {
          return {
            ...ds,
            jobInstances: ds.jobInstances.map((ji) => {
              if (ji.id === remoteJobInstanceUpdatedAction.id) {
                return {
                  ...ji,
                  ...remoteJobInstanceUpdatedAction.updatedJobInstance,
                };
              } else {
                return ji;
              }
            }),
          };
        }),
      };

    case actionTypes.REMOTE_JOBINSTANCE_FIELD_UPDATED:
      const remoteJobInstanceFieldUpdatedAction =
        action as IRemoteJobInstanceFieldUpdated;
      return {
        ...state,
        daySchedules: state.daySchedules.map((ds) => {
          return {
            ...ds,
            jobInstances: ds.jobInstances.map((ji) => {
              if (ji.id === remoteJobInstanceFieldUpdatedAction.jobInstanceId) {
                return {
                  ...ji,
                  [remoteJobInstanceFieldUpdatedAction.fieldName]:
                    remoteJobInstanceFieldUpdatedAction.value,
                };
              } else {
                return ji;
              }
            }),
          };
        }),
      };

    case actionTypes.REMOTE_DAYSCHEDULE_FIELD_UPDATED:
      const remoteDayScheduleFieldUpdatedAction =
        action as IRemoteDayScheduleFieldUpdated;
      return {
        ...state,
        daySchedules: state.daySchedules.map((ds) => {
          if (ds.id === remoteDayScheduleFieldUpdatedAction.dayScheduleId) {
            return {
              ...ds,
              [remoteDayScheduleFieldUpdatedAction.fieldName]:
                remoteDayScheduleFieldUpdatedAction.value,
            };
          } else {
            return ds;
          }
        }),
      };

    case actionTypes.JOB_INSTANCE_DELETE_COMPLETE:
      return handleJobsDeleted(action as IJobInstanceDeleteComplete, state);

    case actionTypes.CUSTOMER_CLEAR_JOBS_COMPLETE:
      const actionCustomerClearJobsComplete =
        action as ICustomerClearJobsComplete;
      return handleJobsDeleted(
        {
          ...actionCustomerClearJobsComplete,
          jobInstanceIds: actionCustomerClearJobsComplete.removedJobInstances,
        },
        state
      );

    case createSpecificActionTypeName(
      formTypes.maintenanceJob,
      formActionTypes.completeSaving
    ):
      let filteredDaySchedules = state.daySchedules;
      if (action.payload.updatedDaySchedules) {
        filteredDaySchedules = filteredDaySchedules.filter(
          (d) => action.payload.updatedDaySchedules.indexOf(d.id) === -1
        );
      }

      const weeksUnscheduledMaintenanceJobsForMaintenanceJobCompleteSaving =
        getWeeksUncheduledMaintenanceJobsNotUpdatedForJobSave(
          action.payload.updatedFlexibleJobWeeks,
          state.weeksUnscheduledMaintenanceJobs,
          action.payload.id
        );

      const mapJobInstance = <T extends IJobInstance>(ji: T) => {
        const jobInstancesWithUpdatedTimes = action?.payload
          ?.jobInstancesWithUpdatedTimes as Array<string> | undefined;
        if (
          jobInstancesWithUpdatedTimes &&
          jobInstancesWithUpdatedTimes.includes(ji.id)
        ) {
          return {
            ...ji,
            startTime: action.payload.startTime,
            endTime: action.payload.endTime,
          };
        } else {
          return ji;
        }
      };

      return {
        ...state,
        daySchedules: filteredDaySchedules.map((ds) => ({
          ...ds,
          jobInstances: ds.jobInstances.map(mapJobInstance),
        })),
        weeksUnscheduledMaintenanceJobs:
          weeksUnscheduledMaintenanceJobsForMaintenanceJobCompleteSaving.map(
            (ds) => ({
              ...ds,
              jobInstances: ds.jobInstances.map(mapJobInstance),
            })
          ),
      };

    case actionTypes.PERMANENT_JOBS_SAVE_COMPLETE:
      const permanentJobsSaveCompleteAction =
        action as IPermanentJobsSaveComplete;

      return getUpdatedStateAfterPermanentJobsSaveComplete(
        state,
        permanentJobsSaveCompleteAction.updatedDaySchedules,
        permanentJobsSaveCompleteAction.movedMaintenanceJobIds,
        permanentJobsSaveCompleteAction.updatedFlexibleJobWeeks
      );

    case actionTypes.AUTO_ROUTE_JOBS_PERMANENT_SAVE_COMPLETED:
      const autoRouteJobsPermanentCompletedAction =
        action as IAutoRouteJobsPermanentCompleted;

      return getUpdatedStateAfterPermanentJobsSaveComplete(
        state,
        autoRouteJobsPermanentCompletedAction.updatedDaySchedules,
        autoRouteJobsPermanentCompletedAction.maintenanceJobIds,
        autoRouteJobsPermanentCompletedAction.updatedFlexibleJobWeeks
      );

    case actionTypes.PROJECT_SAVE:
      return updateJobsState(
        action.updatedDaySchedules,
        action.updatedFlexibleJobWeeks,
        state,
        action
      );

    case createSpecificActionTypeName(
      formTypes.oneTimeJob,
      formActionTypes.completeSaving
    ):
      let updatedDayScheduleIds: Array<string> = [];
      let updatedFlexibleJobWeeks: Array<string> = [];

      if (action.payload.updatedDaySchedules) {
        updatedDayScheduleIds = action.payload.updatedDaySchedules;
      }

      if (action.payload.updatedFlexibleJobWeeks) {
        updatedFlexibleJobWeeks = action.payload.updatedFlexibleJobWeeks;
      }

      const updatedJobState = updateJobsState(
        updatedDayScheduleIds,
        updatedFlexibleJobWeeks,
        state,
        action
      );

      return {
        ...updatedJobState,
        daySchedules: updatedJobState.daySchedules.map((ds) => {
          if (
            !action.payload.id &&
            ds.jobInstances.some(
              (ji) => ji.projectId === action.payload.projectId
            )
          ) {
            return {
              ...ds,
              stale: true,
            };
          } else {
            return ds;
          }
        }),
        weeksUnscheduledMaintenanceJobs:
          updatedJobState.weeksUnscheduledMaintenanceJobs.map((ds) => {
            if (
              !action.payload.id &&
              ds.jobInstances.some(
                (ji) => ji.projectId === action.payload.projectId
              )
            ) {
              return {
                ...ds,
                stale: true,
              };
            } else {
              return ds;
            }
          }),
      };

    case actionTypes.JOB_INSTANCE_TIME_UPDATED:
      const jobInstanceTimeUpdatedAction = action as IJobInstanceTimeUpdated;
      return updateJobInstances(
        state,
        state.weeksUnscheduledMaintenanceJobs,
        (ji) => {
          if (ji.id === jobInstanceTimeUpdatedAction.jobInstanceId) {
            return {
              ...ji,
              startTime: jobInstanceTimeUpdatedAction.startTime,
              endTime: jobInstanceTimeUpdatedAction.endTime,
              arrivalWindowDurationMinutes:
                jobInstanceTimeUpdatedAction.arrivalWindowDurationMinutes,
            };
          } else {
            return ji;
          }
        },
        []
      );

    case actionTypes.SHIFT_SCHEDULE_COMPLETE_AGGREGATE:
      return applyShiftChanges(action, state);

    case createSpecificActionTypeName(
      formTypes.billingDetails,
      formActionTypes.completeSaving
    ):
      const mapJobInstanceBillingDetails = (ji: IJobInstance) => {
        if (ji.id === action.payload.parameters.jobInstanceId) {
          return {
            ...ji,
            ...action.payload,
          };
        } else {
          return ji;
        }
      };

      return {
        ...state,
        weeksUnscheduledMaintenanceJobs:
          state.weeksUnscheduledMaintenanceJobs.map((w) => ({
            ...w,
            jobInstances: w.jobInstances.map(mapJobInstanceBillingDetails),
          })),
        daySchedules: state.daySchedules.map((ds) => ({
          ...ds,
          jobInstances: ds.jobInstances.map(mapJobInstanceBillingDetails),
        })),
      };

    case actionTypes.JOB_INSTANCE_BULK_UPDATED:
      const jobInstanceBulkUpdatedAction = action as IJobInstanceBulkUpdated;
      const mapJobInstanceBulkUpdated = <T extends IJobInstance>(ji: T) => {
        const matchingUpdate = jobInstanceBulkUpdatedAction.updates.find(
          (u) => u.id === ji.id
        );
        if (matchingUpdate) {
          return {
            ...ji,
            ...matchingUpdate,
          };
        } else {
          return ji;
        }
      };

      return {
        ...state,
        weeksUnscheduledMaintenanceJobs:
          state.weeksUnscheduledMaintenanceJobs.map((w) => ({
            ...w,
            jobInstances: w.jobInstances.map(mapJobInstanceBulkUpdated),
          })),
        daySchedules: state.daySchedules.map((ds) => ({
          ...ds,
          jobInstances: ds.jobInstances.map(mapJobInstanceBulkUpdated),
        })),
      };

    case actionTypes.JOB_INSTANCE_TOGGLE_SKIPPED_COMPLETE:
      const jobInstanceToggleSkippedCompleteAction =
        action as IJobInstanceToggleSkippedComplete;
      return {
        ...state,
        weeksUnscheduledMaintenanceJobs:
          state.weeksUnscheduledMaintenanceJobs.map((w) => ({
            ...w,
            jobInstances: w.jobInstances.map((ji) => {
              if (
                jobInstanceToggleSkippedCompleteAction.jobInstanceIds.some(
                  (skippedJi) => ji.id === skippedJi
                )
              ) {
                return {
                  ...ji,
                  skipped: jobInstanceToggleSkippedCompleteAction.skipped,
                };
              } else {
                return ji;
              }
            }),
          })),
        daySchedules: state.daySchedules.map((ds) => ({
          ...ds,
          jobInstances: ds.jobInstances.map((ji) => {
            if (
              jobInstanceToggleSkippedCompleteAction.jobInstanceIds.some(
                (skippedJi) => ji.id === skippedJi
              )
            ) {
              return {
                ...ji,
                skipped: jobInstanceToggleSkippedCompleteAction.skipped,
              };
            } else {
              return ji;
            }
          }),
        })),
      };

    case actionTypes.JOB_INSTANCE_TOGGLE_COMPLETED_COMPLETE:
      const jobInstanceToggleCompletedCompleteAction =
        action as IJobInstanceToggleCompletedComplete;
      return {
        ...state,
        weeksUnscheduledMaintenanceJobs:
          state.weeksUnscheduledMaintenanceJobs.map((w) => ({
            ...w,
            jobInstances: w.jobInstances.map((ji) => {
              if (
                jobInstanceToggleCompletedCompleteAction.jobInstanceIds.some(
                  (completedJi) => ji.id === completedJi
                )
              ) {
                return {
                  ...ji,
                  complete: true,
                };
              } else {
                return ji;
              }
            }),
          })),
        daySchedules: state.daySchedules.map((ds) => ({
          ...ds,
          jobInstances: ds.jobInstances.map((ji) => {
            if (
              jobInstanceToggleCompletedCompleteAction.jobInstanceIds.some(
                (completedJi) => ji.id === completedJi
              )
            ) {
              return {
                ...ji,
                complete: true,
              };
            } else {
              return ji;
            }
          }),
        })),
      };

    case createSpecificActionTypeName(
      formTypes.moveJobInstance,
      formActionTypes.completeSaving
    ):
      // First filter out moved job instances
      // TODO: Have server return all updated day schedules and just keep off of that
      const movedJobInstanceIds = action.parameters
        .jobInstanceIds as Array<string>;
      const sourceDayScheduleMoves = state.daySchedules.filter((ds) => {
        return ds.jobInstances.some((ji) =>
          movedJobInstanceIds.some(
            (movedJobInstanceId) => movedJobInstanceId === ji.id
          )
        );
      });

      const sourceUnscheduledWeeks =
        state.weeksUnscheduledMaintenanceJobs.filter((ds) => {
          return ds.jobInstances.some((ji) =>
            movedJobInstanceIds.some(
              (movedJobInstanceId) => movedJobInstanceId === ji.id
            )
          );
        });

      const jobInstances = getJobInstances(
        movedJobInstanceIds,
        state.daySchedules,
        state.weeksUnscheduledMaintenanceJobs,
        undefined
      );
      let maintenanceJobIds: Array<string> = [];
      if (jobInstances) {
        maintenanceJobIds = jobInstances
          .filter((ji) => !!ji.jobId)
          .map((ji) => ji.jobId as string);
      }

      let filteredDaySchedulesMove = state.daySchedules.filter(
        (s) =>
          !(
            sourceDayScheduleMoves.some(
              (sourceDaySchedule) => sourceDaySchedule.id === s.id
            ) ||
            (s.crewId === action.payload.destinationCrewId &&
              s.date === action.payload.destinationDate)
          )
      );

      let filteredUnscheduledWeeks = state.weeksUnscheduledMaintenanceJobs.map(
        (w) => {
          const sourceWeek = sourceUnscheduledWeeks.some((sourceWeek) =>
            dateService.areDatesEqual(w.week, sourceWeek.week)
          );
          const destinationWeek =
            action.payload.destinationFlexibleJob &&
            dateService.areDatesEqual(w.week, action.payload.destinationDate);

          const containsMovedMaintenanceJob = w.jobInstances.some((ji) =>
            maintenanceJobIds.some(
              (maintenanceJobId) => ji.jobId === maintenanceJobId
            )
          );

          const hasChanged = sourceWeek || destinationWeek;
          if (hasChanged || containsMovedMaintenanceJob) {
            return {
              ...w,
              stale: true,
            };
          } else {
            return w;
          }
        }
      );

      // Now filter out schedules that server returned as dirty as part of the permanent move
      const moveJobUpdatedMaintenanceJobIds = action.payload
        .updatedMaintenanceJobIds as Array<string>;
      const moveJobUpdatedDaySchedules = action.payload
        .updatedDaySchedules as Array<string>;
      const moveJobUpdatedFlexibleJobWeeks = action.payload
        .updatedFlexibleJobWeeks as Array<string>;

      filteredDaySchedulesMove = filteredDaySchedulesMove.filter(
        (d) =>
          moveJobUpdatedDaySchedules.indexOf(d.id) === -1 &&
          // Even if updated day schedule wasn't supplied, still clear the schedule
          // if it contains one of the updated jobs.  This is needed since we clear
          // out the maintenance jobs so they'll be reloaded
          !d.jobInstances.some(
            (ji) =>
              ji.jobId && moveJobUpdatedMaintenanceJobIds.includes(ji.jobId)
          )
      );

      filteredUnscheduledWeeks = getWeeksUncheduledMaintenanceJobsNotUpdated(
        moveJobUpdatedFlexibleJobWeeks,
        filteredUnscheduledWeeks
      );

      // Gather any project ids from moved jobs
      let projectIds = sourceDayScheduleMoves.flatMap((s) =>
        s.jobInstances
          .filter((j) => j.projectId !== null)
          .map((ji) => ji.projectId ?? "")
      );

      projectIds.push(
        ...sourceUnscheduledWeeks.flatMap((s) =>
          s.jobInstances
            .filter((j) => j.projectId !== null)
            .map((ji) => ji.projectId ?? "")
        )
      );

      // removing schedules for any related moved project jobs
      const { dailySchedules, unscheduledJobs } = updateProjectSchedules(
        projectIds,
        filteredDaySchedulesMove,
        filteredUnscheduledWeeks
      );

      return {
        ...state,
        daySchedules: dailySchedules,
        weeksUnscheduledMaintenanceJobs: unscheduledJobs,
      };

    case createSpecificActionTypeName(
      formTypes.crewNotes,
      formActionTypes.completeSaving
    ):
      const mapCrewNotesJobInstance = (ji: IJobInstance) => {
        if (ji.id === action.payload.parameters.jobInstanceId) {
          return {
            ...ji,
            ...action.payload.response,
          };
        } else {
          return ji;
        }
      };

      return {
        ...state,
        weeksUnscheduledMaintenanceJobs:
          state.weeksUnscheduledMaintenanceJobs.map((w) => {
            return {
              ...w,
              jobInstances: w.jobInstances.map(mapCrewNotesJobInstance),
            };
          }),
        daySchedules: state.daySchedules.map((ds) => ({
          ...ds,
          jobInstances: ds.jobInstances.map(mapCrewNotesJobInstance),
        })),
      };

    case createSpecificActionTypeName(
      formTypes.publishSchedule,
      formActionTypes.completeSaving
    ):
      return {
        ...state,
        daySchedules: state.daySchedules.map((ds) => {
          if (action.payload.parameters.dayScheduleId === ds.id) {
            return {
              ...ds,
              dispatched: true,
            };
          } else {
            return ds;
          }
        }),
      };

    case createSpecificActionTypeName(
      formTypes.crewMember,
      formActionTypes.completeSaving
    ):
      // action.payload.inactivated
      // action.payload.parameters.crewMemberId
      let daySchedulesForCrewMemberSave = state.daySchedules;
      if (
        action.payload.parameters.crewMemberId &&
        action.payload.inactivated &&
        action.payload.inactivatedDateEqualOrGreater
      ) {
        const inactivatedDateEqualOrGreater: string =
          action.payload.inactivatedDateEqualOrGreater;
        daySchedulesForCrewMemberSave = daySchedulesForCrewMemberSave.map(
          (ds) => {
            if (
              dateService.formatAsIso(ds.date) >=
              dateService.formatAsIso(inactivatedDateEqualOrGreater)
            ) {
              return {
                ...ds,
                crewMembers: ds.crewMembers.filter(
                  (cm) => cm.id !== action.payload.parameters.crewMemberId
                ),
              };
            } else {
              return ds;
            }
          }
        );
      }

      return {
        ...state,
        daySchedules: daySchedulesForCrewMemberSave,
      };

    case createSpecificActionTypeName(
      formTypes.daySchedule,
      formActionTypes.completeSaving
    ):
      return {
        ...state,
        daySchedules: state.daySchedules.map((ds) => {
          if (ds.id === action.payload.dayScheduleId) {
            return {
              ...ds,
              notes: action.payload.notes,
              notesForCrew: action.payload.notesForCrew,
              crewMembers: action.payload.crewMembers,
              rosterOverridden: action.payload.rosterOverridden,
              shopClockIn: action.payload.shopClockIn,
              shopClockOut: action.payload.shopClockOut,
            };
          } else {
            return ds;
          }
        }),
      };

    case actionTypes.LOAD_DAY_SCHEDULES_STARTING:
      const loadDaySchedulesStartingAction =
        action as ILoadDaySchedulesStartingAction;

      let newWeeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob> =
        [...state.weeksUnscheduledMaintenanceJobs];
      const weeksUnscheduledMaintenanceJobsToLoad: Array<Date> =
        loadDaySchedulesStartingAction.weeksUnscheduledMaintenanceJobsToLoad.map(
          (d) => dateService.getParsedDate(d)
        );
      if (weeksUnscheduledMaintenanceJobsToLoad) {
        newWeeksUnscheduledMaintenanceJobs =
          newWeeksUnscheduledMaintenanceJobs.map((existingWeek) => {
            const updated = weeksUnscheduledMaintenanceJobsToLoad.some(
              (weekToLoad) =>
                dateService.areDatesEqual(weekToLoad, existingWeek.week)
            );
            if (updated) {
              return {
                ...existingWeek,
                stale: false,
                partialDay: false,
              };
            } else {
              return existingWeek;
            }
          });

        newWeeksUnscheduledMaintenanceJobs = [
          ...newWeeksUnscheduledMaintenanceJobs,
          ...weeksUnscheduledMaintenanceJobsToLoad
            .filter(
              (weekToLoad) =>
                !state ||
                !state.weeksUnscheduledMaintenanceJobs.some((existingWeek) =>
                  dateService.areDatesEqual(weekToLoad, existingWeek.week)
                )
            )
            .map((weekUnscheduledMaintenanceJobs) => ({
              week: dateService.formatAsIso(weekUnscheduledMaintenanceJobs),
              initialLoadRunning: true,
              stale: false,
              jobInstances: [],
              partialDay: false,
              partialDayEvaluatedAsOf: null,
            })),
        ];
      }

      return {
        ...state,
        daySchedules: [
          ...state.daySchedules.map((existingDaySchedule) => {
            if (
              loadDaySchedulesStartingAction.daySchedulesToLoad.some(
                (dayScheduleToLoad) =>
                  isMatchingDaySchedule(existingDaySchedule, dayScheduleToLoad)
              )
            ) {
              return {
                ...existingDaySchedule,
                stale: false,
              };
            } else {
              return existingDaySchedule;
            }
          }),
          ...loadDaySchedulesStartingAction.daySchedulesToLoad
            .filter(
              (dayScheduleToLoad) =>
                !state?.daySchedules.some((existingDaySchedule) =>
                  isMatchingDaySchedule(existingDaySchedule, dayScheduleToLoad)
                )
            )
            .map((dayScheduleToLoad) => ({
              id: uuidv4(),
              crewId: dayScheduleToLoad.crewId,
              date: dateService.formatAsIso(dayScheduleToLoad.date),
              jobInstances: [],
              initialLoadRunning: true,
              stale: false,
              crewMembers: [],
              dispatched: false,
              notes: "",
              notesForCrew: "",
              rosterOverridden: false,
              shopClockIn: null,
              shopClockOut: null,
              errorLoading: false,
            })),
        ],
        weeksUnscheduledMaintenanceJobs: newWeeksUnscheduledMaintenanceJobs,
      };

    case actionTypes.MARK_LOCAL_CACHE_STALE:
      return {
        ...state,
        daySchedules: state.daySchedules.map((ds) => ({
          ...ds,
          stale: true,
        })),
        weeksUnscheduledMaintenanceJobs:
          state.weeksUnscheduledMaintenanceJobs.map((ds) => ({
            ...ds,
            stale: true,
          })),
      };

    case createSpecificActionTypeName(
      formTypes.crew,
      formActionTypes.completeSaving
    ):
      if (action.payload.parameters && action.payload.parameters.crewId) {
        return {
          ...state,
          // Clear out day schedules so in case crew members changed, the roster will be updated
          daySchedules: state.daySchedules.filter(
            (ds) => ds.crewId !== action.payload.parameters.crewId
          ),
        };
      } else {
        return state;
      }

    case actionTypes.LOAD_DAY_SCHEDULES_COMPLETED:
      const loadDaySchedulesCompletedAction =
        action as ILoadDaySchedulesCompletedAction;
      const daySchedulesWithoutNewDaySchedules = state.daySchedules.filter(
        (ds) => {
          const dayScheduleBeingAdded =
            loadDaySchedulesCompletedAction.daySchedules.find(
              (actionDaySchedule) =>
                actionDaySchedule.crewId === ds.crewId &&
                actionDaySchedule.date === ds.date
            );
          return !dayScheduleBeingAdded;
        }
      );

      return {
        ...state,
        daySchedules: [
          ...daySchedulesWithoutNewDaySchedules,
          ...loadDaySchedulesCompletedAction.daySchedules.map((ds) => ({
            ...ds,
            stale: false,
          })),
        ],
        weeksUnscheduledMaintenanceJobs:
          getUpdatedWeeksUnscheduledMaintenanceJobs(
            action as ILoadDaySchedulesCompletedAction,
            state.weeksUnscheduledMaintenanceJobs
          ),
      };

    case actionTypes.LOAD_DAY_SCHEDULES_ERROR:
      const loadScheduleErrorsAction = action as ILoadDaySchedulesErrorAction;
      return {
        ...state,
        weeksUnscheduledMaintenanceJobs:
          state.weeksUnscheduledMaintenanceJobs.map((ds) => {
            if (
              loadScheduleErrorsAction.weeksUnscheduledMaintenanceJobsWithError.some(
                (e) => dateService.areDatesEqual(e, ds.week)
              )
            ) {
              return {
                ...ds,
                errorLoading: true,
              };
            } else {
              return ds;
            }
          }),
        daySchedules: state.daySchedules.map((cachedDaySchedule) => {
          if (
            loadScheduleErrorsAction.daySchedulesWithError.some(
              (dayScheduleToLoad) =>
                isMatchingDaySchedule(cachedDaySchedule, dayScheduleToLoad)
            )
          ) {
            return {
              ...cachedDaySchedule,
              errorLoading: true,
            };
          }
          return cachedDaySchedule;
        }),
      };

    case actionTypes.ROUTER_LOCATION_CHANGE:
      return removeErrorLoadingScheduules(state);

    case actionTypes.LOAD_DAY_SCHEDULES_ERROR_CLEAR:
      return removeErrorLoadingScheduules(state);

    case actionTypes.AUTO_ROUTE_JOBS_COMPLETED:
      return {
        ...state,
        daySchedules: state.daySchedules.filter(
          (d) => d.id !== action.dayScheduleId
        ),
      };

    case actionTypes.JOB_INSTANCE_COMPLETION_INFO_UPDATED:
      const savedInfo = {
        ...action.payload,
        payload: action.payload.data,
      };
      return handleMarkCompleteSaved(state, savedInfo);

    case createSpecificActionTypeName(
      formTypes.markComplete,
      formActionTypes.completeSaving
    ):
      return handleMarkCompleteSaved(state, action);

    case createSpecificActionTypeName(
      formTypes.invoice,
      formActionTypes.completeSaving
    ):
      const jobInstanceUpdatesForInvoiceSaved = action.payload
        .jobInstanceUpdates as Array<IInvoiceSaveJobInstanceUpdate>;

      const newDaySchedulesForInvoice = state.daySchedules.map(
        (daySchedule) => {
          return {
            ...daySchedule,
            jobInstances: daySchedule.jobInstances.map((jobInstance) => {
              const jobInstanceUpdates = jobInstanceUpdatesForInvoiceSaved.find(
                (u) => u.jobInstanceId === jobInstance.id
              );

              let invoiceNumber = jobInstance.invoiceNumber;
              let purchaseOrderNumber = jobInstance.purchaseOrderNumber;
              if (jobInstanceUpdates) {
                invoiceNumber = jobInstanceUpdates.invoiceNumber;
                purchaseOrderNumber = jobInstanceUpdates.purchaseOrderNumber;
              }

              let invoiceSent = jobInstance.invoiceSent;
              if (
                action.payload.sendEmail &&
                action.payload.jobInstanceIds.some(
                  (i: string) => i === jobInstance.id
                ) &&
                !action.payload.draft
              ) {
                invoiceSent = true;
              }

              return {
                ...jobInstance,
                invoiceSent,
                invoiceNumber,
                purchaseOrderNumber,
              };
            }),
          };
        }
      );

      return {
        ...state,
        daySchedules: newDaySchedulesForInvoice,
      };

    case actionTypes.INVOICE_BATCH_EMAIL_COMPLETED:
      const invoiceBatchEmailCompletedAction =
        action as IInvoiceBatchEmailCompleted;

      const newDaySchedulesForInvoiceBatchEmail = state.daySchedules.map(
        (daySchedule) => {
          return {
            ...daySchedule,
            jobInstances: daySchedule.jobInstances.map((jobInstance) => {
              const jobInstanceUpdates =
                invoiceBatchEmailCompletedAction.jobInstanceUpdates.find(
                  (u) => u.jobInstanceId === jobInstance.id
                );

              if (jobInstanceUpdates) {
                return {
                  ...jobInstance,
                  invoiceSent: true,
                  invoiceNumber: jobInstanceUpdates.invoiceNumber,
                  purchaseOrderNumber: jobInstanceUpdates.purchaseOrderNumber,
                };
              } else {
                return jobInstance;
              }
            }),
          };
        }
      );

      return {
        ...state,
        daySchedules: newDaySchedulesForInvoiceBatchEmail,
      };

    case actionTypes.CREW_DELETE_COMPLETE:
      const crewDeleteAction = action as ICrewDeleteComplete;
      return {
        ...state,
        daySchedules: state.daySchedules.filter(
          (c) => c.crewId !== crewDeleteAction.crewId
        ),
      };

    case actionTypes.JOB_DELETE_COMPLETE:
      const jobDeleteAction = action as IJobDeleteComplete;
      return {
        ...state,
        daySchedules: state.daySchedules.map((ds) => {
          return {
            ...ds,
            jobInstances: ds.jobInstances.filter(
              (ji) =>
                ji.jobId !== jobDeleteAction.jobId &&
                ji.id !== jobDeleteAction.jobId
            ),
          };
        }),
        weeksUnscheduledMaintenanceJobs:
          state.weeksUnscheduledMaintenanceJobs.map((w) => {
            return {
              ...w,
              jobInstances: w.jobInstances.filter(
                (j) =>
                  j.jobId !== jobDeleteAction.jobId &&
                  j.id !== jobDeleteAction.jobId
              ),
            };
          }),
      };

    // TODO: Refresh from server after drop completed (already implemented for destination day to handle flexible jobs)?  Show spinner while saving is happening?
    case actionTypes.DROP_JOB:
      return applyDropJobAction(action as IDropJobAction, state);

    case actionTypes.DROP_JOB_COMPLETED:
      return applyDropJobCompleted(action as IDropJobCompleteAction, state);

    case actionTypes.REMOTE_CUSTOMER_NOTIFICATION_ADDED:
      return applyRemoteCustomerNotificationAdded(
        action as IRemoteCustomerNotificationAdded,
        state
      );

    case createSpecificActionTypeName(
      formTypes.bulkCopyJobs,
      formActionTypes.completeSaving
    ):
      const bulkCopyJobsRequestParameters = action.payload as CopyJobsRequest;
      return {
        ...state,
        daySchedules: state.daySchedules.filter(
          (ds) =>
            !(
              ds.crewId === bulkCopyJobsRequestParameters.destinationCrewId &&
              dateService.areDatesEqual(
                bulkCopyJobsRequestParameters.destinationDate,
                ds.date
              )
            )
        ),
      };

    default:
      return state;
  }
};

function isMatchingDaySchedule(
  cachedDaySchedule: CachedDaySchedule,
  dayScheduleToLoad: IDayToLoad
): boolean {
  return (
    dayScheduleToLoad.crewId === cachedDaySchedule.crewId &&
    dateService.areDatesEqual(dayScheduleToLoad.date, cachedDaySchedule.date)
  );
}

function getUpdatedStateAfterPermanentJobsSaveComplete(
  state: IScheduleState,
  updatedDaySchedules: string[],
  movedMaintenanceJobIds: string[],
  updatedFlexibleJobWeeks: string[]
) {
  const filteredDaySchedulesPermanent = state.daySchedules.filter(
    (d) =>
      updatedDaySchedules.indexOf(d.id) === -1 &&
      // Even if updated day schedule wasn't supplied, still clear the schedule
      // if it contains one of the updated jobs.  This is needed since we clear
      // out the maintenance jobs so they'll be reloaded
      !d.jobInstances.some(
        (ji) => ji.jobId && movedMaintenanceJobIds.includes(ji.jobId)
      )
  );

  const weeksUnscheduledMaintenanceJobsForPermanentJobsCompleteSaving =
    getWeeksUncheduledMaintenanceJobsNotUpdated(
      updatedFlexibleJobWeeks,
      state.weeksUnscheduledMaintenanceJobs
    );

  return {
    ...state,
    daySchedules: filteredDaySchedulesPermanent,
    weeksUnscheduledMaintenanceJobs:
      weeksUnscheduledMaintenanceJobsForPermanentJobsCompleteSaving,
  };
}

function removeErrorLoadingScheduules(state: IScheduleState): IScheduleState {
  return {
    ...state,
    weeksUnscheduledMaintenanceJobs:
      state.weeksUnscheduledMaintenanceJobs.filter((ds) => !ds.errorLoading),
    daySchedules: state.daySchedules.filter((ds) => !ds.errorLoading),
  };
}

function handleMarkCompleteSaved(state: IScheduleState, action: any) {
  const daySchedulesWithInstanceUpdated = state.daySchedules.map(
    (daySchedule) => {
      return {
        ...daySchedule,
        jobInstances: daySchedule.jobInstances.map((jobInstance) => {
          let result;
          if (jobInstance.id === action.parameters.jobInstanceId) {
            result = {
              ...jobInstance,
              ...action.payload,
            };
          } else {
            result = jobInstance;
          }
          return result;
        }),
      };
    }
  );

  let dateOfJobInstance: string | null = null;
  let jobInstance: IJobInstance | null = null;
  daySchedulesWithInstanceUpdated.forEach((ds) => {
    if (!jobInstance) {
      jobInstance = ds.jobInstances.find(
        (ji) => ji.id === action.parameters.jobInstanceId
      );
      if (jobInstance) {
        dateOfJobInstance = ds.date;
      }
    }
  });

  let filteredDaySchedules = daySchedulesWithInstanceUpdated;
  if (dateOfJobInstance && jobInstance) {
    dateOfJobInstance = dateOfJobInstance as string;
    jobInstance = jobInstance as IJobInstance;

    // Filter our future schedules with the same recurring job so that
    // previous job notes and date completed are updated
    if (jobInstance.jobId) {
      filteredDaySchedules = filteredDaySchedules.filter((ds) => {
        const afterJobInstance = ds.date > (dateOfJobInstance as string);
        const hasRecurringJob = ds.jobInstances.some(
          (ji) => ji.jobId === (jobInstance as IJobInstance).jobId
        );

        return !(afterJobInstance && hasRecurringJob);
      });
    }
  }

  return {
    ...state,
    daySchedules: filteredDaySchedules,
  };
}

function handleJobsDeleted(
  action: {
    jobInstanceIds: Array<string>;
    updatedDaySchedules: Array<string>;
    updatedFlexibleJobWeeks: Array<string>;
  },
  state: IScheduleState
) {
  const jobInstances = getJobInstances(
    action.jobInstanceIds,
    state.daySchedules,
    state.weeksUnscheduledMaintenanceJobs
  );

  const projectIds = jobInstances
    .filter((j) => j.projectId !== null)
    .map((j) => j.projectId);

  const { dailySchedules, unscheduledJobs } = updateProjectSchedules(
    projectIds,
    state.daySchedules,
    state.weeksUnscheduledMaintenanceJobs
  );

  const maintenanceJobIds = jobInstances
    .filter((ji) => !!ji.jobId)
    .map((ji) => ji.jobId);

  const filteredWeeksUnscheduledMaintenanceJobs = unscheduledJobs
    .filter(
      (j) =>
        !action.updatedFlexibleJobWeeks.some((updatedWeek) =>
          dateService.areDatesEqual(updatedWeek, j.week)
        )
    )
    .map((j) => {
      let containsMaintenanceJob = j.jobInstances.some(
        (ji) => ji.jobId && maintenanceJobIds.some((i) => i === ji.jobId)
      );

      return {
        ...j,
        dirty: containsMaintenanceJob ? true : j.stale,
        jobInstances: j.jobInstances.filter(
          (ji) => !jobInstances.some((deletedJi) => deletedJi.id === ji.id)
        ),
      };
    });

  const filteredSchedules = dailySchedules
    .filter((ds) => !action.updatedDaySchedules.includes(ds.id))
    .map((ds) => {
      return {
        ...ds,
        jobInstances: ds.jobInstances.filter(
          (ji) => !jobInstances.some((deletedJi) => deletedJi.id === ji.id)
        ),
      };
    });

  return {
    ...state,
    weeksUnscheduledMaintenanceJobs: filteredWeeksUnscheduledMaintenanceJobs,
    daySchedules: filteredSchedules,
  };
}

function applyShiftChanges(
  action: IShiftScheduleCompleteAggregate,
  state: IScheduleState
) {
  const payload = action.shiftResult;

  let filteredDaySchedules = state.daySchedules;
  let filteredWeeksUnscheduledMaintenanceJobs =
    state.weeksUnscheduledMaintenanceJobs;
  if (payload.updatedDaySchedules) {
    // Gather any project ids in updated schedule
    const projectIds = filteredDaySchedules
      .filter((ds) => payload.updatedDaySchedules.includes(ds.id))
      .flatMap((s) =>
        s.jobInstances
          .filter((j) => j.projectId !== null)
          .map((ji) => ji.projectId)
      );

    const { dailySchedules, unscheduledJobs } = updateProjectSchedules(
      projectIds,
      filteredDaySchedules,
      filteredWeeksUnscheduledMaintenanceJobs
    );

    filteredDaySchedules = dailySchedules;
    filteredWeeksUnscheduledMaintenanceJobs = unscheduledJobs;

    filteredDaySchedules = filteredDaySchedules.filter(
      (d) => payload.updatedDaySchedules.indexOf(d.id) === -1
    );
  }

  return {
    ...state,
    weeksUnscheduledMaintenanceJobs: filteredWeeksUnscheduledMaintenanceJobs,
    daySchedules: filteredDaySchedules,
  };
}

function applyDropJobAction(
  action: IDropJobAction,
  state: IScheduleState
): IScheduleState {
  let jobInstances = getJobInstances(
    action.jobInstanceIds,
    state.daySchedules,
    state.weeksUnscheduledMaintenanceJobs,
    action.jobInstanceUpdates
  );

  const {
    daySchedules: daySchedulesWithSourceJobInstanceRemoved,
    weeksUnscheduledMaintenanceJobs:
      weeksUnscheduledMaintenanceJobsWithSourceJobInstanceRemoved,
  } = removeSourceJobInstance(
    action.jobInstanceIds,
    state.daySchedules,
    state.weeksUnscheduledMaintenanceJobs
  );

  const {
    daySchedules: daySchedulesWithDestinationJobInstanceAdded,
    weeksUnscheduledMaintenanceJobs:
      weeksUnscheduledMaintenanceJobsWithDestinationJobInstanceAdded,
  } = addDestinationJobInstance(
    jobInstances,
    action.destinationDayScheduleId,
    action.destinationFlexibleJob,
    action.destinationFlexibleJobWeek,
    action.destinationPrecedingJobInstanceId,
    daySchedulesWithSourceJobInstanceRemoved,
    weeksUnscheduledMaintenanceJobsWithSourceJobInstanceRemoved
  );

  return {
    ...state,
    daySchedules: daySchedulesWithDestinationJobInstanceAdded,
    weeksUnscheduledMaintenanceJobs:
      weeksUnscheduledMaintenanceJobsWithDestinationJobInstanceAdded,
  };
}

function getJobInstances(
  jobInstanceIds: Array<string>,
  daySchedules: Array<IDaySchedule>,
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>,
  jobInstanceUpdates?: Array<{
    jobInstanceId: string;
    startTime: string | null;
    endTime: string | null;
  }>
): Array<IJobInstance> {
  return jobInstanceIds
    .map((jobInstanceId) =>
      jobFinder.getJobInstance(
        daySchedules,
        weeksUnscheduledMaintenanceJobs,
        jobInstanceId
      )
    )
    .filter((ji) => !!ji)
    .map((ji) => {
      if (jobInstanceUpdates && ji) {
        const updatesForJobInstance = jobInstanceUpdates.find(
          (u) => u.jobInstanceId === ji.id
        );

        if (updatesForJobInstance) {
          return {
            ...ji,
            startTime: updatesForJobInstance.startTime,
            endTime: updatesForJobInstance.endTime,
          };
        }
      }

      return ji as IJobInstance;
    });
}

function removeSourceJobInstance(
  jobInstanceIds: Array<string>,
  daySchedules: Array<IDaySchedule>,
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>
): {
  daySchedules: Array<IDaySchedule>;
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>;
} {
  return {
    daySchedules: daySchedules.map((daySchedule) => {
      return {
        ...daySchedule,
        jobInstances: daySchedule.jobInstances.filter(
          (jobInstance) => !jobInstanceIds.some((i) => i === jobInstance.id)
        ),
      };
    }),

    weeksUnscheduledMaintenanceJobs: weeksUnscheduledMaintenanceJobs.map(
      (weekUnscheduledMaintenanceJobs) => {
        return {
          ...weekUnscheduledMaintenanceJobs,
          jobInstances: weekUnscheduledMaintenanceJobs.jobInstances.filter(
            (jobInstance) => !jobInstanceIds.some((i) => i === jobInstance.id)
          ),
        };
      }
    ),
  };
}

function addDestinationJobInstance(
  jobInstances: Array<IJobInstance>,
  destinationDayScheduleId: string | null,
  destinationFlexibleJob: boolean,
  destinationFlexibleJobWeek: Date | null,
  destinationPrecedingJobInstanceId: string | null,
  daySchedules: Array<IDaySchedule>,
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>
): {
  daySchedules: Array<IDaySchedule>;
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>;
} {
  return {
    daySchedules: daySchedules.map((daySchedule) => {
      if (
        !destinationFlexibleJob &&
        daySchedule.id === destinationDayScheduleId
      ) {
        const jobInstancesToAddJobInstanceTo = [...daySchedule.jobInstances];
        const destinationIndex = getDestinationIndex(
          daySchedule,
          destinationPrecedingJobInstanceId as string
        );
        jobInstancesToAddJobInstanceTo.splice(
          destinationIndex,
          0,
          ...jobInstances
        );

        return {
          ...daySchedule,
          jobInstances: jobInstancesToAddJobInstanceTo,
        };
      } else {
        return daySchedule;
      }
    }),

    weeksUnscheduledMaintenanceJobs: weeksUnscheduledMaintenanceJobs.map(
      (weekUnscheduledMaintenanceJobs) => {
        if (
          destinationFlexibleJob &&
          dateService.areDatesEqual(
            destinationFlexibleJobWeek as Date,
            parse(weekUnscheduledMaintenanceJobs.week)
          )
        ) {
          return {
            ...weekUnscheduledMaintenanceJobs,
            jobInstances: [
              ...weekUnscheduledMaintenanceJobs.jobInstances,
              ...jobInstances.map((ji) => ({
                ...ji,
                cutoffDate: (ji as IUnscheduledJobInstance).cutoffDate,
              })),
            ],
          };
        } else {
          return weekUnscheduledMaintenanceJobs;
        }
      }
    ),
  };
}

function getDestinationIndex(
  destinationDaySchedule: IDaySchedule,
  destinationPrecedingJobInstanceId: string
): number {
  let destinationIndex: number;
  if (destinationPrecedingJobInstanceId === constants.idForFirstJob) {
    destinationIndex = 0;
  } else {
    destinationIndex =
      destinationDaySchedule.jobInstances.findIndex(
        (ji) => ji.id === destinationPrecedingJobInstanceId
      ) + 1;
  }

  return destinationIndex;
}

function updateProjectSchedules(
  projectIds: (string | null)[],
  dailySchedules: IDaySchedule[],
  unscheduledJobs: IUnscheduledMaintenanceJob[]
) {
  // Remove any schedules that have an updated project
  dailySchedules = dailySchedules.filter(
    (d) => !d.jobInstances.some((p) => projectIds.includes(p.projectId))
  );

  // update any unscheduled jobs that have an updated project
  unscheduledJobs = unscheduledJobs.map((week) => {
    // Mark dirty if there was a moved job tied to a project in the week.
    const projectJob = week.jobInstances.some((ji) =>
      projectIds.includes(ji.projectId)
    );

    if (projectJob) {
      return {
        ...week,
        stale: true,
      };
    }

    return week;
  });

  return { dailySchedules, unscheduledJobs };
}

function removeJobInstancesTiedToProject(
  projectIds: Array<string>,
  dailySchedules: IDaySchedule[],
  unscheduledJobs: IUnscheduledMaintenanceJob[]
) {
  return {
    dailySchedules: dailySchedules.map((ds) => ({
      ...ds,
      jobInstances: ds.jobInstances.filter(
        (ji) =>
          typeof ji.projectId !== "string" || !projectIds.includes(ji.projectId)
      ),
    })),
    unscheduledJobs: unscheduledJobs.map((ds) => ({
      ...ds,
      jobInstances: ds.jobInstances.filter(
        (ji) =>
          typeof ji.projectId !== "string" || !projectIds.includes(ji.projectId)
      ),
    })),
  };
}

function updateJobsState(
  updatedDayScheduleIds: Array<string>,
  updatedFlexibleJobWeeks: Array<string>,
  state: IScheduleState,
  action: any
) {
  const weeksUnscheduledMaintenanceJobsForOneTimeJobSave =
    getWeeksUncheduledMaintenanceJobsNotUpdatedForJobSave(
      updatedFlexibleJobWeeks,
      state.weeksUnscheduledMaintenanceJobs
    );

  const mapJobInstance = (ji: IJobInstance) => {
    if (ji.id === action?.payload?.id) {
      return {
        ...ji,
        todoItems: action.payload.todoItems,
        todoTemplateId: action.payload.todoTemplateId,
        photos: action.payload.photos,
        jobNotes: action.payload.notes,
        highlightCrewNotes: action.payload.highlightCrewNotes,
        showCrewNotesOnAdminJobCards:
          action.payload.showCrewNotesOnAdminJobCards,
        administratorOnlyNotes: action.payload.administratorOnlyNotes,
        startTime: action.payload.startTime,
        endTime: action.payload.endTime,
      } as IJobInstance;
    } else {
      return ji;
    }
  };

  return updateJobInstances(
    state,
    weeksUnscheduledMaintenanceJobsForOneTimeJobSave,
    mapJobInstance,
    updatedDayScheduleIds
  );
}

function updateJobInstances(
  state: IScheduleState,
  weeksUnscheduledMaintenanceJobsForOneTimeJobSave: IUnscheduledMaintenanceJob[],
  mapJobInstance: (ji: IJobInstance) => IJobInstance,
  updatedDayScheduleIds: string[]
) {
  return {
    ...state,
    weeksUnscheduledMaintenanceJobs:
      weeksUnscheduledMaintenanceJobsForOneTimeJobSave.map((w) => ({
        ...w,
        jobInstances: w.jobInstances.map((ji) => ({
          ...mapJobInstance(ji),
          cutoffDate: ji.cutoffDate,
        })),
      })),
    daySchedules: state.daySchedules
      .filter((ds) => updatedDayScheduleIds.indexOf(ds.id) === -1)
      .map((ds) => ({
        ...ds,
        jobInstances: ds.jobInstances.map(mapJobInstance),
      })),
  };
}

function getWeeksUncheduledMaintenanceJobsNotUpdatedForJobSave(
  updatedFlexibleJobWeeks: Array<string>,
  weeksUnscheduledMaintenanceJobs: IUnscheduledMaintenanceJob[],
  maintenanceJobId?: string
) {
  if (updatedFlexibleJobWeeks) {
    return getWeeksUncheduledMaintenanceJobsNotUpdated(
      updatedFlexibleJobWeeks,
      weeksUnscheduledMaintenanceJobs,
      maintenanceJobId
    );
  }
  return weeksUnscheduledMaintenanceJobs;
}

function getWeeksUncheduledMaintenanceJobsNotUpdated(
  updatedFlexibleJobWeeks: Array<string>,
  weeksUnscheduledMaintenanceJobs: IUnscheduledMaintenanceJob[],
  maintenanceJobId?: string
) {
  weeksUnscheduledMaintenanceJobs = weeksUnscheduledMaintenanceJobs.map((w) => {
    const isUpdatedWeek = !!updatedFlexibleJobWeeks.find((updatedWeek) =>
      dateService.areDatesEqual(updatedWeek, parse(w.week))
    );

    let containsUpdatedMaintenanceJob: boolean = false;
    if (typeof maintenanceJobId === "string" && maintenanceJobId.length > 0) {
      containsUpdatedMaintenanceJob = w.jobInstances.some(
        (ji) => ji.jobId === maintenanceJobId
      );
    }

    if (isUpdatedWeek || containsUpdatedMaintenanceJob) {
      return {
        ...w,
        stale: true,
      };
    } else {
      return w;
    }
  });

  updatedFlexibleJobWeeks.forEach((updatedWeek) => {
    const weekInState = weeksUnscheduledMaintenanceJobs.some((existingWeek) =>
      dateService.areDatesEqual(updatedWeek, existingWeek.week)
    );
    if (!weekInState) {
      weeksUnscheduledMaintenanceJobs = [
        ...weeksUnscheduledMaintenanceJobs,
        {
          week: dateService.formatAsIso(updatedWeek),
          jobInstances: [],
          stale: true,
          initialLoadRunning: false,
          partialDay: false,
          partialDayEvaluatedAsOf: null,
        },
      ];
    }
  });

  return weeksUnscheduledMaintenanceJobs;
}

function getUpdatedWeeksUnscheduledMaintenanceJobs(
  action: ILoadDaySchedulesCompletedAction,
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>
): Array<CachedUnscheduledMaintenanceJob> {
  if (!action.weeksUnscheduledMaintenanceJobs) {
    return weeksUnscheduledMaintenanceJobs;
  }

  let updatedWeeksUnscheduledMaintenanceJobs =
    weeksUnscheduledMaintenanceJobs.map(
      (stateWeekUnscheduledMaintenanceJob: IUnscheduledMaintenanceJob) => {
        const matchingActionWeek = action.weeksUnscheduledMaintenanceJobs.find(
          (week) => {
            return dateService.areDatesEqual(
              week.date,
              stateWeekUnscheduledMaintenanceJob.week
            );
          }
        );

        if (matchingActionWeek) {
          return {
            ...stateWeekUnscheduledMaintenanceJob,
            initialLoadRunning: false,
            jobInstances: matchingActionWeek.jobInstances,
            partialDay: matchingActionWeek.partialDay,
            partialDayEvaluatedAsOf: matchingActionWeek.partialDayEvaluatedAsOf,
          };
        }

        return stateWeekUnscheduledMaintenanceJob;
      }
    );

  // Add new weeks that weren't requested
  updatedWeeksUnscheduledMaintenanceJobs = [
    ...updatedWeeksUnscheduledMaintenanceJobs,
    ...action.weeksUnscheduledMaintenanceJobs
      .filter((receivedDate) => {
        const hasMatchingRecordForWeek =
          !updatedWeeksUnscheduledMaintenanceJobs.some((existingWeek) => {
            const areDatesEqual = dateService.areDatesEqual(
              receivedDate.date,
              existingWeek.week
            );
            return areDatesEqual;
          });
        return hasMatchingRecordForWeek;
      })
      .map((receivedDate) => {
        return {
          week: dateService.formatAsIso(receivedDate.date),
          initialLoadRunning: false,
          stale: false,
          jobInstances: receivedDate.jobInstances,
          partialDay: receivedDate.partialDay,
          partialDayEvaluatedAsOf: receivedDate.partialDayEvaluatedAsOf,
        } as IUnscheduledMaintenanceJob;
      }),
  ];

  return updatedWeeksUnscheduledMaintenanceJobs;
}

function applyDropJobCompleted(
  action: IDropJobCompleteAction,
  state: IScheduleState
) {
  const jobInstances = getJobInstances(
    action.jobInstanceIds,
    state.daySchedules,
    state.weeksUnscheduledMaintenanceJobs
  );

  const movedJobIds = jobInstances
    .filter((ji) => !!ji.jobId)
    .map((ji) => ji.jobId as string);

  const projectIds = jobInstances
    .filter((j) => j.projectId !== null)
    .map((j) => j.projectId);

  const { dailySchedules, unscheduledJobs } = updateProjectSchedules(
    projectIds,
    state.daySchedules,
    state.weeksUnscheduledMaintenanceJobs
  );

  let weeksUnscheduledMaintenanceJobs = unscheduledJobs.map((week) => {
    // Mark dirty if there is a moved job in the week or if there was a moved job tied to a project in the week.
    const maintenanceJobExists = week.jobInstances.some((ji) =>
      movedJobIds.some((i) => i === ji.jobId)
    );

    if (maintenanceJobExists) {
      return {
        ...week,
        stale: true,
      };
    }

    return week;
  });

  return {
    ...state,
    weeksUnscheduledMaintenanceJobs: weeksUnscheduledMaintenanceJobs,
    daySchedules: dailySchedules,
  };
}

function applyRemoteCustomerNotificationAdded(
  action: IRemoteCustomerNotificationAdded,
  state: IScheduleState
): IScheduleState {
  function mapJobInstance<TJobInstance extends IJobInstance>(ji: TJobInstance) {
    if (ji.id === action.jobInstanceId) {
      let { customerNotifications } = ji;

      if (
        !customerNotifications.some(
          (n) => n.id === action.customerNotification.id
        )
      ) {
        customerNotifications = [
          ...customerNotifications,
          action.customerNotification,
        ];
      }

      return {
        ...ji,
        customerNotifications,
      };
    } else {
      return ji;
    }
  }

  return {
    ...state,
    daySchedules: state.daySchedules.map((s) => ({
      ...s,
      jobInstances: s.jobInstances.map(mapJobInstance),
    })),
    weeksUnscheduledMaintenanceJobs: state.weeksUnscheduledMaintenanceJobs.map(
      (s) => ({
        ...s,
        jobInstances: s.jobInstances.map(mapJobInstance),
      })
    ),
  };
}
