import React, { useEffect, useRef, useState } from "react";
import { populateInvoiceDefaults } from "../../../hooks/useLoadInvoiceData";
import { mapWorkNotInvoicedJobToInvoiceJobInstance } from "../../../services/invoiceFormService";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { IInvoiceItem } from "../../../models/IInvoiceItem";
import { IInvoiceDefaultLineItem } from "../../../models/IInvoiceDefaultLineItem";
import { UseFormSetValue } from "react-hook-form";
import { IInvoiceDefaultDepositCredit } from "../../../slices/billing/models/IInvoiceDefaultDepositCredit";
import {
  IInvoiceFormData,
  IInvoiceJobInstance,
  IInvoiceProject,
  LineItemByLocation,
} from "./InvoiceForm.types";
import {
  createEmailAddressRecord,
  createEmailAddressRecordsForCustomer,
} from "../components/EmailAddresses.functions";
import dataProvider from "../../../services/dataProvider";
import invoiceDataProvider, {
  IInvoiceDefaultsResponse,
} from "../../../slices/billing/services/invoiceDataProvider";
import { Subscription, forkJoin, of } from "rxjs";
import { IWorkNotInvoicedCustomer } from "../../../models/IWorkNotInvoicedCustomer";
import { useLoadCustomers } from "../../../hooks/useLoadCustomers";
import { setDefaultsFromJobs } from "./InvoiceForm.useDefaultsFromJobs";
import { IInvoiceAmountAdjustments } from "../../../slices/sales/components/ProposalForm/IFormData";
import { mergeMap } from "rxjs/operators";
import { DiscountType } from "../../../enums/DiscountType";
import {
  getDefaultDeliveryMethod,
  updateDepositCredits,
} from "./InvoiceForm.functions";
import { useCreditCardsEnabled } from "../../../hooks/useCreditCardsEnabled";
import { addMonths } from "date-fns";
import dateService from "../../../services/dateService";
import { useUserSettings } from "../../../services/userSettingsService";
import { IInvoiceDefaultProposalJobSummary } from "../../../slices/billing/models/IInvoiceDefaultProposalJobSummary";

type LoadedDataType = {
  customerJobInstances: Array<IWorkNotInvoicedCustomer>;
  invoiceDefaults: IInvoiceDefaultsResponse;
} | null;

type SetterArguments = {
  setValue: UseFormSetValue<IInvoiceFormData>;
  setPaymentMethodPreviouslyAuthorized: (hide: boolean) => void;
  setJobInstances: React.Dispatch<
    React.SetStateAction<Array<IInvoiceJobInstance>>
  >;
  setDefaultLineItems: React.Dispatch<
    React.SetStateAction<Array<IInvoiceDefaultLineItem> | null>
  >;
  setDefaultDepositCredits: React.Dispatch<
    React.SetStateAction<IInvoiceDefaultDepositCredit[]>
  >;
  setDefaultAmountAdjustments: React.Dispatch<
    React.SetStateAction<IInvoiceAmountAdjustments>
  >;
  setMaxDepositCreditAmount: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  setDepositCredits: React.Dispatch<
    React.SetStateAction<IInvoiceDefaultDepositCredit[]>
  >;
  setProposalJobSummaries: React.Dispatch<
    React.SetStateAction<IInvoiceDefaultProposalJobSummary[]>
  >;
  setDefaultPurchaseOrderNumber: React.Dispatch<React.SetStateAction<string>>;
  setCaptureDepositItemOverride: React.Dispatch<React.SetStateAction<boolean>>;
};

export function useHandleCustomerChanged({
  customerId,
  passedInCustomerId,
  invoiceItems,
  setters,
  projects,
  lineItemsByLocation,
}: {
  customerId: string;
  passedInCustomerId: string | null;
  invoiceItems: IInvoiceItem[];
  projects: IInvoiceProject[];
  setters: SetterArguments;
  lineItemsByLocation: Array<LineItemByLocation>;
}) {
  const { loading: loadingCustomer } = useLoadCustomers(
    customerId ? [customerId] : []
  );
  usePopulateFieldsFromCustomerCache({
    customerId,
    setValue: setters.setValue,
  });

  const {
    loadingDataOnCustomerChange,
    errorLoadingCustomerData,
    retryLoadingCustomerData,
    loadedData,
  } = useLoadCustomerInvoiceData({ customerId, passedInCustomerId });

  useHandleCustomerInvoiceDataLoaded({
    loadedData,
    customerId,
    invoiceItems,
    projects,
    setters,
    lineItemsByLocation,
  });

  return {
    loadingDataOnCustomerChange: loadingDataOnCustomerChange || loadingCustomer,
    errorLoadingCustomerData,
    retryLoadingCustomerData,
  };
}

function usePopulateFieldsFromCustomerCache({
  customerId,
  setValue,
}: {
  customerId: string;
  setValue: UseFormSetValue<IInvoiceFormData>;
}) {
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customer = customers.find((c) => c.id === customerId);

  const previouslyLoadedCustomerForReduxId = useRef<string | null>(null);
  useEffect(() => {
    if (
      customer &&
      customer.id !== previouslyLoadedCustomerForReduxId.current
    ) {
      setValue("quickBooksCustomerId", customer.quickBooksId ?? "");
      setValue(
        "deliveryMethodDetails.emailFields.recipients",
        createEmailAddressRecordsForCustomer(customer)
      );

      previouslyLoadedCustomerForReduxId.current = customer.id;
    } else if (!customerId && previouslyLoadedCustomerForReduxId.current) {
      setValue("quickBooksCustomerId", "");
      setValue("deliveryMethodDetails.emailFields.recipients", [
        createEmailAddressRecord(""),
      ]);

      previouslyLoadedCustomerForReduxId.current = null;
    }
  }, [customerId, customer, setValue]);
}

function useLoadCustomerInvoiceData({
  customerId,
  passedInCustomerId,
}: {
  customerId: string;
  passedInCustomerId: string | null;
}) {
  const [loadingDataOnCustomerChange, setLoadingDataOnCustomerChange] =
    useState(false);
  const [errorLoadingCustomerData, setErrorLoadingCustomerData] =
    useState(false);

  const [loadedData, setLoadedData] = useState<LoadedDataType>(null);

  const previouslyLoadedCustomerId = useRef<string | null>(null);
  useEffect(() => {
    let subscription: Subscription | null = null;

    if (
      previouslyLoadedCustomerId.current !== customerId &&
      !passedInCustomerId
    ) {
      if (customerId) {
        subscription = loadInvoiceDataForCustomer({
          setLoadingDataOnCustomerChange,
          setLoadedData,
          setErrorLoadingCustomerData,
          customerId,
        });
      } else {
        setLoadedData(null);
      }

      previouslyLoadedCustomerId.current = customerId;
    }

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [passedInCustomerId, customerId]);

  const retryLoadingCustomerData = () =>
    loadInvoiceDataForCustomer({
      setLoadingDataOnCustomerChange,
      setLoadedData,
      setErrorLoadingCustomerData,
      customerId,
    });

  return {
    loadingDataOnCustomerChange,
    errorLoadingCustomerData,
    retryLoadingCustomerData,
    loadedData,
  };
}

function loadInvoiceDataForCustomer({
  setLoadingDataOnCustomerChange,
  setLoadedData,
  setErrorLoadingCustomerData,
  customerId,
}: {
  setLoadingDataOnCustomerChange: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadedData: React.Dispatch<React.SetStateAction<LoadedDataType>>;
  setErrorLoadingCustomerData: React.Dispatch<React.SetStateAction<boolean>>;
  customerId: string;
}) {
  setLoadingDataOnCustomerChange(true);
  setLoadedData(null);
  setErrorLoadingCustomerData(false);

  const subscription = dataProvider
    .getWorkNotInvoicedReport(
      dateService.formatAsIso(addMonths(dateService.getCurrentDate(), -6)),
      null,
      null,
      [customerId]
    )
    .pipe(
      mergeMap((result) =>
        forkJoin({
          customerJobInstances: of(result),
          invoiceDefaults: result.some(
            (r) => r.jobs.length > 0 || r.projects.length > 0
          )
            ? invoiceDataProvider.getInvoiceDefaults({
                invoiceId: null,
                jobInstanceIds: result.flatMap((r) =>
                  r.jobs.flatMap((j) => j.jobInstances).map((ji) => ji.id)
                ),
                projectIds: result.flatMap((r) => r.projects.map((p) => p.id)),
              })
            : of({
                captureDepositItemOverride: false,
                depositCredits: [],
                discount: {
                  amount: null,
                  percent: null,
                  type: DiscountType.amount,
                },
                hideLineItemPrices: false,
                lineItems: [],
                paymentMethodOnFileAuthorized: false,
                purchaseOrderNumber: "",
                taxRate: null,
                proposalSummaries: [],
              }),
        })
      )
    )
    .subscribe({
      next: (result) => {
        setLoadingDataOnCustomerChange(false);

        setLoadedData(result);
      },

      error: () => {
        setErrorLoadingCustomerData(true);

        setLoadingDataOnCustomerChange(false);
      },
    });

  return subscription;
}

function useHandleCustomerInvoiceDataLoaded({
  loadedData,
  customerId,
  invoiceItems,
  projects,
  setters,
  lineItemsByLocation,
}: {
  loadedData: LoadedDataType;
  customerId: string;
  invoiceItems: IInvoiceItem[];
  projects: IInvoiceProject[];
  setters: SetterArguments;
  lineItemsByLocation: Array<LineItemByLocation>;
}) {
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );

  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );
  const quickBooksDeliveryAllowed = useApplicationStateSelector(
    (s) => s.common.quickBooksDeliveryAllowed
  );
  const { areCreditCardsEnabled } = useCreditCardsEnabled();
  const { getUserSettings } = useUserSettings();

  const {
    setJobInstances,
    setValue,
    setPaymentMethodPreviouslyAuthorized,
    setDefaultDepositCredits,
    setDefaultLineItems,
    setDefaultAmountAdjustments,
    setDefaultPurchaseOrderNumber,
    setCaptureDepositItemOverride,
    setDepositCredits,
    setMaxDepositCreditAmount,
    setProposalJobSummaries,
  } = setters;

  const previouslyLoadedData = useRef<LoadedDataType>(null);
  if (loadedData !== previouslyLoadedData.current) {
    if (loadedData) {
      const workNotInvoicedCustomer = loadedData.customerJobInstances.filter(
        (c) => c.id === customerId
      );

      const jobInstances = workNotInvoicedCustomer.flatMap((c) =>
        c.jobs.flatMap((j) =>
          mapWorkNotInvoicedJobToInvoiceJobInstance({
            customer: c,
            job: j,
            customers,
            customerAdditionalLocations,
          })
        )
      );
      setJobInstances(jobInstances);
      setValue("selectedWorkToBill", {
        selectedJobInstances: jobInstances.map((ji) => ji.id),
        selectedProjects: workNotInvoicedCustomer
          .flatMap((c) => c.projects)
          .map((p) => p.id),
      });

      const {
        defaultLineItems,
        defaultAmountAdjustments,
        defaultDepositCredits,
        defaultPurchaseOrderNumber,
      } = populateInvoiceDefaults({
        invoiceDefaults: loadedData.invoiceDefaults,
        setPaymentMethodPreviouslyAuthorized,
        setShowLineItemPrices: (v) => setValue("showLineItemPrices", v),
        setDefaultDepositCredits,
        setDefaultLineItems,
        setDefaultAmountAdjustments,
        setDefaultPurchaseOrderNumber,
        setCaptureDepositItemOverride,
        setProposalJobSummaries,
      });

      setDefaultsFromJobs({
        customerAdditionalLocations,
        defaultAmountAdjustments,
        defaultCredits: defaultDepositCredits,
        defaultLineItems,
        invoiceItems,
        jobInstances,
        projects,
        setValue,
        setDepositCredits,
        setMaxDepositCreditAmount,
      });
      setValue("purchaseOrderNumber", defaultPurchaseOrderNumber);

      setValue(
        "deliveryMethodDetails.invoiceDeliveryMethod",
        getDefaultDeliveryMethod(
          isQuickBooksEnabled && quickBooksDeliveryAllowed,
          areCreditCardsEnabled,
          loadedData.invoiceDefaults.paymentMethodOnFileAuthorized,
          getUserSettings
        )
      );
    } else if (!loadedData) {
      setPaymentMethodPreviouslyAuthorized(false);
      setValue("showLineItemPrices", true);
      setJobInstances([]);
      setValue("selectedWorkToBill", {
        selectedJobInstances: [],
        selectedProjects: [],
      });
      setValue("purchaseOrderNumber", "");

      setValue("amountAdjustments.taxRate", null);

      setValue("amountAdjustments.discount", {
        type: DiscountType.percent,
        amount: null,
        percent: null,
      });
      updateDepositCredits({
        selectedWorkToBill: {
          selectedJobInstances: [],
          selectedProjects: [],
        },
        credits: [],
        setDepositCreditAmount: (v) =>
          setValue("amountAdjustments.depositCreditAmount", v),
        setMaxDepositCreditAmount,
        setDepositCredits,
      });
      setValue(
        "lineItemsByLocation",
        lineItemsByLocation
          .map((l) => ({
            ...l,
            lineItems: l.lineItems.filter((li) => !li.defaultedFromJob),
          }))
          .filter((l) => l.lineItems.length > 0)
      );
    }

    previouslyLoadedData.current = loadedData;
  }
}
