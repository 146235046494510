import React from "react";

import dateFnsFormat from "date-fns/format";
import startOfWeek from "date-fns/start_of_week";
import endOfWeek from "date-fns/end_of_week";
import dateFnsParse from "date-fns/parse";
import addDays from "date-fns/add_days";

import DayPicker from "./DayPicker";

function getWeekDays(weekStart: Date | string) {
  const days = [dateFnsParse(weekStart)];
  for (let i = 1; i < 7; i += 1) {
    days.push(addDays(dateFnsParse(weekStart), i));
  }
  return days;
}

function getWeekRange(date: Date | string) {
  return {
    from: startOfWeek(dateFnsParse(date)),
    to: endOfWeek(dateFnsParse(date)),
  };
}

interface IProps {
  value: string;
  required: boolean;
  inputId: string;
  onWeekSelected(date: Date): void;
  preventMobileView?: boolean;
  showIcon?: boolean;
  inputStyle?: React.CSSProperties;
  labelRef?: React.RefObject<HTMLLabelElement>;
  maxDate?: Date;
  testId?: string;
  scrollWithoutLabel?: boolean;
}

interface IState {
  hoverRange: any;
  selectedDays: Array<Date>;
}

class WeekPicker extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      hoverRange: undefined,
      selectedDays: [],
    };
  }

  componentDidMount() {
    this.initializeSelectedDays();
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.value !== this.props.value) {
      this.initializeSelectedDays();
    }
  }

  initializeSelectedDays() {
    this.handleDayChange(this.props.value);
  }

  handleDayChange = (date: Date | string) => {
    let selectedDays: Array<Date>;
    if (date) {
      selectedDays = getWeekDays(getWeekRange(date).from);
    } else {
      selectedDays = [];
    }

    this.setState({
      selectedDays,
    });
  };

  handleDayEnter = (date: Date | string) => {
    this.setState({
      hoverRange: getWeekRange(date),
    });
  };

  handleDayLeave = () => {
    this.setState({
      hoverRange: undefined,
    });
  };

  render() {
    const { hoverRange, selectedDays } = this.state;
    const {
      value,
      inputId,
      required,
      onWeekSelected,
      showIcon,
      maxDate,
      testId,
    } = this.props;

    const modifiers = {
      hoverRange,
    };

    const modifiersStyles = {
      hoverRange: {
        backgroundColor: "#c5e6f8",
      },
    };

    return (
      <DayPicker
        labelRef={this.props.labelRef}
        scrollWithoutLabel={this.props.scrollWithoutLabel}
        showIcon={showIcon}
        value={value}
        inputId={inputId}
        required={required}
        onDaySelected={(day) => {
          if (day) {
            onWeekSelected(startOfWeek(day));
          } else {
            onWeekSelected(day);
          }
        }}
        dayPickerProps={{
          selectedDays: selectedDays,
          modifiers: modifiers,
          modifiersStyles: modifiersStyles,
          onDayClick: this.handleDayChange,
          onDayMouseEnter: this.handleDayEnter,
          onDayMouseLeave: this.handleDayLeave,
        }}
        onDayPickerHide={() => this.setState({ hoverRange: undefined })}
        preventMobileView={this.props.preventMobileView}
        inputStyle={this.props.inputStyle}
        maxDate={maxDate}
        testId={testId}
        formatDate={(date: Date, format: string, locale: string) => {
          if (date) {
            return dateFnsFormat(startOfWeek(date), format, {
              locale,
            });
          }
          return dateFnsFormat(date, format, { locale });
        }}
      />
    );
  }
}

export default WeekPicker;
