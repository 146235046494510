import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { timeout } from "rxjs/operators";
import { IFormsState } from "../../../formGenerator/formReducers";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { TenantSubscriptionStatus } from "../../../models/IInitialLoad";
import { actionCreators } from "../../../modules/actionCreators";
import { fullStoryLogInfo } from "../../../services/fullStoryService";
import subscriptionDataProvider from "../services/subscriptionDataProvider";
import Spinner from "../../../containers/app/components/Spinner";
import { PortalPage } from "../enums/portalPage";
import { isLockedOutForFailedPayment } from "../services/crewControlSubscriptionService";

enum WarningStatus {
  none,
  warnWithProceed,
  warnWithoutProceed,
}

enum WarningType {
  signup,
  updatePaymentMethod,
  trialUnavailable,
}

type IProps = { onCancel: () => void } & (
  | {
      mode: "formType";
      formType: keyof IFormsState;
    }
  | {
      mode: "alwaysLockedWhenNotSubscribed";
    }
);

const lockedWhenNotSubscribedForms: Array<keyof IFormsState> = [
  "maintenanceJob",
  "oneTimeJob",
  "publishSchedule",
  "customerNotification",
  "markComplete",
];

const SubscriptionWarning: React.FunctionComponent<IProps> = (props) => {
  const { children, onCancel } = props;
  const [warningBypassed, setWarningBypassed] = useState(false);
  const [creatingPortalSession, setCreatingPortalSession] = useState(false);

  const { warningStatus, warningType } = useGetWarningStatus(props);

  const dispatch = useDispatch();

  useEffect(() => {
    if (warningStatus !== WarningStatus.none) {
      fullStoryLogInfo("Subscription Warning Shown");
    }
  }, [warningStatus]);

  if (warningStatus === WarningStatus.none || warningBypassed) {
    return <>{children}</>;
  } else {
    return (
      <>
        {creatingPortalSession ? <Spinner /> : null}

        <Modal isOpen={true} scrollable={true}>
          <ModalBody>
            {warningType === WarningType.signup ||
            warningType === WarningType.trialUnavailable ? (
              <>
                <h4 className="text-primary" data-testid="header">
                  {warningType === WarningType.trialUnavailable
                    ? "Subscribe to get started"
                    : "Your free trial has expired"}
                </h4>
                <div className="my-3" data-testid="details">
                  {warningType === WarningType.trialUnavailable
                    ? "Click Sign Up to get started using Crew Control."
                    : "Thank you trying Crew Control! If you'd like to continue, please click Sign Up."}
                </div>
                <button
                  className="btn btn-block btn-primary my-3"
                  data-testid="signupButton"
                  onClick={() => {
                    onCancel();

                    dispatch(actionCreators.forms.subscription.showForm({}));
                  }}
                >
                  Sign Up
                </button>
              </>
            ) : (
              <>
                <h4 className="text-primary" data-testid="header">
                  Your payment has failed
                </h4>
                <div className="my-3" data-testid="details">
                  Your payment method is out-of-date. Please click Update
                  Payment Method to correct it.
                </div>
                <button
                  className="btn btn-block btn-primary my-3"
                  data-testid="updatePaymentMethodButton"
                  onClick={() => {
                    setCreatingPortalSession(true);

                    subscriptionDataProvider
                      .createSubscriptionPortalSession({
                        page: PortalPage.UpdatePaymentMethod,
                      })
                      .pipe(timeout(15000))
                      .subscribe({
                        next: (portalUrl) => {
                          onCancel();

                          // Need a delay to ensure redux store is updated to prevent
                          // 'Are you sure you want to leave' prompt from appearing
                          // when forms are open. onCancel is asynchronous.
                          setTimeout(() => {
                            window.location.href = portalUrl;
                          }, 50);
                        },

                        error: () => {
                          setCreatingPortalSession(false);
                        },
                      });
                  }}
                >
                  Update Payment Method
                </button>
              </>
            )}
            <button
              className="btn btn-block btn-secondary"
              data-testid="closeButton"
              onClick={() => {
                if (warningStatus === WarningStatus.warnWithoutProceed) {
                  onCancel();
                }

                setWarningBypassed(true);
              }}
            >
              Close
            </button>
          </ModalBody>
        </Modal>
      </>
    );
  }
};

export default SubscriptionWarning;

function useGetWarningStatus(props: IProps) {
  let lockedWhenNotSubscribed: boolean = false;
  if (props.mode === "formType") {
    lockedWhenNotSubscribed = lockedWhenNotSubscribedForms.includes(
      props.formType
    );
  } else if (props.mode === "alwaysLockedWhenNotSubscribed") {
    lockedWhenNotSubscribed = true;
  }

  const subscriptionStatus = useApplicationStateSelector(
    (s) => s.common.tenantSubscriptionStatus
  );
  const subscriptionPaymentFailureUtc = useApplicationStateSelector(
    (s) => s.common.subscriptionPaymentFailureUtc
  );
  const trialDaysRemaining = useApplicationStateSelector(
    (s) => s.common.tenantTrialDaysRemaining
  );

  let warningStatus: WarningStatus;
  let warningType: WarningType = WarningType.signup;
  if (!lockedWhenNotSubscribed) {
    warningStatus = WarningStatus.none;
  } else if (subscriptionStatus === TenantSubscriptionStatus.Canceled) {
    warningStatus = WarningStatus.warnWithoutProceed;
  } else if (subscriptionPaymentFailureUtc) {
    warningType = WarningType.updatePaymentMethod;
    if (!isLockedOutForFailedPayment({ subscriptionPaymentFailureUtc })) {
      warningStatus = WarningStatus.warnWithProceed;
    } else {
      warningStatus = WarningStatus.warnWithoutProceed;
    }
  } else if (subscriptionStatus === TenantSubscriptionStatus.TrialExpired) {
    if (typeof trialDaysRemaining === "number" && trialDaysRemaining >= -1) {
      warningStatus = WarningStatus.warnWithProceed;
    } else {
      warningStatus = WarningStatus.warnWithoutProceed;
    }
  } else if (subscriptionStatus === TenantSubscriptionStatus.TrialUnavailable) {
    warningStatus = WarningStatus.warnWithoutProceed;
    warningType = WarningType.trialUnavailable;
  } else {
    warningStatus = WarningStatus.none;
  }

  return { warningStatus, warningType };
}
