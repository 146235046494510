import { ICustomer } from "../models/ICustomer";
import { IJob } from "../models/IJob";
import customerFinder from "./customerFinder";
import { ICustomerAdditionalLocation } from "../models/ICustomerAdditionalLocation";
import { ICrew } from "../models/ICrew";
import { RouterState } from "connected-react-router";
import { parsers } from "./routing";
import { getSortedCrews, getSortedItemsV2 } from "./sortingService";
import { startOfWeek } from "date-fns";
import { ILineItem } from "../containers/app/components/InvoiceLineItem";
import { JobBillingType } from "../enums/jobBillingType";
import modelConversion from "./modelConversion";
import { getTotal } from "./lineItemService";
import { IDiscount } from "../models/IDiscount";
import { CrewScheduleType } from "../slices/schedule/enums/crewScheduleType";
import constants from "../constants";
import { getLocationName } from "./customerLocationHelper";

export function getNameForJob({
  job,
  customers,
  customerAdditionalLocations,
  fallbackToAddressIfAdditionalLocationNameNotSet,
}: {
  job: IJob;
  customers: Array<ICustomer>;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
  fallbackToAddressIfAdditionalLocationNameNotSet: boolean;
}) {
  let customerName: string | null = null;

  const customer = customerFinder.getCustomerByJob(job, customers);
  if (customer) {
    customerName = customer.name;
  }

  if (!customerName) {
    console.error(`could not find customer for job id '${job.id}'`);
    customerName = "Name Not Found";
  }

  let locationName: string | null = null;
  if (job.customerAdditionalLocationId) {
    const additionalLocation = customerAdditionalLocations.find(
      (l) => l.id === job.customerAdditionalLocationId
    );
    if (additionalLocation) {
      locationName = fallbackToAddressIfAdditionalLocationNameNotSet
        ? getLocationName(additionalLocation.id, customerAdditionalLocations)
        : additionalLocation.name?.trim() ?? "";
    } else {
      console.error(
        `could not find additional location for id '${job.customerAdditionalLocationId}'`
      );
    }
  }

  if (locationName) {
    customerName = `${customerName} - ${locationName}`;
  }

  return customerName;
}

export function getSelectedScheduleStartOfWeek(
  router: RouterState,
  crews: Array<ICrew>
) {
  let routeDate: Date | null = null;

  const parseResult = parsers.schedule.tryParseWeekSequence(router);
  if (parseResult.isMatch) {
    routeDate = parseResult.dateInRoute;
  } else {
    const dayParseResult = parsers.schedule.tryParseDaySequence(router, crews);
    if (dayParseResult.isMatch) {
      routeDate = dayParseResult.dateInRoute;
    }
  }

  if (!routeDate) {
    routeDate = new Date();
  }

  return startOfWeek(routeDate);
}

export function getDefaultCrewId(crews: Array<ICrew>, router: RouterState) {
  let crewId: string | null = null;

  const sortedCrews = getSortedCrews(crews);
  const activeSortedCrews = sortedCrews.filter((c) => !c.inactive);

  const parseResult = parsers.schedule.tryParseWeekSequence(router);
  if (parseResult.isMatch) {
    crewId = parseResult.crewIdInRoute as string;
  }

  if (crewId === null) {
    const dayParseResult = parsers.schedule.tryParseDaySequence(router, crews);
    if (dayParseResult.isMatch) {
      crewId = dayParseResult.crewIdInRoute;
    }
  }

  if (crewId === null) {
    const timeWeekParseResult = parsers.schedule.tryParseTimeWeek(router);
    if (timeWeekParseResult.isMatch) {
      crewId = timeWeekParseResult.crewIdInRoute;
    }
  }

  if (crewId === constants.allCrewsTimeBasedConstant) {
    crewId = getFirstTimeBasedCrewId(activeSortedCrews);
  } else if (crewId === constants.allCrewsSequenceConstant) {
    crewId = getFirstSequenceBasedCrewId(activeSortedCrews);
  } else if (crewId === null) {
    const timeDayParseResult = parsers.schedule.tryParseTimeDay(router, crews);
    if (timeDayParseResult.isMatch) {
      crewId = timeDayParseResult.crewIdInRoute;

      if (!crewId) {
        // Find first time based active crew
        crewId = getFirstTimeBasedCrewId(activeSortedCrews);
      }
    }
  }

  if (!crewId || !activeSortedCrews.some((c) => c.id === crewId)) {
    if (activeSortedCrews.length > 0) {
      return activeSortedCrews[0].id;
    }

    crewId = sortedCrews[0].id;
  }

  return crewId;
}

function getFirstTimeBasedCrewId(activeSortedCrews: ICrew[]) {
  return getFirstCrewOfType(activeSortedCrews, CrewScheduleType.time);
}

function getFirstSequenceBasedCrewId(activeSortedCrews: ICrew[]) {
  return getFirstCrewOfType(activeSortedCrews, CrewScheduleType.sequence);
}

function getFirstCrewOfType(
  activeSortedCrews: ICrew[],
  crewScheduleType: CrewScheduleType
) {
  const activeTimeBasedCrews = activeSortedCrews.filter(
    (c) => c.scheduleType === crewScheduleType
  );
  let crewId: string | null = null;
  if (activeTimeBasedCrews.length > 0) {
    crewId = activeTimeBasedCrews[0].id;
  }
  return crewId;
}

export function isFlexibleJob(job: { daysOfWeek: Array<string | number> }) {
  return (
    job.daysOfWeek &&
    (job.daysOfWeek.includes("0") || job.daysOfWeek.includes(0))
  );
}

export function getDaysOfWeekForDisplay(input: Array<number | string>) {
  if (!input || input.length === 0) {
    return "";
  }

  return getOrderedDaysOfWeek(input)
    .map((i) => getDayOfWeekForDisplay(i.originalValue))
    .join(", ");
}

export function getDayOfWeekForDisplay(dayOfWeek: string | number) {
  if (typeof dayOfWeek === "number") {
    dayOfWeek = dayOfWeek.toString();
  }

  switch (dayOfWeek) {
    case "7":
      return "Sunday";
    case "1":
      return "Monday";
    case "2":
      return "Tuesday";
    case "3":
      return "Wednesday";
    case "4":
      return "Thursday";
    case "5":
      return "Friday";
    case "6":
      return "Saturday";
    default:
      return "Flexible";
  }
}

export function getOrderedDaysOfWeek(daysOfWeek: Array<string | number>) {
  return getSortedItemsV2(
    daysOfWeek.map((dayOfWeek) => {
      dayOfWeek = dayOfWeek.toString();

      // Need to adjust days since here, Sunday is 7.  It should come first though.
      if (dayOfWeek === "0") {
        return { sortValue: -1, originalValue: dayOfWeek };
      } else if (dayOfWeek === "7") {
        return { sortValue: 0, originalValue: dayOfWeek };
      } else {
        return { sortValue: parseInt(dayOfWeek), originalValue: dayOfWeek };
      }
    }),
    ["sortValue"]
  );
}

export function getGrossRevenuePerVisitForForm(
  billingType: JobBillingType,
  amountPerVisit: string,
  lineItems: ILineItem[],
  discount: IDiscount,
  taxRate: number | null
): number | null {
  const isTotalBilling =
    billingType === JobBillingType.PerServiceTotal ||
    billingType === JobBillingType.AtProjectCompletionTotal;
  const isLineItemsBilling =
    billingType === JobBillingType.PerServiceLineItems ||
    billingType === JobBillingType.AtProjectCompletionLineItems;

  return isTotalBilling
    ? modelConversion.convertStringToNumberOrNull(amountPerVisit)
    : isLineItemsBilling
    ? getTotal({ taxRate, lineItems, discount })
    : null;
}
