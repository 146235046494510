export function CustomersChildEntityListRowButtons<TListingType>({
  row,
  onEditClick,
  onDeleteClick,
}: {
  row: TListingType;
  onEditClick?: (item: TListingType) => void;
  onDeleteClick?: (item: TListingType) => void;
}) {
  return (
    <>
      <button
        style={{ marginRight: "10px" }}
        className="btn btn-secondary btn-sm"
        onClick={() => {
          if (onEditClick) {
            onEditClick(row);
          }
        }}
      >
        Edit
      </button>
      <button
        className="btn btn-secondary btn-sm"
        onClick={() => {
          if (onDeleteClick) {
            onDeleteClick(row);
          }
        }}
      >
        Delete
      </button>
    </>
  );
}
