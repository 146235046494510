import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { PaymentMethodType } from "../../../enums/paymentMethodType";
import { IInvoiceView } from "../../../models/IInvoice";
import { getTransactionStatusText } from "../../../services/creditCardTransactionService";
import { formatCurrency } from "../../../services/currencyFormatter";
import { isStringSet } from "../../../services/stringService";
import dateService from "../../../services/dateService";
import React from "react";
import { IInvoicePaymentView } from "../../../models/IInvoicePayment";
import { getSortedItemsV2 } from "../../../services/sortingService";

interface IProps {
  invoice: IInvoiceView;
  formHeader: string;
  onClose: () => void;
  renderPayment: (payment: IInvoicePaymentView) => React.ReactNode;
}

const BillingInvoiceListPaymentsList: React.FunctionComponent<IProps> = ({
  invoice,
  formHeader,
  onClose,
  renderPayment,
}) => {
  return (
    <>
      <Modal isOpen={true} scrollable zIndex={1050}>
        <ModalHeader
          toggle={() => {
            onClose();
          }}
        >
          {formHeader}
        </ModalHeader>
        <ModalBody>
          {getSortedItemsV2(invoice.payments, ["datePaid"]).map((payment) => {
            return (
              <React.Fragment key={payment.id}>
                {renderPayment(payment)}
              </React.Fragment>
            );
          })}
        </ModalBody>
        <ModalFooter>
          <button
            className="btn btn-secondary"
            type="button"
            onClick={() => onClose()}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default BillingInvoiceListPaymentsList;

export function PaymentFields({ payment }: { payment: IInvoicePaymentView }) {
  const paymentMethodTypeSet = typeof payment.paymentMethodType === "number";
  const payrixTransactionStatusSet =
    typeof payment.payrixTransactionStatus === "number";
  const referenceSet =
    (payment.paymentReferenceInformation ?? "").trim() !== "";

  return (
    <>
      <PaymentField
        visible={true}
        label="Amount"
        value={formatCurrency(payment.amount)}
      />
      <PaymentField
        visible={paymentMethodTypeSet}
        label="Payment type"
        value={getPaymentType(payment.paymentMethodType)}
      />
      <PaymentField
        visible={payrixTransactionStatusSet}
        label="Payment status"
        value={
          payment.payrixTransactionStatus !== null
            ? getTransactionStatusText(payment.payrixTransactionStatus)
            : ""
        }
      />
      <PaymentField
        visible={
          typeof payment.convenienceFeePaid === "number" &&
          payment.convenienceFeePaid > 0
        }
        label="Convenience fee"
        value={formatCurrency(payment.convenienceFeePaid ?? 0)}
      />
      <PaymentField
        visible={payment.payrixTransactionAmount !== null}
        label="Charged amount"
        value={formatCurrency(payment.payrixTransactionAmount ?? 0)}
      />
      <PaymentField
        visible={referenceSet}
        label="Reference information"
        value={payment.paymentReferenceInformation ?? ""}
      />
    </>
  );
}

export function PaymentHeader({ payment }: { payment: IInvoicePaymentView }) {
  return (
    <h6>
      Payment
      {isStringSet(payment.datePaid)
        ? ` on ${dateService.formatDateForDisplay(payment.datePaid)}`
        : ""}
    </h6>
  );
}

function PaymentField({
  label,
  value,
  visible,
}: {
  label: string;
  value: string;
  visible: boolean;
}) {
  if (!visible) {
    return null;
  }

  return (
    <div className="mb-3">
      <div className="font-weight-light">{label}</div>
      <div>{value}</div>
    </div>
  );
}

function getPaymentType(type: PaymentMethodType | null) {
  switch (type) {
    case PaymentMethodType.Ach:
      return "Bank transfer";
    case PaymentMethodType.CreditCard:
      return "Credit card";
    case PaymentMethodType.Check:
      return "Check";
    case PaymentMethodType.Cash:
      return "Cash";
    case PaymentMethodType.Other:
      return "Other";
    case null:
      return "";
  }
}
