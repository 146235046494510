import { actionTypes } from "./actionCreators";
import {
  IJobInstanceToggleSkippedStart,
  IJobInstanceToggleSkippedComplete,
  IJobInstanceToggleSkippedError,
  IJobInstanceDeleteError,
  IJobInstanceToggleSelected,
  IJobInstanceDragStart,
  IJobInstanceDeleteComplete,
  IUnscheduledJobsCategoryFilterSet,
  IPermanentJobsSetMessage,
  IUnscheduledJobsSearchTextFilterSet,
  IAutoRouteJobsCompleted,
  IJobInstanceToggleCompletedError,
  IJobInstanceToggleCompletedStart,
  IJobInstanceToggleCompletedComplete,
  IPermanentJobsSaveError,
  IUnscheduledJobsSetPage,
  IUnscheduledJobsSetDisplayModel,
  IDropJobAction,
} from "./actionTypeDefinitions";
import {
  formTypes,
  actionTypes as formActionTypes,
  createSpecificActionTypeName,
} from "../formGenerator";
import { IPermanentDropJobs } from "../models/IPermanentDropJobs";
import {
  ICustomerNotificationResultForSingleCustomer,
  ICustomerNotificationResult,
} from "../services/remoteDataProvider";
import { UnscheduledJobsDisplayMode } from "../enums/unscheduledJobsDisplayMode";
import { IBulkCopyJobsParameters } from "../formGenerator/formParameters/IBulkCopyJobsParameters";

export interface IScheduleUiState {
  columnWithMapMode: ISelectedColumn | null;
  headersVisible: Array<ISelectedColumn>;
  jobInstancesCompletedSaving: Array<string>;
  jobInstancesSkipSaving: Array<string>;
  jobInstancesMoving: Array<string>;
  pageLevelMessage: string;
  pageLevelMessageKey: string;
  pageLevelAlertClass: string;
  deleteJobInstanceInProgress: boolean;
  selectedJobInstanceIds: Array<string>;
  lastSingleSelectedJobInstance: string | null;
  draggedJobInstanceId: string | null;
  unscheduledJobCategoryFilter: IUnscheduledJobCategoryFilter | null;
  unscheduledJobSearchTextFilter: string;
  permanentDropJobsMessage: IPermanentDropJobs | null;
  permanentDropJobsSaveInProgress: boolean;
  permanentDropJobsSaveError: boolean;
  permanentDropJobsSaveValidationError: boolean;
  permanentDropJobsSaveValidationErrorMessage: string | null;
  customerNotificationResults: Array<ICustomerNotificationResultForSingleCustomer>;

  autoRoutePrompt: IAutoRoutePrompt | null;
  autoRoutePromptError: boolean;
  autoRoutePromptSaving: boolean;
  autoRouteAllowReverse: boolean;

  unscheduledJobsDisplayMode: UnscheduledJobsDisplayMode;
  unscheduledJobsCurrentPage: number;
}

export interface ISelectedColumn {
  dayScheduleId: string;
  mode: string;
}

export interface IAutoRoutePrompt {
  dayScheduleId: string;
  maintenanceJobIds: Array<string>;
  jobInstanceIds: Array<string>;
}

export interface IUnscheduledJobCategoryFilter {
  id: string;
  name: string;
}

export default (
  state: IScheduleUiState | undefined,
  action: any
): IScheduleUiState => {
  if (!state) {
    state = {
      columnWithMapMode: null,
      headersVisible: [],
      pageLevelMessage: "",
      pageLevelAlertClass: "",
      pageLevelMessageKey: "",
      jobInstancesSkipSaving: [],
      jobInstancesCompletedSaving: [],
      jobInstancesMoving: [],
      deleteJobInstanceInProgress: false,
      selectedJobInstanceIds: [],
      lastSingleSelectedJobInstance: null,
      draggedJobInstanceId: null,
      unscheduledJobCategoryFilter: null,
      unscheduledJobSearchTextFilter: "",
      permanentDropJobsMessage: null,
      permanentDropJobsSaveInProgress: false,
      permanentDropJobsSaveError: false,
      permanentDropJobsSaveValidationError: false,
      permanentDropJobsSaveValidationErrorMessage: null,
      customerNotificationResults: [],
      autoRoutePrompt: null,
      autoRoutePromptError: false,
      autoRoutePromptSaving: false,
      autoRouteAllowReverse: false,

      unscheduledJobsDisplayMode: UnscheduledJobsDisplayMode.Card,
      unscheduledJobsCurrentPage: 0,
    };
  }

  switch (action.type) {
    case actionTypes.DROP_JOB_ERROR:
      return {
        ...state,
        pageLevelMessage:
          "An error occurred when dragging the job. Refresh your browser to get the updated calendar. If you continue to experience this problem, please contact support for help.",
        pageLevelAlertClass: "alert-danger",
        jobInstancesMoving: state.jobInstancesMoving.filter(
          (existingId) =>
            !action.jobInstanceIds.some(
              (savedId: string) => existingId === savedId
            )
        ),
      };

    case actionTypes.CALENDAR_JOB_RESIZE_ERROR:
      return {
        ...state,
        pageLevelMessage:
          "An error occurred updating the job. Refresh your browser to get the updated calendar. If you continue to experience this problem, please contact support for help.",
        pageLevelAlertClass: "alert-danger",
      };

    case actionTypes.ROUTER_LOCATION_CHANGE:
      return {
        ...state,
        selectedJobInstanceIds: [],
        lastSingleSelectedJobInstance: null,
        autoRoutePromptError: false,
        autoRoutePrompt: null,
        unscheduledJobsCurrentPage: 0,
      };

    case actionTypes.DROP_JOB:
      const dropJobAction = action as IDropJobAction;
      const wasJobOnAutoRoutePromptScheduleMoved =
        state.autoRoutePrompt !== null &&
        dropJobAction.daySchedulesForMovedJobInstances.includes(
          state.autoRoutePrompt.dayScheduleId
        );

      return {
        ...state,
        jobInstancesMoving: [
          ...state.jobInstancesMoving,
          ...action.jobInstanceIds,
        ],
        selectedJobInstanceIds: [],
        lastSingleSelectedJobInstance: null,
        autoRoutePrompt: wasJobOnAutoRoutePromptScheduleMoved
          ? null
          : state.autoRoutePrompt,
      };

    case actionTypes.DROP_JOB_COMPLETED:
      return {
        ...state,
        jobInstancesMoving: state.jobInstancesMoving.filter(
          (existingId) =>
            !action.jobInstanceIds.some(
              (savedId: string) => existingId === savedId
            )
        ),
      };

    case actionTypes.JOB_INSTANCE_DRAG_START:
      const draggingStartAction = action as IJobInstanceDragStart;
      return {
        ...state,
        draggedJobInstanceId: draggingStartAction.jobInstanceId,
      };

    case actionTypes.JOB_INSTANCE_DRAG_END:
      return {
        ...state,
        draggedJobInstanceId: null,
      };

    case actionTypes.JOB_INSTANCE_TOGGLE_SELECTED:
      const toggleSelectedAction = action as IJobInstanceToggleSelected;
      const itemsNotAlreadySelected =
        toggleSelectedAction.jobInstanceIds.filter(
          (i) => !(state as IScheduleUiState).selectedJobInstanceIds.includes(i)
        );

      // First, filter out items and then re-add if applicable
      let newSelectedJobInstanceIds: Array<string> =
        state.selectedJobInstanceIds.filter(
          (s) => !toggleSelectedAction.jobInstanceIds.includes(s)
        );
      if (toggleSelectedAction.selected === true) {
        newSelectedJobInstanceIds = [
          ...newSelectedJobInstanceIds,
          ...toggleSelectedAction.jobInstanceIds,
        ];
      } else if (toggleSelectedAction.selected === undefined) {
        newSelectedJobInstanceIds = [
          ...newSelectedJobInstanceIds,
          ...itemsNotAlreadySelected,
        ];
      }

      return {
        ...state,
        selectedJobInstanceIds: newSelectedJobInstanceIds,
        lastSingleSelectedJobInstance:
          toggleSelectedAction.singleJobInstanceToggle &&
          toggleSelectedAction.jobInstanceIds.length === 1 &&
          newSelectedJobInstanceIds.includes(
            toggleSelectedAction.jobInstanceIds[0]
          )
            ? toggleSelectedAction.jobInstanceIds[0]
            : null,
      };

    case actionTypes.JOB_INSTANCE_DELETE_START:
      return {
        ...state,
        deleteJobInstanceInProgress: true,
      };

    case actionTypes.JOB_INSTANCE_DELETE_COMPLETE:
      const jobInstanceDeleteComplete = action as IJobInstanceDeleteComplete;
      return {
        ...state,
        selectedJobInstanceIds: state.selectedJobInstanceIds.filter(
          (selectedJobInstanceId) =>
            !jobInstanceDeleteComplete.jobInstanceIds.some(
              (deletedJobInstanceId) =>
                selectedJobInstanceId === deletedJobInstanceId
            )
        ),
        lastSingleSelectedJobInstance: null,
        deleteJobInstanceInProgress: false,
        pageLevelMessage: "",
        pageLevelAlertClass: "",
      };

    case actionTypes.JOB_INSTANCE_DELETE_ERROR:
      const jobInstanceDeleteError = action as IJobInstanceDeleteError;
      return {
        ...state,
        deleteJobInstanceInProgress: false,
        pageLevelMessage: jobInstanceDeleteError.error,
        pageLevelAlertClass: "alert-danger",
      };

    case actionTypes.AUTO_ROUTE_JOBS_COMPLETED:
      const autoRouteJobsCompletedAction = action as IAutoRouteJobsCompleted;
      return {
        ...state,
        autoRouteAllowReverse: autoRouteJobsCompletedAction.allowReverse,
        autoRoutePrompt: {
          dayScheduleId: autoRouteJobsCompletedAction.dayScheduleId,
          maintenanceJobIds: autoRouteJobsCompletedAction.maintenanceJobIds,
          jobInstanceIds: autoRouteJobsCompletedAction.jobInstanceIds,
        },
      };

    case actionTypes.AUTO_ROUTE_JOBS_PERMANENT_SAVE_STARTING:
      return {
        ...state,
        autoRoutePromptError: false,
        autoRoutePromptSaving: true,
      };

    case actionTypes.AUTO_ROUTE_JOBS_PROMPT_CLEAR:
      return {
        ...state,
        autoRoutePrompt: null,
      };

    case actionTypes.AUTO_ROUTE_JOBS_PERMANENT_SAVE_COMPLETED:
      return {
        ...state,
        autoRoutePrompt: null,
        autoRoutePromptSaving: false,
      };

    case actionTypes.AUTO_ROUTE_JOBS_PROMPT_SAVE_ERROR:
      return {
        ...state,
        autoRoutePromptError: true,
        autoRoutePromptSaving: false,
      };

    case actionTypes.AUTO_ROUTE_JOBS_ERROR:
      return {
        ...state,
        pageLevelMessage:
          "An unknown error occurred while optimizing route.  Please try again!",
        pageLevelAlertClass: "alert-warning",
        pageLevelMessageKey: action.messageKey || "",
      };

    case createSpecificActionTypeName(
      formTypes.moveJobInstance,
      formActionTypes.showForm
    ):
      return {
        ...state,
        lastSingleSelectedJobInstance: null,
        selectedJobInstanceIds: state.selectedJobInstanceIds.filter(
          (selectedJobInstanceId) =>
            !action.parameters.jobInstanceIds.some(
              (savedJobInstanceId: string) =>
                selectedJobInstanceId === savedJobInstanceId
            )
        ),
      };

    case createSpecificActionTypeName(
      formTypes.bulkCopyJobs,
      formActionTypes.showForm
    ):
      const actionParameters = action.parameters as IBulkCopyJobsParameters;
      return {
        ...state,
        lastSingleSelectedJobInstance: null,
        selectedJobInstanceIds: state.selectedJobInstanceIds.filter(
          (selectedJobInstanceId) =>
            !actionParameters.sourceJobInstanceIds.some(
              (savedJobInstanceId: string) =>
                selectedJobInstanceId === savedJobInstanceId
            )
        ),
      };

    case createSpecificActionTypeName(
      formTypes.customerNotification,
      formActionTypes.showForm
    ):
      return {
        ...state,
        selectedJobInstanceIds: [],
        lastSingleSelectedJobInstance: null,
      };

    case createSpecificActionTypeName(
      formTypes.customerNotification,
      formActionTypes.completeSaving
    ):
      const customerResults = (action.payload as ICustomerNotificationResult)
        .customerResults;
      return {
        ...state,
        customerNotificationResults: customerResults,
      };

    case actionTypes.CLEAR_CUSTOMER_NOTIFICATION_RESULTS:
      return {
        ...state,
        customerNotificationResults: [],
      };

    case createSpecificActionTypeName(
      formTypes.publishSchedule,
      formActionTypes.completeSaving
    ):
      return {
        ...state,
        pageLevelMessage: action.payload.savesDefaultRecipientsOnly
          ? "Recipients updated!"
          : "Schedule sent!",
        pageLevelAlertClass: "alert-success",
        pageLevelMessageKey: action.messageKey || "",
      };

    case actionTypes.CLOSE_PAGE_LEVEL_MESSAGE:
      return {
        ...state,
        pageLevelMessage:
          action.messageKey === state.pageLevelMessageKey || !action.messageKey
            ? ""
            : state.pageLevelMessage,
      };

    case actionTypes.TOGGLE_HEADER_VISIBILITY:
      let headersVisible = [...state.headersVisible];
      if (action.visible) {
        headersVisible.push({
          dayScheduleId: action.dayScheduleId,
          mode: action.mode,
        });
      } else {
        headersVisible = headersVisible.filter(
          (v) =>
            !(
              v.dayScheduleId === action.dayScheduleId && v.mode === action.mode
            )
        );
      }

      return {
        ...state,
        headersVisible,
      };

    case actionTypes.JOB_INSTANCE_TOGGLE_SKIPPED_START:
      const jobInstanceToggleSkippedStartAction =
        action as IJobInstanceToggleSkippedStart;

      return {
        ...state,
        jobInstancesSkipSaving: [
          ...state.jobInstancesSkipSaving,
          ...jobInstanceToggleSkippedStartAction.jobInstanceIds,
        ],
      };

    case actionTypes.JOB_INSTANCE_TOGGLE_SKIPPED_COMPLETE:
      const jobInstanceToggleSkippedCompleteAction =
        action as IJobInstanceToggleSkippedComplete;

      let updatedSelectedJobInstanceIds = state.selectedJobInstanceIds;
      if (jobInstanceToggleSkippedCompleteAction.clearSelected) {
        updatedSelectedJobInstanceIds = updatedSelectedJobInstanceIds.filter(
          (selectedJi) =>
            !jobInstanceToggleSkippedCompleteAction.jobInstanceIds.some(
              (skippedJi) => selectedJi === skippedJi
            )
        );
      }

      return {
        ...state,
        selectedJobInstanceIds: updatedSelectedJobInstanceIds,
        lastSingleSelectedJobInstance: null,
        jobInstancesSkipSaving: state.jobInstancesSkipSaving.filter(
          (ji) =>
            !jobInstanceToggleSkippedCompleteAction.jobInstanceIds.some(
              (skippedJi) => ji === skippedJi
            )
        ),
      };

    case actionTypes.JOB_INSTANCE_TOGGLE_SKIPPED_ERROR:
      const jobInstanceToggleSkippedErrorAction =
        action as IJobInstanceToggleSkippedError;
      return {
        ...state,
        pageLevelMessage: jobInstanceToggleSkippedErrorAction.error,
        pageLevelAlertClass: "alert-danger",
        jobInstancesSkipSaving: state.jobInstancesSkipSaving.filter(
          (ji) =>
            !jobInstanceToggleSkippedErrorAction.jobInstanceIds.some(
              (skippedJi) => ji === skippedJi
            )
        ),
      };

    case actionTypes.JOB_INSTANCE_TOGGLE_COMPLETED_START:
      const jobInstanceToggleCompletedStartAction =
        action as IJobInstanceToggleCompletedStart;

      return {
        ...state,
        jobInstancesCompletedSaving: [
          ...state.jobInstancesCompletedSaving,
          ...jobInstanceToggleCompletedStartAction.jobInstanceIds,
        ],
      };

    case actionTypes.JOB_INSTANCE_TOGGLE_COMPLETED_COMPLETE:
      const jobInstanceToggleCompletedCompleteAction =
        action as IJobInstanceToggleCompletedComplete;

      const updatedSelectedJobInstanceIdsFromCompleted =
        state.selectedJobInstanceIds.filter(
          (selectedJi) =>
            !jobInstanceToggleCompletedCompleteAction.jobInstanceIds.some(
              (skippedJi) => selectedJi === skippedJi
            )
        );

      return {
        ...state,
        selectedJobInstanceIds: updatedSelectedJobInstanceIdsFromCompleted,
        jobInstancesCompletedSaving: state.jobInstancesCompletedSaving.filter(
          (ji) =>
            !jobInstanceToggleCompletedCompleteAction.jobInstanceIds.some(
              (skippedJi) => ji === skippedJi
            )
        ),
      };

    case actionTypes.JOB_INSTANCE_TOGGLE_COMPLETED_ERROR:
      const jobInstanceToggleCompletedErrorAction =
        action as IJobInstanceToggleCompletedError;
      return {
        ...state,
        pageLevelMessage: jobInstanceToggleCompletedErrorAction.error,
        pageLevelAlertClass: "alert-danger",
        jobInstancesCompletedSaving: state.jobInstancesCompletedSaving.filter(
          (ji) =>
            !jobInstanceToggleCompletedErrorAction.jobInstanceIds.some(
              (completedJi) => ji === completedJi
            )
        ),
      };

    case actionTypes.SET_UNSCHEDULEDJOBS_CATEGORYFILTER:
      const setUnscheduledJobsCategoryFilterAction =
        action as IUnscheduledJobsCategoryFilterSet;
      return {
        ...state,
        unscheduledJobCategoryFilter:
          setUnscheduledJobsCategoryFilterAction.categoryFilter,
      };

    case actionTypes.SET_UNSCHEDULEDJOBS_SEARCHTEXTFILTER:
      const setUnscheduledJobsSearchTextFilterAction =
        action as IUnscheduledJobsSearchTextFilterSet;
      return {
        ...state,
        unscheduledJobSearchTextFilter:
          setUnscheduledJobsSearchTextFilterAction.searchText,
      };

    case actionTypes.PERMANENT_JOBS_SET_MESSAGE:
      const permanentJobSetMessageAction = action as IPermanentJobsSetMessage;
      return {
        ...state,
        permanentDropJobsMessage: permanentJobSetMessageAction,
        permanentDropJobsSaveError: false,
        permanentDropJobsSaveValidationError: false,
        permanentDropJobsSaveValidationErrorMessage: null,
      };

    case actionTypes.PERMANENT_JOBS_CLEAR_MESSAGE:
      return {
        ...state,
        permanentDropJobsMessage: null,
        permanentDropJobsSaveError: false,
        permanentDropJobsSaveValidationError: false,
        permanentDropJobsSaveValidationErrorMessage: null,
      };

    case actionTypes.PERMANENT_JOBS_SAVE_START:
      return {
        ...state,
        permanentDropJobsSaveInProgress: true,
      };

    case actionTypes.PERMANENT_JOBS_SAVE_ERROR:
      const permanentJobsSaveErrorAction = action as IPermanentJobsSaveError;
      return {
        ...state,
        permanentDropJobsSaveInProgress: false,
        permanentDropJobsSaveError: true,
        permanentDropJobsSaveValidationError:
          permanentJobsSaveErrorAction.validationError,
        permanentDropJobsSaveValidationErrorMessage:
          permanentJobsSaveErrorAction.validationErrorMessage,
      };

    case actionTypes.PERMANENT_JOBS_SAVE_COMPLETE:
      return {
        ...state,
        permanentDropJobsMessage: null,
        permanentDropJobsSaveInProgress: false,
        permanentDropJobsSaveError: false,
      };

    case createSpecificActionTypeName(
      formTypes.adminViewConfiguration,
      formActionTypes.completeSaving
    ):
      // Clear prompt Admin View Config changed so that old prompts aren't shown now
      // if user enabled showing the prompt when it was previously hidden
      return {
        ...state,
        permanentDropJobsMessage: null,
        permanentDropJobsSaveInProgress: false,
        permanentDropJobsSaveError: false,
      };

    case actionTypes.UNSCHEDULED_JOBS_SET_PAGE:
      return {
        ...state,
        unscheduledJobsCurrentPage: (action as IUnscheduledJobsSetPage).page,
      };

    case actionTypes.UNSCHEDULED_JOBS_SET_DISPLAY_MODE:
      return {
        ...state,
        unscheduledJobsDisplayMode: (action as IUnscheduledJobsSetDisplayModel)
          .displayMode,
      };

    default:
      return state;
  }
};
