import uuidv4 from "uuid/v4";
import {
  actionTypes,
  createSpecificActionTypeName,
  ICancelFormAction,
  IClearErrorMessageAction,
  ICompleteSavingAction,
  ISetErrorMessageAction,
  IShowFormAction,
  IStartSavingAction,
} from "./actionTypes";
import { formTypes, IFormType } from "./formTypes";
import { objectMap } from "./objectMap";
import React from "react";

interface IFormTypeActionCreator<TParameters, TPayload> {
  showForm: (parameters: TParameters) => IShowFormAction<TParameters>;

  cancelForm: () => ICancelFormAction;

  startSaving: (
    payload: TPayload,
    reopenFormOnComplete?: boolean
  ) => IStartSavingAction<TPayload>;

  completeSaving: (
    parameters: TParameters,
    payload: TPayload,
    reopenFormOnComplete?: boolean,
    keepFormOpen?: boolean
  ) => ICompleteSavingAction<TParameters, TPayload>;

  setErrorMessage: (
    errorMessage: string | React.ReactNode
  ) => ISetErrorMessageAction;

  clearErrorMessage: () => IClearErrorMessageAction;
}

function createFormActionCreators<TParameters, TPayload>(
  formToCreateKey: keyof typeof formTypes
): IFormTypeActionCreator<TParameters, TPayload> {
  const createTypeNameForForm = (actionType: any) =>
    createSpecificActionTypeName(formTypes[formToCreateKey], actionType);

  return {
    showForm: (parameters: TParameters) => {
      return {
        type: createTypeNameForForm(actionTypes.showForm),
        parameters,
      };
    },

    cancelForm: () => ({
      type: createTypeNameForForm(actionTypes.cancelForm),
    }),

    startSaving: (payload: any, reopenFormOnComplete?: boolean) => ({
      type: createTypeNameForForm(actionTypes.startSaving),
      payload,
      reopenFormOnComplete,
    }),

    completeSaving: (
      parameters: TParameters,
      payload: any,
      reopenFormOnComplete?: boolean,
      keepFormOpen?: boolean
    ) => ({
      type: createTypeNameForForm(actionTypes.completeSaving),
      messageKey: uuidv4(),
      parameters,
      payload,
      reopenFormOnComplete,
      keepFormOpen,
    }),

    setErrorMessage: (errorMessage: string | React.ReactNode) => ({
      type: createTypeNameForForm(actionTypes.setErrorMessage),
      errorMessage,
    }),

    clearErrorMessage: () => ({
      type: createTypeNameForForm(actionTypes.clearErrorMessage),
    }),
  };
}

type ActionCreatorConversion<T> = {
  [K in keyof T]: T[K] extends IFormType<infer TParameters, infer TPayload>
    ? IFormTypeActionCreator<TParameters, TPayload>
    : IFormTypeActionCreator<any, any>;
};

const actionCreators = objectMap(formTypes, (key) =>
  createFormActionCreators(key)
) as ActionCreatorConversion<typeof formTypes>;

export default actionCreators;
