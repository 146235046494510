import React from "react";
import Prompt from "./Prompt";

interface IProps {
  onCancel: () => void;
  onConfirm: () => void;
}

const QuickBooksTaxAlertPrompt: React.FunctionComponent<IProps> = ({
  onCancel,
  onConfirm,
}) => {
  return (
    <Prompt
      promptMessage="Crew Control uses QuickBooks Online Automated Sales Tax (AST) to calculate invoice taxes. Please set this up in QuickBooks Online before proceeding."
      onCancel={() => onCancel()}
      onConfirm={() => onConfirm()}
      showPrompt={true}
      promptSaveText="Proceed Anyway"
      promptCancelText="Cancel"
    />
  );
};

export default QuickBooksTaxAlertPrompt;
