import React from "react";
import GenericErrorMessage from "./components/GenericErrorMessage";
import { trackException } from "../../services/applicationInsights";
import { logError } from "../../services/errorLogger";
import { fullStoryLogError } from "../../services/fullStoryService";

interface IProps {}

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });

    console.log(error);
    console.log(info);

    fullStoryLogError(error);

    if (error instanceof Error) {
      trackException(error);
    } else {
      logError('ErrorBoundary error is not of type "Error"');
    }
  }

  render() {
    if (this.state.hasError) {
      return <GenericErrorMessage />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
