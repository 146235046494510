import React, { useState } from "react";
import { IJobInstance } from "../../../../../models/IJobInstance";
import ContextMenuButtons from "./ContextMenuButtons";
import { JobType } from "../../../../../models/IJob";
import CardContextMenu from "../../CardContextMenu";
import ProjectFormBody from "../../../../../slices/schedule/components/ProjectForm";
import { ICrew } from "../../../../../models/ICrew";
import { JobInstanceTimeForm } from "../../../../../slices/schedule/components/JobInstanceTimeForm";
import CompleteForm from "../../../forms/CompleteForm";

interface IProps {
  jobInstance: IJobInstance;
  hideSkip?: boolean;
  hideUpdateCompletionInformation?: boolean;
  nameForJob: string;
  jobType: JobType;
  crew: ICrew | null;
  showDetailsLink?: boolean;
  setShowDetails?: (newValue: boolean) => void;
  smallContextTrigger?: boolean;

  renderCustomTrigger?: (arg: {
    toggleContextMenuOpen(): void;
    skipContextMenuCloseClass: string;
  }) => JSX.Element;

  isContextMenuOpen: boolean;
  setIsContextMenuOpen: (newValue: boolean) => void;

  respectAllContextMenuButtonsInGlobalHandler?: boolean;
}

const ContextMenu: React.FunctionComponent<IProps> = ({
  jobInstance,
  showDetailsLink,
  hideSkip,
  hideUpdateCompletionInformation,
  nameForJob,
  jobType,
  crew,
  setShowDetails,
  renderCustomTrigger,
  smallContextTrigger,
  isContextMenuOpen,
  setIsContextMenuOpen,
  respectAllContextMenuButtonsInGlobalHandler,
}) => {
  const [showProjectForm, setShowProjectForm] = useState(false);
  const [showJobInstanceTimeForm, setShowJobInstanceTimeForm] = useState(false);
  const [showCompletionInfoForm, setShowCompletionInfoForm] = useState(false);

  return (
    <>
      <CardContextMenu
        isContextMenuOpen={isContextMenuOpen}
        setIsContextMenuOpen={(v) => {
          setIsContextMenuOpen(v);
        }}
        renderCustomTrigger={renderCustomTrigger}
        smallContextTrigger={smallContextTrigger ?? false}
        respectAllContextMenuButtonsInGlobalHandler={
          respectAllContextMenuButtonsInGlobalHandler
        }
      >
        {({ skipContextMenuCloseClass }) => (
          <ContextMenuButtons
            jobInstance={jobInstance}
            nameForJob={nameForJob}
            jobType={jobType}
            hideSkip={hideSkip}
            hideUpdateCompletionInformation={hideUpdateCompletionInformation}
            skipContextMenuCloseClass={skipContextMenuCloseClass}
            setShowProjectForm={setShowProjectForm}
            setShowJobInstanceTimeForm={setShowJobInstanceTimeForm}
            setShowDetails={(showDetails) => {
              if (typeof setShowDetails === "function") {
                setShowDetails(showDetails);
              }
            }}
            crew={crew}
            showDetailsLink={showDetailsLink ?? false}
            setShowCompletionInfoForm={setShowCompletionInfoForm}
          />
        )}
      </CardContextMenu>

      {showProjectForm && jobInstance.projectId ? (
        <ProjectFormBody
          mode="edit"
          projectId={jobInstance.projectId}
          onSaveComplete={() => setShowProjectForm(false)}
          onCancel={() => setShowProjectForm(false)}
        />
      ) : null}

      {showJobInstanceTimeForm ? (
        <>
          <JobInstanceTimeForm
            jobInstanceId={jobInstance.id}
            onSaveComplete={() => {
              setShowJobInstanceTimeForm(false);
            }}
            onCancel={() => {
              setShowJobInstanceTimeForm(false);
            }}
            jobType={jobType}
          />
        </>
      ) : null}

      {showCompletionInfoForm ? (
        <CompleteForm
          jobId={jobInstance.id}
          onCancel={() => {
            setShowCompletionInfoForm(false);
          }}
          onSaveComplete={() => {
            setShowCompletionInfoForm(false);
          }}
        />
      ) : null}
    </>
  );
};

export default ContextMenu;
