import * as React from "react";
import { RefObject } from "react";
import LinkButton2 from "./LinkButton2";

interface IProps {
  className?: string;
  style?: any;
  id?: string;
  buttonContents: string | JSX.Element;
  onClick(event: React.MouseEvent): void;
  onMouseEnter?(event: React.MouseEvent): void;
  onMouseOver?(event: React.MouseEvent): void;
  onMouseOut?(event: React.MouseEvent): void;
  testId?: string;
  ref?: RefObject<HTMLButtonElement>;
}

const CardContextMenuButtonLink: React.FunctionComponent<IProps> = (props) => {
  let style = props.style;
  if (!style) {
    style = {};
  }

  return (
    <LinkButton2
      {...props}
      style={{
        padding: 0,
        fontSize: "14px",
        textAlign: "left",
        width: "100%",
        ...style,
      }}
    />
  );
};

export default CardContextMenuButtonLink;
