import React, { useState } from "react";
import { timeout } from "rxjs/operators";
import Prompt from "../../../containers/app/components/Prompt";
import opportunityDataProvider from "../services/opportunityDataProvider";

interface IProps {
  opportunityId: string;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  onDeleteComplete: () => void;
  onDeleteError: () => void;
}

const OpportunityDelete: React.FunctionComponent<IProps> = ({
  opportunityId,
  setIsDeleting,
  onClose,
  onDeleteComplete,
  onDeleteError,
}) => {
  const [showDeletePrompt, setShowDeletePrompt] = useState(true);

  return (
    <Prompt
      showPrompt={showDeletePrompt}
      promptMessage="Are you sure you want to delete this opportunity?"
      onConfirm={() => {
        setShowDeletePrompt(false);
        setIsDeleting(true);

        opportunityDataProvider
          .deleteOpportunity(opportunityId)
          .pipe(timeout(5000))
          .subscribe({
            next: () => {
              onClose();
              setIsDeleting(false);
              onDeleteComplete();
            },

            error: () => {
              onClose();
              setIsDeleting(false);
              onDeleteError();
            },
          });
      }}
      onCancel={() => onClose()}
    />
  );
};

export default OpportunityDelete;
