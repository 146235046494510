const convertStringToNumberOrNull = (input: string | null): number | null => {
  if (input) {
    return parseFloat(input);
  } else {
    return null;
  }
};

const convertStringToNumber = (input: string | null): number => {
  if (input) {
    return parseFloat(input);
  } else {
    throw new Error("Value not set");
  }
};

const convertStringOrNumberToNumber = (input: string | number) => {
  if (typeof input === "number") {
    return input;
  } else {
    return parseFloat(input);
  }
};

export default {
  convertStringToNumberOrNull,
  convertStringToNumber,
  convertStringOrNumberToNumber
};
