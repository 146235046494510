import React from "react";
import PublicContentContainer from "../../../containers/app/components/PublicContentContainer";
import { TermsOfService } from "../../../containers/app/components/TermsOfService";

const TermsOfServicePage: React.FunctionComponent<{}> = () => (
  <PublicContentContainer>
    <TermsOfService />
  </PublicContentContainer>
);

export default TermsOfServicePage;
