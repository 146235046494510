import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Alert } from "reactstrap";
import { Subscription } from "rxjs";
import { timeout } from "rxjs/operators";
import Spinner from "../../../containers/app/components/Spinner";
import { IInvoiceForPrint } from "../../../models/IInvoiceForPrint";
import invoiceDataProvider from "../services/invoiceDataProvider";
import InvoicePrint from "./InvoicePrint";

interface IRouteParams {
  invoiceId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {}

const InvoicePrintPage: React.FunctionComponent<IProps> = ({ match }) => {
  const { invoiceId } = match.params;
  const [invoice, setInvoice] = useState<IInvoiceForPrint | null>(null);
  const [errorLoading, setErrorLoading] = useState(false);
  const printContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let subscription: Subscription | null;
    if (invoiceId) {
      subscription = invoiceDataProvider
        .getInvoicesForPrint([invoiceId])
        .pipe(timeout(5000))
        .subscribe({
          next: (i) => {
            setInvoice(i[0]);
          },

          error: () => {
            setErrorLoading(true);
          },
        });
    }

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [invoiceId]);

  if (!invoiceId || errorLoading) {
    return <Alert message="Unable to load the invoice to print." />;
  } else if (!invoice) {
    return <Spinner />;
  } else {
    return (
      <div ref={printContainer}>
        <InvoicePrint invoice={invoice} onLoadComplete={() => {}} />
      </div>
    );
  }
};

export default InvoicePrintPage;
