import { useEffect } from "react";

export function useHideChildrenIfOtherSubmenuShown(
  showSubmenuA: boolean,
  setShowSubmenuB: React.Dispatch<React.SetStateAction<boolean>>
) {
  useEffect(() => {
    if (showSubmenuA) {
      setShowSubmenuB(false);
    }
  }, [showSubmenuA, setShowSubmenuB]);
}

export function useHideChildrenIfOtherSubmenusShown(
  showSubmenuA: boolean,
  setShowSubmenus: Array<React.Dispatch<React.SetStateAction<boolean>>>
) {
  useEffect(() => {
    if (showSubmenuA) {
      setShowSubmenus.forEach((s) => s(false));
    }
  }, [showSubmenuA, setShowSubmenus]);
}
