import { render } from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store";
import App from "./containers/app";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./store";
import configuration from "./services/configuration";
import { AuthProvider } from "./services/AuthContext";
import { registerObserver } from "react-perf-devtool";
import { signalRRegisterCommands } from "./services/signalr";
import ErrorBoundary from "./containers/app/ErrorBoundary";
import PrivacyPolicyPage from "./containers/app/pages/PrivacyPolicyPage";
import { AnyAction, Store } from "redux";
import { IApplicationState } from "./modules";
import { initializeAppInsights } from "./services/applicationInsights";
import TagManager from "react-gtm-module";

// Added to support Safari v10. Fixes bug 1358.
import "core-js/features/array/flat-map";

// Added to support Safari v10. Fixes bug 1867.
import "core-js/features/object/from-entries";

import "./cerulean-bootstrap.min.css";
import ScrollToTop from "./containers/app/components/ScrollToTop";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { LocalStorageNotEnabledError } from "./containers/app/components/LocalStorageNotEnabledError";
import TermsOfServicePage from "./slices/accountManagement/components/TermsOfServicePage";

if (!configuration.isAutomatedTest) {
  TagManager.initialize({
    gtmId: configuration.googleTagManagerId,
  });
}

let contentToRender: JSX.Element;
let store: Store<IApplicationState, AnyAction> | null = null;

const localStorageAllowed = () => {
  try {
    window.localStorage.getItem("test");
    return true;
  } catch {
    return false;
  }
};

if (!localStorageAllowed()) {
  contentToRender = <LocalStorageNotEnabledError />;
} else if (window.location.pathname.startsWith("/privacypolicy")) {
  contentToRender = <PrivacyPolicyPage />;
} else if (window.location.pathname.startsWith("/termsofservice")) {
  contentToRender = <TermsOfServicePage />;
} else {
  initializeAppInsights();

  if (configuration.perfDevToolsEnabled) {
    registerObserver();
  }

  store = configureStore();

  contentToRender = (
    <ErrorBoundary>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ScrollToTop />
          <AuthProvider>
            <div>
              <App />
            </div>
          </AuthProvider>
        </ConnectedRouter>
      </Provider>
    </ErrorBoundary>
  );
}

render(contentToRender, document.getElementById("root"));
console.log(`Build Version: ${process.env.REACT_APP_BUILD_VERSION}`);

// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});

if (store) {
  signalRRegisterCommands(store);
}
