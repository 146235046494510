import { useDispatch } from "react-redux";
import LinkButton2 from "../../../containers/app/components/LinkButton2";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { actionCreators } from "../../../modules/actionCreators";
import { isJobOutsideCrewHours } from "./JobWorkingHoursAlert.functions";

export function JobWorkingHoursAlert({
  crewId,
  startTime,
  endTime,
}: {
  crewId: string;
  startTime: string;
  endTime: string;
}) {
  const dispatch = useDispatch();
  const crews = useApplicationStateSelector((s) => s.crew.crews);
  const crew = crews.find((c) => c.id === crewId);

  if (!crew) {
    return null;
  }

  const outsideHours = isJobOutsideCrewHours({ startTime, crew, endTime });

  if (!outsideHours) {
    return null;
  }

  return (
    <div className="alert alert-warning p-2" data-testid="hoursAlert">
      This job is being scheduled outside the working hours for {crew.name}. If
      needed, you can modify your crews working hours{" "}
      <LinkButton2
        inlineButton
        buttonContents="here"
        className="text-dark"
        style={{ textDecoration: "underline" }}
        testId="openCrewForm"
        onClick={() => {
          dispatch(
            actionCreators.forms.crew.showForm({
              crewId,
              fieldToFocus: "startTime",
            })
          );
        }}
      />
      .
    </div>
  );
}
