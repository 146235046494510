import { IDistance } from "../models/IDistance";

export function areDistancesEqual(a: IDistance, b: IDistance) {
  return (
    a.sourceLatitude === b.sourceLatitude &&
    a.sourceLongitude === b.sourceLongitude &&
    a.destinationLatitude === b.destinationLatitude &&
    a.destinationLongitude === b.destinationLongitude
  );
}
