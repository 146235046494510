import InfoToolTip from "./InfoToolTip";

export function FlexibleJobToolTip() {
  return (
    <InfoToolTip
      id="flexibleJobExplanation"
      text="Flexible jobs are not assigned to a crew or a specific day. They are added to the unscheduled jobs area at the top of the schedule board. They will remain in the unscheduled area until moved to the calendar."
    />
  );
}
