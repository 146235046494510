import { CardNumberElement } from "@stripe/react-stripe-js";
import {
  PaymentIntentResult,
  PaymentMethodResult,
  Stripe,
  StripeCardNumberElement,
  StripeElements,
} from "@stripe/stripe-js";
import { from } from "rxjs";

export function confirmCardPayment(
  stripe: Stripe | null,
  elements: StripeElements | null,
  clientSecret: string,
  cardHolderName: string
) {
  return from(
    stripe?.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements?.getElement(
          CardNumberElement
        ) as StripeCardNumberElement,
        billing_details: {
          name: cardHolderName,
        },
      },
    }) as Promise<PaymentIntentResult>
  );
}

export function createPaymentMethod(
  stripe: Stripe | null,
  elements: StripeElements | null,
  cardHolderName: string
) {
  return from(
    stripe?.createPaymentMethod({
      type: "card",
      card: elements?.getElement(CardNumberElement) as StripeCardNumberElement,
      billing_details: {
        name: cardHolderName,
      },
    }) as Promise<PaymentMethodResult>
  );
}
