import React, { forwardRef } from "react";
import { TenantIndustry } from "../../../enums/tenantIndustry";

const IndustrySelection = forwardRef(
  (
    props: React.DetailedHTMLProps<
      React.SelectHTMLAttributes<HTMLSelectElement>,
      HTMLSelectElement
    >,
    ref: React.Ref<HTMLSelectElement>
  ) => {
    return (
      <select data-testid="industry" {...props} ref={ref}>
        <option value=""></option>

        <option value={TenantIndustry.ApplianceRepair.toString()}>
          Appliance Repair
        </option>
        <option value={TenantIndustry.AudioVisualInstallationRepair.toString()}>
          Audio &amp; Visual Installation and Repair
        </option>
        <option value={TenantIndustry.BuildingInspection.toString()}>
          Building Inspection
        </option>
        <option value={TenantIndustry.CarpetCleaning.toString()}>
          Carpet Cleaning
        </option>
        <option value={TenantIndustry.ChimneySweepHearth.toString()}>
          Chimney Sweep and Hearth
        </option>
        <option value={TenantIndustry.CleaningMaidServices.toString()}>
          Cleaning / Maid Services
        </option>
        <option
          value={TenantIndustry.CommercialFoodEquipmentService.toString()}
        >
          Commercial Food Equipment Service
        </option>
        <option value={TenantIndustry.DrywallInsulationContractors.toString()}>
          Drywall &amp; Insulation Contractors
        </option>
        <option value={TenantIndustry.DuctCleaning.toString()}>
          Duct Cleaning
        </option>
        <option value={TenantIndustry.Electrical.toString()}>Electrical</option>
        <option value={TenantIndustry.ElectricalSigns.toString()}>
          Electrical Signs
        </option>
        <option value={TenantIndustry.ElevatorEscalator.toString()}>
          Elevator &amp; Escalator
        </option>
        <option value={TenantIndustry.Environmental.toString()}>
          Environmental
        </option>
        <option value={TenantIndustry.EvCharging.toString()}>
          EV Charging
        </option>
        <option value={TenantIndustry.Fencing.toString()}>Fencing</option>
        <option value={TenantIndustry.FireRelatedServices.toString()}>
          Fire Related Services
        </option>
        <option value={TenantIndustry.FlooringTiling.toString()}>
          Flooring / Tiling
        </option>
        <option value={TenantIndustry.FoundationRepair.toString()}>
          Foundation Repair
        </option>
        <option value={TenantIndustry.GarageDoor.toString()}>
          Garage Door
        </option>
        <option value={TenantIndustry.GlassInstallation.toString()}>
          Glass Installation
        </option>
        <option value={TenantIndustry.GutterServices.toString()}>
          Gutter Services
        </option>
        <option value={TenantIndustry.Handyman.toString()}>Handyman</option>
        <option value={TenantIndustry.HVAC.toString()}>HVAC</option>
        <option value={TenantIndustry.Irrigation.toString()}>Irrigation</option>
        <option value={TenantIndustry.JanitorialServices.toString()}>
          Janitorial Services
        </option>
        <option value={TenantIndustry.Landscaping.toString()}>
          Landscaping
        </option>
        <option value={TenantIndustry.LawnCareServices.toString()}>
          Lawn Care Services
        </option>
        <option value={TenantIndustry.LeakDetection.toString()}>
          Leak Detection
        </option>
        <option value={TenantIndustry.Locksmith.toString()}>Locksmith</option>
        <option value={TenantIndustry.Moving.toString()}>Moving</option>
        <option value={TenantIndustry.Other.toString()}>Other</option>
        <option value={TenantIndustry.Painting.toString()}>Painting</option>
        <option value={TenantIndustry.Paving.toString()}>Paving</option>
        <option value={TenantIndustry.PestControl.toString()}>
          Pest Control
        </option>
        <option value={TenantIndustry.Plumbing.toString()}>Plumbing</option>
        <option value={TenantIndustry.PoolSpa.toString()}>Pool/Spa</option>
        <option value={TenantIndustry.PressureWashers.toString()}>
          Pressure Washers
        </option>
        <option value={TenantIndustry.Refrigeration.toString()}>
          Refrigeration
        </option>
        <option value={TenantIndustry.Restoration.toString()}>
          Restoration
        </option>
        <option value={TenantIndustry.Roofing.toString()}>Roofing</option>
        <option value={TenantIndustry.SecuritySystems.toString()}>
          Security Systems
        </option>
        <option value={TenantIndustry.Septic.toString()}>Septic</option>
        <option value={TenantIndustry.Solar.toString()}>Solar</option>
        <option value={TenantIndustry.StormwaterManagement.toString()}>
          Stormwater Management
        </option>
        <option value={TenantIndustry.TreeServices.toString()}>
          Tree Services
        </option>
        <option value={TenantIndustry.WaterTreatment.toString()}>
          Water Treatment
        </option>
        <option value={TenantIndustry.Waterproofing.toString()}>
          Waterproofing
        </option>
        <option value={TenantIndustry.WellDrilling.toString()}>
          Well Drilling
        </option>
        <option value={TenantIndustry.WindowCleaning.toString()}>
          Window Cleaning
        </option>
      </select>
    );
  }
);

export default IndustrySelection;
