import { useRef } from "react";
import WeekPicker from "../components/WeekPicker";
import DayPicker, { format as dayPickerFormat } from "../components/DayPicker";
import dateFnsFormat from "date-fns/format";
import dateService from "../../../services/dateService";
import startOfWeek from "date-fns/start_of_week";
import TimeInput from "../components/TimeInput";
import { JobConflictAlert } from "../../../slices/schedule/components/JobConflictAlert";
import { JobArrivalWindowSelection } from "../../../slices/schedule/components/JobArrivalWindowSelection";
import { JobWorkingHoursAlert } from "../../../slices/schedule/components/JobWorkingHoursAlert";
import { JobArrivalWindowScheduledNotification } from "../../../slices/schedule/components/JobArrivalWindowScheduledNotification";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";

export function OneTimeJobFormDateTimeFields({
  isCrewTimeBased,
  crewId,
  flexibleJob,
  date,
  startTime,
  endTime,
  onDateChange,
  onStartTimeChange,
  onEndTimeChange,
  setErrorMessage,
  startTimeInputId,
  jobId,
  arrivalWindowDurationMinutes,
  onArrivalWindowChange,
  notifyCustomer,
  onNotifyCustomerChange,
  customerId,
}: {
  isCrewTimeBased: boolean;
  crewId: string;
  flexibleJob: boolean;
  date: string;
  startTime: string;
  endTime: string;
  onDateChange(newValue: string): void;
  onStartTimeChange(newValue: string): void;
  onEndTimeChange(newValue: string): void;
  setErrorMessage(newValue: string): void;
  startTimeInputId: string;
  jobId: string | null;
  arrivalWindowDurationMinutes: number | null;
  onArrivalWindowChange(newValue: number | null): void;
  notifyCustomer: boolean;
  onNotifyCustomerChange(newValue: boolean): void;
  customerId?: string | null;
}) {
  const dayPickerLabelElement = useRef<HTMLLabelElement>(null);
  const customerTexting = useApplicationStateSelector(
    (s) => s.common.optionalCapabilitiesAllowed.customerTexting
  );

  if (flexibleJob) {
    return (
      <>
        <div className="form-group">
          <label
            htmlFor="date"
            className="required"
            ref={dayPickerLabelElement}
          >
            Starting week
          </label>
          <div>
            <WeekPicker
              labelRef={dayPickerLabelElement}
              required={true}
              value={!!date ? dateFnsFormat(date, dayPickerFormat) : ""}
              inputId="date"
              testId="startingWeek"
              onWeekSelected={(day: Date) => {
                let selectedWeekStart = "";

                if (!!day) {
                  const parsedDate = dateFnsFormat(day, dayPickerFormat);
                  selectedWeekStart = dateService.formatAsIso(
                    startOfWeek(parsedDate)
                  );
                }

                onDateChange(selectedWeekStart);
              }}
            />
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="form-row">
        <div
          className={`form-group col-12 ${isCrewTimeBased ? "col-md-4" : ""}`}
        >
          <label
            htmlFor="date"
            className="required"
            ref={dayPickerLabelElement}
          >
            Date
          </label>
          <div>
            <DayPicker
              labelRef={dayPickerLabelElement}
              onDayPickerHide={() => null}
              dayPickerProps={{}}
              value={!!date ? dateFnsFormat(date, dayPickerFormat) : ""}
              required={true}
              inputId="date"
              testId="date"
              onDaySelected={(day: Date) => {
                let newValue = "";

                if (!!day) {
                  newValue = dateFnsFormat(day, dayPickerFormat);
                }

                onDateChange(newValue);
                setErrorMessage("");
              }}
            />
          </div>
        </div>

        {isCrewTimeBased ? (
          <>
            <div className="form-group col-12 col-md-4">
              <label htmlFor={startTimeInputId} className="required">
                Start time
              </label>
              <TimeInput
                className="form-control"
                data-testid="startTime"
                value={startTime}
                required
                id={startTimeInputId}
                onChange={(time) => {
                  onStartTimeChange(time);
                }}
              />
            </div>
            <div className="form-group col-12 col-md-4">
              <label htmlFor="endTime" className="required">
                End time
              </label>
              <TimeInput
                className="form-control"
                data-testid="endTime"
                required
                value={endTime}
                id={"endTime"}
                onChange={(time) => {
                  onEndTimeChange(time);
                }}
              />
            </div>
          </>
        ) : null}
      </div>

      {isCrewTimeBased ? (
        <>
          <JobConflictAlert
            crewId={crewId}
            date={date}
            startTime={startTime}
            endTime={endTime}
            jobInstanceId={jobId}
          />
          <JobWorkingHoursAlert
            crewId={crewId}
            startTime={startTime}
            endTime={endTime}
          />
        </>
      ) : null}

      {isCrewTimeBased ? (
        <div className="form-row">
          <div className={"form-group col-12 col-md-4"}>
            <JobArrivalWindowSelection
              isCrewTimeBased={isCrewTimeBased}
              startTime={startTime}
              endTime={endTime}
              arrivalWindowDurationMinutes={arrivalWindowDurationMinutes}
              onArrivalWindowChange={onArrivalWindowChange}
            />
          </div>
          {customerTexting ? (
            <div className="col-12 col-md-8">
              <JobArrivalWindowScheduledNotification
                customerId={customerId}
                hideNotifyCustomer={!!jobId}
                notifyCustomer={notifyCustomer}
                onNotifyCustomerChange={onNotifyCustomerChange}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
