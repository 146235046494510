import "exif-js/exif";
import EXIF from "exif-js";

export class ImgExifService {
  public getOrientedImage(image: any, type: string): Promise<HTMLImageElement> {
    let result: Promise<HTMLImageElement> = new Promise((resolve, reject) => {
      let img: any;

      EXIF.getData(image, () => {
        let orientation = EXIF.getTag(image, "Orientation");

        if (orientation !== 1) {
          const canvas: HTMLCanvasElement = document.createElement("canvas");
          const ctx: CanvasRenderingContext2D = canvas.getContext(
            "2d"
          ) as CanvasRenderingContext2D;
          let cw: number = image.width;
          let ch: number = image.height;
          let cx: number = 0,
            cy: number = 0,
            deg: number = 0;
          switch (orientation) {
            case 3:
            case 4:
              cx = -image.width;
              cy = -image.height;
              deg = 180;
              break;
            case 5:
            case 6:
              cw = image.height;
              ch = image.width;
              cy = -image.height;
              deg = 90;
              break;
            case 7:
            case 8:
              cw = image.height;
              ch = image.width;
              cx = -image.width;
              deg = 270;
              break;
            default:
              break;
          }

          canvas.width = cw;
          canvas.height = ch;
          if ([2, 4, 5, 7].indexOf(orientation) > -1) {
            //flip image
            ctx.translate(cw, 0);
            ctx.scale(-1, 1);
          }
          ctx.rotate((deg * Math.PI) / 180);
          ctx.drawImage(image, cx, cy);
          img = document.createElement("img");
          img.width = cw;
          img.height = ch;
          img.addEventListener("load", function() {
            resolve(img);
          });
          img.src = canvas.toDataURL(type);
        } else {
          resolve(image);
        }
      });
    });
    return result;
  }
}
