import TextareaAutosize from "react-autosize-textarea";
import { isStringSet } from "../../../services/stringService";
import LinkButton2 from "./LinkButton2";

export function JobInstructionsForCrewField({
  value,
  onChange,
  proposalJobSummary,
}: {
  value: string;
  onChange: (newValue: string) => void;
  proposalJobSummary: string | undefined;
}) {
  value = value ?? "";

  return (
    <>
      <div className="form-group">
        <label htmlFor="notes">Instructions for crew</label>
        <TextareaAutosize
          maxRows={10}
          id="notes"
          className="form-control"
          name="notes"
          value={value}
          onChange={(e) => {
            onChange(e.currentTarget.value);
          }}
        />
        {isStringSet(proposalJobSummary) &&
        value.indexOf(proposalJobSummary) === -1 ? (
          <div className="mt-1">
            <LinkButton2
              buttonContents={<small>Copy proposal job summary</small>}
              onClick={() => {
                onChange(`${value.trim()}\n${proposalJobSummary}`.trim());
              }}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}
