import React, { useState } from "react";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { IJobHistoryInstance } from "../../../../models/IJobHistory";
import { getSortedItemsV2 } from "../../../../services/sortingService";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";
import timeService from "../../../../services/timeService";

interface IProps {
  jobHistoryInstance: IJobHistoryInstance;
  idDifferentiator: string;
}

const CustomerDetailsHistoryCrewSize: React.SFC<IProps> = ({
  jobHistoryInstance,
  idDifferentiator,
}) => {
  const crewMembers = useApplicationStateSelector((s) => s.crew.crewMembers);
  const [showCrewMembersPopover, setShowCrewMembersPopover] = useState(false);

  const crewMembersForJobInstance = crewMembers.filter((cm) =>
    jobHistoryInstance.crewMembers.some((icm) => cm.id === icm.id)
  );
  const sortedCrewMembersForJobInstance = getSortedItemsV2(
    crewMembersForJobInstance,
    ["name"]
  );

  const linkId = `crewmembers_${idDifferentiator}_${jobHistoryInstance.id}`;
  return (
    <React.Fragment>
      {timeService.getCrewSize(jobHistoryInstance)}
      {sortedCrewMembersForJobInstance.length > 0 ? (
        <span>
          &nbsp;
          <a
            href="/crewmembers"
            id={linkId}
            onClick={(e) => {
              e.preventDefault();

              setShowCrewMembersPopover(true);
            }}
          >
            (roster)
          </a>
          <Popover
            placement="bottom"
            isOpen={showCrewMembersPopover}
            target={linkId}
            toggle={() => {
              setShowCrewMembersPopover(!showCrewMembersPopover);
            }}
            delay={0}
            fade={false}
            trigger="legacy"
          >
            <PopoverBody>
              Crew members for day:
              <ul style={{ marginBottom: 0 }}>
                {sortedCrewMembersForJobInstance.map((cm) => (
                  <li key={cm.id}>{cm.name}</li>
                ))}
              </ul>
            </PopoverBody>
          </Popover>
        </span>
      ) : null}
    </React.Fragment>
  );
};

export default CustomerDetailsHistoryCrewSize;
