import { useState } from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { IInvoiceProject, IInvoiceJobInstance } from "./InvoiceForm.types";

const emptyProjects: Array<IInvoiceProject> = [];

export function useGetFormParameters() {
  const customerId = useApplicationStateSelector(
    (s) => s.forms.invoice.parameters?.customerId
  ) as string;
  const defaultQuickBooksCustomerId = useApplicationStateSelector(
    (s) => s.forms.invoice.parameters?.defaultQuickBooksCustomerId
  ) as string;
  const contractBillingHistoryItemId = useApplicationStateSelector(
    (s) => s.forms.invoice.parameters?.contractBillingHistoryItemId
  ) as string;
  const jobInstancesFromStore = useApplicationStateSelector(
    (s) => s.forms.invoice.parameters?.jobInstances
  ) as Array<IInvoiceJobInstance> | null;
  const invoiceId = useApplicationStateSelector(
    (s) => s.forms.invoice.parameters?.invoiceId
  ) as string | null;
  const projects = useApplicationStateSelector(
    (s) => s.forms.invoice.parameters?.projects
  );

  const [jobInstances, setJobInstances] = useState(jobInstancesFromStore ?? []);

  return {
    customerId,
    defaultQuickBooksCustomerId,
    jobInstances,
    setJobInstances,
    contractBillingHistoryItemId,
    invoiceId,
    projects: projects ?? emptyProjects,
  };
}
