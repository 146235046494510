import { DiscountType } from "../enums/DiscountType";
import { IDiscount } from "../models/IDiscount";
import { IInvoiceItem } from "../models/IInvoiceItem";
import { IJobLineItem } from "../models/IJobLineItem";
import { round } from "./roundingService";
import { getFloatFromNumberOrString, getNumericString } from "./typeConverter";
import { ILineItem as ILineItemForComponent } from "../containers/app/components/InvoiceLineItem";

export function getSubtotal(lineItems: Array<ILineItem>) {
  if (lineItems.length === 0) {
    return 0;
  }

  return round(
    lineItems.reduce((sum, li) => sum + getLineItemSubtotal(li), 0),
    2
  );
}

export function getTotal({
  taxRate,
  lineItems,
  discount,
  depositCreditAmount,
}: {
  taxRate: number | null;
  lineItems: Array<ILineItem>;
  discount?: IDiscount | null;
  depositCreditAmount?: number | null;
}) {
  const subtotal = getSubtotal(lineItems);
  const discountToApply = getDiscountToApply(subtotal, discount);

  // Note: This calculation is also in the API. If the logic
  // is updated, the API code will also need to be updated.
  const subtotalLessDiscount = subtotal - discountToApply;

  const originalTaxAmount = getLineItemTaxableAmount(taxRate, lineItems);

  let proratedTaxAmount: number;
  if (subtotal !== 0) {
    proratedTaxAmount = originalTaxAmount * (subtotalLessDiscount / subtotal);
  } else {
    proratedTaxAmount = 0;
  }

  return round(
    subtotalLessDiscount + proratedTaxAmount - (depositCreditAmount ?? 0),
    2
  );
}

export function getLineItemName(
  invoiceItems: IInvoiceItem[],
  lineItem: { itemId: string }
) {
  const invoiceItem = invoiceItems.find(
    (invoiceItem) => invoiceItem.id === lineItem.itemId
  );
  if (!invoiceItem) {
    return "";
  }

  return invoiceItem.name;
}

export function getLineItemsForForm(lineItemContainer: {
  lineItems: Array<IJobLineItem>;
}): Array<ILineItemForComponent> {
  return lineItemContainer.lineItems && lineItemContainer.lineItems.length > 0
    ? lineItemContainer.lineItems.map((li) => ({
        ...li,
        name: li.name,
        description: li.description ?? "",
        quantity: getNumericString(li.quantity),
        amountPerItem: getNumericString(li.amountPerItem),
        hide: li.hide,
      }))
    : [];
}

export function getLineItemsForSave(
  lineItems: Array<ILineItemForComponent>
): Array<IJobLineItem> {
  return lineItems && lineItems.length > 0
    ? lineItems.map((li) => ({
        ...li,
        name: li.name,
        description: li.description ?? "",
        quantity: getNumericString(li.quantity),
        amountPerItem: getNumericString(li.amountPerItem),
        taxable: li.taxable ?? false,
        hide: li.hide ?? false,
      }))
    : [];
}

export function isCrewControlInvoiceItemId(id: string) {
  return /^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$/.test(
    id
  );
}

function getDiscountToApply(
  subtotal: number,
  discount: IDiscount | null | undefined
) {
  let discountToApply = 0;
  if (!!discount) {
    if (discount.type === DiscountType.percent) {
      if (discount.percent !== null && !isNaN(discount.percent)) {
        discountToApply = subtotal * discount.percent;
      }
    } else if (discount.type === DiscountType.amount) {
      if (discount.amount !== null && !isNaN(discount.amount)) {
        discountToApply = discount.amount;
      }
    }

    discountToApply = round(discountToApply, 2);
  }

  return discountToApply;
}

function getLineItemTaxableAmount(
  rate: number | null,
  lineItems: Array<ILineItem>
) {
  if (lineItems.length === 0 || rate === null) {
    return 0;
  }

  return lineItems.reduce((sum, li) => {
    let taxableAmount = 0;
    if (li.taxable) {
      taxableAmount = rate * getLineItemSubtotal(li);
    }

    return sum + taxableAmount;
  }, 0);
}

function getLineItemSubtotal(li: ILineItem) {
  return !li.optional || (li.optional && li.selected)
    ? getNumber(li.quantity) * getNumber(li.amountPerItem)
    : 0;
}

interface ILineItem {
  quantity: number | string;
  amountPerItem: number | string;
  taxable?: boolean | null;
  optional?: boolean | null;
  selected?: boolean | null;
}

function getNumber(input: number | string) {
  let result = getFloatFromNumberOrString(input);
  if (isNaN(result)) {
    result = 0;
  }
  return result;
}
