import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Observable } from "rxjs";
import { finalize, timeout } from "rxjs/operators";
import { getErrorMessageFromError } from "../../../../services/httpErrorHandler";
import Prompt from "../../components/Prompt";
import Spinner from "../../components/Spinner";

interface IProps {
  header: string;
  onEdit: () => void;
  onDeleteComplete: () => void;
  deleteFunction: () => Observable<{}>;
  isDataSet: boolean;
  dataType: string;
  fields: React.ReactNode;
  visible: boolean;
}

const CustomerBillingSection: React.FunctionComponent<IProps> = ({
  header,
  onEdit,
  onDeleteComplete,
  deleteFunction,
  isDataSet,
  dataType,
  fields,
  visible,
}) => {
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [showDeleteSpinner, setShowDeleteSpinner] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");

  // Clear delete message when changing tabs
  useEffect(() => {
    setDeleteErrorMessage("");
  }, [visible]);

  return (
    <>
      <div className="d-flex justify-content-between mb-3">
        <h5>{header}</h5>

        <div style={{ whiteSpace: "nowrap" }}>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setDeleteErrorMessage("");
              onEdit();
            }}
          >
            <FontAwesomeIcon
              icon={faEdit}
              title={(isDataSet ? "Edit" : "Add") + " " + dataType}
            />
          </button>
          {isDataSet ? (
            <button
              type="button"
              className="btn btn-secondary ml-3"
              onClick={() => {
                setDeleteErrorMessage("");
                setShowDeletePrompt(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} title={`Remove ${dataType}`} />
            </button>
          ) : null}
        </div>
      </div>

      {fields}

      {deleteErrorMessage ? (
        <div className="mt-2 text-danger">{deleteErrorMessage}</div>
      ) : null}

      {showDeletePrompt ? (
        <Prompt
          showPrompt={true}
          promptMessage={`Are you sure you want to remove the customer's ${dataType}?`}
          onConfirm={() => {
            setShowDeletePrompt(false);
            setShowDeleteSpinner(true);
            setDeleteErrorMessage("");

            deleteFunction()
              .pipe(
                timeout(20000),
                finalize(() => setShowDeleteSpinner(false))
              )
              .subscribe({
                next: () => {
                  onDeleteComplete();
                },

                error: (error) => {
                  setDeleteErrorMessage(getErrorMessageFromError(error));
                },
              });
          }}
          onCancel={() => setShowDeletePrompt(false)}
        />
      ) : null}

      {showDeleteSpinner ? <Spinner /> : null}
    </>
  );
};

export default CustomerBillingSection;
