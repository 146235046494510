import { ITodoItem } from "./ITodoItem";
import { ICustomCrewQuestionResponse } from "./ICustomCrewQuestionResponse";
import { ITodoItemResponse } from "./ITodoItemResponse";
import IPhoto from "./IPhoto";
import { ITimeRange } from "./ITimeRange";
import { ICustomerNotification } from "./ICustomerNotification";

export interface IJobInstance {
  id: string;
  jobId: string | null;
  oneTimeJobId: string | null;
  order: number;
  type: string;
  complete: boolean;
  timeRanges: Array<ITimeRange>;
  actualCrewMembers?: number;
  notesFromPreviousJob: string;
  dateOfPreviousJob: string;
  notesFromCrew: string;
  highlightCrewNotes: boolean;
  showCrewNotesOnAdminJobCards: boolean;
  jobNotes: string;
  todoItems: Array<ITodoItem>;
  todoItemResponses: Array<ITodoItemResponse>;
  useCustomTodoItems: boolean;
  todoItemsLocked: boolean;
  todoTemplateId: string | null;
  customCrewQuestionResponses: Array<ICustomCrewQuestionResponse>;
  photosFromCrew: Array<IPhoto>;
  photos: Array<IPhoto>;
  skipped: boolean;
  invoiceSent: boolean;
  doNotInvoice: boolean;
  invoiceNumber: string | null;
  purchaseOrderNumber: string | null;
  previousJobSkipped: boolean | null;
  previousJobCompleted: boolean | null;
  signatureImagePath: boolean;
  administratorOnlyNotes: string;
  customerNotifications: Array<ICustomerNotification>;
  wasAutoGenerated: boolean;
  projectId: string | null;
  projectJobCount: number | null;
  projectJobOrder: number | null;
  startTime: string | null;
  endTime: string | null;
  arrivalWindowDurationMinutes: number | null;
}

export var maintenanceJobType = "maintenance";
export var oneTimeJobType = "onetime";
