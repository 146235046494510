import endOfWeek from "date-fns/end_of_week";
import eachDay from "date-fns/each_day";
import format from "date-fns/format";
import { getSelectedDateStartOfWeek } from "./scheduleWeekService";

export function getDatesForScheduleWeek(currentParams: { date: string }) {
  const selectedDateStartOfWeek = getSelectedDateStartOfWeek(currentParams);
  const selectedDateEndOfWeek = endOfWeek(selectedDateStartOfWeek);
  return eachDay(selectedDateStartOfWeek, selectedDateEndOfWeek).map((date) =>
    format(date, "YYYY-MM-DD")
  );
}
