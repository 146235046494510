import React, { useState, useEffect } from "react";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { DateFilterOptions } from "../../../enums/dateFilterOptions";
import { IWorkNotInvoicedCustomer } from "../../../models/IWorkNotInvoicedCustomer";
import { timeout } from "rxjs/operators";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import dateFnsParse from "date-fns/parse";
import dateFnsFormat from "date-fns/format";
import { useUserSettings } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import {
  getDefaultFilters,
  updateDatesOnFilter,
} from "../../../services/dateFilterService";
import { BillingWorkNotInvoicedTable } from "./BillingWorkNotInvoicedTable";
import NotesModal, {
  INotesModalJobs,
} from "../../../containers/app/pages/completedWork/NotesModal";
import ConsumerPaymentPageInfo from "../../../containers/app/components/invoices/ConsumerPaymentPageInfo";
import ActionBar, {
  actionBarButtonClassName,
} from "../../../containers/app/components/ActionBar";
import LoadReportMessage from "../../../containers/app/components/LoadReportMessage";
import ServerLoadedContent from "../../../containers/app/components/ServerLoadedContent";
import InvoiceBatchEmailForm from "./InvoiceBatchEmailForm";
import { isSearchMatch } from "../../../services/stringSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { formatCurrency } from "../../../services/currencyFormatter";
import DateFilterHeadless from "../../../containers/app/components/DateFilterHeadless";
import BillingHeaderField from "./BillingHeaderField";
import { getErrorMessagesFromError } from "../../../services/httpErrorHandler";
import constants from "../../../constants";

interface IFilters {
  frequency: DateFilterOptions;
  startingDate: string | null;
  endingDate: string | null;
}

const BillingWorkNotInvoiced: React.FunctionComponent<{}> = () => {
  const { getUserSettings } = useUserSettings();
  const [errorLoading, setErrorLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [loadingData, setLoadingData] = useState(false);
  const [hasInitialLoadCompleted, setHasInitialLoadCompleted] = useState(false);
  const [dataNotLoaded, setDataNotLoaded] = useState(true);
  const [filterError, setFilterError] = useState<string>("");
  const [filters, setFilters] = useState<IFilters>(
    getDefaultFilters(getUserSettings, UserSettingsType.workNotInvoicedFilters)
  );
  const [searchFilter, setSearchFilter] = useState("");
  const [notesModalJobs, setNotesModalJobs] = useState<INotesModalJobs | null>(
    null
  );
  const [reportData, setReportData] =
    useState<Array<IWorkNotInvoicedCustomer> | null>(null);
  const imagePrefix = useApplicationStateSelector((s) => s.common.imagePrefix);
  const invoiceSaveCount = useApplicationStateSelector(
    (s) => s.forms.invoice.saveCount
  );
  const [selectedJobs, setSelectedJobs] = useState<Array<string>>([]);
  const [batchEmailInvoiceFormParameters, setBatchEmailInvoiceFormParameters] =
    useState<{
      jobInstanceIds: Array<string>;
      taxExemptCustomerIncluded: boolean;
    } | null>(null);

  useEffect(() => {
    let subscription = loadData({
      setLoadingData,
      setErrorLoading,
      setDataNotLoaded,
      filters,
      setReportData,
      setSelectedJobs,
      setErrorMessage,
      setHasInitialLoadCompleted,
    });

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [filters, invoiceSaveCount]);

  const { setUserSettings } = useUserSettings();

  let filteredReportData = reportData;
  if (searchFilter.trim() && reportData) {
    filteredReportData = reportData.filter(
      (d) =>
        isSearchMatch(searchFilter, d.name) ||
        d.jobs.some(
          (j) =>
            isSearchMatch(searchFilter, j.proposalNumber ?? "") ||
            j.jobInstances.some((ji) =>
              isSearchMatch(searchFilter, ji.purchaseOrderNumber ?? "")
            )
        ) ||
        d.projects.some(
          (p) =>
            isSearchMatch(searchFilter, p.proposalNumber ?? "") ||
            p.jobInstances.some((ji) =>
              isSearchMatch(searchFilter, ji.purchaseOrderNumber ?? "")
            )
        )
    );
  }

  let totalAmount: number | null = null;
  if (filteredReportData !== null) {
    if (errorLoading) {
      totalAmount = 0;
    } else {
      totalAmount = getTotalAmount(totalAmount, filteredReportData);
    }
  }

  const hasFilterError = filterError !== "";
  return (
    <>
      <ServerLoadedContent
        largeHeader={true}
        header={null}
        dataType="work not invoiced"
        errorLoading={errorLoading}
        errorMessage={errorMessage[0]}
        loadingData={loadingData}
        dataLoadNotStarted={dataNotLoaded}
        hasInitialLoadCompleted={hasInitialLoadCompleted}
        showContentWhileRefreshing={true}
        dataNotLoadedContent={<LoadReportMessage />}
        dataLength={(filteredReportData ?? []).length}
        refreshData={() =>
          loadData({
            setLoadingData,
            setErrorLoading,
            setDataNotLoaded,
            filters,
            setReportData,
            setSelectedJobs,
            setErrorMessage,
            setHasInitialLoadCompleted,
          })
        }
        noRecordsMessage="No work to be invoiced."
        hasFilterError={hasFilterError}
        filter={
          <>
            <div className="d-flex justify-content-between my-1">
              <div>
                {typeof totalAmount === "number" && !hasFilterError ? (
                  <BillingHeaderField
                    label="Total amount"
                    value={formatCurrency(totalAmount)}
                    valueTestId="totalAmountValue"
                  />
                ) : null}
              </div>
              <div>
                <ConsumerPaymentPageInfo />
              </div>
            </div>
            <div
              className="d-flex flex-wrap"
              style={{ columnGap: constants.listFilterGap }}
            >
              <DateFilterHeadless
                filters={filters}
                onFiltersChanged={(newFilters) => {
                  newFilters = updateDatesOnFilter(newFilters);

                  setUserSettings(
                    UserSettingsType.workNotInvoicedFilters,
                    newFilters
                  );

                  setFilters({
                    ...filters,
                    ...newFilters,
                  });
                }}
                showAllDates={false}
                setErrorMessage={(m) => setFilterError(m)}
              >
                {({ dateRangeElements, customDateElements }) => (
                  <React.Fragment>
                    <div
                      style={{ maxWidth: constants.listFilterMaxWidth }}
                      className="flex-fill"
                    >
                      <div className="form-group">{dateRangeElements}</div>
                      {customDateElements !== null ? (
                        <div
                          className="d-flex"
                          style={{ columnGap: constants.listFilterGap }}
                        >
                          <div className="form-group flex-fill">
                            {customDateElements.startingDateElement}
                          </div>
                          <div className="form-group flex-fill">
                            {customDateElements.endingDateElement}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </React.Fragment>
                )}
              </DateFilterHeadless>
              <div
                className="flex-fill"
                style={{ maxWidth: constants.listFilterMaxWidth }}
              >
                <label htmlFor={`billingWorkNotInvoicedCustomerSearchField`}>
                  Search
                </label>
                <div className="input-group">
                  <input
                    value={searchFilter}
                    onChange={(e) => setSearchFilter(e.currentTarget.value)}
                    type="text"
                    className="form-control"
                    id={"billingWorkNotInvoicedCustomerSearchField"}
                    style={{ width: "275px" }}
                    placeholder="Search"
                  />
                  <button
                    className="btn bg-transparent"
                    type="button"
                    style={{ marginLeft: "-40px", zIndex: 3 }}
                    onClick={() => setSearchFilter("")}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              </div>
            </div>
            {filterError && (
              <div>
                <div className="text-danger">{filterError}</div>
              </div>
            )}
          </>
        }
      >
        {filteredReportData ? (
          <BillingWorkNotInvoicedTable
            reportData={filteredReportData}
            setNotesModalJobs={(arg) => setNotesModalJobs(arg)}
            selectedJobs={selectedJobs}
            clearSelectedJobs={() => setSelectedJobs([])}
            onJobSelected={(jobIds, checked) => {
              if (checked) {
                setSelectedJobs([...selectedJobs, ...jobIds]);
              } else {
                setSelectedJobs(
                  selectedJobs.filter((j) => !jobIds.includes(j))
                );
              }
            }}
            reloadData={() => {
              loadData({
                setLoadingData,
                setErrorLoading,
                setDataNotLoaded,
                filters,
                setReportData,
                setSelectedJobs,
                setErrorMessage,
                setHasInitialLoadCompleted,
              });
            }}
          />
        ) : null}
      </ServerLoadedContent>

      {notesModalJobs && (
        <NotesModal
          closeNotes={() => setNotesModalJobs(null)}
          customerForNotes={notesModalJobs}
          imagePrefix={imagePrefix ?? ""}
          hideDatesOnFieldHeaders={false}
          formatDateForReportDisplay={formatDateForReportDisplay}
        />
      )}

      {selectedJobs.length > 0 ? (
        <ActionBar>
          <button
            className={`btn btn-${actionBarButtonClassName}`}
            onClick={() => {
              if (reportData) {
                let jobInstanceIdsForSelectedJobs: Array<string> = [];
                let taxExemptCustomerIncluded = false;
                reportData.forEach((customer) => {
                  customer.jobs.forEach((job) => {
                    if (selectedJobs.includes(job.id)) {
                      taxExemptCustomerIncluded =
                        taxExemptCustomerIncluded || customer.taxExempt;
                      jobInstanceIdsForSelectedJobs = [
                        ...jobInstanceIdsForSelectedJobs,
                        ...job.jobInstances.map((ji) => ji.id),
                      ];
                    }
                  });
                });

                setBatchEmailInvoiceFormParameters({
                  taxExemptCustomerIncluded,
                  jobInstanceIds: jobInstanceIdsForSelectedJobs,
                });
              }
            }}
          >
            Batch email
          </button>
        </ActionBar>
      ) : null}

      {batchEmailInvoiceFormParameters ? (
        <InvoiceBatchEmailForm
          jobInstanceIds={batchEmailInvoiceFormParameters.jobInstanceIds}
          taxExemptCustomerIncluded={
            batchEmailInvoiceFormParameters.taxExemptCustomerIncluded
          }
          onSaveComplete={() => {
            loadData({
              setLoadingData,
              setErrorLoading,
              setDataNotLoaded,
              filters,
              setReportData,
              setSelectedJobs,
              setErrorMessage,
              setHasInitialLoadCompleted,
            });

            setBatchEmailInvoiceFormParameters(null);
            setSelectedJobs([]);
          }}
          onCancel={() => {
            setBatchEmailInvoiceFormParameters(null);
            setSelectedJobs([]);
          }}
        />
      ) : null}
    </>
  );
};

export default BillingWorkNotInvoiced;

function loadData({
  setLoadingData,
  setErrorLoading,
  setDataNotLoaded,
  filters,
  setReportData,
  setSelectedJobs,
  setErrorMessage,
  setHasInitialLoadCompleted,
}: {
  setLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setDataNotLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setHasInitialLoadCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  filters: IFilters;
  setReportData: React.Dispatch<
    React.SetStateAction<Array<IWorkNotInvoicedCustomer> | null>
  >;
  setSelectedJobs: React.Dispatch<React.SetStateAction<Array<string>>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<Array<string>>>;
}) {
  setLoadingData(true);
  setErrorLoading(false);
  setDataNotLoaded(false);
  setSelectedJobs([]);
  setErrorMessage([]);

  return remoteDataProvider
    .getWorkNotInvoicedReport(
      filters.startingDate as string,
      filters.endingDate as string
    )
    .pipe(timeout(30_000))
    .subscribe(
      (result) => {
        setReportData(result);
        setLoadingData(false);
        setHasInitialLoadCompleted(true);
      },
      (err) => {
        setErrorMessage(getErrorMessagesFromError(err));
        setErrorLoading(true);
        setLoadingData(false);
      }
    );
}

function formatDateForReportDisplay(input: Date | string) {
  if (typeof input === "string") {
    input = dateFnsParse(input);
  }
  return dateFnsFormat(input, "M/D");
}

function getTotalAmount(
  totalAmount: number | null,
  reportData: IWorkNotInvoicedCustomer[]
) {
  totalAmount = reportData.reduce(
    (acc, invoice) => acc + getTotalAmountForCustomer(invoice),
    0
  );
  return totalAmount;
}

function getTotalAmountForCustomer(customer: IWorkNotInvoicedCustomer) {
  return (
    customer.jobs.reduce(
      (acc, job) =>
        acc + (job.grossRevenuePerVisit ?? 0) * job.jobInstances.length,
      0
    ) +
    customer.projects.reduce((acc, project) => acc + (project.amount ?? 0), 0) +
    customer.failedInvoices.reduce(
      (acc, failedInvoice) => acc + (failedInvoice.amount ?? 0),
      0
    )
  );
}
