import React from "react";
import { useDispatch } from "react-redux";
import { finalize } from "rxjs/operators";
import conditionalRenderer from "../conditionalRenderer";
import { useMerchantOnboardingProvider } from "../../../../hooks/useMerchantOnboardingProvider";
import MerchantOnboardingFormElements from "./MerchantOnboardingFormElements";
import { commonUiActionCreators } from "../../../../modules/commonUi";
import { getIntroText } from "../../../../services/merchantOnboardingService";

const MerchantOnboardingForm: React.FunctionComponent<{}> = () => {
  const dispatch = useDispatch();

  const {
    originalFormData,
    setOriginalFormData,
    saveErrors,
    setSaveErrors,
    merchantFormSaved,
    saveFormData,
    saving,
    setSaving,
    register,
    control,
    getValues,
    setValue,
    watch,
  } = useMerchantOnboardingProvider();

  return (
    <MerchantOnboardingFormElements
      modalHeader="Sign up for Payments"
      onClose={() =>
        dispatch(commonUiActionCreators.hideMerchantOnboardingModal())
      }
      originalFormData={originalFormData}
      setOriginalFormData={setOriginalFormData}
      saveErrors={saveErrors}
      merchantFormSaved={merchantFormSaved}
      showSaveConfirmation={merchantFormSaved}
      register={register}
      control={control}
      getValues={getValues}
      setValue={setValue}
      watch={watch}
      onSubmit={() => {
        setSaving(true);
        setSaveErrors([]);

        saveFormData()
          .pipe(finalize(() => setSaving(false)))
          .subscribe({
            next: () => {},
          });
      }}
      saving={saving}
      introText={getIntroText(false)}
      submitButtonText="Submit"
    />
  );
};

export default conditionalRenderer(
  MerchantOnboardingForm,
  (s) => s.commonUi.showMerchantOnboardingModal
);
