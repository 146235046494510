import React, { useRef, useEffect } from "react";
import { logError } from "../../../services/errorLogger";

const windowAny: any = window as any;

export interface IProps {
  streetAndNumberId: string;
  streetAndNumberName: string;
  streetAndNumberRef: React.RefObject<HTMLInputElement>;
  addressFieldLabel?: string;
  value: ISimpleAddressComponents;
  disabled?: boolean;
  onChange(
    address: ISimpleAddressComponents,
    raisedByAutoComplete: boolean
  ): void;
  showOnlyAddress?: boolean;
  errorMessage: string | null;
  required?: boolean;
}

export interface ISimpleAddressComponents {
  addressShown: string;
  streetAndNumber: string;
  city: string;
  state: string;
  zip: string;
  latitude: string;
  longitude: string;
  country: string;
}

function raiseOnChange(
  onChange: (
    address: ISimpleAddressComponents,
    raisedByAutoComplete: boolean
  ) => void,
  originalAddressComponents: ISimpleAddressComponents,
  key: keyof ISimpleAddressComponents,
  componentValue: string
) {
  const newValue = { ...originalAddressComponents };
  (newValue[key] as any) = componentValue as any;

  onChange(newValue, false);
}

const AddressSimple: React.FunctionComponent<IProps> = ({
  addressFieldLabel,
  streetAndNumberId,
  streetAndNumberName,
  value,
  disabled,
  onChange,
  streetAndNumberRef,
  errorMessage,
  required,
}) => {
  const autocomplete = useRef<any>(null);
  const autocompleteListener = useRef<any>(null);
  const latestOnChange = useRef(onChange);

  useEffect(() => {
    latestOnChange.current = onChange;
  }, [onChange]);

  useEffect(() => {
    if (streetAndNumberRef.current && !autocomplete.current) {
      autocomplete.current = new windowAny.google.maps.places.Autocomplete(
        streetAndNumberRef.current
      );
      autocomplete.current.setFields([
        "address_components",
        "formatted_address",
        "geometry",
        "place_id",
      ]);

      autocompleteListener.current = autocomplete.current.addListener(
        "place_changed",
        () => {
          let place = autocomplete.current.getPlace();
          if (place) {
            let addressShown: string = place.formatted_address || "";

            if (
              streetAndNumberRef.current &&
              streetAndNumberRef.current.value
            ) {
              addressShown = streetAndNumberRef.current.value;
            }

            if (!addressShown) {
              logError("addressShown was not set");
            }

            const country = getAddressComponentValue(place, "country");
            latestOnChange.current(
              {
                addressShown,
                country,
                latitude: getLatLng(place, "lat"),
                longitude: getLatLng(place, "lng"),
                city: getAddressComponentValue(place, "locality"),
                state: getAddressComponentValue(
                  place,
                  "administrative_area_level_1"
                ),
                zip: getAddressComponentValue(place, "postal_code"),
                streetAndNumber: `${getAddressComponentValue(
                  place,
                  "street_number"
                )} ${getAddressComponentValue(place, "route")}`.trim(),
              },
              true
            );
          }
        }
      );
    }
  });

  return (
    <React.Fragment>
      <div className="form-group">
        <label
          htmlFor={streetAndNumberId}
          className={required ? "required" : ""}
        >
          {addressFieldLabel || "Address"}
        </label>
        <input
          type="text"
          className="form-control"
          id={streetAndNumberId}
          name={streetAndNumberName}
          value={value.addressShown}
          onChange={(e) =>
            raiseOnChange(onChange, value, "addressShown", e.target.value)
          }
          disabled={disabled ?? false}
          required={required ?? false}
          ref={streetAndNumberRef}
        />
        {errorMessage ? (
          <div className="text-danger">{errorMessage}</div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default AddressSimple;

function getAddressComponentValue(place: any, componentType: ComponentType) {
  if (!place) {
    return "";
  }

  const addressComponents =
    place.address_components as Array<IGoogleAddressComponent>;

  if (!addressComponents) {
    return "";
  }

  const matchingItem = addressComponents.find(
    (ac) => !!ac.types.find((t) => t === componentType)
  );

  if (matchingItem) {
    return matchingItem.short_name;
  } else {
    return "";
  }
}

function getLatLng(place: any, fn: "lat" | "lng") {
  if (
    place &&
    place.geometry &&
    place.geometry.location &&
    typeof place.geometry.location[fn] === "function"
  ) {
    const value = place.geometry.location[fn]();
    if (typeof value === "string") {
      return value;
    } else if (!!value) {
      return value.toString();
    }
  }
  return "";
}

interface IGoogleAddressComponent {
  long_name: string;
  short_name: string;
  types: Array<string>;
}

type ComponentType =
  | "street_number"
  | "route"
  | "locality"
  | "administrative_area_level_1"
  | "postal_code"
  | "country";
