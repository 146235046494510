import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { builders } from "../../../services/routing";
import { PageWithBranding } from "../PageWithBranding";
import { fullStoryTrack } from "../../../services/fullStoryService";
import { setSessionProperties } from "./LandingPage.functions";

interface IProps {}

const LandingPage: React.FunctionComponent<IProps> = () => {
  useEffect(() => {
    setSessionProperties();
  }, []);

  return (
    <PageWithBranding
      showHeaderScreenshot
      showTransformationText
      showFreeAccessText
    >
      <div
        className="d-flex flex-wrap justify-content-around"
        style={{ columnGap: "15px", rowGap: "30px" }}
      >
        <div>
          <div className="text-center">
            <h5>Existing Company</h5>
            <Link
              role="button"
              to={builders.userAccount.buildLogin()}
              className="btn btn-primary btn-lg mt-3 branded-public-buttons"
              style={{ fontSize: "2rem" }}
            >
              Log In
            </Link>
          </div>
        </div>

        <div>
          <div className="text-center">
            <h5>New Company</h5>
            <Link
              role="button"
              to={builders.userAccount.buildSignup()}
              className="btn btn-primary btn-lg mt-3 branded-public-buttons"
              style={{ fontSize: "2rem" }}
              onClick={() => fullStoryTrack("Start Trial Clicked")}
            >
              Start Trial
            </Link>
          </div>
        </div>
      </div>
    </PageWithBranding>
  );
};

export default LandingPage as React.FunctionComponent<IProps>;
