import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalFooter from "reactstrap/lib/ModalFooter";
import { useEffect, useRef, useState } from "react";
import SpinnerModalFooter from "./SpinnerModalFooter";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { timeout } from "rxjs/operators";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import constants from "../../../constants";
import ReactDOM from "react-dom";
import PhoneNumberField from "./PhoneNumberField";
import SmsOptInCheckbox from "./SmsOptInCheckbox";

interface IProps {
  onClose(): void;
  templateId: string;
  templateVariables: {
    [key: string]: string;
  };
}

const CustomerNotificationsMessageTest: React.FunctionComponent<IProps> = ({
  templateId,
  templateVariables,
  onClose,
}) => {
  const [testPhoneNumber, setTestPhoneNumber] = useState("");
  const [phoneNumberOptedIntoSms, setPhoneNumberOptedIntoSms] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const formName = "messageTestForm";
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    });
  }, []);

  // Use createPortal so the form isn't nested in the parent form
  return ReactDOM.createPortal(
    <Modal isOpen={true} scrollable={true}>
      <ModalHeader toggle={() => onClose()}>Send test message</ModalHeader>
      <ModalBody>
        <form
          id={formName}
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setSaving(true);
            remoteDataProvider
              .sendCustomerNotificationsTestMessage({
                templateId,
                templateVariables,
                phoneNumber: testPhoneNumber,
                phoneNumberOptedIntoSms,
              })
              .pipe(timeout(10000))
              .subscribe({
                next: () => {
                  onClose();
                },

                error: (err) => {
                  setSaving(false);

                  setErrorMessage(
                    getErrorMessageFromError(err, constants.unknownErrorMessage)
                  );
                },
              });
          }}
        >
          <div className="form-group">
            <label htmlFor="numberToSendTo">
              What number should we send it to?
            </label>
            <PhoneNumberField
              id="numberToSendTo"
              value={testPhoneNumber}
              className="form-control"
              onChange={(e) => setTestPhoneNumber(e.currentTarget.value)}
              required={true}
              ref={inputRef}
            />
          </div>
          <SmsOptInCheckbox
            checked={phoneNumberOptedIntoSms}
            onCheckedChange={(newValue) => setPhoneNumberOptedIntoSms(newValue)}
            phoneNumber={testPhoneNumber}
            alwaysVisible
            required
          />
          {errorMessage ? (
            <div className="text-danger">{errorMessage}</div>
          ) : null}
        </form>
      </ModalBody>
      <ModalFooter>
        <div
          className="d-flex justify-content-between"
          style={{ width: "100%" }}
        >
          <div>{saving ? <SpinnerModalFooter /> : null}</div>
          <div>
            <button className="btn btn-primary" type="submit" form={formName}>
              Send
            </button>
            <button
              className="btn btn-secondary ml-2"
              onClick={() => onClose()}
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalFooter>
    </Modal>,
    document.body
  );
};

export default CustomerNotificationsMessageTest;
