import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import isValid from "date-fns/is_valid";
import { logError } from "./errorLogger";
import {
  addDays,
  getDaysInMonth,
  setMinutes,
  setHours,
  getHours,
  getMinutes,
} from "date-fns";
import { DateFilterOptions } from "../enums/dateFilterOptions";
import { IZonedDateTime } from "../models/IZonedDateTime";
import addMonths from "date-fns/add_months";

function getCurrentDate() {
  return new Date();
}

function removeTimeComponents(input: Date) {
  const stringWithoutTime = dateFnsFormat(input, "YYYY-MM-DD");
  return dateFnsParse(stringWithoutTime);
}

function formatAsIso(input: Date | string) {
  let parsedInput: Date = getParsedDate(input);

  const formattedValue = dateFnsFormat(parsedInput, "YYYY-MM-DD");

  if (formattedValue === "Invalid Date") {
    const errorMessage = `dateService.formatAsIso - Error parsing value '${parsedInput}'`;
    logError(errorMessage);
    console.log(errorMessage);
  }

  return formattedValue;
}

function getParsedDate(input: string | Date) {
  let parsedInput: Date;
  if (typeof input === "string") {
    parsedInput = dateFnsParse(input);
  } else {
    parsedInput = input;
  }
  return parsedInput;
}

function formatAsIsoWithTimeAndOffset(input: Date | string) {
  const isoFormat = "YYYY-MM-DD[T]HH:mm:ssZZ";

  return formatAsIsoWithTimeBase(input, isoFormat);
}

function formatAsIsoWithTimeAndNoOffset(input: Date | string) {
  const isoFormat = "YYYY-MM-DD[T]HH:mm:ss";

  return formatAsIsoWithTimeBase(input, isoFormat);
}

function formatAsIsoWithTimeBase(input: string | Date, isoFormat: string) {
  let parsedInput: Date;
  if (typeof input === "string") {
    parsedInput = dateFnsParse(input);
  } else {
    parsedInput = input;
  }

  const formattedValue = dateFnsFormat(parsedInput, isoFormat);

  if (formattedValue === "Invalid Date") {
    const errorMessage = `dateService.formatAsIso - Error parsing value '${parsedInput}'`;
    logError(errorMessage);
    console.log(errorMessage);
  }

  return formattedValue;
}

function areDatesEqual(a: Date | string, b: Date | string) {
  if (a === undefined || b === undefined) {
    return false;
  }

  const parsedA = dateFnsParse(a);
  const parsedB = dateFnsParse(b);

  if (!isValid(parsedA) || !isValid(parsedB)) {
    return false;
  }

  return formatAsIso(parsedA) === formatAsIso(parsedB);
}

function getMaximumDateForSchedule() {
  return addMonths(getCurrentDate(), 14);
}

// TODO: Better solution to handle dates.  Maybe need to consider using dates for calculations to handle daylight savings time.
const formatTimeForSerialization = (input: string | Date) =>
  input
    ? dateFnsFormat(
        typeof input === "string" ? dateFnsParse(`2000-01-01 ${input}`) : input,
        "HH:mm:ss"
      )
    : null;

const constructDateFromDateAndTime = (
  dayScheduleDate: Date,
  time: string | null
): Date => {
  const dateWithTime = dateFnsParse(`2000-01-01 ${time}`);
  return setMinutes(
    setHours(dayScheduleDate, getHours(dateWithTime)),
    getMinutes(dateWithTime)
  );
};

const timeFormatForDisplay = "h:mm A";
const formatTimeForDisplay = (input: string) =>
  dateFnsFormat(dateFnsParse(`2000-01-01 ${input}`), timeFormatForDisplay);

const dateFormatForDisplay = "M/D/YYYY";
const formatDateForDisplay = (input: string | Date, format?: string) =>
  dateFnsFormat(dateFnsParse(input), format ?? dateFormatForDisplay);

const formatDateTimeForTimeDisplay = (input: string) =>
  dateFnsFormat(dateFnsParse(new Date(input)), timeFormatForDisplay);

const formatDateTimeForDateTimeDisplay = (input: string) => {
  const parsedDate = dateFnsParse(new Date(input));
  return dateFnsFormat(
    parsedDate,
    `${dateFormatForDisplay} ${timeFormatForDisplay}`
  );
};

const formatDateTimeForTimeInput = (input: string) =>
  dateFnsFormat(dateFnsParse(new Date(input)), "HH:mm");

const isInvalidIsoDate = (iso: string) => iso === "Invalid Date";

const getDatesFromDateFilterOptions = (frequency: DateFilterOptions) => {
  let numberOfDays: number;
  switch (frequency) {
    case DateFilterOptions.last90Days:
      numberOfDays = 90;
      break;
    case DateFilterOptions.last60Days:
      numberOfDays = 60;
      break;
    default:
      numberOfDays = 30;
      break;
  }

  const endingDate = formatAsIso(new Date());
  const startingDate = formatAsIso(addDays(new Date(), numberOfDays * -1));

  return { endingDate, startingDate };
};

function getZonedDateTimeForDisplay(acceptanceDateTime: IZonedDateTime) {
  const parsedDateTime = dateFnsParse(acceptanceDateTime.value);
  if (!isValid(parsedDateTime)) {
    return "";
  }

  const timeZoneForDisplay = getTimeZoneForDisplay(acceptanceDateTime.timeZone);

  let timeZoneString = "";
  if (timeZoneForDisplay !== null) {
    timeZoneString = ` ${timeZoneForDisplay}`;
  }

  return `${formatDateForDisplay(parsedDateTime)} (${dateFnsFormat(
    parsedDateTime,
    timeFormatForDisplay
  )}${timeZoneString})`;
}

function getTimeZoneForDisplay(timeZone: string) {
  switch (timeZone) {
    case "America/New_York":
      return "ET";
    case "America/Chicago":
      return "CT";
    case "America/Denver":
      return "MT";
    case "America/Los_Angeles":
      return "PT";
    case "Pacific/Honolulu":
      return "HAT";
    case "America/Anchorage":
      return "AKT";
    case "America/Phoenix":
      return "Arizona";
    default:
      logError(`invalid timeZone: ${timeZone}`);
      return null;
  }
}

const getMonthDates = (startDate: Date) => {
  const numberOfDays = getDaysInMonth(startDate);
  const days: Array<Date> = [];
  for (let i = 0; i < numberOfDays; i++) {
    days.push(addDays(startDate, i));
  }
  return days;
};

export default {
  getCurrentDate,
  removeTimeComponents,
  formatAsIso,
  formatAsIsoWithTimeAndOffset,
  formatAsIsoWithTimeAndNoOffset,
  areDatesEqual,
  formatTimeForSerialization,
  formatTimeForDisplay,
  formatDateTimeForTimeDisplay,
  formatDateTimeForTimeInput,
  formatDateTimeForDateTimeDisplay,
  formatDateForDisplay,
  isInvalidIsoDate,
  getMaximumDateForSchedule,
  getDatesFromDateFilterOptions,
  getMonthDates,
  constructDateFromDateAndTime,
  getParsedDate,
  getZonedDateTimeForDisplay,
};
