import { Droppable } from "react-beautiful-dnd";
import constants from "../../../constants";
import { IScheduleColumnHeader } from "../../../containers/app/components/schedule";
import ScheduleRow from "../../../containers/app/components/schedule/ScheduleRow";
import HeaderContainer from "../../../containers/app/components/schedule/day/HeaderContainer";
import { IScheduleRow } from "./types/IScheduleRow";

export function ScheduleSectionList({
  rows,
  columnHeaders,
  customContainerClassName,
  onRowExpanded,
  renderRowlessSchedule,
  renderRow,
  isCalendarHeader,
}: {
  rows: Array<IScheduleRow>;
  columnHeaders: Array<IScheduleColumnHeader>;
  customContainerClassName: string | undefined;
  onRowExpanded(rowIndex: number): void;
  renderRow(row: IScheduleRow, rowIndex: number): JSX.Element;
  renderRowlessSchedule(): JSX.Element;
  isCalendarHeader: boolean;
}) {
  return (
    <>
      {rows.length > 1 ? (
        <div
          style={{
            display: "inline-flex",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <div
            className={
              "schedule-week-container " + (customContainerClassName || "")
            }
            style={{
              marginBottom: "0",
              position: "sticky",
              top: 0,
              zIndex: 9,
            }}
          >
            {columnHeaders.map((ch, scheduleDayIndex) => (
              <div
                className="schedule-week-day"
                data-scheduledayindex={scheduleDayIndex}
                key={ch.key}
                style={{ marginTop: "0px" }}
              >
                <HeaderContainer
                  columnHeader={ch.columnHeader}
                  highlightHeader={ch.highlightHeader}
                  isCalendarHeader={isCalendarHeader}
                  isLastColumn={scheduleDayIndex === columnHeaders.length - 1}
                />
              </div>
            ))}
          </div>

          <Droppable droppableId={"ROW"} type={constants.droppableTypeRow}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {rows.map((r, rowIndex) => (
                  <ScheduleRow
                    key={r.id}
                    name={r.name}
                    id={r.id}
                    index={rowIndex}
                    expanded={r.expanded}
                    onRowExpanded={() => onRowExpanded(rowIndex)}
                  >
                    {renderRow(r, rowIndex)}
                  </ScheduleRow>
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      ) : (
        <>{renderRowlessSchedule()}</>
      )}
    </>
  );
}
