import { Action } from "@reduxjs/toolkit";
import { catchError, mergeMap } from "rxjs/operators";
import opportunityDataProvider from "./opportunityDataProvider";
import { opportunitiesActionCreators } from "../modules/opportunity";
import { concat, forkJoin, Observable, of, throwError } from "rxjs";
import { IApplicationState } from "../../../modules";
import { loadOpportunityRelatedData } from "./loadOpportunityRelatedData";
import { actionCreators } from "../../../modules/actionCreators";

export function loadOpportunitiesBoard(
  state: IApplicationState
): Observable<Action<any>> {
  return forkJoin({
    opportunities: opportunityDataProvider.getOpportunities({}),
    board: opportunityDataProvider.getOpportunityBoard(),
  }).pipe(
    mergeMap(({ opportunities, board }) => {
      const opportunityRelatedDataLoads = loadOpportunityRelatedData(
        state,
        opportunities
      );

      return concat(
        opportunityRelatedDataLoads,
        of(
          opportunitiesActionCreators.loadOpportunitiesCompleted({
            opportunities,
            board,
          })
        )
      );
    }),
    catchError((err) => {
      const forbiddenStatusCode = 403;
      if (err.status === forbiddenStatusCode) {
        return of(actionCreators.null());
      } else {
        return throwError(err);
      }
    })
  );
}
