import { useState, useRef } from "react";
import { PayrixBankAccountType } from "../../../enums/payrixBankAccountType";

type Fields = {
  bankAccountType: string;
  bankRoutingNumber: string;
  bankAccountNumber: string;
  bankAccountNumberConfirmed: string;
};

interface IProps {
  value: Fields;
  onChange(newValue: Fields): void;
}

const BankAccountInline: React.FunctionComponent<IProps> = ({
  value,
  onChange,
}) => {
  const [inRoutingNumber, setInRoutingNumber] = useState(false);
  const [inAccountNumber, setInAccountNumber] = useState(false);

  const bankAccountNumberConfirmedRef = useRef<HTMLInputElement | null>(null);

  const fieldClasses = "form-control";

  return (
    <>
      <div className="form-group">
        <label htmlFor="bankAccountType" className="required">
          Bank account type
        </label>
        <select
          className={fieldClasses}
          id="bankAccountType"
          required
          value={value.bankAccountType ?? ""}
          onChange={(e) => {
            onChange({
              ...value,
              bankAccountType: e.currentTarget.value,
            });
          }}
        >
          <option value="">Select bank account type</option>
          <option value={PayrixBankAccountType.CorporateChecking.toString()}>
            Business checking
          </option>
          <option value={PayrixBankAccountType.PersonalChecking.toString()}>
            Personal checking
          </option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="bankRoutingNumber" className="required">
          Bank routing #
        </label>
        <input
          minLength={9}
          maxLength={9}
          type="text"
          className={`${fieldClasses} fs-mask`}
          id="bankRoutingNumber"
          inputMode="numeric"
          required
          placeholder="123456789"
          onFocus={() => setInRoutingNumber(true)}
          onBlur={() => setInRoutingNumber(false)}
          value={value.bankRoutingNumber ?? ""}
          onChange={(e) => {
            onChange({
              ...value,
              bankRoutingNumber: e.currentTarget.value,
            });
          }}
        />
      </div>

      <div className="form-row">
        <div className="col-xs-12 col-sm-6 form-group">
          <label htmlFor="bankAccountNumber" className="required">
            Bank account #
          </label>
          <input
            minLength={5}
            maxLength={17}
            type="password"
            className={`${fieldClasses} fs-mask`}
            id="bankAccountNumber"
            required
            inputMode="numeric"
            placeholder="00123456789"
            onFocus={() => setInAccountNumber(true)}
            onBlur={() => setInAccountNumber(false)}
            value={value.bankAccountNumber ?? ""}
            onChange={(e) => {
              onChange({
                ...value,
                bankAccountNumber: e.currentTarget.value,
              });
            }}
          />
        </div>
        <div className="col-xs-12 col-sm-6 form-group">
          <label htmlFor="bankAccountNumberConfirmed" className="required">
            Confirm bank account #
          </label>
          <input
            minLength={5}
            maxLength={17}
            type="password"
            className={`${fieldClasses} fs-mask`}
            id="bankAccountNumberConfirmed"
            required
            inputMode="numeric"
            placeholder="00123456789"
            onFocus={() => setInAccountNumber(true)}
            onBlur={() => setInAccountNumber(false)}
            ref={bankAccountNumberConfirmedRef}
            value={value.bankAccountNumberConfirmed ?? ""}
            onChange={(e) => {
              const bankAccountNumber = value.bankAccountNumber ?? "";
              const bankAccountNumberConfirmed = e.currentTarget.value ?? "";

              const showPasswordMismatchError =
                bankAccountNumber.trim() !== "" &&
                bankAccountNumberConfirmed.trim() !== "" &&
                bankAccountNumber !== bankAccountNumberConfirmed;

              bankAccountNumberConfirmedRef.current?.setCustomValidity(
                showPasswordMismatchError
                  ? "Bank account numbers do not match"
                  : ""
              );

              onChange({
                ...value,
                bankAccountNumberConfirmed: e.currentTarget.value,
              });
            }}
          />
        </div>
      </div>

      <div
        className="border p-2 font-weight-light bg-light"
        style={{
          display: "grid",
          gridTemplateColumns: "min-content min-content min-content",
          columnGap: "10px",
        }}
      >
        <div className={inRoutingNumber ? "font-weight-bold" : ""}>
          123456789
        </div>
        <div>|:</div>
        <div className={inAccountNumber ? "font-weight-bold" : ""}>
          00123456789
        </div>
        <div className={inRoutingNumber ? "font-weight-bold" : ""}>Routing</div>
        <div></div>
        <div className={inAccountNumber ? "font-weight-bold" : ""}>Account</div>
      </div>
    </>
  );
};

export default BankAccountInline;

export function parseBankAccountType(
  input: string
): PayrixBankAccountType | null {
  if (!input) {
    return null;
  }

  return parseInt(input);
}
