import React from "react";

interface IMonth {
  value: string;
  name: string;
  numberOfDays: number;
}

interface IValue {
  month: string;
  dayOfMonth: string;
}

interface IProps {
  label: string;
  value: IValue | null;
  onChange(newValue: IValue): void;
}

const getMonth = (months: Array<IMonth>, monthValue: string) => {
  const month = months.find((m) => m.value === monthValue);
  if (!month) {
    throw new Error(`month not found - ${monthValue}`);
  }
  return month;
};

const SeasonalDate: React.SFC<IProps> = ({ label, value, onChange }) => {
  const months: Array<IMonth> = [
    { value: "1", name: "January", numberOfDays: 31 },
    { value: "2", name: "February", numberOfDays: 29 },
    { value: "3", name: "March", numberOfDays: 31 },
    { value: "4", name: "April", numberOfDays: 30 },
    { value: "5", name: "May", numberOfDays: 31 },
    { value: "6", name: "June", numberOfDays: 30 },
    { value: "7", name: "July", numberOfDays: 31 },
    { value: "8", name: "August", numberOfDays: 31 },
    { value: "9", name: "September", numberOfDays: 30 },
    { value: "10", name: "October", numberOfDays: 31 },
    { value: "11", name: "November", numberOfDays: 30 },
    { value: "12", name: "December", numberOfDays: 31 },
  ];

  // TODO: Handle day of month outside bounds when month changes
  let numberOfDays = 31;

  if (value && value.month) {
    const currentMonth = getMonth(months, value.month);
    if (currentMonth) {
      numberOfDays = currentMonth.numberOfDays;
    }
  }

  const days = [];
  for (let i = 1; i <= numberOfDays; i++) {
    days.push(i.toString());
  }

  return (
    <div>
      <label className="required">{label}</label>
      <div style={{ display: "flex" }}>
        <select
          aria-label={`${label} month`}
          className="form-control"
          style={{ marginRight: "25px" }}
          value={value && value.month ? value.month : "1"}
          onChange={(e) => {
            const selectedMonth = getMonth(months, e.target.value);

            let dayOfMonth = value && value.dayOfMonth ? value.dayOfMonth : "1";
            const parsedCurrentDate = parseInt(dayOfMonth, 10);
            if (parsedCurrentDate > selectedMonth.numberOfDays) {
              dayOfMonth = selectedMonth.numberOfDays.toString();
            }

            onChange({ month: e.target.value, dayOfMonth });
          }}
        >
          {months.map((m) => (
            <option value={m.value} key={m.value}>
              {m.name}
            </option>
          ))}
        </select>
        <select
          aria-label={`${label} day of month`}
          className="form-control"
          style={{ width: "100px" }}
          value={value && value.dayOfMonth ? value.dayOfMonth : "1"}
          onChange={(e) =>
            onChange({
              month: value && value.month ? value.month : "1",
              dayOfMonth: e.target.value,
            })
          }
        >
          {days.map((d) => (
            <option value={d} key={d}>
              {d}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SeasonalDate;
