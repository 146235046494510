import React, { CSSProperties, useState } from "react";
import PageWithNavBar2 from "../PageWithNavBar2";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import LabelledField from "./LabelledField";
import { push } from "connected-react-router";
import { finalize } from "rxjs/operators";
import Spinner from "./Spinner";
import Prompt from "./Prompt";
import { IAction } from "../../../modules/actionTypeDefinitions";
import { Observable } from "rxjs";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faExclamationCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import constants from "../../../constants";

interface IProps {
  backLinkUrl: string;
  backLinkText: string;
  recordName: string;
  recordTypeForActions: string;
  labelFieldRows: Array<ILableFieldRow>;
  getEditAction: () => IAction;
  deleteFunction: () => Observable<any>;
  deleteRedirectRoute: string;
  getDeleteCompleteAction: () => IAction;
  errorMessage: string;
  setErrorMessage: (error: string) => void;
  clearErrorMessage: () => void;
  warningMessage?: string | null;
  fullWidth?: boolean;
}

interface ILableFieldRow {
  key: string;
  fields: Array<ILabelField>;
}

interface ILabelField {
  label: string;
  value: React.ReactNode;
  hidden?: boolean;
  style?: Partial<CSSProperties>;
  testId?: string;
}

const RecordDetails: React.FunctionComponent<IProps> = ({
  backLinkUrl,
  backLinkText,
  recordName,
  recordTypeForActions,
  children,
  labelFieldRows,
  getEditAction,
  getDeleteCompleteAction,
  deleteFunction,
  deleteRedirectRoute,
  errorMessage,
  setErrorMessage,
  clearErrorMessage,
  warningMessage,
  fullWidth,
}) => {
  const dispatch = useDispatch();

  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [deleting, setDeleting] = useState(false);

  return (
    <React.Fragment>
      <PageWithNavBar2
        billingContext={true}
        notFluid={fullWidth ? false : true}
      >
        <div
          style={{
            marginBottom: constants.marginBottomForIntercom,
          }}
        >
          {deleting && <Spinner />}
          {!!warningMessage ? (
            <div data-testid="warning-banner" className="alert alert-warning">
              <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
              {warningMessage}
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <div>
              <h4>
                <Link to={backLinkUrl} style={{ textDecoration: "underline" }}>
                  {backLinkText}
                </Link>
                <span style={{ marginLeft: "10px", marginRight: "10px" }}>
                  &gt;
                </span>
                {recordName}
              </h4>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => dispatch(getEditAction())}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  size="2x"
                  title={`Edit ${recordTypeForActions}`}
                />
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                style={{ marginLeft: "20px" }}
                onClick={() => {
                  setShowDeletePrompt(true);
                }}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  size="2x"
                  title={`Delete ${recordTypeForActions}`}
                />
              </button>
            </div>
          </div>
          {!!errorMessage ? (
            <div
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              {errorMessage}
              <button
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
                onClick={() => clearErrorMessage()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          ) : null}

          {labelFieldRows.map((r) => (
            <div
              key={r.key}
              className="mb-3"
              style={{
                display: "flex",
                flexWrap: "wrap",
                rowGap: "15px",
                columnGap: "50px",
              }}
            >
              {r.fields
                .filter((f) => !f.hidden)
                .map((f) => (
                  <LabelledField
                    key={f.label}
                    label={f.label}
                    value={f.value}
                    style={{ marginRight: undefined, ...(f.style ?? {}) }}
                    testId={f.testId}
                  />
                ))}
            </div>
          ))}

          <div style={{ marginTop: "40px" }}>{children}</div>
          <Prompt
            showPrompt={showDeletePrompt}
            promptMessage={`Are you sure you want to delete this ${recordTypeForActions}?`}
            onConfirm={() => {
              setErrorMessage("");
              setDeleting(true);
              setShowDeletePrompt(false);

              deleteFunction()
                .pipe(finalize(() => setDeleting(false)))
                .subscribe(
                  () => {
                    dispatch(push(deleteRedirectRoute));
                    dispatch(getDeleteCompleteAction());
                  },
                  (err: any) => {
                    setErrorMessage(
                      getErrorMessageFromError(
                        err,
                        `An unknown error occurred while deleting the ${recordTypeForActions}.`
                      )
                    );
                  }
                );
            }}
            onCancel={() => setShowDeletePrompt(false)}
          />
        </div>
      </PageWithNavBar2>
    </React.Fragment>
  );
};

export default RecordDetails;
