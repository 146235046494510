import { useState } from "react";
import subscriptionDataProvider from "../services/subscriptionDataProvider";
import { PortalPage } from "../enums/portalPage";
import { timeout } from "rxjs/operators";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import Spinner from "../../../containers/app/components/Spinner";
import { LockedOutPage } from "./LockedOutPage";
import useIsAdmin from "../../../hooks/useIsAdmin";

export function LockedOutForFailedPaymentPage() {
  const isAdmin = useIsAdmin();

  const [creatingPortalSession, setCreatingPortalSession] = useState(false);
  const [errorCreatingPortalSession, setErrorCreatingPortalSession] = useState<
    string | null
  >(null);

  let correctPaymentButton: React.ReactNode | null = null;
  if (isAdmin) {
    correctPaymentButton = (
      <div>
        {creatingPortalSession ? <Spinner /> : null}
        <button
          className="text-nowrap btn btn-success border border-dark mb-2"
          data-testid="updatePaymentMethodButton"
          onClick={() => {
            setCreatingPortalSession(true);
            setErrorCreatingPortalSession(null);

            subscriptionDataProvider
              .createSubscriptionPortalSession({
                page: PortalPage.UpdatePaymentMethod,
              })
              .pipe(timeout(15000))
              .subscribe({
                next: (portalUrl) => {
                  window.location.href = portalUrl;
                },

                error: (err) => {
                  setCreatingPortalSession(false);
                  setErrorCreatingPortalSession(
                    getErrorMessageFromError(err, "An unknown error occurred.")
                  );
                },
              });
          }}
        >
          <b>Correct your payment</b>
        </button>
      </div>
    );
  }

  return (
    <LockedOutPage
      header="There's a problem with your payment!"
      button={correctPaymentButton}
      errorMessage={errorCreatingPortalSession}
    />
  );
}
