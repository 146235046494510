import React from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import CategorySelection from "./CategorySelection";
import { ICategoryIdentifierForSave } from "../../../models/ICategoryIdentifierForSave";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import InfoToolTip from "./InfoToolTip";

interface IProps {
  value: Array<ICategoryIdentifierForSave>;
  onChange: (newValue: Array<ICategoryIdentifierForSave>) => void;
  hideToolTip?: boolean;
}

const CrewCategorySelection: React.FunctionComponent<IProps> = (props) => {
  const dispatch = useDispatch();
  const categories = useApplicationStateSelector(
    (s) => s.common.crewCategories
  );
  const filteredCategories = categories.filter((c) => !c.inactive);

  return (
    <div className={"form-group"}>
      <label htmlFor="categories">Service categories</label>
      {!props.hideToolTip ? (
        <InfoToolTip
          id="categoriesToolTip"
          text="These categories show for both administrators and crew members. They help you organize your work and allow you to collect custom information for different types of services."
        />
      ) : null}
      <CategorySelection
        {...props}
        placeholderText="Select services provided"
        noOptionsMessage="Start typing to add a new service category"
        categories={filteredCategories}
      />
      {props.value.length > 0 ? (
        <div>
          <button
            type="button"
            className="btn btn-sm btn-link pt-0 pb-0"
            data-testid="editServiceCategories"
            onClick={() =>
              dispatch(actionCreators.forms.crewCategories.showForm({}))
            }
          >
            Edit service categories
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default CrewCategorySelection;
