import CustomerNotificationsTemplatedMessageDefaultsForm from "../../../containers/app/components/CustomerNotificationsTemplatedMessageDefaultsForm";
import { useState } from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { CustomerNotificationTemplateType } from "../../../enums/customerNotificationTemplateType";
import { useGetCustomerFromStore } from "../../../hooks/useGetCustomerFromStore";
import LinkButton2 from "../../../containers/app/components/LinkButton2";
import { actionCreators } from "../../../modules/actionCreators";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { TenantSubscriptionStatus } from "../../../models/IInitialLoad";

export function JobArrivalWindowScheduledNotification({
  customerId,
  hideNotifyCustomer,
  notifyCustomer,
  onNotifyCustomerChange,
}: {
  customerId: string | null | undefined;
  hideNotifyCustomer: boolean | undefined;
  notifyCustomer: boolean | undefined;
  onNotifyCustomerChange: ((newValue: boolean) => void) | undefined;
}) {
  const dispatch = useDispatch();
  const subscriptionStatus = useApplicationStateSelector(
    (s) => s.common.tenantSubscriptionStatus
  );
  const customer = useGetCustomerFromStore(customerId ?? null);

  const [showTemplateDefaultsModal, setShowTemplateDefaultsModal] =
    useState(false);

  const jobScheduledTemplateId = "928f3e1e-01f9-4033-92e4-0ba4fde901d0";

  const customerNotificationTemplateOptions = useApplicationStateSelector(
    (s) => s.common.customerNotificationTemplateOptions
  );

  const notifyCustomerTemplate = customerNotificationTemplateOptions.filter(
    (o) =>
      o.type === CustomerNotificationTemplateType.oneOff &&
      o.id === jobScheduledTemplateId
  )?.[0];

  if (
    hideNotifyCustomer ||
    subscriptionStatus !== TenantSubscriptionStatus.Subscribed
  ) {
    return null;
  }

  return (
    <>
      <label className="d-none d-md-block" htmlFor="notifyCustomer">
        &nbsp;
      </label>
      <div className="custom-control custom-checkbox">
        <input
          id="notifyCustomer"
          data-testid="notifyCustomer"
          type="checkbox"
          className="custom-control-input"
          checked={notifyCustomer ?? false}
          onChange={(e) => {
            if (typeof onNotifyCustomerChange === "function") {
              onNotifyCustomerChange(e.currentTarget.checked);
            }
          }}
        />
        <label className="custom-control-label" htmlFor="notifyCustomer">
          Notify customer their job has been scheduled
        </label>
        <div>
          <button
            type="button"
            data-testid="configureMessage"
            className="btn btn-sm btn-link pt-0 pl-0"
            onClick={() => {
              setShowTemplateDefaultsModal(true);
            }}
          >
            Configure message
          </button>
        </div>
      </div>

      {customer &&
      (!customer?.phoneNumberOptedIntoSms || !customer?.phoneNumber) &&
      notifyCustomer ? (
        <div
          className="col-12 alert-warning mb-2"
          style={{
            border: "1px",
            padding: "0rem .25rem",
            borderRadius: ".25rem",
          }}
        >
          <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />
          <small>
            Customer does not have an authorized phone number.{" "}
            <LinkButton2
              className="text-dark"
              buttonContents={<small>Fix it!</small>}
              inlineButton
              style={{
                verticalAlign: "baseline",
              }}
              onClick={() => {
                dispatch(
                  actionCreators.forms.customer.showForm({
                    customerId: customerId,
                  })
                );
              }}
            ></LinkButton2>
          </small>
        </div>
      ) : null}

      {showTemplateDefaultsModal && notifyCustomerTemplate ? (
        <CustomerNotificationsTemplatedMessageDefaultsForm
          selectedTemplate={notifyCustomerTemplate}
          onCancel={() => setShowTemplateDefaultsModal(false)}
          onSaveComplete={() => {
            setShowTemplateDefaultsModal(false);
          }}
        />
      ) : null}
    </>
  );
}
