import { VariableKey } from "../../../models/IITemplatedMessage";

// Do not change these values! They need to stay in sync with the server.
export enum ContactMethodType {
  email = 0,
  phone = 1,
}

export const CompanyNamePlaceholder = "<Company name>";
export const DayScheduledPlaceholder = "<Day scheduled>";
export const ArrivalTimePlaceholder = "<Arrival time>";
export const ComputedDayScheduledPlaceholder = "<Service day>";
export const ShowDaySelection = "<Show day selection>";

export const ContactMethodTypeSelection: VariableKey = "<Contact method type>";
export const EmailAddressPlaceholder: VariableKey = "<Email address>";
export const PhoneNumberPlaceholder: VariableKey = "<Phone number>";
export const ContactMethodPlaceholder: VariableKey = "<Contact method>";
