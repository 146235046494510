import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { ICustomer } from "../../../models/ICustomer";
import { UserSettingsType } from "../../../enums/userSettingsType";
import { useUserSettings } from "../../../services/userSettingsService";

export default function useGetDefaultTaxRate(customer: ICustomer | null) {
  const invoiceConfigurationTaxRate = useApplicationStateSelector(
    (s) => s.common.invoiceConfiguration.taxRate
  );

  const { getUserSettings } = useUserSettings();

  if (customer?.taxExempt) {
    return null;
  }

  return (
    invoiceConfigurationTaxRate ??
    getUserSettings<number | null>(UserSettingsType.taxRate) ??
    null
  );
}
