import { useState } from "react";
import { timeout } from "rxjs/operators";
import remoteDataProvider from "../../../services/remoteDataProvider";
import Prompt from "./Prompt";
import Spinner from "./Spinner";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";

export function CrewMemberSendAppInstructions({
  crewMemberId,
  crewMemberName,
  onClose,
  cancelButton,
}: {
  crewMemberId: string;
  crewMemberName: string;
  onClose: () => void;
  cancelButton: string;
}) {
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  return (
    <>
      {saving ? (
        <Spinner />
      ) : (
        <Prompt
          promptMessage={`Would you like to send ${crewMemberName} instructions to install the mobile application?`}
          promptSubMessageClassName={errorMessage ? "text-danger" : ""}
          promptSubMessage={errorMessage}
          promptSaveText="Send instructions"
          promptCancelText={cancelButton}
          showPrompt={true}
          onConfirm={() => {
            setErrorMessage(null);
            setSaving(true);
            remoteDataProvider
              .sendAppInstructionsToCrewMember({ crewMemberId })
              .pipe(timeout(30000))
              .subscribe({
                next: () => {
                  setSaving(false);
                  onClose();
                },
                error(err) {
                  setSaving(false);
                  setErrorMessage(
                    getErrorMessageFromError(
                      err,
                      "An error occurred while sending the instructions. Would you like to try again?"
                    )
                  );
                },
              });
          }}
          onCancel={() => {
            onClose();
          }}
        />
      )}
    </>
  );
}
