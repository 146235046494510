import React, { useRef, useEffect, useState } from "react";
import AddressComponents2Map from "./AddressComponents2Map";
import { logError } from "../../../services/errorLogger";
import {
  getSignificantDigits,
  hasGoogleMapsAutocompleteLoaded,
  getAddressPlace,
} from "../../../services/googleService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { useGetAddressFields } from "../../../hooks/useGetAddressFields";

const windowAny: any = window as any;

export interface IProps {
  streetAndNumberId: string;
  streetAndNumberName: string;
  streetAndNumberRef: React.RefObject<HTMLInputElement>;
  addressFieldLabel?: string;
  value: IAddressComponents;
  disabled?: boolean;
  onChange(address: IAddressComponents): void;
  showMap?: boolean;
  hideCoordinates?: boolean;
  required?: boolean;
  showCountry?: boolean;
}

export interface IAddressComponents {
  streetAndNumber: string;
  city: string;
  state: string;
  zip: string;
  latitude: string;
  longitude: string;
  latitudeSignificantDigits: number | null;
  longitudeSignificantDigits: number | null;
  placeId?: string | null;
  country?: string;
}

function raiseOnChange(
  onChange: (address: IAddressComponents) => void,
  originalAddressComponents: IAddressComponents,
  key: keyof IAddressComponents,
  componentValue: string
) {
  const newValue = { ...originalAddressComponents };
  (newValue[key] as any) = componentValue as any;

  if (key === "latitude") {
    newValue["latitudeSignificantDigits"] =
      getSignificantDigits(componentValue);
    newValue["placeId"] = null;
  } else if (key === "longitude") {
    newValue["longitudeSignificantDigits"] =
      getSignificantDigits(componentValue);
    newValue["placeId"] = null;
  }

  onChange(newValue);
}

const AddressComponents2: React.FunctionComponent<IProps> = ({
  addressFieldLabel,
  streetAndNumberId,
  streetAndNumberName,
  value,
  disabled,
  onChange,
  streetAndNumberRef,
  showMap,
  hideCoordinates,
  required,
  showCountry,
}) => {
  const autocomplete = useRef<any>(null);
  const autocompleteListener = useRef<any>(null);
  const [addressWarning, setAddressWarning] = useState("");
  const [displayMap, setDisplayMap] = useState(false);

  const addressFields = useGetAddressFields();

  useEffect(() => {
    if (streetAndNumberRef.current && !autocomplete.current) {
      if (hasGoogleMapsAutocompleteLoaded()) {
        autocomplete.current = new windowAny.google.maps.places.Autocomplete(
          streetAndNumberRef.current
        );
        autocomplete.current.setFields([
          "address_components",
          "formatted_address",
          "geometry",
          "place_id",
        ]);

        autocompleteListener.current = autocomplete.current.addListener(
          "place_changed",
          () => {
            getAddressPlace(
              streetAndNumberRef.current?.value,
              autocomplete.current.getPlace()
            ).subscribe(
              (place) => {
                if (place.showWarning) {
                  if (showMap) {
                    setAddressWarning(
                      "Please ensure address is correct and manually select the correct location on the map below."
                    );
                    setDisplayMap(true);
                  } else {
                    setAddressWarning("Please ensure address is correct.");
                  }
                } else {
                  setAddressWarning("");
                }
                onChange({
                  latitude: place.latitude,
                  latitudeSignificantDigits: null,
                  longitude: place.longitude,
                  longitudeSignificantDigits: null,
                  city: place.locality,
                  state: place.administrativeArea,
                  zip: place.postalCode,
                  streetAndNumber:
                    `${place.streetNumber} ${place.route}`.trim(),
                  placeId: place.placeId ?? null,
                  country: place.country,
                });
              },
              (error) => {
                logError(
                  `AddressComponents2 - error loading google place: ${error}`
                );
              }
            );
          }
        );
      } else {
        logError("AddressComponents2 - google maps not loaded");
      }
    }
  });

  return (
    <React.Fragment>
      <div className="form-group">
        <label
          htmlFor={streetAndNumberId}
          className={required ? "required" : ""}
        >
          {addressFieldLabel || "Address"}
        </label>
        <input
          type="text"
          className="form-control"
          id={streetAndNumberId}
          name={streetAndNumberName}
          value={value.streetAndNumber}
          onChange={(e) =>
            raiseOnChange(onChange, value, "streetAndNumber", e.target.value)
          }
          disabled={disabled || false}
          ref={streetAndNumberRef}
          maxLength={400}
          required={required}
          data-testid="streetAndNumber"
        />
        {addressWarning ? (
          <div
            className="alert-warning mt-1"
            style={{
              border: "1px",
              padding: "0rem .25rem",
              borderRadius: ".25rem",
            }}
          >
            <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />
            <small>{addressWarning}</small>
          </div>
        ) : null}
        {!hasGoogleMapsAutocompleteLoaded() ? (
          <div className="text-danger">
            Unfortunately, we were unable to load what we needed to allow
            address autocomplete. Please refresh your browser to try again.
          </div>
        ) : null}
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="inputCity" className={required ? "required" : ""}>
            {addressFields.localityLabel}
          </label>
          <input
            type="text"
            className="form-control"
            id="inputCity"
            value={value.city}
            onChange={(e) =>
              raiseOnChange(onChange, value, "city", e.target.value)
            }
            disabled={disabled || false}
            maxLength={100}
            required={required}
            data-testid="city"
          />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputState" className={required ? "required" : ""}>
            {addressFields.administrativeAreaLabel}
          </label>
          <select
            id="inputState"
            className="form-control"
            value={value.state}
            onChange={(e) =>
              raiseOnChange(onChange, value, "state", e.target.value)
            }
            disabled={disabled || false}
            required={required}
            data-testid="state"
          >
            <option value="" />
            {addressFields.administrativeAreas.map((state) => (
              <option key={state.abbreviation} value={state.abbreviation}>
                {state.abbreviation}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputZip">{addressFields.postCodeLabel}</label>
          <input
            type="text"
            className="form-control"
            id="inputZip"
            value={value.zip}
            onChange={(e) =>
              raiseOnChange(onChange, value, "zip", e.target.value)
            }
            disabled={disabled || false}
            maxLength={20}
            required={required}
            data-testid="zip"
          />
        </div>
      </div>

      {showCountry ? (
        <div className="form-group">
          <label htmlFor="inputCountry" className={required ? "required" : ""}>
            Country
          </label>
          <select
            id="inputCountry"
            className="form-control"
            value={value.country}
            onChange={(e) =>
              raiseOnChange(onChange, value, "country", e.target.value)
            }
            disabled={disabled || false}
            required={required}
            data-testid="country"
          >
            <option value="" />
            <option value="US">United States</option>
            <option value="CA">Canada</option>
          </select>
        </div>
      ) : null}

      {!hideCoordinates ? (
        <>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="inputLatitude">Latitude</label>
              <input
                type="text"
                className="form-control"
                id="inputLatitude"
                value={value.latitude}
                onChange={(e) => {
                  raiseOnChange(onChange, value, "latitude", e.target.value);
                }}
                disabled={disabled || false}
              />
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="inputLongitude">Longitude</label>
              <input
                type="text"
                className="form-control"
                id="inputLongitude"
                value={value.longitude}
                onChange={(e) => {
                  raiseOnChange(onChange, value, "longitude", e.target.value);
                }}
                disabled={disabled || false}
              />
            </div>
          </div>
        </>
      ) : null}

      {showMap ? (
        <div className="form-group">
          <AddressComponents2Map
            latitude={value.latitude}
            longitude={value.longitude}
            onCoordinatesChange={(latitude, longitude) => {
              onChange({
                ...value,
                latitude,
                longitude,
                latitudeSignificantDigits: null,
                longitudeSignificantDigits: null,
                placeId: null,
              });
            }}
            displayMap={displayMap}
            setDisplayMap={setDisplayMap}
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default AddressComponents2;
