import React from "react";
import { SortDirection } from "../enums/sortDirection";
import { useState } from "react";
import SortColumn from "../containers/app/components/SortColumn";

export function useSortColumn<TSortColumns>(
  sortColumn?: TSortColumns,
  sortDirection?: SortDirection
) {
  const [
    currentSortColumn,
    setCurrentSortColumn,
  ] = useState<TSortColumns | null>(sortColumn ?? null);
  const [
    currentSortDirection,
    setCurrentSortDirection,
  ] = useState<SortDirection>(sortDirection ?? SortDirection.Ascending);

  const getSortColumn = (displayName: string, sortColumn: TSortColumns) => (
    <SortColumn<TSortColumns>
      displayName={displayName}
      columnSortProperty={sortColumn}
      currentSortProperty={currentSortColumn}
      currentSortDirection={currentSortDirection}
      onSortDirectionChange={(newSortColumn, newSortDirection) => {
        setCurrentSortColumn(newSortColumn);
        setCurrentSortDirection(newSortDirection);
      }}
    />
  );

  return { currentSortColumn, currentSortDirection, getSortColumn };
}
