import { CSSProperties } from "react";

interface IProps {
  style?: Partial<CSSProperties>;
  selected?: boolean;
  additionalClasses?: string;
}

const OpportunityCardContainer: React.FunctionComponent<IProps> = ({
  style,
  children,
  selected,
  additionalClasses,
}) => {
  return (
    <div
      data-testid="opportunityCardContainer"
      className={
        `card board-card border ` +
        (selected ? " bg-secondary " : "") +
        (additionalClasses ?? "")
      }
      style={{ ...(style ?? {}) }}
    >
      <div className="card-body board-card-body board-card-v2">
        <div className="card-text">{children}</div>
      </div>
    </div>
  );
};

export default OpportunityCardContainer;
