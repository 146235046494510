import { useState } from "react";
import invoiceDataProvider from "../services/invoiceDataProvider";
import Spinner from "../../../containers/app/components/Spinner";
import Prompt from "../../../containers/app/components/Prompt";
import { timeout } from "rxjs/operators";
import { getErrorMessagesFromError } from "../../../services/httpErrorHandler";
import { IInvoicePaymentView } from "../../../models/IInvoicePayment";
import { formatCurrency } from "../../../services/currencyFormatter";
import constants from "../../../constants";

export function BillingInvoiceListRefundPayment({
  showPrompt,
  setShowPrompt,
  invoiceId,
  payment,
  onDataChanged,
  setErrorMessage,
}: {
  showPrompt: boolean;
  setShowPrompt: (v: boolean) => void;
  invoiceId: string;
  payment: IInvoicePaymentView;
  onDataChanged: () => void;
  setErrorMessage: (error: string) => void;
}) {
  const [saving, setSaving] = useState(false);

  return (
    <>
      {saving ? <Spinner /> : null}

      <Prompt
        promptMessage={`Are you sure you want to refund the ${formatCurrency(
          payment.amount
        )} payment?`}
        showPrompt={showPrompt && !saving}
        onCancel={() => setShowPrompt(false)}
        onConfirm={() => {
          setSaving(true);
          setShowPrompt(false);

          invoiceDataProvider
            .refundInvoicePayment({
              invoiceId: invoiceId,
              paymentId: payment.id,
            })
            .pipe(timeout(10000))
            .subscribe({
              next: () => {
                setSaving(false);
                onDataChanged();
              },

              error: (err) => {
                setSaving(false);
                setErrorMessage(
                  getErrorMessagesFromError(
                    err,
                    constants.unknownErrorMessage
                  )[0]
                );
              },
            });
        }}
      />
    </>
  );
}
