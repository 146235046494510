import { InputHTMLAttributes, useEffect, useRef } from "react";
import { removeFormattingForCurrencyFields } from "./CurrencyInputV2.functions";

interface IProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "ref"> {
  onValueChange(value: string): void;
}

const validationRegex = /^\d*\.?\d{0,2}$/;
const CurrencyInputV2: React.FunctionComponent<IProps> = ({
  onValueChange,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let isValid = true;
    if (typeof props.value === "string") {
      const parsedValue = removeFormattingForCurrencyFields(props.value);

      isValid = validationRegex.test(parsedValue);
    }

    if (ref.current) {
      ref.current.setCustomValidity(
        isValid ? "" : "Please enter a valid dollar amount"
      );
    }
  }, [props.value]);

  return (
    <input
      {...props}
      ref={ref}
      inputMode="decimal"
      type="number"
      step=".01"
      onChange={(e) => {
        onValueChange(e.currentTarget.value);
      }}
    />
  );
};

export default CurrencyInputV2;
