import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { actionCreators } from "../../../../modules/actionCreators";
import { IAction } from "../../../../modules/actionTypeDefinitions";
import { JobType } from "../../../../models/IJob";
import { ICrew } from "../../../../models/ICrew";
import {
  getDaysOfWeekForDisplay,
  isFlexibleJob,
} from "../../../../services/jobService";
import JobHelper from "../../../../services/jobHelper";
import dateService from "../../../../services/dateService";
import { MaintenanceJobFrequency } from "../../../../models/IMaintenanceJob";
import { promptActionCreators } from "../../../../modules/prompt";
import ProjectForm from "../../../../slices/schedule/components/ProjectForm";
import {
  getCrewsForProject,
  getDateRangeForProject,
  getRecentVisitLink,
} from "./CustomerDetailsJobs.functions";
import { CustomerDetailsJobsProjectDelete } from "./CustomerDetailsJobsProjectDelete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useCreditCardsEnabled } from "../../../../hooks/useCreditCardsEnabled";
import { useGetCustomerFromStore } from "../../../../hooks/useGetCustomerFromStore";
import { isPaymentMethodOnFileAuthorizedVisible } from "../../../../services/paymentMethodOnFileService";
import useIsAdmin from "../../../../hooks/useIsAdmin";
import CustomerAdditionalLocationSelection from "../../components/CustomerAdditionalLocationSelection";
import { isStringSet } from "../../../../services/stringService";
import useSalesEnabled from "../../../../slices/sales/hooks/useSalesEnabled";
import { builders as routerBuilders } from "../../../../services/routing";
import { Link } from "react-router-dom";
import {
  GetUserSettingsType,
  useUserSettings,
} from "../../../../services/userSettingsService";
import ServerLoadedList from "../../../../libraries/tableLayout/ServerLoadedList";
import jobDataProvider, {
  GetCustomerJobsJob,
  GetCustomerJobsRequest,
} from "../../../../slices/schedule/services/jobDataProvider";
import { catchError, map, switchMap, tap, timeout } from "rxjs/operators";
import { getErrorMessageFromError } from "../../../../services/httpErrorHandler";
import CustomersChildEntityListingAddButtons from "./CustomersChildEntityListingAddButtons";
import { CustomersChildEntityListRowButtons } from "./CustomersChildEntityListRowButtons";
import { SortDirection } from "../../../../enums/sortDirection";
import { UserSettingsType } from "../../../../enums/userSettingsType";
import SortColumn from "../../components/SortColumn";
import { BehaviorSubject, Subject, of } from "rxjs";
import constants from "../../../../constants";
import { useIsResolution } from "../../../../hooks/useIsResolution";

interface IProps {
  customerId: string;
  defaultSearchText?: string | null;
}

interface IJobFilters {
  locationId: string | null;
  searchText: string | null;
}

enum JobSortColumns {
  crew = 0,
  frequency = 1,
  dayOfWeek = 2,
  jobLocation = 3,
  date = 4,
  serviceType = 5,
  paymentAuthorized = 6,
  proposalNumber = 7,
}

const defaultPageSize = 50;
const CustomerDetailsJobs: React.FunctionComponent<IProps> = ({
  customerId,
  defaultSearchText,
}) => {
  const dispatch = useDispatch();

  const [showMultiJobForm, setShowMultiJobForm] = useState(false);
  const [projectToEdit, setProjectToEdit] = useState<string | null>(null);
  const [projectToDelete, setProjectToDelete] = useState<string | null>(null);

  const [jobFilters, setJobFilters] = useState<IJobFilters>({
    locationId: null,
    searchText: defaultSearchText ?? null,
  });

  const { getUserSettings, setUserSettings } = useUserSettings();

  const [currentSortColumn, setCurrentSortColumn] = useState<JobSortColumns>(
    getDefaultSortColumn<JobSortColumns>(
      UserSettingsType.customerJobsSortColumn,
      getUserSettings
    )?.column ?? JobSortColumns.crew
  );

  const [currentSortDirection, setCurrentSortDirection] =
    useState<SortDirection>(
      getDefaultSortColumn<JobSortColumns>(
        UserSettingsType.customerJobsSortColumn,
        getUserSettings
      )?.direction ?? SortDirection.Ascending
    );

  const { areCreditCardsEnabled } = useCreditCardsEnabled();
  const salesEnabled = useSalesEnabled();
  const customer = useGetCustomerFromStore(customerId);
  const isAdmin = useIsAdmin();

  const customerUrlRoot = useApplicationStateSelector(
    (s) => s.common.customerUrlRoot
  );
  useApplicationStateSelector((s) => s.forms.maintenanceJob.saveCount);
  const crews = useApplicationStateSelector((s) => s.crew.crews);

  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );

  const breakpoint = "lg";
  const isDesktopView = useIsResolution(breakpoint);

  let sortColumn = currentSortColumn;
  let sortDirection = currentSortDirection;
  if (!isDesktopView) {
    sortColumn = JobSortColumns.frequency;
    sortDirection = SortDirection.Descending;
  }

  const {
    jobs,
    currentPage,
    setCurrentPage,
    initialDataLoadComplete,
    setInitialDataLoadComplete,
    loadingData,
    errorMessage,
    hasMoreResults,
    setLoadingData,
    setJobs,
    loadRequest,
  } = useLoadJobs({
    customerId,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    jobFilters,
  });

  const restartPaging = () => {
    setCurrentPage(0);
    setJobs([]);
    setInitialDataLoadComplete(false);
  };

  const handleSortChange = (
    newSortColumn: JobSortColumns,
    newSortDirection: SortDirection
  ) => {
    setCurrentSortColumn(newSortColumn);
    setCurrentSortDirection(newSortDirection);
    setUserSettings(UserSettingsType.customerJobsSortColumn, {
      column: newSortColumn,
      direction: newSortDirection,
    } as ISortColumn<JobSortColumns>);

    restartPaging();
    loadRequest.current.next({
      customerId,
      proposalNumber: jobFilters.searchText,
      locationId: jobFilters.locationId,
      page: 0,
      pageSize: defaultPageSize,
      sortColumn: newSortColumn,
      sortDirection: newSortDirection,
    });
  };

  const handleJobFilterChange = (newFilterValue: IJobFilters) => {
    setJobFilters(newFilterValue);

    restartPaging();
    loadRequest.current.next({
      customerId,
      proposalNumber: newFilterValue.searchText,
      locationId: newFilterValue.locationId,
      page: 0,
      pageSize: defaultPageSize,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
    });
  };

  useLoadJobsAfterReduxFormSave({
    jobFilters,
    customerId,
    sortColumn,
    sortDirection,
    currentPage,
    setLoadingData,
    setJobs,
  });

  const paymentAuthorizationFieldsVisible =
    isPaymentMethodOnFileAuthorizedVisible(areCreditCardsEnabled, customer) &&
    isAdmin;

  return (
    <>
      <ServerLoadedList<GetCustomerJobsJob>
        tableTestId="jobs-table"
        header={null}
        breakpoint={breakpoint}
        dataType="jobs"
        errorLoading={errorMessage !== null}
        errorMessage={errorMessage}
        loadingData={loadingData}
        showContentWhileRefreshing={initialDataLoadComplete}
        data={jobs}
        refreshData={() => {
          loadRequest.current.next({
            customerId,
            proposalNumber: jobFilters.searchText,
            locationId: jobFilters.locationId,
            page: currentPage,
            pageSize: defaultPageSize,
            sortColumn: sortColumn,
            sortDirection: sortDirection,
          });
        }}
        filter={
          <div
            className="d-flex justify-content-between align-items-baseline flex-wrap"
            style={{ columnGap: constants.listFilterGap }}
          >
            <div
              className="d-flex flex-wrap align-items-baseline"
              style={{ columnGap: constants.listFilterGap }}
            >
              {salesEnabled ? (
                <div className="form-group flex-fill">
                  <div
                    style={{
                      display: "inline-block",
                      width: "100%",
                      maxWidth: constants.listFilterMaxWidth,
                    }}
                  >
                    <label htmlFor="proposalNumberFilter">Estimate #</label>
                    <div className="input-group">
                      <input
                        id="proposalNumberFilter"
                        data-testid="proposalNumberFilter"
                        className="form-control"
                        type="text"
                        aria-label="Estimate #"
                        placeholder="Search"
                        value={jobFilters.searchText ?? ""}
                        onChange={(e) => {
                          const value = e.currentTarget.value;
                          handleJobFilterChange({
                            ...jobFilters,
                            searchText: value,
                          });
                        }}
                      />
                      <button
                        className="btn bg-transparent"
                        type="button"
                        style={{ marginLeft: "-40px", zIndex: 3 }}
                        onClick={() =>
                          handleJobFilterChange({
                            ...jobFilters,
                            searchText: "",
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}
              {customerAdditionalLocations.some(
                (l) => l.customerId === customerId
              ) ? (
                <div className="form-group flex-fill">
                  <div
                    style={{
                      display: "inline-block",
                      width: "100%",
                      maxWidth: constants.listFilterMaxWidth,
                      minWidth: "345px",
                    }}
                  >
                    <label htmlFor="jobLocationFilter">Job location</label>
                    <CustomerAdditionalLocationSelection
                      inputId="jobLocationFilter"
                      placeholder="Select..."
                      disableAddEdit={true}
                      includeCustomerAddress={true}
                      value={jobFilters.locationId}
                      customerId={customerId}
                      onCustomerAdditionalLocationSelection={(e) => {
                        handleJobFilterChange({
                          ...jobFilters,
                          locationId: e,
                        });
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div>
              <CustomersChildEntityListingAddButtons
                addButtons={[
                  {
                    label: "Add single job",
                    mobileLabel: "Single job",
                    onClick: () =>
                      dispatch(
                        actionCreators.forms.oneTimeJob.showForm({
                          defaultFormData: {
                            customerId: customerId,
                          },
                        })
                      ),
                  },
                  {
                    label: "Add multiple jobs",
                    mobileLabel: "Multiple jobs",
                    onClick: () => setShowMultiJobForm(true),
                  },
                  {
                    label: "Add recurring job",
                    mobileLabel: "Recurring job",
                    onClick: () =>
                      dispatch(
                        actionCreators.forms.maintenanceJob.showForm({
                          customerId: customerId,
                        })
                      ),
                  },
                ]}
              />
            </div>
          </div>
        }
        columns={[
          {
            header: ({ displayType }) =>
              displayType === "desktop" ? (
                <SortColumn
                  displayName={"Crew(s)"}
                  columnSortProperty={JobSortColumns.crew}
                  currentSortProperty={currentSortColumn}
                  currentSortDirection={currentSortDirection}
                  onSortDirectionChange={(newSortColumn, newSortDirection) => {
                    handleSortChange(newSortColumn, newSortDirection);
                  }}
                  testId={"jobCrewHeader"}
                />
              ) : (
                "Crew(s)"
              ),
            cell: ({ row: j }) => {
              if (j.jobType === JobType.maintenanceJob) {
                return getMaintenanceJobCrewName(
                  {
                    crewId: j.crewId ?? "",
                    daysOfWeek: j.daysOfWeek ?? [],
                  },
                  crews
                );
              } else if (j.jobType === JobType.oneTimeJob) {
                return getOneTimeJobCrewName(
                  {
                    crewId: j.crewId ?? "",
                    flexibleJob: j.flexibleJob,
                  },
                  crews
                );
              } else if (j.jobType === JobType.project && j.projectVisits) {
                return getCrewsForProject({
                  crews,
                  projectVisits: j.projectVisits,
                });
              }
              return "";
            },
            testId: "jobCrewName",
            key: "crew",
          },
          {
            header: ({ displayType }) =>
              displayType === "desktop" ? (
                <SortColumn
                  displayName={"Date(s)"}
                  columnSortProperty={JobSortColumns.date}
                  currentSortProperty={currentSortColumn}
                  currentSortDirection={currentSortDirection}
                  onSortDirectionChange={(newSortColumn, newSortDirection) => {
                    handleSortChange(newSortColumn, newSortDirection);
                  }}
                  testId={"dateHeader"}
                />
              ) : (
                "Date(s)"
              ),
            cell: ({ row: j }) => {
              if (j.jobType === JobType.maintenanceJob) {
                return <>Recurring</>;
              } else if (
                j.jobType === JobType.oneTimeJob &&
                typeof j.date === "string" &&
                typeof j.crewId === "string"
              ) {
                return (
                  <Link
                    to={routerBuilders.schedule.buildWeekRoute(
                      getUserSettings,
                      crews,
                      j.date,
                      j.crewId
                    )}
                  >
                    {dateService.formatDateForDisplay(j.date)}
                  </Link>
                );
              } else if (j.jobType === JobType.project && j.projectVisits) {
                return (
                  <Link
                    to={getRecentVisitLink({
                      getUserSettings,
                      crews,
                      projectVisits: j.projectVisits,
                    })}
                  >
                    {
                      getDateRangeForProject({
                        projectVisits: j.projectVisits,
                      }).valueForDisplay
                    }
                  </Link>
                );
              }

              return "";
            },
            testId: "jobDate",
            key: "jobDate",
          },
          {
            header: ({ displayType }) =>
              displayType === "desktop" ? (
                <SortColumn
                  displayName={"Frequency"}
                  columnSortProperty={JobSortColumns.frequency}
                  currentSortProperty={currentSortColumn}
                  currentSortDirection={currentSortDirection}
                  onSortDirectionChange={(newSortColumn, newSortDirection) => {
                    handleSortChange(newSortColumn, newSortDirection);
                  }}
                  testId={"frequencyHeader"}
                />
              ) : (
                "Frequency"
              ),
            cell: ({ row: j }) => {
              if (j.jobType === JobType.maintenanceJob) {
                return JobHelper.getFrequencyForDisplay(j.frequency);
              } else if (j.jobType === JobType.project) {
                return "Project";
              }

              return "N/A";
            },
            testId: "jobFrequency",
            key: "jobFrequency",
          },
          {
            header: ({ displayType }) =>
              displayType === "desktop" ? (
                <SortColumn
                  displayName={"Day(s) of the week"}
                  columnSortProperty={JobSortColumns.dayOfWeek}
                  currentSortProperty={currentSortColumn}
                  currentSortDirection={currentSortDirection}
                  onSortDirectionChange={(newSortColumn, newSortDirection) => {
                    handleSortChange(newSortColumn, newSortDirection);
                  }}
                  testId={"dayOfWeekHeader"}
                />
              ) : (
                "Day(s) of the week"
              ),
            cell: ({ row: j }) => {
              let returnValue = "";
              if (j.jobType === JobType.maintenanceJob) {
                if (!isInactiveJob(j) && j.daysOfWeek) {
                  returnValue = getDaysOfWeekForDisplay(j.daysOfWeek);
                }
              }

              if (!returnValue) {
                returnValue = "N/A";
              }

              return returnValue;
            },
            testId: "dayOfWeek",
            key: "dayOfWeekHeader",
          },
          {
            header: ({ displayType }) =>
              displayType === "desktop" ? (
                <SortColumn
                  displayName={"Job location"}
                  columnSortProperty={JobSortColumns.jobLocation}
                  currentSortProperty={currentSortColumn}
                  currentSortDirection={currentSortDirection}
                  onSortDirectionChange={(newSortColumn, newSortDirection) => {
                    handleSortChange(newSortColumn, newSortDirection);
                  }}
                  testId={"jobLocationHeader"}
                />
              ) : (
                "Job location"
              ),
            testId: "jobAddress",
            key: "jobAddress",
            cell: ({ row: j }) => {
              return j.customerAdditionalLocationName ?? "Customer address";
            },
          },
          {
            header: ({ displayType }) =>
              displayType === "desktop" ? (
                <SortColumn
                  displayName={"Service type"}
                  columnSortProperty={JobSortColumns.serviceType}
                  currentSortProperty={currentSortColumn}
                  currentSortDirection={currentSortDirection}
                  onSortDirectionChange={(newSortColumn, newSortDirection) => {
                    handleSortChange(newSortColumn, newSortDirection);
                  }}
                  testId={"serviceTypeHeader"}
                />
              ) : (
                "Service type"
              ),
            cell: ({ row: j }) => {
              return j.categories ?? "";
            },
            testId: "jobServiceType",
            key: "jobServiceType",
          },
          {
            header: ({ displayType }) =>
              displayType === "desktop" ? (
                <SortColumn
                  displayName={"Estimate #"}
                  columnSortProperty={JobSortColumns.proposalNumber}
                  currentSortProperty={currentSortColumn}
                  currentSortDirection={currentSortDirection}
                  onSortDirectionChange={(newSortColumn, newSortDirection) => {
                    handleSortChange(newSortColumn, newSortDirection);
                  }}
                  testId={"proposalNumberHeader"}
                />
              ) : (
                "Estimate #"
              ),
            cell: ({ row: j }) => {
              if (
                isStringSet(j.proposalNumber) &&
                isStringSet(j.proposalLookupId)
              ) {
                return (
                  <a
                    href={`${customerUrlRoot}/proposal/${j.proposalLookupId}?ref=admin`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    {j.proposalNumber}
                  </a>
                );
              } else {
                return "";
              }
            },
            testId: "proposalNumber",
            key: "proposalNumber",
            hidden: !salesEnabled,
          },
          {
            header: ({ displayType }) =>
              displayType === "desktop" ? (
                <SortColumn
                  displayName={"Payment authorized"}
                  columnSortProperty={JobSortColumns.paymentAuthorized}
                  currentSortProperty={currentSortColumn}
                  currentSortDirection={currentSortDirection}
                  onSortDirectionChange={(newSortColumn, newSortDirection) => {
                    handleSortChange(newSortColumn, newSortDirection);
                  }}
                  testId={"paymentAuthorizedHeader"}
                />
              ) : (
                "Payment authorized"
              ),
            cell: ({ row: j }) => {
              let paymentMethodOnFileAuthorized = j.paymentAuthorized;

              return paymentMethodOnFileAuthorized ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-muted"
                  style={{ marginLeft: "5px" }}
                />
              ) : null;
            },
            testId: "paymentAuthorized",
            hidden: !paymentAuthorizationFieldsVisible,
            key: "paymentAuthorized",
          },
          {
            key: "buttons",
            header: "",
            isButtonCell: true,
            cell: ({ row: j }) => (
              <CustomersChildEntityListRowButtons
                row={j}
                onEditClick={(j) => {
                  if (j.jobType === JobType.maintenanceJob) {
                    dispatch(
                      actionCreators.forms.maintenanceJob.showForm({
                        maintenanceJobId: j.id,
                      })
                    );
                  } else if (j.jobType === JobType.oneTimeJob) {
                    dispatch(
                      actionCreators.forms.oneTimeJob.showForm({
                        oneTimeJobId: j.id,
                      })
                    );
                  } else if (j.jobType === JobType.project) {
                    setProjectToEdit(j.id);
                  }
                }}
                onDeleteClick={(j) => {
                  if (
                    j.jobType === JobType.maintenanceJob ||
                    j.jobType === JobType.oneTimeJob
                  ) {
                    handleDeleteJobClicked({
                      jobType: j.jobType,
                      jobId: j.id,
                      customerId,
                      dispatch,
                    });
                  } else if (j.jobType === JobType.project) {
                    setProjectToDelete(j.id);
                  }
                }}
              />
            ),
          },
        ]}
      />

      {hasMoreResults ? (
        <div className="jumbotron p-4" style={{ textAlign: "center" }}>
          <button
            type="button"
            className="btn btn-primary btn-lg"
            data-testid="loadAdditionalJobs"
            onClick={() => {
              const newPage = currentPage + 1;
              setCurrentPage(newPage);

              loadRequest.current.next({
                customerId,
                proposalNumber: jobFilters.searchText,
                locationId: jobFilters.locationId,
                page: newPage,
                pageSize: defaultPageSize,
                sortColumn: sortColumn,
                sortDirection: sortDirection,
              });
            }}
          >
            Load additional jobs
          </button>
        </div>
      ) : null}

      {showMultiJobForm ? (
        <ProjectForm
          initialCustomerId={customerId}
          onSaveComplete={() => {
            setShowMultiJobForm(false);

            refreshListAfterSave({
              setJobs,
              customerId,
              jobFilters,
              sortColumn,
              sortDirection,
              setLoadingData,
              currentPage,
            });
          }}
          onCancel={() => setShowMultiJobForm(false)}
          mode="add"
        />
      ) : null}

      {projectToEdit !== null ? (
        <ProjectForm
          initialCustomerId={customerId}
          onSaveComplete={() => {
            setProjectToEdit(null);

            refreshListAfterSave({
              setJobs,
              customerId,
              jobFilters,
              sortColumn,
              sortDirection,
              setLoadingData,
              currentPage,
            });
          }}
          onCancel={() => setProjectToEdit(null)}
          mode="edit"
          projectId={projectToEdit}
        />
      ) : null}
      {projectToDelete !== null ? (
        <CustomerDetailsJobsProjectDelete
          onClose={() => {
            setProjectToDelete(null);
          }}
          projectId={projectToDelete}
        />
      ) : null}
    </>
  );
};

export default CustomerDetailsJobs;

function useLoadJobsAfterReduxFormSave({
  jobFilters,
  customerId,
  setLoadingData,
  setJobs,
  sortColumn,
  sortDirection,
  currentPage,
}: {
  jobFilters: IJobFilters;
  customerId: string;
  setLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
  setJobs: React.Dispatch<React.SetStateAction<GetCustomerJobsJob[]>>;
  sortColumn: JobSortColumns;
  sortDirection: SortDirection;
  currentPage: number;
}) {
  const maintenanceJobSaveCount = useApplicationStateSelector(
    (s) => s.forms.maintenanceJob.saveCount
  );
  const oneTimeJobSaveCount = useApplicationStateSelector(
    (s) => s.forms.oneTimeJob.saveCount
  );

  const lastMaintenanceJobSaveCount = useRef(maintenanceJobSaveCount);
  const lastOneTimeJobSaveCount = useRef(oneTimeJobSaveCount);

  // Form save count is to refresh list after jobs edited or added
  useEffect(() => {
    const isNonPagingResetLoadNeeded =
      maintenanceJobSaveCount !== lastMaintenanceJobSaveCount.current ||
      oneTimeJobSaveCount !== lastOneTimeJobSaveCount.current;
    if (isNonPagingResetLoadNeeded) {
      refreshListAfterSave({
        setJobs,
        customerId,
        jobFilters,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
        setLoadingData,
        currentPage,
      });

      lastMaintenanceJobSaveCount.current = maintenanceJobSaveCount;
      lastOneTimeJobSaveCount.current = oneTimeJobSaveCount;
    }
  }, [
    customerId,
    currentPage,
    sortColumn,
    sortDirection,
    jobFilters,
    setJobs,
    setLoadingData,
    maintenanceJobSaveCount,
    oneTimeJobSaveCount,
  ]);
}

function getPageSizeForRefreshAfterFormSave(currentPage: number) {
  return (currentPage + 1) * defaultPageSize;
}

function handleDeleteJobClicked({
  jobType,
  jobId,
  customerId,
  dispatch,
}: {
  jobType: JobType;
  jobId: string;
  customerId: string;
  dispatch: (action: IAction) => void;
}) {
  dispatch(
    promptActionCreators.showPrompt({
      promptMessage: `Are you sure you want to delete this job?`,
      promptSaveText: "Delete",
      promptSubMessage: [
        { text: "Deleting this job will remove " },
        { text: "all ", bold: true },
        {
          text: "historical information for this job.  If you want to deactive this job and keep the historical information, please set the frequency to Inactive.",
        },
      ],
      confirmationAction: actionCreators.jobDeleteStart(
        jobId,
        jobType,
        customerId || ""
      ),
      promptCancelText: null,
      promptSubMessageClassName: undefined,
    })
  );
}

const flexibleJobLabel = "Flexible Job";
function getMaintenanceJobCrewName(
  job: { crewId: string | null; daysOfWeek: Array<string | number> },
  crews: Array<ICrew>
) {
  if (isFlexibleJob(job)) {
    return flexibleJobLabel;
  } else {
    return getCrewName(job, crews);
  }
}

function getOneTimeJobCrewName(
  job: { crewId: string | null; flexibleJob: boolean },
  crews: Array<ICrew>
) {
  const crewName = getCrewName(job, crews);
  if (job.flexibleJob) {
    return flexibleJobLabel;
  } else {
    return crewName;
  }
}

function getCrewName(job: { crewId: string | null }, crews: Array<ICrew>) {
  const crew = crews.find((c) => c.id === job.crewId);
  if (crew) {
    return crew.name;
  } else {
    return "";
  }
}

function isInactiveJob(job: GetCustomerJobsJob) {
  return (
    job.frequency === MaintenanceJobFrequency.None &&
    (job.seasonalScheduleFrequency === null ||
      job.seasonalScheduleFrequency === MaintenanceJobFrequency.None)
  );
}

function refreshListAfterSave({
  setJobs,
  customerId,
  jobFilters,
  sortColumn,
  sortDirection,
  setLoadingData,
  currentPage,
}: {
  setJobs: React.Dispatch<React.SetStateAction<GetCustomerJobsJob[]>>;
  customerId: string;
  jobFilters: IJobFilters;
  sortColumn: JobSortColumns;
  sortDirection: SortDirection;
  setLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
  currentPage: number;
}) {
  setLoadingData(true);

  // Load the entire set of data to ensure any edited rows are reloaded
  // Not the most efficient but believe it's unlikely that users will
  // typically page down and even worst case scenario is equal to
  // behavior before this change
  getLoadDataObservable({
    customerId,
    currentPage,
    pageSize: getPageSizeForRefreshAfterFormSave(currentPage),
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    jobFilters,
  }).subscribe(
    (result) => {
      setLoadingData(false);
      setJobs(result.jobs);
    },
    () => {
      // Don't report error since this was a refresh from a form update
    }
  );
}

interface ISortColumn<TSortColumn> {
  column: TSortColumn;
  direction: SortDirection;
}

function getLoadDataObservable({
  customerId,
  currentPage,
  pageSize,
  sortColumn,
  sortDirection,
  jobFilters,
}: {
  customerId: string;
  currentPage: number;
  pageSize: number | undefined;
  sortColumn: JobSortColumns;
  sortDirection: SortDirection;
  jobFilters: IJobFilters;
}) {
  return jobDataProvider
    .getCustomerJobs({
      customerId,
      page: currentPage,
      pageSize: pageSize ?? defaultPageSize,
      sortColumn,
      sortDirection,
      proposalNumber: jobFilters.searchText,
      locationId: jobFilters.locationId,
    })
    .pipe(timeout(30000));
}

function getDefaultSortColumn<TSortColumn>(
  sortColumnSettingsKey: UserSettingsType,
  getUserSettings: GetUserSettingsType
): ISortColumn<TSortColumn> | null {
  const savedSetting = getUserSettings<ISortColumn<TSortColumn>>(
    sortColumnSettingsKey
  );
  return savedSetting;
}

function useLoadJobs({
  customerId,
  sortColumn,
  sortDirection,
  jobFilters,
}: {
  customerId: string;
  sortColumn: JobSortColumns;
  sortDirection: SortDirection;
  jobFilters: IJobFilters;
}) {
  const [jobs, setJobs] = useState<Array<GetCustomerJobsJob>>([]);
  const [hasMoreResults, setHasMoreResults] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [initialDataLoadComplete, setInitialDataLoadComplete] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Using Subject with switchMap to ensure old requests are cancelled.
  // Otherwise, on a slow connection if multiple requests are issued on the search
  // field, each response will be added
  const loadRequest = useRef<Subject<GetCustomerJobsRequest>>(
    new BehaviorSubject<GetCustomerJobsRequest>({
      customerId,
      locationId: jobFilters.locationId,
      page: 0,
      pageSize: defaultPageSize,
      proposalNumber: jobFilters.searchText,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
    })
  );

  useEffect(() => {
    const subscription = loadRequest.current
      .pipe(
        tap(() => setLoadingData(true)),
        switchMap((params) =>
          jobDataProvider.getCustomerJobs(params).pipe(
            map((r) => ({
              ...r,
              error: false,
              errorDetails: null,
            })),
            catchError((err) => {
              return of({
                error: true,
                jobs: [],
                hasMoreResults: false,
                errorDetails: err,
              });
            })
          )
        )
      )
      .subscribe({
        next: (result) => {
          if (!result.error) {
            setLoadingData(false);
            setJobs((j) => [
              // Complexity is to handle reloading existing jobs
              ...j,
              ...result.jobs,
            ]);
            setHasMoreResults(result.hasMoreResults);
            setErrorMessage(null);
            setInitialDataLoadComplete(true);
          } else {
            setLoadingData(false);
            setErrorMessage(getErrorMessageFromError(result.errorDetails, ""));
          }
        },

        error: (err) => {
          setLoadingData(false);
          setErrorMessage(getErrorMessageFromError(err, ""));
        },
      });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, []);

  return {
    jobs,
    currentPage,
    setCurrentPage,
    initialDataLoadComplete,
    setInitialDataLoadComplete,
    loadingData,
    errorMessage,
    hasMoreResults,
    setLoadingData,
    setJobs,
    loadRequest,
  };
}
