import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import {
  buildUrl,
  executeWithHeaders,
} from "../../../services/remoteDataProvider";

export interface IGetPaymentMethodUsageResponse {
  hasAuthorizedJobs: boolean;
}

export interface IGetTaxRateUsageResponse {
  hasTaxRateInUse: boolean;
}

const customerDataProvider = {
  getPaymentMethodUsage: (customerId: string) => {
    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(`customer/${customerId}/paymentmethodusage`), headers)
    ).pipe(map((result) => result.response as IGetPaymentMethodUsageResponse));
  },

  getTaxRateUsage: (customerId: string) => {
    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(`customer/${customerId}/taxrateusage`), headers)
    ).pipe(map((result) => result.response as IGetTaxRateUsageResponse));
  },
};

export default customerDataProvider;
