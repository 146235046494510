import { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import DayPicker, {
  format as dayPickerFormat,
} from "../../../containers/app/components/DayPicker";
import format from "date-fns/format";
import { getSortedCrews } from "../../../services/sortingService";
import React from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { IFormsState } from "../../../formGenerator/formReducers";
import FormContainer2 from "../../../containers/app/components/FormContainer2";
import conditionalRenderer from "../../../containers/app/components/conditionalRenderer";
import { OneTimeJobOrderingDropDown } from "./OneTimeJobOrderingDropDown";
import constants from "../../../constants";
import dateService from "../../../services/dateService";
import { CrewScheduleType } from "../enums/crewScheduleType";
import { getDestinationCrewId } from "./BulkCopyJobsForm.functions";

interface IFormData {
  destinationCrewId: string;
  destinationDate: string;
  precedingJobInstanceId: string;
}

const formKey: keyof IFormsState = "bulkCopyJobs";
const BulkCopyJobsForm: React.FunctionComponent<{}> = () => {
  const crews = useApplicationStateSelector((s) => s.crew.crews);
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const weeksUnscheduledMaintenanceJobs = useApplicationStateSelector(
    (s) => s.schedule.weeksUnscheduledMaintenanceJobs
  );
  const formParameters = useApplicationStateSelector(
    (s) => s.forms.bulkCopyJobs.parameters
  );
  const dayPickerLabelElement = useRef<HTMLLabelElement>(null);

  const { register, control, watch, setValue, getValues } = useForm<IFormData>({
    defaultValues: {
      destinationCrewId: getDestinationCrewId({
        daySchedules,
        weeksUnscheduledMaintenanceJobs,
        sourceJobInstanceIds: formParameters?.sourceJobInstanceIds ?? [],
      }),
      destinationDate: dateService.formatAsIso(dateService.getCurrentDate()),
      precedingJobInstanceId: constants.idForFirstJob,
    },
  });

  const destinationDate = watch("destinationDate");
  const destinationCrewId = watch("destinationCrewId");
  const precedingJobInstanceId = watch("precedingJobInstanceId");

  const selectedCrew = crews.find((c) => c.id === destinationCrewId);
  const crewTimeBasedSchedule =
    selectedCrew?.scheduleType === CrewScheduleType.time;

  return (
    <FormContainer2
      formHeader={
        formParameters
          ? `Copy ${formParameters.sourceJobInstanceIds.length} ${
              formParameters.sourceJobInstanceIds.length === 1 ? "Job" : "Jobs"
            }`
          : "Copy Jobs"
      }
      formType={formKey}
      getFormData={() => {
        const values = getValues();
        return {
          destinationCrewId: values.destinationCrewId,
          destinationDate: dateService.formatAsIso(values.destinationDate),
          precedingJobInstanceId: !crewTimeBasedSchedule
            ? values.precedingJobInstanceId
            : null,
          sourceJobInstanceIds: formParameters?.sourceJobInstanceIds ?? [],
        };
      }}
    >
      <div style={{ minHeight: "375px" }}>
        <div className="form-group">
          <label
            htmlFor="destinationDate"
            className="required"
            ref={dayPickerLabelElement}
          >
            Date
          </label>
          <div>
            <Controller
              control={control}
              name="destinationDate"
              render={({ field }) => (
                <DayPicker
                  labelRef={dayPickerLabelElement}
                  testId="newDate"
                  onDayPickerHide={() => null}
                  dayPickerProps={{}}
                  value={
                    !!field.value ? format(field.value, dayPickerFormat) : ""
                  }
                  required={true}
                  inputId="destinationDate"
                  onDaySelected={(day: Date) => {
                    let newValue = "";

                    if (!!day) {
                      newValue = format(day, dayPickerFormat);
                    }

                    field.onChange(newValue);
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="crew" className="required">
            Crew
          </label>
          <select
            className="form-control"
            id="crew"
            data-testid="destinationCrewId"
            {...register("destinationCrewId")}
            required
          >
            {getSortedCrews(crews.filter((c) => !c.inactive)).map((crew) => (
              <option key={crew.id} value={crew.id}>
                {crew.name}
              </option>
            ))}
          </select>
        </div>

        {!crewTimeBasedSchedule ? (
          <OneTimeJobOrderingDropDown
            destinationDate={destinationDate}
            destinationCrewId={destinationCrewId}
            destinationPrecedingJobInstanceId={precedingJobInstanceId}
            onDestinationPrecedingJobInstanceIdChanged={(newValue) =>
              setValue("precedingJobInstanceId", newValue)
            }
            jobInstanceIdsToExclude={[]}
            destinationUnscheduledJobs={false}
          />
        ) : null}
      </div>
    </FormContainer2>
  );
};

// Using older redux style forms to simplify visibility
// Otherwise, hosting the form in SelectedJobActions
// would cause the form to be hidden since SelectedJobActions
// is unmounted when the form is shown.
export default conditionalRenderer(
  BulkCopyJobsForm,
  (s) => s.forms[formKey].showForm
);
