import { useCreditCardsEnabled } from "../../../../hooks/useCreditCardsEnabled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

enum CaptureCustomerPaymentMethodType {
  Never = 0,
  Required = 1,
  Optional = 2,
}

type IProps = {
  idPrefix: string;
  captureCustomerPaymentMethod: boolean;
  setCaptureCustomerPaymentMethod: (value: boolean) => void;
  captureCustomerPaymentMethodOptional: boolean;
  setCaptureCustomerPaymentMethodOptional: (value: boolean) => void;
};

const CaptureCustomerPaymentMethod: React.FunctionComponent<IProps> = ({
  idPrefix,
  captureCustomerPaymentMethod,
  setCaptureCustomerPaymentMethod,
  captureCustomerPaymentMethodOptional,
  setCaptureCustomerPaymentMethodOptional,
}) => {
  const { areCreditCardsEnabled } = useCreditCardsEnabled();

  return (
    <>
      {areCreditCardsEnabled ? (
        <div className="form-group">
          <label>
            Allow your customer to save their payment method on file
          </label>
          <select
            id={`${idPrefix}_CaptureCustomerPaymentMethod`}
            data-testid={`${idPrefix}_CaptureCustomerPaymentMethod`}
            className="form-control"
            value={getType(
              captureCustomerPaymentMethod,
              captureCustomerPaymentMethodOptional
            )}
            onChange={(e) => {
              switch (e.currentTarget.value) {
                case CaptureCustomerPaymentMethodType.Never.toString():
                  setCaptureCustomerPaymentMethod(false);
                  setCaptureCustomerPaymentMethodOptional(false);
                  break;
                case CaptureCustomerPaymentMethodType.Required.toString():
                  setCaptureCustomerPaymentMethod(true);
                  setCaptureCustomerPaymentMethodOptional(false);
                  break;
                case CaptureCustomerPaymentMethodType.Optional.toString():
                  setCaptureCustomerPaymentMethod(true);
                  setCaptureCustomerPaymentMethodOptional(true);
                  break;
              }
            }}
          >
            <option value={CaptureCustomerPaymentMethodType.Never.toString()}>
              Never
            </option>
            <option
              value={CaptureCustomerPaymentMethodType.Required.toString()}
            >
              Required
            </option>
            <option
              value={CaptureCustomerPaymentMethodType.Optional.toString()}
            >
              Optional
            </option>
          </select>
          {captureCustomerPaymentMethod ||
          captureCustomerPaymentMethodOptional ? (
            <div
              data-testid={`${idPrefix}_CaptureCustomerPaymentMethodInfo`}
              className="alert alert-info mt-2"
              style={{
                border: "0px",
                padding: "12px",
              }}
            >
              <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
              <small>
                When a payment method is saved on file, you can automatically
                apply the payment to your customer's invoice.
                <br /> However, you cannot include a 3% convenience fee due to
                credit card regulations.
              </small>
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );

  function getType(
    captureCustomerPaymentMethod: boolean,
    captureCustomerPaymentMethodOptional: boolean
  ) {
    return captureCustomerPaymentMethod && captureCustomerPaymentMethodOptional
      ? CaptureCustomerPaymentMethodType.Optional
      : captureCustomerPaymentMethod
      ? CaptureCustomerPaymentMethodType.Required
      : CaptureCustomerPaymentMethodType.Never;
  }
};

export default CaptureCustomerPaymentMethod;
