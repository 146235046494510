import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import {
  IInvoiceFormData,
  IInvoiceJobInstance,
  IInvoiceProject,
  genericLoadErrorMessage,
} from "./InvoiceForm.types";
import { DiscountType } from "../../../enums/DiscountType";
import { ICustomer } from "../../../models/ICustomer";
import { ICustomerAdditionalLocation } from "../../../models/ICustomerAdditionalLocation";
import { IInvoiceView } from "../../../models/IInvoice";
import { IInvoiceConfiguration } from "../../../models/IInvoiceConfiguration";
import dateService from "../../../services/dateService";
import { getInvoiceDueDate } from "../../../services/invoiceService";
import { initializeTextState } from "../../../services/richTextService";
import {
  createEmailAddressRecord,
  createEmailAddressRecordsForCustomer,
} from "../components/EmailAddresses.functions";
import {
  getDefaultDeliveryMethod,
  getLocationsForFormData,
  getSegmentedLineItemsFromExistingInvoice,
  initializeLineItemsByLocation,
} from "./InvoiceForm.functions";
import { EditorState } from "draft-js";
import { useRef, useState, useEffect } from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { useGetReplyToEmailAddress } from "../../../hooks/useGetReplyToEmailAddress";
import { logError } from "../../../services/errorLogger";
import {
  createNewLineItem,
  getDefaultServiceDate,
} from "../../../services/invoiceFormService";
import remoteDataProvider from "../../../services/remoteDataProvider";
import {
  GetUserSettingsType,
  useUserSettings,
} from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import useGetDefaultTaxRate from "../../../slices/sales/hooks/useGetDefaultTaxRate";

export function useSetFormValues({
  defaultQuickBooksCustomerId,
  jobInstances,
  projects,
  isQuickBooksEnabled,
  invoiceId,
  areCreditCardsEnabled,
  loadedSettings,
  customer,
  setValue,
  setOriginalFormValues,
  getValues,
  setInitialIntroText,
  setInitialFooterText,
  setAlwaysShowDepositCredits,
  paymentMethodPreviouslyAuthorized,
  customerAdditionalLocations,
  defaultPurchaseOrderNumber,
  invoiceConfiguration,
  setEditingDraft,
}: {
  defaultQuickBooksCustomerId: string;
  jobInstances: IInvoiceJobInstance[];
  projects: IInvoiceProject[];
  isQuickBooksEnabled: boolean;
  areCreditCardsEnabled: boolean;
  customer: ICustomer | null;
  loadedSettings: boolean;
  setValue: UseFormSetValue<IInvoiceFormData>;
  invoiceId: string | null;
  setOriginalFormValues: React.Dispatch<React.SetStateAction<IInvoiceFormData>>;
  getValues: UseFormGetValues<IInvoiceFormData>;
  setInitialIntroText: React.Dispatch<React.SetStateAction<EditorState>>;
  setInitialFooterText: React.Dispatch<React.SetStateAction<EditorState>>;
  setAlwaysShowDepositCredits: React.Dispatch<React.SetStateAction<boolean>>;
  paymentMethodPreviouslyAuthorized: boolean;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
  defaultPurchaseOrderNumber: string;
  invoiceConfiguration: IInvoiceConfiguration;
  setEditingDraft: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const hasInitialized = useRef(false);
  const replyToEmailAddress = useGetReplyToEmailAddress();
  const quickBooksDeliveryAllowed = useApplicationStateSelector(
    (s) => s.common.quickBooksDeliveryAllowed
  );
  const defaultTaxRate = useGetDefaultTaxRate(customer);

  const [invoice, setInvoice] = useState<IInvoiceView | null>(null);
  const [errorLoadingInvoiceMessage, setErrorLoadingInvoiceMessage] = useState<
    string | null
  >(null);
  const loadedInvoice = !invoiceId || invoice !== null;

  useEffect(() => {
    if (invoiceId) {
      remoteDataProvider
        .getInvoices({
          invoiceIds: [invoiceId],
        })
        .subscribe({
          next: (invoices) => {
            if (invoices.list.length === 0) {
              setErrorLoadingInvoiceMessage(
                "The invoice was deleted. Please reload this page to get the latest invoices."
              );
            } else if (invoices.list.length !== 1) {
              logError("unexpected count for received invoices");
              setErrorLoadingInvoiceMessage(genericLoadErrorMessage);
            } else {
              setInvoice(invoices.list[0]);
            }
          },

          error: () => setErrorLoadingInvoiceMessage(genericLoadErrorMessage),
        });
    }
  }, [invoiceId]);

  const { getUserSettings } = useUserSettings();
  useEffect(() => {
    if (loadedSettings && loadedInvoice && !hasInitialized.current) {
      if (!invoiceId) {
        setFormValuesForNewInvoice({
          setValue,
          defaultQuickBooksCustomerId,
          customer,
          jobInstances,
          projects,
          isQuickBooksEnabled,
          areCreditCardsEnabled,
          setOriginalFormValues,
          allowCreditCardPayment: getValues(
            "paymentMethodOptions.allowCreditCardPayment"
          ),
          replyToEmailAddress: replyToEmailAddress,
          quickBooksDeliveryAllowed,
          paymentMethodPreviouslyAuthorized,
          defaultTaxRate,
          defaultPurchaseOrderNumber,
          invoiceConfiguration,
          getUserSettings,
        });
      } else {
        setFormValuesForExistingInvoice({
          setValue,
          invoice: invoice as IInvoiceView,
          setAlwaysShowDepositCredits,
          customerAdditionalLocations,
          setEditingDraft,
          invoiceConfiguration,
          customer,
        });
      }

      const values = getValues() as IInvoiceFormData;
      setOriginalFormValues(values);
      setInitialIntroText(values.introTextState);
      setInitialFooterText(values.footerTextState);

      hasInitialized.current = true;
    }
  }, [
    defaultQuickBooksCustomerId,
    jobInstances,
    projects,
    isQuickBooksEnabled,
    areCreditCardsEnabled,
    customer,
    loadedSettings,
    loadedInvoice,
    invoice,
    invoiceId,
    setValue,
    setOriginalFormValues,
    getValues,
    setInitialIntroText,
    setInitialFooterText,
    setAlwaysShowDepositCredits,
    replyToEmailAddress,
    quickBooksDeliveryAllowed,
    paymentMethodPreviouslyAuthorized,
    customerAdditionalLocations,
    defaultTaxRate,
    defaultPurchaseOrderNumber,
    invoiceConfiguration,
    setEditingDraft,
    getUserSettings,
  ]);

  return {
    loadedInvoice,
    invoice,
    errorLoadingInvoiceMessage,
    editingPayThroughCrewControlInvoice:
      (invoice?.payThroughCrewControl ?? false) && !invoice?.draft,
  };
}

function setFormValuesForNewInvoice({
  setValue,
  setOriginalFormValues,
  defaultQuickBooksCustomerId,
  customer,
  jobInstances,
  projects,
  isQuickBooksEnabled,
  areCreditCardsEnabled,
  allowCreditCardPayment,
  replyToEmailAddress,
  quickBooksDeliveryAllowed,
  paymentMethodPreviouslyAuthorized,
  defaultTaxRate,
  defaultPurchaseOrderNumber,
  invoiceConfiguration,
  getUserSettings,
}: {
  setValue: UseFormSetValue<IInvoiceFormData>;
  defaultQuickBooksCustomerId: string;
  customer: ICustomer | null;
  jobInstances: IInvoiceJobInstance[];
  projects: IInvoiceProject[];
  isQuickBooksEnabled: boolean;
  areCreditCardsEnabled: boolean;
  allowCreditCardPayment: boolean;
  setOriginalFormValues: React.Dispatch<React.SetStateAction<IInvoiceFormData>>;
  replyToEmailAddress: string | null;
  quickBooksDeliveryAllowed: boolean;
  paymentMethodPreviouslyAuthorized: boolean;
  defaultTaxRate: number | null;
  defaultPurchaseOrderNumber: string;
  invoiceConfiguration: IInvoiceConfiguration;
  getUserSettings: GetUserSettingsType;
}) {
  setValue("quickBooksCustomerId", defaultQuickBooksCustomerId || "");
  setValue(
    "deliveryMethodDetails.emailFields.recipients",
    createEmailAddressRecordsForCustomer(customer)
  );
  setValue(
    "deliveryMethodDetails.emailFields.replyTo",
    replyToEmailAddress ?? ""
  );
  setValue(
    "deliveryMethodDetails.customerPhoneNumber",
    customer?.phoneNumber ?? ""
  );
  setValue(
    "deliveryMethodDetails.customerPhoneNumberOptedIntoSms",
    customer?.phoneNumberOptedIntoSms ?? false
  );

  if (
    typeof defaultPurchaseOrderNumber === "string" &&
    defaultPurchaseOrderNumber.length > 100
  ) {
    defaultPurchaseOrderNumber = defaultPurchaseOrderNumber.substring(0, 100);
  }

  setValue("purchaseOrderNumber", defaultPurchaseOrderNumber);
  setOriginalFormValues((v) => ({
    ...v,
    purchaseOrderNumber: defaultPurchaseOrderNumber,
  }));

  if (jobInstances || projects) {
    const selectedWorkToBill = {
      selectedJobInstances: jobInstances?.map((ji) => ji.id) ?? [],
      selectedProjects: projects?.map((p) => p.id) ?? [],
    };
    setValue("selectedWorkToBill", selectedWorkToBill);
    setValue(
      "lineItemsByLocation",
      initializeLineItemsByLocation({
        buildDefaultLineItem: (quantity) =>
          createNewLineItem(
            quantity,
            getDefaultServiceDate({
              jobInstances,
              selectedJobInstances: selectedWorkToBill.selectedJobInstances,
            })
          ),
        jobInstancesWithLocations: jobInstances,
      })
    );
  } else {
    setValue("selectedWorkToBill", {
      selectedJobInstances: [],
      selectedProjects: [],
    });
    setValue(
      "lineItemsByLocation",
      initializeLineItemsByLocation({
        buildDefaultLineItem: (quantity) => createNewLineItem(quantity),
        jobInstancesWithLocations: jobInstances,
      })
    );
  }

  if (!isQuickBooksEnabled) {
    const initialTaxRate = defaultTaxRate;
    setValue("amountAdjustments.taxRate", initialTaxRate);
  } else {
    setValue("amountAdjustments.taxRate", null);
  }

  const defaultProposalDepositItemId = getUserSettings<string>(
    UserSettingsType.proposalDepositItemId
  );
  if (defaultProposalDepositItemId) {
    setValue("amountAdjustments.depositItem", {
      itemId: defaultProposalDepositItemId,
      name: null,
      description: null,
    });
  }

  const startingDate = dateService.getCurrentDate();
  setValue("startingDate", dateService.formatAsIso(startingDate));

  const dueDate = getInvoiceDueDate(startingDate, invoiceConfiguration);
  if (dueDate !== null) {
    setValue("dueDate", dateService.formatAsIso(dueDate));
  }

  let addConvenienceFee =
    getUserSettings<boolean>(
      UserSettingsType.paymentsConvenienceFeePercentEnabled
    ) ?? true;
  if (!allowCreditCardPayment) {
    addConvenienceFee = false;
  }

  setValue("paymentMethodOptions.addConvenienceFee", addConvenienceFee);

  setValue(
    "deliveryMethodDetails.invoiceDeliveryMethod",
    getDefaultDeliveryMethod(
      isQuickBooksEnabled && quickBooksDeliveryAllowed,
      areCreditCardsEnabled,
      paymentMethodPreviouslyAuthorized,
      getUserSettings
    )
  );
}

function setFormValuesForExistingInvoice({
  setValue,
  invoice,
  setAlwaysShowDepositCredits,
  customerAdditionalLocations,
  setEditingDraft,
  invoiceConfiguration,
  customer,
}: {
  setValue: UseFormSetValue<IInvoiceFormData>;
  invoice: IInvoiceView;
  setAlwaysShowDepositCredits: React.Dispatch<React.SetStateAction<boolean>>;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
  setEditingDraft: React.Dispatch<React.SetStateAction<boolean>>;
  invoiceConfiguration: IInvoiceConfiguration;
  customer: ICustomer | null;
}) {
  setValue("amountAdjustments.taxRate", invoice.taxRate);
  setValue(
    "amountAdjustments.discount",
    invoice.discount ?? {
      type: DiscountType.percent,
      amount: null,
      percent: null,
    }
  );
  setValue("quickBooksCustomerId", invoice.quickBooksCustomerId);
  setValue(
    "lineItemsByLocation",
    getLocationsForFormData({
      lineItemsForLocation: getSegmentedLineItemsFromExistingInvoice(
        invoice.lineItems
      ),
      customerAdditionalLocations,
      jobInstancesWithLocations: [],
    })
  );
  setValue("paymentMethodOptions", {
    addConvenienceFee: invoice.addConvenienceFee,
    allowAchPayment: invoice.allowOnlineAchPayment,
    allowCreditCardPayment: invoice.allowOnlineCreditCardPayment,
  });
  setValue("purchaseOrderNumber", invoice.purchaseOrderNumber);
  initializeTextState(invoice.introText, (v) => setValue("introTextState", v));
  initializeTextState(invoice.footerText, (v) =>
    setValue("footerTextState", v)
  );
  setValue(
    "amountAdjustments.depositCreditAmount",
    invoice.depositCreditAmount
  );
  setAlwaysShowDepositCredits((invoice.depositCreditAmount ?? 0) > 0);
  setValue(
    "files",
    (invoice.files ?? []).map((f) => ({
      ...f,
      actualWidth: null,
      actualHeight: null,
    }))
  );
  setValue("showLineItemPrices", !invoice.hideLineItemPrices);
  setValue("summary", invoice.summary ?? "");

  if (!invoice.draft) {
    setValue("startingDate", invoice.date);
    setValue("dueDate", invoice.dueDate ?? "");
  } else {
    if (
      typeof invoice.deliveryMethod === "number" ||
      typeof invoice.deliveryMethod === "string"
    ) {
      setValue(
        "deliveryMethodDetails.invoiceDeliveryMethod",
        invoice.deliveryMethod.toString()
      );
    }

    setValue(
      "deliveryMethodDetails.emailFields.replyTo",
      invoice.replyToEmailAddress ?? ""
    );
    setValue(
      "deliveryMethodDetails.emailFields.recipients",
      invoice.receiptEmailAddresses.length > 0
        ? invoice.receiptEmailAddresses.map((emailAddress) =>
            createEmailAddressRecord(emailAddress)
          )
        : [createEmailAddressRecord("")]
    );

    setValue(
      "deliveryMethodDetails.customerPhoneNumber",
      customer?.phoneNumber ?? ""
    );
    setValue(
      "deliveryMethodDetails.customerPhoneNumberOptedIntoSms",
      customer?.phoneNumberOptedIntoSms ?? false
    );

    const startingDate = dateService.getCurrentDate();
    setValue("startingDate", dateService.formatAsIso(startingDate));

    const dueDate = getInvoiceDueDate(startingDate, invoiceConfiguration);
    if (dueDate !== null) {
      setValue("dueDate", dateService.formatAsIso(dueDate));
    }
  }

  setEditingDraft(invoice.draft);
}
