import React from "react";
import SendFormBase from "../../../containers/app/components/SendFormBase";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import invoiceDataProvider from "../services/invoiceDataProvider";

interface IProps {
  customerId: string;
  invoiceId: string;
  paymentId: string | null;
  onClose: () => void;
  receiptEmailAddresses: Array<string>;
}

const ResendReceiptForm: React.FunctionComponent<IProps> = ({
  customerId,
  invoiceId,
  paymentId,
  onClose,
  receiptEmailAddresses,
}) => {
  return (
    <SendFormBase
      formHeader="Resend Receipt"
      customerId={customerId}
      formType={FormTypesV2.resendReceiptForm}
      onCancel={onClose}
      onSaveComplete={onClose}
      disableTextDeliveryMethod
      defaultEmailAddresses={receiptEmailAddresses}
      save={(data) => {
        return typeof paymentId === "string"
          ? invoiceDataProvider.resendReceiptForPayment(
              invoiceId,
              paymentId,
              data.replyToEmailAddress,
              data.emailAddresses
            )
          : invoiceDataProvider.resendReceiptForInvoice(
              invoiceId,
              data.replyToEmailAddress,
              data.emailAddresses
            );
      }}
    />
  );
};

export default ResendReceiptForm;
