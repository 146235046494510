import Import from "../../../containers/app/components/Import";
import { IInvoiceItemCrewControl } from "../../../models/IInvoiceItemCrewControl";
import { invoiceItemDataProvider } from "../services/invoiceItemDataProvider";

export function InvoiceItemsImport({
  onClose,
}: {
  onClose: (dataSaved: boolean) => void;
}) {
  return (
    <Import<IInvoiceItemCrewControl>
      headerRecordType="Products & Services"
      successRecordType="product/service(s)"
      uploadFile={invoiceItemDataProvider.invoiceItemImportPreview}
      importSave={invoiceItemDataProvider.invoiceItemImport}
      exampleFile="/assets/ProductServiceImportCsv.csv"
      onClose={onClose}
      size="lg"
      headerRow={
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>AmountPerItem</th>
          <th>Taxable</th>
          <th>InternalNotes</th>
        </tr>
      }
      renderRow={(c) => (
        <tr>
          <td data-testid="name">{c.name}</td>
          <td data-testid="description">{c.description}</td>
          <td data-testid="amountPerItem">{c.unitPrice}</td>
          <td data-testid="taxable">{c.taxable ? "Yes" : "No"}</td>
          <td data-testid="internalNotes">{c.internalNotes}</td>
        </tr>
      )}
      columns={[
        {
          name: "Name",
          required: true,
        },
        { name: "Description " },
        { name: "AmountPerItem" },
        { name: "Taxable" },
        { name: "InternalNotes" },
      ]}
    />
  );
}
