import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FormContainerWithoutRedux from "../../../containers/app/components/FormContainerWithoutRedux";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import clientSupportDataProvider from "../services/clientSupportDataProvider";
import { ErrorMessageType } from "../../../containers/app/components/FormContainer";

interface IProps {
  tenantId: string;
  clientName: string;
  currentDays: number;
  onSaveComplete: () => void;
  onCancel: () => void;
}

interface IFormData {
  trialDays: number;
}

const ExtendTrialForm = ({
  tenantId,
  clientName,
  currentDays,
  onSaveComplete,
  onCancel,
}: IProps) => {
  const { getValues, control } = useForm<IFormData>();
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");

  return (
    <FormContainerWithoutRedux
      formHeader={`Extend trial for ${clientName}`}
      formType={FormTypesV2.clientSupportExtendTrial}
      save={() => {
        return clientSupportDataProvider.extendTrial({
          tenantId: tenantId,
          days: getValues("trialDays"),
        });
      }}
      onSaveComplete={() => {
        onSaveComplete();
      }}
      onCancel={() => {
        onCancel();
      }}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      saveButtonText="Save"
    >
      <label htmlFor="trialDays" className="required">
        Days for trial:
      </label>
      <Controller
        name="trialDays"
        control={control}
        render={({ field }) => (
          <input
            type="number"
            id="trialDays"
            className="form-control"
            required
            {...field}
            min={currentDays + 1}
            max={30}
            step={1}
          />
        )}
      />
    </FormContainerWithoutRedux>
  );
};
export default ExtendTrialForm;
