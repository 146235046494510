import { IDaySchedule } from "../models/IDaySchedule";
import dateService from "./dateService";

export interface IDayToLoad {
  crewId: string;
  date: string | Date;
}

const getDaysToLoad = ({
  loadedDaySchedules,
  crewIds,
  dates,
  skipStaleCheck,
}: {
  loadedDaySchedules: Array<IDaySchedule>;
  crewIds: Array<string>;
  dates: Array<Date | string>;
  skipStaleCheck?: boolean;
}): Array<IDayToLoad> => {
  const daysToLoad: Array<IDayToLoad> = [];

  crewIds.forEach((crewId) => {
    dates.forEach((date) => {
      const daySchedule = loadedDaySchedules.find(
        (d) =>
          d.crewId === crewId &&
          dateService.areDatesEqual(d.date, date) &&
          (!d.stale || skipStaleCheck)
      );

      if (!daySchedule) {
        daysToLoad.push({
          crewId,
          date: dateService.formatAsIso(date),
        });
      }
    });
  });

  return daysToLoad;
};

export { getDaysToLoad };
