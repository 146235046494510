import TextareaAutosize from "react-autosize-textarea";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import PhoneNumberField from "./PhoneNumberField";
import DayPicker, { format as dayPickerFormat } from "./DayPicker";

import { format } from "date-fns";
import dateService from "../../../services/dateService";
import {
  ContactMethodTypeSelection,
  ContactMethodType,
  PhoneNumberPlaceholder,
  EmailAddressPlaceholder,
} from "./CustomerNotificationsTemplatedMessage.options";
import {
  ITemplatedMessage,
  ITemplatedMessageDefaults,
  ITemplatedMessageVariable,
  calculatedContactMethodInputType,
  calculatedShowDayOfServiceInputType,
} from "../../../models/IITemplatedMessage";
import { TemplateVariableValues } from "../../../models/ICustomerNotificationsConfiguration";
import { Input } from "reactstrap";
import { buildMessage } from "./CustomerNotificationsTemplatedMessage.functions";
import constants from "../../../constants";
import { CrewScheduleType } from "../../../slices/schedule/enums/crewScheduleType";

type OnChange = (args: {
  value: string;
  selectedTemplateId: string | null;
  variables: TemplateVariableValues;
}) => void;

interface IProps {
  selectedTemplate: ITemplatedMessage | undefined;
  hardcodedVariables?: TemplateVariableValues;
  templateVariables: ITemplatedMessageVariable[];
  variableValues: TemplateVariableValues;
  messageText?: string;
  onChange: OnChange;
}

type TemplatedMessageVariableWithChildren = ITemplatedMessageVariable & {
  children: Array<ITemplatedMessageVariable>;
};

const CustomerNotificationsTemplatedMessageBuilder: React.FunctionComponent<
  IProps
> = ({
  selectedTemplate,
  hardcodedVariables,
  templateVariables,
  variableValues,
  messageText,
  onChange,
}) => {
  const tenantName = useApplicationStateSelector((s) => s.common.tenantName);
  const crews = useApplicationStateSelector((s) => s.crew.crews);
  const hasActiveTimeBasedCrews = crews
    .filter((c) => !c.inactive)
    .some((c) => c.scheduleType === CrewScheduleType.time);

  const selectedTemplateVariablesWithChildren: Array<TemplatedMessageVariableWithChildren> =
    templateVariables
      .map((v) => {
        if (v.inputType === "contactMethod") {
          const children = templateVariables.filter(
            (v) => v.inputType === "email" || v.inputType === "phone"
          );
          return {
            ...v,
            children: children ?? [],
          };
        } else {
          return {
            ...v,
            children: [],
          };
        }
      })
      .filter((v) => v.inputType !== "email" && v.inputType !== "phone");

  return (
    <>
      <div>
        {selectedTemplateVariablesWithChildren
          .filter((v) => !Object.keys(hardcodedVariables ?? {}).includes(v.key))
          .map((v) => {
            const element = getVariableElement({
              variable: v,
              value: variableValues[v.key] ?? "",
              onChange: (newValue) => {
                const newVariables = {
                  ...variableValues,
                  [v.key]: newValue,
                };

                onChange({
                  variables: newVariables,
                  selectedTemplateId: selectedTemplate?.id ?? "",
                  value: buildMessage({
                    selectedTemplate,
                    hasActiveTimeBasedCrews,
                    tenantName,
                    editedVariables: newVariables,
                    hardcodedVariables,
                  }),
                });
              },
              contactMethodType: variableValues[ContactMethodTypeSelection]
                ? parseInt(variableValues[ContactMethodTypeSelection])
                : null,
            });

            if (element === null) {
              return null;
            }
            if (v.children.length === 0) {
              return (
                <div className="form-group" key={v.key}>
                  {element}
                </div>
              );
            } else {
              return (
                <div className="form-row" key={v.key}>
                  <div className="form-group col-12 col-lg-6">{element}</div>
                  {v.children.map((child) => {
                    const childElement = getVariableElement({
                      variable: child,
                      value: variableValues[child.key] ?? "",
                      onChange: (newValue) => {
                        const newVariables = {
                          ...variableValues,
                          [child.key]: newValue,
                        };

                        onChange({
                          variables: newVariables,
                          selectedTemplateId: selectedTemplate?.id ?? "",
                          value: buildMessage({
                            selectedTemplate,
                            hasActiveTimeBasedCrews,
                            tenantName,
                            editedVariables: newVariables,
                            hardcodedVariables,
                          }),
                        });
                      },
                      contactMethodType: variableValues[
                        ContactMethodTypeSelection
                      ]
                        ? parseInt(variableValues[ContactMethodTypeSelection])
                        : null,
                    });

                    if (childElement === null) {
                      return null;
                    } else {
                      return (
                        <div
                          className="form-group col-12 col-lg-6"
                          key={child.key}
                        >
                          {childElement}
                        </div>
                      );
                    }
                  })}
                </div>
              );
            }
          })}
      </div>
      {selectedTemplate?.id ? (
        <>
          <TextareaAutosize
            data-testid="textMessage"
            aria-label="Text message"
            maxRows={10}
            className="form-control"
            value={messageText}
            disabled={true}
            maxLength={constants.customerNotificationMaximumMessageLength}
            required
          />
        </>
      ) : null}
    </>
  );
};

export default CustomerNotificationsTemplatedMessageBuilder;

export function getTemplateVariableDefaultValues(
  customerNotificationTemplateDefaults: ITemplatedMessageDefaults[],
  variableValues: TemplateVariableValues,
  selectedTemplate: ITemplatedMessage | undefined,
  contactPhoneNumber: string,
  contactEmailAddress: string
): TemplateVariableValues {
  const defaultVariables =
    selectedTemplate?.variables?.filter((v) => v.canDefault) ?? [];

  const templateDefaults = customerNotificationTemplateDefaults?.filter(
    (d) => d.id === selectedTemplate?.id
  )?.[0]?.variableDefaults;

  defaultVariables.forEach((dv) => {
    const defaultValue = templateDefaults?.[dv.key];
    if (dv.key === PhoneNumberPlaceholder) {
      variableValues = {
        ...variableValues,
        [dv.key]: defaultValue ?? contactPhoneNumber,
      };
    } else if (dv.key === EmailAddressPlaceholder) {
      variableValues = {
        ...variableValues,
        [dv.key]: defaultValue ?? contactEmailAddress,
      };
    } else {
      variableValues = { ...variableValues, [dv.key]: defaultValue ?? "" };
    }
  });
  return variableValues;
}

function getVariableElement({
  variable,
  value,
  onChange,
  contactMethodType,
}: {
  variable: ITemplatedMessageVariable;
  value: string;
  onChange(newValue: string): void;
  contactMethodType: ContactMethodType | null;
}): JSX.Element | null {
  switch (variable.inputType) {
    case "text":
    case "date":
    case "contactMethod":
      return (
        <div key={variable.key}>
          <label
            className={`${!variable.optional ? "required" : ""}`}
            htmlFor={variable.key}
          >
            {variable.label}
          </label>
          <VariableInput
            variable={variable}
            value={value}
            onChange={onChange}
          />
        </div>
      );
    case "phone":
      return contactMethodType === null ||
        contactMethodType === undefined ||
        contactMethodType === ContactMethodType.phone ? (
        <div key={variable.key}>
          <label
            className={`${!variable.optional ? "required" : ""}`}
            htmlFor={variable.key}
          >
            {variable.label}
          </label>
          <PhoneNumberField
            required={!variable.optional}
            id={variable.key}
            value={value}
            className="form-control"
            onChange={(e) => {
              onChange(e.currentTarget.value);
            }}
          />
        </div>
      ) : null;
    case "email":
      return contactMethodType === ContactMethodType.email ? (
        <div key={variable.key}>
          <label
            className={`${!variable.optional ? "required" : ""}`}
            htmlFor={variable.key}
          >
            {variable.label}
          </label>
          <Input
            type="email"
            required={!variable.optional}
            id={variable.key}
            value={value}
            className="form-control"
            onChange={(e) => {
              onChange(e.currentTarget.value);
            }}
          />
        </div>
      ) : null;
    case "checkBox":
      return (
        <div key={variable.key}>
          <div
            className="custom-control custom-checkbox"
            style={{ display: "inline-block" }}
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id={variable.key}
              value={value}
              checked={value ? value === "true" : true}
              onChange={(e) => {
                onChange(e.currentTarget.checked.toString());
              }}
            />
            <label className="custom-control-label" htmlFor={variable.key}>
              {variable.label}
            </label>
          </div>
        </div>
      );
    case calculatedContactMethodInputType:
      return null;
    case calculatedShowDayOfServiceInputType:
      return null;
  }
}

function VariableInput({
  variable,
  value,
  onChange,
}: {
  variable: ITemplatedMessageVariable;
  value: string;
  onChange(newValue: string): void;
}): JSX.Element {
  switch (variable.inputType) {
    case "text":
      return (
        <TextareaAutosize
          maxRows={10}
          required={!variable.optional}
          className="form-control"
          maxLength={200}
          id={variable.key}
          value={value}
          onChange={(e) => {
            onChange(e.currentTarget.value);
          }}
        />
      );
    case "date":
      return (
        <DayPicker
          required={!variable.optional}
          onDayPickerHide={() => null}
          dayPickerProps={{}}
          inputId={variable.key}
          value={!!value ? format(value, dayPickerFormat) : ""}
          onDaySelected={(day: Date) => {
            onChange(day ? dateService.formatAsIso(day) : "");
          }}
        />
      );
    case "contactMethod":
      return (
        <select
          id={variable.key}
          className="form-control"
          value={value}
          onChange={(e) => {
            onChange(e.currentTarget.value);
          }}
        >
          <option value={ContactMethodType.phone}>Phone</option>
          <option value={ContactMethodType.email}>Email</option>
        </select>
      );
    default:
      return <></>;
  }
}
