import { catchError, mergeMap, retry, timeout } from "rxjs/operators";
import { SetUserSettingActionType } from "../userSettings";
import remoteDataProvider from "../../services/remoteDataProvider";
import { actionCreators } from "../actionCreators";
import { of } from "rxjs";

export default function saveUserSettings(action: SetUserSettingActionType) {
  return remoteDataProvider
    .saveUserSettings([
      {
        type: action.payload.type,
        value: JSON.stringify(action.payload.value),
      },
    ])
    .pipe(
      timeout(10000),
      retry(2),
      catchError(() => of(actionCreators.null())),
      mergeMap(() => of(actionCreators.null()))
    );
}
