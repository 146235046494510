import { DiscountType } from "../../../enums/DiscountType";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { IDepositItem } from "../../../models/IDepositItem";
import { IDiscount } from "../../../models/IDiscount";
import { IInvoiceItem } from "../../../models/IInvoiceItem";
import { isTaxRateDisabled } from "../../../services/billableItemService";
import { formatCurrency } from "../../../services/currencyFormatter";
import { getSubtotal, getTotal } from "../../../services/lineItemService";
import TaxRateField from "../../../slices/billing/components/TaxRateField";
import AmountField from "../components/AmountField";
import { DepositInvoiceItem } from "../components/DepositInvoiceItem";
import InfoToolTip from "../components/InfoToolTip";
import { ILineItem } from "../components/InvoiceLineItem";
import PercentField from "../components/PercentField";

interface IDiscountFields {
  discount: IDiscount;
  onChange: (newDiscount: IDiscount) => void;
  amountOnly?: boolean;
}

interface IDepositAmountFields {
  maxDepositCreditAmount: number | null;
  depositCreditAmount: number | null;
  onDepositCreditAmountChange: (newAmount: number) => void;
  alwaysShowDepositCredits?: boolean;

  invoiceItems: Array<IInvoiceItem>;
  setInvoiceItems: React.Dispatch<React.SetStateAction<Array<IInvoiceItem>>>;
  depositInvoiceItem?: IDepositItem;
  onDepositInvoiceItemChange(newValue: IDepositItem): void;

  captureDepositItemOverride: boolean;
}

interface IProps {
  lineItems: Array<ILineItem>;
  discountFields?: IDiscountFields;

  taxRate: number | null;
  onTaxRateChange: (newValue: number | null) => void;

  alwaysShowTaxRate?: boolean;
  depositAmountFields?: IDepositAmountFields;
  customerTaxExempt: boolean;
  taxRateAlreadySet: boolean;
}

const InvoiceFormAmounts: React.FunctionComponent<IProps> = ({
  lineItems,
  taxRate,
  onTaxRateChange,
  discountFields,
  alwaysShowTaxRate,
  depositAmountFields,
  customerTaxExempt,
  taxRateAlreadySet,
}) => {
  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );

  const isDepositAmountAvailable =
    (depositAmountFields?.maxDepositCreditAmount ?? 0) > 0 ||
    (depositAmountFields?.depositCreditAmount ?? 0) > 0 ||
    depositAmountFields?.alwaysShowDepositCredits;

  const taxRateDisabled = isTaxRateDisabled({
    customerTaxExempt,
    taxRateAlreadySet,
  });

  const subtotal = getSubtotal(lineItems);
  return (
    <>
      <div className="form-row align-items-baseline">
        <div className="col-9 text-right">
          <label htmlFor="subtotal">Subtotal</label>
        </div>
        <div className="col-3 text-right" data-testid="subtotal">
          {formatCurrency(subtotal)}
        </div>
      </div>

      {discountFields ? (
        <div className="form-row form-group align-items-baseline">
          <div
            className={`${
              !discountFields.amountOnly ? "col-xl-7" : "col-xl-6"
            } col-lg-6 col-md-4`}
          ></div>
          <div
            className={`${
              !discountFields.amountOnly ? "col-xl-2" : "col-xl-3"
            } col-lg-3 col-md-5 text-md-right ${
              !discountFields.amountOnly ? "mb-3 mb-md-0" : "mb-2"
            }`}
          >
            {!discountFields.amountOnly ? (
              <select
                className="form-control"
                aria-label="Discount type"
                value={(discountFields.discount.type ?? 0).toString()}
                onChange={(e) => {
                  discountFields.onChange({
                    ...discountFields.discount,
                    type: parseInt(e.currentTarget.value) as DiscountType,
                  });
                }}
              >
                <option value={DiscountType.percent.toString()}>
                  Discount percent
                </option>
                <option value={DiscountType.amount.toString()}>
                  Discount amount
                </option>
              </select>
            ) : (
              <label htmlFor="discountAmount" className="mb-0">
                Discount amount
              </label>
            )}
          </div>
          <div className={`col-md-3`}>
            {discountFields.discount.type === DiscountType.amount ||
            discountFields.amountOnly ? (
              <AmountField
                id="discountAmount"
                ariaLabel="Discount amount"
                max={subtotal}
                addCreditFormat
                onChange={(e) =>
                  discountFields.onChange({
                    ...discountFields.discount,
                    amount: e ?? 0,
                  })
                }
                value={discountFields.discount.amount}
              />
            ) : (
              <PercentField
                ariaLabel="Discount percent"
                id="discount"
                value={discountFields.discount.percent}
                onChange={(newValue) =>
                  discountFields.onChange({
                    ...discountFields.discount,
                    percent: newValue,
                  })
                }
                decimalPlacesAllowed={0}
              />
            )}
          </div>
        </div>
      ) : null}

      {!isQuickBooksEnabled || alwaysShowTaxRate ? (
        <>
          <div className="form-row align-items-baseline">
            <div className="col-md-9 text-left text-md-right">
              <label htmlFor="taxRate">
                Tax rate
                {taxRateDisabled ? (
                  <InfoToolTip
                    text="Customer is tax-exempt"
                    id={"customerTaxExempt"}
                  />
                ) : null}
              </label>
            </div>

            <TaxRateField
              taxRate={taxRate}
              onTaxRateChange={onTaxRateChange}
              className={"col-md-3"}
              taxRateDisabled={taxRateDisabled}
            />
          </div>

          <div className="form-row align-items-baseline">
            <div className="col-9 text-right">
              <label htmlFor="total">
                {isDepositAmountAvailable ? "Total after taxes" : "Total"}
              </label>
            </div>
            <div className="col-3 text-right" data-testid="total">
              {formatCurrency(
                getTotal({
                  taxRate,
                  lineItems,
                  discount: discountFields?.discount,
                })
              )}
            </div>
          </div>
        </>
      ) : null}
      {isDepositAmountAvailable ? (
        <>
          <div className="form-row align-items-baseline">
            <div className="col-md-9 text-left text-md-right">
              <label htmlFor="deposit">Prepaid deposit</label>
            </div>
            <div className="form-group col-md-3">
              <AmountField
                id="depositCreditAmount"
                ariaLabel="Deposit credit amount"
                addCreditFormat
                max={depositAmountFields?.maxDepositCreditAmount ?? null}
                onChange={(e) =>
                  depositAmountFields?.onDepositCreditAmountChange(e ?? 0)
                }
                value={depositAmountFields?.depositCreditAmount ?? 0}
              />
            </div>
          </div>

          {depositAmountFields?.invoiceItems &&
          depositAmountFields.depositInvoiceItem &&
          depositAmountFields?.captureDepositItemOverride ? (
            <div className="form-row align-items-baseline">
              <div className="col-md-9 text-left text-md-right">
                <label htmlFor="invoiceDepositItemId" className="required">
                  Deposit item
                </label>
                <InfoToolTip
                  id="invoiceDepositItemToolTip"
                  text="The product/service line item used for the deposit credit."
                />
              </div>
              <div className="form-group col-md-3">
                <DepositInvoiceItem
                  inputElementId="invoiceDepositItemId"
                  invoiceItems={depositAmountFields.invoiceItems}
                  setInvoiceItems={depositAmountFields.setInvoiceItems}
                  value={depositAmountFields.depositInvoiceItem}
                  onChange={(newDepositItem) =>
                    depositAmountFields.onDepositInvoiceItemChange(
                      newDepositItem
                    )
                  }
                />
              </div>
            </div>
          ) : null}

          {!isQuickBooksEnabled || alwaysShowTaxRate ? (
            <div className="form-row align-items-baseline">
              <div className="col-9 text-right">
                <label htmlFor="balance">Balance due</label>
              </div>
              <div className="col-3 text-right" data-testid="balance">
                {formatCurrency(
                  getTotal(
                    {
                      taxRate,
                      lineItems,
                      discount: discountFields?.discount,
                      depositCreditAmount:
                        depositAmountFields?.depositCreditAmount,
                    } ?? 0
                  )
                )}
              </div>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default InvoiceFormAmounts;
