import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import IPhoto from "../../../models/IPhoto";
import { getFileName } from "../../../services/fileService";

interface IProps {
  nonImageFiles: Array<IPhoto>;
  id: string;
}

const CardNonImageFiles: React.FunctionComponent<IProps> = ({
  nonImageFiles,
  id,
}) => {
  const imagePrefix = useApplicationStateSelector((s) => s.common.imagePrefix);
  const [showFiles, setShowFiles] = useState(false);

  const fileIndicatorId = `nonimages_${id}`;
  return nonImageFiles.length > 0 ? (
    <>
      <div>
        <FontAwesomeIcon
          icon={faPaperclip}
          size="lg"
          className={`board-card-indicators-icon`}
          id={fileIndicatorId}
          onClick={(e) => {
            e.stopPropagation();
            setShowFiles(!showFiles);
          }}
          style={{
            cursor: "pointer",
          }}
          title="Files"
        />
        <Popover
          delay={0}
          trigger="legacy"
          placement="bottom"
          isOpen={showFiles && nonImageFiles.length > 0}
          target={fileIndicatorId}
          toggle={() => {
            setShowFiles(!showFiles);
          }}
        >
          <PopoverBody>
            <>
              {nonImageFiles.map((p, videoIndex) => {
                return (
                  <div key={p.id}>
                    <a
                      href={`${imagePrefix}/${p.imagePath}`}
                      onClick={(e) => {
                        // Stop propagation so card isn't selected
                        e.stopPropagation();
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {getFileName(p.imagePath)}
                      {p.caption ? ` (${p.caption})` : ""}
                    </a>
                  </div>
                );
              })}
            </>
          </PopoverBody>
        </Popover>
      </div>
    </>
  ) : null;
};

export default CardNonImageFiles;
