import ErrorJumbotron from "../components/ErrorJumbotron";
import PageWithNavBar from "../PageWithNavBar";

const ErrorLoadingInitialData = () => {
  return (
    <ErrorJumbotron
      showRefreshButton
      details={
        <>
          There was an error connecting to the website. Please check your
          internet connection and try again. <br />
          If you continue to have problems, email support at{" "}
          <a href="mailto:crewcontrol@youraspire.com">
            crewcontrol@youraspire.com
          </a>
          .
        </>
      }
    />
  );
};

export default PageWithNavBar(ErrorLoadingInitialData);
