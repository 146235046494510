import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "../../../store";
import remoteDataProvider, {
  buildUrl,
} from "../../../services/remoteDataProvider";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";

import connectToQuickbooksDefaultImage from "../../../connect-to-quickbooks-default.png";
import connectToQuickbooksHoverImage from "../../../connect-to-quickbooks-hover.png";
import { actionCreators } from "../../../modules/actionCreators";
import {
  commonUiActionCreators,
  QuickBooksModalMode,
} from "../../../modules/commonUi";
import { mergeMap } from "rxjs/operators";
import Spinner from "./Spinner";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import constants from "../../../constants";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const QuickBooksConfiguration: React.FunctionComponent<{}> = () => {
  const showForm = useSelector<IRootState, boolean>(
    (state) => state.commonUi.showQuickBooksModal
  );
  const isQuickBooksEnabled = useSelector<IRootState, boolean>(
    (s) => s.common.isQuickBooksEnabled
  );
  const formMode = useSelector<IRootState, QuickBooksModalMode>(
    (s) => s.commonUi.quickBooksModalMode
  );
  const quickBooksModalExplanationText = useApplicationStateSelector(
    (s) => s.commonUi.quickBooksModalExplanationText
  );
  const dispatch = useDispatch();
  const [revokeComplete, setRevokeComplete] = useState<boolean>(false);

  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [hoverOverButton, setHoverOverButton] = useState(false);

  useEffect(() => {
    if (showForm) {
      setRevokeComplete(false);
    }
  }, [showForm]);

  let content: JSX.Element;
  if (formMode === QuickBooksModalMode.confirmation) {
    content = <div>QuickBooks was successfully configured!</div>;
  } else if (formMode === QuickBooksModalMode.error) {
    content = (
      <p>
        An error occurred setting up your QuickBooks connection. Please try
        again. If this continues to not work, please contact support.
      </p>
    );
  } else if (formMode === QuickBooksModalMode.reconnect) {
    content = (
      <React.Fragment>
        <p>Please refresh your QuickBooks connection!</p>
        {quickBooksModalExplanationText ? (
          <p data-testid="explanationText">{quickBooksModalExplanationText}</p>
        ) : null}
        <input
          type="image"
          src={connectToQuickbooksDefaultImage}
          alt="Connect to QuickBooks"
          onClick={() => {
            setSaving(true);
            window.sessionStorage.setItem(
              constants.quickBooksOnlineConnectedRedirect,
              window.location.pathname
            );

            remoteDataProvider
              .revokeQuickBooksAction()
              .pipe(
                mergeMap(() => {
                  return remoteDataProvider.setQuickBooksCookie();
                })
              )
              .subscribe({
                next: () => {
                  setSaving(false);
                  window.location.href = buildUrl(
                    "quickbookssignin/Authenticate"
                  );
                },

                error: (error) => {
                  setSaving(false);
                  setErrorMessage(getErrorMessageFromError(error));
                },
              });
          }}
        />
      </React.Fragment>
    );
  } else if (isQuickBooksEnabled) {
    content = (
      <React.Fragment>
        <p>You are currently setup with QuickBooks.</p>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => {
            setSaving(true);

            remoteDataProvider.revokeQuickBooksAction().subscribe({
              next: () => {
                setSaving(false);
                setRevokeComplete(true);
                dispatch(actionCreators.setQuickBooksNotEnabled());
              },
              error: (error) => {
                setSaving(false);
                setErrorMessage(getErrorMessageFromError(error));
              },
            });
          }}
        >
          Disconnect QuickBooks
        </button>
      </React.Fragment>
    );
  } else {
    let message: string;
    let additionalDetails: JSX.Element | null = null;
    if (revokeComplete) {
      message =
        "Your QuickBooks link has been disconnected. To reconnect it, click the image below.";
    } else {
      message =
        "Connect your QuickBooks online account to load customers and create invoices from Crew Control.";
      additionalDetails = (
        <>
          <div
            className="alert alert-info p-2 mt-2"
            data-testid="productsServicesAlert"
          >
            <div>
              <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
              <strong>Products &amp; Services Will Change!</strong>
            </div>
            <div className="mt-1">
              Any items you created in Crew Control will no longer be available.
              Your available products &amp; services will come directly from
              QuickBooks.
            </div>
            <div className="mt-1">
              If you've created line items on any jobs that haven't been
              completed or have setup contract billing, please review those jobs
              and contract billing records to update them to use your new
              products &amp; services from QuickBooks.
            </div>
          </div>
        </>
      );
    }

    content = (
      <React.Fragment>
        <p>{message}</p>
        {additionalDetails}
        <input
          type="image"
          src={
            hoverOverButton
              ? connectToQuickbooksHoverImage
              : connectToQuickbooksDefaultImage
          }
          onMouseOver={() => setHoverOverButton(true)}
          onMouseLeave={() => setHoverOverButton(false)}
          alt="Connect to QuickBooks"
          onClick={() => {
            setSaving(true);
            window.sessionStorage.setItem(
              constants.quickBooksOnlineConnectedRedirect,
              window.location.pathname
            );

            remoteDataProvider.setQuickBooksCookie().subscribe({
              next: () => {
                window.location.href = buildUrl(
                  "quickbookssignin/Authenticate"
                );
              },
              error: (error) => {
                setSaving(false);
                setErrorMessage(getErrorMessageFromError(error));
              },
            });
          }}
        />
      </React.Fragment>
    );
  }

  return showForm ? (
    <Modal
      isOpen={showForm}
      toggle={() => dispatch(commonUiActionCreators.hideQuickBooksModal())}
    >
      <ModalHeader>QuickBooks Link</ModalHeader>
      <ModalBody>
        {saving ? <Spinner /> : null}
        {content}
        {errorMessage ? (
          <div className="text-danger">{errorMessage}</div>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-secondary"
          type="button"
          onClick={() => dispatch(commonUiActionCreators.hideQuickBooksModal())}
        >
          Close
        </button>
      </ModalFooter>
    </Modal>
  ) : null;
};

export default QuickBooksConfiguration;
