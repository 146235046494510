import { useEffect, useRef } from "react";
import { useGetAddressFields } from "../../../../hooks/useGetAddressFields";
import { logError } from "../../../../services/errorLogger";
import {
  getAddressComponentValue,
  hasGoogleMapsAutocompleteLoaded,
} from "../../../../services/googleService";
import { IAddress } from "./IOnboardingFormData";

const windowAny = window as any;

interface IProps {
  idPrefix: string;
  address: IAddress;
  onChange(newAddress: IAddress): void;
  addressLabel?: string;
  invalidAddressRegEx?: RegExp;
  invalidAddressMessage?: string;
}

const AddressFields: React.FunctionComponent<IProps> = ({
  idPrefix,
  address,
  onChange,
  addressLabel,
  invalidAddressRegEx,
  invalidAddressMessage,
}) => {
  const autocomplete = useRef<any>(null);
  const autocompleteListener = useRef<any>(null);
  const addressRef = useRef<HTMLInputElement>(null);

  const addressFields = useGetAddressFields();

  useEffect(() => {
    if (addressRef.current && !autocomplete.current) {
      if (hasGoogleMapsAutocompleteLoaded()) {
        autocomplete.current = new windowAny.google.maps.places.Autocomplete(
          addressRef.current
        );
        autocomplete.current.setFields([
          "address_components",
          "formatted_address",
          "geometry",
          "place_id",
        ]);

        autocompleteListener.current = autocomplete.current.addListener(
          "place_changed",
          () => {
            let place = autocomplete.current.getPlace();
            if (place) {
              onChange({
                city: getAddressComponentValue(place, "locality"),
                state: getAddressComponentValue(
                  place,
                  "administrative_area_level_1"
                ),
                zip: getAddressComponentValue(place, "postal_code"),
                address: `${getAddressComponentValue(
                  place,
                  "street_number"
                )} ${getAddressComponentValue(place, "route")}`.trim(),
                address2: address.address2,
              });
            }
          }
        );
      } else {
        logError("AddressFields - google maps not loaded");
      }
    }
  });

  return (
    <>
      <div className="form-row">
        <div className="col form-group">
          <label htmlFor={`${idPrefix}address`} className="required">
            {addressLabel ?? "Address"}
          </label>
          <input
            maxLength={100}
            type="text"
            className="form-control"
            id={`${idPrefix}address`}
            data-testid={`${idPrefix}address`}
            required
            value={address.address}
            onChange={(e) => {
              if (invalidAddressRegEx) {
                var isAddressInvalid = invalidAddressRegEx.test(
                  e.currentTarget.value
                );

                addressRef.current?.setCustomValidity(
                  isAddressInvalid
                    ? invalidAddressMessage ?? "The address is invalid."
                    : ""
                );
              }

              onChange({
                ...address,
                address: e.currentTarget.value,
              });
            }}
            ref={addressRef}
          />
        </div>
        <div className="col form-group">
          <label htmlFor={`${idPrefix}address2`}>Address 2 (optional)</label>
          <input
            maxLength={100}
            type="text"
            className="form-control"
            id={`${idPrefix}address2`}
            value={address.address2}
            onChange={(e) =>
              onChange({
                ...address,
                address2: e.currentTarget.value,
              })
            }
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col-6 form-group">
          <label htmlFor={`${idPrefix}city`} className="required">
            {addressFields.localityLabel}
          </label>
          <input
            maxLength={100}
            type="text"
            className="form-control"
            id={`${idPrefix}city`}
            required
            value={address.city}
            onChange={(e) =>
              onChange({
                ...address,
                city: e.currentTarget.value,
              })
            }
          />
        </div>
        <div className="col-3 form-group">
          <label htmlFor={`${idPrefix}state`} className="required">
            {addressFields.administrativeAreaLabel}
          </label>
          <select
            className="form-control"
            id={`${idPrefix}state`}
            value={address.state}
            required
            onChange={(e) =>
              onChange({
                ...address,
                state: e.currentTarget.value,
              })
            }
          >
            <option value=""></option>
            {addressFields.administrativeAreas.map((s) => (
              <option value={s.abbreviation} key={s.abbreviation}>
                {s.name}
              </option>
            ))}
          </select>
        </div>
        <div className="col-3 form-group">
          <label htmlFor={`${idPrefix}zip`} className="required">
            {addressFields.postCodeLabel}
          </label>
          <input
            maxLength={5}
            type="text"
            className="form-control"
            id={`${idPrefix}zip`}
            required
            value={address.zip}
            onChange={(e) =>
              onChange({
                ...address,
                zip: e.currentTarget.value,
              })
            }
          />
        </div>
      </div>
    </>
  );
};

export default AddressFields;
