import { SortDirection } from "../../../../enums/sortDirection";
import { IBillingReportCustomer } from "../../../../models/IBillingReport";

export interface ICustomerWithLocationName extends IBillingReportCustomer {
  locationName: string;
}

export interface IDateRange {
  start: string;
  end: string;
}

export interface ISort {
  property: SortProperty;
  direction: SortDirection;
}

export enum SortProperty {
  CustomerName,
  TotalVariance,
  ManHoursWorked,
  GrossRevenue,
  RevenuePerManHour,
  VisitCount,
}
