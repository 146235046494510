import React from "react";
import { IOpportunity } from "../../../../slices/sales/models/IOpportunity";
import { IProposalExisting } from "../../../../slices/sales/models/IProposal";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import DropDownSubMenu from "../../components/DropDownSubMenu";
import OpportunityActions from "../../../../slices/sales/components/OpportunityActions";
import { ICustomer } from "../../../../models/ICustomer";

interface IProps {
  opportunity: IOpportunity;
  proposal: IProposalExisting | null;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  setIsUnarchiving: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  customer: ICustomer;
  isProposalLane: boolean;
  rightAlign?: boolean;
}

export default function CustomerOpportunitiesActions({
  opportunity,
  proposal,
  setIsDeleting,
  setIsUnarchiving,
  setErrorMessage,
  customer,
  isProposalLane,
  rightAlign,
}: IProps) {
  return (
    <div className={rightAlign ? "text-right" : undefined}>
      <OpportunityActions
        opportunity={opportunity}
        proposal={proposal}
        setIsDeleting={setIsDeleting}
        setIsUnarchiving={setIsUnarchiving}
        setErrorMessage={setErrorMessage}
        customer={customer}
        isProposalLane={isProposalLane}
        renderDropDownContainer={({
          children,
          isContextMenuOpen,
          setIsContextMenuOpen,
        }) => {
          return (
            <ButtonDropdown
              direction="down"
              isOpen={isContextMenuOpen}
              toggle={() => setIsContextMenuOpen(!isContextMenuOpen)}
              size="sm"
            >
              <button
                className="btn btn-secondary btn-sm"
                onClick={() => setIsContextMenuOpen(!isContextMenuOpen)}
              >
                Actions
              </button>
              <DropdownToggle split color="secondary" size="sm" />
              <DropdownMenu positionFixed={true} right={true}>
                {children({})}
              </DropdownMenu>
            </ButtonDropdown>
          );
        }}
        renderDropDownItem={({ text, onClick }) => (
          <DropdownItem onClick={onClick}>{text}</DropdownItem>
        )}
        renderSubMenuParent={({
          text,
          children,
          showChildren,
          setShowChildren,
        }) => (
          <DropDownSubMenu
            text={text}
            showChildren={showChildren}
            setShowChildren={setShowChildren}
          >
            {children}
          </DropDownSubMenu>
        )}
        renderLink={({ text, url, target, rel }) => (
          <a
            href={url}
            target={target}
            rel={rel}
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ textDecoration: "none" }}
          >
            <div className="dropdown-item">{text}</div>
          </a>
        )}
      />
    </div>
  );
}
