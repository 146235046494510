import { ajax } from "rxjs/ajax";
import {
  buildUrl,
  executeWithHeaders,
} from "../../../services/remoteDataProvider";
import { map } from "rxjs/operators";

type TollFreeVerificationInformation = {
  streetAddress: string;
  streetAddress2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  contactFirstName: string;
  contactLastName: string;
  contactEmailAddress: string;
  contactPhoneNumber: string;
  businessName: string;
  businessWebsite: string;
};

const onboardingDataProvider = {
  provisionCustomerTextPhoneNumber: (
    payload: TollFreeVerificationInformation
  ) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl(`Tenant/ProvisionCustomerTextPhoneNumber`),
        payload,
        headers
      )
    );
  },

  getTollFreeVerificationInformation: () => {
    return executeWithHeaders((headers) =>
      ajax
        .get(buildUrl(`Tenant/TollFreeVerificationInformation`), headers)
        .pipe(
          map(({ response }) => response as TollFreeVerificationInformation)
        )
    );
  },

  publishExampleSchedule: (payload: { phoneNumber: string }) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl(`DaySchedule/publishExampleSchedule`),
        payload,
        headers
      )
    );
  },
};

export default onboardingDataProvider;
