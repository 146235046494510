import { push } from "connected-react-router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import dateService from "../../../services/dateService";
import { useUserSettings } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import { getSelectedDateStartOfWeek } from "../services/scheduleWeekService";

export function useRedirectToStickyCrew({
  params,
  defaultCrewUserSettings,
  getRoute,
}: {
  params: { crewId: string; date: string };
  defaultCrewUserSettings: UserSettingsType;
  getRoute: (date: string, crewId: string) => string;
}) {
  const dispatch = useDispatch();
  const { getUserSettings } = useUserSettings();

  // If crew not specified in route but is sticky, redirect user to have crew
  // in route.  This will ensure other areas (like job forms) can default in the
  // current crew.
  useEffect(() => {
    if (!params.crewId) {
      const defaultCrewId = getUserSettings<string>(defaultCrewUserSettings);

      if (defaultCrewId) {
        dispatch(
          push(
            getRoute(
              dateService.formatAsIso(getSelectedDateStartOfWeek(params)),
              defaultCrewId
            )
          )
        );
      }
    }
  }, [dispatch, params, defaultCrewUserSettings, getRoute, getUserSettings]);
}
