import { useState } from "react";
import ButtonDropdown from "reactstrap/lib/ButtonDropdown";
import DropdownItem from "reactstrap/lib/DropdownItem";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownToggle from "reactstrap/lib/DropdownToggle";

interface IProps {
  disabled: boolean;
  onClick(personalizationValue: string): void;
  hideAddressOption?: boolean;
  buttonColor?: "secondary" | "light";
}

const PersonalizeButton: React.FunctionComponent<IProps> = ({
  disabled,
  onClick,
  hideAddressOption,
  buttonColor = "secondary",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const personalizationOptions = ["Customer name"];
  if (!hideAddressOption) {
    personalizationOptions.push("Customer address");
  }

  return (
    <ButtonDropdown
      direction="down"
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      disabled={disabled}
    >
      <button
        className={`btn btn-${buttonColor} btn-sm`}
        type="button"
        disabled={disabled}
        onClick={() => setIsOpen(true)}
        data-testid="personalize"
      >
        Personalize
      </button>
      <DropdownToggle split size="sm" disabled={disabled} color={buttonColor} />
      <DropdownMenu positionFixed={true} right={true}>
        {personalizationOptions.map((o) => (
          <DropdownItem
            key={o}
            onClick={(e) => {
              e.preventDefault();
              onClick(`<${o}>`);
            }}
          >
            &lt;{o}&gt;
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default PersonalizeButton;
