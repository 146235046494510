import uuidv4 from "uuid/v4";
import { IOnboardingFormData, IPrincipal } from "./IOnboardingFormData";

export function getEmptyFormData(
  defaults: Partial<IOnboardingFormData>
): IOnboardingFormData {
  const defaultPrincipalId = uuidv4();
  return {
    address: {
      address: "",
      address2: "",
      state: "",
      city: "",
      zip: "",
    },
    annualProcessingVolume: "",
    averageTransactionAmount: "",
    businessEmail: "",
    businessType: null,
    customerServicePhone: "",
    daytimePhone: "",
    dba: "",
    ein: "",
    einType: "",
    irsFilingName: "",
    secondaryPayoutDescriptor: "",
    website: "",
    businessDescription: "",
    publicCompany: false,
    irsNotForProfitLetterStatus: null,
    irsNotForProfitLetterIssuedDate: "",
    industry: "",

    primaryContactPrincipalId: defaultPrincipalId,
    principals: [getEmptyPrincipal(defaultPrincipalId)],

    bankAccountNumber: "",
    bankAccountNumberConfirmed: "",
    bankAccountType: "",
    bankRoutingNumber: "",

    agreeTermsAndConditions: false,
    attestInformationCorrect: false,
    paymentsPortalUsername: "",
    paymentsPortalPassword: "",
    paymentsPortalPasswordConfirmed: "",
    ...defaults,
  };
}

export function getEmptyPrincipal(suppliedId?: string): IPrincipal {
  const id = suppliedId ?? uuidv4();
  return {
    id,
    internalId: id,
    address: {
      address: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
    },
    businessTitle: "",
    dob: "",
    firstName: "",
    lastName: "",
    middleName: "",
    ownershipPercent: "",
    significantResponsibility: false,
    politicallyExposed: false,
    ssn: "",
    email: "",
    phone: "",
  };
}
