import { useDispatch } from "react-redux";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { ICustomerAdditionalLocation } from "../../../../models/ICustomerAdditionalLocation";
import addressFormatter from "../../../../services/addressFormatter";
import { getSortedItems } from "../../../../services/sortingService";
import CustomersChildEntityListing from "./CustomersChildEntityListing";
import { actionCreators } from "../../../../modules/actionCreators";
import { UserSettingsType } from "../../../../enums/userSettingsType";
import { IAction } from "../../../../modules/actionTypeDefinitions";
import { promptActionCreators } from "../../../../modules/prompt";
import { useState } from "react";
import CustomerLocationHelper from "../../../../services/customerLocationHelper";
import { NoteWithModal } from "../../components/NoteWithModal";
import constants from "../../../../constants";

interface IProps {
  customerId: string;
}

interface ILocationFilters {
  searchText: string | null;
}

enum AdditionalLocationsSortColumns {
  name,
  address,
  inactive,
  administratorOnlyNotes,
  notesForCrew,
}

const CustomerDetailsLocations: React.FunctionComponent<IProps> = ({
  customerId,
}) => {
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );
  const [locationFilters, setLocationFilters] = useState<ILocationFilters>({
    searchText: null,
  });
  const dispatch = useDispatch();

  const notesInlineStringLength = 50;
  const notesMaxLines = 3;
  return (
    <CustomersChildEntityListing<
      ICustomerAdditionalLocation,
      AdditionalLocationsSortColumns
    >
      items={getSortedItems(
        customerAdditionalLocations.filter(
          (l) =>
            l.customerId === customerId &&
            (!locationFilters.searchText ||
              CustomerLocationHelper.isMatchingItem(
                l,
                locationFilters.searchText
              ))
        ),
        "name"
      )}
      addButtons={[
        {
          label: "Add additional location",
          className: "text-nowrap",
          onClick: () =>
            dispatch(
              actionCreators.forms.customerAdditionalLocation.showForm({
                customerId: customerId,
              })
            ),
        },
      ]}
      filters={
        <>
          {customerAdditionalLocations.some(
            (l) => l.customerId === customerId
          ) ? (
            <div className="d-block d-lg-flex align-items-baseline">
              <div className="mt-2 mt-lg-0">
                <div>
                  <input
                    id="locationSearch"
                    data-testid="locationSearch"
                    className="form-control"
                    type="text"
                    aria-label="Search"
                    placeholder="Search"
                    style={{ maxWidth: constants.listFilterMaxWidth }}
                    onChange={(e) => {
                      const value = e.currentTarget.value;
                      setLocationFilters({ searchText: value });
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </>
      }
      noItemsMessage="No additional locations"
      onEditClick={(l) =>
        dispatch(
          actionCreators.forms.customerAdditionalLocation.showForm({
            customerAdditionalLocationId: l.id,
          })
        )
      }
      onDeleteClick={(l) =>
        handleDeleteAdditionalLocationClicked(l.id, l.customerId, dispatch)
      }
      columns={[
        {
          header: "Name",
          cell: ({ row: l }) => l.name || "",
          sortable: true,
          sortColumn: AdditionalLocationsSortColumns.name,
          testId: "name",
          key: "name",
        },
        {
          header: "Address",
          cell: ({ row: l }) => addressFormatter.formatAddressEntity(l),
          sortable: true,
          sortColumn: AdditionalLocationsSortColumns.address,
          testId: "address",
          key: "address",
        },
        {
          header: "Administrator notes",
          cell: ({ row: l }) => (
            <NoteWithModal
              text={l.administratorOnlyNotes ?? ""}
              inlineStringLength={notesInlineStringLength}
              maxLines={notesMaxLines}
            />
          ),
          getSortableValue: (l) => l.administratorOnlyNotes,
          sortable: true,
          sortColumn: AdditionalLocationsSortColumns.administratorOnlyNotes,
          testId: "administratorOnlyNotes",
          key: "administratorOnlyNotes",
        },
        {
          header: "Crew notes",
          cell: ({ row: l }) => (
            <NoteWithModal
              text={l.notesForCrew ?? ""}
              inlineStringLength={notesInlineStringLength}
              maxLines={notesMaxLines}
            />
          ),
          getSortableValue: (l) => l.notesForCrew,
          sortable: true,
          sortColumn: AdditionalLocationsSortColumns.notesForCrew,
          testId: "notesForCrew",
          key: "notesForCrew",
        },
        {
          header: "Inactive",
          cell: ({ row: l }) => (l.inactive ? "Yes" : "No"),
          sortable: true,
          sortColumn: AdditionalLocationsSortColumns.inactive,
          key: "inactive",
        },
      ]}
      sortColumnSettingsKey={UserSettingsType.customerLocationsSortColumn}
    />
  );
};

function handleDeleteAdditionalLocationClicked(
  customerAdditionalLocationId: string,
  customerId: string,
  dispatch: (action: IAction) => void
) {
  dispatch(
    promptActionCreators.showPrompt({
      promptMessage: `Are you sure you want to delete this additional location?`,
      promptSaveText: "Delete",
      promptCancelText: null,
      confirmationAction: actionCreators.customerAdditionalLocationDeleteStart(
        customerAdditionalLocationId,
        customerId
      ),
      promptSubMessage: null,
      promptSubMessageClassName: undefined,
    })
  );
}

export default CustomerDetailsLocations;
