import { trackInformation, trackTrace } from "./applicationInsights";
import { fullStoryLogError, fullStoryLogInfo } from "./fullStoryService";

export function logError(error: string) {
  fullStoryLogError(error);
  trackTrace(error);
  console.log(error);
}

export function logInformation(message: string) {
  fullStoryLogInfo(message);
  trackInformation(message);
  console.log(message);
}
