import { RefObject, useEffect, useRef } from "react";
import InfoToolTip from "./InfoToolTip";

export function ManHours({
  label,
  value,
  onChange,
  idPrefix,
}: {
  label: string;
  value: string;
  onChange(newValue: string): void;
  idPrefix?: string;
}) {
  const inputId = `${idPrefix ?? ""}EstimatedManHours`;
  const tooltipId = `${inputId}ToolTip`;

  const inputRef = useRef<HTMLInputElement>(null);

  useSetInitialCustomValidity(inputRef, value);

  return (
    <>
      <div className="form-group">
        <label htmlFor={inputId}>{label}</label>
        <InfoToolTip
          id={tooltipId}
          text="The total time it would take 1 person to do the job"
        />
        <input
          type="text"
          className="form-control"
          id={inputId}
          value={value}
          ref={inputRef}
          onChange={(e) => {
            const value = e.currentTarget.value;
            setFieldValidity(value, inputRef);
            onChange(value);
          }}
        />
      </div>
    </>
  );
}

function useSetInitialCustomValidity(
  inputRef: RefObject<HTMLInputElement>,
  value: string
) {
  const hasInitializedValidity = useRef(false);
  useEffect(() => {
    if (!hasInitializedValidity.current && inputRef.current) {
      setFieldValidity(value, inputRef);
      hasInitializedValidity.current = true;
    }
  }, [value, inputRef]);
}

function setFieldValidity(
  value: string,
  inputRef: RefObject<HTMLInputElement>
) {
  if (!inputRef.current) {
    return;
  }

  const isValidNumber =
    value.match(/^[0-9]*\.{0,1}[0-9]*$/) && value.trim() !== ".";

  if (!isValidNumber) {
    inputRef.current.setCustomValidity("Please enter a valid number");
    return;
  }

  const parsedNumber = parseFloat(value);
  const maxValue = 1000000;
  if (!isNaN(parsedNumber) && parsedNumber >= maxValue) {
    inputRef.current.setCustomValidity(
      `Please enter a number less than ${maxValue.toLocaleString()}`
    );
    return;
  }

  inputRef.current.setCustomValidity("");
}
