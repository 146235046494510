export enum UserSettingsType {
  jobHistoryFilters = 0,
  weekScheduleCrewLayout = 1,
  weekDefaultCrew = 2,
  payrollReportStartingDate = 3,
  payrollReportEndingDate = 4,
  payrollReportBreakTimeMinutes = 5,
  payrollReportBreakThresholdHours = 6,
  crewMemberPayrollHistoryFilters = 7,
  workNotInvoicedFilters = 8,
  openInvoiceFilters = 9,
  paidInvoiceFilters = 10,
  monthDefaultCrews = 11,
  paymentsConvenienceFeePercentEnabled = 12,
  customerHistorySortColumn = 13,
  customerJobsSortColumn = 14,
  customerLocationsSortColumn = 15,
  quickBooksOnlineASTPromptDismissed = 16,
  billingHistoryFilters = 17,
  taxRate = 18,
  customerOpportunitiesSortColumn = 19,
  paymentsWebinarAlertDismissed = 20,
  installPromptDismissed = 21,
  proposalDepositItemId = 22,
  completedWorkFilters = 23,
  completedWorkColumns = 24,
  unscheduledJobsSorting = 25,
  paidInvoiceTotalAmountWarning = 26,
  replyToEmailAddress = 27,
  printScheduleShowCustomerPhoneNumber = 28,
  transactionProcessingTimelinesExpansion = 29,
  taxableTooltipShown = 30,
  timeBasedWeekScheduleCrewLayout = 31,
  timeBasedWeekDefaultCrew = 32,
  jobArrivalWindowDurationMinutes = 33,
  initialDispatchPromptClosed = 34,
  defaultCrewScheduleType = 35,
  salesPageAssignedToFilter = 36,
  allowCreditCardPayment = 37,
  allowAchPayment = 38,
  invoiceDeliveryMethod = 39,
  completedWorkSort = 40,
  seasonalScheduleStart = 41,
  seasonalScheduleEnd = 42,
  tollFreeVerificationAlertDismissed = 43,
  billingGetStartedPromptDismissed = 44,
}
