import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { SortDirection } from "../../../../enums/sortDirection";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { useGetAddressFields } from "../../../../hooks/useGetAddressFields";
import { ICrewCategory } from "../../../../models/ICrewCategory";
import { IUnscheduleJobsToShow } from "../../../../models/IUnscheduleJobsToShow";
import { actionCreators } from "../../../../modules/actionCreators";
import addressFormatter from "../../../../services/addressFormatter";
import { getCategoryIdentifiersForDisplay } from "../../../../services/crewCategoryService";
import { getNormalizedPhoneNumber } from "../../../../services/phoneNumberService";
import { builders } from "../../../../services/routing";
import { preventClearClass } from "../../../../services/selectedCardService";
import SortColumn from "../SortColumn";
import { SortColumns } from "./UnscheduledJobs.models";

export default function UnscheduledJobsGrid({
  elementsToShow,
  categories,
  gridSort,
  onGridSortChange,
  dragAndDropPlaceholder,
}: {
  elementsToShow: Array<IUnscheduleJobsToShow & { originalIndex: number }>;
  categories: Array<ICrewCategory>;
  gridSort: {
    column: SortColumns;
    direction: SortDirection;
  };
  onGridSortChange(newValue: {
    column: SortColumns;
    direction: SortDirection;
  }): void;
  dragAndDropPlaceholder?: React.ReactElement | null;
}) {
  const selectedJobInstanceIds = useApplicationStateSelector(
    (s) => s.scheduleUi.selectedJobInstanceIds
  );
  const dispatch = useDispatch();

  const allJobInstanceIds = elementsToShow.map((e) => e.jobInstance.id);
  const allItemsChecked = allJobInstanceIds.every((jobInstanceId) =>
    selectedJobInstanceIds.includes(jobInstanceId)
  );

  const getSortColumn = (displayName: string, sortColumn: SortColumns) => (
    <SortColumn<SortColumns>
      displayName={displayName}
      columnSortProperty={sortColumn}
      currentSortProperty={gridSort.column}
      currentSortDirection={gridSort.direction}
      onSortDirectionChange={(newSortColumn, newSortDirection) => {
        onGridSortChange({
          column: newSortColumn,
          direction: newSortDirection,
        });
      }}
    />
  );

  const addressFields = useGetAddressFields();

  return (
    <>
      <div className="table-responsive mt-3" data-testid="unscheduledJobsGrid">
        <table className="table table-sm">
          <thead>
            <tr>
              <th
                scope="col"
                className="border-right"
                style={{ width: "25px" }}
              >
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className={`custom-control-input ${preventClearClass}`}
                    id="selectAllVisibleRows"
                    checked={allItemsChecked}
                    onChange={() => {
                      dispatch(
                        actionCreators.jobInstanceToggleSelected(
                          allJobInstanceIds,
                          !allItemsChecked
                        )
                      );
                    }}
                    data-testid="selectAllRows"
                  />
                  <label
                    className={`custom-control-label ${preventClearClass}`}
                    htmlFor="selectAllVisibleRows"
                  >
                    &nbsp;
                  </label>
                </div>
              </th>
              <th scope="col" className="pl-2">
                {getSortColumn("Customer name", SortColumns.CustomerName)}
              </th>
              <th scope="col">
                {getSortColumn("Street #", SortColumns.StreetNumber)}
              </th>
              <th scope="col">
                {getSortColumn("Street", SortColumns.StreetName)}
              </th>
              <th scope="col">{getSortColumn("City", SortColumns.City)}</th>
              <th scope="col">
                {getSortColumn(
                  addressFields.administrativeAreaLabel,
                  SortColumns.State
                )}
              </th>
              <th scope="col">
                {getSortColumn(addressFields.postCodeLabel, SortColumns.Zip)}
              </th>
              <th scope="col">
                {getSortColumn("Phone number", SortColumns.PhoneNumber)}
              </th>
              <th scope="col">
                {getSortColumn(
                  "Service provided",
                  SortColumns.ServicesProvided
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {elementsToShow.map((unscheduledJob) => (
              <GridRow
                key={unscheduledJob.jobInstance.id}
                unscheduledJob={unscheduledJob}
                categories={categories}
              />
            ))}
          </tbody>
        </table>
      </div>
      {dragAndDropPlaceholder}
    </>
  );
}

function GridRow({
  unscheduledJob,
  categories,
}: {
  unscheduledJob: IUnscheduleJobsToShow;
  categories: Array<ICrewCategory>;
}) {
  const { jobInstance, job } = unscheduledJob;
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );
  const selectedJobInstanceIds = useApplicationStateSelector(
    (s) => s.scheduleUi.selectedJobInstanceIds
  );
  const dispatch = useDispatch();

  const customer = customers.find((c) => c.id === job.customerId);
  const address = addressFormatter.getAddressForJob(
    job,
    customers,
    customerAdditionalLocations
  );

  const { number: addressNumber, street: addressStreet } =
    addressFormatter.parseStreetAndNumber(address.streetAndNumber);

  const phoneNumber = getNormalizedPhoneNumber(customer?.phoneNumber ?? "");

  return (
    <tr key={jobInstance.id}>
      <th scope="row" className="border-right">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className={`custom-control-input ${preventClearClass}`}
            id={`select${jobInstance.id}`}
            checked={selectedJobInstanceIds.includes(jobInstance.id)}
            onChange={(e) => {
              const newChecked = e.currentTarget.checked;
              dispatch(
                actionCreators.jobInstanceToggleSelected(
                  [jobInstance.id],
                  newChecked,
                  true
                )
              );
            }}
            data-testid="selectRow"
          />
          <label
            className={`custom-control-label ${preventClearClass}`}
            htmlFor={`select${jobInstance.id}`}
          >
            &nbsp;
          </label>
        </div>
      </th>
      <td className="pl-2" data-testid="name">
        <Link
          to={builders.manage.buildCustomerDetailsRoute({
            customerId: customer?.id ?? "",
          })}
        >
          {customer?.name ?? ""}
        </Link>
      </td>
      <td data-testid="addressNumber">{addressNumber}</td>
      <td data-testid="addressStreet">{addressStreet}</td>
      <td data-testid="addressCity">{address.city}</td>
      <td data-testid="addressState">{address.state}</td>
      <td data-testid="addressZip">{address.zip}</td>
      <td data-testid="phoneNumber">
        {phoneNumber ? <a href={"tel:" + phoneNumber}>{phoneNumber}</a> : null}
      </td>
      <td data-testid="categories">
        {getCategoryIdentifiersForDisplay(job.categories, categories)}
      </td>
    </tr>
  );
}
