import { useApplicationStateSelector } from "./useApplicationStateSelector";
import { useCreditCardsEnabled } from "./useCreditCardsEnabled";

export function useContractBillingEnabled() {
  const { areCreditCardsEnabled } = useCreditCardsEnabled();
  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );

  return areCreditCardsEnabled || isQuickBooksEnabled;
}
