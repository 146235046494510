import { push } from "connected-react-router";
import DayPicker, {
  format as weekPickerFormat,
} from "../../../containers/app/components/DayPicker";
import { isMobileOnly } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { ICrew } from "../../../models/ICrew";
import { getDistinctSortedCrewCategories } from "../../../services/crewCategoryService";
import dateService from "../../../services/dateService";
import { builders, emptyCategoryCode } from "../../../services/routing";
import { getSortedItemsV2 } from "../../../services/sortingService";
import {
  getSelectedCrewId,
  getSortedCrews,
} from "../services/scheduleDayService";
import TodayButton from "./TodayButton";
import dateFnsFormat from "date-fns/format";
import { CrewScheduleType } from "../enums/crewScheduleType";
import { useUserSettings } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";

export function ScheduleDayFilters({
  params,
  crewsForCurrentSchedule,
  getRoute,
  mode,
}: {
  params: { date: string; mapCrew: string; crewFilter: string };
  crewsForCurrentSchedule: Array<ICrew>;
  getRoute(
    date: string,
    mapCrew: string | undefined,
    crewFilter: string | undefined
  ): string;
  mode: CrewScheduleType;
}) {
  return isMobileOnly ? (
    <MobileFilter params={params} getRoute={getRoute} />
  ) : (
    <NonMobileFilter
      params={params}
      crewsForCurrentSchedule={crewsForCurrentSchedule}
      getRoute={getRoute}
      mode={mode}
    />
  );
}

function NonMobileFilter({
  params,
  crewsForCurrentSchedule,
  getRoute,
  mode,
}: {
  params: { date: string; mapCrew: string; crewFilter: string };
  crewsForCurrentSchedule: Array<ICrew>;
  getRoute(
    date: string,
    mapCrew: string | undefined,
    crewFilter: string | undefined
  ): string;
  mode: CrewScheduleType;
}) {
  const dispatch = useDispatch();
  const crewCategories = useApplicationStateSelector(
    (s) => s.common.crewCategories
  );
  const { setUserSettings } = useUserSettings();
  const sortedCrewCategories = getDistinctSortedCrewCategories(
    crewsForCurrentSchedule,
    crewCategories
  );
  const maxDateForWeekPicker = dateService.getMaximumDateForSchedule();

  const allCrews = useApplicationStateSelector((s) => s.crew.crews);
  const allCrewsActive = allCrews.filter((c) => !c.inactive);
  const hasBothCrewTypes =
    allCrewsActive.some((c) => c.scheduleType === CrewScheduleType.sequence) &&
    allCrewsActive.some((c) => c.scheduleType === CrewScheduleType.time);

  const activeCrews = crewsForCurrentSchedule.filter((c) => !c.inactive);

  return (
    <div className="d-flex flex-wrap" style={{ gap: "10px" }}>
      {hasBothCrewTypes && !params.mapCrew ? (
        <div>
          <select
            value={mode.toString()}
            data-testid="crewTypeFilter"
            onChange={(e) => {
              if (
                e.currentTarget.value === CrewScheduleType.sequence.toString()
              ) {
                setUserSettings(
                  UserSettingsType.defaultCrewScheduleType,
                  CrewScheduleType.sequence
                );
                dispatch(
                  push(builders.schedule.buildSequenceDayRoute(params.date))
                );
              } else if (
                e.currentTarget.value === CrewScheduleType.time.toString()
              ) {
                setUserSettings(
                  UserSettingsType.defaultCrewScheduleType,
                  CrewScheduleType.time
                );
                dispatch(
                  push(builders.schedule.buildTimeDayRoute(params.date))
                );
              }
            }}
            className="form-control"
            style={{ width: "auto" }}
          >
            <option value={CrewScheduleType.sequence.toString()}>
              Sequence Crews
            </option>
            <option value={CrewScheduleType.time.toString()}>
              Time-Based Crews
            </option>
          </select>
        </div>
      ) : null}
      {sortedCrewCategories.length > 0 && !params.mapCrew ? (
        <>
          <div>
            <select
              value={params.crewFilter}
              onChange={(e) => {
                dispatch(
                  push(
                    getRoute(params.date, params.mapCrew, e.currentTarget.value)
                  )
                );
              }}
              className="form-control"
              data-testid="categoryFilter"
              style={{ width: "auto" }}
            >
              <option value={emptyCategoryCode}>All Categories</option>
              {sortedCrewCategories.map((crewCategory) => (
                <option key={crewCategory.id} value={crewCategory.id}>
                  {crewCategory.name}
                </option>
              ))}
            </select>
          </div>
        </>
      ) : null}
      {params.mapCrew && activeCrews.length > 1 ? (
        <div>
          <select
            value={params.mapCrew}
            onChange={(e) => {
              dispatch(
                push(getRoute(params.date, e.currentTarget.value, undefined))
              );
            }}
            className="form-control"
            data-testid="crewSelect"
            style={{ width: "auto" }}
          >
            {getSortedItemsV2(activeCrews, ["name"]).map((crew) => (
              <option key={crew.id} value={crew.id}>
                {crew.name}
              </option>
            ))}
          </select>
        </div>
      ) : null}
      <div className="d-flex flex-nowrap">
        <DayPicker
          showIcon={true}
          inputId="weekScheduleWeekPicker"
          required={true}
          preventMobileView={true}
          inputStyle={{
            width: "135px",
          }}
          onDayPickerHide={() => {}}
          dayPickerProps={{}}
          value={dateFnsFormat(params.date, weekPickerFormat)}
          onDaySelected={(value) => {
            if (
              value &&
              dateService.removeTimeComponents(value) <=
                dateService.removeTimeComponents(maxDateForWeekPicker)
            ) {
              dispatch(
                push(
                  getRoute(
                    dateService.formatAsIso(value),
                    params.mapCrew,
                    params.crewFilter
                  )
                )
              );
            }
          }}
          maxDate={maxDateForWeekPicker}
        />
        <TodayButton
          link={getRoute(
            dateService.formatAsIso(new Date()),
            params.mapCrew,
            getSelectedCrewId(params, crewsForCurrentSchedule)
          )}
        />
      </div>
    </div>
  );
}

function MobileFilter({
  params,
  getRoute,
}: {
  params: { date: string; mapCrew: string; crewFilter: string };
  getRoute(
    date: string,
    mapCrew: string | undefined,
    crewFilter: string | undefined
  ): string;
}) {
  const dispatch = useDispatch();
  const crews = useApplicationStateSelector((s) => s.crew.crews);
  const sortedCrew = getSortedCrews(crews);

  const filteredCrews = sortedCrew.filter(
    (c) => !c.inactive || c.id === getSelectedCrewId(params, crews)
  );

  if (filteredCrews.length < 2) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginLeft: "15px",
      }}
    >
      <div>
        <select
          aria-label="Schedule crew"
          value={getSelectedCrewId(params, crews)}
          onChange={(e) => {
            const crew = crews.find((c) => c.id === e.currentTarget.value);
            const routeAction =
              crew?.scheduleType === CrewScheduleType.time
                ? builders.schedule.buildTimeDayRoute(
                    params.date,
                    params.mapCrew,
                    e.currentTarget.value
                  )
                : builders.schedule.buildSequenceDayRoute(
                    params.date,
                    params.mapCrew,
                    e.currentTarget.value
                  );

            dispatch(push(routeAction));
          }}
          className="form-control"
        >
          {filteredCrews.map((crew) => (
            <option key={crew.id} value={crew.id}>
              {crew.name}
            </option>
          ))}
        </select>
      </div>
      <div style={{ marginRight: "15px" }}>
        <TodayButton
          link={getRoute(
            dateService.formatAsIso(new Date()),
            undefined,
            getSelectedCrewId(params, crews)
          )}
        />
      </div>
    </div>
  );
}
