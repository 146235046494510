import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { TenantSubscriptionStatus } from "../../../models/IInitialLoad";
import { actionCreators } from "../../../modules/actionCreators";
import { InitialLoadState } from "../../../modules/common";
import { fullStoryTrack } from "../../../services/fullStoryService";
import LinkButton2 from "../../../containers/app/components/LinkButton2";
import { Modal } from "reactstrap/lib";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import SubscriptionFormPlanSelection from "./SubscriptionFormPlanSelection";
import { TenantPlan } from "../../../enums/tenantPlan";
import { SubscriptionFrequency } from "../../../enums/subscriptionFrequency";
import dateService from "../../../services/dateService";
import differenceInCalendarDays from "date-fns/difference_in_calendar_days";
import { ReactivateSubscription } from "./ReactivateSubscription";
import { isStringSet } from "../../../services/stringService";
import Spinner from "../../../containers/app/components/Spinner";
import subscriptionDataProvider from "../services/subscriptionDataProvider";
import { PortalPage } from "../enums/portalPage";
import { timeout } from "rxjs/operators";
import useIsAdmin from "../../../hooks/useIsAdmin";

interface IProps {
  containerClassName: string;
  containerStyle?: React.CSSProperties;
}

const TenantSubscriptionStatusAlert: React.FunctionComponent<IProps> = ({
  containerClassName,
  containerStyle,
}) => {
  const isRegistered = useApplicationStateSelector(
    (s) => s.common.isRegistered
  );
  const loadStatus = useApplicationStateSelector(
    (s) => s.common.initialLoadState
  );
  const subscriptionStatus = useApplicationStateSelector(
    (s) => s.common.tenantSubscriptionStatus
  );
  const trialDaysRemaining = useApplicationStateSelector(
    (s) => s.common.tenantTrialDaysRemaining
  );
  const subscriptionCancellationPending = useApplicationStateSelector(
    (s) => s.common.subscriptionCancellationPending
  );
  const subscriptionRenewalDate = useApplicationStateSelector(
    (s) => s.common.subscriptionRenewalDate
  );
  const subscriptionPaymentFailureUtc = useApplicationStateSelector(
    (s) => s.common.subscriptionPaymentFailureUtc
  );
  const isAdmin = useIsAdmin();

  const [showPricing, setShowPricing] = useState(false);
  const [showReactivateSubscription, setShowReactivateSubscription] =
    useState(false);

  let message: string = "";
  let showDangerText = false;
  let actionElement: React.ReactNode | null = null;
  let secondLineElement: React.ReactNode | null = null;
  if (subscriptionStatus === TenantSubscriptionStatus.TrialExpired) {
    actionElement = <SignupButton />;
    secondLineElement = (
      <small>
        Got questions?{" "}
        <a
          className="text-primary"
          style={{ textDecoration: "underline" }}
          href="mailto:getcrewcontrol@youraspire.com"
        >
          Contact sales
        </a>
      </small>
    );

    message = "Your free trial has expired";
  } else if (subscriptionStatus === TenantSubscriptionStatus.Trial) {
    const isDaysValid =
      subscriptionStatus !== TenantSubscriptionStatus.Trial ||
      typeof trialDaysRemaining === "number";

    if (isDaysValid) {
      actionElement = <SignupButton />;

      message = `${trialDaysRemaining} days left in your free trial!`;
    }
  } else if (subscriptionCancellationPending) {
    showDangerText = true;

    actionElement = (
      <button
        className="btn btn-success btn-sm mx-3"
        style={{ whiteSpace: "nowrap" }}
        onClick={() => {
          setShowReactivateSubscription(true);
        }}
      >
        Reactivate
      </button>
    );

    if (typeof subscriptionRenewalDate === "string") {
      message = `Your subscription ends in ${differenceInCalendarDays(
        subscriptionRenewalDate,
        dateService.getCurrentDate()
      )} days!`;
    } else {
      message = `Your subscription is scheduled to end at the end of your billing cycle.`;
    }
  } else if (subscriptionStatus === TenantSubscriptionStatus.Canceled) {
    actionElement = <SignupButton />;
  } else if (subscriptionStatus === TenantSubscriptionStatus.TrialUnavailable) {
    actionElement = <SignupButton />;

    message = "Get started now!";
  } else if (isStringSet(subscriptionPaymentFailureUtc)) {
    showDangerText = true;
    actionElement = isAdmin ? <UpdatePaymentMethod /> : null;

    message = "Your subscription payment has failed!";
  }

  return isRegistered &&
    loadStatus === InitialLoadState.complete &&
    (isStringSet(message) || actionElement !== null) ? (
    <>
      <div
        data-testid="trialMessageContainer"
        style={{
          display: "flex",
          alignItems: "center",
          minWidth: "210px",
          gap: "5px",
          ...(containerStyle ?? {}),
        }}
        className={containerClassName}
      >
        <div>
          <div
            className={`${
              showDangerText ? "text-danger" : "text-info"
            } d-flex flex-wrap font-weight-bold`}
            style={{ gap: "5px" }}
          >
            <div>{message}</div>

            {subscriptionStatus === TenantSubscriptionStatus.Trial ||
            subscriptionStatus === TenantSubscriptionStatus.TrialExpired ||
            subscriptionStatus === TenantSubscriptionStatus.TrialUnavailable ? (
              <LinkButton2
                buttonContents="View pricing"
                inlineButton
                className="ml-2"
                style={{ textDecoration: "underline" }}
                onClick={() => {
                  setShowPricing(true);
                }}
              />
            ) : null}
          </div>
          {secondLineElement !== null ? (
            <div className="text-center">{secondLineElement}</div>
          ) : null}
        </div>

        {actionElement}
      </div>

      {showPricing ? (
        <PricingDetails
          onClose={() => {
            setShowPricing(false);
          }}
        />
      ) : null}

      {showReactivateSubscription ? (
        <ReactivateSubscription
          onClose={() => setShowReactivateSubscription(false)}
        />
      ) : null}
    </>
  ) : null;
};

export default TenantSubscriptionStatusAlert;

function SignupButton() {
  const dispatch = useDispatch();

  return (
    <button
      className="btn btn-success btn-sm mx-3"
      style={{ whiteSpace: "nowrap" }}
      onClick={() => {
        fullStoryTrack("Signup Clicked");
        dispatch(actionCreators.forms.subscription.showForm({}));
      }}
    >
      Sign Up
    </button>
  );
}

function UpdatePaymentMethod() {
  const [creatingPortalSession, setCreatingPortalSession] = useState(false);
  return (
    <>
      {creatingPortalSession ? <Spinner /> : null}

      <LinkButton2
        inlineButton
        buttonContents="Update payment method"
        style={{ fontWeight: 500 }}
        onClick={() => {
          setCreatingPortalSession(true);

          subscriptionDataProvider
            .createSubscriptionPortalSession({
              page: PortalPage.UpdatePaymentMethod,
            })
            .pipe(timeout(15000))
            .subscribe({
              next: (portalUrl) => {
                window.location.href = portalUrl;
              },

              error: () => {
                setCreatingPortalSession(false);
              },
            });
        }}
      />
    </>
  );
}

function PricingDetails({ onClose }: { onClose: () => void }) {
  const pricingUpdates =
    useApplicationStateSelector((s) => s.common.featureFlags?.pricingUpdates) ??
    false;
  const tenantPlan = useApplicationStateSelector((s) => s.common.tenantPlan);
  const defaultSubscriptionFrequency = useApplicationStateSelector(
    (s) => s.common.subscriptionFrequency
  );
  const crews = useApplicationStateSelector((s) => s.crew.crews);
  const activeCrewCount = crews.filter((c) => !c.inactive).length;

  const [subscriptionFrequency, setSubscriptionFrequency] = useState(
    defaultSubscriptionFrequency
      ? defaultSubscriptionFrequency
      : pricingUpdates
      ? SubscriptionFrequency.annual
      : SubscriptionFrequency.month
  );

  return (
    <Modal isOpen toggle={onClose}>
      <ModalHeader toggle={onClose}>Pricing</ModalHeader>
      <ModalBody>
        <SubscriptionFormPlanSelection
          tenantPlan={tenantPlan ?? TenantPlan.plus}
          onTenantPlanChange={() => {}}
          subscriptionFrequency={subscriptionFrequency}
          onSubscriptionFrequencyChange={setSubscriptionFrequency}
          numberOfCrews={activeCrewCount}
          disableSelection={true}
        />
      </ModalBody>
      <ModalFooter>
        <button type="button" className="btn btn-secondary" onClick={onClose}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
}
