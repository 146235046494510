import { useEffect, useState } from "react";
import ContainerHeader from "../../../containers/app/pages/manage/ContainerHeader";
import ServerLoadedList from "../../../libraries/tableLayout/ServerLoadedList";
import { IProposalTemplate } from "../models/IProposalTemplate";
import proposalDataProvider from "../services/proposalDataProvider";
import { timeout } from "rxjs/operators";
import ProposalTemplateForm from "./ProposalTemplateForm";
import ButtonDropdown from "reactstrap/lib/ButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { IInvoiceItem } from "../../../models/IInvoiceItem";
import { forkJoin } from "rxjs";
import { getInvoiceItems } from "../../../services/invoiceFormService";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import Prompt from "../../../containers/app/components/Prompt";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import Spinner from "../../../containers/app/components/Spinner";
import { isStringSet } from "../../../services/stringService";

export default function ProposalTemplatesPage() {
  const [loadingData, setLoadingData] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );

  const [proposalTemplates, setProposalTemplates] =
    useState<Array<IProposalTemplate> | null>(null);
  const [invoiceItems, setInvoiceItems] = useState<Array<IInvoiceItem>>([]);

  useEffect(() => {
    const subscription = loadData({
      setLoadingData,
      setErrorLoading,
      setProposalTemplates,
      setInvoiceItems,
      isQuickBooksEnabled,
    });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [isQuickBooksEnabled]);

  return (
    <ContainerHeader
      addButton={
        <Buttons
          onSaveComplete={() => {
            loadData({
              setLoadingData,
              setErrorLoading,
              setProposalTemplates,
              setInvoiceItems,
              isQuickBooksEnabled,
            });
          }}
          invoiceItems={invoiceItems}
          setInvoiceItems={setInvoiceItems}
        />
      }
      pageHeader={`Proposal Templates`}
      messageToShow=""
      showInactiveFilter={false}
      itemCount={proposalTemplates?.length ?? 0}
      onInactiveFilterChanged={() => {}}
      showInactive={false}
      notFluid={true}
    >
      <ServerLoadedList<IProposalTemplate>
        alwaysShowTable
        columns={[
          {
            key: "name",
            header: "Name",
            testId: "name",
            cell: ({ row: v }) => v.name,
          },
          {
            key: "edit",
            header: "",
            isButtonCell: true,
            cell: ({ row: v, displayType }) => (
              <div className={displayType === "desktop" ? "text-right" : ""}>
                <ActionButton
                  template={v}
                  invoiceItems={invoiceItems}
                  setInvoiceItems={setInvoiceItems}
                  onSaveComplete={() => {
                    loadData({
                      setLoadingData,
                      setErrorLoading,
                      setProposalTemplates,
                      setInvoiceItems,
                      isQuickBooksEnabled,
                    });
                  }}
                />
              </div>
            ),
          },
        ]}
        header={null}
        filter={<></>}
        refreshData={() => {
          loadData({
            setLoadingData,
            setErrorLoading,
            setProposalTemplates,
            setInvoiceItems,
            isQuickBooksEnabled,
          });
        }}
        data={proposalTemplates}
        loadingData={loadingData}
        errorLoading={errorLoading}
        dataType="proposal templates"
        showContentWhileRefreshing={true}
        responsiveTableContainerStyle={{
          // Add bottom margin to table so the bottom content isn't covered by pendo button
          marginBottom: "60px",
        }}
      />
    </ContainerHeader>
  );
}

function ActionButton({
  template,
  onSaveComplete,
  invoiceItems,
  setInvoiceItems,
}: {
  template: IProposalTemplate;
  onSaveComplete(): void;
  invoiceItems: Array<IInvoiceItem>;
  setInvoiceItems: (v: Array<IInvoiceItem>) => void;
}) {
  const [showEditForm, setShowEditForm] = useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [isAdditionalButtonsDropDownOpen, setIsAdditionalButtonsDropDownOpen] =
    useState(false);

  return (
    <>
      <ButtonDropdown
        direction="down"
        isOpen={isAdditionalButtonsDropDownOpen}
        toggle={() =>
          setIsAdditionalButtonsDropDownOpen(!isAdditionalButtonsDropDownOpen)
        }
      >
        <button
          className="btn btn-secondary btn-sm"
          onClick={() =>
            setIsAdditionalButtonsDropDownOpen(!isAdditionalButtonsDropDownOpen)
          }
        >
          Actions
        </button>
        <DropdownToggle split color="secondary" size="sm" />
        <DropdownMenu positionFixed={true} right={true}>
          <DropdownItem
            onClick={() => {
              setShowEditForm(true);
            }}
          >
            Edit
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setShowDeleteConfirmation(true);
              setErrorMessage(null);
            }}
          >
            Delete
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>

      {deleting ? <Spinner /> : null}

      {showEditForm ? (
        <ProposalTemplateForm
          template={template}
          invoiceItems={invoiceItems}
          setInvoiceItems={setInvoiceItems}
          onSaveComplete={() => {
            setShowEditForm(false);
            onSaveComplete();
          }}
          onCancel={() => setShowEditForm(false)}
        />
      ) : null}

      <Prompt
        showPrompt={showDeleteConfirmation}
        promptMessage={
          <>
            Are you sure you want to delete the template{" "}
            <strong>{template.name}</strong>?
          </>
        }
        promptSubMessage={isStringSet(errorMessage) ? errorMessage : undefined}
        promptSubMessageClassName={
          isStringSet(errorMessage) ? "text-danger" : undefined
        }
        // Don't fade the modal in if an error message is set
        // This occurs when reshowing the modal after trying again when first
        // delete failed. If we fade it in, the new error message will show
        // before the modal fades in, causing an odd appearance.
        fadeInModal={!isStringSet(errorMessage)}
        onConfirm={() => {
          setShowDeleteConfirmation(false);
          setDeleting(true);
          setErrorMessage(null);

          proposalDataProvider.deleteProposalTemplate(template.id).subscribe({
            next: () => {
              setDeleting(false);
              onSaveComplete();
            },
            error: (err) => {
              setShowDeleteConfirmation(true);
              setDeleting(false);
              setErrorMessage(getErrorMessageFromError(err));
            },
          });
        }}
        onCancel={() => {
          setShowDeleteConfirmation(false);
        }}
      />
    </>
  );
}

function Buttons({
  onSaveComplete,
  invoiceItems,
  setInvoiceItems,
}: {
  onSaveComplete(): void;
  invoiceItems: Array<IInvoiceItem>;
  setInvoiceItems: (v: Array<IInvoiceItem>) => void;
}) {
  const [showAddForm, setShowAddForm] = useState(false);

  return (
    <>
      <button
        className="btn btn-primary text-nowrap"
        onClick={() => setShowAddForm(true)}
      >
        Add Template
      </button>

      {showAddForm ? (
        <ProposalTemplateForm
          template={null}
          invoiceItems={invoiceItems}
          setInvoiceItems={setInvoiceItems}
          onSaveComplete={() => {
            setShowAddForm(false);
            onSaveComplete();
          }}
          onCancel={() => setShowAddForm(false)}
        />
      ) : null}
    </>
  );
}

function loadData({
  setLoadingData,
  setErrorLoading,
  setProposalTemplates,
  setInvoiceItems,
  isQuickBooksEnabled,
}: {
  setLoadingData: (v: boolean) => void;
  setErrorLoading: (v: boolean) => void;
  setProposalTemplates: (v: Array<IProposalTemplate>) => void;
  setInvoiceItems: (v: Array<IInvoiceItem>) => void;
  isQuickBooksEnabled: boolean;
}) {
  setLoadingData(true);

  return forkJoin({
    proposalTemplates: proposalDataProvider.getProposalTemplates(),
    invoiceItems: getInvoiceItems(isQuickBooksEnabled),
  })
    .pipe(timeout(30000))
    .subscribe(
      ({ proposalTemplates, invoiceItems }) => {
        setLoadingData(false);
        setProposalTemplates(proposalTemplates);
        setInvoiceItems(invoiceItems);
        setErrorLoading(false);
      },
      () => {
        setLoadingData(false);
        setErrorLoading(true);
      }
    );
}
