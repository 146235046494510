import React from "react";
import { IJob, JobType } from "../../../../../models/IJob";
import {
  IJobInstance,
  maintenanceJobType,
} from "../../../../../models/IJobInstance";
import { ICustomCrewQuestion } from "../../../../../models/ICustomCrewQuestion";
import { availableFields, customCrewQuestionId } from "./availableFields";
import timeService from "../../../../../services/timeService";
import { isFieldShown } from "./isFieldShown";
import {
  getEstimatedTimeWorked,
  getTimedWorkedForJobInstance,
  getTimeRangesForDisplay,
} from "../../../../../services/jobInstanceService";
import { formatCurrency } from "../../../../../services/currencyFormatter";
import { ICrew } from "../../../../../models/ICrew";
import { useGetJobGrossAmountAndEstimatedTime } from "../../../../../hooks/useGetJobGrossAmountAndEstimatedTime";
import { getFloatFromNumberOrString } from "../../../../../services/typeConverter";

const fieldContainer: React.CSSProperties = {
  marginRight: "50px",
  marginBottom: "30px",
};

interface IProps {
  crew: ICrew;
  job: IJob;
  jobInstance: IJobInstance;
  customCrewQuestions: Array<ICustomCrewQuestion>;
  fieldsToShow: Array<availableFields | customCrewQuestionId>;
  date: string;
}

export const FieldsForJob: React.FunctionComponent<IProps> = ({
  crew,
  job,
  jobInstance,
  customCrewQuestions,
  fieldsToShow,
  date,
}) => {
  const getJobGrossAmountAndEstimatedManHours =
    useGetJobGrossAmountAndEstimatedTime();

  const actualManHours = timeService.getActualManHours(jobInstance);
  let actualManHoursClass = "";
  if (actualManHours && job.estimatedManHours) {
    if (actualManHours > getFloatFromNumberOrString(job.estimatedManHours)) {
      actualManHoursClass = "text-danger";
    } else if (
      actualManHours < getFloatFromNumberOrString(job.estimatedManHours)
    ) {
      actualManHoursClass = "text-success";
    }
  }

  let notesFromCrew = (jobInstance.notesFromCrew || "").trim();

  let notesFromAdmin = (job.notes || "").trim();
  if (
    (jobInstance.jobNotes || "").trim() &&
    jobInstance.type === maintenanceJobType
  ) {
    if (notesFromAdmin) {
      notesFromAdmin += "\n\n";
    }

    notesFromAdmin += jobInstance.jobNotes.trim();
  }

  let adminOnlyNotes = (job.administratorOnlyNotes || "").trim();
  if (
    (jobInstance.administratorOnlyNotes || "").trim() &&
    jobInstance.type === maintenanceJobType
  ) {
    if (adminOnlyNotes) {
      adminOnlyNotes += "\n\n";
    }

    adminOnlyNotes += jobInstance.administratorOnlyNotes.trim();
  }
  const jobType =
    jobInstance.type === maintenanceJobType
      ? JobType.maintenanceJob
      : JobType.oneTimeJob;

  const { grossRevenuePerVisit, estimatedManHours } =
    getJobGrossAmountAndEstimatedManHours(date, jobType, job, jobInstance);

  const notSetValue = "Not set";
  const fields: Array<{
    label: string;
    content: React.ReactNode;
    requiresComplete?: boolean;
    hide?: boolean;
    divKey?: string;
    key: availableFields | customCrewQuestionId;
    testId?: string;
  }> = [
    {
      label: "Crew",
      content: crew.name,
      key: availableFields.crew,
    },
    {
      label: "Complete",
      content: jobInstance.complete ? "Yes" : "No",
      key: availableFields.complete,
    },
    {
      label: "Estimated man hours",
      content: estimatedManHours || notSetValue,
      key: availableFields.estimatedManHours,
      testId: "estimatedManHours",
    },
    {
      label: "Actual man hours",
      content: (
        <span className={actualManHoursClass}>
          {actualManHours || notSetValue}
        </span>
      ),
      requiresComplete: true,
      key: availableFields.actualManHours,
      hide: !timeService.hasStartAndEndTimeSet(jobInstance),
    },
    {
      label: "Estimated time worked",
      content:
        getEstimatedTimeWorked(crew, estimatedManHours)?.formattedValue ??
        notSetValue,
      testId: "estimatedTimeWorked",
      requiresComplete: false,
      key: availableFields.estimatedTimeWorked,
    },
    {
      label: "Actual time worked",
      content: getActualTimeWorked(crew, estimatedManHours, jobInstance),
      requiresComplete: true,
      key: availableFields.actualTimeWorked,
      hide: !timeService.hasStartAndEndTimeSet(jobInstance),
      testId: "actualTimeWorked",
    },
    {
      label: "Time ranges worked",
      content: getTimeRangesForDisplay(
        jobInstance.timeRanges,
        (id, output) => <div key={id}>{output}</div>,
        notSetValue
      ),
      requiresComplete: false,
      key: availableFields.timeRanges,
    },
    {
      label: "Crew size",
      content: timeService.getCrewSize(jobInstance) || notSetValue,
      requiresComplete: true,
      key: availableFields.numberOfCrewMembers,
      testId: "actualCrewMembers",
    },
    {
      label: "Notes from crew",
      content: <span style={{ whiteSpace: "pre-wrap" }}>{notesFromCrew}</span>,
      hide: notesFromCrew.length === 0,
      key: availableFields.notesFromCrew,
    },
    {
      label: "Notes from administrators",
      content: <span style={{ whiteSpace: "pre-wrap" }}>{notesFromAdmin}</span>,
      hide: notesFromAdmin.length === 0,
      key: availableFields.notesFromAdmin,
    },
    {
      label: "Administrator only notes",
      content: <span style={{ whiteSpace: "pre-wrap" }}>{adminOnlyNotes}</span>,
      hide: adminOnlyNotes.length === 0,
      key: availableFields.adminOnlyNotes,
    },
    {
      label: "PO number",
      content: jobInstance.purchaseOrderNumber || notSetValue,
      key: availableFields.purchaseOrderNumber,
    },
    {
      label: "Invoice details",
      content: jobInstance.invoiceNumber || notSetValue,
      key: availableFields.invoiceNumber,
    },
    {
      label: "Invoice sent",
      content: jobInstance.invoiceSent ? "Yes" : "No",
      key: availableFields.invoiceSent,
    },
    {
      label:
        jobInstance.type === maintenanceJobType ? "Amount per visit" : "Amount",
      content: !!grossRevenuePerVisit
        ? formatCurrency(grossRevenuePerVisit || 0)
        : "Not set",
      key: availableFields.grossRevenuePerVisit,
      testId: "grossRevenuePerVisit",
    },
    ...jobInstance.customCrewQuestionResponses.map((response) => {
      const sourceQuestion = customCrewQuestions.find(
        (q) => q.id === response.customCrewQuestionId
      );
      const label = response.questionText
        ? response.questionText
        : sourceQuestion
        ? sourceQuestion.question
        : "";

      return {
        label,
        key: availableFields.customCrewQuestions,
        divKey: response.customCrewQuestionId,
        content: (
          <span style={{ whiteSpace: "pre-wrap" }}>{response.answer}</span>
        ),
      };
    }),
  ];

  return (
    <React.Fragment>
      {fields
        .filter(
          (f) =>
            isFieldShown(f.key, fieldsToShow) &&
            (jobInstance.complete || !f.requiresComplete) &&
            !f.hide
        )
        .map((f) => (
          <div style={fieldContainer} key={f.divKey || f.key}>
            <h6>{f.label}</h6>
            <span data-testid={f.testId}>{f.content}</span>
          </div>
        ))}
    </React.Fragment>
  );
};

function getActualTimeWorked(
  crew: ICrew,
  estimatedManHours: number | null,
  jobInstance: IJobInstance
): React.ReactNode {
  const actualValue = getTimedWorkedForJobInstance(jobInstance);
  const estimatedValue = getEstimatedTimeWorked(crew, estimatedManHours);

  if (!actualValue?.formattedValue) {
    return null;
  }

  let className = "";
  if (estimatedValue?.value) {
    if (actualValue?.value > estimatedValue?.value) {
      className = "text-danger";
    } else if (actualValue?.value < estimatedValue?.value) {
      className = "text-success";
    }
  }

  return <div className={className}>{actualValue?.formattedValue}</div>;
}
