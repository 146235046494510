import { useMemo } from "react";
import { useApplicationStateSelector } from "../../../../../hooks/useApplicationStateSelector";
import customerFinder from "../../../../../services/customerFinder";
import { getNormalizedPhoneNumber } from "../../../../../services/phoneNumberService";
import CardPhoneNumbers from "../../CardPhoneNumbers";
import { IJob } from "../../../../../models/IJob";

export function JobCardPhoneNumbers({
  job,
  useWhiteText,
}: {
  job: IJob;
  useWhiteText: boolean;
}) {
  const showCustomerPhoneNumberOnAdminJobCard = useApplicationStateSelector(
    (s) => s.common.adminViewConfiguration.showCustomerPhoneNumberOnAdminJobCard
  );
  const customers = useApplicationStateSelector((s) => s.customer.customers);

  const { phoneNumber, alternativePhoneNumber } = useMemo(() => {
    let returnValue = {
      phoneNumber: "",
      alternativePhoneNumber: "",
      emailAddresses: [] as Array<string>,
    };
    if (showCustomerPhoneNumberOnAdminJobCard) {
      const customer = customerFinder.getCustomerByJob(job, customers);
      if (customer) {
        if (showCustomerPhoneNumberOnAdminJobCard) {
          returnValue.phoneNumber = getNormalizedPhoneNumber(
            customer.phoneNumber
          );
          returnValue.alternativePhoneNumber = getNormalizedPhoneNumber(
            customer.alternativePhoneNumber
          );
        }
      }
    }
    return returnValue;
  }, [job, customers, showCustomerPhoneNumberOnAdminJobCard]);

  if (!showCustomerPhoneNumberOnAdminJobCard) {
    return null;
  }

  return showCustomerPhoneNumberOnAdminJobCard ? (
    <CardPhoneNumbers
      phoneNumber={phoneNumber}
      alternativePhoneNumber={alternativePhoneNumber}
      useWhiteText={useWhiteText}
    />
  ) : null;
}
