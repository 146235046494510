import { useRef } from "react";
import { MaintenanceJobFrequency } from "../../../models/IMaintenanceJob";
import { IFrequency } from "../components/FrequencySelection";
import WeekPicker from "../components/WeekPicker";
import dateService from "../../../services/dateService";
import DayPicker, { format as weekPickerFormat } from "../components/DayPicker";
import dateFnsFormat from "date-fns/format";
import startOfWeek from "date-fns/start_of_week";
import parse from "date-fns/parse";
import { isStringSet } from "../../../services/stringService";
import InfoToolTip from "../components/InfoToolTip";

export function MaintenanceJobFormDateFields({
  useWeekPicker,
  frequency,
  startingDate,
  onStartingDateChange,
  endingDate,
  onEndingDateChange,
}: {
  useWeekPicker: boolean;
  frequency: IFrequency;
  startingDate: string;
  onStartingDateChange(newValue: string): void;
  endingDate: string;
  onEndingDateChange(newValue: string): void;
}) {
  const dayPickerLabelElement = useRef<HTMLLabelElement>(null);
  const endDateLabelElement = useRef<HTMLLabelElement>(null);

  const responsiveBreakpoint = "lg";

  return (
    <>
      <div className="form-row">
        <div className={`form-group col-12 col-${responsiveBreakpoint}-6`}>
          <label
            ref={dayPickerLabelElement}
            htmlFor="startingDate"
            className={
              frequency.frequencyType !== MaintenanceJobFrequency.Weekly &&
              frequency.frequencyType !== MaintenanceJobFrequency.None &&
              frequency.frequencyType !== MaintenanceJobFrequency.Monthly
                ? "required"
                : ""
            }
          >
            {useWeekPicker
              ? "Which week should it start?"
              : "Which day should it start?"}
          </label>
          <div>
            {useWeekPicker ? (
              <WeekPicker
                labelRef={dayPickerLabelElement}
                required={
                  frequency.frequencyType !== MaintenanceJobFrequency.Weekly &&
                  frequency.frequencyType !== MaintenanceJobFrequency.None &&
                  frequency.frequencyType !== MaintenanceJobFrequency.Monthly
                }
                value={
                  !!startingDate
                    ? dateFnsFormat(startingDate, weekPickerFormat)
                    : ""
                }
                inputId="startingDate"
                testId="startingDate"
                onWeekSelected={(day: Date) => {
                  let selectedWeekStart = "";

                  if (!!day) {
                    const parsedDate = dateFnsFormat(day, weekPickerFormat);
                    selectedWeekStart = dateService.formatAsIso(
                      startOfWeek(parsedDate)
                    );
                  }

                  onStartingDateChange(selectedWeekStart);
                }}
              />
            ) : (
              <DayPicker
                labelRef={dayPickerLabelElement}
                inputId="startingDate"
                testId="startingDate"
                onDayPickerHide={() => null}
                dayPickerProps={{}}
                value={
                  !!startingDate
                    ? dateFnsFormat(startingDate, weekPickerFormat)
                    : ""
                }
                required={true}
                onDaySelected={(day: Date) => {
                  onStartingDateChange(day ? dateService.formatAsIso(day) : "");
                }}
              />
            )}
          </div>
        </div>
        <div className={`form-group col-12 col-${responsiveBreakpoint}-6`}>
          <label htmlFor="endingDate" ref={endDateLabelElement}>
            When does the recurring job stop?
          </label>
          <InfoToolTip
            id="endingDateToolTip"
            text="No jobs will be created on or after the recurring job's end date"
          />
          <DayPicker
            labelRef={endDateLabelElement}
            inputId="endingDate"
            testId="endingDate"
            onDayPickerHide={() => null}
            dayPickerProps={{}}
            value={
              !!endingDate ? dateFnsFormat(endingDate, weekPickerFormat) : ""
            }
            required={false}
            onDaySelected={(day: Date) => {
              onEndingDateChange(day ? dateService.formatAsIso(day) : "");
            }}
            minDate={
              isStringSet(startingDate) ? parse(startingDate) : undefined
            }
          />
        </div>
      </div>
    </>
  );
}
