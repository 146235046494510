import parse from "date-fns/parse";
import { IJobInstance } from "../../../models/IJobInstance";
import setMinutes from "date-fns/set_minutes";
import setHours from "date-fns/set_hours";
import getHours from "date-fns/get_hours";
import { addDays, getMinutes } from "date-fns";
import { IDaySchedule } from "../../../models/IDaySchedule";
import dateService from "../../../services/dateService";
import {
  DayScheduleWithJobInstancesInTimeSlots,
  JobInstanceWithTimeSlot,
} from "./ScheduleTimeCalendar.types";
import { MinuteWindowOptions } from "./Calendar";
import { getSortedItemsV2 } from "../../../services/sortingService";
import { createRange } from "../services/createRange";
import { ICrew } from "../../../models/ICrew";
import { getCrewWorkingHours } from "../../../services/crewService";
import { IScheduleColumn } from "./types/IScheduleColumn";
import { getWindowThresholds } from "../services/timeBlockCalculator";

export function getDaySchedulesWithJobInstancesInTimeSlots(
  daySchedules: IDaySchedule[],
  dayScheduleIds: string[],
  draggingJobInstance: {
    jobInstance: IJobInstance;
    daySchedule: IDaySchedule;
  } | null
): Array<DayScheduleWithJobInstancesInTimeSlots> {
  const matchingDaySchedules = daySchedules.filter((ds) =>
    dayScheduleIds.includes(ds.id)
  );

  // Map jobs to starting time slots
  const daySchedulesWithJobsInTimeSlots = matchingDaySchedules.map(
    (daySchedule) => {
      const date = parse(daySchedule.date);
      const currentDateJobs = getCombinedJobInstances(
        daySchedule,
        draggingJobInstance
      ).map((jobInstance) => {
        var {
          parsedStartTime: startTime,
          parsedEndTime: endTime,
          extendsToNextDay,
        } = getParsedTimesWithAdjustedEndTime(date, jobInstance);

        return {
          startTime,
          endTime,
          jobInstance,
          columns: null,
          slot: null,
          extendsToNextDay,
          extendedFromPreviousDay: false,
          isDragPlaceholderJob: jobInstance.isDragPlaceholderJob ?? false,
        };
      });

      const precedingDaySchedule = daySchedules.find(
        (ds) =>
          ds.crewId === daySchedule.crewId &&
          dateService.areDatesEqual(ds.date, addDays(daySchedule.date, -1))
      );

      let overnightJobInstancesFromPreviousDay: Array<JobInstanceWithTimeSlot> =
        [];
      if (precedingDaySchedule) {
        overnightJobInstancesFromPreviousDay = getCombinedJobInstances(
          precedingDaySchedule,
          draggingJobInstance
        )
          .filter(
            // startsWith("00:00") is to check if job is at midnight. doesn't pass into next day if ends at midnight
            (ji) =>
              ji.startTime &&
              ji.endTime &&
              ji.endTime < ji.startTime &&
              !ji.endTime.startsWith("00:00")
          )
          .map((jobInstance) => {
            const startTime = dateService.constructDateFromDateAndTime(
              date,
              "00:00:00"
            );
            let endTime = dateService.constructDateFromDateAndTime(
              date,
              jobInstance.endTime
            );

            return {
              startTime,
              endTime,
              jobInstance,
              columns: null,
              slot: null,
              extendsToNextDay: false,
              extendedFromPreviousDay: true,
              isDragPlaceholderJob: jobInstance.isDragPlaceholderJob ?? false,
            };
          });
      }

      return {
        ...daySchedule,
        jobInstancesInTimeSlots: [
          ...currentDateJobs,
          ...overnightJobInstancesFromPreviousDay,
        ],
      };
    }
  );

  // Set columns
  executeForEachTimeRange((matchingSortedJobs) => {
    matchingSortedJobs.forEach((job) => {
      if (
        typeof job.columns !== "number" ||
        matchingSortedJobs.length > job.columns
      ) {
        job.columns = matchingSortedJobs.length;
      }
    });

    matchingSortedJobs.forEach((job) => {
      if (job.slot === null) {
        job.slot = getAvailableSlot(job.columns, matchingSortedJobs);
      }
    });
  });

  function executeForEachTimeRange(
    callback: (matchingSortedJobs: Array<JobInstanceWithTimeSlot>) => void
  ) {
    const hours = createRange(0, 24, 1);
    const minuteWindows: Array<MinuteWindowOptions> = [0, 15, 30, 45];
    hours.forEach((hour) => {
      minuteWindows.forEach((minuteWindow) => {
        daySchedulesWithJobsInTimeSlots.forEach((daySchedule) => {
          const dayScheduleDate = parse(daySchedule.date);
          let { startThreshold, endThreshold } = getWindowThresholds(
            dayScheduleDate,
            hour,
            minuteWindow
          );

          const sortedJobs = getSortedItemsV2(
            daySchedule.jobInstancesInTimeSlots.filter(
              (ji) => !ji.isDragPlaceholderJob
            ),
            [
              (j) =>
                !j.startTime
                  ? ""
                  : dateService.formatAsIsoWithTimeAndOffset(j.startTime),
              (j) =>
                !j.endTime
                  ? ""
                  : dateService.formatAsIsoWithTimeAndOffset(j.endTime),
            ]
          );
          const matchingSortedJobs = sortedJobs.filter((j) => {
            if (!j.startTime || !j.endTime) {
              return false;
            }

            const jobFullyCoversWindow =
              j.startTime <= startThreshold && j.endTime >= endThreshold;
            const jobWithinWindow =
              j.startTime >= startThreshold && j.endTime < endThreshold;

            return jobFullyCoversWindow || jobWithinWindow;
          });

          callback(matchingSortedJobs);
        });
      });
    });
  }

  return daySchedulesWithJobsInTimeSlots;
}

function getCombinedJobInstances(
  daySchedule: IDaySchedule,
  draggingJobInstance: {
    jobInstance: IJobInstance;
    daySchedule: IDaySchedule;
  } | null
) {
  let result: Array<IJobInstance & { isDragPlaceholderJob?: boolean }> =
    daySchedule.jobInstances;
  if (
    draggingJobInstance &&
    draggingJobInstance.daySchedule.id === daySchedule.id
  ) {
    result = [
      ...result,
      {
        ...draggingJobInstance.jobInstance,
        isDragPlaceholderJob: true,
      },
    ];
  }

  return result;
}

export function getCalendarHoursForCrews(
  crewWithDates: Array<{ crew: ICrew; date: Date }>
) {
  let hours: Array<number> = [];

  crewWithDates.forEach(({ crew, date }) => {
    const {
      workingHoursStart: crewWorkingHoursStart,
      workingHoursEnd: crewWorkingHoursEnd,
    } = getCrewWorkingHours(crew);

    let startTime = dateService.constructDateFromDateAndTime(
      date,
      crewWorkingHoursStart
    );
    let endTime = dateService.constructDateFromDateAndTime(
      date,
      crewWorkingHoursEnd
    );

    if (endTime < startTime) {
      // Show all hours for crews going overnight
      startTime = dateService.constructDateFromDateAndTime(date, "00:00:00");
      endTime = dateService.constructDateFromDateAndTime(date, "23:59:00");
    }

    hours = expandHours(startTime, endTime, hours);
  });

  if (hours === null) {
    hours = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
  }

  return hours;
}

export function expandHoursForJobs(
  originalHours: number[],
  daySchedulesWithJobsInTimeSlots: Array<DayScheduleWithJobInstancesInTimeSlots>
) {
  let result = originalHours;

  daySchedulesWithJobsInTimeSlots.forEach((daySchedule) => {
    daySchedule.jobInstancesInTimeSlots
      .filter((ji) => !ji.isDragPlaceholderJob)
      .forEach((jobInstanceWithTimeSlot) => {
        if (
          jobInstanceWithTimeSlot.startTime &&
          jobInstanceWithTimeSlot.endTime
        ) {
          result = expandHours(
            jobInstanceWithTimeSlot.startTime,
            jobInstanceWithTimeSlot.endTime,
            result
          );
        }
      });
  });

  return result;
}

export function isAvailableTime({
  crew,
  hour,
  minute,
}: {
  crew: ICrew;
  hour: number;
  minute: MinuteWindowOptions;
}) {
  const timeSlot = createTimeFromHourMinute(hour, minute);
  const {
    workingHoursStart: crewWorkingHoursStart,
    workingHoursEnd: crewWorkingHoursEnd,
  } = getCrewWorkingHours(crew);

  if (
    crewWorkingHoursStart < crewWorkingHoursEnd &&
    timeSlot >= crewWorkingHoursStart &&
    timeSlot < crewWorkingHoursEnd
  ) {
    return true;
  } else if (
    crewWorkingHoursStart > crewWorkingHoursEnd &&
    (timeSlot >= crewWorkingHoursStart || timeSlot < crewWorkingHoursEnd)
  ) {
    return true;
  } else {
    return false;
  }
}

export function createTimeFromHourMinute(hour: number, minuteWindow: number) {
  return `${hour < 10 ? "0" + hour : hour}:${
    minuteWindow === 0 ? "00" : minuteWindow
  }:00`;
}

export function getHoursAndJobInstancesInTimeSlots({
  daySchedules,
  verifiedColumns,
  draggingJobInstance,
}: {
  daySchedules: IDaySchedule[];
  verifiedColumns: Array<IScheduleColumn>;
  draggingJobInstance: {
    jobInstance: IJobInstance;
    daySchedule: IDaySchedule;
  } | null;
}) {
  const daySchedulesWithJobsInTimeSlots =
    getDaySchedulesWithJobInstancesInTimeSlots(
      daySchedules,
      verifiedColumns.map((c) => c.dayScheduleId),
      draggingJobInstance
    );

  const crewHours = getCalendarHoursForCrews(
    verifiedColumns.map((c) => ({ date: parse(c.date), crew: c.crew }))
  );
  const hours = expandHoursForJobs(crewHours, daySchedulesWithJobsInTimeSlots);
  return { hours, daySchedulesWithJobsInTimeSlots };
}

function expandHours(startTime: Date, endTime: Date, result: number[]) {
  const startHour = getHours(startTime);
  const endMinute = getMinutes(endTime);

  const currentMin = getMin(result);
  if (startHour < currentMin) {
    result = [...createRange(startHour, currentMin, 1), ...result];
  }

  let endHour = getHours(endTime);
  if (endMinute > 0) {
    endHour++;
  }

  const currentMax = getMax(result) ?? startHour - 1;
  if (endHour > currentMax) {
    result = [...result, ...createRange(currentMax + 1, endHour, 1)];
  }
  return result;
}

function getParsedTimesWithAdjustedEndTime(date: Date, ji: IJobInstance) {
  if (!ji.startTime || !ji.endTime) {
    return {
      parsedStartTime: null,
      parsedEndTime: null,
      extendsToNextDay: false,
    };
  }

  const parsedStartTime = dateService.constructDateFromDateAndTime(
    date,
    ji.startTime
  );
  let parsedEndTime = dateService.constructDateFromDateAndTime(
    date,
    ji.endTime
  );

  let extendsToNextDay: boolean = false;
  if (parsedEndTime < parsedStartTime) {
    parsedEndTime = setMinutes(setHours(parsedEndTime, 23), 59);
    extendsToNextDay = true;
  }
  return { parsedStartTime, parsedEndTime, extendsToNextDay };
}

function getMin(numbers: number[]) {
  if (numbers.length <= 0) {
    return 0;
  }
  return numbers.reduce(
    (acc, current) => (current < acc ? current : acc),
    numbers[0]
  );
}

function getMax(numbers: Array<number | null>) {
  return numbers.reduce(
    (acc, current) =>
      current !== null && (acc === null || current > acc) ? current : acc,
    null as null | number
  );
}

function getAvailableSlot(
  columns: number | null,
  matchingSortedJobs: JobInstanceWithTimeSlot[]
): number | null {
  if (columns === null) {
    return null;
  }

  const slotOptions = createRange(0, columns, 1);
  return (
    slotOptions.find(
      (slot) => !matchingSortedJobs.some((j) => j.slot === slot)
    ) ?? null
  );
}
