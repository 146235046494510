import { actionCreators } from "../actionCreators";
import dataProvider from "../../services/dataProvider";
import { map } from "rxjs/operators";
import { IRemoteCrewChangedAction } from "../actionTypeDefinitions";

export function loadRemoteChangedCrew(action: IRemoteCrewChangedAction) {
  const resultAction = dataProvider.getInitialLoad().pipe(
    map((r) => {
      const crew = r.crews.find((c) => c.id === action.crewId);
      if (crew) {
        return actionCreators.crewUpdate(crew.id, crew);
      } else {
        return actionCreators.null();
      }
    })
  );

  return resultAction;
}
