import IPhoto from "./IPhoto";
import { ITodoItem } from "./ITodoItem";
import { ICategoryIdentifier } from "./ICategoryIdentifier";
import { JobBillingType } from "../enums/jobBillingType";
import { IJobLineItem } from "./IJobLineItem";
import { IDiscount } from "./IDiscount";

export interface IJob {
  id: string;
  customerId: string | null;
  customerAdditionalLocationId: string | null;
  crewId: string | null;
  estimatedManHours: number | string | null;
  highlightCrewNotes: boolean;
  showCrewNotesOnAdminJobCards: boolean;
  notes: string;
  order: number;
  photos: Array<IPhoto>;
  todoItems: Array<ITodoItem>;
  todoTemplateId: string | null;
  administratorOnlyNotes: string | null;
  grossRevenuePerVisit: number | null;
  categories: Array<ICategoryIdentifier>;
  billingType: JobBillingType;
  lineItems: Array<IJobLineItem>;
  taxRate: number | null;
  discount: IDiscount;
  opportunityId: string | null;
  paymentMethodOnFileAuthorized: boolean;
  hideLineItemPrices: boolean;
  startTime: string | null;
  endTime: string | null;
  arrivalWindowDurationMinutes: number | null;
  notifyCustomer: boolean;
}

export enum JobType {
  maintenanceJob = 0,
  oneTimeJob = 1,
  project = 2,
}
