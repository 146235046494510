import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { actionCreators } from "../modules/actionCreators";
import { useApplicationStateSelector } from "./useApplicationStateSelector";

export function useLoadCustomers(customerIds: Array<string>) {
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const loadingCustomers = useApplicationStateSelector(
    (s) => s.customer.customersLoading
  );
  const customersFailedToLoad = useApplicationStateSelector(
    (s) => s.customer.customersFailedToLoad
  );
  const dispatch = useDispatch();

  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    if (!hasInitialized) {
      dispatch(actionCreators.loadCustomersErrorClear(customerIds));

      setHasInitialized(true);
    }
  }, [hasInitialized, customerIds, dispatch]);

  const customersNotLoaded = customerIds.filter(
    (customerId) => !customers.some((c) => c.id === customerId)
  );

  const customersNotStartedLoading = customersNotLoaded.filter(
    (customerId) => !loadingCustomers.includes(customerId)
  );

  if (customersNotStartedLoading.length > 0) {
    dispatch(actionCreators.loadCustomersStart(customersNotStartedLoading));
  }

  return {
    loading: customersNotLoaded.length > 0,
    errorLoading:
      hasInitialized &&
      customerIds.some((c) => customersFailedToLoad.includes(c)),
  };
}
