import { concat, EMPTY, of } from "rxjs";
import { IRootState } from "../../store";
import { formSaveDefaultTimeout } from "../actionEpics";
import { IStartSavingAction } from "../actionTypes";
import { formTypes } from "../formTypes";
import { catchError, mergeMap, timeout } from "rxjs/operators";
import { getFormSaveErrorMessage } from "../../services/httpErrorHandler";
import formActionCreators from "../actionCreators";
import constants from "../../constants";
import {
  commonUiActionCreators,
  QuickBooksModalMode,
} from "../../modules/commonUi";
import { Observable } from "rxjs";
import { fullStoryTrack } from "../../services/fullStoryService";

export function handleFormStartSaving(
  formToCreateKey: keyof typeof formTypes,
  action: IStartSavingAction<any>,
  state: IRootState
) {
  const formType = formTypes[formToCreateKey];

  if (!formType.saveFunction) {
    return EMPTY;
  }

  const timeoutValue =
    typeof formType.timeout === "number"
      ? formType.timeout
      : formSaveDefaultTimeout;

  return formType
    .saveFunction(action.payload, state.forms[formToCreateKey].parameters)
    .pipe(
      timeout(timeoutValue),
      mergeMap((formSaveResult: any) => {
        if (formType.postSaveProcessor) {
          return concat(
            formType.postSaveProcessor(
              state,
              formSaveResult.parameters,
              formSaveResult.data
            ),
            of(
              formActionCreators[formToCreateKey].completeSaving(
                formSaveResult.parameters,
                formSaveResult.data,
                action.reopenFormOnComplete
              )
            )
          );
        } else {
          return of(
            formActionCreators[formToCreateKey].completeSaving(
              formSaveResult.parameters,
              formSaveResult.data,
              action.reopenFormOnComplete
            )
          );
        }
      }),
      catchError((err) => {
        let timeoutErrorMessage: string | undefined = undefined;
        let qbReconnectRequired = false;
        let qbInvoiceNumberInUseError = false;
        if (
          typeof formType.timeoutError === "string" &&
          !!formType.timeoutError
        ) {
          timeoutErrorMessage = formType.timeoutError;
        } else if (typeof formType.timeoutError === "function") {
          timeoutErrorMessage = formType.timeoutError(action.payload, state);
        }

        if (
          err?.response?.errorCode === constants.quickBooksReconnectRequired
        ) {
          qbReconnectRequired = true;
        } else if (
          err?.response?.errorCode ===
          constants.quickBooksInvoiceNumberAlreadyInUse
        ) {
          qbInvoiceNumberInUseError = true;
        }

        let errorMessage: string | React.ReactNode;
        if (qbReconnectRequired) {
          errorMessage =
            "Your QuickBooks connection has expired and must be refreshed.";
        } else if (qbInvoiceNumberInUseError) {
          errorMessage = (
            <>
              The invoice number provided by QuickBooks is already in use. Steps
              to fix this are available{" "}
              <a
                href="https://aspire-extensions.document360.io/crew-control/docs/qbo-duplicate-document-number-error"
                target="_blank"
                rel="noreferrer noopener"
              >
                here
              </a>
              .
            </>
          );
        } else {
          errorMessage = getFormSaveErrorMessage(err, timeoutErrorMessage);
        }

        let result: Array<Observable<any>> = [
          of(formActionCreators[formToCreateKey].setErrorMessage(errorMessage)),
        ];

        if (qbReconnectRequired) {
          fullStoryTrack("QuickBooks Reconnected");
          result.push(
            of(
              commonUiActionCreators.showQuickBooksModal({
                quickBooksModalMode: QuickBooksModalMode.reconnect,
                quickBooksModalExplanationText:
                  "Your QuickBooks connection has expired. Please connect again to save.",
              })
            )
          );
        }

        return concat(...result);
      })
    );
}
