import { throwError } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, map } from "rxjs/operators";
import { ICategoryIdentifier } from "../../../models/ICategoryIdentifier";
import { IOneTimeJob } from "../../../models/IOneTimeJob";
import { fullStoryLogError } from "../../../services/fullStoryService";
import {
  buildUrl,
  executeWithHeaders,
} from "../../../services/remoteDataProvider";
import { IProject } from "../models/IProject";

export type ProjectVisit = {
  crewId: string | null;
  flexibleJob: boolean;
  date: string;
  startTime: string | null;
  endTime: string | null;
  categories: Array<ICategoryIdentifier>;
  completed: boolean;
};

export type ProjectVisits = Array<{
  visits: Array<ProjectVisitExisting>;
  projectId: string;
}>;

type ProjectVisitChanges = Omit<ProjectVisit, "categories" | "completed">;

export type ProjectVisitExisting = ProjectVisit & { id: string };
export type VisitChangeOperation =
  | { method: "POST"; body: ProjectVisitChanges }
  | {
      method: "PATCH";
      body: Partial<ProjectVisitChanges>;
      id: string;
    }
  | { method: "DELETE"; id: string };

export type CreateProjectRequest = Partial<Omit<IProject, "customerId">> & {
  customerId: string | null;
  jobs: Array<
    Omit<
      IOneTimeJob,
      | "paymentMethodOnFileAuthorized"
      | "startTime"
      | "endTime"
      | "arrivalWindowDurationMinutes"
      | "notifyCustomer"
    >
  >;
};

export type UpdateProjectRequest = Partial<IProject> & {
  id: string;
  visitChanges: Array<VisitChangeOperation>;
};

const projectDataProvider = {
  getProjects: ({ projectIds }: { projectIds: Array<string> }) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl(`project/getbyids`),
        JSON.stringify({
          projectIds,
        }),
        headers
      )
    ).pipe(
      map((result) => {
        return result.response.projects as Array<IProject>;
      })
    );
  },

  getProjectVisits: (request: { projectIds: Array<string> }) => {
    return executeWithHeaders((headers) =>
      ajax.post(buildUrl(`project/getvisits`), JSON.stringify(request), headers)
    ).pipe(
      map((result) => {
        return result.response.projectVisits as ProjectVisits;
      })
    );
  },

  createProject: ({
    customerId,
    customerAdditionalLocationId,
    jobs,
    opportunityId,
    billingType,
    estimatedManHours,
    grossRevenue,
    lineItems,
    taxRate,
    discount,
    paymentMethodOnFileAuthorized,
    hideLineItemPrices,
  }: CreateProjectRequest) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl("project"),
        JSON.stringify({
          customerId,
          customerAdditionalLocationId,
          opportunityId,
          jobs,
          billingType,
          estimatedManHours,
          grossRevenue,
          lineItems,
          taxRate,
          discount,
          paymentMethodOnFileAuthorized,
          hideLineItemPrices,
        }),
        headers
      )
    ).pipe(
      catchError((err) => {
        fullStoryLogError(err);
        return throwError(err);
      }),
      map((result) => {
        return {
          data: {
            updatedDaySchedules: result.response
              .updatedDaySchedules as Array<string>,
            updatedFlexibleJobWeeks: !result.response.updatedFlexibleJobWeeks
              ? []
              : (result.response.updatedFlexibleJobWeeks as Array<string>),
            changedCategories: result.response.changedCategories,
            savedJobs: result.response.savedJobs,
            categories: result.response.categories,
          },
          success: true,
        };
      })
    );
  },

  updateProject: (args: UpdateProjectRequest) => {
    return executeWithHeaders((headers) =>
      ajax.patch(buildUrl(`project/${args.id}`), JSON.stringify(args), headers)
    ).pipe(
      map((result) => {
        return {
          project: result.response.project as IProject,
          updatedDaySchedules: result.response
            .updatedDaySchedules as Array<string>,
          updatedOneTimeJobs: result.response
            .updatedOneTimeJobs as Array<string>,
          updatedFlexibleJobWeeks: !result.response.updatedFlexibleJobWeeks
            ? []
            : (result.response.updatedFlexibleJobWeeks as Array<string>),
        };
      })
    );
  },

  deleteProject: (projectId: string) => {
    return executeWithHeaders((headers) =>
      ajax.delete(buildUrl(`project/${projectId}`), headers)
    );
  },
};

export default projectDataProvider;
