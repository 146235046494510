import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormTypesV2 } from "../formGenerator/formTypes";

export interface IFormV2State {
  openForms: Array<FormTypesV2>;
}

const initialState: IFormV2State = {
  openForms: [],
};

const { reducer, actions } = createSlice({
  name: "formV2",
  initialState,
  reducers: {
    openForm(state, action: PayloadAction<{ formType: FormTypesV2 }>) {
      return {
        ...state,
        openForms: [...state.openForms, action.payload.formType],
      };
    },

    closeForm(state, action: PayloadAction<{ formType: FormTypesV2 }>) {
      return {
        ...state,
        openForms: state.openForms.filter((t) => t !== action.payload.formType),
      };
    },
  },
});

export default reducer;

export const formV2ActionCreators = actions;
