import WeekPicker from "../../../containers/app/components/WeekPicker";
import DayPicker, {
  format as dayPickerFormat,
} from "../../../containers/app/components/DayPicker";
import dateFnsFormat from "date-fns/format";
import dateService from "../../../services/dateService";
import { startOfWeek } from "date-fns";
import { ICrew } from "../../../models/ICrew";
import { getSortedCrews } from "../../../services/sortingService";
import React from "react";
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFormRegister,
  useWatch,
} from "react-hook-form";
import { IProjectFormData } from "./ProjectForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { ProjectVisitExisting } from "../services/projectDataProvider";
import { CrewScheduleType } from "../enums/crewScheduleType";
import TimeInput from "../../../containers/app/components/TimeInput";
import ResponsiveGridTable from "../../../libraries/tableLayout/ResponsiveGridTable";
import { TableDisplayType } from "../../../libraries/tableLayout/TableColumn";

interface IProps {
  visits: Array<FieldArrayWithId<IProjectFormData, "visits", "id">>;
  crews: ICrew[];
  register: UseFormRegister<IProjectFormData>;
  control: Control<IProjectFormData, unknown>;
  remove: UseFieldArrayRemove;
  showDelete: boolean;
  existingProjectVisits: Array<ProjectVisitExisting>;
}

const ProjectVisitGrid: React.FunctionComponent<IProps> = ({
  visits,
  crews,
  register,
  control,
  remove,
  showDelete,
  existingProjectVisits,
}) => {
  const hasTimeBasedCrew = crews.some(
    (c) => !c.inactive && c.scheduleType === CrewScheduleType.time
  );

  return (
    <>
      <ResponsiveGridTable
        breakpoint="lg"
        rows={visits}
        columnGap="10px"
        gridStyles={{ alignItems: "baseline" }}
        mobileHeader={(arg, index) => {
          return (
            <>
              <h6 className="d-flex justify-content-between align-items-baseline">
                <div>Visit {index + 1}</div>
                <div className="pt-0">
                  <DeleteVisit
                    remove={remove}
                    index={index}
                    className="p-0"
                    showDelete={showDelete}
                    existingProjectVisits={existingProjectVisits}
                    originalVisitId={visits[index].originalId}
                  />
                </div>
              </h6>
            </>
          );
        }}
        columns={[
          {
            key: "index",
            header: () => "",
            cell: ({ index }) => <div>{index + 1}.</div>,
            width: "min-content",
            hideOnMobile: true,
          },
          {
            key: "flexible",
            header: ({ displayType }) => (
              <div>{displayType !== "mobile" ? "Flexible?" : ""}</div>
            ),
            cell: ({ index, displayType }) => (
              <FlexibleJobCheckbox
                control={control}
                index={index}
                register={register}
                displayType={displayType}
              />
            ),
            width: "min-content",
          },
          {
            key: "crew",
            header: () => "Crew",
            cell: ({ index, displayType }) => (
              <CrewSelect
                crews={crews}
                control={control}
                index={index}
                register={register}
                displayType={displayType}
              />
            ),
            width: "auto",
            hidden: crews.length < 2,
          },
          {
            key: "date",
            header: ({ displayType }) => (
              <div>{displayType !== "mobile" ? "Date" : ""}</div>
            ),
            cell: ({ index, displayType }) => (
              <DateInput
                control={control}
                index={index}
                displayType={displayType}
              />
            ),
            width: "auto",
          },
          {
            key: "startTime",
            header: () => "Start time",
            cell: ({ index, displayType }) => (
              <Time
                property="startTime"
                crews={crews}
                control={control}
                index={index}
                displayType={displayType}
              />
            ),
            width: "auto",
            hidden: !hasTimeBasedCrew,
          },
          {
            key: "endTime",
            header: () => "End time",
            cell: ({ index, displayType }) => (
              <Time
                property="endTime"
                crews={crews}
                control={control}
                index={index}
                displayType={displayType}
              />
            ),
            width: "auto",
            hidden: !hasTimeBasedCrew,
          },
          {
            key: "delete",
            header: () => "",
            cell: ({ index }) => (
              <>
                <DeleteVisit
                  remove={remove}
                  index={index}
                  className="pr-0"
                  showDelete={showDelete}
                  existingProjectVisits={existingProjectVisits}
                  originalVisitId={visits[index].originalId}
                />
              </>
            ),
            width: "min-content",
            hideOnMobile: true,
          },
        ]}
      />
    </>
  );
};

const Time: React.FunctionComponent<{
  property: string;
  crews: ICrew[];
  control: Control<IProjectFormData, unknown>;
  index: number;
  displayType: TableDisplayType;
}> = ({ property, crews, control, index, displayType }) => {
  let name =
    property === "startTime"
      ? (`visits.${index}.startTime` as const)
      : (`visits.${index}.endTime` as const);

  const crewId = useWatch({
    control,
    name: `visits.${index}.crewId`,
  });

  const isTimeBasedCrew = crews.some(
    (c) => c.id === crewId && c.scheduleType === CrewScheduleType.time
  );
  const flexibleJob = useWatch({
    control,
    name: `visits.${index}.flexibleJob` as const,
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TimeInput
          className="form-control"
          data-testid={getInputElementId({
            property,
            index,
            displayType,
          })}
          value={!flexibleJob && isTimeBasedCrew ? field.value ?? "" : ""}
          required={isTimeBasedCrew && !flexibleJob}
          id={getInputElementId({
            property,
            index,
            displayType,
          })}
          onChange={(time) => {
            field.onChange(time);
          }}
          disabled={!isTimeBasedCrew || flexibleJob}
        />
      )}
    />
  );
};

const CrewSelect: React.FunctionComponent<{
  crews: ICrew[];
  control: Control<IProjectFormData, unknown>;
  index: number;
  register: UseFormRegister<IProjectFormData>;
  displayType: TableDisplayType;
}> = ({ crews, control, index, register, displayType }) => {
  const crewId = useWatch({
    control,
    name: `visits.${index}.crewId` as const,
  });
  const flexibleJob = useWatch({
    control,
    name: `visits.${index}.flexibleJob` as const,
  });

  return crews.length > 1 ? (
    <>
      <Controller
        control={control}
        name={`visits.${index}.crewId` as const}
        render={({ field }) => (
          <select
            data-testid={getInputElementId({
              property: "crew",
              index,
              displayType,
            })}
            className="form-control"
            disabled={flexibleJob}
            value={field.value}
            onChange={(e) => field.onChange(e.currentTarget.value)}
            title={flexibleJob ? "Not applicable for flexible jobs" : undefined}
          >
            {flexibleJob ? (
              <option value={crewId ?? ""}>
                Not applicable for flexible jobs
              </option>
            ) : (
              getSortedCrews(crews)
                .filter((c) => !c.inactive || c.id === crewId)
                .map((crew) => (
                  <option key={crew.id} value={crew.id}>
                    {crew.name}
                  </option>
                ))
            )}
          </select>
        )}
      />
    </>
  ) : null;
};

const DateInput: React.FunctionComponent<{
  control: Control<IProjectFormData, unknown>;
  index: number;
  displayType: TableDisplayType;
}> = ({ control, index, displayType }) => {
  const flexibleJob = useWatch({
    control,
    name: `visits.${index}.flexibleJob` as const,
  });
  const dayPickerElement: React.RefObject<HTMLLabelElement> = React.createRef();

  return flexibleJob ? (
    <>
      {displayType === "mobile" ? (
        <label htmlFor="date" ref={dayPickerElement}>
          Starting week
        </label>
      ) : null}
      <Controller
        name={`visits.${index}.date` as const}
        control={control}
        render={({ field }) => (
          <WeekPicker
            labelRef={dayPickerElement}
            scrollWithoutLabel={true}
            preventMobileView={true}
            required={true}
            value={
              !!field.value ? dateFnsFormat(field.value, dayPickerFormat) : ""
            }
            inputId={getInputElementId({
              property: "date",
              index,
              displayType,
            })}
            testId={getInputElementId({ property: "date", index, displayType })}
            onWeekSelected={(day: Date) => {
              let selectedWeekStart = "";

              if (!!day) {
                const parsedDate = dateFnsFormat(day, dayPickerFormat);
                selectedWeekStart = dateService.formatAsIso(
                  startOfWeek(parsedDate)
                );
              }

              field.onChange(selectedWeekStart);
            }}
          />
        )}
      ></Controller>
    </>
  ) : (
    <>
      {displayType === "mobile" ? (
        <label htmlFor="date" ref={dayPickerElement}>
          Date
        </label>
      ) : null}
      <Controller
        name={`visits.${index}.date` as const}
        control={control}
        render={({ field }) => (
          <DayPicker
            labelRef={dayPickerElement}
            scrollWithoutLabel={true}
            preventMobileView={true}
            onDayPickerHide={() => null}
            dayPickerProps={{}}
            value={
              !!field.value ? dateFnsFormat(field.value, dayPickerFormat) : ""
            }
            required={true}
            inputId={getInputElementId({
              property: "date",
              index,
              displayType,
            })}
            testId={getInputElementId({ property: "date", index, displayType })}
            onDaySelected={(day: Date) => {
              let newValue = "";

              if (!!day) {
                newValue = dateFnsFormat(day, dayPickerFormat);
              }

              field.onChange(newValue);
            }}
          />
        )}
      ></Controller>
    </>
  );
};

const FlexibleJobCheckbox: React.FunctionComponent<{
  index: number;
  control: Control<IProjectFormData, unknown>;
  register: UseFormRegister<IProjectFormData>;
  displayType: TableDisplayType;
}> = ({ index, control, register, displayType }) => {
  return (
    <div
      className="custom-control custom-checkbox"
      style={{ alignContent: "center" }}
    >
      <Controller
        control={control}
        name={`visits.${index}.flexibleJob` as const}
        render={({ field }) => (
          <input
            type="checkbox"
            id={getInputElementId({ property: "flexible", index, displayType })}
            data-testid={getInputElementId({
              property: "flexible",
              index,
              displayType,
            })}
            className="custom-control-input"
            checked={field.value}
            onChange={(e) => field.onChange(e.currentTarget.checked)}
          />
        )}
      />

      <label
        className="custom-control-label"
        style={{ alignContent: "center" }}
        htmlFor={getInputElementId({
          property: "flexible",
          index,
          displayType,
        })}
      >
        {displayType === "mobile" ? <span>Flexible job?</span> : <></>}
      </label>
    </div>
  );
};

function DeleteVisit({
  remove,
  index,
  className,
  showDelete,
  originalVisitId,
  existingProjectVisits,
}: {
  remove: UseFieldArrayRemove;
  index: number;
  className: string;
  showDelete: boolean;
  originalVisitId: string | null;
  existingProjectVisits: Array<ProjectVisitExisting>;
}) {
  const hasVisitCompleted =
    typeof originalVisitId === "string"
      ? existingProjectVisits.find((v) => v.id === originalVisitId)
          ?.completed ?? false
      : false;

  return showDelete ? (
    <button
      type="button"
      className={`btn btn-sm ${className}`}
      onClick={() => {
        remove(index);
      }}
      style={{ zIndex: 0 }}
      disabled={hasVisitCompleted}
    >
      <FontAwesomeIcon
        icon={faTrash}
        title={
          hasVisitCompleted
            ? "Completed visits cannot be removed"
            : "Remove visit"
        }
      />
    </button>
  ) : null;
}

function getInputElementId({
  property,
  index,
  displayType,
}: {
  property: string;
  index: number;
  displayType: string;
}) {
  return `visit_${property}_${index}_${displayType}`;
}

export default ProjectVisitGrid;
