import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const buildChevron = (
  disabled: boolean | undefined,
  icon: IconProp,
  onExpandedChange: () => void,
  onClick?: (event: React.MouseEvent) => void,
  testId?: string
) => {
  return (
    <button
      type="button"
      className={"link-button"}
      onClick={(e) => {
        e.stopPropagation();
        onExpandedChange();

        if (onClick) {
          onClick(e);
        }
      }}
      style={{ width: "100%", cursor: !disabled ? "pointer" : "default" }}
      disabled={disabled || false}
    >
      <FontAwesomeIcon icon={icon} data-testid={testId} />
    </button>
  );
};

interface IProps {
  expanded: boolean;
  onExpandedChange(newValue: boolean): void;
  onClick?(event: React.MouseEvent): void;
  disabled?: boolean;
  testId?: string;
}

const ExpansionChevron: React.FunctionComponent<IProps> = ({
  expanded,
  onExpandedChange,
  disabled,
  onClick,
  testId,
}) => {
  return expanded
    ? buildChevron(
        disabled,
        faChevronUp,
        () => onExpandedChange(false),
        onClick,
        testId
      )
    : buildChevron(
        disabled,
        faChevronDown,
        () => onExpandedChange(true),
        onClick,
        testId
      );
};

export default ExpansionChevron;
