import { useDispatch } from "react-redux";
import { LockedOutPage } from "./LockedOutPage";
import { actionCreators } from "../../../modules/actionCreators";

export function LockedOutForCancellationPage() {
  const dispatch = useDispatch();

  return (
    <LockedOutPage
      header={<>Welcome back!</>}
      subHeader="Looks like your subscription has ended.  Let's get you back up and running."
      button={
        <button
          className="text-nowrap btn border border-dark mb-3"
          type="button"
          style={{
            background: "#ffc107",
          }}
          onClick={() => {
            dispatch(actionCreators.forms.subscription.showForm({}));
          }}
        >
          <b>Sign Up Now</b>
        </button>
      }
      errorMessage={null}
    />
  );
}
