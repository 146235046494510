import { useState } from "react";
import WrappedCreatableSelect from "../../../../containers/app/components/WrappedCreateableSelect";
import { IProposalTemplate } from "../../models/IProposalTemplate";
import ProposalTemplateForm from "../ProposalTemplateForm";
import { IInvoiceItem } from "../../../../models/IInvoiceItem";
import { GroupBase, OptionProps, components } from "react-select";
import { containsText } from "../../../../services/stringService";
import { WrappedCreatableSelectOption } from "../../../../containers/app/components/WrappedCreatableSelectOption";

interface IProps {
  templates: Array<IProposalTemplate>;
  invoiceItems: IInvoiceItem[];
  setInvoiceItems: (newValue: Array<IInvoiceItem>) => void;
  onSaveComplete: (id: string, created: boolean) => void;
  onTemplateSelection: (template: IProposalTemplate) => void;
  selectedTemplate: Partial<IProposalTemplate> | null;
  setSelectedTemplate: (template: Partial<IProposalTemplate> | null) => void;
}

const ProposalTemplateSelection: React.FunctionComponent<IProps> = ({
  templates,
  invoiceItems,
  setInvoiceItems,
  onSaveComplete,
  onTemplateSelection,
  selectedTemplate,
  setSelectedTemplate,
}) => {
  const [showProposalTemplateForm, setShowProposalTemplateForm] =
    useState(false);
  const option = (
    props: OptionProps<
      Partial<IProposalTemplate>,
      false,
      GroupBase<Partial<IProposalTemplate>>
    >
  ) => {
    const template = props.data as IProposalTemplate;
    const text = template.name;

    return (
      <components.Option {...props}>
        <div>
          <WrappedCreatableSelectOption
            isNewItem={!template.id}
            name={text}
            recordType="template"
          />
        </div>
      </components.Option>
    );
  };

  const handleChange = (newValue: any, actionMeta: any) => {
    const selectedTemplate = newValue as IProposalTemplate;
    setSelectedTemplate(selectedTemplate);

    if (actionMeta.action === "create-option") {
      setShowProposalTemplateForm(true);
    } else if (actionMeta.action === "select-option") {
      const selectedTemplate = newValue as IProposalTemplate;
      onTemplateSelection(selectedTemplate);
    }
  };

  function getExistingItem(templateName: string) {
    return templates.find(
      (c) => c.name.trim().toLowerCase() === templateName.trim().toLowerCase()
    );
  }

  function isMatchingItem(item: Partial<IProposalTemplate>, input: string) {
    const result = containsText(item?.name, input);

    return result;
  }

  return (
    <>
      <div className={!selectedTemplate?.id ? "form-group" : "mb-1"}>
        <label htmlFor="proposalTemplateSelectionInput">
          Populate from template
        </label>
        <WrappedCreatableSelect<Partial<IProposalTemplate>>
          placeholder="Start typing to find templates or add a new one..."
          inputId={"proposalTemplateSelectionInput"}
          value={
            typeof selectedTemplate === "object"
              ? templates.find((t) => t.id === selectedTemplate?.id) ?? null
              : null
          }
          isClearable
          onChange={handleChange}
          isValidNewOption={(inputValue) => !getExistingItem(inputValue)}
          createOptionPosition="first"
          components={{ Option: option }}
          isMulti={false}
          getOptionLabel={(opt) => {
            return opt.name ?? "Default Template";
          }}
          getOptionValue={(c) => c.id ?? ""}
          styles={{
            menu: (styles) => {
              return {
                ...styles,
                zIndex: 3,
              };
            },
          }}
          getNewOptionData={(text) =>
            ({
              name: (text ?? "").trim(),
              depositSettings: null,
              isPrimaryTemplate: !templates.some((t) => t.isPrimaryTemplate),
            } as Partial<IProposalTemplate>)
          }
          options={templates}
          onInputChange={() => {}}
          onFocus={() => {}}
          filterOption={(option, input) =>
            !option.value || // is new
            isMatchingItem(option.data, input)
          }
          isLoading={false}
          onLeaveInputField={(templateName) => {
            let existingItem = getExistingItem(templateName);
            if (existingItem) {
              setSelectedTemplate(existingItem);
              onTemplateSelection(existingItem);
            } else {
              // Hack warning.  Without this code, if the user clicked into a drop-down list on the containing form,
              // the drop-down list would stay open even though the custom form was also opened.  Appears need to leave focus from the select to a control on the existing form
              // and then open the template form.  setTimeout is needed so for a slight delay.
              setTimeout(() => {
                setSelectedTemplate({
                  name: templateName,
                  depositSettings: null,
                } as Partial<IProposalTemplate>);
                setShowProposalTemplateForm(true);
              });
            }
          }}
        ></WrappedCreatableSelect>
        {selectedTemplate?.id ? (
          <button
            type="button"
            className="btn btn-sm btn-link"
            onClick={() => setShowProposalTemplateForm(true)}
          >
            Edit template
          </button>
        ) : null}
        {showProposalTemplateForm ? (
          <ProposalTemplateForm
            template={
              selectedTemplate?.id
                ? templates.find((t) => t.id === selectedTemplate.id) ?? null
                : selectedTemplate
            }
            invoiceItems={invoiceItems}
            setInvoiceItems={setInvoiceItems}
            onSaveComplete={(id, created) => {
              onSaveComplete(id, created);
              setSelectedTemplate({ id: id, ...selectedTemplate });
              setShowProposalTemplateForm(false);
            }}
            onCancel={() => setShowProposalTemplateForm(false)}
          />
        ) : null}
      </div>
    </>
  );
};

export default ProposalTemplateSelection;
