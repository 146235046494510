import { actionTypes } from "./actionCreators";
import { IDistance } from "../models/IDistance";
import { areDistancesEqual } from "../services/distanceService";

export interface IDistanceCacheState {
  distances: Array<IDistance>;
}

export default (
  state: IDistanceCacheState | undefined,
  action: any
): IDistanceCacheState => {
  if (!state) {
    state = {
      distances: []
    };
  }

  switch (action.type) {
    case actionTypes.LOAD_DISTANCES_STARTING:
      return {
        ...state,
        distances: [
          ...state.distances,
          ...action.distancesToLoad
            .filter((actionDistance: IDistance) => {
              const existingDistance = (state as IDistanceCacheState).distances.find(
                stateDistance => {
                  return areDistancesEqual(actionDistance, stateDistance);
                }
              );
              return !existingDistance;
            })
            .map((actionDistance: IDistance) => ({
              ...actionDistance,
              loading: true,
              startLoadingTime: new Date()
            }))
        ]
      };

    case actionTypes.LOAD_DISTANCES_COMPLETED:
      const result = {
        ...state,
        distances: [
          ...state.distances.map(d => {
            const updateToDistance = action.distancesLoaded.find(
              (dl: IDistance) => areDistancesEqual(d, dl)
            );
            if (updateToDistance) {
              return {
                ...d,
                loading: false,
                distanceInSeconds: updateToDistance.distanceInSeconds,
                errorLoadingDistance: updateToDistance.errorLoadingDistance,
                startLoadingTime: undefined
              };
            } else {
              return d;
            }
          })
        ]
      };

      return result;

    default:
      return state;
  }
};
