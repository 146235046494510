import addDays from "date-fns/add_days";
import { IInvoiceConfiguration } from "../models/IInvoiceConfiguration";
import { round } from "./roundingService";

export function getTotalHoursForJobInstances(
  jobInstances: Array<{ actualManHours?: number }>
) {
  return round(
    jobInstances.reduce((acc, ji) => acc + (ji.actualManHours ?? 0), 0),
    1
  );
}

export function getInvoiceDueDate(
  invoiceDate: Date,
  invoiceConfiguration: IInvoiceConfiguration
): Date | null {
  if (
    typeof invoiceConfiguration.invoiceDefaultNumberOfDaysValid === "number"
  ) {
    return addDays(
      invoiceDate,
      invoiceConfiguration.invoiceDefaultNumberOfDaysValid
    );
  }

  return null;
}
