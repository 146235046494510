import React, { useState } from "react";
import {
  ICustomerOpportunitiesFilters,
  OpportunityStatus,
} from "./CustomerOpportunities";
import CustomerAdditionalLocationSelection from "../../components/CustomerAdditionalLocationSelection";
import DateFilterHeadless from "../../components/DateFilterHeadless";
import { updateDatesOnFilter } from "../../../../services/dateFilterService";

export default function CustomerOpportunitiesFilter({
  filters,
  setFilters,
  customerId,
  showLocationFilter,
}: {
  filters: ICustomerOpportunitiesFilters;
  setFilters: React.Dispatch<
    React.SetStateAction<ICustomerOpportunitiesFilters>
  >;
  customerId: string;
  showLocationFilter: boolean;
}) {
  const [dateFilterErrorMessage, setDateFilterErrorMessage] = useState("");

  const archivedCheckbox = (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        className="custom-control-input"
        id="showArchived"
        checked={filters.showArchived}
        onChange={(e) =>
          setFilters({
            ...filters,
            showArchived: e.currentTarget.checked,
          })
        }
      />
      <label
        className="custom-control-label text-nowrap"
        htmlFor="showArchived"
      >
        Show archived
      </label>
    </div>
  );

  return (
    <>
      <DateFilterHeadless
        showAllDates
        filters={filters.dates}
        onFiltersChanged={(newFilters) => {
          newFilters = updateDatesOnFilter(newFilters);
          setFilters({
            ...filters,
            dates: newFilters,
          });
        }}
        setErrorMessage={setDateFilterErrorMessage}
      >
        {({ dateRangeElements, customDateElements }) => (
          <>
            <div className="form-row align-items-center">
              <div className="col-12 col-sm-6 col-sm-4 col-md-3 form-group">
                <label htmlFor="opportunityStatus">Opportunity status</label>
                <select
                  className="form-control"
                  id="opportunityStatus"
                  value={filters.opportunityStatus}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      opportunityStatus: e.currentTarget
                        .value as OpportunityStatus,
                    })
                  }
                >
                  <option value=""></option>
                  <OpportunityStatusFilterOption text="To Do" />
                  <OpportunityStatusFilterOption text="In Progress" />
                  <OpportunityStatusFilterOption text="Proposal Pending" />
                  <OpportunityStatusFilterOption text="Proposal Accepted" />
                  <OpportunityStatusFilterOption text="Proposal Scheduled" />
                  <OpportunityStatusFilterOption text="Proposal Rejected" />
                </select>
              </div>

              {showLocationFilter ? (
                <div className="col-12 col-sm-6 col-sm-4 col-md-3 form-group">
                  <label htmlFor="jobLocationFilter">Job location</label>
                  <CustomerAdditionalLocationSelection
                    inputId="jobLocationFilter"
                    placeholder="Select..."
                    disableAddEdit={true}
                    includeCustomerAddress={true}
                    value={filters.locationId}
                    customerId={customerId}
                    onCustomerAdditionalLocationSelection={(e) =>
                      setFilters({
                        ...filters,
                        locationId: e,
                      })
                    }
                  />
                </div>
              ) : null}

              <div className="col-12 col-sm-12 col-md-6 col-lg-4 form-group">
                {dateRangeElements}
              </div>

              <div
                className="d-none d-xl-block col-xl-2 form-group"
                style={{ marginTop: "30px" }}
              >
                {archivedCheckbox}
              </div>
            </div>

            <div className="form-row align-items-center">
              {customDateElements !== null ? (
                <>
                  <div className="col-6 col-sm-6 col-md-3 offset-md-4 offset-xl-6 col-lg-2 form-group">
                    {customDateElements.startingDateElement}
                  </div>
                  <div className="col-6 col-sm-6 col-md-3 col-lg-2 form-group">
                    {customDateElements.endingDateElement}
                  </div>
                </>
              ) : null}

              <div className="col-12 col-md-2 form-group d-xl-none order-md-first">
                {archivedCheckbox}
              </div>
            </div>

            {dateFilterErrorMessage ? (
              <div className="form-row align-items-center">
                <div className="col-12 offset-md-6 offset-xl-6 col-lg-4 text-danger">
                  {dateFilterErrorMessage}
                </div>
              </div>
            ) : null}
          </>
        )}
      </DateFilterHeadless>
    </>
  );
}

function OpportunityStatusFilterOption({ text }: { text: OpportunityStatus }) {
  return <option value={text}>{text}</option>;
}
