import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { actionTypes } from "../../../modules/actionCreators";
import {
  IAction,
  ICustomerClearJobsComplete,
} from "../../../modules/actionTypeDefinitions";
import { IProject } from "../models/IProject";
import { ICachedItem } from "../../../models/ICachedItem";

export type CachedProject = IProject & ICachedItem;

export interface IProjectState {
  projects: Array<CachedProject>;
  projectsLoading: Array<string>;
}

const initialState: IProjectState = {
  projects: [],
  projectsLoading: [],
};

const { reducer, actions } = createSlice({
  initialState,
  name: "project",
  reducers: {
    loadProjectsComplete: (
      state,
      action: PayloadAction<{ projects: Array<IProject> }>
    ) => {
      state.projects = [
        ...state.projects.filter((originalProject) => {
          const projectInPayload = action.payload.projects.some(
            (newProject) => newProject.id === originalProject.id
          );
          return !projectInPayload;
        }),
        ...action.payload.projects.map((p) => ({ ...p, stale: false })),
      ];
      state.projectsLoading = state.projectsLoading.filter(
        (projectLoadingId) =>
          !action.payload.projects.some((p) => p.id === projectLoadingId)
      );
    },

    updateProject: (state, action: PayloadAction<{ newProject: IProject }>) => {
      state.projects = state.projects.filter(
        (p) => p.id !== action.payload.newProject.id
      );
      state.projects = [
        ...state.projects,
        { ...action.payload.newProject, stale: false },
      ];
    },

    // Just used for actions that are fed to epic
    loadProjectsStart: (
      state,
      action: PayloadAction<{ projectIds: Array<string> }>
    ) => {
      state.projectsLoading = [
        ...state.projectsLoading,
        ...action.payload.projectIds,
      ];
    },

    removeProjects: (
      state,
      action: PayloadAction<{ projectIds: Array<string> }>
    ) => {
      state.projects = state.projects.filter(
        (p) => !action.payload.projectIds.includes(p.id)
      );
    },

    // Just used for actions that are fed to epic
    reloadProjects: (
      state,
      action: PayloadAction<{ projectIds: Array<string> }>
    ) => {},

    reloadProjectsComplete: (
      state,
      action: PayloadAction<{ projects: Array<IProject> }>
    ) => {
      state.projects = state.projects.filter(
        (p) => !action.payload.projects.map((p) => p.id).includes(p.id)
      );
      state.projects = [
        ...state.projects,
        ...action.payload.projects.map((p) => ({
          ...p,
          stale: false,
        })),
      ];
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(isCustomerClearJobsCompleteAction, (state, action) => {
      state.projects = state.projects.filter(
        (p) => !action.removedProjects.includes(p.id)
      );
    });

    builder.addMatcher(isMarkLocalCacheStale, (state) => {
      state.projects = state.projects.map((p) => ({ ...p, stale: true }));
    });
  },
});

function isCustomerClearJobsCompleteAction(
  action: IAction
): action is ICustomerClearJobsComplete {
  return action.type === actionTypes.CUSTOMER_CLEAR_JOBS_COMPLETE;
}

function isMarkLocalCacheStale(action: IAction): action is IAction {
  return action.type === actionTypes.MARK_LOCAL_CACHE_STALE;
}

export const projectActionCreators = actions;

export default reducer;
