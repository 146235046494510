import { DateFilterOptions } from "../enums/dateFilterOptions";
import dateService from "./dateService";
import { GetUserSettingsType } from "./userSettingsService";
import { UserSettingsType } from "../enums/userSettingsType";

interface IFilters {
  frequency: DateFilterOptions;
  startingDate: string | null;
  endingDate: string | null;
}

export function updateDatesOnFilter(newFilters: IFilters) {
  if (newFilters.frequency === DateFilterOptions.all) {
    newFilters = {
      ...newFilters,
      startingDate: null,
      endingDate: null,
    };
  } else if (newFilters.frequency !== DateFilterOptions.custom) {
    const { startingDate, endingDate } =
      dateService.getDatesFromDateFilterOptions(newFilters.frequency);
    newFilters = {
      ...newFilters,
      startingDate,
      endingDate,
    };
  }
  return newFilters;
}

export function getDefaultFilters<T extends IFilters>(
  getUserSettings: GetUserSettingsType,
  settingsKey: UserSettingsType,
  defaults?: Partial<T>
): IFilters {
  const savedFilters = getUserSettings<IFilters>(settingsKey);
  if (savedFilters) {
    return updateDatesOnFilter(savedFilters);
  } else {
    return {
      frequency: DateFilterOptions.last30Days,
      startingDate: dateService.getDatesFromDateFilterOptions(
        DateFilterOptions.last30Days
      ).startingDate,
      endingDate: dateService.getDatesFromDateFilterOptions(
        DateFilterOptions.last30Days
      ).endingDate,
      ...(defaults ?? {}),
    };
  }
}
