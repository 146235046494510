import { ILineItem } from "../../../containers/app/components/InvoiceLineItem";
import { InvoiceItemField } from "../../../containers/app/components/InvoiceLineItemInvoiceItemField";
import { IInvoiceItem } from "../../../models/IInvoiceItem";

interface IProps {
  lineItem: ILineItem;
  invoiceItems: Array<IInvoiceItem>;
  setInvoiceItems: React.Dispatch<React.SetStateAction<IInvoiceItem[]>>;
  onChange(newValue: ILineItem): void;
}

const FixInvoiceForQuickBooksItem: React.FunctionComponent<IProps> = ({
  lineItem,
  invoiceItems,
  setInvoiceItems,
  onChange,
}) => {
  return (
    <InvoiceItemField
      lineItem={lineItem}
      onLineItemChanged={(changedItem) => {
        onChange({
          ...lineItem,
          itemId: changedItem.itemId,
          name: changedItem.name,
          description: changedItem.description,
        });
      }}
      ariaLabel="Invoice item"
      items={invoiceItems}
      onClearErrorMessage={() => {}}
      jobInstances={[]}
      selectedJobInstances={[]}
      setInvoiceItems={setInvoiceItems}
      inputElementId={lineItem.id}
      onItemAdded={(id, name, unitPrice, description, taxable) => {
        onChange({
          ...lineItem,
          itemId: id,
          name,
          description,
          taxable,
          amountPerItem: unitPrice ? unitPrice.toString() : "",
        });

        setInvoiceItems([
          ...(invoiceItems ?? []),
          {
            id,
            name,
            description,
            taxable,
            unitPrice,
            inactive: false,
          },
        ]);
      }}
      onItemUpdated={({ id, name, description, inactive, unitPrice }) => {
        setInvoiceItems(
          (invoiceItems ?? []).map((i) => {
            if (i.id === id) {
              return {
                ...i,
                name,
                description,
                inactive,
                unitPrice,
              };
            }

            return i;
          })
        );

        onChange({
          ...lineItem,
          itemId: id,
          name: name,
          description: description,
        });
      }}
      scrollIntoView
    />
  );
};

export default FixInvoiceForQuickBooksItem;
