import { actionCreators } from "../modules/actionCreators";
import { IDropJobAction, IAction } from "../modules/actionTypeDefinitions";
import dateService from "./dateService";
import { IApplicationState } from "../modules";
import { getMaintenanceJobs } from "./jobInstanceService";
import constants from "../constants";
import {
  IMaintenanceJob,
  MaintenanceJobFrequency,
} from "../models/IMaintenanceJob";
import { IDaySchedule } from "../models/IDaySchedule";
import { parse } from "date-fns";
import { MaintenanceJobChangeType } from "../enums/maintenanceJobChangeType";

export function getPermanentJobsSetMessage(
  dropJobAction: IDropJobAction,
  state: IApplicationState
): IAction {
  let precedingJobId = constants.idForFirstJob;
  if (!dropJobAction.destinationFlexibleJob) {
    const daySchedule = state.schedule.daySchedules.find(
      (ds) => ds.id === dropJobAction.destinationDayScheduleId
    );
    if (!daySchedule) {
      throw new Error(
        `unable to find schedule for ${dropJobAction.destinationDayScheduleId}`
      );
    }

    if (
      dropJobAction.destinationPrecedingJobInstanceId !==
      constants.idForFirstJob
    ) {
      precedingJobId = daySchedule.jobInstances.reduce(
        (acc, current) => {
          let result = acc.result;
          let passedDestinationPrecedingJobInstanceId =
            acc.passedDestinationPrecedingJobInstanceId;

          if (!acc.passedDestinationPrecedingJobInstanceId) {
            if (current.jobId) {
              if (current.wasAutoGenerated) {
                result = current.jobId;
              }
            }

            if (
              !passedDestinationPrecedingJobInstanceId &&
              current.id === dropJobAction.destinationPrecedingJobInstanceId
            ) {
              passedDestinationPrecedingJobInstanceId = true;
            }
          }

          return {
            result,
            passedDestinationPrecedingJobInstanceId,
          };
        },
        {
          passedDestinationPrecedingJobInstanceId: false,
          result: constants.idForFirstJob,
        } as IPrecedingMaintenanceJobAcc
      ).result;
    }
  }

  const maintenanceJobs = getMaintenanceJobs(
    dropJobAction.jobInstanceIds,
    state.schedule.daySchedules,
    state.schedule.weeksUnscheduledMaintenanceJobs,
    state.job.jobs,
    state.job.oneTimeJobs
  );

  let includedMaintenanceJobIds: Array<string>;
  let excludedMaintenanceJobIds: Array<string>;
  if (dropJobAction.destinationFlexibleJob) {
    includedMaintenanceJobIds = maintenanceJobs
      .filter((j) => !isCustomDailyJob(j))
      .map((foundJob) => foundJob?.id as string);

    excludedMaintenanceJobIds = maintenanceJobs
      .filter((j) => isCustomDailyJob(j))
      .map((foundJob) => foundJob?.id as string);
  } else {
    includedMaintenanceJobIds = maintenanceJobs.map((j) => j.id);
    excludedMaintenanceJobIds = [];
  }

  if (includedMaintenanceJobIds.length > 0) {
    return actionCreators.setPermanentJobsMessage({
      precedingJobId,
      maintenanceJobIds: includedMaintenanceJobIds,
      excludedMaintenanceJobIds,
      destinationDate: dropJobAction.destinationFlexibleJob
        ? dateService.formatAsIso(
            dropJobAction.destinationFlexibleJobWeek as Date
          )
        : dateService.formatAsIso(
            dropJobAction.destinationDayScheduleDate as Date
          ),
      destinationCrewId: dropJobAction.destinationDayCrewId,
      destinationFlexible: dropJobAction.destinationFlexibleJob,
      daySchedulesToExcludeIds: dropJobAction.destinationDayScheduleId
        ? [dropJobAction.destinationDayScheduleId]
        : [],
      jobInstancesToResetWasAutoGenerated: dropJobAction.jobInstanceIds,
      maintenanceJobChangeType:
        MaintenanceJobChangeType.DraggedAppliedFutureWeeks,
    });
  } else {
    return actionCreators.null();
  }
}

export function getDestinationProperties(
  destinationIndex: number,
  daySchedules: IDaySchedule[],
  destinationDayScheduleId: string | null,
  sourceDayScheduleId: string | null,
  sourceIndex: number
) {
  let destinationPrecedingJobInstanceId: string | null = null;
  let destinationDayScheduleDate: Date | null = null;
  let destinationDayCrewId: string | null = null;

  const destinationDaySchedule = daySchedules.find(
    (ds) => ds.id === destinationDayScheduleId
  );

  if (!destinationDaySchedule) {
    throw new Error(
      `Unable to find day schedule for '${destinationDayScheduleId}'`
    );
  }

  destinationDayScheduleDate = parse(destinationDaySchedule.date);
  destinationDayCrewId = destinationDaySchedule.crewId;
  if (destinationIndex === 0) {
    destinationPrecedingJobInstanceId = constants.idForFirstJob;
  } else {
    // TODO: Add tests, especially with getting preceding job instance for jobs on the same day
    if (
      destinationDayScheduleId !== sourceDayScheduleId ||
      sourceIndex > destinationIndex
    ) {
      destinationPrecedingJobInstanceId =
        destinationDaySchedule.jobInstances[destinationIndex - 1].id;
    } else {
      destinationPrecedingJobInstanceId =
        destinationDaySchedule.jobInstances[destinationIndex].id;
    }
  }
  return {
    destinationPrecedingJobInstanceId,
    destinationDayScheduleDate,
    destinationDayCrewId,
  };
}

interface IPrecedingMaintenanceJobAcc {
  passedDestinationPrecedingJobInstanceId: boolean;
  result: string;
}

export function isCustomDailyJob(job: IMaintenanceJob) {
  return (
    (job.frequency === MaintenanceJobFrequency.Custom &&
      job.customFrequencyPeriod === 0) ||
    (job.seasonalScheduleFrequency === MaintenanceJobFrequency.Custom &&
      job.seasonalScheduleCustomFrequencyPeriod === 0)
  );
}
