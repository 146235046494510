export function getUniqueItems(items: Array<string>) {
  return items.filter((value, index, self) => self.indexOf(value) === index);
}

export function haveArraysChanged(
  itemsA: Array<string>,
  itemsB: Array<string>
) {
  if (itemsA?.length !== itemsB?.length) {
    return true;
  }

  if (itemsA.some((a) => !itemsB.includes(a))) {
    return true;
  }

  if (itemsB.some((b) => !itemsA.includes(b))) {
    return true;
  }

  return false;
}

export function getItemsRemoved(
  oldArray: Array<string>,
  newArray: Array<string>
) {
  return oldArray.filter((b) => !newArray.includes(b));
}
