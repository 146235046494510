import { Dispatch, useCallback } from "react";
import { UseFormSetValue } from "react-hook-form";
import { useLoadInvoiceData } from "../../../hooks/useLoadInvoiceData";
import {
  IInvoiceJobInstance,
  IInvoiceFormData,
  IInvoiceProject,
  formActionCreators,
} from "./InvoiceForm.types";

export function useLoadData({
  showForm,
  jobInstances,
  customerId,
  invoiceId,
  dispatch,
  setValue,
  projects,
  setPaymentMethodPreviouslyAuthorized,
}: {
  showForm: boolean;
  jobInstances: IInvoiceJobInstance[];
  customerId: string;
  invoiceId: string | null;
  dispatch: Dispatch<any>;
  setValue: UseFormSetValue<IInvoiceFormData>;
  projects: Array<IInvoiceProject> | null;
  setPaymentMethodPreviouslyAuthorized: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}) {
  const setAllowAchPayment = useCallback(
    (newValue) => setValue("paymentMethodOptions.allowAchPayment", newValue),
    [setValue]
  );
  const setAllowCreditCardPayment = useCallback(
    (newValue) =>
      setValue("paymentMethodOptions.allowCreditCardPayment", newValue),
    [setValue]
  );
  const setShowLineItemPrices = useCallback(
    (newValue) => setValue("showLineItemPrices", newValue),
    [setValue]
  );

  return useLoadInvoiceData({
    showForm,
    jobInstances,
    projects,
    customerId: customerId ?? "",
    invoiceId,
    setAllowAchPayment,
    setAllowCreditCardPayment: setAllowCreditCardPayment,
    closeForm: () => dispatch(formActionCreators.cancelForm()),
    setPaymentMethodPreviouslyAuthorized: setPaymentMethodPreviouslyAuthorized,
    setShowLineItemPrices: setShowLineItemPrices,
  });
}
