import { useState } from "react";
import FormContainerWithoutRedux from "../../../containers/app/components/FormContainerWithoutRedux";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import proposalDataProvider from "../services/proposalDataProvider";
import { map } from "rxjs/operators";
import { IProposalEmailConfiguration } from "../../../models/IProposalEmailConfiguration";
import { actionCreators } from "../../../modules/actionCreators";
import { ErrorMessageType } from "../../../containers/app/components/FormContainer";

interface IProps {
  onSaveComplete: () => void;
  onCancel: () => void;
}

interface IFormData {
  includeDetails: boolean;
}

export function CustomerProposalEmailConfigurationForm({
  onSaveComplete,
  onCancel,
}: IProps) {
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");

  const proposalEmailConfiguration = useApplicationStateSelector(
    (s) => s.common.proposalEmailConfiguration
  );

  const { getValues, register } = useForm<IFormData>({
    defaultValues: {
      includeDetails: proposalEmailConfiguration.includeDetails,
    },
  });

  const dispatch = useDispatch();

  return (
    <FormContainerWithoutRedux
      formType={FormTypesV2.customerProposalEmailConfiguration}
      formHeader="Update Proposal Email"
      onSaveComplete={(result) => {
        dispatch(actionCreators.proposalEmailConfigurationUpdated(result));
        onSaveComplete();
      }}
      onCancel={onCancel}
      save={() => {
        const configuration: IProposalEmailConfiguration = {
          ...getValues(),
        };
        return proposalDataProvider
          .updateEmailConfiguration(configuration)
          .pipe(map(() => configuration));
      }}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      <div className="form-group">
        <div className="custom-control custom-checkbox">
          <input
            id="includeDetailsInProposalEmail"
            data-testid="includeDetailsInProposalEmail"
            type="checkbox"
            className="custom-control-input"
            {...register("includeDetails")}
          />
          <label
            htmlFor="includeDetailsInProposalEmail"
            className="custom-control-label"
          >
            Include details in proposal email
          </label>
        </div>
        <small>
          This setting affects whether the proposal's introductory text, line
          items and total price are shown in the email the customer receives.
        </small>
      </div>
    </FormContainerWithoutRedux>
  );
}
