import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useUserSettings } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";

export function PaymentsTransactionTimeline() {
  const { getUserSettings, setUserSettings } = useUserSettings();
  const [expanded, setExpanded] = useState(
    getUserSettings<boolean>(
      UserSettingsType.transactionProcessingTimelinesExpansion
    ) ?? true
  );

  return (
    <div className="alert alert-info p-2">
      <small>
        <div className="d-flex justify-content-between">
          <div data-testid="timelineHeader">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
            Transaction processing timelines
          </div>
          <div>
            <button
              type="button"
              className="btn btn-link p-0 text-dark"
              onClick={() => {
                const newValue = !expanded;
                setExpanded(newValue);
                setUserSettings(
                  UserSettingsType.transactionProcessingTimelinesExpansion,
                  newValue
                );
              }}
            >
              <FontAwesomeIcon
                icon={expanded ? faChevronDown : faChevronUp}
                title={expanded ? "Collapse" : "Expand"}
              />
            </button>
          </div>
        </div>
        {expanded ? (
          <>
            <div data-testid="creditCardDetails">
              Credit card: Up to 2 business days
            </div>
            <div data-testid="achDetails">ACH: Up to 5 business days</div>
            <div className="mt-2" data-testid="cutoffDetails">
              Any transactions received after 9:30PM Eastern Time will take an
              additional day to process.
            </div>
          </>
        ) : null}
      </small>
    </div>
  );
}
