import { IRootState } from "../../store";
import { actionCreators } from "../actionCreators";
import { IRemoteDayScheduleChanged } from "../actionTypeDefinitions";
import { IDayToLoad } from "../../services/dayScheduleLoader";
import dateService from "../../services/dateService";
import { logError } from "../../services/errorLogger";
import dataProvider from "../../services/dataProvider";
import {
  getMaxUnassignedWeekAlreadyLoaded,
  loadJobsForSchedule,
} from "../../services/scheduleService";
import { mergeMap, timeout, catchError } from "rxjs/operators";
import { of } from "rxjs";

export function loadRemoteChangedSchedules(
  action: IRemoteDayScheduleChanged,
  state: IRootState
) {
  // Determine if any changed schedules are already loaded
  const existingDaySchedulesUpdated = state.schedule.daySchedules
    .filter((existingDaySchedule) =>
      action.changedSchedules.schedules
        .filter((updatedDaySchedule) => !updatedDaySchedule.isFlexibleSchedule)
        .map((updatedDaySchedule) => updatedDaySchedule.dayScheduleId)
        .includes(existingDaySchedule.id)
    )
    .map(
      (existingDaySchedule) =>
        ({
          crewId: existingDaySchedule.crewId,
          date: existingDaySchedule.date,
        } as IDayToLoad)
    );

  const existingWeekUnscheduledJobs =
    state.schedule.weeksUnscheduledMaintenanceJobs
      .filter((existingDaySchedule) =>
        action.changedSchedules.schedules
          .filter(
            (updatedDaySchedule) =>
              updatedDaySchedule.isFlexibleSchedule &&
              updatedDaySchedule.flexibleWeek
          )
          .some((updatedDaySchedule) =>
            dateService.areDatesEqual(
              updatedDaySchedule.flexibleWeek as string,
              existingDaySchedule.week
            )
          )
      )
      .map((existingDaySchedule) => existingDaySchedule.week);

  if (
    existingDaySchedulesUpdated.length > 0 ||
    existingWeekUnscheduledJobs.length > 0
  ) {
    return dataProvider
      .loadDaySchedules(
        existingDaySchedulesUpdated,
        existingWeekUnscheduledJobs,
        getMaxUnassignedWeekAlreadyLoaded(
          state.schedule.weeksUnscheduledMaintenanceJobs
        )
      )
      .pipe(
        timeout(60000),
        mergeMap((loadScheduleResult) => {
          return loadJobsForSchedule(loadScheduleResult, state);
        }),
        catchError((error) => {
          logError(
            `Error reloading day schedules after remote change - ${JSON.stringify(
              error
            )}`
          );
          return of(actionCreators.null());
        })
      );
  } else {
    return of(actionCreators.null());
  }
}
