import React from "react";
import { useDispatch } from "react-redux";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { IAction } from "../../../modules/actionTypeDefinitions";
import { promptActionCreators } from "../../../modules/prompt";
import Prompt from "./Prompt";

const PromptRedux: React.FunctionComponent<{}> = () => {
  const {
    showPrompt,
    promptMessage,
    promptSaveText,
    promptCancelText,
    promptSubMessage,
    promptSubMessageClassName,
    confirmationAction,
    secondaryButtons,
  } = useApplicationStateSelector((s) => s.prompt);
  const dispatch = useDispatch();

  const executeConfirm = (action: IAction | null) => {
    dispatch(promptActionCreators.confirmPrompt());
    dispatch(action);
  };

  return (
    <Prompt
      showPrompt={showPrompt}
      promptMessage={promptMessage}
      promptSaveText={promptSaveText}
      promptCancelText={promptCancelText}
      promptSubMessage={promptSubMessage}
      promptSubMessageClassName={promptSubMessageClassName}
      onConfirm={() => executeConfirm(confirmationAction)}
      secondaryButtons={secondaryButtons?.map((b) => ({
        text: b.text,
        onClick: () => executeConfirm(b.action),
      }))}
      onCancel={() => dispatch(promptActionCreators.cancelPrompt())}
    />
  );
};

export default PromptRedux;
