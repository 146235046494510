import { actionCreators } from "../actionCreators";
import dataProvider from "../../services/dataProvider";
import { mergeMap, catchError, timeout } from "rxjs/operators";
import { concat, Observable, of, race, timer } from "rxjs";
import {
  IInitialLoad,
  TenantSubscriptionStatus,
} from "../../models/IInitialLoad";

function loadWithRetries<T>(loadData: () => Observable<T>) {
  return race(
    loadData(),
    timer(2000).pipe(mergeMap(() => loadData())),
    timer(5000).pipe(mergeMap(() => loadData())),
    timer(10000).pipe(mergeMap(() => loadData())),
    timer(20000).pipe(mergeMap(() => loadData()))
  ).pipe(timeout(40000));
}

export function handleInitialLoadStarting() {
  return loadWithRetries(() => dataProvider.getInitialLoad()).pipe(
    mergeMap((response: IInitialLoad) => {
      const actions: Array<any> = [
        actionCreators.completeInitialLoad(response),
      ];
      if (
        response.tenantSubscriptionStatus ===
        TenantSubscriptionStatus.TrialUnavailable
      ) {
        actions.push(actionCreators.forms.subscription.showForm({}));
      }
      return concat(actions);
    }),
    catchError((error) => {
      console.log(error);
      return of(actionCreators.errorOnInitialLoad());
    })
  );
}
