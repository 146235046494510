import { ICustomer } from "../models/ICustomer";
import { IJob } from "../models/IJob";

function getCustomerByJob(job: IJob, customers: Array<ICustomer>) {
  const customerId = job.customerId;
  if (!customerId) {
    throw new Error(`customer id not set for job '${job.id}'`);
  }

  return getCustomerById(customerId, customers);
}

function getOptionalCustomerByJob(job: IJob, customers: Array<ICustomer>) {
  const customerId = job.customerId;
  if (!customerId) {
    throw new Error(`customer id not set for job '${job.id}'`);
  }

  return getOptionalCustomerById(customerId, customers);
}

function getCustomerById(customerId: string, customers: Array<ICustomer>) {
  const customer = getOptionalCustomerById(customerId, customers);
  if (!customer) {
    throw new Error(`unable to find customer '${customerId}'`);
  }

  return customer;
}

function getOptionalCustomerById(
  customerId: string,
  customers: Array<ICustomer>
) {
  return customers.find((c) => c.id === customerId);
}

export default {
  getCustomerByJob,
  getCustomerById,
  getOptionalCustomerByJob,
  getOptionalCustomerById,
};
