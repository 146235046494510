import { Redirect } from "react-router";
import Spinner from "../../../containers/app/components/Spinner";
import { patterns as routePatterns } from "../../../services/routing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrinWink, faHatCowboy } from "@fortawesome/free-solid-svg-icons";
import PaymentDocumentationSearch from "./PaymentDocumentationSearch";
import Nav from "reactstrap/lib/Nav";
import { NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { CSSProperties, Fragment, useEffect, useState } from "react";
import ClientSearch from "./ClientSearch";
import clientSupportDataProvider from "../services/clientSupportDataProvider";

const ClientSupport: React.FunctionComponent<{}> = () => {
  const [isClientSupportUser, setIsClientSupportUser] =
    useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const tabStyle: Partial<CSSProperties> = {
    cursor: "pointer",
    width: "150px",
  };

  const tabContainerStyle: Partial<CSSProperties> = {
    borderColor: "rgb(222, 226, 230)",
    borderStyle: "solid",
    borderWidth: "1px",
    paddingLeft: "20px",
    paddingTop: "20px",
  };

  useEffect(() => {
    if (!loaded) {
      clientSupportDataProvider.getInternalUserType().subscribe((u) => {
        let isClientSupportUser =
          u.isDeveloperUser === true || u.isSupportUser === true;

        if (isClientSupportUser) {
          document.body.className += "bg-dark";
        }
        setIsClientSupportUser(isClientSupportUser);
        setLoaded(true);
      });
    }
  }, [loaded, setLoaded]);

  return !loaded ? (
    <Spinner></Spinner>
  ) : isClientSupportUser ? (
    <Fragment>
      <form className="bg-dark" onSubmit={(e) => e.preventDefault()}>
        <div className="container-fluid text-light">
          <div className="d-flex justifyContent align-items-start mt-2">
            <div>
              <h1 className="text-light">Crew Control Client Support</h1>
            </div>
            <div>
              <span
                className="fa-layers mt-4 ml-3 mr-5"
                style={{ float: "left" }}
              >
                <FontAwesomeIcon
                  icon={faGrinWink}
                  size={"3x"}
                  color={"#f2cb14"}
                  transform={"shrink-2"}
                />
                <FontAwesomeIcon
                  icon={faHatCowboy}
                  size={"3x"}
                  color={"#9c603d"}
                  transform={"up-9 left-2 shrink-4"}
                />
              </span>
            </div>
          </div>
          <Nav
            tabs
            style={{ borderBottomStyle: "none" }}
            pills={true}
            className={"mt-4"}
          >
            <NavItem>
              <NavLink
                style={tabStyle}
                className={getStyles("1", activeTab)}
                onClick={() => {
                  toggle("1");
                }}
              >
                Clients
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={tabStyle}
                className={getStyles("2", activeTab)}
                onClick={() => {
                  toggle("2");
                }}
              >
                Payments
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div style={tabContainerStyle}>
                <ClientSearch />
              </div>
            </TabPane>
          </TabContent>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="2">
              <div style={tabContainerStyle}>
                <PaymentDocumentationSearch />
              </div>
            </TabPane>
          </TabContent>
        </div>
      </form>
    </Fragment>
  ) : (
    <Redirect to={routePatterns.schedule.default} />
  );
};

export default ClientSupport;

function getStyles(tabIndex: string, activeTab: string): string | undefined {
  return activeTab === tabIndex ? "active" : "bg-secondary text-dark";
}
