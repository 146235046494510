import { JobBillingType } from "../../../enums/jobBillingType";
import { IProject } from "../models/IProject";

export function isProjectBillingJobInstance(
  projectId: string | null,
  projects: Array<IProject>
): projectId is string {
  if (!projectId) {
    return false;
  }

  const project = projects.find((p) => p.id === projectId);

  if (!project) {
    return false;
  }

  return isProjectBilling(project);
}

export function isProjectBilling(project: IProject) {
  return isTypeProjectBilling(project.billingType);
}

export function isTypeProjectBilling(billingType: JobBillingType | null) {
  return (
    billingType === JobBillingType.AtProjectCompletionLineItems ||
    billingType === JobBillingType.AtProjectCompletionTotal
  );
}
