import React from "react";
import { connect } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import PageWithNavBar from "../PageWithNavBar";

interface IProps {
  showCrewForm(parameters?: any): void;
}

const IntroScreenAddCrew: React.SFC<IProps> = ({ showCrewForm }) => (
  <div className="jumbotron" id="intro-add-crew-explanation">
    <div>
      <h1>Welcome to Crew Control</h1>
      <p>
        Crew Control is an easy and fast way to manage your business schedule!
      </p>
      <p>Get started in three quick steps </p>
      <div>
        <ol>
          <li>Add Crews</li>
          <li>Add Customers</li>
          <li>Schedule Jobs</li>
        </ol>
      </div>
      <p>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => showCrewForm()}
          id="intro-add-crew-button"
        >
          Add Crew
        </button>
      </p>
    </div>
  </div>
);

const mapDispatchToProps: Partial<IProps> = {
  showCrewForm: actionCreators.forms.crew.showForm,
};

export default connect(
  null,
  mapDispatchToProps
)(PageWithNavBar(IntroScreenAddCrew));
