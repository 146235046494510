interface IProps {
  errorMessage: string;
  subMessage?: string;
}

const RegisterWithInvitationInitialError: React.FunctionComponent<IProps> = ({
  errorMessage,
  subMessage,
}) => {
  return (
    <div className="full-height">
      <div className="container pt-5">
        <div className="jumbotron">
          <h1 className="display-4">{errorMessage}</h1>
          <p className="lead">
            {subMessage ??
              "Please contact your manager to receive a new invitation."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegisterWithInvitationInitialError;
