import { useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentMethodResult } from "@stripe/stripe-js";
import { useState } from "react";
import { throwError } from "rxjs";
import { map, mergeMap, timeout } from "rxjs/operators";
import constants from "../constants";
import { TenantPlan } from "../enums/tenantPlan";
import { actionCreators } from "../modules/actionCreators";
import remoteDataProvider from "../services/remoteDataProvider";
import {
  confirmCardPayment,
  createPaymentMethod,
} from "../services/stripePaymentWrappers";
import { useApplicationStateSelector } from "./useApplicationStateSelector";
import { logError } from "../services/errorLogger";
import { fullStoryTrack } from "../services/fullStoryService";
import { SubscriptionFrequency } from "../enums/subscriptionFrequency";

interface ISaveFormDataArgs {
  cardHolderName: string;
  numberOfCrews: number;
}

export function useSubscribeProvider() {
  const stripe = useStripe();
  const elements = useElements();
  const tenantPlan = useApplicationStateSelector((s) => s.common.tenantPlan);
  const subscriptionFrequency = useApplicationStateSelector(
    (s) => s.common.subscriptionFrequency
  );
  const pricingUpdates =
    useApplicationStateSelector((s) => s.common.featureFlags?.pricingUpdates) ??
    false;

  const currentTenantPlan =
    typeof tenantPlan === "number" ? tenantPlan : TenantPlan.plus;
  const currentSubscriptionFrequency =
    typeof subscriptionFrequency === "number"
      ? subscriptionFrequency
      : pricingUpdates
      ? SubscriptionFrequency.annual
      : SubscriptionFrequency.month;

  const [cardHolderName, setCardHolderName] = useState("");
  const [numberOfCrews, setNumberOfCrews] = useState(1);
  const [declinedCharge, setDeclinedCharge] = useState(false);

  const [selectedTenantPlan, setSelectedTenantPlan] =
    useState(currentTenantPlan);

  const [selectedSubscriptionFrequency, setSelectedSubscriptionFrequency] =
    useState(currentSubscriptionFrequency);

  const saveFormData = (args: ISaveFormDataArgs) => {
    return remoteDataProvider
      .createSubscription({
        crewCount: args.numberOfCrews,
        tenantPlan: selectedTenantPlan,
        subscriptionFrequency: selectedSubscriptionFrequency,
      })
      .pipe(
        mergeMap((r) => {
          if (!r.alreadyPaid) {
            alertWhenPreviousChargeDeclined();
            return confirmCardPayment(
              stripe,
              elements,
              r.clientSecret as string,
              cardHolderName
            );
          } else {
            alertWhenPreviousChargeDeclined();
            return createPaymentMethod(stripe, elements, cardHolderName);
          }
        }),
        mergeMap((r) => {
          if (r?.error) {
            let errorMessage: string;
            if (r.error.message) {
              errorMessage = r.error.message;
              if (
                r.error.code !== "incorrect_cvc" &&
                r.error.code !== "incorrect_number" &&
                r.error.code !== "expired_card" &&
                r.error.code !== "incorrect_zip" &&
                r.error.code !== "invalid_cvc"
              ) {
                setDeclinedCharge(true);
                fullStoryTrack("Fraud Alert: Declined Stripe Payment", {
                  error: errorMessage ?? "",
                });
              }
            } else {
              errorMessage = constants.unknownErrorMessage;
            }
            return throwError(errorMessage);
          }

          let paymentMethodId: string | null = null;
          let paymentMethodResult = r as PaymentMethodResult;
          if (paymentMethodResult.paymentMethod?.id) {
            paymentMethodId = paymentMethodResult.paymentMethod?.id ?? null;
          }

          return remoteDataProvider.createSubscriptionPaymentRecord(
            paymentMethodId
          );
        }),
        timeout(20000)
      );
  };

  function alertWhenPreviousChargeDeclined() {
    if (declinedCharge) {
      logError("Fraud Alert: Declined Stripe Payment");
    }
  }

  // Reload initial data so the optional capabilities structure are updated
  const getPostSubscriptionActions = () =>
    remoteDataProvider
      .getInitialLoad()
      .pipe(
        map((initialLoad) => actionCreators.completeInitialLoad(initialLoad))
      );

  const stripeLoaded = stripe && elements;

  return {
    stripeLoaded,
    saveFormData,
    getPostSubscriptionActions,
    cardHolderName,
    setCardHolderName,
    numberOfCrews,
    setNumberOfCrews,
    selectedTenantPlan,
    setSelectedTenantPlan,
    selectedSubscriptionFrequency,
    setSelectedSubscriptionFrequency,
    pricingUpdates,
  };
}
