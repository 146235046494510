import { Fragment, useState } from "react";
import clientSupportDataProvider, {
  Tenant,
} from "../services/clientSupportDataProvider";
import dateService from "../../../services/dateService";
import dateFnsParse from "date-fns/parse";
import ExtendTrialForm from "./ExtendTrialForm";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Prompt from "../../../containers/app/components/Prompt";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import constants from "../../../constants";

interface IProps {
  client: Tenant;
  onSaveComplete: () => void;
}

const ClientRow: React.FunctionComponent<IProps> = ({
  client,
  onSaveComplete,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [showSimpleSignupPrompt, setShowSimpleSignupPrompt] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  let clientDate = dateService.removeTimeComponents(
    dateFnsParse(client.dateAddedUtc)
  );
  clientDate.setDate(clientDate.getDate() + 14);
  let trialDays = Math.round(
    (clientDate.getTime() -
      dateService
        .removeTimeComponents(dateService.getCurrentDate())
        .getTime()) /
      (1000 * 3600 * 24)
  );

  trialDays = trialDays > 0 ? trialDays : 0;

  return (
    <Fragment key={client.id}>
      <tr>
        <td>{client.name}</td>
        <td>{client.id}</td>
        <td>{client.hubSpotCompanyId}</td>
        <td>{client.subscribed ? "True" : "False"}</td>
        <td>{trialDays}</td>
        <td>{client?.featureFlags?.simpleSignup ? "Disabled" : "Enabled"}</td>
        <td>
          <ButtonDropdown
            direction="down"
            isOpen={isContextMenuOpen}
            toggle={() => setIsContextMenuOpen(!isContextMenuOpen)}
          >
            <button
              className="btn btn-secondary btn-sm"
              onClick={() => setIsContextMenuOpen(!isContextMenuOpen)}
            >
              Actions
            </button>
            <DropdownToggle split color="secondary" size="sm" />
            <DropdownMenu positionFixed={true} right={true}>
              <DropdownItem
                onClick={() => {
                  setShowSimpleSignupPrompt(true);
                }}
              >
                {client.featureFlags.simpleSignup
                  ? "Enabled Payments Onboarding"
                  : "Disable Payments Onboarding"}
              </DropdownItem>
              {!client.subscribed ? (
                <DropdownItem
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  Extend trial
                </DropdownItem>
              ) : null}
            </DropdownMenu>
          </ButtonDropdown>
        </td>
      </tr>
      {showForm ? (
        <ExtendTrialForm
          tenantId={client.id}
          clientName={client.name}
          currentDays={trialDays}
          onSaveComplete={() => {
            onSaveComplete();
            setShowForm(false);
          }}
          onCancel={() => setShowForm(false)}
        />
      ) : null}

      {showSimpleSignupPrompt ? (
        <Prompt
          promptMessage={
            client.featureFlags.simpleSignup
              ? "Are you sure you want to turn on payments onboarding?"
              : "Are you sure you want to turn off payments onboarding?"
          }
          promptSubMessage={errorMessage}
          promptSubMessageClassName="text-danger"
          showPrompt={showSimpleSignupPrompt}
          onCancel={() => {
            setShowSimpleSignupPrompt(false);
            setErrorMessage("");
          }}
          onConfirm={() => {
            clientSupportDataProvider
              .updateTenant(client.id, {
                simpleSignup: !client.featureFlags.simpleSignup,
              })
              .subscribe({
                next: () => {
                  setShowSimpleSignupPrompt(false);
                  onSaveComplete();
                },

                error: (err) => {
                  setErrorMessage(
                    getErrorMessageFromError(err, constants.unknownErrorMessage)
                  );
                },
              });
          }}
        />
      ) : null}
    </Fragment>
  );
};

export default ClientRow;
