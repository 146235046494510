import React, { useCallback, useEffect, useState } from "react";
import FormContainer2 from "../components/FormContainer2";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import conditionalRenderer from "../components/conditionalRenderer";
import { useFieldArray, useForm } from "react-hook-form";
import ModalDataLoader from "../components/ModalDataLoader";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import { logError } from "../../../services/errorLogger";
import { getSortedItemsV2 } from "../../../services/sortingService";

interface IFormData {
  crewCategories: Array<ICrewCategoryForForm>;
  showInactive: boolean;
}

interface ICrewCategoryForForm {
  id: string;
  name: string;
  color: string;
  inactive: boolean;
  originalId: string;
}

const CrewCategoriesForm: React.FunctionComponent<{}> = () => {
  const { control, register, setValue, getValues, watch } =
    useForm<IFormData>();
  const [originalFormData, setOriginalFormData] = useState<IFormData>({
    crewCategories: [],
    showInactive: false,
  });
  const { fields: crewCategoryFields } = useFieldArray({
    control,
    name: "crewCategories",
    keyName: "id",
  });
  const [availableColors, setAvailableColors] = useState<Array<string>>([]);
  const crewCategories = useApplicationStateSelector(
    (s) => s.common.crewCategories
  );
  const dispatch = useDispatch();

  // Rerender on each field value change
  const showInactiveWatch = watch("showInactive");
  const categoriesWatch = watch("crewCategories");

  useEffect(() => {
    // Don't run until available colors loaded
    if (availableColors.length > 0) {
      const crewCategoriesForForm = getSortedItemsV2(crewCategories, ["name"])
        .map((c) => ({
          ...c,
          // If color isn't set, default to first available color
          color: !c.color
            ? availableColors[0]
            : (convertRgbToHex(c.color) as string),
          inactive: c.inactive ?? false,
          originalId: c.id,
        }))
        .filter((c) => typeof c.color === "string");

      setValue("crewCategories", crewCategoriesForForm);
      setValue("showInactive", false);
      setOriginalFormData({
        crewCategories: crewCategoriesForForm,
        showInactive: false,
      });
    }
  }, [setValue, crewCategories, availableColors]);

  const loadAvailableColors = useCallback(
    () => remoteDataProvider.getAvailableCrewCategoryColors(),
    []
  );
  const handleColorsLoaded = useCallback(
    (newAvailableColors: Array<string>) => {
      if (newAvailableColors) {
        setAvailableColors(
          newAvailableColors
            .map((c) => convertRgbToHex(c) as string)
            .filter((c) => typeof c === "string")
        );
      }
    },
    []
  );

  const hasVisibleCategories = crewCategoryFields.some((crewCategoryField) =>
    isCategoryVisible(crewCategoryField, showInactiveWatch, categoriesWatch)
  );

  return (
    <ModalDataLoader<Array<string>>
      errorMessage="The service categories form was unable to open. Please check your Internet connection and try again."
      onErrorAlertClose={() =>
        dispatch(actionCreators.forms.crewCategories.cancelForm())
      }
      loadData={loadAvailableColors}
      onDataLoaded={handleColorsLoaded}
    >
      <FormContainer2
        formHeader={"Edit Service Categories"}
        formType={"crewCategories"}
        getFormData={() => {
          return getValues().crewCategories.map((c) => ({
            ...c,
            color: convertHexToRgb(c.color),
            originalId: undefined,
          }));
        }}
        showForm={true}
        hasFormDataChanged={() => {
          const currentValues = JSON.stringify(getValues().crewCategories);
          const originalValues = JSON.stringify(
            originalFormData.crewCategories
          );

          return currentValues !== originalValues;
        }}
      >
        <div className="text-right mb-3">
          <div
            className="custom-control custom-checkbox"
            style={{ marginTop: "10px", textAlign: "right" }}
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id="crew-categories-include-inactive"
              {...register("showInactive")}
            />
            <label
              className="custom-control-label"
              htmlFor="crew-categories-include-inactive"
            >
              Show inactive
            </label>
          </div>
        </div>

        {hasVisibleCategories ? (
          <>
            <div className="form-row">
              <div className="col-7">
                <label htmlFor="crewCategoryName">Name</label>
              </div>

              <div className="col-3">
                <label htmlFor="crewCategoryColor">Color</label>
              </div>

              <div className="col-2">
                <label htmlFor="crewCategoryColor">Inactive?</label>
              </div>
            </div>

            {crewCategoryFields.map((crewCategoryField, crewCategoryIndex) => (
              <div
                key={crewCategoryField.id}
                className={
                  "form-row" +
                  (isCategoryVisible(
                    crewCategoryField,
                    showInactiveWatch,
                    categoriesWatch
                  )
                    ? ""
                    : " d-none")
                }
              >
                <div className="form-group col-7">
                  <input
                    aria-label="Name"
                    type="text"
                    className="form-control"
                    required
                    {...register(
                      `crewCategories.${crewCategoryIndex}.name` as const
                    )}
                  />
                </div>
                <div className="form-group col-3">
                  <input
                    aria-label="Color"
                    className="form-control"
                    type="color"
                    list="presetColors"
                    required
                    {...register(
                      `crewCategories.${crewCategoryIndex}.color` as const
                    )}
                  ></input>
                  <datalist id="presetColors">
                    {availableColors !== null
                      ? availableColors.map((availableColor) => (
                          <option
                            key={availableColor}
                            value={availableColor}
                            data-testid="availableColor"
                          />
                        ))
                      : null}
                  </datalist>
                </div>
                <div className="form-group col-2">
                  <div className="custom-control custom-checkbox">
                    <input
                      id={getCheckboxId(crewCategoryField.id)}
                      type="checkbox"
                      className="custom-control-input"
                      {...register(
                        `crewCategories.${crewCategoryIndex}.inactive` as const
                      )}
                    ></input>
                    <label
                      className="custom-control-label mt-1"
                      htmlFor={getCheckboxId(crewCategoryField.id)}
                    >
                      <span className="sr-only">
                        {crewCategoryField.inactive ? "Inactive" : "Active"}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="text-center">
            <strong>No categories to display</strong>
          </div>
        )}
      </FormContainer2>
    </ModalDataLoader>
  );
};

export default conditionalRenderer(
  CrewCategoriesForm,
  (s) => s.forms.crewCategories.showForm
);

function isCategoryVisible(
  crewCategoryFieldToCheck: ICrewCategoryForForm,
  showInactiveWatch: boolean,
  categoriesWatch: ICrewCategoryForForm[]
) {
  if (showInactiveWatch) {
    return true;
  }

  const isInactive = categoriesWatch.some(
    (w) => w.originalId === crewCategoryFieldToCheck.originalId && w.inactive
  );

  return !isInactive;
}

function convertRgbToHex(input: string): string | null {
  const rgbComponents = (input ?? "").match(/(\d{1,3})/g);
  if (rgbComponents?.length !== 3) {
    logError(`invalid value passed to convertRgbToHex: ${input}`);
    return null;
  }

  const r = parseInt(rgbComponents[0]);
  const g = parseInt(rgbComponents[1]);
  const b = parseInt(rgbComponents[2]);

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}

function convertHexToRgb(input: string) {
  var hexComponents = input.replace("#", "").match(/.{1,2}/g);

  if (hexComponents?.length !== 3) {
    logError(`invalid value passed to convertHexToRgb: ${input}`);
    return null;
  }

  var [r, g, b] = [
    parseInt(hexComponents[0], 16),
    parseInt(hexComponents[1], 16),
    parseInt(hexComponents[2], 16),
  ];

  return `rgb(${r}, ${g}, ${b})`;
}

function getCheckboxId(id: string): string {
  return `inactiveCheckbox${id}`;
}
