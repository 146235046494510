import { IReminderConfiguration } from "../../../models/IReminderConfiguration";

export enum ConfigurationType {
  ProposalBefore,
  ProposalAfter,
  InvoiceBefore,
  InvoiceAfter,
}

interface IProps {
  type: ConfigurationType;
  reminderConfiguration: IReminderConfiguration;
}

const CustomerCommunicationSettingsReminderSection: React.FunctionComponent<
  IProps
> = ({ type, reminderConfiguration }) => {
  const recordType =
    type === ConfigurationType.ProposalAfter ||
    type === ConfigurationType.ProposalBefore
      ? "proposal"
      : "invoice";

  const timing =
    type === ConfigurationType.ProposalAfter ||
    type === ConfigurationType.InvoiceAfter
      ? "after"
      : "before";

  const timingType =
    type === ConfigurationType.ProposalAfter ||
    type === ConfigurationType.ProposalBefore
      ? "expiration"
      : "due";

  return (
    <div className="form-group">
      <div className="d-md-flex justify-content">
        <div className="mr-3">
          Email {recordType} reminder {timing} {timingType} date
        </div>
        <div data-testid={recordType + timing + "_enabled"}>
          <b>{reminderConfiguration?.isEnabled ? "On" : "Off"}</b>
        </div>
      </div>
      {reminderConfiguration?.isEnabled ? (
        <>
          <div className="bg-light">
            <div className="row mt-2 ml-2">
              <div
                className="col-12"
                data-testid={recordType + timing + "_firstreminder"}
              >
                <small>
                  Reminder 1 ({reminderConfiguration?.firstReminderNumberOfDays}{" "}
                  days {timing} {timingType} date)
                </small>
              </div>
            </div>

            {reminderConfiguration?.isSecondReminderEnabled &&
            type !== ConfigurationType.ProposalAfter ? (
              <div className="row mt-2 ml-2">
                <div
                  className="col-12"
                  data-testid={recordType + timing + "_secondreminder"}
                >
                  <small>
                    Reminder 2 (
                    {reminderConfiguration?.secondReminderNumberOfDays} days{" "}
                    {timing} {timingType} date)
                  </small>
                </div>
              </div>
            ) : null}
          </div>{" "}
        </>
      ) : null}
    </div>
  );
};

export default CustomerCommunicationSettingsReminderSection;
