import InfoToolTip from "./InfoToolTip";

const CustomerAdditionalLocationInfoToolTip: React.FunctionComponent<{}> =
  () => {
    return (
      <InfoToolTip
        id="jobLocationToolTip"
        text="Use additional locations for customers who have multiple properties."
      />
    );
  };

export default CustomerAdditionalLocationInfoToolTip;
