import differenceInMinutes from "date-fns/difference_in_minutes";
import {
  MinuteWindowOptions,
  blockSizeInMinutes,
} from "../components/Calendar";
import {
  setMinutes,
  setHours,
  getHours,
  getMinutes,
  addMinutes,
} from "date-fns";

export function subtractTimeBlocks({
  hour,
  minute,
  blockOffset,
}: {
  hour: number;
  minute: MinuteWindowOptions;
  blockOffset: number;
}): { hour: number; minute: MinuteWindowOptions } {
  if (blockOffset === 0) {
    return { hour, minute };
  }

  let hoursToDeduct = Math.floor(blockOffset / 4);
  const minuteBlocksToAdjust = (blockOffset % 4) * blockSizeInMinutes;

  let newMinute: MinuteWindowOptions;
  if (minuteBlocksToAdjust <= minute) {
    newMinute = (minute - minuteBlocksToAdjust) as MinuteWindowOptions;
  } else {
    hoursToDeduct++;

    const minutesToAddBack = minuteBlocksToAdjust - minute;
    newMinute = (60 - minutesToAddBack) as MinuteWindowOptions;
  }

  return { hour: hour - hoursToDeduct, minute: newMinute };
}

export function addTimeBlocks({
  hour,
  minute,
  blockOffset,
}: {
  hour: number;
  minute: MinuteWindowOptions;
  blockOffset: number;
}): { hour: number; minute: MinuteWindowOptions } {
  if (blockOffset === 0) {
    return { hour, minute };
  }

  let hoursToAdd = Math.floor(blockOffset / 4);
  const minuteBlocksToAdjust = (blockOffset % 4) * blockSizeInMinutes;

  let newMinute = minuteBlocksToAdjust + minute;
  if (newMinute < 60) {
    newMinute = (minute + minuteBlocksToAdjust) as MinuteWindowOptions;
  } else if (newMinute >= 60) {
    hoursToAdd++;
    newMinute = newMinute - 60;
  }

  return { hour: hour + hoursToAdd, minute: newMinute as MinuteWindowOptions };
}

export function getOffsetFromStart({
  timeSlotStartTime,
  referenceTime,
  calendarBlockHeightInPxs,
}: {
  timeSlotStartTime: Date | null;
  referenceTime: Date | null;
  calendarBlockHeightInPxs: number;
}) {
  let offset: number | null = null;
  if (timeSlotStartTime && referenceTime) {
    const minuteDifference = differenceInMinutes(
      referenceTime,
      timeSlotStartTime
    );
    if (minuteDifference > 0) {
      const ratioToBlock = minuteDifference / blockSizeInMinutes;
      offset = calendarBlockHeightInPxs * ratioToBlock;
    }
  }
  return offset;
}

export function getWindowThresholds(
  dayScheduleDate: Date,
  hour: number,
  minuteWindow: number
) {
  const startThreshold = setMinutes(
    setHours(dayScheduleDate, hour),
    minuteWindow
  );

  let endThreshold = setMinutes(
    setHours(dayScheduleDate, minuteWindow < 45 ? hour : hour + 1),
    minuteWindow < 45 ? minuteWindow + blockSizeInMinutes : 0
  );

  const endThresholdHours = getHours(endThreshold);
  const endThresholdMinutes = getMinutes(endThreshold);
  if (endThresholdHours === 0 && endThresholdMinutes === 0) {
    endThreshold = addMinutes(endThreshold, -1);
  }

  return { startThreshold, endThreshold };
}

export function isTimeInSlot({
  startThreshold,
  endThreshold,
  dateTimeToCheck,
}: {
  startThreshold: Date;
  endThreshold: Date;
  dateTimeToCheck: Date;
}) {
  return dateTimeToCheck >= startThreshold && dateTimeToCheck < endThreshold;
}
