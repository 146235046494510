import React, { Fragment, useEffect, useReducer, useRef } from "react";
import { ICrew } from "../../../models/ICrew";
import {
  GoogleMap,
  DirectionsRenderer,
  Marker,
  OverlayView,
} from "@react-google-maps/api";
import CustomizedMapMarker from "./CustomizedMapMarker";
import {
  createLatLng,
  hasGoogleMapsLoaded,
} from "../../../services/googleService";
import ErrorLoadingGoogleMaps from "./ErrorLoadingGoogleMaps";

const windowAny: any = window;

interface IProps {
  rendezvousPoint: ICrew;
  jobSets: Array<Array<IJobMarker>>;
  dayScheduleId: string;
}

export interface IJobMarker {
  index: number;
  id: string;
  status: MapJobStatus;
  locationCoordinates: {
    latitude: number;
    longitude: number;
  };
}

export enum MapJobStatus {
  NotStarted,
  Started,
  Completed,
}

interface IDirectionsState {
  directions: Array<any>;
}

type DirectionsAction =
  | { type: "reset" }
  | { type: "setResult"; result: Array<any>; jobSetIndex: number };

function directionsReducer(
  state: any,
  action: DirectionsAction
): IDirectionsState {
  switch (action.type) {
    case "reset":
      return { directions: [] };
    case "setResult":
      const newDirections = [...state.directions];
      newDirections[action.jobSetIndex] = action.result;
      return {
        directions: newDirections,
      };
    default:
      throw new Error();
  }
}

function setBounds(
  map: React.MutableRefObject<google.maps.Map | null>,
  rendezvousPoint: ICrew,
  jobSets: Array<Array<IJobMarker>>
) {
  // Need to wait to set bounds. Otherwise, a blank map will be shown.
  setTimeout(() => {
    if (map.current) {
      let boundsSetByJob = false;
      const bounds = new windowAny.google.maps.LatLngBounds();

      if (jobSets) {
        jobSets.forEach((jobs) => {
          jobs.forEach((job) => {
            if (
              job.locationCoordinates.latitude &&
              job.locationCoordinates.longitude
            ) {
              boundsSetByJob = true;
              bounds.extend(createLatLng(job.locationCoordinates));
            }
          });
        });
      }

      if (!boundsSetByJob) {
        bounds.extend(createLatLng(rendezvousPoint));
      }

      map.current.fitBounds(bounds);
    }
  });
}

export const MapWithDirections: React.FunctionComponent<IProps> = ({
  rendezvousPoint,
  jobSets,
  dayScheduleId,
}) => {
  const [{ directions }, dispatchDirections] = useReducer(directionsReducer, {
    directions: [],
  });
  const map = useRef<google.maps.Map | null>(null);

  useEffect(() => {
    dispatchDirections({ type: "reset" });

    if (jobSets && hasGoogleMapsLoaded()) {
      const directionsService = new windowAny.google.maps.DirectionsService();
      jobSets.forEach((jobs, jobSetIndex) => {
        if (
          jobs &&
          jobs.length > 0 &&
          rendezvousPoint.latitude &&
          rendezvousPoint.longitude
        ) {
          const origin = createLatLng(rendezvousPoint);

          const waypoints = [];
          for (let i = 0; i < jobs.length; i++) {
            waypoints.push({
              location: createLatLng(jobs[i].locationCoordinates),
            });
          }

          const destination = createLatLng(rendezvousPoint);

          directionsService.route(
            {
              origin,
              destination,
              waypoints,
              travelMode: windowAny.google.maps.TravelMode.DRIVING,
            },
            (result: any, status: any) => {
              if (status === windowAny.google.maps.DirectionsStatus.OK) {
                dispatchDirections({
                  type: "setResult",
                  jobSetIndex,
                  result,
                });
              } else {
                console.error(
                  `error fetching directions ${JSON.stringify(result)}`
                );
              }
            }
          );
        }
      });
    }
  }, [rendezvousPoint, jobSets]);

  const previousDayScheduleId = useRef(dayScheduleId);
  useEffect(() => {
    if (
      dayScheduleId !== previousDayScheduleId.current &&
      hasGoogleMapsLoaded()
    ) {
      setBounds(map, rendezvousPoint, jobSets);
      previousDayScheduleId.current = dayScheduleId;
    }
  }, [dayScheduleId, jobSets, rendezvousPoint]);

  return (
    <Fragment>
      <div
        style={{
          height: `95vh`,
          width: "100%",
          position: "sticky",
          top: "15px",
        }}
      >
        {hasGoogleMapsLoaded() ? (
          <>
            <GoogleMap
              id="map"
              mapContainerStyle={{ height: `100%` }}
              onLoad={(m) => {
                map.current = m;

                setBounds(map, rendezvousPoint, jobSets);
              }}
            >
              {directions.map((directionEntry, directionIndex) => (
                <Fragment key={directionIndex}>
                  {directions ? (
                    <DirectionsRenderer
                      options={{
                        preserveViewport: true,
                        suppressMarkers: true,
                      }}
                      directions={directionEntry}
                    />
                  ) : null}
                </Fragment>
              ))}

              {rendezvousPoint.latitude && rendezvousPoint.longitude ? (
                <React.Fragment>
                  <Marker position={createLatLng(rendezvousPoint)} />
                  <OverlayView
                    position={createLatLng(rendezvousPoint)}
                    mapPaneName={OverlayView.MARKER_LAYER}
                  >
                    <div
                      style={{
                        backgroundColor: "white",
                        opacity: 0.9,
                        fontSize: "12px",
                        padding: "5px",
                      }}
                    >
                      Crew rendezvous
                    </div>
                  </OverlayView>
                </React.Fragment>
              ) : null}

              {jobSets
                ? jobSets.map((jobs) => {
                    return jobs.map((job) => {
                      const position = createLatLng(job.locationCoordinates);
                      const label = (job.index + 1).toString();

                      let marker: JSX.Element;
                      if (job.status === MapJobStatus.NotStarted) {
                        marker = (
                          <Marker position={position} label={{ text: label }} />
                        );
                      } else if (job.status === MapJobStatus.Started) {
                        marker = getCustomizedMarker("#2fa4e7");
                      } else {
                        marker = getCustomizedMarker("#73a839");
                      }

                      return (
                        <React.Fragment key={job.id}>{marker}</React.Fragment>
                      );

                      function getCustomizedMarker(fillColor: string) {
                        return (
                          <CustomizedMapMarker
                            key={job.id}
                            position={position}
                            onClick={() => {}}
                            flexibleJob={false}
                            fillColor={fillColor}
                            labelText={label}
                          />
                        );
                      }
                    });
                  })
                : null}
            </GoogleMap>
          </>
        ) : (
          <ErrorLoadingGoogleMaps />
        )}
      </div>
    </Fragment>
  );
};
