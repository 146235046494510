export function downloadBlob(blob: Blob, fileName: string) {
  const objectUrl = window.URL.createObjectURL(blob);
  const anchor = document.createElement("a");
  anchor.hidden = true;
  document.body.appendChild(anchor);

  anchor.href = objectUrl;
  anchor.download = fileName;
  anchor.click();

  // Timeout seems to be necessary on iPads
  // https://stackoverflow.com/questions/54339607/mobile-safari-download-issue-the-operation-couldn-t-be-completed-webkitblobr
  setTimeout(() => {
    window.URL.revokeObjectURL(objectUrl);
    document.body.removeChild(anchor);
  }, 1000);
}
