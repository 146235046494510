import React from "react";
import { connect } from "react-redux";
import FormContainer from "../components/FormContainer";
import { actionCreators } from "../../../modules/actionCreators";
import dateFnsFormat from "date-fns/format";
import dateService from "../../../services/dateService";

import DayPicker, { format as dayPickerFormat } from "../components/DayPicker";
import { ICrew } from "../../../models/ICrew";
import { IMaintenanceJob } from "../../../models/IMaintenanceJob";
import { IDaySchedule } from "../../../models/IDaySchedule";
import { IRootState } from "../../../store";
import jobFinder from "../../../services/jobFinder";
import constants from "../../../constants";
import { IUnscheduledMaintenanceJob } from "../../../models/IUnscheduledMaintenanceJob";
import { JobType } from "../../../models/IJob";
import { IOneTimeJob } from "../../../models/IOneTimeJob";
import { ICustomer } from "../../../models/ICustomer";
import customerFinder from "../../../services/customerFinder";
import { getSortedCrews } from "../../../services/sortingService";
import {
  getDefaultCrewId,
  getSelectedScheduleStartOfWeek,
} from "../../../services/jobService";
import { RouterState } from "connected-react-router";
import { JobInstanceChangeType } from "../../../enums/jobInstanceChangeType";
import { MaintenanceJobChangeType } from "../../../enums/maintenanceJobChangeType";
import { CrewScheduleType } from "../../../slices/schedule/enums/crewScheduleType";
import { OneTimeJobOrderingDropDown } from "../../../slices/schedule/components/OneTimeJobOrderingDropDown";
import { FlexibleJobToolTip } from "../components/FlexibleJobToolTip";
import WeekPicker from "../components/WeekPicker";
import startOfWeek from "date-fns/start_of_week";
import { isStringSet } from "../../../services/stringService";
import { isCustomDailyJob } from "../../../services/dropJobService";
import InfoToolTip from "../components/InfoToolTip";
import { getJobInstanceV2 } from "../../../services/jobInstanceService";
import TimeInput from "../components/TimeInput";
import { IMoveJobInstanceRequest } from "../../../services/remoteDataProvider";
import { JobConflictAlert } from "../../../slices/schedule/components/JobConflictAlert";

interface IGetDayScheduleForJobToMove {
  daySchedule?: IDaySchedule | undefined;
  flexibleDate?: string | undefined;
}

interface IProps {
  crews: Array<ICrew>;
  jobs: Array<IMaintenanceJob>;
  oneTimeJobs: Array<IOneTimeJob>;
  moveJobInstanceIds: Array<string> | null;
  showForm: boolean;
  errorMessage: string | React.ReactNode;
  saving: boolean;
  daySchedules: Array<IDaySchedule>;
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>;
  startSave(payload: any): void;
  cancel(): void;
  setErrorMessage(message: string): void;
  customers: Array<ICustomer>;
  router: RouterState;
}

interface IState {
  formData: IFormData;
}

interface IFormData {
  destinationCrewId: string | null;
  destinationDate: string;
  destinationPrecedingJobInstanceId: string | null;
  permanentMaintenanceJobsMove: boolean;
  destinationFlexibleJob: boolean;
  startTime: string | null;
  endTime: string | null;
}

class MoveForm extends React.Component<IProps, IState> {
  private dayPickerLabelElement: React.RefObject<HTMLLabelElement>;

  constructor(props: IProps) {
    super(props);

    this.state = {
      formData: {
        destinationCrewId: "",
        destinationDate: "",
        destinationPrecedingJobInstanceId: constants.idForFirstJob,
        permanentMaintenanceJobsMove: false,
        destinationFlexibleJob: false,
        startTime: null,
        endTime: null,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.getFormData = this.getFormData.bind(this);
    this.validate = this.validate.bind(this);

    this.dayPickerLabelElement = React.createRef<HTMLLabelElement>();
  }

  componentDidUpdate(prevProps: IProps) {
    const props = this.props;
    if (prevProps.showForm !== this.props.showForm && props.showForm) {
      const { crews, moveJobInstanceIds } = props;

      if (crews.length === 0) {
        console.error("no crews defined");
      }

      if (!moveJobInstanceIds) {
        throw new Error("moveJobInstanceId is required");
      }

      const dayScheduleForJobToMove = getDayScheduleForJobToMove(
        props.daySchedules,
        props.weeksUnscheduledMaintenanceJobs,
        moveJobInstanceIds
      );

      if (dayScheduleForJobToMove.daySchedule) {
        let startTime: string | null = null;
        let endTime: string | null = null;
        if (moveJobInstanceIds.length === 1) {
          try {
            const moveJobInstance = getJobInstanceV2(
              props.daySchedules,
              props.weeksUnscheduledMaintenanceJobs,
              moveJobInstanceIds[0]
            );
            startTime = moveJobInstance.jobInstance.startTime;
            endTime = moveJobInstance.jobInstance.endTime;
          } catch {
            // No need to handle anything since would just startTime/endTime to null
          }
        }

        this.setState({
          formData: {
            destinationCrewId: dayScheduleForJobToMove.daySchedule.crewId,
            destinationDate: dayScheduleForJobToMove.daySchedule.date,
            destinationPrecedingJobInstanceId: constants.idForFirstJob,
            permanentMaintenanceJobsMove: false,
            destinationFlexibleJob: false,
            startTime,
            endTime,
          },
        });
      } else if (dayScheduleForJobToMove.flexibleDate) {
        this.setState({
          formData: {
            destinationCrewId: getDefaultCrewId(
              this.props.crews,
              this.props.router
            ),
            destinationDate: dayScheduleForJobToMove.flexibleDate,
            destinationPrecedingJobInstanceId: constants.idForFirstJob,
            permanentMaintenanceJobsMove: false,
            destinationFlexibleJob: true,
            startTime: null,
            endTime: null,
          },
        });
      } else {
        this.setState({
          formData: {
            destinationCrewId: getDefaultCrewId(
              this.props.crews,
              this.props.router
            ),
            destinationDate: dateService.formatAsIso(
              getSelectedScheduleStartOfWeek(
                this.props.router,
                this.props.crews
              )
            ),
            destinationPrecedingJobInstanceId: constants.idForFirstJob,
            permanentMaintenanceJobsMove: false,
            destinationFlexibleJob: false,
            startTime: null,
            endTime: null,
          },
        });
      }
    }
  }

  handleChange(
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) {
    this.handleChangeWithoutEvent(
      event.target.name as keyof IFormData,
      event.target.value
    );
  }

  handleChangeWithoutEvent(
    fieldName: keyof IFormData,
    value: string | boolean
  ) {
    const formDataToUpdate: IFormData = { ...this.state.formData };

    (formDataToUpdate[fieldName] as string | boolean) = value;

    if (fieldName === "destinationCrewId" || fieldName === "destinationDate") {
      // TODO: Consolidate with getDerivedStateFromProps and find
      // a way to not have two code paths for loaded vs not loaded
      const { daySchedules } = this.props;
      const daySchedule = daySchedules.find(
        (ds) =>
          ds.crewId === formDataToUpdate.destinationCrewId &&
          dateService.areDatesEqual(
            ds.date,
            formDataToUpdate.destinationDate
          ) &&
          !ds.initialLoadRunning
      );

      if (daySchedule) {
        const destinationPrecedingJobInstanceId = constants.idForFirstJob;

        formDataToUpdate.destinationPrecedingJobInstanceId =
          destinationPrecedingJobInstanceId;
      }
    } else if (fieldName === "destinationFlexibleJob" && value) {
      formDataToUpdate.destinationDate = isStringSet(
        formDataToUpdate.destinationDate
      )
        ? dateFnsFormat(
            startOfWeek(formDataToUpdate.destinationDate),
            dayPickerFormat
          )
        : formDataToUpdate.destinationDate;
    }

    this.setState({
      formData: formDataToUpdate,
    });
  }

  getFormData() {
    const formData: Partial<IMoveJobInstanceRequest> = {
      ...this.state.formData,
      destinationCrewId: !this.state.formData.destinationFlexibleJob
        ? this.state.formData.destinationCrewId
        : null,
      destinationPrecedingJobInstanceId: !this.state.formData
        .destinationFlexibleJob
        ? !this.isCrewTimeBased()
          ? this.state.formData.destinationPrecedingJobInstanceId
          : constants.idForFirstJob
        : null,
      destinationDate: this.getDestinationDateAsIso(),
      jobInstanceChangeType: JobInstanceChangeType.MoveModalSaved,
      maintenanceJobChangeType:
        MaintenanceJobChangeType.MoveModalSavedAppliedFutureWeeks,
    };

    // Clear times since they're persisted with the jobInstanceUpdates properties
    delete (formData as Partial<IFormData>).startTime;
    delete (formData as Partial<IFormData>).endTime;

    if (
      this.isCrewTimeBased() &&
      this.props.moveJobInstanceIds?.length === 1 &&
      typeof this.state.formData.startTime === "string" &&
      typeof this.state.formData.endTime === "string"
    ) {
      formData.jobInstanceUpdates = [
        {
          jobInstanceId: this.props.moveJobInstanceIds[0],
          startTime: dateService.formatTimeForSerialization(
            this.state.formData.startTime
          ),
          endTime: dateService.formatTimeForSerialization(
            this.state.formData.endTime
          ),
        },
      ];
    }

    return formData;
  }

  private isCrewTimeBased() {
    const crews = this.props.crews;
    const { destinationCrewId } = this.state.formData;

    const crew = crews.find((c) => c.id === destinationCrewId);
    if (crew && crew.scheduleType === CrewScheduleType.time) {
      return true;
    }

    return false;
  }

  private getDestinationDateAsIso() {
    return dateService.formatAsIso(this.state.formData.destinationDate);
  }

  validate() {
    if (!this.state.formData.destinationDate) {
      return {
        valid: false,
        errorMessage: "Please select a new date",
      };
    }

    if (dateService.isInvalidIsoDate(this.getDestinationDateAsIso())) {
      return {
        valid: false,
        errorMessage: "New Date is not valid",
      };
    }

    return { valid: true };
  }

  render() {
    const {
      showForm,
      startSave,
      cancel,
      errorMessage,
      saving,
      setErrorMessage,
      crews,
      daySchedules,
      jobs,
      oneTimeJobs,
      moveJobInstanceIds,
      weeksUnscheduledMaintenanceJobs,
      customers,
    } = this.props;

    const { formData } = this.state;

    const formHeader = getFormHeader(
      moveJobInstanceIds,
      daySchedules,
      jobs,
      oneTimeJobs,
      customers,
      weeksUnscheduledMaintenanceJobs
    );

    const customFrequencyJobExists =
      isJobInstanceAssociatedWithCustomFrequencyMaintenanceJob({
        moveJobInstanceIds,
        daySchedules,
        jobs,
        oneTimeJobs,
        weeksUnscheduledMaintenanceJobs,
      });

    const customFrequencyJobMovedToFlexArea =
      formData.destinationFlexibleJob && customFrequencyJobExists;

    return (
      <FormContainer
        setErrorMessage={setErrorMessage}
        validate={this.validate}
        getFormData={this.getFormData}
        formHeader={formHeader}
        showForm={showForm}
        errorMessage={errorMessage}
        saving={saving}
        startSave={startSave}
        cancel={cancel}
        formKey="moveJobInstance"
      >
        <div style={{ minHeight: "375px" }}>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="flexibleJob"
                name="flexibleJob"
                checked={formData.destinationFlexibleJob}
                onChange={(e) => {
                  let value = e.target.checked;
                  this.handleChangeWithoutEvent(
                    "destinationFlexibleJob",
                    value
                  );
                }}
              />
              <label className="custom-control-label" htmlFor="flexibleJob">
                Flexible job?
              </label>
              <FlexibleJobToolTip />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="crew">Crew</label>
            <select
              className="form-control"
              id="crew"
              name="destinationCrewId"
              data-testid="destinationCrewId"
              value={formData.destinationCrewId ?? undefined}
              onChange={this.handleChange}
              disabled={formData.destinationFlexibleJob}
            >
              {getSortedCrews(crews.filter((c) => !c.inactive)).map((crew) => (
                <option key={crew.id} value={crew.id}>
                  {crew.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            {formData.destinationFlexibleJob ? (
              <>
                <label
                  htmlFor="date"
                  className="required"
                  ref={this.dayPickerLabelElement}
                >
                  New starting week
                </label>
                <div>
                  <WeekPicker
                    labelRef={this.dayPickerLabelElement}
                    required={true}
                    value={
                      !!formData.destinationDate
                        ? dateFnsFormat(
                            formData.destinationDate,
                            dayPickerFormat
                          )
                        : ""
                    }
                    inputId="date"
                    testId="startingWeek"
                    onWeekSelected={(day: Date) => {
                      let selectedWeekStart = "";

                      if (!!day) {
                        const parsedDate = dateFnsFormat(day, dayPickerFormat);
                        selectedWeekStart = dateService.formatAsIso(
                          startOfWeek(parsedDate)
                        );
                      }

                      this.handleChangeWithoutEvent(
                        "destinationDate",
                        selectedWeekStart
                      );
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <label
                  htmlFor="destinationDate"
                  className="required"
                  ref={this.dayPickerLabelElement}
                >
                  New date
                </label>
                <div>
                  <DayPicker
                    labelRef={this.dayPickerLabelElement}
                    testId="newDate"
                    onDayPickerHide={() => null}
                    dayPickerProps={{}}
                    value={
                      !!formData.destinationDate
                        ? dateFnsFormat(
                            formData.destinationDate,
                            dayPickerFormat
                          )
                        : ""
                    }
                    required={true}
                    inputId="destinationDate"
                    onDaySelected={(day: Date) => {
                      let newValue = "";

                      if (!!day) {
                        newValue = dateFnsFormat(day, dayPickerFormat);
                      }

                      this.handleChangeWithoutEvent(
                        "destinationDate",
                        newValue
                      );
                    }}
                  />
                </div>
              </>
            )}
          </div>

          {!this.isCrewTimeBased() ? (
            <OneTimeJobOrderingDropDown
              destinationDate={formData.destinationDate}
              destinationCrewId={formData.destinationCrewId}
              destinationPrecedingJobInstanceId={
                formData.destinationPrecedingJobInstanceId
              }
              onDestinationPrecedingJobInstanceIdChanged={(newValue) =>
                this.handleChangeWithoutEvent(
                  "destinationPrecedingJobInstanceId",
                  newValue
                )
              }
              jobInstanceIdsToExclude={moveJobInstanceIds}
              destinationUnscheduledJobs={formData.destinationFlexibleJob}
            />
          ) : null}

          <TimeFields
            destinationCrewId={formData.destinationCrewId}
            destinationFlexibleJob={formData.destinationFlexibleJob}
            destinationDate={formData.destinationDate}
            startTime={formData.startTime}
            endTime={formData.endTime}
            onStartTimeChange={(newValue) => {
              this.setState({
                formData: {
                  ...formData,
                  startTime: newValue,
                },
              });
            }}
            onEndTimeChange={(newValue) => {
              this.setState({
                formData: {
                  ...formData,
                  endTime: newValue,
                },
              });
            }}
            isCrewTimeBased={this.isCrewTimeBased()}
            moveJobInstanceIds={this.props.moveJobInstanceIds}
          />

          {hasMaintenanceJob(
            moveJobInstanceIds,
            daySchedules,
            weeksUnscheduledMaintenanceJobs,
            jobs,
            oneTimeJobs
          ) ? (
            <div className="form-group">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="permanentMaintenanceJobsMove"
                  checked={formData.permanentMaintenanceJobsMove}
                  onChange={(e) => {
                    let value = e.target.checked;
                    this.handleChangeWithoutEvent(
                      "permanentMaintenanceJobsMove",
                      value
                    );
                  }}
                  disabled={customFrequencyJobMovedToFlexArea}
                />
                <label
                  className="custom-control-label"
                  htmlFor="permanentMaintenanceJobsMove"
                >
                  <strong>Apply changes to future weeks</strong>
                </label>
                {customFrequencyJobMovedToFlexArea ? (
                  <InfoToolTip
                    id="applyChangesDisabledExplanation"
                    text="One or more of your selected jobs has a custom frequency."
                    testId="applyChangesDisabledExplanation"
                  />
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </FormContainer>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  crews: state.crew.crews,
  jobs: state.job.jobs,
  oneTimeJobs: state.job.oneTimeJobs,
  daySchedules: state.schedule.daySchedules,
  weeksUnscheduledMaintenanceJobs:
    state.schedule.weeksUnscheduledMaintenanceJobs,
  moveJobInstanceIds:
    state.forms.moveJobInstance.parameters &&
    state.forms.moveJobInstance.parameters.jobInstanceIds
      ? state.forms.moveJobInstance.parameters.jobInstanceIds
      : null,
  showForm: state.forms.moveJobInstance.showForm,
  errorMessage: state.forms.moveJobInstance.errorMessage,
  saving: state.forms.moveJobInstance.saving,
  customers: state.customer.customers,
  router: state.router,
});

const mapDispatchToProps = {
  startSave: actionCreators.forms.moveJobInstance.startSaving,
  cancel: actionCreators.forms.moveJobInstance.cancelForm,
  setErrorMessage: actionCreators.forms.moveJobInstance.setErrorMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(MoveForm);

function TimeFields({
  destinationCrewId,
  destinationFlexibleJob,
  destinationDate,
  startTime,
  endTime,
  onStartTimeChange,
  onEndTimeChange,
  isCrewTimeBased,
  moveJobInstanceIds,
}: {
  destinationCrewId: string | null;
  destinationFlexibleJob: boolean;
  destinationDate: string;
  startTime: string | null;
  endTime: string | null;
  onStartTimeChange: (newValue: string) => void;
  onEndTimeChange: (newValue: string) => void;
  isCrewTimeBased: boolean;
  moveJobInstanceIds: Array<string> | null;
}) {
  if (!isCrewTimeBased || moveJobInstanceIds?.length !== 1) {
    return null;
  }

  return (
    <div data-testid="timeFields">
      <div className="form-row">
        <div className="form-group col-6">
          <label htmlFor="startTime" className="required">
            Start time
          </label>
          <TimeInput
            className="form-control"
            data-testid="startTime"
            value={startTime ?? ""}
            required
            id="startTime"
            onChange={(time) => {
              onStartTimeChange(time);
            }}
          />
        </div>
        <div className="form-group col-6">
          <label htmlFor="endTime" className="required">
            End time
          </label>
          <TimeInput
            className="form-control"
            data-testid="endTime"
            value={endTime ?? ""}
            required
            id="endTime"
            onChange={(time) => {
              onEndTimeChange(time);
            }}
          />
        </div>
      </div>
      {!destinationFlexibleJob &&
      isStringSet(destinationCrewId) &&
      isStringSet(startTime) &&
      isStringSet(endTime) ? (
        <JobConflictAlert
          crewId={destinationCrewId}
          date={destinationDate}
          startTime={startTime}
          endTime={endTime}
          jobInstanceId={moveJobInstanceIds[0]}
        />
      ) : null}
    </div>
  );
}

function hasMaintenanceJob(
  moveJobInstanceIds: Array<string> | null,
  daySchedules: Array<IDaySchedule>,
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>,
  jobs: Array<IMaintenanceJob>,
  oneTimeJobs: Array<IOneTimeJob>
) {
  if (moveJobInstanceIds) {
    const jobsForJobInstances = getJobsForJobInstances({
      moveJobInstanceIds,
      jobs,
      oneTimeJobs,
      daySchedules,
      weeksUnscheduledMaintenanceJobs,
    });

    return jobsForJobInstances.some((j) => j?.type === JobType.maintenanceJob);
  } else {
    return false;
  }
}

function isJobInstanceAssociatedWithCustomFrequencyMaintenanceJob({
  moveJobInstanceIds,
  jobs,
  oneTimeJobs,
  daySchedules,
  weeksUnscheduledMaintenanceJobs,
}: {
  moveJobInstanceIds: Array<string> | null;
  jobs: IMaintenanceJob[];
  oneTimeJobs: IOneTimeJob[];
  daySchedules: IDaySchedule[];
  weeksUnscheduledMaintenanceJobs: IUnscheduledMaintenanceJob[];
}) {
  if (moveJobInstanceIds) {
    const jobsForJobInstances = getJobsForJobInstances({
      moveJobInstanceIds,
      jobs,
      oneTimeJobs,
      daySchedules,
      weeksUnscheduledMaintenanceJobs,
    });

    return jobsForJobInstances.some(
      (j) =>
        j?.type === JobType.maintenanceJob &&
        isCustomDailyJob(j as unknown as IMaintenanceJob)
    );
  } else {
    return false;
  }
}

function getJobsForJobInstances({
  moveJobInstanceIds,
  jobs,
  oneTimeJobs,
  daySchedules,
  weeksUnscheduledMaintenanceJobs,
}: {
  moveJobInstanceIds: string[];
  jobs: IMaintenanceJob[];
  oneTimeJobs: IOneTimeJob[];
  daySchedules: IDaySchedule[];
  weeksUnscheduledMaintenanceJobs: IUnscheduledMaintenanceJob[];
}) {
  return moveJobInstanceIds.map((moveJobInstanceId) => {
    const job = jobFinder.getJobForDayScheduleV3(
      moveJobInstanceId,
      jobs,
      oneTimeJobs,
      daySchedules,
      weeksUnscheduledMaintenanceJobs
    );

    return job;
  });
}

function getFormHeader(
  moveJobInstanceIds: Array<string> | null,
  daySchedules: Array<IDaySchedule>,
  jobs: Array<IMaintenanceJob>,
  oneTimeJobs: Array<IOneTimeJob>,
  customers: Array<ICustomer>,
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>
): string {
  let formHeader = "Move job";
  if (moveJobInstanceIds) {
    if (moveJobInstanceIds.length === 1) {
      const moveJobInstanceId = moveJobInstanceIds[0];
      const job = jobFinder.getJobForDayScheduleV3(
        moveJobInstanceId,
        jobs,
        oneTimeJobs,
        daySchedules,
        weeksUnscheduledMaintenanceJobs
      );

      if (job) {
        const customer = customerFinder.getCustomerByJob(job, customers);
        formHeader = `Move ${customer.name} Job`;
      }
    } else {
      formHeader = `Move ${moveJobInstanceIds.length} Jobs`;
    }
  }

  return formHeader;
}

function getDayScheduleForJobToMove(
  daySchedules: Array<IDaySchedule>,
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>,
  moveJobInstanceIds: Array<string>
): IGetDayScheduleForJobToMove {
  const daySchedulesForMovedJobInstances = daySchedules.filter((ds) =>
    ds.jobInstances.some((jobInstanceOnDay) =>
      moveJobInstanceIds.some(
        (moveJobInstanceId) => moveJobInstanceId === jobInstanceOnDay.id
      )
    )
  );
  const weeksUnscheduledMaintenanceJobsForMovedJobInstances =
    weeksUnscheduledMaintenanceJobs.filter((ds) =>
      ds.jobInstances.some((jobInstanceOnDay) =>
        moveJobInstanceIds.some(
          (moveJobInstanceId) => moveJobInstanceId === jobInstanceOnDay.id
        )
      )
    );

  if (
    daySchedulesForMovedJobInstances.length === 1 &&
    weeksUnscheduledMaintenanceJobsForMovedJobInstances.length === 0
  ) {
    return {
      daySchedule: daySchedulesForMovedJobInstances[0],
    };
  } else if (
    daySchedulesForMovedJobInstances.length === 0 &&
    weeksUnscheduledMaintenanceJobsForMovedJobInstances.length === 1
  ) {
    return {
      flexibleDate: dateService.formatAsIso(
        weeksUnscheduledMaintenanceJobsForMovedJobInstances[0].week
      ),
    };
  } else {
    return {};
  }
}
