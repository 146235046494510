import { Action } from "@reduxjs/toolkit";
import { isMaintenanceJobFormSaveComplete } from "./isMaintenanceJobFormSaveComplete";
import { isOneTimeJobFormSaveComplete } from "./isOneTimeJobFormSaveComplete";
import { isProjectSave } from "./isProjectSave";

export function tryGetOpportunityIdFromJobSaveAddCompleteAction(
  action: Action<any>
):
  | { opportunityIdSet: false; opportunityId: null }
  | { opportunityIdSet: true; opportunityId: string } {
  let opportunityId: string | null = null;
  if (isProjectSave(action) && action.mode === "add") {
    opportunityId = action.opportunityId;
  } else if (isOneTimeJobFormSaveComplete(action) && action.payload.isAdd) {
    opportunityId = action.payload.opportunityId;
  } else if (isMaintenanceJobFormSaveComplete(action) && action.payload.isAdd) {
    opportunityId = action.payload.opportunityId;
  }

  if (opportunityId) {
    return {
      opportunityIdSet: true,
      opportunityId,
    };
  } else {
    return {
      opportunityIdSet: false,
      opportunityId: null,
    };
  }
}
