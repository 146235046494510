import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import format from "date-fns/format";
import parse from "date-fns/parse";
import { push, RouterAction } from "connected-react-router";
import {
  builders,
  builders as routingBuilders,
} from "../../../services/routing";
import dateService from "../../../services/dateService";
import { IDaySchedule } from "../../../models/IDaySchedule";
import { ICrew } from "../../../models/ICrew";
import { IRootState } from "../../../store";
import { IMaintenanceJob } from "../../../models/IMaintenanceJob";
import { Path } from "history";
import { IUnscheduledMaintenanceJob } from "../../../models/IUnscheduledMaintenanceJob";
import { isLoadingScheduleDates } from "../../../services/scheduleService";
import Schedule, {
  IScheduleColumnHeader,
} from "../../../containers/app/components/schedule";
import { useUserSettings } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import { IScheduleColumn } from "./types/IScheduleColumn";
import { ScheduleWeekFilters } from "./ScheduleWeekFilters";
import PageWithNavBar2 from "../../../containers/app/PageWithNavBar2";
import { getDatesForScheduleWeek } from "../services/getDatesForScheduleWeek";
import { useScheduleWeekDefaultSelectedCrews } from "../hooks/useScheduleWeekDefaultSelectedCrews";
import { ISelectedCrew } from "./types/ISelectedCrew";
import {
  getCurrentCrewId,
  getScheduleWeekColumnHeader,
  getScheduleWeekNextScheduleParams,
  getScheduleWeekPreviousScheduleParams,
  getScheduleWeekRows,
  getSelectedDateStartOfWeek,
  getWeekHeaderText,
} from "../services/scheduleWeekService";
import { reorder } from "../services/reorder";
import { useEnsureSchedulesLoadedForWeek } from "../hooks/useEnsureSchedulesLoadedForWeek";
import { useRedirectToStickyCrew } from "../hooks/useRedirectToStickyCrew";
import { CrewScheduleType } from "../enums/crewScheduleType";

interface IProps {
  crews: Array<ICrew>;
  jobs: Array<IMaintenanceJob>;
  daySchedules: Array<IDaySchedule>;
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>;
  redirect(path: Path): RouterAction;
}

interface IRouteParams {
  crewId: string;
  date: string;
  mapDate: string;
}

const ScheduleSequenceWeekPage: React.FunctionComponent<
  IProps & RouteComponentProps<IRouteParams>
> = ({
  match,
  crews,
  daySchedules,
  weeksUnscheduledMaintenanceJobs,
  redirect,
}) => {
  const [selectedCrews, setSelectedCrews] = useState<Array<ISelectedCrew>>([]);

  useRedirectToStickyCrew({
    params: match.params,
    defaultCrewUserSettings: UserSettingsType.weekDefaultCrew,
    getRoute: routingBuilders.schedule.buildSequenceWeekRoute,
  });

  useRedirectToTimeScheduleIfCrewNotTimeBased({
    params: match.params,
    crews,
  });

  useScheduleWeekDefaultSelectedCrews({
    crews,
    params: match.params,
    setSelectedCrews,
    layoutUserSettingsType: UserSettingsType.weekScheduleCrewLayout,
  });

  useEnsureSchedulesLoadedForWeek({
    crews,
    daySchedules,
    weeksUnscheduledMaintenanceJobs,
    params: match.params,
    includePrecedingDay: false,
  });

  const { setUserSettings } = useUserSettings();

  const scheduleDates = getDatesForScheduleWeek(match.params);
  const pageHeaderText = getWeekHeaderText(scheduleDates, match.params);

  const isMapViewOpen = !!match.params.mapDate;

  const columnHeaders = scheduleDates.map((d) => {
    const isCurrentDate = dateService.areDatesEqual(
      d,
      dateService.getCurrentDate()
    );
    return {
      key: d,
      columnHeader: getScheduleWeekColumnHeader(
        parse(d),
        format(d, "dddd"),
        isCurrentDate,
        CrewScheduleType.sequence
      ),
      highlightHeader: isCurrentDate,
    } as IScheduleColumnHeader;
  });

  const rows = getScheduleWeekRows({
    selectedCrews,
    crews,
    scheduleDates,
    daySchedules,
    params: match.params,
    getRoutePath: builders.schedule.buildSequenceWeekRoute,
  });

  const { hasMissingDates, loadingUnscheduledJobs } = isLoadingScheduleDates(
    getSelectedDateStartOfWeek(match.params),
    rows.reduce(
      (acc, row) => [...acc, ...row.columns],
      [] as Array<IScheduleColumn | null>
    ),
    weeksUnscheduledMaintenanceJobs
  );

  return (
    <PageWithNavBar2
      subHeaderLeftSideContent={
        <ScheduleWeekFilters
          selectedCrews={selectedCrews}
          params={match.params}
          todayButtonLink={builders.schedule.buildSequenceWeekRoute(
            dateService.formatAsIso(new Date()),
            getCurrentCrewId(selectedCrews),
            isMapViewOpen ? dateService.formatAsIso(new Date()) : undefined
          )}
        />
      }
    >
      {rows.length > 0 ? (
        <Schedule
          pageHeaderText={pageHeaderText}
          rows={rows}
          onRowExpanded={(rowIndexToToggle) => {
            const updatedSelectedCrews = selectedCrews.map((c, index) => {
              if (index === rowIndexToToggle) {
                return {
                  ...c,
                  expanded: !c.expanded,
                };
              } else {
                return c;
              }
            });

            setSelectedCrews(updatedSelectedCrews);

            setUserSettings(
              UserSettingsType.weekScheduleCrewLayout,
              updatedSelectedCrews
            );
          }}
          columnHeaders={columnHeaders}
          mode="week"
          isLoading={hasMissingDates}
          loadingFlexibleJobs={loadingUnscheduledJobs}
          nextSchedule={() => {
            const { date, crewId, mapDate } = getScheduleWeekNextScheduleParams(
              {
                isMapViewOpen,
                params: match.params,
                scheduleDate: scheduleDates[0],
                selectedCrews,
              }
            );

            redirect(
              routingBuilders.schedule.buildSequenceWeekRoute(
                date,
                crewId,
                mapDate
              )
            );
          }}
          changeScheduleDate={(newDate) =>
            redirect(
              routingBuilders.schedule.buildSequenceWeekRoute(
                dateService.formatAsIso(newDate),
                match.params.crewId
              )
            )
          }
          weekForUnscheduledJobs={getSelectedDateStartOfWeek(match.params)}
          previousSchedule={() => {
            const { date, crewId, mapDate } =
              getScheduleWeekPreviousScheduleParams({
                isMapViewOpen,
                params: match.params,
                scheduleDate: scheduleDates[0],
                selectedCrews,
              });

            redirect(
              routingBuilders.schedule.buildSequenceWeekRoute(
                date,
                crewId,
                mapDate
              )
            );
          }}
          onRowReorder={(sourceIndex, destinationIndex) => {
            const updatedSelectedCrews = reorder(
              selectedCrews,
              sourceIndex,
              destinationIndex
            );
            setSelectedCrews(updatedSelectedCrews);
            setUserSettings(
              UserSettingsType.weekScheduleCrewLayout,
              updatedSelectedCrews
            );
          }}
        />
      ) : null}
    </PageWithNavBar2>
  );
};

const mapStateToProps = (state: IRootState) => ({
  crews: state.crew.crews,
  jobs: state.job.jobs,
  daySchedules: state.schedule.daySchedules,
  weeksUnscheduledMaintenanceJobs:
    state.schedule.weeksUnscheduledMaintenanceJobs,
});

const mapDispatchToProps = {
  redirect: push,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleSequenceWeekPage);

function useRedirectToTimeScheduleIfCrewNotTimeBased({
  params,
  crews,
}: {
  params: { date: string; crewId: string; mapDate: string };
  crews: Array<ICrew>;
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    const crew = crews.find((c) => c.id === params.crewId);
    if (crew && crew.scheduleType === CrewScheduleType.time) {
      dispatch(
        push(builders.schedule.buildTimeWeekRoute(params.date, crew.id))
      );
    }

    const onlyHasActiveTimeBasedCrews = !crews.some(
      (c) => !c.inactive && c.scheduleType === CrewScheduleType.sequence
    );
    if (onlyHasActiveTimeBasedCrews) {
      dispatch(push(builders.schedule.buildTimeWeekRoute(params.date)));
    }
  }, [params.date, params.crewId, crews, dispatch]);
}
