import TextareaAutosize from "react-autosize-textarea";
import { isStringSet } from "../../../services/stringService";
import { IInvoiceDefaultProposalJobSummary } from "../../../slices/billing/models/IInvoiceDefaultProposalJobSummary";
import LinkButton2 from "../components/LinkButton2";
import { getProposalJobSummaryFromSelectedJobs } from "./InvoiceForm.functions";
import { IInvoiceFormSelectedWork } from "./InvoiceForm.types";

export function InvoiceFormJobSummary({
  value,
  onChange,
  selectedWorkToBill,
  proposalJobSummaries,
}: {
  value: string;
  onChange: (newValue: string) => void;
  selectedWorkToBill: IInvoiceFormSelectedWork;
  proposalJobSummaries: Array<IInvoiceDefaultProposalJobSummary>;
}) {
  return (
    <>
      <TextareaAutosize
        maxRows={10}
        className="form-control"
        id="summary"
        value={value}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
      />
      {isStringSet(
        getProposalJobSummaryFromSelectedJobs({
          selectedWorkToBill,
          proposalJobSummaries,
        })
      ) ? (
        <div className="mt-1">
          <LinkButton2
            inlineButton
            buttonContents={<small>Copy job proposal summary</small>}
            onClick={() => {
              onChange(
                `${value}\n${getProposalJobSummaryFromSelectedJobs({
                  selectedWorkToBill,
                  proposalJobSummaries,
                })}`.trim()
              );
            }}
          />
        </div>
      ) : null}
    </>
  );
}
