import React from "react";
import { IRootState } from "../../../store";
import { connect } from "react-redux";
import { ITodoTemplate } from "../../../models/ITodoTemplate";
import ManageTodoItems from "./ManageTodoItems";
import { getSortedTodoTemplates } from "../../../services/sortingService";
import uuidv4 from "uuid/v4";

export interface ITodoItem {
  id: string | null;
  text: string;
  required: boolean;
  tempId: string;
}

interface IProps extends IOwnProps {
  todoTemplates: Array<ITodoTemplate>;
}

interface IOwnProps {
  value: Array<ITodoItem>;
  todoTemplateId: string;
  onChange(newValue: Array<ITodoItem>, todoTemplateId: string): void;
  locked?: boolean;
}

const JobTodoItems: React.FunctionComponent<IProps> = ({
  value,
  todoTemplateId,
  onChange,
  locked,
  todoTemplates,
}) => {
  let todoItems: Array<ITodoItem>;
  if (!!todoTemplateId && !locked) {
    const todoTemplate = todoTemplates.find((t) => t.id === todoTemplateId);
    if (todoTemplate) {
      todoItems = todoTemplate.todoItems.map((ti) => ({
        ...ti,
        tempId: ti.id as string,
      }));
    } else {
      todoItems = [];
    }
  } else {
    todoItems = value;
  }

  return (
    <React.Fragment>
      <h5>Checklist for crew</h5>
      <div className="form-section">
        {locked ? (
          <div style={{ marginBottom: "10px" }} className="text-warning">
            This job has started so the checklist cannot be changed.
          </div>
        ) : null}
        {todoTemplates.length > 0 ? (
          <div className="form-group">
            <label htmlFor="todoTemplateId" className="required">
              Checklist template
            </label>
            <select
              className="form-control"
              name="todoTemplateId"
              value={todoTemplateId}
              onChange={(e) => onChange([], e.target.value)}
              disabled={locked}
            >
              <option value="">None</option>
              {getSortedTodoTemplates(todoTemplates).map((t) => (
                <option value={t.id} key={t.id}>
                  {t.name}
                </option>
              ))}
            </select>
          </div>
        ) : null}
        <ManageTodoItems
          value={todoItems}
          onChange={(newTodoItems) => onChange(newTodoItems, todoTemplateId)}
          locked={locked}
          readOnly={todoTemplateId !== ""}
        />
        {!!todoTemplateId && !locked ? (
          <div className="form-group">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => {
                const todoTemplate = todoTemplates.find(
                  (t) => t.id === todoTemplateId
                );
                if (todoTemplate) {
                  const newValue: Array<ITodoItem> = todoTemplate.todoItems.map(
                    (t) => ({
                      ...t,
                      id: null,
                      tempId: uuidv4(),
                    })
                  );
                  onChange(newValue, "");
                }
              }}
            >
              Customize checklist items
            </button>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps): IProps => ({
  todoTemplates: state.common.todoTemplates,
  value: ownProps.value,
  todoTemplateId: ownProps.todoTemplateId,
  onChange: ownProps.onChange,
  locked: ownProps.locked,
});

export default connect(mapStateToProps)(JobTodoItems);
