import LinkButton2 from "../../../containers/app/components/LinkButton2";
import { useUserSettings } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import { useShowVerificationEnrollmentMessage } from "../hooks/useShowVerificationEnrollmentMessage";
import { TollFreeVerificationPrompt } from "./TollFreeVerificationPrompt";

export function TollFreeVerificationAlert() {
  const { setUserSettings, getUserSettings } = useUserSettings();

  const showVerificationMessage = useShowVerificationEnrollmentMessage();
  if (
    !showVerificationMessage ||
    getUserSettings<boolean>(
      UserSettingsType.tollFreeVerificationAlertDismissed
    ) === true
  ) {
    return null;
  }

  return (
    <>
      <div className={"mt-3 mx-2"}>
        {" "}
        <TollFreeVerificationPrompt
          footerContents={
            <>
              <div className="mt-1">
                <LinkButton2
                  style={{ textDecoration: "underline" }}
                  className="text-dark font-weight-bold"
                  buttonContents={"Dismiss"}
                  testId="dismissButton"
                  onClick={() => {
                    setUserSettings(
                      UserSettingsType.tollFreeVerificationAlertDismissed,
                      true
                    );
                  }}
                  inlineButton
                />
              </div>
            </>
          }
        />
      </div>
    </>
  );
}
