import React from "react";
import ErrorJumbotron from "./ErrorJumbotron";

const GenericErrorMessage: React.FunctionComponent<{}> = () => {
  return (
    <ErrorJumbotron
      details={
        <>
          An unknown error occurred. Please refresh this website to continue. If
          you continue to have problems, email support at{" "}
          <a href="mailto:crewcontrol@youraspire.com">
            crewcontrol@youraspire.com
          </a>
          .
        </>
      }
    />
  );
};

export default GenericErrorMessage;
