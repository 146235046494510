import { useState } from "react";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export interface IAddButton {
  label: string;
  onClick: () => void;
  mobileLabel?: string;
  hide?: boolean;
  className?: string;
}

export default function CustomersChildEntityListingAddButtons({
  addButtons,
}: {
  addButtons: Array<IAddButton>;
}) {
  const [showActionDropdown, setShowActionDropdown] = useState(false);

  const visibleAddButtons = addButtons.filter((b) => !b.hide);

  return (
    <div>
      {visibleAddButtons.length > 1 ? (
        <ButtonDropdown
          className="d-block d-md-none"
          direction="down"
          isOpen={showActionDropdown}
          toggle={() => setShowActionDropdown(!showActionDropdown)}
        >
          <DropdownToggle className="btn-sm" caret>
            Add
          </DropdownToggle>
          <DropdownMenu right={true}>
            {visibleAddButtons.map((addButton) => (
              <DropdownItem key={addButton.label} onClick={addButton.onClick}>
                {addButton.mobileLabel ?? addButton.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </ButtonDropdown>
      ) : null}

      <div className="d-flex" style={{ columnGap: "10px" }}>
        {visibleAddButtons.map((addButton) => (
          <button
            key={addButton.label}
            className={
              (visibleAddButtons.length > 1
                ? "d-none d-md-inline btn btn-secondary btn-sm"
                : "btn btn-secondary btn-sm") +
              " " +
              (addButton.className ?? "")
            }
            onClick={addButton.onClick}
          >
            {addButton.label}
          </button>
        ))}
      </div>
    </div>
  );
}
