import { ajax } from "rxjs/ajax";
import {
  buildUrl,
  executeWithHeaders,
} from "../../../services/remoteDataProvider";
import { map } from "rxjs/operators";
import { MaintenanceJobFrequency } from "../../../models/IMaintenanceJob";
import { JobType } from "../../../models/IJob";
import { SortDirection } from "../../../enums/sortDirection";

export type JobConflict = {
  jobInstanceId: string;
  customerName: string;
  address: string;
  date: string;
  startTime: string | null;
  endTime: string | null;
};

export type CopyJobsRequest = {
  sourceJobInstanceIds: Array<string>;
  destinationDate: string;
  destinationCrewId: string;
  precedingJobInstanceId: string | null;
};

export type GetCustomerJobsJob = {
  id: string;
  crewId: string | null;
  date: string | null;
  endingDate: string | null;
  frequency: MaintenanceJobFrequency | null;
  seasonalScheduleFrequency: MaintenanceJobFrequency | null;
  jobType: JobType;
  daysOfWeek: Array<number> | null;
  categories: string | null;
  proposalNumber: string | null;
  proposalLookupId: string | null;
  paymentAuthorized: boolean;
  customerAdditionalLocationName: string | null;
  flexibleJob: boolean;
  projectVisits: Array<GetCustomerJobsProjectVisit> | null;
};

export type GetCustomerJobsProjectVisit = {
  crewId: string | null;
  date: string;
};

export type GetCustomerJobsRequest = {
  customerId: string;
  locationId: string | null;
  proposalNumber: string | null;
  page: number;
  pageSize: number;
  sortColumn: number;
  sortDirection: SortDirection;
};

const jobDataProvider = {
  getConflicts: ({
    currentJobInstanceId,
    crewId,
    date,
    startTime,
    endTime,
  }: {
    currentJobInstanceId: string | null;
    crewId: string;
    date: string;
    startTime: string;
    endTime: string;
  }) => {
    const params: Array<string> = [];
    params.push(`startTime=${encodeURIComponent(startTime)}`);
    params.push(`endTime=${encodeURIComponent(endTime)}`);
    params.push(`crewId=${encodeURIComponent(crewId)}`);

    if (currentJobInstanceId) {
      params.push(
        `currentJobInstanceId=${encodeURIComponent(currentJobInstanceId)}`
      );
    }

    params.push(`date=${encodeURIComponent(date)}`);

    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(`daySchedule/conflicts?${params.join("&")}`), headers)
    ).pipe(
      map((result) => {
        return result.response.conflicts as Array<JobConflict>;
      })
    );
  },

  copyJobs: (payload: CopyJobsRequest) => {
    return executeWithHeaders((headers) =>
      ajax
        .post(buildUrl("onetimejob/CopyFromJobInstances"), payload, headers)
        .pipe(map(() => ({ data: payload })))
    );
  },

  getCustomerJobs: (payload: GetCustomerJobsRequest) => {
    const params: Array<string> = [];
    params.push(`page=${encodeURIComponent(payload.page)}`);
    params.push(`pageSize=${encodeURIComponent(payload.pageSize)}`);
    params.push(`sortColumn=${encodeURIComponent(payload.sortColumn)}`);
    params.push(`sortDirection=${encodeURIComponent(payload.sortDirection)}`);

    if (payload.locationId) {
      params.push(`locationId=${encodeURIComponent(payload.locationId)}`);
    }

    if (payload.proposalNumber) {
      params.push(
        `proposalNumber=${encodeURIComponent(payload.proposalNumber)}`
      );
    }

    return executeWithHeaders((headers) =>
      ajax.get(
        buildUrl(`customer/${payload.customerId}/jobs?${params.join("&")}`),
        headers
      )
    ).pipe(
      map((result) => {
        return result.response as {
          jobs: Array<GetCustomerJobsJob>;
          hasMoreResults: boolean;
        };
      })
    );
  },
};

export default jobDataProvider;
