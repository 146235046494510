import { IJobInstance } from "../models/IJobInstance";

type JobInstanceContainer = {
  jobInstances: Array<IJobInstance>;
};

export function selectJobInstancesInRange({
  daySchedules,
  newSelectedJobInstance,
  lastSingleSelectedJobInstance,
}: {
  daySchedules: Array<JobInstanceContainer>;
  newSelectedJobInstance: string;
  lastSingleSelectedJobInstance: string | null;
}): Array<string> {
  if (!lastSingleSelectedJobInstance) {
    return [];
  }

  const dayScheduleForLastSelectedJobCardIndex = daySchedules.findIndex((ds) =>
    ds.jobInstances.some((ji) => ji.id === lastSingleSelectedJobInstance)
  );
  const dayScheduleForNewSelectedJobCardIndex = daySchedules.findIndex((ds) =>
    ds.jobInstances.some((ji) => ji.id === newSelectedJobInstance)
  );

  if (
    dayScheduleForLastSelectedJobCardIndex === -1 ||
    dayScheduleForNewSelectedJobCardIndex === -1
  ) {
    return [];
  }

  let jobInstancesToSelect: Array<string> = [];
  if (
    dayScheduleForLastSelectedJobCardIndex ===
    dayScheduleForNewSelectedJobCardIndex
  ) {
    jobInstancesToSelect = getJobInstancesToSelectWhenCardsOnSameSchedule({
      daySchedules,
      dayScheduleForLastSelectedJobCardIndex,
      lastSingleSelectedJobInstance,
      dayScheduleForNewSelectedJobCardIndex,
      newSelectedJobInstance: newSelectedJobInstance,
    });
  } else {
    getJobInstancesToSelectWhenCardsOnDifferentSchedules({
      dayScheduleForLastSelectedJobCardIndex,
      dayScheduleForNewSelectedJobCardIndex,
      daySchedules,
      newSelectedJobInstance,
      lastSingleSelectedJobInstance,
      jobInstancesToSelect,
    });
  }

  return jobInstancesToSelect;
}

function getJobInstancesToSelectWhenCardsOnSameSchedule({
  daySchedules,
  dayScheduleForLastSelectedJobCardIndex,
  lastSingleSelectedJobInstance,
  dayScheduleForNewSelectedJobCardIndex,
  newSelectedJobInstance: jobInstanceId,
}: {
  daySchedules: JobInstanceContainer[];
  newSelectedJobInstance: string;
  lastSingleSelectedJobInstance: string;
  dayScheduleForNewSelectedJobCardIndex: number;
  dayScheduleForLastSelectedJobCardIndex: number;
}) {
  const lastSelectedJobCardIndex = daySchedules[
    dayScheduleForLastSelectedJobCardIndex
  ].jobInstances.findIndex((ji) => ji.id === lastSingleSelectedJobInstance);
  const newSelectedJobCardIndex = daySchedules[
    dayScheduleForNewSelectedJobCardIndex
  ].jobInstances.findIndex((ji) => ji.id === jobInstanceId);

  let startRange: number;
  let endRange: number;
  if (lastSelectedJobCardIndex < newSelectedJobCardIndex) {
    startRange = lastSelectedJobCardIndex + 1;
    endRange = newSelectedJobCardIndex;
  } else {
    startRange = newSelectedJobCardIndex + 1;
    endRange = lastSelectedJobCardIndex;
  }

  return daySchedules[dayScheduleForLastSelectedJobCardIndex].jobInstances
    .map((ji) => ji.id)
    .slice(startRange, endRange);
}

function getJobInstancesToSelectWhenCardsOnDifferentSchedules({
  dayScheduleForLastSelectedJobCardIndex,
  dayScheduleForNewSelectedJobCardIndex,
  daySchedules,
  newSelectedJobInstance,
  lastSingleSelectedJobInstance,
  jobInstancesToSelect,
}: {
  dayScheduleForLastSelectedJobCardIndex: number;
  dayScheduleForNewSelectedJobCardIndex: number;
  daySchedules: JobInstanceContainer[];
  newSelectedJobInstance: string;
  lastSingleSelectedJobInstance: string;
  jobInstancesToSelect: string[];
}) {
  let {
    startScheduleIndex,
    startJobInstanceIndex,
    endScheduleIndex,
    endJobInstanceIndex,
  }: {
    startScheduleIndex: number;
    startJobInstanceIndex: number;
    endScheduleIndex: number;
    endJobInstanceIndex: number;
  } = getBoundaryIndexes(
    dayScheduleForLastSelectedJobCardIndex,
    dayScheduleForNewSelectedJobCardIndex,
    daySchedules,
    newSelectedJobInstance,
    lastSingleSelectedJobInstance
  );

  jobInstancesToSelect.push(
    ...getJobInstancesOnStartColumn({
      daySchedules,
      startScheduleIndex,
      startJobInstanceIndex,
    })
  );

  jobInstancesToSelect.push(
    ...getJobInstancesOnMiddleColumns({
      startScheduleIndex,
      endScheduleIndex,
      daySchedules,
    })
  );

  if (endJobInstanceIndex > 0) {
    jobInstancesToSelect.push(
      ...getJobInstancesOnEndColumn({
        daySchedules,
        endScheduleIndex,
        endJobInstanceIndex,
      })
    );
  }
}

function getBoundaryIndexes(
  dayScheduleForLastSelectedJobCardIndex: number,
  dayScheduleForNewSelectedJobCardIndex: number,
  daySchedules: JobInstanceContainer[],
  newSelectedJobInstance: string,
  lastSingleSelectedJobInstance: string
) {
  let startScheduleIndex: number;
  let endScheduleIndex: number;
  let startJobInstanceIndex: number;
  let endJobInstanceIndex: number;
  if (
    dayScheduleForLastSelectedJobCardIndex >
    dayScheduleForNewSelectedJobCardIndex
  ) {
    startScheduleIndex = dayScheduleForNewSelectedJobCardIndex;
    startJobInstanceIndex = getJobInstanceIndex(
      daySchedules[dayScheduleForNewSelectedJobCardIndex],
      newSelectedJobInstance
    );

    endScheduleIndex = dayScheduleForLastSelectedJobCardIndex;
    endJobInstanceIndex = getJobInstanceIndex(
      daySchedules[dayScheduleForLastSelectedJobCardIndex],
      lastSingleSelectedJobInstance
    );
  } else {
    startScheduleIndex = dayScheduleForLastSelectedJobCardIndex;
    startJobInstanceIndex = getJobInstanceIndex(
      daySchedules[dayScheduleForLastSelectedJobCardIndex],
      lastSingleSelectedJobInstance
    );

    endScheduleIndex = dayScheduleForNewSelectedJobCardIndex;
    endJobInstanceIndex = getJobInstanceIndex(
      daySchedules[dayScheduleForNewSelectedJobCardIndex],
      newSelectedJobInstance
    );
  }
  return {
    startScheduleIndex,
    startJobInstanceIndex,
    endScheduleIndex,
    endJobInstanceIndex,
  };
}

function getJobInstancesOnStartColumn({
  daySchedules,
  startScheduleIndex,
  startJobInstanceIndex,
}: {
  daySchedules: JobInstanceContainer[];
  startScheduleIndex: number;
  startJobInstanceIndex: number;
}) {
  const scheduleJobInstanceCount =
    daySchedules[startScheduleIndex].jobInstances.length;
  if (scheduleJobInstanceCount > startJobInstanceIndex + 1) {
    return daySchedules[startScheduleIndex].jobInstances
      .slice(
        startJobInstanceIndex + 1,
        scheduleJobInstanceCount - startJobInstanceIndex + 1
      )
      .map((ji) => ji.id);
  } else {
    return [];
  }
}

function getJobInstancesOnMiddleColumns({
  startScheduleIndex,
  endScheduleIndex,
  daySchedules,
}: {
  startScheduleIndex: number;
  endScheduleIndex: number;
  daySchedules: JobInstanceContainer[];
}) {
  let result: Array<string> = [];
  for (let i = startScheduleIndex + 1; i < endScheduleIndex; i++) {
    result.push(...daySchedules[i].jobInstances.map((ji) => ji.id));
  }

  return result;
}

function getJobInstancesOnEndColumn({
  daySchedules,
  endScheduleIndex,
  endJobInstanceIndex,
}: {
  daySchedules: JobInstanceContainer[];
  endScheduleIndex: number;
  endJobInstanceIndex: number;
}) {
  return daySchedules[endScheduleIndex].jobInstances
    .slice(0, endJobInstanceIndex)
    .map((ji) => ji.id);
}

function getJobInstanceIndex(
  daySchedule: JobInstanceContainer,
  jobInstanceId: string
): number {
  return daySchedule.jobInstances.findIndex((ji) => ji.id === jobInstanceId);
}
