import { IDaySchedule } from "../../../models/IDaySchedule";
import { IScheduleRow } from "../components/types/IScheduleRow";
import { getColumnsFromRow } from "./getColumnsFromRow";

export function getSchedulesForColumns(
  r: IScheduleRow,
  daySchedules: IDaySchedule[]
): IDaySchedule[] {
  return getColumnsFromRow(r).map(
    (r) => daySchedules.find((ds) => ds.id === r.dayScheduleId) as IDaySchedule
  );
}
