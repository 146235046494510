import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import React, { useCallback, useEffect, useState } from "react";
import { getInvoiceItems } from "../../../services/invoiceFormService";
import ModalDataLoader from "../../../containers/app/components/ModalDataLoader";
import { IInvoiceItem } from "../../../models/IInvoiceItem";
import { forkJoin, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import proposalDataProvider from "../services/proposalDataProvider";
import { IProposalTemplate } from "../models/IProposalTemplate";
import ProposalFormBody from "./ProposalForm/ProposalFormBody";
import { IFormData } from "./ProposalForm/IFormData";
import dataProvider from "../../../services/dataProvider";
import { IQuickBooksCustomer } from "../../../models/IQuickBooksCustomer";
import { useDispatch } from "react-redux";
import constants from "../../../constants";
import {
  commonUiActionCreators,
  QuickBooksModalMode,
} from "../../../modules/commonUi";
import SubscriptionWarning from "../../tenantSubscription/components/SubscriptionWarning";

interface ILoadData {
  invoiceItems: Array<IInvoiceItem>;
  templates: Array<IProposalTemplate>;
  quickBooksCustomers: Array<IQuickBooksCustomer>;
}

export interface IProposalFormProps {
  opportunityId: string | null;
  customerId?: string | null;
  proposalId: string | null;
  defaultValues?: Partial<IFormData>;
  onSaveComplete: () => void;
  onCancel: () => void;
  hideTemplateField?: boolean;
}

const ProposalForm: React.FunctionComponent<IProposalFormProps> = ({
  opportunityId,
  proposalId,
  customerId,
  defaultValues,
  onSaveComplete,
  onCancel,
  hideTemplateField,
}) => {
  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );
  const [invoiceItems, setInvoiceItems] = useState<Array<IInvoiceItem>>([]);
  const [proposalTemplates, setProposalTemplates] = useState<
    Array<IProposalTemplate>
  >([]);
  const [quickBooksCustomers, setQuickBooksCustomers] = useState<
    Array<IQuickBooksCustomer>
  >([]);
  const dispatch = useDispatch();
  const [quickBooksReconnectRequired, setQuickBooksReconnectRequired] =
    useState(false);

  const loadData = useCallback(() => {
    return forkJoin({
      items: getInvoiceItems(isQuickBooksEnabled),
      templates: proposalDataProvider.getProposalTemplates(),
      quickBooksCustomers: isQuickBooksEnabled
        ? dataProvider.getQuickBooksCustomers()
        : of([]),
    }).pipe(
      map(({ items, templates, quickBooksCustomers }) => {
        return {
          invoiceItems: items,
          templates: templates,
          quickBooksCustomers: quickBooksCustomers,
        } as ILoadData;
      }),
      catchError((err) => {
        if (
          err?.response?.errorCode === constants.quickBooksReconnectRequired
        ) {
          setQuickBooksReconnectRequired(true);
        }
        return throwError(err);
      })
    );
  }, [isQuickBooksEnabled]);

  // Using a separate useEffect so loadData won't be triggered if onCancel changes
  useEffect(() => {
    if (quickBooksReconnectRequired) {
      dispatch(
        commonUiActionCreators.showQuickBooksModal({
          quickBooksModalMode: QuickBooksModalMode.reconnect,
        })
      );
      onCancel();
    }
  }, [quickBooksReconnectRequired, dispatch, onCancel]);

  const handleLoadedData = useCallback((result: ILoadData) => {
    setInvoiceItems(result.invoiceItems);
    setProposalTemplates(result.templates);
    setQuickBooksCustomers(result.quickBooksCustomers);
  }, []);

  return (
    <ModalDataLoader<ILoadData>
      errorMessage={
        "The data could not be loaded for this form. Please check your Internet connection and try again."
      }
      onErrorAlertClose={onCancel}
      loadData={loadData}
      onDataLoaded={handleLoadedData}
    >
      <SubscriptionWarning
        mode="alwaysLockedWhenNotSubscribed"
        onCancel={() => {
          onCancel();
        }}
      >
        <ProposalFormBody
          invoiceItems={invoiceItems}
          setInvoiceItems={setInvoiceItems}
          proposalTemplates={proposalTemplates}
          setProposalTemplates={setProposalTemplates}
          quickBooksCustomers={quickBooksCustomers}
          setQuickBooksCustomers={setQuickBooksCustomers}
          proposalId={proposalId}
          opportunityId={opportunityId}
          customerId={customerId ?? null}
          defaultValues={defaultValues}
          onSaveComplete={onSaveComplete}
          onCancel={onCancel}
          hideTemplateField={hideTemplateField}
        />
      </SubscriptionWarning>
    </ModalDataLoader>
  );
};

export default ProposalForm;
