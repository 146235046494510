import React, { useState, useEffect, useRef } from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import FormContainer2 from "../components/FormContainer2";
import { IAdminViewConfiguration } from "../../../models/IAdminViewConfiguration";
import InfoToolTip from "../components/InfoToolTip";
import { CrewScheduleType } from "../../../slices/schedule/enums/crewScheduleType";

const AdminViewConfigurationForm: React.SFC<{}> = () => {
  const adminViewConfiguration = useApplicationStateSelector(
    (s) => s.common.adminViewConfiguration
  );
  const crews = useApplicationStateSelector((s) => s.crew.crews);

  const activeCrews = crews.filter((c) => !c.inactive);
  const hasNonTimeBasedCrews = activeCrews.some(
    (c) => c.scheduleType !== CrewScheduleType.time
  );
  const hasTimeBasedCrews = activeCrews.some(
    (c) => c.scheduleType === CrewScheduleType.time
  );

  const [formData, setFormData] = useState<IAdminViewConfiguration>({
    showActualTimeWorkedOnAdminJobCard: false,
    showActualManHoursOnAdminJobCard: false,
    showEstimatedTimeWorkedOnAdminJobCard: false,
    showEstimatedManHoursOnAdminJobCard: false,
    showLastVisitDateOnAdminJobCard: false,
    showPermanentChangePrompt: false,
    showCustomerPhoneNumberOnAdminJobCard: false,
    showCustomerEmailAddressOnAdminJobCard: false,
    showGrossRevenueOnAdminJobCard: false,
    showAlertForSkippedAndUncompletedJobs: false,
    showFrequencyOnJobCard: false,
    automaticallyMoveNotStartedJobs: false,
  });

  const showForm = useApplicationStateSelector(
    (s) => s.forms.adminViewConfiguration.showForm
  );
  const previousShowForm = useRef<boolean>(false);
  useEffect(() => {
    if (showForm && !previousShowForm.current) {
      setFormData({
        ...adminViewConfiguration,
      });
    }

    previousShowForm.current = showForm;
  }, [showForm, setFormData, adminViewConfiguration]);

  return (
    <FormContainer2
      formType={"adminViewConfiguration"}
      getFormData={() => {
        return formData;
      }}
      formHeader="Admin View Display"
    >
      <div className="form-section">
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="showPermanentChangePrompt"
              checked={formData.showPermanentChangePrompt}
              onChange={(e) => {
                let value = e.target.checked;
                setFormData({
                  ...formData,
                  showPermanentChangePrompt: value,
                });
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="showPermanentChangePrompt"
            >
              Show prompt to apply changes to future weeks after dragging a job
            </label>
          </div>
        </div>
        {hasNonTimeBasedCrews ? (
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="automaticallyMoveNotStartedJobs"
                checked={formData.automaticallyMoveNotStartedJobs}
                onChange={(e) => {
                  let value = e.target.checked;
                  setFormData({
                    ...formData,
                    automaticallyMoveNotStartedJobs: value,
                  });
                }}
              />
              <label
                style={{ display: "inline" }}
                className="custom-control-label"
                htmlFor="automaticallyMoveNotStartedJobs"
              >
                Auto move jobs not started to the following day
              </label>
              <InfoToolTip
                id="automaticallyMoveNotStartedJobsToolTip"
                text={
                  <>
                    <div>
                      Any jobs that are not started by midnight will
                      automatically move to the top of the following day's
                      schedule for the current week. Jobs from Saturday will
                      move to the following Monday.
                    </div>
                    {hasTimeBasedCrews ? (
                      <div className="mt-2">
                        Crews whose scheduling is time-based will not be
                        updated.
                      </div>
                    ) : null}
                  </>
                }
              />
            </div>
          </div>
        ) : null}
      </div>

      <h5>Display on Job Cards</h5>
      <div className="form-section">
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="showActualManHoursOnAdminJobCard"
              checked={formData.showActualManHoursOnAdminJobCard}
              onChange={(e) => {
                let value = e.target.checked;
                setFormData({
                  ...formData,
                  showActualManHoursOnAdminJobCard: value,
                });
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="showActualManHoursOnAdminJobCard"
            >
              Actual man hours
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="showActualTimeWorkedOnAdminJobCard"
              checked={formData.showActualTimeWorkedOnAdminJobCard}
              onChange={(e) => {
                let value = e.target.checked;
                setFormData({
                  ...formData,
                  showActualTimeWorkedOnAdminJobCard: value,
                });
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="showActualTimeWorkedOnAdminJobCard"
            >
              Actual time worked
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="showAlertForSkippedAndUncompletedJobs"
              checked={formData.showAlertForSkippedAndUncompletedJobs}
              onChange={(e) => {
                let value = e.target.checked;
                setFormData({
                  ...formData,
                  showAlertForSkippedAndUncompletedJobs: value,
                });
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="showAlertForSkippedAndUncompletedJobs"
            >
              Alerts for skipped/uncompleted jobs
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="showCustomerEmailAddressOnAdminJobCard"
              checked={formData.showCustomerEmailAddressOnAdminJobCard}
              onChange={(e) => {
                let value = e.target.checked;
                setFormData({
                  ...formData,
                  showCustomerEmailAddressOnAdminJobCard: value,
                });
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="showCustomerEmailAddressOnAdminJobCard"
            >
              Customer email address
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="showCustomerPhoneNumberOnAdminJobCard"
              checked={formData.showCustomerPhoneNumberOnAdminJobCard}
              onChange={(e) => {
                let value = e.target.checked;
                setFormData({
                  ...formData,
                  showCustomerPhoneNumberOnAdminJobCard: value,
                });
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="showCustomerPhoneNumberOnAdminJobCard"
            >
              Customer phone number
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="showEstimatedManHoursOnAdminJobCard"
              checked={formData.showEstimatedManHoursOnAdminJobCard}
              onChange={(e) => {
                let value = e.target.checked;
                setFormData({
                  ...formData,
                  showEstimatedManHoursOnAdminJobCard: value,
                });
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="showEstimatedManHoursOnAdminJobCard"
            >
              Estimated man hours
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="showEstimatedTimeWorkedOnAdminJobCard"
              checked={formData.showEstimatedTimeWorkedOnAdminJobCard}
              onChange={(e) => {
                let value = e.target.checked;
                setFormData({
                  ...formData,
                  showEstimatedTimeWorkedOnAdminJobCard: value,
                });
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="showEstimatedTimeWorkedOnAdminJobCard"
            >
              Estimated time worked
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="showFrequencyOnJobCard"
              checked={formData.showFrequencyOnJobCard}
              onChange={(e) => {
                let value = e.target.checked;
                setFormData({
                  ...formData,
                  showFrequencyOnJobCard: value,
                });
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="showFrequencyOnJobCard"
            >
              Frequency
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="showGrossRevenueOnAdminJobCard"
              checked={formData.showGrossRevenueOnAdminJobCard}
              onChange={(e) => {
                let value = e.target.checked;
                setFormData({
                  ...formData,
                  showGrossRevenueOnAdminJobCard: value,
                });
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="showGrossRevenueOnAdminJobCard"
            >
              Amount per visit
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="showLastVisitDateOnAdminJobCard"
              checked={formData.showLastVisitDateOnAdminJobCard}
              onChange={(e) => {
                let value = e.target.checked;
                setFormData({
                  ...formData,
                  showLastVisitDateOnAdminJobCard: value,
                });
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="showLastVisitDateOnAdminJobCard"
            >
              Last visit date
            </label>
          </div>
        </div>
      </div>
    </FormContainer2>
  );
};

export default AdminViewConfigurationForm;
