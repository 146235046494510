import { actionTypes } from "./actionCreators";
import {
  IOneTimeJobLoadComplete,
  IMaintenanceJobLoadComplete,
  ICustomerLoadComplete,
  ICustomerLoadStart,
  ICustomerDeleteComplete,
  ICustomerAdditionalLocationDeleteComplete,
  ICustomerPaymentConfigurationClear,
  IManageCustomersSearchComplete,
  ICustomerLoadError,
  ICustomerLoadClearError,
  ICustomerPaymentMethodClear,
  ICustomerQuickBooksLinkUpdated,
  IInvoiceConfigurationUpdated,
} from "./actionTypeDefinitions";
import { ICustomer } from "../models/ICustomer";
import {
  formTypes,
  actionTypes as formActionTypes,
  createSpecificActionTypeName,
} from "../formGenerator";
import { ICustomerAdditionalLocation } from "../models/ICustomerAdditionalLocation";
import { IManageCustomerSearchResult } from "../models/IManageCustomersSearch";
import {
  ISaveProposalAction,
  proposalsActionCreators,
} from "../slices/sales/modules/proposal";
import { isStringSet } from "../services/stringService";

export interface ICustomerState {
  customers: Array<ICustomer>;
  customersLoading: Array<string>;
  customersFailedToLoad: Array<string>;
  customersNotFound: Array<string>;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
  fullyLoadedCustomers: Array<string>;
  manageCustomersSearch: IManageCustomersSearch;
}

export interface IManageCustomersSearch {
  matchingCustomers: Array<IManageCustomerSearchResult>;
  additionalCustomers: number;
  loading: boolean;
  error: boolean;
  searchText: string;
  resetCount: number;
}

export default (s: ICustomerState | undefined, action: any): ICustomerState => {
  if (!s) {
    s = {
      customers: [],
      customersLoading: [],
      customersFailedToLoad: [],
      customersNotFound: [],
      customerAdditionalLocations: [],
      fullyLoadedCustomers: [],
      manageCustomersSearch: {
        matchingCustomers: [],
        additionalCustomers: 0,
        loading: false,
        error: false,
        searchText: "",
        resetCount: 0,
      },
    };
  }
  const state = s;

  switch (action.type) {
    case actionTypes.ONE_TIME_JOB_LOAD_COMPLETE:
      const actionOneTimeJobLoadComplete = action as IOneTimeJobLoadComplete;
      return {
        ...state,
        customers: getCustomersToAdd(
          actionOneTimeJobLoadComplete.customers,
          state
        ),
        customerAdditionalLocations: getCustomerAdditionalLocationsToAdd(
          actionOneTimeJobLoadComplete.customerAdditionalLocations,
          state
        ),
      };

    case actionTypes.MAINTENANCE_JOB_LOAD_COMPLETE:
      const actionMaintenanceJobLoadComplete =
        action as IMaintenanceJobLoadComplete;
      return {
        ...state,
        customers: getCustomersToAdd(
          actionMaintenanceJobLoadComplete.customers,
          state
        ),
        customerAdditionalLocations: getCustomerAdditionalLocationsToAdd(
          actionMaintenanceJobLoadComplete.customerAdditionalLocations,
          state
        ),
      };

    case actionTypes.CUSTOMER_LOAD_START:
      const actionCustomerLoadStart = action as ICustomerLoadStart;
      return {
        ...state,
        customersLoading: [
          ...state.customersLoading,
          ...actionCustomerLoadStart.customerIds,
        ],
      };

    case actionTypes.CUSTOMER_LOAD_ERROR:
      const actionCustomerLoadError = action as ICustomerLoadError;
      return {
        ...state,
        customersFailedToLoad: [
          ...state.customersFailedToLoad,
          ...actionCustomerLoadError.customerIds,
        ],
      };

    case actionTypes.CUSTOMER_LOAD_NOT_FOUND:
      const actionCustomerNotFoundError = action as ICustomerLoadError;
      return {
        ...state,
        customersNotFound: [
          ...state.customersNotFound,
          ...actionCustomerNotFoundError.customerIds,
        ],
      };

    case actionTypes.CUSTOMER_LOAD_ERROR_CLEAR:
      const actionCustomerLoadErrorClear = action as ICustomerLoadClearError;
      return {
        ...state,
        customersFailedToLoad: state.customersFailedToLoad.filter(
          (c) => !actionCustomerLoadErrorClear.customerIds.includes(c)
        ),
        customersNotFound: state.customersFailedToLoad.filter(
          (c) => !actionCustomerLoadErrorClear.customerIds.includes(c)
        ),
        customersLoading: state.customersLoading.filter(
          (c) => !actionCustomerLoadErrorClear.customerIds.includes(c)
        ),
      };

    case actionTypes.CUSTOMER_LOAD_COMPLETE:
      const actionCustomerLoadComplete = action as ICustomerLoadComplete;
      return {
        ...state,
        customersLoading: state.customersLoading.filter(
          (cid) =>
            !actionCustomerLoadComplete.customers.find((c) => c.id === cid)
        ),
        customers: getCustomersToAdd(
          actionCustomerLoadComplete.customers,
          state
        ),
        customerAdditionalLocations: getCustomerAdditionalLocationsToAdd(
          actionCustomerLoadComplete.customerAdditionalLocations,
          state
        ),
        fullyLoadedCustomers: [
          ...state.fullyLoadedCustomers,
          ...actionCustomerLoadComplete.customers.map((c) => c.id),
        ],
      };

    case actionTypes.CUSTOMER_QUICKBOOKS_LINK_UPDATED:
      const actionQuickBooksCustomerId =
        action as ICustomerQuickBooksLinkUpdated;

      return {
        ...state,
        customers: state.customers.map((c) => {
          if (c.id === actionQuickBooksCustomerId.customerId) {
            return {
              ...c,
              quickBooksId: actionQuickBooksCustomerId.quickBooksId,
            };
          } else return c;
        }),
      };

    case createSpecificActionTypeName(
      formTypes.customer,
      formActionTypes.completeSaving
    ):
      const updatedCustomers = getCustomersUpdatedBySave(
        state.customers,
        action
      );
      return {
        ...state,
        customers: updatedCustomers,
      };

    case createSpecificActionTypeName(
      formTypes.customerContractBilling,
      formActionTypes.completeSaving
    ):
      return {
        ...state,
        customers: getCustomersUpdatedByPaymentConfigurationSave(
          state.customers,
          action
        ),
      };

    case actionTypes.CUSTOMER_PAYMENT_METHOD_CLEAR:
      const customerPaymentMethodClear = action as ICustomerPaymentMethodClear;

      return {
        ...state,
        customers: state.customers.map((c) => {
          if (c.id === customerPaymentMethodClear.customerId) {
            return {
              ...c,
              paymentMethod: null,
            };
          }

          return c;
        }),
      };

    case actionTypes.CUSTOMER_PAYMENT_CONFIGURATION_CLEAR:
      const customerPaymentConfigurationDeleteCompletedAction =
        action as ICustomerPaymentConfigurationClear;
      return {
        ...state,
        customers: state.customers.map((c) => {
          if (
            c.id ===
            customerPaymentConfigurationDeleteCompletedAction.customerId
          ) {
            return {
              ...c,
              paymentConfiguration: null,
            };
          }

          return c;
        }),
      };

    case createSpecificActionTypeName(
      formTypes.customerAdditionalLocation,
      formActionTypes.completeSaving
    ):
      const updatedCustomerAdditionalLocations =
        getCustomerAdditionalLocationsUpdatedBySave(
          state.customerAdditionalLocations,
          action
        );
      return {
        ...state,
        customerAdditionalLocations: updatedCustomerAdditionalLocations,
      };

    case actionTypes.CUSTOMER_DELETE_COMPLETE:
      const customerDeleteAction = action as ICustomerDeleteComplete;
      return {
        ...state,
        customers: state.customers.filter(
          (c) => c.id !== customerDeleteAction.customerId
        ),
      };

    case actionTypes.CUSTOMER_ADDITIONAL_LOCATION_DELETE_COMPLETE:
      const customerAdditionalLocationDeleteAction =
        action as ICustomerAdditionalLocationDeleteComplete;
      return {
        ...state,
        customerAdditionalLocations: state.customerAdditionalLocations.filter(
          (c) =>
            c.id !==
            customerAdditionalLocationDeleteAction.customerAdditionalLocationId
        ),
      };

    case createSpecificActionTypeName(
      formTypes.invoice,
      formActionTypes.completeSaving
    ):
      return {
        ...state,
        customers: state.customers
          // First set new email address or phone number if saved
          .map(
            updateCustomerContactMethods({
              customerId: action.payload?.customerId,
              customerEmailAddresses: action.payload?.customerEmailAddresses,
              customerPhoneNumber: action.payload?.customerPhoneNumber,
              customerPhoneNumberOptedIntoSms:
                action.payload?.customerPhoneNumberOptedIntoSms,
            })
          )
          // Next populate quickbooks ID after with data from save result
          .map((c) => {
            const update = action.payload.customerUpdates.find(
              (u: any) => u.customerId === c.id
            );
            if (update) {
              return {
                ...c,
                quickBooksId: update.quickBooksId,
              };
            } else {
              return c;
            }
          }),
      };

    case proposalsActionCreators.addProposal.type:
      const addProposalAction = action.payload as ISaveProposalAction;
      return {
        ...state,
        customers: state.customers.map(
          updateCustomerContactMethods({
            customerId: addProposalAction.customerId,
            customerEmailAddresses: addProposalAction.customerEmailAddresses,
            customerPhoneNumber: addProposalAction.customerPhoneNumber,
            customerPhoneNumberOptedIntoSms:
              action.payload?.customerPhoneNumberOptedIntoSms,
          })
        ),
      };

    case actionTypes.MANAGE_CUSTOMERS_SEARCH_START:
      return {
        ...state,
        manageCustomersSearch: {
          ...state.manageCustomersSearch,
          loading: true,
          error: false,
        },
      };

    case actionTypes.MANAGE_CUSTOMERS_SEARCH_COMPLETE:
      const manageCustomersSearchCompleteAction =
        action as IManageCustomersSearchComplete;
      return {
        ...state,
        manageCustomersSearch: {
          ...state.manageCustomersSearch,
          matchingCustomers: manageCustomersSearchCompleteAction.append
            ? [
                ...state.manageCustomersSearch.matchingCustomers,
                ...manageCustomersSearchCompleteAction.matchingCustomers,
              ]
            : manageCustomersSearchCompleteAction.matchingCustomers,
          additionalCustomers:
            manageCustomersSearchCompleteAction.additionalCustomers,
          searchText: manageCustomersSearchCompleteAction.searchText,
          loading: false,
          error: false,
        },
      };

    case actionTypes.MANAGE_CUSTOMERS_SEARCH_ERROR:
      return {
        ...state,
        manageCustomersSearch: {
          ...state.manageCustomersSearch,
          loading: false,
          error: true,
        },
      };

    // Clear search properties when changing pages
    case actionTypes.ROUTER_LOCATION_CHANGE:
      return {
        ...state,
        manageCustomersSearch: {
          ...state.manageCustomersSearch,
          loading: false,
          error: false,
          matchingCustomers: [],
          additionalCustomers: 0,
          searchText: "",
          resetCount: state.manageCustomersSearch.resetCount + 1,
        },
      };

    case createSpecificActionTypeName(
      formTypes.customerPaymentMethod,
      formActionTypes.completeSaving
    ):
      return {
        ...state,
        customers: state.customers
          // First set new email address if saved
          .map((c) => {
            let newCustomer: ICustomer;
            if (
              action.parameters?.customerId &&
              c.id === action.parameters.customerId
            ) {
              newCustomer = {
                ...c,
                paymentMethod: {
                  ...(c.paymentMethod ?? {}),
                  type: action.payload.type,
                  isTokenSet: isStringSet(action.payload.token),
                  partialNumber: action.payload.partialNumber,
                },
              };
            } else {
              newCustomer = c;
            }
            return newCustomer;
          }),
      };

    case actionTypes.INVOICE_CONFIGURATION_UPDATED:
      const invoiceConfigurationUpdatedAction =
        action as IInvoiceConfigurationUpdated;

      if (
        !invoiceConfigurationUpdatedAction.invoiceConfiguration
          .updateContractBilling
      ) {
        return state;
      } else {
        return {
          ...state,
          customers: state.customers.map((c) => {
            if (c.paymentConfiguration) {
              return {
                ...c,
                paymentConfiguration: {
                  ...c.paymentConfiguration,
                  numberOfDaysUntilDue:
                    invoiceConfigurationUpdatedAction.invoiceConfiguration
                      .invoiceDefaultNumberOfDaysValid,
                },
              };
            } else {
              return c;
            }
          }),
        };
      }

    default:
      return state;
  }
};

function updateCustomerContactMethods({
  customerId,
  customerEmailAddresses,
  customerPhoneNumber,
  customerPhoneNumberOptedIntoSms,
}: {
  customerId: string | undefined;
  customerEmailAddresses: Array<string> | null | undefined;
  customerPhoneNumber: string | null | undefined;
  customerPhoneNumberOptedIntoSms: boolean | null;
}): (value: ICustomer, index: number, array: ICustomer[]) => ICustomer {
  return (c) => {
    let newCustomer: ICustomer;
    if (customerId && c.id === customerId) {
      newCustomer = {
        ...c,
        emailAddresses:
          !!customerEmailAddresses &&
          customerEmailAddresses.length > 0 &&
          !(c.emailAddresses && c.emailAddresses.some((e) => isStringSet(e)))
            ? customerEmailAddresses
            : c.emailAddresses,
        phoneNumber:
          customerPhoneNumber && !isStringSet(c.phoneNumber)
            ? customerPhoneNumber
            : c.phoneNumber,
        phoneNumberOptedIntoSms:
          typeof customerPhoneNumberOptedIntoSms === "boolean" &&
          customerPhoneNumber &&
          !isStringSet(c.phoneNumber)
            ? customerPhoneNumberOptedIntoSms
            : c.phoneNumberOptedIntoSms,
      };
    } else {
      newCustomer = c;
    }
    return newCustomer;
  };
}

function getCustomersUpdatedBySave(customers: Array<ICustomer>, action: any) {
  if (action.payload.parameters && action.payload.parameters.customerId) {
    return customers.map((c) => {
      let newCustomer: ICustomer;
      if (c.id === action.payload.parameters.customerId) {
        newCustomer = {
          ...c,
          ...action.payload,
        };
      } else {
        newCustomer = c;
      }
      return newCustomer;
    });
  } else {
    return [...customers, action.payload];
  }
}

function getCustomersUpdatedByPaymentConfigurationSave(
  customers: Array<ICustomer>,
  action: any
) {
  if (action.parameters?.customerId) {
    return customers.map((c) => {
      let newCustomer: ICustomer;
      if (c.id === action.parameters.customerId) {
        newCustomer = {
          ...c,
          emailAddresses:
            action.payload?.customerEmailAddresses ?? c.emailAddresses,
          paymentMethod: {
            ...(c.paymentMethod ?? {}),
            ...action.payload,
          },
          paymentConfiguration: {
            ...(c.paymentConfiguration ?? {}),
            ...action.payload,
          },
        };
      } else {
        newCustomer = c;
      }
      return newCustomer;
    });
  } else {
    return [...customers, action.payload];
  }
}

function getCustomerAdditionalLocationsUpdatedBySave(
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>,
  action: any
) {
  if (
    action.payload.parameters &&
    action.payload.parameters.customerAdditionalLocationId
  ) {
    return customerAdditionalLocations.map((c) => {
      let newCustomerAdditionalLocation: ICustomerAdditionalLocation;
      if (c.id === action.payload.parameters.customerAdditionalLocationId) {
        newCustomerAdditionalLocation = {
          ...c,
          ...action.payload,
        };
      } else {
        newCustomerAdditionalLocation = c;
      }
      return newCustomerAdditionalLocation;
    });
  } else {
    return [...customerAdditionalLocations, action.payload];
  }
}

function getCustomersToAdd(
  customers: Array<ICustomer>,
  state: ICustomerState
): Array<ICustomer> {
  return [
    ...state.customers.filter(
      (customer) => !isCustomerBeingAdded(customer.id, customers)
    ),
    ...removeDuplicates(customers),
  ];
}

function getCustomerAdditionalLocationsToAdd(
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>,
  state: ICustomerState
): Array<ICustomerAdditionalLocation> {
  return [
    ...state.customerAdditionalLocations.filter(
      (customerAdditionalLocation) =>
        !isCustomerBeingAdded(
          customerAdditionalLocation.id,
          customerAdditionalLocations
        )
    ),
    ...removeDuplicates(customerAdditionalLocations),
  ];
}

function isCustomerBeingAdded<T extends { id: string }>(
  id: string,
  items: Array<T>
) {
  return items.some((c) => c.id === id);
}

function removeDuplicates<T extends { id: string }>(items: Array<T>): Array<T> {
  return items.reduce((acc, customer) => {
    if (!acc.find((c) => c.id === customer.id)) {
      return [...acc, customer];
    } else {
      return acc;
    }
  }, [] as Array<T>);
}
