import React, { useState, useEffect } from "react";
import dateService from "../../../../services/dateService";
import remoteDataProvider from "../../../../services/remoteDataProvider";
import { timeout, finalize } from "rxjs/operators";
import {
  GetUserSettingsType,
  useUserSettings,
} from "../../../../services/userSettingsService";
import { UserSettingsType } from "../../../../enums/userSettingsType";
import { SortDirection } from "../../../../enums/sortDirection";
import SortColumn from "../../components/SortColumn";
import { getSortedItemsV2 } from "../../../../services/sortingService";
import { DateFilterOptions } from "../../../../enums/dateFilterOptions";
import DateFilter from "../../components/DateFilter";
import { IPayrollTimeRange } from "../../../../models/IPayrollTimeRange";
import { format } from "date-fns";
import CrewMemberPayrollHistoryButtons from "./CrewMemberPayrollHistoryButtons";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../../modules/actionCreators";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { getErrorMessageFromError } from "../../../../services/httpErrorHandler";
import Spinner from "../../components/Spinner";
import Prompt from "../../components/Prompt";
import { ICrew } from "../../../../models/ICrew";
import ServerLoadedContent from "../../components/ServerLoadedContent";
import ResponsiveTable from "../../../../libraries/tableLayout/ResponsiveTable";
import { ResponsiveTableMobileCard } from "../../../../libraries/tableLayout/ResponsiveTableMobileCard";
import { TableColumns } from "../../../../libraries/tableLayout/TableColumn";

interface IProps {
  crewMemberId: string;
  onErrorClear: () => void;
  onError: (errorMessage: string) => void;
}

interface IFilters {
  frequency: DateFilterOptions;
  startingDate: string | null;
  endingDate: string | null;
}

enum SortColumns {
  VisitDate,
  ClockIn,
  ClockOut,
  TotalHours,
  Crew,
}

const CrewMemberPayrollHistory: React.FunctionComponent<IProps> = ({
  crewMemberId,
  onErrorClear,
  onError,
}) => {
  const [loadingData, setLoadingData] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deletePromptTimeRangeId, setDeletePromptTimeRangeId] = useState<
    string | null
  >(null);
  const [errorLoadingData, setErrorLoadingData] = useState(false);
  const payrollTimeRangeSaveCount = useApplicationStateSelector(
    (s) => s.forms.payrollTimeRange.saveCount
  );
  const { getUserSettings } = useUserSettings();
  const [filters, setFilters] = useState<IFilters>(
    getDefaultFilters(getUserSettings)
  );
  const dispatch = useDispatch();

  const [timeRanges, setTimeRanges] = useState<Array<IPayrollTimeRange> | null>(
    null
  );

  const crews = useApplicationStateSelector((s) => s.crew.crews);

  const [currentSortColumn, setCurrentSortColumn] =
    useState<SortColumns | null>(null);
  const [currentSortDirection, setCurrentSortDirection] =
    useState<SortDirection>(SortDirection.Ascending);

  const { setUserSettings } = useUserSettings();

  useEffect(() => {
    const subscription = loadData(
      filters,
      crewMemberId,
      setLoadingData,
      setErrorLoadingData,
      setTimeRanges
    );

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [
    crewMemberId,
    setTimeRanges,
    setLoadingData,
    setErrorLoadingData,
    filters,
    payrollTimeRangeSaveCount,
  ]);

  const getSortColumn = (
    displayName: string,
    sortColumn: SortColumns,
    testId: string
  ) => (
    <SortColumn<SortColumns>
      displayName={displayName}
      columnSortProperty={sortColumn}
      currentSortProperty={currentSortColumn}
      currentSortDirection={currentSortDirection}
      testId={testId}
      onSortDirectionChange={(newSortColumn, newSortDirection) => {
        setCurrentSortColumn(newSortColumn);
        setCurrentSortDirection(newSortDirection);
      }}
    />
  );

  const columns: TableColumns<IPayrollTimeRange> = [
    {
      key: "crew",
      header: ({ displayType }) =>
        displayType === "desktop"
          ? getSortColumn("Crew", SortColumns.Crew, "crewSort")
          : "Crew",
      cell: ({ row: timeRange }) => {
        return <span>{getCrewName(crews, timeRange)}</span>;
      },
      testId: "crew",
    },
    {
      key: "clockIn",
      header: ({ displayType }) =>
        displayType === "desktop"
          ? getSortColumn("Clock in", SortColumns.ClockIn, "clockInSort")
          : "Clock in",
      cell: ({ row: timeRange }) =>
        `${dateService.formatDateForDisplay(
          timeRange.start
        )} ${dateService.formatDateTimeForTimeDisplay(timeRange.start)}`,
      style: { whiteSpace: "nowrap" },
      testId: "clockIn",
    },
    {
      key: "clockOut",
      header: ({ displayType }) =>
        displayType === "desktop"
          ? getSortColumn("Clock out", SortColumns.ClockOut, "clockOutSort")
          : "Clock out",
      cell: ({ row: timeRange }) =>
        timeRange.end
          ? `${dateService.formatDateForDisplay(
              timeRange.end
            )} ${dateService.formatDateTimeForTimeDisplay(timeRange.end)}`
          : "",
      style: { whiteSpace: "nowrap" },
      testId: "clockOut",
    },
    {
      key: "totalHours",
      header: ({ displayType }) =>
        displayType === "desktop"
          ? getSortColumn(
              "Total hours",
              SortColumns.TotalHours,
              "totalHoursSort"
            )
          : "Total hours",
      cell: ({ row: timeRange }) =>
        timeRange.totalHours
          ? Math.round(timeRange.totalHours * 100) / 100
          : "",
      style: { whiteSpace: "nowrap" },
      testId: "totalHours",
    },
    {
      key: "actions",
      isButtonCell: true,
      header: "",
      cell: ({ row: timeRange }) => (
        <CrewMemberPayrollHistoryButtons
          timeRangeId={timeRange.id}
          onDeleteClick={() => setDeletePromptTimeRangeId(timeRange.id)}
        />
      ),
    },
  ];

  return (
    <>
      {deleting && <Spinner />}

      <ServerLoadedContent
        header={null}
        dataLength={timeRanges?.length ?? 0}
        hasInitialLoadCompleted={timeRanges !== null}
        dataType="time ranges"
        errorLoading={errorLoadingData}
        loadingData={loadingData}
        showContentWhileRefreshing={false}
        refreshData={() =>
          loadData(
            filters,
            crewMemberId,
            setLoadingData,
            setErrorLoadingData,
            setTimeRanges
          )
        }
        filter={
          <div
            className="d-flex flex-wrap align-items-end"
            style={{ justifyContent: "space-between", gap: "10px" }}
          >
            <div>
              <DateFilter
                filters={filters}
                onFiltersChanged={(newFilters) => {
                  if (newFilters.frequency !== DateFilterOptions.custom) {
                    const { startingDate, endingDate } =
                      dateService.getDatesFromDateFilterOptions(
                        newFilters.frequency
                      );
                    newFilters = {
                      ...newFilters,
                      startingDate,
                      endingDate,
                    };
                  }

                  setFilters({
                    ...filters,
                    ...newFilters,
                  });
                  setUserSettings(
                    UserSettingsType.crewMemberPayrollHistoryFilters,
                    newFilters
                  );
                }}
              />
            </div>
            <div>
              <button
                className="btn btn-secondary text-nowrap"
                onClick={() =>
                  dispatch(
                    actionCreators.forms.payrollTimeRange.showForm({
                      crewMemberId,
                    })
                  )
                }
              >
                Add Time
              </button>
            </div>
          </div>
        }
      >
        {timeRanges ? (
          <ResponsiveTable<IPayrollTimeRange>
            tableClass="table-striped"
            rows={
              timeRanges !== null
                ? getSortedItems(
                    timeRanges,
                    currentSortColumn,
                    currentSortDirection,
                    crews
                  )
                : timeRanges
            }
            columns={columns}
            renderMobile={({ row, index }) => {
              return (
                <>
                  <ResponsiveTableMobileCard
                    row={row}
                    columns={columns}
                    rowIndex={index}
                  />
                </>
              );
            }}
          />
        ) : null}
      </ServerLoadedContent>

      {deletePromptTimeRangeId !== null ? (
        <Prompt
          showPrompt={true}
          promptMessage="Are you sure you want to delete this time?"
          onConfirm={() => {
            setDeletePromptTimeRangeId(null);

            setDeleting(true);

            onErrorClear();

            remoteDataProvider
              .deletePayrollTimeRange(deletePromptTimeRangeId)
              .pipe(finalize(() => setDeleting(false)))
              .subscribe(
                () => {
                  loadData(
                    filters,
                    crewMemberId,
                    setLoadingData,
                    setErrorLoadingData,
                    setTimeRanges
                  );
                },
                (err) =>
                  onError(
                    getErrorMessageFromError(
                      err,
                      "An unknown error occurred while deleting the time."
                    )
                  )
              );
          }}
          onCancel={() => setDeletePromptTimeRangeId(null)}
        />
      ) : null}
    </>
  );
};

export default CrewMemberPayrollHistory;

function loadData(
  filters: IFilters,
  crewMemberId: string,
  setLoadingData: (v: boolean) => void,
  setErrorLoadingData: (v: boolean) => void,
  setTimeRanges: (v: Array<IPayrollTimeRange>) => void
) {
  setLoadingData(true);

  return remoteDataProvider
    .getCrewMemberTimeRanges(
      crewMemberId,
      filters.startingDate ?? null,
      filters.endingDate ?? null
    )
    .pipe(timeout(30000))
    .subscribe(
      (result) => {
        setLoadingData(false);
        setTimeRanges(result);
        setErrorLoadingData(false);
      },
      () => {
        setLoadingData(false);
        setErrorLoadingData(true);
      }
    );
}

function getDefaultFilters(getUserSettings: GetUserSettingsType): IFilters {
  const savedSettings = getUserSettings<IFilters>(
    UserSettingsType.crewMemberPayrollHistoryFilters
  );
  if (savedSettings) {
    if (savedSettings.frequency !== DateFilterOptions.custom) {
      const dates = dateService.getDatesFromDateFilterOptions(
        savedSettings.frequency
      );
      return {
        ...savedSettings,
        startingDate: dates.startingDate,
        endingDate: dates.endingDate,
      };
    }
    return savedSettings;
  }

  return {
    frequency: DateFilterOptions.last30Days,
    startingDate: dateService.getDatesFromDateFilterOptions(
      DateFilterOptions.last30Days
    ).startingDate,
    endingDate: dateService.getDatesFromDateFilterOptions(
      DateFilterOptions.last30Days
    ).endingDate,
  };
}

function getSortedItems(
  jobInstances: Array<IPayrollTimeRange>,
  sortColumn: SortColumns | null,
  sortDirection: SortDirection,
  crews: Array<ICrew>
) {
  if (sortColumn === null) {
    return jobInstances;
  }

  let sortProperties: Array<
    keyof IPayrollTimeRange | ((i: IPayrollTimeRange) => string | number)
  >;
  switch (sortColumn) {
    case SortColumns.VisitDate:
      sortProperties = ["start"];
      break;
    case SortColumns.Crew:
      sortProperties = [(tr) => getCrewName(crews, tr)];
      break;
    case SortColumns.ClockIn:
      sortProperties = ["start", (tr) => format(tr.start, "HH:mm")];
      break;
    case SortColumns.ClockOut:
      sortProperties = [
        "start",
        (tr) => (tr.end ? format(tr.end, "HH:mm") : ""),
      ];
      break;
    case SortColumns.TotalHours:
      sortProperties = ["totalHours"];
      break;
  }

  return getSortedItemsV2(
    jobInstances,
    sortProperties,
    sortDirection === SortDirection.Descending
  );
}

function getCrewName(crews: Array<ICrew>, timeRange: IPayrollTimeRange) {
  if (timeRange.crewId) {
    const crew = crews.find((c) => c.id === timeRange.crewId);
    return crew?.name ?? "";
  }

  return "";
}
