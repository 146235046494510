export function parseName(input: string) {
  const nameParts = input.trim().split(/\s/);

  let firstName = "";
  let lastName = "";
  if (nameParts.length === 1) {
    firstName = nameParts[0];
  } else if (nameParts.length > 1) {
    const [, ...lastNameParts] = nameParts;
    firstName = nameParts[0];
    lastName = lastNameParts.join(" ");
  }

  return {
    firstName,
    lastName,
  };
}
