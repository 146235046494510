import {
  Control,
  Controller,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { useUserSettings } from "../../../../services/userSettingsService";
import { UserSettingsType } from "../../../../enums/userSettingsType";
import { formatCurrency } from "../../../../services/currencyFormatter";
import { getTotal } from "../../../../services/lineItemService";
import { useCreditCardsEnabled } from "../../../../hooks/useCreditCardsEnabled";
import { IFormData } from "./IFormData";
import { IInvoiceItem } from "../../../../models/IInvoiceItem";
import QuickBooksCustomerSelection from "../../../../containers/app/components/QuickBooksCustomerSelection";
import { IQuickBooksCustomer } from "../../../../models/IQuickBooksCustomer";
import DepositRequiredFields from "./DepositRequiredFields";
import { fullStoryTrack } from "../../../../services/fullStoryService";
import { ICustomer } from "../../../../models/ICustomer";
import { DepositInvoiceItem } from "../../../../containers/app/components/DepositInvoiceItem";
import { DepositType } from "../../../../enums/DepositType";
import { IDepositSettings } from "../../../../models/IDepositSettings";
import { isDepositRequired } from "../../services/proposalService";

export default function DepositFields({
  register,
  setValue,
  control,
  watch,
  invoiceItems,
  setInvoiceItems,
  quickBooksCustomers,
  setQuickBooksCustomers,
  customer,
}: {
  register: UseFormRegister<IFormData>;
  setValue: UseFormSetValue<IFormData>;
  watch: UseFormWatch<IFormData>;
  control: Control<IFormData, object>;
  invoiceItems: Array<IInvoiceItem>;
  setInvoiceItems: React.Dispatch<React.SetStateAction<Array<IInvoiceItem>>>;
  quickBooksCustomers: Array<IQuickBooksCustomer>;
  setQuickBooksCustomers: React.Dispatch<
    React.SetStateAction<Array<IQuickBooksCustomer>>
  >;
  customer: ICustomer | null;
}) {
  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );
  const { areCreditCardsEnabled } = useCreditCardsEnabled();
  const paymentsConvenienceFeePercentForDisplay = useApplicationStateSelector(
    (s) => s.common.paymentsConvenienceFeePercentForDisplay
  );
  const { setUserSettings, getUserSettings } = useUserSettings();

  const lineItems = watch("lineItems");
  const amountAdjustments = watch("amountAdjustments");
  const depositRequired = watch("depositRequired");
  const depositSettings = watch("depositSettings");

  return (
    <>
      {areCreditCardsEnabled ? (
        <div>
          <DepositRequiredFields
            idPrefix="Proposal"
            depositSettings={depositSettings}
            setDepositSettings={(newValue: IDepositSettings | null) => {
              setValue("depositSettings", newValue);
              if (isDepositRequired(newValue)) {
                setValue("depositRequired", true);
                fullStoryTrack("Deposit Clicked");
                setValue(
                  "addConvenienceFee",
                  getUserSettings<boolean>(
                    UserSettingsType.paymentsConvenienceFeePercentEnabled
                  ) ?? true
                );
              } else {
                setValue("depositRequired", false);
                setValue("addConvenienceFee", false);
              }
            }}
          />
          {depositRequired ? (
            <>
              <div className="form-row align-items-baseline justify-content-end">
                <div className="order-xs-2 order-md-1 col-12 col-md-6 col-lg-4 col-xl-3">
                  <Controller
                    name="depositItem"
                    control={control}
                    render={({ field }) => (
                      <DepositInvoiceItem
                        invoiceItems={invoiceItems}
                        setInvoiceItems={setInvoiceItems}
                        value={field.value}
                        onChange={(newValue) => field.onChange(newValue)}
                        inputElementId="proposalDepositItems"
                      />
                    )}
                  />
                </div>
                <div
                  className="form-group text-right order-xs-1 order-md-2 col-12 col-md-3 col-lg-3"
                  data-testid="depositAmount"
                >
                  <strong>
                    {formatCurrency(
                      depositSettings?.type === DepositType.percent
                        ? getTotal({
                            // Intentionally exclude taxes from deposit amount
                            taxRate: null,
                            lineItems,
                            discount: amountAdjustments.discount,
                          }) * (depositSettings?.percent ?? 0)
                        : depositSettings?.type === DepositType.amount
                        ? depositSettings?.amount ?? 0
                        : 0
                    )}{" "}
                    deposit
                  </strong>
                </div>
              </div>
              {isQuickBooksEnabled ? (
                <div className="form-group form-row align-items-baseline justify-content-end">
                  <div className="col-12 col-md-9 col-lg-3 col-xl-3">
                    <label htmlFor="customer" className="required">
                      QuickBooks Customer
                    </label>
                  </div>
                  <div className="col-12 col-md-9 col-lg-4 col-xl-3">
                    <Controller
                      name={`quickBooksCustomerId`}
                      control={control}
                      render={({ field }) => (
                        <QuickBooksCustomerSelection
                          inputId="customer"
                          customer={customer}
                          options={quickBooksCustomers}
                          quickBooksCustomerId={field.value ?? ""}
                          onChange={(id) => {
                            field.onChange(id);
                          }}
                          onCreatedQuickBooksCustomer={({
                            id,
                            displayName,
                          }) => {
                            setQuickBooksCustomers([
                              ...quickBooksCustomers,
                              {
                                id,
                                displayName,
                              } as IQuickBooksCustomer,
                            ]);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              ) : null}
              <div className="form-group form-row align-items-baseline justify-content-end">
                <div className="col-12 col-md-9 col-lg-7 col-xl-6">
                  <div className="custom-control custom-checkbox">
                    <input
                      id="addConvenienceFee"
                      type="checkbox"
                      className="custom-control-input"
                      {...register("addConvenienceFee", {
                        onChange: (e) =>
                          setUserSettings(
                            UserSettingsType.paymentsConvenienceFeePercentEnabled,
                            e.target.checked
                          ),
                      })}
                    />
                    <label
                      htmlFor="addConvenienceFee"
                      className="custom-control-label"
                    >
                      Add {paymentsConvenienceFeePercentForDisplay}% convenience
                      fee for credit card use
                    </label>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
      ) : null}
    </>
  );
}
