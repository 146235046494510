import React, { useState, useEffect, useRef } from "react";
import { MaintenanceJobFrequency } from "../../../models/IMaintenanceJob";
import constants from "../../../constants";
import {
  IJobsForOrdering,
  IJobForOrdering,
} from "../../../models/IJobForOrdering";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { defer, Subscription } from "rxjs";
import { map, timeoutWith, timeout } from "rxjs/operators";
import LinkButton from "./LinkButton";
import { IMaintenanceJobScheduleProperties } from "../../../models/IMaintenanceJobScheduleProperties";
import { isFlexibleJob } from "../../../services/jobService";

interface PrecedingJobIdChanged {
  maintenanceJobId: string;
  jobInstanceId: string | null;
  dayScheduleId: string | null;
  originalModified: boolean;
}

interface IProps {
  maintenanceJobId: string | null;
  precedingJobId: string;
  crewId: string;
  loading: boolean;

  scheduleProperties: IMaintenanceJobScheduleProperties;

  onLoadingChanged: (loading: boolean) => void;
  onPrecedingJobIdChanged: (changes: PrecedingJobIdChanged) => void;
}

function loadJobs(
  onLoadingChangedRef: React.MutableRefObject<(loading: boolean) => void>,
  crewId: string,
  scheduleProperties: IMaintenanceJobScheduleProperties,
  setJobsForDay: (jobsForDay: IJobsForOrdering) => void,
  originalPrecedingJobId: React.MutableRefObject<string | null>,
  onPrecedingJobIdChangedRef: React.MutableRefObject<
    (changes: PrecedingJobIdChanged) => void
  >,
  setErrorLoading: (errorLoading: boolean) => void,
  maintenanceJobId: string | null,
  previousDayScheduleIdForOrderingRef: React.MutableRefObject<string | null>,
  setShowBlankOption: (showBlankOption: boolean) => void
) {
  onLoadingChangedRef.current(true);
  setErrorLoading(false);

  const getData = () =>
    remoteDataProvider.getOrderedJobs({
      crewId,
      maintenanceJobId,
      ...scheduleProperties,
    });

  return getData()
    .pipe(
      timeoutWith(
        5000,
        defer(() => getData().pipe(timeout(10000)))
      ),
      map((orderedJobResponse: IJobsForOrdering) => {
        let jobs: Array<IJobForOrdering> = [
          {
            maintenanceJobId: constants.idForFirstJob,
            jobInstanceId: constants.idForFirstJob,
            wasAutoGenerated: false,
            customerName: "First job of the day",
            order: -1,
          },
        ];

        if (orderedJobResponse.jobs.length > 0) {
          jobs.push({
            maintenanceJobId: constants.idForLastJob,
            jobInstanceId: constants.idForLastJob,
            wasAutoGenerated: false,
            customerName: "Last job of the day",
            order: -1,
          });
        }

        jobs = [...jobs, ...orderedJobResponse.jobs];

        return {
          date: orderedJobResponse.date,
          errorMessage: orderedJobResponse.errorMessage,
          precedingJobInstanceId: orderedJobResponse.precedingJobInstanceId,
          dayScheduleId: orderedJobResponse.dayScheduleId,
          jobs: jobs,
        };
      })
    )
    .subscribe(
      (orderedJobResponse: IJobsForOrdering) => {
        setJobsForDay(orderedJobResponse);

        if (previousDayScheduleIdForOrderingRef.current === null) {
          // Handle initial load
          let precedingMaintenanceJobId = constants.idForFirstJob;
          if (orderedJobResponse.precedingJobInstanceId) {
            const precedingMaintenanceJob = orderedJobResponse.jobs.find(
              (j) =>
                j.jobInstanceId === orderedJobResponse.precedingJobInstanceId
            );
            if (!precedingMaintenanceJob) {
              throw new Error(
                "preceding maintenance job id not found in ordering component"
              );
            }

            if (
              !precedingMaintenanceJob.maintenanceJobId ||
              precedingMaintenanceJob.maintenanceJobId ===
                constants.idForFirstJob
            ) {
              throw new Error(
                "invalid value for preceding maintenance job maintenance job id"
              );
            }
            precedingMaintenanceJobId =
              precedingMaintenanceJob.maintenanceJobId;
          }

          originalPrecedingJobId.current = precedingMaintenanceJobId;

          onPrecedingJobIdChangedRef.current({
            maintenanceJobId: originalPrecedingJobId.current,
            jobInstanceId: orderedJobResponse.precedingJobInstanceId,
            dayScheduleId: null,
            originalModified: false,
          });
        } else if (
          previousDayScheduleIdForOrderingRef.current !==
          orderedJobResponse.dayScheduleId
        ) {
          // Handle the day schedule changing
          if (
            doJobsExist(
              getOtherJobsOnDay(orderedJobResponse.jobs, maintenanceJobId)
            )
          ) {
            // If there are jobs, clear out the current value to force user to reselect the ordering
            onPrecedingJobIdChangedRef.current({
              maintenanceJobId: "",
              jobInstanceId: "",
              dayScheduleId: null,
              originalModified: true,
            });

            setShowBlankOption(true);
          } else {
            // If there aren't any jobs, there isn't a decision to make and can default to first job of the day
            onPrecedingJobIdChangedRef.current({
              maintenanceJobId: constants.idForFirstJob,
              jobInstanceId: constants.idForFirstJob,
              dayScheduleId: null,
              originalModified: true,
            });
          }
        }

        if (orderedJobResponse.dayScheduleId) {
          previousDayScheduleIdForOrderingRef.current =
            orderedJobResponse.dayScheduleId;
        } else {
          // Need to store as an empty string rather than null since
          // null exists to indicate the initial load.
          previousDayScheduleIdForOrderingRef.current = "";
        }

        onLoadingChangedRef.current(false);
      },
      () => {
        setErrorLoading(true);
        onLoadingChangedRef.current(false);
      }
    );
}

export const MaintenanceJobOrdering: React.FunctionComponent<IProps> = ({
  maintenanceJobId,
  scheduleProperties,
  precedingJobId,
  crewId,
  loading,
  onLoadingChanged,
  onPrecedingJobIdChanged,
}) => {
  const [jobsForDay, setJobsForDay] = useState<IJobsForOrdering>({
    date: null,
    dayScheduleId: null,
    errorMessage: null,
    jobs: [],
    precedingJobInstanceId: null,
  });
  const [errorLoading, setErrorLoading] = useState(false);

  const previousDayScheduleIdForOrderingRef = useRef<string | null>(null);

  const precedingJobIdRef = useRef(precedingJobId);
  useEffect(() => {
    precedingJobIdRef.current = precedingJobId;
  }, [precedingJobId]);

  const onLoadingChangedRef = useRef(onLoadingChanged);
  useEffect(() => {
    onLoadingChangedRef.current = onLoadingChanged;
  }, [onLoadingChanged]);

  const onPrecedingJobIdChangedRef = useRef(onPrecedingJobIdChanged);
  useEffect(() => {
    onPrecedingJobIdChangedRef.current = onPrecedingJobIdChanged;
  }, [onPrecedingJobIdChanged]);

  const originalPrecedingJobId = useRef<string | null>(null);

  const [showBlankOption, setShowBlankOption] = useState(false);

  useEffect(() => {
    let subscription: Subscription | null = null;
    if (crewId && !isFlexibleJob(scheduleProperties)) {
      subscription = loadJobs(
        onLoadingChangedRef,
        crewId,
        {
          customFrequencyPeriod: scheduleProperties.customFrequencyPeriod,
          customFrequencyValue: scheduleProperties.customFrequencyValue,
          daysOfWeek: scheduleProperties.daysOfWeek,
          frequency: scheduleProperties.frequency,
          monthlyWeek: scheduleProperties.monthlyWeek,
          seasonalMonthlyWeek: scheduleProperties.seasonalMonthlyWeek,
          seasonalScheduleCustomFrequencyPeriod:
            scheduleProperties.seasonalScheduleCustomFrequencyPeriod,
          seasonalScheduleCustomFrequencyValue:
            scheduleProperties.seasonalScheduleCustomFrequencyValue,
          seasonalScheduleDaysOfWeek:
            scheduleProperties.seasonalScheduleDaysOfWeek,
          seasonalScheduleStart:
            scheduleProperties.seasonalScheduleStart?.dayOfMonth !==
              undefined &&
            scheduleProperties.seasonalScheduleStart?.month !== undefined
              ? {
                  dayOfMonth:
                    scheduleProperties.seasonalScheduleStart.dayOfMonth,
                  month: scheduleProperties.seasonalScheduleStart.month,
                }
              : null,
          seasonalScheduleEnd:
            scheduleProperties.seasonalScheduleEnd?.dayOfMonth !== undefined &&
            scheduleProperties.seasonalScheduleEnd?.month !== undefined
              ? {
                  dayOfMonth: scheduleProperties.seasonalScheduleEnd.dayOfMonth,
                  month: scheduleProperties.seasonalScheduleEnd.month,
                }
              : null,
          seasonalScheduleEstimatedManHours:
            scheduleProperties.seasonalScheduleEstimatedManHours,
          seasonalScheduleFrequency:
            scheduleProperties.seasonalScheduleFrequency,
          startingDate: scheduleProperties.startingDate,
          endingDate: scheduleProperties.endingDate,
        },
        setJobsForDay,
        originalPrecedingJobId,
        onPrecedingJobIdChangedRef,
        setErrorLoading,
        maintenanceJobId,
        previousDayScheduleIdForOrderingRef,
        setShowBlankOption
      );
    }

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
        onLoadingChangedRef.current(false);
      }
    };
    // Disabled since having to use JSON.stringify for arrays
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    crewId,
    maintenanceJobId,
    scheduleProperties.customFrequencyPeriod,
    scheduleProperties.customFrequencyValue,
    // Same array with different values won't be matched
    // Using JSON.stringify will ensure data isn't reloaded if array contents
    // haven't changed.
    // See https://github.com/facebook/react/issues/14476#issuecomment-471199055
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(scheduleProperties.daysOfWeek),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(scheduleProperties.seasonalScheduleDaysOfWeek),
    scheduleProperties.frequency,
    scheduleProperties.monthlyWeek,
    scheduleProperties.seasonalMonthlyWeek,
    scheduleProperties.seasonalScheduleCustomFrequencyPeriod,
    scheduleProperties.seasonalScheduleCustomFrequencyValue,
    scheduleProperties.seasonalScheduleStart?.dayOfMonth,
    scheduleProperties.seasonalScheduleStart?.month,
    scheduleProperties.seasonalScheduleEnd?.dayOfMonth,
    scheduleProperties.seasonalScheduleEnd?.month,
    scheduleProperties.seasonalScheduleEstimatedManHours,
    scheduleProperties.seasonalScheduleFrequency,
    scheduleProperties.startingDate,
    scheduleProperties.endingDate,
  ]);

  const otherJobsOnDay = getOtherJobsOnDay(jobsForDay.jobs, maintenanceJobId);

  let disabled = false;
  let disabledMessage = "";
  if (loading || errorLoading) {
    disabled = true;
    disabledMessage = "Loading jobs...";
  } else if (isFlexibleJob(scheduleProperties)) {
    disabled = true;
    disabledMessage = "Not applicable for flexible jobs";
  } else if (!crewId) {
    disabled = true;
    disabledMessage = "Select a crew";
  } else if (
    scheduleProperties.daysOfWeek.length === 0 &&
    scheduleProperties.frequency !== MaintenanceJobFrequency.Custom &&
    scheduleProperties.seasonalScheduleFrequency !==
      MaintenanceJobFrequency.Custom
  ) {
    disabled = true;
    disabledMessage = "Select a day of the week";
  } else if (!doJobsExist(otherJobsOnDay)) {
    disabled = true;
    disabledMessage = "No jobs exist on the day";
  }

  return (
    <React.Fragment>
      <select
        required
        data-testid="precedingJobId"
        id="precedingJobId"
        name="precedingJobId"
        className="form-control"
        value={precedingJobId}
        onChange={(e) => {
          const newJobInstanceId = e.currentTarget.value;

          if (!newJobInstanceId) {
            onPrecedingJobIdChangedRef.current({
              maintenanceJobId: "",
              jobInstanceId: "",
              dayScheduleId: null,
              originalModified: true,
            });

            return;
          }

          setShowBlankOption(false);

          let jobInstanceId: string;
          let maintenanceJobId: string;
          if (newJobInstanceId === constants.idForFirstJob) {
            maintenanceJobId = constants.idForFirstJob;
            jobInstanceId = constants.idForFirstJob;
          } else if (newJobInstanceId === constants.idForLastJob) {
            maintenanceJobId = constants.idForLastJob;
            jobInstanceId = constants.idForLastJob;
          } else {
            maintenanceJobId =
              getMaintenanceJobIdFromSelection(
                jobsForDay,
                newJobInstanceId,
                crewId
              ) ?? constants.idForFirstJob;

            const newJob =
              jobsForDay.jobs.find(
                (j) => j.jobInstanceId === newJobInstanceId
              ) ?? null;

            if (!newJob) {
              console.error(
                `could not find job for ${newJobInstanceId} in maintenancejobordering`
              );
            }

            jobInstanceId = newJob?.jobInstanceId ?? constants.idForFirstJob;
          }

          onPrecedingJobIdChanged({
            dayScheduleId: jobsForDay.dayScheduleId,
            jobInstanceId: jobInstanceId,
            maintenanceJobId: maintenanceJobId,
            originalModified:
              newJobInstanceId !== originalPrecedingJobId.current,
          });
        }}
        disabled={disabled}
      >
        {disabled ? (
          <option key="disabledMessage">{disabledMessage}</option>
        ) : (
          <>
            {showBlankOption ? (
              <option disabled value="" key="blankOption"></option>
            ) : null}
            {otherJobsOnDay.map((job) => {
              return (
                <option value={job.jobInstanceId} key={job.jobInstanceId}>
                  {job.maintenanceJobId !== constants.idForFirstJob &&
                  job.maintenanceJobId !== constants.idForLastJob
                    ? "After "
                    : ""}
                  {job.customerName}
                </option>
              );
            })}
          </>
        )}
      </select>
      {errorLoading ? (
        <div className="text-danger">
          Unable to load jobs for ordering.{" "}
          <LinkButton
            hrefForDisplay="/reload"
            linkText="Retry"
            onClick={() =>
              loadJobs(
                onLoadingChangedRef,
                crewId,
                scheduleProperties,
                setJobsForDay,
                originalPrecedingJobId,
                onPrecedingJobIdChangedRef,
                setErrorLoading,
                maintenanceJobId,
                previousDayScheduleIdForOrderingRef,
                setShowBlankOption
              )
            }
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

function getOtherJobsOnDay(
  jobsForDay: Array<IJobForOrdering>,
  maintenanceJobId: string | null
) {
  return jobsForDay.filter(
    (job) => job.maintenanceJobId !== maintenanceJobId || !job.maintenanceJobId
  );
}

function doJobsExist(otherJobsOnDay: IJobForOrdering[]) {
  return otherJobsOnDay.some(
    (j) =>
      j.maintenanceJobId !== constants.idForFirstJob &&
      j.maintenanceJobId !== constants.idForLastJob
  );
}

function getMaintenanceJobIdFromSelection(
  jobsForDay: IJobsForOrdering,
  newJobInstanceId: string,
  crewId: string
) {
  const result = jobsForDay.jobs.reduce(
    (acc: IAccumulator, job: IJobForOrdering) => {
      if (!acc.hasPassedSelectedJob) {
        let hasPassedSelectedJob: boolean = acc.hasPassedSelectedJob;
        let maintenanceJobId = acc.maintenanceJobId;

        if (job.jobInstanceId === newJobInstanceId) {
          hasPassedSelectedJob = true;
        }

        if (
          job.maintenanceJobId &&
          job.maintenanceJobId !== constants.idForFirstJob &&
          job.wasAutoGenerated
        ) {
          maintenanceJobId = job.maintenanceJobId;
        }
        return { hasPassedSelectedJob, maintenanceJobId };
      }
      return acc;
    },
    {
      hasPassedSelectedJob: false,
      maintenanceJobId: null,
    } as IAccumulator
  );

  return result.maintenanceJobId;

  interface IAccumulator {
    hasPassedSelectedJob: boolean;
    maintenanceJobId: string | null;
  }
}
