import React, { CSSProperties } from "react";
import { ICustomer } from "../../../../models/ICustomer";
import { useDispatch } from "react-redux";
import LabelledField from "../../components/LabelledField";
import { formatCurrency } from "../../../../services/currencyFormatter";
import dateService from "../../../../services/dateService";
import { actionCreators } from "../../../../modules/actionCreators";
import remoteDataProvider from "../../../../services/remoteDataProvider";
import { getTotal } from "../../../../services/lineItemService";
import CustomerBillingSection from "./CustomerBillingSection";
import { getFrequencyTypeForDisplay } from "../../../../services/contractBillingService";

interface IProps {
  customer: ICustomer;
  visible: boolean;
}

const CustomerContractBilling: React.FunctionComponent<IProps> = ({
  customer,
  visible,
}) => {
  const dispatch = useDispatch();

  const labelledFieldStyle: Partial<CSSProperties> = {
    width: "8rem",
    marginRight: 0,
  };

  return (
    <CustomerBillingSection
      dataType="contract billing"
      deleteFunction={() =>
        remoteDataProvider.deleteCustomerPaymentConfiguration(customer.id)
      }
      header="Contract Billing"
      isDataSet={!!customer.paymentConfiguration}
      onDeleteComplete={() =>
        dispatch(actionCreators.customerPaymentConfigurationClear(customer.id))
      }
      onEdit={() =>
        dispatch(
          actionCreators.forms.customerContractBilling.showForm({
            customerId: customer.id,
          })
        )
      }
      visible={visible}
      fields={
        <div className="row">
          <div className="col-6 col-lg-3">
            <LabelledField
              label="Amount"
              testId="amount"
              value={
                typeof customer.paymentConfiguration === "object" &&
                customer.paymentConfiguration !== null
                  ? formatCurrency(
                      getTotal({
                        taxRate: customer.paymentConfiguration.taxRate,
                        lineItems:
                          customer.paymentConfiguration.lineItems ?? [],
                      })
                    )
                  : "Not set"
              }
              style={labelledFieldStyle}
            />
          </div>
          <div className="col-6 col-lg-3">
            <LabelledField
              label="Frequency"
              testId="frequencyText"
              value={
                customer.paymentConfiguration
                  ? getFrequencyTypeForDisplay(
                      customer.paymentConfiguration.frequencyType
                    )
                  : "Not set"
              }
              style={labelledFieldStyle}
            />
          </div>
          <div className="col-6 col-lg-3 mt-2 mt-lg-0">
            <LabelledField
              label="Start date"
              testId="startingDate"
              value={
                customer.paymentConfiguration?.startingDate
                  ? dateService.formatDateForDisplay(
                      customer.paymentConfiguration?.startingDate
                    )
                  : "Not set"
              }
              style={labelledFieldStyle}
            />
          </div>
          <div className="col-6 col-lg-3 mt-2 mt-lg-0">
            <LabelledField
              label="End date"
              testId="endingDate"
              value={
                customer.paymentConfiguration?.endingDate
                  ? dateService.formatDateForDisplay(
                      customer.paymentConfiguration?.endingDate
                    )
                  : "Not set"
              }
              style={labelledFieldStyle}
            />
          </div>
        </div>
      }
    />
  );
};

export default CustomerContractBilling;
