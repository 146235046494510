import React, { useCallback, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FormContainerWithoutRedux from "../../../containers/app/components/FormContainerWithoutRedux";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import {
  getNullableFloatFromNumberOrString,
  getNumericString,
} from "../../../services/typeConverter";
import CurrencyInput from "../../../containers/app/components/CurrencyInput";
import modelConversion from "../../../services/modelConversion";
import TextareaAutosize from "react-autosize-textarea/lib";
import ModalDataLoader from "../../../containers/app/components/ModalDataLoader";
import { QuickBooksItemType } from "../enums/quickBooksItemType";
import { getSortedItemsV2 } from "../../../services/sortingService";
import quickBooksDataProvider from "../services/quickBooksDataProvider";
import { IQuickBooksAccount } from "../models/IQuickBooksAccount";
import DayPicker, {
  format as dayPickerFormat,
} from "../../../containers/app/components/DayPicker";
import { format } from "date-fns";
import dateService from "../../../services/dateService";
import { QuickBooksAccountType } from "../enums/quickBooksAccountType";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ErrorMessageType } from "../../../containers/app/components/FormContainer";

interface IFormData {
  name: string;
  description: string;
  unitPrice: string;
  taxable: boolean;
  itemType: string;
  accountId: string;
  quantityOnHand: number | null;
  quantityOnHandAsOfDate: string | null;
  inventoryAssetAccountId: string | null;
  expenseAccountId: string | null;
  inactive: boolean;
}

type IProps = {
  item: {
    name: string;
    description: string | null;
    inactive: boolean;
    unitPrice: number | null;
    taxable: boolean;
  };
  onSaveComplete: (updatedItem: {
    id: string;
    name: string;
    description: string;
    inactive: boolean;
    unitPrice: number | null;
    taxable: boolean;
  }) => void;
  onCancel: () => void;
} & (
  | {
      invoiceItemId: string;
      mode: "edit";
    }
  | {
      invoiceItemId?: undefined;
      mode: "add";
    }
);

const QuickBooksInvoiceItemForm: React.FunctionComponent<
  IProps & { accounts: Array<IQuickBooksAccount> }
> = ({ invoiceItemId, accounts, item, onSaveComplete, onCancel, mode }) => {
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");
  const quantityOnHandAsOfDateRef = useRef<HTMLLabelElement>(null);

  const { register, getValues, setValue, control, watch } = useForm<IFormData>({
    defaultValues: {
      name: item.name,
      description: item.description ?? "",
      unitPrice: getNumericString(item.unitPrice),
      accountId: getNonInventoryDefaultItem(accounts),
      quantityOnHand: null,
      quantityOnHandAsOfDate: format(new Date(), dayPickerFormat),
      inventoryAssetAccountId: getInventoryAssetDefaultItem(accounts),
      expenseAccountId: getCostOfGoodsSoldDefaultItem(accounts),
      inactive: item.inactive,
      taxable: item.taxable,
    },
  });

  const itemType = watch("itemType");
  const inactive = watch("inactive");

  const fieldColumnClasses = "form-group col-12 col-md-6";
  return (
    <FormContainerWithoutRedux
      formHeader={
        mode === "edit" ? "Update QuickBooks Item" : "Add QuickBooks Item"
      }
      formType={FormTypesV2.quickBooksInvoiceItem}
      size="lg"
      saveButtonText={
        mode === "edit" ? "Update QuickBooks item" : "Add QuickBooks item"
      }
      confirmation={
        inactive && !item.inactive ? (
          <>
            <span className="font-weight-bold">Heads-up!</span> Marking an item
            inactive will prevent you from selecting this item in the future.
            <div className="mt-1">Are you sure you want to continue?</div>
          </>
        ) : null
      }
      save={() => {
        const parsedItemType = parseInt(
          getValues("itemType")
        ) as QuickBooksItemType;
        const quantityOnHandAsOfDate = getValues("quantityOnHandAsOfDate");
        const payload = {
          ...getValues(),
          itemType: parsedItemType,
          unitPrice: modelConversion.convertStringToNumberOrNull(
            getValues("unitPrice")
          ),
          quantityOnHandAsOfDate:
            quantityOnHandAsOfDate !== null
              ? dateService.formatAsIso(quantityOnHandAsOfDate)
              : null,
        };

        if (parsedItemType !== QuickBooksItemType.inventory) {
          payload.quantityOnHand = null;
          payload.quantityOnHandAsOfDate = null;
          payload.expenseAccountId = null;
          payload.inventoryAssetAccountId = null;
        }

        let returnValue: Observable<{ id: string }>;
        if (mode === "edit") {
          returnValue = quickBooksDataProvider
            .updateQuickBooksItem(invoiceItemId, payload)
            .pipe(
              map((r) => {
                return { id: invoiceItemId };
              })
            );
        } else {
          returnValue = quickBooksDataProvider.addQuickBooksItem(payload);
        }

        return returnValue;
      }}
      onSaveComplete={(result) =>
        onSaveComplete({
          id: typeof result?.id === "string" ? result?.id : "",
          name: getValues("name"),
          description: getValues("description"),
          inactive: getValues("inactive"),
          unitPrice: getNullableFloatFromNumberOrString(getValues("unitPrice")),
          taxable: getValues("taxable"),
        })
      }
      onCancel={onCancel}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      <div className="form-group">
        <label htmlFor="name" className="required">
          Name
        </label>
        <input
          type="text"
          id="name"
          className="form-control"
          required
          {...register("name")}
        />
      </div>
      <div className="form-group">
        <label htmlFor="description">Description</label>
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <TextareaAutosize
              maxRows={10}
              className="form-control"
              id="description"
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
              }}
            />
          )}
        />
      </div>
      <div className="form-group">
        <label htmlFor="unitPrice">Amount per item</label>
        <Controller
          control={control}
          name="unitPrice"
          render={({ field }) => (
            <CurrencyInput
              type="text"
              id="unitPrice"
              className="form-control"
              value={field.value}
              onValueChange={(e) => field.onChange(e)}
            />
          )}
        />
      </div>
      <div className="form-group">
        <div className="custom-control custom-checkbox">
          <input
            id="taxable"
            type="checkbox"
            className="custom-control-input"
            {...register("taxable")}
          />
          <label htmlFor={"taxable"} className="custom-control-label">
            Taxable
          </label>
        </div>
      </div>
      {!invoiceItemId ? (
        <>
          <div className="form-group">
            <label htmlFor="type" className="required">
              Type
            </label>
            <select
              id="type"
              className="form-control"
              {...register("itemType", {
                onChange: () => {
                  // Need to do setTimeout to allow the Income Account drop-down to re-render
                  // with all options
                  setTimeout(() => {
                    const v = getValues("itemType");
                    if (isInventoryItem(v)) {
                      setValue("accountId", getInventoryDefaultItem(accounts));
                    } else {
                      setValue(
                        "accountId",
                        getNonInventoryDefaultItem(accounts)
                      );
                    }
                  });
                },
              })}
            >
              <option value={QuickBooksItemType.service.toString()}>
                Service
              </option>
              <option value={QuickBooksItemType.nonInventory.toString()}>
                Non-inventory
              </option>
              <option value={QuickBooksItemType.inventory.toString()}>
                Inventory
              </option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="account" className="required">
              Income account
            </label>
            <select
              id="account"
              className="form-control"
              {...register("accountId")}
            >
              {getSortedItemsV2(
                getIncomeAccounts(accounts).filter(
                  (a) =>
                    !isInventoryItem(itemType) ||
                    a.subType === "SalesOfProductIncome"
                ),
                ["name"]
              ).map((a) => (
                <option key={a.id} value={a.id}>
                  {a.name}
                </option>
              ))}
            </select>
          </div>
          {isInventoryItem(itemType) ? (
            <>
              <div className="form-row">
                <div className={fieldColumnClasses}>
                  <label htmlFor="quantityOnHand" className="required">
                    Quantity on hand
                  </label>
                  <input
                    type="number"
                    id="quantityOnHand"
                    className="form-control"
                    min={0}
                    max={9999999}
                    required
                    {...register("quantityOnHand")}
                  />
                </div>
                <div className={fieldColumnClasses}>
                  <label
                    ref={quantityOnHandAsOfDateRef}
                    htmlFor="quantityOnHandAsOfDate"
                    className="required"
                  >
                    Quantity on hand as-of date
                  </label>
                  <Controller
                    control={control}
                    name="quantityOnHandAsOfDate"
                    render={({ field }) => (
                      <DayPicker
                        labelRef={quantityOnHandAsOfDateRef}
                        onDayPickerHide={() => null}
                        dayPickerProps={{}}
                        value={
                          !!field.value
                            ? format(field.value, dayPickerFormat)
                            : ""
                        }
                        required={true}
                        inputId="date"
                        onDaySelected={(day: Date) => {
                          let newValue = "";

                          if (!!day) {
                            newValue = format(day, dayPickerFormat);
                          }

                          field.onChange(newValue);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className={fieldColumnClasses}>
                  <label htmlFor="inventoryAssetAccountId" className="required">
                    Inventory asset account
                  </label>
                  <select
                    id="inventoryAssetAccountId"
                    className="form-control"
                    {...register("inventoryAssetAccountId")}
                  >
                    {getSortedItemsV2(getAssetAccounts(accounts), ["name"]).map(
                      (a) => (
                        <option key={a.id} value={a.id}>
                          {a.name}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className={fieldColumnClasses}>
                  <label htmlFor="expenseAccountId" className="required">
                    Expense account
                  </label>
                  <select
                    id="expenseAccountId"
                    className="form-control"
                    {...register("expenseAccountId")}
                  >
                    {getSortedItemsV2(getCostOfGoodsSoldAccounts(accounts), [
                      "name",
                    ]).map((a) => (
                      <option key={a.id} value={a.id}>
                        {a.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </>
          ) : null}
        </>
      ) : null}
      {invoiceItemId ? (
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="inactive"
              {...register("inactive")}
            />
            <label className="custom-control-label" htmlFor="inactive">
              Inactive
            </label>
          </div>
        </div>
      ) : null}
    </FormContainerWithoutRedux>
  );
};

const QuickBooksInvoiceItemFormWrapper = (props: IProps) => {
  const [accounts, setAccounts] = useState<Array<IQuickBooksAccount>>([]);

  const loadData = useCallback(
    () => quickBooksDataProvider.getQuickBooksAccounts(),
    []
  );
  const onDataLoaded = useCallback(
    (loadedAccounts: Array<IQuickBooksAccount>) => setAccounts(loadedAccounts),
    []
  );

  return (
    <ModalDataLoader
      errorMessage={"Unable to load QuickBooks accounts."}
      loadData={loadData}
      onDataLoaded={onDataLoaded}
      onErrorAlertClose={props.onCancel}
    >
      <QuickBooksInvoiceItemForm {...props} accounts={accounts} />
    </ModalDataLoader>
  );
};

export default QuickBooksInvoiceItemFormWrapper;

function isInventoryItem(itemType: string): unknown {
  return itemType === QuickBooksItemType.inventory.toString();
}

function getInventoryAssetDefaultItem(accounts: IQuickBooksAccount[]) {
  const assetAccounts = getAssetAccounts(accounts);
  if (assetAccounts.length > 0) {
    return assetAccounts[0].id;
  } else {
    return "";
  }
}

function getCostOfGoodsSoldDefaultItem(accounts: IQuickBooksAccount[]) {
  const costOfGoodsAccounts = getCostOfGoodsSoldAccounts(accounts);
  if (costOfGoodsAccounts.length > 0) {
    return costOfGoodsAccounts[0].id;
  } else {
    return "";
  }
}

function getInventoryDefaultItem(accounts: IQuickBooksAccount[]) {
  const salesAccount = getIncomeAccounts(accounts).find(
    (a) => a.subType === "SalesOfProductIncome"
  );
  if (salesAccount) {
    return salesAccount.id;
  } else if (accounts.length > 0) {
    return accounts[0].id;
  } else {
    return "";
  }
}

function getNonInventoryDefaultItem(accounts: IQuickBooksAccount[]) {
  const serviceAccount = getIncomeAccounts(accounts).find(
    (a) => a.name === "Services"
  );
  if (serviceAccount) {
    return serviceAccount.id;
  } else if (accounts.length > 0) {
    return accounts[0].id;
  } else {
    return "";
  }
}

function getIncomeAccounts(accounts: IQuickBooksAccount[]) {
  return accounts.filter((t) => t.type === QuickBooksAccountType.income);
}

function getAssetAccounts(accounts: IQuickBooksAccount[]) {
  return accounts.filter(
    (t) =>
      t.type === QuickBooksAccountType.otherCurrentAsset &&
      t.subType === "Inventory"
  );
}

function getCostOfGoodsSoldAccounts(accounts: IQuickBooksAccount[]) {
  return accounts.filter(
    (t) => t.type === QuickBooksAccountType.costOfGoodsSold
  );
}
