import * as React from "react";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import { isMobileOnly } from "react-device-detect";
import Button from "reactstrap/lib/Button";
import ButtonDropdown from "reactstrap/lib/ButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import {
  ClassAttributes,
  FormHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import SubscriptionWarning from "../../../slices/tenantSubscription/components/SubscriptionWarning";
import { IFormsState } from "../../../formGenerator/formReducers";
import SpinnerModalFooter from "./SpinnerModalFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

export type EnrichFormDataCallback = (originalFormData: any) => any;

export type FormDefaults = Partial<
  FormHTMLAttributes<HTMLFormElement> & ClassAttributes<HTMLFormElement>
> & { "data-testid": string };

export interface IAdditionalSaveButton {
  label: string;
  enrichFormData?: (originalFormData: any) => any;
  saveOverride?: () => void;
}

export type ErrorMessageType = string | React.ReactNode;

interface IFormContainerProps {
  showForm: boolean;
  saving: boolean;
  startSave(
    data: any,
    reopenFormOnComplete?: boolean,
    enrichFormData?: (data: any) => any
  ): void;
  cancel(): void;
  errorMessage: ErrorMessageType;
  setErrorMessage(errorMessage: string): void;
  validate?(): IValidateResult;
  getFormData?(): any;
  formKey: string;
  formHeader: string;
  children: React.ReactNode | ((formDefaults: FormDefaults) => React.ReactNode);
  saveButtonText?: string;
  useKeyboard?: boolean;
  hideSave?: boolean;
  disableFade?: boolean;
  size?: string;
  hasFormDataChanged?: () => boolean;
  additionalSaveButtons?: Array<IAdditionalSaveButton>;
  additionalPrimaryButtons?: Array<IAdditionalSaveButton>;
  disableSave?: boolean;
  saveHandlerOverride?(): void;
  footerMessage?: string | JSX.Element | null;
  footerClassName?: string;
  addSaveAndNewButton?: boolean;
  getConfirmationPrompt?: (formData: any) => React.ReactNode | null;
  disableSubmitOnEnterPress?: boolean;
  saveButtonExplanation?: React.ReactNode;
}

export interface IValidateResult {
  valid: boolean;
  errorMessage?: string;
  hideErrorMessage?: boolean;
}

const FormContainer: React.FunctionComponent<IFormContainerProps> = ({
  showForm,
  startSave,
  cancel,
  errorMessage,
  saving,
  setErrorMessage,
  validate,
  getFormData,
  formKey,
  formHeader,
  children,
  saveButtonText,
  useKeyboard,
  hideSave,
  disableFade,
  size,
  hasFormDataChanged,
  additionalSaveButtons,
  saveHandlerOverride,
  disableSave,
  footerMessage,
  footerClassName,
  addSaveAndNewButton,
  additionalPrimaryButtons,
  getConfirmationPrompt,
  disableSubmitOnEnterPress,
  saveButtonExplanation,
}) => {
  const [
    isAdditionalSaveButtonsDropDownOpen,
    setIsAdditionalSaveButtonsDropDownOpen,
  ] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const modalRef = useRef<HTMLFormElement | null>(null);
  useModalKeyboardScroll(modalRef, showForm);

  const [confirmationPromptData, setConfirmationPromptData] = useState<{
    showPrompt: boolean;
    prompt: React.ReactNode | null;
    enrichFormData?: EnrichFormDataCallback;
    reopenFormOnComplete?: boolean;
  }>({ showPrompt: false, prompt: null });

  const save = (
    enrichFormData?: EnrichFormDataCallback,
    reopenFormOnComplete?: boolean,
    confirmed?: boolean
  ) => {
    if (saving) {
      return;
    }

    const executeStartSave = () => {
      if (saveHandlerOverride) {
        saveHandlerOverride();
      } else {
        let prompt = null;

        if (typeof getConfirmationPrompt === "function" && !confirmed) {
          prompt = getConfirmationPrompt(innerGetFormData());
        }

        if (prompt) {
          setConfirmationPromptData({
            showPrompt: true,
            prompt,
            enrichFormData,
            reopenFormOnComplete,
          });
        } else {
          startSave(innerGetFormData(), reopenFormOnComplete, enrichFormData);
        }
      }
    };

    if (validate) {
      const validateResult = validate();
      if (validateResult.valid) {
        executeStartSave();
      } else {
        if (!validateResult.hideErrorMessage) {
          setErrorMessage(validateResult.errorMessage || "Unknown error");
        }
      }
    } else {
      executeStartSave();
    }

    function innerGetFormData() {
      if (!getFormData) {
        return;
      }

      let formData = getFormData();
      if (enrichFormData) {
        formData = enrichFormData(formData);
      }
      return formData;
    }
  };

  const cancelWrapper = () => {
    if (!hasFormDataChanged || !hasFormDataChanged()) {
      cancel();
    } else {
      if (
        window.confirm(
          "Are you sure you want to close this form without saving?"
        )
      ) {
        cancel();
      }
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    save();
  };

  const formId = formKey + "-form";
  const saveButton = (
    <button
      className="btn btn-primary"
      type="submit"
      id={formKey + "-save-button"}
      disabled={saving ?? disableSave}
      form={formId}
      data-testid="formSaveButton"
    >
      {saveButtonText || "Save"}
    </button>
  );

  additionalSaveButtons = additionalSaveButtons ?? [];
  additionalPrimaryButtons = additionalPrimaryButtons ?? [];

  if (addSaveAndNewButton) {
    additionalSaveButtons = [
      ...additionalSaveButtons,
      {
        label: "Save and new",
        saveOverride: () => save(undefined, true),
      },
    ];
  }

  if (isMobileOnly && additionalPrimaryButtons.length > 0) {
    additionalSaveButtons = [
      ...additionalSaveButtons,
      ...additionalPrimaryButtons,
    ];

    additionalPrimaryButtons = [];
  }

  const formDefaults: FormDefaults = {
    id: formId,
    onSubmit: onSubmit,
    autoComplete: "off",
    className: isMobileOnly ? "mobile-form" : "",
    ref: formRef,
    "data-testid": "formContainerForm",
  };

  return showForm ? (
    <SubscriptionWarning
      mode="formType"
      formType={formKey as keyof IFormsState}
      onCancel={cancel}
    >
      <Modal
        isOpen={showForm}
        backdrop="static"
        keyboard={typeof useKeyboard === "undefined" ? true : useKeyboard}
        toggle={() => {
          if (!saving) {
            cancelWrapper();
          }
        }}
        fade={!disableFade}
        size={size}
        scrollable={true}
        innerRef={modalRef}
      >
        <ModalHeader
          toggle={() => {
            if (!saving) {
              cancelWrapper();
            }
          }}
        >
          {formHeader}
        </ModalHeader>
        <ModalBody className="modal-body-selector">
          {typeof children === "function" ? (
            children(formDefaults)
          ) : (
            <form
              {...formDefaults}
              onKeyDown={(e) => {
                if (disableSubmitOnEnterPress && e.key === "Enter") {
                  if (
                    e.target instanceof HTMLElement &&
                    e.target.tagName &&
                    // Check if strings are equal (case insensitive)
                    "textarea".localeCompare(e.target.tagName, undefined, {
                      sensitivity: "base",
                    }) === 0
                  ) {
                    e.stopPropagation();
                  } else {
                    e.preventDefault();
                  }
                }
              }}
            >
              {children}
            </form>
          )}
        </ModalBody>
        <ModalFooter style={{ justifyContent: "space-between" }}>
          <div>
            {saving ? (
              <div>
                <SpinnerModalFooter />
              </div>
            ) : (
              <>
                {footerMessage ? (
                  <div
                    className={footerClassName ?? "text-danger"}
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {footerMessage}
                  </div>
                ) : null}
                {errorMessage ? (
                  <div
                    className="text-danger"
                    style={{ whiteSpace: "pre-line" }}
                    data-testid="formErrorMessage"
                  >
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      style={{ marginRight: "5px" }}
                    />
                    {errorMessage}
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div>
            {!!saveButtonExplanation ? (
              <div className="text-right mb-2">{saveButtonExplanation}</div>
            ) : null}

            <div className="d-flex justify-content-end">
              {hideSave ? null : additionalSaveButtons.length > 0 ? (
                <ButtonDropdown
                  direction="down"
                  isOpen={isAdditionalSaveButtonsDropDownOpen}
                  toggle={() =>
                    setIsAdditionalSaveButtonsDropDownOpen(
                      !isAdditionalSaveButtonsDropDownOpen
                    )
                  }
                >
                  {saveButton}
                  <DropdownToggle split color="primary" />
                  <DropdownMenu positionFixed={true} right={true}>
                    {additionalSaveButtons.map((additionalSaveButton) => (
                      <DropdownItem
                        key={additionalSaveButton.label}
                        onClick={(e) => {
                          e.preventDefault();

                          if (!formRef.current?.reportValidity()) {
                            return;
                          }

                          if (additionalSaveButton.saveOverride) {
                            additionalSaveButton.saveOverride();
                          } else if (additionalSaveButton.enrichFormData) {
                            save(additionalSaveButton.enrichFormData);
                          }
                        }}
                      >
                        {additionalSaveButton.label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              ) : (
                saveButton
              )}

              {hideSave ? null : additionalPrimaryButtons.length > 0 ? (
                <>
                  {additionalPrimaryButtons.map((additionalPrimaryButton) => (
                    <Button
                      key={additionalPrimaryButton.label}
                      color="primary"
                      className="ml-2"
                      disabled={saving}
                      onClick={(e) => {
                        e.preventDefault();

                        if (!formRef.current?.reportValidity()) {
                          return;
                        }

                        if (additionalPrimaryButton.saveOverride) {
                          additionalPrimaryButton.saveOverride();
                        } else if (additionalPrimaryButton.enrichFormData) {
                          save(additionalPrimaryButton.enrichFormData);
                        }
                      }}
                    >
                      {additionalPrimaryButton.label}
                    </Button>
                  ))}
                </>
              ) : null}
              <button
                className="btn btn-secondary"
                onClick={() => cancelWrapper()}
                type="button"
                disabled={saving}
                style={{ marginLeft: ".5rem" }}
                data-cancelbutton="true"
                data-testid="cancelButton"
              >
                Cancel
              </button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
      {confirmationPromptData.showPrompt &&
      confirmationPromptData.prompt != null ? (
        <Modal isOpen={true}>
          <ModalBody>{confirmationPromptData.prompt}</ModalBody>
          <ModalFooter>
            <button
              className="btn btn-primary"
              onClick={() => {
                setConfirmationPromptData({
                  showPrompt: false,
                  enrichFormData: undefined,
                  prompt: confirmationPromptData.prompt,
                });
                save(
                  confirmationPromptData.enrichFormData,
                  confirmationPromptData.reopenFormOnComplete,
                  true
                );
              }}
            >
              Yes
            </button>

            <button
              className="btn btn-secondary ml-2"
              onClick={() => {
                setConfirmationPromptData({
                  showPrompt: false,
                  enrichFormData: undefined,
                  prompt: confirmationPromptData.prompt,
                });
              }}
            >
              No
            </button>
          </ModalFooter>
        </Modal>
      ) : null}
    </SubscriptionWarning>
  ) : null;
};

export default FormContainer;

function useModalKeyboardScroll(
  modalRef: React.MutableRefObject<HTMLFormElement | null>,
  showForm: boolean
) {
  useEffect(() => {
    function handler(e: any) {
      if (e.key === "ArrowUp") {
        scrollElement(-40);
      } else if (e.key === "ArrowDown") {
        scrollElement(40);
      } else if (e.key === "PageUp") {
        scrollElement("PageUp");
      } else if (e.key === "PageDown") {
        scrollElement("PageDown");
      }

      function scrollElement(scrollBy: number | "PageUp" | "PageDown") {
        if (
          e.target &&
          (e.target instanceof HTMLSelectElement ||
            e.target instanceof HTMLInputElement)
        ) {
          return;
        }

        const result = getModalBody();
        if (result) {
          let scrollAmount: number;
          if (typeof scrollBy !== "number") {
            const modalBodyHeight = result.clientHeight;
            if (scrollBy === "PageUp") {
              scrollAmount = -1 * modalBodyHeight;
            } else if (scrollBy === "PageDown") {
              scrollAmount = modalBodyHeight;
            } else {
              scrollAmount = 0;
            }
          } else {
            scrollAmount = scrollBy;
          }

          result.scroll(0, result.scrollTop + scrollAmount);
        }
      }

      function getModalBody() {
        if (!modalRef.current) {
          return;
        }

        return modalRef.current.querySelector(".modal-body-selector");
      }
    }

    if (showForm) {
      window.addEventListener("keydown", handler, { passive: true });

      return function cleanup() {
        window.removeEventListener("keydown", handler);
      };
    }
  }, [showForm, modalRef]);
}
