import { actionTypes } from "./actionCreators";
import {
  ICrewDeleteError,
  IJobDeleteError,
  IManageUiShowMessage,
  IManageUiHideMessage,
  ICustomerDeleteError,
  ICustomerAdditionalLocationDeleteError,
  ICrewMemberDeleteError,
  IManageUiHideError,
  IManageUiShowError,
} from "./actionTypeDefinitions";
import {
  formTypes,
  actionTypes as formActionTypes,
  createSpecificActionTypeName,
} from "../formGenerator";

export interface IManageUiState {
  topLevelMessage: string | undefined;
  topLevelMessageId: string | undefined;
  addedCustomerId: string | undefined;
  errors: {
    [key: string]: string;
  };
}

export default (
  state: IManageUiState | undefined,
  action: any
): IManageUiState => {
  if (!state) {
    state = {
      topLevelMessage: undefined,
      topLevelMessageId: undefined,
      addedCustomerId: undefined,
      errors: {},
    };
  }

  switch (action.type) {
    case actionTypes.MANGEUI_SHOW_MESSAGE:
      const showMessageAction = action as IManageUiShowMessage;
      return {
        ...state,
        topLevelMessage: showMessageAction.message,
        topLevelMessageId: showMessageAction.messageId,
      };

    case actionTypes.MANGEUI_HIDE_MESSAGE:
      const hideMessageAction = action as IManageUiHideMessage;
      return {
        ...state,
        topLevelMessage:
          hideMessageAction.messageId === state.topLevelMessageId ||
          hideMessageAction.messageId === undefined
            ? undefined
            : state.topLevelMessage,
        topLevelMessageId:
          hideMessageAction.messageId === state.topLevelMessageId ||
          hideMessageAction.messageId === undefined
            ? undefined
            : state.topLevelMessageId,
      };

    case actionTypes.MANGEUI_HIDE_ERROR:
      const hideErrorAction = action as IManageUiHideError;
      const newErrors = {
        ...state.errors,
      };
      delete newErrors[hideErrorAction.errorId];

      return {
        ...state,
        errors: newErrors,
      };

    case actionTypes.MANGEUI_SHOW_ERROR:
      const showErrorAction = action as IManageUiShowError;
      const newErrorsForShow = {
        ...state.errors,
      };
      newErrorsForShow[showErrorAction.errorId] = showErrorAction.message;

      return {
        ...state,
        errors: newErrorsForShow,
      };

    case actionTypes.CREW_DELETE_ERROR:
      const crewDeleteAction = action as ICrewDeleteError;
      const newErrorsForCrewDelete = { ...state.errors };
      newErrorsForCrewDelete[crewDeleteAction.crewId] = crewDeleteAction.error;
      return {
        ...state,
        errors: newErrorsForCrewDelete,
      };

    case actionTypes.CREW_MEMBER_DELETE_ERROR:
      const crewMemberDeleteAction = action as ICrewMemberDeleteError;
      const newErrorsForCrewMemberDelete = { ...state.errors };
      newErrorsForCrewMemberDelete[crewMemberDeleteAction.crewMemberId] =
        crewMemberDeleteAction.error;
      return {
        ...state,
        errors: newErrorsForCrewMemberDelete,
      };

    case actionTypes.CUSTOMER_DELETE_ERROR:
      const customerDeleteAction = action as ICustomerDeleteError;
      const newErrorsForCustomerDelete = { ...state.errors };
      newErrorsForCustomerDelete[customerDeleteAction.customerId] =
        customerDeleteAction.error;
      return {
        ...state,
        errors: newErrorsForCustomerDelete,
      };

    case actionTypes.JOB_DELETE_ERROR:
      const jobDeleteAction = action as IJobDeleteError;
      const newErrorsForJobDelete = { ...state.errors };
      newErrorsForJobDelete[jobDeleteAction.jobId] = jobDeleteAction.error;

      if (jobDeleteAction.contextId) {
        newErrorsForJobDelete[jobDeleteAction.contextId] =
          jobDeleteAction.error;
      }

      return {
        ...state,
        errors: newErrorsForJobDelete,
      };

    case actionTypes.CUSTOMER_ADDITIONAL_LOCATION_DELETE_ERROR:
      const customerAdditionalLocationDeleteAction =
        action as ICustomerAdditionalLocationDeleteError;
      const newErrorsForCustomerAdditionalLocationDelete = { ...state.errors };
      newErrorsForCustomerAdditionalLocationDelete[
        customerAdditionalLocationDeleteAction.customerAdditionalLocationId
      ] = customerAdditionalLocationDeleteAction.error;

      if (customerAdditionalLocationDeleteAction.contextId) {
        newErrorsForCustomerAdditionalLocationDelete[
          customerAdditionalLocationDeleteAction.contextId
        ] = customerAdditionalLocationDeleteAction.error;
      }

      return {
        ...state,
        errors: newErrorsForCustomerAdditionalLocationDelete,
      };

    case actionTypes.ROUTER_LOCATION_CHANGE:
      return {
        ...state,
        topLevelMessage: undefined,
        topLevelMessageId: undefined,
        addedCustomerId: undefined,
        errors: {},
      };

    case createSpecificActionTypeName(
      formTypes.crew,
      formActionTypes.completeSaving
    ):
      let crewId = "";
      if (action.payload.parameters) {
        crewId = action.payload.parameters.crewId;
      }

      const errors = { ...state.errors };
      if (crewId) {
        delete errors[crewId];
      }

      return {
        ...state,
        errors,
      };

    case createSpecificActionTypeName(
      formTypes.crewMember,
      formActionTypes.completeSaving
    ):
      let crewMemberId = "";
      if (action.payload.parameters) {
        crewMemberId = action.payload.parameters.crewMemberId;
      }

      const crewMemberErrors = { ...state.errors };
      if (crewMemberId) {
        delete crewMemberErrors[crewMemberId];
      }

      return {
        ...state,
        errors: crewMemberErrors,
      };

    case createSpecificActionTypeName(
      formTypes.oneTimeJob,
      formActionTypes.completeSaving
    ):
      let oneTimeJobJobId = "";
      if (action.payload.id) {
        oneTimeJobJobId = action.payload.id;
      }

      const errorsForOneTimeJob = { ...state.errors };
      if (oneTimeJobJobId) {
        delete errorsForOneTimeJob[oneTimeJobJobId];
      }

      return {
        ...state,
        errors: errorsForOneTimeJob,
      };

    case createSpecificActionTypeName(
      formTypes.maintenanceJob,
      formActionTypes.completeSaving
    ):
      let maintenanceJobId = "";
      if (action.payload.id) {
        maintenanceJobId = action.payload.id;
      }

      const errorsForMaintenanceJob = { ...state.errors };
      if (maintenanceJobId) {
        delete errorsForMaintenanceJob[maintenanceJobId];
      }

      return {
        ...state,
        errors: errorsForMaintenanceJob,
      };

    case createSpecificActionTypeName(
      formTypes.customer,
      formActionTypes.completeSaving
    ):
      const isExistingCustomer =
        action.payload &&
        action.payload.parameters &&
        action.payload.parameters.customerId;
      return {
        ...state,
        addedCustomerId: !isExistingCustomer ? action.payload.id : undefined,
      };

    default:
      return state;
  }
};
