import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import { IInvoiceRetryChargeParameters } from "../../../formGenerator/formParameters/IInvoiceRetryChargeParameters";
import { IBankAccount } from "../../../models/IBankAccount";
import { IDiscount } from "../../../models/IDiscount";
import { IInvoiceDefaultDepositCredit } from "../models/IInvoiceDefaultDepositCredit";
import { IInvoiceDefaultLineItem } from "../../../models/IInvoiceDefaultLineItem";
import { IInvoiceForPrint } from "../../../models/IInvoiceForPrint";
import { IInvoiceSaveJobInstanceUpdate } from "../../../models/IInvoiceSaveJobInstanceUpdate";
import { InvoiceDeliveryMethod } from "../../../models/InvoiceDeliveryMethod";
import {
  buildUrl,
  executeWithHeaders,
} from "../../../services/remoteDataProvider";
import { Observable } from "rxjs";
import { IInvoiceDraft } from "../models/IInvoiceDraft";
import { IInvoicePayment } from "../../../models/IInvoicePayment";
import { PaymentMethodType } from "../../../enums/paymentMethodType";
import { IInvoiceDefaultProposalJobSummary } from "../models/IInvoiceDefaultProposalJobSummary";

// WARNING: FullStory network capture settings need to be updated with any changes to this path!
const invoicePaymentSaveFormSuffix = "invoicePayment";

export type InvoicePaymentSavePayload = IInvoicePayment & {
  payrixToken: string | null;
  bankAccount: IBankAccount | null;
  chargePaymentMethodOnFile: boolean;
  receiptEmailAddresses: Array<string> | null;
  replyToEmailAddress: string | null;
  sendReceipt: boolean;
  amount: number | null;
};

export interface IInvoiceRetryChargeRequest {
  invoiceId: string;
}

export interface IInvoiceDefaultsResponse {
  lineItems: Array<IInvoiceDefaultLineItem> | null;
  taxRate: number | null;
  discount: IDiscount;
  depositCredits: Array<IInvoiceDefaultDepositCredit> | null;
  captureDepositItemOverride: boolean;
  paymentMethodOnFileAuthorized: boolean;
  hideLineItemPrices: boolean;
  purchaseOrderNumber: string | null;
  proposalSummaries: Array<IInvoiceDefaultProposalJobSummary>;
}

export interface IGetInvoicePaymentFormDataResponse {
  addConvenienceFee: boolean;
  convenienceFeeAmount: number;
  totalAmount: number;
  balance: number;
  number: string;
  existingPayment: {
    amount: number;
    datePaid: string | null;
    paymentMethodType: PaymentMethodType | null;
    paymentReferenceInformation: string | null;
  } | null;
}

const invoiceDataProvider = {
  saveInvoiceRetryCharge: (
    request: IInvoiceRetryChargeRequest,
    parameters: IInvoiceRetryChargeParameters
  ) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl(`invoice/${parameters.invoiceId}/retryCharge`),
        JSON.stringify(request),
        headers
      )
    );
  },

  createBatchInvoices: (request: {
    jobInstanceIds: Array<string>;
    date: string;
    dueDate: string | null;
    deliveryMethod: InvoiceDeliveryMethod;
    taxRate: number | null;
    allowOnlineCreditCardPayment: boolean;
    allowOnlineAchPayment: boolean;
    addConvenienceFee: boolean;
    copyJobProposalSummary: boolean;
  }) => {
    return executeWithHeaders((headers) =>
      ajax.post(buildUrl("Invoice/batch"), request, headers)
    ).pipe(
      map((result) => {
        return {
          invoiceCreationFailureCount:
            result.response.invoiceCreationFailureCount,
          jobInstanceUpdates:
            result.response.jobInstanceUpdates ??
            ([] as Array<IInvoiceSaveJobInstanceUpdate>),
        };
      })
    );
  },

  getInvoicesForPrint: (invoiceIds: Array<string>) => {
    const params: Array<string> = [];
    invoiceIds.forEach((invoiceId) =>
      params.push(`invoiceIds=${encodeURIComponent(invoiceId)}`)
    );

    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(`Invoice/Print?${params.join("&")}`), headers)
    ).pipe(
      map((result) => result.response.invoices as Array<IInvoiceForPrint>)
    );
  },

  getCustomerBalance: (customerId: string) => {
    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(`customer/${customerId}/balance`), headers)
    ).pipe(
      map(
        (result) =>
          result.response as { hasBalance: boolean; balance: number | null }
      )
    );
  },

  getInvoiceDefaults: (request: {
    jobInstanceIds: Array<string>;
    projectIds: Array<string>;
    invoiceId: string | null;
  }): Observable<IInvoiceDefaultsResponse> => {
    return executeWithHeaders((headers) =>
      ajax.post(buildUrl("invoice/defaults"), request, headers)
    ).pipe(
      map((result) => {
        return {
          lineItems: result.response
            .lineItemDefaults as null | Array<IInvoiceDefaultLineItem>,
          taxRate: result.response.taxRate,
          discount: result.response.discount,
          proposalSummaries: result.response.proposalSummaries,
          depositCredits: result.response
            .depositCredits as null | Array<IInvoiceDefaultDepositCredit>,
          paymentMethodOnFileAuthorized: result.response
            .paymentMethodOnFileAuthorized as boolean,
          captureDepositItemOverride: result.response
            .captureDepositItemOverride as boolean,
          hideLineItemPrices: result.response.hideLineItemPrices as boolean,
          purchaseOrderNumber: result.response.purchaseOrderNumber as
            | string
            | null,
        };
      })
    );
  },

  updateContractBillingHistoryItem: ({
    contractBillingHistoryItemId,
    changes,
  }: {
    contractBillingHistoryItemId: string;
    changes: Partial<{ doNotInvoice: boolean }>;
  }) => {
    return executeWithHeaders((headers) =>
      ajax.patch(
        buildUrl(`contractBillingHistoryItem/${contractBillingHistoryItemId}`),
        changes,
        headers
      )
    );
  },

  resendReceiptForInvoice: (
    invoiceId: string,
    replyToEmailAddress: string,
    emailAddresses: Array<string>
  ) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl(`invoice/${invoiceId}/resendReceipt`),
        { replyToEmailAddress, emailAddresses },
        headers
      )
    );
  },

  resendReceiptForPayment: (
    invoiceId: string,
    paymentId: string,
    replyToEmailAddress: string,
    emailAddresses: Array<string>
  ) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl(`invoicePayment/${invoiceId}/${paymentId}/resendReceipt`),
        { replyToEmailAddress, emailAddresses },
        headers
      )
    );
  },

  getPaymentMethodOnFileAuthorizationAsync: (invoiceId: string) => {
    return executeWithHeaders((headers) =>
      ajax.get(
        buildUrl(`invoice/${invoiceId}/paymentMethodOnFileAuthorization`),
        headers
      )
    ).pipe(
      map(
        (result) =>
          result.response as { paymentMethodOnFileAuthorized: boolean }
      )
    );
  },

  getInvoicePaymentFormData: (invoiceId: string, paymentId: string | null) => {
    return executeWithHeaders((headers) =>
      ajax.get(
        buildUrl(
          `invoice/${invoiceId}/paymentFormData${
            typeof paymentId === "string" ? "/" + paymentId : ""
          }`
        ),
        headers
      )
    ).pipe(
      map((result) => result.response as IGetInvoicePaymentFormDataResponse)
    );
  },

  getConvenienceFeeAmount: (invoiceId: string, amount: number) => {
    return executeWithHeaders((headers) =>
      ajax.get(
        buildUrl(`invoice/${invoiceId}/convenienceFeeAmount?amount=${amount}`),
        headers
      )
    ).pipe(
      map((result) => result.response as { convenienceFeeAmount: number })
    );
  },

  getInvoiceDrafts: ({ top }: { top: number | null }) => {
    const params: Array<string> = [];

    if (typeof top === "number") {
      params.push(`top=${top}`);
    }

    return executeWithHeaders((headers) =>
      ajax.get(buildUrl(`invoice/drafts?${params.join("&")}`), headers)
    ).pipe(map((result) => result.response.drafts as Array<IInvoiceDraft>));
  },

  addInvoicePayment: ({
    invoiceId,
    changes,
  }: {
    invoiceId: string;
    changes: InvoicePaymentSavePayload;
  }) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl(`${invoicePaymentSaveFormSuffix}/${invoiceId}`),
        changes,
        headers
      )
    );
  },

  updateInvoicePayment: ({
    invoiceId,
    paymentId,
    changes,
  }: {
    invoiceId: string;
    paymentId: string;
    changes: Partial<InvoicePaymentSavePayload>;
  }) => {
    return executeWithHeaders((headers) =>
      ajax.patch(
        buildUrl(`${invoicePaymentSaveFormSuffix}/${invoiceId}/${paymentId}`),
        changes,
        headers
      )
    );
  },

  deleteInvoicePayment: ({
    invoiceId,
    paymentId,
  }: {
    invoiceId: string;
    paymentId: string;
  }) => {
    return executeWithHeaders((headers) =>
      ajax.delete(
        buildUrl(`${invoicePaymentSaveFormSuffix}/${invoiceId}/${paymentId}`),
        headers
      )
    );
  },

  refundInvoicePayment: ({
    invoiceId,
    paymentId,
  }: {
    invoiceId: string;
    paymentId: string;
  }) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl(
          `${invoicePaymentSaveFormSuffix}/${invoiceId}/${paymentId}/refund`
        ),
        {},
        headers
      )
    );
  },
};

export default invoiceDataProvider;
