import { UserAccountRole } from "../../../enums/userAccountRole";
import { IUserAccount } from "../../../models/IUserAccount";
import { getSortedItemsV2 } from "../../../services/sortingService";
import { isStringSet } from "../../../services/stringService";
import { IAddress } from "../../../models/IAddress";
import { ICustomer } from "../../../models/ICustomer";
import { ICustomerAdditionalLocation } from "../../../models/ICustomerAdditionalLocation";
import { logError } from "../../../services/errorLogger";
import { OpportunityStatus } from "../enums/opportunityStatus";
import { IOpportunity } from "../models/IOpportunity";
import { IOpportunityBoard } from "../models/IOpportunityBoard";
import { getLocationName } from "../../../services/customerLocationHelper";

export function getOpportunitiesForLane(
  opportunities: Array<IOpportunity>,
  board: IOpportunityBoard,
  lane: keyof IOpportunityBoard
) {
  const laneOpportunityIds = board[lane];
  return laneOpportunityIds
    .map((opportunityId) =>
      opportunities.find((opportunity) => opportunity.id === opportunityId)
    )
    .filter((o) => typeof o === "object" && !o.archived)
    .map((o) => o as IOpportunity);
}

export function getOpportunityLaneKey(
  status: OpportunityStatus
): keyof IOpportunityBoard | null {
  if (status === OpportunityStatus.Todo) {
    return "todoOpportunities";
  } else if (status === OpportunityStatus.InProgress) {
    return "inProgressOpportunities";
  } else if (status === OpportunityStatus.Proposal) {
    return "proposalOpportunities";
  }

  return null;
}

export function getAssignedToOptions(userAccounts: Array<IUserAccount>) {
  return getSortedItemsV2(
    userAccounts.filter((ua) => ua.role !== UserAccountRole.scheduler),
    ["name", "emailAddress"]
  ).map((ua) => ({
    label: isStringSet(ua.name) ? ua.name : ua.emailAddress,
    id: ua.id,
  }));
}

export function getNameToShow(
  customers: ICustomer[],
  opportunity: IOpportunity,
  customerAdditionalLocations: ICustomerAdditionalLocation[]
) {
  let address: IAddress | null = null;

  const customer = customers.find((c) => c.id === opportunity.customerId);
  if (!customer) {
    logError(`customer not found for opportunity ${opportunity.id}`);
  } else {
    address = customer;
  }

  let nameToShow = customer?.name ?? "";

  if (opportunity.customerAdditionalLocationId) {
    const location = customerAdditionalLocations.find(
      (l) => l.id === opportunity.customerAdditionalLocationId
    );

    if (location) {
      address = location;
      const locationName = getLocationName(
        location.id,
        customerAdditionalLocations
      );
      if (locationName) {
        nameToShow += ` - ${locationName}`;
      }
    }
  }
  return { nameToShow, customer, address };
}
