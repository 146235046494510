import { push } from "connected-react-router";
import { addDays, startOfWeek } from "date-fns";
import { useDispatch } from "react-redux";
import constants from "../../../constants";
import WeekPicker from "../../../containers/app/components/WeekPicker";
import TodayButton from "./TodayButton";
import dateService from "../../../services/dateService";
import { getSortedCrews } from "../../../services/sortingService";
import {
  builders,
  builders as routingBuilders,
} from "../../../services/routing";
import format from "date-fns/format";
import DayPicker, {
  format as datePickerFormat,
} from "../../../containers/app/components/DayPicker";
import { ICrew } from "../../../models/ICrew";
import { ISelectedCrew } from "./types/ISelectedCrew";
import {
  getCurrentCrewId,
  getScheduleWeekSelectedCrews,
  getSelectedDateStartOfWeek,
} from "../services/scheduleWeekService";
import { CrewScheduleType } from "../enums/crewScheduleType";
import { useUserSettings } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import { getSelectedCrewId } from "../services/scheduleDayService";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";

export function ScheduleWeekFilters({
  selectedCrews,
  params,
  todayButtonLink,
}: {
  selectedCrews: Array<ISelectedCrew>;
  params: { crewId: string; mapDate: string; date: string };
  todayButtonLink: string;
}) {
  const dispatch = useDispatch();
  const { getUserSettings, setUserSettings } = useUserSettings();
  const crews = useApplicationStateSelector((s) => s.crew.crews);

  const maxDateForWeekPicker = addDays(
    startOfWeek(dateService.getMaximumDateForSchedule()),
    -1
  );

  const sortedCrews = getSortedCrews(crews).filter(
    (c) =>
      !c.inactive ||
      // Just in case the selected crew is inactive,
      getScheduleWeekSelectedCrews(crews, params).some(
        (selectedCrew) => selectedCrew.id === c.id
      )
  );

  const hasTimeBasedCrews = sortedCrews.some(
    (c) => c.scheduleType === CrewScheduleType.time
  );
  const hasSequenceBasedCrews = sortedCrews.some(
    (c) => c.scheduleType === CrewScheduleType.sequence
  );

  let allCrewsOption: JSX.Element | null = null;
  if (sortedCrews.length > 1 && !params.mapDate) {
    if (hasTimeBasedCrews && hasSequenceBasedCrews) {
      allCrewsOption = (
        <>
          <option value={constants.allCrewsSequenceConstant}>
            All Sequence Crews
          </option>
          <option value={constants.allCrewsTimeBasedConstant}>
            All Time-Based Crews
          </option>
        </>
      );
    } else {
      allCrewsOption = (
        <option value={constants.allCrewsConstant}>All Crews</option>
      );
    }
  }

  let crewSelectionValue = params.crewId;
  if (getIsCrewOptionMissingValue(crewSelectionValue, sortedCrews, params)) {
    const selectedCrew = getSelectedCrewId(
      {
        crewFilter: params.crewId,
      },
      sortedCrews
    );
    crewSelectionValue = selectedCrew;
  }
  return (
    <>
      <div style={{ display: "flex" }}>
        {sortedCrews.length > 1 ? (
          <select
            data-testid="crewSelect"
            aria-label="Schedule crew"
            value={crewSelectionValue}
            onChange={(e) => {
              const newCrewId = e.target.value;
              const newMapDate =
                newCrewId !== constants.allCrewsConstant
                  ? params.mapDate
                  : undefined;

              const crew = crews.find((c) => c.id === newCrewId);
              if (
                crew?.scheduleType === CrewScheduleType.time ||
                newCrewId === constants.allCrewsTimeBasedConstant ||
                (newCrewId === constants.allCrewsConstant &&
                  !hasSequenceBasedCrews)
              ) {
                setUserSettings(
                  UserSettingsType.timeBasedWeekDefaultCrew,
                  newCrewId
                );
                setUserSettings(
                  UserSettingsType.defaultCrewScheduleType,
                  CrewScheduleType.time
                );
                dispatch(
                  push(
                    builders.schedule.buildWeekRoute(
                      getUserSettings,
                      crews,
                      params.date,
                      newCrewId,
                      newMapDate
                    )
                  )
                );
              } else {
                setUserSettings(UserSettingsType.weekDefaultCrew, newCrewId);
                setUserSettings(
                  UserSettingsType.defaultCrewScheduleType,
                  CrewScheduleType.sequence
                );
                dispatch(
                  push(
                    builders.schedule.buildWeekRoute(
                      getUserSettings,
                      crews,
                      params.date,
                      newCrewId,
                      newMapDate
                    )
                  )
                );
              }
            }}
            className="form-control mr-3"
            style={{ width: "auto" }}
          >
            {allCrewsOption}
            {sortedCrews.map((c) => {
              return (
                <option value={c.id} key={c.id}>
                  {c.name}
                </option>
              );
            })}
          </select>
        ) : null}
        {params.mapDate ? (
          <DayPicker
            showIcon={true}
            inputId="weekScheduleDayPicker"
            testId="weekScheduleDayPicker"
            required={true}
            preventMobileView={true}
            inputStyle={{
              width: "135px",
            }}
            onDayPickerHide={() => {}}
            dayPickerProps={{}}
            value={format(params.date, datePickerFormat)}
            onDaySelected={(value) => {
              if (
                value &&
                dateService.removeTimeComponents(value) <=
                  dateService.removeTimeComponents(maxDateForWeekPicker)
              ) {
                dispatch(
                  push(
                    routingBuilders.schedule.buildSequenceWeekRoute(
                      dateService.formatAsIso(value),
                      getCurrentCrewId(selectedCrews),
                      dateService.formatAsIso(value)
                    )
                  )
                );
              }
            }}
            maxDate={maxDateForWeekPicker}
          />
        ) : (
          <WeekPicker
            showIcon={true}
            inputId="weekScheduleWeekPicker"
            testId="weekScheduleWeekPicker"
            required={true}
            preventMobileView={true}
            inputStyle={{
              width: "135px",
            }}
            value={format(getSelectedDateStartOfWeek(params), datePickerFormat)}
            onWeekSelected={(value) => {
              if (
                value &&
                dateService.removeTimeComponents(value) <=
                  dateService.removeTimeComponents(maxDateForWeekPicker)
              ) {
                dispatch(
                  push(
                    routingBuilders.schedule.buildSequenceWeekRoute(
                      dateService.formatAsIso(value),
                      getCurrentCrewId(selectedCrews)
                    )
                  )
                );
              }
            }}
            maxDate={maxDateForWeekPicker}
          />
        )}

        <TodayButton link={todayButtonLink} />
      </div>
    </>
  );
}
function getIsCrewOptionMissingValue(
  crewSelectionValue: string,
  sortedCrews: ICrew[],
  params: { crewId: string; mapDate: string; date: string }
) {
  return (
    crewSelectionValue !== constants.allCrewsConstant &&
    crewSelectionValue !== constants.allCrewsSequenceConstant &&
    crewSelectionValue !== constants.allCrewsTimeBasedConstant &&
    !sortedCrews.some((c) => c.id === params.crewId)
  );
}
