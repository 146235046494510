import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { CustomerProposalEmailConfigurationForm } from "./CustomerProposalEmailConfigurationForm";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";

export function CustomerProposalEmailConfigurationSummary() {
  const [showForm, setShowForm] = useState(false);
  const proposalEmailConfiguration = useApplicationStateSelector(
    (s) => s.common.proposalEmailConfiguration
  );

  return (
    <>
      <div
        className={"card"}
        style={{ marginBottom: "25px" }}
        data-testid="customerProposalEmailConfigurationSummary"
      >
        <div className={`card-body board-card-body board-card-v2 `}>
          <div className="d-flex justify-content-between mb-3">
            <h4
              className="card-title"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>Proposal Email</div>
            </h4>
            <button
              type="button"
              className="btn btn-primary"
              data-testid="editProposalEmail"
              onClick={() => {
                setShowForm(true);
              }}
            >
              <FontAwesomeIcon icon={faEdit} size="2x" />
            </button>
          </div>
          <div>
            <div className="form-group">
              <div className="d-md-flex justify-content">
                <div className="mr-3">Include details in proposal email</div>
                <div data-testid="includeDetailsValue">
                  <b>
                    {proposalEmailConfiguration.includeDetails ? "On" : "Off"}
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showForm ? (
        <CustomerProposalEmailConfigurationForm
          onSaveComplete={() => {
            setShowForm(false);
          }}
          onCancel={() => {
            setShowForm(false);
          }}
        />
      ) : null}
    </>
  );
}
