import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { IJobInstance } from "../../../models/IJobInstance";
import jobFinder from "../../../services/jobFinder";
import { getNameForJob } from "../../../services/jobService";

export function useGetJobNameFunction({
  fallbackToAddressIfAdditionalLocationNameNotSet,
}: {
  fallbackToAddressIfAdditionalLocationNameNotSet: boolean;
}) {
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const weeksUnscheduledMaintenanceJobs = useApplicationStateSelector(
    (s) => s.schedule.weeksUnscheduledMaintenanceJobs
  );
  const maintenanceJobs = useApplicationStateSelector((s) => s.job.jobs);
  const oneTimeJobs = useApplicationStateSelector((s) => s.job.oneTimeJobs);
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );

  return function getJobName(ji: IJobInstance) {
    const job = jobFinder.getJobForDayScheduleV3(
      ji.id,
      maintenanceJobs,
      oneTimeJobs,
      daySchedules,
      weeksUnscheduledMaintenanceJobs
    );

    let jobName = "";
    if (job) {
      jobName = getNameForJob({
        job,
        customers,
        customerAdditionalLocations,
        fallbackToAddressIfAdditionalLocationNameNotSet,
      });
    }
    return { jobName, job };
  };
}
