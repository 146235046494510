import { useRef, useState } from "react";
import dateService from "../../../services/dateService";
import TextareaAutosize from "react-autosize-textarea/lib";
import CurrencyInput from "./CurrencyInput";
import { KeyOfType } from "../../../keyofType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import DayPicker, { format as dayPickerFormat } from "../components/DayPicker";
import { format } from "date-fns";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";
import { useUserSettings } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import LinkButton2 from "./LinkButton2";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import NonQuickBooksInvoiceItemForm from "../forms/NonQuickBooksInvoiceItemForm";
import { IInvoiceItem } from "../../../models/IInvoiceItem";
import { fullStoryTrack } from "../../../services/fullStoryService";
import { TableDisplayType } from "../../../libraries/tableLayout/TableColumn";

export interface ILineItem {
  id: string;
  name: string | null;
  quantity: string;
  amountPerItem: string;
  description: string;
  itemId: string;
  taxable?: boolean | null;
  hide?: boolean | null;
  serviceDate?: string;
  optional?: boolean | null;
  selected?: boolean | null;
}

function updateLineItem<T>(
  onLineItemChanged: (updatedItem: ILineItem) => void,
  lineItem: ILineItem,
  propName: keyof ILineItem,
  propValue: T
) {
  onLineItemChanged({
    ...lineItem,
    [propName]: propValue,
  });
}

export function ServiceDateField({
  lineItem,
  onLineItemChanged,
  inputElementId,
}: {
  lineItem: ILineItem;
  onLineItemChanged: (updatedItem: ILineItem) => void;
  inputElementId: string;
}) {
  return (
    <>
      <DayPicker
        onDayPickerHide={() => null}
        inputClass="form-control"
        dayPickerProps={{}}
        required={false}
        inputId={inputElementId}
        preventMobileView={true}
        scrollWithoutLabel={true}
        value={
          !!lineItem.serviceDate
            ? format(lineItem.serviceDate, dayPickerFormat)
            : ""
        }
        onDaySelected={(day: Date) => {
          let value: string;
          if (!!day) {
            value = dateService.formatAsIso(day);
          } else {
            value = "";
          }
          updateLineItem(onLineItemChanged, lineItem, "serviceDate", value);
        }}
      />
    </>
  );
}

export function DescriptionField({
  lineItem,
  onLineItemChanged,
  inputElementId,
}: {
  lineItem: ILineItem;
  onLineItemChanged: (updatedItem: ILineItem) => void;
  inputElementId: string;
}) {
  return (
    <>
      <TextareaAutosize
        maxRows={10}
        id={inputElementId}
        data-testid={"productServiceDescription"}
        className="form-control"
        name="notes"
        value={lineItem.description}
        onChange={(e) =>
          updateLineItem(
            onLineItemChanged,
            lineItem,
            "description",
            e.currentTarget.value
          )
        }
      />
    </>
  );
}

export function QuantityField({
  lineItem,
  onLineItemChanged,
  inputElementId,
  allowBlankQuantity,
}: {
  lineItem: ILineItem;
  onLineItemChanged: (updatedItem: ILineItem) => void;
  inputElementId: string;
  allowBlankQuantity?: boolean;
}) {
  const lineItemQuantityElement = useRef<HTMLInputElement | null>(null);

  return (
    <>
      <input
        className="form-control"
        id={inputElementId}
        type="number"
        step="any"
        value={lineItem.quantity}
        ref={lineItemQuantityElement}
        onChange={(e) => {
          const value = e.currentTarget.value;
          updateLineItem(onLineItemChanged, lineItem, "quantity", value);
        }}
        required={allowBlankQuantity ? undefined : true}
        data-testid="quantity"
      />
    </>
  );
}

export function AmountPerItemField({
  lineItem,
  onLineItemChanged,
  inputElementId,
  allowBlankAmountPerItem,
}: {
  lineItem: ILineItem;
  onLineItemChanged: (updatedItem: ILineItem) => void;
  inputElementId: string;
  allowBlankAmountPerItem?: boolean;
}) {
  return (
    <>
      <CurrencyInput
        className="form-control"
        id={inputElementId}
        value={lineItem.amountPerItem}
        onValueChange={(newValue) =>
          updateLineItem(onLineItemChanged, lineItem, "amountPerItem", newValue)
        }
        required={allowBlankAmountPerItem ? undefined : true}
      />
    </>
  );
}

export function TaxableField({
  onLineItemChanged,
  lineItem,
  inputElementId,
  checkboxClassName,
  defaultTaxable,
  items,
  onItemUpdated,
}: {
  onLineItemChanged: (updatedItem: ILineItem) => void;
  lineItem: ILineItem;
  inputElementId: string;
  checkboxClassName?: string;
  defaultTaxable?: boolean;
  items: Array<IInvoiceItem> | null;
  onItemUpdated: (args: {
    id: string;
    name: string;
    description: string;
    inactive: boolean;
    unitPrice: number | null;
    taxable: boolean;
  }) => void;
}) {
  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );
  const [showPopover, setShowPopover] = useState(false);
  const [nonQuickBooksItemEditing, setNonQuickBooksItemEditing] = useState<
    string | null
  >(null);

  const { getUserSettings, setUserSettings } = useUserSettings();

  return (
    <>
      <CheckboxField
        property={"taxable"}
        inputElementId={inputElementId}
        lineItem={lineItem}
        onLineItemChanged={(newValue) => {
          if (
            !getUserSettings(UserSettingsType.taxableTooltipShown) &&
            !isQuickBooksEnabled &&
            typeof defaultTaxable === "boolean" &&
            defaultTaxable !== newValue.taxable &&
            typeof lineItem.itemId === "string"
          ) {
            setUserSettings(UserSettingsType.taxableTooltipShown, "true");
            setShowPopover(true);

            fullStoryTrack("Invoice form Taxable Popover Shown");
          }

          onLineItemChanged(newValue);
        }}
        checkboxClassName={checkboxClassName}
        testId="taxableCheckbox"
      />
      <Popover
        delay={0}
        trigger="legacy"
        placement="bottom"
        isOpen={showPopover}
        target={inputElementId}
        toggle={() => {
          setShowPopover(false);
        }}
      >
        <PopoverBody>
          <div>
            Change whether your is item is taxable by default{" "}
            <LinkButton2
              buttonContents={"here"}
              onClick={() => {
                setShowPopover(false);
                setNonQuickBooksItemEditing(lineItem.itemId);
              }}
              testId="editLineItemFromTaxablePopover"
              style={{
                textDecoration: "underline",
                verticalAlign: "baseline",
                fontSize: ".875rem",
                // pointerEvents is needed to override popover behavior
                pointerEvents: "visible",
              }}
            />
            .
          </div>
          <div>In the future, use 'Edit line item'.</div>
        </PopoverBody>
      </Popover>

      {items && nonQuickBooksItemEditing ? (
        <NonQuickBooksInvoiceItemForm
          mode="edit"
          invoiceItemId={nonQuickBooksItemEditing}
          onSaveComplete={({
            name: newName,
            description: newDescription,
            inactive: newInactive,
            unitPrice: newUnitPrice,
            taxable: newTaxable,
          }) => {
            setNonQuickBooksItemEditing(null);
            onItemUpdated({
              id: nonQuickBooksItemEditing,
              name: newName.trim(),
              description: newDescription.trim(),
              inactive: newInactive,
              unitPrice: newUnitPrice,
              taxable: newTaxable,
            });
          }}
          onCancel={() => setNonQuickBooksItemEditing(null)}
        />
      ) : null}
    </>
  );
}

export function CheckboxField({
  onLineItemChanged,
  lineItem,
  property,
  inputElementId,
  checkboxClassName,
  testId,
  disabled,
}: {
  onLineItemChanged: (updatedItem: ILineItem) => void;
  lineItem: ILineItem;
  property: KeyOfType<ILineItem, boolean | null | undefined>;
  inputElementId: string;
  checkboxClassName?: string;
  testId?: string;
  disabled?: boolean;
}) {
  return (
    <>
      <div className="custom-control custom-checkbox mr-sm-2 ml-2">
        <input
          id={inputElementId}
          type="checkbox"
          className={"custom-control-input " + (checkboxClassName ?? "")}
          checked={lineItem[property] ?? false}
          onChange={(e) => {
            updateLineItem(
              onLineItemChanged,
              lineItem,
              property,
              e.target.checked
            );
          }}
          data-testid={testId}
          disabled={disabled}
        />
        <label htmlFor={inputElementId} className="custom-control-label">
          <span className="sr-only"></span>
        </label>
      </div>
    </>
  );
}

export function DeleteLineItemButton({
  lineItem,
  lineItems,
  setLineItems,
  displayType,
}: {
  lineItem: ILineItem;
  lineItems: Array<ILineItem>;
  setLineItems: (newLineItems: Array<ILineItem>) => void;
  displayType: TableDisplayType;
}) {
  return (
    <button
      type="button"
      className={`btn pt-0 ${displayType === "mobile" ? "pr-0" : ""}`}
      onClick={() =>
        setLineItems(lineItems.filter((l) => l.id !== lineItem.id))
      }
      data-testid="removeLineItem"
    >
      <FontAwesomeIcon icon={faTrash} title="Remove line item" />
    </button>
  );
}
