import { DragDropContext } from "react-beautiful-dnd";
import constants from "../../../constants";
import { ScheduleTimeCalendar } from "./ScheduleTimeCalendar";
import { ScheduleHeader } from "./ScheduleHeader";
import { ScheduleSectionList } from "./ScheduleSectionList";
import { IScheduleColumnHeader } from "../../../containers/app/components/schedule";
import { IScheduleRow } from "./types/IScheduleRow";
import ErrorLoadingSchedule from "../../../containers/app/components/schedule/ErrorLoadingSchedule";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import Spinner from "../../../containers/app/components/Spinner";
import JobsMap from "../../../containers/app/components/JobsMap";
import UnscheduledJobs from "../../../containers/app/components/schedule/UnscheduledJobs";
import {
  useEffect,
  useReducer,
  useState,
  memo,
  useCallback,
  useRef,
  MouseEventHandler,
} from "react";
import { initialCalendarBlockHeightInPxs } from "./Calendar";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { IJobInstance } from "../../../models/IJobInstance";
import parse from "date-fns/parse";
import {
  getVisibleRows,
  useGetMovingJobInstanceWithAdjustedTimes,
} from "./ScheduleTimePage.functions";
import SelectedJobActions from "../../../containers/app/components/schedule/SelectedJobActions";
import {
  JobInstanceBeingMoved,
  FocusedTimeSlot,
} from "./ScheduleTimePage.types";
import {
  isClearAllCardsPrevented,
  preventClearingAllCards,
} from "../../../services/selectedCardService";

// Memoizing component so it rerenders only when the job instance being moved is moved
// to another slot rather than whenever the mouse coordinates move.
const MemoizedComponent = memo(function ScheduleTimePageMemoized({
  pageHeaderText,
  onNextSchedule,
  onPreviousSchedule,
  currentWeekDate,
  changeScheduleDate,
  columnHeaders,
  rows,
  onRowReorder,
  onRowExpanded,
  mode,
  isLoading,
  isLoadingFlexibleJobs,
  weekForUnscheduledJobs,
  calendarBlockHeightInPxs,
  setCalendarBlockHeightInPxs,
  startMove,
  jobInstanceBeingMoved,
  isAnyJobMoveInProgress,
  setFocusedTimeSlot,
  hasJobMovedPosition,
}: {
  pageHeaderText: JSX.Element;
  onNextSchedule: () => void;
  onPreviousSchedule: () => void;
  changeScheduleDate: (newDate: Date) => void;
  currentWeekDate: string;
  columnHeaders: Array<IScheduleColumnHeader>;
  rows: Array<IScheduleRow>;
  onRowReorder?: (sourceIndex: number, destinationIndex: number) => void;
  onRowExpanded: (rowIndex: number) => void;
  mode: "week" | "day";
  isLoading: boolean;
  isLoadingFlexibleJobs: boolean;
  weekForUnscheduledJobs: Date;
  calendarBlockHeightInPxs: number;
  setCalendarBlockHeightInPxs: React.Dispatch<React.SetStateAction<number>>;
  startMove: (jobInstanceId: string, blockOffset: number) => void;
  jobInstanceBeingMoved: JobInstanceBeingMoved;
  isAnyJobMoveInProgress: boolean;
  setFocusedTimeSlot: (arg: FocusedTimeSlot) => void;
  hasJobMovedPosition: boolean;
}) {
  const dispatch = useDispatch();
  const selectedJobInstances = useApplicationStateSelector(
    (s) => s.scheduleUi.selectedJobInstanceIds
  );
  const [jobInstanceShowingDetails, setJobInstanceShowingDetails] = useState<
    string | null
  >(null);

  // Close details when a drag starts. It can interfere with the job card positioning.
  useEffect(() => {
    if (isAnyJobMoveInProgress) {
      setJobInstanceShowingDetails(null);
    }
  }, [isAnyJobMoveInProgress]);

  const isMapVisible = rows.some((r) => r.columns.some((c) => c?.isMapOpen));
  ({ rows, columnHeaders } = getVisibleRows(isMapVisible, rows, columnHeaders));

  const errorLoading = rows.some((r) => r.columns.some((c) => c?.errorLoading));
  if (errorLoading) {
    return (
      <ErrorLoadingSchedule
        onRefreshSchedule={() => {
          dispatch(actionCreators.loadDaySchedulesErrorClear());
        }}
      />
    );
  }

  const columnLoading = rows.some((r) => r.columns.some((c) => c?.loading));

  const showMap = isMapVisible && rows[0].columns[0];
  const movingJobInstanceId =
    jobInstanceBeingMoved !== null
      ? jobInstanceBeingMoved.jobInstance.id
      : null;

  const isLoadingSpinnerShowing = isLoading || columnLoading;
  return (
    <>
      {isLoadingSpinnerShowing ? <Spinner /> : null}
      <div className={isLoadingSpinnerShowing ? "invisible" : ""}>
        <DragDropContext
          onDragEnd={(result) => {
            if (result.type === constants.droppableTypeRow) {
              if (onRowReorder && result.destination) {
                onRowReorder(result.source.index, result.destination.index);
              }
            }
          }}
        >
          <ScheduleHeader
            pageHeaderText={pageHeaderText}
            previousSchedule={onPreviousSchedule}
            nextSchedule={onNextSchedule}
            unscheduledJobsElement={
              <UnscheduledJobsWrapper
                startMove={startMove}
                weekForUnscheduledJobs={weekForUnscheduledJobs}
                isLoadingFlexibleJobs={isLoadingFlexibleJobs}
                jobInstanceBeingDraggedFromTimeCalendar={
                  jobInstanceBeingMoved?.type === "FlexibleContainer"
                    ? jobInstanceBeingMoved.jobInstance
                    : null
                }
                onMouseOver={() => {
                  setFocusedTimeSlot({
                    type: "FlexibleContainer",
                  });
                }}
              />
            }
            changeScheduleDate={changeScheduleDate}
            baseDate={currentWeekDate}
          />

          <div className={showMap ? "d-flex" : ""} style={{ gap: "15px" }}>
            {rows.length > 0 ? (
              <ScheduleSectionList
                rows={rows}
                columnHeaders={[]}
                onRowExpanded={onRowExpanded}
                customContainerClassName={""}
                isCalendarHeader={true}
                renderRow={(scheduleRow) => (
                  <>
                    <ScheduleTimeCalendar
                      calendarBlockHeightInPxs={calendarBlockHeightInPxs}
                      setCalendarBlockHeightInPxs={setCalendarBlockHeightInPxs}
                      columns={scheduleRow.columns}
                      columnHeaders={columnHeaders}
                      mode={mode}
                      movingJobInstanceId={movingJobInstanceId}
                      isAnyJobMoveInProgress={isAnyJobMoveInProgress}
                      onStartMove={startMove}
                      jobInstanceShowingDetails={jobInstanceShowingDetails}
                      setJobInstanceShowingDetails={
                        setJobInstanceShowingDetails
                      }
                      hasJobMovedPosition={hasJobMovedPosition}
                      draggingJobInstance={
                        jobInstanceBeingMoved?.type !== "FlexibleContainer"
                          ? jobInstanceBeingMoved
                          : null
                      }
                      onTimeSlotFocus={(dayScheduleId, hour, minute) => {
                        setFocusedTimeSlot({
                          type: "ScheduleTimeSlot",
                          dayScheduleId,
                          hour,
                          minute,
                        });
                      }}
                    />
                  </>
                )}
                renderRowlessSchedule={() => (
                  <ScheduleTimeCalendar
                    calendarBlockHeightInPxs={calendarBlockHeightInPxs}
                    setCalendarBlockHeightInPxs={setCalendarBlockHeightInPxs}
                    columns={rows[0].columns}
                    columnHeaders={columnHeaders}
                    mode={mode}
                    movingJobInstanceId={movingJobInstanceId}
                    isAnyJobMoveInProgress={isAnyJobMoveInProgress}
                    onStartMove={startMove}
                    jobInstanceShowingDetails={jobInstanceShowingDetails}
                    setJobInstanceShowingDetails={setJobInstanceShowingDetails}
                    hasJobMovedPosition={hasJobMovedPosition}
                    draggingJobInstance={
                      jobInstanceBeingMoved?.type !== "FlexibleContainer"
                        ? jobInstanceBeingMoved
                        : null
                    }
                    onTimeSlotFocus={(dayScheduleId, hour, minute) => {
                      setFocusedTimeSlot({
                        type: "ScheduleTimeSlot",
                        dayScheduleId,
                        hour,
                        minute,
                      });
                    }}
                  />
                )}
              />
            ) : null}

            {showMap && rows[0].columns[0] ? (
              <div style={{ width: "100%" }}>
                <JobsMap
                  dayScheduleId={rows[0].columns[0].dayScheduleId}
                  closeMapUrl={rows[0].columns[0].mapLinkProps.url}
                />
              </div>
            ) : null}
          </div>
        </DragDropContext>
        {selectedJobInstances.length > 0 ? <SelectedJobActions /> : null}
      </div>
    </>
  );
});

export function ScheduleTimePage({
  pageHeaderText,
  onNextSchedule,
  onPreviousSchedule,
  currentWeekDate,
  changeScheduleDate,
  columnHeaders,
  rows,
  onRowReorder,
  onRowExpanded,
  mode,
  isLoading,
  isLoadingFlexibleJobs,
  weekForUnscheduledJobs,
}: {
  pageHeaderText: JSX.Element;
  onNextSchedule: () => void;
  onPreviousSchedule: () => void;
  changeScheduleDate: (newDate: Date) => void;
  currentWeekDate: string;
  columnHeaders: Array<IScheduleColumnHeader>;
  rows: Array<IScheduleRow>;
  onRowReorder?: (sourceIndex: number, destinationIndex: number) => void;
  onRowExpanded: (rowIndex: number) => void;
  mode: "week" | "day";
  isLoading: boolean;
  isLoadingFlexibleJobs: boolean;
  weekForUnscheduledJobs: Date;
}) {
  const [calendarBlockHeightInPxs, setCalendarBlockHeightInPxs] = useState(
    initialCalendarBlockHeightInPxs
  );

  const [focusedTimeSlot, setFocusedTimeSlot] = useState<FocusedTimeSlot>(null);

  const {
    startMove,
    movingJobInstanceId,
    moving: isAnyJobMoveInProgress,
    blockOffset,
    hasJobMovedPosition,
  } = useHandleMoving({
    focusedTimeSlot,
    setFocusedTimeSlot,
    rows,
    weekForUnscheduledJobs,
  });

  const jobInstanceBeingMoved = useGetMovingJobInstanceWithAdjustedTimes({
    movingJobInstanceId,
    rows,
    focusedTimeSlot,
    blockOffset,
  });

  useUpdateCursor(isAnyJobMoveInProgress);

  const setFocusedTimeSlotWithCheck = useCallback(
    (newFocusedTimeSlot: FocusedTimeSlot) => {
      if (isAnyJobMoveInProgress) {
        setFocusedTimeSlot(newFocusedTimeSlot);
      }
    },
    [isAnyJobMoveInProgress]
  );

  useClearSelectedJobsOnClick();

  return (
    <MemoizedComponent
      pageHeaderText={pageHeaderText}
      onNextSchedule={onNextSchedule}
      onPreviousSchedule={onPreviousSchedule}
      changeScheduleDate={changeScheduleDate}
      currentWeekDate={currentWeekDate}
      columnHeaders={columnHeaders}
      rows={rows}
      onRowExpanded={onRowExpanded}
      onRowReorder={onRowReorder}
      mode={mode}
      isLoading={isLoading}
      isLoadingFlexibleJobs={isLoadingFlexibleJobs}
      weekForUnscheduledJobs={weekForUnscheduledJobs}
      calendarBlockHeightInPxs={calendarBlockHeightInPxs}
      setCalendarBlockHeightInPxs={setCalendarBlockHeightInPxs}
      startMove={startMove}
      jobInstanceBeingMoved={jobInstanceBeingMoved}
      isAnyJobMoveInProgress={isAnyJobMoveInProgress}
      setFocusedTimeSlot={setFocusedTimeSlotWithCheck}
      hasJobMovedPosition={hasJobMovedPosition}
    />
  );
}

function UnscheduledJobsWrapper({
  startMove,
  weekForUnscheduledJobs,
  isLoadingFlexibleJobs,
  jobInstanceBeingDraggedFromTimeCalendar,
  onMouseOver,
}: {
  startMove: (jobInstanceId: string, blockOffset: number) => void;
  weekForUnscheduledJobs: Date;
  isLoadingFlexibleJobs: boolean;
  jobInstanceBeingDraggedFromTimeCalendar: IJobInstance | null;
  onMouseOver: MouseEventHandler<HTMLDivElement>;
}) {
  return (
    <div onMouseOver={onMouseOver} data-testid="unscheduledJobsWrapper">
      <UnscheduledJobs
        weekForUnscheduledJobs={weekForUnscheduledJobs}
        loadingFlexibleJobs={isLoadingFlexibleJobs}
        jobInstanceBeingDraggedFromTimeCalendar={
          jobInstanceBeingDraggedFromTimeCalendar
        }
        renderJobCardWithWrapper={({
          element,
          jobInstance,
          onJobCardMultiSelected,
        }) => (
          <UnscheduledJobCardWrapper
            jobInstance={jobInstance}
            onStartMove={startMove}
            onJobCardMultiSelected={onJobCardMultiSelected}
          >
            {element}
          </UnscheduledJobCardWrapper>
        )}
      />
    </div>
  );
}

function UnscheduledJobCardWrapper({
  children,
  jobInstance,
  onStartMove,
  onJobCardMultiSelected,
}: {
  children: JSX.Element;
  jobInstance: IJobInstance;
  onStartMove: (jobInstanceId: string, blockOffset: number) => void;
  onJobCardMultiSelected?: (jobInstanceId: string) => void;
}) {
  const dispatch = useDispatch();
  const selectedJobInstanceIds = useApplicationStateSelector(
    (s) => s.scheduleUi.selectedJobInstanceIds
  );

  return (
    <div
      className="draggable-card"
      data-testid="unscheduledJobWrapper"
      onMouseDown={(e) => {
        onStartMove(jobInstance.id, 0);
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        preventClearingAllCards(e);
        dispatch(
          actionCreators.jobInstanceToggleSelected(
            [jobInstance.id],
            undefined,
            true
          )
        );

        const cardSelected = !selectedJobInstanceIds.includes(jobInstance.id);
        if (e.shiftKey && onJobCardMultiSelected && cardSelected) {
          onJobCardMultiSelected(jobInstance.id);
        }
      }}
      // Prevent default drag start. This causes issues when dragging links on the job card
      // where our drag system doesn't start until mouse is raised up.
      onDragStart={(e) => e.preventDefault()}
      style={{
        userSelect: "none",
        width: "150px",
        marginRight: "15px",
        marginBottom: "15px",
      }}
    >
      {children}
    </div>
  );
}

function useUpdateCursor(moving: boolean) {
  useEffect(() => {
    if (moving) {
      document.body.style.cursor = "grabbing";
    } else {
      document.body.style.cursor = "default";
    }
  }, [moving]);
}

type UseHandleMovingReducerActionState = {
  movingJobInstanceId: string | null;
  blockOffset: number | null;
};
type UseHandleMovingReducerAction =
  | {
      type: "StartMoving";
      jobInstanceId: string;
      blockOffset: number;
    }
  | { type: "EndMoving" };

function UseHandleMovingReducer(
  state: UseHandleMovingReducerActionState,
  action: UseHandleMovingReducerAction
): UseHandleMovingReducerActionState {
  switch (action.type) {
    case "StartMoving":
      return {
        ...state,
        movingJobInstanceId: action.jobInstanceId,
        blockOffset: action.blockOffset,
      };
    case "EndMoving":
      return typeof state.movingJobInstanceId === "string"
        ? {
            ...state,
            movingJobInstanceId: null,
          }
        : state;
    default:
      return {} as never;
  }
}

function useHandleMoving({
  rows,
  focusedTimeSlot,
  setFocusedTimeSlot,
  weekForUnscheduledJobs,
}: {
  rows: IScheduleRow[];
  focusedTimeSlot: FocusedTimeSlot;
  setFocusedTimeSlot: React.Dispatch<React.SetStateAction<FocusedTimeSlot>>;
  weekForUnscheduledJobs: Date;
}) {
  const dispatchRedux = useDispatch();
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const weeksUnscheduledMaintenanceJobs = useApplicationStateSelector(
    (s) => s.schedule.weeksUnscheduledMaintenanceJobs
  );

  const [{ movingJobInstanceId, blockOffset }, dispatchMoveAction] = useReducer(
    UseHandleMovingReducer,
    {
      movingJobInstanceId: null,
      blockOffset: null,
    }
  );

  const [hasJobMovedPosition, setHasJobMovedPosition] = useState(false);

  useEffect(() => {
    function onMouseUp() {
      dispatchMoveAction({
        type: "EndMoving",
      });
    }

    window.addEventListener("mouseup", onMouseUp);

    return function cleanup() {
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  const startMoving = useCallback(
    (jobInstanceId: string, blockOffset: number) => {
      setFocusedTimeSlot(null);
      setHasJobMovedPosition(false);
      dispatchMoveAction({
        type: "StartMoving",
        jobInstanceId,
        blockOffset,
      });
    },
    [setFocusedTimeSlot]
  );

  // Handle dropped jobs
  const previousMovingJobInstanceId = useRef<string | null>(null);
  const jobInstanceBeingMoved = useGetMovingJobInstanceWithAdjustedTimes({
    movingJobInstanceId: previousMovingJobInstanceId.current,
    rows,
    focusedTimeSlot,
    blockOffset,
  });

  useEffect(() => {
    if (
      typeof previousMovingJobInstanceId.current === "string" &&
      movingJobInstanceId === null
    ) {
      if (jobInstanceBeingMoved) {
        if (jobInstanceBeingMoved.type === "ScheduleTimeSlot") {
          dispatchRedux(
            actionCreators.dropJob(
              [previousMovingJobInstanceId.current],
              jobInstanceBeingMoved.daySchedule.id,
              false,
              null,
              constants.idForFirstJob,
              parse(jobInstanceBeingMoved.daySchedule.date),
              jobInstanceBeingMoved.daySchedule.crewId,
              [],
              [
                {
                  jobInstanceId: jobInstanceBeingMoved.jobInstance.id,
                  startTime: jobInstanceBeingMoved.jobInstance.startTime,
                  endTime: jobInstanceBeingMoved.jobInstance.endTime,
                },
              ]
            )
          );
        } else if (jobInstanceBeingMoved.type === "FlexibleContainer") {
          dispatchRedux(
            actionCreators.dropJob(
              [previousMovingJobInstanceId.current],
              null,
              true,
              weekForUnscheduledJobs,
              null,
              null,
              null,
              [],
              []
            )
          );
        }
      }
    }

    if (jobInstanceBeingMoved) {
      setHasJobMovedPosition(true);
    }

    previousMovingJobInstanceId.current = movingJobInstanceId;
  }, [
    rows,
    movingJobInstanceId,
    daySchedules,
    weeksUnscheduledMaintenanceJobs,
    jobInstanceBeingMoved,
    dispatchRedux,
    weekForUnscheduledJobs,
  ]);

  return {
    moving: movingJobInstanceId !== null,
    movingJobInstanceId,
    blockOffset: blockOffset,
    startMove: startMoving,
    hasJobMovedPosition,
  };
}

function useClearSelectedJobsOnClick() {
  const dispatch = useDispatch();
  const selectedJobInstanceIds = useApplicationStateSelector(
    (s) => s.scheduleUi.selectedJobInstanceIds
  );

  useEffect(() => {
    function clearSelectedJobCards(e: MouseEvent) {
      // Clear selected job cards if user clicked on an item that wouldn't toggle card selection
      // or is the action bar
      if (
        selectedJobInstanceIds.length > 0 &&
        e.target &&
        !isClearAllCardsPrevented(e)
      ) {
        dispatch(
          actionCreators.jobInstanceToggleSelected(
            selectedJobInstanceIds,
            false
          )
        );
      }
    }

    document.addEventListener("click", clearSelectedJobCards);

    return function cleanup() {
      document.removeEventListener("click", clearSelectedJobCards);
    };
  }, [dispatch, selectedJobInstanceIds]);
}
