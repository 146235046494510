import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";
import LinkButton2 from "../../../containers/app/components/LinkButton2";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { JobType } from "../../../models/IJob";
import {
  BatchIneligibleReasonType,
  IWorkNotInvoicedCustomer,
  IWorkNotInvoicedJob,
} from "../../../models/IWorkNotInvoicedCustomer";
import { actionCreators } from "../../../modules/actionCreators";
import { fullStoryTrack } from "../../../services/fullStoryService";
import LinkQuickBooksCustomerForm from "../../customer/components/LinkQuickBooksCustomerForm";

const BillingWorkNotInvoicedInfo: React.FunctionComponent<{
  job: IWorkNotInvoicedJob;
  customer: IWorkNotInvoicedCustomer;
  onSaveComplete(): void;
}> = ({ job, customer, onSaveComplete }) => {
  const [showPopover, setShowPopover] = useState(false);
  const [showQuickBooksLinkForm, setShowQuickBooksLinkForm] = useState(false);
  const oneTimeJobFormSaveCount = useApplicationStateSelector(
    (s) => s.forms.oneTimeJob.saveCount
  );
  const lastOneTimeJobFormSaveCount = useRef(oneTimeJobFormSaveCount);
  const maintenanceJobFormSaveCount = useApplicationStateSelector(
    (s) => s.forms.maintenanceJob.saveCount
  );
  const lastMaintenanceJobFormSaveCount = useRef(maintenanceJobFormSaveCount);
  const customerFormSaveCount = useApplicationStateSelector(
    (s) => s.forms.customer.saveCount
  );
  const lastCustomerFormSaveCount = useRef(customerFormSaveCount);

  const dispatch = useDispatch();
  const iconId = `batchIneligiableInfo_${job.id}`;

  useEffect(() => {
    if (
      oneTimeJobFormSaveCount !== lastOneTimeJobFormSaveCount.current ||
      maintenanceJobFormSaveCount !== lastMaintenanceJobFormSaveCount.current ||
      customerFormSaveCount !== lastCustomerFormSaveCount.current
    ) {
      onSaveComplete();

      lastOneTimeJobFormSaveCount.current = oneTimeJobFormSaveCount;
      lastMaintenanceJobFormSaveCount.current = maintenanceJobFormSaveCount;
      lastCustomerFormSaveCount.current = customerFormSaveCount;
    }
  }, [
    customerFormSaveCount,
    maintenanceJobFormSaveCount,
    oneTimeJobFormSaveCount,
    onSaveComplete,
  ]);

  function showFixFormFromType(type: BatchIneligibleReasonType) {
    setShowPopover(!showPopover);
    switch (type) {
      case BatchIneligibleReasonType.lineItems:
      case BatchIneligibleReasonType.totalLessThanMinimumAmount:
        if (job.jobType === JobType.oneTimeJob) {
          dispatch(
            actionCreators.forms.oneTimeJob.showForm({
              oneTimeJobId: job.id,
            })
          );
        } else {
          dispatch(
            actionCreators.forms.maintenanceJob.showForm({
              maintenanceJobId: job.id,
            })
          );
        }
        fullStoryTrack("Work Not Invoiced Set Line Items Clicked");
        break;
      case BatchIneligibleReasonType.customerEmail:
        dispatch(
          actionCreators.forms.customer.showForm({
            customerId: customer.id,
          })
        );
        fullStoryTrack("Work Not Invoiced Set Customer Email Clicked");
        break;
      case BatchIneligibleReasonType.quickBooksCustomer:
        setShowQuickBooksLinkForm(true);
        fullStoryTrack("Work Not Invoiced Set QuickBooks Customer Clicked");
        break;
    }
  }

  function getDescriptionFromType(type: BatchIneligibleReasonType) {
    switch (type) {
      case BatchIneligibleReasonType.lineItems:
        return "Line items not set on job.";
      case BatchIneligibleReasonType.customerEmail:
        return "Customer has no email address.";
      case BatchIneligibleReasonType.quickBooksCustomer:
        return "QuickBooks customer not linked.";
      case BatchIneligibleReasonType.depositExists:
        return "This job has a deposit.";
      case BatchIneligibleReasonType.totalLessThanMinimumAmount:
        // Note: This amount is defined on Constants.cs in server.
        return "This job is less than the minimum invoice amount of $1.00.";
    }
  }

  return (
    <>
      {(job.batchIneligibleReasons?.length ?? 0) > 0 ? (
        <>
          <FontAwesomeIcon
            id={iconId}
            data-testid={iconId}
            icon={faExclamationCircle}
            style={{ cursor: "pointer" }}
          />

          <Popover
            delay={0}
            trigger="legacy"
            placement="bottom"
            isOpen={showPopover}
            target={iconId}
            toggle={() => {
              setShowPopover(!showPopover);
            }}
          >
            <PopoverBody>
              {"Batch invoicing disabled due to: "}
              <ul>
                {job.batchIneligibleReasons?.map(function (type) {
                  return (
                    <li key={type}>
                      {getDescriptionFromType(type) + " "}

                      {type !== BatchIneligibleReasonType.depositExists ? (
                        <LinkButton2
                          buttonContents={"Fix it!"}
                          inlineButton
                          onClick={() => {
                            showFixFormFromType(type);
                          }}
                        ></LinkButton2>
                      ) : null}
                    </li>
                  );
                })}
              </ul>
            </PopoverBody>
          </Popover>
          {showQuickBooksLinkForm ? (
            <LinkQuickBooksCustomerForm
              customerId={customer.id}
              customerName={customer.name}
              onSaveComplete={onSaveComplete}
              onCancel={() => {
                setShowQuickBooksLinkForm(false);
              }}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default BillingWorkNotInvoicedInfo;
