import { Link } from "react-router-dom";

interface IParams {
  link: string;
}

const TodayButton: React.FunctionComponent<IParams> = ({ link }) => {
  return (
    <div style={{ display: "flex", marginLeft: "15px" }}>
      <Link
        data-testid="calendar-today-button"
        className="btn btn-secondary"
        to={link}
      >
        Today
      </Link>
    </div>
  );
};

export default TodayButton;
