import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { actionCreators } from "../../../modules/actionCreators";
import { useDispatch } from "react-redux";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { ScheduledCommunication } from "../../../enums/scheduledCommunication";
import PageWithNavBar2 from "../../../containers/app/PageWithNavBar2";
import CustomerCommunicationSettingsReminderSection, {
  ConfigurationType,
} from "./CustomerCommunicationSettingsReminderSection";
import CustomerCommunicationRemindersForm from "./CustomerCommunicationRemindersForm";
import useSalesEnabled from "../../sales/hooks/useSalesEnabled";
import { CustomerProposalEmailConfigurationSummary } from "../../sales/components/CustomerProposalEmailConfigurationSummary";

const CustomerCommunicationSettingsPage: React.FunctionComponent<{}> = () => {
  const salesEnabled = useSalesEnabled();
  const CustomerNotificationsConfiguration = useApplicationStateSelector(
    (s) => s.common.customerNotificationsConfiguration
  );
  const ProposalBeforeReminderConfiguration = useApplicationStateSelector(
    (s) => s.common.proposalBeforeReminderConfiguration
  );
  const ProposalAfterReminderConfiguration = useApplicationStateSelector(
    (s) => s.common.proposalAfterReminderConfiguration
  );
  const InvoiceBeforeReminderConfiguration = useApplicationStateSelector(
    (s) => s.common.invoiceBeforeReminderConfiguration
  );
  const InvoiceAfterReminderConfiguration = useApplicationStateSelector(
    (s) => s.common.invoiceAfterReminderConfiguration
  );

  const [showRemindersForm, setShowRemindersForm] = useState(false);

  const dispatch = useDispatch();

  return (
    <PageWithNavBar2 notFluid={true} billingContext>
      <h1 style={{ marginTop: "20px", marginBottom: "20px" }}>
        Customer Communication
      </h1>

      {salesEnabled ? <CustomerProposalEmailConfigurationSummary /> : null}

      <div
        className={"card"}
        style={{ marginBottom: "25px" }}
        data-testid="card"
      >
        <div className={`card-body board-card-body board-card-v2 `}>
          <div className="d-flex justify-content-between mb-3">
            <h4
              className="card-title"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>Visit Notifications</div>
            </h4>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                dispatch(
                  actionCreators.forms.customerNotificationsConfiguration.showForm(
                    {}
                  )
                );
              }}
            >
              <FontAwesomeIcon icon={faEdit} size="2x" />
            </button>
          </div>
          <div>
            <div className="form-group">
              <div className="d-md-flex justify-content">
                <div className="mr-3">Pre-visit notification</div>
                <div data-testid="preVisitValue">
                  <b>
                    {CustomerNotificationsConfiguration.sendPreVisitNotification !==
                    ScheduledCommunication.Never
                      ? "On"
                      : "Off"}
                  </b>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="d-md-flex justify-content">
                <div className="mr-3">Visit complete notification</div>
                <div data-testid="visitCompletedValue">
                  <b>
                    {CustomerNotificationsConfiguration.sendCompleteNotification
                      ? "On"
                      : "Off"}
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={"card"}
        style={{ marginBottom: "25px" }}
        data-testid="card"
      >
        <div className={`card-body board-card-body board-card-v2 `}>
          <div className="d-flex justify-content-between mb-3">
            <h4
              className="card-title"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <div>Sales & Billing Reminders</div>
            </h4>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setShowRemindersForm(true);
              }}
            >
              <FontAwesomeIcon icon={faEdit} size="2x" />
            </button>
          </div>
          <div>
            {salesEnabled ? (
              <>
                <CustomerCommunicationSettingsReminderSection
                  type={ConfigurationType.ProposalBefore}
                  reminderConfiguration={ProposalBeforeReminderConfiguration}
                />
                <CustomerCommunicationSettingsReminderSection
                  type={ConfigurationType.ProposalAfter}
                  reminderConfiguration={ProposalAfterReminderConfiguration}
                />
              </>
            ) : null}
            <CustomerCommunicationSettingsReminderSection
              type={ConfigurationType.InvoiceBefore}
              reminderConfiguration={InvoiceBeforeReminderConfiguration}
            />
            <CustomerCommunicationSettingsReminderSection
              type={ConfigurationType.InvoiceAfter}
              reminderConfiguration={InvoiceAfterReminderConfiguration}
            />
          </div>
        </div>
      </div>
      {showRemindersForm ? (
        <CustomerCommunicationRemindersForm
          onCancel={() => {
            setShowRemindersForm(false);
          }}
          onSaveComplete={() => {
            setShowRemindersForm(false);
          }}
        />
      ) : null}
    </PageWithNavBar2>
  );
};

export default CustomerCommunicationSettingsPage;
