import React from "react";

interface IProps {
  visible: boolean;
}

const JobInstanceCategoriesContainer: React.FunctionComponent<IProps> = ({
  visible,
  children,
}) => {
  return (
    <div
      className={"category-and-indicators " + (visible ? "" : "d-none")}
      style={{ display: "flex", flexWrap: "wrap" }}
    >
      {children}
    </div>
  );
};

export default JobInstanceCategoriesContainer;
