import { IInvoiceView } from "../../../models/IInvoice";
import React, { useEffect, useState } from "react";
import BillingInvoiceListPaymentsList, {
  PaymentFields,
  PaymentHeader,
} from "./BillingInvoiceListPaymentsList";
import { IInvoicePaymentParameters } from "../../../formGenerator/formParameters/IInvoicePaymentParameters";
import { BillingInvoiceListDeletePayment } from "./BillingInvoiceListDeletePayment";
import { IInvoicePaymentView } from "../../../models/IInvoicePayment";
import { BillingInvoiceListRefundPayment } from "./BillingInvoiceListRefundPayment";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { timeout } from "rxjs/operators";
import Alert from "../../../containers/app/components/Alert";
import Spinner from "../../../containers/app/components/Spinner";
import InvoicePaymentForm from "../../../containers/app/forms/InvoicePaymentForm";
import ResendReceiptForm from "./ResendReceiptForm";
import ButtonDropdown from "reactstrap/lib/ButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";

interface IProps {
  invoiceId: string;
  onClose: () => void;
  onDataChanged: () => void;
}

const BillingInvoiceListPaymentsManage: React.FunctionComponent<IProps> = ({
  invoiceId,
  onClose,
  onDataChanged,
}) => {
  const { invoice, errorLoadingInvoice, reloadInvoice } =
    useLoadInvoice(invoiceId);

  if (errorLoadingInvoice) {
    return <Alert message="Unable to load invoice data." closeForm={onClose} />;
  } else if (!invoice) {
    return <Spinner />;
  }

  return (
    <>
      <BillingInvoiceListPaymentsList
        invoice={invoice}
        formHeader={"Manage Payments"}
        onClose={() => {
          onClose();
          onDataChanged();
        }}
        renderPayment={(payment) => (
          <Payment
            invoice={invoice}
            payment={payment}
            onDataChanged={reloadInvoice}
          />
        )}
      />
    </>
  );
};

export default BillingInvoiceListPaymentsManage;

function Payment({
  invoice,
  payment,
  onDataChanged,
}: {
  invoice: IInvoiceView;
  payment: IInvoicePaymentView;
  onDataChanged: () => void;
}) {
  const [
    showDeletePaymentConfirmationPrompt,
    setShowDeletePaymentConfirmationPrompt,
  ] = useState(false);

  const [
    showRefundPaymentConfirmationPrompt,
    setShowRefundPaymentConfirmationPrompt,
  ] = useState(false);

  const [showResendReceiptForm, setShowResendReceiptForm] = useState(false);

  const [showEditPaymentForm, setShowEditPaymentForm] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [showActions, setShowActions] = useState(false);

  const buttons: Array<{
    text: string;
    onClick: () => void;
  }> = [];

  if (payment.editPaymentAllowed) {
    buttons.push({
      text: "Edit",
      onClick: () => {
        setErrorMessage("");
        setShowEditPaymentForm(true);
      },
    });

    buttons.push({
      text: "Delete",
      onClick: () => {
        setErrorMessage("");
        setShowDeletePaymentConfirmationPrompt(true);
      },
    });
  }

  if (payment.refundAllowed) {
    buttons.push({
      text: "Refund",
      onClick: () => {
        setErrorMessage("");
        setShowRefundPaymentConfirmationPrompt(true);
      },
    });
  }
  buttons.push({
    text: "Resend receipt",
    onClick: () => {
      setShowResendReceiptForm(true);
    },
  });

  const buttonBreakpoint = "md";

  return (
    <>
      <div className="d-flex justify-content-between align-items-baseline">
        <PaymentHeader payment={payment} />

        <div className="text-right">
          <div
            className={`d-none d-${buttonBreakpoint}-flex`}
            style={{ columnGap: "10px" }}
          >
            {buttons.map((button) => (
              <button
                className="btn btn-sm btn-secondary text-nowrap"
                onClick={button.onClick}
                key={button.text}
              >
                {button.text}
              </button>
            ))}
          </div>
          <div className={`d-block d-${buttonBreakpoint}-none`}>
            <ButtonDropdown
              direction="down"
              isOpen={showActions}
              toggle={() => setShowActions(!showActions)}
            >
              <button
                className="btn btn-secondary btn-sm"
                onClick={() => setShowActions(!showActions)}
              >
                Actions
              </button>
              <DropdownToggle split color="secondary" size="sm" />
              <DropdownMenu positionFixed={true} right={true}>
                {buttons.map((button) => (
                  <DropdownItem
                    className="btn btn-sm btn-secondary text-nowrap"
                    onClick={button.onClick}
                    key={button.text}
                  >
                    {button.text}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </ButtonDropdown>
          </div>
        </div>
      </div>

      {errorMessage ? (
        <div className="text-danger my-2">{errorMessage}</div>
      ) : null}

      <PaymentFields payment={payment} />

      <BillingInvoiceListDeletePayment
        showPrompt={showDeletePaymentConfirmationPrompt}
        setShowPrompt={setShowDeletePaymentConfirmationPrompt}
        invoiceId={invoice.id}
        paymentId={payment.id}
        onDataChanged={onDataChanged}
        setErrorMessage={setErrorMessage}
        promptMessage={"Are you sure you want to delete this payment?"}
      />

      <BillingInvoiceListRefundPayment
        showPrompt={showRefundPaymentConfirmationPrompt}
        setShowPrompt={setShowRefundPaymentConfirmationPrompt}
        invoiceId={invoice.id}
        payment={payment}
        onDataChanged={onDataChanged}
        setErrorMessage={setErrorMessage}
      />

      {showEditPaymentForm ? (
        <InvoicePaymentForm
          formParameters={getSaveInvoicePaymentParameters({
            invoice,
            paymentId: payment.id,
          })}
          onSaveComplete={() => {
            onDataChanged();
            setShowEditPaymentForm(false);
          }}
          onCancel={() => {
            setShowEditPaymentForm(false);
          }}
        />
      ) : null}

      {showResendReceiptForm ? (
        <ResendReceiptForm
          customerId={invoice.customerId}
          invoiceId={invoice.id}
          paymentId={payment.id}
          receiptEmailAddresses={invoice.receiptEmailAddresses}
          onClose={() => setShowResendReceiptForm(false)}
        />
      ) : null}
    </>
  );
}

function getSaveInvoicePaymentParameters({
  paymentId,
  invoice,
}: {
  paymentId: string | null;
  invoice: IInvoiceView;
}): IInvoicePaymentParameters {
  return {
    invoiceId: invoice.id,
    paymentId: paymentId,
    customerName: invoice.customerName,
    customerId: invoice.customerId,
    replyToEmailAddress: invoice.replyToEmailAddress,
  };
}

function useLoadInvoice(invoiceId: string) {
  const [invoice, setInvoice] = useState<IInvoiceView | null>(null);
  const [errorLoadingInvoice, setErrorLoadingInvoice] = useState(false);

  useEffect(() => {
    const subscription = remoteDataProvider
      .getInvoices({ invoiceIds: [invoiceId] })
      .pipe(timeout(10000))
      .subscribe({
        next: (result) => setInvoice(result.list[0]),

        error: () => setErrorLoadingInvoice,
      });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [invoiceId]);

  const reloadInvoice = () => {
    remoteDataProvider
      .getInvoices({ invoiceIds: [invoiceId] })
      .pipe(timeout(10000))
      .subscribe({
        next: (result) => setInvoice(result.list[0]),
      });
  };

  return { invoice, errorLoadingInvoice, reloadInvoice };
}
