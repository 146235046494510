import React, { useEffect } from "react";

const ActionBar: React.FunctionComponent<{ className?: string }> = ({
  children,
  className,
}) => {
  useEffect(() => {
    let pendoBadgeCol = document.getElementsByClassName(
      "_pendo-resource-center-badge-container"
    ) as HTMLCollectionOf<HTMLElement>;

    if (pendoBadgeCol?.length > 0) {
      pendoBadgeCol[0].style["marginBottom"] = "60px";
    }

    return function cleanup() {
      if (pendoBadgeCol?.length > 0) {
        pendoBadgeCol[0].style["marginBottom"] = "0px";
      }
    };
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: "10px",
        paddingBottom: "10px",
        zIndex: 1050,
        paddingRight: "15px",
      }}
      className={`bg-secondary ${className ?? ""}`}
    >
      {children}
    </div>
  );
};

export default ActionBar;

export const actionBarButtonClassName = "info";
