import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useEffect } from "react";

interface IProps {
  text: string;
  showChildren: boolean;
  setShowChildren: React.Dispatch<React.SetStateAction<boolean>>;
}

const DropDownSubMenu: React.FunctionComponent<IProps> = ({
  text,
  children,
  showChildren,
  setShowChildren,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const submenuWidth = 160;
  const marginAdjustmentFactor = 0;

  const [submenuLeft, setSubmenuLeft] = useState(0);
  const [menuFlipped, setMenuFlipped] = useState(false);
  useEffect(() => {
    if (ref.current) {
      const boundingRect = ref.current.getBoundingClientRect();

      const willOverflowOnLeft =
        boundingRect.right + submenuWidth > window.innerWidth;
      if (willOverflowOnLeft) {
        setSubmenuLeft(-1 * (submenuWidth + marginAdjustmentFactor));
        setMenuFlipped(true);
      } else {
        setSubmenuLeft(boundingRect.width + marginAdjustmentFactor);
        setMenuFlipped(false);
      }
    }
  }, [setSubmenuLeft, setMenuFlipped]);

  return (
    <div
      style={{ position: "relative" }}
      ref={ref}
      className="context-menu-sub-menu-container"
    >
      <button
        type="button"
        role="menuitem"
        className="dropdown-item"
        onClick={(e) => {
          setShowChildren(true);
          e.stopPropagation();
          e.preventDefault();
        }}
        onMouseEnter={() => setShowChildren(true)}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginRight: "10px" }}>{text}</div>
          <div>
            <FontAwesomeIcon icon={faCaretRight} />
          </div>
        </div>
      </button>
      {showChildren ? (
        <div
          className={`dropdown-menu show context-menu-container context-menu-sub-menu ${
            menuFlipped
              ? "context-menu-sub-menu-flipped"
              : "context-menu-sub-menu-not-flipped"
          }`}
          style={{
            position: "absolute",
            top: "-11px",
            left: `${submenuLeft}px`,
            zIndex: 100,
            width: `${submenuWidth}px`,
            padding: "5px",
          }}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default DropDownSubMenu;
