import { useDispatch } from "react-redux";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { IUnscheduleJobsToShow } from "../../../../models/IUnscheduleJobsToShow";
import { actionCreators } from "../../../../modules/actionCreators";
import dateService from "../../../../services/dateService";
import JobCard, { RenderJobCardWithWrapperType } from "./jobCard";

export default function UnscheduledJobsCards({
  elementsToShow,
  filteredUnscheduledJobs,
  weekForUnscheduledJobs,
  dragAndDropPlaceholder,
  renderJobCardWithWrapper,
}: {
  elementsToShow: Array<IUnscheduleJobsToShow & { originalIndex: number }>;
  filteredUnscheduledJobs: Array<IUnscheduleJobsToShow>;
  weekForUnscheduledJobs: Date;
  dragAndDropPlaceholder?: React.ReactElement | null;
  renderJobCardWithWrapper: RenderJobCardWithWrapperType;
}) {
  const lastSingleSelectedJobInstance = useApplicationStateSelector(
    (s) => s.scheduleUi.lastSingleSelectedJobInstance
  );
  const dispatch = useDispatch();

  return (
    <>
      <div
        className="board-card-container"
        style={{
          flexWrap: "wrap",
          marginLeft: "-15px",
          display: "flex",
        }}
        data-testid="unscheduledJobsCardContainer"
      >
        {elementsToShow.map((unscheduledJob) => {
          const { jobInstance, job, originalIndex: jobIndex } = unscheduledJob;
          return (
            <JobCard
              renderJobCardWithWrapper={renderJobCardWithWrapper}
              hideUpdateCompletionInformation={true}
              hideSkip={true}
              key={jobInstance.id}
              date={dateService.formatAsIso(weekForUnscheduledJobs)}
              crew={null}
              distanceFromPreviousJob={null}
              distanceFromPreviousJobErrorLoading={false}
              distanceToCrewLocation={null}
              distanceToCrewLocationErrorLoading={false}
              isMapVisible={false}
              job={job}
              jobIndex={jobIndex}
              jobInstance={jobInstance}
              jobType={job.type}
              lastJob={false}
              hideContextMenu={false}
              draggableStyleOverride={{
                width: "150px",
                marginRight: "15px",
                marginBottom: "15px",
              }}
              onJobCardMultiSelected={(jobInstanceId) => {
                if (!lastSingleSelectedJobInstance) {
                  return;
                }

                const newIndex = filteredUnscheduledJobs.findIndex(
                  (ji) => ji.jobInstance.id === jobInstanceId
                );
                const lastIndex = filteredUnscheduledJobs.findIndex(
                  (ji) => ji.jobInstance.id === lastSingleSelectedJobInstance
                );

                if (newIndex === -1 || lastIndex === -1) {
                  return;
                }

                const startIndex = Math.min(newIndex, lastIndex);
                const endIndex = Math.max(newIndex, lastIndex);

                const jobInstancesBetweenStartAndEnd = filteredUnscheduledJobs
                  .slice(startIndex + 1, endIndex)
                  .map((ji) => ji.jobInstance.id);

                dispatch(
                  actionCreators.jobInstanceToggleSelected(
                    jobInstancesBetweenStartAndEnd,
                    true
                  )
                );
              }}
            />
          );
        })}
        {dragAndDropPlaceholder}
      </div>
    </>
  );
}
