import { IJob } from "../../models/IJob";
import { actionCreators } from "../actionCreators";
import { IAction } from "../actionTypeDefinitions";
import { of, Observable } from "rxjs";
import { IRootState } from "../../store";
import remoteDataProvider from "../../services/remoteDataProvider";
import { map } from "rxjs/operators";

export function loadMissingCategories(
  jobs: Array<IJob>,
  state: IRootState
): Observable<IAction> {
  const jobCrewCategoriesNotLoaded = jobs.reduce((acc, job) => {
    const categoryIds = job.categories
      .filter((c) => c.id)
      .map((c) => c.id as string);

    return [
      ...acc,
      ...categoryIds.filter(
        (categoryId) =>
          !acc.includes(categoryId) &&
          !state.common.crewCategories.some((cc) => cc.id === categoryId)
      ),
    ];
  }, [] as Array<string>);

  if (jobCrewCategoriesNotLoaded.length > 0) {
    return remoteDataProvider.getInitialLoad().pipe(
      map((initialLoadResult) => {
        return actionCreators.addCrewCategory(
          initialLoadResult.crewCategories.filter((cc) =>
            jobCrewCategoriesNotLoaded.includes(cc.id)
          )
        );
      })
    );
  } else {
    return of(actionCreators.null());
  }
}
