import { format } from "date-fns";
import { useRef } from "react";
import { Control, Controller } from "react-hook-form";
import { IFormData } from "./IFormData";
import DayPicker, {
  format as dayPickerFormat,
} from "../../../../containers/app/components/DayPicker";
import dateService from "../../../../services/dateService";

export function DateField({
  label,
  control,
  fieldId,
  onDaySelected,
}: {
  label: string;
  fieldId: "proposalDate" | "validUntilDate";
  control: Control<IFormData, object>;
  onDaySelected?: (day: Date) => void;
}) {
  const dateLabelRef = useRef<HTMLLabelElement>(null);

  return (
    <div>
      <label htmlFor={fieldId} className="required">
        {label}
      </label>

      <Controller
        name={fieldId}
        control={control}
        render={({ field }) => (
          <DayPicker
            preventMobileView={true}
            onDayPickerHide={() => null}
            dayPickerProps={{}}
            required={true}
            inputId={fieldId}
            labelRef={dateLabelRef}
            value={!!field.value ? format(field.value, dayPickerFormat) : ""}
            onDaySelected={(day: Date) => {
              if (!!day) {
                field.onChange(dateService.formatAsIso(day));
                if (typeof onDaySelected === "function") {
                  onDaySelected(day);
                }
              } else {
                field.onChange("");
              }
            }}
          />
        )}
      />
    </div>
  );
}
