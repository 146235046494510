export function objectMap<TSource, TResultPropertyType>(
  sourceObject: TSource,
  fn: (
    key: keyof TSource,
    originalObjectPropertyValue: any
  ) => TResultPropertyType
) {
  return Object.fromEntries(
    // Using "any" here since type doesn't provide any protection
    // and resolves a breaking dchange
    Object.entries(sourceObject as any).map(([key, value]) => [
      key,
      fn(key as keyof TSource, value),
    ])
  );
}
