import uuidv4 from "uuid/v4";
import { ICustomer } from "../../../models/ICustomer";
import { IEmailAddressRecord } from "./EmailAddresses";

export function createEmailAddressRecord(value: string): IEmailAddressRecord {
  return {
    id: uuidv4(),
    value,
  };
}

export function createEmailAddressRecordsForCustomer(
  customer: ICustomer | undefined | null
) {
  if (
    !customer ||
    !customer.emailAddresses ||
    customer.emailAddresses.length === 0
  ) {
    return [createEmailAddressRecord("")];
  }

  return customer.emailAddresses.map((e) => createEmailAddressRecord(e));
}

export function getEmailAddressesForSave(
  emailAddresses: Array<IEmailAddressRecord>
) {
  return emailAddresses.map((e) => e.value).filter((v) => v.trim() !== "");
}
