import React, { useEffect, useReducer, useRef, useState } from "react";
import PageWithNavBar2 from "../../PageWithNavBar2";
import { useSelector, useDispatch } from "react-redux";
import { IApplicationState } from "../../../../modules";
import { ICrew } from "../../../../models/ICrew";
import { IDaySchedule } from "../../../../models/IDaySchedule";
import { RouteComponentProps } from "react-router-dom";
import {
  startOfWeek,
  parse,
  addDays,
  differenceInDays,
  format,
} from "date-fns";
import Spinner from "../../components/Spinner";
import dateService from "../../../../services/dateService";
import { actionCreators } from "../../../../modules/actionCreators";
import { IAction } from "../../../../modules/actionTypeDefinitions";
import {
  IDayToLoad,
  getDaysToLoad,
} from "../../../../services/dayScheduleLoader";
import { GoogleMap, InfoWindow, LoadScriptNext } from "@react-google-maps/api";
import jobFinder, { IFoundJob } from "../../../../services/jobFinder";
import { IOneTimeJob } from "../../../../models/IOneTimeJob";
import { IMaintenanceJob } from "../../../../models/IMaintenanceJob";
import { ICustomer } from "../../../../models/ICustomer";
import customerFinder from "../../../../services/customerFinder";
import { IJob, JobType } from "../../../../models/IJob";
import { getSortedCrews } from "../../../../services/sortingService";
import { IUnscheduledMaintenanceJob } from "../../../../models/IUnscheduledMaintenanceJob";
import Select from "react-select";
import CustomizedMapMarker from "../../components/CustomizedMapMarker";
import { IJobInstance } from "../../../../models/IJobInstance";
import addressFormatter from "../../../../services/addressFormatter";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import WeekPicker from "../../components/WeekPicker";
import { format as weekPickerFormat } from "../../components/DayPicker";
import { push } from "connected-react-router";
import { builders as routingBuilders } from "../../../../services/routing";
import { ICustomerAdditionalLocation } from "../../../../models/ICustomerAdditionalLocation";
import { getUnscheduledJobs } from "../../../../services/jobInstanceService";
import {
  createLatLng,
  googleMapLibraries,
  hasGoogleMapsLoaded,
} from "../../../../services/googleService";
import constants from "../../../../constants";
import ErrorLoadingGoogleMaps from "../../components/ErrorLoadingGoogleMaps";

interface IRouteParams {
  date: string;
}

interface IDay {
  name: string;
  offset: number;
}

const days: Array<IDay> = [
  { offset: 0, name: "Sunday" },
  { offset: 1, name: "Monday" },
  { offset: 2, name: "Tuesday" },
  { offset: 3, name: "Wednesday" },
  { offset: 4, name: "Thursday" },
  { offset: 5, name: "Friday" },
  { offset: 6, name: "Saturday" },
];

interface IJobWithCustomer {
  job: IJob;
  jobInstance: IJobInstance;
  customer: ICustomer;
  jobType: JobType;
}

interface IJobsToShow {
  crewId: string;
  date: string;
  jobsWithCustomers: Array<IJobWithCustomer>;
}

interface ICrewFilterOption {
  id: string;
  name: string;
  color: string;
}

const flexibleJobsCrewId = "flexibleJobsId";
const windowAny: any = window;

const flexibleCrewFilterOption = {
  name: "Flexible jobs",
  id: flexibleJobsCrewId,
  color: "rgb(234, 67, 53)",
};

type SelectedCrewsAction = {
  type: "updateSelectedCrews";
  selectedCrews: Array<string>;
};

function selectedCrewsReducer(
  state: Array<string>,
  action: SelectedCrewsAction
) {
  switch (action.type) {
    case "updateSelectedCrews":
      return action.selectedCrews;
    default:
      throw new Error("unsupported type in selectedCrewsReducer");
  }
}

const FullWeek: React.FunctionComponent<RouteComponentProps<IRouteParams>> = ({
  match,
}) => {
  const crews = useSelector<IApplicationState, Array<ICrew>>(
    (s) => s.crew.crews
  );
  const [selectedDays, setSelectedDays] = useState<Array<number>>(
    days.map((d) => d.offset)
  );
  const [includeFlexibleJobs, setIncludeFlexibleJobs] = useState(true);

  const maintenanceJobs = useApplicationStateSelector((s) => s.job.jobs);
  const oneTimeJobs = useApplicationStateSelector((s) => s.job.oneTimeJobs);
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );

  const map = useRef<google.maps.Map | null>(null);
  const dispatch = useDispatch();
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const weeksUnscheduledMaintenanceJobs = useSelector<
    IApplicationState,
    Array<IUnscheduledMaintenanceJob>
  >((s) => s.schedule.weeksUnscheduledMaintenanceJobs);

  const activeCrews = getActiveCrews(crews);
  const weekDates = getWeeksDates(getStartingDate(match.params));
  const [selectedJob, setSelectedJob] = useState<IJobWithCustomer | null>(null);
  const [showMap, setShowMap] = useState(false);
  const [boundsDefined, setBoundsDefined] = useState(false);

  const [selectedCrews, selectedCrewsDispatch] = useReducer(
    selectedCrewsReducer,
    activeCrews.map((c) => c.id)
  );

  const jobsWithCustomer = useCrewsJobsWithCustomers(
    crews,
    match.params,
    daySchedules,
    maintenanceJobs,
    oneTimeJobs,
    customers,
    customerAdditionalLocations
  );

  const flexibleJobsWithCustomers = useFlexibleJobsWithCustomers(
    weeksUnscheduledMaintenanceJobs,
    match.params,
    maintenanceJobs,
    oneTimeJobs,
    customers,
    customerAdditionalLocations
  );

  useEnsureSchedulesLoaded(
    daySchedules,
    activeCrews,
    weekDates,
    weeksUnscheduledMaintenanceJobs,
    dispatch
  );

  useEffect(() => {
    const jobs = getAllJobs(jobsWithCustomer, flexibleJobsWithCustomers);

    if (
      !areSchedulesLoading(
        daySchedules,
        activeCrews,
        weekDates,
        weeksUnscheduledMaintenanceJobs
      ) &&
      !boundsDefined &&
      hasGoogleMapsLoaded()
    ) {
      setBounds(map, jobs, customers, customerAdditionalLocations);
      setBoundsDefined(true);
    }

    if (jobs.length > 0) {
      setShowMap(true);
    }
  }, [
    daySchedules,
    activeCrews,
    weekDates,
    weeksUnscheduledMaintenanceJobs,
    map,
    jobsWithCustomer,
    flexibleJobsWithCustomers,
    boundsDefined,
    setBoundsDefined,
    customers,
    customerAdditionalLocations,
  ]);

  const crewFilterOptions: Array<ICrewFilterOption> =
    getCrewFilterOptions(activeCrews);

  const crewFilterValue = getSelectedCrews(
    crewFilterOptions,
    selectedCrews,
    includeFlexibleJobs
  );

  const maxDateForWeekPicker = addDays(
    startOfWeek(dateService.getMaximumDateForSchedule()),
    -1
  );

  const loadingJobs = areSchedulesLoading(
    daySchedules,
    activeCrews,
    weekDates,
    weeksUnscheduledMaintenanceJobs
  );

  return (
    <PageWithNavBar2
      subHeaderLeftSideContent={
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <WeekPicker
              showIcon={true}
              inputId="weekScheduleWeekPicker"
              required={true}
              preventMobileView={true}
              inputStyle={{
                width: "135px",
              }}
              value={format(
                getSelectedDateStartOfWeek(match.params),
                weekPickerFormat
              )}
              onWeekSelected={(value) => {
                if (
                  value &&
                  dateService.removeTimeComponents(value) <=
                    dateService.removeTimeComponents(maxDateForWeekPicker)
                ) {
                  dispatch(
                    push(
                      routingBuilders.schedule.buildMapWeekRoute(
                        dateService.formatAsIso(startOfWeek(value)),
                        selectedCrews[0]
                      )
                    )
                  );
                }
              }}
              maxDate={maxDateForWeekPicker}
            />
          </div>
        </>
      }
    >
      {hasGoogleMapsLoaded() ? (
        <>
          <div className="row" style={{ marginBottom: "15px" }}>
            <div className="col-12 col-lg-6" style={{ marginBottom: "10px" }}>
              <Select
                styles={{
                  multiValue: (provided, { data }) => {
                    return {
                      ...provided,
                      color: "white",
                      backgroundColor: data.color ?? undefined,
                    };
                  },
                  multiValueLabel: (styles) => ({
                    ...styles,
                    color: "white",
                  }),
                }}
                options={crewFilterOptions}
                getOptionLabel={(c) => c.name}
                getOptionValue={(c) => c.id}
                isMulti={true}
                value={crewFilterValue}
                placeholder="Select crews..."
                noOptionsMessage={() => "No remaining crews"}
                onChange={(newCrews) => {
                  if (newCrews) {
                    const typedNewCrews = newCrews as Array<ICrewFilterOption>;
                    selectedCrewsDispatch({
                      type: "updateSelectedCrews",
                      selectedCrews: typedNewCrews.map((c) => c.id),
                    });
                    setIncludeFlexibleJobs(
                      typedNewCrews.some((c) => c.id === flexibleJobsCrewId)
                    );
                  } else {
                    selectedCrewsDispatch({
                      type: "updateSelectedCrews",
                      selectedCrews: [],
                    });
                    setIncludeFlexibleJobs(false);
                  }
                }}
              />
            </div>
            <div className="col-12 col-lg-6">
              <Select
                options={days}
                getOptionLabel={(c) => c.name}
                getOptionValue={(c) => c.offset.toString()}
                isMulti={true}
                value={days.filter((d) => selectedDays.includes(d.offset))}
                placeholder="Select days..."
                noOptionsMessage={() => "No remaining days"}
                onChange={(newDays) => {
                  if (newDays) {
                    setSelectedDays(
                      (newDays as Array<IDay>).map((c) => c.offset)
                    );
                  } else {
                    setSelectedDays([]);
                  }
                }}
              />
            </div>
          </div>

          <div
            data-testid="map-container"
            style={{
              marginBottom: constants.marginBottomForIntercom,
            }}
          >
            {loadingJobs ? <Spinner /> : null}

            {getAllJobs(jobsWithCustomer, flexibleJobsWithCustomers).length >
            0 ? (
              <>
                <div
                  data-testid="mapContainer"
                  style={{
                    visibility: !showMap ? "hidden" : undefined,
                  }}
                >
                  <LoadScriptNext
                    loadingElement={<div></div>}
                    id="script-loader"
                    libraries={googleMapLibraries as any}
                    googleMapsApiKey={
                      process.env.REACT_APP_GOOGLE_MAPS_KEY as string
                    }
                    onError={() => {
                      console.log("Unable to load google maps script");
                    }}
                  >
                    <React.Fragment>
                      <div
                        style={{
                          height: `75vh`,
                          width: "100%",
                          position: "sticky",
                          top: "15px",
                        }}
                      >
                        <GoogleMap
                          id="fullmap"
                          options={{ gestureHandling: "cooperative" }}
                          mapContainerStyle={{ height: `100%` }}
                          onClick={() => setSelectedJob(null)}
                          onLoad={(m) => {
                            map.current = m;
                            const jobs = getAllJobs(
                              jobsWithCustomer,
                              flexibleJobsWithCustomers
                            );
                            setBounds(
                              map,
                              jobs,
                              customers,
                              customerAdditionalLocations
                            );

                            if (jobs.length > 0) {
                              setShowMap(true);
                            }
                          }}
                        >
                          {jobsWithCustomer
                            .filter((c) =>
                              selectedCrews.some((sc) => sc === c.crewId)
                            )
                            .filter((c) =>
                              selectedDays.some(
                                (sd) =>
                                  sd ===
                                  differenceInDays(
                                    c.date,
                                    getStartingDate(match.params)
                                  )
                              )
                            )
                            .map((c) => {
                              const dayOfWeek = days.find(
                                (d) =>
                                  d.offset ===
                                  differenceInDays(
                                    c.date,
                                    getStartingDate(match.params)
                                  )
                              );
                              return c.jobsWithCustomers.map(
                                (jobWithCustomer) => (
                                  <CustomizedMapMarker
                                    flexibleJob={false}
                                    key={jobWithCustomer.jobInstance.id}
                                    labelText={
                                      dayOfWeek
                                        ? dayOfWeek.name.substring(0, 2)
                                        : ""
                                    }
                                    fillColor={
                                      crewFilterOptions.find(
                                        (crewFilterOption) =>
                                          crewFilterOption.id === c.crewId
                                      )?.color ?? ""
                                    }
                                    position={getPosition(
                                      jobWithCustomer,
                                      customers,
                                      customerAdditionalLocations
                                    )}
                                    onClick={() =>
                                      setSelectedJob(jobWithCustomer)
                                    }
                                  />
                                )
                              );
                            })}
                          {includeFlexibleJobs
                            ? flexibleJobsWithCustomers.map(
                                (jobWithCustomer) => (
                                  <CustomizedMapMarker
                                    flexibleJob={true}
                                    key={jobWithCustomer.jobInstance.id}
                                    position={getPosition(
                                      jobWithCustomer,
                                      customers,
                                      customerAdditionalLocations
                                    )}
                                    onClick={() =>
                                      setSelectedJob(jobWithCustomer)
                                    }
                                  />
                                )
                              )
                            : null}
                          {selectedJob ? (
                            <InfoWindow
                              onCloseClick={() => setSelectedJob(null)}
                              position={getPosition(
                                selectedJob,
                                customers,
                                customerAdditionalLocations
                              )}
                            >
                              <>
                                <div>{selectedJob.customer.name}</div>
                                <div>
                                  {addressFormatter.formatAddressEntity(
                                    addressFormatter.getAddressForJob(
                                      selectedJob.job,
                                      customers,
                                      customerAdditionalLocations
                                    )
                                  )}
                                </div>
                                <div>
                                  <button
                                    className="btn btn-link btn-sm"
                                    style={{ paddingLeft: 0, paddingBottom: 0 }}
                                    onClick={() => {
                                      dispatch(
                                        actionCreators.forms.moveJobInstance.showForm(
                                          {
                                            jobInstanceIds: [
                                              selectedJob.jobInstance.id,
                                            ],
                                          }
                                        )
                                      );
                                      setSelectedJob(null);
                                    }}
                                  >
                                    Move
                                  </button>
                                </div>
                                <div>
                                  <button
                                    className="btn btn-link btn-sm"
                                    style={{ paddingLeft: 0, paddingBottom: 0 }}
                                    onClick={() => {
                                      dispatch(
                                        selectedJob.jobType ===
                                          JobType.maintenanceJob
                                          ? actionCreators.forms.maintenanceJob.showForm(
                                              {
                                                maintenanceJobId:
                                                  selectedJob.job.id,
                                              }
                                            )
                                          : actionCreators.forms.oneTimeJob.showForm(
                                              {
                                                oneTimeJobId:
                                                  selectedJob.job.id,
                                              }
                                            )
                                      );
                                      setSelectedJob(null);
                                    }}
                                  >
                                    {selectedJob.jobType ===
                                    JobType.maintenanceJob
                                      ? "Edit Recurring Job"
                                      : "Edit Job"}
                                  </button>
                                </div>
                              </>
                            </InfoWindow>
                          ) : null}
                        </GoogleMap>
                      </div>
                    </React.Fragment>
                  </LoadScriptNext>
                </div>
              </>
            ) : (
              !loadingJobs && (
                <div>
                  <div className="jumbotron" style={{ textAlign: "center" }}>
                    <h5>No jobs with coordinates were found</h5>
                  </div>
                </div>
              )
            )}
          </div>
        </>
      ) : (
        <ErrorLoadingGoogleMaps />
      )}
    </PageWithNavBar2>
  );
};

export default FullWeek;

function getAllJobs(
  jobsWithCustomer: IJobsToShow[],
  flexibleJobsWithCustomers: IJobWithCustomer[]
) {
  return [
    ...jobsWithCustomer.flatMap((j) => j.jobsWithCustomers),
    ...flexibleJobsWithCustomers,
  ];
}

function getSelectedCrews(
  crewFilterOptions: ICrewFilterOption[],
  selectedCrews: string[],
  includeFlexibleJobs: boolean
) {
  return crewFilterOptions.filter(
    (c) =>
      selectedCrews.includes(c.id) ||
      (c.id === flexibleCrewFilterOption.id && includeFlexibleJobs)
  );
}

function getCrewFilterOptions(activeCrews: ICrew[]): ICrewFilterOption[] {
  const availableColors = getAvailableColors();
  return [
    ...getSortedCrews(activeCrews).map((c, index) => ({
      ...c,
      color: availableColors[index % (availableColors.length - 1)],
    })),
    flexibleCrewFilterOption,
  ];
}

function useCrewsJobsWithCustomers(
  crews: ICrew[],
  matchParams: IRouteParams,
  daySchedules: IDaySchedule[],
  maintenanceJobs: IMaintenanceJob[],
  oneTimeJobs: IOneTimeJob[],
  customers: ICustomer[],
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>
): IJobsToShow[] {
  const schedulesForWeek = getSchedulesForWeek(crews, matchParams);
  return daySchedules
    .filter((ds) =>
      schedulesForWeek.find(
        (sfw) => sfw.crewId === ds.crewId && sfw.date === ds.date
      )
    )
    .map((a) => ({
      crewId: a.crewId,
      date: a.date,
      jobsWithCustomers: getJobsWithCustomerToShow(
        a.jobInstances,
        customers,
        maintenanceJobs,
        oneTimeJobs,
        customerAdditionalLocations
      ),
    }));
}

function useFlexibleJobsWithCustomers(
  weeksUnscheduledMaintenanceJobs: IUnscheduledMaintenanceJob[],
  matchParams: IRouteParams,
  maintenanceJobs: IMaintenanceJob[],
  oneTimeJobs: IOneTimeJob[],
  customers: ICustomer[],
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>
): IJobWithCustomer[] {
  const jobInstances = getUnscheduledJobs(
    getStartingDate(matchParams),
    weeksUnscheduledMaintenanceJobs,
    maintenanceJobs
  );

  return getJobsWithCustomerToShow(
    jobInstances,
    customers,
    maintenanceJobs,
    oneTimeJobs,
    customerAdditionalLocations
  );
}

function getJobsWithCustomerToShow(
  jobInstances: Array<IJobInstance>,
  customers: Array<ICustomer>,
  maintenanceJobs: Array<IMaintenanceJob>,
  oneTimeJobs: Array<IOneTimeJob>,
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>
) {
  return jobInstances
    .map((ji) => ({
      job: jobFinder.getJobForDayScheduleV2(maintenanceJobs, oneTimeJobs, ji),
      jobInstance: ji,
    }))
    .filter((j) => j.job !== null)
    .map((j) => ({
      job: j.job as IJob,
      jobType: (j.job as IFoundJob).type,
      jobInstance: j.jobInstance,
      customer: customerFinder.getCustomerByJob(j.job as IJob, customers),
      location: customerAdditionalLocations.find(
        (l) => l.id === j.job?.customerAdditionalLocationId
      ),
    }))
    .filter(
      (c) =>
        (c.customer?.latitude && c.customer?.longitude) ||
        (c.location?.latitude && c.location?.longitude)
    );
}

function getStartingDate(params: IRouteParams) {
  return startOfWeek(params.date ? parse(params.date) : new Date());
}

function getActiveCrews(crews: ICrew[]) {
  return crews.filter((c) => !c.inactive);
}

function getSchedulesForWeek(crews: ICrew[], params: IRouteParams) {
  const activeCrews = getActiveCrews(crews);
  const startingDate = getStartingDate(params);
  const weekDates = getWeeksDates(startingDate);
  return activeCrews.reduce((crewAcc, crew) => {
    return weekDates.reduce((dateAcc, date) => {
      return [
        ...dateAcc,
        { crewId: crew.id, date: dateService.formatAsIso(date) },
      ];
    }, crewAcc);
  }, [] as Array<IDayToLoad>);
}

function getWeeksDates(startDate: Date) {
  return days.map((v) => addDays(startDate, v.offset));
}

function setBounds(
  map: React.MutableRefObject<google.maps.Map | null>,
  jobs: Array<IJobWithCustomer>,
  customers: Array<ICustomer>,
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>
) {
  // Need to wait to set bounds. Otherwise, a blank map will be shown.
  setTimeout(() => {
    if (map.current) {
      const bounds = new windowAny.google.maps.LatLngBounds();

      jobs.forEach((j) => {
        const address = addressFormatter.getAddressForJob(
          j.job,
          customers,
          customerAdditionalLocations
        );

        if (
          typeof address.latitude === "number" &&
          typeof address.longitude === "number"
        ) {
          bounds.extend(
            createLatLng({
              latitude: address.latitude,
              longitude: address.longitude,
            })
          );
        }
      });

      map.current.fitBounds(bounds);
    }
  });
}

function getPosition(
  jobWithCustomer: IJobWithCustomer,
  customers: Array<ICustomer>,
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>
): google.maps.LatLng {
  const address = addressFormatter.getAddressForJob(
    jobWithCustomer.job,
    customers,
    customerAdditionalLocations
  );

  return createLatLng({
    latitude: address.latitude as number,
    longitude: address.longitude as number,
  });
}

function getAvailableColors() {
  return [
    "rgb(144, 188, 98)",
    "rgb(120, 28, 129)",
    "rgb(77, 31, 130)",
    "rgb(227, 154, 54)",
    "rgb(64, 99, 176)",
    "rgb(215, 175, 61)",
    "rgb(64, 62, 149)",
    "rgb(70, 132, 194)",
    "rgb(195, 186, 69)",
    "rgb(81, 156, 184)",
    "rgb(171, 190, 81)",
    "rgb(119, 183, 123)",
    "rgb(98, 172, 154)",
    "rgb(231, 120, 48)",
  ];
}

function getSelectedDateStartOfWeek(currentParams: IRouteParams) {
  return startOfWeek(
    currentParams.date ? parse(currentParams.date) : new Date()
  );
}

function useEnsureSchedulesLoaded(
  daySchedules: IDaySchedule[],
  activeCrews: ICrew[],
  weekDates: Date[],
  weeksUnscheduledMaintenanceJobs: IUnscheduledMaintenanceJob[],
  dispatch: (action: IAction) => void
) {
  useEffect(() => {
    const { schedulesToLoad, flexibleJobDatesToLoad } =
      getSchedulesAndFlexibleDatesToLoad({
        daySchedules,
        activeCrews,
        weekDates,
        weeksUnscheduledMaintenanceJobs,
        skipStaleCheck: false,
      });

    if (schedulesToLoad.length > 0 || flexibleJobDatesToLoad.length > 0) {
      dispatch(
        actionCreators.loadDaySchedulesStarting(
          schedulesToLoad,
          flexibleJobDatesToLoad,
          null
        )
      );
    }
  }, [
    daySchedules,
    activeCrews,
    weekDates,
    dispatch,
    weeksUnscheduledMaintenanceJobs,
  ]);
}

function getSchedulesAndFlexibleDatesToLoad({
  daySchedules,
  activeCrews,
  weekDates,
  weeksUnscheduledMaintenanceJobs,
  skipStaleCheck,
}: {
  daySchedules: IDaySchedule[];
  activeCrews: ICrew[];
  weekDates: Date[];
  weeksUnscheduledMaintenanceJobs: IUnscheduledMaintenanceJob[];
  skipStaleCheck: boolean;
}) {
  const schedulesToLoad = getDaysToLoad({
    loadedDaySchedules: daySchedules,
    crewIds: activeCrews.map((c) => c.id),
    dates: weekDates.map((d) => dateService.formatAsIso(d)),
    skipStaleCheck: true,
  });

  let flexibleJobDatesToLoad: Array<Date> = [];
  if (
    !weeksUnscheduledMaintenanceJobs.some(
      (w) =>
        dateService.areDatesEqual(w.week, weekDates[0]) &&
        (!w.stale || skipStaleCheck)
    )
  ) {
    flexibleJobDatesToLoad = [weekDates[0]];
  } else {
    // Always reload dirty weeks so if a job in a past week that rolled
    // over was updated, the schedule will be updated
    flexibleJobDatesToLoad = weeksUnscheduledMaintenanceJobs
      .filter((w) => w.stale && !skipStaleCheck)
      .map((w) => parse(w.week));
  }

  return { schedulesToLoad, flexibleJobDatesToLoad };
}

function areSchedulesLoading(
  daySchedules: IDaySchedule[],
  activeCrews: ICrew[],
  weekDates: Date[],
  weeksUnscheduledMaintenanceJobs: IUnscheduledMaintenanceJob[]
) {
  const { schedulesToLoad, flexibleJobDatesToLoad } =
    getSchedulesAndFlexibleDatesToLoad({
      daySchedules,
      activeCrews,
      weekDates,
      weeksUnscheduledMaintenanceJobs,
      skipStaleCheck: true,
    });

  if (schedulesToLoad.length > 0 || flexibleJobDatesToLoad.length > 0) {
    return true;
  }

  if (
    daySchedules.some(
      (ds) =>
        activeCrews.some((c) => c.id === ds.crewId) &&
        weekDates.some((d) => dateService.areDatesEqual(d, ds.date)) &&
        ds.initialLoadRunning
    )
  ) {
    return true;
  }

  if (
    weeksUnscheduledMaintenanceJobs.some(
      (w) =>
        weekDates.some((d) => dateService.areDatesEqual(w.week, d)) &&
        w.initialLoadRunning
    )
  ) {
    return true;
  }

  return false;
}
