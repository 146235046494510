import React from "react";

const LoadReportMessage: React.FunctionComponent<{}> = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
      }}
    >
      <div
        className="jumbotron"
        style={{
          textAlign: "center",
          width: "100%",
        }}
      >
        <h5>Load report to get started</h5>
      </div>
    </div>
  );
};

export default LoadReportMessage;
