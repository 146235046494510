import React from "react";
import PageWithNavBar2 from "../../PageWithNavBar2";
import dateService from "../../../../services/dateService";
import Spinner from "../../components/Spinner";
import NotesModal from "./NotesModal";
import ReportContents from "./ReportContents";

import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import addDays from "date-fns/add_days";
import Filters, { IFilters } from "./Filters";
import { IBillingReportCustomer } from "../../../../models/IBillingReport";
import { IRootState } from "../../../../store";
import { connect } from "react-redux";
import BillingDetailsModal from "./BillingDetailsModal";
import { lastDayOfWeek, differenceInDays, parse } from "date-fns";
import { actionCreators } from "../../../../modules/actionCreators";
import LoadReportMessage from "../../components/LoadReportMessage";
import { CompletedWorkReportColumn } from "../../../../enums/completedWorkReportColumn";
import { getUserSettingsFromStore } from "../../../../services/userSettingsService";
import { UserSettingsType } from "../../../../enums/userSettingsType";
import { BillingReportJobStatusFilter } from "../../../../enums/billingReportJobStatusFilter";
import { userSettingsActionCreators } from "../../../../modules/userSettings";
import { IUserSetting } from "../../../../models/IUserSetting";

const formatDateForReportDisplay = (input: Date | string) => {
  if (typeof input === "string") {
    input = dateFnsParse(input);
  }
  return dateFnsFormat(input, "M/D");
};

interface IProps {
  imagePrefix: string;
  errorMessage: string | null;
  customers: Array<IBillingReportCustomer> | null;
  loading: boolean;
  billingReportStartLoad: (
    startDate: string,
    endDate: string,
    category: string,
    customerName: string,
    crewIds: Array<string>,
    jobStatus: BillingReportJobStatusFilter
  ) => void;
  userSettings: Array<IUserSetting>;
  setUserSetting: (payload: { type: UserSettingsType; value: unknown }) => void;
}

interface IState {
  internalErrorMessage: string;
  filters: IFilters | null;
  customerForNotes: IBillingReportCustomer | null;
  customerForBillingDetails: IBillingReportCustomer | null;
  visibleColumns: Array<CompletedWorkReportColumn>;
}

class Report extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    let defaultVisibleColumns: Array<CompletedWorkReportColumn> | null =
      getUserSettingsFromStore(
        this.props.userSettings,
        UserSettingsType.completedWorkColumns
      );
    if (defaultVisibleColumns === null) {
      defaultVisibleColumns = [
        CompletedWorkReportColumn.SpecificVisits,
        CompletedWorkReportColumn.VisitCount,
        CompletedWorkReportColumn.Frequency,
        CompletedWorkReportColumn.Notes,
        CompletedWorkReportColumn.Billing,
        CompletedWorkReportColumn.Services,
        CompletedWorkReportColumn.Variance,
        CompletedWorkReportColumn.Hours,
        CompletedWorkReportColumn.Revenue,
        CompletedWorkReportColumn.RevenuePerManHour,
      ];
    }

    this.state = {
      internalErrorMessage: "",
      filters: null,
      customerForNotes: null,
      customerForBillingDetails: null,
      visibleColumns: defaultVisibleColumns,
    };

    this.showNotes = this.showNotes.bind(this);
    this.showBillingDetails = this.showBillingDetails.bind(this);
    this.closeNotes = this.closeNotes.bind(this);
    this.closeBillingDetails = this.closeBillingDetails.bind(this);
  }

  componentDidMount() {}

  loadReport(filters: IFilters) {
    if (!filters.startingDate || !filters.endingDate) {
      this.setState({
        internalErrorMessage: "Starting date and Ending date are required",
      });

      return;
    }

    this.setState({
      internalErrorMessage: "",
    });

    this.props.billingReportStartLoad(
      filters.startingDate,
      filters.endingDate,
      filters.category,
      filters.customerName,
      filters.crewIds,
      parseInt(filters.jobStatus)
    );
  }

  showNotes(customerForNotes: IBillingReportCustomer) {
    this.setState({
      customerForNotes,
    });
  }

  closeNotes() {
    this.setState({
      customerForNotes: null,
    });
  }

  showBillingDetails(customerForBillingDetails: IBillingReportCustomer) {
    this.setState({
      customerForBillingDetails,
    });
  }

  closeBillingDetails() {
    this.setState({
      customerForBillingDetails: null,
    });
  }

  render() {
    const { filters, internalErrorMessage } = this.state;
    let { errorMessage, customers, loading } = this.props;

    if (!errorMessage) {
      errorMessage = internalErrorMessage;
    }

    const dateRanges = [];
    if (filters) {
      const startDate = parse(filters.startingDate);
      const endDate = parse(filters.endingDate);
      if (startDate < endDate) {
        let currentRangeStart = startDate;
        let isFirstLoop = true;
        while (currentRangeStart <= endDate) {
          let daysToAdd = 6;
          if (isFirstLoop) {
            daysToAdd = differenceInDays(
              lastDayOfWeek(currentRangeStart),
              currentRangeStart
            );
          }

          let currentRangeEnd = addDays(currentRangeStart, daysToAdd);
          if (currentRangeEnd > endDate) {
            currentRangeEnd = endDate;
          }

          dateRanges.push({
            start: currentRangeStart,
            end: currentRangeEnd,
          });

          currentRangeStart = addDays(currentRangeEnd, 1);

          isFirstLoop = false;
        }
      }
    }

    return (
      <PageWithNavBar2 billingContext={true}>
        <h1>Work History</h1>
        <div className="mb-3 mt-3">
          <Filters
            onLoadReport={(filters: IFilters) => {
              this.loadReport(filters);
              this.setState({
                filters,
              });
            }}
            visibleColumns={this.state.visibleColumns}
            onVisibleColumnsChange={(newValue) => {
              this.props.setUserSetting({
                type: UserSettingsType.completedWorkColumns,
                value: newValue,
              });
              this.setState({
                visibleColumns: newValue,
              });
            }}
          />
        </div>

        {!!errorMessage ? (
          <div className="text-danger">{errorMessage}</div>
        ) : null}

        {loading ? (
          <Spinner />
        ) : !filters ? (
          <LoadReportMessage />
        ) : !!customers ? (
          <ReportContents
            reportData={customers}
            formatDateForReportDisplay={formatDateForReportDisplay}
            dateRanges={dateRanges.map((d) => {
              return {
                start: dateService.formatAsIso(d.start),
                end: dateService.formatAsIso(d.end),
              };
            })}
            showNotes={this.showNotes}
            showBillingDetails={this.showBillingDetails}
            visibleColumns={this.state.visibleColumns}
          />
        ) : null}

        <NotesModal
          imagePrefix={this.props.imagePrefix}
          customerForNotes={this.state.customerForNotes}
          closeNotes={this.closeNotes}
          formatDateForReportDisplay={formatDateForReportDisplay}
        />
        <BillingDetailsModal
          customerForBillingDetails={this.state.customerForBillingDetails}
          closeBillingDetails={this.closeBillingDetails}
          formatDateForReportDisplay={formatDateForReportDisplay}
        />
      </PageWithNavBar2>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  imagePrefix: state.common.imagePrefix || "",
  loading: state.billingReport.loading,
  customers: state.billingReport.customers,
  errorMessage: state.billingReport.errorMessage,
  userSettings: state.userSettings.userSettings,
});

const mapDispatchToProps = {
  billingReportStartLoad: actionCreators.billingReportStartLoad,
  setUserSetting: userSettingsActionCreators.setUserSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);
