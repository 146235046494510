import { Control, Controller } from "react-hook-form";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import Files from "../../../../containers/app/components/files/Index";
import { isPhoto } from "../../../../services/fileService";
import { IFormData } from "./IFormData";

export default function FilesField({
  control,
  setFileUploading,
}: {
  control: Control<IFormData, object>;
  setFileUploading: (newValue: boolean) => void;
}) {
  const tenantId = useApplicationStateSelector((s) => s.common.tenantId);
  const imagePrefix = useApplicationStateSelector((s) => s.common.imagePrefix);

  return (
    <div className="form-group">
      <Controller
        name={`files`}
        control={control}
        render={({ field }) => (
          <Files
            header="Attachments"
            showHeader={true}
            hideLabel={true}
            files={field.value}
            tenantId={tenantId ?? ""}
            imagePrefix={imagePrefix ?? ""}
            allowExcelAndWord={true}
            onFileUploadingStatusChange={(hasPendingFileAdd) => {
              setFileUploading(hasPendingFileAdd);
            }}
            onFileAdded={(photo) => {
              field.onChange([...field.value, photo]);
            }}
            onFileRemoved={(photoId, imagePath) => {
              field.onChange(
                field.value.filter((p) => !isPhoto(photoId, imagePath, p))
              );
            }}
            onFileUpdated={(photoId, imagePath, caption) => {
              field.onChange(
                field.value.map((p) => {
                  if (isPhoto(photoId, imagePath, p)) {
                    return {
                      ...p,
                      caption,
                    };
                  } else {
                    return p;
                  }
                })
              );
            }}
          />
        )}
      />
    </div>
  );
}
