import React, { useState } from "react";
import { IAction } from "../../../../modules/actionTypeDefinitions";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import ContainerHeader from "./ContainerHeader";

interface IProps<T> {
  pageHeader: string;
  entityNameForDelete?: string;
  promptSubMessage?: string;
  items: Array<T>;
  showEditForm(item: T): any;
  showPrompt: (
    promptMessage: string,
    promptSaveText: string | null,
    promptSubMessage: string | null,
    confirmationAction: IAction
  ) => any;
  deleteItem(item: T): any;
  getTitle: (item: T) => string;
  getFields: (item: T) => Array<IField<T>>;
  addButton: JSX.Element;
  messageToShow: string | JSX.Element;
  additionalButton?: IAdditionalButton<T>;

  showInactiveFilter?: boolean;
}

interface IIdAndInactive {
  id: string;
  inactive?: boolean;
}

interface IAdditionalButton<T> {
  text: string;
  onClick: (item: T) => void;
}

export interface IField<T> {
  label: string;
  getValue: (item: T) => string;
  testId?: string;
}

const Container: <T extends IIdAndInactive>(
  p: IProps<T>
) => React.ReactElement<IProps<T>> = ({
  getFields,
  pageHeader,
  entityNameForDelete,
  items,
  showEditForm,
  showPrompt,
  promptSubMessage,
  deleteItem,
  getTitle,
  addButton,
  messageToShow,
  showInactiveFilter,
  additionalButton,
}) => {
  const fieldContainer: React.CSSProperties = {
    marginRight: "50px",
    marginBottom: "30px",
    flexShrink: 0,
  };

  const [showInactive, setShowInactive] = useState(false);

  const filteredItems = items.filter(
    (i) => !showInactiveFilter || showInactive || !i.inactive
  );

  const errors = useApplicationStateSelector((s) => s.manageUi.errors);

  return (
    <ContainerHeader
      addButton={addButton}
      itemCount={filteredItems.length}
      messageToShow={messageToShow}
      showInactive={showInactive}
      onInactiveFilterChanged={(showInactive) => setShowInactive(showInactive)}
      pageHeader={pageHeader}
      showInactiveFilter={showInactiveFilter}
    >
      {filteredItems.map((item) => {
        const errorMessage = errors[item.id];
        return (
          <div key={item.id} className="card" style={{ marginBottom: "30px" }}>
            <div className="card-body">
              <h4 className="card-title" data-testid="title">
                {getTitle(item)}
              </h4>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {getFields(item).map((f) => (
                  <div style={fieldContainer} key={f.label}>
                    <h6>{f.label}</h6>
                    <span data-testid={f.testId}>{f.getValue(item)}</span>
                  </div>
                ))}
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {additionalButton ? (
                  <div style={{ marginRight: "20px", marginBottom: "10px" }}>
                    <button
                      type="button"
                      className="btn btn-secondary btn-sm"
                      onClick={() => {
                        additionalButton.onClick(item);
                      }}
                    >
                      {additionalButton.text}
                    </button>
                  </div>
                ) : null}
                <div style={{ marginRight: "20px", marginBottom: "10px" }}>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={() => {
                      showEditForm(item);
                    }}
                  >
                    Edit {entityNameForDelete}
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={(e) => {
                      showPrompt(
                        `Are you sure you want to delete this ${entityNameForDelete}?`,
                        "Delete",
                        promptSubMessage || null,
                        deleteItem(item)
                      );
                    }}
                  >
                    Delete {entityNameForDelete}
                  </button>
                </div>
              </div>
              {!!errorMessage ? (
                <div
                  className="text-danger"
                  style={{ marginTop: "10px" }}
                  data-testid="errorMessage"
                >
                  {errorMessage}
                </div>
              ) : null}
            </div>
          </div>
        );
      })}
    </ContainerHeader>
  );
};

export default Container;
