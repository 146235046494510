import React, { CSSProperties } from "react";
import photoService from "../../../../services/fileService";
import IPhoto from "../../../../models/IPhoto";
import { round } from "../../../../services/roundingService";
import { isStringSet } from "../../../../services/stringService";
import dateService from "../../../../services/dateService";

interface IProps {
  file: IPhoto;
  imagePrefix: string;
  onOpenLightbox: () => void;
  divStyle?: Partial<CSSProperties>;
  thumbnailKey?: 350 | 280;
  forcedThumbnailWidth?: number;
}

export const ReadOnlyImage: React.FunctionComponent<IProps> = ({
  file,
  imagePrefix,
  onOpenLightbox,
  divStyle,
  thumbnailKey,
  forcedThumbnailWidth,
}) => {
  const photoProps = photoService.getPhotoPropertiesForImgSrc(
    imagePrefix,
    file.id as string,
    file.thumbnails,
    thumbnailKey ?? 350
  );

  let height = photoProps?.height;
  let width = photoProps?.width;
  if (forcedThumbnailWidth && height && width) {
    const ratio = height / width;

    width = forcedThumbnailWidth;
    height = round(ratio * forcedThumbnailWidth, 0);
  }

  return (
    <div style={{ marginBottom: "25px", ...(divStyle ?? {}) }}>
      {!!photoProps ? (
        <div style={{ width }}>
          <button
            className="btn btn-link"
            style={{ padding: 0 }}
            type="button"
            onClick={() => {
              onOpenLightbox();
            }}
          >
            <img src={photoProps.url} height={height} width={width} alt="" />
            {file.caption ? <div>{file.caption}</div> : null}
          </button>
          {isStringSet(file.timestamp) ? (
            <div className="text-center mt-2" data-testid="timestampContainer">
              {dateService.formatDateTimeForDateTimeDisplay(file.timestamp)}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
