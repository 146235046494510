import { createStore, applyMiddleware, compose } from "redux";
import { rootReducer, rootEpic, IApplicationState } from "./modules";
import { createEpicMiddleware } from "redux-observable";
import { createBrowserHistory } from "history";
import { routerMiddleware, RouterState } from "connected-react-router";
import { trackPageView } from "./services/applicationInsights";

const epicMiddleware = createEpicMiddleware();
const history = createBrowserHistory();
history.listen((l) => {
  trackPageView(l.pathname);
});

const initialState = {};
const enhancers = [];
const middleware = [epicMiddleware, routerMiddleware(history)];

const windowAny: any = window;

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = windowAny.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }

  //enhancers.push(persistState());
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const configureStore = () => {
  const store = createStore(
    rootReducer(history),
    initialState,
    composedEnhancers
  );
  epicMiddleware.run(rootEpic);

  // TODO: Get HMR working for epics.  Check if also needed for services.
  if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
      module.hot.accept("./modules", () => {
        store.replaceReducer(rootReducer(history));
      });
    }
  }

  return store;
};

export interface IRootState extends IApplicationState {
  router: RouterState;
}
export default configureStore;
export { history };

export function actionSanitizer(action: { [key: string]: any }) {
  if (action?.type === "forms/customerpaymentmethod/COMPLETE_SAVING") {
    return {
      ...action,
      payload: action?.payload
        ? {
            ...action.payload,
            token: undefined,
          }
        : undefined,
    };
  }

  return action;
}
