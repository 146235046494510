import React from "react";
import { connect } from "react-redux";
import FormContainer from "../components/FormContainer";
import { actionCreators } from "../../../modules/actionCreators";
import { IRootState } from "../../../store";
import { ICustomCrewQuestion } from "../../../models/ICustomCrewQuestion";
import { ICrewViewConfiguration } from "../../../models/ICrewViewConfiguration";
import { ICrewCategory } from "../../../models/ICrewCategory";
import { getSortedItems } from "../../../services/sortingService";
import uuidv4 from "uuid/v4";
import TimeInput from "../components/TimeInput";
import { ICategoryIdentifier } from "../../../models/ICategoryIdentifier";
import { CrewAllowedNextSchedulesToPull } from "../../../enums/crewAllowedNextSchedulesToPull";
import InfoToolTip from "../components/InfoToolTip";
import { CrewScheduleType } from "../../../slices/schedule/enums/crewScheduleType";
import { ICrew } from "../../../models/ICrew";
import { ITenantFeatureFlags } from "../../../models/ITenantFeatureFlags";

interface ICustomCrewQuestionForFormData extends ICustomCrewQuestion {
  tempId: string;
}

interface IProps {
  crewViewConfiguration: ICrewViewConfiguration;
  showForm: boolean;
  errorMessage: string | React.ReactNode;
  saving: boolean;
  startSave(formData: any): void;
  cancel(): void;
  setErrorMessage(errorMessage: string): void;
  crewCategories: Array<ICrewCategory>;
  crews: Array<ICrew>;
  featureFlags: ITenantFeatureFlags | null;
}

interface IState {
  formData: IFormData;
  crewCategory: ICategoryIdentifier | null;
  originalShowPayrollTimeTracking: boolean;
}

interface IFormData {
  hideCustomerNameOnCrewView: boolean;
  hideCustomerPhoneNumberOnCrewView: boolean;
  hideEstimatedTimeOnCrewView: boolean;
  showCustomerEmailAddressOnCrewView: boolean;
  showCustomerJobHistory: boolean;
  customCrewQuestions: Array<ICustomCrewQuestionForFormData>;
  showDayClockInClockOut: boolean;
  showPayrollTimeTracking: boolean;
  showCrewEstimatedTimes: boolean;
  showLastVisitDate: boolean;
  showGrossRevenuePerVisitToCrew: boolean;
  showJobLineItemsOnCrewView: boolean;
  allowCrewsToSkipJobs: boolean;
  allowCrewsToPullFromNextDaysSchedule: boolean;
  typicalStartTime: string;
  typicalLunchStartTime: string;
  typicalLunchEndTime: string;
  crewAllowedToPullScheduleRange: string;
}

class CrewViewConfigurationForm extends React.Component<IProps, IState> {
  private lastCustomQuestionRef: React.RefObject<HTMLInputElement>;

  constructor(props: IProps) {
    super(props);

    this.state = {
      formData: this.getDefaultFormData(),
      crewCategory: null,
      originalShowPayrollTimeTracking: false,
    };

    this.lastCustomQuestionRef = React.createRef();

    this.getFormData = this.getFormData.bind(this);
    this.updateText = this.updateText.bind(this);
    this.removeTodoItem = this.removeTodoItem.bind(this);
    this.addTodoItem = this.addTodoItem.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidUpdate(prevProps: IProps) {
    const props = this.props;
    if (prevProps.showForm !== this.props.showForm && props.showForm) {
      const formData = this.getDefaultFormData();
      this.setState({
        formData,
        crewCategory: null,
        originalShowPayrollTimeTracking:
          props.crewViewConfiguration.showPayrollTimeTracking,
      });
    }
  }

  private hasTimeBasedCrews() {
    return this.props.crews.some(
      (c) => c.inactive === false && c.scheduleType === CrewScheduleType.time
    );
  }

  private hasSequenceBasedCrews() {
    return this.props.crews.some(
      (c) =>
        c.inactive === false && c.scheduleType === CrewScheduleType.sequence
    );
  }

  getDefaultFormData(): IFormData {
    return {
      hideCustomerNameOnCrewView:
        this.props.crewViewConfiguration.hideCustomerNameOnCrewView,
      showCustomerEmailAddressOnCrewView:
        this.props.crewViewConfiguration.showCustomerEmailAddressOnCrewView,
      showCustomerJobHistory:
        this.props.crewViewConfiguration.showCustomerJobHistory,
      hideCustomerPhoneNumberOnCrewView:
        this.props.crewViewConfiguration.hideCustomerPhoneNumberOnCrewView,
      hideEstimatedTimeOnCrewView:
        this.props.crewViewConfiguration.hideEstimatedTimeOnCrewView,
      showDayClockInClockOut:
        this.props.crewViewConfiguration.showDayClockInClockOut,
      showPayrollTimeTracking:
        this.props.crewViewConfiguration.showPayrollTimeTracking,
      showCrewEstimatedTimes:
        this.props.crewViewConfiguration.showCrewEstimatedTimes,
      showLastVisitDate: this.props.crewViewConfiguration.showLastVisitDate,
      showGrossRevenuePerVisitToCrew:
        this.props.crewViewConfiguration.showGrossRevenuePerVisitToCrew,
      allowCrewsToSkipJobs:
        this.props.crewViewConfiguration.allowCrewsToSkipJobs,
      allowCrewsToPullFromNextDaysSchedule:
        this.props.crewViewConfiguration.allowCrewsToPullFromNextDaysSchedule,
      typicalStartTime: this.props.crewViewConfiguration.typicalStartTime || "",
      typicalLunchStartTime:
        this.props.crewViewConfiguration.typicalLunchStartTime || "",
      typicalLunchEndTime:
        this.props.crewViewConfiguration.typicalLunchEndTime || "",
      customCrewQuestions:
        this.props.crewViewConfiguration.customCrewQuestions.map((q) => ({
          ...q,
          tempId: uuidv4(),
        })),
      crewAllowedToPullScheduleRange: this.props.crewViewConfiguration
        ?.crewAllowedToPullScheduleRange
        ? this.props.crewViewConfiguration.crewAllowedToPullScheduleRange.toString()
        : CrewAllowedNextSchedulesToPull.NextDay.toString(),
      showJobLineItemsOnCrewView:
        this.props.crewViewConfiguration.showJobLineItemsOnCrewView,
    };
  }

  updateText(tempId: string, question: string) {
    const customCrewQuestions = this.state.formData.customCrewQuestions.map(
      (q) => {
        if (q.tempId === tempId) {
          return {
            ...q,
            question,
          };
        } else {
          return q;
        }
      }
    );

    this.setState({
      formData: {
        ...this.state.formData,
        customCrewQuestions,
      },
    });
  }

  removeTodoItem(tempId: string) {
    const customCrewQuestions = this.state.formData.customCrewQuestions.filter(
      (q) => q.tempId !== tempId
    );

    this.setState({
      formData: {
        ...this.state.formData,
        customCrewQuestions,
      },
    });
  }

  addTodoItem() {
    const customCrewQuestions = [...this.state.formData.customCrewQuestions];

    customCrewQuestions.push({
      id: "",
      question: "",
      crewCategory: this.state.crewCategory
        ? {
            id: this.state.crewCategory.id,
          }
        : null,
      tempId: uuidv4(),
    });

    this.setState({
      formData: {
        ...this.state.formData,
        customCrewQuestions,
      },
    });

    setTimeout(() => {
      if (this.lastCustomQuestionRef.current) {
        this.lastCustomQuestionRef.current.focus();
      }
    });
  }

  getFormData() {
    const formDataToSave: any = {
      ...this.state.formData,
    };

    if (!formDataToSave.showCrewEstimatedTimes) {
      delete formDataToSave.typicalStartTime;
      delete formDataToSave.typicalLunchStartTime;
      delete formDataToSave.typicalLunchEndTime;
    }

    return formDataToSave;
  }

  validate() {
    const hasEmptyQuestion = this.state.formData.customCrewQuestions.reduce(
      (hasEmpty, val) => hasEmpty || (val.question || "").trim().length === 0,
      false
    );
    if (hasEmptyQuestion) {
      return {
        valid: false,
        errorMessage: "All custom crew questions must have their text set.",
      };
    }

    if (
      this.state.formData.showCrewEstimatedTimes &&
      !!this.state.formData.typicalLunchStartTime !==
        !!this.state.formData.typicalLunchEndTime
    ) {
      return {
        valid: false,
        errorMessage:
          "Either neither or both of the 'Typical crew lunch time' fields need to be entered.",
      };
    }

    return { valid: true };
  }

  render() {
    const { formData, crewCategory } = this.state;
    const {
      showForm,
      startSave,
      cancel,
      errorMessage,
      saving,
      setErrorMessage,
    } = this.props;

    const customCrewQuestionsFiltered = formData.customCrewQuestions.filter(
      (question) => {
        if (!crewCategory) {
          return !question.crewCategory?.id;
        } else {
          return (
            question.crewCategory &&
            question.crewCategory.id === crewCategory.id
          );
        }
      }
    );

    return (
      <FormContainer
        setErrorMessage={setErrorMessage}
        validate={this.validate}
        getFormData={this.getFormData}
        formHeader="Crew View Display"
        showForm={showForm}
        errorMessage={errorMessage}
        saving={saving}
        startSave={startSave}
        cancel={cancel}
        formKey="crew"
      >
        <h5>Display</h5>
        <div className="form-section">
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="allowCrewsToPullFromNextDaysSchedule"
                checked={formData.allowCrewsToPullFromNextDaysSchedule}
                onChange={(e) => {
                  let value = e.target.checked;
                  this.setState({
                    formData: {
                      ...formData,
                      allowCrewsToPullFromNextDaysSchedule: value,
                    },
                  });
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="allowCrewsToPullFromNextDaysSchedule"
              >
                Pull jobs from future day schedules
              </label>
              {this.props.featureFlags?.techApp ? (
                <InfoToolTip
                  id="allowCrewsToPullFromNextDaysScheduleInfo"
                  text="Only applies to dispatched schedules. Schedules opened through the app always allow viewing the next and previous week."
                />
              ) : null}
            </div>
          </div>
          {formData.allowCrewsToPullFromNextDaysSchedule ? (
            <div className="form-group">
              <select
                aria-label="Next schedules crews can pull from"
                className="form-control"
                value={formData.crewAllowedToPullScheduleRange.toString()}
                onChange={(e) =>
                  this.setState({
                    formData: {
                      ...formData,
                      crewAllowedToPullScheduleRange: e.currentTarget.value,
                    },
                  })
                }
              >
                <option
                  value={CrewAllowedNextSchedulesToPull.NextDay.toString()}
                >
                  Tomorrow
                </option>
                <option
                  value={CrewAllowedNextSchedulesToPull.FullWeek.toString()}
                >
                  Full week
                </option>
              </select>
            </div>
          ) : null}
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="allowCrewsToSkipJobs"
                checked={formData.allowCrewsToSkipJobs}
                onChange={(e) => {
                  let value = e.target.checked;
                  this.setState({
                    formData: {
                      ...formData,
                      allowCrewsToSkipJobs: value,
                    },
                  });
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="allowCrewsToSkipJobs"
              >
                Ability to skip jobs
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="showGrossRevenuePerVisitToCrew"
                checked={formData.showGrossRevenuePerVisitToCrew}
                onChange={(e) => {
                  let value = e.target.checked;
                  this.setState({
                    formData: {
                      ...formData,
                      showGrossRevenuePerVisitToCrew: value,
                    },
                  });
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="showGrossRevenuePerVisitToCrew"
              >
                Amount
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customerEmailAddressOnCrewView"
                checked={formData.showCustomerEmailAddressOnCrewView}
                onChange={(e) => {
                  this.setState({
                    formData: {
                      ...formData,
                      showCustomerEmailAddressOnCrewView: e.target.checked,
                    },
                  });
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="customerEmailAddressOnCrewView"
              >
                Customer email address
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customerNameOnCrewView"
                checked={!formData.hideCustomerNameOnCrewView}
                onChange={(e) => {
                  let value = !e.target.checked;
                  this.setState({
                    formData: {
                      ...formData,
                      hideCustomerNameOnCrewView: value,
                    },
                  });
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="customerNameOnCrewView"
              >
                Customer name
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customerPhoneNumberOnCrewView"
                checked={!formData.hideCustomerPhoneNumberOnCrewView}
                onChange={(e) => {
                  let value = !e.target.checked;
                  this.setState({
                    formData: {
                      ...formData,
                      hideCustomerPhoneNumberOnCrewView: value,
                    },
                  });
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="customerPhoneNumberOnCrewView"
              >
                Customer phone number
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="showCustomerJobHistory"
                checked={formData.showCustomerJobHistory}
                onChange={(e) => {
                  let value = e.target.checked;
                  this.setState({
                    formData: {
                      ...formData,
                      showCustomerJobHistory: value,
                    },
                  });
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="showCustomerJobHistory"
              >
                Customer job history
              </label>
              <InfoToolTip
                id="jobHistoryDetails"
                text={
                  <>
                    Allow your Crew members to view{" "}
                    <a
                      href="/assets/JobHistory.png"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      historic job notes
                    </a>
                    .
                  </>
                }
              />
            </div>
          </div>
          {this.hasSequenceBasedCrews() ? (
            <React.Fragment>
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="showCrewEstimatedTimes"
                    checked={formData.showCrewEstimatedTimes}
                    onChange={(e) => {
                      let value = e.target.checked;
                      this.setState({
                        formData: {
                          ...formData,
                          showCrewEstimatedTimes: value,
                        },
                      });
                    }}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="showCrewEstimatedTimes"
                    style={{ display: "inline" }}
                  >
                    Estimated arrival and completion times
                  </label>
                  {this.hasTimeBasedCrews() ? (
                    <InfoToolTip
                      id="crewEstimatedTimesInfo"
                      text={
                        <>
                          Used for calculating times on sequenced based crew
                          schedules only.
                        </>
                      }
                    />
                  ) : null}
                </div>
              </div>
              {formData.showCrewEstimatedTimes && (
                <>
                  <div className="form-row">
                    <div className="form-group col-md-5 text-nowrap">
                      <label htmlFor="typicalStartTime" className="required">
                        Typical crew start time
                      </label>
                      <TimeInput
                        className="form-control"
                        name="typicalStartTime"
                        disabled={!formData.showCrewEstimatedTimes}
                        required={formData.showCrewEstimatedTimes}
                        value={formData.typicalStartTime}
                        id="typicalStartTime"
                        onChange={(time) =>
                          this.setState({
                            formData: {
                              ...formData,
                              typicalStartTime: time,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-5 text-nowrap">
                      <label className="required">
                        Typical crew lunch time
                      </label>
                      <TimeInput
                        aria-label="Typical crew lunch time start"
                        className="form-control"
                        name="typicalLunchStartTime"
                        disabled={!formData.showCrewEstimatedTimes}
                        required={formData.showCrewEstimatedTimes}
                        value={formData.typicalLunchStartTime}
                        id="typicalLunchStartTime"
                        onChange={(time) =>
                          this.setState({
                            formData: {
                              ...formData,
                              typicalLunchStartTime: time,
                            },
                          })
                        }
                      />
                    </div>
                    <div className="col-md-1 mb-3">
                      <label className="d-none d-md-block">&nbsp;</label>
                      <div style={{ textAlign: "center" }}>to</div>
                    </div>
                    <div className="form-group col-md-5">
                      <label className="d-none d-md-block">&nbsp;</label>
                      <TimeInput
                        aria-label="Typical crew lunch time end"
                        className="form-control"
                        name="typicalLunchEndTime"
                        disabled={!formData.showCrewEstimatedTimes}
                        required={formData.showCrewEstimatedTimes}
                        value={formData.typicalLunchEndTime}
                        id="typicalLunchEndTime"
                        onChange={(time) =>
                          this.setState({
                            formData: {
                              ...formData,
                              typicalLunchEndTime: time,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            </React.Fragment>
          ) : null}
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="estimatedTimeOnCrewView"
                checked={!formData.hideEstimatedTimeOnCrewView}
                onChange={(e) => {
                  let value = !e.target.checked;
                  this.setState({
                    formData: {
                      ...formData,
                      hideEstimatedTimeOnCrewView: value,
                    },
                  });
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="estimatedTimeOnCrewView"
              >
                Estimated time
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="showJobLineItemsOnCrewView"
                checked={formData.showJobLineItemsOnCrewView}
                onChange={(e) => {
                  let value = e.target.checked;
                  this.setState({
                    formData: {
                      ...formData,
                      showJobLineItemsOnCrewView: value,
                    },
                  });
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="showJobLineItemsOnCrewView"
              >
                Job line items
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="showLastVisitDate"
                checked={formData.showLastVisitDate}
                onChange={(e) => {
                  let value = e.target.checked;
                  this.setState({
                    formData: {
                      ...formData,
                      showLastVisitDate: value,
                    },
                  });
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="showLastVisitDate"
              >
                Last visit date
              </label>
            </div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="showPayrollTimeTracking"
                checked={formData.showPayrollTimeTracking}
                onChange={(e) => {
                  let value = e.target.checked;
                  this.setState({
                    formData: {
                      ...formData,
                      showPayrollTimeTracking: value,
                    },
                  });
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="showPayrollTimeTracking"
              >
                Payroll time tracking
              </label>
            </div>
          </div>
        </div>
        <div className="form-section">
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="showDayClockInClockOut"
                checked={formData.showDayClockInClockOut}
                onChange={(e) => {
                  let value = e.target.checked;
                  this.setState({
                    formData: {
                      ...formData,
                      showDayClockInClockOut: value,
                    },
                  });
                }}
              />
              <label
                className="custom-control-label"
                htmlFor="showDayClockInClockOut"
              >
                Track shop times
              </label>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between mb-3">
          <h5 style={{ whiteSpace: "nowrap" }} className="mr-4">
            Custom Questions
          </h5>
          <select
            className="form-control form-control-sm"
            aria-label="Category"
            value={crewCategory?.id ?? ""}
            onChange={(e) => {
              if (e.currentTarget.value !== "") {
                const crewCategory = this.props.crewCategories.find(
                  (cc) => cc.id === e.currentTarget.value
                );

                if (crewCategory) {
                  this.setState({ crewCategory });
                }
              } else {
                this.setState({ crewCategory: null });
              }
            }}
          >
            <option value="">All categories</option>
            {getSortedItems(
              this.props.crewCategories.filter((cc) => !cc.inactive),
              "name"
            ).map((cc) => (
              <option value={cc.id} key={cc.id}>
                {cc.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-section">
          <div className="form-group">
            {customCrewQuestionsFiltered.length === 0 ? (
              <div>
                <small className="text-muted">
                  No custom crew questions are set.
                </small>
              </div>
            ) : null}

            {customCrewQuestionsFiltered.map((t, index) => (
              <div key={t.tempId} className="input-group mb-3">
                <input
                  aria-label="Custom crew question"
                  type="text"
                  className="form-control"
                  value={t.question}
                  onChange={(e) => {
                    this.updateText(t.tempId, e.target.value);
                  }}
                  ref={
                    index === customCrewQuestionsFiltered.length - 1
                      ? this.lastCustomQuestionRef
                      : null
                  }
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm"
                    onClick={() => this.removeTodoItem(t.tempId)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.addTodoItem()}
              style={{ marginTop: "15px" }}
            >
              Add New Custom Crew Question
            </button>
          </div>
        </div>
      </FormContainer>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  showForm: state.forms.crewViewConfiguration.showForm,
  errorMessage: state.forms.crewViewConfiguration.errorMessage,
  saving: state.forms.crewViewConfiguration.saving,
  crewViewConfiguration: state.common.crewViewConfiguration,
  crewCategories: state.common.crewCategories,
  originalTimeZone: state.common.timeZone,
  crews: state.crew.crews,
  featureFlags: state.common.featureFlags,
});

const mapDispatchToProps = {
  startSave: actionCreators.forms.crewViewConfiguration.startSaving,
  cancel: actionCreators.forms.crewViewConfiguration.cancelForm,
  setErrorMessage: actionCreators.forms.crewViewConfiguration.setErrorMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CrewViewConfigurationForm);
