import { IJob, JobType } from "../models/IJob";
import { IOneTimeJob } from "../models/IOneTimeJob";
import {
  IMaintenanceJob,
  MaintenanceJobFrequency,
  ISeasonalDate,
} from "../models/IMaintenanceJob";
import { parse, getMonth, getDate } from "date-fns";
import { getSortedItemsV2 } from "./sortingService";
import jobFinder from "./jobFinder";
import { IJobInstance } from "../models/IJobInstance";
import { getNameForJob } from "./jobService";
import { ICustomer } from "../models/ICustomer";
import { ICustomerAdditionalLocation } from "../models/ICustomerAdditionalLocation";

function getFrequencyForDisplay(input: MaintenanceJobFrequency | null) {
  if (input === null) {
    return "";
  }

  switch (input) {
    case MaintenanceJobFrequency.Custom:
      return "Custom";
    case MaintenanceJobFrequency.EveryTwoWeeks:
      return "Every 2 weeks";
    case MaintenanceJobFrequency.EveryFourWeeks:
      return "Every 4 weeks";
    case MaintenanceJobFrequency.EverySixWeeks:
      return "Every 6 weeks";
    case MaintenanceJobFrequency.Weekly:
      return "Weekly";
    case MaintenanceJobFrequency.None:
      return "Inactive";
    case MaintenanceJobFrequency.Daily:
      return "Daily";
    case MaintenanceJobFrequency.Monthly:
      return "Monthly";
    default:
      return "";
  }
}

const getCrewJobsOrderedByTime = (
  jobInstances: IJobInstance[],
  maintenanceJobs: IMaintenanceJob[],
  oneTimeJobs: IOneTimeJob[],
  customers: ICustomer[],
  customerAdditionalLocations: ICustomerAdditionalLocation[]
) => {
  return getSortedItemsV2(jobInstances, [
    "startTime",
    // Calculate if end time is actually next day
    (jobInstance) =>
      jobInstance.endTime &&
      jobInstance.startTime &&
      jobInstance.endTime >= jobInstance.startTime
        ? 0
        : 1,
    "endTime",
    (jobInstance) => {
      let name = "";

      const job = jobFinder.getJobForDayScheduleV2(
        maintenanceJobs,
        oneTimeJobs,
        jobInstance
      );

      if (job) {
        name = getNameForJob({
          job,
          customers,
          customerAdditionalLocations,
          fallbackToAddressIfAdditionalLocationNameNotSet: false,
        });
      }

      return name;
    },
  ]);
};

const getJobFrequencyForDisplay = (
  maintenanceJob: IMaintenanceJob | null,
  oneTimeJob: IOneTimeJob | null,
  frequency: MaintenanceJobFrequency | null,
  seasonalFrequency: MaintenanceJobFrequency | null,
  seasonalStart: ISeasonalDate | null,
  seasonalEnd: ISeasonalDate | null,
  dates: Array<string>
) => {
  if (maintenanceJob) {
    frequency = maintenanceJob.frequency;
    seasonalFrequency = maintenanceJob.seasonalScheduleFrequency;
    seasonalStart = maintenanceJob.seasonalScheduleStart;
    seasonalEnd = maintenanceJob.seasonalScheduleEnd;
  }

  if (oneTimeJob || frequency === null) {
    return "One time job";
  }

  const hasNonSeasonalJob =
    dates.reduce((acc, date) => {
      return acc || !isInSeasonalPeriod(date, seasonalStart, seasonalEnd);
    }, false) && frequency !== null;

  const hasSeasonalJob =
    dates.reduce((acc, date) => {
      return acc || isInSeasonalPeriod(date, seasonalStart, seasonalEnd);
    }, false) && seasonalFrequency !== null;

  if (hasNonSeasonalJob && hasSeasonalJob && frequency !== seasonalFrequency) {
    return `${getFrequencyForDisplay(frequency)} / ${getFrequencyForDisplay(
      seasonalFrequency
    )}`;
  } else if (hasNonSeasonalJob) {
    return getFrequencyForDisplay(frequency);
  } else if (hasSeasonalJob) {
    return getFrequencyForDisplay(seasonalFrequency);
  } else {
    return "";
  }
};

function isInSeasonalPeriod(
  date: string,
  seasonalScheduleStart: ISeasonalDate | null,
  seasonalScheduleEnd: ISeasonalDate | null
) {
  if (!seasonalScheduleStart || !seasonalScheduleEnd) {
    return false;
  }
  const parsedDate = parse(date);
  // + 1 to normalize to format for seasonal month
  const jobsMonth = getMonth(parsedDate) + 1;
  const jobsDayOfMonth = getDate(parsedDate);

  const normalizedSeasonalScheduleStart = getNormalizedDate(
    seasonalScheduleStart
  );
  const normalizedSeasonalScheduleEnd = getNormalizedDate(seasonalScheduleEnd);
  const normalizedDayScheduleDate = getNormalizedDate({
    month: jobsMonth,
    dayOfMonth: jobsDayOfMonth,
  });

  if (normalizedSeasonalScheduleStart > normalizedSeasonalScheduleEnd) {
    return (
      normalizedDayScheduleDate >= normalizedSeasonalScheduleStart ||
      normalizedDayScheduleDate <= normalizedSeasonalScheduleEnd
    );
  } else if (normalizedSeasonalScheduleEnd > normalizedSeasonalScheduleStart) {
    return (
      normalizedDayScheduleDate >= normalizedSeasonalScheduleStart &&
      normalizedDayScheduleDate <= normalizedSeasonalScheduleEnd
    );
  }

  return false;
}

function getNormalizedDate(input: ISeasonalDate) {
  return new Date(2000, input.month, input.dayOfMonth);
}

function getEstimatedManHours(
  date: string | undefined,
  jobType: JobType,
  job: IJob
) {
  if (jobType === JobType.oneTimeJob) {
    return job.estimatedManHours;
  } else {
    const maintenanceJob: IMaintenanceJob = job as IMaintenanceJob;
    if (
      date !== undefined &&
      isInSeasonalPeriod(
        date,
        maintenanceJob.seasonalScheduleStart,
        maintenanceJob.seasonalScheduleEnd
      )
    ) {
      return maintenanceJob.seasonalScheduleEstimatedManHours;
    } else {
      return maintenanceJob.estimatedManHours;
    }
  }
}

const JobHelper = {
  getJobType: (job: IJob) => {
    // TODO: Is this sufficient?
    if ((job as IOneTimeJob).date) {
      return JobType.oneTimeJob;
    } else {
      return JobType.maintenanceJob;
    }
  },
  getFrequencyForDisplay,
  getJobFrequencyForDisplay,
  isInSeasonalPeriod,
  getEstimatedManHours,
  getCrewJobsOrderedByTime,
};

export default JobHelper;
