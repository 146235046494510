import CustomerPaymentMethodForForms from "../../../containers/app/components/CustomerPaymentMethodForForms";
import {
  EmailSendFields,
  EmailSendValue,
} from "../../../containers/app/components/EmailSendFields";
import PhoneNumberField from "../../../containers/app/components/PhoneNumberField";
import SmsOptInCheckbox from "../../../containers/app/components/SmsOptInCheckbox";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { useCreditCardsEnabled } from "../../../hooks/useCreditCardsEnabled";
import { InvoiceDeliveryMethod } from "../../../models/InvoiceDeliveryMethod";
import PaymentMethodOnFileDisclosure from "../../../containers/app/components/PaymentMethodOnFileDisclosure";

export interface IProps {
  customerId: string;
  enableTexting: boolean;
  enableCrewControlPrinting?: boolean;

  deliveryMethod: string;
  setDeliveryMethod: (newValue: string) => void;

  emailFields: EmailSendValue;
  setEmailFields: (newValue: EmailSendValue) => void;

  customerPhoneNumber: string;
  setCustomerPhoneNumber: (newValue: string) => void;

  hideCustomerPhoneNumberOptedIntoSms?: boolean;
  customerPhoneNumberOptedIntoSms?: boolean;
  setCustomerPhoneNumberOptedIntoSms?: (newValue: boolean) => void;

  paymentMethodOnFileAuthorized?: boolean;
  setPaymentMethodOnFileAuthorized?: (newValue: boolean) => void;

  paymentMethodPreviouslyAuthorized?: boolean;
}

const InvoiceDeliveryMethodSelection: React.FunctionComponent<IProps> = ({
  customerId,
  enableTexting,
  enableCrewControlPrinting,
  deliveryMethod,
  setDeliveryMethod,
  emailFields,
  setEmailFields,
  customerPhoneNumber,
  setCustomerPhoneNumber,
  hideCustomerPhoneNumberOptedIntoSms,
  customerPhoneNumberOptedIntoSms,
  setCustomerPhoneNumberOptedIntoSms,
  paymentMethodOnFileAuthorized,
  setPaymentMethodOnFileAuthorized,
  paymentMethodPreviouslyAuthorized,
}) => {
  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );
  const quickBooksDeliveryAllowed = useApplicationStateSelector(
    (s) => s.common.quickBooksDeliveryAllowed
  );
  const { areCreditCardsEnabled } = useCreditCardsEnabled();
  const customerTextingAllowed = useApplicationStateSelector(
    (s) => s.common.optionalCapabilitiesAllowed.customerTexting
  );

  enableTexting = enableTexting && customerTextingAllowed;

  if (
    !enableTexting &&
    deliveryMethod === InvoiceDeliveryMethod.textWithCrewControl.toString()
  ) {
    setDeliveryMethod(InvoiceDeliveryMethod.emailWithCrewControl.toString());
  }

  const showQuickBooksDeliveryOptions =
    isQuickBooksEnabled && quickBooksDeliveryAllowed;

  return (
    <>
      <div className="form-row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="delivery" className="required">
              Delivery
            </label>
            <select
              id="delivery"
              className="form-control"
              value={deliveryMethod}
              onChange={(e) => setDeliveryMethod(e.currentTarget.value)}
            >
              {areCreditCardsEnabled ? (
                <option
                  value={InvoiceDeliveryMethod.paymentMethodOnFile.toString()}
                >
                  Payment method on file
                </option>
              ) : null}

              <option
                value={InvoiceDeliveryMethod.emailWithCrewControl.toString()}
              >
                {showQuickBooksDeliveryOptions
                  ? "Email with Crew Control"
                  : "Email"}
              </option>

              {enableTexting ? (
                <option
                  value={InvoiceDeliveryMethod.textWithCrewControl.toString()}
                >
                  {showQuickBooksDeliveryOptions
                    ? "Text with Crew Control"
                    : "Text message"}
                </option>
              ) : null}

              {enableCrewControlPrinting ? (
                <option
                  value={InvoiceDeliveryMethod.printWithCrewControl.toString()}
                >
                  {showQuickBooksDeliveryOptions
                    ? "Print with Crew Control"
                    : "Print"}
                </option>
              ) : null}

              {showQuickBooksDeliveryOptions ? (
                <>
                  <option
                    value={InvoiceDeliveryMethod.emailWithQuickBooks.toString()}
                  >
                    Email with QuickBooks
                  </option>
                  <option
                    value={InvoiceDeliveryMethod.printWithQuickBooks.toString()}
                  >
                    Send to QuickBooks to print
                  </option>
                </>
              ) : null}
            </select>
          </div>
        </div>
      </div>

      {getParsedDeliveryMethod(deliveryMethod) ===
        InvoiceDeliveryMethod.paymentMethodOnFile &&
      !paymentMethodPreviouslyAuthorized ? (
        <PaymentMethodOnFileDisclosure
          paymentMethodOnFileAuthorized={paymentMethodOnFileAuthorized}
          setPaymentMethodOnFileAuthorized={setPaymentMethodOnFileAuthorized}
        />
      ) : null}

      {getParsedDeliveryMethod(deliveryMethod) ===
      InvoiceDeliveryMethod.paymentMethodOnFile ? (
        <CustomerPaymentMethodForForms customerId={customerId} />
      ) : null}

      {getParsedDeliveryMethod(deliveryMethod) ===
        InvoiceDeliveryMethod.emailWithCrewControl ||
      getParsedDeliveryMethod(deliveryMethod) ===
        InvoiceDeliveryMethod.emailWithQuickBooks ||
      getParsedDeliveryMethod(deliveryMethod) ===
        InvoiceDeliveryMethod.paymentMethodOnFile ? (
        <EmailSendFields
          value={emailFields}
          onChange={setEmailFields}
          recipientHeaderLabel="Customer email addresses"
          recipientFieldLabel="Customer email address"
          hideReplyToField={
            getParsedDeliveryMethod(deliveryMethod) ===
            InvoiceDeliveryMethod.emailWithQuickBooks
          }
        />
      ) : null}

      {getParsedDeliveryMethod(deliveryMethod) ===
      InvoiceDeliveryMethod.textWithCrewControl ? (
        <>
          <div className="form-group">
            <label htmlFor="customerPhoneNumber" className="required">
              Customer phone number
            </label>
            <PhoneNumberField
              id="customerPhoneNumber"
              name="customerPhoneNumber"
              value={customerPhoneNumber}
              className="form-control"
              onChange={(e) => setCustomerPhoneNumber(e.currentTarget.value)}
            />
          </div>
          {typeof customerPhoneNumberOptedIntoSms === "boolean" &&
          !hideCustomerPhoneNumberOptedIntoSms ? (
            <SmsOptInCheckbox
              phoneNumber={customerPhoneNumber}
              checked={customerPhoneNumberOptedIntoSms}
              onCheckedChange={(newValue) => {
                if (typeof setCustomerPhoneNumberOptedIntoSms === "function") {
                  setCustomerPhoneNumberOptedIntoSms(newValue);
                }
              }}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default InvoiceDeliveryMethodSelection;

function getParsedDeliveryMethod(deliveryMethod: string) {
  return parseInt(deliveryMethod);
}
