import { SubscriptionFrequency } from "../../../enums/subscriptionFrequency";
import { TenantPlan } from "../../../enums/tenantPlan";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { formatCurrency } from "../../../services/currencyFormatter";
import { ISubscriptionDetails } from "../models/ISubscriptionDetails";
import {
  getSubscriptionPrice,
  GetSubscriptionPriceResult,
} from "../services/crewControlSubscriptionService";
import { SubscriptionPriceBreakdown } from "./SubscriptionPriceBreakdown";

export function SubscriptionPlanDetails({
  subscriptionDetails,
}: {
  subscriptionDetails: ISubscriptionDetails;
}) {
  const pricingUpdates = useApplicationStateSelector(
    (s) => s.common.featureFlags?.pricingUpdates ?? false
  );

  let costBreakdown: GetSubscriptionPriceResult | null = null;
  if (subscriptionDetails !== null) {
    costBreakdown = getSubscriptionPrice({
      tenantPlan: subscriptionDetails.plan,
      subscriptionType: subscriptionDetails.subscriptionType,
      subscriptionFrequency: subscriptionDetails.subscriptionFrequency,
      useUpdatedPricing: pricingUpdates,
      numberOfCrews: subscriptionDetails.activeCrewCount,
    });
  }

  return (
    <>
      <div className="font-weight-bold" data-testid="planSummary">
        {subscriptionDetails.plan === TenantPlan.plus ? "Plus" : "Basic"} (
        {subscriptionDetails.subscriptionFrequency ===
        SubscriptionFrequency.annual
          ? "Annual"
          : "Monthly"}
        )
      </div>

      {costBreakdown !== null &&
      costBreakdown.chargedAmount === subscriptionDetails.subscriptionAmount ? (
        <SubscriptionPriceBreakdown
          numberOfCrews={subscriptionDetails.activeCrewCount}
          subscriptionFrequency={subscriptionDetails.subscriptionFrequency}
          tooltipElementId={"manageSubscription"}
          subscriptionPriceResult={costBreakdown}
        />
      ) : null}

      {typeof subscriptionDetails.subscriptionAmount === "number" ? (
        <div data-testid="planCost">
          {formatCurrency(subscriptionDetails.subscriptionAmount)} per{" "}
          {subscriptionDetails.subscriptionFrequency ===
          SubscriptionFrequency.annual
            ? "year"
            : "month"}
        </div>
      ) : null}
    </>
  );
}
