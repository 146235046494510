export default {
  idForFirstJob: "00000000-0000-0000-0000-000000000000",
  idForLastJob: "FFFFFFFF-FFFF-FFFF-FFFF-FFFFFFFFFFFF",
  idForFirstOpportunity: "00000000-0000-0000-0000-000000000000",
  flexibleJobDroppableId: "UNSCHEDULED",
  droppableTypeJob: "JOB",
  droppableTypeOpportunity: "OPPORTUNITY",
  droppableTypeRow: "ROW",
  allCrewsConstant: "AllCrews",
  allCrewsTimeBasedConstant: "AllCrewsTimeBased",
  allCrewsSequenceConstant: "AllCrewsSequence",
  marginBottomForIntercom: "85px",
  unknownErrorMessage: "An unknown error occurred while saving.",
  crewBasicCostPerMonth: 30,
  crewPlusCostPerMonth: 50,
  technicianPlusCostPerMonth: 22,
  companyNameMaxLength: 250,
  firstNameMaxLength: 100,
  lastNameMaxLength: 200,
  customerNotificationMaximumMessageLength: 320,
  signUpReferralSessionStorageKey: "signUpReferral",
  planSessionStorageKey: "signupPlan",
  trialUnavailableSessionStorageKey: "directSignup",
  trialUnavailableQueryStringKey: "directsignup",
  invitationIdQueryString: "invitationId",
  authenticationRedirectSessionStorageKey: "redirect_path",
  quickBooksOnlineConnectedRedirect: "quickBooksOnlineConnectedRedirect",
  quickBooksReconnectRequired: "QuickBooksReconnectRequired",
  quickBooksInvoiceNumberAlreadyInUse: "QuickBooksInvoiceNumberAlreadyInUse",
  quickBooksDefaultTaxRateNotSet: "QuickBooksDefaultTaxRateNotSet",
  scheduleHideMapLinkText: "Hide map",
  scheduleShowMapLinkText: "Show map",
  defaultWorkingHoursStart: "07:00:00",
  defaultWorkingHoursEnd: "18:00:00",
  customerRecordTab: "tab",
  customerRecordSearchText: "searchText",
  flexibleDayOfWeekId: "0",

  listFilterMaxWidth: "400px",
  listFilterGap: "10px",
};

export const updatedPricingCosts = {
  // TODO: Basic or Base?
  crewBasicMonthlyCostPerMonth: 39,
  crewBasicAnnuallyCostPerMonth: 30,
  crewPlusMonthlyCostPerMonth: 59,
  crewPlusAnnuallyCostPerMonth: 49,
  techBasicMonthlyFixedCostPerMonth: 39,
  techBasicMonthlyAdditionalCostPerMonth: 22,
  techBasicAnnuallyFixedCostPerMonth: 30,
  techBasicAnnuallyAdditionalCostPerMonth: 15,
  techPlusMonthlyFixedCostPerMonth: 59,
  techPlusMonthlyAdditionalCostPerMonth: 22,
  techPlusAnnuallyFixedCostPerMonth: 40,
  techPlusAnnuallyAdditionalCostPerMonth: 15,
};
