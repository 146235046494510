import { actionTypes } from "./actionCreators";
import {
  IInitialLoadCompletedAction,
  ITodoTemplateDeleteComplete,
  ICrewCategoryAdd,
  IMerchantOnboardingSubmitted,
  ICustomerCommunicationTemplateUpdated,
  IInvoiceConfigurationUpdated,
  IReminderConfigurationUpdated,
  IProposalEmailConfigurationUpdated,
} from "./actionTypeDefinitions";
import {
  formTypes,
  actionTypes as formActionTypes,
  createSpecificActionTypeName,
} from "../formGenerator";
import { ICrewViewConfiguration } from "../models/ICrewViewConfiguration";
import { ITodoTemplate } from "../models/ITodoTemplate";
import { ICrewCategory } from "../models/ICrewCategory";
import { IAdminViewConfiguration } from "../models/IAdminViewConfiguration";
import { TimeZone, defaultTimeZone } from "../models/TimeZone";
import { ICustomerCategory } from "../models/ICustomerCategory";
import { IBaseCategory } from "../models/IBaseCategory";
import { TenantSubscriptionStatus } from "../models/IInitialLoad";
import { ICategoryIdentifier } from "../models/ICategoryIdentifier";
import { ICompanyLogo, ICompanyProfile } from "../models/ICompanyProfile";
import { ICustomerNotificationsConfiguration } from "../models/ICustomerNotificationsConfiguration";
import { ScheduledCommunication } from "../enums/scheduledCommunication";
import { IOptionalCapabilitiesAllowed } from "../models/IOptionalCapabilitiesAllowed";
import { TenantPlan } from "../enums/tenantPlan";
import { ICustomerCommunicationTemplate } from "../models/ICustomerCommunicationTemplate";
import { UserAccountRole } from "../enums/userAccountRole";
import { TenantIndustry } from "../enums/tenantIndustry";
import { IInvoiceConfiguration } from "../models/IInvoiceConfiguration";
import { CrewAllowedNextSchedulesToPull } from "../enums/crewAllowedNextSchedulesToPull";
import {
  ITemplatedMessage,
  ITemplatedMessageDefaults,
} from "../models/IITemplatedMessage";
import { ITenantFeatureFlags } from "../models/ITenantFeatureFlags";
import { SubscriptionType } from "../enums/subscriptionType";
import { IReminderConfiguration } from "../models/IReminderConfiguration";
import { IProposalEmailConfiguration } from "../models/IProposalEmailConfiguration";
import { IUserAccount } from "../models/IUserAccount";
import { TollFreeVerificationStatus } from "../enums/tollFreeVerificationStatus";
import { CrewScheduleType } from "../slices/schedule/enums/crewScheduleType";
import { SubscriptionFrequency } from "../enums/subscriptionFrequency";

export interface ICommonState {
  initialLoadState: InitialLoadState;
  isRegistered: boolean;
  tenantName: string | null;
  tenantId: string | null;
  tenantDateAddedUtc: string | null;
  userAccountId: string;
  userAccountEmail: string | null;
  userAccountRole: UserAccountRole;
  imagePrefix: string | null;
  crewViewConfiguration: ICrewViewConfiguration;
  adminViewConfiguration: IAdminViewConfiguration;
  invoiceConfiguration: IInvoiceConfiguration;
  customerNotificationsConfiguration: ICustomerNotificationsConfiguration;
  invoiceBeforeReminderConfiguration: IReminderConfiguration;
  invoiceAfterReminderConfiguration: IReminderConfiguration;
  proposalBeforeReminderConfiguration: IReminderConfiguration;
  proposalAfterReminderConfiguration: IReminderConfiguration;
  proposalEmailConfiguration: IProposalEmailConfiguration;
  todoTemplates: Array<ITodoTemplate>;
  crewCategories: Array<ICrewCategory>;
  customerCategories: Array<ICustomerCategory>;
  isQuickBooksEnabled: boolean;
  quickBooksDeliveryAllowed: boolean;
  industry: TenantIndustry | null;
  timeZone: TimeZone;
  anyJobExists: boolean;
  payrixPublicApiKey: string;
  payrixMerchantAccountId: string;
  payrixOnboardingAllowed: boolean;
  paymentsConvenienceFeePercentForDisplay: number;
  customerUrlRoot: string;
  tenantSubscriptionStatus: TenantSubscriptionStatus;
  subscriptionPaymentFailureUtc: string | null;
  tenantTrialDaysRemaining: number | null;
  canManageSubscription: boolean;
  isNonProductionEnvironment: boolean;
  optionalCapabilitiesAllowed: IOptionalCapabilitiesAllowed;
  featureFlags: ITenantFeatureFlags | null;
  tollFreeVerificationStatus: TollFreeVerificationStatus;
  customerTextingRequiresDedicatedNumber: boolean;
  customerTextingAllowedOnBasicPlan: boolean;
  tollFreeVerificationSubmittedDate: string | null;
  customerTextMessagesSentRecently: boolean | null;
  showDispatchPrompt: boolean;
  defaultCrewScheduleType: CrewScheduleType | null;

  contactPhoneNumber: string;
  companyLogo: ICompanyLogo | null;
  emailAddress: string;

  country: string | null;
  streetAndNumber: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  latitude: number | null;
  longitude: number | null;
  tenantPlan: TenantPlan | null;
  subscriptionFrequency: SubscriptionFrequency | null;

  invoiceTemplate: ICustomerCommunicationTemplate | null;
  contractBillingTemplate: ICustomerCommunicationTemplate | null;
  proposalTemplate: ICustomerCommunicationTemplate | null;
  customerNotificationTemplateOptions: Array<ITemplatedMessage>;
  customerNotificationTemplateDefaults: Array<ITemplatedMessageDefaults>;
  subscriptionType: SubscriptionType;
  userAccounts: Array<IUserAccount>;

  subscriptionCancellationPending: boolean;
  subscriptionRenewalDate: string | null;
}

export enum InitialLoadState {
  notStarted = 0,
  inProgress = 1,
  complete = 2,
  error = 3,
}

export default (state: ICommonState | undefined, action: any): ICommonState => {
  if (!state) {
    state = {
      initialLoadState: InitialLoadState.notStarted,
      isRegistered: false,
      tenantName: null,
      tenantId: null,
      tenantDateAddedUtc: null,
      userAccountId: "",
      userAccountEmail: null,
      userAccountRole: UserAccountRole.administrator,
      imagePrefix: null,
      crewViewConfiguration: {
        customCrewQuestions: [],
        hideCustomerNameOnCrewView: false,
        hideCustomerPhoneNumberOnCrewView: false,
        hideEstimatedTimeOnCrewView: false,
        showCustomerEmailAddressOnCrewView: false,
        showCrewEstimatedTimes: false,
        showDayClockInClockOut: false,
        showPayrollTimeTracking: false,
        showLastVisitDate: false,
        showCustomerJobHistory: false,
        showJobLineItemsOnCrewView: false,
        typicalStartTime: null,
        typicalLunchStartTime: null,
        typicalLunchEndTime: null,
        allowCrewsToSkipJobs: false,
        allowCrewsToPullFromNextDaysSchedule: false,
        crewAllowedToPullScheduleRange: CrewAllowedNextSchedulesToPull.NextDay,
        showGrossRevenuePerVisitToCrew: false,
      },
      adminViewConfiguration: {
        showActualTimeWorkedOnAdminJobCard: false,
        showActualManHoursOnAdminJobCard: false,
        showEstimatedTimeWorkedOnAdminJobCard: false,
        showEstimatedManHoursOnAdminJobCard: false,
        showLastVisitDateOnAdminJobCard: false,
        showPermanentChangePrompt: false,
        showGrossRevenueOnAdminJobCard: false,
        showCustomerPhoneNumberOnAdminJobCard: false,
        showCustomerEmailAddressOnAdminJobCard: false,
        showAlertForSkippedAndUncompletedJobs: false,
        showFrequencyOnJobCard: false,
        automaticallyMoveNotStartedJobs: false,
      },
      invoiceConfiguration: {
        captureCustomerPaymentMethod: false,
        taxRate: null,
        invoiceDefaultNumberOfDaysValid: null,
      },
      customerNotificationsConfiguration: {
        sendPreVisitNotification: ScheduledCommunication.Never,
        preVisitNotificationText: "",
        preVisitNotificationTime: null,
        preVisitSelectedTemplateId: null,
        preVisitVariables: {},

        sendCompleteNotification: false,
        completeNotificationText: "",
        completeSelectedTemplateId: null,
        completeVariables: {},
      },
      invoiceBeforeReminderConfiguration: {
        isEnabled: false,
        subject: "",
        message: "",
        replyToEmailAddress: "",
        firstReminderNumberOfDays: null,
        isSecondReminderEnabled: false,
        secondReminderNumberOfDays: null,
      },
      invoiceAfterReminderConfiguration: {
        isEnabled: false,
        subject: "",
        message: "",
        replyToEmailAddress: "",
        firstReminderNumberOfDays: null,
        isSecondReminderEnabled: false,
        secondReminderNumberOfDays: null,
      },
      proposalBeforeReminderConfiguration: {
        isEnabled: false,
        subject: "",
        message: "",
        replyToEmailAddress: "",
        firstReminderNumberOfDays: null,
        isSecondReminderEnabled: false,
        secondReminderNumberOfDays: null,
      },
      proposalAfterReminderConfiguration: {
        isEnabled: false,
        subject: "",
        message: "",
        replyToEmailAddress: "",
        firstReminderNumberOfDays: null,
        isSecondReminderEnabled: false,
        secondReminderNumberOfDays: null,
      },
      proposalEmailConfiguration: {
        includeDetails: false,
      },
      todoTemplates: [],
      crewCategories: [],
      customerCategories: [],
      isQuickBooksEnabled: false,
      quickBooksDeliveryAllowed: false,
      industry: null,
      timeZone: defaultTimeZone,
      anyJobExists: false,
      payrixMerchantAccountId: "",
      payrixPublicApiKey: "",
      payrixOnboardingAllowed: false,
      paymentsConvenienceFeePercentForDisplay: 0,
      customerUrlRoot: "",
      // Default to subscribed so no message shows until we know the real value
      tenantSubscriptionStatus: TenantSubscriptionStatus.Subscribed,
      subscriptionPaymentFailureUtc: null,
      tenantTrialDaysRemaining: null,
      canManageSubscription: false,
      isNonProductionEnvironment: true,
      companyLogo: null,
      contactPhoneNumber: "",
      emailAddress: "",
      country: null,
      streetAndNumber: null,
      city: null,
      state: null,
      zip: null,
      latitude: null,
      longitude: null,
      optionalCapabilitiesAllowed: {
        customerTexting: false,
        sales: false,
      },
      featureFlags: null,
      tenantPlan: null,
      subscriptionFrequency: null,
      invoiceTemplate: null,
      contractBillingTemplate: null,
      proposalTemplate: null,
      customerNotificationTemplateOptions: [],
      customerNotificationTemplateDefaults: [],
      subscriptionType: SubscriptionType.crew,
      customerTextMessagesSentRecently: null,
      tollFreeVerificationStatus: TollFreeVerificationStatus.notStarted,
      customerTextingRequiresDedicatedNumber: false,
      customerTextingAllowedOnBasicPlan: false,
      tollFreeVerificationSubmittedDate: null,
      showDispatchPrompt: false,
      userAccounts: [],
      defaultCrewScheduleType: null,
      subscriptionCancellationPending: false,
      subscriptionRenewalDate: null,
    };
  }

  switch (action.type) {
    case actionTypes.INITIAL_LOAD_STARTING:
      return {
        ...state,
        initialLoadState: InitialLoadState.inProgress,
      };

    case actionTypes.INITIAL_LOAD_COMPLETED:
      const initialLoadAction = action as IInitialLoadCompletedAction;
      return {
        ...state,
        initialLoadState: InitialLoadState.complete,
        isRegistered: initialLoadAction.isRegistered,
        tenantName: initialLoadAction.tenantName,
        tenantId: initialLoadAction.tenantId,
        imagePrefix: initialLoadAction.imagePrefix,
        tenantDateAddedUtc: initialLoadAction.tenantDateAddedUtc,
        userAccountId: initialLoadAction.userAccountId,
        userAccountEmail: initialLoadAction.userAccountEmail,
        userAccountRole: initialLoadAction.userAccountRole,
        crewViewConfiguration: initialLoadAction.tenantCrewViewConfiguration,
        adminViewConfiguration: initialLoadAction.tenantAdminViewConfiguration,
        invoiceConfiguration: initialLoadAction.invoiceConfiguration,
        customerNotificationsConfiguration:
          initialLoadAction.customerNotificationsConfiguration,
        invoiceBeforeReminderConfiguration:
          initialLoadAction.invoiceBeforeReminderConfiguration,
        invoiceAfterReminderConfiguration:
          initialLoadAction.invoiceAfterReminderConfiguration,
        proposalBeforeReminderConfiguration:
          initialLoadAction.proposalBeforeReminderConfiguration,
        proposalAfterReminderConfiguration:
          initialLoadAction.proposalAfterReminderConfiguration,
        proposalEmailConfiguration:
          initialLoadAction.proposalEmailConfiguration,
        todoTemplates: initialLoadAction.todoTemplates,
        crewCategories: initialLoadAction.crewCategories,
        customerCategories: initialLoadAction.customerCategories,
        isQuickBooksEnabled: initialLoadAction.isQuickBooksEnabled,
        quickBooksDeliveryAllowed: initialLoadAction.quickBooksDeliveryAllowed,
        industry: initialLoadAction.industry,
        timeZone: initialLoadAction.timeZone as TimeZone,
        anyJobExists: initialLoadAction.anyJobExists,
        payrixMerchantAccountId: initialLoadAction.payrixMerchantAccountId,
        payrixPublicApiKey: initialLoadAction.payrixPublicApiKey,
        payrixOnboardingAllowed: initialLoadAction.payrixOnboardingAllowed,
        paymentsConvenienceFeePercentForDisplay:
          initialLoadAction.paymentsConvenienceFeePercentForDisplay,
        customerUrlRoot: initialLoadAction.customerUrlRoot,
        tenantSubscriptionStatus: initialLoadAction.tenantSubscriptionStatus,
        subscriptionPaymentFailureUtc:
          initialLoadAction.subscriptionPaymentFailureDate,
        tenantTrialDaysRemaining: initialLoadAction.tenantTrialDaysRemaining,
        canManageSubscription: initialLoadAction.canManageSubscription,
        isNonProductionEnvironment:
          initialLoadAction.isNonProductionEnvironment,
        showDispatchPrompt: initialLoadAction.showDispatchPrompt,

        companyLogo: initialLoadAction.companyLogo,
        contactPhoneNumber: initialLoadAction.contactPhoneNumber,
        emailAddress: initialLoadAction.emailAddress,
        streetAndNumber: initialLoadAction.streetAndNumber,
        country: initialLoadAction.country,
        city: initialLoadAction.city,
        state: initialLoadAction.state,
        zip: initialLoadAction.zip,
        latitude: initialLoadAction.latitude,
        longitude: initialLoadAction.longitude,
        optionalCapabilitiesAllowed:
          initialLoadAction.optionalCapabilitiesAllowed ?? {
            customerTexting: false,
            sales: false,
          },
        featureFlags: initialLoadAction.featureFlags,
        customerTextMessagesSentRecently:
          initialLoadAction.customerTextMessagesSentRecently,
        tollFreeVerificationStatus:
          initialLoadAction.tollFreeVerificationStatus,
        customerTextingRequiresDedicatedNumber:
          initialLoadAction.customerTextingRequiresDedicatedNumber,
        customerTextingAllowedOnBasicPlan:
          initialLoadAction.customerTextingAllowedOnBasicPlan,
        tollFreeVerificationSubmittedDate:
          initialLoadAction.tollFreeVerificationSubmittedDate,
        tenantPlan: initialLoadAction.tenantPlan,
        subscriptionFrequency: initialLoadAction.subscriptionFrequency,
        invoiceTemplate: initialLoadAction.invoiceTemplate,
        contractBillingTemplate: initialLoadAction.contractBillingTemplate,
        proposalTemplate: initialLoadAction.proposalTemplate,
        customerNotificationTemplateOptions:
          initialLoadAction.customerNotificationTemplateOptions,
        customerNotificationTemplateDefaults:
          initialLoadAction.customerNotificationTemplateDefaults,
        subscriptionType: initialLoadAction.subscriptionType,
        userAccounts: initialLoadAction.userAccounts,
        defaultCrewScheduleType: initialLoadAction.defaultCrewScheduleType,
        subscriptionCancellationPending:
          initialLoadAction.subscriptionCancellationPending,
        subscriptionRenewalDate: initialLoadAction.subscriptionRenewalDate,
      };

    case actionTypes.INITIAL_LOAD_ERROR:
      return {
        ...state,
        initialLoadState: 3,
      };

    case createSpecificActionTypeName(
      formTypes.registerExistingCompany,
      formActionTypes.completeSaving
    ):
      // Trigger an initial load again so request goes in while logged in
      return {
        ...state,
        initialLoadState: 0,
      };

    case createSpecificActionTypeName(
      formTypes.registerNewCompany,
      formActionTypes.completeSaving
    ):
      // Trigger an initial load again so request goes in while logged in
      return {
        ...state,
        initialLoadState: 0,
      };

    case createSpecificActionTypeName(
      formTypes.crewViewConfiguration,
      formActionTypes.completeSaving
    ):
      return {
        ...state,
        crewViewConfiguration: action.payload
          .response as ICrewViewConfiguration,
      };

    case createSpecificActionTypeName(
      formTypes.adminViewConfiguration,
      formActionTypes.completeSaving
    ):
      return {
        ...state,
        adminViewConfiguration: action.payload as IAdminViewConfiguration,
      };

    case createSpecificActionTypeName(
      formTypes.customerNotificationsConfiguration,
      formActionTypes.completeSaving
    ):
      return {
        ...state,
        customerNotificationsConfiguration:
          action.payload as ICustomerNotificationsConfiguration,
      };

    case createSpecificActionTypeName(
      formTypes.todoTemplate,
      formActionTypes.completeSaving
    ):
      let todoTemplates;
      if (
        !action.payload.parameters ||
        !action.payload.parameters.todoTemplateId
      ) {
        todoTemplates = [
          ...state.todoTemplates,
          {
            ...action.payload,
          },
        ];
      } else {
        todoTemplates = state.todoTemplates.map((c) => {
          if (c.id === action.payload.parameters.todoTemplateId) {
            return {
              ...action.payload,
            };
          } else {
            return c;
          }
        });
      }

      return {
        ...state,
        todoTemplates,
      };

    case actionTypes.CUSTOMER_NOTIFICTAION_TEMPLATE_DEFAULTS_UPDATED:
      let customerNotificationTemplateDefaults =
        state.customerNotificationTemplateDefaults.filter(
          (d) => d.id !== action.templateId
        );
      return {
        ...state,
        customerNotificationTemplateDefaults: [
          ...customerNotificationTemplateDefaults,
          { id: action.templateId, variableDefaults: action.defaultValues },
        ],
      };

    case actionTypes.TODO_TEMPLATE_DELETE_COMPLETE:
      const todoTemplateDeleteAction = action as ITodoTemplateDeleteComplete;
      return {
        ...state,
        todoTemplates: state.todoTemplates.filter(
          (c) => c.id !== todoTemplateDeleteAction.todoTemplateId
        ),
      };

    case actionTypes.QUICKBOOKS_SET_NOT_ENABLED:
      return {
        ...state,
        isQuickBooksEnabled: false,
      };

    case createSpecificActionTypeName(
      formTypes.crew,
      formActionTypes.completeSaving
    ):
      return applyCrewCompleteSaving(state, action);

    case createSpecificActionTypeName(
      formTypes.maintenanceJob,
      formActionTypes.completeSaving
    ):
      return applyJobCompleteSaving(state, action);

    case createSpecificActionTypeName(
      formTypes.oneTimeJob,
      formActionTypes.completeSaving
    ):
      return applyJobCompleteSaving(state, action);

    case createSpecificActionTypeName(
      formTypes.customer,
      formActionTypes.completeSaving
    ):
      return applyCustomerCompleteSaving(state, action);

    case actionTypes.CREW_CATEGORY_ADD:
      const crewCategoryAddAction = action as ICrewCategoryAdd;
      return {
        ...state,
        crewCategories: [
          ...state.crewCategories,
          ...crewCategoryAddAction.categoriesToAdd.filter(
            (crewCategoryToAdd) =>
              !state?.crewCategories.some(
                (existingCrewCategory) =>
                  existingCrewCategory.name === crewCategoryToAdd.name
              )
          ),
        ],
      };

    case createSpecificActionTypeName(
      formTypes.subscription,
      formActionTypes.completeSaving
    ):
      return {
        ...state,
        tenantSubscriptionStatus: TenantSubscriptionStatus.Subscribed,
        canManageSubscription: true,
      };

    case actionTypes.CANCEL_SUBSCRIPTION:
      return {
        ...state,
        tenantSubscriptionStatus: TenantSubscriptionStatus.TrialExpired,
      };

    case createSpecificActionTypeName(
      formTypes.companyProfile,
      formActionTypes.completeSaving
    ):
      const companyProfileSaveCompletePayload =
        action.payload as ICompanyProfile;

      return {
        ...state,
        ...companyProfileSaveCompletePayload,
      };

    case createSpecificActionTypeName(
      formTypes.crewCategories,
      formActionTypes.completeSaving
    ):
      const crewCategoriesPayload = action.payload.updates as Array<
        Partial<ICrewCategory>
      >;

      return {
        ...state,
        crewCategories: state.crewCategories.map((c) => {
          const crewCategoryUpdate = crewCategoriesPayload.find(
            (p) => p.id === c.id
          );
          if (crewCategoryUpdate) {
            return {
              ...c,
              ...crewCategoryUpdate,
            };
          } else {
            return c;
          }
        }),
      };

    case actionTypes.MERCHANT_ONBOARDING_SUBMITTED:
      const merchantOnboardingSubmitedAction =
        action as IMerchantOnboardingSubmitted;
      return {
        ...state,
        payrixOnboardingAllowed: false,
        contactPhoneNumber: merchantOnboardingSubmitedAction.daytimePhone,
      };

    case actionTypes.CUSTOMER_COMMUNICATION_TEMPLATE_UPDATED:
      const customerCommunicationTemplateUpdatedAction =
        action as ICustomerCommunicationTemplateUpdated;

      let existingTemplate =
        state[customerCommunicationTemplateUpdatedAction.template];
      if (existingTemplate === null) {
        existingTemplate = {
          introText: "",
          footerText: "",
        };
      }

      return {
        ...state,
        [customerCommunicationTemplateUpdatedAction.template]: {
          ...existingTemplate,
          [customerCommunicationTemplateUpdatedAction.property]:
            customerCommunicationTemplateUpdatedAction.value,
        },
      };

    case actionTypes.REMINDER_CONFIGURATION_UPDATED:
      const reminderConfigurationAction =
        action as IReminderConfigurationUpdated;

      return {
        ...state,
        proposalBeforeReminderConfiguration:
          reminderConfigurationAction.proposalBeforeReminderConfiguration,
        proposalAfterReminderConfiguration:
          reminderConfigurationAction.proposalAfterReminderConfiguration,
        invoiceBeforeReminderConfiguration:
          reminderConfigurationAction.invoiceBeforeReminderConfiguration,
        invoiceAfterReminderConfiguration:
          reminderConfigurationAction.invoiceAfterReminderConfiguration,
      };

    case actionTypes.PROPOSAL_EMAIL_CONFIGURATION_UPDATED:
      const proposalEmailConfigurationAction =
        action as IProposalEmailConfigurationUpdated;

      return {
        ...state,
        proposalEmailConfiguration:
          proposalEmailConfigurationAction.configuration,
      };

    case actionTypes.INVOICE_CONFIGURATION_UPDATED:
      const invoiceConfigurationUpdatedAction =
        action as IInvoiceConfigurationUpdated;

      return {
        ...state,
        invoiceConfiguration:
          invoiceConfigurationUpdatedAction.invoiceConfiguration,
      };

    case actionTypes.TOLL_FREE_VERIFICATION_SUBMITTED:
      return {
        ...state,
        tollFreeVerificationStatus:
          TollFreeVerificationStatus.verificationSubmitted,
      };

    case createSpecificActionTypeName(
      formTypes.publishSchedule,
      formActionTypes.completeSaving
    ):
      return {
        ...state,
        showDispatchPrompt: false,
      };

    case actionTypes.INITIAL_DISPATCH_SENT:
      return {
        ...state,
        showDispatchPrompt: false,
      };

    default:
      return state;
  }
};

function applyCustomerCompleteSaving(state: ICommonState, action: any) {
  const castedState = state as ICommonState;
  const changedCategoriesFromSave = (action.payload.categories ??
    []) as Array<ICustomerCategory>;
  const removedCategories = (action.payload.deletedCategories ??
    []) as Array<ICategoryIdentifier>;

  return {
    ...state,
    customerCategories: getUpdateCategories(
      castedState.customerCategories,
      changedCategoriesFromSave
    ).filter(
      (category) =>
        !removedCategories.some(
          (removedCategory) => removedCategory.id === category.id
        )
    ),
  };
}

function applyCrewCompleteSaving(state: ICommonState, action: any) {
  const castedState = state as ICommonState;
  const changedCategoriesFromSave = (
    action.payload.changedCategories ? action.payload.changedCategories : []
  ) as Array<ICrewCategory>;

  return {
    ...state,
    defaultCrewScheduleType: action.payload.isAddedCrew
      ? action.payload.scheduleType
      : state.defaultCrewScheduleType,
    crewCategories: getUpdateCategories(
      castedState.crewCategories,
      changedCategoriesFromSave
    ),
  };
}

function applyJobCompleteSaving(state: ICommonState, action: any) {
  const castedState = state as ICommonState;
  const changedCategoriesFromSave = (
    action.payload.changedCategories ? action.payload.changedCategories : []
  ) as Array<ICrewCategory>;

  return {
    ...state,
    anyJobExists: true,
    crewCategories: getUpdateCategories(
      castedState.crewCategories,
      changedCategoriesFromSave
    ),
  };
}

function getUpdateCategories<TCategory extends IBaseCategory>(
  stateCategories: Array<TCategory>,
  changedCategories: Array<TCategory>
) {
  const updatedCategories = stateCategories.map((stateCategory) => {
    const matchingChangedCrewCategory = changedCategories.find(
      (changedCrewCategory) => changedCrewCategory.id === stateCategory.id
    );
    if (matchingChangedCrewCategory) {
      return matchingChangedCrewCategory;
    } else {
      return stateCategory;
    }
  });

  const newCategories = changedCategories.filter(
    (changedCrewCategory) =>
      !stateCategories.some(
        (stateCategory) => stateCategory.id === changedCrewCategory.id
      )
  );

  return [...updatedCategories, ...newCategories];
}
