import { EditorState, convertFromRaw } from "draft-js";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { DiscountType } from "../../../enums/DiscountType";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { InvoiceDeliveryMethod } from "../../../models/InvoiceDeliveryMethod";
import { getDefaultIntroText } from "../../../services/invoiceFormService";
import { isTemplateTextSet } from "../../../services/richTextService";
import { createEmailAddressRecord } from "../components/EmailAddresses.functions";
import { IInvoiceFormData } from "./InvoiceForm.types";

export function useCreateForm({
  passedInCustomerId,
}: {
  passedInCustomerId: string | null;
}) {
  const tenantName = useApplicationStateSelector((s) => s.common.tenantName);
  const invoiceTemplate = useApplicationStateSelector(
    (s) => s.common.invoiceTemplate
  );

  const defaultValues = {
    customerId: passedInCustomerId ?? "",
    quickBooksCustomerId: "",
    startingDate: "",
    dueDate: "",
    purchaseOrderNumber: "",
    lineItemsByLocation: [],
    amountAdjustments: {
      taxRate: null,
      discount: {
        type: DiscountType.percent,
        amount: null,
        percent: null,
      },
      depositCreditAmount: null,
      depositItem: {
        itemId: "",
        name: null,
        description: null,
      },
    },
    deliveryMethodDetails: {
      emailFields: {
        recipients: [createEmailAddressRecord("")],
        replyTo: "",
      },
      customerPhoneNumber: "",
      customerPhoneNumberOptedIntoSms: false,
      invoiceDeliveryMethod:
        InvoiceDeliveryMethod.printWithQuickBooks.toString(),
    },
    selectedWorkToBill: {
      selectedJobInstances: [],
      selectedProjects: [],
    },
    paymentMethodOptions: {
      addConvenienceFee: true,
      allowAchPayment: true,
      allowCreditCardPayment: true,
    },
    introTextState: EditorState.createWithContent(
      invoiceTemplate && isTemplateTextSet(invoiceTemplate.introText)
        ? convertFromRaw(JSON.parse(invoiceTemplate?.introText))
        : getDefaultIntroText(tenantName)
    ),
    footerTextState:
      invoiceTemplate && isTemplateTextSet(invoiceTemplate.footerText)
        ? EditorState.createWithContent(
            convertFromRaw(JSON.parse(invoiceTemplate?.footerText))
          )
        : EditorState.createEmpty(),
    maxDepositCreditAmount: null,
    depositCredits: [],
    files: [],
    showLineItemPrices: true,
    summary: "",
  } as IInvoiceFormData;

  const [originalFormValues, setOriginalFormValues] = useState(defaultValues);
  const [initialIntroText, setInitialIntroText] = useState<EditorState>(
    defaultValues.introTextState
  );
  const [initialFooterText, setInitialFooterText] = useState<EditorState>(
    defaultValues.footerTextState
  );

  const { control, watch, setValue, getValues, register } =
    useForm<IInvoiceFormData>({
      defaultValues,
    });

  const { invoiceDeliveryMethod: deliveryMethod } = watch(
    "deliveryMethodDetails"
  );
  const selectedWorkToBill = watch("selectedWorkToBill");
  const lineItemsByLocation = watch("lineItemsByLocation");
  const showLineItemPrices = watch("showLineItemPrices");
  const startingDate = watch("startingDate");
  const customerId = watch("customerId");
  const quickBooksCustomerId = watch("quickBooksCustomerId");

  return {
    control,
    setValue,
    getValues,
    register,
    deliveryMethod,
    selectedWorkToBill,
    lineItemsByLocation,
    originalFormValues,
    setOriginalFormValues,
    initialIntroText,
    setInitialIntroText,
    initialFooterText,
    setInitialFooterText,
    showLineItemPrices,
    startingDate,
    customerId,
    quickBooksCustomerId,
  };
}
