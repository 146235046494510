import ErrorJumbotron from "./ErrorJumbotron";

export default function ErrorLoadingGoogleMaps() {
  return (
    <>
      <ErrorJumbotron
        headerText="Google Maps not loaded"
        details={
          "Google Maps was not able to load. Please reload this page and try again."
        }
        showRefreshButton
        refreshButtonText="Reload Page"
      />
    </>
  );
}
