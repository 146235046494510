import { ICompleteSavingAction } from "../../formGenerator/actionTypes";
import { ISaveCustomerResponse } from "../../services/remoteDataProvider";
import { actionCreators } from "../actionCreators";
import { commonUiActionCreators } from "../commonUi";

export function handleCustomerCompleteSaving(
  action: ICompleteSavingAction<{}, ISaveCustomerResponse>
) {
  if (!action.payload.originalInactive && action.payload.inactive) {
    return commonUiActionCreators.showCustomerClearJobsModal({
      customerId: action.payload.id,
      customerName: action.payload.name ?? "",
    });
  } else {
    return actionCreators.null();
  }
}
