import { IAddress } from "../../../models/IAddress";
import { IWorkNotInvoicedCustomer } from "../../../models/IWorkNotInvoicedCustomer";
import { isStringSet } from "../../../services/stringService";

export function buildCustomerAddress(
  customer: IWorkNotInvoicedCustomer
): IAddress | null {
  if (
    !isStringSet(customer.streetAndNumber) &&
    !isStringSet(customer.city) &&
    !isStringSet(customer.state) &&
    !isStringSet(customer.zip) &&
    customer.latitude === null &&
    customer.longitude === null
  ) {
    return null;
  }

  return {
    streetAndNumber: customer.streetAndNumber,
    city: customer.city,
    state: customer.state,
    zip: customer.zip,
    latitude: customer.latitude,
    longitude: customer.longitude,
    latitudeSignificantDigits: null,
    longitudeSignificantDigits: null,
  };
}
