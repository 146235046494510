import React, { useCallback, useState } from "react";
import { Controller, UseFormGetValues, useForm } from "react-hook-form";
import FormContainerWithoutRedux from "../../../containers/app/components/FormContainerWithoutRedux";
import QuickBooksCustomerSelection from "../../../containers/app/components/QuickBooksCustomerSelection";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import { IInvoiceView } from "../../../models/IInvoice";
import { IQuickBooksCustomer } from "../../../models/IQuickBooksCustomer";
import { logError } from "../../../services/errorLogger";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ModalDataLoader from "../../../containers/app/components/ModalDataLoader";
import { IInvoiceItem } from "../../../models/IInvoiceItem";
import { forkJoin, throwError } from "rxjs";
import { getInvoiceItems } from "../../../services/invoiceFormService";
import { catchError, map, timeout } from "rxjs/operators";
import { ILineItem } from "../../../containers/app/components/InvoiceLineItem";
import { IInvoiceLineItem } from "../../../models/IInvoiceLineItem";
import { ICustomer } from "../../../models/ICustomer";
import FixInvoiceForQuickBooksItem from "./FixInvoiceForQuickBooksItem";
import ResponsiveTable from "../../../libraries/tableLayout/ResponsiveTable";
import LabelledField from "../../../containers/app/components/LabelledField";
import quickBooksDataProvider, {
  IFixInvoiceForQuickBooksChanges,
} from "../services/quickBooksDataProvider";
import constants from "../../../constants";
import {
  QuickBooksModalMode,
  commonUiActionCreators,
} from "../../../modules/commonUi";
import { useDispatch } from "react-redux";
import { DepositInvoiceItem } from "../../../containers/app/components/DepositInvoiceItem";
import { ErrorMessageType } from "../../../containers/app/components/FormContainer";

interface IFormData {
  quickBooksCustomerId: string;
  quickBooksDepositItemIdOverride: string;
  lineItems: Array<ILineItem>;
}

interface IProps {
  invoiceId: string;
  customerId: string;
  onSaveComplete: () => void;
  onCancel: () => void;
}

interface ILoadData {
  invoice: IInvoiceView;
  invoiceItems: Array<IInvoiceItem>;
  quickBooksCustomers: Array<IQuickBooksCustomer>;
  customer: ICustomer;
}

const FixInvoiceForQuickBooksForm: React.FunctionComponent<IProps> = ({
  invoiceId,
  customerId,
  onSaveComplete,
  onCancel,
}) => {
  const { control, setValue, getValues } = useForm<IFormData>({
    defaultValues: {},
  });
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");
  const [quickBooksCustomers, setQuickBooksCustomers] = useState<
    Array<IQuickBooksCustomer>
  >([]);
  const [invoiceItems, setInvoiceItems] = useState<Array<IInvoiceItem>>([]);
  const [showDepositField, setShowDepositField] = useState(false);
  const [customer, setCustomer] = useState<ICustomer | null>(null);
  const [originalLineItems, setOriginalLineItems] = useState<Array<ILineItem>>(
    []
  );
  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    return forkJoin({
      invoiceResponse: remoteDataProvider.getInvoices({
        invoiceIds: [invoiceId],
      }),
      items: getInvoiceItems(true),
      quickBooksCustomers: remoteDataProvider.getQuickBooksCustomers(),
      customer: remoteDataProvider.getCustomers({
        customerIds: [customerId],
      }),
    }).pipe(
      timeout(30000),
      map(({ invoiceResponse, items, quickBooksCustomers, customer }) => {
        if (invoiceResponse.list.length !== 1) {
          logError("Invoice not found.");
        }

        return {
          invoice: invoiceResponse.list[0],
          invoiceItems: items,
          quickBooksCustomers: quickBooksCustomers,
          customer: customer.customers[0],
        } as ILoadData;
      })
    );
  }, [invoiceId, customerId]);

  const handleLoadedData = useCallback(
    (result: ILoadData) => {
      function mapInvoiceLIneItemsToLineItems(
        invoiceLineItems: Array<IInvoiceLineItem> | null,
        invoiceItems: Array<IInvoiceItem>
      ): ILineItem[] {
        return invoiceLineItems && invoiceLineItems.length > 0
          ? invoiceLineItems.map((li) => {
              return {
                id: li.id,
                amountPerItem: li.amountPerItem
                  ? li.amountPerItem.toString()
                  : "",
                description: li.description,
                itemId: invoiceItems.some((i) => i.id === li.itemId)
                  ? li.itemId
                  : "",
                quantity: li.quantity ? li.quantity.toString() : "",
                taxable: li.taxable,
                hide: li.hide,
                name: li.name,
              };
            })
          : [];
      }

      setValue("quickBooksCustomerId", result.invoice.quickBooksCustomerId);
      setValue(
        "quickBooksDepositItemIdOverride",
        result.invoice.quickBooksDepositItemIdOverride ?? ""
      );
      setQuickBooksCustomers(result.quickBooksCustomers);
      setInvoiceItems(result.invoiceItems);

      let lineItemsResult = mapInvoiceLIneItemsToLineItems(
        result.invoice.lineItems,
        result.invoiceItems
      );

      setValue("lineItems", lineItemsResult);
      setOriginalLineItems(lineItemsResult);
      setCustomer(result.customer);
      setShowDepositField(
        typeof result.invoice.depositCreditAmount === "number" &&
          result.invoice.depositCreditAmount > 0
      );
    },
    [setValue]
  );

  return (
    <ModalDataLoader<ILoadData>
      errorMessage={
        "The data could not be loaded for this form. Please check your Internet connection and try again."
      }
      onErrorAlertClose={onCancel}
      loadData={loadData}
      onDataLoaded={handleLoadedData}
    >
      <FormContainerWithoutRedux
        formHeader={`Fix Invoice For ${customer?.name}`}
        formType={FormTypesV2.fixInvoiceForQuickBooksForm}
        saveButtonText="Send invoice to QuickBooks"
        size="xl"
        save={() => {
          return quickBooksDataProvider
            .fixInvoiceForQuickBooks({
              invoiceId: invoiceId,
              changes: getSavePayload(getValues),
            })
            .pipe(
              catchError((err) => {
                if (
                  err?.response?.errorCode ===
                  constants.quickBooksReconnectRequired
                ) {
                  dispatch(
                    commonUiActionCreators.showQuickBooksModal({
                      quickBooksModalMode: QuickBooksModalMode.reconnect,
                      quickBooksModalExplanationText:
                        "Your QuickBooks connection has expired. Please connect again to save.",
                    })
                  );
                  return throwError({
                    ...err,
                    response: {
                      errorCode:
                        "Your QuickBooks connection has expired and must be refreshed.",
                    },
                  });
                } else {
                  return throwError(err);
                }
              })
            );
        }}
        getCustomErrorMessage={(err) => {
          if (
            err !== null &&
            typeof err === "object" &&
            "response" in err &&
            err.response !== null &&
            typeof err.response === "object" &&
            "errorCode" in err.response &&
            err.response.errorCode === constants.quickBooksDefaultTaxRateNotSet
          ) {
            return (
              <>
                Your default tax rate is not configured in QuickBooks. Please
                follow the instructions "Set a default sales tax rate for all
                customers"{" "}
                <a
                  href="https://quickbooks.intuit.com/learn-support/en-us/help-article/sales-taxes/default-customer-sales-tax-rate-quickbooks-online/L9eklXl8w_US_en_US"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="text-danger"
                  style={{ textDecoration: "underline" }}
                >
                  here
                </a>
                .
              </>
            );
          }

          return null;
        }}
        onSaveComplete={onSaveComplete}
        onCancel={onCancel}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      >
        <div
          className="alert alert-info mt-1"
          style={{
            border: "0px",
            padding: "12px",
          }}
        >
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
          <small>
            Crew Control was unable to send this invoice to QuickBooks. Ensure
            all fields are entered and try again.
          </small>
        </div>
        <div className="form-group">
          <label htmlFor="customer" className="required">
            QuickBooks Customer
          </label>
          <Controller
            name={`quickBooksCustomerId`}
            control={control}
            render={({ field }) => (
              <QuickBooksCustomerSelection
                inputId="customer"
                customer={customer}
                options={quickBooksCustomers}
                quickBooksCustomerId={field.value}
                required={true}
                onChange={(id) => {
                  field.onChange(id);
                }}
                scrollIntoView
                onCreatedQuickBooksCustomer={({ id, displayName }) => {
                  setQuickBooksCustomers([
                    ...quickBooksCustomers,
                    {
                      id,
                      displayName,
                    } as IQuickBooksCustomer,
                  ]);
                }}
              />
            )}
          />
        </div>
        {showDepositField ? (
          <div className="form-group" data-testid="depositContainer">
            <label htmlFor="customer" className="required">
              Deposit item
            </label>
            <Controller
              name={`quickBooksDepositItemIdOverride`}
              control={control}
              render={({ field }) => (
                <DepositInvoiceItem
                  inputElementId="invoiceDepositItemId"
                  invoiceItems={invoiceItems}
                  setInvoiceItems={setInvoiceItems}
                  value={{
                    itemId: field.value,
                    name: null,
                    description: null,
                  }}
                  onChange={(newDepositItem) =>
                    field.onChange(newDepositItem.itemId)
                  }
                />
              )}
            />
          </div>
        ) : null}
        <div
          className="form-group"
          style={{ marginTop: "20px", marginBottom: "100px" }}
        >
          <Controller
            name={`lineItems`}
            control={control}
            render={({ field }) => (
              <ResponsiveTable
                tableClass="table-sm"
                rows={field.value.map((v) => {
                  let orignalLineItem = originalLineItems.filter(
                    (l) => l.id === v.id
                  )[0];
                  return {
                    id: v.id,
                    name: () => (
                      <div className="text-truncate">
                        {orignalLineItem.name}
                      </div>
                    ),
                    description: () => (
                      <div className="text-truncate text-wrap">
                        {orignalLineItem.description}
                      </div>
                    ),
                    item: () => (
                      <div style={{ minWidth: "350px" }}>
                        <FixInvoiceForQuickBooksItem
                          key={`FixInvoiceRowItem_${v.id}`}
                          lineItem={v}
                          invoiceItems={invoiceItems}
                          setInvoiceItems={setInvoiceItems}
                          onChange={(updated) => {
                            setValue(
                              "lineItems",
                              field.value.map((i) => {
                                if (i.id !== updated.id) {
                                  return i;
                                } else {
                                  return {
                                    ...updated,
                                  };
                                }
                              })
                            );
                          }}
                        />
                      </div>
                    ),
                  };
                })}
                columns={[
                  {
                    key: "name",
                    header: "Name",
                    cell: "name",
                  },
                  {
                    key: "description",
                    header: "Description",
                    cell: "description",
                  },
                  {
                    key: "item",
                    header: "Item",
                    cell: "item",
                  },
                ]}
                renderMobile={({ row, index }) => (
                  <div>
                    <div className="font-weight-bold mb-3">
                      Line Item {index + 1}
                    </div>

                    <LabelledField
                      style={{ marginRight: undefined }}
                      label="Name"
                      value={row.name()}
                      containerClassName="mb-2"
                      testId="nameMobileValue"
                    />

                    <LabelledField
                      style={{ marginRight: undefined }}
                      label="Description"
                      value={row.description()}
                      containerClassName="mb-2"
                      testId="descriptionMobileValue"
                    />

                    <LabelledField
                      style={{ marginRight: undefined }}
                      label="Item"
                      value={row.item()}
                      testId="itemMobileValue"
                    />
                  </div>
                )}
              />
            )}
          ></Controller>
        </div>
      </FormContainerWithoutRedux>
    </ModalDataLoader>
  );
};

export default FixInvoiceForQuickBooksForm;

function getSavePayload(
  getValues: UseFormGetValues<IFormData>
): IFixInvoiceForQuickBooksChanges {
  const values = getValues();

  return {
    quickBooksCustomerId: values.quickBooksCustomerId,
    quickBooksDepositItemIdOverride: values.quickBooksDepositItemIdOverride,
    lineItemIds: values.lineItems.map((li) => {
      return { id: li.id, quickBooksLineItemId: li.itemId };
    }),
  };
}
