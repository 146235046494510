import React from "react";
import { IFormsState } from "../../../formGenerator/formReducers";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";

interface IProps {
  formType: keyof IFormsState;
}

const ConditionalFormContainer: React.FunctionComponent<IProps> = ({
  formType,
  children,
}) => {
  const showForm = useApplicationStateSelector(
    (s) => s.forms[formType].showForm
  );

  return showForm ? <React.Fragment>{children}</React.Fragment> : null;
};

export default ConditionalFormContainer;
