import { ICompleteSavingAction } from "../../formGenerator/actionTypes";
import { ISaveCrewMemberResponse } from "../../services/remoteDataProvider";
import { actionCreators } from "../actionCreators";
import { commonUiActionCreators } from "../commonUi";

export function handleCrewMemberCompleteSaving(
  action: ICompleteSavingAction<{}, ISaveCrewMemberResponse>
) {
  if (
    !action.payload.originalAllowMobileApplication &&
    action.payload.allowMobileApplication &&
    action.payload.id &&
    action.payload.name
  ) {
    return commonUiActionCreators.showCrewMemberInvitationModal({
      crewMemberId: action.payload.id,
      crewMemberName: action.payload.name,
    });
  } else {
    return actionCreators.null();
  }
}
