import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, useRef } from "react";
import { IInvoiceItem } from "../../../models/IInvoiceItem";
import {
  createNewLineItem,
  getDefaultServiceDate,
} from "../../../services/invoiceFormService";
import { getNumericString } from "../../../services/typeConverter";
import { IInvoiceJobInstance } from "../forms/InvoiceForm.types";
import {
  AmountPerItemField,
  CheckboxField,
  DeleteLineItemButton,
  ILineItem,
  QuantityField,
  ServiceDateField,
  TaxableField,
} from "./InvoiceLineItem";
import { InvoiceItemField } from "./InvoiceLineItemInvoiceItemField";
import ResponsiveGridTable from "../../../libraries/tableLayout/ResponsiveGridTable";
import { isTaxRateDisabled } from "../../../services/billableItemService";

interface IProps {
  lineItems: Array<ILineItem>;
  selectedJobInstances?: Array<string>;
  jobInstances?: Array<IInvoiceJobInstance>;
  invoiceItems: Array<IInvoiceItem> | null;
  onClearErrorMessage: () => void;
  showHideOption?: boolean;
  showServiceDate?: boolean;
  elementIdPrefix: string;
  noLineItemMessage?: string;

  setLineItems: (newLineItems: Array<ILineItem>) => void;
  setInvoiceItems: (newValue: Array<IInvoiceItem>) => void;

  allowBlankQuantity?: boolean;
  allowBlankAmountPerItem?: boolean;
  allowZeroLineItems?: boolean;
  hideHeader?: boolean;
  allowOptionalItems?: boolean;
  customerTaxExempt: boolean;
  taxRateAlreadySet: boolean;
}

const InvoiceLineItems: React.FunctionComponent<IProps> = ({
  lineItems,
  jobInstances,
  selectedJobInstances,
  invoiceItems,
  showHideOption,
  showServiceDate,
  setLineItems,
  setInvoiceItems,
  onClearErrorMessage,
  elementIdPrefix,
  noLineItemMessage,

  allowBlankAmountPerItem,
  allowBlankQuantity,
  allowZeroLineItems,
  allowOptionalItems,

  hideHeader,

  customerTaxExempt,
  taxRateAlreadySet,
}) => {
  const buildOnLineItemChanged = (li: ILineItem) => {
    return (newLineItem: ILineItem) => {
      setLineItems(
        lineItems.map((lineItemToUpdate) => {
          if (lineItemToUpdate.id === li.id) {
            return newLineItem;
          } else {
            return lineItemToUpdate;
          }
        })
      );
    };
  };

  const isDeleteAllowed = lineItems.length > 1 || allowZeroLineItems;

  return (
    <div data-testid="lineItemContainer">
      {!hideHeader ? (
        <div>
          <h6>Line Items</h6>
        </div>
      ) : null}

      {lineItems.length > 0 ? (
        <ResponsiveGridTable
          breakpoint="lg"
          mobileHeader={(lineItem, index) => {
            return (
              <MobileHeader
                lineItems={lineItems}
                index={index}
                setLineItems={setLineItems}
                isDeleteAllowed={isDeleteAllowed}
                lineItem={lineItem}
              />
            );
          }}
          columns={[
            {
              key: "reorder",
              header: () => "",
              cell: ({ index, displayType }) =>
                lineItems.length > 1 && displayType === "desktop" ? (
                  <ReorderButtons
                    index={index}
                    lineItems={lineItems}
                    setLineItems={setLineItems}
                  />
                ) : null,
              width: "min-content",
            },
            {
              header: ({ displayType, rowIndex }) => (
                <label
                  htmlFor={getInputElementId({
                    property: "serviceDate",
                    index: rowIndex ?? 0,
                    displayType: displayType,
                  })}
                >
                  Service date
                </label>
              ),
              key: "serviceDate",
              width: "min-content",
              cell: ({ row: lineItem, index, displayType }) => {
                return (
                  <div style={{ minWidth: "110px" }}>
                    <ServiceDateField
                      lineItem={lineItem}
                      onLineItemChanged={buildOnLineItemChanged(lineItem)}
                      inputElementId={getInputElementId({
                        property: "serviceDate",
                        index,
                        displayType,
                      })}
                    />
                  </div>
                );
              },
              hidden: !showServiceDate,
            },
            {
              header: ({ displayType, rowIndex }) => (
                <label
                  htmlFor={getInputElementId({
                    property: "item",
                    index: rowIndex ?? 0,
                    displayType: displayType,
                  })}
                  className="required"
                >
                  Product / Service
                </label>
              ),
              key: "item",
              width: "auto",
              cell: ({ row: lineItem, index, displayType }) => {
                return (
                  <>
                    <InvoiceItemField
                      lineItem={lineItem}
                      onLineItemChanged={buildOnLineItemChanged(lineItem)}
                      items={invoiceItems}
                      onClearErrorMessage={onClearErrorMessage}
                      jobInstances={jobInstances}
                      selectedJobInstances={selectedJobInstances ?? []}
                      setInvoiceItems={setInvoiceItems}
                      ariaLabel="Product/service"
                      inputElementId={getInputElementId({
                        property: "item",
                        index,
                        displayType,
                      })}
                      includeDescription={true}
                      descriptionElementId={getInputElementId({
                        property: "description",
                        index,
                        displayType,
                      })}
                      onItemAdded={(
                        id,
                        name,
                        unitPrice,
                        description,
                        taxable
                      ) => {
                        setInvoiceItems([
                          ...(invoiceItems ?? []),
                          {
                            id,
                            name,
                            unitPrice,
                            description,
                            taxable,
                            inactive: false,
                          },
                        ]);
                      }}
                      onItemUpdated={({
                        id,
                        name,
                        description,
                        inactive,
                        unitPrice,
                        taxable,
                      }) => {
                        handleOnItemUpdated({
                          invoiceItems,
                          id,
                          lineItems,
                          name,
                          unitPrice,
                          taxable,
                          description,
                          setLineItems,
                          setInvoiceItems,
                          inactive,
                        });
                      }}
                    />
                  </>
                );
              },
            },
            {
              header: ({ displayType, rowIndex }) => (
                <label
                  htmlFor={getInputElementId({
                    property: "optional",
                    index: rowIndex ?? 0,
                    displayType: displayType,
                  })}
                  className="mr-2"
                >
                  Optional
                </label>
              ),
              key: "optional",
              width: "min-content",
              hidden: !allowOptionalItems,
              cell: ({ row: lineItem, index, displayType }) => {
                return (
                  <CheckboxField
                    property={"optional"}
                    inputElementId={getInputElementId({
                      property: "optional",
                      index,
                      displayType,
                    })}
                    lineItem={lineItem}
                    onLineItemChanged={buildOnLineItemChanged(lineItem)}
                    checkboxClassName={`pendoOptionalCheckbox${displayType}`}
                    testId="optionalCheckbox"
                  />
                );
              },
            },
            {
              header: ({ displayType, rowIndex }) => (
                <label
                  htmlFor={getInputElementId({
                    property: "quantity",
                    index: rowIndex ?? 0,
                    displayType: displayType,
                  })}
                  className={`${allowBlankQuantity ? "" : "required"} mr-2`}
                >
                  Quantity
                </label>
              ),
              key: "quantity",
              width: "min-content",
              cell: ({ row: lineItem, index, displayType }) => {
                return (
                  <QuantityField
                    allowBlankQuantity={allowBlankQuantity}
                    lineItem={lineItem}
                    onLineItemChanged={buildOnLineItemChanged(lineItem)}
                    inputElementId={getInputElementId({
                      property: "quantity",
                      index,
                      displayType,
                    })}
                  />
                );
              },
            },
            {
              header: ({ displayType, rowIndex }) => (
                <label
                  htmlFor={getInputElementId({
                    property: "amountPerItem",
                    index: rowIndex ?? 0,
                    displayType: displayType,
                  })}
                  className={`${
                    allowBlankAmountPerItem ? "" : "required"
                  } mr-2`}
                >
                  Amount per item
                </label>
              ),
              key: "amountPerItem",
              width: "min-content",
              cell: ({ row: lineItem, index, displayType }) => {
                return (
                  <AmountPerItemField
                    allowBlankAmountPerItem={allowBlankAmountPerItem}
                    inputElementId={getInputElementId({
                      property: "amountPerItem",
                      index,
                      displayType,
                    })}
                    lineItem={lineItem}
                    onLineItemChanged={buildOnLineItemChanged(lineItem)}
                  />
                );
              },
            },
            {
              header: ({ displayType, rowIndex }) => (
                <label
                  htmlFor={getInputElementId({
                    property: "taxable",
                    index: rowIndex ?? 0,
                    displayType: displayType,
                  })}
                >
                  Taxable
                </label>
              ),
              key: "taxable",
              width: "min-content",
              hidden: isTaxRateDisabled({
                customerTaxExempt,
                taxRateAlreadySet,
              }),
              cell: ({ row: lineItem, index, displayType }) => {
                return (
                  <TaxableField
                    inputElementId={getInputElementId({
                      property: "taxable",
                      index,
                      displayType,
                    })}
                    lineItem={lineItem}
                    onLineItemChanged={buildOnLineItemChanged(lineItem)}
                    checkboxClassName={`pendoTaxableCheckbox${displayType}`}
                    items={invoiceItems}
                    defaultTaxable={
                      invoiceItems?.find((i) => i.id === lineItem.itemId)
                        ?.taxable
                    }
                    onItemUpdated={({
                      id,
                      name,
                      description,
                      inactive,
                      unitPrice,
                      taxable,
                    }) => {
                      handleOnItemUpdated({
                        invoiceItems,
                        id,
                        lineItems,
                        name,
                        unitPrice,
                        taxable,
                        description,
                        setLineItems,
                        setInvoiceItems,
                        inactive,
                      });
                    }}
                  />
                );
              },
            },
            {
              header: ({ displayType, rowIndex }) => (
                <label
                  htmlFor={getInputElementId({
                    property: "hide",
                    index: rowIndex ?? 0,
                    displayType: displayType,
                  })}
                >
                  Hide
                </label>
              ),
              key: "hide",
              width: "min-content",
              cell: ({ row: lineItem, index, displayType }) => {
                return (
                  <CheckboxField
                    property={"hide"}
                    inputElementId={getInputElementId({
                      property: "hide",
                      index,
                      displayType,
                    })}
                    lineItem={{
                      ...lineItem,
                      hide: lineItem.optional ? false : lineItem.hide,
                    }}
                    onLineItemChanged={buildOnLineItemChanged(lineItem)}
                    disabled={lineItem.optional ?? false}
                    testId="hideCheckbox"
                  />
                );
              },
              hidden: !showHideOption,
            },
            {
              header: () => "",
              key: "delete",
              width: "min-content",
              cell: ({ row: lineItem, displayType }) => {
                return isDeleteAllowed ? (
                  <DeleteLineItemButton
                    lineItem={lineItem}
                    lineItems={lineItems}
                    setLineItems={setLineItems}
                    displayType={displayType}
                  />
                ) : null;
              },
              hidden: !isDeleteAllowed,
              hideOnMobile: true,
            },
          ]}
          rows={lineItems}
        />
      ) : (
        <div className="font-weight-light">
          {noLineItemMessage ?? "No line items are set"}
        </div>
      )}

      <div>
        <button
          className="btn btn-secondary btn-sm mt-3"
          type="button"
          onClick={() => {
            setLineItems([
              ...lineItems,
              createNewLineItem(
                getQuantityForNewLineItem({
                  selectedJobInstances,
                  allowBlankQuantity,
                }),
                showServiceDate
                  ? getDefaultServiceDate({
                      jobInstances,
                      selectedJobInstances,
                    })
                  : undefined
              ),
            ]);
          }}
          data-testid="addLineItem"
        >
          Add Line Item
        </button>
      </div>
    </div>
  );

  function getInputElementId({
    property,
    index,
    displayType,
  }: {
    property: string;
    index: number;
    displayType: string;
  }) {
    return `${elementIdPrefix}${property}_${index}_${displayType}`;
  }
};

export default InvoiceLineItems;

function handleOnItemUpdated({
  invoiceItems,
  id,
  lineItems,
  name,
  unitPrice,
  taxable,
  description,
  setLineItems,
  setInvoiceItems,
  inactive,
}: {
  invoiceItems: IInvoiceItem[] | null;
  id: string;
  lineItems: ILineItem[];
  name: string;
  unitPrice: number | null;
  taxable: boolean;
  description: string;
  setLineItems: (newLineItems: Array<ILineItem>) => void;
  setInvoiceItems: (newValue: Array<IInvoiceItem>) => void;
  inactive: boolean;
}) {
  const originalInvoiceItem = (invoiceItems ?? []).find((i) => i.id === id);

  let updatedLineItems = lineItems.map((lineItemToUpdate) => {
    if (lineItemToUpdate.itemId === id) {
      return {
        ...lineItemToUpdate,
        name,
        amountPerItem:
          (!lineItemToUpdate.amountPerItem && unitPrice) ||
          (originalInvoiceItem?.unitPrice &&
            lineItemToUpdate.amountPerItem ===
              originalInvoiceItem?.unitPrice.toString())
            ? getNumericString(unitPrice)
            : lineItemToUpdate.amountPerItem,
        taxable,
        optional: lineItemToUpdate.optional,
        selected: lineItemToUpdate.selected,
      };
    }

    return lineItemToUpdate;
  });

  if (originalInvoiceItem && originalInvoiceItem?.description !== description) {
    updatedLineItems = updatedLineItems.map((lineItemToUpdate) => {
      if (
        lineItemToUpdate.itemId === id &&
        lineItemToUpdate.description === originalInvoiceItem.description
      ) {
        return {
          ...lineItemToUpdate,
          description: description,
        };
      }

      return lineItemToUpdate;
    });
  }

  setLineItems(updatedLineItems);

  setInvoiceItems(
    (invoiceItems ?? []).map((i) => {
      if (i.id === id) {
        return {
          ...i,
          name,
          description,
          inactive,
          unitPrice,
          taxable,
        };
      }

      return i;
    })
  );
}

function MobileHeader({
  lineItems,
  index,
  setLineItems,
  isDeleteAllowed,
  lineItem,
}: {
  lineItems: ILineItem[];
  index: number;
  setLineItems: (newLineItems: Array<ILineItem>) => void;
  isDeleteAllowed: boolean | undefined;
  lineItem: ILineItem;
}) {
  const containerRef = useRef<HTMLDivElement>(null);

  const onClick = () => {
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollIntoView();
      }
    });
  };

  const buttonStyleOverrides: Partial<CSSProperties> = {
    margin: undefined,
    padding: undefined,
  };

  return (
    <div className="d-flex justify-content-between" ref={containerRef}>
      <div className="d-flex align-items-center">
        {lineItems.length > 1 && index !== 0 ? (
          <MoveUpButton
            index={index}
            lineItems={lineItems}
            setLineItems={setLineItems}
            onClick={onClick}
            buttonStyle={buttonStyleOverrides}
          />
        ) : null}
        <div className="font-weight-bold">Line Item {index + 1}</div>
        {lineItems.length > 1 && index < lineItems.length - 1 ? (
          <MoveDownButton
            index={index}
            lineItems={lineItems}
            setLineItems={setLineItems}
            onClick={onClick}
            buttonStyle={buttonStyleOverrides}
          />
        ) : null}
      </div>
      {isDeleteAllowed ? (
        <div>
          <DeleteLineItemButton
            lineItem={lineItem}
            lineItems={lineItems}
            setLineItems={setLineItems}
            displayType={"mobile"}
          />
        </div>
      ) : null}
    </div>
  );
}

function ReorderButtons({
  index,
  setLineItems,
  lineItems,
}: {
  index: number;
  setLineItems: (newLineItems: Array<ILineItem>) => void;
  lineItems: ILineItem[];
}) {
  return (
    <div className="mt-1">
      <MoveUpButton
        index={index}
        setLineItems={setLineItems}
        lineItems={lineItems}
        size="sm"
      />
      <MoveDownButton
        index={index}
        setLineItems={setLineItems}
        lineItems={lineItems}
        size="sm"
      />
    </div>
  );
}

function MoveUpButton({
  index,
  setLineItems,
  lineItems,
  size,
  className,
  onClick,
  buttonStyle,
}: {
  index: number;
  setLineItems: (newLineItems: Array<ILineItem>) => void;
  lineItems: ILineItem[];
  size?: SizeProp;
  className?: string;
  onClick?: () => void;
  buttonStyle?: Partial<CSSProperties>;
}) {
  return (
    <ReorderButton
      disabled={index === 0}
      icon={faChevronUp}
      title="Move up"
      onClick={() => {
        if (index === 0) {
          return;
        }

        setLineItems(moveArrayElement(lineItems, index, -1));

        if (onClick) {
          onClick();
        }
      }}
      size={size}
      className={className}
      buttonStyle={buttonStyle}
    />
  );
}

function MoveDownButton({
  index,
  setLineItems,
  lineItems,
  size,
  className,
  onClick,
  buttonStyle,
}: {
  index: number;
  setLineItems: (newLineItems: Array<ILineItem>) => void;
  lineItems: ILineItem[];
  size?: SizeProp;
  className?: string;
  onClick?: () => void;
  buttonStyle?: Partial<CSSProperties>;
}) {
  return (
    <ReorderButton
      disabled={index === lineItems.length - 1}
      icon={faChevronDown}
      title="Move down"
      onClick={() => {
        if (index === lineItems.length - 1) {
          return;
        }

        setLineItems(moveArrayElement(lineItems, index, 1));

        if (onClick) {
          onClick();
        }
      }}
      size={size}
      className={className}
      buttonStyle={buttonStyle}
    />
  );
}

function ReorderButton({
  disabled,
  icon,
  title,
  onClick,
  size,
  className,
  buttonStyle,
}: {
  disabled: boolean;
  icon: IconProp;
  title: string;
  onClick: () => void;
  size?: SizeProp;
  className?: string;
  buttonStyle?: Partial<CSSProperties>;
}) {
  return (
    <button
      className={`btn btn-secondary btn-sm ${className ?? ""}`}
      style={{
        border: 0,
        padding: 0,
        margin: 0,
        backgroundColor: "transparent",
        lineHeight: 0.5,
        display: "block",
        ...(buttonStyle ?? {}),
      }}
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} size={size} title={title} />
    </button>
  );
}

function moveArrayElement(
  lineItems: ILineItem[],
  elementToMove: number,
  offset: number
) {
  const newItems = [...lineItems];
  const element = newItems[elementToMove];
  newItems.splice(elementToMove, 1);
  newItems.splice(elementToMove + offset, 0, element);
  return newItems;
}

function getQuantityForNewLineItem({
  selectedJobInstances,
  allowBlankQuantity,
}: {
  selectedJobInstances: string[] | undefined;
  allowBlankQuantity?: boolean;
}): number | string {
  const selectedJobCount = (selectedJobInstances ?? []).length;

  if (selectedJobCount > 0) {
    return selectedJobCount;
  }

  if (allowBlankQuantity) {
    return "";
  }

  return 1;
}
