import React, { useRef, useEffect, useState, useMemo } from "react";
import { getSortedItems } from "../../../services/sortingService";
import { GroupBase, OptionProps, SelectInstance } from "react-select";
import { components } from "react-select";
import { Subject } from "rxjs";
import { ICustomer } from "../../../models/ICustomer";
import { connect } from "react-redux";
import { IRootState } from "../../../store";
import { actionCreators } from "../../../modules/actionCreators";
import addressFormatter from "../../../services/addressFormatter";
import uuidv4 from "uuid/v4";
import {
  ICustomerSearchResult,
  IGroupSearchResult,
} from "../../../models/ICustomerSearchResult";
import { IAddress } from "../../../models/IAddress";
import WrappedCreatableSelect from "./WrappedCreateableSelect";
import { ICustomerCategory } from "../../../models/ICustomerCategory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { isStringSet } from "../../../services/stringService";
import { WrappedCreatableSelectOption } from "./WrappedCreatableSelectOption";

export type CustomerSelectionRecordType = "customer" | "group";

interface IProps extends IOwnProps {
  customerSelectionSearchInput: (
    searchTerm: string,
    includeQuickBooksRecords?: boolean,
    includeGroups?: boolean
  ) => any;
  showCustomersForm: (parameters: any) => any;
  customers: Array<ICustomer>;
  searching: boolean;
  matchedCustomers: Array<ICustomerSearchResult>;
  matchedGroups: Array<IGroupSearchResult>;
  customerCategories: Array<ICustomerCategory>;
  errorLoadingResults: boolean;
  includeGroups?: boolean;
}

interface IOwnProps {
  value: ISelectedCustomer;
  onCustomerSelection: (
    type: CustomerSelectionRecordType,
    id: string | null
  ) => void;
  onCustomerClear: () => void;
  selectRef: React.MutableRefObject<SelectInstance<IOption, false> | null>;
  inputId?: string;
  disabled?: boolean;
}

interface ISelectedCustomer {
  recordType: CustomerSelectionRecordType;
  id: string | null;
}

export interface ICustomerForSelection {
  id: string;
  name: string;
  address: string;
  isQuickbooksCustomer: boolean;
  quickBooksAddress: IAddress | null;
  quickBooksId: string | null;
  quickBooksPhoneNumber: string | null;
  quickBooksAlternativePhoneNumber: string | null;
  quickBooksEmailAddress: string | null;
  quickBooksTaxExempt: boolean;
}

const newItemId = "newitem";
const CustomerSelection: React.FunctionComponent<IProps> = ({
  value,
  customers,
  onCustomerSelection,
  onCustomerClear,
  showCustomersForm,
  selectRef,
  searching,
  matchedCustomers,
  matchedGroups,
  customerSelectionSearchInput,
  errorLoadingResults,
  includeGroups,
  customerCategories,
  inputId,
  disabled,
}) => {
  const inputChange$ = useRef(new Subject<string>());

  const [customerFormInstanceKey, setCustomerFormInstanceKey] = useState("");

  useEffect(() => {
    const sub = inputChange$.current.subscribe((input) => {
      customerSelectionSearchInput(input, true, includeGroups);
    });

    return function cleanup() {
      sub.unsubscribe();
    };
  }, [customerSelectionSearchInput, includeGroups]);

  useEffect(() => {
    if (customerFormInstanceKey) {
      // TODO: Cleanup
      const customer = customers.find(
        (c) =>
          (c as any).parameters &&
          (c as any).parameters.formInstanceKey === customerFormInstanceKey
      );
      if (customer) {
        onCustomerSelection("customer", customer.id);
        setCustomerFormInstanceKey("");
      }
    }
  }, [customers, customerFormInstanceKey, onCustomerSelection]);

  function openCustomerForm(selectedOption: IOption | undefined) {
    const formInstanceKey = uuidv4();
    setCustomerFormInstanceKey(formInstanceKey);

    if (!selectedOption) {
      showCustomersForm({
        formInstanceKey: formInstanceKey,
      });
    } else if (selectedOption.customer) {
      const selectedCustomer = selectedOption.customer;

      let emailAddresses: Array<string> = [];
      if (isStringSet(selectedCustomer.quickBooksEmailAddress)) {
        emailAddresses = selectedCustomer.quickBooksEmailAddress.split(",");
      }
      showCustomersForm({
        defaultName: selectedCustomer.name,
        defaultPhoneNumber: selectedCustomer.quickBooksPhoneNumber,
        defaultAlternativePhoneNumber:
          selectedCustomer.quickBooksAlternativePhoneNumber,
        defaultEmailAddresses: emailAddresses,
        defaultAddress: selectedCustomer.quickBooksAddress,
        defaultQuickBooksCustomerId: selectedCustomer.quickBooksId,
        defaultTaxExempt: selectedCustomer.quickBooksTaxExempt,
        formInstanceKey: formInstanceKey,
      });
    }
  }

  const handleChange = (newValue: any, actionMeta: any) => {
    const selectedOption = newValue as IOption | undefined;
    if (
      actionMeta.action === "create-option" ||
      (selectedOption &&
        selectedOption.customer &&
        selectedOption.customer.isQuickbooksCustomer)
    ) {
      openCustomerForm(selectedOption);
    } else if (actionMeta.action === "select-option") {
      const selectedOption = newValue as IOption;

      if (selectedOption.customer) {
        onCustomerSelection("customer", selectedOption.customer.id);
      } else if (selectedOption.group) {
        onCustomerSelection("group", selectedOption.group.name);
      }
    } else if (actionMeta.action === "clear") {
      onCustomerClear();
    }
  };

  const Option = (props: OptionProps<IOption, false, GroupBase<IOption>>) => {
    const optionItem = props.data as IOption;

    if (optionItem.customer) {
      const customer = optionItem.customer;
      return (
        <components.Option {...props}>
          <div id="customerSelectionAddItem">
            <WrappedCreatableSelectOption
              isNewItem={customer.id === newItemId}
              name={customer.name}
              recordType="customer"
            />
          </div>
          {customer.address ? (
            <div>
              <small>{customer.address}</small>
            </div>
          ) : null}
          {customer.isQuickbooksCustomer ? (
            <div>
              <small className="text-success">
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  style={{ marginRight: "5px" }}
                />
                Import from QuickBooks
              </small>
            </div>
          ) : null}
        </components.Option>
      );
    } else if (optionItem.group) {
      const group = optionItem.group;
      return (
        <components.Option {...props}>
          <div id="customerSelectionAddItem">
            <span>All customers with "{group.name}" tag</span>
          </div>
          <div>
            <small>
              {group.customerCount}{" "}
              {group.customerCount > 1 ? "customers" : "customer"}
            </small>
          </div>
        </components.Option>
      );
    } else {
      return <></>;
    }
  };

  const SingleValue = (args: any) => {
    const { children, ...props } = args;
    if (args.data.customer) {
      const customer = args.data.customer as ICustomerForSelection;
      return (
        <components.SingleValue {...props}>
          {customer.name}
          {customer.address && customer.address.trim() ? (
            <small className="font-weight-light"> {customer.address}</small>
          ) : null}
        </components.SingleValue>
      );
    } else if (args.data.group) {
      const group = args.data.group as IGroupSearchResult;
      return (
        <components.SingleValue {...props}>
          All customers with "{group.name}" tag
        </components.SingleValue>
      );
    } else {
      return <></>;
    }
  };

  const customersForSelection = customers.map(
    mapCustomerToCustomerForSelection
  );

  let selectedOption: IOption | null = null;
  if (value.recordType === "customer") {
    let selectedCustomer = customersForSelection.find((c) => c.id === value.id);

    if (selectedCustomer) {
      selectedOption = { customer: selectedCustomer };
    }
  } else if (value.recordType === "group") {
    let selectedGroup = customerCategories.find((c) => c.name === value.id);

    if (selectedGroup) {
      // Just leave customerCount as 0 since aren't showing as part of the selected option
      selectedOption = { group: { ...selectedGroup, customerCount: 0 } };
    }
  }

  const mappedOptions: Array<IOption> = useMemo(() => {
    const internalMatchedCustomers = matchedCustomers
      .filter((c) => !c.isQuickBooksCustomer)
      .map((c) => c.id)
      .map((c) => {
        const matchingCustomer = customers.find(
          (customer) => customer.id === c
        );

        return matchingCustomer;
      });

    if (internalMatchedCustomers.reduce((acc, c) => acc || !c, false)) {
      console.error("missing customer id in customer selection");
    }

    let customersForSelectionList = internalMatchedCustomers
      .filter((c) => !!c)
      .map((c) => mapCustomerToCustomerForSelection(c as ICustomer));

    customersForSelectionList = [
      ...customersForSelectionList,
      ...matchedCustomers
        .filter((c) => c.isQuickBooksCustomer)
        .map(
          (c) =>
            ({
              id: uuidv4(),
              name: c.quickBooksCustomerName || "",
              address: c.quickBooksAddress
                ? addressFormatter.formatAddressEntity(c.quickBooksAddress)
                : "",
              isQuickbooksCustomer: c.isQuickBooksCustomer,
              quickBooksId: c.quickBooksCustomerId,
              quickBooksAddress: c.quickBooksAddress,
              quickBooksEmailAddress: c.quickBooksCustomerEmailAddress,
              quickBooksPhoneNumber: c.quickBooksCustomerPhoneNumber,
              quickBooksAlternativePhoneNumber:
                c.quickBooksCustomerAlternativePhoneNumber,
              quickBooksTaxExempt: c.quickBooksCustomerTaxExempt,
            } as ICustomerForSelection)
        ),
    ];

    return [
      ...matchedGroups.map((g) => ({ group: g })),
      ...getSortedItems(customersForSelectionList, "name").map((c) => ({
        customer: c,
      })),
    ];
  }, [matchedGroups, matchedCustomers, customers]);

  return (
    <WrappedCreatableSelect
      placeholder="Search here"
      inputId={inputId ?? "customerSelectionInput"}
      isDisabled={disabled}
      selectRef={selectRef}
      value={selectedOption}
      isClearable
      onChange={handleChange}
      isValidNewOption={(inputValue) => !errorLoadingResults}
      createOptionPosition="first"
      components={{ Option, SingleValue }}
      getOptionLabel={(opt) => {
        if (opt.customer) {
          return opt.customer.address
            ? `${opt.customer.name} - ${opt.customer.address}`
            : opt.customer.name;
        } else if (opt.group) {
          return opt.group.name;
        } else {
          return "";
        }
      }}
      styles={{
        menu: (styles) => {
          return {
            ...styles,
            zIndex: 3,
          };
        },
        noOptionsMessage: (styles) => {
          if (!errorLoadingResults) {
            return styles;
          } else {
            return {
              ...styles,
              // Pulled from text-danger class
              color: "#C71C22",
            };
          }
        },
      }}
      getNewOptionData={(text, b) =>
        ({
          customer: {
            id: newItemId,
            name: text,
            address: "",
          },
        } as IOption)
      }
      getOptionValue={(opt) => {
        if (opt.customer) {
          return opt.customer.id;
        } else if (opt.group) {
          return opt.group.name;
        } else {
          return "";
        }
      }}
      noOptionsMessage={() =>
        !errorLoadingResults
          ? "Start typing to find customers or add a new one..."
          : "Error searching.  Please check Internet connection."
      }
      isLoading={searching}
      options={mappedOptions}
      onInputChange={(input) => inputChange$.current.next(input)}
      onFocus={() => inputChange$.current.next("")}
      filterOption={() => true}
      onLeaveInputField={(customerName) => {
        // Hack warning.  Without this code, if the user clicked into a drop-down list on the containing form (i.e. Frequency on the Recurring Job form),
        // the drop-down list would stay open even though the custom form was also opened.  Appears need to leave focus from the select to a control on the existing form
        // and then open the customer form.  setTimeout is needed so for a slight delay.
        setTimeout(() => {
          selectRef.current?.focus();
          openCustomerForm({
            customer: {
              name: customerName,
              address: "",
              id: "",
              isQuickbooksCustomer: false,
              quickBooksAddress: null,
              quickBooksEmailAddress: null,
              quickBooksId: null,
              quickBooksPhoneNumber: null,
              quickBooksAlternativePhoneNumber: null,
              quickBooksTaxExempt: false,
            },
          });
        });
      }}
    />
  );
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => ({
  value: ownProps.value,
  onCustomerSelection: ownProps.onCustomerSelection,
  onCustomerClear: ownProps.onCustomerClear,
  inputId: ownProps.inputId,
  customers: state.customer.customers,
  matchedCustomers: state.customerSelection.matchedCustomers,
  matchedGroups: state.customerSelection.matchedGroups,
  searching: state.customerSelection.searching,
  errorLoadingResults: state.customerSelection.errorLoadingResults,
  customerCategories: state.common.customerCategories,
  disabled: ownProps.disabled,
});

const mapDispatchToProps = {
  showCustomersForm: actionCreators.forms.customer.showForm,
  customerSelectionSearchInput: actionCreators.customerSelectionSearchInput,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerSelection);

function mapCustomerToCustomerForSelection(
  customer: ICustomer
): ICustomerForSelection {
  return {
    id: customer.id,
    name: customer.name,
    address: addressFormatter.formatAddressEntity(customer),
    isQuickbooksCustomer: false,
    quickBooksId: null,
    quickBooksAddress: null,
    quickBooksEmailAddress: null,
    quickBooksPhoneNumber: null,
    quickBooksAlternativePhoneNumber: null,
    quickBooksTaxExempt: false,
  };
}

export interface IOption {
  customer?: ICustomerForSelection;
  group?: IGroupSearchResult;
}
