import { IRootState } from "../../store";
import { actionCreators } from "../actionCreators";
import dataProvider from "../../services/dataProvider";
import { map } from "rxjs/operators";
import { of } from "rxjs";
import { IRemoteCustomerChangedAction } from "../actionTypeDefinitions";

export function loadRemoteChangedCustomer(
  action: IRemoteCustomerChangedAction,
  rootState: IRootState
) {
  let resultAction = of(actionCreators.null());
  if (rootState.customer.customers.some((j) => j.id === action.customerId)) {
    resultAction = dataProvider
      .getCustomers({
        customerIds: [action.customerId],
      })
      .pipe(
        map((r) =>
          actionCreators.loadCustomersComplete(
            r.customers,
            r.customerAdditionalLocations
          )
        )
      );
  }

  return resultAction;
}
