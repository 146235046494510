import { Action } from "@reduxjs/toolkit";
import { Epic } from "redux-observable";
import { catchError, filter, mergeMap, timeout } from "rxjs/operators";
import { opportunitiesActionCreators } from "../opportunity";
import { IApplicationState } from "../../../../modules";
import opportunityDataProvider from "../../services/opportunityDataProvider";
import { actionCreators } from "../../../../modules/actionCreators";
import { concat, Observable, of } from "rxjs";
import { loadOpportunityRelatedData } from "../../services/loadOpportunityRelatedData";

const reloadOpportunity: Epic<Action, Action, IApplicationState> = (
  action,
  state$
) =>
  action.pipe(
    filter(opportunitiesActionCreators.reloadOpportunity.match),
    mergeMap((action) => {
      return opportunityDataProvider
        .getOpportunities({ opportunityIds: [action.payload.opportunityId] })
        .pipe(
          mergeMap((opportunities) => {
            if (opportunities.length === 1) {
              const opportunityRelatedDataLoads: Observable<Action<any>> =
                loadOpportunityRelatedData(state$.value, opportunities);

              return concat(
                opportunityRelatedDataLoads,
                of(
                  opportunitiesActionCreators.loadSpecificOpportunities({
                    opportunities,
                  })
                )
              );
            } else {
              return of(actionCreators.null());
            }
          }),
          timeout(30000),
          catchError(() => of(actionCreators.null()))
        );
    })
  );

export default reloadOpportunity;
