import { useDispatch } from "react-redux";
import { IJob, JobType } from "../../../../../models/IJob";
import LinkButton2 from "../../LinkButton2";
import { actionCreators } from "../../../../../modules/actionCreators";
import { useApplicationStateSelector } from "../../../../../hooks/useApplicationStateSelector";
import { CrewScheduleType } from "../../../../../slices/schedule/enums/crewScheduleType";
import { IJobInstance } from "../../../../../models/IJobInstance";

export function IndicatorsMissingTimeWarning({
  jobType,
  job,
  jobInstance,
  onFixItClick,
  onShowJobInstanceTimeForm,
}: {
  job: IJob | null;
  jobInstance: IJobInstance;
  jobType: JobType | null;
  onFixItClick: () => void;
  onShowJobInstanceTimeForm: () => void;
}) {
  const dispatch = useDispatch();
  const crews = useApplicationStateSelector((s) => s.crew.crews);

  return (
    <>
      <div>
        Time not set
        <>
          {" "}
          <LinkButton2
            buttonContents={"Fix it!"}
            testId="fixIt"
            inlineButton
            onClick={() => {
              onFixItClick();

              if (jobType === JobType.maintenanceJob) {
                const isRecurringJobCrewSequenceBased =
                  crews.find((c) => c.id === job?.crewId)?.scheduleType ===
                  CrewScheduleType.sequence;

                if (isRecurringJobCrewSequenceBased) {
                  onShowJobInstanceTimeForm();
                } else {
                  dispatch(
                    actionCreators.forms.maintenanceJob.showForm({
                      maintenanceJobId: jobInstance.jobId,
                      fieldToFocus: "startTime",
                    })
                  );
                }
              } else if (jobType === JobType.oneTimeJob) {
                dispatch(
                  actionCreators.forms.oneTimeJob.showForm({
                    oneTimeJobId: jobInstance.id,
                    fieldToFocus: "startTime",
                  })
                );
              }
            }}
          ></LinkButton2>
        </>
      </div>
    </>
  );
}
