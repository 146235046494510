import { CSSProperties, useEffect, useRef } from "react";
import Select, { GroupBase, SelectInstance } from "react-select";
import { ICrew } from "../../../models/ICrew";

export default function CrewMultiSelect({
  crews,
  selectedCrewIds,
  setSelectedCrewIds,
  inputId,
  style,
  isDisabled,
  placeholder,
  required,
}: {
  crews: Array<ICrew>;
  selectedCrewIds: Array<string>;
  setSelectedCrewIds: (newCrews: Array<string>) => void;
  inputId: string;
  style?: Partial<CSSProperties>;
  isDisabled?: boolean;
  placeholder?: string;
  required?: boolean;
}) {
  const selectRef = useRef<SelectInstance<
    ICrew,
    true,
    GroupBase<ICrew>
  > | null>(null);

  useEffect(() => {
    let validityMessage = "";
    if (required && selectedCrewIds.length === 0) {
      validityMessage = "Please select at least one crew";
    }

    if (selectRef.current?.inputRef) {
      selectRef.current.inputRef.setCustomValidity(validityMessage);
    }
  }, [required, selectedCrewIds]);

  return (
    <Select
      ref={selectRef}
      inputId={inputId}
      isDisabled={isDisabled}
      styles={{
        container: (base) => ({ ...base, minWidth: "300px", ...(style ?? {}) }),
      }}
      options={crews}
      getOptionLabel={(c) => c.name}
      getOptionValue={(c) => c.id}
      isMulti={true}
      value={crews.filter((c) => selectedCrewIds?.includes(c.id))}
      placeholder={placeholder ?? "Select crews..."}
      noOptionsMessage={() => "No remaining crews"}
      onChange={(newCrews) => {
        if (newCrews) {
          const typedNewCrews = newCrews as Array<ICrew>;
          setSelectedCrewIds(typedNewCrews.map((c) => c.id));
        } else {
          setSelectedCrewIds([]);
        }
      }}
    />
  );
}
