import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap/lib";
import ButtonDropdown from "reactstrap/lib/ButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { timeout } from "rxjs/operators";
import constants from "../../../constants";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import { IInvoiceView } from "../../../models/IInvoice";
import { IInvoicePaymentParameters } from "../../../formGenerator/formParameters/IInvoicePaymentParameters";
import { commonUiActionCreators } from "../../../modules/commonUi";
import Spinner from "../../../containers/app/components/Spinner";
import Prompt from "../../../containers/app/components/Prompt";
import InvoiceSendForm from "../../../containers/app/forms/InvoiceSendForm";
import ResendReceiptForm from "./ResendReceiptForm";
import { isMobile } from "react-device-detect";
import { builders as routerBuilders } from "../../../services/routing";
import { InvoiceListType } from "../../../enums/invoiceListType";
import FixInvoiceForQuickBooksForm from "./FixInvoiceForQuickBooksForm";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { isStringSet } from "../../../services/stringService";
import BillingInvoiceListPaymentsManage from "./BillingInvoiceListPaymentsManage";
import { BillingInvoiceListDeletePayment } from "./BillingInvoiceListDeletePayment";
import { BillingInvoiceListRefundPayment } from "./BillingInvoiceListRefundPayment";
import InvoicePaymentForm from "../../../containers/app/forms/InvoicePaymentForm";

interface IProps {
  invoice: IInvoiceView;
  listType: InvoiceListType;
  onDataChanged(): void;
}

const BillingInvoiceListActionButtons: React.FunctionComponent<IProps> = ({
  invoice,
  listType,
  onDataChanged,
}) => {
  const {
    id: invoiceId,
    customerId,
    sendAllowed,
    deleteAllowed,
    addPaymentAllowed,
    createPayrixTransactionFailureMessage,
    payments,
  } = invoice;
  const invoicePaid = !!invoice.datePaid;

  const customerUrlRoot = useApplicationStateSelector(
    (s) => s.common.customerUrlRoot
  );
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [
    showDeleteInvoiceConfirmationPrompt,
    setShowDeleteInvoiceConfirmationPrompt,
  ] = useState(false);
  const [
    showDeletePaymentConfirmationPrompt,
    setShowDeletePaymentConfirmationPrompt,
  ] = useState(false);
  const [showManagePayments, setShowManagePayments] = useState(false);
  const [
    showRefundPaymentConfirmationPrompt,
    setShowRefundPaymentConfirmationPrompt,
  ] = useState(false);
  const [showPaymentForm, setShowPaymentForm] =
    useState<IInvoicePaymentParameters | null>(null);
  const [showInvoiceSendForm, setShowInvoiceSendForm] = useState(false);
  const [showResendReceiptForm, setShowResendReceiptForm] = useState(false);
  const [showFixInvoiceForQuickBooksForm, setShowFixInvoiceForQuickBooksForm] =
    useState(false);
  const [isAdditionalButtonsDropDownOpen, setIsAdditionalButtonsDropDownOpen] =
    useState(false);
  const dispatch = useDispatch();

  const buttons: Array<
    | { type: "button"; text: string; onClick: () => void }
    | { type: "link"; text: string; url: string }
  > = [];

  if (addPaymentAllowed) {
    buttons.push({
      text: "Add payment",
      onClick: () =>
        setShowPaymentForm(
          getSaveInvoicePaymentParameters({
            invoice,
            paymentId: null,
          })
        ),
      type: "button",
    });
  }

  if (payments.length === 1) {
    const payment = payments[0];
    if (payment.editPaymentAllowed) {
      buttons.push({
        text: "Edit payment",
        onClick: () =>
          setShowPaymentForm(
            getSaveInvoicePaymentParameters({
              invoice,
              paymentId: payment.id,
            })
          ),
        type: "button",
      });

      buttons.push({
        text: "Clear payment details",
        onClick: () => {
          setShowDeletePaymentConfirmationPrompt(true);
        },
        type: "button",
      });
    }

    if (payment.refundAllowed) {
      buttons.push({
        text: "Refund payment",
        onClick: () => {
          setShowRefundPaymentConfirmationPrompt(true);
        },
        type: "button",
      });
    }
  } else if (payments.length > 1) {
    buttons.push({
      text: "Manage payments",
      onClick: () => {
        setShowManagePayments(true);
      },
      type: "button",
    });
  }

  if (invoice.editAllowed && listType !== InvoiceListType.notSynced) {
    buttons.push({
      text: "Edit invoice",
      onClick: () =>
        dispatch(
          actionCreators.forms.invoice.showForm({
            customerId,
            invoiceId: invoice.id,
          })
        ),
      type: "button",
    });
  }

  if (invoicePaid || invoice.payments.length === 1) {
    buttons.push({
      text: "Resend receipt",
      onClick: () => {
        setShowResendReceiptForm(true);
      },
      type: "button",
    });
  }

  if (createPayrixTransactionFailureMessage && addPaymentAllowed) {
    buttons.push({
      text: "Retry charge",
      onClick: () =>
        dispatch(
          actionCreators.forms.invoiceRetryCharge.showForm({
            customerId: invoice.customerId,
            invoiceId: invoice.id,
          })
        ),
      type: "button",
    });
  }

  if (listType !== InvoiceListType.notSynced) {
    if (sendAllowed) {
      buttons.push({
        text: "Send invoice",
        onClick: () => setShowInvoiceSendForm(true),
        type: "button",
      });
    }

    if (isStringSet(invoice.lookupId)) {
      buttons.push({
        text: "View",
        url: `${customerUrlRoot}/pay/${invoice.lookupId}?ref=admin`,
        type: "link",
      });
    }

    if (!isMobile) {
      buttons.push({
        text: "Print",
        onClick: () =>
          dispatch(
            commonUiActionCreators.showInvoicePrintContainer({
              idForInvoicePrintContainer: invoice.id,
            })
          ),
        type: "button",
      });
    } else {
      buttons.push({
        text: "Print",
        url: routerBuilders.print.buildInvoicePrintPage(invoice.id),
        type: "link",
      });
    }
  }

  if (deleteAllowed) {
    buttons.push({
      text: "Delete",
      onClick: () => setShowDeleteInvoiceConfirmationPrompt(true),
      type: "button",
    });
  }

  if (buttons.length === 0) {
    return null;
  }

  return (
    <>
      {saving ? <Spinner /> : null}

      <ButtonDropdown
        direction="down"
        isOpen={isAdditionalButtonsDropDownOpen}
        toggle={() =>
          setIsAdditionalButtonsDropDownOpen(!isAdditionalButtonsDropDownOpen)
        }
      >
        {listType === InvoiceListType.notSynced ? (
          <button
            className="btn btn-secondary btn-sm text-nowrap"
            onClick={() => setShowFixInvoiceForQuickBooksForm(true)}
          >
            Fix it
          </button>
        ) : (
          <button
            className="btn btn-secondary btn-sm"
            onClick={() =>
              setIsAdditionalButtonsDropDownOpen(
                !isAdditionalButtonsDropDownOpen
              )
            }
          >
            Actions
          </button>
        )}
        <DropdownToggle split color="secondary" size="sm" />
        <DropdownMenu positionFixed={true} right={true}>
          {buttons.map((additionalButton) =>
            additionalButton.type === "link" ? (
              <DropdownItem
                href={additionalButton.url}
                target="_blank"
                rel="noopener noreferrer"
                key={additionalButton.text}
              >
                {additionalButton.text}
              </DropdownItem>
            ) : (
              <DropdownItem
                key={additionalButton.text}
                onClick={additionalButton.onClick}
              >
                {additionalButton.text}
              </DropdownItem>
            )
          )}
        </DropdownMenu>
      </ButtonDropdown>

      <Prompt
        promptMessage="Are you sure you want to delete this invoice?"
        showPrompt={showDeleteInvoiceConfirmationPrompt && !saving}
        onCancel={() => setShowDeleteInvoiceConfirmationPrompt(false)}
        onConfirm={() => {
          setSaving(true);
          setShowDeleteInvoiceConfirmationPrompt(false);

          remoteDataProvider
            .deleteInvoice(invoiceId)
            .pipe(timeout(10000))
            .subscribe({
              next: () => {
                setSaving(false);
                onDataChanged();
              },

              error: (err) => {
                setSaving(false);
                setErrorMessage(
                  getErrorMessageFromError(err, constants.unknownErrorMessage)
                );
              },
            });
        }}
      />

      {invoice.payments.length === 1 ? (
        <BillingInvoiceListDeletePayment
          showPrompt={showDeletePaymentConfirmationPrompt}
          setShowPrompt={setShowDeletePaymentConfirmationPrompt}
          invoiceId={invoice.id}
          paymentId={invoice.payments[0].id}
          onDataChanged={onDataChanged}
          setErrorMessage={setErrorMessage}
          promptMessage={"Are you sure you want to clear the payment details?"}
        />
      ) : null}

      {invoice.payments.length === 1 ? (
        <BillingInvoiceListRefundPayment
          showPrompt={showRefundPaymentConfirmationPrompt}
          setShowPrompt={setShowRefundPaymentConfirmationPrompt}
          invoiceId={invoice.id}
          payment={invoice.payments[0]}
          onDataChanged={onDataChanged}
          setErrorMessage={setErrorMessage}
        />
      ) : null}

      {errorMessage ? (
        <Modal isOpen={true}>
          <ModalBody>
            <div className="text-danger">{errorMessage}</div>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => setErrorMessage("")}
            >
              Close
            </button>
          </ModalFooter>
        </Modal>
      ) : null}

      {showInvoiceSendForm ? (
        <InvoiceSendForm
          customerId={customerId}
          invoiceId={invoice.id}
          defaultReplyToEmailAddress={invoice.replyToEmailAddress}
          onClose={() => setShowInvoiceSendForm(false)}
          onSaveComplete={() => {
            onDataChanged();
            setShowInvoiceSendForm(false);
          }}
        />
      ) : null}

      {showResendReceiptForm ? (
        <ResendReceiptForm
          customerId={customerId}
          invoiceId={invoice.id}
          paymentId={
            invoice.payments.length === 1 ? invoice.payments[0].id : null
          }
          receiptEmailAddresses={invoice.receiptEmailAddresses}
          onClose={() => setShowResendReceiptForm(false)}
        />
      ) : null}

      {showFixInvoiceForQuickBooksForm ? (
        <FixInvoiceForQuickBooksForm
          invoiceId={invoice.id}
          customerId={invoice.customerId}
          onCancel={() => setShowFixInvoiceForQuickBooksForm(false)}
          onSaveComplete={() => {
            onDataChanged();
            setShowFixInvoiceForQuickBooksForm(false);
          }}
        />
      ) : null}

      {showManagePayments ? (
        <BillingInvoiceListPaymentsManage
          invoiceId={invoice.id}
          onClose={() => setShowManagePayments(false)}
          onDataChanged={onDataChanged}
        />
      ) : null}

      {showPaymentForm !== null ? (
        <InvoicePaymentForm
          formParameters={showPaymentForm}
          onSaveComplete={() => {
            onDataChanged();
            setShowPaymentForm(null);
          }}
          onCancel={() => {
            setShowPaymentForm(null);
          }}
        />
      ) : null}
    </>
  );
};

export default BillingInvoiceListActionButtons;

function getSaveInvoicePaymentParameters({
  paymentId,
  invoice,
}: {
  paymentId: string | null;
  invoice: IInvoiceView;
}): IInvoicePaymentParameters {
  return {
    invoiceId: invoice.id,
    paymentId: paymentId,
    customerName: invoice.customerName,
    customerId: invoice.customerId,
    replyToEmailAddress: invoice.replyToEmailAddress,
  };
}
