import { useState } from "react";
import Spinner from "../../../containers/app/components/Spinner";
import subscriptionDataProvider from "../services/subscriptionDataProvider";
import { timeout } from "rxjs/operators";
import { PortalPage } from "../enums/portalPage";

export function UpgradePlan({
  renderButton,
  onRedirectToPortal,
}: {
  renderButton: (args: { onClick: () => void }) => React.ReactNode;
  onRedirectToPortal: (url: string) => void;
}) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [errorLoadingPortalLink, setErrorLoadingPortalLink] = useState(false);

  return (
    <>
      {showSpinner ? <Spinner /> : null}

      {renderButton({
        onClick: () => {
          setShowSpinner(true);
          setErrorLoadingPortalLink(false);

          subscriptionDataProvider
            .createSubscriptionPortalSession({
              page: PortalPage.UpdatePlan,
            })
            .pipe(timeout(15000))
            .subscribe({
              next: (portalUrl) => {
                setShowSpinner(false);

                onRedirectToPortal(portalUrl);
              },

              error: () => {
                setShowSpinner(false);
                setErrorLoadingPortalLink(true);
              },
            });
        },
      })}

      {errorLoadingPortalLink ? (
        <div className="text-danger">
          There was an error taking you to the subscription management page.
          Please try again or contact support if you have any questions.
        </div>
      ) : null}
    </>
  );
}
