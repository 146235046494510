import { useState } from "react";
import InvoiceConfigurationForm from "../../../containers/app/forms/InvoiceConfigurationForm";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";

export function InvoiceUpdateDefaultDueDatePrompt({
  hasNumberOfDaysUntilDueChanged,
  newDefaultNumberOfDaysDue,
}: {
  hasNumberOfDaysUntilDueChanged: boolean;
  newDefaultNumberOfDaysDue: number | string | null;
}) {
  const [showInvoiceConfiguration, setShowInvoiceConfiguration] =
    useState(false);

  const invoiceConfiguration = useApplicationStateSelector(
    (s) => s.common.invoiceConfiguration
  );

  if (!hasNumberOfDaysUntilDueChanged) {
    return null;
  }

  if (typeof newDefaultNumberOfDaysDue === "string") {
    newDefaultNumberOfDaysDue = parseInt(newDefaultNumberOfDaysDue);
    if (isNaN(newDefaultNumberOfDaysDue)) {
      newDefaultNumberOfDaysDue = null;
    }
  }

  if (
    newDefaultNumberOfDaysDue === null ||
    newDefaultNumberOfDaysDue ===
      invoiceConfiguration.invoiceDefaultNumberOfDaysValid
  ) {
    return null;
  }

  return (
    <>
      <div>
        <button
          type="button"
          className="btn btn-sm btn-link pl-0 py-0"
          data-testid="updateDefaultDaysValid"
          onClick={() => {
            setShowInvoiceConfiguration(true);
          }}
          style={{ textAlign: "left" }}
        >
          Update default number of days until due
        </button>
      </div>
      {showInvoiceConfiguration ? (
        <InvoiceConfigurationForm
          onClose={() => {
            setShowInvoiceConfiguration(false);
          }}
          onSaveComplete={() => {
            setShowInvoiceConfiguration(false);
          }}
          defaultInvoiceDefaultNumberOfDaysValid={newDefaultNumberOfDaysDue}
        />
      ) : null}
    </>
  );
}
