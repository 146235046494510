import React, { Dispatch, useEffect, useState } from "react";
import { ScheduledCommunication } from "../../../enums/scheduledCommunication";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import {
  ICustomerNotificationsConfiguration,
  TemplateVariableValues,
} from "../../../models/ICustomerNotificationsConfiguration";
import { ITime } from "../../../models/ITime";
import timeService from "../../../services/timeService";
import FormContainer2 from "../components/FormContainer2";
import TimeInputQuarterHour from "../components/TimeInputQuarterHour";
import InfoToolTip from "../components/InfoToolTip";
import CustomerNotificationsTemplatedMessage from "../components/CustomerNotificationsTemplatedMessage";
import { CustomerNotificationTemplateType } from "../../../enums/customerNotificationTemplateType";
import { DayScheduledPlaceholder } from "../components/CustomerNotificationsTemplatedMessage.options";
import { CustomerNotificationsDisabledAlert } from "../components/CustomerNotificationsDisabledAlert";
import { useShowVerificationEnrollmentMessage } from "../../../slices/tenantSubscription/hooks/useShowVerificationEnrollmentMessage";
import { TollFreeVerificationPrompt } from "../../../slices/tenantSubscription/components/TollFreeVerificationPrompt";

const dayOf = "dayOf";
const dayBefore = "dayBefore";

const defaultTime: ITime = {
  hour: 9,
  minute: 0,
  period: "AM",
};

interface IFormData {
  sendPreVisitNotificationEnabled: boolean;
  preVisitNotificationType: string;
  preVisitNotificationText: string;
  time: ITime;

  sendCompleteNotification: boolean;
  completeNotificationText: string;

  preVisitSelectedTemplateId: string | null;
  preVisitVariables: TemplateVariableValues;

  completeSelectedTemplateId: string | null;
  completeVariables: TemplateVariableValues;
}

const CustomerNotificationsConfigurationForm: React.FunctionComponent<{}> =
  () => {
    const [formData, setFormData] = useState<IFormData>(getEmptyFormData());
    const [originalFormData, setOriginalFormData] = useState<IFormData>(
      getEmptyFormData()
    );
    const customerTextingAllowed = useApplicationStateSelector(
      (s) => s.common.optionalCapabilitiesAllowed.customerTexting
    );

    useInitializeFormData(setFormData, setOriginalFormData);

    const showVerificationEnrollmentMessage =
      useShowVerificationEnrollmentMessage();

    const saveDisabled = !customerTextingAllowed;

    return (
      <FormContainer2
        size="lg"
        formHeader="Customer Notifications"
        formType="customerNotificationsConfiguration"
        getFormData={() => {
          const payload: Omit<
            ICustomerNotificationsConfiguration,
            "preVisitNotificationText" | "completeNotificationText"
          > = {
            sendCompleteNotification: formData.sendCompleteNotification,
            preVisitNotificationTime: timeService.formatTimeForJson(
              formData.time
            ),
            sendPreVisitNotification: formData.sendPreVisitNotificationEnabled
              ? formData.preVisitNotificationType === dayOf
                ? ScheduledCommunication.DayOf
                : ScheduledCommunication.DayBefore
              : ScheduledCommunication.Never,
            preVisitSelectedTemplateId: formData.preVisitSelectedTemplateId,
            preVisitVariables: formData.preVisitVariables,
            completeSelectedTemplateId: formData.completeSelectedTemplateId,
            completeVariables: formData.completeVariables,
          };

          return payload;
        }}
        hasFormDataChanged={() => {
          return (
            !saveDisabled &&
            JSON.stringify(formData) !== JSON.stringify(originalFormData)
          );
        }}
        hideSave={saveDisabled}
      >
        {saveDisabled ? (
          <CustomerNotificationsDisabledAlert parentFormType="customerNotificationsConfiguration" />
        ) : null}

        {showVerificationEnrollmentMessage && !saveDisabled ? (
          <TollFreeVerificationPrompt />
        ) : null}

        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="sendPreVisitNotificationEnabled"
              checked={formData.sendPreVisitNotificationEnabled}
              onChange={(e) => {
                let value = e.target.checked;
                setFormData({
                  ...formData,
                  sendPreVisitNotificationEnabled: value,
                });
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="sendPreVisitNotificationEnabled"
            >
              Pre-visit notification
            </label>
            <InfoToolTip
              id="preVisitNotificationToolTip"
              text="Turning this on allows you to send scheduled group texts to your customers letting them know their property is on the schedule."
            />
          </div>
        </div>

        <div className="form-section">
          <div className="form-row">
            <div className="form-group col-12 col-md-6">
              <label htmlFor="preVisitNotificationType">
                Send text message
              </label>
              <select
                className="form-control"
                id="preVisitNotificationType"
                value={formData.preVisitNotificationType}
                disabled={!formData.sendPreVisitNotificationEnabled}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    preVisitNotificationType: e.currentTarget.value,
                  });
                }}
              >
                <option value={dayOf}>Day of</option>
                <option value={dayBefore}>Day before</option>
              </select>
            </div>

            <div className="form-group col-12 col-md-6">
              <TimeInputQuarterHour
                value={formData.time}
                disabled={!formData.sendPreVisitNotificationEnabled}
                onChange={(newValue) =>
                  setFormData({
                    ...formData,
                    time: newValue,
                  })
                }
              />
            </div>
          </div>

          {formData.sendPreVisitNotificationEnabled ? (
            <CustomerNotificationsTemplatedMessage
              value={formData.preVisitNotificationText}
              onChange={(newValue) => {
                setFormData((f) => ({
                  ...f,
                  preVisitNotificationText: newValue.value,
                  preVisitSelectedTemplateId: newValue.selectedTemplateId,
                  preVisitVariables: newValue.variables,
                }));
              }}
              templateType={CustomerNotificationTemplateType.preVisit}
              hardcodedVariables={{
                [DayScheduledPlaceholder]:
                  getDayScheduledTextForMessage(formData),
              }}
              selectedTemplateId={formData.preVisitSelectedTemplateId}
              variables={formData.preVisitVariables}
            />
          ) : null}
        </div>

        <div className="form-group mt-3">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="sendCompleteNotification"
              checked={formData.sendCompleteNotification}
              onChange={(e) => {
                let value = e.target.checked;
                setFormData({
                  ...formData,
                  sendCompleteNotification: value,
                });
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="sendCompleteNotification"
            >
              Visit complete notification
            </label>
            <InfoToolTip
              id="completeNotificationToolTip"
              text="Turning this on will automatically send a text to customers when a crew marks the customer's job as completed."
            />
          </div>
        </div>

        <div className="form-section">
          {formData.sendCompleteNotification ? (
            <CustomerNotificationsTemplatedMessage
              value={formData.completeNotificationText}
              onChange={(newValue) =>
                setFormData((f) => ({
                  ...f,
                  completeNotificationText: newValue.value,
                  completeSelectedTemplateId: newValue.selectedTemplateId,
                  completeVariables: newValue.variables,
                }))
              }
              templateType={CustomerNotificationTemplateType.postVisit}
              selectedTemplateId={formData.completeSelectedTemplateId}
              variables={formData.completeVariables}
            />
          ) : null}
        </div>
      </FormContainer2>
    );
  };

export default CustomerNotificationsConfigurationForm;

function getDayScheduledTextForMessage(formData: IFormData): string {
  // Note: Need to keep "today"/"tomorrow" in sync with values in server's
  // GreenerPastures.Features.Administration.CustomerNotificationsConfiguration.Update
  return formData.preVisitNotificationType === dayOf ? "today" : "tomorrow";
}

function useInitializeFormData(
  setFormData: Dispatch<React.SetStateAction<IFormData>>,
  setOriginalFormData: Dispatch<React.SetStateAction<IFormData>>
) {
  const customerNotificationsConfiguration = useApplicationStateSelector(
    (s) => s.common.customerNotificationsConfiguration
  );

  const showForm = useApplicationStateSelector(
    (s) => s.forms.customerNotificationsConfiguration.showForm
  );

  useEffect(() => {
    if (showForm) {
      const newFormData: IFormData = {
        completeNotificationText:
          customerNotificationsConfiguration.completeNotificationText ?? "",
        preVisitNotificationText:
          customerNotificationsConfiguration.preVisitNotificationText ?? "",
        sendCompleteNotification:
          customerNotificationsConfiguration.sendCompleteNotification,
        sendPreVisitNotificationEnabled:
          customerNotificationsConfiguration.sendPreVisitNotification !==
          ScheduledCommunication.Never,
        preVisitNotificationType:
          customerNotificationsConfiguration.sendPreVisitNotification ===
          ScheduledCommunication.DayBefore
            ? dayBefore
            : dayOf,
        time: customerNotificationsConfiguration.preVisitNotificationTime
          ? timeService.getTimeComponents(
              customerNotificationsConfiguration.preVisitNotificationTime
            )
          : defaultTime,
        preVisitSelectedTemplateId:
          customerNotificationsConfiguration.preVisitSelectedTemplateId,
        preVisitVariables: customerNotificationsConfiguration.preVisitVariables,
        completeSelectedTemplateId:
          customerNotificationsConfiguration.completeSelectedTemplateId,
        completeVariables: customerNotificationsConfiguration.completeVariables,
      };

      if (newFormData.preVisitNotificationText.trim() === "") {
        newFormData.preVisitNotificationText =
          "Good Morning <Customer name>, your property at <Customer address> is on the schedule for today! Please be sure your lawn is ready for our crews by picking up any toys, unlocking gates and making sure your sprinklers are turned off.";
      }

      if (newFormData.completeNotificationText.trim() === "") {
        newFormData.completeNotificationText =
          "Hello, <Customer name>. Your service at <Customer address> has been completed.";
      }

      setFormData(newFormData);
      setOriginalFormData(newFormData);
    }
  }, [
    showForm,
    customerNotificationsConfiguration,
    setFormData,
    setOriginalFormData,
  ]);
}

function getEmptyFormData(): IFormData | (() => IFormData) {
  return {
    completeNotificationText: "",
    preVisitNotificationText: "",
    sendCompleteNotification: false,
    sendPreVisitNotificationEnabled: false,
    preVisitNotificationType: dayOf,
    time: defaultTime,
    preVisitSelectedTemplateId: null,
    preVisitVariables: {},
    completeSelectedTemplateId: null,
    completeVariables: {},
  };
}
