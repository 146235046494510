import { ITodoItem } from "../models/ITodoItem";
import {
  IJobInstance,
  oneTimeJobType,
  maintenanceJobType
} from "../models/IJobInstance";
import { ITodoTemplate } from "../models/ITodoTemplate";
import jobFinder from "./jobFinder";
import { IMaintenanceJob } from "../models/IMaintenanceJob";
import { IOneTimeJob } from "../models/IOneTimeJob";

function getTodoTemplate(
  todoTemplates: Array<ITodoTemplate>,
  todoTemplateId: string
) {
  const todoTemplate = todoTemplates.find(tt => tt.id === todoTemplateId);
  if (!todoTemplate) {
    throw new Error(`todo template ${todoTemplateId} not found`);
  }
  return todoTemplate;
}

export function getTodoItems(
  jobInstance: IJobInstance,
  todoTemplates: Array<ITodoTemplate>,
  maintenanceJobs: Array<IMaintenanceJob>,
  oneTimeJobs: Array<IOneTimeJob>
) {
  let todoItems: Array<ITodoItem> = [];
  let todoTemplate: ITodoTemplate | null = null;

  if (jobInstance.todoItemsLocked) {
    todoItems = jobInstance.todoItems;

    if (jobInstance.todoTemplateId) {
      todoTemplate = getTodoTemplate(todoTemplates, jobInstance.todoTemplateId);
    }
  } else if (
    jobInstance.useCustomTodoItems ||
    jobInstance.type === oneTimeJobType
  ) {
    if (jobInstance.todoTemplateId) {
      const todoTemplateId = jobInstance.todoTemplateId;
      todoTemplate = getTodoTemplate(todoTemplates, todoTemplateId);

      todoItems = todoTemplate.todoItems;
    } else {
      todoItems = jobInstance.todoItems;
    }
  } else if (jobInstance.type === maintenanceJobType) {
    const foundJobResult = jobFinder.getJobForDayScheduleV2(
      maintenanceJobs,
      oneTimeJobs,
      jobInstance
    );
    if (foundJobResult) {
      const maintenanceJob = (foundJobResult as unknown) as IMaintenanceJob;
      if (maintenanceJob.todoTemplateId) {
        todoTemplate = getTodoTemplate(
          todoTemplates,
          maintenanceJob.todoTemplateId
        );
        todoItems = todoTemplate.todoItems;
      } else {
        todoItems = maintenanceJob.todoItems;
      }
    }
  }

  return { todoItems, todoTemplate };
}
