import { ICustomer } from "../../../models/ICustomer";
import { ICustomerAdditionalLocation } from "../../../models/ICustomerAdditionalLocation";
import { IJobInstance } from "../../../models/IJobInstance";
import { IMaintenanceJob } from "../../../models/IMaintenanceJob";
import { IOneTimeJob } from "../../../models/IOneTimeJob";
import customerFinder from "../../../services/customerFinder";
import jobFinder from "../../../services/jobFinder";

export function doesJobMissingLocationExist({
  filteredJobInstances,
  jobs,
  oneTimeJobs,
  customers,
  customerAdditionalLocations,
}: {
  filteredJobInstances: IJobInstance[];
  jobs: IMaintenanceJob[];
  oneTimeJobs: IOneTimeJob[];
  customers: ICustomer[];
  customerAdditionalLocations: ICustomerAdditionalLocation[];
}) {
  let hasJobsMissingLocations = false;
  filteredJobInstances.forEach((ji) => {
    const job = jobFinder.getJobForDayScheduleV2(jobs, oneTimeJobs, ji);
    if (job) {
      if (!job.customerAdditionalLocationId) {
        const customer = customerFinder.getCustomerByJob(job, customers);
        if (!customer.latitude || !customer.longitude) {
          hasJobsMissingLocations = true;
        }
      } else {
        const customerAdditionalLocation = customerAdditionalLocations.find(
          (l) => l.id === job.customerAdditionalLocationId
        );
        if (
          customerAdditionalLocation &&
          (!customerAdditionalLocation.latitude ||
            !customerAdditionalLocation.longitude)
        ) {
          hasJobsMissingLocations = true;
        }
      }
    }
  });
  return hasJobsMissingLocations;
}
