import { TenantSubscriptionStatus } from "../models/IInitialLoad";
import { useApplicationStateSelector } from "./useApplicationStateSelector";

export function useCreditCardsEnabled() {
  const payrixMerchantAccountId = useApplicationStateSelector(
    (s) => s.common.payrixMerchantAccountId
  );

  const tenantSubscriptionStatus = useApplicationStateSelector(
    (s) => s.common.tenantSubscriptionStatus
  );

  return {
    areCreditCardsEnabled:
      areCreditCardsEnabled(payrixMerchantAccountId) &&
      tenantSubscriptionStatus === TenantSubscriptionStatus.Subscribed,
  };
}

export function areCreditCardsEnabled(payrixMerchantAccountId: string) {
  return !!payrixMerchantAccountId;
}
