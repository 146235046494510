import React from "react";
import { useDispatch } from "react-redux";
import FormContainer, {
  FormDefaults,
  IValidateResult,
} from "../components/FormContainer";
import { actionCreators } from "../../../modules/actionCreators";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { formTypes } from "../../../formGenerator";
import { IFormType } from "../../../formGenerator/formTypes";

interface IProps<K extends keyof typeof formTypes> {
  formType: K;
  validate?(): IValidateResult;
  // First infered type not needed but required
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getFormData?(): (typeof formTypes)[K] extends IFormType<
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    infer _,
    infer TPayload
  >
    ? TPayload
    : {};
  formHeader: string;
  children: React.ReactNode | ((formDefaults: FormDefaults) => React.ReactNode);
  saveButtonText?: string;
  useKeyboard?: boolean;
  hideSave?: boolean;
  disableFade?: boolean;
  hasFormDataChanged?: () => boolean;
  size?: string;
  showForm?: boolean;
  disableSave?: boolean;
  saveHandlerOverride?(): void;
  saveButtonExplanation?: React.ReactNode;
}

const FormContainer2 = <T extends keyof typeof formTypes>(props: IProps<T>) => {
  const formType = props.formType;

  const defaultShowForm = useApplicationStateSelector(
    (s) => s.forms[formType].showForm
  );
  const saving = useApplicationStateSelector((s) => s.forms[formType].saving);
  const errorMessage = useApplicationStateSelector(
    (s) => s.forms[formType].errorMessage
  );
  const dispatch = useDispatch();

  return (
    <FormContainer
      {...props}
      setErrorMessage={(errorMessage) =>
        dispatch(actionCreators.forms[formType].setErrorMessage(errorMessage))
      }
      showForm={defaultShowForm && (props.showForm ?? true)}
      errorMessage={errorMessage}
      saving={saving}
      startSave={(data) =>
        dispatch(actionCreators.forms[formType].startSaving(data))
      }
      cancel={() => dispatch(actionCreators.forms[formType].cancelForm())}
      formKey={formType.toString()}
      hasFormDataChanged={props.hasFormDataChanged}
    >
      {props.children}
    </FormContainer>
  );
};

export default FormContainer2;
