import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import { getDefaultRoute } from "../../../services/routing";
import UserNotificationsForm from "../forms/UserNotificationsForm";
import PageWithNavBar2 from "../PageWithNavBar2";

const UserNotificationsFormPage = () => {
  const dispatch = useDispatch();

  return (
    <>
      <PageWithNavBar2 billingContext />
      <UserNotificationsForm
        onClose={() => {
          dispatch(push(getDefaultRoute()));
        }}
      />
    </>
  );
};

export { UserNotificationsFormPage };
