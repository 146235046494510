import { BillingFrequencyType } from "../../../models/ICustomerPaymentConfiguration";
import { InvoiceDeliveryMethod } from "../../../models/InvoiceDeliveryMethod";
import { formatCurrency } from "../../../services/currencyFormatter";
import dateService from "../../../services/dateService";
import { getTotal } from "../../../services/lineItemService";
import { isStringSet } from "../../../services/stringService";
import { IFormData } from "./CustomerContractBillingForm.types";

export function getBillingExplanation(formData: IFormData) {
  const frequency = getFrequencyTypeForDisplay(formData.frequencyType);
  const total = getTotal({
    taxRate: formData.taxRate,
    lineItems: formData.lineItems,
  });

  if (!isStringSet(frequency) || !formData.startingDate || !total) {
    return null;
  }

  if (
    getParsedDeliveryMethod(formData) ===
    InvoiceDeliveryMethod.paymentMethodOnFile
  ) {
    let endingDateSuffix = " with no end";
    if (formData.endingDate) {
      endingDateSuffix = ` and end on ${dateService.formatDateForDisplay(
        formData.endingDate
      )}`;
    }

    return `${frequency} charges for ${formatCurrency(
      total
    )} will begin on ${dateService.formatDateForDisplay(
      formData.startingDate
    )}${endingDateSuffix}.`;
  } else {
    let endingDateSuffix = " with no end";
    if (formData.endingDate) {
      endingDateSuffix = ` and ending on ${dateService.formatDateForDisplay(
        formData.endingDate
      )}`;
    }

    return `${frequency} invoices will be sent for ${formatCurrency(
      total
    )} beginning on ${dateService.formatDateForDisplay(
      formData.startingDate
    )}${endingDateSuffix}.`;
  }
}

function getFrequencyTypeForDisplay(
  frequencyType: BillingFrequencyType | null
) {
  switch (frequencyType) {
    case BillingFrequencyType.monthly:
      return "Monthly";
    case BillingFrequencyType.weekly:
      return "Weekly";
    case BillingFrequencyType.quarterly:
      return "Every 3 months";
    case BillingFrequencyType.semiAnnually:
      return "Every 6 months";
    case BillingFrequencyType.annually:
      return "Annual";
  }

  return "";
}

export function getParsedDeliveryMethod(
  formData: IFormData
): InvoiceDeliveryMethod {
  return parseInt(formData.invoiceDeliveryMethod);
}
