import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FormContainerWithoutRedux from "../../../containers/app/components/FormContainerWithoutRedux";
import RichTextEditor from "../../../containers/app/components/RichTextEditor";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import { IProposalTemplate } from "../models/IProposalTemplate";
import proposalDataProvider from "../services/proposalDataProvider";
import { convertFromRaw, EditorState } from "draft-js";
import {
  isTemplateTextSet,
  serializeTextState,
} from "../../../services/richTextService";
import { IInvoiceItem } from "../../../models/IInvoiceItem";
import InvoiceLineItems from "../../../containers/app/components/InvoiceLineItems";
import { mapToProposalTemplateLineItems } from "../../../services/invoiceFormService";
import { mapToFormLineItems } from "../services/proposalService";
import { ILineItem } from "../../../containers/app/components/InvoiceLineItem";
import InfoToolTip from "../../../containers/app/components/InfoToolTip";
import DepositRequiredFields from "./ProposalForm/DepositRequiredFields";
import CaptureCustomerPaymentMethod from "./ProposalForm/CaptureCustomerPaymentMethod";
import { DepositType } from "../../../enums/DepositType";
import { isDepositRequired } from "../services/proposalService";
import { IDepositSettings } from "../../../models/IDepositSettings";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { isStringSet } from "../../../services/stringService";
import { ErrorMessageType } from "../../../containers/app/components/FormContainer";

interface IProps {
  template: Partial<IProposalTemplate> | null;
  invoiceItems: IInvoiceItem[];
  setInvoiceItems: (newValue: Array<IInvoiceItem>) => void;
  onSaveComplete: (id: string, completed: boolean) => void;
  onCancel: () => void;
}

type ProposalTemplateFormData = Partial<IProposalTemplate> & {
  subject: string;
  invoiceLineItems: ILineItem[];
  introTextState: EditorState;
  footerTextState: EditorState;
  showLineItemPrices: boolean;
  depositRequired: boolean;
  captureCustomerPaymentMethod: boolean;
  captureCustomerPaymentMethodOptional: boolean;
  daysValid: number | null;
};

const ProposalTemplateForm: React.FunctionComponent<IProps> = ({
  template,
  invoiceItems,
  setInvoiceItems,
  onSaveComplete,
  onCancel,
}) => {
  const tenantName = useApplicationStateSelector((s) => s.common.tenantName);
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");
  const { control, getValues, register, watch, setValue } =
    useForm<ProposalTemplateFormData>({
      defaultValues: {
        ...template,
        introTextState:
          template && isTemplateTextSet(template.introText ?? "")
            ? EditorState.createWithContent(
                convertFromRaw(JSON.parse(template?.introText ?? ""))
              )
            : EditorState.createEmpty(),
        footerTextState:
          template && isTemplateTextSet(template.footerText ?? "")
            ? EditorState.createWithContent(
                convertFromRaw(JSON.parse(template?.footerText ?? ""))
              )
            : EditorState.createEmpty(),
        lineItems: template?.lineItems ?? [],
        invoiceLineItems: mapToFormLineItems(template?.lineItems ?? []) ?? [],
        showLineItemPrices: template?.hideLineItemPrices
          ? !template.hideLineItemPrices
          : true,
        depositRequired: isDepositRequired(template?.depositSettings ?? null),
        captureCustomerPaymentMethod:
          template?.captureCustomerPaymentMethod ?? false,
        captureCustomerPaymentMethodOptional:
          template?.captureCustomerPaymentMethodOptional ?? false,
        subject: getSubjectForFormData({ template, tenantName }),
      },
    });
  const showLineItemPrices = watch("showLineItemPrices");
  const depositSettings = watch("depositSettings");
  const captureCustomerPaymentMethod = watch("captureCustomerPaymentMethod");
  const captureCustomerPaymentMethodOptional = watch(
    "captureCustomerPaymentMethodOptional"
  );

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <FormContainerWithoutRedux
        formHeader={
          template?.id ? "Update Proposal Template" : "Add Proposal Template"
        }
        formType={FormTypesV2.proposalTemplateForm}
        size="lg"
        save={() =>
          proposalDataProvider.saveProposalTemplate(
            buildDataForSave({ tenantName })
          )
        }
        onSaveComplete={(result) => {
          onSaveComplete(result.id, result.created);
        }}
        onCancel={() => {
          onCancel();
        }}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      >
        <div className="form-row">
          <div className="form-group col-12 col-lg-8">
            <label htmlFor="name" className="required">
              Name
            </label>
            <input
              id="name"
              type="text"
              className="form-control"
              required={true}
              autoComplete="off"
              maxLength={100}
              {...register("name")}
            />
          </div>
          <div className="form-group col-12 col-lg-4">
            <label htmlFor="daysValid">Number of days valid</label>
            <input
              id="daysValid"
              type="number"
              className="form-control"
              autoComplete="off"
              max={365}
              min={1}
              {...register("daysValid")}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              id="primaryDefaultCheckbox"
              type="checkbox"
              className="custom-control-input"
              {...register("isPrimaryTemplate")}
            />
            <label
              htmlFor="primaryDefaultCheckbox"
              className="custom-control-label"
            >
              Default template?
            </label>
            <InfoToolTip
              id="defaultTemplateToolTip"
              text="The default template will be automatically applied when creating new proposals."
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="subject" className="required">
            Subject
          </label>
          <InfoToolTip
            id="subjectTooltip"
            text="The subject will appear to your customers as the proposal title and the proposal's email subject line."
          />
          <input
            id="subject"
            type="text"
            className="form-control"
            required={true}
            autoComplete="off"
            maxLength={200}
            {...register("subject")}
          />
        </div>

        <div className="form-group">
          <h5>Intro text</h5>
          <div id="introTextContainer" data-testid="introTextContainer">
            <Controller
              name={`introTextState`}
              control={control}
              render={({ field }) => (
                <RichTextEditor
                  editorState={field.value as EditorState}
                  setEditorState={(newText) => field.onChange(newText)}
                  ariaLabel="Intro text"
                  placeholder="Type your intro text here..."
                />
              )}
            />
          </div>
        </div>

        <CaptureCustomerPaymentMethod
          idPrefix="ProposalTemplate"
          captureCustomerPaymentMethod={captureCustomerPaymentMethod}
          setCaptureCustomerPaymentMethod={(newValue: boolean) => {
            setValue("captureCustomerPaymentMethod", newValue);
          }}
          captureCustomerPaymentMethodOptional={
            captureCustomerPaymentMethodOptional
          }
          setCaptureCustomerPaymentMethodOptional={(newValue: boolean) => {
            setValue("captureCustomerPaymentMethodOptional", newValue);
          }}
        />

        <div className="form-group">
          <h5>Line Items</h5>
          <div className="custom-control custom-checkbox">
            <input
              id="showLineItemPrices"
              type="checkbox"
              className="custom-control-input"
              {...register("showLineItemPrices")}
            />
            <label
              htmlFor="showLineItemPrices"
              className="custom-control-label"
            >
              Show line item prices
            </label>
          </div>
        </div>

        <div className="form-group">
          <Controller
            name={`invoiceLineItems`}
            control={control}
            render={({ field }) => (
              <InvoiceLineItems
                elementIdPrefix="proposalTemplate"
                lineItems={field.value}
                invoiceItems={invoiceItems}
                setInvoiceItems={setInvoiceItems}
                setLineItems={(newValue) => field.onChange(newValue)}
                showHideOption={!showLineItemPrices}
                onClearErrorMessage={() => {
                  setErrorMessage("");
                }}
                allowBlankAmountPerItem={true}
                allowBlankQuantity={true}
                allowZeroLineItems={true}
                customerTaxExempt={false}
                taxRateAlreadySet={false}
                allowOptionalItems={true}
              />
            )}
          />
        </div>

        <DepositRequiredFields
          idPrefix="ProposalTemplate"
          depositSettings={depositSettings ?? null}
          setDepositSettings={(newValue: IDepositSettings | null) => {
            setValue("depositSettings", newValue);

            if (
              newValue?.type === DepositType.percent ||
              newValue?.type === DepositType.amount
            ) {
              setValue("depositRequired", true);
            } else {
              setValue("depositRequired", false);
            }
          }}
        />

        <div className="form-group">
          <h5>Footer text</h5>
          <div data-testid="footerTextContainer">
            <Controller
              name={`footerTextState`}
              control={control}
              render={({ field }) => (
                <RichTextEditor
                  editorState={field.value as EditorState}
                  setEditorState={(newText) => field.onChange(newText)}
                  ariaLabel="Footer text"
                  placeholder="Type your footer text here..."
                />
              )}
            />
          </div>
        </div>
      </FormContainerWithoutRedux>
    </div>
  );

  function buildDataForSave({
    tenantName,
  }: {
    tenantName: string | null;
  }): IProposalTemplate {
    let formValues = getValues();

    let depositSettingsForSave = depositSettings;
    if (!isDepositRequired(depositSettingsForSave ?? null)) {
      depositSettingsForSave = null;
    }

    return {
      id: formValues.id,
      name: formValues.name,
      isPrimaryTemplate: formValues.isPrimaryTemplate,
      introText: serializeTextState(formValues.introTextState as EditorState),
      footerText: serializeTextState(formValues.footerTextState as EditorState),
      hideLineItemPrices: !formValues.showLineItemPrices,
      lineItems: mapToProposalTemplateLineItems(
        formValues.invoiceLineItems,
        !formValues.showLineItemPrices,
        invoiceItems
      ),
      depositSettings: depositSettingsForSave,
      captureCustomerPaymentMethod: captureCustomerPaymentMethod,
      captureCustomerPaymentMethodOptional:
        captureCustomerPaymentMethodOptional,
      daysValid: formValues.daysValid,
      subject:
        formValues.subject === getDefaultSubject({ tenantName })
          ? null
          : formValues.subject,
    } as IProposalTemplate;
  }
};

export default ProposalTemplateForm;

function getSubjectForFormData({
  template,
  tenantName,
}: {
  template: Partial<IProposalTemplate> | null;
  tenantName: string | null;
}): string {
  if (template && template.subject && isStringSet(template.subject)) {
    return template.subject;
  }

  return getDefaultSubject({ tenantName });
}

function getDefaultSubject({
  tenantName,
}: {
  tenantName: string | null;
}): string {
  if (!tenantName) {
    return "";
  }

  return `Proposal from ${tenantName}`;
}
