import {
  actionTypes,
  createSpecificActionTypeName,
  ICompleteSavingAction,
  ISetErrorMessageAction,
  IShowFormAction,
} from "./actionTypes";
import { formTypes, IFormType } from "./formTypes";
import { combineReducers, Reducer } from "redux";
import { objectMap } from "./objectMap";
import { IAction } from "../modules/actionTypeDefinitions";
import React from "react";

function createReducer<TParameters, TPayload>(
  formToCreateKey: keyof typeof formTypes
) {
  return (
    state: IFormState<TParameters> | undefined,
    action: IAction
  ): IFormState<TParameters> => {
    if (!state) {
      state = {
        showForm: false,
        saving: false,
        errorMessage: "",
        parameters: null,
        saveCount: 0,
      };
    }

    const createTypeNameForForm = (actionType: any) =>
      createSpecificActionTypeName(formTypes[formToCreateKey], actionType);

    switch (action.type) {
      case createTypeNameForForm(actionTypes.showForm):
        const showFormAction = action as IShowFormAction<TParameters>;
        return {
          ...state,
          showForm: true,
          saving: false,
          errorMessage: "",
          parameters: showFormAction.parameters,
        };

      case createTypeNameForForm(actionTypes.cancelForm):
        return {
          ...state,
          showForm: false,
          parameters: null,
        };

      case createTypeNameForForm(actionTypes.startSaving):
        return {
          ...state,
          saving: true,
        };

      case createTypeNameForForm(actionTypes.completeSaving):
        const completeSavingAction = action as ICompleteSavingAction<
          TParameters,
          TPayload
        >;
        return {
          ...state,
          showForm: completeSavingAction.keepFormOpen ?? false,
          saving: false,
          parameters: null,
          saveCount: state.saveCount + 1,
        };

      case createTypeNameForForm(actionTypes.setErrorMessage):
        const setErrorMessageAction = action as ISetErrorMessageAction;
        return {
          ...state,
          saving: false,
          errorMessage: setErrorMessageAction.errorMessage,
        };

      case createTypeNameForForm(actionTypes.clearErrorMessage):
        return {
          ...state,
          errorMessage: "",
        };

      default:
        return state;
    }
  };
}

type FormStateConversion<T> = {
  // Need to match IFormType
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [K in keyof T]: T[K] extends IFormType<infer TParameters, infer _>
    ? IFormState<TParameters>
    : IFormState<any>;
};

export type IFormsState = FormStateConversion<typeof formTypes>;

export interface IFormState<TParameters> {
  showForm: boolean;
  saving: boolean;
  errorMessage: string | React.ReactNode;
  parameters: TParameters | null;
  saveCount: number;
}

type FormReducerConversion<T> = {
  // Need to match IFormType
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [K in keyof T]: T[K] extends IFormType<infer TParameters, infer _>
    ? Reducer<IFormState<TParameters>, any>
    : Reducer<IFormState<any>, any>;
};

const formReducers = combineReducers<IFormsState>(
  objectMap(formTypes, (key) => createReducer(key)) as FormReducerConversion<
    typeof formTypes
  >
);

export default formReducers;
