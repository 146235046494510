import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties } from "react";

export function PrintContainer({
  printTypeTitleCased,
  printTypeLowerCased,
  children,
}: {
  printTypeTitleCased: string;
  printTypeLowerCased: string;
  children: React.ReactElement;
}) {
  return (
    <>
      <div className="d-block d-sm-none d-print-none">
        <PageJumbotron
          header={
            <span>
              <FontAwesomeIcon icon={faPrint} /> Print Your{" "}
              {printTypeTitleCased}
            </span>
          }
          message={
            <>
              <div>Please print with your browser print button.</div>
              <div>
                The full {printTypeLowerCased} will appear when printed.
              </div>
            </>
          }
          headerStyle={{ fontSize: "2rem" }}
        />
      </div>
      <div className="d-none d-sm-block d-print-block">{children}</div>
    </>
  );
}

function PageJumbotron({
  header,
  message,
  headerStyle,
}: {
  header: string | React.ReactElement;
  message: string | React.ReactElement;
  headerStyle?: Partial<CSSProperties>;
}) {
  return (
    <div className="full-height bg-secondary">
      <div className="container pt-5">
        <div className="jumbotron bg-light">
          <h1 className="display-4" style={headerStyle}>
            {header}
          </h1>
          <div className="lead">{message}</div>
        </div>
      </div>
    </div>
  );
}
