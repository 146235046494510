import { of } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import { logInformation } from "../../services/errorLogger";
import remoteDataProvider from "../../services/remoteDataProvider";
import { actionCreators } from "../actionCreators";
import { IProjectSaved } from "../actionTypeDefinitions";
import { concat, forkJoin } from "rxjs";
import projectDataProvider from "../../slices/schedule/services/projectDataProvider";
import { projectActionCreators } from "../../slices/schedule/modules/project";

export function handleJobInstanceBulkAdded(action: IProjectSaved) {
  const jobIds = action.savedJobs.map((j) => j.createdId);
  const projectIds = action.savedJobs
    .filter((j) => typeof j.projectId === "string")
    .map((j) => j.projectId as string);

  return forkJoin({
    jobs: remoteDataProvider.getOneTimeJobs(jobIds),
    projects:
      projectIds.length > 0
        ? projectDataProvider.getProjects({ projectIds })
        : of([]),
  }).pipe(
    mergeMap(({ jobs, projects }) => {
      return concat(
        of(
          actionCreators.loadOneTimeJobsComplete(
            jobs.oneTimeJobs,
            jobs.customers,
            jobs.customerAdditionalLocations
          )
        ),
        of(projectActionCreators.loadProjectsComplete({ projects }))
      );
    }),

    catchError(() => {
      logInformation("Unable to load jobs after bulk add");
      return of(actionCreators.null());
    })
  );
}
