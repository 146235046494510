export enum TenantIndustry {
  ApplianceRepair = 0,
  AudioVisualInstallationRepair = 1,
  BuildingInspection = 2,
  CarpetCleaning = 3,
  ChimneySweepHearth = 4,
  CleaningMaidServices = 5,
  CommercialFoodEquipmentService = 6,
  DrywallInsulationContractors = 7,
  DuctCleaning = 8,
  Electrical = 9,
  ElectricalSigns = 10,
  ElevatorEscalator = 11,
  Environmental = 12,
  EvCharging = 13,
  Fencing = 14,
  FireRelatedServices = 15,
  FlooringTiling = 16,
  FoundationRepair = 17,
  GarageDoor = 18,
  GlassInstallation = 19,
  GutterServices = 20,
  Handyman = 21,
  HVAC = 22,
  Irrigation = 23,
  JanitorialServices = 24,
  Landscaping = 25,
  LawnCareServices = 26,
  LeakDetection = 27,
  Locksmith = 28,
  Moving = 29,
  Painting = 30,
  Paving = 31,
  PestControl = 32,
  Plumbing = 33,
  PoolSpa = 34,
  PressureWashers = 35,
  Refrigeration = 36,
  Restoration = 37,
  Roofing = 38,
  SecuritySystems = 39,
  Septic = 40,
  Solar = 41,
  StormwaterManagement = 42,
  TreeServices = 43,
  WaterTreatment = 44,
  Waterproofing = 45,
  WellDrilling = 46,
  WindowCleaning = 47,
  Other = 48,
}
