import { DiscountType } from "../../../enums/DiscountType";
import { UserAccountRole } from "../../../enums/userAccountRole";
import { isAdmin } from "../../../hooks/useIsAdmin";
import { IDiscount } from "../../../models/IDiscount";
import {
  ISeasonalDate,
  MaintenanceJobFrequency,
} from "../../../models/IMaintenanceJob";
import { IMaintenanceJobScheduleProperties } from "../../../models/IMaintenanceJobScheduleProperties";
import {
  getGrossRevenuePerVisitForForm,
  getDefaultCrewId,
} from "../../../services/jobService";
import { areDiscountAndTaxRateEnabled } from "../components/JobBillingConfiguration.functions";
import { IFormData, IFormDataSeasonalDate } from "./MaintenanceJobForms.types";
import modalConversion from "../../../services/modelConversion";
import dateFnsFormat from "date-fns/format";
import { IFrequency } from "../components/FrequencySelection";
import { RouterState } from "connected-react-router";
import { ICrew } from "../../../models/ICrew";
import dateService from "../../../services/dateService";
import { SetUserSettingsType } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import constants from "../../../constants";

export function getFormData({
  formData,
  userAccountRole,
  crews,
  router,
  isCrewTimeBased,
  setUserSettings,
}: {
  formData: IFormData;
  userAccountRole: UserAccountRole;
  router: RouterState;
  crews: Array<ICrew>;
  isCrewTimeBased: boolean;
  setUserSettings: SetUserSettingsType;
}) {
  const discountAndTaxRateEnabled = areDiscountAndTaxRateEnabled(
    formData.billingType
  );
  const emptyDiscount: IDiscount = {
    type: DiscountType.amount,
    amount: null,
    percent: null,
  };

  const dataForSave = {
    customerId: formData.customerId,
    customerAdditionalLocationId: formData.customerAdditionalLocationId,
    crewId: formData.crewId,
    estimatedManHours: formData.estimatedManHours || null,
    id: formData.id ? formData.id : "",
    highlightCrewNotes: formData.highlightCrewNotes,
    showCrewNotesOnAdminJobCards: formData.showCrewNotesOnAdminJobCards,
    notes: formData.notes,
    administratorOnlyNotes: formData.administratorOnlyNotes,
    todoItems: formData.todoItems
      .filter((i) => i.text.trim())
      .map((i) => ({ ...i })),
    photos: formData.photos.map((i) => ({
      ...i,
      imagePath: i.imagePath as string,
    })),
    todoTemplateId: formData.todoTemplateId || null,
    billingType: formData.billingType,
    grossRevenuePerVisit: getGrossRevenuePerVisitForForm(
      formData.billingType,
      formData.grossRevenuePerVisit,
      formData.lineItems,
      formData.discount,
      formData.taxRate
    ),
    lineItems: formData.lineItems,
    taxRate: discountAndTaxRateEnabled ? formData.taxRate : null,
    discount: discountAndTaxRateEnabled ? formData.discount : emptyDiscount,
    categories: formData.categories,
    opportunityId: formData.opportunityId,
    paymentMethodOnFileAuthorized: formData.paymentMethodOnFileAuthorized,
    hideLineItemPrices: formData.hideLineItemPrices,
    startTime: isCrewTimeBased
      ? dateService.formatTimeForSerialization(formData.startTime)
      : null,
    endTime: isCrewTimeBased
      ? dateService.formatTimeForSerialization(formData.endTime)
      : null,
    arrivalWindowDurationMinutes: isCrewTimeBased
      ? formData.arrivalWindowDurationMinutes
      : null,
  };

  const scheduleProperties = getSchedulePropertiesFormData(formData);

  // If this is a flexible job and no crew is set, need to default one to allow saving to the server.
  if (isFlexibleJob(formData) && !dataForSave.crewId) {
    dataForSave.crewId = getDefaultCrewId(crews, router);
  }

  if (!shouldUseWeekPicker(formData)) {
    scheduleProperties.daysOfWeek = [];
  }

  if (!isAdmin(userAccountRole)) {
    delete (dataForSave as any).billingType;
    delete (dataForSave as any).grossRevenuePerVisit;
    delete (dataForSave as any).lineItems;
    delete (dataForSave as any).paymentMethodOnFileAuthorized;
    delete (dataForSave as any).hideLineItemPrices;
  }

  if (isCrewTimeBased && !formData.id) {
    setUserSettings(
      UserSettingsType.jobArrivalWindowDurationMinutes,
      formData.arrivalWindowDurationMinutes
    );
  }

  return {
    ...dataForSave,
    ...scheduleProperties,
    precedingJobId: !scheduleProperties.daysOfWeek.includes("0")
      ? formData.precedingJobId
      : null,
    // TODO: Add testing
    precedingJobIdModified: formData.precedingJobIdModified,
    dayScheduleOverride: formData.dayScheduleOrderOverride,
    // Temporary until we figure out how to handle non-peak day of week
    seasonalScheduleDayOfWeek: undefined,
  };
}

export function getSchedulePropertiesFormData(
  formData: IFormData
): IMaintenanceJobScheduleProperties {
  const scheduleProperties: IMaintenanceJobScheduleProperties = {
    daysOfWeek: formData.daysOfWeek.includes(constants.flexibleDayOfWeekId)
      ? [constants.flexibleDayOfWeekId]
      : formData.daysOfWeek,
    frequency: formData.frequency.frequencyType,
    monthlyWeek: modalConversion.convertStringToNumberOrNull(
      formData.frequency.monthlyWeek
    ),
    seasonalMonthlyWeek: modalConversion.convertStringToNumberOrNull(
      formData.seasonalScheduleFrequency.monthlyWeek
    ),
    customFrequencyPeriod: modalConversion.convertStringToNumber(
      formData.frequency.customFrequencyPeriod
    ),
    customFrequencyValue: modalConversion.convertStringToNumberOrNull(
      formData.frequency.customFrequencyValue
    ),
    seasonalScheduleEnd: convertSeasonalDate(formData.seasonalScheduleEnd),
    seasonalScheduleStart: convertSeasonalDate(formData.seasonalScheduleStart),
    seasonalScheduleEstimatedManHours:
      modalConversion.convertStringToNumberOrNull(
        formData.seasonalScheduleEstimatedManHours
      ),
    seasonalScheduleFrequency: formData.seasonalScheduleFrequency.frequencyType,
    seasonalScheduleCustomFrequencyPeriod:
      modalConversion.convertStringToNumberOrNull(
        formData.seasonalScheduleFrequency.customFrequencyPeriod
      ),
    seasonalScheduleCustomFrequencyValue:
      modalConversion.convertStringToNumberOrNull(
        formData.seasonalScheduleFrequency.customFrequencyValue
      ),
    startingDate: formData.startingDate,
    endingDate: formData.endingDate,
    seasonalScheduleDaysOfWeek: formData.seasonalScheduleDaysOfWeek,
  };

  if (!formData.scheduleVariesBySeason) {
    scheduleProperties.seasonalScheduleStart = null;
    scheduleProperties.seasonalScheduleEnd = null;
    scheduleProperties.seasonalScheduleFrequency = null;
    scheduleProperties.seasonalScheduleEstimatedManHours = null;
    scheduleProperties.seasonalScheduleDaysOfWeek = [];
  }

  return {
    ...scheduleProperties,
    startingDate: !!scheduleProperties.startingDate
      ? dateFnsFormat(scheduleProperties.startingDate, "YYYY-MM-DD")
      : null,
    endingDate: !!scheduleProperties.endingDate
      ? dateFnsFormat(scheduleProperties.endingDate, "YYYY-MM-DD")
      : null,
  };
}

export function shouldUseWeekPicker(formData: IFormData) {
  const isCustomDailyFrequency = (frequency: IFrequency | null) => {
    if (!frequency) {
      return false;
    }

    return (
      frequency.frequencyType === MaintenanceJobFrequency.Custom &&
      frequency.customFrequencyPeriod === "0"
    );
  };

  return (
    !isCustomDailyFrequency(formData.frequency) &&
    !(
      formData.scheduleVariesBySeason &&
      isCustomDailyFrequency(formData.seasonalScheduleFrequency)
    ) &&
    formData.frequency.frequencyType !== MaintenanceJobFrequency.Daily
  );
}

export function isFlexibleJob(formData: IFormData) {
  return formData.daysOfWeek.includes("0") && shouldUseWeekPicker(formData);
}

export function isOnlyFlexibleJob(formData: IFormData) {
  return formData.daysOfWeek.length === 1 && formData.daysOfWeek[0] === "0";
}

export function getCrewIdForForm(
  crews: ICrew[],
  router: RouterState,
  defaultFormData: Partial<IFormData> | null
) {
  let crewId = getDefaultCrewId(crews, router);
  if (
    defaultFormData?.crewId &&
    crews.some((c) => c.id === defaultFormData.crewId && !c.inactive)
  ) {
    crewId = defaultFormData.crewId;
  }

  return crewId;
}

function convertSeasonalDate(
  input: IFormDataSeasonalDate | null
): ISeasonalDate | null {
  if (input) {
    return {
      month: modalConversion.convertStringToNumber(input.month),
      dayOfMonth: modalConversion.convertStringToNumber(input.dayOfMonth),
    };
  } else {
    return null;
  }
}
