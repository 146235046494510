import { IJob } from "../models/IJob";
import { ICustomer } from "../models/ICustomer";
import remoteDataProvider, {
  IGetMaintenanceJobsResult,
  IGetOneTimeJobsResult,
} from "./remoteDataProvider";
import { of } from "rxjs";
import { IProject } from "../slices/schedule/models/IProject";
import { ICachedItem } from "../models/ICachedItem";
import { CachedMaintenanceJob, CachedOneTimeJob } from "../modules/job";

export function getJobsToLoad(
  jobIdsToEnsureLoaded: Array<string>,
  jobs: Array<IJob & ICachedItem>,
  jobsLoading: Array<string>
) {
  return getItemsToLoad(jobIdsToEnsureLoaded, jobs, jobsLoading);
}

export function getProjectsToLoad(
  projectIdsToEnsureLoaded: Array<string>,
  projects: Array<IProject & ICachedItem>,
  projectsLoading: Array<string>
) {
  return getItemsToLoad(projectIdsToEnsureLoaded, projects, projectsLoading);
}

export function getCustomersToLoad(
  customerIdsToEnsureLoaded: Array<string>,
  customers: Array<ICustomer>,
  customersLoading: Array<string>
) {
  const missingCustomerIds = customerIdsToEnsureLoaded.filter(
    (customerId) => !customers.find((customer) => customer.id === customerId)
  );

  const missingCustomerIdsNotAlreadyLoading = missingCustomerIds.filter(
    (missingOneTimeJobId) =>
      !customersLoading.find(
        (customerLoading) => missingOneTimeJobId === customerLoading
      )
  );

  return missingCustomerIdsNotAlreadyLoading;
}

export function loadMissingJobs(
  oneTimeJobIdsToEnsureLoaded: Array<string>,
  maintenanceJobIdsToEnsureLoaded: Array<string>,
  existingOneTimeJobs: Array<CachedOneTimeJob>,
  jobsLoading: string[],
  existingMaintenanceJobs: Array<CachedMaintenanceJob>
) {
  let loadOneTimeJobs = of({
    oneTimeJobs: [],
    customers: [],
    customerAdditionalLocations: [],
  } as IGetOneTimeJobsResult);
  let loadMaintenanceJobs = of({
    maintenanceJobs: [],
    customers: [],
    customerAdditionalLocations: [],
  } as IGetMaintenanceJobsResult);

  const oneTimeJobIdsToLoad = getJobsToLoad(
    oneTimeJobIdsToEnsureLoaded,
    existingOneTimeJobs,
    jobsLoading
  );
  if (oneTimeJobIdsToLoad.length > 0) {
    loadOneTimeJobs = remoteDataProvider.getOneTimeJobs(oneTimeJobIdsToLoad);
  }
  const maintenanceJobIdsToLoad = getJobsToLoad(
    maintenanceJobIdsToEnsureLoaded,
    existingMaintenanceJobs,
    jobsLoading
  );
  if (maintenanceJobIdsToLoad.length > 0) {
    loadMaintenanceJobs = remoteDataProvider.getMaintenanceJobs(
      maintenanceJobIdsToLoad
    );
  }
  return { loadOneTimeJobs, loadMaintenanceJobs };
}

function getItemsToLoad(
  itemIdsToEnsureLoaded: Array<string>,
  items: Array<{ id: string; stale: boolean }>,
  itemsLoading: Array<string>
) {
  const missingItemIds = itemIdsToEnsureLoaded.filter(
    (itemId) => !items.find((item) => item.id === itemId && !item.stale)
  );

  const missingItemIdsNotAlreadyLoading = missingItemIds.filter(
    (missingProjectId) =>
      !itemsLoading.find((projectId) => missingProjectId === projectId)
  );

  return missingItemIdsNotAlreadyLoading;
}
