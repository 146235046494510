import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { isStringSet } from "./stringService";

export function serializeTextState(editorState: EditorState) {
  return JSON.stringify(convertToRaw(editorState.getCurrentContent()));
}

export function initializeTextState(
  sourceText: string | null,
  setState: (newState: EditorState) => void
) {
  if (sourceText) {
    setState(parseSourceText(sourceText));
  }
}

export function parseSourceText(input: string | null) {
  if (!isStringSet(input)) {
    return EditorState.createEmpty();
  }

  const contentState = convertFromRaw(JSON.parse(input));
  return EditorState.createWithContent(contentState);
}

export function isTemplateTextSet(source: string | null): source is string {
  if (typeof source === "string" && source.trim() !== "") {
    return true;
  }

  return false;
}
