import FormContainer2 from "../../../containers/app/components/FormContainer2";
import React, { useEffect, useState } from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import {
  getErrorMessageFromError,
  getErrorMessagesFromError,
} from "../../../services/httpErrorHandler";
import constants from "../../../constants";
import SubscriptionFormElements from "./SubscriptionFormElements";
import { useSubscribeProvider } from "../../../hooks/useSubscribeProvider";
import { Elements } from "@stripe/react-stripe-js";
import { getStripePromise } from "../../../services/stripeService";
import { finalize, mergeMap } from "rxjs/operators";
import conditionalRenderer from "../../../containers/app/components/conditionalRenderer";
import { useMerchantOnboardingProvider } from "../../../hooks/useMerchantOnboardingProvider";
import { Observable } from "rxjs";
import { AjaxResponse } from "rxjs/ajax";
import MerchantOnboardingFormElements from "../../../containers/app/components/merchantOnboarding/MerchantOnboardingFormElements";
import { Flags, isFeatureFlagSet } from "../../../services/featureFlagService";
import { getIntroText } from "../../../services/merchantOnboardingService";
import {
  fullStoryLogError,
  fullStoryTrack,
} from "../../../services/fullStoryService";
import { formatCurrencyWithoutDecimalDigits } from "../../../services/currencyFormatter";
import { getSubscriptionPrice } from "../services/crewControlSubscriptionService";
import NonRefundableWarning from "../../../containers/app/components/merchantOnboarding/NonRefundableWarning";

const SubscriptionFormStandalone: React.FunctionComponent<{}> = () => {
  const {
    stripeLoaded,
    saveFormData,
    getPostSubscriptionActions,
    numberOfCrews,
    setNumberOfCrews,
    cardHolderName,
    setCardHolderName,
    selectedTenantPlan,
    setSelectedTenantPlan,
    selectedSubscriptionFrequency,
    setSelectedSubscriptionFrequency,
  } = useSubscribeProvider();

  const dispatch = useDispatch();

  const pricingUpdates = useApplicationStateSelector(
    (s) => s.common.featureFlags?.pricingUpdates ?? false
  );

  const subscriptionType = useApplicationStateSelector(
    (s) => s.common.subscriptionType
  );
  const costBreakdown = getSubscriptionPrice({
    tenantPlan: selectedTenantPlan,
    subscriptionType: subscriptionType,
    subscriptionFrequency: selectedSubscriptionFrequency,
    useUpdatedPricing: pricingUpdates,
    numberOfCrews,
  });

  return (
    <FormContainer2
      formHeader="Sign Up"
      formType="subscription"
      saveButtonText={`Pay ${formatCurrencyWithoutDecimalDigits(
        costBreakdown.chargedAmount
      )}`}
      saveButtonExplanation={<NonRefundableWarning />}
      disableSave={!stripeLoaded}
      saveHandlerOverride={() => {
        dispatch(actionCreators.forms.subscription.startSaving({}));

        saveFormData({ numberOfCrews, cardHolderName }).subscribe({
          next: () => {
            getPostSubscriptionActions().subscribe((action) =>
              dispatch(action)
            );

            dispatch(actionCreators.forms.subscription.completeSaving({}, {}));
          },

          error: (err) => {
            dispatch(
              actionCreators.forms.subscription.setErrorMessage(
                getErrorMessageFromError(err, constants.unknownErrorMessage)
              )
            );
          },
        });
      }}
    >
      <SubscriptionFormElements
        cardHolderName={cardHolderName}
        setCardHolderName={setCardHolderName}
        numberOfCrews={numberOfCrews}
        setNumberOfCrews={setNumberOfCrews}
        selectedTenantPlan={selectedTenantPlan}
        setSelectedTenantPlan={setSelectedTenantPlan}
        selectedSubscriptionFrequency={selectedSubscriptionFrequency}
        setSelectedSubscriptionFrequency={setSelectedSubscriptionFrequency}
      />
    </FormContainer2>
  );
};

const SubscriptionFormWithMerchantOnboarding: React.FunctionComponent<{}> =
  () => {
    const {
      saveFormData: saveSubscriptionFormData,
      getPostSubscriptionActions,
      numberOfCrews,
      setNumberOfCrews,
      cardHolderName,
      setCardHolderName,
      selectedTenantPlan,
      setSelectedTenantPlan,
      selectedSubscriptionFrequency,
      setSelectedSubscriptionFrequency,
      pricingUpdates,
    } = useSubscribeProvider();

    const [subscriptionSaved, setSubscriptionSaved] = useState(false);

    const {
      originalFormData,
      setOriginalFormData,
      saveErrors,
      setSaveErrors,
      merchantFormSaved,
      saveFormData: saveMerchantFormData,
      saving,
      setSaving,
      register,
      control,
      getValues,
      setValue,
      watch,
    } = useMerchantOnboardingProvider();

    const dispatch = useDispatch();

    const subscriptionType = useApplicationStateSelector(
      (s) => s.common.subscriptionType
    );
    const costBreakdown = getSubscriptionPrice({
      tenantPlan: selectedTenantPlan,
      subscriptionType: subscriptionType,
      subscriptionFrequency: selectedSubscriptionFrequency,
      useUpdatedPricing: pricingUpdates,
      numberOfCrews,
    });

    const tabKey = "confirmSubscription";
    return (
      <>
        <MerchantOnboardingFormElements
          modalHeader="Sign up for Crew Control"
          register={register}
          control={control}
          getValues={getValues}
          setValue={setValue}
          watch={watch}
          originalFormData={originalFormData}
          setOriginalFormData={setOriginalFormData}
          saveErrors={saveErrors}
          saving={saving}
          merchantFormSaved={merchantFormSaved}
          showSaveConfirmation={merchantFormSaved && subscriptionSaved}
          introText={getIntroText(true)}
          submitButtonText={`Pay ${formatCurrencyWithoutDecimalDigits(
            costBreakdown.chargedAmount
          )}`}
          saveConfigurationHeaderOverride="Your payments application is submitted!"
          saveConfigurationBodyOverride="Feel free to continue using Crew Control for all your scheduling & crew management needs, and we will follow-up with your payments approval status within 1 - 2 business days. Please contact support if you have any questions in the meantime."
          onClose={() => {
            const closeForm = () =>
              dispatch(actionCreators.forms.subscription.cancelForm());
            if (merchantFormSaved) {
              closeForm();
            } else if (
              window.confirm(
                "Are you sure you want to close this form without saving?"
              )
            ) {
              closeForm();
            }
          }}
          onSubmit={() => {
            let saveFn: Observable<AjaxResponse>;
            if (merchantFormSaved) {
              saveFn = saveSubscriptionFormData({
                cardHolderName,
                numberOfCrews,
              });
            } else {
              saveFn = saveMerchantFormData().pipe(
                mergeMap(() =>
                  saveSubscriptionFormData({
                    cardHolderName,
                    numberOfCrews,
                  })
                )
              );
            }

            setSaving(true);
            setSaveErrors([]);

            saveFn
              .pipe(
                finalize(() => {
                  setSaving(false);
                })
              )
              .subscribe({
                next: () => {
                  setSubscriptionSaved(true);

                  getPostSubscriptionActions().subscribe((action) =>
                    dispatch(action)
                  );

                  dispatch(
                    actionCreators.forms.subscription.completeSaving(
                      {},
                      {},
                      undefined,
                      true
                    )
                  );
                },

                error: (err) => {
                  const errMessage = getErrorMessagesFromError(err);
                  setSaveErrors(errMessage);
                  fullStoryTrack("Onboarding Submit Failed", {
                    error: errMessage?.[0] ?? "",
                  });
                  fullStoryLogError(err);
                },
              });
          }}
          additionalTabs={[
            {
              header: "Confirm Subscription",
              key: tabKey,
              contents: (
                <SubscriptionFormElements
                  cardHolderName={cardHolderName}
                  setCardHolderName={setCardHolderName}
                  numberOfCrews={numberOfCrews}
                  setNumberOfCrews={setNumberOfCrews}
                  selectedTenantPlan={selectedTenantPlan}
                  setSelectedTenantPlan={setSelectedTenantPlan}
                  selectedSubscriptionFrequency={selectedSubscriptionFrequency}
                  setSelectedSubscriptionFrequency={
                    setSelectedSubscriptionFrequency
                  }
                />
              ),
            },
          ]}
        />
      </>
    );
  };

const SubscriptionForm: React.FunctionComponent<{}> = () => {
  const payrixOnboardingAllowed = useApplicationStateSelector(
    (s) => s.common.payrixOnboardingAllowed
  );
  const tenantFeatureFlagSimpleSignUp = useApplicationStateSelector(
    (s) => s.common.featureFlags?.simpleSignup
  );

  // Use different variable other than payrixOnboardingAllowed. Once the merchant form is
  // submitted, it'll update payrixOnboardingAllowed and cause a rerender. Don't want that property
  // to change after initial render here.
  const [onboardWithPayrix, setOnboardWithPayrix] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    if (onboardWithPayrix === null) {
      setOnboardWithPayrix(payrixOnboardingAllowed);
    }
  }, [onboardWithPayrix, payrixOnboardingAllowed]);

  return (
    <Elements stripe={getStripePromise()}>
      {onboardWithPayrix &&
      !isFeatureFlagSet(Flags.simpleSignUp) &&
      !tenantFeatureFlagSimpleSignUp ? (
        <SubscriptionFormWithMerchantOnboarding />
      ) : (
        <SubscriptionFormStandalone />
      )}
    </Elements>
  );
};

export default conditionalRenderer(
  SubscriptionForm,
  (s) => s.forms.subscription.showForm
);
