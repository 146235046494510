import { useMemo } from "react";
import { UserSettingsType } from "../enums/userSettingsType";
import { useApplicationStateSelector } from "../hooks/useApplicationStateSelector";
import { useDispatch } from "react-redux";
import {
  userSettingsActionCreators,
  decodeValue,
} from "../modules/userSettings";
import { IUserSetting } from "../models/IUserSetting";

export function getKey(settingsType: UserSettingsType) {
  return `usersettingskey_${settingsType}`;
}

export type GetUserSettingsType = <T>(
  settingsType: UserSettingsType
) => T | null;
export type SetUserSettingsType = <T>(
  settingsType: UserSettingsType,
  settings: T
) => void;

export function useUserSettings() {
  const userSettings = useApplicationStateSelector(
    (s) => s.userSettings.userSettings
  );
  const dispatch = useDispatch();

  const getUserSettings: GetUserSettingsType = useMemo(
    () => (settingsType: UserSettingsType) => {
      return getUserSettingsFromStore(userSettings, settingsType);
    },
    [userSettings]
  );

  const setUserSettings: SetUserSettingsType = useMemo(
    () =>
      <T>(settingsType: UserSettingsType, settingValue: T) => {
        dispatch(
          userSettingsActionCreators.setUserSetting({
            type: settingsType,
            value: settingValue,
          })
        );
      },
    [dispatch]
  );

  return { getUserSettings, setUserSettings };
}

export function getUserSettingsFromStore<T>(
  userSettings: Array<IUserSetting>,
  settingsType: UserSettingsType
) {
  const settingInStore = userSettings.find((s) => s.type === settingsType);
  if (settingInStore) {
    return decodeValue<T>(settingInStore.value);
  }

  return getUserSettingsFromStorageLegacy<T>(settingsType);
}

export function getUserSettingsFromStorageLegacy<T>(
  settingsType: UserSettingsType
): T | null {
  const serializedSettings = window.localStorage.getItem(getKey(settingsType));
  if (serializedSettings) {
    return decodeValue<T>(serializedSettings);
  } else {
    return null;
  }
}
