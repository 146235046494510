import constants from "../../../constants";
import { fullStoryTrack } from "../../../services/fullStoryService";
import { getQueryVariable } from "../../../services/getQueryVariable";
import { INewCompanyFormData } from "../../../slices/accountManagement/components/Register.types";

export function getSavePayload(newCompanyFormData: INewCompanyFormData) {
  return {
    ...newCompanyFormData,
    isTestAccount: window.sessionStorage.getItem("testrun") === "1",
    landingPageUrl: window.sessionStorage.getItem(
      constants.signUpReferralSessionStorageKey
    ),
    tenantPlan: window.sessionStorage.getItem(constants.planSessionStorageKey),
    trialUnavailable:
      window.sessionStorage.getItem(
        constants.trialUnavailableSessionStorageKey
      ) === "1" ||
      getQueryVariable(constants.trialUnavailableQueryStringKey) === "1",
    streetAndNumber: newCompanyFormData.addressForSave.streetAndNumber,
    city: newCompanyFormData.addressForSave.city,
    state: newCompanyFormData.addressForSave.state,
    zip: newCompanyFormData.addressForSave.zip,
    latitude: newCompanyFormData.addressForSave.latitude,
    longitude: newCompanyFormData.addressForSave.longitude,
    mode: 0,
  };
}

export function setSessionProperties() {
  window.sessionStorage.setItem("isSignUp", "true");

  if (
    !window.sessionStorage.getItem(constants.signUpReferralSessionStorageKey)
  ) {
    const decodedReferral = getReferralCodeFromQueryString();

    if (decodedReferral) {
      window.sessionStorage.setItem(
        constants.signUpReferralSessionStorageKey,
        decodedReferral
      );
    }
  }

  let plan = getQueryVariable("plan");
  if (plan) {
    window.sessionStorage.setItem(constants.planSessionStorageKey, plan);
  }

  const trialUnavailable = getQueryVariable(
    constants.trialUnavailableQueryStringKey
  );
  if (trialUnavailable) {
    window.sessionStorage.setItem(
      constants.trialUnavailableSessionStorageKey,
      trialUnavailable
    );
  }
}

function getReferralCodeFromQueryString() {
  let encodedReferral = getQueryVariable("ref");
  let decodedReferral = "";
  if (encodedReferral) {
    try {
      decodedReferral = atob(encodedReferral);
    } catch {
      decodedReferral = "https://youraspire.com/InvalidRefSupplied";
    }
  } else if (document.referrer) {
    decodedReferral = document.referrer;
    fullStoryTrack("Referral Code Not Supplied");
  }
  return decodedReferral;
}
