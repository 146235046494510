import { useState } from "react";
import {
  Control,
  Controller,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { EmailSendFields } from "../../../../containers/app/components/EmailSendFields";
import PhoneNumberField from "../../../../containers/app/components/PhoneNumberField";
import SmsOptInCheckbox from "../../../../containers/app/components/SmsOptInCheckbox";
import { SendType } from "../../../../enums/sendType";
import { IFormData } from "./IFormData";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";

export default function DeliveryFields({
  register,
  watch,
  control,
  setValue,
  originalPhoneNumber,
  originalPhoneNumberOptedIntoSms,
}: {
  register: UseFormRegister<IFormData>;
  watch: UseFormWatch<IFormData>;
  setValue: UseFormSetValue<IFormData>;
  control: Control<IFormData, object>;
  originalPhoneNumber: string;
  originalPhoneNumberOptedIntoSms: boolean;
}) {
  const delivery = watch("deliveryMethod");
  const customerPhoneNumber = watch("customerPhoneNumber");
  const [phoneNumberOptedIntoSmsDirty, setPhoneNumberOptedIntoSmsDirty] =
    useState(false);
  const customerTextingAllowed = useApplicationStateSelector(
    (s) => s.common.optionalCapabilitiesAllowed.customerTexting
  );

  if (!customerTextingAllowed && delivery === SendType.text) {
    setValue("deliveryMethod", SendType.email);
  }

  const showEmailAddressRecipientField = delivery === SendType.email;
  const showPhoneNumberRecipientField = delivery === SendType.text;
  return (
    <>
      <div className="form-group">
        <label htmlFor="delivery" className="required">
          Delivery
        </label>

        <select
          id="delivery"
          className="form-control"
          required
          {...register("deliveryMethod", {
            setValueAs: (value) => parseInt(value) as SendType,
          })}
        >
          {getDeliveryMethodOption(SendType.email, "Email")}
          {customerTextingAllowed
            ? getDeliveryMethodOption(SendType.text, "Text")
            : null}
        </select>
      </div>

      {showEmailAddressRecipientField ? (
        <Controller
          name="emailFields"
          control={control}
          render={({ field }) => (
            <EmailSendFields
              recipientHeaderLabel="Customer email addresses"
              recipientFieldLabel="Customer email address"
              value={{
                recipients: field.value.customerEmailAddresses,
                replyTo: field.value.replyToEmailAddress,
              }}
              onChange={(newValue) =>
                field.onChange({
                  customerEmailAddresses: newValue.recipients,
                  replyToEmailAddress: newValue.replyTo,
                })
              }
            />
          )}
        />
      ) : null}

      {showPhoneNumberRecipientField ? (
        <>
          <div className="form-group" key="text">
            <label htmlFor="phoneNumber" className="required">
              Phone number
            </label>

            <Controller
              name={`customerPhoneNumber`}
              control={control}
              render={({ field }) => (
                <PhoneNumberField
                  required
                  id="phoneNumber"
                  className="form-control"
                  value={field.value}
                  onChange={(e) => {
                    const newPhoneNumber = e.currentTarget.value;
                    if (!phoneNumberOptedIntoSmsDirty) {
                      if (newPhoneNumber !== originalPhoneNumber) {
                        setValue("customerPhoneNumberOptedIntoSms", false);
                      } else {
                        setValue(
                          "customerPhoneNumberOptedIntoSms",
                          originalPhoneNumberOptedIntoSms
                        );
                      }
                    }

                    field.onChange(e);
                  }}
                />
              )}
            />
          </div>
          <Controller
            name={`customerPhoneNumberOptedIntoSms`}
            control={control}
            render={({ field }) =>
              !field.value || phoneNumberOptedIntoSmsDirty ? (
                <SmsOptInCheckbox
                  phoneNumber={customerPhoneNumber}
                  checked={field.value}
                  onCheckedChange={(newValue) => {
                    setPhoneNumberOptedIntoSmsDirty(true);
                    field.onChange(newValue);
                  }}
                />
              ) : (
                <></>
              )
            }
          />
        </>
      ) : null}
    </>
  );
}

function getDeliveryMethodOption(deliveryMethod: SendType, text: string) {
  return <option value={deliveryMethod.toString()}>{text}</option>;
}
