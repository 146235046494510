import React from "react";
import { getFileComponents } from "../../../../services/fileService";
import IPhoto from "../../../../models/IPhoto";

interface IProps {
  file: IPhoto;
  imagePrefix: string;
  onOpenLightbox: () => void;
}

export const ReadOnlyNonImage: React.FunctionComponent<IProps> = ({
  file,
  imagePrefix,
  onOpenLightbox,
}) => {
  const fileComponents = getFileComponents(file.imagePath);

  let path: string;
  let name: string;
  if (fileComponents.success) {
    path = fileComponents.linkUrl as string;
    name = fileComponents.name as string;
  } else {
    path = file.imagePath;
    name = file.imagePath;
  }

  return (
    <React.Fragment>
      <a
        href={`${imagePrefix}/${path}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          textDecoration: "underline",
          fontWeight: "bold",
        }}
      >
        {name}
      </a>
      {file.caption ? <span> - {file.caption}</span> : null}
    </React.Fragment>
  );
};
