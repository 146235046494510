import { Observable, Observer } from "rxjs";

export function getGeocodedAddress(
  address: string | undefined
): Observable<google.maps.GeocoderResult[]> {
  let service = new window.google.maps.Geocoder();

  return new Observable((observer: Observer<google.maps.GeocoderResult[]>) => {
    service.geocode({ address: address }, (result, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        observer.next(result as google.maps.GeocoderResult[]);
        observer.complete();
      } else {
        observer.next([]);
        observer.complete();
      }
    });
  });
}
