import { Action } from "@reduxjs/toolkit";
import { ICrew } from "../models/ICrew";
import dataProvider from "./dataProvider";
import { of, concat, Observable, merge, throwError } from "rxjs";
import { catchError, mergeMap, timeout } from "rxjs/operators";
import { actionCreators } from "../modules/actionCreators";
import { ICustomer } from "../models/ICustomer";
import { PaymentMethodType } from "../enums/paymentMethodType";
import { IRootState } from "../store";
import {
  opportunitiesActionCreators,
  OpportunityLoadStatus,
} from "../slices/sales/modules/opportunity";
import { loadOpportunitiesBoard } from "../slices/sales/services/loadOpportunitiesBoard";
import { isAdmin } from "../hooks/useIsAdmin";
import opportunityDataProvider from "../slices/sales/services/opportunityDataProvider";
import { loadProposals } from "../slices/sales/services/loadOpportunityRelatedData";
import CustomerNotFoundError from "../errors/customerNotFoundError";

export function getCrewNameForJobVisit(
  crews: Array<ICrew>,
  crewId: string | null,
  isInWeeksFlexibleJobsHolder: boolean
) {
  let crewName = "";
  if (isInWeeksFlexibleJobsHolder) {
    crewName = "Unscheduled job";
  } else if (crewId) {
    const matchingCrew = crews.find((c) => c.id === crewId);
    if (matchingCrew) {
      crewName = matchingCrew.name;
    }
  }

  return crewName;
}

export function loadCustomersAndChildRecords(
  customerIds: Array<string>,
  state: IRootState
) {
  let getOpportunityLoadActions: Observable<Action<any>>;
  let getArchivedOpportunitiesForCustomers: Observable<Action<any>>;

  const opportunityLoadStatusRequiresLoad =
    state.opportunity.opportunityLoadStatus ===
      OpportunityLoadStatus.notStarted ||
    state.opportunity.opportunityLoadStatus === OpportunityLoadStatus.error;
  if (
    opportunityLoadStatusRequiresLoad &&
    isAdmin(state.common.userAccountRole)
  ) {
    getOpportunityLoadActions = loadOpportunitiesBoard(state);
  } else {
    getOpportunityLoadActions = of(actionCreators.null());
  }

  if (isAdmin(state.common.userAccountRole)) {
    getArchivedOpportunitiesForCustomers = getArchivedProposals(
      customerIds,
      state
    );
  } else {
    getArchivedOpportunitiesForCustomers = of(actionCreators.null());
  }

  return merge(
    dataProvider
      .getCustomers({
        customerIds,
      })
      .pipe(
        mergeMap((customers) => {
          return of(
            actionCreators.loadCustomersComplete(
              customers.customers,
              customers.customerAdditionalLocations
            )
          );
        })
      ),
    getOpportunityLoadActions,
    getArchivedOpportunitiesForCustomers
  ).pipe(
    timeout(45000),
    catchError((e) => {
      console.log(e);

      if (e instanceof CustomerNotFoundError) {
        return of(actionCreators.loadCustomersNotFound(customerIds));
      } else {
        return of(actionCreators.loadCustomersError(customerIds));
      }
    })
  );
}

function getArchivedProposals(
  customerIds: string[],
  state: IRootState
): Observable<Action<any>> {
  return opportunityDataProvider.getArchivedOpportunities({ customerIds }).pipe(
    mergeMap((opportunities) => {
      const opportunityProposals = loadProposals(state, opportunities);

      return concat(
        opportunityProposals,
        of(
          opportunitiesActionCreators.loadSpecificOpportunities({
            opportunities,
          })
        )
      );
    }),
    catchError((err) => {
      const forbiddenStatusCode = 403;
      if (err.status === forbiddenStatusCode) {
        return of(actionCreators.null());
      } else {
        return throwError(err);
      }
    })
  );
}

export function getPaymentMethodType(customer: ICustomer) {
  if (typeof customer?.paymentMethod?.type !== "number") {
    return "Not set";
  }

  if (customer?.paymentMethod?.type === PaymentMethodType.Ach) {
    return "Bank account";
  } else {
    return "Credit card";
  }
}

export function getCustomerEmailAddress(
  customer: ICustomer | null | undefined
) {
  if (!customer?.emailAddresses) {
    return "";
  } else if (customer.emailAddresses.length === 0) {
    return "";
  } else {
    return customer.emailAddresses[0];
  }
}
