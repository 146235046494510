import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import FormContainer, {
  IAdditionalSaveButton,
} from "../components/FormContainer";
import Spinner from "../components/Spinner";
import dateService from "../../../services/dateService";
import DayPicker, { format as weekPickerFormat } from "../components/DayPicker";
import dateFnsFormat from "date-fns/format";
import InvoiceWork from "../components/InvoiceWork";
import { useCreditCardsEnabled } from "../../../hooks/useCreditCardsEnabled";
import QuickBooksTaxAlertPrompt from "../components/QuickBooksTaxAlertPrompt";
import { InvoiceDeliveryMethod } from "../../../models/InvoiceDeliveryMethod";
import InvoiceFormAmounts from "./InvoiceFormAmounts";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import conditionalRenderer from "../components/conditionalRenderer";
import Alert from "../components/Alert";
import {
  useUserSettings,
  SetUserSettingsType,
} from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import QuickBooksCustomerSelection from "../components/QuickBooksCustomerSelection";
import { IQuickBooksCustomer } from "../../../models/IQuickBooksCustomer";
import InvoiceLineItems from "../components/InvoiceLineItems";
import RichTextEditor from "../components/RichTextEditor";
import { EditorState } from "draft-js";
import { serializeTextState } from "../../../services/richTextService";
import InvoiceDeliveryMethodSelection from "../../../slices/billing/components/InvoiceDeliveryMethodSelection";
import InvoicePaymentMethodSelection, {
  arePaymentFieldsVisible,
  arePaymentFieldsVisibleForCrewControl,
  isCrewControlDeliveryMethod,
} from "../../../slices/billing/components/InvoicePaymentMethodSelection";
import { Controller, useFieldArray, UseFormGetValues } from "react-hook-form";
import { IInvoiceSaveRequest } from "../../../services/remoteDataProvider";
import { CustomerCommunicationTemplateType } from "../../../enums/customerCommunicationTemplateType";
import { getLineItemName, getTotal } from "../../../services/lineItemService";
import { IInvoiceDefaultDepositCredit } from "../../../slices/billing/models/IInvoiceDefaultDepositCredit";
import Files from "../components/files/Index";
import { isPhoto, mapPhotoToDataFile } from "../../../services/fileService";
import { IPhoto as IPhotoFromBillingReport } from "../../../models/IBillingReport";
import {
  formActionCreators,
  genericLoadErrorMessage,
} from "./InvoiceForm.types";
import { getEmailAddressesForSave } from "../components/EmailAddresses.functions";
import {
  flattenLineItemsByLocation,
  getChangesFromJobLineItemChanges,
  getFormHeader,
  getSelectedWorkForLocation,
  getUpdatedDeliveryDetailsAfterPhoneChange,
  getUpdatedLineItemsAfterSelectedJobsChange,
  hasSelectedWorkChanged,
  updateDepositCredits,
} from "./InvoiceForm.functions";
import { InvoiceFormLineItemAlert } from "./InvoiceFormLineItemAlert";
import { useGetCustomerFromStore } from "../../../hooks/useGetCustomerFromStore";
import { PaymentsTransactionTimeline } from "../../../slices/billing/components/PaymentsTransactionTimeline";
import { JobType } from "../../../models/IJob";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { formatAddressEntity } from "../../../services/addressFormatter";
import parse from "date-fns/parse";
import { InvoiceUpdateDefaultDueDatePrompt } from "../../../slices/billing/components/InvoiceUpdateDefaultDueDatePrompt";
import { getInvoiceDueDate } from "../../../services/invoiceService";
import {
  getNewDefaultNumberOfDaysDue,
  hasNumberOfDaysUntilDueChanged,
} from "../../../slices/billing/components/InvoiceUpdateDefaultDueDatePrompt.functions";
import { isTaxRateSet } from "./InvoiceFormAmounts.functions";
import { SelectInstance } from "react-select";
import CustomerSelection, { IOption } from "../components/CustomerSelection";
import { IInvoiceFormData } from "./InvoiceForm.types";
import { useDefaultsFromJobs } from "./InvoiceForm.useDefaultsFromJobs";
import { useHandleCustomerChanged } from "./InvoiceForm.useHandleCustomerChanged";
import { useSetFormValues } from "./InvoiceForm.useSetFormValues";
import { useGetFormParameters } from "./InvoiceForm.useGetFormParameters";
import { useCreateForm } from "./InvoiceForm.useCreateForm";
import { useLoadData } from "./InvoiceForm.useLoadData";
import LinkButton2 from "../components/LinkButton2";
import { InvoiceFormJobSummary } from "./InvoiceFormJobSummary";

const InvoiceForm: React.FunctionComponent<{}> = () => {
  const {
    customerId: passedInCustomerId,
    defaultQuickBooksCustomerId,
    jobInstances,
    setJobInstances,
    contractBillingHistoryItemId,
    invoiceId,
    projects,
  } = useGetFormParameters();

  const isUpdateMode = !!invoiceId;

  const { showForm, errorMessage, saving } = useApplicationStateSelector(
    (s) => s.forms.invoice
  );
  const invoiceConfiguration = useApplicationStateSelector(
    (s) => s.common.invoiceConfiguration
  );

  const customerElement = useRef<SelectInstance<IOption>>(null);
  const quickBooksCustomerElement =
    useRef<SelectInstance<IQuickBooksCustomer>>(null);

  const {
    control,
    getValues,
    setValue,
    register,
    deliveryMethod,
    lineItemsByLocation,
    selectedWorkToBill,
    originalFormValues,
    setOriginalFormValues,
    initialIntroText,
    setInitialIntroText,
    initialFooterText,
    setInitialFooterText,
    showLineItemPrices,
    startingDate,
    customerId,
    quickBooksCustomerId,
  } = useCreateForm({ passedInCustomerId });

  const { fields: lineItemsByLocationField } = useFieldArray({
    name: "lineItemsByLocation",
    control,
  });

  const dispatch = useDispatch();

  const { areCreditCardsEnabled } = useCreditCardsEnabled();
  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );
  const tenantId = useApplicationStateSelector((s) => s.common.tenantId);
  const imagePrefix = useApplicationStateSelector((s) => s.common.imagePrefix);
  const [fileUploading, setFileUploading] = useState(false);
  const [filesAddedFromJobs, setFilesAddedFromJobs] = useState<
    Array<IPhotoFromBillingReport>
  >([]);

  const [maxDepositCreditAmount, setMaxDepositCreditAmount] = useState<
    number | null
  >(null);
  const [depositCredits, setDepositCredits] = useState<
    IInvoiceDefaultDepositCredit[]
  >([]);
  const [alwaysShowDepositCredits, setAlwaysShowDepositCredits] =
    useState<boolean>(false);
  const [phoneNumberOptedIntoSmsDirty, setPhoneNumberOptedIntoSmsDirty] =
    useState(false);
  const [
    paymentMethodPreviouslyAuthorized,
    setPaymentMethodPreviouslyAuthorized,
  ] = useState(false);
  const [editingDraft, setEditingDraft] = useState(false);
  const { setUserSettings } = useUserSettings();

  const customer = useGetCustomerFromStore(customerId);
  const {
    quickBooksCustomers,
    invoiceItems,
    billingDetails,
    quickBooksSettings,
    setInvoiceItems,
    setQuickBooksCustomers,
    loaded: loadedSettings,
    errorLoading: errorLoadingSettings,
    showTaxAlert,
    taxAlertDismiss,
    defaultLineItems,
    setDefaultLineItems,
    defaultAmountAdjustments,
    defaultDepositCredits,
    captureDepositItemOverride,
    defaultPurchaseOrderNumber,
    setDefaultAmountAdjustments,
    setDefaultDepositCredits,
    setDefaultPurchaseOrderNumber,
    setCaptureDepositItemOverride,
    proposalJobSummaries,
    setProposalJobSummaries,
  } = useLoadData({
    showForm,
    jobInstances,
    customerId,
    dispatch,
    setValue,
    projects,
    setPaymentMethodPreviouslyAuthorized,
    invoiceId,
  });

  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );
  const {
    loadedInvoice,
    errorLoadingInvoiceMessage,
    editingPayThroughCrewControlInvoice,
    invoice,
  } = useSetFormValues({
    customer,
    defaultQuickBooksCustomerId,
    jobInstances,
    projects,
    loadedSettings,
    isQuickBooksEnabled,
    areCreditCardsEnabled,
    getValues,
    setValue,
    invoiceId,
    setOriginalFormValues,
    setInitialIntroText,
    setInitialFooterText,
    setAlwaysShowDepositCredits,
    paymentMethodPreviouslyAuthorized,
    customerAdditionalLocations,
    defaultPurchaseOrderNumber,
    invoiceConfiguration,
    setEditingDraft,
  });

  useDefaultsFromJobs({
    invoiceId,
    loadedInvoice,
    loaded: loadedSettings,
    defaultLineItems,
    invoiceItems,
    defaultAmountAdjustments,
    defaultCredits: defaultDepositCredits,
    setMaxDepositCreditAmount,
    setDepositCredits,
    jobInstances,
    projects,
    customerAdditionalLocations,
    setValue,
  });

  useSetFocusOnCustomerField({
    passedInCustomerId,
    customerElement,
    customerId,
    quickBooksCustomerId,
    isQuickBooksEnabled,
    quickBooksCustomerElement,
  });

  const {
    loadingDataOnCustomerChange,
    errorLoadingCustomerData,
    retryLoadingCustomerData,
  } = useHandleCustomerChanged({
    customerId,
    passedInCustomerId,
    invoiceItems,
    projects,
    lineItemsByLocation,
    setters: {
      setValue,
      setJobInstances,
      setDefaultLineItems,
      setDefaultAmountAdjustments,
      setDefaultDepositCredits,
      setDefaultPurchaseOrderNumber,
      setDepositCredits,
      setMaxDepositCreditAmount,
      setPaymentMethodPreviouslyAuthorized,
      setCaptureDepositItemOverride,
      setProposalJobSummaries,
    },
  });

  if (errorLoadingSettings || errorLoadingInvoiceMessage) {
    return (
      <Alert
        message={errorLoadingInvoiceMessage ?? genericLoadErrorMessage}
        closeForm={() => dispatch(formActionCreators.cancelForm())}
      />
    );
  } else if (!loadedSettings || !loadedInvoice) {
    return <Spinner />;
  }

  let additionalButtons: IAdditionalSaveButton[] = [];
  if (!isUpdateMode || invoice?.draft) {
    additionalButtons = [
      {
        label: "Save as draft",
        enrichFormData: (d) => ({
          ...d,
          draft: true,
        }),
      },
    ];
  }

  return (
    <>
      {showTaxAlert ? (
        <QuickBooksTaxAlertPrompt
          onCancel={() => dispatch(formActionCreators.cancelForm())}
          onConfirm={taxAlertDismiss}
        />
      ) : (
        <FormContainer
          saveButtonText={getSaveButtonText(
            getParsedDeliveryMethod(deliveryMethod)
          )}
          additionalPrimaryButtons={additionalButtons}
          hideSave={errorLoadingSettings}
          disableSubmitOnEnterPress={!isUpdateMode}
          setErrorMessage={(error) =>
            dispatch(formActionCreators.setErrorMessage(error))
          }
          validate={() => {
            const formData = getValues();

            if (!formData.quickBooksCustomerId && isQuickBooksEnabled) {
              return {
                errorMessage: "You must select a customer",
                valid: false,
              };
            }
            if (
              parseInt(formData.deliveryMethodDetails.invoiceDeliveryMethod) ===
                InvoiceDeliveryMethod.paymentMethodOnFile &&
              !(customer?.paymentMethod?.isTokenSet ?? false)
            ) {
              return {
                valid: false,
                errorMessage:
                  "The customer must have a payment method to use Payment Method On File",
              };
            }

            if (
              flattenLineItemsByLocation(formData.lineItemsByLocation).filter(
                (li) => !li.itemId
              ).length > 0
            ) {
              return {
                errorMessage: "You must select a line item",
                valid: false,
              };
            }

            if (fileUploading) {
              return {
                valid: false,
                errorMessage: "Please wait until all files are uploaded",
              };
            }

            return { valid: true };
          }}
          getFormData={() => {
            const formData = getValues() as IInvoiceFormData;

            persistStickySettings({
              formData,
              isQuickBooksEnabled,
              paymentMethodPreviouslyAuthorized,
              captureDepositItemOverride,
              setUserSettings,
            });

            const deliveryMethod = getParsedDeliveryMethod(
              formData.deliveryMethodDetails.invoiceDeliveryMethod
            );
            return {
              id: invoiceId,
              date: formData.startingDate,
              dueDate: formData.dueDate ?? "",
              discount: formData.amountAdjustments.discount,
              taxRate:
                !isQuickBooksEnabled || invoice?.createdByCrewMember
                  ? formData.amountAdjustments.taxRate
                  : null,
              customerId,
              quickBooksCustomerId: isQuickBooksEnabled
                ? formData.quickBooksCustomerId
                : null,
              lineItems: flattenLineItemsByLocation(
                formData.lineItemsByLocation
              ).map((li) => ({
                ...li,
                name: getLineItemName(invoiceItems, li),
                hide: isHideLineItemPricesAvailable(
                  formData.deliveryMethodDetails.invoiceDeliveryMethod,
                  editingPayThroughCrewControlInvoice
                )
                  ? li.hide
                  : false,
              })),
              jobInstanceIds: formData.selectedWorkToBill.selectedJobInstances,
              projectIds: formData.selectedWorkToBill.selectedProjects,
              customerEmailAddresses: getEmailAddressesForSave(
                formData.deliveryMethodDetails.emailFields.recipients
              ),
              replyToEmailAddress:
                formData.deliveryMethodDetails.emailFields.replyTo,
              customerPhoneNumber:
                formData.deliveryMethodDetails.customerPhoneNumber,
              customerPhoneNumberOptedIntoSms:
                deliveryMethod === InvoiceDeliveryMethod.textWithCrewControl
                  ? formData.deliveryMethodDetails
                      .customerPhoneNumberOptedIntoSms
                  : null,
              allowOnlineCreditCardPayment: arePaymentFieldsVisible({
                quickBooksSettings,
                deliveryMethod: getParsedDeliveryMethod(
                  formData.deliveryMethodDetails.invoiceDeliveryMethod
                ),
                editingPayThroughCrewControlInvoice:
                  editingPayThroughCrewControlInvoice,
                isQuickBooksEnabled,
                areCreditCardsEnabled,
              })
                ? formData.paymentMethodOptions.allowCreditCardPayment
                : false,
              allowOnlineAchPayment: arePaymentFieldsVisible({
                quickBooksSettings,
                deliveryMethod: getParsedDeliveryMethod(
                  formData.deliveryMethodDetails.invoiceDeliveryMethod
                ),
                editingPayThroughCrewControlInvoice:
                  editingPayThroughCrewControlInvoice,
                isQuickBooksEnabled,
                areCreditCardsEnabled,
              })
                ? formData.paymentMethodOptions.allowAchPayment
                : false,
              addConvenienceFee: isCrewControlDeliveryWithPayments(
                getParsedDeliveryMethod(
                  formData.deliveryMethodDetails.invoiceDeliveryMethod
                ),
                editingPayThroughCrewControlInvoice,
                areCreditCardsEnabled
              )
                ? formData.paymentMethodOptions.addConvenienceFee
                : false,
              contractBillingHistoryItemId: contractBillingHistoryItemId,
              deliveryMethod: deliveryMethod,
              introText: areRichTextFieldsEnabled(
                invoiceId,
                getParsedDeliveryMethod(
                  formData.deliveryMethodDetails.invoiceDeliveryMethod
                )
              )
                ? serializeTextState(formData.introTextState)
                : null,
              footerText: areRichTextFieldsEnabled(
                invoiceId,
                getParsedDeliveryMethod(
                  formData.deliveryMethodDetails.invoiceDeliveryMethod
                )
              )
                ? serializeTextState(formData.footerTextState)
                : null,
              purchaseOrderNumber: formData.purchaseOrderNumber,
              printOnSave:
                getParsedDeliveryMethod(
                  formData.deliveryMethodDetails.invoiceDeliveryMethod
                ) === InvoiceDeliveryMethod.printWithCrewControl,
              files: formData.files,
              depositCreditAmount:
                formData.amountAdjustments.depositCreditAmount,
              quickBooksDepositItemIdOverride:
                getQuickBooksDepositItemIdOverride(
                  captureDepositItemOverride,
                  formData
                ),
              hideLineItemPrices: isHideLineItemPricesAvailable(
                formData.deliveryMethodDetails.invoiceDeliveryMethod,
                editingPayThroughCrewControlInvoice
              )
                ? !formData.showLineItemPrices
                : false,
              summary: formData.summary,
              draft: false,
            } as IInvoiceSaveRequest;
          }}
          hasFormDataChanged={() => {
            return (
              JSON.stringify(getValues()) !== JSON.stringify(originalFormValues)
            );
          }}
          formHeader={getFormHeader(
            isUpdateMode,
            invoice,
            passedInCustomerId ? customer?.name : null
          )}
          showForm={showForm && loadedSettings}
          errorMessage={errorMessage}
          saving={saving}
          startSave={(args) => dispatch(formActionCreators.startSaving(args))}
          cancel={() => dispatch(formActionCreators.cancelForm())}
          formKey="invoice"
          size="xl"
          getConfirmationPrompt={(formData) => {
            const isZeroDollar = isZeroDollarInvoice(getValues, formData.draft);
            const isZeroDollarNewInvoice = isZeroDollar && !invoiceId;
            const hasChangedToZeroDollarInvoice =
              isZeroDollar && invoiceId && (invoice?.amount ?? 0) > 0;

            if (
              !formData.draft &&
              (isZeroDollarNewInvoice || hasChangedToZeroDollarInvoice)
            ) {
              return (
                <>
                  <div>
                    <b>This invoice is for $0! </b>
                  </div>
                  <div className="mt-2">
                    A receipt will be emailed and this invoice will be marked as
                    paid when saved.
                  </div>
                  <div className="mt-2">Are you sure you want to continue?</div>
                </>
              );
            } else return null;
          }}
        >
          {loadingDataOnCustomerChange ? <Spinner /> : null}

          {invoice?.hasSignature ? (
            <div
              data-testid="signatureWarning"
              className="alert alert-warning p-2"
            >
              <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
              Editing this invoice will void the signature captured
            </div>
          ) : null}

          {!passedInCustomerId ? (
            <>
              <div className="form-group" data-testid="customerContainer">
                <label htmlFor="customerId" className="required">
                  Customer
                </label>
                <Controller
                  name="customerId"
                  control={control}
                  render={({ field }) => (
                    <CustomerSelection
                      inputId="customerId"
                      selectRef={customerElement}
                      includeGroups={false}
                      value={{
                        recordType: "customer",
                        id: field.value,
                      }}
                      onCustomerClear={() => {
                        field.onChange(null);
                      }}
                      onCustomerSelection={(type, selectedValue) => {
                        if (type === "customer") {
                          const newCustomerId = selectedValue;
                          field.onChange(newCustomerId);
                        }
                      }}
                    />
                  )}
                />
                {errorLoadingCustomerData ? (
                  <div className="text-danger mt-2">
                    Unable to load jobs for this customer.{" "}
                    <LinkButton2
                      style={{ verticalAlign: "baseline" }}
                      onClick={retryLoadingCustomerData}
                      buttonContents={"Retry"}
                      testId="retryLoadingCustomerJobs"
                    />
                  </div>
                ) : null}
              </div>
            </>
          ) : null}

          {!errorLoadingSettings ? (
            <React.Fragment>
              {isQuickBooksEnabled ? (
                <div className="form-group">
                  <label htmlFor="customer" className="required">
                    QuickBooks Customer
                  </label>
                  <Controller
                    name={`quickBooksCustomerId`}
                    control={control}
                    render={({ field }) => (
                      <QuickBooksCustomerSelection
                        inputId="customer"
                        customer={customer}
                        required
                        options={quickBooksCustomers}
                        quickBooksCustomerId={field.value}
                        selectRef={quickBooksCustomerElement}
                        onChange={(id) => {
                          field.onChange(id);
                          dispatch(formActionCreators.clearErrorMessage());
                        }}
                        onCreatedQuickBooksCustomer={({ id, displayName }) => {
                          setQuickBooksCustomers([
                            ...quickBooksCustomers,
                            {
                              id,
                              displayName,
                            } as IQuickBooksCustomer,
                          ]);
                        }}
                      />
                    )}
                  />
                </div>
              ) : null}

              <React.Fragment>
                <div className="form-row">
                  <div className={`col-12 col-md-4 form-group`}>
                    {/* "display: block" is needed for mobile so the calendar picker is on new line */}
                    <label
                      htmlFor="startingDate"
                      className="required"
                      style={{ display: "block" }}
                    >
                      Invoice date
                    </label>
                    <Controller
                      name={`startingDate`}
                      control={control}
                      render={({ field }) => (
                        <DayPicker
                          inputId="startingDate"
                          onDayPickerHide={() => null}
                          dayPickerProps={{}}
                          value={
                            !!field.value
                              ? dateFnsFormat(field.value, weekPickerFormat)
                              : ""
                          }
                          required={true}
                          onDaySelected={(day: Date) => {
                            field.onChange(
                              day ? dateService.formatAsIso(day) : ""
                            );

                            if (day) {
                              const newDueDate = getInvoiceDueDate(
                                day,
                                invoiceConfiguration
                              );

                              if (newDueDate) {
                                setValue(
                                  "dueDate",
                                  dateService.formatAsIso(newDueDate)
                                );
                              }
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="col-12 col-md-4 form-group">
                    {/* "display: block" is needed for mobile so the calendar picker is on new line */}
                    <label htmlFor="dueDate" style={{ display: "block" }}>
                      Due date
                    </label>
                    <Controller
                      name={`dueDate`}
                      control={control}
                      render={({ field }) => (
                        <>
                          <DayPicker
                            inputId="dueDate"
                            onDayPickerHide={() => null}
                            dayPickerProps={{}}
                            minDate={parse(startingDate)}
                            value={
                              !!field.value
                                ? dateFnsFormat(field.value, weekPickerFormat)
                                : ""
                            }
                            required={false}
                            onDaySelected={(day: Date) => {
                              field.onChange(
                                day ? dateService.formatAsIso(day) : ""
                              );
                            }}
                          />

                          {!isUpdateMode ? (
                            <InvoiceUpdateDefaultDueDatePrompt
                              hasNumberOfDaysUntilDueChanged={hasNumberOfDaysUntilDueChanged(
                                {
                                  currentDueDate: field.value,
                                  originalDueDate: originalFormValues.dueDate,
                                }
                              )}
                              newDefaultNumberOfDaysDue={getNewDefaultNumberOfDaysDue(
                                {
                                  currentDueDate: field.value,
                                  startingDate,
                                }
                              )}
                            />
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                  <div className={`col-12 col-md-4 form-group`}>
                    <label htmlFor="purchaseOrderNumber">PO number</label>
                    <input
                      id="purchaseOrderNumber"
                      type="text"
                      className="form-control"
                      maxLength={100}
                      {...register("purchaseOrderNumber")}
                    />
                  </div>
                </div>

                {!isUpdateMode || editingDraft ? (
                  <Controller
                    name={`deliveryMethodDetails`}
                    control={control}
                    render={({ field }) => (
                      <InvoiceDeliveryMethodSelection
                        customerId={customerId}
                        enableTexting={true}
                        enableCrewControlPrinting={true}
                        emailFields={field.value.emailFields}
                        setEmailFields={(newValue) =>
                          field.onChange({
                            ...field.value,
                            emailFields: newValue,
                          })
                        }
                        customerPhoneNumber={field.value.customerPhoneNumber}
                        setCustomerPhoneNumber={(newValue) => {
                          field.onChange(
                            getUpdatedDeliveryDetailsAfterPhoneChange({
                              newPhoneNumber: newValue,
                              currentDeliveryDetails: field.value,
                              originalDeliveryDetails:
                                originalFormValues.deliveryMethodDetails,
                              phoneNumberOptedIntoSmsDirty,
                            })
                          );
                        }}
                        hideCustomerPhoneNumberOptedIntoSms={
                          field.value.customerPhoneNumberOptedIntoSms &&
                          !phoneNumberOptedIntoSmsDirty
                        }
                        customerPhoneNumberOptedIntoSms={
                          field.value.customerPhoneNumberOptedIntoSms
                        }
                        setCustomerPhoneNumberOptedIntoSms={(newValue) => {
                          setPhoneNumberOptedIntoSmsDirty(true);

                          field.onChange({
                            ...field.value,
                            customerPhoneNumberOptedIntoSms: newValue,
                          });
                        }}
                        deliveryMethod={field.value.invoiceDeliveryMethod}
                        setDeliveryMethod={(newValue) =>
                          field.onChange({
                            ...field.value,
                            invoiceDeliveryMethod: newValue,
                          })
                        }
                        paymentMethodPreviouslyAuthorized={
                          paymentMethodPreviouslyAuthorized
                        }
                      />
                    )}
                  />
                ) : null}

                {arePaymentFieldsVisible({
                  quickBooksSettings,
                  deliveryMethod: getParsedDeliveryMethod(deliveryMethod),
                  editingPayThroughCrewControlInvoice,
                  isQuickBooksEnabled,
                  areCreditCardsEnabled,
                }) ? (
                  <>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <Controller
                          name={`paymentMethodOptions`}
                          control={control}
                          render={({ field }) => (
                            <InvoicePaymentMethodSelection
                              isCrewControlDeliveryWithPayments={isCrewControlDeliveryWithPayments(
                                getParsedDeliveryMethod(deliveryMethod),
                                editingPayThroughCrewControlInvoice,
                                areCreditCardsEnabled
                              )}
                              value={field.value}
                              onChange={(newPaymentValues) => {
                                field.onChange(newPaymentValues);
                              }}
                              hideAuthorizePaymentMethodOnFile={
                                !jobInstances.some(
                                  (j) => j.jobType === JobType.maintenanceJob
                                )
                              }
                            />
                          )}
                        />
                      </div>
                      <div className="col-12 col-md-6">
                        <PaymentsTransactionTimeline />
                      </div>
                    </div>
                  </>
                ) : null}
              </React.Fragment>

              {areRichTextFieldsEnabled(
                invoiceId,
                getParsedDeliveryMethod(deliveryMethod)
              ) ? (
                <div className="form-group mb-4">
                  <h5>Intro text</h5>
                  <div data-testid="introTextContainer">
                    <Controller
                      name={`introTextState`}
                      control={control}
                      render={({ field }) => (
                        <RichTextEditor
                          templateOptions={{
                            template: "invoiceTemplate",
                            templateType:
                              CustomerCommunicationTemplateType.invoice,
                            initialState: initialIntroText,
                            setInitialState: (v) => setInitialIntroText(v),
                            templateProperty: "introText",
                          }}
                          editorState={field.value as EditorState}
                          setEditorState={(newText) => field.onChange(newText)}
                          ariaLabel="Intro text"
                          placeholder="Type your intro text here..."
                        />
                      )}
                    />
                  </div>
                </div>
              ) : null}

              <div className="form-group" data-testid="summaryContainer">
                <label htmlFor="summary">Job summary</label>
                <Controller
                  name="summary"
                  control={control}
                  render={({ field }) => (
                    <InvoiceFormJobSummary
                      value={field.value}
                      onChange={(newValue) => field.onChange(newValue)}
                      proposalJobSummaries={proposalJobSummaries}
                      selectedWorkToBill={selectedWorkToBill}
                    />
                  )}
                />
              </div>

              {!isUpdateMode &&
              ((jobInstances && jobInstances.length > 0) ||
                (projects && projects.length > 0)) ? (
                <Controller
                  name={`selectedWorkToBill`}
                  control={control}
                  render={({ field }) => (
                    <InvoiceWork
                      jobInstances={jobInstances}
                      projects={projects ?? []}
                      billingDetails={billingDetails}
                      selectedWorkToBill={field.value}
                      setSelectedWorkToBill={(newValue) => {
                        if (hasSelectedWorkChanged(newValue, field.value)) {
                          const newLineItems =
                            getUpdatedLineItemsAfterSelectedJobsChange({
                              oldSelectedWork: field.value,
                              newSelectedWork: newValue,
                              lineItemsByLocation: lineItemsByLocation,
                              defaultLineItems,
                              invoiceItems,
                              jobInstances,
                            });

                          setValue("lineItemsByLocation", newLineItems);
                          updateDepositCredits({
                            selectedWorkToBill: newValue,
                            credits: depositCredits,
                            setDepositCreditAmount: (newDepositCreditAmount) =>
                              setValue("amountAdjustments", {
                                ...getValues("amountAdjustments"),
                                depositCreditAmount: newDepositCreditAmount,
                              }),
                            setMaxDepositCreditAmount,
                            setDepositCredits,
                          });
                        }

                        field.onChange(newValue);
                      }}
                      addedFiles={filesAddedFromJobs}
                      onAddFile={(file) => {
                        setFilesAddedFromJobs([...filesAddedFromJobs, file]);

                        setValue("files", [
                          ...getValues("files"),
                          mapPhotoToDataFile(file),
                        ]);
                      }}
                    />
                  )}
                />
              ) : null}

              {!isUpdateMode && jobInstances !== null ? (
                <Controller
                  name={`lineItemsByLocation`}
                  control={control}
                  render={({ field }) => (
                    <InvoiceFormLineItemAlert
                      invoicejobInstances={jobInstances}
                      invoiceItems={invoiceItems}
                      onDataRefreshed={(maintenanceJobId, data) => {
                        const {
                          newLineItemsByLocation,
                          newDefaultLineItems,
                          newJobInstances,
                        } = getChangesFromJobLineItemChanges({
                          maintenanceJobId,
                          data,
                          defaultLineItems,
                          invoiceItems,
                          jobInstances,
                          lineItemsByLocation: getValues("lineItemsByLocation"),
                        });

                        setValue("lineItemsByLocation", newLineItemsByLocation);
                        setDefaultLineItems(newDefaultLineItems);
                        setJobInstances(newJobInstances);
                      }}
                    />
                  )}
                />
              ) : null}

              {isHideLineItemPricesAvailable(
                deliveryMethod,
                editingPayThroughCrewControlInvoice
              ) ? (
                <div className="form-group">
                  <div className="custom-control custom-checkbox">
                    <input
                      id="showLineItemPrices"
                      type="checkbox"
                      className="custom-control-input"
                      {...register("showLineItemPrices")}
                    />
                    <label
                      htmlFor="showLineItemPrices"
                      className="custom-control-label"
                    >
                      Show line item prices
                    </label>
                  </div>
                </div>
              ) : null}

              {lineItemsByLocationField.map((locationField, locationIndex) => {
                const location = locationField.customerAdditionalLocationId
                  ? customerAdditionalLocations.find(
                      (l) => l.id === locationField.customerAdditionalLocationId
                    )
                  : customer
                  ? {
                      ...customer,
                      name: "",
                    }
                  : null;

                const hasMultipleLocations =
                  lineItemsByLocationField.length > 1;

                return (
                  <div key={locationField.id} className="mb-4">
                    {location && hasMultipleLocations ? (
                      <h6 data-testid="locationLineItemHeader">
                        {location.name}
                        {location.name && formatAddressEntity(location)
                          ? " - "
                          : ""}
                        {formatAddressEntity(location)}
                      </h6>
                    ) : null}

                    <Controller
                      name={
                        `lineItemsByLocation.${locationIndex}.lineItems` as const
                      }
                      control={control}
                      render={({ field: lineItemsField }) => (
                        <>
                          <InvoiceLineItems
                            elementIdPrefix={`invoice_${location?.id ?? ""}_`}
                            lineItems={lineItemsField.value}
                            invoiceItems={invoiceItems}
                            setInvoiceItems={setInvoiceItems}
                            setLineItems={(li) => {
                              lineItemsField.onChange(li);
                            }}
                            customerTaxExempt={customer?.taxExempt ?? false}
                            taxRateAlreadySet={isTaxRateSet(invoice)}
                            onClearErrorMessage={() =>
                              dispatch(formActionCreators.clearErrorMessage())
                            }
                            jobInstances={jobInstances}
                            selectedJobInstances={
                              getSelectedWorkForLocation({
                                customerAdditionalLocationId:
                                  (location?.id === customerId
                                    ? null
                                    : location?.id) ?? null,
                                jobInstances,
                                selectedWorkToFilter: selectedWorkToBill,
                              }).selectedJobInstances
                            }
                            hideHeader={hasMultipleLocations}
                            showServiceDate={true}
                            allowZeroLineItems={lineItemsByLocation.some(
                              (l) =>
                                l.customerAdditionalLocationId !==
                                  locationField.customerAdditionalLocationId &&
                                l.lineItems.length > 0
                            )}
                            showHideOption={
                              isHideOptionAvailable(showLineItemPrices) &&
                              (isCrewControlDeliveryMethod(
                                getParsedDeliveryMethod(deliveryMethod)
                              ) ||
                                editingPayThroughCrewControlInvoice)
                            }
                          />
                        </>
                      )}
                    />
                  </div>
                );
              })}

              <Controller
                name={`amountAdjustments`}
                control={control}
                render={({ field }) => (
                  <InvoiceFormAmounts
                    lineItems={flattenLineItemsByLocation(lineItemsByLocation)}
                    taxRate={field.value.taxRate}
                    alwaysShowTaxRate={invoice?.createdByCrewMember}
                    customerTaxExempt={customer?.taxExempt ?? false}
                    taxRateAlreadySet={isTaxRateSet(invoice)}
                    onTaxRateChange={(newTaxRate) =>
                      field.onChange({
                        ...field.value,
                        taxRate: newTaxRate,
                      })
                    }
                    discountFields={{
                      discount: field.value.discount,
                      onChange: (newDiscount) => {
                        field.onChange({
                          ...field.value,
                          discount: newDiscount,
                        });
                      },
                    }}
                    depositAmountFields={{
                      maxDepositCreditAmount: maxDepositCreditAmount,
                      depositCreditAmount:
                        field.value.depositCreditAmount ?? null,
                      onDepositCreditAmountChange: (newDepositCreditAmount) => {
                        field.onChange({
                          ...field.value,
                          depositCreditAmount: newDepositCreditAmount,
                        });
                      },
                      alwaysShowDepositCredits: alwaysShowDepositCredits,
                      invoiceItems,
                      setInvoiceItems,
                      depositInvoiceItem: field.value.depositItem,
                      onDepositInvoiceItemChange: (newDepositItem) => {
                        field.onChange({
                          ...field.value,
                          depositItem: newDepositItem,
                        });
                      },
                      captureDepositItemOverride: captureDepositItemOverride,
                    }}
                  />
                )}
              />

              <div className="form-group">
                <Controller
                  name={`files`}
                  control={control}
                  render={({ field }) => (
                    <Files
                      header="Attachments"
                      showHeader={true}
                      hideLabel={true}
                      files={field.value}
                      tenantId={tenantId ?? ""}
                      imagePrefix={imagePrefix ?? ""}
                      allowExcelAndWord={true}
                      onFileUploadingStatusChange={(hasPendingFileAdd) => {
                        setFileUploading(hasPendingFileAdd);
                      }}
                      onFileAdded={(photo) => {
                        field.onChange([...field.value, photo]);
                      }}
                      onFileRemoved={(photoId, imagePath) => {
                        field.onChange(
                          field.value.filter(
                            (p) => !isPhoto(photoId, imagePath, p)
                          )
                        );
                      }}
                      onFileUpdated={(photoId, imagePath, caption) => {
                        field.onChange(
                          field.value.map((p) => {
                            if (isPhoto(photoId, imagePath, p)) {
                              return {
                                ...p,
                                caption,
                              };
                            } else {
                              return p;
                            }
                          })
                        );
                      }}
                    />
                  )}
                />
              </div>

              {areRichTextFieldsEnabled(
                invoiceId,
                getParsedDeliveryMethod(deliveryMethod)
              ) ? (
                <div className="form-group mt-4">
                  <h5>Footer text</h5>
                  <div data-testid="footerTextContainer">
                    <Controller
                      name={`footerTextState`}
                      control={control}
                      render={({ field }) => (
                        <RichTextEditor
                          templateOptions={{
                            template: "invoiceTemplate",
                            templateType:
                              CustomerCommunicationTemplateType.invoice,
                            initialState: initialFooterText,
                            setInitialState: (v) => setInitialFooterText(v),
                            templateProperty: "footerText",
                          }}
                          editorState={field.value as EditorState}
                          setEditorState={(newText) => field.onChange(newText)}
                          ariaLabel="Footer text"
                          placeholder="Type your footer text here..."
                        />
                      )}
                    />
                  </div>
                </div>
              ) : null}
            </React.Fragment>
          ) : null}
        </FormContainer>
      )}
    </>
  );
};

export default conditionalRenderer(
  InvoiceForm,
  (s) => s.forms.invoice.showForm
);

function useSetFocusOnCustomerField({
  passedInCustomerId,
  customerElement,
  customerId,
  quickBooksCustomerId,
  isQuickBooksEnabled,
  quickBooksCustomerElement,
}: {
  passedInCustomerId: string | null;
  customerElement: React.RefObject<SelectInstance<IOption>>;
  quickBooksCustomerElement: React.RefObject<
    SelectInstance<IQuickBooksCustomer>
  >;
  customerId: string;
  quickBooksCustomerId: string;
  isQuickBooksEnabled: boolean;
}) {
  const hasFocusedRaised = useRef(false);
  if (!hasFocusedRaised.current) {
    if (!passedInCustomerId && customerElement.current && !customerId) {
      hasFocusedRaised.current = true;

      setTimeout(() => {
        if (customerElement.current && !customerId) {
          customerElement.current.focus();
        }
      });
    } else if (
      isQuickBooksEnabled &&
      quickBooksCustomerElement.current &&
      !quickBooksCustomerId
    ) {
      hasFocusedRaised.current = true;

      setTimeout(() => {
        if (quickBooksCustomerElement.current && !quickBooksCustomerId) {
          quickBooksCustomerElement.current.focus();
        }
      });
    }
  }
}

function isHideLineItemPricesAvailable(
  invoiceDeliveryMethod: string,
  editingPayThroughCrewControlInvoice: boolean
) {
  return (
    isCrewControlDeliveryMethod(
      getParsedDeliveryMethod(invoiceDeliveryMethod)
    ) || editingPayThroughCrewControlInvoice
  );
}

function isZeroDollarInvoice(
  getValues: UseFormGetValues<IInvoiceFormData>,
  isDraft: boolean
) {
  const formData = getValues();
  const lineItems = flattenLineItemsByLocation(
    formData.lineItemsByLocation
  ).map((li) => ({
    quantity: li.quantity,
    amountPerItem: li.amountPerItem,
    taxable: li.taxable,
  }));

  const total = getTotal({
    taxRate: formData.amountAdjustments.taxRate,
    lineItems,
    discount: formData.amountAdjustments.discount,
    depositCreditAmount: formData.amountAdjustments.depositCreditAmount,
  });

  return total === 0 && !isDraft;
}

function getQuickBooksDepositItemIdOverride(
  captureDepositItemOverride: boolean,
  formData: IInvoiceFormData
): string | null {
  return captureDepositItemOverride
    ? formData.amountAdjustments?.depositItem?.itemId ?? null
    : null;
}

function isCrewControlDeliveryWithPayments(
  deliveryMethod: InvoiceDeliveryMethod,
  editingPayThroughCrewControlInvoice: boolean,
  areCreditCardsEnabled: boolean
): boolean {
  return arePaymentFieldsVisibleForCrewControl(
    deliveryMethod,
    editingPayThroughCrewControlInvoice,
    areCreditCardsEnabled
  );
}

function persistStickySettings({
  formData,
  isQuickBooksEnabled,
  paymentMethodPreviouslyAuthorized,
  captureDepositItemOverride,
  setUserSettings,
}: {
  formData: IInvoiceFormData;
  isQuickBooksEnabled: boolean;
  paymentMethodPreviouslyAuthorized: boolean;
  captureDepositItemOverride: boolean;
  setUserSettings: SetUserSettingsType;
}) {
  setUserSettings(
    UserSettingsType.allowCreditCardPayment,
    formData.paymentMethodOptions.allowCreditCardPayment
  );
  setUserSettings(
    UserSettingsType.allowAchPayment,
    formData.paymentMethodOptions.allowAchPayment
  );

  if (
    !(
      paymentMethodPreviouslyAuthorized &&
      getParsedDeliveryMethod(
        formData.deliveryMethodDetails.invoiceDeliveryMethod
      ) === InvoiceDeliveryMethod.paymentMethodOnFile
    )
  )
    setUserSettings(
      UserSettingsType.invoiceDeliveryMethod,
      formData.deliveryMethodDetails.invoiceDeliveryMethod.toString()
    );

  setUserSettings<string>(
    UserSettingsType.replyToEmailAddress,
    formData.deliveryMethodDetails.emailFields.replyTo
  );

  if (!isQuickBooksEnabled) {
    setUserSettings<number | null>(
      UserSettingsType.taxRate,
      formData.amountAdjustments.taxRate
    );
  }

  const depositItem = getQuickBooksDepositItemIdOverride(
    captureDepositItemOverride,
    formData
  );
  if (getQuickBooksDepositItemIdOverride !== null) {
    setUserSettings(UserSettingsType.proposalDepositItemId, depositItem);
  }
}

function isHideOptionAvailable(showLineItemPrices: boolean): boolean {
  return !showLineItemPrices;
}

function getSaveButtonText(deliveryMethod: InvoiceDeliveryMethod) {
  switch (deliveryMethod) {
    case InvoiceDeliveryMethod.emailWithCrewControl:
    case InvoiceDeliveryMethod.emailWithQuickBooks:
      return "Send email";
    case InvoiceDeliveryMethod.textWithCrewControl:
      return "Send text";
    case InvoiceDeliveryMethod.paymentMethodOnFile:
      return "Charge invoice";
    case InvoiceDeliveryMethod.printWithCrewControl:
      return "Print";
    default:
      return "Save";
  }
}

function areRichTextFieldsEnabled(
  invoiceId: string | null,
  deliveryMethod: InvoiceDeliveryMethod
) {
  // Always show text fields when editing. Edit doesn't
  // have a delivery method set.
  if (!!invoiceId) {
    return true;
  }

  switch (deliveryMethod) {
    case InvoiceDeliveryMethod.emailWithCrewControl:
    case InvoiceDeliveryMethod.textWithCrewControl:
    case InvoiceDeliveryMethod.printWithCrewControl:
      return true;
    default:
      return false;
  }
}

function getParsedDeliveryMethod(invoiceDeliveryMethod: string) {
  return parseInt(invoiceDeliveryMethod);
}
