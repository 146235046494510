import { useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import LinkButton2 from "./LinkButton2";
import { NoteWithHyperlinks } from "./NoteWithHyperlinks";
import {
  getNoteTokens,
  getTruncatedTokens,
} from "./NoteWithHyperlinks.functions";

export function NoteWithModal({
  text,
  inlineStringLength,
  maxLines,
}: {
  text: string;
  inlineStringLength?: number;
  maxLines?: number;
}) {
  const [showModal, setShowModal] = useState(false);
  inlineStringLength = inlineStringLength ?? 250;

  const noteTokens = getNoteTokens(text);
  const { newTokens, isPartialStringShown } = getTruncatedTokens({
    inlineStringLength,
    maxLines,
    noteTokens,
  });

  return (
    <div
      style={{
        whiteSpace: "pre-line",
      }}
    >
      {isPartialStringShown ? (
        <>
          <div>
            <NoteWithHyperlinks
              noteTokens={newTokens}
              testId="partialStringContainer"
            />
            ...
          </div>
          <div>
            <LinkButton2
              buttonContents="(see more)"
              onClick={() => {
                setShowModal(true);
              }}
            />
          </div>
          {showModal ? (
            <Modal
              isOpen={true}
              toggle={() => setShowModal(false)}
              scrollable={true}
            >
              <ModalBody>
                <NoteWithHyperlinks notes={text} testId="fullStringContainer" />
              </ModalBody>
              <ModalFooter>
                <button
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </ModalFooter>
            </Modal>
          ) : null}
        </>
      ) : (
        <NoteWithHyperlinks noteTokens={newTokens} />
      )}
    </div>
  );
}
