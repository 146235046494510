import { of } from "rxjs";
import { catchError, map, timeout } from "rxjs/operators";
import constants from "../../constants";
import { MaintenanceJobChangeType } from "../../enums/maintenanceJobChangeType";
import { IDaySchedule } from "../../models/IDaySchedule";
import dataProvider from "../../services/dataProvider";
import { IRootState } from "../../store";
import { actionCreators } from "../actionCreators";
import { IAction } from "../actionTypeDefinitions";
import { IAutoRoutePrompt } from "../scheduleUi";

export function autoRouteJobsPermanentSave(action: IAction, state: IRootState) {
  const { daySchedules } = state.schedule;
  const autoRoutePrompt = state.scheduleUi.autoRoutePrompt as IAutoRoutePrompt;
  const daySchedule = daySchedules.find(
    (ds) => ds.id === autoRoutePrompt?.dayScheduleId
  ) as IDaySchedule;

  return dataProvider
    .moveMaintenanceJob({
      precedingJobId: constants.idForFirstJob,
      destinationCrewId: daySchedule.crewId,
      destinationDate: daySchedule.date,
      destinationFlexible: false,
      maintenanceJobIds: autoRoutePrompt.maintenanceJobIds,
      daySchedulesToExcludeIds: [daySchedule.id],
      excludedMaintenanceJobIds: [],
      jobInstancesToResetWasAutoGenerated: autoRoutePrompt.jobInstanceIds,
      maintenanceJobChangeType:
        MaintenanceJobChangeType.AutoRoutedAppliedFutureWeeks,
    })
    .pipe(
      timeout(30000),
      map((result) => {
        return actionCreators.autoRouteJobsPermanentCompleted(
          result.data.updatedDaySchedules,
          result.data.updatedFlexibleJobWeeks,
          autoRoutePrompt.maintenanceJobIds
        );
      }),
      catchError(() => {
        return of(actionCreators.autoRouteJobsPermanentError());
      })
    );
}
