import { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { withRouter } from "react-router-dom";

const ScrollToTop: React.FunctionComponent<RouteComponentProps<{}>> = ({
  location,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

export default withRouter(ScrollToTop);
