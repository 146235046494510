import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import { OpportunityProposalStatus } from "../enums/opportunityProposalStatus";
import { IOpportunity } from "../models/IOpportunity";
import { IProposal } from "../models/IProposal";
import { opportunitiesActionCreators } from "../modules/opportunity";
import { proposalsActionCreators } from "../modules/proposal";
import opportunityDataProvider from "../services/opportunityDataProvider";

interface IProps {
  opportunity: IOpportunity;
  proposal: IProposal | null;
  customerName: string;
  setIsProposalStatusUpdating: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProposalStatusSelection: React.FunctionComponent<IProps> = ({
  opportunity,
  proposal,
  customerName,
  setIsProposalStatusUpdating,
}) => {
  const dispatch = useDispatch();
  const selectRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.value = getProposalStatusValue(opportunity);
    }
  }, [opportunity]);

  return (
    <select
      className="form-control form-control-sm"
      aria-label="Proposal status"
      ref={selectRef}
      defaultValue={getProposalStatusValue(opportunity)}
      onChange={(e) => {
        setIsProposalStatusUpdating(true);

        const proposalStatus = parseInt(e.currentTarget.value);
        opportunityDataProvider
          .saveOpportunity({
            id: opportunity.id,
            proposalStatus,
          })
          .subscribe({
            next: (opportunity) => {
              setIsProposalStatusUpdating(false);
              dispatch(
                opportunitiesActionCreators.editOpportunity(opportunity)
              );

              if (opportunity.proposalIdToReload) {
                dispatch(
                  proposalsActionCreators.reloadProposal({
                    proposalId: opportunity.proposalIdToReload,
                  })
                );
              }

              const statusTriggeringArchive =
                proposalStatus === OpportunityProposalStatus.Rejected ||
                proposalStatus === OpportunityProposalStatus.Scheduled;
              if (statusTriggeringArchive && !opportunity.archived) {
                dispatch(
                  opportunitiesActionCreators.showArchivePrompt({
                    promptMessageOverride:
                      "Would you like to archive this proposal?",
                    opportunitiesToArchive: [
                      {
                        opportunityId: opportunity.id,
                        customerName,
                      },
                    ],
                  })
                );
              }
            },

            error: (error) => {
              setIsProposalStatusUpdating(false);

              dispatch(
                opportunitiesActionCreators.setError({
                  pageErrorMessage: getErrorMessageFromError(
                    error,
                    "An unknown error occurred while updating the proposal."
                  ),
                })
              );

              // Set value back to original value
              if (selectRef.current) {
                selectRef.current.value = getProposalStatusValue(opportunity);
              }
            },
          });
      }}
    >
      {!proposal?.acceptanceDateTimeUtc ? (
        <option value="">Pending</option>
      ) : null}
      <option value={OpportunityProposalStatus.Accepted.toString()}>
        Accepted
      </option>
      <option value={OpportunityProposalStatus.Scheduled.toString()}>
        Scheduled
      </option>
      <option value={OpportunityProposalStatus.Rejected.toString()}>
        Rejected
      </option>
    </select>
  );
};

export default ProposalStatusSelection;

function getProposalStatusValue(opportunity: IOpportunity) {
  return typeof opportunity.proposalStatus !== "number"
    ? ""
    : opportunity.proposalStatus.toString();
}
