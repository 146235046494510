import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BillingReportJobStatusFilter } from "../../../../enums/billingReportJobStatusFilter";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { getActiveSortedCrews } from "../../../../services/crewService";
import { getSortedItems } from "../../../../services/sortingService";
import CrewMultiSelect from "../../components/CrewMultiSelect";

interface IAdditionalFilterValues {
  category: string;
  jobStatus: string;
  crewIds: Array<string>;
}

export default function AdditionalFilters({
  values: defaultValues,
  onFilterValuesChange,
}: {
  values: IAdditionalFilterValues;
  onFilterValuesChange(newValue: IAdditionalFilterValues): void;
}) {
  const [showModal, setShowModal] = useState(false);

  const categories = useApplicationStateSelector(
    (s) => s.common.crewCategories
  );
  const filteredCategories = categories.filter((c) => !c.inactive);
  const crews = useApplicationStateSelector((s) => s.crew.crews);

  const [filterValues, setFilterValues] =
    useState<IAdditionalFilterValues>(defaultValues);

  const onClose = () => {
    setShowModal(false);
    onFilterValuesChange(filterValues);
  };

  return (
    <>
      <button
        type="button"
        className="d-block btn btn-secondary"
        onClick={() => {
          // Make sure filter values are up-to-date
          setFilterValues(defaultValues);

          setShowModal(true);
        }}
      >
        <FontAwesomeIcon icon={faFilter} />
        <span className="ml-1">Filters</span>
      </button>
      {showModal ? (
        <Modal toggle={() => onClose()} isOpen>
          <ModalHeader toggle={() => onClose()}>Filters</ModalHeader>
          <ModalBody>
            {filteredCategories.length > 0 ? (
              <div className="form-group">
                <label htmlFor="categories">Services</label>
                <select
                  className="form-control"
                  id="categories"
                  name="category"
                  value={filterValues.category}
                  onChange={(e) => {
                    setFilterValues({
                      ...filterValues,
                      category: e.currentTarget.value,
                    });
                  }}
                >
                  <option value="">All services</option>
                  {getSortedItems(filteredCategories, "name").map((c) => (
                    <option key={c.name} value={c.name}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}

            {getActiveSortedCrews(crews).length > 0 ? (
              <div className="form-group">
                <label htmlFor="crews">Crews</label>
                <CrewMultiSelect
                  crews={getActiveSortedCrews(crews)}
                  selectedCrewIds={filterValues.crewIds}
                  setSelectedCrewIds={(newValue) =>
                    setFilterValues({
                      ...filterValues,
                      crewIds: newValue,
                    })
                  }
                  inputId="crews"
                />
              </div>
            ) : null}

            <div className="form-group">
              <label htmlFor="jobStatus">Job status</label>
              <select
                id="jobStatus"
                className="form-control"
                value={filterValues.jobStatus}
                onChange={(e) =>
                  setFilterValues({
                    ...filterValues,
                    jobStatus: e.currentTarget.value,
                  })
                }
              >
                <option value={BillingReportJobStatusFilter.All.toString()}>
                  All
                </option>
                <option
                  value={BillingReportJobStatusFilter.Completed.toString()}
                >
                  Completed
                </option>
                <option
                  value={BillingReportJobStatusFilter.NotCompleted.toString()}
                >
                  Not Completed
                </option>
              </select>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => onClose()}
            >
              Close
            </button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
}
