import React from "react";
import { Marker } from "@react-google-maps/api";

interface IProps {
  labelText?: string;
  fillColor?: string;
  position: google.maps.LatLng;
  onClick: () => void;
  flexibleJob: boolean;
}

const CustomizedMapMarker: React.FunctionComponent<IProps> = ({
  labelText,
  fillColor,
  position,
  onClick,
  flexibleJob,
}) => {
  let label: google.maps.MarkerLabel | undefined = undefined;
  let icon: google.maps.Symbol | undefined = undefined;
  if (!flexibleJob) {
    label = {
      text: labelText ?? "",
      fontSize: "10px",
      color: "white",
    };

    icon = {
      path: "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z",
      fillColor,
      fillOpacity: 1,
      strokeColor: "#000",
      strokeWeight: 1,
      scale: 1,
      labelOrigin: new google.maps.Point(1, -28),
    };
  }

  return (
    <Marker onClick={onClick} label={label} icon={icon} position={position} />
  );
};

export default CustomizedMapMarker;
