import React, { useEffect } from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { IFormState } from "../../../formGenerator/formReducers";

const FormAlertOnLeave: React.FunctionComponent<{}> = () => {
  const forms = useApplicationStateSelector((s) => s.forms);
  const openFormsV2 = useApplicationStateSelector((s) => s.formV2.openForms);

  useEffect(() => {
    const handler = (e: BeforeUnloadEvent) => {
      const isFormOpenV1 = Object.values(forms)
        .map((f) => f as IFormState<any>)
        .reduce((acc, cur) => {
          return acc || cur.showForm;
        }, false);

      const isFormOpenV2 = openFormsV2.length > 0;

      const isFormOpen = isFormOpenV1 || isFormOpenV2;

      if (isFormOpen) {
        const message = "Changes to the form will not be saved if you leave";

        e.preventDefault();
        e.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handler);

    return function cleanup() {
      window.removeEventListener("beforeunload", handler);
    };
  }, [forms, openFormsV2]);

  return null;
};

export default FormAlertOnLeave;
