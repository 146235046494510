import {
  Controller,
  useForm,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import DayPicker, { format as dayPickerFormat } from "../components/DayPicker";
import { format } from "date-fns";
import dateService from "../../../services/dateService";
import React, { useEffect, useRef, useState } from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import CreditCardInline, {
  ISavedCard,
  triggerSave,
} from "../components/CreditCardInline";
import BankAccountInline, {
  parseBankAccountType,
} from "../components/BankAccountInline";
import { PayrixBankAccountType } from "../../../enums/payrixBankAccountType";
import { mergeMap, timeout } from "rxjs/operators";
import { formSaveDefaultTimeout } from "../../../formGenerator/actionEpics";
import { IInvoicePaymentParameters } from "../../../formGenerator/formParameters/IInvoicePaymentParameters";
import { parseName } from "../../../services/achService";
import { IBankAccount } from "../../../models/IBankAccount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import invoiceDataProvider, {
  IGetInvoicePaymentFormDataResponse,
  InvoicePaymentSavePayload,
} from "../../../slices/billing/services/invoiceDataProvider";
import { useCreditCardsEnabled } from "../../../hooks/useCreditCardsEnabled";
import { formatCurrency } from "../../../services/currencyFormatter";
import { useLoadCustomers } from "../../../hooks/useLoadCustomers";
import Alert from "../components/Alert";
import Spinner from "../components/Spinner";
import { EmailSendFields, EmailSendValue } from "../components/EmailSendFields";
import {
  createEmailAddressRecord,
  createEmailAddressRecordsForCustomer,
  getEmailAddressesForSave,
} from "../components/EmailAddresses.functions";
import CustomerPaymentMethodForForms from "../components/CustomerPaymentMethodForForms";
import {
  parsePaymentMethodType,
  getSaveButtonText,
  getPaymentMethodTypeForSave,
  getPaymentMethodTypeFromFormParameters,
  isPaymentDateVisible,
  getMinimumPaymentAmount,
  isAddingPayment,
} from "./InvoicePaymentForm.functions";
import { useGetReplyToEmailAddress } from "../../../hooks/useGetReplyToEmailAddress";
import { useUserSettings } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import PaymentMethodOnFileDisclosure from "../components/PaymentMethodOnFileDisclosure";
import { forkJoin, Observable, Subject, Subscription, throwError } from "rxjs";
import CurrencyInputV2 from "../components/CurrencyInputV2";
import { FormPaymentMethodOptions } from "./InvoicePaymentForm.types";
import modalConversion from "../../../services/modelConversion";
import { PaymentMethodType } from "../../../enums/paymentMethodType";
import FormContainerWithoutRedux from "../components/FormContainerWithoutRedux";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import { fullStoryTrack } from "../../../services/fullStoryService";
import { isStringSet } from "../../../services/stringService";
import { ErrorMessageType } from "../components/FormContainer";

interface IFormData {
  datePaid: string;
  paymentMethodType: string;
  paymentReferenceInformation: string;
  amount: string;

  bankAccountType: string;
  bankRoutingNumber: string;
  bankAccountNumber: string;
  bankAccountNumberConfirmed: string;
  name: string;
  sendReceipt: boolean;
  receiptEmailFields: EmailSendValue;
}

const InvoicePaymentForm: React.FunctionComponent<{
  formParameters: IInvoicePaymentParameters;
  onSaveComplete: () => void;
  onCancel: () => void;
}> = ({ formParameters, onSaveComplete, onCancel }) => {
  const { control, register, getValues, setValue, watch } = useForm<IFormData>({
    defaultValues: getEmptyFormData(),
  });
  const [originalFormValues, setOriginalFormValues] = useState(
    getEmptyFormData()
  );
  const creditCardIframeRef = useRef<HTMLIFrameElement | null>(null);
  const { areCreditCardsEnabled } = useCreditCardsEnabled();
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");
  const creditCardSaveMonitor = useRef(
    new Subject<
      | { type: "success"; savedCard: ISavedCard }
      | { type: "error"; error: string }
    >()
  );

  const paymentMethodType = parsePaymentMethodType(watch("paymentMethodType"));
  const amount = parseFloat(watch("amount"));
  const sendReceipt = watch("sendReceipt");

  const {
    loadingData,
    errorLoadingData,
    invoiceAuthorizedForPaymentMethodOnFile,
    getInvoicePaymentFormDataResponse,
    customer,
  } = useLoadDataForForm({ formParameters, setValue, setOriginalFormValues });

  const datePaidRef = useRef<HTMLLabelElement>(null);

  const { setUserSettings } = useUserSettings();

  const bankAccountNumber = watch("bankAccountNumber");
  const bankAccountNumberConfirmed = watch("bankAccountNumberConfirmed");
  const bankAccountType = watch("bankAccountType");
  const bankRoutingNumber = watch("bankRoutingNumber");

  if (errorLoadingData) {
    return (
      <Alert message="Unable to load invoice data." closeForm={onCancel} />
    );
  } else if (loadingData || getInvoicePaymentFormDataResponse === null) {
    return <Spinner />;
  }

  const creditCardFieldsVisible =
    paymentMethodType === FormPaymentMethodOptions.CreditCard;
  const addingPayment = isAddingPayment(formParameters);

  return (
    <FormContainerWithoutRedux
      formHeader={getFormHeader({
        addingPayment,
        getInvoicePaymentFormDataResponse,
      })}
      size="lg"
      formType={FormTypesV2.invoicePayment}
      showForm={true}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      saveButtonText={getSaveButtonText(paymentMethodType)}
      onCancel={onCancel}
      onSaveComplete={onSaveComplete}
      save={() => {
        setUserSettings(
          UserSettingsType.replyToEmailAddress,
          getValues("receiptEmailFields.replyTo")
        );

        if (
          parseFloat(getValues("amount")) <
          getInvoicePaymentFormDataResponse.balance
        ) {
          fullStoryTrack("Partial Payment Saved");
        }

        if (paymentMethodType === FormPaymentMethodOptions.CreditCard) {
          if (triggerSave(creditCardIframeRef)) {
            return creditCardSaveMonitor.current.pipe(
              mergeMap((cardMonitorResult) =>
                cardMonitorResult.type === "success"
                  ? executeSave({
                      payload: buildSavePayload({
                        getValues,
                        payrixToken: cardMonitorResult.savedCard.token,
                        formParameters,
                        isPaymentDateVisible:
                          isPaymentDateVisible(paymentMethodType),
                      }),
                      formParameters,
                    })
                  : throwError(cardMonitorResult.error)
              )
            );
          } else {
            return throwError("Unable to trigger save");
          }
        } else {
          const { firstName, lastName } = parseName(getValues("name"));
          const payload = buildSavePayload({
            getValues,
            bankAccount:
              parsePaymentMethodType(getValues("paymentMethodType")) ===
              FormPaymentMethodOptions.Ach
                ? {
                    accountNumber: getValues("bankAccountNumber"),
                    accountType: parseBankAccountType(
                      getValues("bankAccountType")
                    ) as PayrixBankAccountType,
                    routingNumber: getValues("bankRoutingNumber"),
                    firstName,
                    lastName,
                  }
                : null,
            formParameters,
            isPaymentDateVisible: isPaymentDateVisible(paymentMethodType),
          });

          return executeSave({
            payload,
            formParameters,
          });
        }
      }}
      hasFormDataChanged={() => {
        return (
          JSON.stringify(getValues()) !== JSON.stringify(originalFormValues)
        );
      }}
      validate={() => {
        if (
          paymentMethodType === FormPaymentMethodOptions.PaymentMethodOnFile &&
          !(customer?.paymentMethod?.isTokenSet ?? false)
        ) {
          return {
            valid: false,
            errorMessage:
              "The customer must have a payment method to use Payment Method On File",
          };
        }

        return {
          valid: true,
        };
      }}
    >
      <div className="form-group d-flex">
        <div>
          <div className="font-weight-light">Customer</div>
          <div>{formParameters?.customerName}</div>
        </div>
        <div className="ml-5" style={{ flexShrink: 0 }}>
          <div className="font-weight-light">Total amount</div>
          <div className="text-right" data-testid="totalAmount">
            {formatCurrency(getInvoicePaymentFormDataResponse.totalAmount ?? 0)}
          </div>
        </div>
        {addingPayment ? (
          <div className="ml-5" style={{ flexShrink: 0 }}>
            <div className="font-weight-light">Balance</div>
            <div className="text-right" data-testid="balance">
              {formatCurrency(getInvoicePaymentFormDataResponse.balance ?? 0)}
            </div>
          </div>
        ) : null}
      </div>
      <div className="form-group">
        <label htmlFor="paymentMethodType" className="required">
          Payment method
        </label>
        <select
          id="paymentMethodType"
          className="form-control"
          {...register("paymentMethodType")}
          required
        >
          <option value="">Select payment method</option>
          <option value={FormPaymentMethodOptions.Check.toString()}>
            Check
          </option>
          <option value={FormPaymentMethodOptions.Cash.toString()}>Cash</option>
          {areCreditCardsEnabled && addingPayment ? (
            <>
              <option value={FormPaymentMethodOptions.CreditCard.toString()}>
                Card
              </option>
              <option value={FormPaymentMethodOptions.Ach.toString()}>
                Bank account
              </option>
              {areCreditCardsEnabled ? (
                <option
                  value={FormPaymentMethodOptions.PaymentMethodOnFile.toString()}
                >
                  Payment method on file
                </option>
              ) : null}
            </>
          ) : null}
          <option value={FormPaymentMethodOptions.Other.toString()}>
            Other
          </option>
        </select>
      </div>

      {
        // Credit card toggles whether elements are visible so that switching from Bank Transfer doesn't force the iframe to reload
      }
      <div
        style={{
          display:
            paymentMethodType === FormPaymentMethodOptions.CreditCard
              ? undefined
              : "none",
        }}
      >
        <div data-testid="creditCardContainer">
          <CreditCardInline
            creditCardIframeRef={creditCardIframeRef}
            visible={creditCardFieldsVisible}
            cardOnFile={false}
            onError={(error) =>
              creditCardSaveMonitor.current.next({ type: "error", error })
            }
            onSave={(savedCard) => {
              creditCardSaveMonitor.current.next({
                type: "success",
                savedCard,
              });
            }}
          />
        </div>
      </div>

      {paymentMethodType === FormPaymentMethodOptions.Ach ? (
        <div className="form-group">
          <div className="form-group">
            <label htmlFor="name" className="required">
              Name
            </label>
            <input
              id="name"
              type="text"
              className="form-control"
              {...register("name")}
              required
              placeholder="JOHN SMITH"
            />
          </div>

          <BankAccountInline
            value={{
              bankAccountNumber,
              bankAccountNumberConfirmed,
              bankAccountType,
              bankRoutingNumber,
            }}
            onChange={(newValue) => {
              setValue("bankAccountNumber", newValue.bankAccountNumber);
              setValue(
                "bankAccountNumberConfirmed",
                newValue.bankAccountNumberConfirmed
              );
              setValue("bankAccountType", newValue.bankAccountType);
              setValue("bankRoutingNumber", newValue.bankRoutingNumber);
            }}
          />
        </div>
      ) : null}

      {paymentMethodType === FormPaymentMethodOptions.PaymentMethodOnFile &&
      customer ? (
        <>
          {
            // Showing PaymentMethodOnFileDisclosure will prevent the form from submitting
            // if the checkbox isn't checked since the control is marked as required
          }
          {!invoiceAuthorizedForPaymentMethodOnFile ? (
            <PaymentMethodOnFileDisclosure />
          ) : null}
          <CustomerPaymentMethodForForms customerId={customer.id} />

          {getInvoicePaymentFormDataResponse.addConvenienceFee &&
          customer.paymentMethod?.type === PaymentMethodType.CreditCard ? (
            <InlineAlert
              alertType={"warning"}
              testId={"convenienceFeeNotApplied"}
              contents={
                "The convenience fee will not be applied when charging a payment method on file. This is due to credit card regulations."
              }
            />
          ) : null}
        </>
      ) : null}

      {isPaymentDateVisible(paymentMethodType) ? (
        <div className="form-group">
          <label htmlFor="datePaid" className="required" ref={datePaidRef}>
            Payment date
          </label>

          <Controller
            name="datePaid"
            control={control}
            render={({ field }) => (
              <DayPicker
                onDayPickerHide={() => null}
                inputClass="form-control"
                dayPickerProps={{}}
                required={true}
                inputId={"datePaid"}
                labelRef={datePaidRef}
                overlayFixed={true}
                value={
                  !!field.value ? format(field.value, dayPickerFormat) : ""
                }
                onDaySelected={(day: Date) => {
                  if (!!day) {
                    field.onChange(dateService.formatAsIso(day));
                  } else {
                    field.onChange("");
                  }
                }}
              />
            )}
          />
        </div>
      ) : null}

      {addingPayment ? (
        <>
          <div className="form-group">
            <label htmlFor="paymentAmount" className="required">
              Payment amount
            </label>

            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <CurrencyInputV2
                  id="paymentAmount"
                  className="form-control"
                  placeholder="$0.00"
                  value={field.value}
                  onValueChange={field.onChange}
                  max={
                    getInvoicePaymentFormDataResponse.balance +
                    (addingPayment ||
                    getInvoicePaymentFormDataResponse.existingPayment === null
                      ? 0
                      : getInvoicePaymentFormDataResponse.existingPayment
                          .amount)
                  }
                  min={getMinimumPaymentAmount(paymentMethodType)}
                  required
                />
              )}
            />
          </div>

          <ConvenienceFeeAmountAlert
            invoiceId={formParameters.invoiceId}
            addConvenienceFee={
              getInvoicePaymentFormDataResponse.addConvenienceFee
            }
            amount={amount}
            paymentMethodType={paymentMethodType}
          />

          {getRemainingBalanceAfterPayment(
            getInvoicePaymentFormDataResponse,
            amount
          ) > 0 ? (
            <InlineAlert
              alertType="warning"
              testId="invoiceRemainOpenAlert"
              contents={
                <>
                  Invoice will remain open and have a new balance of{" "}
                  {formatCurrency(
                    getRemainingBalanceAfterPayment(
                      getInvoicePaymentFormDataResponse,
                      amount
                    )
                  )}
                  .
                </>
              }
            />
          ) : null}
        </>
      ) : null}

      <div className="form-group">
        <label htmlFor="referenceInformation">Reference information</label>
        <input
          id="referenceInformation"
          type="text"
          className="form-control"
          maxLength={250}
          {...register("paymentReferenceInformation")}
        />
      </div>

      {addingPayment ? (
        <>
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="sendReceipt"
                {...register("sendReceipt")}
              />
              <label className="custom-control-label" htmlFor="sendReceipt">
                Send receipt
              </label>
            </div>
          </div>
          <Controller
            control={control}
            name="receiptEmailFields"
            render={({ field }) => (
              <EmailSendFields
                value={field.value}
                onChange={(newValue) => field.onChange(newValue)}
                disabled={!sendReceipt}
              />
            )}
          />
        </>
      ) : null}
    </FormContainerWithoutRedux>
  );
};

export default InvoicePaymentForm;

function getFormHeader({
  addingPayment,
  getInvoicePaymentFormDataResponse,
}: {
  addingPayment: boolean;
  getInvoicePaymentFormDataResponse: IGetInvoicePaymentFormDataResponse;
}): string {
  let invoiceNumberSuffix = "";
  if (isStringSet(getInvoicePaymentFormDataResponse.number)) {
    invoiceNumberSuffix = ` for Invoice #${getInvoicePaymentFormDataResponse.number}`;
  }
  return addingPayment
    ? `Add Payment${invoiceNumberSuffix}`
    : `Update Payment${invoiceNumberSuffix}`;
}

function getRemainingBalanceAfterPayment(
  getInvoicePaymentFormDataResponse: IGetInvoicePaymentFormDataResponse,
  amount: number
): number {
  return (
    getInvoicePaymentFormDataResponse.balance +
    (getInvoicePaymentFormDataResponse.existingPayment?.amount ?? 0) -
    amount
  );
}

function useLoadDataForForm({
  formParameters,
  setValue,
  setOriginalFormValues,
}: {
  formParameters: IInvoicePaymentParameters;
  setValue: UseFormSetValue<IFormData>;
  setOriginalFormValues: React.Dispatch<React.SetStateAction<IFormData>>;
}) {
  const [loadingData, setLoadingData] = useState(false);
  const [errorLoadingData, setErrorLoadingData] = useState(false);
  const [
    invoiceAuthorizedForPaymentMethodOnFile,
    setInvoiceAuthorizedForPaymentMethodOnFile,
  ] = useState(false);
  const [
    getInvoicePaymentFormDataResponse,
    setGetInvoicePaymentFormDataResponse,
  ] = useState<IGetInvoicePaymentFormDataResponse | null>(null);

  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const replyToEmailAddress = useGetReplyToEmailAddress();

  const { loading: loadingCustomer, errorLoading: errorLoadingCustomer } =
    useLoadCustomers(
      formParameters?.customerId ? [formParameters.customerId] : []
    );

  const customer =
    customers.find((c) => c.id === formParameters?.customerId) ?? null;

  useEffect(() => {
    let subscription: Subscription | null = null;

    if (formParameters?.invoiceId) {
      setLoadingData(true);
      subscription = forkJoin({
        paymentMethodOnFileAuthorization:
          invoiceDataProvider.getPaymentMethodOnFileAuthorizationAsync(
            formParameters.invoiceId
          ),
        getInvoicePaymentFormDataResponse:
          invoiceDataProvider.getInvoicePaymentFormData(
            formParameters.invoiceId,
            formParameters.paymentId
          ),
      }).subscribe({
        next: ({
          paymentMethodOnFileAuthorization,
          getInvoicePaymentFormDataResponse,
        }) => {
          setInvoiceAuthorizedForPaymentMethodOnFile(
            paymentMethodOnFileAuthorization.paymentMethodOnFileAuthorized
          );
          setGetInvoicePaymentFormDataResponse(
            getInvoicePaymentFormDataResponse
          );

          setLoadingData(false);
          setErrorLoadingData(false);
        },

        error: (err) => {
          setLoadingData(false);
          setErrorLoadingData(true);
        },
      });
    }

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [formParameters?.invoiceId, formParameters?.paymentId]);

  const hasFormDataInitialized = useRef(false);
  if (
    !hasFormDataInitialized.current &&
    getInvoicePaymentFormDataResponse !== null
  ) {
    const defaultValues: IFormData = {
      datePaid: dateService.formatAsIso(dateService.getCurrentDate()),
      amount: getInvoicePaymentFormDataResponse.balance.toString(),
      paymentMethodType: "",
      paymentReferenceInformation: "",
      bankAccountNumber: "",
      bankAccountNumberConfirmed: "",
      bankAccountType: "",
      bankRoutingNumber: "",
      name: "",
      sendReceipt: true,
      receiptEmailFields: {
        recipients: createEmailAddressRecordsForCustomer(customer),
        replyTo:
          formParameters.replyToEmailAddress ?? replyToEmailAddress ?? "",
      },
    };

    if (getInvoicePaymentFormDataResponse.existingPayment !== null) {
      defaultValues.amount =
        getInvoicePaymentFormDataResponse.existingPayment.amount.toString();
      defaultValues.paymentMethodType = getPaymentMethodTypeFromFormParameters(
        getInvoicePaymentFormDataResponse
      );
      defaultValues.paymentReferenceInformation =
        getInvoicePaymentFormDataResponse.existingPayment
          .paymentReferenceInformation ?? "";
      defaultValues.datePaid =
        getInvoicePaymentFormDataResponse.existingPayment.datePaid ?? "";
    } else if (invoiceAuthorizedForPaymentMethodOnFile) {
      const defaultPaymentMethodType =
        FormPaymentMethodOptions.PaymentMethodOnFile.toString();

      defaultValues.paymentMethodType = defaultPaymentMethodType;
    }

    setValues(defaultValues, setValue);
    setOriginalFormValues(defaultValues);

    hasFormDataInitialized.current = true;
  }

  return {
    loadingData: loadingData || loadingCustomer,
    errorLoadingData: errorLoadingData || errorLoadingCustomer,
    invoiceAuthorizedForPaymentMethodOnFile,
    getInvoicePaymentFormDataResponse,
    customer,
  };
}

function executeSave({
  payload,
  formParameters,
}: {
  payload: InvoicePaymentSavePayload;
  formParameters: IInvoicePaymentParameters;
}) {
  let saveFn: Observable<unknown>;
  if (typeof formParameters?.paymentId === "string") {
    saveFn = invoiceDataProvider.updateInvoicePayment({
      invoiceId: formParameters.invoiceId,
      paymentId: formParameters.paymentId,
      changes: payload,
    });
  } else {
    saveFn = invoiceDataProvider.addInvoicePayment({
      invoiceId: formParameters.invoiceId,
      changes: payload,
    });
  }

  return saveFn.pipe(timeout(formSaveDefaultTimeout));
}

function buildSavePayload({
  getValues,
  payrixToken,
  bankAccount,
  formParameters,
  isPaymentDateVisible,
}: {
  getValues: UseFormGetValues<IFormData>;
  payrixToken?: string | null;
  bankAccount?: IBankAccount | null;
  formParameters: IInvoicePaymentParameters | null;
  isPaymentDateVisible: boolean;
}): InvoicePaymentSavePayload {
  const addingPayment = isAddingPayment(formParameters);
  let receiptEmailAddresses: Array<string> | null;
  if (addingPayment) {
    receiptEmailAddresses = getEmailAddressesForSave(
      getValues("receiptEmailFields.recipients")
    );
  } else {
    receiptEmailAddresses = null;
  }

  const formPaymentMethod = parsePaymentMethodType(
    getValues("paymentMethodType")
  );

  let chargePaymentMethodOnFile: boolean = false;
  if (formPaymentMethod === FormPaymentMethodOptions.PaymentMethodOnFile) {
    chargePaymentMethodOnFile = true;
  }

  return {
    amount: addingPayment
      ? modalConversion.convertStringToNumber(getValues("amount"))
      : null,
    datePaid: isPaymentDateVisible ? getValues("datePaid") : null,
    paymentMethodType: getPaymentMethodTypeForSave(formPaymentMethod),
    paymentReferenceInformation: getValues("paymentReferenceInformation"),
    chargePaymentMethodOnFile,
    payrixToken: payrixToken ?? null,
    bankAccount: bankAccount ?? null,
    receiptEmailAddresses,
    replyToEmailAddress: addingPayment
      ? getValues("receiptEmailFields.replyTo")
      : null,
    sendReceipt: addingPayment ? getValues("sendReceipt") : false,
  };
}

function setValues(
  defaultValues: IFormData,
  setValue: UseFormSetValue<IFormData>
) {
  for (let prop in defaultValues) {
    const key = prop as keyof typeof defaultValues;
    setValue(key, defaultValues[key]);
  }
}

function getEmptyFormData(): IFormData {
  return {
    datePaid: dateService.formatAsIso(dateService.getCurrentDate()),
    amount: "",
    paymentMethodType: "",
    paymentReferenceInformation: "",
    bankAccountNumber: "",
    bankAccountNumberConfirmed: "",
    bankAccountType: "",
    bankRoutingNumber: "",
    name: "",
    sendReceipt: true,
    receiptEmailFields: {
      recipients: [createEmailAddressRecord("")],
      replyTo: "",
    },
  };
}

function ConvenienceFeeAmountAlert({
  invoiceId,
  amount,
  addConvenienceFee,
  paymentMethodType,
}: {
  invoiceId: string;
  amount: number;
  addConvenienceFee: boolean;
  paymentMethodType: FormPaymentMethodOptions | null;
}) {
  const paymentsConvenienceFeePercentForDisplay = useApplicationStateSelector(
    (s) => s.common.paymentsConvenienceFeePercentForDisplay
  );

  const [convenienceFeeAmount, setConvenienceFeeAmount] = useState<
    number | null
  >(null);

  useEffect(() => {
    // Ensure an old value isn't show once the amount changes.
    // When it's null, the percent will show.
    setConvenienceFeeAmount(null);

    let subscription: Subscription | null = null;
    if (!isNaN(amount) && amount > 0) {
      subscription = invoiceDataProvider
        .getConvenienceFeeAmount(invoiceId, amount)
        .subscribe({
          next: (result) => {
            setConvenienceFeeAmount(result.convenienceFeeAmount);
          },
        });
    }

    return function cleanup() {
      if (subscription !== null) {
        subscription.unsubscribe();
      }
    };
  }, [invoiceId, amount]);

  if (
    !addConvenienceFee ||
    paymentMethodType !== FormPaymentMethodOptions.CreditCard
  ) {
    return null;
  }

  return (
    <InlineAlert
      alertType="info"
      testId="convenienceFeeWarning"
      contents={
        <>
          A{" "}
          {convenienceFeeAmount !== null
            ? `${formatCurrency(
                convenienceFeeAmount
              )} (${paymentsConvenienceFeePercentForDisplay}%)`
            : `${paymentsConvenienceFeePercentForDisplay}%`}{" "}
          convenience fee will be applied
        </>
      }
    />
  );
}

function InlineAlert({
  alertType,
  testId,
  contents,
}: {
  alertType: "info" | "warning";
  testId: string;
  contents: React.ReactNode;
}) {
  return (
    <div className={`alert alert-${alertType} p-2 my-2`} data-testid={testId}>
      <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
      <small>{contents}</small>
    </div>
  );
}
