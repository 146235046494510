import TableColumn from "./TableColumn";
import RowProperties from "./rowProperties";

export function isColumnVisible<T extends RowProperties>(
  column: TableColumn<T>,
  row: T | null
): unknown {
  return !(
    (typeof column.hidden === "boolean" && column.hidden === true) ||
    (row !== null &&
      typeof column.hidden === "function" &&
      column.hidden({ row }))
  );
}
