import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAction } from "./actionTypeDefinitions";

export interface IPromptState {
  showPrompt: boolean;
  promptMessage: string;
  promptSaveText: string | null;
  promptCancelText: string | null;
  promptSubMessage: string | null | Array<IPromptMessageSection>;
  promptSubMessageClassName: string | undefined;
  confirmationAction: IAction | null;
  secondaryButtons: Array<IPromptStateSecondaryButton> | null;
}

export interface IPromptStateSecondaryButton {
  text: string;
  action: IAction;
}

export interface IPromptMessageSection {
  text: string;
  bold?: boolean;
  dangerColor?: boolean;
  newBlock?: boolean;
}

const initialState = {
  showPrompt: false,
  promptMessage: "",
  promptSaveText: null,
  promptCancelText: null,
  promptSubMessage: null,
  promptSubMessageClassName: undefined,
  confirmationAction: null,
  secondaryButtons: null,
} as IPromptState;

const { reducer, actions } = createSlice({
  name: "prompt",
  initialState,
  reducers: {
    showPrompt: (state, action: PayloadAction<IPromptShow>) => {
      return {
        ...state,
        showPrompt: true,
        promptMessage: action.payload.promptMessage,
        promptSaveText: action.payload.promptSaveText,
        promptCancelText: action.payload.promptCancelText,
        promptSubMessage: action.payload.promptSubMessage,
        promptSubMessageClassName: action.payload.promptSubMessageClassName,
        confirmationAction: action.payload.confirmationAction,
        secondaryButtons: action.payload.secondaryButtons ?? null,
      };
    },

    confirmPrompt: (state) => {
      return {
        ...state,
        showPrompt: false,
        promptSaveText: null,
        promptCancelText: null,
        promptMessage: "",
        promptSubMessage: null,
        promptSubMessageClassName: undefined,
        confirmationAction: null,
        secondaryButtons: null,
      };
    },

    cancelPrompt: (state) => {
      return {
        ...state,
        showPrompt: false,
        promptSaveText: null,
        promptCancelText: null,
        promptMessage: "",
        promptSubMessage: null,
        promptSubMessageClassName: undefined,
        confirmationAction: null,
        secondaryButtons: null,
      };
    },
  },
});

export default reducer;

export const promptActionCreators = actions;

export interface IPromptShow {
  promptMessage: string;
  promptSubMessage: string | null | Array<IPromptMessageSection>;
  promptSaveText: string | null;
  promptCancelText: string | null;
  promptSubMessageClassName: string | undefined;
  confirmationAction: IAction;
  secondaryButtons?: Array<IPromptStateSecondaryButton>;
}

export interface IPromptConfirm {
  confirmationAction: IAction;
}
