import { useState } from "react";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import LinkButton from "../../../containers/app/components/LinkButton";
import { PrivacyPolicy } from "../../../containers/app/components/PrivacyPolicy";
import { TermsOfService } from "../../../containers/app/components/TermsOfService";

interface IProps {
  id: string;
  checked: boolean;
  onChecked(newValue: boolean): void;
  linkButtonStyle?: React.CSSProperties;
}

enum ModalToShow {
  termsOfService,
  privacy,
}

const RegisterTermsOfServiceAndPrivacy: React.FunctionComponent<IProps> = ({
  id,
  checked,
  onChecked,
  linkButtonStyle,
}) => {
  const [modalToShow, setModalToShow] = useState<ModalToShow | null>(null);

  return (
    <>
      <div className="form-group">
        <div className="custom-control custom-checkbox">
          <input
            required={true}
            type="checkbox"
            className="custom-control-input branded-public-custom-control-input"
            checked={checked}
            onChange={(e) => {
              onChecked(e.target.checked);
            }}
            id={id}
            data-testid="agreeTermsCheckBox"
          />
          <label
            className="custom-control-label branded-public-custom-control-label"
            htmlFor={id}
            id={id + "-label"}
            data-testid="agreeTerms"
          >
            <span id={id + "-span"}>I agree to Crew Control's</span>{" "}
            <LinkButton
              hrefForDisplay="terms-of-service"
              onClick={() => setModalToShow(ModalToShow.termsOfService)}
              style={linkButtonStyle}
              linkText="Terms of Service"
              className="branded-public-link"
            />{" "}
            and{" "}
            <LinkButton
              hrefForDisplay="privacy-policy"
              onClick={() => setModalToShow(ModalToShow.privacy)}
              style={linkButtonStyle}
              linkText="Privacy Policy"
              className="branded-public-link"
            />
          </label>
        </div>
      </div>

      {modalToShow !== null ? (
        <Modal
          isOpen={true}
          toggle={() => setModalToShow(null)}
          size={"lg"}
          scrollable={true}
        >
          <div className="modal-header">
            <div
              className="d-flex justify-content-between"
              style={{ width: "100%" }}
            >
              <h1 className="modal-title">
                {modalToShow === ModalToShow.privacy
                  ? "Privacy Policy"
                  : "Terms of Service"}
              </h1>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setModalToShow(null)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <ModalBody>
            <div style={{ fontSize: "85%" }}>
              {modalToShow === ModalToShow.privacy ? (
                <PrivacyPolicy hideHeader={true} />
              ) : (
                <TermsOfService />
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-primary branded-public-buttons"
              onClick={() => setModalToShow(null)}
            >
              Close
            </button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
};

export default RegisterTermsOfServiceAndPrivacy;
