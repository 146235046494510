import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { ICrew } from "../../../models/ICrew";
import { IDistance } from "../../../models/IDistance";
import { areDistancesEqual } from "../../../services/distanceService";
import { getJobDistances } from "../../../services/jobDistances";
import { doesJobMissingLocationExist } from "../services/doesJobMissingLocationExist";
import { actionCreators } from "../../../modules/actionCreators";

export function useLoadDistances({
  dayScheduleId,
  mode,
  isMapVisible,
  crew,
}: {
  dayScheduleId: string;
  mode: string;
  isMapVisible: boolean;
  crew: ICrew;
}) {
  const dispatch = useDispatch();

  const [hasJobsMissingLocationsOuter, setHasJobsMissingLocations] =
    useState(false);

  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );
  const jobs = useApplicationStateSelector((s) => s.job.jobs);
  const oneTimeJobs = useApplicationStateSelector((s) => s.job.oneTimeJobs);
  const distances = useApplicationStateSelector(
    (s) => s.distanceCache.distances
  );
  const headersVisible = useApplicationStateSelector(
    (s) => s.scheduleUi.headersVisible
  );

  const hasCrewMissingLocation = !crew.latitude || !crew.longitude;
  useEffect(() => {
    const isHeaderVisible = !!headersVisible.find(
      (v) => v.dayScheduleId === dayScheduleId && v.mode === mode
    );

    const daySchedule = daySchedules.find(
      (daySchedule) => daySchedule.id === dayScheduleId
    );
    if (daySchedule) {
      const filteredJobInstances = daySchedule.jobInstances;

      let hasJobsMissingLocations = doesJobMissingLocationExist({
        filteredJobInstances,
        jobs,
        oneTimeJobs,
        customers,
        customerAdditionalLocations,
      });

      if (hasJobsMissingLocations !== hasJobsMissingLocationsOuter) {
        setHasJobsMissingLocations(hasJobsMissingLocations);
      }

      if (!isMapVisible && !isHeaderVisible) {
        return;
      }

      if (hasJobsMissingLocations || hasCrewMissingLocation) {
        return;
      }

      const addItemsToArray = (
        sourceArray: Array<IDistance>,
        destinationArray: Array<IDistance>
      ) => {
        sourceArray.forEach((source) => destinationArray.push(source));
      };

      let distancesToLoad: Array<IDistance> = [];
      addItemsToArray(
        getJobDistances(
          filteredJobInstances,
          jobs,
          oneTimeJobs,
          customers,
          crew.latitude,
          crew.longitude,
          customerAdditionalLocations
        ),
        distancesToLoad
      );
      addItemsToArray(
        getJobDistances(
          filteredJobInstances.filter((ji) => !ji.skipped),
          jobs,
          oneTimeJobs,
          customers,
          crew.latitude,
          crew.longitude,
          customerAdditionalLocations
        ),
        distancesToLoad
      );

      distancesToLoad = distancesToLoad.reduce(
        (unique: Array<IDistance>, item: IDistance) =>
          !!unique.find((u) => areDistancesEqual(u, item))
            ? unique
            : [...unique, item],
        []
      );

      const missingDistances = distancesToLoad.filter((dl) => {
        const existingDistance = distances.find((d) =>
          areDistancesEqual(d, dl)
        );
        return !existingDistance;
      });

      const missingDistancesWithAllFields = missingDistances.filter(
        (dl) =>
          dl.sourceLatitude &&
          dl.sourceLongitude &&
          dl.destinationLatitude &&
          dl.destinationLongitude
      );

      if (missingDistancesWithAllFields.length > 0) {
        dispatch(
          actionCreators.loadDistancesStarting(missingDistancesWithAllFields)
        );
      }
    }
  }, [
    headersVisible,
    daySchedules,
    hasJobsMissingLocationsOuter,
    isMapVisible,
    hasCrewMissingLocation,
    dayScheduleId,
    mode,
    jobs,
    oneTimeJobs,
    customers,
    customerAdditionalLocations,
    crew.latitude,
    crew.longitude,
    distances,
    dispatch,
  ]);

  return { hasJobsMissingLocationsOuter, hasCrewMissingLocation };
}
