import { useState } from "react";
import { useDispatch } from "react-redux";
import { DiscountType } from "../../../enums/DiscountType";
import { JobBillingType } from "../../../enums/jobBillingType";
import { actionCreators } from "../../../formGenerator";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { useContractBillingEnabled } from "../../../hooks/useContractBillingEnabled";
import { useCreditCardsEnabled } from "../../../hooks/useCreditCardsEnabled";
import { ICustomer } from "../../../models/ICustomer";
import { IDiscount } from "../../../models/IDiscount";
import { IInvoiceItem } from "../../../models/IInvoiceItem";
import { formatCurrency } from "../../../services/currencyFormatter";
import customerFinder from "../../../services/customerFinder";
import { getSubtotal, getTotal } from "../../../services/lineItemService";
import { isPaymentMethodOnFileAuthorizedVisible } from "../../../services/paymentMethodOnFileService";
import InvoiceFormAmounts from "../forms/InvoiceFormAmounts";
import CurrencyInput from "./CurrencyInput";
import InfoToolTip from "./InfoToolTip";
import { ILineItem } from "./InvoiceLineItem";
import InvoiceLineItems from "./InvoiceLineItems";
import {
  areDiscountAndTaxRateEnabled,
  getFrequency,
} from "./JobBillingConfiguration.functions";
import PaymentMethodOnFileDisclosure from "./PaymentMethodOnFileDisclosure";

export enum BillingTypeDropDownOptions {
  PerService = 0,
  ContractBilling = 1,
  AtProjectCompletion = 3,
}

type JobBillingConfigurationValues = {
  lineItems: Array<ILineItem>;
  billingType: JobBillingType;
  grossRevenuePerVisit: string;
  taxRate: number | null;
  discount: IDiscount;
  paymentMethodOnFileAuthorized: boolean;
};

interface IProps {
  customerId: string | null;
  values: JobBillingConfigurationValues;
  onChange: (newValue: JobBillingConfigurationValues) => void;
  showAtProjectCompletion?: boolean;
  perServiceLabel?: string;
  billingTypeDisabled?: boolean;
  taxRateAlreadySet: boolean;
}

const wrapBreakpoint = "lg";

const JobBillingConfiguration: React.FunctionComponent<IProps> = ({
  values,
  customerId,
  onChange,
  showAtProjectCompletion,
  perServiceLabel,
  billingTypeDisabled,
  taxRateAlreadySet,
}) => {
  const { billingType, grossRevenuePerVisit, lineItems, taxRate, discount } =
    values;

  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const { areCreditCardsEnabled } = useCreditCardsEnabled();

  const [invoiceItems, setInvoiceItems] = useState<Array<IInvoiceItem> | null>(
    null
  );

  let customer: ICustomer | null = null;
  if (customerId) {
    customer =
      customerFinder.getOptionalCustomerById(customerId, customers) ?? null;
  }

  const billingTypeDropDownValue = getBillingTypeDropDownOptions(billingType);

  return (
    <>
      <div className="form-row">
        <div
          className={`${
            billingTypeDropDownValue ===
            BillingTypeDropDownOptions.ContractBilling
              ? `mb-2 mb-${wrapBreakpoint}-3`
              : "mb-3"
          } col-12 col-${wrapBreakpoint}-7`}
        >
          <ServiceBillingTypeSelection
            billingTypeDropDownValue={billingTypeDropDownValue}
            showAtProjectCompletion={showAtProjectCompletion}
            perServiceLabel={perServiceLabel}
            billingTypeDisabled={billingTypeDisabled}
            onBillingTypeDropDownChange={(newValue) => {
              if (newValue === BillingTypeDropDownOptions.ContractBilling) {
                onChange({
                  ...values,
                  billingType: JobBillingType.ContractBilling,
                });
              } else if (
                newValue === BillingTypeDropDownOptions.AtProjectCompletion
              ) {
                onChange({
                  ...values,
                  billingType:
                    lineItems.length > 0
                      ? JobBillingType.AtProjectCompletionLineItems
                      : JobBillingType.AtProjectCompletionTotal,
                });
              } else if (newValue === BillingTypeDropDownOptions.PerService) {
                onChange({
                  ...values,
                  billingType:
                    lineItems.length > 0
                      ? JobBillingType.PerServiceLineItems
                      : JobBillingType.PerServiceTotal,
                });
              }
            }}
          />
        </div>
        <div className={`mb-3 col-12 col-${wrapBreakpoint}-5`}>
          {billingTypeDropDownValue === BillingTypeDropDownOptions.PerService ||
          billingTypeDropDownValue ===
            BillingTypeDropDownOptions.AtProjectCompletion ? (
            <PerServiceDetails
              grossRevenuePerVisit={grossRevenuePerVisit}
              onGrossRevenuePerVisitChange={(newValue) => {
                onChange({
                  ...values,
                  grossRevenuePerVisit: newValue,
                });
              }}
              disabled={
                billingType !== JobBillingType.PerServiceTotal &&
                billingType !== JobBillingType.AtProjectCompletionTotal
              }
            />
          ) : null}

          {billingTypeDropDownValue ===
          BillingTypeDropDownOptions.ContractBilling ? (
            <MonthlyContractDetails customer={customer} />
          ) : null}
        </div>
      </div>
      {billingTypeDropDownValue === BillingTypeDropDownOptions.PerService ||
      billingTypeDropDownValue ===
        BillingTypeDropDownOptions.AtProjectCompletion ? (
        <>
          <div className="form-group">
            <InvoiceLineItems
              elementIdPrefix="jobBillingConfiguration"
              invoiceItems={invoiceItems}
              lineItems={lineItems}
              onClearErrorMessage={() => {}}
              setInvoiceItems={(newValue) => {
                setInvoiceItems(newValue);
              }}
              setLineItems={(newValue) => {
                onChange({
                  ...values,
                  lineItems: newValue,
                  grossRevenuePerVisit:
                    newValue.length > 0
                      ? getSubtotal(newValue).toString()
                      : values.grossRevenuePerVisit,
                  billingType:
                    billingTypeDropDownValue ===
                    BillingTypeDropDownOptions.PerService
                      ? newValue.length > 0
                        ? JobBillingType.PerServiceLineItems
                        : JobBillingType.PerServiceTotal
                      : newValue.length > 0
                      ? JobBillingType.AtProjectCompletionLineItems
                      : JobBillingType.AtProjectCompletionTotal,
                });
              }}
              allowZeroLineItems
              noLineItemMessage="Added line items will automatically be included when invoiced."
              customerTaxExempt={customer?.taxExempt ?? false}
              taxRateAlreadySet={taxRateAlreadySet}
            />
          </div>

          {areDiscountAndTaxRateEnabled(billingType) ? (
            <div className="form-group">
              <InvoiceFormAmounts
                customerTaxExempt={customer?.taxExempt ?? false}
                taxRateAlreadySet={taxRateAlreadySet}
                lineItems={lineItems}
                taxRate={taxRate}
                onTaxRateChange={(newTaxRate) =>
                  onChange({
                    ...values,
                    taxRate: newTaxRate,
                  })
                }
                discountFields={{
                  discount: discount ?? {
                    type: DiscountType.amount,
                    amount: 0,
                    percent: null,
                  },
                  onChange: (newDiscount) => {
                    onChange({
                      ...values,
                      discount: newDiscount,
                    });
                  },
                  amountOnly: true,
                }}
              />
            </div>
          ) : null}
        </>
      ) : null}

      {isPaymentMethodOnFileAuthorizedVisible(
        areCreditCardsEnabled,
        customer
      ) && billingType !== JobBillingType.ContractBilling ? (
        <PaymentMethodOnFileDisclosure
          paymentMethodNumberToInclude={customer.paymentMethod.partialNumber}
          fontSize="md"
          required={false}
          paymentMethodOnFileAuthorized={values.paymentMethodOnFileAuthorized}
          setPaymentMethodOnFileAuthorized={(newValue) =>
            onChange({
              ...values,
              paymentMethodOnFileAuthorized: newValue,
            })
          }
        />
      ) : null}
    </>
  );
};

export default JobBillingConfiguration;

function getBillingTypeDropDownOptions(
  billingType: JobBillingType
): BillingTypeDropDownOptions {
  switch (billingType) {
    case JobBillingType.AtProjectCompletionTotal:
      return BillingTypeDropDownOptions.AtProjectCompletion;
    case JobBillingType.AtProjectCompletionLineItems:
      return BillingTypeDropDownOptions.AtProjectCompletion;
    case JobBillingType.ContractBilling:
      return BillingTypeDropDownOptions.ContractBilling;
    case JobBillingType.PerServiceLineItems:
      return BillingTypeDropDownOptions.PerService;
    case JobBillingType.PerServiceTotal:
      return BillingTypeDropDownOptions.PerService;
    default:
      const exhaustiveCheck: never = billingType;
      return exhaustiveCheck;
  }
}

function ServiceBillingTypeSelection({
  billingTypeDropDownValue,
  onBillingTypeDropDownChange,
  showAtProjectCompletion,
  perServiceLabel = "Per service",
  billingTypeDisabled,
}: {
  billingTypeDropDownValue: BillingTypeDropDownOptions;
  onBillingTypeDropDownChange: (newValue: BillingTypeDropDownOptions) => void;
  showAtProjectCompletion?: boolean;
  perServiceLabel?: string;
  billingTypeDisabled?: boolean;
}) {
  return (
    <>
      <div className="d-flex align-items-baseline">
        <label htmlFor="billType">How is this service billed?</label>
        {billingTypeDropDownValue ===
        BillingTypeDropDownOptions.ContractBilling ? (
          <InfoToolTip
            title="Included in contract billing details"
            id="includedInMaintenanceContractToolTip"
            text="These jobs will not pull into the billing center. You will want to invoice this customer separately for their monthly maintenance fee."
          />
        ) : null}
        {billingTypeDropDownValue === BillingTypeDropDownOptions.PerService ? (
          <InfoToolTip
            title={perServiceLabel}
            id="perServiceLineItemsToolTip"
            text="Entering line items allows this job to be batch invoiced and the line items to be defaulted into the invoice form."
          />
        ) : null}
      </div>
      <select
        className="form-control"
        id="billType"
        value={billingTypeDropDownValue.toString()}
        onChange={(e) => {
          onBillingTypeDropDownChange(parseInt(e.currentTarget.value));
        }}
        disabled={billingTypeDisabled}
      >
        <option value={BillingTypeDropDownOptions.PerService.toString()}>
          {perServiceLabel}
        </option>
        <option value={BillingTypeDropDownOptions.ContractBilling.toString()}>
          Included in contract billing
        </option>
        {showAtProjectCompletion ? (
          <option
            value={BillingTypeDropDownOptions.AtProjectCompletion.toString()}
          >
            At project completion
          </option>
        ) : null}
      </select>
    </>
  );
}

function PerServiceDetails({
  grossRevenuePerVisit,
  onGrossRevenuePerVisitChange,
  disabled,
}: {
  grossRevenuePerVisit: string;
  onGrossRevenuePerVisitChange: (newValue: string) => void;
  disabled: boolean;
}) {
  return (
    <>
      <label htmlFor="grossRevenuePerVisit">Amount</label>
      <CurrencyInput
        id="grossRevenuePerVisit"
        className="form-control"
        name="grossRevenuePerVisit"
        value={grossRevenuePerVisit}
        onValueChange={(newValue) => onGrossRevenuePerVisitChange(newValue)}
        disabled={disabled}
      />
    </>
  );
}

function MonthlyContractDetails({ customer }: { customer: ICustomer | null }) {
  const dispatch = useDispatch();
  const contractBillingEnabled = useContractBillingEnabled();

  if (!customer) {
    return null;
  }

  if (!contractBillingEnabled) {
    return null;
  }

  if (customer.paymentConfiguration) {
    return (
      <>
        <label className="d-none d-lg-block">&nbsp;</label>
        <div>
          <button
            style={{ padding: 0 }}
            className={`btn btn-link pt-${wrapBreakpoint}-1`}
            type="button"
            onClick={() =>
              dispatch(
                actionCreators.customerContractBilling.showForm({
                  customerId: customer.id,
                })
              )
            }
          >
            {`${formatCurrency(
              getTotal({
                taxRate: customer.paymentConfiguration.taxRate,
                lineItems: customer.paymentConfiguration.lineItems ?? [],
              })
            )} / ${getFrequency(customer.paymentConfiguration)}`}
          </button>
        </div>
      </>
    );
  } else {
    return (
      <>
        <label className="d-none d-lg-block">&nbsp;</label>
        <div>
          <button
            className="btn btn-secondary btn-sm"
            type="button"
            onClick={() =>
              dispatch(
                actionCreators.customerContractBilling.showForm({
                  customerId: customer.id,
                })
              )
            }
          >
            Configure billing
          </button>
        </div>
      </>
    );
  }
}
