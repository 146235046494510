import { of } from "rxjs";
import { catchError, map, timeout } from "rxjs/operators";
import dataProvider from "../../services/dataProvider";
import { getErrorMessageFromError } from "../../services/httpErrorHandler";
import { actionCreators } from "../actionCreators";
import { IPermanentJobsSaveStart } from "../actionTypeDefinitions";

export function handlePermanentJobsSaveStart(action: IPermanentJobsSaveStart) {
  return dataProvider.moveMaintenanceJob(action).pipe(
    timeout(30000),
    map((result: any) => {
      return actionCreators.completeSavePermanentJobs(
        result.data.updatedDaySchedules,
        result.data.updatedFlexibleJobWeeks,
        action.maintenanceJobIds
      );
    }),
    catchError((error) => {
      const validationError = error.status === 400;
      return of(
        actionCreators.errorSavePermanentJobs({
          validationError,
          validationErrorMessage: validationError
            ? getErrorMessageFromError(error)
            : null,
        })
      );
    })
  );
}
