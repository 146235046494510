import parse from "date-fns/parse";
import { IScheduleColumn } from "./types/IScheduleColumn";
import { MinuteWindowOptions, emptyCellClass } from "./Calendar";
import {
  getWindowThresholds,
  isTimeInSlot,
} from "../services/timeBlockCalculator";
import {
  DayScheduleWithJobInstancesInTimeSlots,
  JobInstanceWithTimeSlot,
} from "./ScheduleTimeCalendar.types";
import { useState } from "react";
import { ScheduleTimeCalendarJobCard } from "./ScheduleTimeCalendarJobCard";
import { getBlocksFromCalendarEnd } from "./ScheduleTimeCalendarTimeSlot.functions";
import { getSortedItemsV2 } from "../../../services/sortingService";

export function ScheduleTimeCalendarTimeSlot({
  column,
  hour,
  minuteWindow,
  calendarBlockHeightInPxs,
  daySchedulesWithJobsInTimeSlots,
  lastHour,
  onStartMove,
  movingJobInstanceId,
  isAnyJobMoveInProgress,
  jobInstanceShowingDetails,
  setJobInstanceShowingDetails,
  jobInstanceShowingContextMenu,
  setJobInstanceShowingContextMenu,
  hasJobMovedPosition,
}: {
  column: IScheduleColumn;
  hour: number;
  minuteWindow: MinuteWindowOptions;
  calendarBlockHeightInPxs: number;
  daySchedulesWithJobsInTimeSlots: Array<DayScheduleWithJobInstancesInTimeSlots>;
  lastHour: number | null;
  onStartMove: (jobInstanceId: string, blockOffset: number) => void;
  movingJobInstanceId: string | null;
  isAnyJobMoveInProgress: boolean;
  jobInstanceShowingDetails: string | null;
  setJobInstanceShowingDetails: (newValue: string | null) => void;
  jobInstanceShowingContextMenu: string | null;
  setJobInstanceShowingContextMenu: (newValue: string | null) => void;
  hasJobMovedPosition: boolean;
}) {
  const [jobInstanceIdBeingResized, setJobInstanceIdBeingResized] = useState<
    string | null
  >(null);

  const daySchedule = daySchedulesWithJobsInTimeSlots.find(
    (ds) => ds.id === column.dayScheduleId
  );

  let dayScheduleDate: Date | null = null;
  let jobInstancesInRange: Array<JobInstanceWithTimeSlot> = [];
  let timeSlotStartTime: Date | null = null;
  if (daySchedule) {
    dayScheduleDate = parse(daySchedule.date);
    if (dayScheduleDate) {
      const { startThreshold, endThreshold } = getWindowThresholds(
        dayScheduleDate,
        hour,
        minuteWindow
      );

      timeSlotStartTime = startThreshold;

      jobInstancesInRange = getJobInstancesInTimeRange({
        jobInstancesInRange,
        daySchedule,
        startThreshold,
        endThreshold,
      });
    }
  }

  const containsJobMoving = jobInstancesInRange.some(
    (r) => r.isDragPlaceholderJob
  );

  // Need to sort by slot to ensure spacerDivs are handled properly in ScheduleTimeCalendarJobCard
  const jobInstancesToShow = getSortedItemsV2(
    jobInstancesInRange.filter((jobInstanceWithTimeSlot) => {
      if (movingJobInstanceId !== null && containsJobMoving) {
        return jobInstanceWithTimeSlot.isDragPlaceholderJob;
      } else if (jobInstanceIdBeingResized !== null) {
        return (
          jobInstanceWithTimeSlot.jobInstance.id === jobInstanceIdBeingResized
        );
      } else {
        return true;
      }
    }),
    ["slot"]
  );

  const hasJobDragging = jobInstancesInRange.some(
    (ji) => ji.isDragPlaceholderJob
  );

  const blocksFromCalendarEnd = getBlocksFromCalendarEnd({
    hour,
    minuteWindow,
    lastHour,
  });

  return (
    <div className="d-flex" data-testid="timeslot">
      <div
        className="d-flex"
        style={{
          width: "100%",
          // minWidth of 0 is needed so the flex item doesn't overflow parent container.
          // This would prevent the 20px spacer from being visible.
          minWidth: 0,
        }}
      >
        {dayScheduleDate
          ? // Filter to only show job being dragged so it can take the full width
            jobInstancesToShow.map((jobInstanceWithTimeSlot, index) => {
              return (
                <ScheduleTimeCalendarJobCard
                  key={
                    jobInstanceWithTimeSlot.jobInstance.id +
                    (jobInstanceWithTimeSlot.isDragPlaceholderJob
                      ? "_drag"
                      : "")
                  }
                  jobInstanceWithTimeSlot={jobInstanceWithTimeSlot}
                  calendarBlockHeightInPxs={calendarBlockHeightInPxs}
                  missingTime={false}
                  hasJobMovedPosition={hasJobMovedPosition}
                  jobIndex={index}
                  onResizeStart={() =>
                    setJobInstanceIdBeingResized(
                      jobInstanceWithTimeSlot.jobInstance.id
                    )
                  }
                  onResizeEnd={() => setJobInstanceIdBeingResized(null)}
                  timeSlotStartTime={timeSlotStartTime}
                  blocksFromCalendarEnd={blocksFromCalendarEnd}
                  onStartMove={onStartMove}
                  isDragging={
                    movingJobInstanceId ===
                    jobInstanceWithTimeSlot.jobInstance.id
                  }
                  isAnyJobMoveInProgress={isAnyJobMoveInProgress}
                  crew={column.crew}
                  date={column.date}
                  jobInstanceShowingDetails={jobInstanceShowingDetails}
                  setJobInstanceShowingDetails={setJobInstanceShowingDetails}
                  isDragPlaceholderJob={
                    jobInstanceWithTimeSlot.isDragPlaceholderJob
                  }
                  jobInstanceShowingContextMenu={jobInstanceShowingContextMenu}
                  setJobInstanceShowingContextMenu={
                    setJobInstanceShowingContextMenu
                  }
                />
              );
            })
          : null}
      </div>
      {jobInstanceIdBeingResized === null && !hasJobDragging ? (
        <div
          data-testid="slotSpacerForAddingJob"
          className={emptyCellClass}
          style={{ width: "20px" }}
        ></div>
      ) : null}
    </div>
  );
}

function getJobInstancesInTimeRange({
  jobInstancesInRange,
  daySchedule,
  startThreshold,
  endThreshold,
}: {
  jobInstancesInRange: JobInstanceWithTimeSlot[];
  daySchedule: DayScheduleWithJobInstancesInTimeSlots;
  startThreshold: Date;
  endThreshold: Date;
}) {
  jobInstancesInRange = daySchedule.jobInstancesInTimeSlots.filter(
    (jobInstanceInTimeSlot) => {
      if (!jobInstanceInTimeSlot.startTime || !jobInstanceInTimeSlot.endTime) {
        return false;
      }

      return isTimeInSlot({
        startThreshold,
        endThreshold,
        dateTimeToCheck: jobInstanceInTimeSlot.startTime,
      });
    }
  );
  return jobInstancesInRange;
}
