import auth from "../../../services/Auth";
import { UserAccountForm } from "./UserAccountForm";

export function AccountCreate({
  onSuccess,
  onStartCreate,
  defaultEmailAddress,
}: {
  onSuccess?: () => void;
  onStartCreate?: () => void;
  defaultEmailAddress?: string;
}) {
  return (
    <UserAccountForm
      buttonText="Create account"
      defaultEmailAddress={defaultEmailAddress}
      accountCreation={true}
      onSubmit={({ email, password, onError }) =>
        auth.signupAndAuthorize({
          email,
          password,
          onError: (message) => {
            onError(message);
          },
          onSuccess,
        })
      }
      onStartCreate={onStartCreate}
    />
  );
}
