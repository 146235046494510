import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function WrappedCreatableSelectOption({
  name,
  isNewItem,
  recordType,
}: {
  name: string;
  isNewItem: boolean;
  recordType: string;
}) {
  return (
    <>
      {isNewItem ? (
        <span data-testid="addNewItem">
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          {name ? "Add " : `Add new ${recordType}`}
        </span>
      ) : null}
      <span data-testid="itemOption">{name}</span>
    </>
  );
}
