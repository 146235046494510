import React, { useState } from "react";
import { DateFilterOptions } from "../../../enums/dateFilterOptions";
import DateFilterHeadless from "./DateFilterHeadless";

interface IDateFilters {
  frequency: DateFilterOptions;
  startingDate: string | null;
  endingDate: string | null;
}

interface IProps {
  filters: IDateFilters;
  onFiltersChanged: (filters: IDateFilters) => void;
  showAllDates?: boolean;
  showEndingDateBeforeStartingDateError?: boolean;
}

const customDateWidth = "120px";
const DateFilter: React.FunctionComponent<IProps> = ({
  filters,
  onFiltersChanged,
  showAllDates,
  showEndingDateBeforeStartingDateError,
}) => {
  const [dateFilterErrorMessage, setDateFilterErrorMessage] = useState("");

  return (
    <DateFilterHeadless
      filters={filters}
      onFiltersChanged={onFiltersChanged}
      showAllDates={showAllDates}
      setErrorMessage={(m) => setDateFilterErrorMessage(m)}
    >
      {({ dateRangeElements, customDateElements }) => (
        <React.Fragment>
          <div>
            <div style={{ minWidth: "250px" }}>{dateRangeElements}</div>
          </div>
          {customDateElements !== null ? (
            <>
              <div
                className="mt-3"
                style={{
                  display: "flex",
                  columnGap: "10px",
                }}
              >
                <div style={{ width: customDateWidth }}>
                  {customDateElements.startingDateElement}
                </div>
                <div style={{ width: customDateWidth }}>
                  {customDateElements.endingDateElement}
                </div>
              </div>
              {showEndingDateBeforeStartingDateError &&
              dateFilterErrorMessage ? (
                <div className="text-danger">{dateFilterErrorMessage}</div>
              ) : null}
            </>
          ) : null}
        </React.Fragment>
      )}
    </DateFilterHeadless>
  );
};

export default DateFilter;
