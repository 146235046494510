import { Action } from "@reduxjs/toolkit";
import { Epic } from "redux-observable";
import { catchError, filter, map, mergeMap, timeout } from "rxjs/operators";
import opportunityDataProvider from "../../services/opportunityDataProvider";
import { opportunitiesActionCreators } from "../opportunity";
import { IApplicationState } from "../../../../modules";
import { of } from "rxjs";

const handleOpportunityDrop: Epic<Action, Action, IApplicationState> = (
  action,
  state$
) =>
  action.pipe(
    filter(opportunitiesActionCreators.dropOpportunity.match),
    mergeMap((action) => {
      return opportunityDataProvider
        .moveOpportunity(
          action.payload.opportunityId,
          action.payload.precedingOpportunityId,
          action.payload.status
        )
        .pipe(
          timeout(30000),
          map(() =>
            opportunitiesActionCreators.dropOpportunityCompleted({
              opportunityId: action.payload.opportunityId,
            })
          ),
          catchError(() =>
            of(
              opportunitiesActionCreators.dropOpportunityError({
                opportunityId: action.payload.opportunityId,
              })
            )
          )
        );
    })
  );

export default handleOpportunityDrop;
