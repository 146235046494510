import { useEffect } from "react";

export function useSetPrintPageStyles(pageSize: string, margin: string) {
  useEffect(() => {
    setPageSize(pageSize, margin);
    return () => {
      const child = document.getElementById("page-orientation");
      if (child?.parentNode) {
        child.parentNode.removeChild(child);
      }
    };
  }, [pageSize, margin]);
}

function setPageSize(pageSize: string, margin: string) {
  const style = document.createElement("style");
  style.innerHTML = `@page {size: ${pageSize}; margin: ${margin};}`;
  style.id = "page-orientation";
  document.head.appendChild(style);
}
