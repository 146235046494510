import React, { CSSProperties } from "react";

interface IProps {
  label: string;
  value: React.ReactNode;
  testId?: string;
  style?: Partial<CSSProperties>;
  containerClassName?: string;
}

const LabelledField: React.FunctionComponent<IProps> = ({
  label,
  value,
  testId,
  style,
  containerClassName,
}) => {
  return (
    <div
      style={{
        marginRight: "50px",
        ...(style ?? {}),
      }}
      className={containerClassName}
    >
      <div className="font-weight-light">{label}:</div>
      <div data-testid={testId} className="text-truncate">
        {value}
      </div>
    </div>
  );
};

export default LabelledField;
