export default function NonRefundableWarning() {
  return (
    <div className="text-info">
      <small>
        This purchase is non-refundable. Be sure to utilize your free trial
        before continuing.
      </small>
    </div>
  );
}
