import OpportunityCardContainer from "./OpportunityCardContainer";

const NoOpportunitiesCard: React.FunctionComponent<{}> = () => {
  return (
    <OpportunityCardContainer>
      <div className="text-center font-weight-bold">No Opportunities</div>
    </OpportunityCardContainer>
  );
};

export default NoOpportunitiesCard;
