import { useDispatch } from "react-redux";
import { opportunitiesActionCreators } from "../modules/opportunity";

const OpportunitiesLoadError: React.FunctionComponent<{}> = () => {
  const dispatch = useDispatch();

  return (
    <div>
      <h1>Opportunities Could Not Be Loaded</h1>
      <div>Please check your Internet connection and try again.</div>
      <div>
        <button
          className="btn btn-secondary"
          type="button"
          onClick={() =>
            dispatch(opportunitiesActionCreators.loadOpportunitiesStart())
          }
        >
          Retry
        </button>
      </div>
    </div>
  );
};

export default OpportunitiesLoadError;
