import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { timeout, mergeMap } from "rxjs/operators";
import { formatCurrency } from "../../../services/currencyFormatter";
import { Observable, race, timer } from "rxjs";
import invoiceDataProvider from "../../../slices/billing/services/invoiceDataProvider";

interface IProps {
  customerId: string;
}

const CustomerBalanceWarning: React.FunctionComponent<IProps> = ({
  customerId,
}) => {
  const [hasOutstandingBalance, setHasOutstandingBalance] = useState(false);
  const [outstandingBalance, setOutstandingBalance] = useState<number | null>(
    0.0
  );

  function loadWithRetries<T>(loadData: () => Observable<T>) {
    return race(
      loadData(),
      timer(2000).pipe(mergeMap(() => loadData())),
      timer(4000).pipe(mergeMap(() => loadData()))
    ).pipe(timeout(7000));
  }

  useEffect(() => {
    const subscription = loadWithRetries(() =>
      invoiceDataProvider.getCustomerBalance(customerId)
    ).subscribe(
      (i) => {
        setHasOutstandingBalance(i.hasBalance);
        setOutstandingBalance(i.balance);
      },
      () => {}
    );

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [customerId]);

  return (
    <>
      {hasOutstandingBalance ? (
        <div
          className="alert-warning mt-1"
          style={{
            border: "1px",
            padding: "0rem .25rem",
            borderRadius: ".25rem",
          }}
        >
          <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />
          <small>
            {outstandingBalance === null
              ? "Customer has an outstanding balance"
              : `Outstanding balance: ${formatCurrency(outstandingBalance)}`}
          </small>
        </div>
      ) : null}
    </>
  );
};

export default CustomerBalanceWarning;
