import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { timeout } from "rxjs/operators";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { actionCreators } from "../../../../modules/actionCreators";
import remoteDataProvider from "../../../../services/remoteDataProvider";
import Spinner from "../Spinner";

interface IProps {}

const AutoRoutePrompt: React.FunctionComponent<IProps> = () => {
  const dispatch = useDispatch();
  const autoRoutePrompt = useApplicationStateSelector(
    (s) => s.scheduleUi.autoRoutePrompt
  );
  const autoRoutePromptError = useApplicationStateSelector(
    (s) => s.scheduleUi.autoRoutePromptError
  );
  const autoRoutePromptSaving = useApplicationStateSelector(
    (s) => s.scheduleUi.autoRoutePromptSaving
  );
  const autoRouteAllowReverse = useApplicationStateSelector(
    (s) => s.scheduleUi.autoRouteAllowReverse
  );

  const [reverseSaving, setReverseSaving] = useState(false);
  const [errorSavingReverse, setErrorSavingReverse] = useState(false);
  const [hideReverse, setHideReverse] = useState(false);

  if (!autoRoutePrompt) {
    return null;
  }

  return (
    <React.Fragment>
      {autoRoutePromptSaving || reverseSaving ? <Spinner /> : null}

      <div className="board-sticky-banner" role="alert">
        <div className="alert alert-dismissible fade show alert-info">
          <div>Route is optimized!</div>
          <div>
            {!autoRoutePromptError
              ? "Apply changes to future weeks?"
              : "An error occurred while making this change permanent. Would you like to try again?"}
            <button
              className="btn btn-link schedule-banner-confirm-button"
              onClick={() => {
                dispatch(actionCreators.autoRouteJobsPermanentStarting());
              }}
            >
              Yes
            </button>
            <button
              className="btn btn-link schedule-banner-confirm-button"
              onClick={() =>
                dispatch(actionCreators.autoRouteJobsPermanentClear())
              }
            >
              No
            </button>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={() =>
                dispatch(actionCreators.autoRouteJobsPermanentClear())
              }
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {autoRouteAllowReverse && !hideReverse ? (
            <div>
              {!errorSavingReverse
                ? "Reverse route?"
                : "An error occurred reversing the route. Would you like to try again?"}
              <button
                className="btn btn-link schedule-banner-confirm-button"
                onClick={() => {
                  setReverseSaving(true);
                  setErrorSavingReverse(false);

                  const reversedJobInstanceIds = autoRoutePrompt.jobInstanceIds
                    .reverse()
                    .map((jobInstanceId, index) => ({
                      jobInstanceId,
                      newOrder: index,
                    }));
                  const reversedMaintenanceJobs =
                    autoRoutePrompt.maintenanceJobIds.reverse();
                  remoteDataProvider
                    .saveAutoRoutedJobs({
                      dayScheduleId: autoRoutePrompt.dayScheduleId,
                      updatedJobs: reversedJobInstanceIds,
                    })
                    .pipe(timeout(15000))
                    .subscribe({
                      next: () => {
                        setReverseSaving(false);

                        dispatch(
                          actionCreators.autoRouteJobsCompleted(
                            autoRoutePrompt.dayScheduleId,
                            reversedJobInstanceIds.map(
                              (orderedJob) => orderedJob.jobInstanceId
                            ),
                            reversedMaintenanceJobs,
                            false
                          )
                        );
                      },

                      error: () => {
                        setReverseSaving(false);
                        setErrorSavingReverse(true);
                      },
                    });
                }}
              >
                Yes
              </button>
              <button
                className="btn btn-link schedule-banner-confirm-button"
                onClick={() => setHideReverse(true)}
              >
                No
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AutoRoutePrompt;
