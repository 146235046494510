export default function ErrorJumbotron({
  details,
  showRefreshButton,
  headerText,
  refreshButtonText,
}: {
  details: React.ReactNode;
  showRefreshButton?: boolean;
  headerText?: string;
  refreshButtonText?: string;
}) {
  return (
    <div className="container pt-5">
      <div className="jumbotron">
        <h1 className="display-4">
          {headerText ?? "Oops, something went wrong..."}
        </h1>
        <div className="lead">{details}</div>
        {showRefreshButton ? (
          <div className="mt-3">
            <button
              className="btn btn-primary"
              onClick={() => {
                window.location.reload();
              }}
            >
              {refreshButtonText ?? "Try Again"}
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
