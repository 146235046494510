import React from "react";
import FrequencySelection, { IFrequency } from "./FrequencySelection";
import { MaintenanceJobFrequency } from "../../../models/IMaintenanceJob";
import { isWeeklyOrMonthlySchedule } from "../../../services/frequencyService";

interface IProps {
  frequency: IFrequency;
  seasonalScheduleFrequency: IFrequency;
  onChange: (seasonalScheduleFrequency: IFrequency) => void;
  daysOfWeek: Array<string>;
}

const FrequencySelectionSeasonal: React.SFC<IProps> = ({
  frequency,
  seasonalScheduleFrequency,
  onChange,
  daysOfWeek,
}) => {
  return (
    <FrequencySelection
      id="seasonalScheduleFrequency"
      label="Non-peak season frequency"
      value={seasonalScheduleFrequency}
      hideWeekly={hideWeeklyOptions(frequency.frequencyType)}
      hideEvery2Weeks={hideWeeklyOptions(frequency.frequencyType)}
      hideEvery4Weeks={hideWeeklyOptions(frequency.frequencyType)}
      hideEvery6Weeks={hideWeeklyOptions(frequency.frequencyType)}
      hideMonthly={hideWeeklyOptions(frequency.frequencyType)}
      hideCustom={
        frequency.frequencyType !== MaintenanceJobFrequency.Custom &&
        frequency.frequencyType !== MaintenanceJobFrequency.None
      }
      hideCustomDay={
        frequency.frequencyType === MaintenanceJobFrequency.Custom &&
        frequency.customFrequencyPeriod === "1"
      }
      hideCustomWeek={
        frequency.frequencyType === MaintenanceJobFrequency.Custom &&
        frequency.customFrequencyPeriod === "0"
      }
      hideInactive={false}
      hideDaily={
        frequency.frequencyType !== MaintenanceJobFrequency.Daily &&
        frequency.frequencyType !== MaintenanceJobFrequency.None
      }
      onChange={(value) => onChange(value)}
      daysOfWeek={daysOfWeek}
    />
  );
};

export default FrequencySelectionSeasonal;

function hideWeeklyOptions(frequencyToCheck: MaintenanceJobFrequency) {
  return (
    !isWeeklyOrMonthlySchedule(frequencyToCheck) &&
    frequencyToCheck !== MaintenanceJobFrequency.None
  );
}
