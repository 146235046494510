import React from "react";
import { connect } from "react-redux";
import FormContainer from "../components/FormContainer";
import { actionCreators } from "../../../modules/actionCreators";
import { IRootState } from "../../../store";
import { IJobInstance } from "../../../models/IJobInstance";
import { IDaySchedule } from "../../../models/IDaySchedule";
import { IUnscheduledMaintenanceJob } from "../../../models/IUnscheduledMaintenanceJob";

interface IProps {
  jobInstanceId: string;
  showForm: boolean;
  errorMessage: string | React.ReactNode;
  saving: boolean;
  startSave(formData: any): void;
  cancel(): void;
  setErrorMessage(errorMessage: string): void;
  daySchedules: Array<IDaySchedule>;
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>;
}

interface IState {
  formData: IFormData;
  originalFormData: IFormData;
}

interface IFormData {
  invoiceSent: boolean;
  doNotInvoice: boolean;
  invoiceNumber: string;
  purchaseOrderNumber: string;
}

class BillingDetailsForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const formData = this.getDefaultFormData();
    this.state = {
      formData,
      originalFormData: formData,
    };

    this.handleChange = this.handleChange.bind(this);
    this.getFormData = this.getFormData.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidUpdate(prevProps: IProps) {
    const props = this.props;
    if (prevProps.showForm !== this.props.showForm && props.showForm) {
      const formData = this.getDefaultFormData();

      this.setState({ formData, originalFormData: formData });
    }
  }

  getDefaultFormData() {
    const jobInstance = this.getJobInstance();
    if (jobInstance) {
      return {
        invoiceSent: jobInstance.invoiceSent,
        doNotInvoice: jobInstance.doNotInvoice,
        invoiceNumber: jobInstance.invoiceNumber || "",
        purchaseOrderNumber: jobInstance.purchaseOrderNumber || "",
      };
    } else {
      return {
        invoiceSent: false,
        doNotInvoice: false,
        invoiceNumber: "",
        purchaseOrderNumber: "",
      };
    }
  }

  handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const formDataToUpdate = { ...this.state.formData };
    (formDataToUpdate[event.target.name as keyof IFormData] as string) =
      event.target.value;
    this.setState({
      formData: formDataToUpdate,
    });
  }

  getFormData(): Partial<IJobInstance> {
    return {
      ...this.state.formData,
    };
  }

  validate() {
    return { valid: true, errorMessage: "" };
  }

  getJobInstance(): IJobInstance | undefined {
    const jobInstanceId = this.props.jobInstanceId;
    let jobInstance: IJobInstance | undefined = undefined;
    this.props.daySchedules.forEach((ds) => {
      ds.jobInstances.forEach((ji) => {
        if (ji.id === jobInstanceId) {
          jobInstance = ji;
        }
      });
    });

    if (!jobInstance) {
      this.props.weeksUnscheduledMaintenanceJobs.forEach((ds) => {
        ds.jobInstances.forEach((ji) => {
          if (ji.id === jobInstanceId) {
            jobInstance = ji;
          }
        });
      });
    }

    return jobInstance;
  }

  render() {
    const { formData } = this.state;
    const {
      showForm,
      startSave,
      cancel,
      errorMessage,
      saving,
      setErrorMessage,
    } = this.props;

    const jobInstance = this.getJobInstance();
    if (!jobInstance) {
      return null;
    }

    return (
      <FormContainer
        setErrorMessage={setErrorMessage}
        validate={this.validate}
        getFormData={this.getFormData}
        formHeader="Edit Billing Details"
        showForm={showForm}
        errorMessage={errorMessage}
        saving={saving}
        startSave={startSave}
        cancel={cancel}
        formKey="crew"
        hasFormDataChanged={() => {
          return (
            JSON.stringify(this.state.formData) !==
            JSON.stringify(this.state.originalFormData)
          );
        }}
      >
        <div className="form-group">
          <label htmlFor="purchaseOrderNumber">PO number</label>
          <input
            type="text"
            className="form-control"
            name="purchaseOrderNumber"
            id="purchaseOrderNumber"
            value={formData.purchaseOrderNumber}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="invoiceNumber">Invoice details</label>
          <input
            type="text"
            className="form-control"
            name="invoiceNumber"
            id="invoiceNumber"
            value={formData.invoiceNumber}
            onChange={this.handleChange}
          />
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="invoiceSent"
              checked={formData.invoiceSent}
              onChange={(e) => {
                let value = e.target.checked;
                this.setState({
                  formData: {
                    ...formData,
                    invoiceSent: value,
                  },
                });
              }}
            />
            <label className="custom-control-label" htmlFor="invoiceSent">
              Invoice sent
            </label>
          </div>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="doNotInvoice"
              checked={formData.doNotInvoice}
              onChange={(e) => {
                let value = e.target.checked;
                this.setState({
                  formData: {
                    ...formData,
                    doNotInvoice: value,
                  },
                });
              }}
            />
            <label className="custom-control-label" htmlFor="doNotInvoice">
              Do not invoice
            </label>
          </div>
        </div>
      </FormContainer>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  daySchedules: state.schedule.daySchedules,
  weeksUnscheduledMaintenanceJobs:
    state.schedule.weeksUnscheduledMaintenanceJobs,
  jobInstanceId: state.forms.billingDetails.parameters
    ? state.forms.billingDetails.parameters.jobInstanceId
    : null,
  showForm: state.forms.billingDetails.showForm,
  errorMessage: state.forms.billingDetails.errorMessage,
  saving: state.forms.billingDetails.saving,
});

const mapDispatchToProps = {
  startSave: actionCreators.forms.billingDetails.startSaving,
  cancel: actionCreators.forms.billingDetails.cancelForm,
  setErrorMessage: actionCreators.forms.billingDetails.setErrorMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingDetailsForm);
