const timeZones = [
  "America/New_York",
  "America/Chicago",
  "America/Denver",
  "America/Los_Angeles",
  "Pacific/Honolulu",
  "America/Anchorage",
  "America/Phoenix",
] as const;

export type TimeZone = typeof timeZones[number];

export const defaultTimeZone: TimeZone = "America/New_York";

export function parseTimeZone(input: string | undefined): TimeZone {
  if (
    typeof input === "string" &&
    (timeZones as readonly string[]).includes(input)
  ) {
    return input as TimeZone;
  }

  return defaultTimeZone;
}
