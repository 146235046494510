import { useState } from "react";
import { useDispatch } from "react-redux";
import { PopoverBody } from "reactstrap/lib";
import Popover from "reactstrap/lib/Popover";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import useIsAdmin from "../../../hooks/useIsAdmin";
import { ICategoryIdentifier } from "../../../models/ICategoryIdentifier";
import { ICrewCategory } from "../../../models/ICrewCategory";
import { actionCreators } from "../../../modules/actionCreators";
import { getCategories } from "../../../services/crewCategoryService";
import LinkButton2 from "./LinkButton2";

interface IProps {
  categories: Array<ICategoryIdentifier>;
  jobInstanceId: string;
}

const JobInstanceCategories: React.FunctionComponent<IProps> = ({
  categories,
  jobInstanceId,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const crewCategories = useApplicationStateSelector(
    (s) => s.common.crewCategories
  );
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();

  const categoriesContainerId = `jobInstanceCategories${jobInstanceId}`;

  return (
    <>
      <div
        data-testid="categoriesContainer"
        id={categoriesContainerId}
        style={{ cursor: isAdmin ? "pointer" : undefined }}
        onClick={(e) => {
          e.stopPropagation();
          setShowPopover(true);
        }}
      >
        {getCategories(categories, crewCategories).map((c) => (
          <div
            key={c.id}
            className="badge badge-info"
            style={{
              display: "inline-block",
              marginRight: "5px",
              fontSize: "90%",
              fontWeight: 400,
              backgroundColor: getCategoryBackgroundColor(c, crewCategories),
              marginBottom: "5px",
              whiteSpace: "normal",
              overflowWrap: "anywhere",
            }}
          >
            {c.name}
          </div>
        ))}
      </div>
      <Popover
        delay={0}
        trigger="legacy"
        placement="bottom"
        isOpen={showPopover && isAdmin}
        target={categoriesContainerId}
        toggle={() => {
          setShowPopover(!showPopover);
        }}
      >
        <PopoverBody>
          <LinkButton2
            onClick={(e) => {
              e.stopPropagation();
              setShowPopover(false);

              dispatch(actionCreators.forms.crewCategories.showForm({}));
            }}
            buttonContents="Edit service categories"
          />
        </PopoverBody>
      </Popover>
    </>
  );
};

export default JobInstanceCategories;

function getCategoryBackgroundColor(
  category: ICategoryIdentifier,
  crewCategories: Array<ICrewCategory>
) {
  const matchingCategory = crewCategories.find((c) => c.id === category.id);
  if (matchingCategory) {
    return matchingCategory.color;
  }

  return "#033c73";
}
