import React, { useEffect, useRef, useState } from "react";
import { IInvoiceItem } from "../../../models/IInvoiceItem";
import WrappedCreatableSelect from "./WrappedCreateableSelect";
import {
  components,
  GroupBase,
  OptionProps,
  SelectInstance,
  StylesConfig,
} from "react-select";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { finalize, timeout } from "rxjs/operators";
import Spinner from "./Spinner";
import { logError } from "../../../services/errorLogger";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import LinkButton2 from "./LinkButton2";
import NonQuickBooksInvoiceItemForm from "../forms/NonQuickBooksInvoiceItemForm";
import { getSortedItemsV2 } from "../../../services/sortingService";
import {
  getDefaultDescription,
  getGrossRevenueAmount,
  getInvoiceItems,
} from "../../../services/invoiceFormService";
import { isStringSet } from "../../../services/stringService";
import { getNumericString } from "../../../services/typeConverter";
import { DescriptionField, ILineItem } from "./InvoiceLineItem";
import { IInvoiceJobInstance } from "../forms/InvoiceForm.types";
import { isCrewControlInvoiceItemId } from "../../../services/lineItemService";
import QuickBooksInvoiceItem from "../../../slices/billing/components/QuickBooksInvoiceItemForm";
import { WrappedCreatableSelectOption } from "./WrappedCreatableSelectOption";

type SaveErrorType = "none" | "unknown" | "duplicateName";

export function InvoiceItemField({
  lineItem,
  items,
  onItemAdded,
  onLineItemChanged,
  jobInstances,
  selectedJobInstances,
  onClearErrorMessage,
  onItemUpdated,
  setInvoiceItems,
  inputElementId,
  placeHolderText,
  ariaLabel,
  scrollIntoView,
  includeDescription,
  descriptionElementId,
}: {
  lineItem: ILineItem;
  items: Array<IInvoiceItem> | null;
  onItemAdded?: (
    id: string,
    name: string,
    unitPrice: number | null,
    description: string,
    taxable: boolean
  ) => void;
  onLineItemChanged: (updatedItem: ILineItem) => void;
  onItemUpdated: (args: {
    id: string;
    name: string;
    description: string;
    inactive: boolean;
    unitPrice: number | null;
    taxable: boolean;
  }) => void;
  selectedJobInstances: Array<string>;
  jobInstances: Array<IInvoiceJobInstance> | undefined;
  onClearErrorMessage: () => void;
  setInvoiceItems: (newValue: Array<IInvoiceItem>) => void;
  inputElementId: string;
  placeHolderText?: string | null;
  ariaLabel?: string;
  scrollIntoView?: boolean;
  includeDescription?: boolean;
  descriptionElementId?: string;
}) {
  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );

  const previousLineItem = useRef<ILineItem>(lineItem);
  const orderedItems = getOrderedItems(items);
  useUpdateAmountPerItemOnItemChange({
    previousLineItem,
    lineItem,
    orderedItems,
    selectedJobInstances,
    jobInstances,
    onLineItemChanged,
  });

  const { loadingLocalItems, errorLoadingLocalItems, reloadInvoiceItems } =
    useLoadInvoiceItems(items, setInvoiceItems);

  const [nonQuickBooksItemEditing, setNonQuickBooksItemEditing] = useState<
    string | null
  >(null);
  const [quickBooksItemEditing, setQuickBooksItemEditing] = useState<
    string | null
  >(null);

  const [quickBooksItemAdd, setQuickBooksItemAdd] = useState<{
    name: string;
  } | null>(null);
  const [nonQuickBooksItemAdd, setNonQuickBooksItemAdd] = useState<{
    name: string;
  } | null>(null);

  const lineItemSelectRef = useRef<SelectInstance<IInvoiceItem> | null>(null);
  useEffect(() => {
    if (lineItemSelectRef.current?.inputRef) {
      lineItemSelectRef.current.inputRef.setCustomValidity(
        lineItem.itemId ? "" : "Please fill out this field"
      );
    }
  }, [lineItem.itemId]);

  const { saveItemErrorType, setSaveItemErrorType, savingItem } = useSaveItem();

  return (
    <div>
      {savingItem ? <Spinner /> : null}
      <div className="mb-3">
        <WrappedCreatableSelect<IInvoiceItem>
          placeholder={placeHolderText}
          styles={getStyles()}
          selectRef={lineItemSelectRef}
          options={orderedItems.filter(
            (i) => !i.inactive || i.id === lineItem.id
          )}
          onMenuOpen={() => {
            if (scrollIntoView) {
              // Call with setTimeout to allow menu to render open
              setTimeout(() => {
                if (lineItemSelectRef.current?.inputRef) {
                  lineItemSelectRef.current.inputRef.scrollIntoView(true);
                }
              });
            }
          }}
          isLoading={loadingLocalItems}
          getOptionLabel={(c) => {
            if (c.id === lineItem.itemId && isStringSet(lineItem.name)) {
              return lineItem.name;
            }

            return c.name;
          }}
          getOptionValue={(c) => c.id}
          inputId={inputElementId}
          aria-label={ariaLabel}
          onLeaveInputField={(input: string) => {
            let existingItem = getExistingItem(orderedItems, input);
            if (!existingItem) {
              if (!isQuickBooksEnabled) {
                setNonQuickBooksItemAdd({ name: input });
              } else {
                setQuickBooksItemAdd({ name: input });
              }
            } else if (existingItem) {
              onLineItemChanged({
                ...lineItem,
                itemId: existingItem.id,
                description: existingItem.name,
                taxable: existingItem.taxable,
              });
            }
          }}
          getNewOptionData={(text) =>
            ({
              name: (text ?? "").trim(),
            } as IInvoiceItem)
          }
          onCreateOption={(input: string) => {
            if (!isQuickBooksEnabled) {
              setNonQuickBooksItemAdd({ name: input });
            } else {
              setQuickBooksItemAdd({ name: input });
            }
          }}
          isValidNewOption={(input) => !getExistingItem(orderedItems, input)}
          components={{ Option: OptionComponent }}
          createOptionPosition="first"
          noOptionsMessage={() => "No items exist"}
          isMulti={false}
          onChange={(option) => {
            let id = "";
            let name = "";
            let description = "";
            let taxable = true;
            let amountPerItem = lineItem.amountPerItem;
            if (option) {
              if (Array.isArray(option) && option.length > 0) {
                option = option[0];
              }

              const itemOption = option as IInvoiceItem;
              id = itemOption.id;
              name = itemOption.name;

              if (itemOption.description && itemOption.description.trim()) {
                description = itemOption.description;
              }

              if (!amountPerItem && itemOption.unitPrice) {
                amountPerItem = getNumericString(itemOption.unitPrice);
              }

              taxable = itemOption.taxable;
            }

            if (!description) {
              description = getDefaultDescription({
                selectedJobInstances,
                jobInstances,
              });
            }

            onLineItemChanged({
              ...lineItem,
              itemId: id,
              name,
              description,
              taxable,
              amountPerItem,
            });

            onClearErrorMessage();
          }}
          value={orderedItems.find((c) => c.id === lineItem.itemId)}
        />

        {errorLoadingLocalItems ? (
          <>
            <div className="text-danger">
              There was an error loading invoice items, please check your
              Internet connection.
            </div>
            <div>
              <button
                type="button"
                className="btn btn-link p-0"
                onClick={() => reloadInvoiceItems()}
              >
                Try Again
              </button>
            </div>
          </>
        ) : null}

        {includeDescription ? (
          <div className="mt-2">
            <DescriptionField
              lineItem={lineItem}
              onLineItemChanged={(updatedItem) => {
                onLineItemChanged(updatedItem);
              }}
              inputElementId={descriptionElementId ?? "descriptionInput"}
            />
          </div>
        ) : null}

        {!isQuickBooksEnabled &&
        lineItem.itemId &&
        isCrewControlInvoiceItemId(lineItem.itemId) ? (
          <div className="text-nowrap">
            <LinkButton2
              buttonContents={<small>Edit item</small>}
              onClick={() => setNonQuickBooksItemEditing(lineItem.itemId)}
              className="pendoEditLineItemLink"
              testId="editLineItem"
            />
          </div>
        ) : null}

        {isQuickBooksEnabled &&
        lineItem.itemId &&
        !isCrewControlInvoiceItemId(lineItem.itemId) ? (
          <div className="text-nowrap">
            <LinkButton2
              buttonContents={<small>Edit item</small>}
              onClick={() => setQuickBooksItemEditing(lineItem.itemId)}
              className="pendoEditLineItemLink"
              testId="editQBLineItem"
            />
          </div>
        ) : null}

        {saveItemErrorType !== "none" ? (
          <div className="text-danger">
            {saveItemErrorType === "duplicateName"
              ? "The same item was just added by another user. Please reload the items and select the item."
              : "There was an error saving the new item. Please try again."}
            {saveItemErrorType === "duplicateName" ? (
              <div>
                <button
                  type="button"
                  className="btn btn-link p-0"
                  onClick={() => {
                    reloadInvoiceItems();
                    setSaveItemErrorType("none");
                  }}
                >
                  Reload Items
                </button>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>

      {nonQuickBooksItemEditing ? (
        <NonQuickBooksInvoiceItemForm
          mode="edit"
          invoiceItemId={nonQuickBooksItemEditing}
          onSaveComplete={({
            name: newName,
            description: newDescription,
            inactive: newInactive,
            unitPrice: newUnitPrice,
            taxable: newTaxable,
          }) => {
            setNonQuickBooksItemEditing(null);
            onItemUpdated({
              id: nonQuickBooksItemEditing,
              name: newName.trim(),
              description: newDescription.trim(),
              inactive: newInactive,
              unitPrice: newUnitPrice,
              taxable: newTaxable,
            });
          }}
          onCancel={() => setNonQuickBooksItemEditing(null)}
        />
      ) : null}

      {quickBooksItemEditing ? (
        <QuickBooksInvoiceItem
          mode="edit"
          invoiceItemId={quickBooksItemEditing}
          item={
            orderedItems.find((i) => i.id === quickBooksItemEditing) ?? {
              name: "",
              description: "",
              inactive: false,
              unitPrice: null,
              taxable: false,
            }
          }
          onSaveComplete={({
            name: newName,
            description: newDescription,
            inactive: newInactive,
            unitPrice: newUnitPrice,
            taxable: newTaxable,
          }) => {
            setQuickBooksItemEditing(null);
            onItemUpdated({
              id: quickBooksItemEditing,
              name: newName.trim(),
              description: newDescription.trim(),
              inactive: newInactive,
              unitPrice: newUnitPrice,
              taxable: newTaxable,
            });
          }}
          onCancel={() => setQuickBooksItemEditing(null)}
        />
      ) : null}

      {quickBooksItemAdd !== null ? (
        <QuickBooksInvoiceItem
          item={{
            name: quickBooksItemAdd.name,
            description: "",
            inactive: false,
            unitPrice: null,
            taxable: false,
          }}
          onSaveComplete={({
            id,
            name: newName,
            description: newDescription,
            unitPrice: newUnitPrice,
            taxable: newTaxable,
          }) => {
            setQuickBooksItemAdd(null);

            if (onItemAdded) {
              newName = newName.trim();
              onItemAdded(
                id,
                newName,
                newUnitPrice,
                newDescription,
                newTaxable
              );

              onLineItemChanged({
                ...lineItem,
                itemId: id,
                name: newName,
                description: newDescription,
                taxable: newTaxable,
                amountPerItem: getNumericString(newUnitPrice),
              });
            }
          }}
          onCancel={() => setQuickBooksItemAdd(null)}
          mode={"add"}
        />
      ) : null}

      {nonQuickBooksItemAdd !== null ? (
        <NonQuickBooksInvoiceItemForm
          mode="add"
          defaults={{
            name: nonQuickBooksItemAdd.name,
          }}
          onSaveComplete={({
            id: newId,
            name: newName,
            description: newDescription,
            unitPrice: newUnitPrice,
            taxable: newTaxable,
          }) => {
            setNonQuickBooksItemAdd(null);
            if (onItemAdded) {
              newName = newName.trim();
              onItemAdded(
                newId,
                newName,
                newUnitPrice,
                newDescription,
                newTaxable
              );

              onLineItemChanged({
                ...lineItem,
                itemId: newId,
                name: newName,
                description: newDescription,
                taxable: newTaxable,
                amountPerItem: getNumericString(newUnitPrice),
              });
            }
          }}
          onCancel={() => setNonQuickBooksItemAdd(null)}
        />
      ) : null}
    </div>
  );
}

function useSaveItem() {
  const [saveItemErrorType, setSaveItemErrorType] =
    useState<SaveErrorType>("none");
  const [savingItem, setSavingItem] = useState(false);
  const saveItem = ({
    name,
    onSaveComplete,
  }: {
    name: string;
    onSaveComplete(id: string, name: string): void;
  }) => {
    setSavingItem(true);
    setSaveItemErrorType("none");

    name = name.trim();

    remoteDataProvider
      .addNonQuickBooksInvoiceItems({ name })
      .pipe(
        timeout(10000),
        finalize(() => setSavingItem(false))
      )
      .subscribe({
        next: (id) => {
          onSaveComplete(id, name);
        },

        error: (error) => {
          if (error?.response?.errorCode === "InvoiceItemNameInUse") {
            setSaveItemErrorType("duplicateName");
          } else {
            logError("unable to create new invoice item");
            setSaveItemErrorType("unknown");
          }
        },
      });
  };

  return { setSaveItemErrorType, saveItemErrorType, savingItem, saveItem };
}

function getExistingItem(items: IInvoiceItem[], invoiceItemName: string) {
  return items.find(
    (c) => c.name.trim().toLowerCase() === invoiceItemName.trim().toLowerCase()
  );
}

function useLoadInvoiceItems(
  items: IInvoiceItem[] | null,
  setInvoiceItems: (newValue: Array<IInvoiceItem>) => void
) {
  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );

  const [loadingLocalItems, setLoadingLocalItems] = useState(false);
  const [errorLoadingLocalItems, setErrorLoadingLocalItems] = useState(false);
  useEffect(() => {
    if (!items && !loadingLocalItems && !errorLoadingLocalItems) {
      loadInvoiceItems({
        setLoadingLocalItems,
        isQuickBooksEnabled,
        setInvoiceItems,
        setErrorLoadingLocalItems,
      });
    }
  }, [
    items,
    errorLoadingLocalItems,
    loadingLocalItems,
    isQuickBooksEnabled,
    setInvoiceItems,
  ]);

  const reloadInvoiceItems = () => {
    loadInvoiceItems({
      setLoadingLocalItems,
      isQuickBooksEnabled,
      setInvoiceItems,
      setErrorLoadingLocalItems,
    });
  };

  return { loadingLocalItems, errorLoadingLocalItems, reloadInvoiceItems };
}

function useUpdateAmountPerItemOnItemChange({
  previousLineItem,
  lineItem,
  orderedItems,
  selectedJobInstances,
  jobInstances,
  onLineItemChanged,
}: {
  previousLineItem: React.MutableRefObject<ILineItem>;
  lineItem: ILineItem;
  orderedItems: IInvoiceItem[];
  selectedJobInstances: string[];
  jobInstances: IInvoiceJobInstance[] | undefined;
  onLineItemChanged: (updatedItem: ILineItem) => void;
}) {
  useEffect(() => {
    if (previousLineItem.current !== lineItem) {
      if (previousLineItem.current.itemId !== lineItem.itemId) {
        const item = orderedItems.find((i) => i.id === lineItem.itemId);
        const grossRevenueAmountResult = getGrossRevenueAmount(
          selectedJobInstances,
          jobInstances
        );
        if (item && typeof item.unitPrice === "number" && item.unitPrice > 0) {
          updateLineItem(
            onLineItemChanged,
            lineItem,
            "amountPerItem",
            item.unitPrice.toString()
          );
        } else if (
          grossRevenueAmountResult.hasSingleAmount &&
          typeof grossRevenueAmountResult.amount === "number"
        ) {
          updateLineItem(
            onLineItemChanged,
            lineItem,
            "amountPerItem",
            grossRevenueAmountResult.amount.toString()
          );
        } else {
          updateLineItem(onLineItemChanged, lineItem, "amountPerItem", "0");
        }
      }
      previousLineItem.current = lineItem;
    }
  }, [
    onLineItemChanged,
    lineItem,
    orderedItems,
    selectedJobInstances,
    jobInstances,
    previousLineItem,
  ]);
}

function loadInvoiceItems({
  setLoadingLocalItems,
  isQuickBooksEnabled,
  setInvoiceItems,
  setErrorLoadingLocalItems,
}: {
  setLoadingLocalItems: React.Dispatch<React.SetStateAction<boolean>>;
  isQuickBooksEnabled: boolean;
  setInvoiceItems: (newValue: Array<IInvoiceItem>) => void;
  setErrorLoadingLocalItems: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  setLoadingLocalItems(true);
  setErrorLoadingLocalItems(false);
  getInvoiceItems(isQuickBooksEnabled)
    .pipe(
      timeout(5000),
      finalize(() => setLoadingLocalItems(false))
    )
    .subscribe(
      (i) => setInvoiceItems(i),
      () => setErrorLoadingLocalItems(true)
    );
}

function updateLineItem<T>(
  onLineItemChanged: (updatedItem: ILineItem) => void,
  lineItem: ILineItem,
  propName: keyof ILineItem,
  propValue: T
) {
  onLineItemChanged({
    ...lineItem,
    [propName]: propValue,
  });
}

function getOrderedItems(items: IInvoiceItem[] | null) {
  return getSortedItemsV2(items ?? [], ["name"]);
}

function OptionComponent(
  props: OptionProps<IInvoiceItem, false, GroupBase<IInvoiceItem>>
) {
  const invoiceItem = props.data as IInvoiceItem;
  const text = invoiceItem.name;

  return (
    <components.Option {...props}>
      <div>
        <WrappedCreatableSelectOption
          isNewItem={!invoiceItem.id}
          name={text}
          recordType="product/service"
        />
      </div>
    </components.Option>
  );
}

function getStyles() {
  return {
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
    }),

    valueContainer: (provided) => ({
      ...provided,
      padding: "0 6px",
    }),

    option: (provided) => ({
      ...provided,
    }),

    input: (provided) => ({
      ...provided,
      margin: "0px",
    }),

    indicatorSeparator: () => ({
      display: "none",
    }),

    indicatorsContainer: (provided) => ({
      ...provided,
    }),

    control: (provided) => {
      return {
        ...provided,
        padding: 0,
        lineHeight: 1.5,
        borderRadius: ".2rem",
      };
    },
  } as StylesConfig<IInvoiceItem>;
}
