import { Editor } from "react-draft-wysiwyg";
import { EditorState, Modifier } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { useState } from "react";
import PersonalizeButton from "./PersonalizeButton";
import { serializeTextState } from "../../../services/richTextService";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { CustomerCommunicationTemplateType } from "../../../enums/customerCommunicationTemplateType";
import { finalize, timeout } from "rxjs/operators";
import { ICustomerCommunicationTemplate } from "../../../models/ICustomerCommunicationTemplate";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import { KeyOfType } from "../../../keyofType";
import { ICommonState } from "../../../modules/common";

interface IProps {
  templateOptions?: {
    initialState: EditorState;
    setInitialState: (v: EditorState) => void;
    template: KeyOfType<ICommonState, ICustomerCommunicationTemplate | null>;
    templateType: CustomerCommunicationTemplateType;
    templateProperty: keyof ICustomerCommunicationTemplate;
  };
  editorState: EditorState;
  setEditorState: (newState: EditorState) => void;
  ariaLabel: string;
  placeholder: string;
  hideSaveAsDefault?: boolean;
}

const RichTextEditor: React.FunctionComponent<IProps> = ({
  templateOptions,
  editorState,
  setEditorState,
  ariaLabel,
  placeholder,
  hideSaveAsDefault,
}) => {
  const [saving, setSaving] = useState(false);
  const [errorSaving, setErrorSaving] = useState(false);
  const dispatch = useDispatch();

  const isDirty =
    templateOptions?.initialState &&
    serializeTextState(templateOptions?.initialState) !==
      serializeTextState(editorState);

  return (
    <>
      <Editor
        ariaLabel={ariaLabel}
        editorState={editorState}
        onEditorStateChange={(s) => setEditorState(s)}
        editorClassName="richtext-editor"
        toolbarCustomButtons={[<PersonalizeButtonsWrapper />]}
        stripPastedStyles={true}
        placeholder={placeholder}
        toolbar={{
          options: ["inline", "list", "textAlign", "link"],
          inline: {
            inDropdown: false,
            options: ["bold", "italic", "underline"],
          },
          list: {
            options: ["unordered", "ordered"],
          },
        }}
        editorStyle={{ minHeight: "80px" }}
      />

      {errorSaving ? (
        <div className="mt-2 text-danger">
          There was an error saving the default text. Please try again.
        </div>
      ) : null}

      {isDirty && !hideSaveAsDefault ? (
        <div className="mt-2">
          <button
            className="btn btn-sm btn-primary mr-3"
            type="button"
            disabled={saving}
            data-testid="saveAsDefaultText"
            onClick={() => {
              setSaving(true);
              setErrorSaving(false);

              const serializedValue = serializeTextState(editorState);
              remoteDataProvider
                .saveCustomerCommunicationTemplate(
                  templateOptions?.templateType,
                  {
                    [templateOptions.templateProperty]: serializedValue,
                  }
                )
                .pipe(
                  timeout(5000),
                  finalize(() => setSaving(false))
                )
                .subscribe({
                  next: () => {
                    templateOptions?.setInitialState(editorState);
                    dispatch(
                      actionCreators.updateCustomerCommunicationTemplate(
                        templateOptions.template,
                        templateOptions.templateProperty,
                        serializedValue
                      )
                    );
                  },

                  error: () => {
                    setErrorSaving(true);
                  },
                });
            }}
          >
            Save as default text
          </button>

          <button
            className="btn btn-sm btn-secondary mr-3"
            type="button"
            disabled={saving}
            onClick={() => {
              setErrorSaving(false);
              templateOptions?.setInitialState(editorState);
            }}
          >
            Ignore
          </button>
        </div>
      ) : null}
    </>
  );
};

export default RichTextEditor;

export function PersonalizeButtonsWrapper(props: {
  // A little hacky but required since Editor's custom button handling is bizarre.
  // It'll clone the element and pass through onChange and editorState to the cloned item.
  onChange?: (arg: EditorState) => void;
  editorState?: EditorState;
  hideAddressOption?: boolean;
}) {
  const { onChange, editorState, hideAddressOption } = props;

  return (
    <PersonalizeButton
      disabled={false}
      onClick={(value) => {
        if (onChange && editorState) {
          const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            value,
            editorState.getCurrentInlineStyle()
          );
          onChange(
            EditorState.push(editorState, contentState, "insert-characters")
          );
        }
      }}
      buttonColor="light"
      hideAddressOption={hideAddressOption ?? false}
    />
  );
}
