import React from "react";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../../modules/actionCreators";

interface IProps {
  timeRangeId: string;
  onDeleteClick: () => void;
}

const CrewMemberPayrollHistoryButtons: React.FunctionComponent<IProps> = ({
  timeRangeId,
  onDeleteClick,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "flex-end",
        }}
      >
        <button
          className="btn btn-secondary btn-sm"
          style={{ marginRight: "10px" }}
          onClick={() =>
            dispatch(
              actionCreators.forms.payrollTimeRange.showForm({
                timeRangeId,
              })
            )
          }
        >
          Edit
        </button>
        <button
          className="btn btn-secondary btn-sm"
          onClick={() => onDeleteClick()}
        >
          Delete
        </button>
      </div>
    </>
  );
};

export default CrewMemberPayrollHistoryButtons;
