import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { timeout } from "rxjs/operators";
import conditionalRenderer from "../../../containers/app/components/conditionalRenderer";
import Prompt from "../../../containers/app/components/Prompt";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { IPromptMessageSection } from "../../../modules/prompt";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import { opportunitiesActionCreators } from "../modules/opportunity";
import opportunityDataProvider from "../services/opportunityDataProvider";

const OpportunityArchive: React.FunctionComponent<{}> = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isArchiving, setIsArchiving] = useState(false);
  const promptData = useApplicationStateSelector(
    (s) => s.opportunity.archivePrompt
  );
  const dispatch = useDispatch();

  const subMessage: Array<IPromptMessageSection> = [
    {
      text:
        promptData && promptData.opportunitiesToArchive.length === 1
          ? `Once archived, this proposal is available on ${promptData.opportunitiesToArchive[0].customerName}'s customer record.`
          : `Once archived, these proposals are available on the customer's records.`,
    },
  ];

  if (errorMessage) {
    subMessage.push({
      text: errorMessage,
      newBlock: true,
      dangerColor: true,
    });
  }

  if (!promptData) {
    return null;
  }

  let promptMessage = promptData.promptMessageOverride;
  if (!promptMessage) {
    if (promptData.opportunitiesToArchive.length === 1) {
      promptMessage = `Are you sure you want to archive this proposal for ${promptData.opportunitiesToArchive[0].customerName}?`;
    } else {
      promptMessage = `Are you sure you want to archive ${promptData.opportunitiesToArchive.length} proposals?`;
    }
  }

  return (
    <Prompt
      showPrompt={true}
      showSpinner={isArchiving}
      promptMessage={promptMessage}
      promptSubMessage={subMessage}
      onConfirm={() => {
        setIsArchiving(true);

        opportunityDataProvider
          .archiveOpportunities({
            opportunityIds: promptData.opportunitiesToArchive.map(
              (o) => o.opportunityId
            ),
          })
          .pipe(timeout(5000))
          .subscribe({
            next: (updatedOpportunities) => {
              setIsArchiving(false);

              dispatch(
                opportunitiesActionCreators.loadSpecificOpportunities({
                  opportunities: updatedOpportunities,
                })
              );

              dispatch(opportunitiesActionCreators.closeArchivePrompt({}));
            },

            error: (err) => {
              setIsArchiving(false);
              setErrorMessage(getErrorMessageFromError(err));
            },
          });
      }}
      onCancel={() =>
        dispatch(opportunitiesActionCreators.closeArchivePrompt({}))
      }
    />
  );
};

export default conditionalRenderer(
  OpportunityArchive,
  (s) => s.opportunity.archivePrompt !== null
);
