import React from "react";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import remoteDataProvider from "../../../services/remoteDataProvider";

import SendFormBase from "../components/SendFormBase";

interface IProps {
  customerId: string;
  invoiceId: string;
  onClose: () => void;
  onSaveComplete: () => void;
  defaultReplyToEmailAddress: string | null;
}

const InvoiceSendForm: React.FunctionComponent<IProps> = ({
  customerId,
  invoiceId,
  onClose,
  onSaveComplete,
  defaultReplyToEmailAddress,
}) => {
  return (
    <SendFormBase
      formHeader="Send Invoice"
      customerId={customerId}
      formType={FormTypesV2.invoiceSendForm}
      onCancel={onClose}
      onSaveComplete={onSaveComplete}
      defaultReplyToEmailAddress={defaultReplyToEmailAddress}
      save={(data) => {
        return remoteDataProvider.sendInvoice({
          ...data,
          invoiceId,
        });
      }}
    />
  );
};

export default InvoiceSendForm;
