import {
  faArrows,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Draggable } from "react-beautiful-dnd";

interface IProps {
  name: string;
  id: string;
  index: number;
  expanded: boolean;
  onRowExpanded: () => void;
}

const ScheduleRow: React.FunctionComponent<IProps> = ({
  name,
  children,
  id,
  index,
  expanded,
  onRowExpanded,
}) => {
  return (
    <Fragment>
      <Draggable draggableId={id} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={provided.draggableProps.style}
            data-testid="scheduleRowContainer"
          >
            <div
              className="alert"
              style={{
                backgroundColor: "rgb(244, 246, 248)",
                borderWidth: "0px 0px 2px",
                borderColor: "rgb(210, 215, 220)",
                marginBottom: "0",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button
                  className="link-button expansion-button"
                  type="button"
                  onClick={() => onRowExpanded()}
                  data-testid="toggleCrewExpandedButton"
                >
                  <>
                    {expanded ? (
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        data-testid="chevronDown"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faChevronUp}
                        data-testid="chevronUp"
                      />
                    )}
                    <span style={{ marginLeft: "10px" }}>{name}</span>
                  </>
                </button>
                <div {...provided.dragHandleProps}>
                  <FontAwesomeIcon icon={faArrows} title="Move Schedule" />
                </div>
              </div>
            </div>
            {expanded ? children : null}
          </div>
        )}
      </Draggable>
    </Fragment>
  );
};

export default ScheduleRow;
