import React, { Fragment, useContext, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import IntroScreenAddCrew from "./pages/IntroScreenAddCrew";
import CrewForm from "./forms/CrewForm";
import MaintenanceJobForm from "./forms/MaintenanceJobForm";
import OneTimeJobForm from "./forms/OneTimeJobForm";
import MoveForm from "./forms/MoveForm";
import PublishScheduleForm from "./forms/PublishScheduleForm";
import CrewViewConfigurationForm from "./forms/CrewViewConfigurationForm";
import SchedulePrint from "./pages/schedule/Print";
import CompletedWorkReport from "./pages/completedWork/Report";
import DailyReport from "./pages/reports/daily/Index";
import EmployeeTimes from "./pages/reports/payroll/Index";
import ErrorLoadingInitialData from "./pages/ErrorLoadingInitialData";
import { Route, Switch, Redirect, withRouter } from "react-router";
import { actionCreators } from "../../modules/actionCreators";
import LandingPage from "./pages/LandingPage";
import Spinner from "./components/Spinner";
import {
  patterns as routePatterns,
  getDefaultRoute,
} from "../../services/routing";
import configuration from "../../services/configuration";
import "./index.css";
import { IRootState } from "../../store";
import Crews from "./pages/manage/Crews";
import PromptRedux from "./components/PromptRedux";
import CrewNotesForm from "./forms/CrewNotesForm";
import CustomersV2 from "./pages/manage/CustomersV2";
import { isMobileOnly } from "react-device-detect";
import BillingDetailsForm from "./forms/BillingDetailsForm";
import TodoTemplates from "./pages/manage/TodoTemplates";
import TodoTemplateForm from "./forms/TodoTemplateForm";
import DayScheduleForm from "./forms/DayScheduleForm";
import CustomerForm from "./forms/CustomerForm";
import { Auth0Context, IAuthContext } from "../../services/AuthContext";
import { LoadScriptNext } from "@react-google-maps/api";
import QuickBooksConfiguration from "./components/QuickBooksConfiguration";
import {
  commonUiActionCreators,
  QuickBooksModalMode,
} from "../../modules/commonUi";
import InvoiceForm from "./forms/InvoiceForm";
import QuickBooksCustomerImport from "./pages/QuickBooksCustomerImport";
import Map from "./pages/schedule/Map";
import CustomerAdditionalLocationForm from "./forms/CustomerAdditionalLocationForm";
import CrewMemberForm from "./forms/CrewMemberForm";
import ShiftScheduleForm from "./forms/ShiftScheduleForm";
import AdminViewConfigurationForm from "./forms/AdminViewConfigurationForm";
import { identifyApplicationInsightsUser } from "../../services/applicationInsights";
import ScheduledDispatchSettingsForm from "./forms/ScheduledDispatchSettingsForm";
import FormAlertOnLeave from "./components/FormAlertOnLeave";
import CustomerDetails from "./pages/manage/CustomerDetails";
import CrewMemberDetails from "./pages/manage/CrewMemberDetails";
import CustomerNotificationForm from "./forms/CustomerNotificationForm";
import CustomerPaymentMethodForm from "./forms/CustomerPaymentMethodForm";
import CustomerNotificationResultsAlert from "./components/schedule/CustomerNotificationResultsAlert";
import CustomerContractBillingForm from "./forms/CustomerContractBillingForm";
import CrewMembers2 from "./pages/manage/CrewMembers2";
import PayrollTimeRangeForm from "./forms/PayrollTimeRangeForm";
import { TenantSubscriptionStatus } from "../../models/IInitialLoad";
import { googleMapLibraries } from "../../services/googleService";
import SubscriptionForm from "../../slices/tenantSubscription/components/SubscriptionForm";
import ConditionalFormContainer from "./components/ConditionalFormContainer";
import Month from "./pages/schedule/Month";
import { logError } from "../../services/errorLogger";
import CompanyProfileForm from "./forms/CompanyProfileForm";
import CustomerNotificationsConfigurationForm from "./forms/CustomerNotificationsConfigurationForm";
import ManageAdminUsers from "./components/ManageAdminUsers";
import MerchantOnboardingForm from "./components/merchantOnboarding/MerchantOnboardingForm";
import CommonUiAlertModal from "./components/CommonUiAlertModal";
import CrewCategoriesForm from "./forms/CrewCategoriesForm";
import InvoiceRetryChargeForm from "./forms/InvoiceRetryChargeForm";
import SalesPage from "../../slices/sales/components/SalesPage";
import TagManager from "react-gtm-module";
import constants from "../../constants";
import useSalesEnabled from "../../slices/sales/hooks/useSalesEnabled";
import { useApplicationStateSelector } from "../../hooks/useApplicationStateSelector";
import { useCreditCardsEnabled } from "../../hooks/useCreditCardsEnabled";
import { Dispatch } from "redux";
import { useQuery } from "../../hooks/useQuery";
import InvoicePrintModal from "../../slices/billing/components/InvoicePrintContainer";
import Invoicing from "../../slices/billing/components/Billing";
import { TenantIndustry } from "../../enums/tenantIndustry";
import { CustomerClearJobsPrompt } from "./components/CustomerClearJobsPrompt";
import InvoiceItems from "./pages/manage/InvoiceItems";
import ClientSupport from "../../slices/clientsupport/components/ClientSupport";
import OpportunityArchive from "../../slices/sales/components/OpportunityArchive";
import { UserNotificationsFormPage } from "./pages/UserNotificationsFormPage";
import { FS, fullStoryLogError } from "../../services/fullStoryService";
import { Register } from "../../slices/accountManagement/components/Register";
import { LoginPage } from "../../slices/accountManagement/components/LoginPage";
import RegisterWithInvitation from "../../slices/accountManagement/components/RegisterWithInvitation";
import InvoicePrintPage from "../../slices/billing/components/InvoicePrintPage";
import ProposalPrintPage from "../../slices/sales/components/ProposalPrintPage";
import { ScheduleTimeWeekPage } from "../../slices/schedule/components/ScheduleTimeWeekPage";
import ScheduleSequenceWeekPage from "../../slices/schedule/components/ScheduleSequenceWeekPage";
import ScheduleSequenceDayPage from "../../slices/schedule/components/ScheduleSequenceDayPage";
import { ScheduleTimeDayPage } from "../../slices/schedule/components/ScheduleTimeDayPage";
import { ITenantFeatureFlags } from "../../models/ITenantFeatureFlags";
import CustomerCommunicationSettingsPage from "../../slices/configuration/components/CustomerCommunicationSettingsPage";
import ProposalTemplatesPage from "../../slices/sales/components/ProposalTemplatesPage";
import BulkCopyJobsForm from "../../slices/schedule/components/BulkCopyJobsForm";
import { useMigrateUserSettings } from "../../hooks/useMigrateUserSettings";
import { isStringSet } from "../../services/stringService";
import { LockedOutForFailedPaymentPage } from "../../slices/tenantSubscription/components/LockedOutForFailedPaymentPage";
import { isLockedOutForFailedPayment } from "../../slices/tenantSubscription/services/crewControlSubscriptionService";
import { LockedOutForCancellationPage } from "../../slices/tenantSubscription/components/LockedOutForCancellationPage";
import { CrewMemberSendAppInstructionsRedux } from "./components/CrewMemberSendAppInstructionsRedux";

interface IProps {
  initialLoadState: number;
  crews: Array<any>;
  isRegistered: boolean;
  tenantId: string | null;
  tenantName: string | null;
  tenantDateAddedUtc: string | null;
  userAccountId: string;
  userAccountEmail: string | null;
  tenantSubscriptionStatus: TenantSubscriptionStatus;
  showPayrollTimeTracking: boolean;
  crewCount: number;
  tenantIndustry: TenantIndustry | null;
  tenantCity: string | null;
  tenantState: string | null;
  tenantCountry: string | null;
  startInitialLoad(): void;
  dispatch(): void;
  featureFlags: ITenantFeatureFlags | null;
}

function hasAuthenticationTokenInQueryString() {
  return /access_token|id_token|error/.test(window.location.hash);
}

let registeredWithAppInsights = false;
let loginRegistered = false;

const App: React.FunctionComponent<IProps> = ({
  crews,
  initialLoadState,
  isRegistered,
  tenantId,
  tenantName,
  tenantDateAddedUtc,
  userAccountId,
  userAccountEmail,
  startInitialLoad,
  showPayrollTimeTracking,
  tenantSubscriptionStatus,
  crewCount,
  tenantIndustry,
  tenantCity,
  tenantState,
  tenantCountry,
  featureFlags,
}) => {
  const authContext = useContext(Auth0Context);
  const dispatch = useDispatch();
  const query = useQuery();
  const salesEnabled = useSalesEnabled();
  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );
  const { areCreditCardsEnabled } = useCreditCardsEnabled();
  const isPaying =
    tenantSubscriptionStatus === TenantSubscriptionStatus.Subscribed;

  const subscriptionStatus = useApplicationStateSelector(
    (s) => s.common.tenantSubscriptionStatus
  );
  const subscriptionPaymentFailureUtc = useApplicationStateSelector(
    (s) => s.common.subscriptionPaymentFailureUtc
  );

  useEffect(() => {
    if (initialLoadState === 0 && authContext.isAuthenticated) {
      startInitialLoad();
    }
  }, [initialLoadState, authContext, startInitialLoad]);

  useMigrateUserSettings();

  let content;
  if (!authContext.isAuthenticated) {
    if (authContext.isLoading) {
      content = <Spinner />;
    } else {
      content = (
        <Switch>
          <Route
            path="/callback"
            render={() => {
              return getCallbackBody(authContext, dispatch, query);
            }}
          />
          <Route
            path={routePatterns.userAccount.signup}
            render={() => {
              return <Register />;
            }}
          />
          <Route
            path={routePatterns.userAccount.login}
            render={() => {
              return <LoginPage />;
            }}
          />
          <Route
            path={routePatterns.registration.acceptInvitation}
            render={({ match }) => {
              return (
                <RegisterWithInvitation
                  invitationId={match.params.invitationId}
                />
              );
            }}
          />
          <Route
            render={() => {
              return <LandingPage />;
            }}
          />
        </Switch>
      );
    }
  } else if (initialLoadState === 2) {
    if (
      !registeredWithAppInsights &&
      tenantName &&
      tenantId &&
      !configuration.isAutomatedTest
    ) {
      identifyApplicationInsightsUser(tenantName, tenantId);
      registeredWithAppInsights = true;
    }

    if (tenantName && !loginRegistered && !configuration.isAutomatedTest) {
      registerLogins({
        isPaying,
        userAccountId,
        userAccountEmail,
        tenantId,
        tenantName,
        tenantDateAddedUtc,
        isQuickBooksEnabled,
        areCreditCardsEnabled,
        salesEnabled,
        crewCount,
        tenantIndustry,
        tenantCity,
        tenantState,
        tenantCountry,
        subscriptionStatus,
        subscriptionPaymentFailureUtc,
      });

      loginRegistered = true;
    }

    let subContent: React.ReactNode;
    if (!isRegistered) {
      subContent = (
        <Switch>
          <Route
            path="/callback"
            render={() => {
              return getCallbackBody(authContext, dispatch, query);
            }}
          />
          <Route
            path="/usersignup/:invitationId"
            render={({ match }) => {
              return (
                <RegisterWithInvitation
                  invitationId={match.params.invitationId}
                />
              );
            }}
          />
          <Route
            render={() => {
              return <Register />;
            }}
          />
        </Switch>
      );
    } else if (crews.length === 0) {
      subContent = <IntroScreenAddCrew />;
    } else {
      if (subscriptionStatus === TenantSubscriptionStatus.Canceled) {
        subContent = <LockedOutForCancellationPage />;
      } else if (
        isLockedOutForFailedPayment({ subscriptionPaymentFailureUtc })
      ) {
        subContent = <LockedOutForFailedPaymentPage />;
      } else {
        subContent = (
          <Fragment>
            <Switch>
              {!isMobileOnly ? (
                <Route
                  exact
                  path={routePatterns.schedule.sequenceWeek}
                  component={ScheduleSequenceWeekPage}
                />
              ) : null}
              {!isMobileOnly ? (
                <Route
                  exact
                  path={routePatterns.schedule.print}
                  component={SchedulePrint}
                />
              ) : null}
              {!isMobileOnly ? (
                <Route
                  path={routePatterns.schedule.timeWeek}
                  component={ScheduleTimeWeekPage}
                />
              ) : null}
              <Route
                path={routePatterns.schedule.timeDay}
                component={ScheduleTimeDayPage}
              />
              <Route
                path={routePatterns.schedule.sequenceDay}
                component={ScheduleSequenceDayPage}
              />
              <Route
                exact
                path={routePatterns.completedWork.report}
                component={CompletedWorkReport}
              />
              <Route
                path={routePatterns.report.daily}
                component={DailyReport}
              />
              <Route
                path={routePatterns.report.employeeTimes}
                component={EmployeeTimes}
              />
              <Route path={routePatterns.schedule.map} component={Map} />
              <Route path={routePatterns.schedule.month} component={Month} />
              <Route
                exact
                path={routePatterns.manage.crews}
                component={Crews}
              />
              <Route
                exact
                path={routePatterns.manage.crewsMember}
                component={CrewMembers2}
              />
              <Route
                exact
                path={routePatterns.manage.todoTemplates}
                component={TodoTemplates}
              />
              <Route
                exact
                path={routePatterns.manage.lineItems}
                component={InvoiceItems}
              />
              <Route
                exact
                path={routePatterns.manage.customerCommunication}
                component={CustomerCommunicationSettingsPage}
              />
              <Route
                exact
                path={routePatterns.manage.customers}
                component={CustomersV2}
              />
              <Route
                exact
                path={routePatterns.manage.customerDetails}
                component={CustomerDetails}
              />
              <Route
                exact
                path={routePatterns.manage.crewMemberDetails}
                component={CrewMemberDetails}
              />
              <Route
                exact
                path={routePatterns.billing.index}
                component={Invoicing}
              />
              <Route
                exact
                path={routePatterns.estimates.index}
                component={SalesPage}
              />
              <Route
                exact
                path={routePatterns.manage.proposalTemplates}
                component={ProposalTemplatesPage}
              />
              <Route
                exact
                path={routePatterns.setup.quickBooksCustomerImport}
                component={QuickBooksCustomerImport}
              />
              <Route
                exact
                path={routePatterns.clientSupport.index}
                component={ClientSupport}
              />
              <Route
                path="/qbconfirm"
                render={() => {
                  const wasQuickbooksSetupValid =
                    window.location.href.indexOf("v=1");
                  if (wasQuickbooksSetupValid === -1) {
                    fullStoryLogError(
                      "QuickBooks was not configured correctly"
                    );
                    dispatch(
                      commonUiActionCreators.showQuickBooksModal({
                        quickBooksModalMode: QuickBooksModalMode.error,
                      })
                    );
                  } else {
                    dispatch(
                      commonUiActionCreators.showQuickBooksModal({
                        quickBooksModalMode: QuickBooksModalMode.confirmation,
                      })
                    );
                  }

                  const redirectLocation = window.sessionStorage.getItem(
                    constants.quickBooksOnlineConnectedRedirect
                  );

                  if (redirectLocation) {
                    return <Redirect to={redirectLocation} />;
                  } else {
                    return <Redirect to={routePatterns.schedule.default} />;
                  }
                }}
              />
              <Route
                path="/mynotifications"
                component={UserNotificationsFormPage}
              />
              <Route
                exact
                path={routePatterns.print.invoice}
                component={InvoicePrintPage}
              />
              <Route
                exact
                path={routePatterns.print.proposal}
                component={ProposalPrintPage}
              />
              <Route render={() => <Redirect to={getDefaultRoute()} />} />
            </Switch>
          </Fragment>
        );
      }
    }

    return (
      <React.Fragment>
        {subContent}
        <CrewForm />
        <CrewMemberForm />
        <MaintenanceJobForm />
        <OneTimeJobForm />
        <MoveForm />
        <PublishScheduleForm />
        <CrewNotesForm />
        <DayScheduleForm />
        <CrewViewConfigurationForm />
        <AdminViewConfigurationForm />
        <PayrollTimeRangeForm />
        <BillingDetailsForm />
        <TodoTemplateForm />
        <CustomerForm />
        <CustomerAdditionalLocationForm />
        <QuickBooksConfiguration />
        <InvoicePrintModal />
        <PromptRedux />

        <InvoiceForm />

        <InvoiceRetryChargeForm />
        <BulkCopyJobsForm />

        <ShiftScheduleForm />
        <SubscriptionForm />
        <CrewCategoriesForm />
        <ScheduledDispatchSettingsForm />
        <CustomerNotificationForm />
        <CustomerPaymentMethodForm />
        <CustomerContractBillingForm />

        <ConditionalFormContainer formType="companyProfile">
          <CompanyProfileForm />
        </ConditionalFormContainer>

        <ConditionalFormContainer formType="customerNotificationsConfiguration">
          <CustomerNotificationsConfigurationForm />
        </ConditionalFormContainer>

        <ManageAdminUsers />

        <CustomerClearJobsPrompt />
        <CrewMemberSendAppInstructionsRedux />

        <MerchantOnboardingForm />

        <CustomerNotificationResultsAlert />
        <CommonUiAlertModal />
        <OpportunityArchive />
        <FormAlertOnLeave />
        <LoadScriptNext
          loadingElement={<div></div>}
          id="script-loader"
          libraries={googleMapLibraries as any}
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY as string}
          onError={() => {
            console.log("Unable to load google maps script");
          }}
        >
          <React.Fragment />
        </LoadScriptNext>
      </React.Fragment>
    );
  } else if (initialLoadState === 3) {
    content = <ErrorLoadingInitialData />;
  } else {
    content = <Spinner />;
  }

  return content;
};

const mapStateToProps = (state: IRootState) => ({
  crews: state.crew.crews,
  initialLoadState: state.common.initialLoadState,
  isRegistered: state.common.isRegistered,
  tenantId: state.common.tenantId,
  tenantName: state.common.tenantName,
  tenantDateAddedUtc: state.common.tenantDateAddedUtc,
  userAccountId: state.common.userAccountId,
  userAccountEmail: state.common.userAccountEmail,
  showPayrollTimeTracking:
    state.common.crewViewConfiguration.showPayrollTimeTracking,
  tenantSubscriptionStatus: state.common.tenantSubscriptionStatus,
  crewCount: state.crew.crews.filter((c) => c.inactive === false)?.length,
  tenantIndustry: state.common.industry,
  tenantCity: state.common.city,
  tenantState: state.common.state,
  tenantCountry: state.common.country,
  featureFlags: state.common.featureFlags,
});

const mapDispatchToProps = (dispatch: any) => ({
  startInitialLoad: () => dispatch(actionCreators.startInitialLoad()),
  dispatch,
});

const connected = connect(mapStateToProps, mapDispatchToProps)(App);

export default withRouter(connected as any);

function getCallbackBody(
  authContext: IAuthContext,
  dispatch: Dispatch<any>,
  query: URLSearchParams
) {
  if (hasAuthenticationTokenInQueryString()) {
    authContext.handleAuthentication({
      dispatch,
      invitationId: query.get(constants.invitationIdQueryString),
    });
  }
  return <Spinner />;
}

function registerLogins({
  isPaying,
  userAccountId,
  userAccountEmail,
  tenantId,
  tenantName,
  tenantDateAddedUtc,
  isQuickBooksEnabled,
  areCreditCardsEnabled,
  salesEnabled,
  crewCount,
  tenantIndustry,
  tenantCity,
  tenantState,
  tenantCountry,
  subscriptionStatus,
  subscriptionPaymentFailureUtc,
}: {
  isPaying: boolean;
  userAccountId: string;
  userAccountEmail: string | null;
  tenantId: string | null;
  tenantName: string;
  tenantDateAddedUtc: string | null;
  isQuickBooksEnabled: boolean;
  areCreditCardsEnabled: boolean;
  salesEnabled: boolean;
  crewCount: number;
  tenantIndustry: TenantIndustry | null;
  tenantCity: string | null;
  tenantState: string | null;
  tenantCountry: string | null;
  subscriptionStatus: TenantSubscriptionStatus;
  subscriptionPaymentFailureUtc: string | null;
}) {
  try {
    let runningAsPwa = false;
    try {
      const navigatorAny = navigator as any;
      if (
        navigatorAny.standalone ||
        window.matchMedia("(display-mode: standalone)").matches
      ) {
        runningAsPwa = true;
      }
    } catch {
      console.error("unable to determine if running as pwa");
    }

    const hasPaymentFailed = isStringSet(subscriptionPaymentFailureUtc);

    const pendo = (window as any).pendo;
    if (pendo) {
      pendo.initialize({
        visitor: {
          id: userAccountId,
          email: userAccountEmail,
        },
        account: {
          id: tenantId,
          name: tenantName,
          is_paying: isPaying,
          creationDate: tenantDateAddedUtc,
          quickbooks_enabled: isQuickBooksEnabled,
          credit_card_payments_enabled: areCreditCardsEnabled,
          sales_enabled: salesEnabled,
          active_crews: crewCount,
          industry: tenantIndustry ? TenantIndustry[tenantIndustry] : null,
          state: tenantState,
          city: tenantCity,
          country: tenantCountry,
          running_as_pwa: runningAsPwa,
          trial_subscription_status: subscriptionStatus,
          has_payment_failed: hasPaymentFailed,
        },
      });
    } else {
      logError(`pendo was not set for ${tenantId}`);
    }

    if (FS) {
      FS.identify(userAccountId, {
        displayName: tenantName,
        email: userAccountEmail,
        tenant_id: tenantId,
        company_name: tenantName,
        company_start_date: tenantDateAddedUtc,
        tenant_country: tenantCountry,
        company_state: tenantState,
        company_city: tenantCity,
        subscribed: isPaying,
        plus_package: salesEnabled,
        payments_enabled: areCreditCardsEnabled,
        quickbooks_enabled: isQuickBooksEnabled,
        active_crews: crewCount,
        industry: tenantIndustry ? TenantIndustry[tenantIndustry] : null,
        running_as_pwa: runningAsPwa,
        trial_subscription_status: subscriptionStatus,
        has_payment_failed: hasPaymentFailed,
      });
    } else {
      logError(`fullstory was not set for ${tenantId}`);
    }

    if (TagManager?.dataLayer) {
      TagManager.dataLayer({
        dataLayer: {
          event: "analytics.login",
          data: {
            userId: userAccountId,
            email: userAccountEmail,
            tenantId: tenantId,
            tenantName: tenantName,
            isPaying,
            creationDate: tenantDateAddedUtc,
          },
        },
      });
    } else {
      logError(`TagManager.dataLayer was not set for ${tenantId}`);
    }
  } catch (ex) {
    logError("Error initializing login");
  }
}
