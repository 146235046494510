import constants from "../constants";
import { logError } from "./errorLogger";

function getErrorMessageFromError(
  err: any,
  unknownErrorMessage: string = constants.unknownErrorMessage
) {
  let errorMessage = unknownErrorMessage;
  if (err.response && typeof err.response === "object") {
    if (err.response.message && err.response.errorCode) {
      errorMessage = err.response.message;
    } else if (err.response.title && err.response.traceId) {
      errorMessage = err.response.title;
    } else {
      const properties = Object.getOwnPropertyNames(err.response);
      properties.forEach((property) => {
        if (err.response[property].length > 0) {
          errorMessage = err.response[property];
        }
      });
    }
  } else if (typeof err.response === "string" && err.response.length > 0) {
    errorMessage = err.response;
  } else if (err.status === 401) {
    errorMessage =
      "Your session has timed out.  Please reload this page and login again.";
  } else if (typeof err === "string") {
    errorMessage = err;
  }

  return errorMessage;
}

function getErrorMessagesFromError(
  err: any,
  unknownErrorMessage: string = constants.unknownErrorMessage
): Array<string> {
  if (Array.isArray(err?.response)) {
    const errorMessages = err.response as Array<any>;
    return errorMessages.map((e) => e.message);
  } else {
    return [getErrorMessageFromError(err, unknownErrorMessage)];
  }
}

function getFormSaveErrorMessage(
  error: any,
  timeoutErrorMessage?: string,
  customDefaultErrorMessage?: string
) {
  let errorMessage: string;

  const isTimeoutError =
    error && typeof error === "object" && error.name === "TimeoutError";

  const isModelBindingError =
    error &&
    error.response &&
    typeof error === "object" &&
    typeof error.response === "object" &&
    error.response.type === "https://tools.ietf.org/html/rfc7231#section-6.5.1";

  if (isTimeoutError) {
    errorMessage =
      "An error occurred while saving. Please verify your internet connection and try again.";

    if (typeof timeoutErrorMessage === "string" && !!timeoutErrorMessage) {
      errorMessage = timeoutErrorMessage;
    } else if (!!customDefaultErrorMessage) {
      errorMessage = customDefaultErrorMessage;
    }

    logError("Timeout when saving form");
  } else if (isModelBindingError) {
    // This is a 400 from the default .net model binding.  Shouldn't happen so log an error
    logError(
      `model binding error: ${getProblemDetailsErrorText(error.response)}`
    );

    errorMessage = constants.unknownErrorMessage;

    if (!!customDefaultErrorMessage) {
      errorMessage = customDefaultErrorMessage;
    }
  } else {
    errorMessage = getErrorMessagesFromError(
      error,
      !!customDefaultErrorMessage
        ? customDefaultErrorMessage
        : "An unknown error occurred while saving."
    )[0];
  }
  return errorMessage;
}

function getProblemDetailsErrorText(err: any) {
  let result = "";
  if (typeof err.errors === "object") {
    const errorDetails: any = err.errors;
    for (const key in errorDetails) {
      if (errorDetails[key].length) {
        const errorArray: Array<string> = errorDetails[key];
        result += errorArray.reduce(
          (acc, currentError) => (acc += currentError),
          ""
        );
      }
    }
  }

  return result;
}

export {
  getErrorMessageFromError,
  getErrorMessagesFromError,
  getFormSaveErrorMessage,
};
