import React, { useState } from "react";
import { finalize } from "rxjs/operators";
import { IUserAccount } from "../../../models/IUserAccount";
import dataProvider from "../../../services/dataProvider";
import Prompt from "./Prompt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  userAccount: IUserAccount;
  currentUserIsAdmin: boolean;
  reloadUserAccounts(): void;
  setFormWaiting(waiting: boolean): void;
  setErrorMessage(message: string): void;
}

const ManageAdminUsersUserActions: React.FunctionComponent<IProps> = ({
  userAccount,
  currentUserIsAdmin,
  reloadUserAccounts,
  setErrorMessage,
  setFormWaiting,
}) => {
  const [showDeletedPrompt, setShowDeletedPrompt] = useState(false);

  return (
    <>
      <Prompt
        showPrompt={showDeletedPrompt}
        promptMessage={`Are you sure you want to delete the admin user ${userAccount.emailAddress}?`}
        onConfirm={() => {
          setErrorMessage("");
          setFormWaiting(true);
          setShowDeletedPrompt(false);

          dataProvider
            .deleteUserAccount(userAccount.id)
            .pipe(finalize(() => setFormWaiting(false)))
            .subscribe(
              () => {
                reloadUserAccounts();
              },
              (err: any) => {
                setErrorMessage(
                  "An unknown error occurred when deleting the admin user."
                );
              }
            );
        }}
        onCancel={() => setShowDeletedPrompt(false)}
      />
      {currentUserIsAdmin && !userAccount.isTenantOwner ? (
        <div className="text-right pr-0 mb-2">
          <button
            className="btn btn-link m-0 p-0 text-dark"
            onClick={() => setShowDeletedPrompt(true)}
          >
            <FontAwesomeIcon icon={faTrash} title="Delete" />
          </button>
        </div>
      ) : null}
    </>
  );
};

export default ManageAdminUsersUserActions;
