import { CSSProperties } from "react";
import Nav from "reactstrap/lib/Nav";
import NavItem from "reactstrap/lib/NavItem";
import NavLink from "reactstrap/lib/NavLink";

const tabStyle: Partial<CSSProperties> = {
  cursor: "pointer",
};

interface IProps {
  activeTab: string;
  setActiveTab(newActiveTab: string): void;
  tabs: Array<ITab>;
}

interface ITab {
  header: string;
  key: string;
  disabled?: boolean;
}

const MerchantOnboardingFormTabs: React.FunctionComponent<IProps> = ({
  activeTab,
  setActiveTab,
  tabs,
}) => {
  return (
    <>
      <Nav tabs style={{ borderBottomStyle: "none" }} pills={true} fill={true}>
        {tabs.map((t) => (
          <NavItem key={t.key}>
            <NavLink
              style={tabStyle}
              className={getStyles(t.key, activeTab)}
              onClick={() => {
                setActiveTab(t.key);
              }}
              disabled={t.disabled}
            >
              <small>{t.header}</small>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </>
  );
};

export default MerchantOnboardingFormTabs;

function getStyles(tab: string, activeTab: string): string | undefined {
  return activeTab === tab ? "active" : "";
}
