export function ResponsiveTableMobileCardGrid({
  className,
  children,
}: {
  className: string;
  children: React.ReactNode;
}) {
  return (
    <div
      className={className + " mobile-card-layout"}
      style={{
        columnGap: "25px",
        rowGap: "5px",
      }}
    >
      {children}
    </div>
  );
}
