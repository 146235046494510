import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { logError } from "../../../services/errorLogger";
import uuidv4 from "uuid/v4";

export interface ISavedCard {
  token: string;
  partialNumber: string;
  expiration: string;
}

export interface ICreditCardInlineProps {
  onSave(savedCard: ISavedCard): void;
  onError(error: string): void;
  creditCardIframeRef: React.MutableRefObject<HTMLIFrameElement | null>;
  visible?: boolean;
  cardOnFile: boolean;
}

const CreditCardInline: React.FunctionComponent<ICreditCardInlineProps> = ({
  onSave,
  onError,
  creditCardIframeRef,
  visible,
  cardOnFile,
}) => {
  const payrixPublicApiKey = useApplicationStateSelector(
    (s) => s.common.payrixPublicApiKey
  );
  const payrixMerchantAccountId = useApplicationStateSelector(
    (s) => s.common.payrixMerchantAccountId
  );
  const isNonProductionEnvironment = useApplicationStateSelector(
    (s) => s.common.isNonProductionEnvironment
  );
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [iframeHeightSet, setIframeHeightSet] = useState(false);
  const componentInstanceKey = useRef(uuidv4());

  useEffect(() => {
    if (visible && !iframeHeightSet) {
      setIframeHeight(creditCardIframeRef, setIframeHeightSet);
    }
  }, [iframeHeightSet, visible, creditCardIframeRef]);

  const handleMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.document.location.origin ||
        event.data.componentInstanceKey !== componentInstanceKey.current
      ) {
        return;
      }

      if (event.data.payfieldsMessage) {
        if (event.data.successMessage) {
          if (!event.data.response || !event.data.response.data) {
            logError("received invalid data response from payfields");
          } else {
            const payrixResponse = event.data.response.data[0];

            let number = "";
            let token = "";
            if (typeof payrixResponse.payment === "object") {
              number = payrixResponse.payment.number;
              token = payrixResponse.token;
            } else {
              number = payrixResponse.token.payment.number;
              token = payrixResponse.token.token;
            }

            onSave({
              expiration: "",
              partialNumber: number,
              token: token,
            });
          }
        } else if (event.data.failureMessage) {
          const response = event.data?.response;
          if (
            response?.errors &&
            response.errors.length > 0 &&
            response.errors[0]?.msg
          ) {
            onError(response.errors[0].msg);
          } else {
            onError("An unknown error occurred saving the credit card.");
            logError(
              "Unknown error tokenizing card data: " +
                JSON.stringify(event.data)
            );
          }
        } else if (event.data.validationFailure) {
          // Validation failure will show message inline
          // Raising error to clear spinner
          onError("");
        }
      }
    },
    [onSave, onError]
  );

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return function cleanup() {
      window.removeEventListener("message", handleMessage);
    };
  });

  const iframeSuffix = !cardOnFile ? "&mode=token" : "";

  return (
    <React.Fragment>
      {!iframeLoaded ? (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faSpinner}
            spin={true}
            size="3x"
            fixedWidth={true}
            data-testid="creditCardSpinner"
          />
        </div>
      ) : null}
      <iframe
        ref={creditCardIframeRef}
        style={{
          visibility: iframeHeightSet ? undefined : "hidden",
          border: 0,
          height: "260px",
          width: "100%",
        }}
        name="paymentFields"
        title="Payment Fields"
        src={`${process.env.PUBLIC_URL}/assets/payfields${
          isNonProductionEnvironment ? "" : "-production"
        }.html?k=${encodeURIComponent(
          payrixPublicApiKey
        )}&m=${encodeURIComponent(
          payrixMerchantAccountId
        )}${iframeSuffix}&componentInstanceKey=${componentInstanceKey.current}`}
        onLoad={() => {
          setIframeLoaded(true);
          setIframeHeight(creditCardIframeRef, setIframeHeightSet);
        }}
      ></iframe>
    </React.Fragment>
  );
};

export default CreditCardInline;

function setIframeHeight(
  creditCardIframeRef: React.MutableRefObject<HTMLIFrameElement | null>,
  setIframeHeightSet: React.Dispatch<React.SetStateAction<boolean>>
) {
  if (
    creditCardIframeRef.current &&
    creditCardIframeRef.current.contentWindow?.document?.body?.scrollHeight
  ) {
    creditCardIframeRef.current.style.height = `${creditCardIframeRef.current.contentWindow?.document.body.scrollHeight}px`;
    setIframeHeightSet(true);
  }
}

export function triggerSave(
  creditCardIframeRef: React.MutableRefObject<HTMLIFrameElement | null>
) {
  if (
    creditCardIframeRef.current &&
    creditCardIframeRef.current.contentWindow
  ) {
    creditCardIframeRef.current.contentWindow.postMessage(
      { submitForm: true },
      window.document.location.origin
    );
    return true;
  }

  return false;
}
