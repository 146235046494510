import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { IFormData as IOneTimeJobFormData } from "../../../containers/app/forms/OneTimeJobForm";
import { IFormData as IMaintenanceJobFormData } from "../../../containers/app/forms/MaintenanceJobForms.types";
import { JobBillingType } from "../../../enums/jobBillingType";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { useHideChildrenIfOtherSubmenusShown } from "../../../hooks/useHideChildrenIfOtherSubmenuShown";
import { actionCreators } from "../../../modules/actionCreators";
import ProjectFormBody from "../../schedule/components/ProjectForm";
import { IOpportunity } from "../models/IOpportunity";
import { IProposalExisting, IProposalLineItem } from "../models/IProposal";
import { opportunitiesActionCreators } from "../modules/opportunity";
import OpportunityDelete from "./OpportunityDelete";
import OpportunityForm from "./OpportunityForm";
import ProposalForm, { IProposalFormProps } from "./ProposalForm";
import ProposalSendForm from "./ProposalSendForm";
import uuidv4 from "uuid/v4";
import { getNumericString } from "../../../services/typeConverter";
import ProposalPrintContainer from "./ProposalPrintContainer";
import { mapToFormLineItems } from "../services/proposalService";
import { ICustomer } from "../../../models/ICustomer";
import { DiscountType } from "../../../enums/DiscountType";
import { getSubtotal } from "../../../services/lineItemService";
import { round } from "../../../services/roundingService";
import { getCopyProposalDefaultValues } from "./OpportunityActions.functions";
import { ILineItem } from "../../../containers/app/components/InvoiceLineItem";
import opportunityDataProvider from "../services/opportunityDataProvider";
import { finalize, timeout } from "rxjs/operators";
import { fullStoryTrack } from "../../../services/fullStoryService";
import { isMobile } from "react-device-detect";
import { builders as routerBuilders } from "../../../services/routing";
import { CustomerRecordTab } from "../../../enums/customerRecordTab";
import { isProjectBilling } from "../../schedule/services/projectService";

interface IProps<TContainerRenderProps = {}> {
  opportunity: IOpportunity;
  proposal: IProposalExisting | null;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  setIsUnarchiving: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: (errorMessage: string) => void;
  customer: ICustomer;
  isProposalLane: boolean;
  renderDropDownContainer(args: {
    children(renderProps: TContainerRenderProps): React.ReactNode;
    isContextMenuOpen: boolean;
    setIsContextMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }): React.ReactNode;
  renderSubMenuParent(args: {
    text: string;
    children: React.ReactNode;
    showChildren: boolean;
    setShowChildren: React.Dispatch<React.SetStateAction<boolean>>;
    containerRenderProps: TContainerRenderProps;
  }): React.ReactNode;
  renderDropDownItem(args: { text: string; onClick(): void }): React.ReactNode;
  renderLink(args: {
    text: string;
    url: string;
    target: string;
    rel: string;
  }): React.ReactNode;
}

enum MultipleJobFormMode {
  project = 0,
  perVisitBilling = 1,
}

export default function CustomerOpportunitiesActions<
  TContainerRenderProps = {}
>({
  opportunity,
  proposal,
  setIsDeleting,
  setIsUnarchiving,
  setErrorMessage,
  customer,
  isProposalLane,
  renderDropDownContainer,
  renderDropDownItem,
  renderSubMenuParent,
  renderLink,
}: IProps<TContainerRenderProps>) {
  const dispatch = useDispatch();

  const [showOpportunityForm, setShowOpportunityForm] = useState(false);
  const [proposalFormArguments, setProposalFormArguments] =
    useState<Partial<IProposalFormProps> | null>(null);
  const [showProposalSendForm, setShowProposalSendForm] = useState(false);
  const [showMultiJobForm, setShowMultiJobForm] = useState<
    { show: true; mode: MultipleJobFormMode } | { show: false }
  >({ show: false });
  const [showDelete, setShowDelete] = useState(false);
  const [showPrint, setShowPrint] = useState(false);
  const [showScheduleChildren, setShowScheduleChildren] = useState(false);
  const [showEditChildren, setShowEditChildren] = useState(false);
  const [showViewChildren, setShowViewChildren] = useState(false);
  const [showCopyChildren, setShowCopyChildren] = useState(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const customerUrlRoot = useApplicationStateSelector(
    (s) => s.common.customerUrlRoot
  );

  const jobs = useApplicationStateSelector((s) => s.job.jobs);
  const maintenanceJobsForProposal = useMemo(
    () =>
      jobs.filter(
        (m) =>
          m.customerId === customer.id &&
          m.opportunityId === proposal?.opportunityId &&
          proposal
      ),
    [jobs, customer, proposal]
  );

  const oneTimeJobs = useApplicationStateSelector((s) => s.job.oneTimeJobs);
  const oneTimeJobsForProposal = useMemo(
    () =>
      oneTimeJobs.filter(
        (j) =>
          j.customerId === customer.id &&
          j.opportunityId === proposal?.opportunityId &&
          proposal
      ),
    [oneTimeJobs, customer, proposal]
  );

  const projects = useApplicationStateSelector((s) => s.project.projects);
  const projectsForProposal = projects.filter(
    (p) =>
      p.customerId === customer.id &&
      isProjectBilling(p) &&
      p.opportunityId === proposal?.id &&
      proposal
  );

  const hasScheduledJobs =
    maintenanceJobsForProposal.length > 0 ||
    oneTimeJobsForProposal.length > 0 ||
    projectsForProposal.length > 0;

  useEffect(() => {
    if (!isContextMenuOpen) {
      setShowScheduleChildren(false);
      setShowEditChildren(false);
      setShowViewChildren(false);
      setShowCopyChildren(false);
    }
  }, [isContextMenuOpen]);

  const editAndCopyAndView = useMemo(
    () => [setShowEditChildren, setShowCopyChildren, setShowViewChildren],
    [setShowEditChildren, setShowCopyChildren, setShowViewChildren]
  );
  const scheduleAndCopyAndView = useMemo(
    () => [setShowScheduleChildren, setShowCopyChildren, setShowViewChildren],
    [setShowScheduleChildren, setShowCopyChildren, setShowViewChildren]
  );
  const editAndSchedule = useMemo(
    () => [setShowScheduleChildren, setShowEditChildren],
    [setShowScheduleChildren, setShowEditChildren]
  );
  const scheduleAndEditAndView = useMemo(
    () => [setShowScheduleChildren, setShowEditChildren, setShowViewChildren],
    [setShowScheduleChildren, setShowEditChildren, setShowViewChildren]
  );

  useHideChildrenIfOtherSubmenusShown(showScheduleChildren, editAndCopyAndView);
  useHideChildrenIfOtherSubmenusShown(showEditChildren, scheduleAndCopyAndView);
  useHideChildrenIfOtherSubmenusShown(showCopyChildren, scheduleAndEditAndView);
  useHideChildrenIfOtherSubmenusShown(showViewChildren, editAndSchedule);

  return (
    <>
      {renderDropDownContainer({
        isContextMenuOpen,
        setIsContextMenuOpen,
        children: (args) => (
          <>
            {!proposal ||
            !!proposal.acceptanceDateTimeUtc ||
            !!proposal.rejectedDateTimeUtc
              ? renderDropDownItem({
                  text: "Edit",
                  onClick: () => {
                    setErrorMessage("");
                    setShowOpportunityForm(true);
                  },
                })
              : renderSubMenuParent({
                  text: "Edit",
                  showChildren: showEditChildren,
                  setShowChildren: setShowEditChildren,
                  containerRenderProps: args,
                  children: (
                    <>
                      {renderDropDownItem({
                        text: "Opportunity",
                        onClick: () => {
                          fullStoryTrack("Edit Opportunity");
                          setErrorMessage("");
                          setShowOpportunityForm(true);
                        },
                      })}
                      {renderDropDownItem({
                        text: "Proposal",
                        onClick: () => {
                          fullStoryTrack("Edit Proposal");
                          setErrorMessage("");
                          setProposalFormArguments({});
                        },
                      })}
                    </>
                  ),
                })}

            {proposal
              ? renderDropDownItem({
                  text: "Copy",
                  onClick: () => {
                    fullStoryTrack("Copy Proposal");
                    setErrorMessage("");
                    setProposalFormArguments({
                      opportunityId: null,
                      proposalId: null,
                      customerId: opportunity.customerId,
                      defaultValues: getCopyProposalDefaultValues(
                        proposal,
                        customer
                      ),
                      hideTemplateField: true,
                    });
                  },
                })
              : null}

            {!proposal ? (
              renderDropDownItem({
                text: "Estimate",
                onClick: () => {
                  fullStoryTrack("Estimate Proposal");
                  setErrorMessage("");
                  setProposalFormArguments({});
                },
              })
            ) : (
              <>
                {renderDropDownItem({
                  text: !!opportunity.lastSentDateTime ? "Resend" : "Send",
                  onClick: () => {
                    fullStoryTrack(
                      !!opportunity.lastSentDateTime
                        ? "Resend Proposal"
                        : "Send Proposal"
                    );
                    setErrorMessage("");
                    setShowProposalSendForm(true);
                  },
                })}
                {proposal.lookupId && !hasScheduledJobs
                  ? renderLink({
                      text: "View",
                      url: `${customerUrlRoot}/proposal/${proposal.lookupId}?ref=admin`,
                      target: "_blank",
                      rel: "noopener noreferrer",
                    })
                  : null}
                {proposal.lookupId && hasScheduledJobs
                  ? renderSubMenuParent({
                      text: "View",
                      showChildren: showViewChildren,
                      setShowChildren: setShowViewChildren,
                      containerRenderProps: args,
                      children: (
                        <>
                          {renderLink({
                            text: "Proposal",
                            url: `${customerUrlRoot}/proposal/${proposal.lookupId}?ref=admin`,
                            target: "_blank",
                            rel: "noopener noreferrer",
                          })}
                          {renderLink({
                            text: "Jobs",
                            url: routerBuilders.manage.buildCustomerDetailsRoute(
                              {
                                customerId: customer.id,
                                activeTab: CustomerRecordTab.jobs,
                                searchText: proposal.proposalNumber,
                              }
                            ),
                            target: "",
                            rel: "",
                          })}
                        </>
                      ),
                    })
                  : null}

                {isMobile
                  ? renderLink({
                      text: "Print",
                      url: routerBuilders.print.buildProposalPrintPage(
                        proposal.id
                      ),
                      target: "_blank",
                      rel: "noopener noreferrer",
                    })
                  : renderDropDownItem({
                      text: "Print",
                      onClick: () => {
                        fullStoryTrack("Print Proposal");
                        setShowPrint(true);
                      },
                    })}
              </>
            )}

            {!opportunity.archived
              ? renderDropDownItem({
                  text: "Archive",
                  onClick: () => {
                    fullStoryTrack("Archive Proposal");
                    setErrorMessage("");
                    dispatch(
                      opportunitiesActionCreators.showArchivePrompt({
                        opportunitiesToArchive: [
                          {
                            customerName: customer?.name ?? "",
                            opportunityId: opportunity.id,
                          },
                        ],
                      })
                    );
                  },
                })
              : null}

            {opportunity.archived
              ? renderDropDownItem({
                  text: "Unarchive",
                  onClick: () => {
                    fullStoryTrack("Unarchive Proposal");
                    setErrorMessage("");
                    setIsUnarchiving(true);
                    opportunityDataProvider
                      .saveOpportunity({
                        id: opportunity.id,
                        archived: false,
                      })
                      .pipe(
                        timeout(10000),
                        finalize(() => {
                          setIsUnarchiving(false);
                        })
                      )
                      .subscribe({
                        next: () => {
                          dispatch(opportunitiesActionCreators.reloadBoard());
                        },

                        error: () => {
                          setErrorMessage(
                            "An unknown error occurred while unarchiving the opportunity."
                          );
                        },
                      });
                  },
                })
              : null}

            {renderDropDownItem({
              text: "Delete",
              onClick: () => {
                fullStoryTrack("Delete Proposal");
                setErrorMessage("");
                setShowDelete(true);
              },
            })}

            {renderSubMenuParent({
              text: "Schedule",
              showChildren: showScheduleChildren,
              setShowChildren: setShowScheduleChildren,
              containerRenderProps: args,
              children: (
                <>
                  {renderDropDownItem({
                    text: "Single Job",
                    onClick: () => {
                      fullStoryTrack("Single Job Schedule Proposal");
                      setErrorMessage("");
                      dispatch(
                        actionCreators.forms.oneTimeJob.showForm({
                          proposalFiles: proposal?.files,
                          proposalJobSummary: proposal?.summary,
                          defaultFormData: buildFormDefaults(
                            opportunity,
                            proposal
                          ) as Partial<IOneTimeJobFormData>,
                        })
                      );
                    },
                  })}

                  {renderDropDownItem({
                    text: "Recurring Job",
                    onClick: () => {
                      fullStoryTrack("Recurring Job Schedule Proposal");
                      setErrorMessage("");
                      dispatch(
                        actionCreators.forms.maintenanceJob.showForm({
                          proposalFiles: proposal?.files,
                          proposalJobSummary: proposal?.summary,
                          defaultFormData: buildFormDefaults(
                            opportunity,
                            proposal
                          ) as Partial<IMaintenanceJobFormData>,
                        })
                      );
                    },
                  })}

                  {renderDropDownItem({
                    text: "Project",
                    onClick: () => {
                      fullStoryTrack("Multiple Jobs Schedule Proposal");
                      setErrorMessage("");
                      setShowMultiJobForm({
                        show: true,
                        mode: MultipleJobFormMode.project,
                      });
                    },
                  })}

                  {renderDropDownItem({
                    text: "Multiple Jobs",
                    onClick: () => {
                      setErrorMessage("");
                      setShowMultiJobForm({
                        show: true,
                        mode: MultipleJobFormMode.perVisitBilling,
                      });
                    },
                  })}
                </>
              ),
            })}
          </>
        ),
      })}

      {showOpportunityForm ? (
        <OpportunityForm
          opportunityId={opportunity.id}
          onSaveComplete={() => setShowOpportunityForm(false)}
          onCancel={() => setShowOpportunityForm(false)}
        />
      ) : null}

      {showMultiJobForm.show ? (
        <ProjectFormBody
          initialCustomerId={opportunity.customerId}
          initialCustomerAdditionalLocationId={
            opportunity.customerAdditionalLocationId
          }
          initialBillingType={getProjectFormInitialBillingType(
            proposal,
            showMultiJobForm.mode
          )}
          initialLineItems={getProjectFormInitialLineITems(
            proposal,
            showMultiJobForm.mode
          )}
          initialTaxRate={proposal?.taxRate ?? null}
          initialDiscount={buildDiscountAmont(proposal)}
          opportunityId={opportunity.id}
          initialPaymentMethodOnFileAuthorized={
            proposal?.paymentMethodOnFileAuthorized ?? false
          }
          initialHideLineItemPrices={proposal?.hideLineItemPrices}
          proposalFiles={proposal?.files ?? []}
          proposalJobSummary={proposal?.summary ?? ""}
          onSaveComplete={() => setShowMultiJobForm({ show: false })}
          onCancel={() => setShowMultiJobForm({ show: false })}
          mode="add"
          billingTypeDisabled={true}
        />
      ) : null}

      {proposalFormArguments !== null ? (
        <ProposalForm
          opportunityId={opportunity.id}
          customerId={null}
          proposalId={proposal?.id ?? null}
          onSaveComplete={() => setProposalFormArguments(null)}
          onCancel={() => setProposalFormArguments(null)}
          {...proposalFormArguments}
        />
      ) : null}

      {showProposalSendForm && proposal ? (
        <ProposalSendForm
          proposalId={proposal.id}
          customerId={opportunity.customerId}
          onClose={() => setShowProposalSendForm(false)}
          defaultReplyToEmailAddress={proposal.replyToEmailAddress}
        />
      ) : null}

      {showDelete ? (
        <OpportunityDelete
          opportunityId={opportunity.id}
          setIsDeleting={setIsDeleting}
          onClose={() => setShowDelete(false)}
          onDeleteComplete={() => {
            dispatch(
              opportunitiesActionCreators.deleteOpportunity({
                opportunityId: opportunity.id,
              })
            );
          }}
          onDeleteError={() => {
            setErrorMessage(
              "An unknown error occurred while deleting the opportunity."
            );
          }}
        />
      ) : null}

      {showPrint ? (
        <ProposalPrintContainer
          proposalId={proposal?.id ?? ""}
          onClose={() => setShowPrint(false)}
        />
      ) : null}
    </>
  );
}

function getProjectFormInitialLineITems(
  proposal: IProposalExisting | null,
  mode: MultipleJobFormMode
) {
  let selectedLineItems = mapLineItem(
    proposal?.lineItems?.filter(
      (li) => !li.optional || (li.optional && li.selected)
    )
  );

  if (mode === MultipleJobFormMode.perVisitBilling) {
    selectedLineItems = selectedLineItems.map((li) => ({
      ...li,
      amountPerItem: "",
      quantity: "",
    }));
  }

  return selectedLineItems;
}

function getProjectFormInitialBillingType(
  proposal: IProposalExisting | null,
  mode: MultipleJobFormMode
): JobBillingType | undefined {
  if (mode === MultipleJobFormMode.perVisitBilling) {
    return getProjectFormInitialLineITems(proposal, mode).length > 0
      ? JobBillingType.PerServiceLineItems
      : JobBillingType.PerServiceTotal;
  } else {
    return getProjectFormInitialLineITems(proposal, mode).length > 0
      ? JobBillingType.AtProjectCompletionLineItems
      : JobBillingType.AtProjectCompletionTotal;
  }
}

function buildDiscountAmont(proposal: IProposalExisting | null) {
  return {
    type: DiscountType.amount,
    amount:
      proposal?.discount?.type === DiscountType.percent
        ? round(
            getSubtotal(mapToFormLineItems(proposal?.lineItems ?? null) ?? []) *
              (proposal?.discount?.percent ?? 0),
            2
          )
        : proposal?.discount?.amount ?? 0,
    percent: null,
  };
}

function buildFormDefaults(
  opportunity: IOpportunity,
  proposal: IProposalExisting | null
) {
  const defaults: Partial<IOneTimeJobFormData | IMaintenanceJobFormData> = {
    customerId: opportunity.customerId,
    customerAdditionalLocationId: opportunity.customerAdditionalLocationId,
    opportunityId: opportunity.id,
  };

  if ((proposal?.lineItems ?? []).length > 0) {
    defaults.billingType = JobBillingType.PerServiceLineItems;
    defaults.lineItems = mapLineItem(
      proposal?.lineItems?.filter(
        (li) => !li.optional || (li.optional && li.selected)
      )
    );
    defaults.taxRate = proposal?.taxRate;
    defaults.discount = buildDiscountAmont(proposal);
  }

  defaults.paymentMethodOnFileAuthorized =
    proposal?.paymentMethodOnFileAuthorized ?? false;

  defaults.hideLineItemPrices = proposal?.hideLineItemPrices ?? false;
  return defaults;
}

function mapLineItem(
  proposalLineItems: Array<IProposalLineItem> | null | undefined
): ILineItem[] {
  return (proposalLineItems ?? []).map((li) => ({
    id: uuidv4(),
    itemId: li.itemId,
    amountPerItem: getNumericString(li.amount),
    name: li.name,
    description: li.description,
    quantity: getNumericString(li.quantity),
    taxable: li.taxable,
    hide: li.hide,
  }));
}
