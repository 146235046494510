export const actionTypes = {
  ROUTER_LOCATION_CHANGE: "@@router/LOCATION_CHANGE",

  CLOSE_PAGE_LEVEL_MESSAGE: "job/CLOSE_PAGE_LEVEL_MESSAGE",

  CLEAR_CUSTOMER_NOTIFICATION_RESULTS:
    "schedule/CLEAR_CUSTOMER_NOTIFICATION_RESULTS",

  AUTO_ROUTE_JOBS_STARTING: "job/AUTO_ROUTE_JOBS_STARTING",
  AUTO_ROUTE_JOBS_COMPLETED: "job/AUTO_ROUTE_JOBS_COMPLETED",
  AUTO_ROUTE_JOBS_ERROR: "job/AUTO_ROUTE_JOBS_ERROR",

  AUTO_ROUTE_JOBS_PERMANENT_SAVE_STARTING:
    "job/AUTO_ROUTE_JOBS_PERMANENT_SAVE_STARTING",
  AUTO_ROUTE_JOBS_PERMANENT_SAVE_COMPLETED:
    "job/AUTO_ROUTE_JOBS_PERMANENT_SAVE_COMPLETED",
  AUTO_ROUTE_JOBS_PROMPT_SAVE_ERROR: "job/AUTO_ROUTE_JOBS_PROMPT_SAVE_ERROR",
  AUTO_ROUTE_JOBS_PROMPT_CLEAR: "job/AUTO_ROUTE_JOBS_PROMPT_CLEAR",

  CUSTOMER_PAYMENT_CONFIGURATION_CLEAR:
    "customer/CUSTOMER_PAYMENT_CONFIGURATION_CLEAR",

  DROP_JOB: "job/DROP_JOB",
  DROP_JOB_UNSCHEDULED_JOBS_PANEL: "job/DROP_JOB_UNSCHEDULED_JOBS_PANEL",
  DROP_JOB_ERROR: "job/DROP_JOB_ERROR",
  DROP_JOB_COMPLETED: "job/DROP_JOB_COMPLETED",

  CUSTOMER_LOAD_COMPLETE: "customer/CUSTOMER_LOAD_COMPLETE",
  CUSTOMER_LOAD_START: "customer/CUSTOMER_LOAD_START",
  CUSTOMER_LOAD_ERROR: "customer/CUSTOMER_LOAD_ERROR",
  CUSTOMER_LOAD_NOT_FOUND: "customer/CUSTOMER_LOAD_NOT_FOUND",
  CUSTOMER_LOAD_ERROR_CLEAR: "customer/CUSTOMER_LOAD_ERROR_CLEAR",

  CUSTOMER_PAYMENT_METHOD_CLEAR: "customer/CUSTOMER_PAYMENT_METHOD_CLEAR",

  ONE_TIME_JOB_LOAD_START: "job/ONE_TIME_JOB_LOAD_START",
  ONE_TIME_JOB_LOAD_COMPLETE: "job/ONE_TIME_JOB_LOAD_COMPLETE",

  MANAGE_CUSTOMERS_SEARCH_START: "managecustomers/SEARCH_START",
  MANAGE_CUSTOMERS_SEARCH_COMPLETE: "managecustomers/SEARCH_COMPLETE",
  MANAGE_CUSTOMERS_SEARCH_ERROR: "managecustomers/SEARCH_ERROR",

  SHIFT_SCHEDULE_COMPLETE_AGGREGATE: "shift/SHIFT_SCHEDULE_COMPLETE_AGGREGATE",

  MAINTENANCE_JOB_LOAD_START: "job/MAINTENANCE_JOB_LOAD_START",
  MAINTENANCE_JOB_LOAD_COMPLETE: "job/MAINTENANCE_JOB_LOAD_COMPLETE",

  INITIAL_LOAD_STARTING: "common/INITIAL_LOAD_STARTING",
  INITIAL_LOAD_COMPLETED: "common/INITIAL_LOAD_COMPLETED",
  INITIAL_LOAD_ERROR: "common/INITIAL_LOAD_ERROR",
  INITIAL_LOAD_UPDATE: "common/INITIAL_LOAD_UPDATE",

  TOGGLE_HEADER_VISIBILITY: "crew/TOGGLE_HEADER_VISIBILITY",

  LOAD_DAY_SCHEDULES_STARTING: "schedule/LOAD_DAY_SCHEDULES_STARTING",
  LOAD_DAY_SCHEDULES_COMPLETED: "schedule/LOAD_DAY_SCHEDULES_COMPLETED",
  LOAD_DAY_SCHEDULES_ERROR: "schedule/LOAD_DAY_SCHEDULES_ERROR",
  LOAD_DAY_SCHEDULES_ERROR_CLEAR: "schedule/LOAD_DAY_SCHEDULES_ERROR_CLEAR",

  MARK_LOCAL_CACHE_STALE: "MARK_LOCAL_CACHE_STALE",

  LOAD_DISTANCES_STARTING: "distancecache/LOAD_DISTANCES_STARTING",
  LOAD_DISTANCES_COMPLETED: "distancecache/LOAD_DISTANCES_COMPLETED",

  CREW_DELETE_START: "crew/DELETE_START",
  CREW_DELETE_COMPLETE: "crew/DELETE_COMPLETE",
  CREW_DELETE_ERROR: "crew/DELETE_ERROR",

  CREW_UPDATE: "crew/UPDATE",

  CREW_MEMBER_DELETE_START: "crewmember/DELETE_START",
  CREW_MEMBER_DELETE_COMPLETE: "crewmember/DELETE_COMPLETE",
  CREW_MEMBER_DELETE_ERROR: "crewmember/DELETE_ERROR",

  JOB_DELETE_START: "job/DELETE_START",
  JOB_DELETE_COMPLETE: "job/DELETE_COMPLETE",
  JOB_DELETE_ERROR: "job/DELETE_ERROR",

  CUSTOMER_ADDITIONAL_LOCATION_DELETE_START:
    "customeradditionallocation/DELETE_START",
  CUSTOMER_ADDITIONAL_LOCATION_DELETE_COMPLETE:
    "customeradditionallocation/DELETE_COMPLETE",
  CUSTOMER_ADDITIONAL_LOCATION_DELETE_ERROR:
    "customeradditionallocation/DELETE_ERROR",

  CUSTOMER_DELETE_START: "customer/DELETE_START",
  CUSTOMER_DELETE_COMPLETE: "customer/DELETE_COMPLETE",
  CUSTOMER_DELETE_ERROR: "customer/DELETE_ERROR",

  CUSTOMER_CLEAR_JOBS_COMPLETE: "customer/CLEAR_JOBS_COMPLETE",

  JOB_INSTANCE_DELETE_START: "job_instance/DELETE_START",
  JOB_INSTANCE_DELETE_COMPLETE: "job_instance/DELETE_COMPLETE",
  JOB_INSTANCE_DELETE_ERROR: "job_instance/DELETE_ERROR",

  TODO_TEMPLATE_DELETE_START: "todotemplate/DELETE_START",
  TODO_TEMPLATE_DELETE_COMPLETE: "todotemplate/DELETE_COMPLETE",
  TODO_TEMPLATE_DELETE_ERROR: "todotemplate/DELETE_ERROR",

  MANGEUI_SHOW_MESSAGE: "manageui/SHOW_MESSAGE",
  MANGEUI_HIDE_MESSAGE: "manageui/HIDE_MESSAGE",

  MANGEUI_SHOW_ERROR: "manageui/SHOW_ERROR",
  MANGEUI_HIDE_ERROR: "manageui/HIDE_ERROR",

  REMOTE_JOBINSTANCE_UPDATED: "remote/JOBINSTANCE_UPDATED",
  REMOTE_JOBINSTANCE_FIELD_UPDATED: "remote/JOBINSTANCE_FIELD_UPDATED",
  REMOTE_DAYSCHEDULE_FIELD_UPDATED: "remote/DAYSCHEDULE_FIELD_UPDATED",
  REMOTE_DAYSCHEDULE_CHANGED: "remote/DAYSCHEDULE_CHANGED",
  REMOTE_JOB_CHANGED: "remote/JOB_CHANGED",
  REMOTE_CUSTOMER_CHANGED: "remote/CUSTOMER_CHANGED",
  REMOTE_CUSTOMER_NOTIFICATION_ADDED: "remote/CUSTOMER_NOTIFICATION_ADDED",
  REMOTE_CREW_CHANGED: "remote/CREW_CHANGED",

  JOB_INSTANCE_TOGGLE_SKIPPED_START: "jobinstance/TOGGLE_SKIPPED_START",
  JOB_INSTANCE_TOGGLE_SKIPPED_COMPLETE: "jobinstance/TOGGLE_SKIPPED_COMPLETE",
  JOB_INSTANCE_TOGGLE_SKIPPED_ERROR: "jobinstance/TOGGLE_SKIPPED_ERROR",

  JOB_INSTANCE_TOGGLE_COMPLETED_START: "jobinstance/TOGGLE_COMPLETED_START",
  JOB_INSTANCE_TOGGLE_COMPLETED_COMPLETE:
    "jobinstance/TOGGLE_COMPLETED_COMPLETE",
  JOB_INSTANCE_TOGGLE_COMPLETED_ERROR: "jobinstance/TOGGLE_COMPLETED_ERROR",

  JOB_INSTANCE_TOGGLE_SELECTED: "jobinstance/TOGGLE_SELECTED",

  JOB_INSTANCE_BULK_UPDATED: "jobinstance/BULK_UPDATED",
  JOB_INSTANCE_TIME_UPDATED: "jobinstance/TIME_UPDATED",
  JOB_INSTANCE_COMPLETION_INFO_UPDATED: "schedule/COMPLETION_INFO_UPDATED",

  CALENDAR_JOB_RESIZE_ERROR: "calendarui/JOB_RESIZE_ERROR",

  PROJECT_SAVE: "project/SAVE",

  JOB_INSTANCE_DRAG_START: "jobinstance/DRAGGING_START",
  JOB_INSTANCE_DRAG_END: "jobinstance/DRAGGING_END",

  QUICKBOOKS_SET_NOT_ENABLED: "quickbooks/SET_NOT_ENABLED",

  MERCHANT_ONBOARDING_SUBMITTED: "merchantonboarding/SUBMITTED",

  CUSTOMER_SELECTION_SEARCH_INPUT: "customer_selection/SEARCH_INPUT",
  CUSTOMER_SELECTION_SEARCH_EMPTY: "customer_selection/SEARCH_EMPTY",
  CUSTOMER_SELECTION_SEARCH_START: "customer_selection/SEARCH_START",
  CUSTOMER_SELECTION_SEARCH_COMPLETE: "customer_selection/SEARCH_COMPLETE",
  CUSTOMER_SELECTION_SEARCH_ERROR: "customer_selection/SEARCH_ERROR",
  CUSTOMER_QUICKBOOKS_LINK_UPDATED: "customer/QUICKBOOKS_LINK_UPDATED",

  BILLING_REPORT_START_LOAD: "billing_report/START_LOAD",
  BILLING_REPORT_ERROR_LOAD: "billing_report/ERROR_LOAD",
  BILLING_REPORT_COMPLETE_LOAD: "billing_report/COMPLETE_LOAD",

  SET_UNSCHEDULEDJOBS_CATEGORYFILTER:
    "scheduleui/SET_UNSCHEDULEDJOBS_CATEGORYFILTER",
  SET_UNSCHEDULEDJOBS_SEARCHTEXTFILTER:
    "scheduleui/SET_UNSCHEDULEDJOBS_SEARCHTEXTFILTER",

  PERMANENT_JOBS_SET_MESSAGE: "scheduleui/SET_PERMANENT_JOBS_MESSAGE",
  PERMANENT_JOBS_CLEAR_MESSAGE: "scheduleui/CLEAR_PERMANENT_JOBS_MESSAGE",
  PERMANENT_JOBS_SAVE_START: "scheduleui/SAVE_PERMANENT_JOBS_START",
  PERMANENT_JOBS_SAVE_COMPLETE: "scheduleui/SAVE_PERMANENT_JOBS_COMPLETE",
  PERMANENT_JOBS_SAVE_ERROR: "scheduleui/SAVE_PERMANENT_JOBS_ERROR",

  CLEAR_CURRENT_GUIDE: "guide/CLEAR_CURRENT_GUIDE",

  CREW_CATEGORY_ADD: "common/CREW_CATEGORY_ADD",

  CANCEL_SUBSCRIPTION: "common/CANCEL_SUBSCRIPTION",

  CUSTOMER_COMMUNICATION_TEMPLATE_UPDATED:
    "common/CUSTOMER_COMMUNICATION_TEMPLATE_UPDATED",
  CUSTOMER_NOTIFICTAION_TEMPLATE_DEFAULTS_UPDATED:
    "common/CUSTOMER_COMMUNICATION_TEMPLATE_DEFAULTS_UPDATED",

  INVOICE_BATCH_EMAIL_COMPLETED: "schedule/INVOICE_BATCH_EMAIL_COMPLETED",

  UNSCHEDULED_JOBS_SET_PAGE: "scheduleui/SET_PAGE",
  UNSCHEDULED_JOBS_SET_DISPLAY_MODE: "scheduleui/SET_DISPLAY_MODE",

  INVOICE_CONFIGURATION_UPDATED: "settings/INVOICE_CONFIGURATION_UDPATED",
  REMINDER_CONFIGURATION_UPDATED: "settings/REMINDER_CONFIGURATION_UPDATED",
  PROPOSAL_EMAIL_CONFIGURATION_UPDATED:
    "settings/PROPOSAL_EMAIL_CONFIGURATION_UPDATED",

  TOLL_FREE_VERIFICATION_SUBMITTED:
    "onboarding/TOLL_FREE_VERIFICATION_SUBMITTED",

  INITIAL_DISPATCH_SENT: "onboarding/INITIAL_DISPATCH_SENT",

  // Used for epics when don't need to return an action
  NULL_ACTION: "null",
};
