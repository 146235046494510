export function getNormalizedPhoneNumber(phoneNumber: string) {
  return phoneNumber === "___-___-____" ? "" : phoneNumber;
}

export function getOptInStatusAfterNumberChange({
  currentOptInStatus,
  originalOptInStatus,
  phoneNumberOptInDirty,
  newNumber,
  originalNumber,
}: {
  currentOptInStatus: boolean;
  originalOptInStatus: boolean;
  phoneNumberOptInDirty: boolean;
  newNumber: string;
  originalNumber: string;
}) {
  if (!phoneNumberOptInDirty) {
    if (newNumber !== originalNumber) {
      return false;
    } else {
      return originalOptInStatus;
    }
  }

  return currentOptInStatus;
}
