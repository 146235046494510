import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TenantSubscriptionStatus } from "../../../models/IInitialLoad";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import LinkButton2 from "./LinkButton2";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { actionCreators, formTypes } from "../../../formGenerator";
import { TollFreeVerificationStatus } from "../../../enums/tollFreeVerificationStatus";
import { isStringSet } from "../../../services/stringService";
import dateService from "../../../services/dateService";
import { TenantPlan } from "../../../enums/tenantPlan";
import { TollFreeVerificationForm } from "../../../slices/tenantSubscription/components/TollFreeVerificationForm";
import { UpgradePlan } from "../../../slices/tenantSubscription/components/UpgradePlan";

export function CustomerNotificationsDisabledAlert({
  parentFormType,
}: {
  parentFormType: keyof typeof formTypes;
}) {
  const tenantSubscriptionStatus = useApplicationStateSelector(
    (s) => s.common.tenantSubscriptionStatus
  );
  const customerTextingRequiresDedicatedNumber = useApplicationStateSelector(
    (s) => s.common.customerTextingRequiresDedicatedNumber
  );
  const tollFreeVerificationStatus = useApplicationStateSelector(
    (s) => s.common.tollFreeVerificationStatus
  );
  const tollFreeVerificationSubmittedDate = useApplicationStateSelector(
    (s) => s.common.tollFreeVerificationSubmittedDate
  );
  const customerTextingAllowedOnBasicPlan = useApplicationStateSelector(
    (s) => s.common.customerTextingAllowedOnBasicPlan
  );
  const tenantPlan = useApplicationStateSelector((s) => s.common.tenantPlan);

  const [showForm, setShowForm] = useState(false);

  const dispatch = useDispatch();

  let message: React.ReactNode;
  const requiresPlanUpgrade =
    !customerTextingAllowedOnBasicPlan && tenantPlan === TenantPlan.basic;
  if (
    tenantSubscriptionStatus !== TenantSubscriptionStatus.Subscribed ||
    requiresPlanUpgrade
  ) {
    message = (
      <>
        Customer text notifications will be enabled once you have subscribed to
        the Plus package. In the meantime, you can explore the messages you'll
        be able to send!
      </>
    );
  } else if (customerTextingRequiresDedicatedNumber) {
    if (tollFreeVerificationStatus === TollFreeVerificationStatus.notStarted) {
      message = (
        <>
          To avoid disruptions sending customer text messages, ensure you apply
          for texting by providing your information{" "}
          <LinkButton2
            buttonContents="here"
            onClick={() => {
              setShowForm(true);
            }}
            inlineButton
            className="text-dark"
            style={{ textDecoration: "underline" }}
            testId="hereButton"
          />
          . Due to phone carrier regulations to reduce spam, text message
          providers are requiring information about businesses sending text
          messages.
        </>
      );
    } else {
      if (isStringSet(tollFreeVerificationSubmittedDate)) {
        message = (
          <>
            On{" "}
            {dateService.formatDateForDisplay(
              tollFreeVerificationSubmittedDate
            )}
            , we submitted a request to our carrier to order & verify a phone
            number for your business so that you can send text messages through
            Crew Control. This process takes 2-4 weeks. You will be notified
            when you can start sending customer notification text messages.
          </>
        );
      } else {
        message = (
          <>
            We submitted a request to our carrier to order & verify a phone
            number for your business so that you can send text messages through
            Crew Control. This process takes 2-4 weeks. You will be notified
            when you can start sending customer notification text messages.
          </>
        );
      }
    }
  } else {
    // Note: Shouldn't ever show but need to handle this somehow in case server-side logic changes
    // we want to still show some message
    message = <>Customer text notifications are not enabled on your account.</>;
  }

  return (
    <>
      <div
        className="alert alert-info p-2"
        data-testid="customerNotificationsDisabledMessage"
      >
        <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
        {message}{" "}
        {requiresPlanUpgrade ? (
          <UpgradePlan
            renderButton={({ onClick }) => (
              <LinkButton2
                testId="upgradeButton"
                inlineButton
                buttonContents="Upgrade to the Plus package."
                className="text-dark"
                style={{ textDecoration: "underline" }}
                onClick={onClick}
              />
            )}
            onRedirectToPortal={(portalUrl) => {
              // Cancel form so the form alert on leave doesn't fire and block user from navigating to stripe portal
              dispatch(actionCreators[parentFormType].cancelForm());

              // Wait for redux dispatched action to update store
              setTimeout(() => {
                window.location.href = portalUrl;
              }, 15);
            }}
          />
        ) : null}
      </div>
      {showForm ? (
        <TollFreeVerificationForm
          onSaveComplete={() => setShowForm(false)}
          onCancel={() => setShowForm(false)}
        />
      ) : null}
    </>
  );
}
