import React from "react";
import { IDaySchedule } from "../../../../../models/IDaySchedule";
import { getSortFunctionForString } from "../../../../../services/sortingService";
import timeService from "../../../../../services/timeService";
import jobFinder from "../../../../../services/jobFinder";
import { round } from "../../../../../services/roundingService";
import { useApplicationStateSelector } from "../../../../../hooks/useApplicationStateSelector";
import { useGetJobGrossAmountAndEstimatedTime } from "../../../../../hooks/useGetJobGrossAmountAndEstimatedTime";

interface IProps {
  dayScheduleToShow: IDaySchedule;
  crewName: string;
}

const fieldContainer: React.CSSProperties = {
  marginRight: "50px",
  marginBottom: "30px",
};

const DayHeader: React.FunctionComponent<IProps> = ({
  dayScheduleToShow,
  crewName,
}) => {
  const crewMembers = useApplicationStateSelector((s) => s.crew.crewMembers);
  const jobs = useApplicationStateSelector((s) => s.job.jobs);
  const oneTimeJobs = useApplicationStateSelector((s) => s.job.oneTimeJobs);
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const weeksUnscheduledMaintenanceJobs = useApplicationStateSelector(
    (s) => s.schedule.weeksUnscheduledMaintenanceJobs
  );
  const getJobGrossAmountAndEstimatedManHours =
    useGetJobGrossAmountAndEstimatedTime();

  const dayEstimatedManHours = dayScheduleToShow.jobInstances.reduce(
    (acc, ji) => {
      const jobFindResult = jobFinder.getJobForDayScheduleV3(
        ji.id,
        jobs,
        oneTimeJobs,
        daySchedules,
        weeksUnscheduledMaintenanceJobs
      );

      let result = acc;
      if (jobFindResult) {
        const { estimatedManHours } = getJobGrossAmountAndEstimatedManHours(
          dayScheduleToShow.date,
          jobFindResult.type,
          jobFindResult,
          ji
        );

        if (
          typeof estimatedManHours === "number" &&
          !isNaN(estimatedManHours)
        ) {
          result = round((acc += estimatedManHours), 2);
        }
      }

      return result;
    },
    0
  );

  return (
    <div className="card bg-secondary" style={{ marginBottom: "30px" }}>
      <div className="card-body">
        <h4 className="card-title">{crewName} - Daily Summary</h4>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={fieldContainer}>
            <h6>Daily note</h6>
            <div style={{ whiteSpace: "pre-wrap" }}>
              {dayScheduleToShow.notes}
            </div>
          </div>
          <div style={fieldContainer}>
            <h6>Crew members</h6>
            {dayScheduleToShow.crewMembers
              .map((cm) => {
                const crewMember = crewMembers.find(
                  (possibleCrewMember) => cm.id === possibleCrewMember.id
                );
                if (crewMember) {
                  return crewMember.name;
                } else {
                  return "";
                }
              })
              .filter((cm) => !!cm)
              .sort(getSortFunctionForString)
              .join(", ")}
          </div>
          <div style={fieldContainer}>
            <h6>Estimated Man Hours</h6>
            <span data-testid="dayEstimatedManHours">
              {dayEstimatedManHours}
            </span>
          </div>
          <div style={fieldContainer}>
            <h6>Actual Man Hours</h6>
            <span data-testid="dayActualManHours">
              {dayScheduleToShow.jobInstances
                .filter((ji) => ji.complete)
                .reduce((acc, ji) => {
                  const actualManHours = timeService.getActualManHours(ji);

                  if (actualManHours) {
                    return round((acc += actualManHours), 2);
                  }

                  return round(acc, 2);
                }, 0)}
            </span>
          </div>
          {dayScheduleToShow.shopClockIn ? (
            <div style={fieldContainer}>
              <h6>Shop times</h6>
              <div>
                {dayScheduleToShow.shopClockIn &&
                dayScheduleToShow.shopClockOut ? (
                  <span>
                    {timeService.formatTimeForDisplay(
                      dayScheduleToShow.shopClockIn
                    )}{" "}
                    -{" "}
                    {timeService.formatTimeForDisplay(
                      dayScheduleToShow.shopClockOut
                    )}
                  </span>
                ) : (
                  timeService.formatTimeForDisplay(
                    dayScheduleToShow.shopClockIn
                  )
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DayHeader;
