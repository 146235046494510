import { IDaySchedule } from "../../../models/IDaySchedule";
import { IUnscheduledMaintenanceJob } from "../../../models/IUnscheduledMaintenanceJob";
import { getUniqueItems } from "../../../services/arrayService";
import { isStringSet } from "../../../services/stringService";

export function getDestinationCrewId({
  daySchedules,
  sourceJobInstanceIds,
  weeksUnscheduledMaintenanceJobs,
}: {
  daySchedules: Array<IDaySchedule>;
  sourceJobInstanceIds: Array<string>;
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>;
}) {
  const sourceCrewIds = getUniqueItems(
    daySchedules
      .filter((daySchedule) =>
        daySchedule.jobInstances.some((jobInstance) =>
          sourceJobInstanceIds.includes(jobInstance.id)
        )
      )
      .map((daySchedule) => daySchedule.crewId)
  );

  const isSourceJobUnscheduled = weeksUnscheduledMaintenanceJobs.some(
    (unscheduledWeek) =>
      unscheduledWeek.jobInstances.some((jobInstance) =>
        sourceJobInstanceIds.includes(jobInstance.id)
      )
  );

  if (isSourceJobUnscheduled) {
    return "";
  }

  if (sourceCrewIds.length === 1 && isStringSet(sourceCrewIds[0])) {
    return sourceCrewIds[0];
  }

  return "";
}
