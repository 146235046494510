import { useState } from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { ITemplatedMessage } from "../../../models/IITemplatedMessage";
import { TemplateVariableValues } from "../../../models/ICustomerNotificationsConfiguration";
import FormContainerWithoutRedux from "./FormContainerWithoutRedux";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { useForm } from "react-hook-form";
import CustomerNotificationsTemplatedMessageBuilder, {
  getTemplateVariableDefaultValues,
} from "./CustomerNotificationsTemplatedMessageBuilder";
import { buildMessage } from "./CustomerNotificationsTemplatedMessage.functions";
import { ICustomerTextTemplateDefaults } from "../../../models/ICustomerTextTemplateDefaults";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import { CrewScheduleType } from "../../../slices/schedule/enums/crewScheduleType";
import { ErrorMessageType } from "./FormContainer";

interface IProps {
  selectedTemplate: ITemplatedMessage;
  onSaveComplete: (saveResult: ICustomerTextTemplateDefaults) => void;
  onCancel: () => void;
}

interface IFormData {
  variableValues: TemplateVariableValues;
  textMessage: string;
}

const CustomerNotificationsTemplatedMessageDefaultsForm: React.FunctionComponent<
  IProps
> = ({ selectedTemplate, onSaveComplete, onCancel }) => {
  const contactPhoneNumber = useApplicationStateSelector(
    (s) => s.common.contactPhoneNumber
  );
  const contactEmailAddress = useApplicationStateSelector(
    (s) => s.common.emailAddress
  );
  const tenantName = useApplicationStateSelector((s) => s.common.tenantName);
  const customerNotificationTemplateDefaults = useApplicationStateSelector(
    (s) => s.common.customerNotificationTemplateDefaults
  );
  const crews = useApplicationStateSelector((s) => s.crew.crews);
  const hasActiveTimeBasedCrews = crews
    .filter((c) => !c.inactive)
    .some((c) => c.scheduleType === CrewScheduleType.time);

  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");
  const { setValue, watch } = useForm<IFormData>({
    defaultValues: getDefaults(),
  });

  const variableValues = watch("variableValues");
  const textMessage = watch("textMessage");
  const dispatch = useDispatch();

  function getDefaults(): IFormData {
    let values = getTemplateVariableDefaultValues(
      customerNotificationTemplateDefaults,
      {} as TemplateVariableValues,
      selectedTemplate,
      contactPhoneNumber,
      contactEmailAddress
    );
    return {
      variableValues: values,
      textMessage: buildMessage({
        selectedTemplate: selectedTemplate,
        hasActiveTimeBasedCrews: hasActiveTimeBasedCrews,
        tenantName: tenantName,
        editedVariables: values,
        hardcodedVariables: {},
      }),
    };
  }

  return (
    <FormContainerWithoutRedux
      formHeader="Template Defaults"
      size="lg"
      formType={FormTypesV2.customerNotificationDefaults}
      save={() => {
        return remoteDataProvider.saveCustomerTextTemplateDefaults(
          selectedTemplate.id,
          variableValues
        );
      }}
      onSaveComplete={(saveResult) => {
        dispatch(
          actionCreators.customerNotificationTemplateDefaultsUpdated(
            selectedTemplate.id,
            saveResult.data
          )
        );
        onSaveComplete(saveResult.data);
      }}
      onCancel={onCancel}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      <CustomerNotificationsTemplatedMessageBuilder
        selectedTemplate={selectedTemplate}
        templateVariables={
          selectedTemplate?.variables?.filter((v) => v.canDefault) ?? []
        }
        variableValues={variableValues}
        messageText={textMessage}
        onChange={(newValue) => {
          setValue("textMessage", newValue.value);
          setValue("variableValues", newValue.variables);
        }}
      />
    </FormContainerWithoutRedux>
  );
};

export default CustomerNotificationsTemplatedMessageDefaultsForm;
