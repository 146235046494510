import { IScheduleColumnHeader } from "../../../containers/app/components/schedule";
import Header from "../../../containers/app/components/schedule/day/Header";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { useLoadDistances } from "../hooks/useLoadDistances";
import { IScheduleColumn } from "./types/IScheduleColumn";
import { useGetJobNameFunction } from "../hooks/useGetJobNameFunction";
import { Calendar, MinuteWindowOptions } from "./Calendar";
import {
  createTimeFromHourMinute,
  getHoursAndJobInstancesInTimeSlots,
  isAvailableTime,
} from "./ScheduleTimeCalendar.functions";
import { DayScheduleWithJobInstancesInTimeSlots } from "./ScheduleTimeCalendar.types";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import { getSortedItemsV2 } from "../../../services/sortingService";
import { ScheduleTimeCalendarJobCard } from "./ScheduleTimeCalendarJobCard";
import { ScheduleTimeCalendarTimeSlot } from "./ScheduleTimeCalendarTimeSlot";
import { IJobInstance } from "../../../models/IJobInstance";
import { IDaySchedule } from "../../../models/IDaySchedule";
import { useState } from "react";

export function ScheduleTimeCalendar({
  mode,
  columnHeaders,
  calendarBlockHeightInPxs,
  setCalendarBlockHeightInPxs,
  onStartMove,
  movingJobInstanceId,
  isAnyJobMoveInProgress,
  draggingJobInstance,
  onTimeSlotFocus,
  jobInstanceShowingDetails,
  setJobInstanceShowingDetails,
  hasJobMovedPosition,
  ...props
}: {
  columns: Array<IScheduleColumn | null>;
  mode: "week" | "day";
  columnHeaders: Array<IScheduleColumnHeader>;
  calendarBlockHeightInPxs: number;
  setCalendarBlockHeightInPxs: (newValue: number) => void;
  onStartMove: (jobInstanceId: string, blockOffset: number) => void;
  movingJobInstanceId: string | null;
  isAnyJobMoveInProgress: boolean;
  draggingJobInstance: {
    jobInstance: IJobInstance;
    daySchedule: IDaySchedule;
  } | null;
  onTimeSlotFocus: (
    dayScheduleId: string,
    hour: number,
    minute: MinuteWindowOptions
  ) => void;
  jobInstanceShowingDetails: string | null;
  setJobInstanceShowingDetails: (newValue: string | null) => void;
  hasJobMovedPosition: boolean;
}) {
  const dispatch = useDispatch();

  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );

  const [jobInstanceShowingContextMenu, setJobInstanceShowingContextMenu] =
    useState<string | null>(null);

  const verifiedColumns = props.columns
    .filter((c) => c !== null)
    .map((c) => c as IScheduleColumn)
    .map((c) => ({
      ...c,
      key: c.dayScheduleId,
    }));

  const { hours, daySchedulesWithJobsInTimeSlots } =
    getHoursAndJobInstancesInTimeSlots({
      daySchedules,
      verifiedColumns,
      draggingJobInstance,
    });
  return (
    <>
      <Calendar<IScheduleColumn & { key: string }>
        calendarBlockHeightInPxs={calendarBlockHeightInPxs}
        setCalendarBlockHeightInPxs={setCalendarBlockHeightInPxs}
        onMouseOver={({ column, hour, minuteWindow }) => {
          onTimeSlotFocus(column.dayScheduleId, hour, minuteWindow);
        }}
        columns={verifiedColumns}
        hours={hours}
        onTimeSlotClicked={({ column, hour, minuteWindow }) => {
          if (
            jobInstanceShowingDetails === null &&
            jobInstanceShowingContextMenu === null
          ) {
            dispatch(
              actionCreators.forms.oneTimeJob.showForm({
                defaultFormData: {
                  crewId: column.crew.id,
                  date: column.date,
                  startTime: createTimeFromHourMinute(hour, minuteWindow),
                },
              })
            );
          }
        }}
        getHeaderContents={({ column, columnIndex, isLastColumn }) => (
          <ScheduleDayHeader
            column={column}
            columnIndex={columnIndex}
            mode={mode}
            columnHeaders={columnHeaders}
            isLastColumn={isLastColumn}
          />
        )}
        getSpacerContents={({ column }) => (
          <ScheduleDaySpacerContents
            column={column}
            daySchedulesWithJobsInTimeSlots={daySchedulesWithJobsInTimeSlots}
            calendarBlockHeightInPxs={calendarBlockHeightInPxs}
            movingJobInstanceId={movingJobInstanceId}
            onStartMove={onStartMove}
            isAnyJobMoveInProgress={isAnyJobMoveInProgress}
            jobInstanceShowingDetails={jobInstanceShowingDetails}
            setJobInstanceShowingDetails={setJobInstanceShowingDetails}
            jobInstanceShowingContextMenu={jobInstanceShowingContextMenu}
            setJobInstanceShowingContextMenu={setJobInstanceShowingContextMenu}
            hasJobMovedPosition={hasJobMovedPosition}
          />
        )}
        getTimeSlotContents={({ column, hour, minuteWindow }) => (
          <ScheduleTimeCalendarTimeSlot
            column={column}
            hour={hour}
            minuteWindow={minuteWindow}
            calendarBlockHeightInPxs={calendarBlockHeightInPxs}
            daySchedulesWithJobsInTimeSlots={daySchedulesWithJobsInTimeSlots}
            lastHour={hours.length > 0 ? hours[hours.length - 1] : null}
            movingJobInstanceId={movingJobInstanceId}
            onStartMove={onStartMove}
            isAnyJobMoveInProgress={isAnyJobMoveInProgress}
            jobInstanceShowingDetails={jobInstanceShowingDetails}
            setJobInstanceShowingDetails={setJobInstanceShowingDetails}
            jobInstanceShowingContextMenu={jobInstanceShowingContextMenu}
            setJobInstanceShowingContextMenu={setJobInstanceShowingContextMenu}
            hasJobMovedPosition={hasJobMovedPosition}
          />
        )}
        getIsBlocked={({ column, hour, minuteWindow }) => {
          const result = isAvailableTime({
            crew: column.crew,
            hour,
            minute: minuteWindow,
          });

          return !result;
        }}
      />
    </>
  );
}

function ScheduleDaySpacerContents({
  column,
  calendarBlockHeightInPxs,
  daySchedulesWithJobsInTimeSlots,
  onStartMove,
  movingJobInstanceId,
  isAnyJobMoveInProgress,
  jobInstanceShowingDetails,
  setJobInstanceShowingDetails,
  jobInstanceShowingContextMenu,
  setJobInstanceShowingContextMenu,
  hasJobMovedPosition,
}: {
  column: IScheduleColumn;
  calendarBlockHeightInPxs: number;
  daySchedulesWithJobsInTimeSlots: Array<DayScheduleWithJobInstancesInTimeSlots>;
  onStartMove: (jobInstanceId: string, blockOffset: number) => void;
  movingJobInstanceId: string | null;
  isAnyJobMoveInProgress: boolean;
  jobInstanceShowingDetails: string | null;
  setJobInstanceShowingDetails: (newValue: string | null) => void;
  jobInstanceShowingContextMenu: string | null;
  setJobInstanceShowingContextMenu: (newValue: string | null) => void;
  hasJobMovedPosition: boolean;
}) {
  const getJobName = useGetJobNameFunction({
    fallbackToAddressIfAdditionalLocationNameNotSet: true,
  });

  const daySchedule = daySchedulesWithJobsInTimeSlots.find(
    (ds) => ds.id === column.dayScheduleId
  );
  const jobsWithoutTimes = getSortedItemsV2(
    (daySchedule?.jobInstancesInTimeSlots ?? [])
      .filter(
        (jobInstanceWithTimeSlot) =>
          !jobInstanceWithTimeSlot.startTime || !jobInstanceWithTimeSlot.endTime
      )
      .map((jobInstanceWithTimeSlot) => {
        return {
          ...jobInstanceWithTimeSlot,
          customerName: getJobName(jobInstanceWithTimeSlot.jobInstance).jobName,
        };
      }),
    ["customerName"]
  );

  return (
    <>
      {jobsWithoutTimes.map((jobInstanceWithTimeSlot) => (
        <div
          className="my-1"
          key={jobInstanceWithTimeSlot.jobInstance.id}
          style={{ width: "100%" }}
        >
          <ScheduleTimeCalendarJobCard
            jobInstanceWithTimeSlot={jobInstanceWithTimeSlot}
            calendarBlockHeightInPxs={calendarBlockHeightInPxs}
            missingTime={true}
            jobIndex={0}
            onResizeStart={() => {}}
            onResizeEnd={() => {}}
            timeSlotStartTime={null}
            blocksFromCalendarEnd={null}
            onStartMove={onStartMove}
            isDragging={
              movingJobInstanceId === jobInstanceWithTimeSlot.jobInstance.id
            }
            isAnyJobMoveInProgress={isAnyJobMoveInProgress}
            crew={column.crew}
            date={column.date}
            jobInstanceShowingDetails={jobInstanceShowingDetails}
            setJobInstanceShowingDetails={setJobInstanceShowingDetails}
            isDragPlaceholderJob={false}
            jobInstanceShowingContextMenu={jobInstanceShowingContextMenu}
            setJobInstanceShowingContextMenu={setJobInstanceShowingContextMenu}
            hasJobMovedPosition={hasJobMovedPosition}
          />
        </div>
      ))}
    </>
  );
}

function ScheduleDayHeader({
  column,
  columnIndex,
  mode,
  columnHeaders,
  isLastColumn,
}: {
  column: IScheduleColumn;
  columnIndex: number;
  mode: "week" | "day";
  columnHeaders: Array<IScheduleColumnHeader>;
  isLastColumn: boolean;
}) {
  const { hasJobsMissingLocationsOuter, hasCrewMissingLocation } =
    useLoadDistances({
      dayScheduleId: column.dayScheduleId,
      mode,
      isMapVisible: column.isMapOpen,
      crew: column.crew,
    });

  return (
    <Header
      additionalLink={column.additionalLink}
      columnHeader={columnHeaders[columnIndex].columnHeader}
      dayScheduleId={column.dayScheduleId}
      crew={column.crew}
      date={column.date}
      isMapVisible={column.isMapOpen}
      mode={mode}
      mapLinkProps={column.mapLinkProps}
      hasCrewMissingLocation={hasCrewMissingLocation}
      highlightHeader={columnHeaders[columnIndex].highlightHeader}
      isCalendarHeader={true}
      isLastColumn={isLastColumn}
      hasJobsMissingLocationsOuter={hasJobsMissingLocationsOuter}
      hideSelectClearAllButton={false}
    />
  );
}
