import React, { CSSProperties, useMemo } from "react";
import ServerLoadedContent from "../../containers/app/components/ServerLoadedContent";
import { ServerLoadedListContent } from "./ServerLoadedListContent";
import { TableColumns } from "./TableColumn";
import RowProperties from "./rowProperties";
import { Breakpoint } from "./ResponsiveTable";

interface IProps<T> {
  header: string | null;
  dataType: string;
  data: Array<T> | null;
  errorLoading: boolean;
  errorMessage?: string | null;
  loadingData: boolean;
  refreshData: () => void;
  filter: React.ReactNode;
  columns: TableColumns<T>;
  largeHeader?: boolean;
  dataNotLoaded?: boolean;
  dataNotLoadedContent?: React.ReactNode;
  tableTestId?: string;
  responsiveTableContainerStyle?: Partial<CSSProperties>;
  hasFilterError?: boolean;
  hasMoreResults?: boolean;
  showContentWhileRefreshing: boolean;
  breakpoint?: Breakpoint;
  alwaysShowTable?: boolean;
}

const ServerLoadedList: <T extends RowProperties>(
  p: IProps<T>
) => React.ReactElement<IProps<T>> = ({
  header,
  largeHeader,
  data,
  refreshData,
  filter,
  columns,
  errorLoading,
  errorMessage,
  loadingData,
  dataType,
  dataNotLoaded,
  dataNotLoadedContent,
  tableTestId,
  responsiveTableContainerStyle,
  hasFilterError,
  hasMoreResults,
  showContentWhileRefreshing,
  breakpoint,
  alwaysShowTable,
}) => {
  // Only show result counts when the result count is greater than the data length.
  let dataLength = data?.length ?? null;

  const visibleColumns = useMemo(
    () => columns.filter((c) => typeof c.hidden !== "boolean" || !c.hidden),
    [columns]
  );

  return (
    <ServerLoadedContent
      header={header}
      largeHeader={largeHeader}
      dataLength={dataLength}
      dataType={dataType}
      errorLoading={errorLoading}
      errorMessage={errorMessage}
      filter={filter}
      loadingData={loadingData}
      refreshData={refreshData}
      dataLoadNotStarted={dataNotLoaded}
      dataNotLoadedContent={dataNotLoadedContent}
      hasFilterError={hasFilterError}
      hasInitialLoadCompleted={data !== null}
      showContentWhileRefreshing={showContentWhileRefreshing}
    >
      <ServerLoadedListContent
        data={data}
        visibleColumns={visibleColumns}
        tableTestId={tableTestId}
        hasMoreResults={hasMoreResults}
        breakpoint={breakpoint}
        alwaysShowTable={alwaysShowTable}
        responsiveTableContainerStyle={responsiveTableContainerStyle}
      />
    </ServerLoadedContent>
  );
};

export default ServerLoadedList;
