export default function OneTimeJobFormProjectBillingNotice({
  projectId,
}: {
  projectId: string;
}) {
  return (
    <>
      <div className="my-3">
        <div className="font-weight-light">
          This visit is part of a project. Please edit the project to configure
          billing on the project.
        </div>
      </div>
    </>
  );
}
