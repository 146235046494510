export function LocalStorageNotEnabledError() {
  return (
    <div className="container pt-5">
      <div className="jumbotron">
        <h1 className="display-4">Cookies Are Required</h1>
        <p className="lead">
          Cookies must be enabled for this application to work. Please enable
          cookies for this site. If you have any questions, email support at{" "}
          <a href="mailto:crewcontrol@youraspire.com">
            crewcontrol@youraspire.com
          </a>
          .
        </p>
      </div>
    </div>
  );
}
