import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getNormalizedPhoneNumber } from "../../../services/phoneNumberService";
import { isStringSet } from "../../../services/stringService";

export default function SmsOptInCheckbox({
  phoneNumber,
  checked,
  onCheckedChange,
  alwaysVisible,
  required,
}: {
  phoneNumber: string;
  checked: boolean;
  onCheckedChange(newChecked: boolean): void;
  alwaysVisible?: boolean;
  required?: boolean;
}) {
  if (!isStringSet(getNormalizedPhoneNumber(phoneNumber)) && !alwaysVisible) {
    return null;
  }

  return (
    <div className="form-group" data-testid="smsOptInCheckboxContainer">
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id="optin"
          name="optin"
          checked={checked}
          onChange={(e) => onCheckedChange(e.currentTarget.checked)}
          required={required}
        />
        <label className="custom-control-label" htmlFor="optin">
          Owner of this phone number has opted-in to receive texts and been
          informed:
        </label>
      </div>
      <div>
        <small className="font-weight-light">
          <ul>
            <li>Message and data rates may apply</li>
            <li>Messages may be sent every day</li>
            <li>Can reply to messages with HELP for help or STOP to cancel</li>
          </ul>
        </small>
      </div>
      <div
        className="alert alert-info"
        style={{ padding: "5px", paddingLeft: "10px" }}
      >
        <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
        <small>
          Please ensure you have the consent of the phone number owner to
          receive text messages. The phone number will not receive text messages
          if the above box is not checked.
        </small>
      </div>
    </div>
  );
}
