import { Action } from "@reduxjs/toolkit";
import {
  createSpecificActionTypeName,
  formTypes,
  actionTypes as formActionTypes,
} from "../../../../../formGenerator";
import { JobFormSaveCompleteAction } from "./JobFormSaveCompleteAction";

export function isOneTimeJobFormSaveComplete(
  action: Action<any>
): action is JobFormSaveCompleteAction {
  return (
    action.type ===
    createSpecificActionTypeName(
      formTypes.oneTimeJob,
      formActionTypes.completeSaving
    )
  );
}
