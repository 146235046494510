import React, { useState, useEffect, useRef, CSSProperties } from "react";
import dateService from "../../../../services/dateService";
import { IJobHistoryInstance } from "../../../../models/IJobHistory";
import remoteDataProvider from "../../../../services/remoteDataProvider";
import { timeout } from "rxjs/operators";
import LinkButton from "../../components/LinkButton";
import {
  GetUserSettingsType,
  useUserSettings,
} from "../../../../services/userSettingsService";
import { UserSettingsType } from "../../../../enums/userSettingsType";
import NotesModal from "../completedWork/NotesModal";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { SortDirection } from "../../../../enums/sortDirection";
import SortColumn from "../../components/SortColumn";
import { getSortedItemsV2 } from "../../../../services/sortingService";
import { getCrewNameForJobVisit } from "../../../../services/customerService";
import { ICrew } from "../../../../models/ICrew";
import timeService from "../../../../services/timeService";
import { trackTrace } from "../../../../services/applicationInsights";
import { ICustomerAdditionalLocation } from "../../../../models/ICustomerAdditionalLocation";
import addressFormatter from "../../../../services/addressFormatter";
import { ICustomer } from "../../../../models/ICustomer";
import { DateFilterOptions } from "../../../../enums/dateFilterOptions";
import ServerLoadedList from "../../../../libraries/tableLayout/ServerLoadedList";
import { formatCurrency } from "../../../../services/currencyFormatter";
import { getTimeRangesForDisplay } from "../../../../services/jobInstanceService";
import CustomerDetailsHistoryCrewSize from "./CustomerDetailsHistoryCrewSize";
import { getCategories } from "../../../../services/crewCategoryService";
import { getErrorMessageFromError } from "../../../../services/httpErrorHandler";
import { isStringSet } from "../../../../services/stringService";
import { CrewScheduleType } from "../../../../slices/schedule/enums/crewScheduleType";
import CustomerAdditionalLocationSelection from "../../components/CustomerAdditionalLocationSelection";
import { builders as routerBuilders } from "../../../../services/routing";
import { Link } from "react-router-dom";
import DateFilterHeadless from "../../components/DateFilterHeadless";
import { useIsResolution } from "../../../../hooks/useIsResolution";
import { updateDatesOnFilter } from "../../../../services/dateFilterService";

interface IProps {
  customerId: string;
  customerName: string;
}

interface IStickyFilters {
  frequency: DateFilterOptions;
  startingDate: string | null;
  endingDate: string | null;
}

interface IFilters {
  category: string;
  locationId: string | null;
}

interface ISortColumn {
  column: SortColumns;
  direction: SortDirection;
}

export interface IJobHistoryInstanceWithCalculatedProps
  extends IJobHistoryInstance {
  crewName: string;
  actualManHours: number | null;
  locationName: string | null;
  formattedAddress: string;
  scheduleTimes: string;
}

enum SortColumns {
  Address,
  VisitDate,
  ManHours,
  CrewSize,
  Revenue,
}

const CustomerDetailsHistory: React.FunctionComponent<IProps> = ({
  customerId,
  customerName,
}) => {
  const { getUserSettings, setUserSettings } = useUserSettings();

  const [loadingData, setLoadingData] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [jobHistoryStickyFilters, setJobHistoryStickyFilters] =
    useState<IStickyFilters>(getDefaultFilters(getUserSettings));
  const lastJobHistoryStickyFilters = useRef(jobHistoryStickyFilters);

  const [jobHistoryFilters, setJobHistoryFilters] = useState<IFilters>({
    category: "",
    locationId: null,
  });

  const lastCustomerId = useRef("");
  const lastJobHistoryFilters = useRef(jobHistoryFilters);
  const [jobHistoryInstances, setJobHistoryInstances] =
    useState<Array<IJobHistoryInstanceWithCalculatedProps> | null>(null);
  const [notesToView, setNotesToVist] = useState<IJobHistoryInstance | null>(
    null
  );
  const imagePrefix = useApplicationStateSelector((s) => s.common.imagePrefix);

  const [currentSortColumn, setCurrentSortColumn] =
    useState<SortColumns | null>(
      getDefaultSortColumn(getUserSettings)?.column ?? null
    );
  const [currentSortDirection, setCurrentSortDirection] =
    useState<SortDirection>(
      getDefaultSortColumn(getUserSettings)?.direction ??
        SortDirection.Ascending
    );
  const crews = useApplicationStateSelector((s) => s.crew.crews);
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );
  const categories = useApplicationStateSelector(
    (s) => s.common.crewCategories
  );

  const breakpoint = "lg";
  const isDesktopView = useIsResolution(breakpoint);

  useEffect(() => {
    if (
      customerId !== lastCustomerId.current ||
      jobHistoryFilters !== lastJobHistoryFilters.current ||
      jobHistoryStickyFilters !== lastJobHistoryStickyFilters.current
    ) {
      loadData(
        crews,
        customers,
        customerAdditionalLocations,
        jobHistoryStickyFilters,
        jobHistoryFilters,
        customerId,
        setLoadingData,
        setErrorMessage,
        setJobHistoryInstances
      );

      lastCustomerId.current = customerId;
      lastJobHistoryFilters.current = jobHistoryFilters;
      lastJobHistoryStickyFilters.current = jobHistoryStickyFilters;
    }
  }, [
    customerId,
    crews,
    customers,
    customerAdditionalLocations,
    setJobHistoryInstances,
    setLoadingData,
    setErrorMessage,
    jobHistoryStickyFilters,
    jobHistoryFilters,
    currentSortColumn,
    currentSortDirection,
  ]);

  const getSortColumn = (displayName: string, sortColumn: SortColumns) => (
    <SortColumn<SortColumns>
      displayName={displayName}
      columnSortProperty={sortColumn}
      currentSortProperty={currentSortColumn}
      currentSortDirection={currentSortDirection}
      onSortDirectionChange={(newSortColumn, newSortDirection) => {
        setCurrentSortColumn(newSortColumn);
        setCurrentSortDirection(newSortDirection);
        setUserSettings(UserSettingsType.customerHistorySortColumn, {
          column: newSortColumn,
          direction: newSortDirection,
        } as ISortColumn);
      }}
    />
  );

  let sortColumn = currentSortColumn;
  let sortDirection = currentSortDirection;
  if (!isDesktopView) {
    sortColumn = SortColumns.VisitDate;
    sortDirection = SortDirection.Descending;
  }

  return (
    <>
      <ServerLoadedList<IJobHistoryInstanceWithCalculatedProps>
        tableTestId="job-history-table"
        header={null}
        dataType="jobs"
        breakpoint={breakpoint}
        errorLoading={errorMessage !== null}
        errorMessage={errorMessage}
        loadingData={loadingData}
        showContentWhileRefreshing={false}
        data={getSortedItems(
          jobHistoryInstances ?? [],
          sortColumn,
          sortDirection
        )}
        refreshData={() =>
          loadData(
            crews,
            customers,
            customerAdditionalLocations,
            jobHistoryStickyFilters,
            jobHistoryFilters,
            customerId,
            setLoadingData,
            setErrorMessage,
            setJobHistoryInstances
          )
        }
        filter={
          <Filters
            customerId={customerId}
            dateFilters={jobHistoryStickyFilters}
            jobHistoryFilters={jobHistoryFilters}
            setDateFilters={setJobHistoryStickyFilters}
            setJobHistoryFilters={setJobHistoryFilters}
          />
        }
        columns={[
          {
            key: "address",
            header: ({ displayType }) =>
              displayType === "desktop"
                ? getSortColumn("Address", SortColumns.Address)
                : "Address",
            cell: ({ row: jobHistoryInstance }) => (
              <>
                {jobHistoryInstance.locationName ? (
                  <span>{jobHistoryInstance.locationName} - </span>
                ) : null}
                {jobHistoryInstance.formattedAddress}
              </>
            ),
            style: {
              maxWidth: "125px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          },
          {
            key: "serviceType",
            header: "Service type",
            cell: ({ row: jobHistoryInstance }) =>
              getCategories(jobHistoryInstance.categories, categories)
                .map((c) => c.name)
                .join(", "),
          },
          {
            key: "visitDate",
            header: ({ displayType }) =>
              displayType === "desktop"
                ? getSortColumn("Visit date", SortColumns.VisitDate)
                : "Visit date",
            testId: "visitDate",
            cell: ({ row: jobHistoryInstance }) => (
              <>
                <Link
                  to={routerBuilders.schedule.buildWeekRoute(
                    getUserSettings,
                    crews,
                    jobHistoryInstance.date,
                    jobHistoryInstance?.crewId ?? ""
                  )}
                >
                  {dateService.formatDateForDisplay(jobHistoryInstance.date)}
                  {jobHistoryInstance.crewName ? (
                    <div>{jobHistoryInstance.crewName}</div>
                  ) : null}
                </Link>
              </>
            ),
          },
          {
            key: "notes",
            header: "Notes",
            cell: ({ row: jobHistoryInstance }) =>
              jobHistoryInstance.showNotesLink ? (
                <LinkButton
                  id={`notes_${jobHistoryInstance.id}`}
                  onClick={() => {
                    setNotesToVist(jobHistoryInstance);
                  }}
                  linkText="View"
                  hrefForDisplay="notes"
                />
              ) : null,
          },
          {
            key: "manHours",
            header: ({ displayType }) =>
              displayType === "desktop"
                ? getSortColumn("Man hours", SortColumns.ManHours)
                : "Man hours",
            cell: ({ row: jobHistoryInstance }) => (
              <>
                {jobHistoryInstance.estimatedManHours ? (
                  <div>Estimated {jobHistoryInstance.estimatedManHours}</div>
                ) : null}
                {jobHistoryInstance.actualManHours ? (
                  <div>Actual {jobHistoryInstance.actualManHours}</div>
                ) : null}
              </>
            ),
            hidden: ({ row: jobHistoryInstance }) =>
              !jobHistoryInstance.estimatedManHours &&
              !jobHistoryInstance.actualManHours,
          },
          {
            key: "crewSize",
            header: ({ displayType }) =>
              displayType === "desktop"
                ? getSortColumn("Crew size", SortColumns.CrewSize)
                : "Crew size",
            cell: ({ row: jobHistoryInstance, displayType }) => (
              <CustomerDetailsHistoryCrewSize
                jobHistoryInstance={jobHistoryInstance}
                idDifferentiator={displayType}
              />
            ),
            hidden: ({ row: jobHistoryInstance }) =>
              jobHistoryInstance.crewMembers.length === 0 &&
              !timeService.getCrewSize(jobHistoryInstance),
          },
          {
            key: "timeRanges",
            header: "Time range(s)",
            cell: ({ row: jobHistoryInstance }) =>
              getTimeRangesForDisplay(
                jobHistoryInstance.timeRanges,
                (id, output) => <div key={id}>{output}</div>,
                ""
              ),
          },
          {
            key: "scheduledTimeRanges",
            header: "Scheduled time range",
            testId: "scheduledTimeRanges",
            cell: ({ row: jobHistoryInstance }) =>
              jobHistoryInstance.scheduleTimes,
            hidden: !(
              jobHistoryInstances?.some((ji) =>
                isStringSet(ji.scheduleTimes)
              ) ?? false
            ),
          },
          {
            key: "revenue",
            header: ({ displayType }) =>
              displayType === "desktop"
                ? getSortColumn("Amount", SortColumns.Revenue)
                : "Amount",
            cell: ({ row: jobHistoryInstance }) =>
              jobHistoryInstance.grossRevenuePerVisit
                ? formatCurrency(jobHistoryInstance.grossRevenuePerVisit)
                : null,
            headerStyle: { textAlign: "right" },
            style: { textAlign: "right" },
          },
        ]}
      />
      <NotesModal
        customerForNotes={
          notesToView
            ? {
                name: customerName,
                jobInstances: [notesToView],
              }
            : null
        }
        closeNotes={() => setNotesToVist(null)}
        hideDatesOnFieldHeaders={true}
        imagePrefix={imagePrefix as string}
        formatDateForReportDisplay={(input) => {
          return dateService.formatDateForDisplay(input);
        }}
      />
    </>
  );
};

export default CustomerDetailsHistory;

function loadData(
  crews: Array<ICrew>,
  customers: Array<ICustomer>,
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>,
  jobHistoryStickyFilters: IStickyFilters,
  jobHistoryFilters: IFilters,
  customerId: string,
  setLoadingData: (v: boolean) => void,
  setErrorMessage: (v: string | null) => void,
  setJobHistory: (v: Array<IJobHistoryInstanceWithCalculatedProps>) => void
) {
  setLoadingData(true);

  return remoteDataProvider
    .getJobHistory(
      customerId,
      jobHistoryStickyFilters.startingDate ?? "",
      jobHistoryStickyFilters.endingDate ?? "",
      jobHistoryFilters.category,
      jobHistoryFilters.locationId
    )
    .pipe(timeout(30000))
    .subscribe(
      (result) => {
        setLoadingData(false);
        setJobHistory(
          result.jobInstances.map((j) =>
            setJobHistoryInstanceCalculatedProps(
              customerId,
              crews,
              customers,
              customerAdditionalLocations,
              j
            )
          )
        );
        setErrorMessage(null);
      },
      (err) => {
        setLoadingData(false);
        setErrorMessage(getErrorMessageFromError(err, ""));
      }
    );
}

function setJobHistoryInstanceCalculatedProps(
  customerId: string,
  crews: Array<ICrew>,
  customers: Array<ICustomer>,
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>,
  jobHistoryInstance: IJobHistoryInstance
): IJobHistoryInstanceWithCalculatedProps {
  const actualManHours = timeService.getActualManHours(jobHistoryInstance);

  const crewName = getCrewNameForJobVisit(
    crews,
    jobHistoryInstance.crewId,
    jobHistoryInstance.isInWeeksFlexibleJobsHolder
  );

  let locationName: string | null = null;
  if (jobHistoryInstance.additionalLocationId) {
    const location = customerAdditionalLocations.find(
      (l) => l.id === jobHistoryInstance.additionalLocationId
    );
    if (location) {
      if (location.name) {
        locationName = location.name;
      }
    } else {
      trackTrace(
        `job history - could not find additional location for ${jobHistoryInstance.additionalLocationId}`
      );
    }
  }

  const formattedAddress = addressFormatter.formatAddressForJob(
    {
      id: jobHistoryInstance.id,
      customerId: customerId,
      customerAdditionalLocationId: jobHistoryInstance.additionalLocationId,
    },
    customers,
    customerAdditionalLocations
  );

  let scheduleTimes: string = "";
  const crew = crews.find((c) => c.id === jobHistoryInstance.crewId);
  if (
    crew?.scheduleType === CrewScheduleType.time &&
    isStringSet(jobHistoryInstance.scheduledStartTime) &&
    isStringSet(jobHistoryInstance.scheduledEndTime)
  ) {
    scheduleTimes = `${dateService.formatTimeForDisplay(
      jobHistoryInstance.scheduledStartTime
    )} - ${dateService.formatTimeForDisplay(
      jobHistoryInstance.scheduledEndTime
    )}`;
  }

  return {
    ...jobHistoryInstance,
    crewName,
    actualManHours,
    locationName,
    formattedAddress,
    scheduleTimes,
  };
}

function getDefaultFilters(
  getUserSettings: GetUserSettingsType
): IStickyFilters {
  const savedSettings = getUserSettings<IStickyFilters>(
    UserSettingsType.jobHistoryFilters
  );
  if (savedSettings) {
    if (savedSettings.frequency !== DateFilterOptions.custom) {
      const dates = dateService.getDatesFromDateFilterOptions(
        savedSettings.frequency
      );
      return {
        ...savedSettings,
        startingDate: dates.startingDate,
        endingDate: dates.endingDate,
      };
    }
    return savedSettings;
  }

  return {
    frequency: DateFilterOptions.last30Days,
    startingDate: dateService.getDatesFromDateFilterOptions(
      DateFilterOptions.last30Days
    ).startingDate,
    endingDate: dateService.getDatesFromDateFilterOptions(
      DateFilterOptions.last30Days
    ).endingDate,
  };
}

function getDefaultSortColumn(
  getUserSettings: GetUserSettingsType
): ISortColumn | null {
  const savedSetting = getUserSettings<ISortColumn>(
    UserSettingsType.customerHistorySortColumn
  );
  return savedSetting;
}

function getSortedItems(
  jobInstances: Array<IJobHistoryInstanceWithCalculatedProps>,
  sortColumn: SortColumns | null,
  sortDirection: SortDirection
) {
  if (sortColumn === null) {
    return jobInstances;
  }

  let sortProperties: Array<
    | keyof IJobHistoryInstanceWithCalculatedProps
    | ((i: IJobHistoryInstanceWithCalculatedProps) => string | number)
  > = [];
  switch (sortColumn) {
    case SortColumns.Address:
      sortProperties = ["locationName", "formattedAddress"];
      break;
    case SortColumns.VisitDate:
      sortProperties = ["date"];
      break;
    case SortColumns.ManHours:
      sortProperties = ["estimatedManHours", "actualManHours"];
      break;
    case SortColumns.CrewSize:
      sortProperties = [
        (ji: IJobHistoryInstanceWithCalculatedProps) =>
          timeService.getCrewSize(ji) ?? 0,
      ];
      break;
    case SortColumns.Revenue:
      sortProperties = ["grossRevenuePerVisit"];
      break;
  }

  return getSortedItemsV2(
    jobInstances,
    sortProperties,
    sortDirection === SortDirection.Descending
  );
}

function Filters({
  customerId,
  jobHistoryFilters,
  setJobHistoryFilters,
  dateFilters,
  setDateFilters,
}: {
  customerId: string;
  jobHistoryFilters: IFilters;
  setJobHistoryFilters: React.Dispatch<React.SetStateAction<IFilters>>;
  dateFilters: IStickyFilters;
  setDateFilters: React.Dispatch<React.SetStateAction<IStickyFilters>>;
}) {
  const categories = useApplicationStateSelector(
    (s) => s.common.crewCategories
  );
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );

  const components = [
    {
      key: "categoryFilter",
      label: "Display",
      renderFilter: (id: string, styles: Partial<CSSProperties>) => (
        <select
          style={{ ...styles }}
          id={id}
          className="form-control"
          value={jobHistoryFilters.category}
          onChange={(e) =>
            setJobHistoryFilters({
              ...jobHistoryFilters,
              category: e.currentTarget.value,
            })
          }
        >
          <option value="">All services</option>
          {getSortedItemsV2(
            categories.filter((c) => !c.inactive),
            ["name"]
          ).map((c) => (
            <option key={c.name} value={c.name}>
              {c.name}
            </option>
          ))}
        </select>
      ),
    },
  ];

  const hasAdditionalLocations = customerAdditionalLocations.some(
    (l) => l.customerId === customerId
  );
  if (hasAdditionalLocations) {
    components.push({
      key: "location",
      label: "Job location",
      renderFilter: (id: string, styles: Partial<CSSProperties>) => (
        <div style={styles}>
          <CustomerAdditionalLocationSelection
            inputId={id}
            placeholder="Select..."
            disableAddEdit={true}
            includeCustomerAddress={true}
            value={jobHistoryFilters.locationId}
            customerId={customerId}
            onCustomerAdditionalLocationSelection={(selectedLocationId) => {
              setJobHistoryFilters({
                ...jobHistoryFilters,
                locationId: selectedLocationId,
              });
            }}
          />
        </div>
      ),
    });
  }

  const customDateElementsOffset = hasAdditionalLocations
    ? "offset-md-6"
    : "offset-md-3";

  return (
    <DateFilterHeadless
      filters={dateFilters}
      onFiltersChanged={(newFilters) => {
        newFilters = updateDatesOnFilter(newFilters);
        setDateFilters({
          startingDate: newFilters.startingDate ?? "",
          endingDate: newFilters.endingDate ?? "",
          frequency: newFilters.frequency,
        });
      }}
      showAllDates={false}
      setErrorMessage={() => {
        // Ignore error since server will return message
      }}
    >
      {({ dateRangeElements, customDateElements }) => (
        <>
          <div className="form-row">
            {components.map((f) => (
              <div
                className="col-12 col-sm-6 col-sm-4 col-md-3 form-group"
                key={f.key}
              >
                <label htmlFor={`${f.key}Filter`}>{f.label}</label>
                {f.renderFilter(`${f.key}Filter`, {})}
              </div>
            ))}
            <div className="col-12 col-sm-12 col-md-4 form-group">
              {dateRangeElements}
            </div>
          </div>
          {customDateElements !== null ? (
            <>
              <div className="form-row">
                <div
                  className={`col-6 col-sm-6 col-md-2 ${customDateElementsOffset} form-group`}
                >
                  {customDateElements.startingDateElement}
                </div>
                <div className="col-6 col-sm-6 col-md-2 form-group">
                  {customDateElements.endingDateElement}
                </div>
              </div>
            </>
          ) : null}
        </>
      )}
    </DateFilterHeadless>
  );
}
