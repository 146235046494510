import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";

interface IProps {
  closeForm(): void;
  message: string;
}

const Alert: React.FunctionComponent<IProps> = ({ closeForm, message }) => {
  return (
    <Modal
      isOpen={true}
      toggle={() => {
        closeForm();
      }}
    >
      <ModalBody>
        <div className="text-danger" style={{ whiteSpace: "pre-line" }}>
          {message}
        </div>
      </ModalBody>
      <ModalFooter style={{ justifyContent: "space-between" }}>
        <div></div>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => {
              closeForm();
            }}
            type="button"
          >
            Close
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default Alert;
