import { IInvoiceDefaultLineItem } from "../../../models/IInvoiceDefaultLineItem";
import { IInvoiceItem } from "../../../models/IInvoiceItem";
import { getNumericString } from "../../../services/typeConverter";
import uuidv4 from "uuid/v4";
import {
  IDeliveryMethodDetails,
  IInvoiceFormSelectedWork,
  IInvoiceJobInstance,
  LineItemByLocation,
  LineItemForForm,
} from "./InvoiceForm.types";
import { haveArraysChanged } from "../../../services/arrayService";
import { IInvoiceView } from "../../../models/IInvoice";
import { InvoiceDeliveryMethod } from "../../../models/InvoiceDeliveryMethod";
import { UpdatesFromLineItemChanges } from "./InvoiceFormLineItemAlert";
import { isStringSet } from "../../../services/stringService";
import { IInvoiceLineItem } from "../../../models/IInvoiceLineItem";
import { ICustomerAdditionalLocation } from "../../../models/ICustomerAdditionalLocation";
import addressFormatter from "../../../services/addressFormatter";
import { getSortedItemsV2 } from "../../../services/sortingService";
import { createNewLineItem } from "../../../services/invoiceFormService";
import { IInvoiceDefaultDepositCredit } from "../../../slices/billing/models/IInvoiceDefaultDepositCredit";
import { GetUserSettingsType } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import { IInvoiceDefaultProposalJobSummary } from "../../../slices/billing/models/IInvoiceDefaultProposalJobSummary";

export function getUpdatedLineItemsAfterSelectedJobsChange({
  oldSelectedWork,
  newSelectedWork,
  lineItemsByLocation,
  defaultLineItems,
  invoiceItems,
  jobInstances,
}: {
  oldSelectedWork: IInvoiceFormSelectedWork;
  newSelectedWork: IInvoiceFormSelectedWork;
  lineItemsByLocation: LineItemByLocation[];
  defaultLineItems: IInvoiceDefaultLineItem[] | null;
  invoiceItems: IInvoiceItem[];
  jobInstances: IInvoiceJobInstance[];
}) {
  const removedSources = [
    ...getMissingIds(
      oldSelectedWork.selectedJobInstances,
      newSelectedWork.selectedJobInstances
    ),
    ...getMissingIds(
      oldSelectedWork.selectedProjects,
      newSelectedWork.selectedProjects
    ),
  ];

  const addedSources = [
    ...getMissingIds(
      newSelectedWork.selectedJobInstances,
      oldSelectedWork.selectedJobInstances
    ),
    ...getMissingIds(
      newSelectedWork.selectedProjects,
      oldSelectedWork.selectedProjects
    ),
  ];

  let newLineItemsByLocation = lineItemsByLocation.map((location) => {
    const getSelectedWorkForCurrentLocation = (
      selectedWorkToFilter: IInvoiceFormSelectedWork
    ) =>
      getSelectedWorkForLocation({
        customerAdditionalLocationId: location.customerAdditionalLocationId,
        selectedWorkToFilter,
        jobInstances,
      });

    if (
      getSelectedWorkRowCount(
        getSelectedWorkForCurrentLocation(oldSelectedWork)
      ) !==
      getSelectedWorkRowCount(
        getSelectedWorkForCurrentLocation(newSelectedWork)
      )
    ) {
      return {
        ...location,
        lineItems: location.lineItems.map((l) => {
          if (l.defaultedFromJob) {
            return l;
          } else {
            return {
              ...l,
              quantity:
                getSelectedWorkRowCount(
                  getSelectedWorkForCurrentLocation(oldSelectedWork)
                ) === parseFloat(l.quantity)
                  ? getSelectedWorkRowCount(
                      getSelectedWorkForCurrentLocation(newSelectedWork)
                    ).toString()
                  : l.quantity,
            };
          }
        }),
      };
    } else {
      return location;
    }
  });

  // Remove line items defaulted in from work unselected
  newLineItemsByLocation = newLineItemsByLocation.map((l) => ({
    ...l,
    lineItems: l.lineItems.filter(
      (li) => !li.sourceId || !removedSources.includes(li.sourceId)
    ),
  }));

  // Add line items defaulted in from work reselected
  newLineItemsByLocation = getSegmentedLineItemsFromJobInstanceDefaults({
    source: getLineItemsWithMatchingItems(
      defaultLineItems ?? [],
      invoiceItems
    ).filter(
      (d) => typeof d.sourceId === "string" && addedSources.includes(d.sourceId)
    ),
    existingLocations: newLineItemsByLocation,
    invoiceItems,
  });

  return newLineItemsByLocation;
}

export function getSelectedWorkForLocation({
  customerAdditionalLocationId,
  selectedWorkToFilter,
  jobInstances,
}: {
  customerAdditionalLocationId: string | null;
  selectedWorkToFilter: IInvoiceFormSelectedWork;
  jobInstances: IInvoiceJobInstance[];
}): IInvoiceFormSelectedWork {
  // It's possible customerAdditionalLocationId is undefined for tests
  customerAdditionalLocationId = customerAdditionalLocationId ?? null;

  return {
    selectedJobInstances: selectedWorkToFilter.selectedJobInstances.filter(
      (selectedJobInstance) =>
        jobInstances.some(
          (jobInstanceSource) =>
            jobInstanceSource.id === selectedJobInstance &&
            jobInstanceSource.customerAdditionalLocationId ===
              customerAdditionalLocationId
        )
    ),
    selectedProjects: selectedWorkToFilter.selectedProjects.filter(
      (selectedJobInstance) =>
        jobInstances.some(
          (jobInstanceSource) =>
            jobInstanceSource.id === selectedJobInstance &&
            jobInstanceSource.customerAdditionalLocationId ===
              customerAdditionalLocationId
        )
    ),
  };
}

export function getSelectedWorkRowCount(
  selectedWork: IInvoiceFormSelectedWork
) {
  return (
    selectedWork.selectedJobInstances.length +
    selectedWork.selectedProjects.length
  );
}

export function hasSelectedWorkChanged(
  selectedWorkA: IInvoiceFormSelectedWork,
  selectedWorkB: IInvoiceFormSelectedWork
) {
  return (
    haveArraysChanged(
      selectedWorkA.selectedJobInstances,
      selectedWorkB.selectedJobInstances
    ) ||
    haveArraysChanged(
      selectedWorkA.selectedProjects,
      selectedWorkB.selectedProjects
    )
  );
}

export function mapDefaultLineItemToLineItem({
  defaultLineItem,
  invoiceItems,
}: {
  defaultLineItem: IInvoiceDefaultLineItem;
  invoiceItems: IInvoiceItem[];
}): LineItemForForm {
  const matchingItem = invoiceItems.find(
    (i) => i.id === defaultLineItem.itemId
  );
  return {
    id: uuidv4(),
    itemId: defaultLineItem.itemId,
    description: defaultLineItem.description ?? matchingItem?.description ?? "",
    name: defaultLineItem.name ?? matchingItem?.name ?? "",
    taxable: defaultLineItem.taxable,
    quantity: getNumericString(defaultLineItem.quantity),
    amountPerItem: getNumericString(defaultLineItem.amountPerItem),
    serviceDate: defaultLineItem.serviceDate ?? "",
    defaultedFromJob: true,
    sourceId: defaultLineItem.sourceId,
    hide: defaultLineItem.hide,
  };
}

export function getLineItemsWithMatchingItems(
  defaultLineItems: IInvoiceDefaultLineItem[],
  invoiceItems: IInvoiceItem[]
) {
  return defaultLineItems.filter((defaultLineItem) =>
    invoiceItems.some(
      (invoiceItem) => invoiceItem.id === defaultLineItem.itemId
    )
  );
}

export function getUpdatedDeliveryDetailsAfterPhoneChange({
  newPhoneNumber,
  currentDeliveryDetails,
  originalDeliveryDetails,
  phoneNumberOptedIntoSmsDirty,
}: {
  newPhoneNumber: string;
  currentDeliveryDetails: IDeliveryMethodDetails;
  originalDeliveryDetails: IDeliveryMethodDetails;
  phoneNumberOptedIntoSmsDirty: boolean;
}): IDeliveryMethodDetails {
  let customerPhoneNumberOptedIntoSms =
    currentDeliveryDetails.customerPhoneNumberOptedIntoSms;
  if (!phoneNumberOptedIntoSmsDirty) {
    if (newPhoneNumber !== originalDeliveryDetails.customerPhoneNumber) {
      customerPhoneNumberOptedIntoSms = false;
    } else {
      customerPhoneNumberOptedIntoSms =
        originalDeliveryDetails.customerPhoneNumberOptedIntoSms;
    }
  }

  return {
    ...currentDeliveryDetails,
    customerPhoneNumberOptedIntoSms,
    customerPhoneNumber: newPhoneNumber,
  };
}

export function getFormHeader(
  isUpdateMode: boolean,
  invoice: IInvoiceView | null,
  customerName?: string | null
) {
  if (!isUpdateMode) {
    return customerName
      ? `Create Invoice for ${customerName}`
      : "Create Invoice";
  } else if (invoice && invoice.invoiceNumber) {
    return `Update Invoice #${invoice.invoiceNumber}`;
  } else {
    return "Update Invoice";
  }
}

export function getDefaultDeliveryMethod(
  isQuickBooksEnabled: boolean,
  areCreditCardsEnabled: boolean,
  paymentMethodPreviouslyAuthorized: boolean,
  getUserSettings: GetUserSettingsType
): string {
  const cachedDeliveryMethod = getUserSettings<string>(
    UserSettingsType.invoiceDeliveryMethod
  );

  let deliveryMethod: InvoiceDeliveryMethod;
  // Default to payment method on file when fully authorized.
  if (paymentMethodPreviouslyAuthorized && areCreditCardsEnabled) {
    deliveryMethod = InvoiceDeliveryMethod.paymentMethodOnFile;
  } else if (cachedDeliveryMethod) {
    const parsedCachedDeliveryMethod = parseInt(
      cachedDeliveryMethod,
      10
    ) as InvoiceDeliveryMethod;

    deliveryMethod = parsedCachedDeliveryMethod;
    const isQuickBooksOnlyDeliveryMethod =
      parsedCachedDeliveryMethod ===
        InvoiceDeliveryMethod.emailWithQuickBooks ||
      parsedCachedDeliveryMethod === InvoiceDeliveryMethod.printWithQuickBooks;
    const isCreditCardOnlyDeliveryMethod =
      parsedCachedDeliveryMethod === InvoiceDeliveryMethod.paymentMethodOnFile;

    if (isQuickBooksOnlyDeliveryMethod && !isQuickBooksEnabled) {
      deliveryMethod =
        getDefaultDeliveryMethodWithoutCacheOrPaymentMethodAuthorized();
    } else if (isCreditCardOnlyDeliveryMethod && !areCreditCardsEnabled) {
      deliveryMethod =
        getDefaultDeliveryMethodWithoutCacheOrPaymentMethodAuthorized();
    }
  } else {
    deliveryMethod =
      getDefaultDeliveryMethodWithoutCacheOrPaymentMethodAuthorized();
  }

  return deliveryMethod.toString();
}

export function getChangesFromJobLineItemChanges({
  maintenanceJobId,
  data,
  lineItemsByLocation,
  defaultLineItems,
  jobInstances,
  invoiceItems,
}: {
  maintenanceJobId: string;
  data: UpdatesFromLineItemChanges;
  lineItemsByLocation: Array<LineItemByLocation>;
  defaultLineItems: Array<IInvoiceDefaultLineItem> | null;
  jobInstances: Array<IInvoiceJobInstance>;
  invoiceItems: Array<IInvoiceItem>;
}) {
  let newLineItemsByLocation = lineItemsByLocation.map((l) => ({
    ...l,
    lineItems: l.lineItems.filter(
      (li) =>
        // Remove items previously defaulted in and not blank item
        li.sourceId !== maintenanceJobId && !isBlankItem(li)
    ),
  }));

  newLineItemsByLocation = getSegmentedLineItemsFromJobInstanceDefaults({
    source: data.updatedDefaultLineItems,
    invoiceItems,
    existingLocations: newLineItemsByLocation,
  });

  const newDefaultLineItems = [
    ...(defaultLineItems ?? []).filter((d) => d.sourceId !== maintenanceJobId),
    ...data.updatedDefaultLineItems,
  ];

  const newJobInstances = [
    ...jobInstances.filter((ji) => ji.jobId !== maintenanceJobId),
    ...data.updatedInvoiceJobInstances,
  ];

  return {
    newLineItemsByLocation,
    newDefaultLineItems,
    newJobInstances,
  };
}

export function flattenLineItemsByLocation(source: Array<LineItemByLocation>) {
  return source.flatMap((l) =>
    l.lineItems.map((li) => ({
      ...li,
      customerAdditionalLocationId: l.customerAdditionalLocationId,
    }))
  );
}

export function getSegmentedLineItemsFromExistingInvoice(
  source: IInvoiceLineItem[]
): Array<LineItemByLocation> {
  return getSegmentedLineItems<IInvoiceLineItem>({
    source,
    existingLocations: [],
    mapItem: (li) => ({
      id: li.id,
      amountPerItem: li.amountPerItem.toString(),
      name: li.name,
      description: li.description,
      itemId: li.itemId,
      quantity: li.quantity.toString(),
      taxable: li.taxable,
      serviceDate: li.serviceDate ?? "",
      hide: li.hide,
    }),
  });
}

export function getSegmentedLineItemsFromJobInstanceDefaults({
  source,
  existingLocations,
  invoiceItems,
}: {
  source: IInvoiceDefaultLineItem[];
  existingLocations: Array<LineItemByLocation>;
  invoiceItems: Array<IInvoiceItem>;
}): Array<LineItemByLocation> {
  return getSegmentedLineItems<IInvoiceDefaultLineItem>({
    source,
    existingLocations,
    mapItem: (li) =>
      mapDefaultLineItemToLineItem({
        defaultLineItem: li,
        invoiceItems,
      }),
  });
}

export function initializeLineItemsByLocation({
  buildDefaultLineItem,
  jobInstancesWithLocations,
}: {
  buildDefaultLineItem: (quantity: number) => LineItemForForm;
  jobInstancesWithLocations: Array<{
    customerAdditionalLocationId: string | null;
  }>;
}): LineItemByLocation[] {
  let lineItemsByLocation = ensureLocationsExists({
    jobInstancesWithLocations,
    lineItemsForLocation: [
      { customerAdditionalLocationId: null, lineItems: [] },
    ],
  });

  const jobCountAtPrimaryLocation = getCountOfJobsAtLocation(null);
  if (jobCountAtPrimaryLocation > 0 || jobInstancesWithLocations.length === 0) {
    lineItemsByLocation = lineItemsByLocation.map((li) => {
      if (li.customerAdditionalLocationId === null) {
        return {
          ...li,
          lineItems: addDefaultLineItemsIfNotAlreadySet(
            li,
            jobCountAtPrimaryLocation
          ),
        };
      } else {
        return li;
      }
    });
  } else {
    let hasDefaultLineItemBeenAssigned = false;
    lineItemsByLocation = lineItemsByLocation.map((locationForLineItems) => {
      const jobCountAtLocation = getCountOfJobsAtLocation(
        locationForLineItems.customerAdditionalLocationId
      );
      if (!hasDefaultLineItemBeenAssigned && jobCountAtLocation > 0) {
        hasDefaultLineItemBeenAssigned = true;

        return {
          ...locationForLineItems,
          lineItems: addDefaultLineItemsIfNotAlreadySet(
            locationForLineItems,
            jobCountAtLocation
          ),
        };
      } else {
        return locationForLineItems;
      }
    });
  }

  return lineItemsByLocation;

  function getCountOfJobsAtLocation(locationId: string | null) {
    return jobInstancesWithLocations.filter(
      (l) =>
        l.customerAdditionalLocationId === locationId ||
        // This is for scenarios where customerAdditionalLocationId is undefined rather than null
        (!l.customerAdditionalLocationId && locationId === null)
    ).length;
  }

  function addDefaultLineItemsIfNotAlreadySet(
    locationForLineItems: LineItemByLocation,
    jobCountAtLocation: number
  ): LineItemForForm[] {
    return locationForLineItems.lineItems.length === 0
      ? [buildDefaultLineItem(jobCountAtLocation > 0 ? jobCountAtLocation : 1)]
      : locationForLineItems.lineItems;
  }
}

export function getLocationsForFormData({
  lineItemsForLocation,
  customerAdditionalLocations,
  jobInstancesWithLocations,
}: {
  lineItemsForLocation: LineItemByLocation[];
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
  jobInstancesWithLocations: Array<{
    customerAdditionalLocationId: string | null;
  }>;
}): Array<LineItemByLocation> {
  if (
    !lineItemsForLocation.some(
      (l) => (l.customerAdditionalLocationId ?? null) === null
    )
  ) {
    lineItemsForLocation = [
      {
        customerAdditionalLocationId: null,
        lineItems: [],
      },
      ...lineItemsForLocation,
    ];
  }

  lineItemsForLocation = ensureLocationsExists({
    jobInstancesWithLocations,
    lineItemsForLocation,
  });

  const hasLineItem =
    flattenLineItemsByLocation(lineItemsForLocation).length > 0;
  if (!hasLineItem) {
    const defaultLocation = lineItemsForLocation.find(
      (l) => (l.customerAdditionalLocationId ?? null) === null
    );
    if (defaultLocation && defaultLocation.lineItems.length === 0) {
      defaultLocation.lineItems = [createNewLineItem(1)];
    }
  }

  return getLineItemByLocationSorted({
    lineItemsForLocation,
    customerAdditionalLocations,
  });
}

export function updateDepositCredits({
  selectedWorkToBill,
  credits,
  setDepositCreditAmount,
  setMaxDepositCreditAmount,
  setDepositCredits,
}: {
  selectedWorkToBill: IInvoiceFormSelectedWork;
  credits: IInvoiceDefaultDepositCredit[];
  setDepositCreditAmount: (newDepositCreidtAmount: number | null) => void;
  setMaxDepositCreditAmount: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  setDepositCredits: React.Dispatch<
    React.SetStateAction<IInvoiceDefaultDepositCredit[]>
  >;
}) {
  // Filter to selected jobs
  const selectedSources = [
    ...selectedWorkToBill.selectedJobInstances,
    ...selectedWorkToBill.selectedProjects,
  ];
  let filteredCredits = credits
    .filter((c) => selectedSources.includes(c.sourceId))
    .map((c) => {
      return { opportunityId: c.opportunityId, amount: c.amount };
    });

  // Remove duplicate proposal credits. Occurs for different jobs from same proposal.
  let distinctCredits: Array<{ opportunityId: string; amount: number }> = [];

  filteredCredits.forEach((credit) => {
    if (
      !distinctCredits.some((d) => d.opportunityId === credit.opportunityId)
    ) {
      distinctCredits.push(credit);
    }
  });

  let totalCredit = distinctCredits.reduce((a, b) => a + (b.amount || 0), 0);
  setMaxDepositCreditAmount(totalCredit);
  setDepositCreditAmount(totalCredit);
  setDepositCredits(credits);
}

export function getProposalJobSummaryFromSelectedJobs({
  selectedWorkToBill,
  proposalJobSummaries,
}: {
  selectedWorkToBill: IInvoiceFormSelectedWork;
  proposalJobSummaries: Array<IInvoiceDefaultProposalJobSummary>;
}) {
  let result = "";

  const opportunityIdsProcessed: Array<string> = [];
  proposalJobSummaries.forEach((summary) => {
    if (opportunityIdsProcessed.includes(summary.opportunityId)) {
      return;
    }

    if (
      !selectedWorkToBill.selectedJobInstances.includes(summary.sourceId) &&
      !selectedWorkToBill.selectedProjects.includes(summary.sourceId)
    ) {
      return;
    }

    opportunityIdsProcessed.push(summary.opportunityId);
    result += `${summary.summary.trim()}\n`;
  });

  return result.trim();
}

function ensureLocationsExists({
  jobInstancesWithLocations,
  lineItemsForLocation,
}: {
  jobInstancesWithLocations: { customerAdditionalLocationId: string | null }[];
  lineItemsForLocation: LineItemByLocation[];
}) {
  const result = [...lineItemsForLocation];
  jobInstancesWithLocations.forEach((j) => {
    if (
      !result.some(
        (l) =>
          (l.customerAdditionalLocationId ?? null) ===
          (j.customerAdditionalLocationId ?? null)
      )
    ) {
      result.push({
        customerAdditionalLocationId: j.customerAdditionalLocationId,
        lineItems: [],
      });
    }
  });

  return result;
}

function getLineItemByLocationSorted({
  lineItemsForLocation,
  customerAdditionalLocations,
}: {
  lineItemsForLocation: LineItemByLocation[];
  customerAdditionalLocations: ICustomerAdditionalLocation[];
}) {
  const itemsWithNameAndAddress = lineItemsForLocation.map((l) => {
    let name = "";
    let address = "";
    if (l.customerAdditionalLocationId) {
      const location = customerAdditionalLocations.find(
        (c) => c.id === l.customerAdditionalLocationId
      );
      if (location) {
        name = location.name ?? "";
        address = addressFormatter.formatAddressEntity(location);
      }
    }
    return {
      ...l,
      isAdditionalLocation: l.customerAdditionalLocationId !== null,
      name,
      address,
    };
  });

  const sortedItems = getSortedItemsV2(itemsWithNameAndAddress, [
    "isAdditionalLocation",
    "name",
    "address",
  ]);

  return sortedItems;
}

function getSegmentedLineItems<
  TSource extends { customerAdditionalLocationId: string | null }
>({
  source,
  existingLocations,
  mapItem,
}: {
  source: TSource[];
  existingLocations: LineItemByLocation[];
  mapItem: (a: TSource) => LineItemForForm;
}) {
  source.forEach((li) => {
    const matchingLocation = existingLocations.find(
      (l) =>
        (l.customerAdditionalLocationId ?? null) ===
        (li.customerAdditionalLocationId ?? null)
    );
    if (!matchingLocation) {
      existingLocations.push({
        customerAdditionalLocationId: li.customerAdditionalLocationId,
        lineItems: [mapItem(li)],
      });
    } else {
      matchingLocation.lineItems.push(mapItem(li));
    }
  });

  return existingLocations;
}

function getMissingIds(listA: Array<string>, listB: Array<string>) {
  return listA.filter((itemA) => !listB.includes(itemA));
}

function getDefaultDeliveryMethodWithoutCacheOrPaymentMethodAuthorized(): InvoiceDeliveryMethod {
  return InvoiceDeliveryMethod.emailWithCrewControl;
}

function isBlankItem(li: LineItemForForm) {
  return (
    !isStringSet(li.itemId) &&
    !isStringSet(li.name) &&
    !isStringSet(li.description) &&
    !isStringSet(li.amountPerItem)
  );
}
