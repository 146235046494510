import { useMemo } from "react";
import { useApplicationStateSelector } from "../../../../../hooks/useApplicationStateSelector";
import { IJob } from "../../../../../models/IJob";
import customerFinder from "../../../../../services/customerFinder";

export function JobCardEmailAddresses({
  job,
  useWhiteText,
}: {
  job: IJob;
  useWhiteText: boolean;
}) {
  const showCustomerEmailAddressOnAdminJobCard = useApplicationStateSelector(
    (s) =>
      s.common.adminViewConfiguration.showCustomerEmailAddressOnAdminJobCard
  );
  const customers = useApplicationStateSelector((s) => s.customer.customers);

  const { emailAddresses } = useMemo(() => {
    let returnValue = {
      phoneNumber: "",
      alternativePhoneNumber: "",
      emailAddresses: [] as Array<string>,
    };
    if (showCustomerEmailAddressOnAdminJobCard) {
      const customer = customerFinder.getCustomerByJob(job, customers);
      if (customer) {
        if (showCustomerEmailAddressOnAdminJobCard) {
          returnValue.emailAddresses = customer.emailAddresses;
        }
      }
    }
    return returnValue;
  }, [job, customers, showCustomerEmailAddressOnAdminJobCard]);

  return (
    <>
      {showCustomerEmailAddressOnAdminJobCard &&
      !!emailAddresses &&
      emailAddresses.length > 0 ? (
        <div data-testid="emailContainer">
          {emailAddresses.map((emailAddress) => (
            <div key={emailAddress}>
              <a
                href={"mailto:" + emailAddress}
                onClick={(e) => e.stopPropagation()}
                className="schedule-link mr-2"
                style={{
                  color: useWhiteText ? "#fff" : "#495057",
                }}
              >
                {emailAddress}
              </a>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
}
