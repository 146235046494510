import React, { CSSProperties } from "react";
import { TableColumns } from "./TableColumn";
import getCellHeader from "./getHeaderContents";
import { getCellContents } from "./getCellContents";

export default function ResponsiveGridTable<T extends { id: string }>({
  rows,
  columns,
  breakpoint,
  mobileHeader,
  gridStyles,
  rowGap,
  columnGap,
}: {
  rows: Array<T>;
  columns: TableColumns<T>;
  breakpoint?: string;
  mobileHeader?: (arg: T, index: number) => React.ReactNode;
  gridStyles?: Partial<CSSProperties>;
  rowGap?: string;
  columnGap?: string;
}) {
  breakpoint = breakpoint ?? "md";
  const visibleColumns = columns.filter((column) => !column.hidden);

  return (
    <>
      <div className={`d-none d-${breakpoint}-block`}>
        <div
          style={{
            display: "grid",
            gap: `${rowGap ?? "5px"} ${columnGap ?? "5px"}`,
            gridTemplateColumns: visibleColumns.map((c) => c.width).join(" "),
            ...(gridStyles ?? {}),
          }}
        >
          {visibleColumns.map((column) => (
            <div
              className={`text-nowrap ${column.headerClassName ?? ""}`}
              key={column.key}
            >
              {getCellHeader(column, {
                displayType: "desktop",
                rowIndex: null,
              })}
            </div>
          ))}

          {rows.map((row, rowIndex) =>
            visibleColumns.map((column) => (
              <div
                key={`${row.id}_${column.key}`}
                className={column.cellClassName}
                data-testid={column.testId}
              >
                {getCellContents<T>(row, column, "desktop", rowIndex)}
              </div>
            ))
          )}
        </div>
      </div>
      <div className={`d-block d-${breakpoint}-none`}>
        {rows.map((row, rowIndex) => (
          <React.Fragment key={row.id}>
            {typeof mobileHeader === "function"
              ? mobileHeader(row, rowIndex)
              : null}

            {visibleColumns
              .filter((c) => !c.hideOnMobile)
              .map((column) => (
                <div key={column.key} className="mb-3">
                  <div>
                    {getCellHeader(column, {
                      displayType: "mobile",
                      row: row,
                      rowIndex,
                    })}
                  </div>
                  <div>
                    {getCellContents<T>(row, column, "mobile", rowIndex)}
                  </div>
                </div>
              ))}
            {rowIndex < rows.length - 1 ? (
              <hr data-testid="smallRowSeparator" />
            ) : null}
          </React.Fragment>
        ))}
      </div>
    </>
  );
}
