import { ofType } from "redux-observable";
import { of, EMPTY } from "rxjs";
import { mergeMap } from "rxjs/operators";
import {
  actionTypes,
  createSpecificActionTypeName,
  ICompleteSavingAction,
  IStartSavingAction,
} from "./actionTypes";
import { formTypes } from "./formTypes";
import formActionCreators from "./actionCreators";
import { handleFormStartSaving } from "./epicActionHandlers/handleFormStartSaving";

export const formSaveDefaultTimeout = 10000;

const formsToCreateEpicsFor = Object.getOwnPropertyNames(formTypes).map(
  (k) => k as keyof typeof formTypes
);
const actionEpics = formsToCreateEpicsFor.flatMap((formToCreateKey) => {
  const createTypeNameForForm = (actionType: any) =>
    createSpecificActionTypeName(formTypes[formToCreateKey], actionType);

  return [
    // Reopen the form if requested
    (action$: any) =>
      action$.pipe(
        ofType(createTypeNameForForm(actionTypes.completeSaving)),
        mergeMap((action: ICompleteSavingAction<any, any>) => {
          if (action.reopenFormOnComplete) {
            return of(
              formActionCreators[formToCreateKey].showForm({} as unknown as any)
            );
          } else {
            return EMPTY;
          }
        })
      ),

    (action$: any, state$: any) =>
      action$.pipe(
        ofType(createTypeNameForForm(actionTypes.startSaving)),
        mergeMap((action: IStartSavingAction<any>) => {
          return handleFormStartSaving(formToCreateKey, action, state$.value);
        })
      ),
  ];
});

export default actionEpics;
