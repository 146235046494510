import { Action } from "@reduxjs/toolkit";
import { Epic } from "redux-observable";
import { catchError, filter, mergeMap, timeout } from "rxjs/operators";
import { opportunitiesActionCreators } from "../opportunity";
import { IApplicationState } from "../../../../modules";
import { loadOpportunitiesBoard } from "../../services/loadOpportunitiesBoard";
import { of } from "rxjs";

const loadOpportunities: Epic<Action, Action, IApplicationState> = (
  action,
  state$
) =>
  action.pipe(
    filter(opportunitiesActionCreators.loadOpportunitiesStart.match),
    mergeMap(() => {
      return loadOpportunitiesBoard(state$.value).pipe(
        timeout(30000),
        catchError((err) => {
          return of(opportunitiesActionCreators.loadOpportunitiesError());
        })
      );
    })
  );

export default loadOpportunities;
