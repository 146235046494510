import { ITodoItemResponse } from "../../../../../models/ITodoItemResponse";

export function isCompleted(
  todoItemId: string,
  todoResponses: Array<ITodoItemResponse>
) {
  const response = todoResponses.find(r => r.id === todoItemId);
  if (response) {
    return response.completed;
  }
  return false;
}
