import React, { useEffect, useRef } from "react";
import { IInvoiceItem } from "../../../models/IInvoiceItem";
import { IInvoiceDefaultLineItem } from "../../../models/IInvoiceDefaultLineItem";
import { UseFormSetValue } from "react-hook-form";
import { IInvoiceDefaultDepositCredit } from "../../../slices/billing/models/IInvoiceDefaultDepositCredit";
import { IInvoiceAmountAdjustments } from "../../../slices/sales/components/ProposalForm/IFormData";
import { IInvoiceJobInstance, IInvoiceProject } from "./InvoiceForm.types";
import {
  getLineItemsWithMatchingItems,
  getLocationsForFormData,
  getSegmentedLineItemsFromJobInstanceDefaults,
  initializeLineItemsByLocation,
  updateDepositCredits,
} from "./InvoiceForm.functions";
import { ICustomerAdditionalLocation } from "../../../models/ICustomerAdditionalLocation";
import { IInvoiceFormData } from "./InvoiceForm.types";
import { getSortedItemsV2 } from "../../../services/sortingService";
import {
  createNewLineItem,
  getDefaultServiceDate,
} from "../../../services/invoiceFormService";

export function useDefaultsFromJobs({
  invoiceId,
  loadedInvoice,
  loaded,
  defaultLineItems,
  invoiceItems,
  defaultAmountAdjustments,
  setValue,
  defaultCredits,
  setMaxDepositCreditAmount,
  setDepositCredits,
  jobInstances,
  projects,
  customerAdditionalLocations,
}: {
  invoiceId: string | null;
  loadedInvoice: boolean;
  loaded: boolean;
  defaultLineItems: IInvoiceDefaultLineItem[] | null;
  invoiceItems: IInvoiceItem[];
  defaultAmountAdjustments: IInvoiceAmountAdjustments;
  setValue: UseFormSetValue<IInvoiceFormData>;
  defaultCredits: IInvoiceDefaultDepositCredit[];
  setMaxDepositCreditAmount: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  setDepositCredits: React.Dispatch<
    React.SetStateAction<IInvoiceDefaultDepositCredit[]>
  >;
  jobInstances: IInvoiceJobInstance[];
  projects: IInvoiceProject[];
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
}) {
  const invoiceHasBeenDefaulted = useRef(false);
  useEffect(() => {
    if (
      loaded &&
      loadedInvoice &&
      !invoiceId &&
      !invoiceHasBeenDefaulted.current
    ) {
      // Only set tax rate if its greater than zero
      // Otherwise, keep the existing user setting default
      setDefaultsFromJobs({
        defaultAmountAdjustments,
        setValue,
        defaultCredits,
        jobInstances,
        projects,
        setMaxDepositCreditAmount,
        setDepositCredits,
        defaultLineItems,
        invoiceItems,
        customerAdditionalLocations,
      });

      invoiceHasBeenDefaulted.current = true;
    }
  }, [
    loaded,
    invoiceItems,
    defaultLineItems,
    defaultAmountAdjustments,
    setValue,
    invoiceId,
    loadedInvoice,
    defaultCredits,
    jobInstances,
    projects,
    setDepositCredits,
    setMaxDepositCreditAmount,
    customerAdditionalLocations,
  ]);
}

export function setDefaultsFromJobs({
  defaultAmountAdjustments,
  setValue,
  defaultCredits,
  jobInstances,
  projects,
  setMaxDepositCreditAmount,
  setDepositCredits,
  defaultLineItems,
  invoiceItems,
  customerAdditionalLocations,
}: {
  customerAdditionalLocations: ICustomerAdditionalLocation[];
  defaultAmountAdjustments: IInvoiceAmountAdjustments;
  defaultCredits: IInvoiceDefaultDepositCredit[];
  jobInstances: IInvoiceJobInstance[];
  projects: IInvoiceProject[];
  defaultLineItems: IInvoiceDefaultLineItem[] | null;
  invoiceItems: IInvoiceItem[];
  setValue: UseFormSetValue<IInvoiceFormData>;
  setMaxDepositCreditAmount: React.Dispatch<
    React.SetStateAction<number | null>
  >;
  setDepositCredits: React.Dispatch<
    React.SetStateAction<IInvoiceDefaultDepositCredit[]>
  >;
}) {
  if (
    defaultAmountAdjustments.taxRate &&
    defaultAmountAdjustments.taxRate > 0
  ) {
    setValue("amountAdjustments.taxRate", defaultAmountAdjustments.taxRate);
  }

  // Only set the default discount if there is an amount
  // This is to keep entry by percent the default entry type on the invoice form
  if (
    typeof defaultAmountAdjustments.discount?.amount === "number" &&
    defaultAmountAdjustments.discount.amount > 0
  ) {
    setValue("amountAdjustments.discount", defaultAmountAdjustments.discount);
  }

  const selectedWorkToBill = {
    selectedJobInstances: jobInstances?.map((j) => j.id),
    selectedProjects: projects?.map((j) => j.id),
  };

  // Only set the default deposit credit amount if its greater than zero
  if (defaultCredits.length > 0) {
    updateDepositCredits({
      selectedWorkToBill,
      credits: defaultCredits,
      setDepositCreditAmount: (v) =>
        setValue("amountAdjustments.depositCreditAmount", v),
      setMaxDepositCreditAmount,
      setDepositCredits,
    });
  }

  // Filter out line items that no longer exist
  if (defaultLineItems !== null) {
    const defaultLineItemsStillExisting = getLineItemsWithMatchingItems(
      defaultLineItems,
      invoiceItems
    );

    // Apply defaults for line items that exist
    if (defaultLineItemsStillExisting.length > 0) {
      let lineItemsForLocation = getSegmentedLineItemsFromJobInstanceDefaults({
        source: getSortedItemsV2(defaultLineItemsStillExisting, [
          "serviceDate",
        ]),
        existingLocations: [],
        invoiceItems,
      });

      setValue(
        "lineItemsByLocation",
        getLocationsForFormData({
          lineItemsForLocation,
          customerAdditionalLocations,
          jobInstancesWithLocations: jobInstances,
        })
      );
    } else {
      setValue(
        "lineItemsByLocation",
        initializeLineItemsByLocation({
          buildDefaultLineItem: (quantity) =>
            createNewLineItem(
              quantity,
              getDefaultServiceDate({
                jobInstances,
                selectedJobInstances: selectedWorkToBill.selectedJobInstances,
              })
            ),
          jobInstancesWithLocations: jobInstances,
        })
      );
    }
  }
}
