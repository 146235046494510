export function isStringSet(input: string | null | undefined): input is string {
  return (input ?? "").trim() !== "";
}

export function containsText(
  v1: string | null | undefined,
  v2: string | null | undefined
) {
  v1 = v1?.trim() || "";
  v2 = v2?.trim() || "";
  return v1.toLocaleLowerCase().indexOf(v2.toLocaleLowerCase()) !== -1;
}
