import {
  PayrixTransactionStatus,
  ICreditCardTransaction,
} from "../models/ICreditCardTransaction";
import { getSortedItemsV2 } from "./sortingService";
import { SortDirection } from "../enums/sortDirection";

export enum TransactionSortColumns {
  amount,
  customer,
  date,
  authorizationCode,
  status,
}

export function getSortedTransactions(
  transactions: Array<ICreditCardTransaction>,
  sortColumn: TransactionSortColumns | null,
  sortDirection: SortDirection
) {
  if (sortColumn === null) {
    return transactions;
  }

  let sortProperties: Array<
    | keyof ICreditCardTransaction
    | ((i: ICreditCardTransaction) => string | number)
  >;
  switch (sortColumn) {
    case TransactionSortColumns.amount:
      sortProperties = ["amount"];
      break;
    case TransactionSortColumns.authorizationCode:
      sortProperties = ["authorizationCode"];
      break;
    case TransactionSortColumns.customer:
      sortProperties = ["customerName"];
      break;
    case TransactionSortColumns.date:
      sortProperties = ["createdDate"];
      break;
    case TransactionSortColumns.status:
      sortProperties = [(t) => getTransactionStatusText(t.status)];
      break;
  }

  return getSortedItemsV2(
    transactions,
    sortProperties,
    sortDirection === SortDirection.Descending
  );
}

export function getTransactionStatusText(status: PayrixTransactionStatus) {
  switch (status) {
    case PayrixTransactionStatus.approved:
      return "Approved";
    case PayrixTransactionStatus.failed:
      return "Failed";
    case (PayrixTransactionStatus.pending, PayrixTransactionStatus.captured):
      return "Pending";
    case PayrixTransactionStatus.returned:
      return "Returned";
    case PayrixTransactionStatus.settled:
      return "Completed";
    default:
      return "";
  }
}
