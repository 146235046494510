import React from "react";
import { PrivacyPolicy } from "../components/PrivacyPolicy";
import PublicContentContainer from "../components/PublicContentContainer";

const PrivacyPolicyPage: React.SFC<{}> = () => (
  <PublicContentContainer>
    <PrivacyPolicy />
  </PublicContentContainer>
);

export default PrivacyPolicyPage;
