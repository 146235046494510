// Disable rule since bootstrap docs have the href missing for tabs
/* eslint-disable jsx-a11y/anchor-is-valid */

import {
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { mergeMap, timeout } from "rxjs/operators";
import constants from "../../../constants";
import { PaymentMethodType } from "../../../enums/paymentMethodType";
import { PayrixBankAccountType } from "../../../enums/payrixBankAccountType";
import { formSaveDefaultTimeout } from "../../../formGenerator/actionEpics";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { actionCreators } from "../../../modules/actionCreators";
import { parseName } from "../../../services/achService";
import { getErrorMessagesFromError } from "../../../services/httpErrorHandler";
import remoteDataProvider from "../../../services/remoteDataProvider";
import customerDataProvider, {
  IGetPaymentMethodUsageResponse,
} from "../../../slices/customer/services/customerDataProvider";
import BankAccountInline, {
  parseBankAccountType,
} from "../components/BankAccountInline";
import conditionalRenderer from "../components/conditionalRenderer";
import CreditCardInline, { triggerSave } from "../components/CreditCardInline";
import FormContainer2 from "../components/FormContainer2";
import ModalDataLoader from "../components/ModalDataLoader";

interface IFormData {
  bankAccountType: string;
  bankRoutingNumber: string;
  bankAccountNumber: string;
  bankAccountNumberConfirmed: string;
  name: string;
}

const CustomerPaymentMethodForm: React.FunctionComponent<{}> = () => {
  const [paymentType, setPaymentType] = useState(PaymentMethodType.CreditCard);
  const { register, getValues, watch, setValue } = useForm<IFormData>();
  const creditCardIframeRef = useRef<HTMLIFrameElement | null>(null);
  const { customerId } = useApplicationStateSelector(
    (s) => s.forms.customerPaymentMethod.parameters
  );
  const [paymentMethodUsage, setPaymentMethodUsage] =
    useState<IGetPaymentMethodUsageResponse | null>(null);
  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    return customerDataProvider.getPaymentMethodUsage(customerId);
  }, [customerId]);

  const handleLoadedData = useCallback(
    (result: IGetPaymentMethodUsageResponse) => {
      setPaymentMethodUsage(result);
    },
    []
  );

  const bankAccountNumber = watch("bankAccountNumber");
  const bankAccountNumberConfirmed = watch("bankAccountNumberConfirmed");
  const bankAccountType = watch("bankAccountType");
  const bankRoutingNumber = watch("bankRoutingNumber");

  return (
    <ModalDataLoader<IGetPaymentMethodUsageResponse>
      errorMessage={
        "The customer payment method form was unable to open. Please check your Internet connection and try again."
      }
      loadData={loadData}
      onDataLoaded={handleLoadedData}
      onErrorAlertClose={() =>
        dispatch(actionCreators.forms.customerPaymentMethod.cancelForm())
      }
    >
      <FormContainer2
        formHeader={"Customer Payment Method"}
        formType={"customerPaymentMethod"}
        saveHandlerOverride={() => {
          if (paymentType === PaymentMethodType.CreditCard) {
            if (triggerSave(creditCardIframeRef)) {
              dispatch(
                actionCreators.forms.customerPaymentMethod.startSaving({})
              );

              // Rest of save will be completed in CreditCardInline.onSave handler
            }
          } else {
            dispatch(
              actionCreators.forms.customerPaymentMethod.startSaving({})
            );

            const { firstName, lastName } = parseName(getValues("name"));
            const bankAccountType = parseBankAccountType(
              getValues("bankAccountType")
            ) as PayrixBankAccountType;

            remoteDataProvider
              .tokenizeAchAccount({
                firstName,
                lastName,
                accountNumber: getValues("bankAccountNumber"),
                accountType: bankAccountType,
                routingNumber: getValues("bankRoutingNumber"),
              })
              .pipe(
                mergeMap((tokenSaveResult) => {
                  return remoteDataProvider.saveCustomerPaymentMethod(
                    {
                      type: PaymentMethodType.Ach,
                      partialNumber: tokenSaveResult.partialNumber,
                      token: tokenSaveResult.token,
                    },
                    { customerId }
                  );
                }),
                timeout(formSaveDefaultTimeout)
              )
              .subscribe({
                next: (formSaveResult) => {
                  dispatch(
                    actionCreators.forms.customerPaymentMethod.completeSaving(
                      { customerId },
                      formSaveResult.data
                    )
                  );
                },

                error: (err) => {
                  const errorMessage = getErrorMessage(err);
                  dispatch(
                    actionCreators.forms.customerPaymentMethod.setErrorMessage(
                      errorMessage
                    )
                  );
                },
              });
          }
        }}
        showForm={true}
      >
        {paymentMethodUsage?.hasAuthorizedJobs ? (
          <div
            className="alert alert-warning p-2"
            data-testid="authorizedJobsWarning"
          >
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
            <small>
              This customer has job(s) authorized to use their payment method on
              file. Updating this payment method will replace the current
              payment method. All services that were authorized by the previous
              method of payment will be authorized for this payment method.
            </small>
          </div>
        ) : null}

        <ul className="nav nav-tabs nav-pills nav-fill">
          <li className="nav-item">
            <a
              className={getNavLinkClasses(
                paymentType,
                PaymentMethodType.CreditCard
              )}
              onClick={() => setPaymentType(PaymentMethodType.CreditCard)}
              style={{ cursor: "pointer" }}
            >
              Credit card
            </a>
          </li>
          <li className="nav-item">
            <a
              className={getNavLinkClasses(paymentType, PaymentMethodType.Ach)}
              onClick={() => setPaymentType(PaymentMethodType.Ach)}
              style={{ cursor: "pointer" }}
            >
              Bank transfer
            </a>
          </li>
        </ul>
        <div className="pt-3">
          {
            // Credit card toggles whether elements are visible so that switching from Bank Transfer doesn't force the iframe to reload
          }
          <div
            style={{
              display:
                paymentType === PaymentMethodType.CreditCard
                  ? undefined
                  : "none",
            }}
          >
            <CreditCardInline
              creditCardIframeRef={creditCardIframeRef}
              cardOnFile={true}
              onError={(error) =>
                dispatch(
                  actionCreators.forms.customerPaymentMethod.setErrorMessage(
                    error
                  )
                )
              }
              onSave={(savedCard) => {
                remoteDataProvider
                  .saveCustomerPaymentMethod(
                    {
                      type: PaymentMethodType.CreditCard,
                      partialNumber: savedCard.partialNumber,
                      token: savedCard.token,
                    },
                    { customerId }
                  )
                  .pipe(timeout(formSaveDefaultTimeout))
                  .subscribe({
                    next: (formSaveResult) => {
                      dispatch(
                        actionCreators.forms.customerPaymentMethod.completeSaving(
                          formSaveResult.parameters,
                          formSaveResult.data
                        )
                      );
                    },

                    error: (err) => {
                      const errorMessage = getErrorMessage(err);
                      dispatch(
                        actionCreators.forms.customerPaymentMethod.setErrorMessage(
                          errorMessage
                        )
                      );
                    },
                  });
              }}
            />
            <div
              data-testid={`$PaymentMethodConvenienceFeeWarning`}
              className="alert alert-info mt-1"
              style={{
                maxWidth: "700px",
                border: "0px",
                padding: "12px",
              }}
            >
              <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
              <small>
                Charges using this payment method cannot include a 3%
                convenience fee due to credit card regulations.
              </small>
            </div>
          </div>
          {
            // ACH toggles whether elements are actually in DOM so that the required elements don't prevent form submission
          }
          {paymentType === PaymentMethodType.Ach ? (
            <div>
              <div className="form-group">
                <label htmlFor="name" className="required">
                  Name
                </label>
                <input
                  id="name"
                  type="text"
                  className="form-control"
                  {...register("name")}
                  required
                  placeholder="JOHN SMITH"
                />
              </div>

              <BankAccountInline
                value={{
                  bankAccountNumber,
                  bankAccountNumberConfirmed,
                  bankAccountType,
                  bankRoutingNumber,
                }}
                onChange={(newValue) => {
                  setValue("bankAccountNumber", newValue.bankAccountNumber);
                  setValue(
                    "bankAccountNumberConfirmed",
                    newValue.bankAccountNumberConfirmed
                  );
                  setValue("bankAccountType", newValue.bankAccountType);
                  setValue("bankRoutingNumber", newValue.bankRoutingNumber);
                }}
              />
            </div>
          ) : null}
        </div>
      </FormContainer2>
    </ModalDataLoader>
  );
};

export default conditionalRenderer(
  CustomerPaymentMethodForm,
  (s) => s.forms.customerPaymentMethod.showForm
);

function getErrorMessage(err: any) {
  const parsedErrors = getErrorMessagesFromError(err);
  const errorMessage =
    parsedErrors.length > 0 ? parsedErrors[0] : constants.unknownErrorMessage;
  return errorMessage;
}

function getNavLinkClasses(
  currentPaymentType: PaymentMethodType,
  navPaymentType: PaymentMethodType
): string {
  let classes = "nav-link";
  if (currentPaymentType === navPaymentType) {
    classes += " active";
  }
  return classes;
}
