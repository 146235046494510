import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { logError } from "../../../services/errorLogger";

export function useGetOpportunityFromStore(opportunityId: string | null) {
  const opportunities = useApplicationStateSelector(
    (s) => s.opportunity.opportunities
  );

  if (!opportunityId) {
    return null;
  }

  const opportunity = opportunities.find((c) => c.id === opportunityId);

  if (!opportunity) {
    logError(
      `unable to find opportunity ${opportunityId} in useGetOpportunityFromStore`
    );
    return null;
  }

  return opportunity;
}
