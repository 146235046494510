import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";

export interface IProps {
  latitude: string;
  longitude: string;
  onCoordinatesChange(latitude: string, longitude: string): void;
  displayMap: boolean;
  setDisplayMap: (displayMap: boolean) => void;
}

const AddressComponents2Map: React.SFC<IProps> = ({
  latitude,
  longitude,
  onCoordinatesChange,
  displayMap,
  setDisplayMap,
}) => {
  const crews = useApplicationStateSelector((s) => s.crew.crews);

  let coordinates = getLatLng(latitude, longitude);

  if (!coordinates) {
    // If the job doesn't have coordinates, get the first crew's coordinates to serve as a default
    let crewWithCoordinates = crews.find(
      (c) => !c.inactive && c.latitude && c.longitude
    );
    if (crewWithCoordinates) {
      coordinates = {
        lat: crewWithCoordinates.latitude,
        lng: crewWithCoordinates.longitude,
      };
    }
  }

  if (!coordinates) {
    // If still no coordinates after trying to pull a crew, just default to an arbitrary point
    coordinates = {
      lat: 32.8212559,
      lng: -80.1105635,
    };
  }

  let coordinateLocation: google.maps.LatLngLiteral | undefined = getLatLng(
    latitude,
    longitude
  );

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <button
          type="button"
          className="btn btn-link"
          style={{ padding: 0 }}
          onClick={() => setDisplayMap(!displayMap)}
        >
          <h5 style={{ textDecoration: "underline" }}>
            {!displayMap ? "Show Map" : "Hide Map"}
          </h5>
        </button>
      </div>
      {displayMap ? (
        <div style={{ height: "400px" }}>
          <GoogleMap
            id="addressmap"
            mapContainerStyle={{ height: `100%` }}
            zoom={18}
            center={coordinates}
            onClick={(e) => {
              onCoordinatesChange(
                e.latLng?.lat()?.toString() ?? "",
                e.latLng?.lng()?.toString() ?? ""
              );
            }}
          >
            {coordinateLocation ? (
              <Marker position={coordinateLocation} />
            ) : null}
          </GoogleMap>
        </div>
      ) : null}
    </div>
  );
};

export default AddressComponents2Map;
function getLatLng(latitude: string, longitude: string) {
  let coordinateLocation: google.maps.LatLngLiteral | undefined = undefined;
  if (latitude && longitude) {
    let parsedLat = parseFloat(latitude);
    let parsedLng = parseFloat(longitude);
    if (!isNaN(parsedLat) && !isNaN(parsedLng)) {
      coordinateLocation = {
        lat: parsedLat,
        lng: parsedLng,
      };
    }
  }
  return coordinateLocation;
}
