import { ICrew } from "../../../models/ICrew";
import { getCrewWorkingHours } from "../../../services/crewService";
import dateService from "../../../services/dateService";

export function isJobOutsideCrewHours({
  startTime,
  endTime,
  crew,
}: {
  startTime: string;
  endTime: string;
  crew: ICrew;
}) {
  const normalizedStartTime = dateService.formatTimeForSerialization(startTime);
  const normalizedEndTime = dateService.formatTimeForSerialization(endTime);

  if (!normalizedStartTime || !normalizedEndTime) {
    return false;
  }

  const { workingHoursStart, workingHoursEnd } = getCrewWorkingHours(crew);
  const normalizedWorkingHoursStart =
    dateService.formatTimeForSerialization(workingHoursStart);
  const normalizedWorkingHoursEnd =
    dateService.formatTimeForSerialization(workingHoursEnd);

  if (!normalizedWorkingHoursStart || !normalizedWorkingHoursEnd) {
    return false;
  }

  const isStartTimeValidForOvernightCrews =
    normalizedWorkingHoursEnd < normalizedWorkingHoursStart &&
    normalizedStartTime < normalizedWorkingHoursEnd;

  return (
    (normalizedStartTime < normalizedWorkingHoursStart &&
      !isStartTimeValidForOvernightCrews) ||
    normalizedEndTime > normalizedWorkingHoursEnd
  );
}
