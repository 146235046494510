import { EditorState } from "draft-js";
import { Control, Controller } from "react-hook-form";
import RichTextEditor from "../../../../containers/app/components/RichTextEditor";
import { IFormData } from "./IFormData";

export default function IntroTextField({
  label,
  field,
  control,
  testId,
}: {
  label: string;
  field: "introText" | "footerText";
  testId: string;
  control: Control<IFormData, object>;
}) {
  return (
    <div className="form-group">
      <h5>{label}</h5>
      <div data-testid={testId}>
        <Controller
          name={field}
          control={control}
          render={({ field }) => (
            <RichTextEditor
              editorState={field.value as EditorState}
              setEditorState={(newText) => field.onChange(newText)}
              ariaLabel={label}
              placeholder={`Type your ${label.toLowerCase()} here...`}
            />
          )}
        />
      </div>
    </div>
  );
}
