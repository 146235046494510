import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useEffect } from "react";
import CardContextMenuButtonLink from "./CardContextMenuButtonLink";

interface IProps {
  text: string;
  skipContextMenuCloseClass: string;
  showChildren: boolean;
  setShowChildren: React.Dispatch<React.SetStateAction<boolean>>;
}

const CardContextMenuEditSubMenu: React.FunctionComponent<IProps> = ({
  text,
  skipContextMenuCloseClass,
  children,
  showChildren,
  setShowChildren,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const submenuWidth = 120;
  const marginAdjustmentFactor = 5;

  const [submenuLeft, setSubmenuLeft] = useState(0);
  const [menuFlipped, setMenuFlipped] = useState(false);
  useEffect(() => {
    if (ref.current) {
      const boundingRect = ref.current.getBoundingClientRect();

      const willOverflowOnRight =
        boundingRect.right + submenuWidth + marginAdjustmentFactor >
        window.innerWidth;
      if (willOverflowOnRight) {
        setSubmenuLeft(-1 * (submenuWidth + marginAdjustmentFactor));
        setMenuFlipped(true);
      } else {
        setSubmenuLeft(boundingRect.width + marginAdjustmentFactor);
        setMenuFlipped(false);
      }
    }
  }, [setSubmenuLeft, setMenuFlipped]);

  return (
    <div
      style={{ position: "relative" }}
      ref={ref}
      className="context-menu-sub-menu-container"
    >
      <CardContextMenuButtonLink
        onClick={() => setShowChildren(true)}
        onMouseEnter={() => setShowChildren(true)}
        className={skipContextMenuCloseClass}
        style={{ width: "100%" }}
        buttonContents={
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
            className={skipContextMenuCloseClass}
          >
            <div
              style={{ marginRight: "10px" }}
              className={skipContextMenuCloseClass}
            >
              {text}
            </div>
            <div>
              <FontAwesomeIcon
                icon={faCaretRight}
                className={skipContextMenuCloseClass}
              />
            </div>
          </div>
        }
      />
      {showChildren ? (
        <div
          className={`border border-dark bg-light context-menu-container context-menu-sub-menu ${
            menuFlipped
              ? "context-menu-sub-menu-flipped"
              : "context-menu-sub-menu-not-flipped"
          }`}
          style={{
            position: "absolute",
            top: "-6px",
            left: `${submenuLeft}px`,
            zIndex: 100,
            width: `${submenuWidth}px`,
            padding: "5px",
          }}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default CardContextMenuEditSubMenu;
