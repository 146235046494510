import { IUnscheduledMaintenanceJob } from "../models/IUnscheduledMaintenanceJob";
import dateService from "./dateService";
import dateFnsParse from "date-fns/parse";
import { actionCreators } from "../modules/actionCreators";
import { mergeMap, timeout } from "rxjs/operators";
import dataProvider from "./dataProvider";
import {
  ILoadDaySchedulesResult,
  IGetMaintenanceJobsResult,
  IGetOneTimeJobsResult,
} from "./remoteDataProvider";
import { forkJoin, of, Observable, race, timer } from "rxjs";
import { getJobsToLoad, getProjectsToLoad } from "./lazyLoadingService";
import { IRootState } from "../store";
import { maintenanceJobType, oneTimeJobType } from "../models/IJobInstance";
import { IDaySchedule } from "../models/IDaySchedule";
import { getSortedItemsV2 } from "./sortingService";
import { IProject } from "../slices/schedule/models/IProject";
import projectDataProvider from "../slices/schedule/services/projectDataProvider";
import { projectActionCreators } from "../slices/schedule/modules/project";
import { getUniqueItems } from "./arrayService";

export function loadWithRetries<T>(loadData: () => Observable<T>) {
  return race(
    loadData(),
    timer(4000).pipe(mergeMap(() => loadData())),
    timer(8000).pipe(mergeMap(() => loadData())),
    timer(16000).pipe(mergeMap(() => loadData())),
    timer(60000).pipe(mergeMap(() => loadData()))
  ).pipe(timeout(70000));
}

export function loadJobsForSchedule(
  loadScheduleResult: ILoadDaySchedulesResult,
  state: IRootState
) {
  const oneTimeJobIdsToEnsureLoaded = [
    ...loadScheduleResult.daySchedules
      .map((ds) =>
        ds.jobInstances
          .filter((ji) => ji.type === oneTimeJobType)
          .filter((ji) => !!ji.oneTimeJobId)
          .map((ji) => ji.oneTimeJobId as string)
      )
      .reduce((arr, ids) => arr.concat(ids), []),
    ...loadScheduleResult.unscheduledMaintenanceJobs
      .map((ds) =>
        ds.jobInstances
          .filter((ji) => ji.type === oneTimeJobType)
          .filter((ji) => !!ji.oneTimeJobId)
          .map((ji) => ji.oneTimeJobId as string)
      )
      .reduce((arr, ids) => arr.concat(ids), []),
  ];

  const maintenanceJobIdsToEnsureLoaded = [
    ...loadScheduleResult.daySchedules
      .map((ds) =>
        ds.jobInstances
          .filter((ji) => ji.type === maintenanceJobType)
          .filter((ji) => !!ji.jobId)
          .map((ji) => ji.jobId as string)
      )
      .reduce((arr, ids) => arr.concat(ids), []),
    ...loadScheduleResult.unscheduledMaintenanceJobs
      .map((ds) =>
        ds.jobInstances
          .filter((ji) => ji.type === maintenanceJobType)
          .filter((ji) => !!ji.jobId)
          .map((ji) => ji.jobId as string)
      )
      .reduce((arr, ids) => arr.concat(ids), []),
  ];

  const projectIdsToEnsureLoaded = [
    ...loadScheduleResult.daySchedules
      .map((ds) =>
        ds.jobInstances
          .filter((ji) => !!ji.projectId)
          .map((ji) => ji.projectId as string)
      )
      .reduce((arr, ids) => arr.concat(ids), []),
    ...loadScheduleResult.unscheduledMaintenanceJobs
      .map((ds) =>
        ds.jobInstances
          .filter((ji) => !!ji.projectId)
          .map((ji) => ji.projectId as string)
      )
      .reduce((arr, ids) => arr.concat(ids), []),
  ];

  const missingOneTimeJobIdsNotAlreadyLoading = getJobsToLoad(
    oneTimeJobIdsToEnsureLoaded,
    state.job.oneTimeJobs,
    state.job.jobsLoading
  );

  const missingMaintenanceJobIdsNotAlreadyLoading = getJobsToLoad(
    maintenanceJobIdsToEnsureLoaded,
    state.job.jobs,
    state.job.jobsLoading
  );

  const missingProjectIdsNotAlreadyLoading = getProjectsToLoad(
    projectIdsToEnsureLoaded,
    state.project.projects,
    state.project.projectsLoading
  );

  let getOneTimeJobsStream: Observable<IGetOneTimeJobsResult>;
  if (missingOneTimeJobIdsNotAlreadyLoading.length > 0) {
    getOneTimeJobsStream = loadWithRetries(() =>
      dataProvider.getOneTimeJobs(missingOneTimeJobIdsNotAlreadyLoading)
    );
  } else {
    getOneTimeJobsStream = of({
      oneTimeJobs: [],
      customers: [],
      customerAdditionalLocations: [],
    });
  }

  let getMaintenanceJobsStream: Observable<IGetMaintenanceJobsResult>;
  if (missingMaintenanceJobIdsNotAlreadyLoading.length > 0) {
    getMaintenanceJobsStream = loadWithRetries(() =>
      dataProvider.getMaintenanceJobs(missingMaintenanceJobIdsNotAlreadyLoading)
    );
  } else {
    getMaintenanceJobsStream = of({
      maintenanceJobs: [],
      customers: [],
      customerAdditionalLocations: [],
    });
  }

  let getProjectsStream: Observable<Array<IProject>>;
  if (missingProjectIdsNotAlreadyLoading.length > 0) {
    getProjectsStream = loadWithRetries(() =>
      projectDataProvider.getProjects({
        projectIds: missingProjectIdsNotAlreadyLoading,
      })
    );
  } else {
    getProjectsStream = of([]);
  }

  return forkJoin({
    oneTimeJobsResult: getOneTimeJobsStream,
    maintenanceJobsResult: getMaintenanceJobsStream,
    projectsResult: getProjectsStream,
  }).pipe(
    mergeMap(({ oneTimeJobsResult, maintenanceJobsResult, projectsResult }) => {
      return of(
        actionCreators.loadOneTimeJobsComplete(
          oneTimeJobsResult.oneTimeJobs,
          oneTimeJobsResult.customers,
          oneTimeJobsResult.customerAdditionalLocations
        ),
        actionCreators.loadMaintenanceJobsComplete(
          maintenanceJobsResult.maintenanceJobs,
          maintenanceJobsResult.customers,
          maintenanceJobsResult.customerAdditionalLocations
        ),
        actionCreators.loadDaySchedulesCompleted(
          loadScheduleResult.daySchedules,
          loadScheduleResult.unscheduledMaintenanceJobs
        ),
        projectActionCreators.loadProjectsComplete({ projects: projectsResult })
      );
    })
  );
}

export function isLoadingScheduleDates(
  scheduleWeek: Date,
  scheduleColumns: Array<object | null>,
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>
) {
  const hasMissingDates = scheduleColumns.findIndex((c) => !c) !== -1;

  const loadingUnscheduledJobs = weeksUnscheduledMaintenanceJobs.some(
    (w) => w.initialLoadRunning
  );

  return { hasMissingDates, loadingUnscheduledJobs };
}

export function getMaxUnassignedWeekAlreadyLoaded(
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>
) {
  return weeksUnscheduledMaintenanceJobs
    .filter((weekJob) => !weekJob.stale && !weekJob.partialDay)
    .reduce((maxValue: null | string, weekJobs: IUnscheduledMaintenanceJob) => {
      const isoDate = dateService.formatAsIso(weekJobs.week);
      if (maxValue === null) {
        return isoDate;
      } else if (isoDate > maxValue) {
        return isoDate;
      } else {
        return maxValue;
      }
    }, null);
}

export function getUnassignedWeeksToLoad(
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>,
  currentWeek: Date
): Array<Date> {
  let datesToReturn: Array<Date> = [];
  if (
    !weeksUnscheduledMaintenanceJobs.some(
      (d) => dateService.areDatesEqual(d.week, currentWeek) && !d.stale
    )
  ) {
    datesToReturn.push(currentWeek);
  }

  const currentWeekIso = dateService.formatAsIso(currentWeek);
  const dirtyOrPartialEarlierWeeks = weeksUnscheduledMaintenanceJobs
    .filter((d) => {
      const returnValue =
        dateService.formatAsIso(d.week) <= currentWeekIso &&
        (d.stale ||
          (d.partialDay &&
            d.partialDayEvaluatedAsOf &&
            dateService.formatAsIso(d.partialDayEvaluatedAsOf) >
              currentWeekIso));
      return returnValue;
    })
    .filter((d) => !d.initialLoadRunning)
    .map((d) => dateFnsParse(d.week));

  datesToReturn = [...datesToReturn, ...dirtyOrPartialEarlierWeeks];

  return datesToReturn;
}

export function getOrderedJobInstanceIdsForDrop(
  jobInstanceIds: Array<string>,
  daySchedules: Array<IDaySchedule>,
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>
) {
  // Track fromStaleSchedule so that job instances that are on both stale and non-stale schedules
  // can be distinguished and the non-stale schedule instance takes precedence.
  let orderedJobInstanceIds: Array<{ id: string; fromStaleSchedule: boolean }> =
    [];

  const sortedDaySchedules = getSortedItemsV2(daySchedules, ["date"]);
  sortedDaySchedules.forEach((sortedDaySchedule) => {
    // Use the natural ordering of the job instances rather than the "order"
    // property.  Order of objects in the list is the true order.
    const weekOrderedJobInstances = sortedDaySchedule.jobInstances;
    orderedJobInstanceIds = [
      ...orderedJobInstanceIds,
      ...weekOrderedJobInstances
        .filter((ji) => jobInstanceIds.some((i) => i === ji.id))
        .map((ji) => ({
          id: ji.id,
          fromStaleSchedule: sortedDaySchedule.stale,
        })),
    ];
  });

  orderedJobInstanceIds = [
    ...orderedJobInstanceIds,
    ...jobInstanceIds
      .filter((jobInstanceId) =>
        weeksUnscheduledMaintenanceJobs.some((w) =>
          w.jobInstances.some((i) => i.id === jobInstanceId)
        )
      )
      .map((ji) => ({
        id: ji,
        // Shouldn't matter to not respect stale property for unscheduled jobs
        // since will always end up last regardless
        fromStaleSchedule: false,
      })),
  ];

  // Remove items that are both stale and not-stale, giving precedence to non-stale
  orderedJobInstanceIds = orderedJobInstanceIds.filter(
    (ji1) =>
      !(
        ji1.fromStaleSchedule &&
        orderedJobInstanceIds.some(
          (ji2) => ji2.id === ji1.id && !ji2.fromStaleSchedule
        )
      )
  );

  return getUniqueItems(orderedJobInstanceIds.flatMap((ji) => ji.id));
}
