import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FormContainerWithoutRedux from "../../../containers/app/components/FormContainerWithoutRedux";
import ModalDataLoader from "../../../containers/app/components/ModalDataLoader";
import QuickBooksCustomerSelection from "../../../containers/app/components/QuickBooksCustomerSelection";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { IQuickBooksCustomer } from "../../../models/IQuickBooksCustomer";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { map } from "rxjs/operators";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import { useGetCustomerFromStore } from "../../../hooks/useGetCustomerFromStore";
import { ErrorMessageType } from "../../../containers/app/components/FormContainer";

interface ILoadData {
  quickBooksCustomers: Array<IQuickBooksCustomer>;
}

interface IFormData {
  quickBooksCustomerId: string;
}

interface IProps {
  customerId: string;
  customerName: string;
  onSaveComplete: () => void;
  onCancel: () => void;
}

const LinkQuickBooksCustomerForm: React.FunctionComponent<IProps> = ({
  customerId,
  customerName,
  onSaveComplete,
  onCancel,
}) => {
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");

  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );

  const [quickBooksCustomers, setQuickBooksCustomers] = useState<
    Array<IQuickBooksCustomer>
  >([]);

  const customer = useGetCustomerFromStore(customerId);

  const { control, getValues } = useForm<IFormData>();
  const dispatch = useDispatch();

  const loadData = useCallback(() => {
    return remoteDataProvider.getQuickBooksCustomers().pipe(
      map((quickBooksCustomers) => {
        return {
          quickBooksCustomers: quickBooksCustomers,
        } as ILoadData;
      })
    );
  }, []);

  const handleLoadedData = useCallback((result: ILoadData) => {
    setQuickBooksCustomers(result.quickBooksCustomers);
  }, []);

  return (
    <ModalDataLoader<ILoadData>
      errorMessage={
        "The data could not be loaded for this form. Please check your Internet connection and try again."
      }
      onErrorAlertClose={onCancel}
      loadData={loadData}
      onDataLoaded={handleLoadedData}
    >
      <FormContainerWithoutRedux
        formHeader={`Link QuickBooks Customer for ${customerName}`}
        formType={FormTypesV2.linkQuickBooksCustomerForm}
        save={() => {
          return remoteDataProvider.saveCustomer(
            {
              id: customerId,
              quickBooksId: getValues("quickBooksCustomerId"),
              originalInactive: null,
            },
            { customerId: customerId }
          );
        }}
        onSaveComplete={() => {
          dispatch(
            actionCreators.customerQuickBooksLinkUpdated(
              customerId,
              getValues("quickBooksCustomerId")
            )
          );
          onSaveComplete();
        }}
        onCancel={() => {
          onCancel();
        }}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      >
        <div className="form-group">
          {isQuickBooksEnabled ? (
            <div className="form-group">
              <label htmlFor="customer" className="required">
                QuickBooks Customer
              </label>
              <Controller
                name={`quickBooksCustomerId`}
                control={control}
                render={({ field }) => (
                  <QuickBooksCustomerSelection
                    inputId="customer"
                    customer={customer}
                    required={true}
                    options={quickBooksCustomers}
                    quickBooksCustomerId={field.value ?? ""}
                    onChange={(id) => {
                      field.onChange(id);
                    }}
                    onCreatedQuickBooksCustomer={({ id, displayName }) => {
                      setQuickBooksCustomers([
                        ...quickBooksCustomers,
                        {
                          id,
                          displayName,
                        } as IQuickBooksCustomer,
                      ]);
                    }}
                    usePortal={true}
                  />
                )}
              />
            </div>
          ) : null}
        </div>
      </FormContainerWithoutRedux>
    </ModalDataLoader>
  );
};

export default LinkQuickBooksCustomerForm;
