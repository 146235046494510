import { useState } from "react";
import PhoneNumberField from "../../../containers/app/components/PhoneNumberField";
import { fullStoryTrack } from "../../../services/fullStoryService";
import { useUserSettings } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import onboardingDataProvider from "../services/onboardingDataProvider";
import Spinner from "../../../containers/app/components/Spinner";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";

const closedStickySettingValue = "true";
export function InitialDispatchPrompt() {
  const showDispatchPrompt = useApplicationStateSelector(
    (s) => s.common.showDispatchPrompt
  );

  const [phoneNumber, setPhoneNumber] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [closed, setClosed] = useState(false);

  const dispatch = useDispatch();

  const { getUserSettings, setUserSettings } = useUserSettings();

  const initialDispatchPromptClosed = getUserSettings(
    UserSettingsType.initialDispatchPromptClosed
  );
  if (
    // Even if showDispatchPrompt is false, still show if
    // closed so confirmation appears
    (!showDispatchPrompt && !showConfirmation) ||
    closed ||
    initialDispatchPromptClosed === closedStickySettingValue
  ) {
    return null;
  }

  return (
    <>
      {saving ? <Spinner /> : null}

      <div
        data-testid="dispatchPrompt"
        className={"alert alert-primary"}
        role="alert"
      >
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
          data-testid="close"
          onClick={() => {
            setClosed(true);
            setUserSettings(
              UserSettingsType.initialDispatchPromptClosed,
              "true"
            );
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        {!showConfirmation ? (
          <>
            <div>
              <div className="d-flex justify-content-center">
                Check out our crew member mobile experience by dispatching a
                schedule to your phone!
              </div>
              <div className="d-flex justify-content-center">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();

                    fullStoryTrack("Dispatch Prompt Submitted");

                    setSaving(true);

                    onboardingDataProvider
                      .publishExampleSchedule({
                        phoneNumber,
                      })
                      .subscribe({
                        next: () => {
                          setSaving(false);
                          setShowConfirmation(true);
                          dispatch(actionCreators.initialDispatchSent());
                        },

                        error: (err) => {
                          setSaving(false);
                          setErrorMessage(getErrorMessageFromError(err));
                        },
                      });
                  }}
                >
                  <div>
                    <div
                      className="d-flex mt-1"
                      style={{ columnGap: "10px", alignItems: "end" }}
                    >
                      <label
                        htmlFor="dispatchPhoneNumber"
                        className="mb-1 text-nowrap"
                      >
                        Your phone number:
                      </label>
                      <div style={{ maxWidth: "125px" }}>
                        <PhoneNumberField
                          id={"dispatchPhoneNumber"}
                          dataTestId="dispatchPhoneNumber"
                          onChange={(e) =>
                            setPhoneNumber(e.currentTarget.value)
                          }
                          value={phoneNumber}
                          className={"form-control"}
                          required
                        />
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="btn btn-secondary btn-sm"
                          data-testid="send"
                        >
                          Send
                        </button>
                      </div>
                    </div>
                    {errorMessage ? (
                      <div className="text-danger" data-testid="errorMessage">
                        {errorMessage}
                      </div>
                    ) : null}
                  </div>
                </form>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="d-flex justify-content-center"
              data-testid="scheduleSentConfirmation"
            >
              <div style={{ maxWidth: "700px" }}>
                <div>
                  <strong>Schedule sent!</strong>
                </div>
                <div>
                  To send automatically every day, go to Settings &gt; Dispatch
                  Scheduling.
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
