import { useEffect, useState } from "react";
import { finalize } from "rxjs/operators";
import subscriptionDataProvider from "../services/subscriptionDataProvider";
import { ISubscriptionDetails } from "../models/ISubscriptionDetails";
import { ModalDataLoaderStateless } from "../../../containers/app/components/ModalDataLoaderStateless";
import FormContainerWithoutRedux from "../../../containers/app/components/FormContainerWithoutRedux";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import { ErrorMessageType } from "../../../containers/app/components/FormContainer";
import { SubscriptionPlanDetails } from "./SubscriptionPlanDetails";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";

export function ReactivateSubscription({ onClose }: { onClose(): void }) {
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");

  const dispatch = useDispatch();

  const {
    loadingSubscriptionDetails,
    errorLoadingSubscriptionDetails,
    subscriptionDetails,
  } = useLoadSubscriptionDetails();

  return (
    <>
      <ModalDataLoaderStateless
        loadingData={loadingSubscriptionDetails}
        errorLoadingData={errorLoadingSubscriptionDetails}
        onErrorAlertClose={onClose}
        errorMessage="The subscription details were unable to load. Please try again."
      >
        <FormContainerWithoutRedux
          formType={FormTypesV2.reactivateSubscription}
          formHeader="Reactivate Subscription"
          saveButtonText="Reactivate"
          onCancel={onClose}
          save={() => subscriptionDataProvider.reactivateSubscription()}
          onSaveComplete={() => {
            dispatch(actionCreators.updateInitialLoad());
            onClose();
          }}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        >
          <div className="mb-3">
            Your subscription will be recovered where you left off!
          </div>

          {subscriptionDetails !== null ? (
            <div className="mb-3">
              <SubscriptionPlanDetails
                subscriptionDetails={subscriptionDetails}
              />
            </div>
          ) : null}
        </FormContainerWithoutRedux>
      </ModalDataLoaderStateless>
    </>
  );
}

function useLoadSubscriptionDetails() {
  const [loadingSubscriptionDetails, setLoadingSubscriptionDetails] =
    useState(false);
  const [errorLoadingSubscriptionDetails, setErrorLoadingSubscriptionDetails] =
    useState(false);
  const [subscriptionDetails, setSubscriptionDetails] =
    useState<ISubscriptionDetails | null>(null);

  useEffect(() => {
    setLoadingSubscriptionDetails(true);
    const subscription = subscriptionDataProvider
      .getSubscriptionDetails()
      .pipe(
        finalize(() => {
          setLoadingSubscriptionDetails(false);
        })
      )
      .subscribe({
        next: (result) => {
          setSubscriptionDetails(result);
        },

        error: () => {
          setErrorLoadingSubscriptionDetails(true);
        },
      });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, []);

  return {
    loadingSubscriptionDetails,
    errorLoadingSubscriptionDetails,
    subscriptionDetails,
  };
}
