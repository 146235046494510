import { useState } from "react";
import invoiceDataProvider from "../services/invoiceDataProvider";
import Spinner from "../../../containers/app/components/Spinner";
import Prompt from "../../../containers/app/components/Prompt";
import { timeout } from "rxjs/operators";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";

export function BillingInvoiceListDeletePayment({
  showPrompt,
  setShowPrompt,
  invoiceId,
  paymentId,
  onDataChanged,
  setErrorMessage,
  promptMessage,
}: {
  showPrompt: boolean;
  setShowPrompt: (v: boolean) => void;
  invoiceId: string;
  paymentId: string;
  onDataChanged: () => void;
  setErrorMessage: (error: string) => void;
  promptMessage: string;
}) {
  const [saving, setSaving] = useState(false);

  return (
    <>
      {saving ? <Spinner /> : null}

      <Prompt
        promptMessage={promptMessage}
        showPrompt={showPrompt && !saving}
        onCancel={() => setShowPrompt(false)}
        onConfirm={() => {
          setSaving(true);
          setShowPrompt(false);

          invoiceDataProvider
            .deleteInvoicePayment({
              invoiceId: invoiceId,
              paymentId: paymentId,
            })
            .pipe(timeout(10000))
            .subscribe({
              next: () => {
                setSaving(false);
                onDataChanged();
              },

              error: (err) => {
                setSaving(false);
                setErrorMessage(getErrorMessageFromError(err));
              },
            });
        }}
      />
    </>
  );
}
