export function getIntegerFromNumberOrString(input: number | string) {
  if (typeof input === "number") {
    return input;
  } else if (typeof input === "string") {
    return parseInt(input, 10);
  } else {
    return 0;
  }
}

export function getFloatFromNumberOrString(input: number | string) {
  if (typeof input === "number") {
    return input;
  } else if (typeof input === "string") {
    return parseFloat(input);
  } else {
    return 0;
  }
}

export function getNullableFloatFromNumberOrString(
  input: number | string
): number | null {
  if (typeof input === "number") {
    return input;
  } else if (typeof input === "string" && input.trim() !== "") {
    return parseFloat(input);
  } else {
    return null;
  }
}

export function getNumericString(input: number | string | null) {
  if (typeof input === "number") {
    return input.toString();
  } else if (typeof input === "string") {
    return input;
  } else {
    return "";
  }
}
