import { isStringSet } from "../services/stringService";
import { useUserSettings } from "../services/userSettingsService";
import { UserSettingsType } from "../enums/userSettingsType";
import { useApplicationStateSelector } from "./useApplicationStateSelector";

export function useGetReplyToEmailAddress() {
  const { getUserSettings } = useUserSettings();
  const userAccountEmail = useApplicationStateSelector(
    (s) => s.common.userAccountEmail
  );

  const savedReplyToEmailAddress = getUserSettings<string>(
    UserSettingsType.replyToEmailAddress
  );

  if (isStringSet(savedReplyToEmailAddress)) {
    return savedReplyToEmailAddress;
  } else {
    return userAccountEmail;
  }
}
