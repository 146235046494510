import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import { catchError } from "rxjs/operators";
import crew, { ICrewState } from "./crew";
import job, { IJobState } from "./job";
import { History } from "history";
import { RouterState, connectRouter } from "connected-react-router";

import schedule, { IScheduleState } from "./schedule";
import scheduleUi, { IScheduleUiState } from "./scheduleUi";
import common, { ICommonState } from "./common";
import distanceCache, { IDistanceCacheState } from "./distanceCache";
import actionEpics from "./actionEpics";
import { formReducers as forms } from "../formGenerator";
import { IFormsState } from "../formGenerator/formReducers";
import prompt, { IPromptState } from "./prompt";
import manageUi, { IManageUiState } from "./manageUi";
import commonUi, { ICommonUiState } from "./commonUi";
import customer, { ICustomerState } from "./customer";
import customerSelection, {
  ICustomerSelectionState,
} from "./customerSelection";
import billingReport, { IBillingReportState } from "./billingReport";
import formV2, { IFormV2State } from "./formV2";
import opportunity, {
  IOpportunityState,
} from "../slices/sales/modules/opportunity";
import proposal, { IProposalState } from "../slices/sales/modules/proposal";
import salesEpics from "../slices/sales/modules/salesEpics";
import project, { IProjectState } from "../slices/schedule/modules/project";
import scheduleEpics from "../slices/schedule/modules/scheduleEpics";
import userSettings, { IUserSettingsState } from "./userSettings";

export const rootEpic = (action$: any, store: any) => {
  return combineEpics(
    ...actionEpics,
    ...salesEpics,
    ...scheduleEpics
  )(action$, store).pipe(
    catchError((err, stream) => {
      // TODO: Log to server
      console.log(err);
      throw new Error(err);
    })
  );
};

export interface IApplicationState {
  router: RouterState;
  job: IJobState;
  crew: ICrewState;
  common: ICommonState;
  schedule: IScheduleState;
  distanceCache: IDistanceCacheState;
  scheduleUi: IScheduleUiState;
  forms: IFormsState;
  prompt: IPromptState;
  manageUi: IManageUiState;
  commonUi: ICommonUiState;
  formV2: IFormV2State;
  customer: ICustomerState;
  customerSelection: ICustomerSelectionState;
  billingReport: IBillingReportState;
  opportunity: IOpportunityState;
  proposal: IProposalState;
  project: IProjectState;
  userSettings: IUserSettingsState;
}

export const rootReducer = (history: History) =>
  combineReducers<IApplicationState>({
    router: connectRouter(history),
    job,
    crew,
    common,
    schedule,
    scheduleUi,
    distanceCache,
    forms,
    prompt,
    manageUi,
    commonUi,
    customer,
    customerSelection,
    billingReport,
    formV2,
    opportunity,
    proposal,
    project,
    userSettings,
  });
