import React, { useEffect, useRef, useState } from "react";
import PageWithNavBar2 from "../../PageWithNavBar2";
import { useSelector, useDispatch } from "react-redux";
import { IApplicationState } from "../../../../modules";
import { ICrew } from "../../../../models/ICrew";
import { RouteComponentProps } from "react-router-dom";
import { addMonths, endOfMonth, parse, startOfMonth } from "date-fns";
import Spinner from "../../components/Spinner";
import dateService from "../../../../services/dateService";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import Calendar from "../../components/Calendar";
import {
  getDaysToLoad,
  IDayToLoad,
} from "../../../../services/dayScheduleLoader";
import { actionCreators } from "../../../../modules/actionCreators";
import MonthDay from "./MonthDay";
import { builders } from "../../../../services/routing";
import { useUserSettings } from "../../../../services/userSettingsService";
import { UserSettingsType } from "../../../../enums/userSettingsType";
import { DragDropContext } from "react-beautiful-dnd";
import DropJobsPermanentPrompt from "../../components/schedule/DropJobsPermanentPrompt";
import { getDestinationProperties } from "../../../../services/dropJobService";
import TodayButton from "../../../../slices/schedule/components/TodayButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { getActiveSortedCrews } from "../../../../services/crewService";
import CrewMultiSelect from "../../components/CrewMultiSelect";
import { CrewScheduleType } from "../../../../slices/schedule/enums/crewScheduleType";

interface IRouteParams {
  date: string;
  defaultCrewId?: string;
}

const Month: React.FunctionComponent<RouteComponentProps<IRouteParams>> = ({
  match,
}) => {
  const crews = useSelector<IApplicationState, Array<ICrew>>(
    (s) => s.crew.crews
  );
  const [selectedCrewIds, setSelectedCrewIds] = useState<Array<string>>([]);

  const dispatch = useDispatch();
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const { getUserSettings } = useUserSettings();

  const haveCrewsBeenDefaulted = useRef(false);

  const startingDate = getStartingDate(match.params);
  const monthDates = dateService.getMonthDates(startingDate);

  const schedulesToLoad = getDaysToLoad({
    loadedDaySchedules: daySchedules,
    crewIds: selectedCrewIds,
    dates: monthDates,
  });

  useEffect(() => {
    const activeSortedCrews = getActiveSortedCrews(crews);
    if (selectedCrewIds.length === 0 && !haveCrewsBeenDefaulted.current) {
      const savedSelectedCrewIds = getUserSettings(
        UserSettingsType.monthDefaultCrews
      ) as Array<string>;
      if (
        savedSelectedCrewIds &&
        activeSortedCrews.some((c) => savedSelectedCrewIds.includes(c.id))
      ) {
        setSelectedCrewIds(savedSelectedCrewIds);
      } else {
        setSelectedCrewIds([activeSortedCrews[0].id]);
      }

      haveCrewsBeenDefaulted.current = true;
    }
  }, [selectedCrewIds, crews, getUserSettings]);

  useEffect(() => {
    const activeSortedCrews = getActiveSortedCrews(crews);
    if (
      match.params.defaultCrewId &&
      activeSortedCrews.some((c) => c.id === match.params.defaultCrewId)
    ) {
      setSelectedCrewIds([match.params.defaultCrewId]);
    }
  }, [match.params.defaultCrewId, crews]);

  useEffect(() => {
    if (schedulesToLoad.length > 0) {
      // Partition requests to server into smaller buckets to prevent
      // requests from being too large. Potentially could result in server
      // timeouts. Parititioning also allows requests to be parallel.
      const partitionedSchedulesToLoad =
        getPartitionedSchedules(schedulesToLoad);

      partitionedSchedulesToLoad.forEach((partition) =>
        dispatch(actionCreators.loadDaySchedulesStarting(partition, [], null))
      );
    }
  }, [dispatch, schedulesToLoad]);

  const { setUserSettings } = useUserSettings();

  useEffect(() => {
    setUserSettings(UserSettingsType.monthDefaultCrews, selectedCrewIds);
  }, [selectedCrewIds, setUserSettings]);

  const permanentDropJobsMessage = useApplicationStateSelector(
    (s) => s.scheduleUi.permanentDropJobsMessage
  );

  const loadingJobs =
    schedulesToLoad.length > 0 ||
    daySchedules.some(
      (ds) =>
        selectedCrewIds.some((c) => c === ds.crewId) &&
        monthDates.some((d) => dateService.areDatesEqual(d, ds.date)) &&
        ds.initialLoadRunning
    );

  let calendarContents: { [key: string]: JSX.Element | undefined };

  // Only set calendarContents once schedules are no longer loading.
  // Otherwise, days already loaded will appear but days not loaded will be black, potentially
  // causing confusion.
  if (!loadingJobs) {
    calendarContents = buildCalendarContents(monthDates, selectedCrewIds);
  } else {
    calendarContents = {};
  }

  const doesNextMonthExceedMaxDate =
    endOfMonth(addMonths(startingDate, 1)) >=
    dateService.getMaximumDateForSchedule();

  return (
    <PageWithNavBar2
      hideShiftSchedule={true}
      subHeaderLeftSideContent={
        <div className="d-flex" data-testid="crewSelectContainer">
          <CrewMultiSelect
            crews={getActiveSortedCrews(crews)}
            selectedCrewIds={selectedCrewIds}
            setSelectedCrewIds={setSelectedCrewIds}
            inputId="crewSelect"
          />
          <div style={{ alignSelf: "start" }}>
            <TodayButton
              link={builders.schedule.buildMonthRoute(
                dateService.formatAsIso(new Date()),
                undefined
              )}
            />
          </div>
        </div>
      }
      subHeaderRightSideContent={
        <button
          className="btn btn-link"
          type="button"
          onClick={() => window.print()}
        >
          <FontAwesomeIcon
            icon={faPrint}
            size="2x"
            className="text-dark"
            style={{ verticalAlign: "middle" }}
            title="Print Month"
          />
        </button>
      }
    >
      {!!permanentDropJobsMessage ? (
        <div
          style={{
            position: "sticky",
            top: "15px",
            zIndex: 15,
          }}
          role="alert"
        >
          <DropJobsPermanentPrompt />
        </div>
      ) : null}

      <div>{loadingJobs ? <Spinner /> : null}</div>

      {!loadingJobs ? (
        <DragDropContext
          onDragEnd={(result) => {
            if (!result?.destination) {
              return;
            }

            let hasMovedInSameTimeBasedCrew = false;
            if (result.source.droppableId === result.destination.droppableId) {
              const daySchedule = daySchedules.find(
                (ds) => ds.id === result.source.droppableId
              );
              const crew = crews.find((c) => c.id === daySchedule?.crewId);
              if (crew?.scheduleType === CrewScheduleType.time) {
                hasMovedInSameTimeBasedCrew = true;
              }
            }
            if (hasMovedInSameTimeBasedCrew) {
              return;
            }

            const destinationDayScheduleId = result.destination.droppableId;
            const destinationIndex = result.destination.index;

            const destinationProperties = getDestinationProperties(
              destinationIndex,
              daySchedules,
              destinationDayScheduleId,
              result.source.droppableId,
              result.source.index
            );

            dispatch(
              actionCreators.dropJob(
                [result.draggableId],
                destinationDayScheduleId,
                false,
                null,
                destinationProperties.destinationPrecedingJobInstanceId,
                destinationProperties.destinationDayScheduleDate,
                destinationProperties.destinationDayCrewId,
                daySchedules
              )
            );
          }}
        >
          <Calendar
            className="my-5"
            date={dateService.formatAsIso(startingDate)}
            previousMonthLink={builders.schedule.buildMonthRoute(
              dateService.formatAsIso(addMonths(startingDate, -1))
            )}
            nextMonthLink={builders.schedule.buildMonthRoute(
              dateService.formatAsIso(addMonths(startingDate, 1))
            )}
            nextMonthLinkVisible={!doesNextMonthExceedMaxDate}
            calendarContents={calendarContents}
          />
        </DragDropContext>
      ) : null}
    </PageWithNavBar2>
  );
};

export default Month;

function buildCalendarContents(
  monthDates: Date[],
  selectedCrewIds: Array<string>
) {
  const calendarContents: { [k: string]: JSX.Element | undefined } = {};
  monthDates.forEach((date) => {
    calendarContents[dateService.formatAsIso(date)] = (
      <MonthDay
        date={dateService.formatAsIso(date)}
        selectedCrewIds={selectedCrewIds}
      />
    );
  });

  return calendarContents;
}

function getStartingDate(params: IRouteParams) {
  return startOfMonth(params.date ? parse(params.date) : new Date());
}

function getPartitionedSchedules(schedulesToLoad: IDayToLoad[]) {
  const schedulesToLoadPartitions = [];
  let currentPartition = [];

  const partitionSize = 15;
  for (let i = 0; i < schedulesToLoad.length; i++) {
    currentPartition.push(schedulesToLoad[i]);

    if (i !== 0 && i % partitionSize === 0) {
      schedulesToLoadPartitions.push(currentPartition);
      currentPartition = [];
    }
  }

  if (currentPartition.length > 0) {
    schedulesToLoadPartitions.push(currentPartition);
  }

  return schedulesToLoadPartitions;
}
