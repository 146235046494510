import { MinuteWindowOptions } from "./Calendar";

export function getBlocksFromCalendarEnd({
  hour,
  minuteWindow,
  lastHour,
}: {
  hour: number;
  minuteWindow: MinuteWindowOptions;
  lastHour: number | null;
}) {
  let blocksFromCalendarEnd: number | null = null;
  if (typeof lastHour === "number") {
    // lastHour is just the last hour shown. We still show the next blocks under it so add 1.
    blocksFromCalendarEnd = (lastHour - hour + 1) * 4;

    let lastHourAdjustment = 0;
    if (minuteWindow === 45) {
      lastHourAdjustment = 3;
    } else if (minuteWindow === 30) {
      lastHourAdjustment = 2;
    } else if (minuteWindow === 15) {
      lastHourAdjustment = 1;
    }

    blocksFromCalendarEnd -= lastHourAdjustment;
  }

  return blocksFromCalendarEnd;
}
