import * as React from "react";
import NewPhoto from "./NewFile";
import ExistingPhoto, { IFormDataFile } from "./ExistingFile";
import PendingPhoto from "./PendingFile";
import {
  resizeAndUploadImage,
  uploadFile,
} from "../../../../services/fileUpload";
import { timeout } from "rxjs/operators";
import {
  getFileComponents,
  isImageByType,
} from "../../../../services/fileService";
import Lightbox from "../Lightbox";

interface IProps {
  header: string;
  imagePrefix: string;
  tenantId: string;
  files: Array<IFormDataFile>;
  onFileAdded: (file: IFormDataFile) => void;
  onFileRemoved: (fileId: string | null, imagePath: string | null) => void;
  onFileUpdated: (
    fileId: string | null,
    imagePath: string | null,
    caption: string
  ) => void;
  onFileUploadingStatusChange: (uploading: boolean) => void;
  showHeader?: boolean;
  allowExcelAndWord?: boolean;
  hideLabel?: boolean;
  addAdditionalFiles?: React.ReactNode | null;
}

interface IState {
  pendingPhotos: Array<IPendingFile>;
  showLightbox: boolean;
  photoIndex: number;
}

export interface IPendingFile {
  tempId: string;
  file: File;
  errorUploading: boolean;
}

class Files extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      pendingPhotos: [],
      showLightbox: false,
      photoIndex: 0,
    };

    this.uploadFiles = this.uploadFiles.bind(this);
  }

  public render() {
    const files = this.props.files;
    const photos = files.filter((f) => isImageByType(f.contentType));

    return (
      <React.Fragment>
        {this.props.showHeader ? <h5>{this.props.header}</h5> : null}

        {this.props.files.map((p, index) => (
          <ExistingPhoto
            tenantId={this.props.tenantId}
            imagePrefix={this.props.imagePrefix}
            key={p.id || p.imagePath || index}
            file={p}
            onFileRemoved={this.props.onFileRemoved}
            onFileUpdated={this.props.onFileUpdated}
            onPhotoClicked={() => {
              this.setState({
                showLightbox: true,
                photoIndex: photos.indexOf(p),
              });
            }}
          />
        ))}

        {this.state.pendingPhotos.map((p) => (
          <PendingPhoto
            file={p}
            key={p.tempId}
            onRetryFileUpload={() => {
              this.setState({
                pendingPhotos: this.state.pendingPhotos.map((pp) => {
                  if (pp.tempId === p.tempId) {
                    return {
                      ...pp,
                      errorUploading: false,
                    };
                  } else {
                    return pp;
                  }
                }),
              });

              this.uploadFiles([p]);
            }}
            onCloseError={() => {
              this.setState({
                pendingPhotos: this.state.pendingPhotos.filter(
                  (pendingPhotoToCheck) =>
                    pendingPhotoToCheck.tempId !== p.tempId
                ),
              });
            }}
          />
        ))}

        {this.props.hideLabel ? null : <h6>Add file</h6>}

        <NewPhoto
          onFilesAdded={(p) => {
            this.setState({
              pendingPhotos: [...this.state.pendingPhotos, ...p],
            });

            this.uploadFiles(p);
          }}
          allowExcelAndWord={this.props.allowExcelAndWord}
          addAdditionalFiles={this.props.addAdditionalFiles}
        />
        {this.state.showLightbox && (
          <Lightbox
            items={files.map((p) => {
              const fileComponents = getFileComponents(p.imagePath);
              const imagePath = fileComponents.success
                ? fileComponents.linkUrl
                : p.imagePath;

              return {
                ...p,
                imagePath: imagePath ?? "",
              };
            })}
            itemIndex={this.state.photoIndex}
            setItemIndex={(newIndex) =>
              this.setState({
                photoIndex: newIndex,
              })
            }
            onClose={() =>
              this.setState({
                showLightbox: false,
              })
            }
          />
        )}
      </React.Fragment>
    );
  }

  private uploadFiles(pendingPhotos: Array<IPendingFile>) {
    if (pendingPhotos.length === 0) {
      return;
    }

    const pendingPhoto = pendingPhotos[0];

    this.props.onFileUploadingStatusChange(true);

    const errorUploadHandler = (error: any) => {
      console.log(error);

      const newPendingPhotos = this.state.pendingPhotos.map((x) =>
        x.tempId === pendingPhoto.tempId
          ? {
              ...x,
              errorUploading: true,
            }
          : x
      );

      this.setState({
        pendingPhotos: newPendingPhotos,
      });

      this.onUploadComplete(newPendingPhotos);
    };

    const handlePhotoAdded = () => {
      const newPendingPhotos = this.state.pendingPhotos.filter(
        (x) => x.tempId !== pendingPhoto.tempId
      );

      this.setState({
        pendingPhotos: newPendingPhotos,
      });

      this.onUploadComplete(newPendingPhotos);

      this.uploadFiles(pendingPhotos.slice(1));
    };

    const timeoutValue = 30000;

    if (pendingPhoto.file.type.toLowerCase().startsWith("image")) {
      resizeAndUploadImage(pendingPhoto.file)
        .pipe(timeout(timeoutValue))
        .subscribe((result) => {
          const baseImage = result.find((r) => r.thumbnailKey === null);
          if (!baseImage) {
            throw new Error("base image not found");
          }

          const thumbnails = result
            .filter((r) => r !== baseImage && r.thumbnailKey)
            .map((r) => ({
              ...r,
              thumbnailKey: r.thumbnailKey as number,
            }));

          this.props.onFileAdded({
            caption: "",
            id: null,
            thumbnails,
            imagePath: baseImage.imagePath,
            contentType: pendingPhoto.file.type,
            actualWidth: baseImage.actualWidth,
            actualHeight: baseImage.actualHeight,
            timestamp: baseImage.timestamp,
          });

          handlePhotoAdded();
        }, errorUploadHandler);
    } else {
      uploadFile(pendingPhoto.file)
        .pipe(timeout(timeoutValue))
        .subscribe((result) => {
          this.props.onFileAdded({
            caption: "",
            id: null,
            thumbnails: [],
            imagePath: result.imagePath,
            contentType: pendingPhoto.file.type,
            actualWidth: null,
            actualHeight: null,
            timestamp: null,
          });

          handlePhotoAdded();
        }, errorUploadHandler);
    }
  }

  private onUploadComplete(pendingPhotos: Array<IPendingFile>) {
    this.props.onFileUploadingStatusChange(
      !!pendingPhotos.find((p) => !p.errorUploading)
    );
  }
}

export default Files;
