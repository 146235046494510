import { GoogleSignin } from "./GoogleSignin";
import auth from "../../../services/Auth";
import { useEffect, useRef, useState } from "react";
import Spinner from "../../../containers/app/components/Spinner";
import accountManagementDataProvider from "../services/accountManagementDataProvider";
import { fullStoryTrack } from "../../../services/fullStoryService";

export function UserAccountForm({
  onSubmit,
  buttonText,
  defaultEmailAddress,
  accountCreation,
  checkLoginType,
  isGoogleAuth,
  setIsGoogleAuth,
  invitationId,
  onStartCreate,
}: {
  onSubmit(args: {
    email: string;
    password: string;
    onError: (message: string) => void;
  }): void;
  buttonText: string;
  defaultEmailAddress?: string;
  accountCreation: boolean;
  checkLoginType?: boolean;
  isGoogleAuth?: boolean;
  setIsGoogleAuth?: React.Dispatch<React.SetStateAction<boolean>>;
  invitationId?: string | null;
  onStartCreate?: () => void;
}) {
  const emailRef = useRef<HTMLInputElement>(null);

  const [email, setEmail] = useState(defaultEmailAddress ?? "");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }, []);

  return (
    <>
      {saving ? <Spinner /> : null}

      {!isGoogleAuth ? (
        <>
          <GoogleSignIn />
          <div className="my-3 text-center font-weight-light">OR</div>
        </>
      ) : null}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          setErrorMessage("");
          setSaving(true);

          if (onStartCreate) {
            onStartCreate();
          }

          onSubmit({
            email,
            password,
            onError: (message) => {
              setErrorMessage(message);
              setSaving(false);
            },
          });
        }}
      >
        <div className="form-group">
          <label htmlFor="email" className="required">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            required={true}
            data-testid="email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            onBlur={(e) => {
              if (checkLoginType && typeof setIsGoogleAuth === "function")
                if (e.currentTarget.validity.valid) {
                  accountManagementDataProvider
                    .isGoogleAuthentication(e.currentTarget.value)
                    .subscribe((r) => {
                      setIsGoogleAuth(r);
                      if (r) {
                        fullStoryTrack("Login Page Google Only Mode");
                      }
                    });
                }
            }}
            ref={emailRef}
          />
        </div>

        {!isGoogleAuth ? (
          <>
            <div className="form-group">
              <label htmlFor="password" className="required">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                required={true}
                data-testid="password"
                value={password}
                onChange={(e) => setPassword(e.currentTarget.value)}
              />
            </div>

            {errorMessage ? (
              <div
                className="text-danger"
                data-testid="errorMessage"
                style={{ whiteSpace: "pre-line" }}
              >
                {errorMessage}
              </div>
            ) : null}

            <button
              type="submit"
              data-testid="getStartedButton"
              className="btn btn-block btn-primary mb-4 branded-public-buttons"
              disabled={false}
            >
              {buttonText}
            </button>
          </>
        ) : (
          <>
            <GoogleSignIn />
            <div
              data-testid="googleAuthMode"
              className="text-center text-danger"
            >
              This email is associated to a Google Account. Please sign in with
              Google.
            </div>
          </>
        )}
      </form>
    </>
  );

  function GoogleSignIn() {
    return (
      <div className="my-3 text-center">
        <GoogleSignin
          onClick={() => {
            setSaving(true);

            if (onStartCreate) {
              onStartCreate();
            }

            auth.authorizeWithGoogle({
              invitationId,
            });
          }}
          accountCreation={accountCreation}
        />
      </div>
    );
  }
}
