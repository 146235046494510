export function isSearchMatch(term: string, stringToCheck: string) {
  term = term?.trim() ?? "";
  const termTokens = getTokens(term);
  const stringToCheckTokens = getTokens(stringToCheck);

  return termTokens.reduce((isMatch, termToken) => {
    return (
      isMatch &&
      stringToCheckTokens.some(
        (stringToCheckToken) =>
          stringToCheckToken.toLowerCase().indexOf(termToken.toLowerCase()) !==
          -1
      )
    );
  }, true);
}

function getTokens(input: string) {
  return input.split(/\s/);
}
