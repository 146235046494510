import { useApplicationStateSelector } from "./useApplicationStateSelector";
import { useMemo } from "react";
import { getSortedCrews } from "../services/sortingService";

export function useSortedAndFilteredCrews() {
  const crews = useApplicationStateSelector(s => s.crew.crews);
  const sortedAndFilteredCrews = useMemo(
    () => getSortedCrews(crews.filter(c => !c.inactive)),
    [crews]
  );
  return sortedAndFilteredCrews;
}
