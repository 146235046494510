export enum Flags {
  altTimeInput,
  testRegistration,
  simpleSignUp,
  estimates,
  optionalItems,
  partialPayment,
}

try {
  Object.keys(Flags).forEach((flagKey) => {
    // Omit the indexes 0, 1, ....
    // Only deal with string keys.
    if (!isNaN(Number(flagKey))) {
      return;
    }

    const isFlagSet = window.location.href.indexOf(`${flagKey}=1`) !== -1;
    if (isFlagSet) {
      getStorage(Flags[flagKey as keyof typeof Flags]).setItem(
        getSessionKey(flagKey),
        "1"
      );
    }
  });
} catch (err) {
  console.error(`error checking flags: ${err}`);
}

export function isFeatureFlagSet(flag: Flags) {
  const flagKey = Flags[flag];

  try {
    const sessionValue = getStorage(flag).getItem(getSessionKey(flagKey));

    return sessionValue === "1";
  } catch (err) {
    console.error(`error in isFeatureFlagSet: ${err}`);
    return false;
  }
}

function getSessionKey(flagKey: string): string {
  return `featureflag_${flagKey.toString()}`;
}

function getStorage(flag: Flags) {
  if (isStickyFlag(flag)) {
    return window.localStorage;
  } else {
    return window.sessionStorage;
  }
}

function isStickyFlag(flag: Flags) {
  // Include flags that need to be sticky to the stickyFlags array
  const stickyFlags: Array<Flags> = [Flags.optionalItems];
  return stickyFlags.includes(flag);
}
