import { CSSProperties, ReactNode } from "react";
import { IJobInstance } from "../../../../models/IJobInstance";
import { getCardColors } from "../../../../slices/schedule/services/getCardColors";

interface IProps {
  jobInstance: IJobInstance;
  selected: boolean;
  showAlertBorder: boolean;
  children: (useWhiteText: boolean) => ReactNode;
  className?: string;
  style?: Partial<CSSProperties>;
  ignoreStatusForBackgroundColor?: boolean;
}

const JobCardContainer: React.FunctionComponent<IProps> = ({
  jobInstance,
  selected,
  showAlertBorder,
  children,
  className,
  style,
  ignoreStatusForBackgroundColor,
}) => {
  let classes = "card board-card ";
  let useWhiteText = false;

  if (!ignoreStatusForBackgroundColor) {
    const { classes: cardClasses, useWhiteText: useWhiteTextForCard } =
      getCardColors({
        selected,
        jobInstance,
      });
    classes += cardClasses;
    useWhiteText = useWhiteTextForCard;
  }

  if (showAlertBorder) {
    classes += " border border-danger";
  }

  return (
    <div
      className={classes + " " + (className ?? "")}
      style={style}
      data-testid="card"
    >
      <div className="card-body board-card-body board-card-v2">
        <div className="card-text">{children(useWhiteText)}</div>
      </div>
    </div>
  );
};

export default JobCardContainer;
