import React, { useState, useEffect, useRef } from "react";
import remoteDataProvider from "../../../../services/remoteDataProvider";
import { timeout } from "rxjs/operators";
import {
  GetUserSettingsType,
  useUserSettings,
} from "../../../../services/userSettingsService";
import { UserSettingsType } from "../../../../enums/userSettingsType";
import { SortDirection } from "../../../../enums/sortDirection";
import SortColumn from "../../components/SortColumn";
import { getSortedItemsV2 } from "../../../../services/sortingService";
import { DateFilterOptions } from "../../../../enums/dateFilterOptions";
import ServerLoadedList from "../../../../libraries/tableLayout/ServerLoadedList";
import { getErrorMessageFromError } from "../../../../services/httpErrorHandler";
import { IInvoiceView } from "../../../../models/IInvoice";
import { formatCurrency } from "../../../../services/currencyFormatter";
import { PaymentMethodType } from "../../../../enums/paymentMethodType";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import BillingInvoicesListDate from "../../../../slices/billing/components/BillingInvoicesListDate";
import {
  getDefaultFilters,
  updateDatesOnFilter,
} from "../../../../services/dateFilterService";
import dateService from "../../../../services/dateService";
import { isStringSet } from "../../../../services/stringService";
import BillingInvoiceListActionButtons from "../../../../slices/billing/components/BillingInvoiceListActionButtons";
import { InvoiceListType } from "../../../../enums/invoiceListType";
import constants from "../../../../constants";
import DateFilterHeadless from "../../components/DateFilterHeadless";
import { TableDisplayType } from "../../../../libraries/tableLayout/TableColumn";
import { useIsResolution } from "../../../../hooks/useIsResolution";

interface IProps {
  customerId: string;
}

interface IStickyFilters {
  frequency: DateFilterOptions;
  startingDate: string | null;
  endingDate: string | null;
}

interface ISortColumn {
  column: SortColumns;
  direction: SortDirection;
}

enum SortColumns {
  InvoiceDate,
  InvoiceNumber,
  TotalAmount,
  Balance,
  PaymentMethod,
  ReferenceInformation,
  DatePaid,
}

const CustomerBillingHistory: React.FunctionComponent<IProps> = ({
  customerId,
}) => {
  const { getUserSettings, setUserSettings } = useUserSettings();
  const [loadingData, setLoadingData] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [billingHistoryFilters, setBillingHistoryFilters] =
    useState<IStickyFilters>(
      getDefaultFilters(getUserSettings, UserSettingsType.billingHistoryFilters)
    );
  const [showPastDueOnly, setShowPastDueOnly] = useState(false);

  const lastCustomerId = useRef("");
  const lastBillingHistoryFilters = useRef(billingHistoryFilters);
  const lastShowPastDueOnly = useRef(false);
  const [invoices, setInvoices] = useState<Array<IInvoiceView> | null>(null);
  const [currentSortColumn, setCurrentSortColumn] =
    useState<SortColumns | null>(
      getDefaultSortColumn(getUserSettings)?.column ?? null
    );
  const [currentSortDirection, setCurrentSortDirection] =
    useState<SortDirection>(
      getDefaultSortColumn(getUserSettings)?.direction ??
        SortDirection.Ascending
    );

  const breakpoint = "md";
  const isDesktopView = useIsResolution(breakpoint);

  const invoiceSaveCount = useApplicationStateSelector(
    (s) => s.forms.invoice.saveCount
  );
  const lastInvoiceFormSaveCount = useRef(invoiceSaveCount);
  useEffect(() => {
    if (
      customerId !== lastCustomerId.current ||
      billingHistoryFilters !== lastBillingHistoryFilters.current ||
      invoiceSaveCount !== lastInvoiceFormSaveCount.current ||
      showPastDueOnly !== lastShowPastDueOnly.current
    ) {
      loadData(
        billingHistoryFilters,
        customerId,
        showPastDueOnly,
        setLoadingData,
        setErrorMessage,
        setInvoices
      );

      lastCustomerId.current = customerId;
      lastBillingHistoryFilters.current = billingHistoryFilters;
      lastInvoiceFormSaveCount.current = invoiceSaveCount;
      lastShowPastDueOnly.current = showPastDueOnly;
    }
  }, [
    customerId,
    showPastDueOnly,
    setLoadingData,
    setErrorMessage,
    billingHistoryFilters,
    currentSortColumn,
    currentSortDirection,
    invoiceSaveCount,
  ]);

  const getSortColumn = (
    displayName: string,
    sortColumn: SortColumns,
    displayType: TableDisplayType
  ) =>
    displayType === "desktop" ? (
      <SortColumn<SortColumns>
        displayName={displayName}
        columnSortProperty={sortColumn}
        currentSortProperty={currentSortColumn}
        currentSortDirection={currentSortDirection}
        onSortDirectionChange={(newSortColumn, newSortDirection) => {
          setCurrentSortColumn(newSortColumn);
          setCurrentSortDirection(newSortDirection);
          setUserSettings(UserSettingsType.customerHistorySortColumn, {
            column: newSortColumn,
            direction: newSortDirection,
          } as ISortColumn);
        }}
      />
    ) : (
      displayName
    );

  let sortColumn = currentSortColumn;
  let sortDirection = currentSortDirection;
  if (!isDesktopView) {
    sortColumn = SortColumns.InvoiceDate;
    sortDirection = SortDirection.Descending;
  }

  return (
    <>
      <ServerLoadedList<IInvoiceView>
        tableTestId="billing-history-table"
        breakpoint={breakpoint}
        header={null}
        dataType="invoices"
        errorLoading={errorMessage !== null}
        errorMessage={errorMessage}
        loadingData={loadingData}
        showContentWhileRefreshing={false}
        data={getSortedItems(invoices ?? [], sortColumn, sortDirection).map(
          (d) => ({
            ...d,
            testId: d.id,
          })
        )}
        refreshData={() =>
          loadData(
            billingHistoryFilters,
            customerId,
            showPastDueOnly,
            setLoadingData,
            setErrorMessage,
            setInvoices
          )
        }
        filter={
          <Filters
            billingHistoryFilters={billingHistoryFilters}
            setBillingHistoryFilters={setBillingHistoryFilters}
            showPastDueOnly={showPastDueOnly}
            setShowPastDueOnly={setShowPastDueOnly}
          />
        }
        columns={[
          {
            key: "invoiceDate",
            testId: "invoiceDate",
            header: ({ displayType }) =>
              getSortColumn(
                "Invoice date",
                SortColumns.InvoiceDate,
                displayType
              ),
            cell: ({ row: invoice }) => (
              <BillingInvoicesListDate invoice={invoice} />
            ),
          },
          {
            key: "number",
            testId: "number",
            header: ({ displayType }) =>
              getSortColumn("Number", SortColumns.InvoiceNumber, displayType),
            cell: ({ row: invoice }) => invoice.invoiceNumber,
          },
          {
            key: "totalAmount",
            testId: "totalAmount",
            header: ({ displayType }) =>
              getSortColumn(
                "Total amount",
                SortColumns.TotalAmount,
                displayType
              ),
            headerClassName: "text-right pr-3",
            cellClassName: "text-right pr-3",
            cell: ({ row: invoice }) => formatCurrency(invoice.totalAmount),
          },
          {
            key: "balance",
            testId: "balance",
            header: ({ displayType }) =>
              getSortColumn("Balance", SortColumns.Balance, displayType),
            headerClassName: "text-right pr-3",
            cellClassName: "text-right pr-3",
            cell: ({ row: invoice }) => formatCurrency(invoice.balance),
          },
          {
            key: "datePaid",
            testId: "datePaid",
            header: ({ displayType }) =>
              getSortColumn("Date paid", SortColumns.DatePaid, displayType),
            cell: ({ row: invoice }) =>
              invoice.datePaid
                ? dateService.formatDateForDisplay(
                    invoice.datePaid,
                    "MM/DD/YYYY"
                  )
                : "",
          },
          {
            key: "paymentMethod",
            testId: "paymentMethod",
            header: ({ displayType }) =>
              getSortColumn(
                "Payment method",
                SortColumns.PaymentMethod,
                displayType
              ),
            cell: ({ row: invoice }) => {
              const paymentTypes = getPaymentTypesForDisplay(invoice);

              return paymentTypes;
            },
          },
          {
            key: "referenceInfo",
            testId: "referenceInfo",
            header: ({ displayType }) =>
              getSortColumn(
                "Reference",
                SortColumns.ReferenceInformation,
                displayType
              ),
            cell: ({ row: invoice }) => {
              const referenceInformation =
                getPaymentReferenceInformationForDisplay(invoice);

              return referenceInformation;
            },
          },
          {
            key: "edit",
            header: "",
            isButtonCell: true,
            cell: ({ row: invoice, displayType }) => {
              return (
                <div
                  className={
                    displayType === "desktop" ? "text-right" : undefined
                  }
                >
                  <BillingInvoiceListActionButtons
                    invoice={invoice}
                    listType={InvoiceListType.customer}
                    onDataChanged={() => {
                      loadData(
                        billingHistoryFilters,
                        customerId,
                        showPastDueOnly,
                        setLoadingData,
                        setErrorMessage,
                        setInvoices
                      );
                    }}
                  />
                </div>
              );
            },
          },
        ]}
      />
    </>
  );
};

export default CustomerBillingHistory;

function Filters({
  billingHistoryFilters,
  setBillingHistoryFilters,
  showPastDueOnly,
  setShowPastDueOnly,
}: {
  billingHistoryFilters: IStickyFilters;
  setBillingHistoryFilters: React.Dispatch<
    React.SetStateAction<IStickyFilters>
  >;
  showPastDueOnly: boolean;
  setShowPastDueOnly: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { setUserSettings } = useUserSettings();

  return (
    <div
      className="d-flex align-items-baseline flex-wrap"
      style={{ columnGap: constants.listFilterGap }}
    >
      <div
        className="flex-fill"
        style={{ maxWidth: constants.listFilterMaxWidth }}
      >
        <DateFilterHeadless
          filters={billingHistoryFilters}
          onFiltersChanged={(newFilters) => {
            newFilters = updateDatesOnFilter(newFilters);

            setBillingHistoryFilters({
              ...billingHistoryFilters,
              ...newFilters,
            });

            setUserSettings(UserSettingsType.billingHistoryFilters, newFilters);
          }}
          showAllDates={false}
          setErrorMessage={() => {
            // Ignore error since server will return message
          }}
        >
          {({ dateRangeElements, customDateElements }) => (
            <>
              <div>
                <div
                  className="form-group"
                  style={{ display: "inline-block", width: "100%" }}
                >
                  {dateRangeElements}
                </div>
              </div>
              {customDateElements !== null ? (
                <>
                  <div
                    className="d-flex"
                    style={{ columnGap: constants.listFilterGap }}
                  >
                    <div className="flex-fill form-group">
                      {customDateElements.startingDateElement}
                    </div>
                    <div className="flex-fill form-group">
                      {customDateElements.endingDateElement}
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )}
        </DateFilterHeadless>
      </div>
      <div>
        <div className="custom-control custom-checkbox text-nowrap">
          <input
            type="checkbox"
            className="custom-control-input"
            id="showPastDueOnly"
            checked={showPastDueOnly}
            onChange={(e) => setShowPastDueOnly(e.target.checked)}
          />
          <label className="custom-control-label" htmlFor="showPastDueOnly">
            Show past due only
          </label>
        </div>
      </div>
    </div>
  );
}

function getPaymentReferenceInformationForDisplay(invoice: IInvoiceView) {
  return getSortedItemsV2(invoice.payments, ["datePaid"])
    .map((p) => p.paymentReferenceInformation)
    .filter((v) => isStringSet(v))
    .join(", ");
}

function getPaymentTypesForDisplay(invoice: IInvoiceView) {
  return getSortedItemsV2(invoice.payments, ["datePaid"])
    .map((p) => getPaymentType(p.paymentMethodType))
    .filter((v) => isStringSet(v))
    .join(", ");
}

function loadData(
  billingHistoryFilters: IStickyFilters,
  customerId: string,
  showPastDueOnly: boolean,
  setLoadingData: (v: boolean) => void,
  setErrorMessage: (v: string | null) => void,
  setBillingHistory: (v: Array<IInvoiceView>) => void
) {
  setLoadingData(true);

  return remoteDataProvider
    .getInvoices({
      startingDate: billingHistoryFilters.startingDate ?? "",
      endingDate: billingHistoryFilters.endingDate ?? "",
      customerId,
      showPastDueOnly,
    })
    .pipe(timeout(30000))
    .subscribe(
      (result) => {
        setLoadingData(false);
        setBillingHistory(result.list);
        setErrorMessage(null);
      },
      (err) => {
        setLoadingData(false);
        setErrorMessage(getErrorMessageFromError(err, ""));
      }
    );
}

function getDefaultSortColumn(
  getUserSettings: GetUserSettingsType
): ISortColumn | null {
  const savedSetting = getUserSettings<ISortColumn>(
    UserSettingsType.customerHistorySortColumn
  );
  return savedSetting;
}

function getSortedItems(
  invoices: Array<IInvoiceView>,
  sortColumn: SortColumns | null,
  sortDirection: SortDirection
) {
  if (sortColumn === null) {
    return invoices;
  }

  let sortProperties: Array<
    keyof IInvoiceView | ((i: IInvoiceView) => string | number)
  >;
  switch (sortColumn) {
    case SortColumns.InvoiceDate:
      sortProperties = ["date"];
      break;
    case SortColumns.DatePaid:
      sortProperties = ["datePaid"];
      break;
    case SortColumns.InvoiceNumber:
      sortProperties = ["invoiceNumber"];
      break;
    case SortColumns.TotalAmount:
      sortProperties = ["totalAmount"];
      break;
    case SortColumns.Balance:
      sortProperties = ["balance"];
      break;
    case SortColumns.PaymentMethod:
      sortProperties = [(i) => getPaymentTypesForDisplay(i)];
      break;
    case SortColumns.ReferenceInformation:
      sortProperties = [(i) => getPaymentReferenceInformationForDisplay(i)];
      break;
  }

  return getSortedItemsV2(
    invoices,
    sortProperties,
    sortDirection === SortDirection.Descending
  );
}

function getPaymentType(type: PaymentMethodType | null) {
  switch (type) {
    case PaymentMethodType.Ach:
      return "Bank transfer";
    case PaymentMethodType.CreditCard:
      return "Credit card";
    case PaymentMethodType.Check:
      return "Check";
    case PaymentMethodType.Cash:
      return "Cash";
    case PaymentMethodType.Other:
      return "Other";
    case null:
      return "";
  }
}
