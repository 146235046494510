export function getQueryVariable(variable: string) {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (
      typeof decodeURIComponent(pair[0]) === "string" &&
      typeof variable === "string" &&
      decodeURIComponent(pair[0]).toLowerCase() === variable.toLowerCase()
    ) {
      return decodeURIComponent(pair[1]);
    }
  }
}
