import { CSSProperties, memo } from "react";
import { TableColumns } from "./TableColumn";
import RowProperties from "./rowProperties";
import ResponsiveTable, { Breakpoint } from "./ResponsiveTable";
import { ResponsiveTableMobileCard } from "./ResponsiveTableMobileCard";

function ServerLoadedListContentInternal<T extends RowProperties>({
  responsiveTableContainerStyle,
  tableTestId,
  visibleColumns,
  data,
  hasMoreResults,
  breakpoint,
  alwaysShowTable,
}: {
  data: Array<T> | null;
  visibleColumns: TableColumns<T>;
  tableTestId?: string;
  responsiveTableContainerStyle?: Partial<CSSProperties>;
  hasMoreResults?: boolean;
  breakpoint?: Breakpoint;
  alwaysShowTable?: boolean;
}) {
  return (
    <>
      {data ? (
        <ResponsiveTable
          rows={data}
          breakpoint={breakpoint}
          columns={visibleColumns}
          alwaysShowTable={alwaysShowTable}
          responsiveTableContainerStyle={responsiveTableContainerStyle}
          tableTestId={tableTestId}
          tableClass="table-striped"
          renderMobile={({ row, index: rowIndex }) => (
            <ResponsiveTableMobileCard
              columns={visibleColumns}
              row={row}
              rowIndex={rowIndex}
            />
          )}
        />
      ) : null}

      {hasMoreResults ? (
        <div
          data-testid={`${tableTestId}_ResultCount`}
          className="alert alert-warning"
        >
          There are additional records that exceeded our maximum amount of
          records we can return. Use the list filters and sort to see others.
        </div>
      ) : null}
    </>
  );
}

export const ServerLoadedListContent = memo(
  ServerLoadedListContentInternal
) as typeof ServerLoadedListContentInternal;
