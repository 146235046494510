import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { getErrorMessageFromError } from "../../services/httpErrorHandler";
import remoteDataProvider from "../../services/remoteDataProvider";
import { actionCreators } from "../actionCreators";
import { IJobInstanceToggleCompletedStart } from "../actionTypeDefinitions";

export function handleJobInstanceToggleCompletedStart(
  action: IJobInstanceToggleCompletedStart
) {
  return remoteDataProvider
    .saveJobInstances(
      action.jobInstanceIds.map((jobInstanceId) => ({
        id: jobInstanceId,
        complete: true,
      }))
    )
    .pipe(
      map(() =>
        actionCreators.jobInstanceToggleCompletedComplete(action.jobInstanceIds)
      ),
      catchError((err) => {
        let errorMessage = getErrorMessageFromError(err);

        return of(
          actionCreators.jobInstanceToggleCompletedError(
            action.jobInstanceIds,
            errorMessage
          )
        );
      })
    );
}
