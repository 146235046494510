import { useEffect, useRef, useState } from "react";

function attachMediaListener(
  query: MediaQueryList,
  callback: (event: { matches: boolean; media: string }) => void
) {
  try {
    query.addEventListener("change", callback);
    return () => query.removeEventListener("change", callback);
  } catch (e) {
    // https://stackoverflow.com/questions/56466261/matchmedia-addlistener-marked-as-deprecated-addeventlistener-equivalent
    query.addListener(callback);
    return () => query.removeListener(callback);
  }
}

export function useMediaQuery(query: string, initialValue: boolean) {
  const [matches, setMatches] = useState(initialValue);
  const queryRef = useRef<MediaQueryList>();

  useEffect(() => {
    if ("matchMedia" in window) {
      queryRef.current = window.matchMedia(query);
      setMatches(queryRef.current.matches);
      return attachMediaListener(queryRef.current, (event) =>
        setMatches(event.matches)
      );
    }
  }, [query]);

  return matches;
}
