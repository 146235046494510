export const preventClearClass = "prevent-card-clear";

export function preventClearingAllCards(event: React.MouseEvent | MouseEvent) {
  event.preventDefault();
}

export function isClearAllCardsPrevented(event: React.MouseEvent | MouseEvent) {
  let hasClass = false;
  if (event.target) {
    const target = event.target as any;
    if (
      typeof target.classList === "object" &&
      typeof target.classList.contains === "function"
    ) {
      hasClass = target.classList.contains(preventClearClass);
    }
  }

  return event.defaultPrevented || hasClass;
}
