import { MaintenanceJobFrequency } from "../models/IMaintenanceJob";

export function isWeeklyOrMonthlySchedule(
  frequencyToCheck: MaintenanceJobFrequency
) {
  return (
    frequencyToCheck === MaintenanceJobFrequency.Weekly ||
    frequencyToCheck === MaintenanceJobFrequency.EveryTwoWeeks ||
    frequencyToCheck === MaintenanceJobFrequency.EveryFourWeeks ||
    frequencyToCheck === MaintenanceJobFrequency.EverySixWeeks ||
    frequencyToCheck === MaintenanceJobFrequency.Monthly
  );
}
