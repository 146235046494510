import differenceInCalendarDays from "date-fns/difference_in_calendar_days";

export function hasNumberOfDaysUntilDueChanged({
  currentDueDate,
  originalDueDate,
}: {
  currentDueDate: string;
  originalDueDate: string;
}) {
  return currentDueDate !== originalDueDate;
}

export function getNewDefaultNumberOfDaysDue({
  currentDueDate,
  startingDate,
}: {
  currentDueDate: string;
  startingDate: string;
}) {
  if (!startingDate || !currentDueDate) {
    return null;
  }

  return differenceInCalendarDays(currentDueDate, startingDate);
}
