import { useContext, useEffect } from "react";
import { PageWithBranding } from "../../../containers/app/PageWithBranding";
import { Auth0Context } from "../../../services/AuthContext";
import { RegisterForm } from "./RegisterForm";
import { AccountCreate } from "./AccountCreate";
import { fullStoryTrack } from "../../../services/fullStoryService";
import TagManager from "react-gtm-module";
import { getGoogelAnalyticsSignupCategory } from "../../../services/googleService";
import { setSessionProperties } from "./Register.functions";
import { HelpIcon } from "./HelpIcon";

let googleTagManagerEventRaised = false;
export function Register() {
  const authContext = useContext(Auth0Context);

  useEffect(() => {
    fullStoryTrack("Signup Form Loaded");

    setSessionProperties();
  }, []);

  if (!googleTagManagerEventRaised && !authContext.isAuthenticated) {
    TagManager.dataLayer({
      dataLayer: {
        category: getGoogelAnalyticsSignupCategory(),
        event: "registrationLoginFormShown",
      },
    });

    googleTagManagerEventRaised = true;
  }

  const showTextOnHeader = !authContext.isAuthenticated;
  return (
    <PageWithBranding
      showTransformationText={showTextOnHeader}
      showFreeAccessText={showTextOnHeader}
      showHeaderScreenshot={showTextOnHeader}
    >
      {authContext.isAuthenticated ? (
        <RegisterForm />
      ) : (
        <AccountCreate
          onStartCreate={() => {
            fullStoryTrack("User Account Created");
          }}
        />
      )}
      <HelpIcon />
    </PageWithBranding>
  );
}
