import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import { Auth0Context, IAuthContext } from "../../../services/AuthContext";
import remoteDataProvider, {
  IInvitationRegistrationDetails,
} from "../../../services/remoteDataProvider";
import RegisterTermsOfServiceAndPrivacy from "./RegisterTermsOfServiceAndPrivacy";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import RegisterWithInvitationInitialError from "./RegisterWithInvitationInitialError";
import Spinner from "../../../containers/app/components/Spinner";
import { PageWithBranding } from "../../../containers/app/PageWithBranding";
import { AccountCreate } from "./AccountCreate";
import { Login } from "./Login";
import { fullStoryTrack } from "../../../services/fullStoryService";
import { Dispatch } from "redux";

export const existingCompanyInvitationAgreementAcceptedKey =
  "existingCompanyInvitationAgreementAccepted";

interface IProps {
  invitationId: string;
}

const RegisterWithInvitation: React.FunctionComponent<IProps> = ({
  invitationId,
}) => {
  const [registrationDetails, setRegistrationDetails] =
    useState<IInvitationRegistrationDetails | null>(null);
  const [invitationNotFound, setInvitationNotFound] = useState(false);
  const [invitationLoadFailed, setInvitationLoadFailed] = useState(false);
  const [mode, setMode] = useState<"initialForm" | "authentication">(
    "initialForm"
  );
  const authContext = useContext(Auth0Context);
  const dispatch = useDispatch();

  const savingForm = useApplicationStateSelector(
    (s) => s.forms.registerExistingCompany.saving
  );

  const { initialSave, setInitialSave } = useSubmitOnLoadIfAuthenticated({
    authContext,
    dispatch,
    invitationId,
    savingForm,
  });

  useEffect(() => {
    fullStoryTrack("Register With Invitation Loaded");

    const subscription = remoteDataProvider
      .getInvitationRegistrationDetails(invitationId)
      .subscribe({
        next: (result) => setRegistrationDetails(result),

        error: (err) => {
          setInvitationLoadFailed(true);
          if (err.status === 404) {
            setInvitationNotFound(true);
          }
        },
      });

    return function cleanup() {
      return subscription.unsubscribe();
    };
  }, [invitationId]);

  if (invitationNotFound) {
    return (
      <RegisterWithInvitationInitialError errorMessage="Invitation Not Found" />
    );
  } else if (invitationLoadFailed) {
    return (
      <RegisterWithInvitationInitialError
        errorMessage="Error Loading Invitation"
        subMessage="Please reload your browser to try again."
      />
    );
  } else if (!registrationDetails) {
    return <Spinner />;
  } else if (registrationDetails?.used) {
    return (
      <RegisterWithInvitationInitialError errorMessage="Invitation Already Used" />
    );
  } else if (registrationDetails?.expired) {
    return (
      <RegisterWithInvitationInitialError errorMessage="Invitation Expired" />
    );
  } else if (initialSave && savingForm) {
    return <Spinner />;
  }

  return (
    <PageWithBranding>
      {savingForm ? <Spinner /> : null}

      {mode === "initialForm" || authContext.isAuthenticated ? (
        <RegisterWithInvitationForm
          isAuthenticated={authContext.isAuthenticated}
          invitationId={invitationId}
          registrationDetails={registrationDetails}
          onSubmit={() => setMode("authentication")}
        />
      ) : (
        <Authentication
          registrationDetails={registrationDetails}
          invitationId={invitationId}
          onSuccess={() => {
            setInitialSave(true);

            // Perform registration...
            createRegistration(
              dispatch,
              getExistingCompanyInvitationAgreementFromSession(),
              invitationId
            );
          }}
        />
      )}
    </PageWithBranding>
  );
};

export default RegisterWithInvitation;

function useSubmitOnLoadIfAuthenticated({
  authContext,
  dispatch,
  invitationId,
  savingForm,
}: {
  authContext: IAuthContext;
  dispatch: Dispatch<any>;
  invitationId: string;
  savingForm: boolean;
}) {
  const isSaving = useApplicationStateSelector(
    (s) => s.forms.registerExistingCompany.saving
  );
  const [initialSave, setInitialSave] = useState(false);
  const initialSaveSubmitted = useRef(false);
  useEffect(() => {
    if (
      authContext.isAuthenticated &&
      !isSaving &&
      getExistingCompanyInvitationAgreementFromSession() &&
      !initialSaveSubmitted.current
    ) {
      setInitialSave(true);

      // Perform registration...
      createRegistration(
        dispatch,
        getExistingCompanyInvitationAgreementFromSession(),
        invitationId
      );

      initialSaveSubmitted.current = true;
    }
  }, [authContext.isAuthenticated, dispatch, invitationId, isSaving]);

  useEffect(() => {
    if (initialSave && !savingForm) {
      setInitialSave(false);
    }
  }, [initialSave, savingForm]);

  return { initialSave, setInitialSave };
}

function RegisterWithInvitationForm({
  isAuthenticated,
  registrationDetails,
  invitationId,
  onSubmit,
}: {
  isAuthenticated: boolean;
  registrationDetails: IInvitationRegistrationDetails;
  invitationId: string;
  onSubmit: () => void;
}) {
  const [acceptTerms, setAcceptTerms] = useState(
    getExistingCompanyInvitationAgreementFromSession()
  );
  const dispatch = useDispatch();
  const errorMessage = useApplicationStateSelector(
    (s) => s.forms.registerExistingCompany.errorMessage
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (!isAuthenticated) {
          window.sessionStorage.setItem(
            existingCompanyInvitationAgreementAcceptedKey,
            acceptTerms.toString()
          );

          onSubmit();
        } else {
          createRegistration(dispatch, acceptTerms, invitationId);
        }
      }}
    >
      <div>
        <span data-testid="tenantName" className="font-weight-bold">
          {registrationDetails.tenantName}
        </span>{" "}
        is inviting you to be a new admin user in their Aspire Crew Control
        account.
      </div>
      <div className="mt-2">
        Click the button below to create your username and password and join
        their account. You only have to do this once and will automatically be
        linked in the future.
      </div>

      <div className="my-3">
        Hint: you might want to bookmark this page for future logins
      </div>

      <div className="my-3">
        <RegisterTermsOfServiceAndPrivacy
          checked={acceptTerms}
          onChecked={(checked) => setAcceptTerms(checked)}
          id="existingCompanyInvitationTermsOfServiceAndPrivacy"
        />
      </div>

      {errorMessage ? (
        <div className="my-3 text-danger">{errorMessage}</div>
      ) : null}

      <button
        type="submit"
        className="btn btn-block btn-primary branded-public-buttons"
        id="existing-company-invitation-save"
      >
        Get started
      </button>
    </form>
  );
}

function Authentication({
  registrationDetails,
  onSuccess,
  invitationId,
}: {
  registrationDetails: IInvitationRegistrationDetails;
  onSuccess: () => void;
  invitationId: string;
}) {
  return registrationDetails.hasExistingAuthUser ? (
    <Login
      defaultEmailAddress={registrationDetails.emailAddress}
      onSuccess={onSuccess}
      invitationId={invitationId}
    />
  ) : (
    <AccountCreate
      defaultEmailAddress={registrationDetails.emailAddress}
      onSuccess={onSuccess}
    />
  );
}

function createRegistration(
  dispatch: (arg: any) => void,
  acceptTerms: boolean,
  invitationId: string
) {
  dispatch(
    actionCreators.forms.registerExistingCompany.startSaving({
      acceptTermsOfServiceAndPrivacyPolicy: acceptTerms,
      invitationId: invitationId,
      mode: 1,
    })
  );
}

function getExistingCompanyInvitationAgreementFromSession() {
  return (
    window.sessionStorage.getItem(
      existingCompanyInvitationAgreementAcceptedKey
    ) === "true"
  );
}
