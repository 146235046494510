import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import { executeWithHeaders, url } from "../../../services/remoteDataProvider";

export const buildSupportUrl = (path: string) => `${url}/supportapi/${path}`;

export type Tenant = {
  id: string;
  name: string;
  hubSpotCompanyId: string;
  subscribed: string;
  dateAddedUtc: string;
  featureFlags: FeatureFlags;
};

type FeatureFlags = {
  simpleSignup: boolean;
};

const clientSupportDataProvider = {
  extendTrial: (request: { tenantId: string; days: number }) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildSupportUrl(`tenant/extendTrial`),
        JSON.stringify(request),
        headers
      )
    );
  },

  updateTenant: (
    tenantId: string,
    request: {
      simpleSignup: boolean;
    }
  ) => {
    return executeWithHeaders((headers) =>
      ajax.patch(
        buildSupportUrl(`tenant/${tenantId}`),
        JSON.stringify(request),
        headers
      )
    );
  },

  getTenants: (tenantName: string) => {
    return executeWithHeaders((headers) =>
      ajax.get(
        buildSupportUrl(`tenant/lookup?tenantName=${tenantName}`),
        headers
      )
    ).pipe(map((result) => result.response.matches as Array<Tenant>));
  },

  getInternalUserType: () => {
    return executeWithHeaders((headers) =>
      ajax.get(buildSupportUrl(`useraccount/internalusertype`), headers)
    ).pipe(
      map(
        (result) =>
          result.response as {
            isDeveloperUser: boolean;
            isSupportUser: boolean;
          }
      )
    );
  },

  getTransactionDocumentation: (transactionId: string) => {
    return executeWithHeaders((headers) =>
      ajax.get(
        buildSupportUrl(
          `payments/transactionDocumentation?transactionId=${transactionId}`
        ),
        headers
      )
    ).pipe(map((result) => result.response.documentationUrl as string));
  },
};

export default clientSupportDataProvider;
