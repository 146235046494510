import { Action } from "@reduxjs/toolkit";
import {
  createSpecificActionTypeName,
  formTypes,
  actionTypes as formActionTypes,
} from "../../../../../formGenerator";

export function isMaintenanceJobFormSaveComplete(
  action: Action<any>
): action is {
  type: string;
  payload: { isAdd: boolean; opportunityId: string | null };
} {
  return (
    action.type ===
    createSpecificActionTypeName(
      formTypes.maintenanceJob,
      formActionTypes.completeSaving
    )
  );
}
