import constants from "../constants";
import { ICrew } from "../models/ICrew";
import { getSortedItemsV2 } from "./sortingService";

export function getActiveSortedCrews(crews: ICrew[]) {
  return getSortedItemsV2(
    crews.filter((c) => !c.inactive),
    ["name"]
  );
}

export function getCrewWorkingHours(crew: ICrew) {
  return {
    workingHoursStart:
      crew.workingHoursStart ?? constants.defaultWorkingHoursStart,
    workingHoursEnd: crew.workingHoursEnd ?? constants.defaultWorkingHoursEnd,
  };
}
