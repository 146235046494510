import React, { useState, useEffect, useRef, useMemo } from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import FormContainer2 from "../components/FormContainer2";
import {
  IScheduledDispatchSettings,
  IScheduledDispatchSettingsCrew,
} from "../../../models/IScheduledDispatchSettings";
import { getSortedCrews } from "../../../services/sortingService";
import CrewMemberSelection from "../components/CrewMemberSelection";
import { ICrewMemberIdentifier } from "../../../models/ICrewMemberIdentifier";
import { ITime } from "../../../models/ITime";
import timeService from "../../../services/timeService";
import { getDefaultCrewId } from "../../../services/jobService";
import { ScheduledCommunication } from "../../../enums/scheduledCommunication";
import TimeInputQuarterHour from "../components/TimeInputQuarterHour";

interface IFormData {
  crews: Array<ICrewData>;
}

interface ICrewData {
  crewId: string;
  recipients: Array<ICrewMemberIdentifier>;
  sendSchedule: ScheduledCommunication;
  time: ITime;
}

const defaultTime: ITime = {
  hour: 6,
  minute: 0,
  period: "AM",
};

const ScheduledDispatchSettingsForm: React.SFC<{}> = () => {
  const [formData, setFormData] = useState<IFormData>({
    crews: [],
  });
  const [crewId, setCrewId] = useState<string>("");
  const [showCrewMemberRequiredError, setShowCrewMemberRequiredError] =
    useState(false);

  const showForm = useApplicationStateSelector(
    (s) => s.forms.scheduledDispatchSettings.showForm
  );
  const previousShowForm = useRef<boolean>(false);

  const crews = useApplicationStateSelector((s) => s.crew.crews);

  const filteredAndSortedCrews = useMemo(
    () => getSortedCrews(crews.filter((c) => !c.inactive)),
    [crews]
  );

  function updateSelectedCrew(updateCrewProp: (input: ICrewData) => void) {
    setFormData({
      ...formData,
      crews: formData.crews.map((c) => {
        if (c.crewId === crewId) {
          const newCrewData = {
            ...c,
          };
          updateCrewProp(newCrewData);
          return newCrewData;
        } else {
          return c;
        }
      }),
    });
  }

  const currentCrewConfiguration = useMemo(() => {
    return formData.crews.find((c) => c.crewId === crewId) as ICrewData;
  }, [formData, crewId]);

  const router = useApplicationStateSelector((s) => s.router);

  useEffect(() => {
    if (showForm && !previousShowForm.current) {
      setShowCrewMemberRequiredError(false);
      setCrewId(getDefaultCrewId(filteredAndSortedCrews, router));
      setFormData({
        crews: filteredAndSortedCrews.map(
          (c) =>
            ({
              crewId: c.id,
              recipients: c.scheduledDispatchRecipientCrewMembers || [],
              sendSchedule: c.scheduledDispatchSendSchedule || 0,
              time: c.scheduledDispatchTime
                ? timeService.getTimeComponents(c.scheduledDispatchTime)
                : defaultTime,
            } as ICrewData)
        ),
      });
    }

    previousShowForm.current = showForm;
  }, [
    showForm,
    setFormData,
    filteredAndSortedCrews,
    setShowCrewMemberRequiredError,
    router,
  ]);

  return (
    <FormContainer2
      formType={"scheduledDispatchSettings"}
      getFormData={() => {
        return {
          crews: formData.crews.map(
            (c) =>
              ({
                crewId: c.crewId,
                sendSchedule: c.sendSchedule,
                recipients: c.recipients,
                time: timeService.formatTimeForJson(c.time),
              } as IScheduledDispatchSettingsCrew)
          ),
        } as IScheduledDispatchSettings;
      }}
      validate={() => {
        if (
          currentCrewConfiguration.sendSchedule !==
            ScheduledCommunication.Never &&
          currentCrewConfiguration.recipients.length === 0
        ) {
          setShowCrewMemberRequiredError(true);
          return { valid: false, hideErrorMessage: true };
        }

        return { valid: true };
      }}
      formHeader="Scheduled Dispatch Settings"
    >
      <div className="form-section">
        <div className="d-flex justify-content-between mb-3">
          <h5 style={{ whiteSpace: "nowrap" }} className="mr-4">
            Selected Crew
          </h5>
          <select
            className="form-control form-control-sm"
            aria-label="Crew"
            value={crewId}
            onChange={(e) => {
              if (
                currentCrewConfiguration.sendSchedule !==
                  ScheduledCommunication.Never &&
                currentCrewConfiguration.recipients.length === 0
              ) {
                setShowCrewMemberRequiredError(true);
                return;
              }
              setCrewId(e.currentTarget.value);
            }}
          >
            {filteredAndSortedCrews.map((c) => (
              <option value={c.id} key={c.id}>
                {c.name}
              </option>
            ))}
          </select>
        </div>
        {currentCrewConfiguration ? (
          <React.Fragment>
            <div className="form-group">
              <label htmlFor="status" className="required">
                Send Schedule
              </label>
              <select
                id="status"
                className="form-control"
                name="status"
                value={currentCrewConfiguration.sendSchedule}
                onChange={(e) => {
                  updateSelectedCrew(
                    (c) =>
                      (c.sendSchedule = parseInt(
                        e.currentTarget.value
                      ) as ScheduledCommunication)
                  );
                  setShowCrewMemberRequiredError(false);
                }}
              >
                <option value="0">Never</option>
                <option value="1">Day of</option>
                <option value="2">Day before</option>
              </select>
            </div>
            <CrewMemberSelection
              value={currentCrewConfiguration.recipients}
              usePortal={true}
              isDisabled={
                currentCrewConfiguration.sendSchedule ===
                ScheduledCommunication.Never
              }
              onChange={(newCrewMembers) => {
                setShowCrewMemberRequiredError(false);
                updateSelectedCrew((c) => (c.recipients = newCrewMembers));
              }}
              trailingContent={
                showCrewMemberRequiredError ? (
                  <div className="text-danger">
                    Crew members are required if dispatching the schedule
                  </div>
                ) : null
              }
            />

            <TimeInputQuarterHour
              value={currentCrewConfiguration.time}
              disabled={
                currentCrewConfiguration.sendSchedule ===
                ScheduledCommunication.Never
              }
              onChange={(newValue) =>
                updateSelectedCrew((c) => (c.time = newValue))
              }
            />
          </React.Fragment>
        ) : null}
      </div>
    </FormContainer2>
  );
};

export default ScheduledDispatchSettingsForm;
