import { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import configuration from "./configuration";

let stripePromise: Promise<Stripe | null> | null = null;

export function getStripePromise() {
  if (stripePromise === null) {
    stripePromise = loadStripe(configuration.stripePublicKey);
  }
  return stripePromise;
}
