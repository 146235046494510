import { useDispatch } from "react-redux";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { actionCreators } from "../../../modules/actionCreators";
import { getPaymentMethodType } from "../../../services/customerService";
import { logError } from "../../../services/errorLogger";

interface IProps {
  customerId: string;
}

const CustomerPaymentMethodForForms: React.FunctionComponent<IProps> = ({
  customerId,
}) => {
  const dispatch = useDispatch();
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customer = customers.find((c) => c.id === customerId);

  if (!customer) {
    if (customerId !== null) {
      logError(
        `Customer '${customerId}' passed into CustomerPaymentMethodForForms but is not in store`
      );
    }

    return null;
  }

  return (
    <>
      <div className="form-group">
        <div className="d-flex">
          <div className="mr-3">
            <div className="font-weight-light">Payment type</div>
            <div data-testid="paymentType">
              {getPaymentMethodType(customer)}
            </div>
          </div>
          <div>
            <div className="font-weight-light">Partial number</div>
            <div data-testid="partialNumber">
              {customer?.paymentMethod?.partialNumber || "Not set"}
            </div>
          </div>
        </div>
      </div>

      <div className="form-group">
        <button
          type="button"
          className="btn btn-sm btn-secondary"
          onClick={() => {
            dispatch(
              actionCreators.forms.customerPaymentMethod.showForm({
                customerId,
              })
            );
          }}
        >
          {customer?.paymentMethod?.isTokenSet ? "Update" : "Add"} Customer
          Payment Method
        </button>
      </div>
    </>
  );
};

export default CustomerPaymentMethodForForms;
