import React, { useState, useEffect, useRef } from "react";
import dateService from "../../../../../services/dateService";
import ServerLoadedList from "../../../../../libraries/tableLayout/ServerLoadedList";
import remoteDataProvider from "../../../../../services/remoteDataProvider";
import PageWithNavBar2 from "../../../PageWithNavBar2";
import { IPayrollReportCrewMember } from "../../../../../models/IPayrollReportCrewMember";
import { getSortedItemsV2 } from "../../../../../services/sortingService";
import { eachDay, format, addDays } from "date-fns";
import DayPicker, {
  format as dayPickerFormat,
} from "../../../components/DayPicker";
import LoadReportMessage from "../../../components/LoadReportMessage";
import {
  GetUserSettingsType,
  useUserSettings,
} from "../../../../../services/userSettingsService";
import { UserSettingsType } from "../../../../../enums/userSettingsType";
import DayDetails from "./DayDetails";
import { finalize, timeout } from "rxjs/operators";
import { downloadBlob } from "../../../../../services/csvDownloadService";
import { useSortColumn } from "../../../../../hooks/useSortColumn";
import { SortDirection } from "../../../../../enums/sortDirection";
import constants from "../../../../../constants";
import { builders } from "../../../../../services/routing";
import { Link } from "react-router-dom";
import { useApplicationStateSelector } from "../../../../../hooks/useApplicationStateSelector";
import TableColumn from "../../../../../libraries/tableLayout/TableColumn";

interface IProps {}

interface IFilters {
  startingDate: string;
  endingDate: string;
  breakTimeMinutes: string;
  breakThresholdHours: string;
}

interface IReportData {
  crewMemberData: Array<IPayrollReportCrewMember>;
  startingDate: string;
  endingDate: string;
}

enum SortColumn {
  crewMember,
  crew,
  totalHours,
}

function getDefaultStartingDate(getUserSettings: GetUserSettingsType) {
  let savedDefaultStartingDate = getUserSettings<string>(
    UserSettingsType.payrollReportStartingDate
  );
  if (!savedDefaultStartingDate) {
    savedDefaultStartingDate = dateService.formatAsIso(
      addDays(new Date(), -14)
    );
  }
  return savedDefaultStartingDate;
}

function getDefaultEndingDate(getUserSettings: GetUserSettingsType) {
  let savedDefaultEndingDate = getUserSettings<string>(
    UserSettingsType.payrollReportEndingDate
  );
  if (!savedDefaultEndingDate) {
    savedDefaultEndingDate = dateService.formatAsIso(new Date());
  }
  return savedDefaultEndingDate;
}

function getDefaultBreakTimeMinutes(getUserSettings: GetUserSettingsType) {
  return (
    getUserSettings<string>(UserSettingsType.payrollReportBreakTimeMinutes) ??
    "0"
  );
}

function getDefaultBreakThresholdHours(getUserSettings: GetUserSettingsType) {
  return (
    getUserSettings<string>(
      UserSettingsType.payrollReportBreakThresholdHours
    ) ?? ""
  );
}

const Index: React.FunctionComponent<IProps> = () => {
  const [errorLoading, setErrorLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [dataNotLoaded, setDataNotLoaded] = useState(true);
  const [filterError, setFilterError] = useState<string | null>(null);

  const { getUserSettings, setUserSettings } = useUserSettings();
  const [filters, setFilters] = useState<IFilters>({
    startingDate: getDefaultStartingDate(getUserSettings),
    endingDate: getDefaultEndingDate(getUserSettings),
    breakTimeMinutes: getDefaultBreakTimeMinutes(getUserSettings),
    breakThresholdHours: getDefaultBreakThresholdHours(getUserSettings),
  });
  const [reportData, setReportData] = useState<IReportData | null>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const { currentSortColumn, currentSortDirection, getSortColumn } =
    useSortColumn<SortColumn>(undefined, SortDirection.Ascending);

  useEffect(() => {
    if (filters.endingDate < filters.startingDate) {
      setFilterError("Ending Date cannot be before the Starting Date.");
    } else {
      setFilterError("");
    }
  }, [filters]);

  // Ensure that if a user corrects an unclosed time range, the change is updated
  useReloadFormDataOnPayrollFormSave({
    setLoadingData,
    setErrorLoading,
    setDataNotLoaded,
    filters,
    setReportData,
  });

  return (
    <PageWithNavBar2 billingContext={true}>
      <div style={{ marginBottom: constants.marginBottomForIntercom }}>
        <ServerLoadedList<IPayrollReportCrewMember>
          largeHeader={true}
          header="Employee Times"
          dataType="employee times"
          showContentWhileRefreshing={true}
          errorLoading={errorLoading}
          loadingData={loadingData}
          dataNotLoaded={dataNotLoaded}
          alwaysShowTable
          dataNotLoadedContent={<LoadReportMessage />}
          data={
            reportData !== null
              ? getSortedData(
                  reportData?.crewMemberData ?? [],
                  currentSortColumn,
                  currentSortDirection
                )
              : null
          }
          refreshData={() =>
            loadData(
              setLoadingData,
              setErrorLoading,
              setDataNotLoaded,
              filters,
              setReportData
            )
          }
          filter={
            <>
              <form ref={formRef}>
                <div className="form-row align-items-end mt-3">
                  <div className="col-md-2 col-sm-3 col-xs-12 mb-sm-0 mb-3">
                    <label htmlFor="startDate">Starting date</label>
                    <div>
                      <DayPicker
                        required={true}
                        onDayPickerHide={() => null}
                        dayPickerProps={{}}
                        value={
                          !!filters.startingDate
                            ? format(filters.startingDate, dayPickerFormat)
                            : ""
                        }
                        inputId="startDate"
                        onDaySelected={(day: any) => {
                          let newStartingDate = "";
                          if (day) {
                            const parsedDate = format(day, dayPickerFormat);
                            newStartingDate =
                              dateService.formatAsIso(parsedDate);

                            setFilters({
                              ...filters,
                              startingDate: newStartingDate,
                            });
                            setUserSettings<string>(
                              UserSettingsType.payrollReportStartingDate,
                              newStartingDate
                            );
                          }
                        }}
                        preventMobileView={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-3 col-xs-12 mb-sm-0 mb-3">
                    <label htmlFor="endDate">Ending date</label>
                    <div>
                      <DayPicker
                        required={true}
                        onDayPickerHide={() => null}
                        dayPickerProps={{}}
                        value={
                          !!filters.endingDate
                            ? format(filters.endingDate, dayPickerFormat)
                            : ""
                        }
                        inputId="endDate"
                        onDaySelected={(day: any) => {
                          let newEndingDate = "";
                          if (day) {
                            const parsedDate = format(day, dayPickerFormat);
                            newEndingDate = dateService.formatAsIso(parsedDate);

                            setFilters({
                              ...filters,
                              endingDate: newEndingDate,
                            });
                            setUserSettings<string>(
                              UserSettingsType.payrollReportEndingDate,
                              newEndingDate
                            );
                          }
                        }}
                        preventMobileView={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-5 col-xs-12 mb-sm-0 mb-3 d-flex align-items-end">
                    <div style={{ width: "100%" }}>
                      <label htmlFor="breakTimeMinutes">
                        Deduct break time
                      </label>
                      <div style={{ width: "100%" }}>
                        <select
                          className="form-control"
                          id="breakTimeMinutes"
                          value={filters.breakTimeMinutes}
                          onChange={(e) => {
                            const newBreakTimeMinutes = e.currentTarget.value;
                            const newBreakThresholdHours =
                              filters.breakThresholdHours !== ""
                                ? filters.breakThresholdHours
                                : "6";

                            setUserSettings(
                              UserSettingsType.payrollReportBreakTimeMinutes,
                              newBreakTimeMinutes
                            );
                            setUserSettings(
                              UserSettingsType.payrollReportBreakThresholdHours,
                              newBreakThresholdHours
                            );

                            setFilters({
                              ...filters,
                              breakTimeMinutes: newBreakTimeMinutes,
                              breakThresholdHours: newBreakThresholdHours,
                            });
                          }}
                        >
                          <option value="0">None</option>
                          <option value="15">15 minutes</option>
                          <option value="30">30 minutes</option>
                          <option value="45">45 minutes</option>
                          <option value="60">60 minutes</option>
                        </select>
                      </div>
                    </div>
                    {filters.breakTimeMinutes !== "0" && (
                      <>
                        <div className="text-danger mx-3">When</div>
                        <div>
                          <label htmlFor="breakThreshold">
                            Working time exceeds
                          </label>
                          <div className="d-flex d-flex align-items-baseline">
                            <input
                              id="breakThreshold"
                              style={{ width: "100px" }}
                              type="number"
                              className="form-control mr-2"
                              value={filters.breakThresholdHours}
                              min={1}
                              onChange={(e) => {
                                const newBreakThresholdHours =
                                  e.currentTarget.value;
                                setUserSettings(
                                  UserSettingsType.payrollReportBreakThresholdHours,
                                  newBreakThresholdHours
                                );
                                setFilters({
                                  ...filters,
                                  breakThresholdHours: newBreakThresholdHours,
                                });
                              }}
                            ></input>
                            hours
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-md-5 col-sm-12 col-xs-12 mt-sm-3 ml-auto-md d-flex justify-content-md-end flex-nowrap">
                    <button
                      className="btn btn-primary"
                      style={{ marginRight: "10px", whiteSpace: "nowrap" }}
                      type="button"
                      onClick={() => {
                        if (!isFormValid(formRef, filterError)) {
                          return;
                        }

                        loadData(
                          setLoadingData,
                          setErrorLoading,
                          setDataNotLoaded,
                          filters,
                          setReportData
                        );
                      }}
                    >
                      Load Times
                    </button>

                    <button
                      className="btn btn-secondary"
                      type="button"
                      style={{ whiteSpace: "nowrap" }}
                      onClick={() => {
                        if (!isFormValid(formRef, filterError)) {
                          return;
                        }

                        const {
                          parsedBreakTimeMinutes,
                          parsedBreakThresholdMinutes,
                        } = getParsedBreakValues(filters);

                        setLoadingData(true);
                        setErrorLoading(false);
                        remoteDataProvider
                          .downloadPayrollReportCsv(
                            filters.startingDate,
                            filters.endingDate,
                            parsedBreakTimeMinutes,
                            parsedBreakThresholdMinutes
                          )
                          .pipe(
                            timeout(20000),
                            finalize(() => setLoadingData(false))
                          )
                          .subscribe(
                            (blob) => {
                              downloadBlob(blob, "PayrollTimeReport.csv");
                            },
                            () => setErrorLoading(true)
                          );
                      }}
                    >
                      Export to Excel
                    </button>
                  </div>
                </div>
                {filterError && (
                  <div className="form-row">
                    <div className="col text-danger">{filterError}</div>
                  </div>
                )}
              </form>
            </>
          }
          columns={[
            {
              key: "crewMemberName",
              header: getSortColumn("Crew member", SortColumn.crewMember),
              cell: ({ row: t }) => (
                <Link to={builders.manage.buildCrewMemberDetailsRoute(t.id)}>
                  {t.crewMemberName}
                </Link>
              ),
              testId: "crewMemberName",
              headerStyle: {
                whiteSpace: "nowrap",
              },
            },
            {
              key: "crews",
              header: getSortColumn("Crew(s)", SortColumn.crew),
              cell: ({ row: t }) => t.crews,
              testId: "crews",
              headerStyle: {
                whiteSpace: "nowrap",
              },
            },
            {
              key: "totalHours",
              header: getSortColumn("Total hours", SortColumn.totalHours),
              cell: ({ row: t }) => roundHours(t.totalHours),
              testId: "totalHours",
              headerClassName: "text-right",
              cellClassName: "text-right",
              headerStyle: {
                whiteSpace: "nowrap",
              },
            },
            ...getDayColumns(reportData),
          ]}
        />
      </div>
    </PageWithNavBar2>
  );
};

export default Index;

function useReloadFormDataOnPayrollFormSave({
  setLoadingData,
  setErrorLoading,
  setDataNotLoaded,
  filters,
  setReportData,
}: {
  setLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setDataNotLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  filters: IFilters;
  setReportData: React.Dispatch<React.SetStateAction<IReportData | null>>;
}) {
  const payrollTimeRangeSaveCount = useApplicationStateSelector(
    (s) => s.forms.payrollTimeRange.saveCount
  );
  const previousPayrollTimeRangeSaveCount = useRef(payrollTimeRangeSaveCount);
  useEffect(() => {
    if (
      payrollTimeRangeSaveCount !== previousPayrollTimeRangeSaveCount.current
    ) {
      loadData(
        setLoadingData,
        setErrorLoading,
        setDataNotLoaded,
        filters,
        setReportData
      );

      previousPayrollTimeRangeSaveCount.current = payrollTimeRangeSaveCount;
    }
  }, [
    filters,
    payrollTimeRangeSaveCount,
    setLoadingData,
    setErrorLoading,
    setDataNotLoaded,
    setReportData,
  ]);
}

function roundHours(input: number) {
  return Math.round(input * 100) / 100;
}

function getDayColumns(reportData: IReportData | null) {
  if (
    !reportData?.startingDate ||
    !reportData?.endingDate ||
    reportData.endingDate < reportData.startingDate
  ) {
    return [];
  } else {
    return eachDay(reportData.startingDate, reportData.endingDate).map(
      (day) => {
        const formattedDate = format(day, "M/D");
        return {
          key: formattedDate,
          header: formattedDate,
          testId: formattedDate,
          headerClassName: "text-right",
          className: "text-right",
          cell: ({ row: crewMember }) => {
            const matchingDayDetails = crewMember.dayDetails.find((cm) =>
              dateService.areDatesEqual(cm.day, day)
            );
            if (matchingDayDetails) {
              return (
                <DayDetails
                  crewMemberId={crewMember.id}
                  dayDetails={matchingDayDetails}
                  roundHours={roundHours}
                />
              );
            } else {
              return "";
            }
          },
        } as TableColumn<IPayrollReportCrewMember>;
      }
    );
  }
}

function loadData(
  setLoadingData: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setDataNotLoaded: React.Dispatch<React.SetStateAction<boolean>>,
  filters: IFilters,
  setReportData: React.Dispatch<React.SetStateAction<IReportData | null>>
) {
  setLoadingData(true);
  setErrorLoading(false);
  setDataNotLoaded(false);

  const { parsedBreakTimeMinutes, parsedBreakThresholdMinutes } =
    getParsedBreakValues(filters);

  return remoteDataProvider
    .getPayrollReport(
      filters.startingDate,
      filters.endingDate,
      parsedBreakTimeMinutes,
      parsedBreakThresholdMinutes
    )
    .subscribe(
      (r) => {
        setReportData({
          crewMemberData: r,
          startingDate: filters.startingDate,
          endingDate: filters.endingDate,
        });
        setLoadingData(false);
      },
      () => {
        setErrorLoading(true);
        setLoadingData(false);
      }
    );
}

function getParsedBreakValues(filters: IFilters) {
  const parsedBreakTimeMinutes =
    filters.breakTimeMinutes !== "0"
      ? parseFloat(filters.breakTimeMinutes)
      : null;
  const parsedBreakThresholdMinutes =
    filters.breakThresholdHours !== ""
      ? parseFloat(filters.breakThresholdHours) * 60
      : null;
  return { parsedBreakTimeMinutes, parsedBreakThresholdMinutes };
}

function isFormValid(
  formRef: React.RefObject<HTMLFormElement>,
  filterError: string | null
) {
  if (formRef.current) {
    formRef.current.reportValidity();
    if (!formRef.current.checkValidity()) {
      return false;
    }
  }

  if (filterError) {
    return false;
  }

  return true;
}

export function getSortedData(
  invoices: Array<IPayrollReportCrewMember>,
  sortColumn: SortColumn | null,
  sortDirection: SortDirection
) {
  if (sortColumn === null) {
    return invoices;
  }

  let sortProperties: Array<
    | keyof IPayrollReportCrewMember
    | ((i: IPayrollReportCrewMember) => string | number)
  >;
  switch (sortColumn) {
    case SortColumn.crew:
      sortProperties = ["crews"];
      break;
    case SortColumn.totalHours:
      sortProperties = ["totalHours"];
      break;
    default:
      sortProperties = ["crewMemberName"];
      break;
  }

  return getSortedItemsV2(
    invoices,
    sortProperties,
    sortDirection === SortDirection.Descending
  );
}
