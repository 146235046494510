import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FormContainerWithoutRedux from "../components/FormContainerWithoutRedux";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import remoteDataProvider, {
  IQuickBooksCustomerRequest,
  IQuickBooksCustomerResponse,
} from "../../../services/remoteDataProvider";
import PhoneNumberField from "../components/PhoneNumberField";
import AddressComponents2, {
  IAddressComponents,
} from "../components/AddressComponents2";
import { ICustomer } from "../../../models/ICustomer";
import addressFormatter from "../../../services/addressFormatter";
import { ErrorMessageType } from "../components/FormContainer";

interface IFormData {
  displayName: string;
  address: IAddressComponents;
  emailAddress: string;
  phoneNumber: string;
  alternativePhoneNumber: string;
  taxExempt: boolean;
}

interface IProps {
  defaultCustomerName: string;
  customer: ICustomer | null;
  onSaveComplete: (saveResult: IQuickBooksCustomerResponse) => void;
  onCancel: () => void;
}

function createAddress(customer: ICustomer | null) {
  if (!customer) {
    return {
      streetAndNumber: "",
      city: "",
      latitude: "",
      latitudeSignificantDigits: null,
      longitude: "",
      longitudeSignificantDigits: null,
      state: "",
      zip: "",
    };
  } else
    return {
      streetAndNumber: customer.streetAndNumber,
      city: customer.city,
      latitude: customer.latitude
        ? addressFormatter.formatLatLng(
            customer.latitude,
            customer.latitudeSignificantDigits
          )
        : "",
      longitude: customer.longitude
        ? addressFormatter.formatLatLng(
            customer.longitude,
            customer.longitudeSignificantDigits
          )
        : "",
      latitudeSignificantDigits: customer.latitudeSignificantDigits,
      longitudeSignificantDigits: customer.longitudeSignificantDigits,
      state: customer.state,
      zip: customer.zip,
    };
}

const QuickBooksCustomerForm: React.FunctionComponent<IProps> = ({
  defaultCustomerName,
  customer,
  onSaveComplete,
  onCancel,
}) => {
  const { register, getValues, control } = useForm<IFormData>({
    defaultValues: {
      displayName: defaultCustomerName,
      address: createAddress(customer),
      phoneNumber: customer?.phoneNumber ?? "",
      alternativePhoneNumber: customer?.alternativePhoneNumber ?? "",
      emailAddress: customer?.emailAddresses
        ? customer?.emailAddresses[0] ?? ""
        : "",
      taxExempt: customer?.taxExempt ?? false,
    },
  });
  const [errorMessage, setErrorMessage] = useState<ErrorMessageType>("");
  const streetAndNumberRef = useRef<HTMLInputElement>(null);

  function getFormData() {
    const values = getValues();
    const formData: IQuickBooksCustomerRequest = {
      displayName: values.displayName,
      streetAndNumber: values.address.streetAndNumber,
      city: values.address.city,
      state: values.address.state,
      zip: values.address.zip,
      latitude: values.address.latitude,
      longitude: values.address.longitude,
      phoneNumber: values.phoneNumber,
      alternativePhoneNumber: values.alternativePhoneNumber,
      emailAddress: values.emailAddress,
      taxExempt: values.taxExempt,
    };
    return formData;
  }

  return (
    <FormContainerWithoutRedux
      formHeader="Create QuickBooks Customer"
      formType={FormTypesV2.quickBooksCustomer}
      saveButtonText="Add QuickBooks Customer"
      save={() => {
        return remoteDataProvider.saveQuickBooksCustomer(getFormData());
      }}
      onSaveComplete={(saveResult) => onSaveComplete(saveResult)}
      onCancel={onCancel}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    >
      <div className="form-group">
        <label htmlFor="displayName" className="required">
          Customer name
        </label>
        <input
          type="text"
          id="displayName"
          className="form-control"
          required
          {...register("displayName")}
        />
      </div>
      <div className="form-group">
        <Controller
          name={`address`}
          control={control}
          render={({ field }) => (
            <AddressComponents2
              streetAndNumberId="inputAddress"
              streetAndNumberName="inputAddress"
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
              }}
              streetAndNumberRef={streetAndNumberRef}
              showMap={true}
            />
          )}
        />
      </div>
      <div className="form-group">
        <label htmlFor="phoneNumber">Primary phone</label>
        <Controller
          name={`phoneNumber`}
          control={control}
          render={({ field }) => (
            <PhoneNumberField
              id="phoneNumber"
              className="form-control"
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
              }}
            />
          )}
        />
      </div>
      <div className="form-group">
        <label htmlFor="alternativePhoneNumber">Alternative phone</label>
        <Controller
          name={`alternativePhoneNumber`}
          control={control}
          render={({ field }) => (
            <PhoneNumberField
              id="alternativePhoneNumber"
              className="form-control"
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
              }}
            />
          )}
        />
      </div>
      <div className="form-group">
        <label htmlFor="emailAddress">Email address</label>
        <input
          type="email"
          id="emailAddress"
          className="form-control"
          {...register("emailAddress")}
        />
      </div>
      <div className="form-group">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="taxExempt"
            {...register("taxExempt")}
          />
          <label className="custom-control-label" htmlFor="taxExempt">
            Tax-exempt
          </label>
        </div>
      </div>
    </FormContainerWithoutRedux>
  );
};

export default QuickBooksCustomerForm;
