import { IBillingReportCustomer } from "../models/IBillingReport";
import { actionTypes } from "./actionCreators";
import {
  IBillingReportCompleteLoad,
  IBillingReportErrorLoad,
} from "./actionTypeDefinitions";

export interface IBillingReportState {
  loading: boolean;
  errorMessage: string | null;
  customers: Array<IBillingReportCustomer> | null;
}

export default (
  state: IBillingReportState | undefined,
  action: any
): IBillingReportState => {
  if (!state) {
    state = {
      loading: false,
      errorMessage: null,
      customers: null,
    };
  }

  switch (action.type) {
    case actionTypes.BILLING_REPORT_START_LOAD:
      return {
        ...state,
        errorMessage: null,
        customers: null,
        loading: true,
      };

    case actionTypes.BILLING_REPORT_COMPLETE_LOAD:
      const completeAction = action as IBillingReportCompleteLoad;
      return {
        ...state,
        customers: completeAction.customers,
        loading: false,
      };

    case actionTypes.BILLING_REPORT_ERROR_LOAD:
      const errorAction = action as IBillingReportErrorLoad;
      return {
        ...state,
        errorMessage: errorAction.errorMessage,
        loading: false,
      };

    default:
      return state;
  }
};
