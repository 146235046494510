import { ICrew } from "../models/ICrew";
import { ICategoryIdentifier } from "../models/ICategoryIdentifier";
import { IBaseCategory } from "../models/IBaseCategory";
import { ICrewCategory } from "../models/ICrewCategory";
import { getSortedItemsV2 } from "./sortingService";

export function getDistinctSortedCrewCategories(
  crews: Array<ICrew>,
  crewCategories: Array<ICrewCategory>
): Array<ICrewCategory> {
  return getSortedItemsV2(
    crews.reduce((categories: Array<ICrewCategory>, crew: ICrew) => {
      return [
        ...categories,
        ...getCategories(crew.crewCategories, crewCategories),
      ];
    }, []),
    ["name"]
  ).filter(
    (value, index, self) => self.findIndex((c) => c.id === value.id) === index
  );
}

export function doesCrewHaveCategory(
  crew: ICrew,
  crewCategoryIds: Array<string>
): boolean {
  if (crewCategoryIds.length === 0) {
    return true;
  } else {
    return crewCategoryIds.reduce(
      (passesFilter, crewCategoryId) =>
        passesFilter ||
        crew.crewCategories.some((c) => c.id === crewCategoryId),
      false as boolean
    );
  }
}

export function getCategories<TCategory extends IBaseCategory>(
  categoryIdentifiers: Array<ICategoryIdentifier>,
  categories: Array<TCategory>
) {
  return categoryIdentifiers
    .map((identifier) =>
      categories.find((category) => category.id === identifier.id)
    )
    .filter((category) => typeof category === "object")
    .map((category) => category as TCategory);
}

export function getCategoryIdentifiersForDisplay<
  TCategory extends IBaseCategory
>(
  categoryIdentifiers: Array<ICategoryIdentifier>,
  categories: Array<TCategory>
) {
  return getSortedItemsV2(
    categoryIdentifiers
      .map((c) => categories.find((c2) => c2.id === c.id))
      .filter((c) => !!c),
    [(c) => c?.name ?? ""]
  )
    .map((c) => c?.name)
    .join(", ");
}
