import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import clientSupportDataProvider, {
  Tenant,
} from "../services/clientSupportDataProvider";
import ClientRow from "./ClientRow";

interface IFormData {
  clientName: string;
  trialDays: number;
}

const ClientSearch: React.FunctionComponent<{}> = () => {
  const [searchText, setSearchText] = useState<string>("");
  const [clients, setClients] = useState<Array<Tenant>>([]);
  const { register, getValues } = useForm<IFormData>({
    defaultValues: {},
  });

  return (
    <Fragment>
      <div
        className="d-flex flex-wrap align-items-end mb-4"
        style={{ columnGap: "10px", rowGap: "10px" }}
      >
        <div>
          <label style={{ fontSize: "large" }} htmlFor="clientName">
            Client name
          </label>
          <input
            id="clientName"
            type="text"
            className="form-control"
            width="300px"
            {...register("clientName")}
          ></input>
        </div>
        <button
          className="btn btn-primary"
          onClick={() => {
            <Fragment>
              {clientSupportDataProvider
                .getTenants(getValues("clientName"))
                .subscribe((tenants) => {
                  setClients(tenants);
                  setSearchText(getValues("clientName"));
                })}
            </Fragment>;
          }}
          style={{ whiteSpace: "nowrap" }}
        >
          Search
        </button>
      </div>
      <div>
        <table className="table table-dark table-sm table-striped text-light">
          <thead>
            <tr>
              <th scope="col" style={{ minWidth: "200px", maxWidth: "200px" }}>
                Name
              </th>
              <th scope="col" style={{ minWidth: "100px", maxWidth: "100px" }}>
                Tenant ID
              </th>
              <th scope="col" style={{ minWidth: "100px", maxWidth: "100px" }}>
                HubSpot ID
              </th>
              <th scope="col" style={{ minWidth: "100px", maxWidth: "100px" }}>
                Subscribed
              </th>
              <th scope="col" style={{ minWidth: "100px", maxWidth: "100px" }}>
                Trial days
              </th>
              <th scope="col" style={{ minWidth: "100px", maxWidth: "100px" }}>
                Payments Onboarding
              </th>
              <th
                scope="col"
                style={{ minWidth: "100px", maxWidth: "100px" }}
              ></th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client) => {
              return (
                <ClientRow
                  key={client.id}
                  client={client}
                  onSaveComplete={() =>
                    clientSupportDataProvider
                      .getTenants(searchText)
                      .subscribe((tenants) => setClients(tenants))
                  }
                ></ClientRow>
              );
            })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default ClientSearch;
