import React, { useState, useMemo, useEffect, useRef } from "react";
import { ICrew } from "../../../models/ICrew";
import dateService from "../../../services/dateService";
import { startOfWeek, getDay, addDays, format } from "date-fns";
import { parsers, ITryParseScheduleResult } from "../../../services/routing";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { useSortedAndFilteredCrews } from "../../../hooks/useSortedAndFilteredCrews";
import { IShiftRequest } from "../../../services/remoteDataProvider";
import FormContainer2 from "../components/FormContainer2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Select, { OptionProps, SelectInstance, components } from "react-select";
import constants from "../../../constants";

type CrewOption = {
  id: string;
  name: string;
};

const AllCrewsId = "AllCrews";

const ShiftScheduleForm: React.FunctionComponent<{}> = () => {
  const [crewIds, setCrewIds] = useState<Array<string>>([]);
  const [startDate, setStartDate] = useState("");
  const sortedAndFilteredCrews = useSortedAndFilteredCrews();
  const crewSelectRef = useRef<SelectInstance<CrewOption, true> | null>(null);

  const week = useWeek();

  useSetFormProperties(setCrewIds, sortedAndFilteredCrews, setStartDate);

  const datesToMove = useDatesToMove(startDate, week);

  const option = (props: OptionProps<CrewOption, true>) => {
    return (
      <components.Option {...props}>
        {props.data.id === AllCrewsId ? (
          <strong>{props.label}</strong>
        ) : (
          props.children
        )}
      </components.Option>
    );
  };

  let options: Array<CrewOption> = sortedAndFilteredCrews;
  const hasUnselectedOptions =
    sortedAndFilteredCrews.filter((c) => !crewIds.includes(c.id)).length > 0;

  if (hasUnselectedOptions) {
    options = [{ id: AllCrewsId, name: "All Crews" }, ...options];
  }

  return (
    <FormContainer2
      formType={"shiftSchedules"}
      getFormData={() => {
        const data: IShiftRequest = {
          crewIds,
          dates: datesToMove,
        };

        return data;
      }}
      formHeader="Shift Uncompleted Jobs"
      saveButtonText="Shift"
    >
      <div className="form-group">
        <label htmlFor="startDate" className="required">
          Day to start shift this week
        </label>
        <select
          id="startDate"
          className="form-control"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        >
          <option value="0">Sunday</option>
          <option value="1">Monday</option>
          <option value="2">Tuesday</option>
          <option value="3">Wednesday</option>
          <option value="4">Thursday</option>
          <option value="5">Friday</option>
        </select>
      </div>
      <div className="form-group">
        <table style={{ marginLeft: "15px" }}>
          <tbody>
            {datesToMove.map((d) => {
              return (
                <tr key={dateService.formatAsIso(d)}>
                  <td>
                    <small data-testid="sourceDate">
                      {format(d, "dddd (M/D)")}
                    </small>
                  </td>

                  <td>
                    <small>
                      <FontAwesomeIcon icon={faArrowRight} className="mx-2" />
                    </small>
                  </td>
                  <td>
                    <small data-testid="destinationDate">
                      {format(addDays(d, 1), "dddd (M/D)")}
                    </small>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {sortedAndFilteredCrews.length > 1 ? (
        <div className="form-group">
          <label htmlFor="crewId" className="required">
            Crew
          </label>
          <Select<CrewOption, true>
            inputId="crewId"
            ref={(r) => (crewSelectRef.current = r)}
            options={options}
            components={{
              Option: option,
            }}
            getOptionLabel={(c) => c.name}
            getOptionValue={(c) => c.id}
            isMulti={true}
            value={sortedAndFilteredCrews.filter((c) => crewIds.includes(c.id))}
            menuPosition="fixed"
            placeholder="Select crews..."
            noOptionsMessage={() => "No remaining crews"}
            onChange={(newCrews) => {
              if (typeof newCrews.length === "number") {
                let newCrewIds = newCrews.map((c) => c.id);
                if (newCrewIds.includes(AllCrewsId)) {
                  newCrewIds = sortedAndFilteredCrews.map((c) => c.id);
                }

                setCrewIds(newCrewIds);

                if (crewSelectRef.current) {
                  crewSelectRef.current.inputRef?.setCustomValidity(
                    newCrewIds.length === 0 ? "Please fill out this field" : ""
                  );
                }
              }
            }}
          />
        </div>
      ) : null}
    </FormContainer2>
  );
};

export default ShiftScheduleForm;

function useSetFormProperties(
  setCrewIds: React.Dispatch<React.SetStateAction<Array<string>>>,
  sortedAndFilteredCrews: ICrew[],
  setStartDate: React.Dispatch<React.SetStateAction<string>>
) {
  const showForm = useApplicationStateSelector(
    (s) => s.forms.shiftSchedules.showForm
  );
  const crews = useApplicationStateSelector((s) => s.crew.crews);

  const router = useApplicationStateSelector((s) => s.router);
  useEffect(() => {
    if (showForm) {
      let parseResult = parsers.schedule.tryParseWeekSequence(router);

      if (!parseResult.isMatch) {
        parseResult = parsers.schedule.tryParseTimeWeek(router);
      }

      if (!parseResult.isMatch) {
        parseResult = parsers.schedule.tryParseDaySequence(router, crews);
      }

      if (!parseResult.isMatch) {
        parseResult = parsers.schedule.tryParseTimeDay(router, crews);
      }

      let suppliedValidCrewId: string | null = null;
      let isAllCrewsId = false;
      if (parseResult.isMatch && parseResult.crewIdInRoute) {
        if (
          parseResult.crewIdInRoute === constants.allCrewsConstant ||
          parseResult.crewIdInRoute === constants.allCrewsSequenceConstant ||
          parseResult.crewIdInRoute === constants.allCrewsTimeBasedConstant
        ) {
          isAllCrewsId = true;
        } else {
          const doesCrewIdExist = !!sortedAndFilteredCrews.find(
            (c) => c.id === parseResult.crewIdInRoute
          );
          if (doesCrewIdExist) {
            suppliedValidCrewId = parseResult.crewIdInRoute;
          }
        }
      }

      if (isAllCrewsId) {
        setCrewIds([]);
      } else if (suppliedValidCrewId) {
        setCrewIds([suppliedValidCrewId]);
      } else {
        setCrewIds([
          sortedAndFilteredCrews.length > 0 ? sortedAndFilteredCrews[0].id : "",
        ]);
      }

      let currentDayOfWeek = getDay(dateService.getCurrentDate());
      if (currentDayOfWeek === 6) {
        currentDayOfWeek = 5;
      }
      setStartDate(currentDayOfWeek.toString());
    }
  }, [
    showForm,
    setCrewIds,
    setStartDate,
    sortedAndFilteredCrews,
    router,
    crews,
  ]);
}

function useDatesToMove(startDate: string, week: Date) {
  return useMemo(() => {
    const result: Array<Date> = [];
    for (let i = parseInt(startDate, 10); i < 6; i++) {
      result.push(addDays(week, i));
    }
    return result;
  }, [startDate, week]);
}

function useWeek() {
  const router = useApplicationStateSelector((s) => s.router);
  const crews = useApplicationStateSelector((s) => s.crew.crews);

  return useMemo(() => {
    let parseResult: ITryParseScheduleResult =
      parsers.schedule.tryParseWeekSequence(router);

    if (!parseResult.isMatch) {
      parseResult = parsers.schedule.tryParseDaySequence(router, crews);
    }

    let week: Date;
    if (parseResult.isMatch && parseResult.dateInRoute) {
      week = parseResult.dateInRoute;
    } else {
      week = dateService.getCurrentDate();
    }

    week = startOfWeek(week);

    return week;
  }, [router, crews]);
}
