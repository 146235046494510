import React from "react";
import { useDispatch, useStore } from "react-redux";
import { finalize } from "rxjs/operators";
import SendFormBase from "../../../containers/app/components/SendFormBase";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import { logError } from "../../../services/errorLogger";
import { loadOpportunitiesBoard } from "../services/loadOpportunitiesBoard";
import proposalDataProvider from "../services/proposalDataProvider";

interface IProps {
  customerId: string;
  proposalId: string;
  onClose: () => void;
  defaultReplyToEmailAddress: string | null;
}

const ProposalSendForm: React.FunctionComponent<IProps> = ({
  customerId,
  proposalId,
  onClose,
  defaultReplyToEmailAddress,
}) => {
  const dispatch = useDispatch();
  const store = useStore();

  return (
    <SendFormBase
      formHeader="Send Proposal"
      defaultReplyToEmailAddress={defaultReplyToEmailAddress}
      customerId={customerId}
      formType={FormTypesV2.proposalSendForm}
      onCancel={onClose}
      onSaveComplete={() => {
        loadOpportunitiesBoard(store.getState())
          .pipe(
            finalize(() => {
              onClose();
            })
          )
          .subscribe({
            next: (action) => {
              dispatch(action);
            },

            error: (err) => {
              logError(
                `unable to refresh board after sending proposal: ${err}`
              );
            },
          });
      }}
      save={(data) => {
        return proposalDataProvider.sendProposal({
          emailAddresses: data.emailAddresses,
          replyToEmailAddress: data.replyToEmailAddress,
          phoneNumber: data.phoneNumber,
          phoneNumberOptedIntoSms: data.phoneNumberOptedIntoSms,
          sendType: data.sendType,
          proposalId,
        });
      }}
    />
  );
};

export default ProposalSendForm;
