import React, { CSSProperties, useState } from "react";
import ButtonDropdown from "reactstrap/lib/ButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { preventClearingAllCards } from "../../../../services/selectedCardService";
import jobFinder from "../../../../services/jobFinder";
import { JobType } from "../../../../models/IJob";
import { useApplicationStateSelector } from "../../../../hooks/useApplicationStateSelector";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../../modules/actionCreators";
import { getOrderedJobInstanceIdsForDrop } from "../../../../services/scheduleService";
import {
  getJobInstanceDeletePromptAction,
  getJobInstanceV2,
} from "../../../../services/jobInstanceService";
import {
  IPromptMessageSection,
  promptActionCreators,
} from "../../../../modules/prompt";
import dateService from "../../../../services/dateService";
import ActionBar, { actionBarButtonClassName } from "../ActionBar";
import { IJobInstance } from "../../../../models/IJobInstance";

const dropDownItemStyle: Partial<CSSProperties> = { zIndex: 4 };
const SelectedJobActions: React.FunctionComponent = () => {
  const [isSelectedJobsDropdownOpen, setIsSelectedJobsDropdownOpen] =
    useState(false);

  const selectedJobInstanceIds = useApplicationStateSelector(
    (s) => s.scheduleUi.selectedJobInstanceIds
  );
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const weeksUnscheduledMaintenanceJobs = useApplicationStateSelector(
    (s) => s.schedule.weeksUnscheduledMaintenanceJobs
  );
  const jobs = useApplicationStateSelector((s) => s.job.jobs);
  const oneTimeJobs = useApplicationStateSelector((s) => s.job.oneTimeJobs);

  let selectedJobInstances: Array<IJobInstance>;

  try {
    selectedJobInstances = selectedJobInstanceIds.map(
      (ji) =>
        getJobInstanceV2(daySchedules, weeksUnscheduledMaintenanceJobs, ji)
          .jobInstance
    );
  } catch {
    // getJobInstanceV2 throws an exception if jobinstance not found. we don't want to have the exception
    // break this component if not found so catch the exception.
    selectedJobInstances = [];
  }

  const allJobsSkipped =
    selectedJobInstances.length > 0 &&
    selectedJobInstances.every((ji) => ji.skipped);

  const dispatch = useDispatch();

  return (
    <ActionBar>
      <ButtonDropdown
        style={{ zIndex: 4 }}
        isOpen={isSelectedJobsDropdownOpen}
        toggle={() =>
          setIsSelectedJobsDropdownOpen(!isSelectedJobsDropdownOpen)
        }
        onClick={(e) => preventClearingAllCards(e)}
      >
        <DropdownToggle caret color={actionBarButtonClassName}>
          Update Selected Jobs
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            style={dropDownItemStyle}
            onClick={() =>
              dispatch(
                actionCreators.forms.moveJobInstance.showForm({
                  jobInstanceIds: getOrderedJobInstanceIdsForDrop(
                    selectedJobInstanceIds,
                    daySchedules,
                    weeksUnscheduledMaintenanceJobs
                  ),
                })
              )
            }
          >
            Move
          </DropdownItem>
          <DropdownItem
            style={dropDownItemStyle}
            onClick={() =>
              dispatch(
                actionCreators.forms.bulkCopyJobs.showForm({
                  sourceJobInstanceIds: getOrderedJobInstanceIdsForDrop(
                    selectedJobInstanceIds,
                    daySchedules,
                    weeksUnscheduledMaintenanceJobs
                  ),
                })
              )
            }
          >
            Copy
          </DropdownItem>
          <DropdownItem
            style={dropDownItemStyle}
            onClick={() => {
              const saveAction = getSkipSaveActionText(allJobsSkipped);
              const inMessageAction = saveAction.toLocaleLowerCase();
              dispatch(
                promptActionCreators.showPrompt({
                  promptMessage:
                    selectedJobInstanceIds.length > 1
                      ? `Are you sure you want to ${inMessageAction} ${selectedJobInstanceIds.length} job cards?`
                      : `Are you sure you want to ${inMessageAction} ${selectedJobInstanceIds.length} job card?`,
                  promptSaveText: saveAction,
                  confirmationAction:
                    actionCreators.jobInstanceToggleSkippedStart(
                      selectedJobInstanceIds,
                      allJobsSkipped ? false : true,
                      true
                    ),
                  promptCancelText: null,
                  promptSubMessage: null,
                  promptSubMessageClassName: undefined,
                })
              );
            }}
          >
            {getSkipSaveActionText(allJobsSkipped)}
          </DropdownItem>
          <DropdownItem
            style={dropDownItemStyle}
            onClick={() =>
              dispatch(
                promptActionCreators.showPrompt({
                  promptMessage:
                    selectedJobInstanceIds.length > 1
                      ? `Are you sure you want to complete ${selectedJobInstanceIds.length} job cards?`
                      : `Are you sure you want to complete ${selectedJobInstanceIds.length} job card?`,
                  promptSaveText: "Complete",
                  confirmationAction:
                    actionCreators.jobInstanceToggleCompletedStart(
                      selectedJobInstanceIds
                    ),
                  promptCancelText: null,
                  promptSubMessage: null,
                  promptSubMessageClassName: undefined,
                })
              )
            }
          >
            Complete
          </DropdownItem>
          <DropdownItem
            style={dropDownItemStyle}
            onClick={() => {
              const foundJobs = selectedJobInstanceIds.map((jobInstanceId) =>
                jobFinder.getJobForDayScheduleV3(
                  jobInstanceId,
                  jobs,
                  oneTimeJobs,
                  daySchedules,
                  weeksUnscheduledMaintenanceJobs
                )
              );
              const maintenanceJobs = foundJobs.filter(
                (j) => j !== null && j.type === JobType.maintenanceJob
              );
              const multipleMaintenanceJobs = maintenanceJobs.length > 1;
              const singleMaintenanceJob = maintenanceJobs.length === 1;
              const someMaintenanceJobs = maintenanceJobs.length > 0;

              dispatch(
                getJobInstanceDeletePromptAction({
                  promptMessage:
                    selectedJobInstanceIds.length > 1
                      ? `Remove ${selectedJobInstanceIds.length} jobs from schedule?`
                      : `Remove ${selectedJobInstanceIds.length} job from schedule?`,
                  markMaintenanceJobsInactiveSubMessage:
                    getDeletePromptSubMessage(
                      multipleMaintenanceJobs,
                      singleMaintenanceJob
                    ),
                  hasMaintenanceJob: someMaintenanceJobs,
                  jobInstanceIds: selectedJobInstanceIds,
                })
              );
            }}
          >
            Remove
          </DropdownItem>
          <DropdownItem
            style={dropDownItemStyle}
            onClick={() =>
              dispatch(
                actionCreators.forms.customerNotification.showForm({
                  jobInstanceIds: getOrderedJobInstanceIdsForDrop(
                    selectedJobInstanceIds,
                    daySchedules,
                    weeksUnscheduledMaintenanceJobs
                  ),
                })
              )
            }
          >
            {selectedJobInstanceIds.length > 1
              ? "Text Customers"
              : "Text Customer"}
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </ActionBar>
  );
};

export default SelectedJobActions;

function getSkipSaveActionText(allJobsSkipped: boolean) {
  return allJobsSkipped ? "Unskip" : "Skip";
}

function getDeletePromptSubMessage(
  multipleMaintenanceJobs: boolean,
  singleMaintenanceJob: boolean
): string | null | Array<IPromptMessageSection> {
  if (multipleMaintenanceJobs) {
    return [
      {
        text: "This will remove all future job visits for these recurring jobs from ",
      },
      {
        text: `${dateService.formatDateForDisplay(
          dateService.getCurrentDate()
        )} `,
        bold: true,
      },
      {
        text: "onward for these customers but keep any visits in the past for historical reporting.",
      },
    ];
  } else if (singleMaintenanceJob) {
    return [
      {
        text: "This will remove all future job visits for this recurring job from ",
      },
      {
        text: `${dateService.formatDateForDisplay(
          dateService.getCurrentDate()
        )} `,
        bold: true,
      },
      {
        text: "onward for this customer but keep any visits in the past for historical reporting.",
      },
    ];
  } else {
    return null;
  }
}
