export function getIntroText(subscriptionMode: boolean) {
  const signupText = subscriptionMode
    ? "By signing up for Crew Control, you also sign up for our payment processing. "
    : "";
  return (
    <>
      <div>
        <strong>Why are we asking for this information?</strong>
      </div>
      <div>
        This information is required by regulations to sign up for payment
        processing. {signupText} If you have concerns, please contact support.
      </div>
      <div className="mt-2">
        <strong>Why use Crew Control payments?</strong>
      </div>
      <div>
        Text invoices, setup recurring billing, and more with our billing
        workcenter! You can learn more by watching{" "}
        <a
          href="https://www.youraspire.com/hubfs/Videos/Release%20videos/Crew%20Control%20Payments%20release.mp4"
          target="_blank"
          rel="noopener noreferrer"
        >
          this video
        </a>
        .
      </div>
    </>
  );
}
