import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useState } from "react";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";
import { IProposalExisting } from "../models/IProposal";
import { isStringSet } from "../../../services/stringService";

interface IProps {
  proposal: IProposalExisting | null;
}

const ProposalRejectedReason: React.FunctionComponent<IProps> = ({
  proposal,
}) => {
  const [showPopover, setShowPopover] = useState(false);

  if (!isStringSet(proposal?.rejectedReason ?? null)) {
    return null;
  }

  const iconId = `proposalRejectedReason${proposal?.id}`;
  return (
    <>
      <FontAwesomeIcon
        icon={faComment}
        size="lg"
        data-testid="proposalRejectedReasonContainer"
        className={`board-card-indicators-icon`}
        id={iconId}
        onClick={(e) => {
          e.stopPropagation();
          setShowPopover(showPopover);
        }}
        style={{
          cursor: "pointer",
        }}
      />
      <span className="sr-only">
        Rejected reason: {proposal?.rejectedReason}
      </span>
      <Popover
        delay={0}
        trigger="legacy"
        placement="bottom"
        isOpen={showPopover}
        target={iconId}
        toggle={() => {
          setShowPopover(!showPopover);
        }}
      >
        <PopoverBody>Rejected reason: {proposal?.rejectedReason}</PopoverBody>
      </Popover>
    </>
  );
};

export default ProposalRejectedReason;
