import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import {
  buildUrl,
  executeWithHeaders,
} from "../../../services/remoteDataProvider";
import { IInvoiceItemCrewControl } from "../../../models/IInvoiceItemCrewControl";

export const invoiceItemDataProvider = {
  invoiceItemImportPreview: (
    file: File
  ): Observable<Array<IInvoiceItemCrewControl>> => {
    const formData = new FormData();
    formData.append("file", file);
    return executeWithHeaders((headers) => {
      delete headers["Content-Type"];
      return ajax
        .post(buildUrl(`invoiceItem/importPreview`), formData, headers)
        .pipe(map((r) => r.response.invoiceItems));
    });
  },

  invoiceItemImport: (invoiceItems: Array<IInvoiceItemCrewControl>) => {
    return executeWithHeaders((headers) => {
      return ajax.post(
        buildUrl(`invoiceItem/import`),
        { invoiceItems },
        headers
      );
    });
  },
};
