import React, { useState, useRef, useEffect } from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import FormContainer2 from "../components/FormContainer2";
import TimeInput from "../components/TimeInput";
import dateService from "../../../services/dateService";
import Spinner from "../components/Spinner";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { finalize } from "rxjs/operators";
import { format } from "date-fns";
import DayPicker, { format as dayPickerFormat } from "../components/DayPicker";
import { getSortedCrews } from "../../../services/sortingService";

interface IFormData {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  crewId: string;
}

const defaultFormData: IFormData = {
  startDate: "",
  startTime: "",
  endDate: "",
  endTime: "",
  crewId: "",
};

const PayrollTimeRangeForm: React.SFC<{}> = () => {
  const [formData, setFormData] = useState<IFormData>(defaultFormData);
  const [originalFormData, setOriginalFormData] =
    useState<IFormData>(defaultFormData);
  const [loading, setLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);

  const showForm = useApplicationStateSelector(
    (s) => s.forms.payrollTimeRange.showForm
  );
  const previousShowForm = useRef<boolean>(false);
  const timeRangeId = useApplicationStateSelector(
    (s) => s.forms.payrollTimeRange.parameters?.timeRangeId
  );
  const crews = useApplicationStateSelector((s) => s.crew.crews);

  useEffect(() => {
    if (showForm && !previousShowForm.current) {
      setFormData(defaultFormData);
      setOriginalFormData(defaultFormData);
      setErrorLoading(false);

      if (timeRangeId) {
        setLoading(true);
        remoteDataProvider
          .getPayrollTimeRange(timeRangeId)
          .pipe(finalize(() => setLoading(false)))
          .subscribe(
            (r) => {
              const formDataToSet = {
                startDate: dateService.formatAsIso(r.start),
                startTime: dateService.formatDateTimeForTimeInput(r.start),
                endDate: r.end ? dateService.formatAsIso(r.end) : "",
                endTime: r.end
                  ? dateService.formatDateTimeForTimeInput(r.end)
                  : "",
                crewId: r.crewId ?? "",
              };
              setFormData(formDataToSet);
              setOriginalFormData(formDataToSet);
            },
            () => setErrorLoading(true)
          );
      }
    }

    previousShowForm.current = showForm;
  }, [showForm, setFormData, timeRangeId, setLoading, setErrorLoading]);

  return (
    <>
      {loading && <Spinner />}
      <FormContainer2
        formType={"payrollTimeRange"}
        hideSave={errorLoading}
        hasFormDataChanged={() => {
          return JSON.stringify(formData) !== JSON.stringify(originalFormData);
        }}
        getFormData={() => {
          return {
            crewId: formData.crewId === "" ? null : formData.crewId,
            start: `${dateService.formatAsIso(
              formData.startDate
            )}T${dateService.formatTimeForSerialization(formData.startTime)}`,
            end:
              formData.endDate && formData.endTime
                ? `${dateService.formatAsIso(
                    formData.endDate
                  )}T${dateService.formatTimeForSerialization(
                    formData.endTime
                  )}`
                : null,
          };
        }}
        validate={() => {
          const endDateSet = formData.endDate !== "";
          const endTimeSet = formData.endTime !== "";
          if (endDateSet !== endTimeSet) {
            return {
              valid: false,
              errorMessage:
                "If setting Clock Out, both the date and time must be set",
            };
          }

          if (!formData.startDate) {
            return {
              valid: false,
              errorMessage: "Please select a Clock In Date",
            };
          }

          return {
            valid: true,
          };
        }}
        formHeader="Crew Member Time"
        showForm={!loading}
      >
        {errorLoading ? (
          <div>
            The crew member time could not be loaded. Please check your Internet
            connection and try again.
          </div>
        ) : (
          <div style={{ minHeight: "455px" }}>
            <div className="form-group">
              <label htmlFor="crewId">Crew</label>
              <select
                id="crewId"
                className="form-control"
                name="crewId"
                value={formData.crewId}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    crewId: e.currentTarget.value,
                  })
                }
              >
                <option value=""></option>
                {getSortedCrews(crews)
                  .filter((c) => !c.inactive || c.id === formData.crewId)
                  .map((crew) => (
                    <option key={crew.id} value={crew.id}>
                      {crew.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="form-row align-items-end">
              <div className="form-group col-12 col-sm-6">
                <label htmlFor="startDate" className="required">
                  <span className="d-none d-sm-inline">Clock in</span>
                  <span className="d-sm-none">Clock in date</span>
                </label>
                <div>
                  <DayPicker
                    testId="startDate"
                    inputId="startDate"
                    onDayPickerHide={() => null}
                    dayPickerProps={{}}
                    value={
                      !!formData.startDate
                        ? format(formData.startDate, dayPickerFormat)
                        : ""
                    }
                    overlayFixed={true}
                    required={true}
                    onDaySelected={(day: Date) => {
                      setFormData({
                        ...formData,
                        startDate: day ? dateService.formatAsIso(day) : "",
                      });
                    }}
                  />
                </div>
              </div>
              <div className="form-group col-12 col-sm-6">
                <div className="d-sm-none">
                  <label htmlFor="startTime" className="required">
                    Clock in time
                  </label>
                </div>
                <TimeInput
                  className="form-control"
                  name="startTime"
                  value={formData.startTime || ""}
                  id={"startTime"}
                  data-testid="startTime"
                  onChange={(time) =>
                    setFormData({
                      ...formData,
                      startTime: time,
                    })
                  }
                  required={true}
                />
              </div>
            </div>
            <div className="form-row align-items-end">
              <div className="form-group col-12 col-sm-6">
                <label htmlFor="endDate">
                  <span className="d-none d-sm-inline">Clock out</span>
                  <span className="d-sm-none">Clock out date</span>
                </label>
                <div>
                  <DayPicker
                    testId="endDate"
                    inputId="endDate"
                    onDayPickerHide={() => null}
                    dayPickerProps={{}}
                    value={
                      !!formData.endDate
                        ? format(formData.endDate, dayPickerFormat)
                        : ""
                    }
                    overlayFixed={true}
                    required={false}
                    onDaySelected={(day: Date) => {
                      setFormData({
                        ...formData,
                        endDate: day ? dateService.formatAsIso(day) : "",
                      });
                    }}
                  />
                </div>
              </div>
              <div className="form-group col-12 col-sm-6">
                <div className="d-sm-none">
                  <label htmlFor="endTime">Clock out time</label>
                </div>
                <TimeInput
                  className="form-control"
                  name="endTime"
                  value={formData.endTime || ""}
                  id={"endTime"}
                  data-testid="endTime"
                  onChange={(time) =>
                    setFormData({
                      ...formData,
                      endTime: time,
                    })
                  }
                />
              </div>
            </div>
          </div>
        )}
      </FormContainer2>
    </>
  );
};

export default PayrollTimeRangeForm;
