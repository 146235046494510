import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserSettingsType } from "../enums/userSettingsType";
import { IAction, IInitialLoadCompletedAction } from "./actionTypeDefinitions";
import { actionTypes } from "./actionTypes";
import { isStringSet } from "../services/stringService";
import { IUserSetting } from "../models/IUserSetting";

export interface IUserSettingsState {
  userSettings: Array<IUserSetting>;
}

type SetUserSetting = {
  type: UserSettingsType;
  value: unknown;
};

export type SetUserSettingActionType = PayloadAction<SetUserSetting>;

const initialState: IUserSettingsState = {
  userSettings: [],
};

const { reducer, actions } = createSlice({
  name: "userSettings",
  initialState: initialState,
  reducers: {
    setUserSetting(state, action: SetUserSettingActionType) {
      return {
        ...state,
        userSettings: [
          // Only retain existing user settings that aren't being updated
          ...state.userSettings.filter(
            (existingSetting) => action.payload.type !== existingSetting.type
          ),
          {
            type: action.payload.type,
            value: encodeValue(action.payload.value),
          },
        ],
      };
    },

    setUserSettingsFromMigration(
      state,
      action: PayloadAction<{ userSettings: Array<SetUserSetting> }>
    ) {
      return {
        ...state,
        userSettings: [
          // Only retain existing user settings that aren't being updated
          ...state.userSettings.filter(
            (existingSetting) =>
              !action.payload.userSettings.some(
                (newSetting) => newSetting.type === existingSetting.type
              )
          ),
          ...action.payload.userSettings.map((newSetting) => ({
            ...newSetting,
            value: encodeValue(newSetting.value),
          })),
        ],
      };
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(isInitialLoadCompletedAction, (state, action) => {
      state.userSettings = action.userSettings;
    });
  },
});

export default reducer;

export const userSettingsActionCreators = actions;

export function encodeValue(value: unknown): string {
  return JSON.stringify(value);
}

export function decodeValue<T>(value: string) {
  if (isStringSet(value)) {
    return JSON.parse(value) as T;
  } else {
    return null;
  }
}

function isInitialLoadCompletedAction(
  action: IAction
): action is IInitialLoadCompletedAction {
  return action.type === actionTypes.INITIAL_LOAD_COMPLETED;
}
