import modelConversion from "./modelConversion";

export interface ILatLngValidateResult {
  valid: boolean;
  error: string;
}

type LatLngInput = number | string | null;

export function validateLatitudeOptional(value: LatLngInput) {
  return validateLatLngOptional(value, "Latitude", 90);
}

export function validateLongitudeOptional(value: LatLngInput) {
  return validateLatLngOptional(value, "Longitude", 180);
}

function validateLatLngOptional(
  value: LatLngInput,
  fieldLabel: string,
  maxValue: number
): ILatLngValidateResult {
  if (value === null) {
    return {
      valid: true,
      error: ""
    };
  }

  let parsedValue = modelConversion.convertStringOrNumberToNumber(value);
  if (parsedValue > maxValue || parsedValue < maxValue * -1) {
    return {
      valid: false,
      error: `${fieldLabel} is not valid`
    };
  } else {
    return {
      valid: true,
      error: ""
    };
  }
}
