import { actionCreators, actionTypes } from "./actionCreators";
import {
  IDropJobAction,
  ICrewDeleteStart,
  IJobDeleteStart,
  IManageUiShowMessage,
  IJobInstanceToggleSkippedStart,
  ITodoTemplateDeleteStart,
  IOneTimeJobLoadStart,
  IMaintenanceJobLoadStart,
  IJobInstanceDeleteStart,
  ICustomerLoadStart,
  ICustomerDeleteStart,
  ICustomerAdditionalLocationDeleteStart,
  ICrewMemberDeleteStart,
  ICustomerSelectionSearchStart,
  ICustomerSelectionSearchInput,
  IBillingReportStartLoad,
  ILoadDaySchedulesStartingAction,
  ILoadDistancesStartingAction,
  IPermanentJobsSaveStart,
  IRemoteDayScheduleChanged,
  IRemoteJobChangedAction,
  IOneTimeJobLoadComplete,
  IMaintenanceJobLoadComplete,
  IManageCustomersSearchStart,
  IAction,
  IJobInstanceToggleCompletedStart,
  IProjectSaved,
  IRemoteCustomerChangedAction,
  IRemoteCrewChangedAction,
} from "./actionTypeDefinitions";
import { ofType } from "redux-observable";
import { of } from "rxjs";
import {
  map,
  mergeMap,
  catchError,
  timeout,
  flatMap,
  delay,
  switchMap,
  debounceTime,
  retry,
  tap,
} from "rxjs/operators";
import { actionEpics as formActionEpics } from "../formGenerator";
import {
  formTypes,
  actionTypes as formActionTypes,
  createSpecificActionTypeName,
} from "../formGenerator";
import { push } from "connected-react-router";
import format from "date-fns/format";
import {
  builders as routingBuilders,
  parsers as routingParsers,
} from "../services/routing";
import dataProvider from "../services/dataProvider";
import remoteDataProvider, {
  ISaveCrewMemberResponse,
  ISaveCustomerResponse,
  IShiftResult,
} from "../services/remoteDataProvider";
import { getErrorMessageFromError } from "../services/httpErrorHandler";
import { JobType } from "../models/IJob";
import { IRootState } from "../store";
import { parse } from "date-fns";
import { getPermanentJobsSetMessage } from "../services/dropJobService";
import { logError } from "../services/errorLogger";
import { loadCustomersAndChildRecords } from "../services/customerService";
import { getGoogelAnalyticsSignupCategory } from "../services/googleService";
import { loadFlexibleWeekContainers } from "./epicActionHandlers/loadFlexibleWeekContainers";
import { loadDaySchedules } from "./epicActionHandlers/loadDaySchedules";
import { loadRemoteChangedSchedules } from "./epicActionHandlers/loadRemoteChangedSchedules";
import { loadMissingCategories } from "./epicActionHandlers/loadMissingCategories";
import { handleManageCustomersSearch } from "./epicActionHandlers/handleManageCustomersSearch";
import { handleJobInstanceDeleteStart } from "./epicActionHandlers/handleJobInstanceDeleteStart";
import { autoRouteJobs } from "./epicActionHandlers/autoRouteJobs";
import { autoRouteJobsPermanentSave } from "./epicActionHandlers/autoRouteJobsPermanentSave";
import { JobInstanceChangeType } from "../enums/jobInstanceChangeType";
import TagManager from "react-gtm-module";
import { handleJobInstanceToggleCompletedStart } from "./epicActionHandlers/handleJobInstanceToggleCompletedStart";
import { redirectFromOldSchedules } from "./epicActionHandlers/redirectFromOldSchedules";
import { handleJobInstanceBulkAdded } from "./epicActionHandlers/handleJobInstanceBulkAdded";
import { handlePermanentJobsSaveStart } from "./epicActionHandlers/handlePermanentJobsSaveStart";
import { ICompleteSavingAction } from "../formGenerator/actionTypes";
import { handleCustomerCompleteSaving } from "./epicActionHandlers/handleCustomerCompleteSaving";
import { handleInitialLoadStarting } from "./epicActionHandlers/handleInitialLoadStarting";
import { loadRemoteChangedCustomer } from "./epicActionHandlers/loadRemoteChangedCustomer";
import { loadRemoteChangedCrew } from "./epicActionHandlers/loadRemoteChangedCrew";
import saveUserSettings from "./epicActionHandlers/saveUserSetting";
import {
  SetUserSettingActionType,
  userSettingsActionCreators,
} from "./userSettings";
import { handleCrewMemberCompleteSaving } from "./epicActionHandlers/handleCrewMemberCompleteSaving";

const actionEpics = [
  ...formActionEpics,

  (action$: any, state: any) =>
    action$.pipe(
      ofType("@@router/LOCATION_CHANGE"),
      map((action: any) => {
        return redirectFromOldSchedules(
          action.payload.isFirstRendering,
          state.value as IRootState
        );
      })
    ),

  (action: any, state: any) =>
    action.pipe(
      ofType(
        createSpecificActionTypeName(
          formTypes.crew,
          formActionTypes.completeSaving
        )
      ),
      map((result: any) => {
        if (
          !result.payload.parameters ||
          !result.payload.parameters.skipRedirect
        ) {
          const tryParseWeekResult =
            routingParsers.schedule.tryParseWeekSequence(state.value.router);

          let date: any;
          if (tryParseWeekResult.isMatch) {
            date = tryParseWeekResult.dateInRoute;
          } else {
            date = new Date();
          }

          return push(
            routingBuilders.schedule.buildSequenceWeekRoute(
              format(date, "YYYY-MM-DD"),
              result.payload.id
            )
          );
        } else {
          return actionCreators.null();
        }
      })
    ),

  // Load specific day schedules
  (action$: any, state: any) =>
    action$.pipe(
      ofType(actionTypes.LOAD_DAY_SCHEDULES_STARTING),
      mergeMap((action: ILoadDaySchedulesStartingAction) => {
        return loadDaySchedules(action, state.value as IRootState);
      })
    ),

  // Load flexible week containers
  (action$: any, state: any) =>
    action$.pipe(
      ofType(actionTypes.LOAD_DAY_SCHEDULES_STARTING),
      mergeMap((action: ILoadDaySchedulesStartingAction) => {
        return loadFlexibleWeekContainers(action, state.value as IRootState);
      })
    ),

  // Reload schedules if changed by other users
  (action$: any, state: any) =>
    action$.pipe(
      ofType(actionTypes.REMOTE_DAYSCHEDULE_CHANGED),
      mergeMap((action: IRemoteDayScheduleChanged) => {
        return loadRemoteChangedSchedules(action, state.value as IRootState);
      })
    ),

  // Reload jobs if changed by other users
  (action$: any, state: any) =>
    action$.pipe(
      ofType(actionTypes.REMOTE_JOB_CHANGED),
      mergeMap((action: IRemoteJobChangedAction) => {
        const rootState = state.value as IRootState;
        let resultAction = of(actionCreators.null());
        if (action.jobType === JobType.maintenanceJob) {
          if (rootState.job.jobs.some((j) => j.id === action.jobId)) {
            resultAction = dataProvider
              .getMaintenanceJobs([action.jobId])
              .pipe(
                map((r) =>
                  actionCreators.loadMaintenanceJobsComplete(
                    r.maintenanceJobs,
                    r.customers,
                    r.customerAdditionalLocations
                  )
                )
              );
          }
        } else if (action.jobType === JobType.oneTimeJob) {
          resultAction = dataProvider
            .getOneTimeJobs([action.jobId])
            .pipe(
              map((r) =>
                actionCreators.loadOneTimeJobsComplete(
                  r.oneTimeJobs,
                  r.customers,
                  r.customerAdditionalLocations
                )
              )
            );
        }

        return resultAction;
      })
    ),

  // Reload customers if changed by other users
  (action$: any, state: any) =>
    action$.pipe(
      ofType(actionTypes.REMOTE_CUSTOMER_CHANGED),
      mergeMap((action: IRemoteCustomerChangedAction) => {
        const rootState = state.value as IRootState;
        return loadRemoteChangedCustomer(action, rootState);
      })
    ),

  // Reload crews if changed by other users
  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.REMOTE_CREW_CHANGED),
      mergeMap((action: IRemoteCrewChangedAction) => {
        return loadRemoteChangedCrew(action);
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.LOAD_DISTANCES_STARTING),
      mergeMap((action: ILoadDistancesStartingAction) => {
        return remoteDataProvider
          .getDriveTimes({
            legs: action.distancesToLoad.map((dl) => ({
              start: {
                latitude: dl.sourceLatitude.toString(),
                longitude: dl.sourceLongitude.toString(),
              },
              end: {
                latitude: dl.destinationLatitude.toString(),
                longitude: dl.destinationLongitude.toString(),
              },
            })),
          })
          .pipe(
            map((result) => {
              return actionCreators.loadDistancesCompleted(
                result.legResults.map((r) => {
                  const mappedLegResult = {
                    sourceLatitude: parseFloat(r.start.latitude),
                    sourceLongitude: parseFloat(r.start.longitude),
                    destinationLatitude: parseFloat(r.end.latitude),
                    destinationLongitude: parseFloat(r.end.longitude),
                    distanceInSeconds:
                      r.driveTimeInSeconds !== null
                        ? r.driveTimeInSeconds
                        : undefined,
                    errorLoadingDistance: r.error,
                  };

                  const matchingRequestExists = action.distancesToLoad.some(
                    (d) =>
                      d.destinationLatitude ===
                        mappedLegResult.destinationLatitude &&
                      d.destinationLongitude ===
                        mappedLegResult.destinationLongitude &&
                      d.sourceLatitude === mappedLegResult.sourceLatitude &&
                      d.sourceLongitude === mappedLegResult.sourceLongitude
                  );

                  if (!matchingRequestExists) {
                    logError(
                      `Unable to find initial request for drive time. SLat: ${mappedLegResult.sourceLatitude}, SLon: ${mappedLegResult.sourceLongitude}, DLat: ${mappedLegResult.destinationLatitude}, DLon: ${mappedLegResult.destinationLongitude}`
                    );
                  }

                  return mappedLegResult;
                })
              );
            })
          );
      })
    ),

  (action$: any, state: any) =>
    action$.pipe(
      ofType(actionTypes.DROP_JOB),
      mergeMap((action: IDropJobAction) => {
        let destinationCrewId: string | null = null;
        let destinationDate: string;

        if (!action.destinationFlexibleJob) {
          const daySchedule = state.value.schedule.daySchedules.find(
            (ds: any) => ds.id === action.destinationDayScheduleId
          );
          destinationCrewId = daySchedule.crewId;
          destinationDate = daySchedule.date;
        } else {
          destinationDate = format(
            action.destinationFlexibleJobWeek as Date,
            "YYYY-MM-DD"
          );
        }

        return dataProvider
          .moveJobInstance(
            {
              destinationCrewId: destinationCrewId,
              destinationDate: destinationDate,
              destinationPrecedingJobInstanceId:
                action.destinationPrecedingJobInstanceId,
              destinationFlexibleJob: action.destinationFlexibleJob,
              jobInstanceChangeType: JobInstanceChangeType.Dragged,
              jobInstanceUpdates: action.jobInstanceUpdates,
            },
            {
              jobInstanceIds: action.jobInstanceIds,
            }
          )
          .pipe(
            flatMap((result: any) => {
              return of(
                getPermanentJobsSetMessage(action, state.value),
                actionCreators.dropJobComplete(
                  action.jobInstanceIds,
                  result.parameters.destinationDayScheduleId
                )
              );
            }),
            // TODO: Review all code to ensure catchError in inner pipes
            catchError(() => {
              return of(actionCreators.errorOnDropJob(action.jobInstanceIds));
            })
          );
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.PERMANENT_JOBS_SAVE_START),
      mergeMap((action: IPermanentJobsSaveStart) => {
        return handlePermanentJobsSaveStart(action);
      })
    ),

  (action$: any, state$: any) =>
    action$.pipe(
      ofType(actionTypes.AUTO_ROUTE_JOBS_PERMANENT_SAVE_STARTING),
      mergeMap((action: IAction) => {
        return autoRouteJobsPermanentSave(action, state$.value);
      })
    ),

  (action$: any, state$: any) =>
    action$.pipe(
      ofType(actionTypes.AUTO_ROUTE_JOBS_STARTING),
      mergeMap((action: any) => {
        return autoRouteJobs(action, state$.value);
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.INITIAL_LOAD_STARTING),
      mergeMap(() => handleInitialLoadStarting())
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.INITIAL_LOAD_UPDATE),
      mergeMap(() => {
        return remoteDataProvider
          .getInitialLoad()
          .pipe(map((result) => actionCreators.completeInitialLoad(result)));
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.MANGEUI_SHOW_MESSAGE),
      delay(5000),
      map((action: IManageUiShowMessage) =>
        actionCreators.hideManageUiMessage(action.messageId)
      )
    ),

  (action$: any) =>
    action$.pipe(
      ofType(
        createSpecificActionTypeName(
          formTypes.publishSchedule,
          formActionTypes.completeSaving
        )
      ),
      delay(5000),
      map((action: any) =>
        actionCreators.closePageLevelMessage(action.messageKey)
      )
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.CREW_DELETE_START),
      mergeMap((action: ICrewDeleteStart) => {
        return remoteDataProvider.deleteCrew(action.crewId).pipe(
          flatMap(() =>
            of(
              actionCreators.crewDeleteComplete(action.crewId),
              actionCreators.showManageUiMessage("Crew deleted")
            )
          ),
          catchError((err) => {
            let errorMessage = getErrorMessageFromError(
              err,
              "An unknown error occurred while saving."
            );

            return of(
              actionCreators.crewDeleteError(action.crewId, errorMessage)
            );
          })
        );
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.CREW_MEMBER_DELETE_START),
      mergeMap((action: ICrewMemberDeleteStart) => {
        return remoteDataProvider.deleteCrewMember(action.crewMemberId).pipe(
          flatMap(() =>
            of(
              actionCreators.crewMemberDeleteComplete(action.crewMemberId),
              actionCreators.showManageUiMessage("Crew member deleted")
            )
          ),
          catchError((err) => {
            let errorMessage = getErrorMessageFromError(
              err,
              "An unknown error occurred while saving."
            );

            return of(
              actionCreators.crewMemberDeleteError(
                action.crewMemberId,
                errorMessage
              )
            );
          })
        );
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.TODO_TEMPLATE_DELETE_START),
      mergeMap((action: ITodoTemplateDeleteStart) => {
        return remoteDataProvider
          .deleteTodoTemplate(action.todoTemplateId)
          .pipe(
            flatMap(() =>
              of(
                actionCreators.todoTemplateDeleteComplete(
                  action.todoTemplateId
                ),
                actionCreators.showManageUiMessage("Checklist template deleted")
              )
            ),
            catchError((err) => {
              let errorMessage = getErrorMessageFromError(
                err,
                "An unknown error occurred while saving."
              );

              return of(
                actionCreators.crewDeleteError(
                  action.todoTemplateId,
                  errorMessage
                )
              );
            })
          );
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.JOB_DELETE_START),
      mergeMap((action: IJobDeleteStart) => {
        let deleteFn: (id: string) => any;
        if (action.jobType === JobType.maintenanceJob) {
          deleteFn = remoteDataProvider.deleteMaintenanceJob;
        } else {
          deleteFn = remoteDataProvider.deleteOneTimeJob;
        }

        return deleteFn(action.jobId).pipe(
          flatMap(() => {
            return of(
              actionCreators.jobDeleteComplete(action.jobId),
              actionCreators.showManageUiMessage("Job deleted")
            );
          }),
          catchError((err) => {
            let errorMessage = getErrorMessageFromError(
              err,
              "An unknown error occurred while saving."
            );

            return of(
              actionCreators.jobDeleteError(
                action.jobId,
                errorMessage,
                action.contextId
              )
            );
          })
        );
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.CUSTOMER_ADDITIONAL_LOCATION_DELETE_START),
      mergeMap((action: ICustomerAdditionalLocationDeleteStart) => {
        return remoteDataProvider
          .deleteCustomerAdditionalLocation(action.customerAdditionalLocationId)
          .pipe(
            flatMap(() => {
              return of(
                actionCreators.customerAdditionalLocationDeleteComplete(
                  action.customerAdditionalLocationId
                ),
                actionCreators.showManageUiMessage(
                  "Additional location deleted"
                )
              );
            }),
            catchError((err) => {
              let errorMessage = getErrorMessageFromError(
                err,
                "An unknown error occurred while saving."
              );

              return of(
                actionCreators.customerAdditionalLocationDeleteError(
                  action.customerAdditionalLocationId,
                  errorMessage,
                  action.contextId
                )
              );
            })
          );
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.CUSTOMER_DELETE_START),
      mergeMap((action: ICustomerDeleteStart) => {
        return remoteDataProvider.deleteCustomer(action.customerId).pipe(
          flatMap(() => {
            return of(
              actionCreators.customerDeleteComplete(action.customerId),
              actionCreators.showManageUiMessage("Customer deleted")
            );
          }),
          catchError((err) => {
            let errorMessage = getErrorMessageFromError(
              err,
              "An unknown error occurred while saving."
            );

            return of(
              actionCreators.customerDeleteError(
                action.customerId,
                errorMessage
              )
            );
          })
        );
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.JOB_INSTANCE_DELETE_START),
      mergeMap((action: IJobInstanceDeleteStart) => {
        return handleJobInstanceDeleteStart(action);
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.JOB_INSTANCE_TOGGLE_SKIPPED_START),
      mergeMap((action: IJobInstanceToggleSkippedStart) => {
        return remoteDataProvider
          .skipJobInstances(action.jobInstanceIds, action.skipped)
          .pipe(
            map(() =>
              actionCreators.jobInstanceToggleSkippedComplete(
                action.jobInstanceIds,
                action.skipped,
                action.clearSelected
              )
            ),
            catchError((err) => {
              let errorMessage = getErrorMessageFromError(
                err,
                "An unknown error occurred while saving."
              );

              return of(
                actionCreators.jobInstanceToggleSkippedError(
                  action.jobInstanceIds,
                  errorMessage
                )
              );
            })
          );
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.JOB_INSTANCE_TOGGLE_COMPLETED_START),
      mergeMap((action: IJobInstanceToggleCompletedStart) => {
        return handleJobInstanceToggleCompletedStart(action);
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.ONE_TIME_JOB_LOAD_START),
      mergeMap((action: IOneTimeJobLoadStart) =>
        dataProvider.getOneTimeJobs(action.jobIds).pipe(
          timeout(45000),
          map((result) => {
            return actionCreators.loadOneTimeJobsComplete(
              result.oneTimeJobs,
              result.customers,
              result.customerAdditionalLocations
            );
          })
        )
      )
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.MAINTENANCE_JOB_LOAD_START),
      mergeMap((action: IMaintenanceJobLoadStart) =>
        dataProvider.getMaintenanceJobs(action.jobIds).pipe(
          timeout(45000),
          map((result) => {
            return actionCreators.loadMaintenanceJobsComplete(
              result.maintenanceJobs,
              result.customers,
              result.customerAdditionalLocations
            );
          })
        )
      )
    ),

  (action$: any, state: any) =>
    action$.pipe(
      ofType(actionTypes.CUSTOMER_LOAD_START),
      mergeMap((action: ICustomerLoadStart) => {
        const rootState = state.value as IRootState;
        return loadCustomersAndChildRecords(action.customerIds, rootState);
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.CUSTOMER_SELECTION_SEARCH_INPUT),
      debounceTime(500),
      map((action: ICustomerSelectionSearchInput) => {
        if ((action.searchTerm || "").trim().length > 0) {
          return actionCreators.customerSelectionSearchStart(
            action.searchTerm,
            action.includeQuickBooksRecords,
            action.includeGroups
          );
        } else {
          return actionCreators.customerSelectionSearchEmpty();
        }
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.BILLING_REPORT_START_LOAD),
      mergeMap((action: IBillingReportStartLoad) => {
        return dataProvider
          .loadBillingReport(
            parse(action.startDate),
            parse(action.endDate),
            action.category,
            action.customerName,
            action.crewIds,
            action.jobStatus
          )
          .pipe(
            timeout(30000),
            map((reportData) => {
              return actionCreators.billingReportCompleteLoad(
                reportData.customers
              );
            }),
            catchError((err) => {
              let errorMessage;
              if (err.name === "TimeoutError") {
                errorMessage =
                  "The report has timed out.  Please refresh this website to continue. If you continue to have problems, email support at crewcontrol@youraspire.com.";
              } else {
                errorMessage = getErrorMessageFromError(
                  err,
                  "An unknown error occurred while loading the report"
                );
              }

              return of(actionCreators.billingReportErrorLoad(errorMessage));
            })
          );
      })
    ),

  // Clear out one-time jobs so they'll be reloaded when the schedules are reloaded
  // and they'll have the correct data
  (action$: any, state: any) =>
    action$.pipe(
      ofType(
        createSpecificActionTypeName(
          formTypes.shiftSchedules,
          formActionTypes.completeSaving
        )
      ),
      switchMap((action: any) => {
        const shiftResult = action.payload as IShiftResult;
        const rootState = state.value as IRootState;

        const oneTimeJobsToClearIds = rootState.schedule.daySchedules.reduce(
          (acc, daySchedule) => {
            if (
              shiftResult.updatedDaySchedules.indexOf(daySchedule.id) !== -1
            ) {
              return [
                ...acc,
                ...daySchedule.jobInstances
                  .filter((ji) => !!ji.oneTimeJobId)
                  .map((ji) => ji.oneTimeJobId as string),
              ];
            } else {
              return acc;
            }
          },
          [] as Array<string>
        );

        return of(
          actionCreators.shiftScheduleCompleteAggregate(
            oneTimeJobsToClearIds,
            shiftResult
          )
        );
      })
    ),

  // TODO: test all customers being loaded, test none being loaded
  // loading customers
  (action$: any, state: any) =>
    action$.pipe(
      ofType(actionTypes.CUSTOMER_SELECTION_SEARCH_START),
      switchMap((action: ICustomerSelectionSearchStart) =>
        dataProvider
          .searchCustomers(
            action.searchTerm.trim(),
            undefined,
            undefined,
            undefined,
            action.includeQuickBooksRecords,
            false,
            undefined,
            action.includeGroups
          )
          .pipe(
            timeout(5000),
            retry(1),
            flatMap((result) => {
              const missingCustomerIds = result.customers
                .filter(
                  (customerSearchResult) =>
                    !(state.value as IRootState).customer.customers.find(
                      (realCustomer) =>
                        realCustomer.id === customerSearchResult.id
                    )
                )
                .filter((c) => !c.isQuickBooksCustomer)
                .map((c) => c.id);

              if (missingCustomerIds.length === 0) {
                return of({
                  matchedCustomers: result.customers,
                  matchedGroups: result.groups,
                  customers: [],
                  customerAdditionalLocations: [],
                  oneTimeJobIds: [],
                  maintenanceJobIds: [],
                });
              } else {
                return remoteDataProvider
                  .getCustomers({
                    customerIds: missingCustomerIds,
                  })
                  .pipe(
                    timeout(5000),
                    retry(1),
                    map((c) => ({
                      ...c,
                      matchedCustomers: result.customers,
                      matchedGroups: result.groups,
                    }))
                  );
              }
            }),
            flatMap((result) => {
              return of(
                actionCreators.loadCustomersComplete(
                  result.customers,
                  result.customerAdditionalLocations
                ),
                actionCreators.customerSelectionSearchComplete(
                  result.matchedCustomers,
                  result.matchedGroups
                )
              );
            }),
            catchError((err) => {
              console.error("error searching customers");
              console.log(err);
              return of(actionCreators.customerSelectionSearchError());
            })
          )
      )
    ),

  (action$: any) =>
    action$.pipe(
      ofType(
        createSpecificActionTypeName(
          formTypes.registerNewCompany,
          formActionTypes.completeSaving
        )
      ),
      tap(() => {
        TagManager.dataLayer({
          dataLayer: {
            category: getGoogelAnalyticsSignupCategory(),
            event: "registrationCompleted",
          },
        });
      }),
      map(() => actionCreators.null())
    ),

  (action$: any, state: any) =>
    action$.pipe(
      ofType(actionTypes.ONE_TIME_JOB_LOAD_COMPLETE),
      mergeMap((action: IOneTimeJobLoadComplete) => {
        return loadMissingCategories(
          action.oneTimeJobs,
          state.value as IRootState
        );
      })
    ),

  (action$: any, state: any) =>
    action$.pipe(
      ofType(actionTypes.MAINTENANCE_JOB_LOAD_COMPLETE),
      mergeMap((action: IMaintenanceJobLoadComplete) => {
        return loadMissingCategories(
          action.maintenanceJobs,
          state.value as IRootState
        );
      })
    ),

  (action$: any, state: any) =>
    action$.pipe(
      ofType(actionTypes.MANAGE_CUSTOMERS_SEARCH_START),
      mergeMap((action: IManageCustomersSearchStart) => {
        return handleManageCustomersSearch(action, state.value as IRootState);
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(actionTypes.PROJECT_SAVE),
      mergeMap((action: IProjectSaved) => {
        return handleJobInstanceBulkAdded(action);
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(
        createSpecificActionTypeName(
          formTypes.customer,
          formActionTypes.completeSaving
        )
      ),
      map((action: ICompleteSavingAction<{}, ISaveCustomerResponse>) => {
        return handleCustomerCompleteSaving(action);
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(
        createSpecificActionTypeName(
          formTypes.crewMember,
          formActionTypes.completeSaving
        )
      ),
      map((action: ICompleteSavingAction<{}, ISaveCrewMemberResponse>) => {
        return handleCrewMemberCompleteSaving(action);
      })
    ),

  (action$: any) =>
    action$.pipe(
      ofType(userSettingsActionCreators.setUserSetting.type),
      mergeMap((action: SetUserSettingActionType) => {
        return saveUserSettings(action);
      })
    ),
];

export default actionEpics;
