import React, { useCallback, useRef, useState } from "react";
import remoteDataProvider from "../../../services/remoteDataProvider";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import ModalFooter from "reactstrap/lib/ModalFooter";
import { useDispatch } from "react-redux";
import { finalize, timeout } from "rxjs/operators";
import SpinnerModalFooter from "./SpinnerModalFooter";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import constants from "../../../constants";
import { commonUiActionCreators } from "../../../modules/commonUi";
import { getSortedItemsV2 } from "../../../services/sortingService";
import { IInvitation } from "../../../models/IInvitation";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import ModalDataLoader from "./ModalDataLoader";
import ManageAdminUsersUserDelete from "./ManageAdminUsersUserDelete";
import ManageAdminUsersInvitedDelete from "./ManageAdminUsersInvitedDelete";
import ButtonDropdown from "reactstrap/lib/ButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { UserAccountRole } from "../../../enums/userAccountRole";
import ResponsiveTable from "../../../libraries/tableLayout/ResponsiveTable";
import ManageAdminUsersRoleSelection from "./ManageAdminUsersRoleSelection";
import { actionCreators } from "../../../modules/actionCreators";
import { ManageAdminUsersUserNameField } from "./ManageAdminUsersUserNameField";
import { useIsCurrentUserOwner } from "../../../hooks/useIsCurrentUserOwner";

interface ILoadData {
  invitations: Array<IInvitation>;
  userIsOwner: boolean;
}

const ManageAdminUsersInternal: React.FunctionComponent<{}> = () => {
  const dispatch = useDispatch();

  const userAccounts = useApplicationStateSelector(
    (s) => s.common.userAccounts
  );

  const currentUserId = useApplicationStateSelector(
    (s) => s.common.userAccountId
  );

  const [formWaiting, setFormWaiting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [invitations, setInvitations] = useState<Array<IInvitation>>([]);

  const currentUserIsOwner = useIsCurrentUserOwner();

  const loadData = useCallback(() => {
    return forkJoin({
      invitations: remoteDataProvider.getInvitations(),
    }).pipe(
      map(({ invitations }) => {
        return {
          invitations: invitations,
        } as ILoadData;
      })
    );
  }, []);

  const handleLoadedData = useCallback((result: ILoadData) => {
    updateInvitations(result.invitations);
  }, []);

  const closeForm = () => {
    if (!formWaiting) {
      dispatch(commonUiActionCreators.hideAdminUsersManageModal());
    }
  };

  const updateInvitations = (invs: Array<IInvitation>) => {
    setInvitations(invs.filter((i) => i.expired === false));
  };

  const reloadInvitations = () => {
    remoteDataProvider.getInvitations().subscribe((invs) => {
      updateInvitations(invs);
    });
  };

  const reloadUserAccounts = useCallback(() => {
    dispatch(actionCreators.updateInitialLoad());
  }, [dispatch]);

  const rows = [
    ...getSortedItemsV2(invitations, ["emailAddress"]).map((invitation) => ({
      id: invitation.id,
      testId: invitation.id,
      user: () => (
        <div style={{ overflowWrap: "break-word" }}>
          {invitation.emailAddress}{" "}
          <span className="font-weight-light">(invited)</span>
        </div>
      ),
      name: () => (
        <div style={{ overflowWrap: "break-word" }}>{invitation.name}</div>
      ),
      role: () => <></>,
      actions: () => (
        <ManageAdminUsersInvitedDelete
          currentUserIsTenantOwner={currentUserIsOwner}
          invitation={invitation}
          reloadInvitations={reloadInvitations}
          setErrorMessage={setErrorMessage}
          setFormWaiting={setFormWaiting}
        />
      ),
    })),
    ...getSortedItemsV2(userAccounts, ["emailAddress"]).map((userAccount) => ({
      id: userAccount.id,
      testId: userAccount.id,
      user: () => (
        <div
          style={{ overflowWrap: "break-word" }}
          className="mr-2"
          data-testid="emailCell"
        >
          {userAccount.emailAddress}
        </div>
      ),
      name: () => (
        <div className="mr-2">
          <ManageAdminUsersUserNameField
            reloadUserAccounts={reloadUserAccounts}
            userAccountId={userAccount.id}
            initialValue={userAccount.name}
          />
        </div>
      ),
      role: () => (
        <div className="mr-2" style={{ width: "150px" }}>
          <ManageAdminUsersRoleSelection
            currentUserIsTenantOwner={currentUserIsOwner}
            formWaiting={formWaiting}
            userAccount={userAccount}
            reloadUserAccounts={reloadUserAccounts}
            setErrorMessage={setErrorMessage}
            setFormWaiting={setFormWaiting}
            currentUserId={currentUserId}
          />
        </div>
      ),
      actions: () => (
        <ManageAdminUsersUserDelete
          currentUserIsAdmin={currentUserIsOwner}
          reloadUserAccounts={reloadUserAccounts}
          userAccount={userAccount}
          setErrorMessage={setErrorMessage}
          setFormWaiting={setFormWaiting}
        />
      ),
    })),
  ];

  return (
    <ModalDataLoader<ILoadData>
      errorMessage={
        "The manage admin users form was unable to open. Please check your Internet connection and try again."
      }
      onErrorAlertClose={() => closeForm()}
      loadData={loadData}
      onDataLoaded={handleLoadedData}
    >
      <Modal
        size="lg"
        isOpen={true}
        toggle={() => {
          closeForm();
        }}
        scrollable={true}
      >
        <ModalHeader
          toggle={() => {
            closeForm();
          }}
        >
          Manage Admin Users
        </ModalHeader>
        <ModalBody>
          <div>
            <InvitationForm
              formWaiting={formWaiting}
              reloadInvitations={reloadInvitations}
              setErrorMessage={setErrorMessage}
              setFormWaiting={setFormWaiting}
            />
            <div>
              <h5 className="mb-3">Current Users</h5>
              <ResponsiveTable
                rows={rows}
                tableClass="table-sm"
                columns={[
                  {
                    key: "user",
                    header: "User",
                    cell: "user",
                    width: "0",
                    style: {
                      verticalAlign: "middle",
                    },
                  },
                  {
                    key: "name",
                    header: "Name",
                    cell: "name",
                  },
                  {
                    key: "role",
                    header: "Role",
                    cell: "role",
                    width: "0",
                  },
                  {
                    key: "actions",
                    header: "",
                    cell: "actions",
                    width: "0",
                  },
                ]}
                renderMobile={({ row }) => (
                  <>
                    <div>
                      <div className="d-flex flex-wrap justify-content-between">
                        <div>{row.user()}</div>
                        <div>{row.actions()}</div>
                      </div>
                      <div className="d-flex mt-2">
                        <div style={{ flexGrow: 1 }}>{row.name()}</div>
                        <div>{row.role()}</div>
                      </div>
                    </div>
                    <hr className="my-4" />
                  </>
                )}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter style={{ justifyContent: "space-between" }}>
          <div>
            {formWaiting ? (
              <>
                <SpinnerModalFooter />
              </>
            ) : errorMessage ? (
              <div
                className={errorMessage ? "text-danger" : "text-success"}
                data-testid="invitationMessage"
              >
                {errorMessage ? errorMessage : ""}
              </div>
            ) : null}
          </div>
          <div>
            <button
              className="btn btn-primary"
              onClick={() => {
                closeForm();
              }}
              type="button"
              disabled={formWaiting}
            >
              Close
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </ModalDataLoader>
  );
};

function InvitationForm({
  reloadInvitations,
  setErrorMessage,
  setFormWaiting,
  formWaiting,
}: {
  reloadInvitations: () => void;
  setErrorMessage: (v: string) => void;
  setFormWaiting: (v: boolean) => void;
  formWaiting: boolean;
}) {
  const emailAddressElementRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const [emailAddress, setEmailAddress] = useState("");
  const [name, setName] = useState("");
  const [isInvitationDropDownOpen, setIsInvitationDropDownOpen] =
    useState(false);

  const inviteUser = (role: UserAccountRole) => {
    if (!formRef.current) {
      return;
    }

    if (formRef.current.reportValidity()) {
      setFormWaiting(true);
      setErrorMessage("");

      remoteDataProvider
        .createInvitation({ emailAddress, name, role })
        .pipe(
          timeout(10000),
          finalize(() => {
            setFormWaiting(false);
          })
        )
        .subscribe({
          next: () => {
            setEmailAddress("");
            setName("");
            reloadInvitations();
          },

          error: (error) => {
            setErrorMessage(
              getErrorMessageFromError(error, constants.unknownErrorMessage)
            );
          },
        });
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()} ref={formRef} className="mb-4">
      <label htmlFor="emailAddress">
        Enter name &amp; email address to invite a new admin user
      </label>
      <div className="form-row">
        <div className="col-12 col-lg-6 form-group">
          <input
            type="text"
            className="form-control"
            id="name"
            autoComplete="off"
            value={name}
            required={true}
            onChange={(e) => setName(e.currentTarget.value)}
            placeholder="Name"
            aria-label="Name"
          />
        </div>
        <div className="col-12 col-lg-6 form-group">
          <input
            type="email"
            className="form-control"
            id="emailAddress"
            autoComplete="off"
            ref={emailAddressElementRef}
            value={emailAddress}
            required={true}
            onChange={(e) => setEmailAddress(e.currentTarget.value)}
            placeholder="Email address"
            aria-label="Email address"
          />
        </div>
      </div>
      <div>
        <ButtonDropdown
          direction="down"
          isOpen={isInvitationDropDownOpen}
          toggle={() => setIsInvitationDropDownOpen(!isInvitationDropDownOpen)}
        >
          <button
            className="btn btn-secondary"
            type="button"
            disabled={formWaiting}
            onClick={() =>
              setIsInvitationDropDownOpen(!isInvitationDropDownOpen)
            }
          >
            Invite As
          </button>
          <DropdownToggle split color="secondary" />
          <DropdownMenu positionFixed={true} right={true}>
            <DropdownItem
              onClick={(e) => {
                e.preventDefault();
                inviteUser(UserAccountRole.administrator);
              }}
            >
              Administrator
            </DropdownItem>
            <DropdownItem
              onClick={(e) => {
                e.preventDefault();
                inviteUser(UserAccountRole.scheduler);
              }}
            >
              Scheduler
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    </form>
  );
}

const ManageAdminUsers: React.FunctionComponent<{}> = () => {
  const showAdminUsersModal = useApplicationStateSelector(
    (s) => s.commonUi.showAdminUsersModal
  );

  return showAdminUsersModal ? <ManageAdminUsersInternal /> : null;
};

export default ManageAdminUsers;
