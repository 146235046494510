import { CompletedWorkReportColumn } from "../../../../enums/completedWorkReportColumn";
import { SortDirection } from "../../../../enums/sortDirection";
import { UserSettingsType } from "../../../../enums/userSettingsType";
import {
  BillingReportJobType,
  IBillingReportCustomer,
  IBillingReportJobInstance,
} from "../../../../models/IBillingReport";
import { GetUserSettingsType } from "../../../../services/userSettingsService";
import {
  ICustomerWithLocationName,
  IDateRange,
  ISort,
  SortProperty,
} from "./ReportContents.model";

export function isColumnVisible(
  visibleColumns: CompletedWorkReportColumn[],
  column: CompletedWorkReportColumn
) {
  return visibleColumns.includes(column);
}

export function getCustomerNameColSpan(
  columnsToShow: Array<CompletedWorkReportColumn>,
  dateRanges: IDateRange[]
): number {
  let colSpan = 1;

  const columnsToUpdateColSpanByOne = [
    CompletedWorkReportColumn.Frequency,
    CompletedWorkReportColumn.Notes,
    CompletedWorkReportColumn.Billing,
    CompletedWorkReportColumn.Services,
    CompletedWorkReportColumn.VisitCount,
  ];

  columnsToUpdateColSpanByOne.forEach((column) => {
    if (isColumnVisible(columnsToShow, column)) {
      colSpan++;
    }
  });

  if (
    isColumnVisible(columnsToShow, CompletedWorkReportColumn.SpecificVisits)
  ) {
    colSpan += dateRanges.length;
  }

  return colSpan;
}

export function getVarianceForCustomer(
  customer: IBillingReportCustomer,
  customers: Array<IBillingReportCustomer>
) {
  const jobInstancesForCustomer = customers
    .filter((c) => c.id === customer.id)
    .reduce(
      (ji: Array<IBillingReportJobInstance>, c: IBillingReportCustomer) => [
        ...ji,
        ...c.jobInstances,
      ],
      [] as Array<IBillingReportJobInstance>
    );

  return jobInstancesForCustomer.reduce((sum: number | null, ji) => {
    if (
      typeof sum === "number" &&
      typeof ji.estimatedManHours === "number" &&
      typeof ji.actualManHours === "number"
    ) {
      const jobVariance = ji.estimatedManHours - ji.actualManHours;
      return (sum || 0) + jobVariance;
    } else {
      return null;
    }
  }, 0);
}

export function getTotalManHoursForCustomer(
  customer: IBillingReportCustomer,
  customers: Array<IBillingReportCustomer>
) {
  const jobInstancesForCustomer = customers
    .filter((c) => c.id === customer.id)
    .reduce(
      (ji: Array<IBillingReportJobInstance>, c: IBillingReportCustomer) => [
        ...ji,
        ...c.jobInstances,
      ],
      [] as Array<IBillingReportJobInstance>
    );

  return jobInstancesForCustomer.reduce((sum: number | null, ji) => {
    if (typeof sum === "number" && typeof ji.actualManHours === "number") {
      return (sum || 0) + ji.actualManHours;
    } else {
      return null;
    }
  }, 0);
}

export function getTotalGrossRevenueForCustomer(
  customer: IBillingReportCustomer,
  customers: Array<IBillingReportCustomer>
) {
  const combinedCustomers = customers.filter((c) => c.id === customer.id);

  return combinedCustomers.reduce((sum: number | null, c) => {
    const revenueForCustomer = getTotalGrossRevenue(c);
    if (typeof revenueForCustomer === "number") {
      return (sum || 0) + revenueForCustomer;
    } else {
      return sum;
    }
  }, null);
}

export function getVarianceForJob(customer: IBillingReportCustomer) {
  if (customer.type === BillingReportJobType.Project) {
    const totalManHours = getTotalManHours(customer);
    if (
      typeof customer.projectEstimatedManHours === "number" &&
      typeof totalManHours === "number"
    ) {
      return customer.projectEstimatedManHours - totalManHours;
    } else {
      return null;
    }
  }

  return customer.jobInstances.reduce((sum: number | null, ji) => {
    if (
      typeof ji.estimatedManHours === "number" &&
      typeof ji.actualManHours === "number"
    ) {
      const jobVariance = ji.estimatedManHours - ji.actualManHours;
      return (sum || 0) + jobVariance;
    } else {
      return sum;
    }
  }, null);
}

export function getRevenuePerManHour(
  customer: IBillingReportCustomer,
  sortedCustomers: Array<IBillingReportCustomer>
) {
  const grossRevenue = getTotalGrossRevenueForCustomer(
    customer,
    sortedCustomers
  );
  const totalManHours = getTotalManHoursForCustomer(customer, sortedCustomers);

  if (grossRevenue === null || totalManHours === null) {
    return null;
  }

  return (grossRevenue as number) / (totalManHours as number);
}

export function getTotalManHours(customer: IBillingReportCustomer) {
  return customer.jobInstances.reduce((sum: number | null, ji) => {
    if (ji.actualManHours) {
      return (sum || 0) + ji.actualManHours;
    } else {
      return sum;
    }
  }, null);
}

export function getTotalGrossRevenue(customer: IBillingReportCustomer) {
  if (customer.type === BillingReportJobType.Project) {
    return customer.projectGrossRevenue;
  }

  return customer.jobInstances.reduce((sum: number | null, ji) => {
    if (ji.grossRevenuePerVisit) {
      return (sum || 0) + ji.grossRevenuePerVisit;
    } else {
      return sum;
    }
  }, null);
}

export function getVarianceElement(variance: number | null) {
  if (variance === null) {
    return null;
  } else if (variance < 0) {
    return (
      <span className="text-danger">{roundToOneDecimalPlace(variance)}</span>
    );
  } else if (variance > 0) {
    return (
      <span className="text-success">{roundToOneDecimalPlace(variance)}</span>
    );
  } else {
    return <span>0</span>;
  }
}

export function compareCustomersByName(
  a: ICustomerWithLocationName,
  b: ICustomerWithLocationName
) {
  if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
    return -1;
  }

  if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
    return 1;
  }

  return 0;
}

export function compareCustomersByVisitCount(
  a: ICustomerWithLocationName,
  b: ICustomerWithLocationName
) {
  if (a.jobInstances.length < b.jobInstances.length) {
    return -1;
  }

  if (a.jobInstances.length > b.jobInstances.length) {
    return 1;
  }

  return 0;
}

export function compareCustomersByLocationName(
  a: ICustomerWithLocationName,
  b: ICustomerWithLocationName
) {
  if (a.locationName.toLocaleLowerCase() < b.locationName.toLocaleLowerCase()) {
    return -1;
  }

  if (a.locationName.toLocaleLowerCase() > b.locationName.toLocaleLowerCase()) {
    return 1;
  }

  return 0;
}

export function compareCustomersByTotalVariance(
  a: IBillingReportCustomer,
  b: IBillingReportCustomer,
  customers: Array<IBillingReportCustomer>
) {
  // TODO: Cache?
  const aTotalVariance = getVarianceForCustomer(a, customers);
  const bTotalVariance = getVarianceForCustomer(b, customers);

  if (
    (aTotalVariance === null && bTotalVariance !== null) ||
    (aTotalVariance !== null &&
      bTotalVariance !== null &&
      aTotalVariance < bTotalVariance)
  ) {
    return -1;
  }

  if (
    (aTotalVariance !== null && bTotalVariance === null) ||
    (aTotalVariance !== null &&
      bTotalVariance !== null &&
      aTotalVariance > bTotalVariance)
  ) {
    return 1;
  }

  return 0;
}

export function compareCustomersByTotalManHours(
  a: IBillingReportCustomer,
  b: IBillingReportCustomer,
  customers: Array<IBillingReportCustomer>
) {
  // TODO: Cache?
  const aTotalVariance = getTotalManHoursForCustomer(a, customers);
  const bTotalVariance = getTotalManHoursForCustomer(b, customers);

  if (
    (aTotalVariance === null && bTotalVariance !== null) ||
    (aTotalVariance !== null &&
      bTotalVariance !== null &&
      aTotalVariance < bTotalVariance)
  ) {
    return -1;
  }

  if (
    (aTotalVariance !== null && bTotalVariance === null) ||
    (aTotalVariance !== null &&
      bTotalVariance !== null &&
      aTotalVariance > bTotalVariance)
  ) {
    return 1;
  }

  return 0;
}

export function compareCustomersByGrossRevenue(
  a: IBillingReportCustomer,
  b: IBillingReportCustomer,
  customers: Array<IBillingReportCustomer>
) {
  // TODO: Cache?
  const aTotalGross = getTotalGrossRevenueForCustomer(a, customers);
  const bTotalGross = getTotalGrossRevenueForCustomer(b, customers);

  if (
    (aTotalGross === null && bTotalGross !== null) ||
    (aTotalGross !== null && bTotalGross !== null && aTotalGross < bTotalGross)
  ) {
    return -1;
  }

  if (
    (aTotalGross !== null && bTotalGross === null) ||
    (aTotalGross !== null && bTotalGross !== null && aTotalGross > bTotalGross)
  ) {
    return 1;
  }

  return 0;
}

export function compareCustomersByRevenuePerManHour(
  customerA: IBillingReportCustomer,
  customerB: IBillingReportCustomer,
  customers: Array<IBillingReportCustomer>
) {
  // TODO: Cache?
  const aTotalGross = getRevenuePerManHour(customerA, customers);
  const bTotalGross = getRevenuePerManHour(customerB, customers);

  if (
    (aTotalGross === null && bTotalGross !== null) ||
    (aTotalGross !== null && bTotalGross !== null && aTotalGross < bTotalGross)
  ) {
    return -1;
  }

  if (
    (aTotalGross !== null && bTotalGross === null) ||
    (aTotalGross !== null && bTotalGross !== null && aTotalGross > bTotalGross)
  ) {
    return 1;
  }

  return 0;
}

export function sortCustomers(
  sort: ISort,
  customers: Array<ICustomerWithLocationName>
) {
  return customers.sort((a, b) => {
    let comparison: number = 0;
    if (sort.property === SortProperty.CustomerName) {
      comparison = compareCustomersByName(a, b);
    } else if (sort.property === SortProperty.TotalVariance) {
      comparison = compareCustomersByTotalVariance(a, b, customers);
    } else if (sort.property === SortProperty.ManHoursWorked) {
      comparison = compareCustomersByTotalManHours(a, b, customers);
    } else if (sort.property === SortProperty.GrossRevenue) {
      comparison = compareCustomersByGrossRevenue(a, b, customers);
    } else if (sort.property === SortProperty.RevenuePerManHour) {
      comparison = compareCustomersByRevenuePerManHour(a, b, customers);
    } else if (sort.property === SortProperty.VisitCount) {
      comparison = compareCustomersByVisitCount(a, b);
    }

    // Break ties by sorting by location name
    if (comparison === 0) {
      comparison = compareCustomersByLocationName(a, b);
    }

    return sort.direction === SortDirection.Ascending
      ? comparison
      : -1 * comparison;
  });
}

export function getDefaultSort(getUserSettings: GetUserSettingsType) {
  let sort: ISort;
  const cachedSortValues = getUserSettings<ISort>(
    UserSettingsType.completedWorkSort
  );
  if (cachedSortValues) {
    sort = cachedSortValues;
  } else {
    sort = {
      property: SortProperty.CustomerName,
      direction: SortDirection.Ascending,
    };
  }
  return sort;
}

export function roundToOneDecimalPlace(input: number | null) {
  if (input === null) {
    return null;
  }

  return Math.round(input * 10) / 10;
}
