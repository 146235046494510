import {
  ITemplatedMessage,
  calculatedContactMethodInputType,
  calculatedShowDayOfServiceInputType,
} from "../../../models/IITemplatedMessage";
import dateService from "../../../services/dateService";
import {
  CompanyNamePlaceholder,
  ContactMethodType,
  ContactMethodTypeSelection,
  DayScheduledPlaceholder,
  EmailAddressPlaceholder,
  PhoneNumberPlaceholder,
  ArrivalTimePlaceholder,
  ShowDaySelection,
} from "./CustomerNotificationsTemplatedMessage.options";

// If this logic is updated, need to update "GetMessageWithPlaceholdersReplaced" in API
export function buildMessage({
  selectedTemplate,
  hasActiveTimeBasedCrews,
  tenantName,
  editedVariables,
  hardcodedVariables,
}: {
  selectedTemplate: ITemplatedMessage | undefined;
  hasActiveTimeBasedCrews: boolean;
  tenantName: string | null;
  editedVariables: { [key: string]: string };
  hardcodedVariables: { [key: string]: string } | undefined;
}): string {
  if (!selectedTemplate) {
    return "";
  }

  const combinedVariables = {
    ...editedVariables,
    ...(hardcodedVariables ?? {}),
  };

  let message = replaceKnownPlaceholders(
    selectedTemplate,
    hasActiveTimeBasedCrews,
    tenantName
  );
  selectedTemplate.variables.forEach((variableDefinition) => {
    let variableValue = combinedVariables[variableDefinition.key];

    if (variableDefinition.inputType === calculatedContactMethodInputType) {
      if (
        combinedVariables[ContactMethodTypeSelection] ===
        ContactMethodType.email.toString()
      ) {
        variableValue = combinedVariables[EmailAddressPlaceholder] ?? "";
      } else {
        variableValue = combinedVariables[PhoneNumberPlaceholder] ?? "";
      }
    } else if (
      variableDefinition.inputType === calculatedShowDayOfServiceInputType
    ) {
      if (combinedVariables[ShowDaySelection] === "false") {
        message = message.replace(ArrivalTimePlaceholder, "");
        variableValue = "";
      } else if (combinedVariables[DayScheduledPlaceholder]) {
        variableValue = " " + combinedVariables[DayScheduledPlaceholder];
      } else {
        message = message.replace(ArrivalTimePlaceholder, "");
        variableValue = "";
      }
    }

    const isSet = variableValue && variableValue.trim();
    if (isSet || variableDefinition.optional) {
      if (variableDefinition) {
        let value = variableValue ?? "";

        if (variableDefinition.inputType === "date" && isSet) {
          value = dateService.formatDateForDisplay(value);
        }

        if (
          isSet &&
          variableDefinition.addLeadingWhitespace &&
          !value.startsWith(" ")
        ) {
          value = " " + value;
        }

        message = message.replace(variableDefinition.key, value);
      }
    }
  });

  return message.trim();
}

export function replaceKnownPlaceholders(
  selectedTemplate: ITemplatedMessage,
  hasActiveTimeBasedCrews: boolean,
  tenantName: string | null
) {
  let message = selectedTemplate.message.replace(
    CompanyNamePlaceholder,
    tenantName ?? ""
  );

  if (!hasActiveTimeBasedCrews) {
    message = message.replace(ArrivalTimePlaceholder, "");
  }

  return message;
}
