import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { ModalFooter } from "reactstrap/lib";
import { finalize, map, timeout } from "rxjs/operators";
import Spinner from "../../../containers/app/components/Spinner";
import { actionCreators } from "../../../modules/actionCreators";
import { logError } from "../../../services/errorLogger";
import { fullStoryTrack } from "../../../services/fullStoryService";
import { getErrorMessageFromError } from "../../../services/httpErrorHandler";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { SubscriptionCancelReason } from "../enums/subscriptionCancelReason";
import subscriptionDataProvider from "../services/subscriptionDataProvider";
import { ISubscriptionDetails } from "../models/ISubscriptionDetails";
import { ModalDataLoaderStateless } from "../../../containers/app/components/ModalDataLoaderStateless";
import dateService from "../../../services/dateService";
import { formatCurrency } from "../../../services/currencyFormatter";
import { PortalPage } from "../enums/portalPage";
import { SubscriptionType } from "../../../enums/subscriptionType";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SubscriptionPlanDetails } from "./SubscriptionPlanDetails";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";

type ScreenModes = "initial" | "unsubscribe" | "unsubscribeConfirm";

export function ManageSubscription({ onClose }: { onClose(): void }) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [mode, setMode] = useState<ScreenModes>("initial");

  const [loadingSubscriptionDetails, setLoadingSubscriptionDetails] =
    useState(false);
  const [errorLoadingSubscriptionDetails, setErrorLoadingSubscriptionDetails] =
    useState(false);
  const [subscriptionDetails, setSubscriptionDetails] =
    useState<ISubscriptionDetails | null>(null);

  useEffect(() => {
    setLoadingSubscriptionDetails(true);
    const subscription = subscriptionDataProvider
      .getSubscriptionDetails()
      .pipe(
        finalize(() => {
          setLoadingSubscriptionDetails(false);
        })
      )
      .subscribe({
        next: (result) => {
          setSubscriptionDetails(result);
        },

        error: () => {
          setErrorLoadingSubscriptionDetails(true);
        },
      });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, []);

  let content: JSX.Element;
  if (mode === "unsubscribe") {
    content =
      subscriptionDetails !== null ? (
        <UnsubscribeScreen
          setMode={setMode}
          setShowSpinner={setShowSpinner}
          subscriptionDetails={subscriptionDetails}
        />
      ) : (
        <></>
      );
  } else if (mode === "unsubscribeConfirm") {
    content = <UnsubscribeConfirm />;
  } else {
    content = (
      <InitialScreen
        setMode={setMode}
        setShowSpinner={setShowSpinner}
        subscriptionDetails={subscriptionDetails}
      />
    );
  }

  return (
    <>
      <ModalDataLoaderStateless
        loadingData={loadingSubscriptionDetails}
        errorLoadingData={errorLoadingSubscriptionDetails}
        onErrorAlertClose={onClose}
        errorMessage="The subscription details were unable to load. Please try again."
      >
        <Modal isOpen>
          <ModalHeader>Manage Subscription</ModalHeader>
          <ModalBody>
            {showSpinner ? <Spinner /> : null}

            {content}
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => onClose()}
            >
              Close
            </button>
          </ModalFooter>
        </Modal>
      </ModalDataLoaderStateless>
    </>
  );
}

function InitialScreen({
  setShowSpinner,
  setMode,
  subscriptionDetails,
}: {
  setShowSpinner(newValue: boolean): void;
  setMode(newValue: ScreenModes): void;
  subscriptionDetails: ISubscriptionDetails | null;
}) {
  return (
    <>
      <TechnicianSubscriptionUnderusedWarning
        subscriptionDetails={subscriptionDetails}
      />

      {subscriptionDetails !== null ? (
        <>
          <div className="mb-3">
            <SubscriptionPlanDetails
              subscriptionDetails={subscriptionDetails}
            />
          </div>

          {typeof subscriptionDetails.nextPaymentDate === "string" &&
          typeof subscriptionDetails.nextPaymentAmount === "number" ? (
            <div className="mb-3" data-testid="nextPaymentContainer">
              <div className="font-weight-bold">Next Payment</div>
              <div data-testid="nextPaymentDate">
                Date:{" "}
                {dateService.formatDateForDisplay(
                  subscriptionDetails.nextPaymentDate
                )}
              </div>
              <div data-testid="nextPaymentAmount">
                Amount: {formatCurrency(subscriptionDetails.nextPaymentAmount)}
              </div>
            </div>
          ) : null}
        </>
      ) : null}

      <PortalButton
        text="Update plan"
        setShowSpinner={setShowSpinner}
        page={PortalPage.UpdatePlan}
      />

      <PortalButton
        text="Manage payment method"
        setShowSpinner={setShowSpinner}
        page={PortalPage.UpdatePaymentMethod}
      />

      <PortalButton
        text="View billing history"
        setShowSpinner={setShowSpinner}
      />

      <SubscriptionButton
        onClick={() => {
          setMode("unsubscribe");
          fullStoryTrack("Subscription Unsubscribe Start");
        }}
        text="Unsubscribe"
      />
    </>
  );
}

function TechnicianSubscriptionUnderusedWarning({
  subscriptionDetails,
}: {
  subscriptionDetails: ISubscriptionDetails | null;
}) {
  if (!subscriptionDetails) {
    return null;
  }

  return (
    <>
      {subscriptionDetails.subscriptionType === SubscriptionType.technician &&
      subscriptionDetails.activeCrewCount < 2 ? (
        <div
          data-testid="underusedSubscriptionWarning"
          className="alert alert-warning p-2"
        >
          <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
          Your plan includes 2 crews but you are using only 1 crew.
        </div>
      ) : null}
    </>
  );
}

function PortalButton({
  text,
  setShowSpinner,
  page,
}: {
  text: string;
  setShowSpinner(newValue: boolean): void;
  page?: PortalPage;
}) {
  const [errorLoadingPortalLink, setErrorLoadingPortalLink] = useState(false);

  return (
    <>
      <SubscriptionButton
        onClick={() => {
          setShowSpinner(true);
          setErrorLoadingPortalLink(false);
          fullStoryTrack("Subscription Update Clicked");
          subscriptionDataProvider
            .createSubscriptionPortalSession({ page })
            .pipe(timeout(15000))
            .subscribe({
              next: (portalUrl) => (window.location.href = portalUrl),

              error: () => {
                setShowSpinner(false);
                setErrorLoadingPortalLink(true);
                logError(`error creating session portal`);
              },
            });
        }}
        text={text}
      />
      {errorLoadingPortalLink ? (
        <div className="text-danger">
          There was an error taking you to the subscription management page.
          Please try again or contact support if you have any questions.
        </div>
      ) : null}
    </>
  );
}

function SubscriptionButton({
  onClick,
  text,
}: {
  onClick: () => void;
  text: string;
}) {
  return (
    <div className="mt-3">
      <button
        className="btn btn-secondary btn-sm"
        type="button"
        onClick={onClick}
      >
        {text}
      </button>
    </div>
  );
}

function UnsubscribeScreen({
  setMode,
  setShowSpinner,
  subscriptionDetails,
}: {
  setShowSpinner(newValue: boolean): void;
  setMode(newValue: ScreenModes): void;
  subscriptionDetails: ISubscriptionDetails;
}) {
  const [reason, setReason] = useState("");
  const [details, setDetails] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const featureFlags = useApplicationStateSelector(
    (s) => s.common.featureFlags
  );

  const parsedReason = parseInt(reason);
  const showDetails =
    parsedReason === SubscriptionCancelReason.NotUsedEnough ||
    parsedReason === SubscriptionCancelReason.MissingFeatures ||
    parsedReason === SubscriptionCancelReason.Switching ||
    parsedReason === SubscriptionCancelReason.Other;

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          fullStoryTrack("Subscription Unsubscribe Complete");

          setErrorMessage("");
          setShowSpinner(true);
          subscriptionDataProvider
            .cancelSubscription({
              reason: parseInt(reason),
              details,
            })
            .subscribe({
              next: () => {
                setShowSpinner(false);

                remoteDataProvider
                  .getInitialLoad()
                  .pipe(
                    map((initialLoad) =>
                      actionCreators.completeInitialLoad(initialLoad)
                    )
                  )
                  .subscribe({ next: (action) => dispatch(action) });
                setMode("unsubscribeConfirm");
              },

              error: (err) => {
                setShowSpinner(false);

                setErrorMessage(getErrorMessageFromError(err));
              },
            });
        }}
      >
        <div className="mb-3">
          <h3>We're sad to see you go.</h3>
        </div>
        <div className="mb-3" data-testid="keepUsingUntil">
          You can keep using Crew Control until{" "}
          {dateService.formatDateForDisplay(subscriptionDetails.periodEndDate)}.
        </div>
        <div className="mb-3">
          Please let us know the reason you're leaving. Every bit of feedback
          helps!
        </div>
        <div className="form-group">
          <ReasonOption
            optionValue={SubscriptionCancelReason.Seasonal}
            text="My business is seasonal"
            currentValue={reason}
            setCurrentValue={setReason}
          />
          <ReasonOption
            optionValue={SubscriptionCancelReason.NotUsedEnough}
            text="Didn't use the product enough"
            currentValue={reason}
            setCurrentValue={setReason}
          />
          <ReasonOption
            optionValue={SubscriptionCancelReason.MissingFeatures}
            text="Missing features/functionality I need to run my business"
            currentValue={reason}
            setCurrentValue={setReason}
          />
          <ReasonOption
            optionValue={SubscriptionCancelReason.Switching}
            text="Switching to another product"
            currentValue={reason}
            setCurrentValue={setReason}
          />
          <ReasonOption
            optionValue={SubscriptionCancelReason.Other}
            text="Other"
            currentValue={reason}
            setCurrentValue={setReason}
          />
        </div>
        {showDetails ? (
          <div className="form-group">
            <label htmlFor="details" className="required">
              Details
            </label>
            <textarea
              id="details"
              required
              className="form-control"
              value={details}
              onChange={(e) => setDetails(e.currentTarget.value)}
              maxLength={500}
              rows={4}
            />
          </div>
        ) : null}

        {featureFlags?.pricingUpdates ? (
          <div className="my-2 text-danger" data-testid="dataArchivedMessage">
            Your data cannot be recovered 30 days after your subscription has
            ended.
          </div>
        ) : null}

        {typeof subscriptionDetails.availableCredit === "number" ? (
          <div className="my-2 text-danger" data-testid="creditAlert">
            You still have {formatCurrency(subscriptionDetails.availableCredit)}{" "}
            in credits on your account. Are you sure you'd like to unsubscribe?
          </div>
        ) : null}

        <div>
          <button type="submit" className="btn btn-secondary btn-sm">
            Confirm Unsubscribe
          </button>
        </div>
        {errorMessage ? (
          <div className="text-danger">{errorMessage}</div>
        ) : null}
      </form>
    </>
  );
}

export function UnsubscribeConfirm() {
  const featureFlags = useApplicationStateSelector(
    (s) => s.common.featureFlags
  );

  return (
    <>
      <h6>Unsubscribe Complete</h6>
      <div>
        {featureFlags?.pricingUpdates
          ? "Your data will be available for 30 days after your subscription ends."
          : "We're sorry to see you go. We will keep your data around if you'd like to come back!"}
      </div>
    </>
  );
}

function ReasonOption({
  text,
  optionValue,
  currentValue,
  setCurrentValue,
}: {
  text: string;
  optionValue: SubscriptionCancelReason;
  currentValue: string;
  setCurrentValue(newValue: string): void;
}) {
  const elementId = `reason_${optionValue}`;
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="radio"
        name="reason"
        id={elementId}
        value={optionValue.toString()}
        checked={currentValue === optionValue.toString()}
        onChange={(e) => {
          setCurrentValue(e.currentTarget.value);
        }}
        required
      />
      <label className="form-check-label" htmlFor={elementId}>
        {text}
      </label>
    </div>
  );
}
