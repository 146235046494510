import { useRef, useState } from "react";
import { timeout } from "rxjs/operators";
import { ICompanyLogo } from "../../../models/ICompanyProfile";
import { resizeAndUploadImageWithoutThumbnails } from "../../../services/fileUpload";

interface IProps {
  onFileUploaded: (logo: ICompanyLogo) => void;
  setFileUploading: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompanyProfileFormLogoSelect: React.FunctionComponent<IProps> = ({
  onFileUploaded,
  setFileUploading,
}) => {
  const fileElement = useRef<HTMLInputElement>(null);
  const [error, setError] = useState("");

  return (
    <>
      <div className="custom-file">
        <input
          type="file"
          accept="image/png, image/jpeg"
          className="custom-file-input"
          ref={fileElement}
          id="fileInput"
          data-testid="newFile"
          multiple={false}
          onChange={() => {
            const fileList = (fileElement.current as any).files as FileList;
            if (fileList && fileList.length > 0) {
              Array.from(fileList).forEach((file) => {
                if (file.size > 1024 * 1024 * 15) {
                  setError("The file cannot be larger than 15MB");
                  return;
                }

                if (!isFileValid(file)) {
                  setError("Only image files can be uploaded");
                  return;
                }

                setFileUploading(true);

                resizeAndUploadImageWithoutThumbnails(file, 300, 125)
                  .pipe(timeout(60000))
                  .subscribe({
                    next: (result) => {
                      setFileUploading(false);
                      onFileUploaded({
                        path: result.imagePath,
                        height: result.actualHeight,
                        width: result.actualWidth,
                      });
                    },

                    error: () => {
                      setFileUploading(false);
                    },
                  });
              });
            }
          }}
        />
        <label className="custom-file-label" htmlFor="fileInput">
          Select a logo
        </label>
      </div>
      {error ? <div className="text-danger">{error}</div> : null}
    </>
  );
};

export default CompanyProfileFormLogoSelect;

function isFileValid(file: File) {
  if (file.type.toLowerCase().indexOf("image") !== -1) {
    return true;
  }

  return false;
}
