import React from "react";
import TableColumn, {
  CellFunction,
  ReactNodeFn,
  TableDisplayType,
} from "./TableColumn";

export function getCellContents<T>(
  row: T,
  column: TableColumn<T>,
  displayType: TableDisplayType,
  rowIndex: number
): React.ReactNode {
  if (column.hidden === true) {
    return null;
  } else if (typeof column.hidden === "function" && column.hidden({ row })) {
    return null;
  }

  // Typescript unable to infer types so have to do some casting
  const key = column.cell as unknown as keyof T | CellFunction<T>;
  if (typeof key === "function") {
    return key({ row, displayType, index: rowIndex });
  }

  const fn = row[key] as unknown as ReactNodeFn | string;

  return typeof fn === "function" ? fn() : fn;
}
