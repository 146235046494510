import React, { useState } from "react";
import SortColumn from "../../components/SortColumn";
import { SortDirection } from "../../../../enums/sortDirection";
import { getSortedItemsV2 } from "../../../../services/sortingService";
import {
  GetUserSettingsType,
  useUserSettings,
} from "../../../../services/userSettingsService";
import { UserSettingsType } from "../../../../enums/userSettingsType";
import CustomersChildEntityListingAddButtons, {
  IAddButton,
} from "./CustomersChildEntityListingAddButtons";
import { CustomersChildEntityListRowButtons } from "./CustomersChildEntityListRowButtons";
import TableColumn from "../../../../libraries/tableLayout/TableColumn";
import { getCellContents } from "../../../../libraries/tableLayout/getCellContents";
import getCellHeader from "../../../../libraries/tableLayout/getHeaderContents";
import ResponsiveTable, {
  Breakpoint,
} from "../../../../libraries/tableLayout/ResponsiveTable";
import { ResponsiveTableMobileCard } from "../../../../libraries/tableLayout/ResponsiveTableMobileCard";
import { useIsResolution } from "../../../../hooks/useIsResolution";

interface IProps<TListingType, TSortColumn> {
  addButtons: Array<IAddButton>;
  items: Array<TListingType>;
  noItemsMessage: string;
  columns: Array<ChildEntityListColumn<TListingType, TSortColumn>>;
  sortColumnSettingsKey: UserSettingsType;
  filters?: React.ReactNode;

  defaultSortColumn?: TSortColumn;
  defaultSortDirection?: SortDirection;

  mobileSortColumn?: TSortColumn;
  mobileSortDirection?: SortDirection;

  onEditClick?: (item: TListingType) => void;
  onDeleteClick?: (item: TListingType) => void;
  hideRowButtons?: boolean;
  breakpoint?: Breakpoint;
}

type ChildEntityListColumn<TListingType, TSortColumn> =
  TableColumn<TListingType> & {
    getSortableValue?: (item: TListingType) => string;
    sortable?: boolean;
    sortColumn?: TSortColumn;
    headerSortLinkTestId?: string;
  };

interface ISortColumn<TSortColumn> {
  column: TSortColumn;
  direction: SortDirection;
}

function getDefaultSortColumn<TSortColumn>(
  sortColumnSettingsKey: UserSettingsType,
  getUserSettings: GetUserSettingsType
): ISortColumn<TSortColumn> | null {
  const savedSetting = getUserSettings<ISortColumn<TSortColumn>>(
    sortColumnSettingsKey
  );
  return savedSetting;
}

export default function CustomersChildEntityListing<
  TListingType extends { id: string },
  TSortColumn
>(props: IProps<TListingType, TSortColumn>) {
  const {
    addButtons,
    onEditClick,
    onDeleteClick,
    items,
    columns: sourceColumns,
    noItemsMessage,
    sortColumnSettingsKey,
    filters,
    defaultSortColumn,
    defaultSortDirection,
    mobileSortColumn,
    mobileSortDirection,
    hideRowButtons,
    breakpoint = "md",
  } = props;
  const visibleColumns = sourceColumns.filter((c) => !c.hidden);

  const isDesktopView = useIsResolution(breakpoint);

  if (!hideRowButtons) {
    visibleColumns.push({
      header: "",
      cell: ({ row, displayType }) => (
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: displayType === "desktop" ? "flex-end" : undefined,
          }}
        >
          <CustomersChildEntityListRowButtons
            row={row}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
          />
        </div>
      ),
      key: "buttons",
      isButtonCell: true,
    });
  }

  const { getUserSettings, setUserSettings } = useUserSettings();

  const [currentSortColumn, setCurrentSortColumn] =
    useState<TSortColumn | null>(
      getDefaultSortColumn<TSortColumn>(sortColumnSettingsKey, getUserSettings)
        ?.column ??
        defaultSortColumn ??
        null
    );
  const [currentSortDirection, setCurrentSortDirection] =
    useState<SortDirection>(
      getDefaultSortColumn<TSortColumn>(sortColumnSettingsKey, getUserSettings)
        ?.direction ??
        defaultSortDirection ??
        SortDirection.Ascending
    );

  let sortColumn = currentSortColumn;
  let sortDirection = currentSortDirection;
  if (!isDesktopView) {
    if (mobileSortColumn !== undefined) {
      sortColumn = mobileSortColumn;
    }

    if (mobileSortDirection !== undefined) {
      sortDirection = mobileSortDirection;
    }
  }

  let sortedItems = items;
  if (sortColumn !== null) {
    const columnBeingSorted = visibleColumns.find(
      (c) => c.sortColumn === sortColumn
    );
    if (columnBeingSorted) {
      sortedItems = getSortedItemsV2(
        sortedItems,
        [
          (rowObject) => {
            let sortableValue: string = "";
            if (columnBeingSorted.getSortableValue) {
              sortableValue = columnBeingSorted.getSortableValue(rowObject);
            } else {
              const value = getCellContents(
                rowObject,
                columnBeingSorted,
                "desktop",
                0
              );
              if (typeof value === "string") {
                sortableValue = value;
              }
            }

            return sortableValue;
          },
        ],
        sortDirection === SortDirection.Descending
      );
    }
  }

  return (
    <React.Fragment>
      <div
        className="d-flex flex-wrap"
        style={{
          gap: "10px",
          justifyContent: "space-between",
          marginBottom: "10px",
          alignItems: "baseline",
        }}
      >
        <div className="flex-fill">{filters ?? <React.Fragment />}</div>
        <CustomersChildEntityListingAddButtons addButtons={addButtons} />
      </div>

      <ResponsiveTable
        rows={sortedItems}
        tableClass="table-striped"
        breakpoint={breakpoint}
        columns={visibleColumns.map((c) => ({
          ...c,
          headerClassName: "text-nowrap " + (c.headerClassName ?? ""),
          header: (headerArgs) =>
            headerArgs.displayType === "desktop" && c.sortable ? (
              <SortColumn
                displayName={getCellHeader(c, headerArgs)}
                columnSortProperty={c.sortColumn as TSortColumn}
                currentSortProperty={currentSortColumn}
                currentSortDirection={currentSortDirection}
                onSortDirectionChange={(newSortColumn, newSortDirection) => {
                  setCurrentSortColumn(newSortColumn);
                  setCurrentSortDirection(newSortDirection);
                  setUserSettings(sortColumnSettingsKey, {
                    column: newSortColumn,
                    direction: newSortDirection,
                  } as ISortColumn<TSortColumn>);
                }}
                testId={c.headerSortLinkTestId}
              />
            ) : (
              getCellHeader(c, { displayType: "desktop", rowIndex: null })
            ),
        }))}
        renderMobile={({ row, index }) => {
          return (
            <>
              <ResponsiveTableMobileCard
                row={row}
                columns={visibleColumns}
                rowIndex={index}
              />
            </>
          );
        }}
      />

      {sortedItems.length === 0 ? (
        <div className="text-center mt-4 text-sm-left mt-sm-0 pb-4">
          <strong>{noItemsMessage}</strong>
        </div>
      ) : null}
    </React.Fragment>
  );
}
