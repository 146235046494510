import { useDispatch } from "react-redux";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { commonUiActionCreators } from "../../../modules/commonUi";
import InvoicePrint from "./InvoicePrint";
import { useReactToPrint } from "react-to-print";
import { useCallback, useEffect, useRef, useState } from "react";
import Alert from "../../../containers/app/components/Alert";
import { IInvoiceForPrint } from "../../../models/IInvoiceForPrint";
import { Subscription } from "rxjs";
import Spinner from "../../../containers/app/components/Spinner";
import invoiceDataProvider from "../services/invoiceDataProvider";
import { timeout } from "rxjs/operators";

const InvoicePrintModal: React.FunctionComponent<{}> = () => {
  const invoiceId = useApplicationStateSelector(
    (s) => s.commonUi.idForInvoicePrintContainer
  );
  const dispatch = useDispatch();
  const printHandler = useReactToPrint({
    content: () => printContainer.current,
    removeAfterPrint: true,
    pageStyle: "@page {size: letter portrait; margin: .5in .5in .5in .5in;}",
  });
  const printContainer = useRef<HTMLDivElement>(null);

  const [invoice, setInvoice] = useState<IInvoiceForPrint | null>(null);
  const [errorLoading, setErrorLoading] = useState(false);

  useEffect(() => {
    setErrorLoading(false);
    setInvoice(null);
  }, [invoiceId]);

  const onLoadComplete = useCallback(() => {
    printHandler();
    dispatch(commonUiActionCreators.dismissInvoicePrintContainer());
  }, [printHandler, dispatch]);

  useEffect(() => {
    let subscription: Subscription | null;
    if (invoiceId) {
      subscription = invoiceDataProvider
        .getInvoicesForPrint([invoiceId])
        .pipe(timeout(5000))
        .subscribe({
          next: (i) => {
            setInvoice(i[0]);
          },

          error: () => {
            setErrorLoading(true);
          },
        });
    }

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [invoiceId]);

  if (!invoiceId) {
    return null;
  } else if (errorLoading) {
    return (
      <Alert
        message="Unable to load the invoice to print."
        closeForm={() =>
          dispatch(commonUiActionCreators.dismissInvoicePrintContainer())
        }
      />
    );
  } else if (!invoice) {
    return <Spinner />;
  } else {
    return (
      <div ref={printContainer} className="billing-item-print-container">
        <InvoicePrint invoice={invoice} onLoadComplete={onLoadComplete} />
      </div>
    );
  }
};

export default InvoicePrintModal;
