import { RouterState } from "connected-react-router";
import { ICrew } from "../models/ICrew";
import { ITryParseScheduleResult, parsers } from "./routing";

export default function getDefaultsForScheduleLink(
  router: RouterState,
  crews: Array<ICrew>
) {
  let dateToUse: Date | null = null;
  let crewId: string | null = null;

  const parseFunctions = [
    () => parsers.schedule.tryParseScheduleMap(router),
    () => parsers.schedule.tryParseMonthSchedule(router),
    () => parsers.schedule.tryParseWeekSequence(router),
    () => parsers.schedule.tryParseDaySequence(router, crews),
    () => parsers.schedule.tryParseTimeWeek(router),
    () => parsers.schedule.tryParseTimeDay(router, crews),
  ];

  parseFunctions.forEach((parseFunction) => {
    if (dateToUse !== null) {
      return;
    }

    const result = parseFunction();
    if (result.isMatch) {
      dateToUse = result.dateInRoute;

      if ((result as ITryParseScheduleResult).crewIdInRoute) {
        crewId = (result as ITryParseScheduleResult).crewIdInRoute;
      }
    }
  });

  return { date: dateToUse ?? new Date(), crewId };
}
