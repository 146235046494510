import React from "react";
import { ICrew } from "../../../../../models/ICrew";
import { formatCurrency } from "../../../../../services/currencyFormatter";
import { IFoundJob } from "../../../../../services/jobFinder";
import { IJobInstance } from "../../../../../models/IJobInstance";
import { useGetJobGrossAmountAndEstimatedTime } from "../../../../../hooks/useGetJobGrossAmountAndEstimatedTime";

export interface IProps {
  crew: ICrew;
  jobsForDayWorkTimeMinutes: Array<{
    job: IFoundJob;
    jobInstance: IJobInstance;
  }>;
  jobsWithDriveTime: Array<IJobDistances>;
  hasJobsMissingLocations: boolean;
  hasCrewMissingLocation: boolean;
  date: string;
}

export interface IJobDistances {
  distanceFromPreviousJob: number | null | undefined;
  distanceToCrewLocation: number | null | undefined;
  distanceFromPreviousJobErrorLoading: boolean;
  distanceToCrewLocationJobErrorLoading: boolean;
}

const DayDetails: React.FunctionComponent<IProps> = ({
  crew,
  jobsForDayWorkTimeMinutes,
  jobsWithDriveTime,
  hasJobsMissingLocations,
  hasCrewMissingLocation,
  date,
}) => {
  const getJobGrossAmountAndEstimatedTime =
    useGetJobGrossAmountAndEstimatedTime();

  let crewMembers;
  if (crew && !isNaN(crew.typicalCrewSize)) {
    crewMembers = crew.typicalCrewSize;
  } else {
    crewMembers = 1;
  }

  const totalManHours = jobsForDayWorkTimeMinutes.reduce(
    (sum, job) =>
      (sum +=
        getJobGrossAmountAndEstimatedTime(
          date,
          job.job.type,
          job.job,
          job.jobInstance
        ).estimatedManHours ?? 0),
    0
  );
  const workTimeNeededInMinutes = (totalManHours * 60) / crewMembers;

  const driveTimeInMinutes = getDriveTimeInMinutes(jobsWithDriveTime);
  const totalTimeInMinute = workTimeNeededInMinutes + driveTimeInMinutes;

  const errorLoadingTimes = jobsWithDriveTime.reduce(
    (previousValue, job) =>
      previousValue ||
      job.distanceFromPreviousJobErrorLoading ||
      job.distanceToCrewLocationJobErrorLoading,
    false
  );

  const totalRevenue = jobsForDayWorkTimeMinutes.reduce(
    (sum, job) =>
      (sum +=
        getJobGrossAmountAndEstimatedTime(
          date,
          job.job.type,
          job.job,
          job.jobInstance
        ).grossRevenuePerVisit ?? 0),
    0
  );

  return (
    <div className="crew-details text-nowrap">
      {hasJobsMissingLocations || hasCrewMissingLocation ? (
        <React.Fragment>
          <span className="text-danger" style={{ whiteSpace: "pre-wrap" }}>
            {hasCrewMissingLocation
              ? "Crew does not have latitude/longitude set"
              : "Not all jobs have latitude/longitude set"}
          </span>
          <br />
        </React.Fragment>
      ) : null}
      {
        // Cleanup this whole section.  NaN checks and drive time set to 0 if rounded down not great.
      }
      <div>Work time: {formatTime(workTimeNeededInMinutes, false)}</div>
      {!hasJobsMissingLocations &&
      !hasCrewMissingLocation &&
      !errorLoadingTimes ? (
        <React.Fragment>
          <div>Drive time: {formatTime(driveTimeInMinutes, true)}</div>
          <div>Total time: {formatTime(totalTimeInMinute, true)}</div>
        </React.Fragment>
      ) : null}
      {!hasJobsMissingLocations &&
      !hasCrewMissingLocation &&
      errorLoadingTimes ? (
        <React.Fragment>
          <div>
            Drive time:{" "}
            <span className="text-danger" style={{ whiteSpace: "pre-wrap" }}>
              Unable to load times
            </span>
          </div>
          <div>
            Total time:{" "}
            <span className="text-danger" style={{ whiteSpace: "pre-wrap" }}>
              Unable to load times
            </span>
          </div>
        </React.Fragment>
      ) : null}
      {totalRevenue ? (
        <div>Amount: {formatCurrency(totalRevenue || 0)}</div>
      ) : null}
    </div>
  );
};

export default DayDetails;

function getDriveTimeInMinutes(jobsWithDriveTime: IJobDistances[]) {
  let driveTimeInSeconds = jobsWithDriveTime.reduce(
    (acc, val) => getDistance(val.distanceFromPreviousJob) + acc,
    0
  );

  if (jobsWithDriveTime.length > 0) {
    driveTimeInSeconds += getDistance(
      jobsWithDriveTime[jobsWithDriveTime.length - 1].distanceToCrewLocation
    );
  }

  const driveTimeInMinutes = driveTimeInSeconds / 60;

  return driveTimeInMinutes;
}

function getDistance(value: number | null | undefined) {
  if (value === null || value === undefined) {
    return NaN;
  } else {
    return value;
  }
}

function formatTime(numberMinutes: number, showLoadingIfNaN: boolean) {
  const normalizedMinutes = Math.round(numberMinutes);

  if (isNaN(numberMinutes)) {
    if (showLoadingIfNaN) {
      return "Loading...";
    } else {
      return "";
    }
  }

  if (normalizedMinutes < 60) {
    return `${normalizedMinutes} min`;
  } else if (normalizedMinutes % 60 === 0) {
    return `${normalizedMinutes / 60} hr`;
  } else {
    const hours = Math.floor(normalizedMinutes / 60);
    const remainingMinutes = normalizedMinutes - hours * 60;
    return `${hours} hr, ${remainingMinutes} min`;
  }
}
