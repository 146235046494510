import EXIF from "exif-js";
import { Observable } from "rxjs";
import dateService from "./dateService";

const dateTimeExifAttributeName = "DateTime";

export function exifParser(img: HTMLImageElement) {
  return new Observable<{ timestamp: string | null }>((subscriber) => {
    try {
      EXIF.getData(img as any, () => {
        let timestamp: string | null = null;
        try {
          if (img) {
            const dateTimeOriginal = EXIF.getTag(
              img,
              dateTimeExifAttributeName
            );

            if (dateTimeOriginal && typeof dateTimeOriginal === "string") {
              timestamp = formatExifDateAsIso8601(dateTimeOriginal);
            } else if (isCapturedByIOSCamera(img)) {
              timestamp = dateService.formatAsIsoWithTimeAndNoOffset(
                new Date()
              );
            }
          }
        } catch (err) {
          console.error(`unable to parse exif date`);
          console.error(err);
        }

        subscriber.next({ timestamp });

        subscriber.complete();
      });
    } catch (err) {
      subscriber.error(err);
    }
  });
}

export function formatExifDateAsIso8601(dateTimeOriginal: string) {
  let timestamp: string | null = null;
  const regexMatch = /^(\d{4}):(\d{2}):(\d{2}) (\d{2}):(\d{2}):(\d{2})$/.exec(
    dateTimeOriginal
  );
  if (regexMatch && regexMatch.length === 7) {
    const [, year, month, day, hour, minute, second] = regexMatch;

    const parsedYear = parseInt(year);
    const parsedMonth = parseInt(month);
    const parsedDay = parseInt(day);
    const parsedHour = parseInt(hour);
    const parsedMinute = parseInt(minute);
    const parsedSecond = parseInt(second);
    timestamp = dateService.formatAsIsoWithTimeAndNoOffset(
      new Date(
        parsedYear,
        // JS Date is off by 1 from EXIF format
        parsedMonth - 1,
        parsedDay,
        parsedHour,
        parsedMinute,
        parsedSecond
      )
    );
  }

  return timestamp;
}

function isCapturedByIOSCamera(img: HTMLImageElement) {
  if (!isIOS()) {
    return false;
  }

  // Assuming that if DateTime not set but rest of properties are,
  // this image was just captured by the ios camera since
  // the datetime prop is stripped but these attributes are set
  return (
    !isExifAttributeSet(img, dateTimeExifAttributeName) &&
    isExifAttributeSet(img, "ExifIFDPointer") &&
    isExifAttributeSet(img, "ColorSpace") &&
    isExifAttributeSet(img, "PixelXDimension") &&
    isExifAttributeSet(img, "PixelYDimension")
  );
}

function isIOS() {
  console.log(`navigator.platform: ${navigator.platform}`);
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

function isExifAttributeSet(img: HTMLImageElement, attributeName: string) {
  const v = EXIF.getTag(img, attributeName);

  return typeof v === "string" || typeof v === "number";
}
