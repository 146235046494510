import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import LinkButton2 from "../components/LinkButton2";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import { JobBillingType } from "../../../enums/jobBillingType";
import jobHelper from "../../../services/jobHelper";
import { IInvoiceJobInstance } from "./InvoiceForm.types";
import { JobType } from "../../../models/IJob";
import { useEffect, useMemo, useRef, useState } from "react";
import { forkJoin, Subscription } from "rxjs";
import invoiceDataProvider from "../../../slices/billing/services/invoiceDataProvider";
import { IInvoiceDefaultLineItem } from "../../../models/IInvoiceDefaultLineItem";
import { IMaintenanceJob } from "../../../models/IMaintenanceJob";
import remoteDataProvider from "../../../services/remoteDataProvider";
import { IWorkNotInvoicedCustomer } from "../../../models/IWorkNotInvoicedCustomer";
import { mapWorkNotInvoicedJobToInvoiceJobInstance } from "../../../services/invoiceFormService";
import { retry, timeout } from "rxjs/operators";
import { IInvoiceItem } from "../../../models/IInvoiceItem";
import {
  fullStoryLogInfo,
  fullStoryTrack,
} from "../../../services/fullStoryService";

export type UpdatesFromLineItemChanges = {
  updatedDefaultLineItems: Array<IInvoiceDefaultLineItem>;
  updatedInvoiceJobInstances: Array<IInvoiceJobInstance>;
};

export function InvoiceFormLineItemAlert({
  invoicejobInstances,
  onDataRefreshed,
  invoiceItems,
}: {
  invoicejobInstances: Array<IInvoiceJobInstance>;
  onDataRefreshed(
    maintenanceJobId: string,
    args: UpdatesFromLineItemChanges
  ): void;
  invoiceItems: Array<IInvoiceItem>;
}) {
  const maintenanceJobsToInvoiceIds =
    getMaintenanceJobsToInvoiceIds(invoicejobInstances);

  const {
    maintenanceJobsToDisplay,
    jobsPreviouslyRequiringCorrect,
    setJobsPreviouslyRequiringCorrect,
  } = useGetMaintenanceJobsToDisplay({
    maintenanceJobsToInvoiceIds,
    invoiceItems,
  });

  useLogAlertShown(maintenanceJobsToDisplay);

  if (maintenanceJobsToDisplay.length === 0) {
    return null;
  }

  return (
    <div
      className="alert alert-warning mb-2"
      style={{ padding: "5px", paddingLeft: "10px" }}
      data-testid="lineItemAlert"
    >
      <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
      <small>
        Not all of your jobs have line items or some line items are missing
        required fields. Correcting this will default line items onto your
        invoice in the future. It will also make these jobs eligible for batch
        invoicing.
        <ul className="mb-0">
          {maintenanceJobsToDisplay.map((maintenanceJob) => (
            <li key={maintenanceJob.id}>
              <InvoiceFormLineItemAlertCorrectJob
                maintenanceJob={maintenanceJob}
                invoicejobInstances={invoicejobInstances}
                invoiceItems={invoiceItems}
                onDataRefreshed={(data) => {
                  setJobsPreviouslyRequiringCorrect(
                    jobsPreviouslyRequiringCorrect.filter(
                      (maintenanceJobId) =>
                        maintenanceJobId !== maintenanceJob.id
                    )
                  );
                  onDataRefreshed(maintenanceJob.id, data);
                }}
              />
            </li>
          ))}
        </ul>
      </small>
    </div>
  );
}

function InvoiceFormLineItemAlertCorrectJob({
  maintenanceJob,
  invoicejobInstances,
  onDataRefreshed,
  invoiceItems,
}: {
  maintenanceJob: IMaintenanceJob;
  invoicejobInstances: IInvoiceJobInstance[];
  onDataRefreshed(args: UpdatesFromLineItemChanges): void;
  invoiceItems: Array<IInvoiceItem>;
}) {
  const dispatch = useDispatch();

  const maintenanceJobId = maintenanceJob.id;

  const { errorLoading, retryLoadData } = useLoadUpdatesFromLineItemChanges({
    maintenanceJob,
    invoicejobInstances,
    maintenanceJobId,
    onDataRefreshed,
    invoiceItems,
  });

  return !errorLoading ? (
    <LinkButton2
      className="text-reset"
      testId="addLineItemsToJob"
      buttonContents={
        <u>
          <small>
            <strong>
              Add line items to{" "}
              {jobHelper.getFrequencyForDisplay(maintenanceJob.frequency)}{" "}
              Frequency job
            </strong>
          </small>
        </u>
      }
      onClick={() => {
        fullStoryTrack("Invoice Form Set Line Items");
        dispatch(
          actionCreators.forms.maintenanceJob.showForm({
            maintenanceJobId: maintenanceJob.id,
          })
        );
      }}
      style={{ verticalAlign: "baseline" }}
    />
  ) : (
    <>
      Unable to update this invoice with the job's new line items.
      <LinkButton2
        className="ml-1 text-reset"
        buttonContents={
          <u>
            <small>
              <strong>Try again</strong>
            </small>
          </u>
        }
        onClick={() => {
          retryLoadData();
        }}
        style={{ verticalAlign: "baseline" }}
      />
    </>
  );
}

function getMaintenanceJobsToInvoiceIds(
  invoicejobInstances: IInvoiceJobInstance[]
) {
  return invoicejobInstances
    .filter((ji) => ji.jobType === JobType.maintenanceJob && ji.jobId !== null)
    .map((ji) => ji.jobId as string);
}

function useLogAlertShown(maintenanceJobsToDisplay: IMaintenanceJob[]) {
  const hasLoggedAlertShown = useRef(false);
  useEffect(() => {
    if (maintenanceJobsToDisplay.length > 0 && !hasLoggedAlertShown.current) {
      fullStoryLogInfo("Invoice Form Line Items Alert Shown");

      hasLoggedAlertShown.current = true;
    }
  }, [maintenanceJobsToDisplay]);
}

function useGetMaintenanceJobsToDisplay({
  maintenanceJobsToInvoiceIds,
  invoiceItems,
}: {
  maintenanceJobsToInvoiceIds: string[];
  invoiceItems: Array<IInvoiceItem>;
}) {
  const maintenanceJobs = useApplicationStateSelector((s) => s.job.jobs);

  // This is for making sure we continue to show rows for jobs
  // even after corrected but while loading updated data.
  const [jobsPreviouslyRequiringCorrect, setJobsPreviouslyRequiringCorrect] =
    useState<Array<string>>(
      maintenanceJobs
        .filter(
          (mj) =>
            maintenanceJobsToInvoiceIds.includes(mj.id) &&
            doesJobRequireCorrection({ maintenanceJob: mj, invoiceItems })
        )
        .map((mj) => mj.id)
    );

  const maintenanceJobsToDisplay = maintenanceJobs.filter(
    (mj) =>
      maintenanceJobsToInvoiceIds.includes(mj.id) &&
      (doesJobRequireCorrection({ maintenanceJob: mj, invoiceItems }) ||
        jobsPreviouslyRequiringCorrect.includes(mj.id))
  );

  useEffect(() => {
    if (
      maintenanceJobsToDisplay.some(
        (j) => !jobsPreviouslyRequiringCorrect.includes(j.id)
      )
    ) {
      setJobsPreviouslyRequiringCorrect([
        ...jobsPreviouslyRequiringCorrect,
        ...maintenanceJobsToDisplay
          .filter((j) => !jobsPreviouslyRequiringCorrect.includes(j.id))
          .map((j) => j.id),
      ]);
    }
  }, [jobsPreviouslyRequiringCorrect, maintenanceJobsToDisplay]);

  return {
    jobsPreviouslyRequiringCorrect,
    setJobsPreviouslyRequiringCorrect,
    maintenanceJobsToDisplay,
  };
}

type DataLoaded = {
  defaultLineItems: Array<IInvoiceDefaultLineItem>;
  workNotInvoicedCustomers: Array<IWorkNotInvoicedCustomer>;
};

function useLoadUpdatesFromLineItemChanges({
  maintenanceJob,
  invoicejobInstances,
  maintenanceJobId,
  onDataRefreshed,
  invoiceItems,
}: {
  maintenanceJob: IMaintenanceJob;
  invoicejobInstances: IInvoiceJobInstance[];
  maintenanceJobId: string;
  onDataRefreshed: (args: UpdatesFromLineItemChanges) => void;
  invoiceItems: Array<IInvoiceItem>;
}) {
  const missingLineItems = doesJobRequireCorrection({
    maintenanceJob,
    invoiceItems,
  });
  const oldMissingLineItems = useRef(missingLineItems);
  const [errorLoading, setErrorLoading] = useState(false);

  const jobInstancesToRequest = useMemo(
    () =>
      invoicejobInstances
        .filter((j) => j.jobId !== null && j.jobId === maintenanceJobId)
        .map((j) => j.id),
    [invoicejobInstances, maintenanceJobId]
  );

  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );

  const [dataLoaded, setDataLoaded] = useState<DataLoaded>({
    defaultLineItems: [],
    workNotInvoicedCustomers: [],
  });

  useEffect(() => {
    let subscription: Subscription | null = null;

    if (missingLineItems !== oldMissingLineItems.current) {
      oldMissingLineItems.current = missingLineItems;

      subscription = loadChanges({
        jobInstancesToRequest,
        maintenanceJobId,
        setDataLoaded,
        setErrorLoading,
      });
    }

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [jobInstancesToRequest, maintenanceJobId, missingLineItems]);

  const oldDataLoaded = useRef<DataLoaded>(dataLoaded);

  useEffect(() => {
    if (dataLoaded !== oldDataLoaded.current) {
      oldDataLoaded.current = dataLoaded;

      onDataRefreshed({
        updatedDefaultLineItems: dataLoaded.defaultLineItems,
        updatedInvoiceJobInstances: dataLoaded.workNotInvoicedCustomers.flatMap(
          (customer) => {
            return customer.jobs.flatMap((job) =>
              mapWorkNotInvoicedJobToInvoiceJobInstance({
                job,
                customer,
                customers,
                customerAdditionalLocations,
              })
            );
          }
        ),
      });
    }
  }, [dataLoaded, onDataRefreshed, customers, customerAdditionalLocations]);

  return {
    errorLoading,
    retryLoadData: () =>
      loadChanges({
        jobInstancesToRequest,
        maintenanceJobId,
        setDataLoaded,
        setErrorLoading,
      }),
  };
}

function loadChanges({
  jobInstancesToRequest,
  maintenanceJobId,
  setDataLoaded,
  setErrorLoading,
}: {
  jobInstancesToRequest: string[];
  maintenanceJobId: string;
  setDataLoaded: (newValue: DataLoaded) => void;
  setErrorLoading: (newValue: boolean) => void;
}): Subscription | null {
  setErrorLoading(false);

  return forkJoin({
    invoiceDefaults: invoiceDataProvider.getInvoiceDefaults({
      jobInstanceIds: jobInstancesToRequest,
      projectIds: [],
      invoiceId: null,
    }),

    workNotInvoicedCustomers: remoteDataProvider.getWorkNotInvoicedReport(
      null,
      null,
      [maintenanceJobId]
    ),
  })
    .pipe(timeout(5000), retry(2))
    .subscribe({
      next: ({ invoiceDefaults, workNotInvoicedCustomers }) => {
        setDataLoaded({
          defaultLineItems: invoiceDefaults.lineItems ?? [],
          workNotInvoicedCustomers: workNotInvoicedCustomers.map((c) => ({
            ...c,
            jobs: c.jobs.map((j) => ({
              ...j,
              jobInstances: j.jobInstances.filter((ji) =>
                // Only include the original job instances
                jobInstancesToRequest.some((r) => r === ji.id)
              ),
            })),
          })),
        });
      },

      error: () => {
        setErrorLoading(true);
      },
    });
}

function doesJobRequireCorrection({
  maintenanceJob,
  invoiceItems,
}: {
  maintenanceJob: IMaintenanceJob;
  invoiceItems: Array<IInvoiceItem>;
}) {
  return (
    maintenanceJob.billingType === JobBillingType.PerServiceTotal ||
    (maintenanceJob.billingType === JobBillingType.PerServiceLineItems &&
      (maintenanceJob.lineItems ?? []).filter((li) =>
        invoiceItems.some((i) => i.id === li.itemId)
      ).length === 0)
  );
}
