import React, { Fragment, useState } from "react";
import DayWithJobsHeaderLinks from "./DayWithJobsHeaderLinks";
import DayDetails from "./DayDetails";
import { useApplicationStateSelector } from "../../../../../hooks/useApplicationStateSelector";
import { IDaySchedule } from "../../../../../models/IDaySchedule";
import Popover from "reactstrap/lib/Popover";
import PopoverBody from "reactstrap/lib/PopoverBody";
import { useDispatch } from "react-redux";
import LinkButton from "../../LinkButton";
import { actionCreators } from "../../../../../modules/actionCreators";
import { ICrew } from "../../../../../models/ICrew";
import jobFinder, { IFoundJob } from "../../../../../services/jobFinder";
import { populateDriveTimes } from "../../../../../services/jobInstanceService";
import HeaderContainer from "./HeaderContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { IMapLinkProps } from "../../../../../slices/schedule/components/types/IMapLinkProps";

interface IProps {
  columnHeader: React.ReactNode;
  dayScheduleId: string;
  crew: ICrew;
  date: string;
  isMapVisible: boolean;
  mode: string;
  additionalLink: any;
  mapLinkProps: IMapLinkProps;
  hasJobsMissingLocationsOuter: boolean;
  hasCrewMissingLocation: boolean;
  highlightHeader?: boolean;
  isCalendarHeader: boolean;
  isLastColumn: boolean;
  hideSelectClearAllButton: boolean;
}

const Header: React.FunctionComponent<IProps> = ({
  columnHeader,
  dayScheduleId,
  crew,
  date,
  isMapVisible,
  mode,
  additionalLink,
  mapLinkProps,
  hasJobsMissingLocationsOuter,
  hasCrewMissingLocation,
  highlightHeader,
  isCalendarHeader,
  isLastColumn,
  hideSelectClearAllButton,
}) => {
  const daySchedules = useApplicationStateSelector(
    (s) => s.schedule.daySchedules
  );
  const [showDayScheduleNotes, setShowDayScheduleNotes] = useState(false);
  const jobs = useApplicationStateSelector((s) => s.job.jobs);
  const oneTimeJobs = useApplicationStateSelector((s) => s.job.oneTimeJobs);
  const customers = useApplicationStateSelector((s) => s.customer.customers);
  const customerAdditionalLocations = useApplicationStateSelector(
    (s) => s.customer.customerAdditionalLocations
  );
  const distances = useApplicationStateSelector(
    (s) => s.distanceCache.distances
  );
  const headersVisible = useApplicationStateSelector(
    (s) => s.scheduleUi.headersVisible
  );
  const dispatch = useDispatch();

  const isHeaderVisible =
    !!headersVisible.find(
      (v) => v.dayScheduleId === dayScheduleId && v.mode === mode
    ) || isMapVisible;

  const daySchedule = daySchedules.find(
    (s) => s.id === dayScheduleId
  ) as IDaySchedule;

  let dispatched = false;
  if (!daySchedule) {
    return null;
  } else {
    dispatched = daySchedule.dispatched;
  }

  const jobsForHeaderDriveTime = daySchedule.jobInstances.filter(
    (j) => !j.skipped
  );
  const jobsForHeaderDriveTimeWithDriveTime = jobsForHeaderDriveTime.map(
    populateDriveTimes(
      jobs,
      oneTimeJobs,
      distances,
      jobsForHeaderDriveTime,
      customers,
      crew,
      customerAdditionalLocations
    )
  );

  const jobsWithDriveTime = daySchedule.jobInstances.map(
    populateDriveTimes(
      jobs,
      oneTimeJobs,
      distances,
      daySchedule.jobInstances,
      customers,
      crew,
      customerAdditionalLocations
    )
  );

  const jobsForDayWorkTimeMinutes = daySchedule.jobInstances
    .filter((ji) => !ji.skipped)
    .map((ji) => {
      const correspondingJob = jobFinder.getJobForDayScheduleV2(
        jobs,
        oneTimeJobs,
        ji
      );
      return { job: correspondingJob, jobInstance: ji };
    })
    .filter((j) => j.job !== null)
    .map((j) => ({
      job: j.job as IFoundJob,
      jobInstance: j.jobInstance,
    }));

  return (
    <HeaderContainer
      highlightHeader={highlightHeader}
      columnHeader={columnHeader}
      isCalendarHeader={isCalendarHeader}
      isLastColumn={isLastColumn}
      columnHeaderAddition={
        (daySchedule.notes || "").trim() ? (
          <React.Fragment>
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="text-danger"
              style={{ marginLeft: "10px", cursor: "pointer" }}
              id={`dayschedulenote_${daySchedule.id}`}
              onClick={() => {
                setShowDayScheduleNotes(!showDayScheduleNotes);
              }}
            />
            <Popover
              placement="bottom"
              isOpen={showDayScheduleNotes || false}
              target={`dayschedulenote_${daySchedule.id}`}
              toggle={() => {
                setShowDayScheduleNotes(!showDayScheduleNotes);
              }}
              delay={0}
              fade={false}
              trigger="legacy"
            >
              <PopoverBody>
                <p>{daySchedule.notes}</p>
                <p>
                  <LinkButton
                    linkText="Set day details"
                    hrefForDisplay="dayschedulenotes"
                    onClick={() => {
                      setShowDayScheduleNotes(!showDayScheduleNotes);

                      dispatch(
                        actionCreators.forms.daySchedule.showForm({
                          dayScheduleId,
                        })
                      );
                    }}
                  />
                </p>
              </PopoverBody>
            </Popover>
          </React.Fragment>
        ) : null
      }
    >
      {daySchedule.jobInstances.length > 0 ? (
        <div>
          <small>
            <LinkButton
              linkText="Dispatch schedule"
              hrefForDisplay="textschedule"
              onClick={() =>
                dispatch(
                  actionCreators.forms.publishSchedule.showForm({
                    dayScheduleId,
                  })
                )
              }
            />
            {dispatched ? (
              <FontAwesomeIcon
                icon={faCheck}
                className="text-muted"
                style={{ marginLeft: "5px" }}
              />
            ) : null}
          </small>
        </div>
      ) : null}
      <Fragment>
        {isHeaderVisible && daySchedule.jobInstances.length > 0 ? (
          <DayDetails
            crew={crew}
            jobsForDayWorkTimeMinutes={jobsForDayWorkTimeMinutes}
            jobsWithDriveTime={jobsForHeaderDriveTimeWithDriveTime}
            hasJobsMissingLocations={hasJobsMissingLocationsOuter}
            hasCrewMissingLocation={hasCrewMissingLocation}
            date={date}
          />
        ) : null}
        <small>
          <DayWithJobsHeaderLinks
            dayScheduleId={dayScheduleId}
            isHeaderVisible={isHeaderVisible}
            isMapVisible={isMapVisible}
            mode={mode}
            additionalLink={additionalLink}
            date={date}
            crew={crew}
            hasJobsMissingLocations={hasJobsMissingLocationsOuter}
            hasCrewMissingLocation={hasCrewMissingLocation}
            mapLinkProps={mapLinkProps}
            jobsOnDayCount={jobsWithDriveTime.length}
            hideExpandCollapseChevron={isMapVisible}
            hideSelectClearAllButton={hideSelectClearAllButton}
          />
        </small>
      </Fragment>
    </HeaderContainer>
  );
};

export default Header;
