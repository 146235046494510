import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ExportButton({ onClick }: { onClick(): void }) {
  return (
    <button
      className="btn btn-secondary"
      type="button"
      style={{ whiteSpace: "nowrap" }}
      onClick={onClick}
      title="Export to Excel"
    >
      <FontAwesomeIcon icon={faFileExcel} />
      <span className="ml-1">Export</span>
    </button>
  );
}
