import React, { useState, useEffect } from "react";
import { timeout } from "rxjs/operators";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import LoadReportMessage from "../../../containers/app/components/LoadReportMessage";
import {
  getErrorMessageFromError,
  getErrorMessagesFromError,
} from "../../../services/httpErrorHandler";
import invoiceDataProvider from "../services/invoiceDataProvider";
import { IInvoiceDraft } from "../models/IInvoiceDraft";
import ServerLoadedList from "../../../libraries/tableLayout/ServerLoadedList";
import dateService from "../../../services/dateService";
import { formatCurrency } from "../../../services/currencyFormatter";
import ButtonDropdown from "reactstrap/lib/ButtonDropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownItem from "reactstrap/lib/DropdownItem";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import Prompt from "../../../containers/app/components/Prompt";
import remoteDataProvider from "../../../services/remoteDataProvider";
import constants from "../../../constants";
import Spinner from "../../../containers/app/components/Spinner";
import BillingListCustomerPopover from "./BillingListCustomerPopover";

const DraftInvoices: React.FunctionComponent<{}> = () => {
  const [errorLoading, setErrorLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [loadingData, setLoadingData] = useState(false);
  const [dataNotLoaded, setDataNotLoaded] = useState(true);
  const invoiceSaveCount = useApplicationStateSelector(
    (s) => s.forms.invoice.saveCount
  );
  const [reportData, setReportData] = useState<Array<IInvoiceDraft> | null>(
    null
  );

  useEffect(() => {
    let subscription = loadData({
      setLoadingData,
      setReportData,
      setErrorLoading,
      setDataNotLoaded,
      setErrorMessage,
    });

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [invoiceSaveCount]);

  return (
    <>
      <ServerLoadedList
        largeHeader={true}
        header={null}
        dataType="invoice draft"
        errorLoading={errorLoading}
        errorMessage={errorMessage[0]}
        loadingData={loadingData}
        dataNotLoaded={dataNotLoaded}
        dataNotLoadedContent={<LoadReportMessage />}
        data={reportData}
        showContentWhileRefreshing={true}
        columns={[
          {
            key: "customerName",
            cell: ({ row: draft, displayType }) => (
              <BillingListCustomerPopover
                recordId={draft.id}
                customerId={draft.customerId}
                customerName={draft.customerName}
                customerAddress={draft.customerAddress}
                customerPhoneNumber={draft.customerPhoneNumber}
                customerAlternativePhoneNumber={
                  draft.customerAlternativePhoneNumber
                }
                idPrefix={displayType}
              />
            ),
            header: "Customer",
            testId: "customer",
          },
          {
            key: "dateLastChanged",
            cell: ({ row: d }) =>
              typeof d.dateLastChanged === "string"
                ? dateService.formatDateForDisplay(d.dateLastChanged)
                : "",
            header: "Last updated",
            testId: "dateLastChanged",
          },
          {
            key: "amount",

            cell: ({ row: d }) => formatCurrency(d.amount),
            header: "Amount",
            testId: "amount",
          },
          {
            key: "actions",
            isButtonCell: true,
            cell: ({ row: d, displayType }) => (
              <div
                className={displayType === "desktop" ? "text-right" : undefined}
              >
                <ActionButtons
                  invoiceId={d.id}
                  customerId={d.customerId}
                  onDataChanged={() => {
                    loadData({
                      setLoadingData,
                      setErrorLoading,
                      setDataNotLoaded,
                      setReportData,
                      setErrorMessage,
                    });
                  }}
                />
              </div>
            ),
            header: "",
          },
        ]}
        refreshData={() =>
          loadData({
            setLoadingData,
            setErrorLoading,
            setDataNotLoaded,
            setReportData,
            setErrorMessage,
          })
        }
        hasFilterError={false}
        filter={<></>}
      />
    </>
  );
};

export default DraftInvoices;

function loadData({
  setLoadingData,
  setReportData,
  setErrorLoading,
  setDataNotLoaded,
  setErrorMessage,
}: {
  setLoadingData: React.Dispatch<React.SetStateAction<boolean>>;
  setReportData: React.Dispatch<
    React.SetStateAction<Array<IInvoiceDraft> | null>
  >;
  setErrorLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setDataNotLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<Array<string>>>;
}) {
  setLoadingData(true);
  setErrorLoading(false);
  setDataNotLoaded(false);
  setErrorMessage([]);

  return invoiceDataProvider
    .getInvoiceDrafts({ top: null })
    .pipe(timeout(30_000))
    .subscribe(
      (result) => {
        setReportData(result);
        setLoadingData(false);
      },
      (err) => {
        setErrorMessage(getErrorMessagesFromError(err));
        setErrorLoading(true);
        setLoadingData(false);
      }
    );
}

function ActionButtons({
  invoiceId,
  customerId,
  onDataChanged,
}: {
  invoiceId: string;
  customerId: string;
  onDataChanged: () => void;
}) {
  const [isAdditionalButtonsDropDownOpen, setIsAdditionalButtonsDropDownOpen] =
    useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const dispatch = useDispatch();

  return (
    <>
      <ButtonDropdown
        direction="down"
        isOpen={isAdditionalButtonsDropDownOpen}
        toggle={() =>
          setIsAdditionalButtonsDropDownOpen(!isAdditionalButtonsDropDownOpen)
        }
      >
        <button
          className="btn btn-secondary btn-sm"
          onClick={() => {
            dispatch(
              dispatch(
                actionCreators.forms.invoice.showForm({
                  customerId,
                  invoiceId,
                })
              )
            );
          }}
        >
          Edit draft
        </button>
        <DropdownToggle split color="secondary" size="sm" />
        <DropdownMenu positionFixed={true} right={true}>
          <DropdownItem
            onClick={() => {
              setShowDeleteConfirmation(true);
              setErrorMessage(null);
            }}
          >
            Delete draft
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>

      {deleting ? <Spinner /> : null}

      <Prompt
        promptMessage="Are you sure you want to delete this invoice draft?"
        promptSubMessage={errorMessage}
        promptSubMessageClassName="text-danger"
        showPrompt={showDeleteConfirmation && !deleting}
        onCancel={() => setShowDeleteConfirmation(false)}
        onConfirm={() => {
          setDeleting(true);

          remoteDataProvider
            .deleteInvoice(invoiceId)
            .pipe(timeout(10000))
            .subscribe({
              next: () => {
                setDeleting(false);
                setShowDeleteConfirmation(false);
                onDataChanged();
              },

              error: (err) => {
                setDeleting(false);
                setErrorMessage(
                  getErrorMessageFromError(err, constants.unknownErrorMessage)
                );
              },
            });
        }}
      />
    </>
  );
}
