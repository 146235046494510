import React, { useEffect, useRef, useState } from "react";
import { round } from "../../../services/roundingService";
import { formatCurrency } from "../../../services/currencyFormatter";

export default function AmountField({
  id,
  value,
  onChange,
  ariaLabel,
  disabled,
  required,
  max,
  addCreditFormat,
}: {
  id: string;
  value: number | null;
  onChange: (newValue: null | number) => void;
  ariaLabel?: string;
  disabled?: boolean;
  required?: boolean;
  max: number | null;
  addCreditFormat?: boolean;
}) {
  const [hasFocus, setHasFocus] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>();

  useUpdateValidityIfMaxChanged({
    max,
    inputRef,
    value,
  });

  let valueToDisplay: number | string;
  if (value === null || value === 0) {
    valueToDisplay = "";
  } else {
    const adjustedValue = Math.abs(value);
    if (hasFocus) {
      valueToDisplay = adjustedValue;
    } else {
      valueToDisplay = addCreditFormat
        ? `(${formatCurrency(adjustedValue)})`
        : `${formatCurrency(adjustedValue)}`;
    }
  }

  return (
    <input
      aria-label={ariaLabel}
      ref={(r) => (inputRef.current = r)}
      id={id}
      min={0}
      className="form-control text-right"
      type={hasFocus ? "number" : undefined}
      step="0.01"
      value={valueToDisplay}
      onFocus={() => setHasFocus(true)}
      onBlur={() => setHasFocus(false)}
      disabled={disabled}
      required={required}
      onChange={(e) => {
        clearFieldValidity(inputRef);

        let parsedRate = parseFloat(e.currentTarget.value);
        if (isNaN(parsedRate)) {
          onChange(null);
        } else {
          const adjustedValue = round(Math.abs(parsedRate), 2);
          onChange(adjustedValue);

          updateFieldValidity({ inputRef, max, value: adjustedValue });
        }
      }}
    />
  );
}

function useUpdateValidityIfMaxChanged({
  max,
  inputRef,
  value,
}: {
  max: number | null;
  inputRef: React.MutableRefObject<HTMLInputElement | null | undefined>;
  value: number | null;
}) {
  const oldMax = useRef(max);

  useEffect(() => {
    if (oldMax.current !== max) {
      oldMax.current = max;

      clearFieldValidity(inputRef);

      if (typeof value === "number") {
        updateFieldValidity({
          inputRef,
          max,
          value,
        });
      }
    }
  }, [inputRef, max, value]);
}

function clearFieldValidity(
  inputRef: React.MutableRefObject<HTMLInputElement | null | undefined>
) {
  if (inputRef.current) {
    inputRef.current.setCustomValidity("");
  }
}

function updateFieldValidity({
  inputRef,
  max,
  value,
}: {
  inputRef: React.MutableRefObject<HTMLInputElement | null | undefined>;
  max: number | null;
  value: number;
}) {
  if (inputRef.current) {
    if (typeof max === "number" && value > max) {
      inputRef.current.setCustomValidity(
        `Value must be less than or equal to ${max}`
      );
    }
  }
}
