import {
  faChartLine,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent } from "reactstrap/lib";
import constants from "../../../constants";
import Spinner from "../../../containers/app/components/Spinner";
import TabPaneAndContainerLazyLoaded from "../../../containers/app/components/TabPaneAndContainerLazyLoaded";
import PageWithNavBar2 from "../../../containers/app/PageWithNavBar2";
import { InvoiceListType } from "../../../enums/invoiceListType";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import remoteDataProvider from "../../../services/remoteDataProvider";
import BillingInvoicesList from "./BillingInvoicesList";
import WorkNotInvoiced from "./BillingWorkNotInvoiced";
import invoiceDataProvider from "../services/invoiceDataProvider";
import DraftInvoices from "./DraftInvoices";
import { Subscription } from "rxjs";
import { useDispatch } from "react-redux";
import { actionCreators } from "../../../modules/actionCreators";
import { useIsResolution } from "../../../hooks/useIsResolution";
import MobileDetailsPageTabContents from "../../../containers/app/components/MobileDetailsPageTabContents";
import { useUserSettings } from "../../../services/userSettingsService";
import { UserSettingsType } from "../../../enums/userSettingsType";
import Modal from "reactstrap/lib/Modal";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalFooter from "reactstrap/lib/ModalFooter";
import { useCreditCardsEnabled } from "../../../hooks/useCreditCardsEnabled";

const tabStyle: Partial<CSSProperties> = {
  cursor: "pointer",
};

enum BillingPageTab {
  workNotInvoiced = 1,
  drafts = 2,
  openInvoices = 3,
  paidInvoices = 4,
  invoicesNotSynced = 5,
}

const Invoicing: React.FunctionComponent<{}> = () => {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const dispatch = useDispatch();

  const { getUserSettings, setUserSettings } = useUserSettings();
  const billingGetStartedPromptDismissed = getUserSettings<boolean>(
    UserSettingsType.billingGetStartedPromptDismissed
  );

  const [showPaymentsPortalModal, setShowPaymentsPortalModal] = useState(false);

  const { loadingData: loadingDataNotSyncedData, showNotSyncedTab } =
    useLoadShowNotSyncedTab();

  const { loadingData: loadingDataDraftsTab, showDraftsTab } =
    useLoadShowDraftsTab();

  const isDesktopViewForSectionContents = useIsResolution("lg");

  const navbarBreakpoint = "md";
  const isDesktopForNavbar = useIsResolution(navbarBreakpoint);

  const { areCreditCardsEnabled } = useCreditCardsEnabled();

  const tabs = [
    {
      tabId: BillingPageTab.workNotInvoiced,
      render: () => <WorkNotInvoiced />,
    },
    {
      tabId: BillingPageTab.drafts,
      render: () => <DraftInvoices />,
    },
    {
      tabId: BillingPageTab.openInvoices,
      render: () => <BillingInvoicesList listType={InvoiceListType.unpaid} />,
    },
    {
      tabId: BillingPageTab.paidInvoices,
      render: () => <BillingInvoicesList listType={InvoiceListType.paid} />,
    },
    {
      tabId: BillingPageTab.invoicesNotSynced,
      render: () => (
        <BillingInvoicesList listType={InvoiceListType.notSynced} />
      ),
    },
  ];

  return (
    <PageWithNavBar2 billingContext={true}>
      <div
        className="d-lg-flex justify-content-between align-items-center flex-wrap"
        style={{ columnGap: "10px" }}
      >
        <h1>Billing</h1>

        {!billingGetStartedPromptDismissed ? (
          <div
            data-testid="billingInfoPrompt"
            className={"alert alert-primary"}
            role="alert"
            style={{ maxWidth: "450px" }}
          >
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              data-testid="close"
              onClick={() => {
                setUserSettings(
                  UserSettingsType.billingGetStartedPromptDismissed,
                  true
                );
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="d-flex justify-content-between">
              <div>
                <FontAwesomeIcon
                  style={{ background: "white", borderRadius: "20%" }}
                  icon={faChartLine}
                  color="text-info"
                  size="3x"
                ></FontAwesomeIcon>
              </div>
              <div className="ml-3">
                <div>
                  <h5>Take your billing to the next level.</h5>
                </div>
                <div>
                  Learn more about
                  <a
                    className="ml-1"
                    href="https://aspire-extensions.document360.io/crew-control/docs/gettting-started-with-invoicing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    how to get started with invoicing
                  </a>
                  !
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="d-flex flex-nowrap" style={{ gap: "10px" }}>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => {
              dispatch(
                actionCreators.forms.invoice.showForm({
                  customerId: null,
                })
              );
            }}
          >
            Create Invoice
          </button>

          {areCreditCardsEnabled ? (
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => {
                setShowPaymentsPortalModal(true);
              }}
            >
              Payments Portal
            </button>
          ) : null}
        </div>
      </div>
      {loadingDataNotSyncedData || loadingDataDraftsTab ? (
        <Spinner />
      ) : (
        <div
          className="mt-3"
          style={{
            marginBottom: constants.marginBottomForIntercom,
          }}
        >
          <Nav
            tabs
            pills={true}
            className={`d-block d-${navbarBreakpoint}-flex`}
            style={
              !isDesktopForNavbar
                ? { marginLeft: "-14px", marginRight: "-14px" }
                : { borderBottomStyle: "none" }
            }
          >
            <NavItem>
              <NavLink
                style={tabStyle}
                className={getStyles(
                  BillingPageTab.workNotInvoiced.toString(),
                  activeTab,
                  isDesktopForNavbar
                )}
                onClick={() => {
                  toggle(BillingPageTab.workNotInvoiced.toString());
                }}
              >
                <small>Work Not Invoiced</small>
              </NavLink>
            </NavItem>
            {showDraftsTab ? (
              <NavItem>
                <NavLink
                  style={tabStyle}
                  className={getStyles(
                    BillingPageTab.drafts.toString(),
                    activeTab,
                    isDesktopForNavbar
                  )}
                  onClick={() => {
                    toggle(BillingPageTab.drafts.toString());
                  }}
                >
                  <small>Drafts</small>
                </NavLink>
              </NavItem>
            ) : null}
            <>
              <NavItem>
                <NavLink
                  style={tabStyle}
                  className={getStyles(
                    BillingPageTab.openInvoices.toString(),
                    activeTab,
                    isDesktopForNavbar
                  )}
                  onClick={() => {
                    toggle(BillingPageTab.openInvoices.toString());
                  }}
                >
                  <small>Open Invoices</small>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={tabStyle}
                  className={getStyles(
                    BillingPageTab.paidInvoices.toString(),
                    activeTab,
                    isDesktopForNavbar
                  )}
                  onClick={() => {
                    toggle(BillingPageTab.paidInvoices.toString());
                  }}
                >
                  <small>Paid Invoices</small>
                </NavLink>
              </NavItem>
              {showNotSyncedTab ? (
                <NavItem>
                  <NavLink
                    style={tabStyle}
                    className={getStyles(
                      BillingPageTab.invoicesNotSynced.toString(),
                      activeTab,
                      isDesktopForNavbar
                    )}
                    onClick={() => {
                      toggle(BillingPageTab.invoicesNotSynced.toString());
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      style={{ marginRight: "5px" }}
                    />
                    <small>Invoices Not Synced</small>
                  </NavLink>
                </NavItem>
              ) : null}
            </>
          </Nav>

          {isDesktopViewForSectionContents ? (
            <TabContent activeTab={activeTab}>
              {tabs.map((tab) => (
                <TabPaneAndContainerLazyLoaded
                  activeTab={activeTab}
                  tabId={tab.tabId.toString()}
                  key={tab.tabId.toString()}
                >
                  {tab.render()}
                </TabPaneAndContainerLazyLoaded>
              ))}
            </TabContent>
          ) : (
            <MobileDetailsPageTabContents>
              {tabs.map((tab) => (
                <React.Fragment key={tab.tabId.toString()}>
                  {tab.tabId.toString() === activeTab ? tab.render() : null}
                </React.Fragment>
              ))}
            </MobileDetailsPageTabContents>
          )}
        </div>
      )}

      {showPaymentsPortalModal ? (
        <PaymentsPortalModal
          onClose={() => setShowPaymentsPortalModal(false)}
        />
      ) : null}
    </PageWithNavBar2>
  );
};

export default Invoicing;

function useLoadShowNotSyncedTab() {
  const isQuickBooksEnabled = useApplicationStateSelector(
    (s) => s.common.isQuickBooksEnabled
  );

  const [showNotSyncedTab, setShowNotSyncedTab] = useState(false);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    if (isQuickBooksEnabled) {
      let subscription = remoteDataProvider
        .getInvoices({
          top: 1,
          notSyncedToQuickBooks: true,
        })
        .subscribe(
          (r) => {
            setShowNotSyncedTab(r.list.length > 0);
            setLoadingData(false);
          },
          () => {
            setLoadingData(false);
          }
        );

      return function cleanup() {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    } else {
      setLoadingData(false);
    }
  }, [setLoadingData, isQuickBooksEnabled]);

  return { loadingData, showNotSyncedTab };
}

function useLoadShowDraftsTab() {
  const [showDraftsTab, setShowDraftsTab] = useState(false);
  const [loadingData, setLoadingData] = useState(true);

  const invoiceSaveCount = useApplicationStateSelector(
    (s) => s.forms.invoice.saveCount
  );

  useEffect(() => {
    let subscription: Subscription | null = null;

    if (!showDraftsTab) {
      subscription = invoiceDataProvider
        .getInvoiceDrafts({
          top: 1,
        })
        .subscribe(
          (drafts) => {
            setShowDraftsTab(drafts.length > 0);
            setLoadingData(false);
          },
          () => {
            setLoadingData(false);
          }
        );
    }

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
    // Recheck drafts when saving an invoice
    // in case a draft is being added on the save
  }, [showDraftsTab, invoiceSaveCount]);

  return { loadingData, showDraftsTab };
}

function getStyles(
  tabIndex: string,
  activeTab: string,
  isDesktopNavbar: boolean
) {
  return (
    "text-center " +
    (isDesktopNavbar ? "" : " border rounded-0 ") +
    (activeTab === tabIndex ? "active" : "")
  );
}

function PaymentsPortalModal({ onClose }: { onClose: () => void }) {
  return (
    <Modal isOpen>
      <ModalHeader>Payments Portal</ModalHeader>
      <ModalBody>
        <div className="mb-2">
          Login to your payment processing portal for advanced access to view
          and manage your merchant account.
        </div>
        <div className="mb-2">
          If you've forgotten your username or password,{" "}
          <a
            href="https://youraspire.atlassian.net/servicedesk/customer/portal/9"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact support
          </a>
          .
        </div>
        <div className="mb-2">
          <strong>Help resources:</strong>
          <ul>
            <li>
              <a
                href="https://aspire-extensions.document360.io/crew-control/docs/how-to-change-your-bank-account"
                target="_blank"
                rel="noopener noreferrer"
              >
                How to change your bank account for withdrawals/deposits
              </a>
            </li>
            <li>
              <a
                href="https://aspire-extensions.document360.io/crew-control/docs/fee-reconciliation"
                target="_blank"
                rel="noopener noreferrer"
              >
                Payment processing fee reconciliation
              </a>
            </li>
          </ul>
        </div>
        <div className="mb-2 text-center">
          <a
            href="https://payments.youraspire.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn btn-primary">Go To Portal</button>
          </a>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-secondary" onClick={onClose}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
}
