import { ICustomerAdditionalLocation } from "../models/ICustomerAdditionalLocation";
import { containsText } from "./stringService";

function isMatchingItem(item: ICustomerAdditionalLocation, input: string) {
  let inputParts = input?.split(" ") ?? "";

  if (inputParts) {
    let result = true;
    inputParts.forEach((p) => {
      if (result) {
        result = isMatch(item, p);
      }
    });
    return result;
  } else {
    return false;
  }
}

function isMatch(item: ICustomerAdditionalLocation, input: string) {
  let result =
    containsText(item.name, input) ||
    containsText(item.streetAndNumber, input) ||
    containsText(item.city, input) ||
    containsText(item.state, input) ||
    containsText(item.zip, input);

  return result;
}

const CustomerLocationHelper = { isMatchingItem };

export function getLocationName(
  customerAdditionalLocationId: string,
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>
) {
  const foundLocation = customerAdditionalLocations.find(
    (l) => l.id === customerAdditionalLocationId
  );
  if (!foundLocation) {
    console.error(
      `unable to find location for ${customerAdditionalLocationId}`
    );
    return "";
  }
  return (foundLocation.name || foundLocation.streetAndNumber || "").trim();
}

export default CustomerLocationHelper;
