import { IMaintenanceJob } from "../models/IMaintenanceJob";
import { IJobInstance } from "../models/IJobInstance";
import { IJob, JobType } from "../models/IJob";
import { IOneTimeJob } from "../models/IOneTimeJob";
import { IDaySchedule } from "../models/IDaySchedule";
import { IUnscheduledMaintenanceJob } from "../models/IUnscheduledMaintenanceJob";

export interface IFoundJob extends IJob {
  type: JobType;
}

function getJobForDayScheduleV3(
  jobInstanceId: string,
  maintenanceJobs: Array<IMaintenanceJob>,
  oneTimeJobs: Array<IOneTimeJob>,
  daySchedules: Array<IDaySchedule>,
  weeksUnscheduledMaintenanceJobs: Array<IUnscheduledMaintenanceJob>
) {
  const jobInstance = getJobInstance(
    daySchedules,
    weeksUnscheduledMaintenanceJobs,
    jobInstanceId
  );
  if (!jobInstance) {
    return null;
  }

  return getJobForDayScheduleV2(maintenanceJobs, oneTimeJobs, jobInstance);
}

function getJobInstance(
  daySchedules: IDaySchedule[],
  weeksUnscheduledMaintenanceJobs: IUnscheduledMaintenanceJob[],
  jobInstanceId: string
) {
  const dayScheduleJobInstances = daySchedules.reduce(
    (jobInstances, daySchedule) => {
      return [...jobInstances, ...daySchedule.jobInstances];
    },
    [] as Array<IJobInstance>
  );

  const unscheduledJobInstances = weeksUnscheduledMaintenanceJobs.reduce(
    (jobInstances, unscheduledJobs) => {
      return [...jobInstances, ...unscheduledJobs.jobInstances];
    },
    [] as Array<IJobInstance>
  );

  const allJobInstances = [
    ...dayScheduleJobInstances,
    ...unscheduledJobInstances,
  ];

  const jobInstance = allJobInstances.find((ji) => ji.id === jobInstanceId);
  return jobInstance;
}

function getJobForDayScheduleV2(
  maintenanceJobs: Array<IMaintenanceJob>,
  oneTimeJobs: Array<IOneTimeJob>,
  jobInstance: IJobInstance
): IFoundJob | null {
  if (jobInstance.jobId) {
    const foundMaintenanceJob = getJobById(maintenanceJobs, jobInstance.jobId);

    if (foundMaintenanceJob) {
      return {
        ...foundMaintenanceJob,
        type: JobType.maintenanceJob,
      };
    } else {
      return null;
    }
  } else if (jobInstance.oneTimeJobId) {
    const foundOneTimeJob = getOneTimeJobById(
      oneTimeJobs,
      jobInstance.oneTimeJobId
    );

    if (foundOneTimeJob) {
      return {
        ...foundOneTimeJob,
        type: JobType.oneTimeJob,
      };
    } else {
      return null;
    }
  } else {
    throw new Error(
      `job instance '${jobInstance.id}' did not have jobId or oneTimeJob set`
    );
  }
}

function getJobById(
  maintenanceJobs: Array<IMaintenanceJob>,
  jobId: string
): IJob | null {
  const foundMaintenanceJob = maintenanceJobs.find((j) => j.id === jobId);
  return foundMaintenanceJob || null;
}

function getOneTimeJobById(
  oneTimeJobs: Array<IOneTimeJob>,
  jobId: string
): IJob | null {
  const foundOneTimeJob = oneTimeJobs.find((j) => j.id === jobId);
  return foundOneTimeJob || null;
}

export default {
  getJobForDayScheduleV3,
  getJobForDayScheduleV2,
  getJobById,
  getOneTimeJobById,
  getJobInstance,
};
