export interface ICreditCardTransaction {
  id: string;
  createdDate: string;
  amount: number;
  customerName: string;
  customerId: string;
  status: PayrixTransactionStatus;
  authorizationCode: string;
}

export enum PayrixTransactionStatus {
  pending = 0,
  approved = 1,
  failed = 2,
  captured = 3,
  settled = 4,
  returned = 5,
}
