import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FormContainer from "../components/FormContainer";
import { actionCreators } from "../../../modules/actionCreators";
import { IRootState } from "../../../store";
import { IDaySchedule } from "../../../models/IDaySchedule";
import TextareaAutosize from "react-autosize-textarea/lib";
import { ICrew } from "../../../models/ICrew";
import format from "date-fns/format";
import { ICrewMemberIdentifier } from "../../../models/ICrewMemberIdentifier";
import CrewMemberSelection from "../components/CrewMemberSelection";
import TimeInput from "../components/TimeInput";

interface IProps {
  dayScheduleId: string;
  crews: Array<ICrew>;
  showForm: boolean;
  errorMessage: string | React.ReactNode;
  saving: boolean;
  startSave(formData: any): void;
  cancel(): void;
  setErrorMessage(errorMessage: string): void;
  daySchedules: Array<IDaySchedule>;
}

interface IFormData {
  dayScheduleId: string | null;
  notes: string;
  notesForCrew: string;
  crewMembers: Array<ICrewMemberIdentifier>;
  rosterOverridden: boolean;
  shopClockIn: string;
  shopClockOut: string;
}

const DayScheduleForm: React.SFC<IProps> = ({
  dayScheduleId,
  crews,
  showForm,
  errorMessage,
  saving,
  startSave,
  cancel,
  setErrorMessage,
  daySchedules,
}) => {
  const daySchedule = daySchedules.find((ds) => ds.id === dayScheduleId);

  const [formData, setFormData] = useState<IFormData>(getEmptyFormData());
  const [originalFormData, setOriginalFormData] = useState<IFormData>(
    getEmptyFormData()
  );

  useEffect(() => {
    if (dayScheduleId !== formData.dayScheduleId) {
      let newFormData: IFormData;
      if (dayScheduleId) {
        newFormData = {
          dayScheduleId,
          notes: daySchedule ? daySchedule.notes || "" : "",
          notesForCrew: daySchedule ? daySchedule.notesForCrew || "" : "",
          crewMembers: daySchedule ? daySchedule.crewMembers : [],
          rosterOverridden: daySchedule ? daySchedule.rosterOverridden : false,
          shopClockIn: (daySchedule ? daySchedule.shopClockIn : "") || "",
          shopClockOut: (daySchedule ? daySchedule.shopClockOut : "") || "",
        };
      } else {
        newFormData = {
          dayScheduleId: null,
          notes: "",
          notesForCrew: "",
          crewMembers: [],
          rosterOverridden: false,
          shopClockIn: "",
          shopClockOut: "",
        };
      }
      setFormData(newFormData);
      setOriginalFormData(newFormData);
    }
  }, [dayScheduleId, daySchedule, formData]);

  if (!daySchedule) {
    return null;
  }

  let header = "Notes";

  const crew = crews.find((c) => c.id === daySchedule.crewId);
  if (crew) {
    header = `Notes for ${crew.name} on ${format(daySchedule.date, "M/D")}`;
  }

  return (
    <FormContainer
      setErrorMessage={setErrorMessage}
      validate={() => ({ valid: true })}
      getFormData={() => {
        return formData;
      }}
      formHeader={header}
      showForm={showForm}
      errorMessage={errorMessage}
      saving={saving}
      startSave={startSave}
      cancel={cancel}
      formKey="crewNotes"
      hasFormDataChanged={() => {
        return JSON.stringify(formData) !== JSON.stringify(originalFormData);
      }}
    >
      <div className="form-group">
        <label htmlFor="shopClockIn">Shop clock in</label>
        <TimeInput
          className="form-control"
          value={formData.shopClockIn}
          id="shopClockIn"
          onChange={(time) =>
            setFormData({
              ...formData,
              shopClockIn: time,
            })
          }
        />
      </div>
      <div className="form-group">
        <label htmlFor="shopClockOut">Shop clock out</label>
        <TimeInput
          className="form-control"
          value={formData.shopClockOut}
          id="shopClockOut"
          onChange={(time) =>
            setFormData({
              ...formData,
              shopClockOut: time,
            })
          }
        />
      </div>
      <div className="form-group">
        <label htmlFor="notes">Administrator only notes</label>
        <TextareaAutosize
          maxRows={10}
          className="form-control"
          name="notes"
          id="notes"
          value={formData.notes}
          onChange={(e) => {
            setFormData({
              ...formData,
              notes: e.currentTarget.value,
            });
          }}
        />
      </div>
      <div className="form-group">
        <label htmlFor="notesForCrew">Notes for crew</label>
        <TextareaAutosize
          maxRows={10}
          className="form-control"
          name="notesForCrew"
          id="notesForCrew"
          value={formData.notesForCrew}
          onChange={(e) => {
            setFormData({
              ...formData,
              notesForCrew: e.currentTarget.value,
            });
          }}
        />
      </div>
      <CrewMemberSelection
        usePortal={true}
        value={formData.crewMembers}
        onChange={(newCrewMembers) =>
          setFormData({
            ...formData,
            rosterOverridden: true,
            crewMembers: newCrewMembers,
          })
        }
      />
    </FormContainer>
  );
};

const mapStateToProps = (state: IRootState) => ({
  daySchedules: state.schedule.daySchedules,
  crews: state.crew.crews,
  dayScheduleId: state.forms.daySchedule.parameters
    ? state.forms.daySchedule.parameters.dayScheduleId
    : null,
  showForm: state.forms.daySchedule.showForm,
  errorMessage: state.forms.daySchedule.errorMessage,
  saving: state.forms.daySchedule.saving,
  tenantId: state.common.tenantId,
  imagePrefix: state.common.imagePrefix || "",
});

const mapDispatchToProps = {
  startSave: actionCreators.forms.daySchedule.startSaving,
  cancel: actionCreators.forms.daySchedule.cancelForm,
  setErrorMessage: actionCreators.forms.daySchedule.setErrorMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(DayScheduleForm);

function getEmptyFormData(): IFormData | (() => IFormData) {
  return {
    dayScheduleId: null,
    notes: "",
    notesForCrew: "",
    crewMembers: [],
    rosterOverridden: false,
    shopClockIn: "",
    shopClockOut: "",
  };
}
