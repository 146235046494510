import { PersonalizeButtonsWrapper } from "../../../containers/app/components/RichTextEditor";
import { Editor } from "react-draft-wysiwyg";
import { useState } from "react";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import InvoiceConfigurationForm from "../../../containers/app/forms/InvoiceConfigurationForm";
import { CustomerCommunicationSettingsReminderConfiguration } from "./CustomerCommunicationSettingsReminderConfiguration";

interface IProps {
  value: CustomerCommunicationSettingsReminderConfiguration;
  onChange: (
    newValue: CustomerCommunicationSettingsReminderConfiguration
  ) => void;
  valuePrefix: string;
  optionLabel: string;
  daysLabel: string;
  allowAdditionalReminder: boolean;
  allowInvoiceConfigurationLink: boolean;
}

const CustomerCommunicationReminderOption: React.FunctionComponent<IProps> = ({
  value,
  onChange,
  valuePrefix,
  optionLabel,
  daysLabel,
  allowAdditionalReminder,
  allowInvoiceConfigurationLink,
}) => {
  const [showInvoiceConfigurationForm, setShowInvoiceConfigurationForm] =
    useState(false);

  const invoiceConfiguration = useApplicationStateSelector(
    (s) => s.common.invoiceConfiguration
  );

  return (
    <>
      <div className="mb-2">
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            data-testid={valuePrefix + "_IsEnabled"}
            className="custom-control-input"
            id={`${valuePrefix}_ReminderEnabled`}
            checked={value.isEnabled}
            onChange={(e) => {
              onChange({
                ...value,
                isEnabled: e.target.checked,
              });
            }}
          />
          <label
            className="custom-control-label"
            htmlFor={`${valuePrefix}_ReminderEnabled`}
            style={{
              verticalAlign: "middle",
            }}
          >
            <h6>{optionLabel}</h6>
          </label>
        </div>
      </div>

      {typeof invoiceConfiguration.invoiceDefaultNumberOfDaysValid !==
        "number" &&
      value.isEnabled &&
      allowInvoiceConfigurationLink ? (
        <button
          type="button"
          className="btn btn-sm btn-link custom-control"
          style={{ marginTop: "-15px" }}
          data-testid={`${valuePrefix}_UpdateDefaultDaysDue`}
          onClick={() => {
            setShowInvoiceConfigurationForm(true);
          }}
        >
          Configure default days until invoices are due
        </button>
      ) : null}

      {value.isEnabled ? (
        <div className="form-section bg-light">
          <div className="p-4 mb-2">
            <div className="form-row">
              <div className="form-group col-12 col-md-6">
                <label
                  htmlFor={`${valuePrefix}ReplyToEmailAddress`}
                  className={"required"}
                >
                  Reply to email address
                </label>
                <div key={`${valuePrefix}ReplyToEmailAddress`}>
                  <input
                    id={`${valuePrefix}ReplyToEmailAddress`}
                    data-testid={`${valuePrefix}_ReplyToEmailAddress`}
                    className="form-control"
                    type="email"
                    value={value.replyToEmailAddress}
                    required={value.isEnabled}
                    onChange={(e) => {
                      onChange({
                        ...value,
                        replyToEmailAddress: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-12">
                <label htmlFor={`${valuePrefix}Subject`} className={"required"}>
                  Subject
                </label>
                <div key={`${valuePrefix}Subject`}>
                  <input
                    id={`${valuePrefix}Subject`}
                    data-testid={`${valuePrefix}_Subject`}
                    className="form-control"
                    type="text"
                    value={value.subject ?? ""}
                    required={value.isEnabled}
                    onChange={(e) => {
                      onChange({
                        ...value,
                        subject: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-12">
                <label
                  htmlFor={`${valuePrefix}EmailMessage`}
                  className={value.isEnabled ? "required" : ""}
                >
                  Message
                </label>
                <div
                  data-testid={`${valuePrefix}_EmailMessage`}
                  id={`${valuePrefix}EmailMessage`}
                >
                  <Editor
                    ariaLabel={`${valuePrefix}EmailMessage`}
                    editorState={value.messageTextState}
                    onEditorStateChange={(newText) => {
                      onChange({
                        ...value,
                        messageTextState: newText,
                      });
                    }}
                    editorClassName="richtext-editor bg-white"
                    toolbarCustomButtons={[
                      <PersonalizeButtonsWrapper hideAddressOption={true} />,
                    ]}
                    stripPastedStyles={true}
                    placeholder="Test"
                    toolbar={{
                      options: ["inline", "list", "textAlign"],
                      inline: {
                        inDropdown: false,
                        options: ["bold", "italic", "underline"],
                      },
                      list: {
                        options: ["unordered", "ordered"],
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-12 col-md-6">
                <label
                  htmlFor={`${valuePrefix}FirstReminderNumberOfDays`}
                  className={"required"}
                >
                  {daysLabel}
                </label>
                <div key={`${valuePrefix}FirstReminderNumberOfDays`}>
                  <input
                    id={`${valuePrefix}FirstReminderNumberOfDays`}
                    data-testid={`${valuePrefix}_FirstReminderNumberOfDays`}
                    className="form-control"
                    type="number"
                    value={value.firstReminderNumberOfDays ?? ""}
                    required={value.isEnabled}
                    onChange={(e) => {
                      onChange({
                        ...value,
                        firstReminderNumberOfDays: parseInt(e.target.value),
                      });
                    }}
                    min={0}
                    max={120}
                  />
                </div>
              </div>
            </div>

            {allowAdditionalReminder ? (
              <>
                <div className="form-row">
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`${valuePrefix}IsSecondReminderEnabled`}
                        data-testid={`${valuePrefix}_IsSecondReminderEnabled`}
                        checked={value.isSecondReminderEnabled}
                        onChange={(e) => {
                          onChange({
                            ...value,
                            isSecondReminderEnabled: e.target.checked,
                          });
                        }}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`${valuePrefix}IsSecondReminderEnabled`}
                      >
                        Send another reminder
                      </label>
                    </div>
                  </div>
                </div>

                {value.isSecondReminderEnabled ? (
                  <div className="form-row">
                    <div className="form-group col-12 col-md-6 ml-3">
                      <label
                        htmlFor={`${valuePrefix}SecondReminderNumberOfDays`}
                        className={"required"}
                      >
                        {daysLabel}
                      </label>
                      <div key={`${valuePrefix}SecondReminderNumberOfDays`}>
                        <input
                          id={`${valuePrefix}SecondReminderNumberOfDays`}
                          data-testid={`${valuePrefix}_SecondReminderNumberOfDays`}
                          className="form-control"
                          type="number"
                          value={value.secondReminderNumberOfDays ?? ""}
                          required={true}
                          onChange={(e) => {
                            onChange({
                              ...value,
                              secondReminderNumberOfDays: parseInt(
                                e.target.value
                              ),
                            });
                          }}
                          min={0}
                          max={120}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      ) : null}

      {showInvoiceConfigurationForm ? (
        <InvoiceConfigurationForm
          onClose={() => {
            setShowInvoiceConfigurationForm(false);
          }}
          onSaveComplete={(newValue) => {
            setShowInvoiceConfigurationForm(false);
          }}
        />
      ) : null}
    </>
  );
};

export default CustomerCommunicationReminderOption;
