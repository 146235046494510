import { MaintenanceJobFrequency } from "../../../models/IMaintenanceJob";
import { getDayOfWeekForDisplay } from "../../../services/jobService";
import { IFrequency } from "./FrequencySelection";
import Select from "react-select";
import { useEffect, useRef } from "react";
import constants from "../../../constants";
import { FlexibleJobToolTip } from "./FlexibleJobToolTip";

type Option = {
  value: string;
  label: string;
};

interface IProps {
  value: Array<string>;
  onChange: (newValue: Array<string>) => void;
  frequency: IFrequency;
  disabled: boolean;
  jobDisabled: boolean;
  isDailyFrequency: boolean;
  idPrefix: string;
  label: string;
  hideFlexibleOption?: boolean;
}

const MaintenanceJobDayOfWeekSelection: React.FunctionComponent<IProps> = ({
  value,
  onChange,
  frequency,
  disabled,
  jobDisabled,
  isDailyFrequency,
  idPrefix,
  label,
  hideFlexibleOption,
}) => {
  const id = `${idPrefix}dayOfWeek`;
  const inputRef = useRef<HTMLInputElement | null>();

  let options: Array<Option> = [];
  options.push(getDayOfWeekOption("7"));
  options.push(getDayOfWeekOption("1"));
  options.push(getDayOfWeekOption("2"));
  options.push(getDayOfWeekOption("3"));
  options.push(getDayOfWeekOption("4"));
  options.push(getDayOfWeekOption("5"));
  options.push(getDayOfWeekOption("6"));
  const isFlexibleJobSelected = value.includes(constants.flexibleDayOfWeekId);

  let placeholderText = "Select...";
  let optionValues = options.filter((o) => value.includes(o.value));

  if (jobDisabled) {
    placeholderText = "Not applicable for inactive jobs";
    optionValues = [];
    hideFlexibleOption = true;
  } else if (isDailyFrequency) {
    placeholderText = `Not applicable for ${
      frequency.frequencyType === MaintenanceJobFrequency.Daily
        ? "daily"
        : "custom daily"
    } frequencies`;
    optionValues = [];
    hideFlexibleOption = true;
  } else if (isFlexibleJobSelected) {
    placeholderText = "Not applicable for flexible jobs";
    optionValues = [];
  }

  const isSelectDisabled = disabled || isFlexibleJobSelected;
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setCustomValidity(
        value && value.length > 0
          ? ""
          : "At least one day of the week must be selected"
      );
    }
  }, [value, isSelectDisabled]);

  return (
    <>
      {!hideFlexibleOption && !disabled ? (
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="flexibleMaintenanceJob"
              checked={isFlexibleJobSelected}
              onChange={(e) => {
                let checked = e.target.checked;
                if (checked) {
                  onChange([...value, constants.flexibleDayOfWeekId]);
                } else {
                  onChange(
                    value.filter((v) => v !== constants.flexibleDayOfWeekId)
                  );
                }
              }}
            />
            <label
              className="custom-control-label"
              htmlFor="flexibleMaintenanceJob"
            >
              Flexible job?
            </label>
            <FlexibleJobToolTip />
          </div>
        </div>
      ) : null}

      <div className="form-group">
        <label htmlFor={id} className={isSelectDisabled ? "" : "required"}>
          {label}
        </label>
        <Select
          placeholder={placeholderText}
          ref={(r) => (inputRef.current = r?.inputRef)}
          inputId={id}
          options={options}
          value={optionValues}
          isMulti={true}
          isDisabled={isSelectDisabled}
          onChange={(e) => {
            onChange(e.map((o) => o.value));
          }}
        />
      </div>
    </>
  );
};

export default MaintenanceJobDayOfWeekSelection;

function getDayOfWeekOption(value: string) {
  return {
    value,
    label: getDayOfWeekForDisplay(value),
  };
}
