import React, { useState, useMemo, useEffect, useRef } from "react";
import { connect } from "react-redux";
import FormContainer from "../components/FormContainer";
import { actionCreators } from "../../../modules/actionCreators";
import { IRootState } from "../../../store";
import uuidv4 from "uuid/v4";
import { ITodoTemplate } from "../../../models/ITodoTemplate";
import ManageTodoItems, { ITodoItem } from "../components/ManageTodoItems";

interface IProps {
  todoTemplates: Array<ITodoTemplate>;
  todoTemplateId: string | null;
  defaultName: string | null;
  defaultTodoItems: Array<ITodoItem> | null;
  showForm: boolean;
  errorMessage: string | React.ReactNode;
  saving: boolean;
  startSave(formData: any): void;
  cancel(): void;
  setErrorMessage(errorMessage: string): void;
}

const TodoTemplateForm: React.SFC<IProps> = ({
  showForm,
  startSave,
  cancel,
  errorMessage,
  saving,
  setErrorMessage,
  todoTemplateId,
  todoTemplates,
  defaultName,
  defaultTodoItems,
}) => {
  const [name, setName] = useState("");
  const [todoItems, setTodoItems] = useState<Array<ITodoItem>>([]);

  const [originalName, setOriginalName] = useState("");
  const [originalTodoItems, setOriginalTodoItems] = useState<Array<ITodoItem>>(
    []
  );

  const nameRef = useRef<HTMLInputElement>(null);

  const previousShowForm = useRef(false);

  useEffect(() => {
    if (showForm !== previousShowForm.current && showForm) {
      if (!todoTemplateId) {
        const mappedDefaultTodoItems = (defaultTodoItems ?? []).map((t) => ({
          ...t,
          id: null,
          tempId: uuidv4(),
        }));

        setName(defaultName ?? "");
        setTodoItems(mappedDefaultTodoItems);
        setOriginalName(defaultName ?? "");
        setOriginalTodoItems(mappedDefaultTodoItems);

        setTimeout(() => {
          if (nameRef.current) {
            nameRef.current.focus();
          }
        });
      } else {
        const todoTemplate = getTodoTemplate(todoTemplates, todoTemplateId);

        const mappedTodoItems = todoTemplate.todoItems.map((t) => ({
          ...t,
          tempId: uuidv4(),
        }));
        setName(todoTemplate.name);
        setTodoItems(mappedTodoItems);
        setOriginalName(todoTemplate.name);
        setOriginalTodoItems(mappedTodoItems);
      }
    }

    previousShowForm.current = showForm;
  }, [showForm, todoTemplates, todoTemplateId, defaultName, defaultTodoItems]);

  const formHeader = useMemo(() => {
    if (todoTemplateId) {
      const todoTemplate = getTodoTemplate(todoTemplates, todoTemplateId);
      return `Update ${todoTemplate.name}`;
    } else {
      return "Add Checklist Template";
    }
  }, [todoTemplates, todoTemplateId]);

  return (
    <FormContainer
      setErrorMessage={setErrorMessage}
      validate={() => ({ valid: true, errorMessage: "" })}
      getFormData={() => {
        return { name, todoItems: todoItems.filter((t) => t.text.trim()) };
      }}
      formHeader={formHeader}
      showForm={showForm}
      errorMessage={errorMessage}
      saving={saving}
      startSave={startSave}
      cancel={cancel}
      formKey="todoTemplate"
      hasFormDataChanged={() => {
        return (
          JSON.stringify({ name, todoItems }) !==
          JSON.stringify({ name: originalName, todoItems: originalTodoItems })
        );
      }}
    >
      <div className="form-group">
        <label htmlFor="name" className="required">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          name="name"
          id="name"
          required={true}
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          ref={nameRef}
        />
      </div>
      <h5>Checklist Items</h5>
      <ManageTodoItems
        value={todoItems}
        onChange={(newTodoItems) => {
          setTodoItems(newTodoItems);
        }}
        noItemsText="No checklist items are set for this template."
      />
    </FormContainer>
  );
};

const mapStateToProps = (state: IRootState) => ({
  todoTemplates: state.common.todoTemplates,
  todoTemplateId: state.forms.todoTemplate?.parameters?.todoTemplateId ?? null,
  defaultName: state.forms.todoTemplate?.parameters?.defaultName ?? null,
  defaultTodoItems:
    state.forms.todoTemplate?.parameters?.defaultTodoItems ?? null,
  showForm: state.forms.todoTemplate.showForm,
  errorMessage: state.forms.todoTemplate.errorMessage,
  saving: state.forms.todoTemplate.saving,
});

const mapDispatchToProps = {
  startSave: actionCreators.forms.todoTemplate.startSaving,
  cancel: actionCreators.forms.todoTemplate.cancelForm,
  setErrorMessage: actionCreators.forms.todoTemplate.setErrorMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(TodoTemplateForm);
function getTodoTemplate(
  todoTemplates: ITodoTemplate[],
  todoTemplateId: string
) {
  const todoTemplate = todoTemplates.find((c) => c.id === todoTemplateId);
  if (!todoTemplate) {
    throw new Error(`todoTemplate not found ${todoTemplateId}`);
  }
  return todoTemplate;
}
