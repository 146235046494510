import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import BankAccountInline from "../BankAccountInline";
import { IOnboardingFormData } from "./IOnboardingFormData";

interface IProps {
  setValue: UseFormSetValue<IOnboardingFormData>;
  watch: UseFormWatch<IOnboardingFormData>;
}

const AddBankAccountTab: React.FunctionComponent<IProps> = ({
  setValue,
  watch,
}) => {
  const bankAccountNumber = watch("bankAccountNumber");
  const bankAccountNumberConfirmed = watch("bankAccountNumberConfirmed");
  const bankAccountType = watch("bankAccountType");
  const bankRoutingNumber = watch("bankRoutingNumber");

  return (
    <>
      <div className="mb-2">
        Please enter the bank account details for the account you would like to
        receive your payment deposits.
      </div>

      <BankAccountInline
        value={{
          bankAccountNumber,
          bankAccountNumberConfirmed,
          bankAccountType,
          bankRoutingNumber,
        }}
        onChange={(newValue) => {
          setValue("bankAccountNumber", newValue.bankAccountNumber);
          setValue(
            "bankAccountNumberConfirmed",
            newValue.bankAccountNumberConfirmed
          );
          setValue("bankAccountType", newValue.bankAccountType);
          setValue("bankRoutingNumber", newValue.bankRoutingNumber);
        }}
      />
    </>
  );
};

export default AddBankAccountTab;
