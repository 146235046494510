import TableColumn, {
  HeaderFunction as HeaderFunctionArguments,
} from "./TableColumn";

export default function getCellHeader<T>(
  column: TableColumn<T>,
  headerArguments: HeaderFunctionArguments<T>
): React.ReactNode {
  return typeof column.header === "function"
    ? column.header(headerArguments)
    : column.header;
}
