import uuidv4 from "uuid/v4";
import { DepositType } from "../../../enums/DepositType";
import { IDepositSettings } from "../../../models/IDepositSettings";
import { ILineItem } from "../../../containers/app/components/InvoiceLineItem";
import { IProposalLineItem } from "../models/IProposal";

export function isDepositRequired(
  depositSettings: IDepositSettings | null
): boolean {
  return (
    (depositSettings?.type === DepositType.percent &&
      (depositSettings?.percent ?? 0) > 0) ||
    (depositSettings?.type === DepositType.amount &&
      (depositSettings?.amount ?? 0) > 0)
  );
}

export function mapToFormLineItems(
  proposalLineItems: Array<IProposalLineItem> | null
): ILineItem[] | null {
  return proposalLineItems && proposalLineItems.length > 0
    ? proposalLineItems.map((li) => {
        return {
          id: li.id ?? uuidv4(),
          amountPerItem:
            typeof li.amount === "number" ? li.amount.toString() : "",
          description: li.description,
          itemId: li.itemId,
          quantity:
            typeof li.quantity === "number" ? li.quantity.toString() : "",
          taxable: li.taxable,
          hide: li.hide,
          name: li.name,
          optional: li.optional,
          selected: li.selected,
        };
      })
    : null;
}
