import { useEffect, useRef, useState } from "react";
import Select, { components, OptionProps, SelectInstance } from "react-select";
import { useApplicationStateSelector } from "../../../hooks/useApplicationStateSelector";
import CustomerNotificationsMessageTest from "./CustomerNotificationsMessageTest";
import {
  buildMessage,
  replaceKnownPlaceholders,
} from "./CustomerNotificationsTemplatedMessage.functions";
import {
  EmailAddressPlaceholder,
  PhoneNumberPlaceholder,
} from "./CustomerNotificationsTemplatedMessage.options";
import { CustomerNotificationTemplateType } from "../../../enums/customerNotificationTemplateType";
import { ITemplatedMessage } from "../../../models/IITemplatedMessage";
import { TemplateVariableValues } from "../../../models/ICustomerNotificationsConfiguration";
import LinkButton2 from "./LinkButton2";
import CustomerNotificationsTemplatedMessageDefaultsForm from "./CustomerNotificationsTemplatedMessageDefaultsForm";
import CustomerNotificationsTemplatedMessageBuilder, {
  getTemplateVariableDefaultValues,
} from "./CustomerNotificationsTemplatedMessageBuilder";
import { CrewScheduleType } from "../../../slices/schedule/enums/crewScheduleType";

type OnChange = (args: {
  value: string;
  selectedTemplateId: string | null;
  variables: TemplateVariableValues;
}) => void;

interface IProps {
  value: string;
  onChange: OnChange;
  templateType: CustomerNotificationTemplateType;
  hardcodedVariables?: TemplateVariableValues;
  variables: TemplateVariableValues;
  selectedTemplateId: string | null;
  required?: boolean;
}

type TemplateOption = ITemplatedMessage & { lastOption: boolean };

const Option = (props: OptionProps<TemplateOption, false>) => {
  return (
    <div data-testid="templateOption">
      <components.Option {...props} />
      {!props.data.lastOption ? <hr className="my-2" /> : null}
    </div>
  );
};

const CustomerNotificationsTemplatedMessage: React.FunctionComponent<
  IProps
> = ({
  value,
  onChange,
  templateType,
  hardcodedVariables,
  variables,
  selectedTemplateId,
  required,
}) => {
  const customerNotificationTemplateOptions = useApplicationStateSelector(
    (s) => s.common.customerNotificationTemplateOptions
  );
  const customerNotificationTemplateDefaults = useApplicationStateSelector(
    (s) => s.common.customerNotificationTemplateDefaults
  );
  const optionsToUse = customerNotificationTemplateOptions.filter(
    (o) => o.type === templateType
  );

  const tenantName = useApplicationStateSelector((s) => s.common.tenantName);
  const contactPhoneNumber = useApplicationStateSelector(
    (s) => s.common.contactPhoneNumber
  );
  const contactEmailAddress = useApplicationStateSelector(
    (s) => s.common.emailAddress
  );
  const crews = useApplicationStateSelector((s) => s.crew.crews);
  const hasActiveTimeBasedCrews = crews
    .filter((c) => !c.inactive)
    .some((c) => c.scheduleType === CrewScheduleType.time);

  const [showTestMessageModal, setShowTestMessageModal] = useState(false);
  const [showTemplateDefaultsModal, setShowTemplateDefaultsModal] =
    useState(false);

  useSetDefaultValues({
    variables,
    contactPhoneNumber,
    contactEmailAddress,
    optionsToUse,
    onChange,
    tenantName,
    hardcodedVariables,
    hasActiveTimeBasedCrews,
  });

  useUpdateFromHardcodedVariables({
    optionsToUse,
    onChange,
    tenantName,
    variables,
    hardcodedVariables,
    templateId: selectedTemplateId,
    hasActiveTimeBasedCrews: hasActiveTimeBasedCrews,
  });

  const templateSelectRef = useRef<SelectInstance<TemplateOption> | null>(null);
  useEffect(() => {
    if (required && templateSelectRef.current?.inputRef) {
      templateSelectRef.current.inputRef.setCustomValidity(
        value ? "" : "Please fill out this field"
      );
    }
  }, [required, value]);

  const selectedTemplate = optionsToUse.find(
    (o) =>
      selectedTemplateId &&
      o.id.toLowerCase() === selectedTemplateId.toLowerCase()
  );

  return (
    <>
      <div>
        {optionsToUse.length !== 1 ? (
          <div className="form-group mt-3">
            <label className="required" htmlFor="template">
              Template
            </label>
            <Select
              ref={(r) => (templateSelectRef.current = r)}
              inputId="template"
              options={optionsToUse.map((o, index) => ({
                ...o,
                lastOption: index === optionsToUse.length - 1,
              }))}
              getOptionLabel={(o) =>
                replaceKnownPlaceholders(o, false, tenantName)
              }
              getOptionValue={(o) => o.id}
              components={{
                Option,
              }}
              isMulti={false}
              onChange={(e) => {
                if (e?.id) {
                  let selectedTemplate = optionsToUse.find(
                    (o) => o.id.toLowerCase() === e.id.toLowerCase()
                  );
                  variables = getTemplateVariableDefaultValues(
                    customerNotificationTemplateDefaults,
                    variables,
                    selectedTemplate,
                    contactPhoneNumber,
                    contactEmailAddress
                  );
                  onChange({
                    value: buildMessage({
                      selectedTemplate: selectedTemplate,
                      hasActiveTimeBasedCrews: hasActiveTimeBasedCrews,
                      tenantName,
                      editedVariables: variables,
                      hardcodedVariables,
                    }),
                    selectedTemplateId: e?.id ?? null,
                    variables,
                  });
                } else {
                  onChange({
                    value: "",
                    selectedTemplateId: null,
                    variables,
                  });
                }
              }}
              menuPosition="fixed"
              placeholder="Select a text message template..."
            />
            {selectedTemplateId !== null ? (
              <LinkButton2
                buttonContents={<small>Configure your template defaults</small>}
                onClick={() => setShowTemplateDefaultsModal(true)}
              />
            ) : null}
          </div>
        ) : null}
        <CustomerNotificationsTemplatedMessageBuilder
          selectedTemplate={selectedTemplate}
          templateVariables={selectedTemplate?.variables ?? []}
          hardcodedVariables={hardcodedVariables}
          variableValues={variables}
          messageText={value}
          onChange={onChange}
        />
        {selectedTemplateId !== null ? (
          <>
            <div className="d-flex justify-content-between">
              <div>
                <button
                  className="btn btn-link"
                  type="button"
                  style={{ padding: 0 }}
                  onClick={() => setShowTestMessageModal(true)}
                  disabled={value.trim() === ""}
                >
                  <small>Send test</small>
                </button>
              </div>
            </div>
          </>
        ) : null}
      </div>
      {showTestMessageModal && selectedTemplateId ? (
        <CustomerNotificationsMessageTest
          onClose={() => setShowTestMessageModal(false)}
          templateId={selectedTemplateId}
          templateVariables={{
            ...variables,
            ...hardcodedVariables,
          }}
        />
      ) : null}
      {showTemplateDefaultsModal && selectedTemplate ? (
        <CustomerNotificationsTemplatedMessageDefaultsForm
          selectedTemplate={selectedTemplate}
          onCancel={() => setShowTemplateDefaultsModal(false)}
          onSaveComplete={(defaultValues) => {
            Object.keys(defaultValues).forEach((key) => {
              if (defaultValues[key].length > 0) {
                variables = {
                  ...variables,
                  [key]: defaultValues[key],
                };
              }
            });

            onChange({
              value: buildMessage({
                selectedTemplate: selectedTemplate,
                hasActiveTimeBasedCrews: hasActiveTimeBasedCrews,
                tenantName,
                editedVariables: variables,
                hardcodedVariables,
              }),
              selectedTemplateId: selectedTemplate.id,
              variables: variables,
            });

            setShowTemplateDefaultsModal(false);
          }}
        />
      ) : null}
    </>
  );
};

export default CustomerNotificationsTemplatedMessage;

function useSetDefaultValues({
  variables,
  contactPhoneNumber,
  contactEmailAddress,
  optionsToUse,
  onChange,
  tenantName,
  hardcodedVariables,
  hasActiveTimeBasedCrews,
}: {
  variables: TemplateVariableValues;
  contactPhoneNumber: string;
  contactEmailAddress: string;
  optionsToUse: ITemplatedMessage[];
  onChange: OnChange;
  tenantName: string | null;
  hardcodedVariables: TemplateVariableValues | undefined;
  hasActiveTimeBasedCrews: boolean;
}) {
  const hasTemplatedTemplateValues = useRef(false);
  if (!hasTemplatedTemplateValues.current) {
    const newVariables = {
      ...variables,
      [PhoneNumberPlaceholder]: contactPhoneNumber,
      [EmailAddressPlaceholder]: contactEmailAddress,
    };
    const newSelectedTemplateId = getDefaultTemplateId(optionsToUse);

    onChange({
      value: buildMessage({
        selectedTemplate: optionsToUse.find(
          (o) =>
            newSelectedTemplateId &&
            o.id.toLowerCase() === newSelectedTemplateId.toLowerCase()
        ),
        hasActiveTimeBasedCrews: hasActiveTimeBasedCrews,
        tenantName,
        editedVariables: newVariables,
        hardcodedVariables,
      }),
      selectedTemplateId: newSelectedTemplateId,
      variables: newVariables,
    });

    hasTemplatedTemplateValues.current = true;
  }
}

function useUpdateFromHardcodedVariables({
  optionsToUse,
  onChange,
  tenantName,
  variables,
  hardcodedVariables,
  templateId,
  hasActiveTimeBasedCrews,
}: {
  optionsToUse: ITemplatedMessage[];
  onChange: OnChange;
  tenantName: string | null;
  variables: TemplateVariableValues;
  hardcodedVariables: TemplateVariableValues | undefined;
  templateId: string | null;
  hasActiveTimeBasedCrews: boolean;
}) {
  const oldHardcodedVariables = useRef<TemplateVariableValues | null>(null);

  const variablesChanged =
    hardcodedVariables &&
    oldHardcodedVariables.current !== hardcodedVariables &&
    haveHardcodedVariablesChanged(
      oldHardcodedVariables.current,
      hardcodedVariables
    );

  if (templateId && variablesChanged) {
    onChange({
      value: buildMessage({
        selectedTemplate: optionsToUse.find(
          (o) => o.id.toLowerCase() === templateId.toLowerCase()
        ),
        hasActiveTimeBasedCrews: hasActiveTimeBasedCrews,
        tenantName,
        editedVariables: variables,
        hardcodedVariables,
      }),
      variables,
      selectedTemplateId: templateId,
    });
    oldHardcodedVariables.current = hardcodedVariables;
  }
}

function haveHardcodedVariablesChanged(
  a: TemplateVariableValues | null,
  b: TemplateVariableValues
) {
  return JSON.stringify(a) !== JSON.stringify(b);
}

function getDefaultTemplateId(optionsToUse: ITemplatedMessage[]) {
  return optionsToUse.length === 1 ? optionsToUse[0].id : null;
}
