import { InvoiceDeliveryMethod } from "./InvoiceDeliveryMethod";

export interface ICustomerPaymentConfiguration {
  frequencyType: BillingFrequencyType | null;
  frequencyInterval: number | null;
  startingDate: string | null;
  endingDate: string | null;
  quickBooksCustomerId: string | null;
  hideLineItemPrices: boolean;
  lineItems: Array<ICustomerPaymentConfigurationLineItem> | null;
  allowOnlineCreditCardPayment: boolean;
  allowOnlineAchPayment: boolean;
  addConvenienceFee: boolean;
  introText: string | null;
  footerText: string | null;
  taxRate: number | null;
  invoiceDeliveryMethod: InvoiceDeliveryMethod;
  replyToEmailAddress: string | null;
  paymentMethodOnFileAuthorized: boolean | null;
  numberOfDaysUntilDue: number | null;
}

export interface ICustomerPaymentConfigurationLineItem {
  id: string;
  quickBooksLineItemId: string | null;
  crewControlInvoiceItemId: string | null;
  quantity: number;
  amountPerItem: number;
  name: string | null;
  description: string;
  taxable: boolean | null;
  hide: boolean;
}

export enum BillingFrequencyType {
  daily = 1,
  weekly = 2,
  monthly = 3,
  annually = 4,
  quarterly = 5,
  semiAnnually = 6,
}
