import { actionCreators } from "../actionCreators";
import { ILoadDaySchedulesStartingAction } from "../actionTypeDefinitions";
import dataProvider from "../../services/dataProvider";
import { mergeMap, catchError } from "rxjs/operators";
import {
  loadJobsForSchedule,
  loadWithRetries,
} from "../../services/scheduleService";
import { IRootState } from "../../store";
import { of, TimeoutError } from "rxjs";
import { logError } from "../../services/errorLogger";
import { fullStoryLogError } from "../../services/fullStoryService";

export function loadFlexibleWeekContainers(
  action: ILoadDaySchedulesStartingAction,
  state: IRootState
) {
  if (
    action.weeksUnscheduledMaintenanceJobsToLoad &&
    action.weeksUnscheduledMaintenanceJobsToLoad.length > 0
  ) {
    return loadWithRetries(() =>
      dataProvider.loadDaySchedules(
        [],
        action.weeksUnscheduledMaintenanceJobsToLoad,
        action.maxUnassignedWeekAlreadyLoaded
      )
    ).pipe(
      mergeMap((loadScheduleResult) => {
        return loadJobsForSchedule(loadScheduleResult, state);
      }),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          fullStoryLogError("ScheduleTimeout");
        }

        logError(`Error loading day schedules - ${JSON.stringify(error)}`);
        return of(
          actionCreators.loadDaySchedulesError(
            [],
            action.weeksUnscheduledMaintenanceJobsToLoad
          )
        );
      })
    );
  } else {
    return of(actionCreators.null());
  }
}
