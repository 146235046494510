import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { throwError } from "rxjs";
import { catchError, tap, timeout } from "rxjs/operators";
import { IOnboardingFormData } from "../containers/app/components/merchantOnboarding/IOnboardingFormData";
import { getEmptyFormData } from "../containers/app/components/merchantOnboarding/objectCreators";
import { actionCreators } from "../modules/actionCreators";
import { fullStoryLogError } from "../services/fullStoryService";
import { getErrorMessagesFromError } from "../services/httpErrorHandler";
import remoteDataProvider from "../services/remoteDataProvider";
import { useApplicationStateSelector } from "./useApplicationStateSelector";
import { removeFormattingForCurrencyFields } from "../containers/app/components/CurrencyInputV2.functions";

export function useMerchantOnboardingProvider() {
  const dispatch = useDispatch();

  let defaultBusinessName = useApplicationStateSelector(
    (s) => s.common.tenantName
  );

  const industry = useApplicationStateSelector((s) => s.common.industry);

  if (defaultBusinessName && defaultBusinessName.length > 40) {
    defaultBusinessName = defaultBusinessName.substring(0, 40);
  }

  defaultBusinessName = defaultBusinessName ?? "";

  const emptyFormData = getEmptyFormData({
    irsFilingName: defaultBusinessName,
    dba: defaultBusinessName,
    industry: typeof industry === "number" ? industry?.toString() : "",
  });

  const [originalFormData, setOriginalFormData] =
    useState<IOnboardingFormData>(emptyFormData);
  const [saveErrors, setSaveErrors] = useState<Array<string>>([]);
  const [saving, setSaving] = useState(false);
  const [merchantFormSaved, setMerchantFormSaved] = useState(false);

  const { register, getValues, setValue, watch, control } =
    useForm<IOnboardingFormData>({
      defaultValues: emptyFormData,
    });

  const saveFormData = () => {
    const values = getValues();

    const daytimePhone = values.daytimePhone;
    return remoteDataProvider
      .submitMerchantOnboarding({
        ...values,
        businessName: getValues("irsFilingName"),
        annualProcessingVolume: removeFormattingForCurrencyFields(
          values.annualProcessingVolume
        ),
        averageTransactionAmount: removeFormattingForCurrencyFields(
          values.averageTransactionAmount
        ),
      })
      .pipe(
        timeout(45000),
        catchError((err) => {
          setSaveErrors(getErrorMessagesFromError(err));
          fullStoryLogError(err);

          return throwError(err);
        }),
        tap(() => {
          setMerchantFormSaved(true);
          dispatch(
            actionCreators.merchantOnboardingSubmitted({ daytimePhone })
          );
        })
      );
  };

  return {
    originalFormData,
    setOriginalFormData,
    saveErrors,
    setSaveErrors,
    saving,
    setSaving,
    saveFormData,
    merchantFormSaved,
    register,
    getValues,
    setValue,
    watch,
    control,
  };
}
