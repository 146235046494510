import React, { useRef, useState } from "react";

import dateFnsFormat from "date-fns/format";
import dateFnsParse from "date-fns/parse";
import { isMobileOnly } from "react-device-detect";

import OriginalDayPicker, { DayPickerProps, Modifier } from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import dateService from "../../../services/dateService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-regular-svg-icons";

function isDate(value: any) {
  return value instanceof Date && !isNaN(value.valueOf());
}

function parseDate(str: string) {
  // TODO: Update to date-fns v2 to support format + locale
  // const parsed = dateFnsParse(str, format, { locale });
  const matches = str.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
  if (matches) {
    const [, month, day, year] = matches;
    const iso8601Str = `${year}-${month}-${day}`;
    const parsed = dateFnsParse(iso8601Str);
    if (isDate(parsed)) {
      return parsed;
    }
  }

  return undefined;
}

function dayPickerFormatDate(date: Date, format: string, locale: string) {
  return dateFnsFormat(date, format, { locale });
}

interface IProps {
  value: string;
  inputId: string;
  required: boolean;
  onDaySelected(date: Date): void;
  dayPickerProps: Partial<DayPickerProps>;
  onDayPickerHide(): void;
  preventMobileView?: boolean;
  inputStyle?: React.CSSProperties;
  showIcon?: boolean;
  labelRef?: React.RefObject<HTMLLabelElement>;
  minDate?: Date;
  maxDate?: Date;
  testId?: string;
  inputClass?: string;
  overlayFixed?: boolean;
  scrollWithoutLabel?: boolean;
  formatDate?: (date: Date, format: string, locale: string) => string;
}

const format = "M/D/YYYY";

const DayPicker: React.FunctionComponent<IProps> = ({
  value,
  inputId,
  required,
  onDaySelected,
  dayPickerProps,
  onDayPickerHide,
  preventMobileView,
  inputStyle,
  showIcon,
  labelRef,
  minDate,
  maxDate,
  testId,
  inputClass,
  overlayFixed,
  scrollWithoutLabel,
  formatDate,
}) => {
  const selectedDatesForMobileView =
    dayPickerProps && dayPickerProps.selectedDays
      ? dayPickerProps.selectedDays
      : !!value
      ? dateFnsParse(value)
      : undefined;
  const dayPickerRef = useRef<DayPickerInput | null>(null);
  const [isInvalid, setIsInvalid] = useState(false);

  let disabledDaysModifiers: Array<Modifier> = [];
  if (maxDate) {
    disabledDaysModifiers.push({
      after: maxDate,
    });
  }

  if (minDate) {
    disabledDaysModifiers.push({
      before: minDate,
    });
  }

  return !isMobileOnly || preventMobileView ? (
    <label style={{ display: "flex", marginBottom: 0 }}>
      <DayPickerInput
        value={value}
        classNames={{
          container: "day-picker-input-container",
          overlay: "day-picker-overlay",
          overlayWrapper:
            "day-picker-overlay-wrapper" +
            (overlayFixed ? " day-picker-overlay-wrapped-fixed" : ""),
        }}
        onDayChange={(day, _, dayPickerInput) => {
          const isInputEmpty = dayPickerInput?.getInput()?.value === "";
          const isDateInvalid = !day && !isInputEmpty;

          let exceedsMaxDate = false;
          if (
            !isDateInvalid &&
            maxDate &&
            dateService.removeTimeComponents(day) >
              dateService.removeTimeComponents(maxDate)
          ) {
            exceedsMaxDate = true;
          }

          onDaySelected(day);
          setIsInvalid(isDateInvalid || exceedsMaxDate);

          if (dayPickerRef.current) {
            const input = dayPickerRef.current.getInput() as HTMLInputElement;
            if (input) {
              input.setCustomValidity(
                isDateInvalid
                  ? "The date is not valid"
                  : exceedsMaxDate
                  ? "The date exceeds the maximum date allowed"
                  : ""
              );

              input.focus();
            }
          }
        }}
        inputProps={{
          autoComplete: "off",
          className:
            "form-control" +
            (isInvalid ? " is-invalid" : "") +
            ` ${inputClass ?? ""}`,
          required: required,
          id: inputId,
          name: inputId,
          style: inputStyle,
          "data-testid": testId,
        }}
        ref={dayPickerRef}
        keepFocus={false}
        formatDate={formatDate ?? dayPickerFormatDate}
        format={format}
        parseDate={parseDate}
        placeholder={format}
        dayPickerProps={{
          ...dayPickerProps,
          disabledDays: disabledDaysModifiers,
        }}
        onDayPickerHide={onDayPickerHide}
        onDayPickerShow={() => {
          if (
            labelRef &&
            labelRef.current &&
            labelRef.current.scrollIntoView &&
            getComputedStyle(labelRef.current).display !== "none"
          ) {
            labelRef.current.scrollIntoView(true);
          } else if (scrollWithoutLabel) {
            const input = dayPickerRef.current?.getInput() as HTMLElement;

            if (input) {
              input.scrollIntoView(true);
            }
          }
        }}
      />
      {showIcon ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <FontAwesomeIcon
            icon={faCalendarDays}
            aria-hidden="true"
            style={{
              cursor: "pointer",
              marginTop: "2px",
              marginLeft: "7px",
              fontSize: "1.3rem",
              color: "#6C757D",
            }}
          />
        </div>
      ) : null}
    </label>
  ) : (
    <OriginalDayPicker
      {...dayPickerProps}
      selectedDays={selectedDatesForMobileView}
      onDayClick={(day) => onDaySelected(day)}
    />
  );
};

export default DayPicker;
export { format };
