import React, { useMemo } from "react";
import { connect } from "react-redux";
import NoJobCard from "../noJobCard";
import { Droppable } from "react-beautiful-dnd";
import JobCard, { RenderJobCardWithWrapperType } from "../jobCard/index";
import jobFinder from "../../../../../services/jobFinder";
import { IRootState } from "../../../../../store";
import { ICrew } from "../../../../../models/ICrew";
import { IMaintenanceJob } from "../../../../../models/IMaintenanceJob";
import { IDaySchedule } from "../../../../../models/IDaySchedule";
import { IDistance } from "../../../../../models/IDistance";
import { IOneTimeJob } from "../../../../../models/IOneTimeJob";
import { ICustomer } from "../../../../../models/ICustomer";
import { ICustomerAdditionalLocation } from "../../../../../models/ICustomerAdditionalLocation";
import Header from "./Header";
import { populateDriveTimes } from "../../../../../services/jobInstanceService";
import constants from "../../../../../constants";
import { CrewScheduleType } from "../../../../../slices/schedule/enums/crewScheduleType";
import JobHelper from "../../../../../services/jobHelper";
import { IMapLinkProps } from "../../../../../slices/schedule/components/types/IMapLinkProps";
import { useLoadDistances } from "../../../../../slices/schedule/hooks/useLoadDistances";

interface IProps extends IOwnProps {
  jobs: Array<IMaintenanceJob>;
  oneTimeJobs: Array<IOneTimeJob>;
  daySchedules: Array<IDaySchedule>;
  distances: Array<IDistance>;
  mapLinkProps: IMapLinkProps;
  customers: Array<ICustomer>;
  customerAdditionalLocations: Array<ICustomerAdditionalLocation>;
  isLastColumn: boolean;
}

interface IOwnProps {
  date: string;
  dayScheduleId: string;
  crew: ICrew;
  columnHeader: React.ReactNode;
  mode: string;
  isMapVisible: boolean;
  scheduleDayIndex: number;
  additionalLink: any;
  hideHeaderElement?: boolean;
  highlightHeader?: boolean;
  onJobCardMultiSelected?: (jobInstanceId: string) => void;
  renderJobCardWithWrapper: RenderJobCardWithWrapperType;
}

const ScheduleDay: React.FunctionComponent<IProps> = ({
  dayScheduleId,
  date,
  daySchedules,
  crew,
  jobs,
  oneTimeJobs,
  columnHeader,
  additionalLink,
  mode,
  isMapVisible,
  distances,
  scheduleDayIndex,
  mapLinkProps,
  customers,
  customerAdditionalLocations,
  hideHeaderElement,
  highlightHeader,
  onJobCardMultiSelected,
  isLastColumn,
  renderJobCardWithWrapper,
}) => {
  const { hasJobsMissingLocationsOuter, hasCrewMissingLocation } =
    useLoadDistances({
      dayScheduleId,
      mode,
      isMapVisible,
      crew,
    });

  const daySchedule = daySchedules.find(
    (daySchedule) => daySchedule.id === dayScheduleId
  );

  if (!daySchedule) {
    throw new Error(`day schedule not found ${dayScheduleId}`);
  }

  const filteredJobInstances = useMemo(
    () =>
      crew.scheduleType === CrewScheduleType.time
        ? JobHelper.getCrewJobsOrderedByTime(
            daySchedule.jobInstances,
            jobs,
            oneTimeJobs,
            customers,
            customerAdditionalLocations
          )
        : daySchedule.jobInstances,
    [
      crew.scheduleType,
      daySchedule.jobInstances,
      jobs,
      oneTimeJobs,
      customers,
      customerAdditionalLocations,
    ]
  );

  const jobsWithDriveTime = filteredJobInstances.map(
    populateDriveTimes(
      jobs,
      oneTimeJobs,
      distances,
      filteredJobInstances,
      customers,
      crew,
      customerAdditionalLocations
    )
  );

  return (
    <div
      className="schedule-week-day d-flex flex-column"
      key={date + crew.id}
      data-scheduledayindex={scheduleDayIndex}
      data-testid="schedule-column"
    >
      {!hideHeaderElement ? (
        <Header
          additionalLink={additionalLink}
          columnHeader={columnHeader}
          dayScheduleId={dayScheduleId}
          crew={crew}
          date={date}
          isMapVisible={isMapVisible}
          mode={mode}
          mapLinkProps={mapLinkProps}
          hasCrewMissingLocation={hasCrewMissingLocation}
          hasJobsMissingLocationsOuter={hasJobsMissingLocationsOuter}
          highlightHeader={highlightHeader}
          isCalendarHeader={false}
          isLastColumn={isLastColumn}
          hideSelectClearAllButton={false}
        />
      ) : null}
      <Droppable droppableId={dayScheduleId} type={constants.droppableTypeJob}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className={`board-card-container d-flex flex-column border ${
              snapshot.isDraggingOver &&
              crew.scheduleType === CrewScheduleType.time &&
              !jobsWithDriveTime.some(
                (ji) => ji.id === snapshot.draggingOverWith
              )
                ? "bg-primary"
                : "bg-secondary"
            }`}
            style={{ flex: "1" }}
            {...provided.droppableProps}
          >
            <div style={{ flex: "1" }}>
              {jobsWithDriveTime.map((jobInstance, jobIndex) => {
                const job = jobFinder.getJobForDayScheduleV2(
                  jobs,
                  oneTimeJobs,
                  jobInstance
                );
                if (job === null) {
                  console.log(
                    `schedule-day-index droppable - failed to load job - job instance id ${jobInstance.id}`
                  );
                  return null;
                }

                return (
                  <JobCard
                    date={date}
                    jobType={job.type}
                    key={jobInstance.id}
                    job={job}
                    jobInstance={jobInstance}
                    lastJob={jobIndex === jobsWithDriveTime.length - 1}
                    distanceFromPreviousJob={
                      jobInstance.distanceFromPreviousJob
                    }
                    distanceFromPreviousJobErrorLoading={
                      jobInstance.distanceFromPreviousJobErrorLoading
                    }
                    distanceToCrewLocation={jobInstance.distanceToCrewLocation}
                    distanceToCrewLocationErrorLoading={
                      jobInstance.distanceToCrewLocationJobErrorLoading
                    }
                    jobIndex={jobIndex}
                    isMapVisible={isMapVisible}
                    crew={crew}
                    onJobCardMultiSelected={onJobCardMultiSelected}
                    renderJobCardWithWrapper={renderJobCardWithWrapper}
                    hideContextMenu={false}
                  />
                );
              })}

              {jobsWithDriveTime.length === 0 ? <NoJobCard /> : null}

              <div
                style={{
                  display:
                    crew.scheduleType === CrewScheduleType.time
                      ? "none"
                      : undefined,
                }}
              >
                {provided.placeholder}
              </div>
            </div>
          </div>
        )}
      </Droppable>
    </div>
  );
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => ({
  date: ownProps.date,
  dayScheduleId: ownProps.dayScheduleId,
  jobs: state.job.jobs,
  oneTimeJobs: state.job.oneTimeJobs,
  daySchedules: state.schedule.daySchedules,
  distances: state.distanceCache.distances,
  crew: ownProps.crew,
  columnHeader: ownProps.columnHeader,
  additionalLink: ownProps.additionalLink,
  mode: ownProps.mode,
  isMapVisible: ownProps.isMapVisible,
  scheduleDayIndex: ownProps.scheduleDayIndex,
  customers: state.customer.customers,
  customerAdditionalLocations: state.customer.customerAdditionalLocations,
  hideHeaderElement: ownProps.hideHeaderElement,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleDay);
