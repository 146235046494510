import React, { Fragment } from "react";
import Navbar from "./components/Navbar";
import InstallPrompt from "./components/InstallPrompt";

interface IProps {
  subHeaderLeftSideContent?: React.ReactNode;
  subHeaderRightSideContent?: React.ReactNode;
  billingContext?: boolean;
  notFluid?: boolean;
  hideShiftSchedule?: boolean;
  hideNavBarNavigation?: boolean;
}

const PageWithNavBar2: React.FunctionComponent<IProps> = (props) => {
  const {
    subHeaderLeftSideContent,
    subHeaderRightSideContent,
    billingContext,
    notFluid,
    hideShiftSchedule,
    hideNavBarNavigation,
  } = props;

  return (
    <Fragment>
      <InstallPrompt />
      <Navbar
        subHeaderLeftSideContent={subHeaderLeftSideContent}
        subHeaderRightSideContent={subHeaderRightSideContent}
        billingContext={billingContext}
        hideShiftSchedule={hideShiftSchedule}
        hideNavBarNavigation={hideNavBarNavigation}
      />
      <div
        className={notFluid ? "container container-page" : "container-fluid"}
      >
        {props.children}
      </div>
    </Fragment>
  );
};

// TODO: To indicate name better
export default PageWithNavBar2;
