import React, { useEffect, useState } from "react";
import FormContainer, {
  EnrichFormDataCallback,
  ErrorMessageType,
  FormDefaults,
  IAdditionalSaveButton,
  IValidateResult,
} from "../components/FormContainer";
import { Observable } from "rxjs";
import { timeout } from "rxjs/operators";
import { formSaveDefaultTimeout } from "../../../formGenerator/actionEpics";
import { getFormSaveErrorMessage } from "../../../services/httpErrorHandler";
import { FormTypesV2 } from "../../../formGenerator/formTypes";
import { useDispatch } from "react-redux";
import { formV2ActionCreators } from "../../../modules/formV2";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

type EnrichFormData<T> = (data: T) => T;

interface IProps<TSaveResponse, TSaveRequest = void> {
  validate?(): IValidateResult;
  save(
    enrichFormData?: EnrichFormData<TSaveRequest>
  ): Observable<TSaveResponse>;
  onSaveComplete: (response: TSaveResponse) => void;
  onCancel: () => void;
  formHeader: string;
  children: React.ReactNode | ((formDefaults: FormDefaults) => React.ReactNode);
  saveButtonText?: string;
  useKeyboard?: boolean;
  hideSave?: boolean;
  disableFade?: boolean;
  hasFormDataChanged?: () => boolean;
  size?: string;
  showForm?: boolean;
  disableSave?: boolean;
  saveHandlerOverride?(): void;
  formType: FormTypesV2;
  errorMessage: ErrorMessageType;
  setErrorMessage: React.Dispatch<React.SetStateAction<ErrorMessageType>>;
  getCustomErrorMessage?: (err: unknown) => ErrorMessageType | null;
  additionalSaveButtons?: Array<IAdditionalSaveButton>;
  additionalPrimaryButtons?: Array<IAdditionalSaveButton>;
  confirmation?: React.ReactNode | null;
  customTimeout?: number;
  customDefaultErrorMessage?: string;
  disableSubmitOnEnterPress?: boolean;
}

const FormContainerWithoutRedux = <TSaveResponse, TSaveRequest = void>(
  props: IProps<TSaveResponse, TSaveRequest>
) => {
  const {
    save,
    onSaveComplete,
    onCancel,
    formType,
    errorMessage,
    setErrorMessage,
    confirmation,
    customTimeout,
    customDefaultErrorMessage,
    disableSubmitOnEnterPress,
    getCustomErrorMessage,
  } = props;

  const [saving, setSaving] = useState(false);
  const [confirmationPromptData, setConfirmationPromptData] = useState<{
    showPrompt: boolean;
    enrichFormData?: EnrichFormDataCallback;
  }>({ showPrompt: false });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      formV2ActionCreators.openForm({
        formType,
      })
    );
  }, [dispatch, formType]);

  const executeSave = (enrichFormData?: EnrichFormDataCallback) => {
    setSaving(true);
    setErrorMessage("");

    save(enrichFormData)
      .pipe(timeout(customTimeout ?? formSaveDefaultTimeout))
      .subscribe({
        next: (response) => {
          setSaving(false);

          dispatch(
            formV2ActionCreators.closeForm({
              formType,
            })
          );

          onSaveComplete(response);
        },

        error: (error) => {
          setSaving(false);

          let errorMessage: string | React.ReactNode = getFormSaveErrorMessage(
            error,
            undefined,
            customDefaultErrorMessage
          );
          if (typeof getCustomErrorMessage === "function") {
            const customErrorMessage = getCustomErrorMessage(error);
            if (customErrorMessage) {
              errorMessage = customErrorMessage;
            }
          }

          setErrorMessage(errorMessage);
        },
      });
  };

  return (
    <>
      <div
        onClick={(e) => {
          // Prevent clicks in form from affecting parents.
          // This fixes issues like Opportunity Card being selected
          // (due to parent div having onClick handler)
          // when user clicks modal
          e.stopPropagation();
        }}
      >
        <FormContainer
          {...props}
          formKey={formType.toString()}
          setErrorMessage={(e) => setErrorMessage(e)}
          showForm={true}
          errorMessage={errorMessage}
          saving={saving}
          startSave={(_, __, enrichFormData) => {
            if (confirmation) {
              setConfirmationPromptData({ showPrompt: true, enrichFormData });
            } else {
              executeSave(enrichFormData);
            }
          }}
          cancel={() => {
            dispatch(
              formV2ActionCreators.closeForm({
                formType,
              })
            );

            onCancel();
          }}
          hasFormDataChanged={props.hasFormDataChanged}
          disableSubmitOnEnterPress={disableSubmitOnEnterPress}
        >
          {props.children}
        </FormContainer>
      </div>
      {confirmationPromptData.showPrompt && confirmation ? (
        <Modal isOpen={true}>
          <ModalBody>{confirmation}</ModalBody>
          <ModalFooter>
            <button
              className="btn btn-primary"
              onClick={() => {
                executeSave(confirmationPromptData.enrichFormData);

                setConfirmationPromptData({
                  showPrompt: false,
                  enrichFormData: undefined,
                });
              }}
            >
              Yes
            </button>

            <button
              className="btn btn-secondary ml-2"
              onClick={() => {
                setConfirmationPromptData({
                  showPrompt: false,
                  enrichFormData: undefined,
                });
              }}
            >
              No
            </button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
};

export default FormContainerWithoutRedux;
