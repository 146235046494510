import { Observable } from "rxjs";
import { ajax, AjaxResponse } from "rxjs/ajax";
import { map } from "rxjs/operators";
import {
  buildUrl,
  executeWithHeaders,
} from "../../../services/remoteDataProvider";
import { OpportunityStatus } from "../enums/opportunityStatus";
import { IOpportunity } from "../models/IOpportunity";
import { IOpportunityBoard } from "../models/IOpportunityBoard";

const opportunityDataProvider = {
  getOpportunities: (request: { opportunityIds?: Array<string> }) => {
    return executeWithHeaders((headers) =>
      ajax
        .post(
          buildUrl("opportunity/getbyids"),
          JSON.stringify(request),
          headers
        )
        .pipe(
          map((result) => result.response.opportunities as Array<IOpportunity>)
        )
    );
  },

  getArchivedOpportunities: (request: { customerIds?: Array<string> }) => {
    return executeWithHeaders((headers) =>
      ajax
        .post(
          buildUrl("opportunity/getarchived"),
          JSON.stringify(request),
          headers
        )
        .pipe(
          map((result) => result.response.opportunities as Array<IOpportunity>)
        )
    );
  },

  getOpportunityBoard: () => {
    return executeWithHeaders((headers) =>
      ajax
        .get(buildUrl("opportunityBoard"), headers)
        .pipe(map((result) => result.response as IOpportunityBoard))
    );
  },

  saveOpportunity: (opportunity: Partial<IOpportunity>) => {
    let saveFn: (
      url: string,
      body: any,
      headers: any
    ) => Observable<AjaxResponse>;
    let urlSegment: string;
    if (opportunity.id) {
      saveFn = ajax.patch;
      urlSegment = `opportunity/${opportunity.id}`;
    } else {
      saveFn = ajax.post;
      urlSegment = "opportunity";
    }

    return executeWithHeaders((headers) =>
      saveFn(buildUrl(urlSegment), JSON.stringify(opportunity), headers)
    ).pipe(
      map((result) => {
        return result.response as IOpportunity & {
          proposalIdToReload?: string | null;
        };
      })
    );
  },

  moveOpportunity: (
    opportunityId: string,
    precedingOpportunityId: string,
    status: OpportunityStatus
  ) => {
    return executeWithHeaders((headers) =>
      ajax.post(
        buildUrl("opportunity/move"),
        JSON.stringify({ opportunityId, precedingOpportunityId, status }),
        headers
      )
    );
  },

  deleteOpportunity: (opportunityId: string) => {
    return executeWithHeaders((headers) =>
      ajax.delete(buildUrl(`opportunity/${opportunityId}`), headers)
    );
  },

  archiveOpportunities: (request: { opportunityIds: Array<string> }) => {
    return executeWithHeaders((headers) =>
      ajax
        .post(
          buildUrl("opportunity/bulkarchive"),
          JSON.stringify(request),
          headers
        )
        .pipe(
          map((result) => result.response.opportunities as Array<IOpportunity>)
        )
    );
  },
};

export default opportunityDataProvider;
